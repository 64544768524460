import {Typography, Tag, Space, Button} from 'antd';
import {SortOrder} from 'antd/lib/table/interface';
import moment from 'moment-timezone';
import {useNavigate} from 'react-router-dom';
import {FormatTimezoneAbbr} from '@canimmunize/tools';
import {FHIRTable} from '../fhir-table';
import {AppointmentStatusTag} from '../appointment/appointment-ux';
import {AbilityContext} from '../../services/roles/ability-context';
import * as Icons from "@ant-design/icons";
import {SchedulerModal} from "../appointment/scheduler/schedule-modal";
import React, {useState} from 'react';

const {Title} = Typography;

export const AppointmentsWidget = (props) => {
  const {patient, refreshKey} = props;
  const ability = React.useContext(AbilityContext);
  const navigate = useNavigate();
  const [bookAppointmentModalVisible, setBookAppointmentModalVisible] = useState(false);
  const [refreshApmtWidgetKey, setRefreshApmtWidgetKey] = React.useState<any>(refreshKey);
  const hasAbilityBookAppointment = ability.can('create', 'patients', 'appointment');
  const defaultAge = moment().diff(patient.birthdate, 'years');

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingBottom: 15,
          alignItems: 'center',
        }}
      >
        <div>
          <Title level={4}>Appointments</Title>
        </div>

        <Space>
          {hasAbilityBookAppointment && (
            <Button
              type="primary"
              onClick={() => {
                setBookAppointmentModalVisible(true);
              }}
            >
              <Icons.CalendarOutlined /> Book Appointment
            </Button>
          )}
        </Space>
      </div>
      <FHIRTable
        fhirResource="appointment"
        mode="table"
        fixedFilters={{patientId: patient.id, includeClinic: 'true', includeNoteCounts: 'true'}}
        columns={columns}
        hideSearch
        size="small"
        style={{width: '100%'}}
        rowClassName="pointer"
        onClickRow={(appointment) => navigate(`/appointments/${appointment.id}`)}
        triggerRefresh={refreshApmtWidgetKey}
      />
      {hasAbilityBookAppointment && (
          <SchedulerModal
              visible={bookAppointmentModalVisible}
              setVisible={setBookAppointmentModalVisible}
              patientId={patient.id}
              onSchedule={setRefreshApmtWidgetKey}
              defaultAge={defaultAge}
          />
      )}
    </>
  );
};

export const columns = [
  {
    title: 'Date',
    dataIndex: 'datetime',
    key: 'datetime',
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    defaultSortOrder: 'descend' as SortOrder,
    sorter: (a, b) => moment(a.datetime).diff(moment(b.datetime)),
    render: (_, appt) => (
      <a href="#" onClick={(e) => e.preventDefault()}>{`${moment
        .tz(appt.datetime, appt.clinic ? appt.clinic.timezone : appt.timezone)
        .format('LLL')} ${FormatTimezoneAbbr(
        moment.tz(appt.datetime, appt.clinic ? appt.clinic.timezone : appt.timezone).format('zz'),
        'en',
        appt.clinic ? appt.clinic.timezone : appt.timezone
      )}`}</a>
    ),
  },
  {
    title: 'Status',
    render: (_, app) => <AppointmentStatusTag appointment={app} />,
  },
  {
    title: 'Clinic',
    render: (_, app) => app.calendar?.clinic?.name,
  },
  {
    title: 'Calendar',
    render: (_, app) => app.calendar?.name,
  },
  {
    title: 'Notes',
    render: (_, app) => (
      <Tag color={app.aefiNoteCount ? 'orange' : undefined}>
        {app.noteCount} ({app.aefiNoteCount} AEFI)
      </Tag>
    ),
  },
];
