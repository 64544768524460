import {faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Alert, Button, Col, Row} from 'antd';
import {Formik, FormikHelpers} from 'formik';
import {Radio, Form, Input} from 'formik-antd';
import React from 'react';
import MaskedInput from 'antd-mask-input';
import axios from 'axios';
import {useCOVIDPortalConfig} from '../../util/portal-config';
import {Ids, StrLang} from '@canimmunize/tools';
import {RequiredValidationRuleId} from '../../../../validation/validation-rules/required-validation';
import {ValidateFormItem} from '../../../../components/validate-form-item';
import {
  EmailValidationRules,
  PhoneValidationRules,
  RequiredBirthDateValidationRules,
} from '../../../../services/ui-validation-rules';
import Axios from 'axios';
import {ReactDatePicker} from '../../../../components/pickers/react-datepicker';
import {useUrlData} from '../../../../components/scheduler/util';
import {SessionConfigContext} from '../../util/session-config';
import {useNavigate} from 'react-router-dom';

export const NovaScotiaPortalAuthForm = (props) => {
  const [mode, setMode] =
    React.useState<'email' | 'phone' | 'email-sent' | 'verify-phone'>('email');
  const [phone, setPhone] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [emailSent, setEmailSent] = React.useState(false);
  const [hcn, setHcn] = React.useState('');

  const formProps = {
    mode,
    setMode,
    phone,
    setPhone,
    email,
    setEmail,
    hcn,
    setHcn,
    emailSent,
    setEmailSent,
    setPage: props.setPage,
    setClinicEntry: props.setClinicEntry,
  };

  return (
    <Row>
      <Col>
        {/* Email Form */}
        {mode === 'email' && <EmailForm {...formProps} />}

        {/* Phone Form */}
        {mode === 'phone' && <PhoneForm {...formProps} />}

        {/* Verify Phone Form*/}
        {mode === 'verify-phone' && <VerifyPhoneForm {...formProps} />}
      </Col>
    </Row>
  );
};

const EmailForm = (props) => {
  const [submitting, setSubmitting] = React.useState(false);
  const {setMode, setEmail, emailSent, setEmailSent, setHcn, hcn} = props;
  const {syncUrl, config} = useCOVIDPortalConfig();
  const [error, setError] = React.useState<{message: string; stringId?: string}>();
  const handleSubmit = async (values, helpers) => {
    setSubmitting(true);
    setError(undefined);
    setEmail(values.email);
    const result = await axios
      .post(`${syncUrl}/public/portal/auth`, {
        ...values,
        formType: 'ns-portal-email',
      })
      .catch((err) => err.response.data);

    setSubmitting(false);
    if (result.status === 200) {
      setEmailSent(true);
    } else {
      window.scrollTo(0, 0);
      console.log(result);
      setError(result.error);
    }
  };
  return (
    <Formik initialValues={{hcn, email: ''}} onSubmit={handleSubmit}>
      {(formikProps) => {
        return (
          <Form layout="vertical" style={{fontSize: 18}}>
            {emailSent && (
              <Alert
                showIcon
                type="success"
                message={
                  <div>
                    An email has been sent to <b>{props.email}</b>.
                  </div>
                }
                description="Click on the link in the email to access your vaccination record. You may now close this window."
                style={{marginBottom: 15}}
              />
            )}
            {error && (
              <Alert type="error" message={error?.message} style={{marginBottom: 15}} showIcon />
            )}
            <div style={{marginBottom: 10}}>
              <SectionTitle title="Access with Email" />
              <b>
                Enter your Canadian provincial or territorial Health Card number and email address
                used to book your COVID-19 vaccination appointment. Once you submit the form, you
                will receive an email with a link to your Nova Scotia COVID-19 Proof of Vaccination.
              </b>
            </div>
            <div style={{maxWidth: 300}}>
              <ValidateFormItem
                validationRules={[
                  {
                    validationRuleType: RequiredValidationRuleId,
                  },
                ]}
                renderFormItem={(validate) => {
                  return (
                    <Form.Item
                      label="Health Card Number"
                      name="hcn"
                      // tooltip="If you have an Ontario health card number please enter the last 4 numeric digits and do not include the version code."
                      required
                      validate={validate}
                    >
                      <Input
                        {...formikProps}
                        name="hcn"
                        size="large"
                        onChange={(e) => setHcn(e.target.value)}
                        autoComplete="off"
                      />
                    </Form.Item>
                  );
                }}
              />
              <ValidateFormItem
                validationRules={[
                  {
                    validationRuleType: RequiredValidationRuleId,
                  },
                  ...EmailValidationRules,
                ]}
                renderFormItem={(validate) => {
                  return (
                    <Form.Item
                      label="Email"
                      name="email"
                      required
                      validate={validate}
                      tooltip='You must use the email address that you provided when booking your COVID-19 vaccination appointment. If you did not provide an email, press the "Verify by Phone" button below.'
                    >
                      <Input {...formikProps} name="email" size="large" />
                    </Form.Item>
                  );
                }}
              />
            </div>

            <div style={{marginBottom: 25, display: 'flex'}}>
              <Button
                size="large"
                loading={submitting}
                onClick={() => formikProps.handleSubmit()}
                style={{backgroundColor: config.primaryColor, border: 'none', color: 'white'}}
              >
                Submit
              </Button>
            </div>

            <hr />
            <SectionTitle title="Access with Phone Number" />
            <div>
              <div style={{marginBottom: 15}}>
                If you didn’t provide an email address when booking your COVID-19 vaccination
                appointment, you can access your proof of vaccination by providing your phone
                number. Your verification code will be sent by text or an automated call.
              </div>
              <Button
                style={{color: config.primaryColor, marginRight: 5, marginBottom: 10}}
                size="large"
                onClick={() => {
                  setMode('phone');
                }}
              >
                Verify by phone
              </Button>
            </div>
            <hr />
            <SectionTitle title="Access Through 1-833" />
            <div>
              <div style={{marginBottom: 15}}>
                If you’re not able to access your proof of vaccination by email or phone, call
                1-833-797-7772 for support.
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export const SectionTitle = ({title}) => {
  const {config} = useCOVIDPortalConfig();
  return (
    <div
      style={{
        color: config.primaryColor,
        fontSize: 14,
        fontWeight: 'bold',
        marginBottom: 15,
        marginTop: 10,
      }}
    >
      {title.toUpperCase()}
    </div>
  );
};

const PhoneForm = (props) => {
  const [submitting, setSubmitting] = React.useState(false);
  const [error, setError] = React.useState<{message: string; stringId?: string}>();
  const {setMode, setPhone, setHcn, hcn} = props;
  const {config, syncUrl} = useCOVIDPortalConfig();
  const {lang} = useUrlData();

  const handleSubmit = async (values, helpers) => {
    const cleanPhone = ('' + values.phone).replace(/\D/g, '');
    setPhone(cleanPhone);
    setHcn(values.hcn);

    setSubmitting(true);
    setError(undefined);

    const result = await axios
      .post(`${syncUrl}/public/portal/auth`, {
        ...values,
        formType: 'ns-portal-phone',
      })
      .catch((err) => err.response.data);

    setSubmitting(false);

    if (result.status === 200) {
      // setMode('email-sent');
      // setEmailSent(true);
      setMode('verify-phone');
    } else {
      window.scrollTo(0, 0);
      console.log(result);
      setError(result.error);
    }
  };
  return (
    <Formik
      initialValues={{hcn, channel: 'sms', phone: '', birthdate: null}}
      onSubmit={handleSubmit}
    >
      {(formikProps) => {
        return (
          <Form layout="vertical" style={{fontSize: 18}}>
            {error && (
              <Alert type="error" message={error?.message} style={{marginBottom: 15}} showIcon />
            )}
            <div style={{marginBottom: 10}}>
              <SectionTitle title="Access with Phone Number" />
              <b>
                Enter your Canadian provincial or territorial Health Card number, date of birth
                shown on your Health Card and the phone number used to book your COVID-19
                vaccination appointment. Once you submit the form, you will receive your
                verification code by text or automated call. Your phone provider's standard rates
                for sending and receiving text messages still apply.
              </b>
            </div>
            <div style={{maxWidth: 300}}>
              <ValidateFormItem
                validationRules={[
                  {
                    validationRuleType: RequiredValidationRuleId,
                  },
                ]}
                renderFormItem={(validate) => {
                  return (
                    <Form.Item
                      label="Health Card Number"
                      name="hcn"
                      // tooltip="If you have an Ontario health card number please enter the last 4 numeric digits and do not include the version code."
                      required
                      validate={validate}
                    >
                      <Input {...formikProps} name="hcn" size="large" />
                    </Form.Item>
                  );
                }}
              />
              <ValidateFormItem
                validationRules={RequiredBirthDateValidationRules}
                renderFormItem={(validate) => {
                  return (
                    <Form.Item
                      label={StrLang(lang, Ids.date_of_birth)}
                      name="birthdate"
                      required
                      validate={validate}
                    >
                      <ReactDatePicker
                        name="birthdate"
                        popperPlacement="top"
                        dateFormat="yyyy/MM/dd"
                        value={formikProps.values.birthdate}
                        placeholder="YYYY/MM/DD"
                        onChange={(e) => formikProps.setFieldValue('birthdate', e)}
                      />
                    </Form.Item>
                  );
                }}
              />
              {/* Twilio Verify Channel */}
              <Form.Item label="Phone Type" name="channel" required>
                <Radio.Group
                  options={[
                    {label: 'Mobile Phone', value: 'sms'},
                    {label: 'Landline', value: 'call'},
                  ]}
                  name="channel"
                  optionType="button"
                  size="large"
                  buttonStyle="solid"
                />
              </Form.Item>
              <ValidateFormItem
                validationRules={[
                  ...PhoneValidationRules,
                  {
                    validationRuleType: RequiredValidationRuleId,
                  },
                ]}
                renderFormItem={(validate) => {
                  return (
                    <Form.Item label="Phone (10 digits)" name="phone" required validate={validate}>
                      <MaskedInput
                        {...formikProps}
                        onChange={(e) => {
                          formikProps.setFieldValue('phone', e.target.value);
                        }}
                        name="phone"
                        size="large"
                        mask="(111) 111-1111"
                      />
                    </Form.Item>
                  );
                }}
              />
            </div>
            <div>
              <p>
                Note: In order to receive your 6-digit code, your phone number will be sent to
                Twilio - a company in the United States. Neither your name, Health Card Number or
                Date of Birth are sent to Twilio. Twilio will not otherwise use, disclose or retain
                your phone number, the sole purpose is to issue you the 6-digit code to access your
                records. To find out more information about Twilio and their privacy policies and
                practices,{' '}
                <a href="https://www.twilio.com/legal/privacy" target="_blank">
                  click here
                </a>
                .
              </p>
              <p>
                By clicking submit below, you consent to your phone number being sent to Twilio for
                the purpose of receiving the code.
              </p>
            </div>

            <div style={{marginBottom: 25}}>
              <Button
                size="large"
                loading={submitting}
                onClick={() => formikProps.handleSubmit()}
                style={{backgroundColor: config.primaryColor, border: 'none', color: 'white'}}
              >
                Submit
              </Button>
            </div>

            <hr />
            <div>
              {/* <div style={{marginBottom: 15}}>
                      If you provided an email address while booking your COVID-19 vaccination appointment, you can
                    </div> */}
              <Button
                style={{color: config.primaryColor, marginRight: 5}}
                size="large"
                onClick={() => setMode('email')}
              >
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  color={config.primaryColor}
                  style={{marginRight: 5}}
                />
                Back to Access with Email
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export const VerifyPhoneForm = (props) => {
  const {lang, syncUrl, config} = useCOVIDPortalConfig();
  const {setMode, setCode, setPage, phone, setClinicEntry} = props;
  const [error, setError] = React.useState<{message: string; stringId?: string}>();
  const {setSessionId} = React.useContext(SessionConfigContext);

  const handleSubmit = async (values, helpers: FormikHelpers<any>) => {
    helpers.setSubmitting(true);
    const result = await Axios.post(`${syncUrl}/public/receipt`, {code: values.code, phone}).catch(
      (err) => err.response.data
    );

    if (result.status === 200) {
      setClinicEntry(result.data);
      if (setSessionId) setSessionId(result.data?.sessionId);
      setPage(2);
    } else {
      window.scrollTo(0, 0);
      setError(result.error);
    }

    helpers.setSubmitting(false);
  };

  return (
    <Formik initialValues={{phone}} onSubmit={handleSubmit}>
      {(formikProps) => {
        return (
          <Form layout="vertical" style={{fontSize: 18}}>
            {error && (
              <Alert
                type="error"
                message={StrLang(lang, Ids[error?.stringId || '']) || error?.message}
                style={{marginBottom: 15}}
                showIcon
              />
            )}
            <div style={{marginBottom: 10}}>
              <b>
                {`${StrLang(lang, Ids.six_digit_code_will_be_sent_to)} ${formatPhoneNumber(
                  phone
                )}. ${StrLang(lang, Ids.enter_code_to_access_record)}`}
              </b>
            </div>
            <div style={{maxWidth: 300}}>
              <Form.Item
                label={StrLang(lang, Ids.enter_6_digit_code)}
                name="code"
                tooltip={StrLang(lang, Ids.this_field_is_required)}
                required
              >
                <Input
                  {...formikProps}
                  name="code"
                  size="large"
                  placeholder="XXXXXX"
                  maxLength={6}
                  autoFocus
                />
              </Form.Item>
            </div>

            <div style={{marginBottom: 25}}>
              <Button
                size="large"
                loading={formikProps.isSubmitting}
                onClick={() => formikProps.handleSubmit()}
                style={{backgroundColor: config.primaryColor, border: 'none', color: 'white'}}
              >
                {StrLang(lang, Ids.submit)}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

function formatPhoneNumber(phone) {
  //normalize string and remove all unnecessary characters
  phone = phone.replace(/[^\d]/g, '');

  //check if number length equals to 10
  if (phone.length == 10) {
    //reformat and return phone number
    return phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  }

  return null;
}
