import React from 'react';

interface ButtonGroupProps {
  children?: React.ReactNode;
  alignment?: string;
  id: string;
}

export const ButtonGroup = (props: ButtonGroupProps) => {
  const style = {display: 'flex', alignItems: 'center', flexGrow: 1} as React.CSSProperties;
  const children = Array.isArray(props.children) ? props.children : [props.children];
  if (props.alignment) style.justifyContent = props.alignment;
  return (
    <div style={style}>
      {children.map((childElement, idx) => (
        <div key={`${props.id}-${idx}`} style={{marginLeft: idx === 0 ? 0 : 8}}>
          {childElement}
        </div>
      ))}
    </div>
  );
};
