"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_CapabilityStatement = exports.CapabilityStatementFhirVersionKind = exports.CapabilityStatementKindKind = exports.CapabilityStatementStatusKind = void 0;
var t = __importStar(require("io-ts"));
var RTTI_id_1 = require("../Scalar/RTTI_id");
var RTTI_Meta_1 = require("./RTTI_Meta");
var RTTI_uri_1 = require("../Scalar/RTTI_uri");
var RTTI_Element_1 = require("./RTTI_Element");
var RTTI_code_1 = require("../Scalar/RTTI_code");
var RTTI_Narrative_1 = require("./RTTI_Narrative");
var RTTI_ResourceList_1 = require("../Union/RTTI_ResourceList");
var RTTI_Extension_1 = require("./RTTI_Extension");
var RTTI_dateTime_1 = require("../Scalar/RTTI_dateTime");
var RTTI_ContactDetail_1 = require("./RTTI_ContactDetail");
var RTTI_markdown_1 = require("../Scalar/RTTI_markdown");
var RTTI_UsageContext_1 = require("./RTTI_UsageContext");
var RTTI_CodeableConcept_1 = require("./RTTI_CodeableConcept");
var RTTI_canonical_1 = require("../Scalar/RTTI_canonical");
var RTTI_CapabilityStatement_Software_1 = require("./RTTI_CapabilityStatement_Software");
var RTTI_CapabilityStatement_Implementation_1 = require("./RTTI_CapabilityStatement_Implementation");
var RTTI_CapabilityStatement_Rest_1 = require("./RTTI_CapabilityStatement_Rest");
var RTTI_CapabilityStatement_Messaging_1 = require("./RTTI_CapabilityStatement_Messaging");
var RTTI_CapabilityStatement_Document_1 = require("./RTTI_CapabilityStatement_Document");
var CapabilityStatementStatusKind;
(function (CapabilityStatementStatusKind) {
    CapabilityStatementStatusKind["_draft"] = "draft";
    CapabilityStatementStatusKind["_active"] = "active";
    CapabilityStatementStatusKind["_retired"] = "retired";
    CapabilityStatementStatusKind["_unknown"] = "unknown";
})(CapabilityStatementStatusKind = exports.CapabilityStatementStatusKind || (exports.CapabilityStatementStatusKind = {}));
var CapabilityStatementKindKind;
(function (CapabilityStatementKindKind) {
    CapabilityStatementKindKind["_instance"] = "instance";
    CapabilityStatementKindKind["_capability"] = "capability";
    CapabilityStatementKindKind["_requirements"] = "requirements";
})(CapabilityStatementKindKind = exports.CapabilityStatementKindKind || (exports.CapabilityStatementKindKind = {}));
var CapabilityStatementFhirVersionKind;
(function (CapabilityStatementFhirVersionKind) {
    CapabilityStatementFhirVersionKind["_001"] = "0.01";
    CapabilityStatementFhirVersionKind["_005"] = "0.05";
    CapabilityStatementFhirVersionKind["_006"] = "0.06";
    CapabilityStatementFhirVersionKind["_011"] = "0.11";
    CapabilityStatementFhirVersionKind["_0080"] = "0.0.80";
    CapabilityStatementFhirVersionKind["_0081"] = "0.0.81";
    CapabilityStatementFhirVersionKind["_0082"] = "0.0.82";
    CapabilityStatementFhirVersionKind["_040"] = "0.4.0";
    CapabilityStatementFhirVersionKind["_050"] = "0.5.0";
    CapabilityStatementFhirVersionKind["_100"] = "1.0.0";
    CapabilityStatementFhirVersionKind["_101"] = "1.0.1";
    CapabilityStatementFhirVersionKind["_102"] = "1.0.2";
    CapabilityStatementFhirVersionKind["_110"] = "1.1.0";
    CapabilityStatementFhirVersionKind["_140"] = "1.4.0";
    CapabilityStatementFhirVersionKind["_160"] = "1.6.0";
    CapabilityStatementFhirVersionKind["_180"] = "1.8.0";
    CapabilityStatementFhirVersionKind["_300"] = "3.0.0";
    CapabilityStatementFhirVersionKind["_301"] = "3.0.1";
    CapabilityStatementFhirVersionKind["_330"] = "3.3.0";
    CapabilityStatementFhirVersionKind["_350"] = "3.5.0";
    CapabilityStatementFhirVersionKind["_400"] = "4.0.0";
})(CapabilityStatementFhirVersionKind = exports.CapabilityStatementFhirVersionKind || (exports.CapabilityStatementFhirVersionKind = {}));
var EnumType_1 = require("../../EnumType");
exports.RTTI_CapabilityStatement = t.recursion('ICapabilityStatement', function () {
    return t.intersection([
        t.type({
            resourceType: t.literal('CapabilityStatement')
        }),
        t.partial({
            id: RTTI_id_1.RTTI_id,
            meta: RTTI_Meta_1.RTTI_Meta,
            implicitRules: RTTI_uri_1.RTTI_uri,
            _implicitRules: RTTI_Element_1.RTTI_Element,
            language: RTTI_code_1.RTTI_code,
            _language: RTTI_Element_1.RTTI_Element,
            text: RTTI_Narrative_1.RTTI_Narrative,
            contained: t.array(RTTI_ResourceList_1.RTTI_ResourceList),
            extension: t.array(RTTI_Extension_1.RTTI_Extension),
            modifierExtension: t.array(RTTI_Extension_1.RTTI_Extension),
            url: RTTI_uri_1.RTTI_uri,
            _url: RTTI_Element_1.RTTI_Element,
            version: t.string,
            _version: RTTI_Element_1.RTTI_Element,
            name: t.string,
            _name: RTTI_Element_1.RTTI_Element,
            title: t.string,
            _title: RTTI_Element_1.RTTI_Element,
            status: EnumType_1.createEnumType(CapabilityStatementStatusKind, 'CapabilityStatementStatusKind'),
            _status: RTTI_Element_1.RTTI_Element,
            experimental: t.boolean,
            _experimental: RTTI_Element_1.RTTI_Element,
            date: RTTI_dateTime_1.RTTI_dateTime,
            _date: RTTI_Element_1.RTTI_Element,
            publisher: t.string,
            _publisher: RTTI_Element_1.RTTI_Element,
            contact: t.array(RTTI_ContactDetail_1.RTTI_ContactDetail),
            description: RTTI_markdown_1.RTTI_markdown,
            _description: RTTI_Element_1.RTTI_Element,
            useContext: t.array(RTTI_UsageContext_1.RTTI_UsageContext),
            jurisdiction: t.array(RTTI_CodeableConcept_1.RTTI_CodeableConcept),
            purpose: RTTI_markdown_1.RTTI_markdown,
            _purpose: RTTI_Element_1.RTTI_Element,
            copyright: RTTI_markdown_1.RTTI_markdown,
            _copyright: RTTI_Element_1.RTTI_Element,
            kind: EnumType_1.createEnumType(CapabilityStatementKindKind, 'CapabilityStatementKindKind'),
            _kind: RTTI_Element_1.RTTI_Element,
            instantiates: t.array(RTTI_canonical_1.RTTI_canonical),
            imports: t.array(RTTI_canonical_1.RTTI_canonical),
            software: RTTI_CapabilityStatement_Software_1.RTTI_CapabilityStatement_Software,
            implementation: RTTI_CapabilityStatement_Implementation_1.RTTI_CapabilityStatement_Implementation,
            fhirVersion: EnumType_1.createEnumType(CapabilityStatementFhirVersionKind, 'CapabilityStatementFhirVersionKind'),
            _fhirVersion: RTTI_Element_1.RTTI_Element,
            format: t.array(RTTI_code_1.RTTI_code),
            _format: t.array(RTTI_Element_1.RTTI_Element),
            patchFormat: t.array(RTTI_code_1.RTTI_code),
            _patchFormat: t.array(RTTI_Element_1.RTTI_Element),
            implementationGuide: t.array(RTTI_canonical_1.RTTI_canonical),
            rest: t.array(RTTI_CapabilityStatement_Rest_1.RTTI_CapabilityStatement_Rest),
            messaging: t.array(RTTI_CapabilityStatement_Messaging_1.RTTI_CapabilityStatement_Messaging),
            document: t.array(RTTI_CapabilityStatement_Document_1.RTTI_CapabilityStatement_Document)
        })
    ]);
});
