/**
 * The different days of the week that can be assigned to an availability
 * slot
 *
 * @export
 * @enum {number}
 */
export enum BookingPageStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  PROTECTED = 'protected',
}

export function getLabelForBookingPageStatus(status: BookingPageStatus): string {
  switch (status) {
    case BookingPageStatus.ACTIVE: {
      return 'Active';
    }
    case BookingPageStatus.INACTIVE: {
      return 'Inactive';
    }
    case BookingPageStatus.PROTECTED: {
      return 'Password Protected';
    }
  }
}
