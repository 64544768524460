/**
 * The parameters when dealing with the clinic resource
 *
 * @export
 * @interface ClinicUrlParams
 */
export interface ClinicUrlParams {
  /**
   * The url parameter that has the currently active child slug/tab
   *
   * @type {ClinicChildSlug}
   * @memberof UrlParams
   */
  childSlug: ClinicChildSlug;
  /**
   * The id of the clinic currently being viewed
   *
   * @type {string}
   * @memberof UrlParams
   */
  clinicId: string;
  /**
   * The current action the user wants to do on the resource.
   * Possible values are:
   * undefined - View all the resources of this type
   *
   * @type {string}
   * @memberof ClinicUrlParams
   */
  action?: string;
}

/* The slugs for each of the clinic child resources */
export enum ClinicChildSlug {
  Schedule = 'schedule',
  Calendar = 'calendars',
  Cohort = 'cohorts',
  Immunizer = 'immunizers',
  Patient = 'patients',
  InvitationList = 'invitations',
}

/**
 * Parses the URL and returns the current parameter. Mainly meant to be
 * used outside of React components since we can't use hooks. The URL looks like
 * /clinic/:clinicId/:childSlug/:action
 *
 * @export
 * @returns {{
 *   clinicId: string;
 *   childSlug: ClinicChildSlug;
 * }}
 */
export function getClinicUrlComponents(): ClinicUrlParams {
  const urlComponents = window.location.pathname.split('/');

  return {
    clinicId: urlComponents[2],
    childSlug: urlComponents[3] as ClinicChildSlug,
    action: urlComponents[4],
  };
}

/**
 * Use to navigate to the edit page of one of the child entities
 *
 * @export
 * @param {ClinicChildSlug} childSlug The slug of the child
 * @param {string} itemId The id of the entity the user wants to edit
 * @returns {void}
 */
export function navigateToChildEntityEditPage(
  navigate,
  childSlug: ClinicChildSlug,
  itemId: string
): void {
  const urlComponents = getClinicUrlComponents();

  return navigate(`/clinics/${urlComponents.clinicId}/${childSlug}/${itemId}`);
}
