import {faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Row, Tag, Select} from 'antd';
import {Form} from 'formik-antd';
import {FieldArray} from 'formik';
import {useEffect, useState} from 'react';
import {Organization} from '../../models/organizations';
import {FhirUtils} from '../../services/fhir';
import {ValidationRules} from '../../validation/validation-rules/validation-rules';
import {ValidateFormItem} from '../validate-form-item';
import querystring from 'querystring';
import {RootState} from '../../models';
import {useSelector} from 'react-redux';
import React from 'react';

interface OrganizationMultiPickerFormItemProps {
  label?: string;
  name: string;
  setFieldValue: (name: string, value: any) => void;
  item?: Organization;
  validationRules?: ValidationRules[];
  required?: boolean;
}

export const OrganizationMultiPickerFormItem = (props: OrganizationMultiPickerFormItemProps) => {
  const globalOrg = useSelector((state: RootState) => state.ui.localOrg);
  const client = FhirUtils.useClient();
  const [pickableOrganizations, setPickableOrganizations] = useState<Organization[]>([]);
  const [selectionDisplay, setSelectionDisplay] = useState<string | undefined>(undefined);

  useEffect(() => {
    client.get(`Organization?${querystring.stringify({_organizationId: globalOrg?.id})}`)
          .then((res) => setPickableOrganizations(res.data.entry.map((e) => e.resource)));
  }, [globalOrg]);

  useEffect(() => setSelectionDisplay(undefined), [selectionDisplay]);

  return (
    <ValidateFormItem
      validationRules={props.validationRules || []}
      renderFormItem={(validate) => {
        return (
          <Form.Item
            name={props.name || 'organizations'}
            label={props.label || 'Organizations'}
            validate={validate}
            tooltip={props.required ? {icon: <span style={{color: 'red', fontSize: 14, fontWeight: 'bolder'}}>*</span>,} : {}}
          >
            <FieldArray
              name="organizations"
              render={(arrayHelpers) => (
                <>
                  <Select
                    showSearch
                    placeholder="Select one or more organizations"
                    optionFilterProp="children"
                    value={selectionDisplay}
                    onChange={(value: string) => {
                      arrayHelpers.push({id: value});
                      setSelectionDisplay(value);
                    }}
                    filterOption={(input, option: any) =>
                      option.children.toLowerCase().indexOf(input?.toLowerCase()) >= 0
                    }
                  >
                    {pickableOrganizations
                      .reduce((a: Organization[], o: Organization) => {
                        return !!arrayHelpers.form.values.organizations?.find(
                          (o2: Organization) => o.id === o2.id
                        )
                          ? a
                          : [...a, o];
                      }, [])
                      .map((o) => (
                        <Select.Option value={o.id!} key={o.id}>
                          {`${o.organizationCode ? `${o.organizationCode} - ` : ''}${o.name?.en}` ||
                            o.id}
                        </Select.Option>
                      ))}
                  </Select>
                  <Row>
                    {arrayHelpers.form.values.organizations?.map((o, i) => (
                      <Tag
                        key={o.id}
                        style={{marginTop: '8px'}}
                        onClick={() => arrayHelpers.remove(i)}
                      >
                        {
                          pickableOrganizations.find((opick: Organization) => o.id === opick.id)
                            ?.nameEn
                        }
                        <FontAwesomeIcon icon={faTimesCircle} style={{marginLeft: 10}} />
                      </Tag>
                    ))}
                  </Row>
                </>
              )}
            />
          </Form.Item>
        );
      }}
    />
  );
};
