import {useAuth0} from '@auth0/auth0-react';
import {Ids} from '@canimmunize/tools';
import {faMapMarkerAlt, faSortDown} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Alert, Button as ButtonANTD, Col, Input, Row, Spin} from 'antd';
import axios from 'axios';
import {Formik} from 'formik';
import jwtDecode from 'jwt-decode';
import {Form, InputNumber, Select} from 'formik-antd';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {Button, CardBody, CardSubtitle, CardTitle} from 'reactstrap';
import {useEnvInfo} from '../../../services/environment';
import {getBetaFlagFromDecodedToken} from '../../../services/fhir';
import {useStr} from '../../../services/str';
import {appointmentDateString} from '../../../util/helpers/appointment-date-string';
import s from '../styles.module.css';
import {AppointmentType} from '../types';
import {useUrlData} from '../util';
import {AddressSelect} from './address-select';
import {BFCard, BFCardProps} from './card';

interface AppointmentTypePickerProps {
  theme: any;
  onSelectAppointmentType: any;
  selectedAppointmentType: any;
  selectedAppointment: any;
  setSelectedAppointment: any;
  previewConfig: any;
  appointment: any;
  previousAppointmentId: string;
  previousDoseId?: string;
  defaultAge?: number;
  disableAddressLookup?: boolean;
  rescheduling?: boolean;
  filterProducts?: string[];
  forceUseCurrentAppointment?: boolean;
}

export const AppointmentTypePicker = (props: AppointmentTypePickerProps) => {
  const {auth0Audience} = useEnvInfo();
  const {
    theme,
    onSelectAppointmentType,
    selectedAppointmentType,
    selectedAppointment,
    setSelectedAppointment,
    previewConfig,
    appointment,
    previousAppointmentId,
    previousDoseId,
    defaultAge,
    disableAddressLookup,
    rescheduling,
    filterProducts,
    forceUseCurrentAppointment,
  } = props;
  const {getAccessTokenSilently, error} = useAuth0();
  const {config, preview} = useUrlData(previewConfig);
  const Str = useStr();
  const useAddressLookup =
    !preview &&
    (rescheduling && config.rescheduleBookingPage
      ? config.rescheduleBookingPage.showAddressLookup === 'true'
      : config.showAddressLookup === 'true') &&
    !disableAddressLookup;
  const unsetAppointmentType = () => {
    onSelectAppointmentType(undefined);
  };

  const [beta, setBeta] = React.useState<boolean>(false);

  React.useEffect(() => {
    (async () => {
      getAccessTokenSilently({
        audience: auth0Audience,
        scope: 'openid profile email',
      })
        .then((token) => {
          const decodedToken: string = jwtDecode(token);
          setBeta(!!getBetaFlagFromDecodedToken(auth0Audience, decodedToken));
        })
        .catch((err) => {
          // whatever, they're probably not logged in
        });
    })();
  }, [getAccessTokenSilently]);

  const [resultsLength, setResultsLength] = React.useState(10);

  const [formValues, setFormValues] = useState<{address?: string; age?: number; ageUnit?: string}>({
    address: '',
    age: undefined,
    ageUnit: 'year',
  });
  const {appointmentTypes, loading, queryErrors} = useAppointmentTypes({
    appointmentId: appointment?.id || previousAppointmentId,
    previousDoseId: previousDoseId,
    forceUseCurrentAppointment: forceUseCurrentAppointment,
    preview,
    rescheduling,
    previousAppointmentId,
  });

  React.useEffect(() => {
    if (
      appointmentTypes.length === 1 &&
      !appointmentTypes[0].fullyBooked &&
      !appointmentTypes[0].patientIneligible &&
      !useAddressLookup
    ) {
      onSelectAppointmentType(appointmentTypes[0]);
    }
  }, [appointmentTypes]);

  let filteredAppointmentTypes;
  if (filterProducts?.length) {
    const appTypes = filterByProducts(appointmentTypes, filterProducts);
    filteredAppointmentTypes = useAddressLookup
      ? filterAndSortAppointmentTypes(appTypes, formValues, useAddressLookup)
      : appTypes;
  } else {
    filteredAppointmentTypes = useAddressLookup
      ? filterAndSortAppointmentTypes(appointmentTypes, formValues, useAddressLookup)
      : appointmentTypes;
  }

  const visibleAppointmentTypes = filteredAppointmentTypes.slice(0, resultsLength);

  // Show a loading screen if the appointment types are not loaded yet
  if (loading) {
    return (
      <div style={{textAlign: 'center', padding: 15}}>
        <Spin />
        <div style={{fontSize: 16, color: 'grey', paddingTop: 5}}>Loading...</div>
      </div>
    );
  }

  return (
    <div style={{marginBottom: 60}}>
      {useAddressLookup && (
        <div style={{marginBottom: 60}}>
          <h3>{Str('1. Find a Clinic')}</h3>
          <Formik
            validateOnChange={true}
            validateOnBlur={true}
            initialValues={{
              address: '',
              age: defaultAge,
              ageUnit: 'year',
            }}
            validate={(values) => {
              const errors: any = {};

              if (!values.address) {
                errors.address = 'Address is required';
              }

              if (values.age !== 0 && !values.age) {
                errors.age = 'Age is required';
              } else if (values.age < 0) {
                errors.age = 'Age must 0 or higher';
              }

              return errors;
            }}
            onSubmit={(values) => {
              setFormValues(values);
              setSelectedAppointment(undefined);
              onSelectAppointmentType(undefined);
              setResultsLength(10);
            }}
          >
            {({values, errors, touched, handleSubmit, isSubmitting, setFieldTouched}) => (
              <form onSubmit={handleSubmit}>
                <p>
                  {Str(
                    "Start by entering an address (for example, 1723 Hollis St, Halifax) and then select the address from the options. Next, enter your age and click search to see clinics close to you that you're eligible to book an appointment for."
                  )}
                </p>
                <Row gutter={[8, 0]}>
                  <Col style={{marginBottom: 12}} lg={12} xs={24}>
                    <label style={{fontWeight: 'bold'}}>{Str('Enter Your Address')}</label>

                    <AddressSelect
                      name="address"
                      onBlur={() => setFieldTouched('address', true, true)}
                    />

                    {errors.address && (touched.address || isSubmitting) && (
                      <p style={{color: theme.danger, marginBottom: 0}}>{errors.address}</p>
                    )}
                  </Col>
                  <Col lg={9} xs={24}>
                    <label style={{fontWeight: 'bold'}}>{Str('Age')}</label>
                    <div style={{display: 'flex'}}>
                      <Input.Group compact>
                        <Form.Item name="age" noStyle>
                          <InputNumber
                            name="age"
                            onInput={() => setFieldTouched('age', true, false)}
                            placeholder={Str('Ex. 75')}
                            value={values.age}
                            disabled={defaultAge !== undefined}
                            size="large"
                            style={{width: '60%'}}
                            min="0"
                          />
                        </Form.Item>
                        <Form.Item name="ageUnit" noStyle>
                          <Select
                            size="large"
                            style={{width: '40%'}}
                            defaultValue="year"
                            name="ageUnit"
                          >
                            <Select.Option key="year" value="year">
                              Years
                            </Select.Option>
                            <Select.Option key="month" value="month">
                              Months
                            </Select.Option>
                          </Select>
                        </Form.Item>
                      </Input.Group>
                    </div>
                    {errors.age && (touched.age || isSubmitting) && (
                      <p style={{color: theme.danger, marginBottom: 0}}>{errors.age}</p>
                    )}
                  </Col>
                  <Col lg={3} xs={24}>
                    <label style={{fontWeight: 'bold', visibility: 'hidden'}}>
                      {'Ghost label'}
                    </label>
                    <ButtonANTD
                      style={{backgroundColor: config.primaryColor}}
                      size="large"
                      className="btn"
                      type="primary"
                      htmlType="submit"
                      block
                    >
                      {Str(Ids.search)}
                    </ButtonANTD>
                  </Col>
                </Row>
              </form>
            )}
          </Formik>
          {filteredAppointmentTypes.length <= 0 &&
            formValues.address &&
            (formValues.age || formValues.age === 0) && (
              <Alert
                message="No Results"
                style={{marginTop: 15}}
                description={
                  <div>
                    There are no scheduled clinics that you are eligible to attend. Please consult{' '}
                    {/* eslint-disable-next-line react/jsx-no-target-blank*/}
                    <a
                      target="_blank"
                      href="https://novascotia.ca/coronavirus/book-your-vaccination-appointment/"
                    >
                      this website
                    </a>{' '}
                    for updates on new clinics and eligibility rules.
                  </div>
                }
              />
            )}
          {queryErrors?.apiError && (
            <Alert message="Error" description={queryErrors?.apiError} type="error" />
          )}
        </div>
      )}

      {filteredAppointmentTypes.length > 0 && (
        <h3 style={{marginBottom: 20}}>
          {(useAddressLookup ? '2. ' : '1. ') + Str(Ids.select_appointment)}
        </h3>
      )}
      {visibleAppointmentTypes
        .filter((item) => (selectedAppointmentType ? selectedAppointmentType.id === item.id : true))
        .map((item) => {
          const selected = selectedAppointmentType?.id === item.id;
          const appointment = selectedAppointment
            ? {...selectedAppointment, appointmentType: selectedAppointmentType}
            : {appointmentType: item};

          const showFullyBooked = !preview && item.fullyBooked;
          const showPatientIneligible = !preview && item.patientIneligible;
          const disabled = showFullyBooked || showPatientIneligible;
          const showEarliestAvailability =
            preview || (!item.patientIneligible && !item.fullyBooked);
          const formatedEarliestAvailability = item.earliestAvailability
            ? // Server always sends date in English format, so have moment re-format it for French
              moment(item.earliestAvailability).format('dddd, LL')
            : Str(Ids.unknown);

          return (
            <AppointmentCard
              appointment={appointment}
              selectable={!showFullyBooked}
              selected={selected}
              key={item.id}
              onClick={() => {
                if (!disabled) onSelectAppointmentType(item);
              }}
              style={disabled ? {opacity: 0.7} : {}}
              actionBar={
                <Row align="middle">
                  {showEarliestAvailability && (
                    <Col
                      style={{
                        marginInlineEnd: '2em',
                      }}
                    >
                      <CardTitle
                        tag="h6"
                        style={{
                          textAlign: 'end',
                          fontWeight: 'bold',
                          color: selectedAppointmentType ? 'white' : 'black',
                        }}
                      >
                        {Str(Ids.earliest_availability)}
                      </CardTitle>
                      <CardSubtitle
                        style={{
                          textAlign: 'end',
                          color: selectedAppointmentType ? 'white' : 'black',
                        }}
                        tag="h6"
                      >
                        {formatedEarliestAvailability}
                      </CardSubtitle>
                    </Col>
                  )}
                  <Col>
                    <Button
                      className="btn"
                      style={{
                        backgroundColor: selectedAppointmentType ? 'white' : theme.blue,
                        borderRadius: 10,
                        borderColor: 'unset',
                      }}
                      disabled={disabled}
                      onClick={(e) => {
                        // stopPropagation within the if blocks so that click on the button if there is no
                        // selected appointment or appointment type will trigger the action of the underlying cad
                        // If there is a selected appointment, just unset the appointment
                        if (selectedAppointment) {
                          e.stopPropagation();
                          setSelectedAppointment(undefined);
                        }
                        // If there's only an appointmentType set then unset that
                        else if (selectedAppointmentType) {
                          e.stopPropagation();
                          unsetAppointmentType();
                        }
                      }}
                    >
                      {showPatientIneligible ? (
                        <div style={{color: 'white'}}>{Str(Ids.patient_ineligible)}</div>
                      ) : showFullyBooked ? (
                        <div style={{color: 'white'}}>{Str(Ids.fully_booked)}</div>
                      ) : (
                        <div style={{color: selectedAppointmentType ? theme.blue : 'white'}}>
                          {selectedAppointmentType ? Str(Ids.change) : Str(Ids.select)}
                        </div>
                      )}
                    </Button>
                  </Col>
                </Row>
              }
            />
          );
        })}
      {!selectedAppointmentType &&
        filteredAppointmentTypes.length > 0 &&
        resultsLength < filteredAppointmentTypes.length && (
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <ButtonANTD
              type="link"
              style={{
                fontSize: 18,
                fontWeight: 'bold',
                display: 'flex',
                color: config.primaryColor,
              }}
              onClick={() => setResultsLength(resultsLength + 10)}
            >
              <FontAwesomeIcon icon={faSortDown} style={{marginLeft: 10, marginRight: 10}} />
              Load More Clinics
              <FontAwesomeIcon icon={faSortDown} style={{marginLeft: 10, marginRight: 10}} />
            </ButtonANTD>
          </div>
        )}
    </div>
  );
};

interface useAppointmentTypesParams {
  location?: string;
  age?: Number;
  appointmentId?: string;
  forceUseCurrentAppointment?: boolean;
  preview?: boolean;
  rescheduling?: boolean;
  previousAppointmentId?: string;
  previousDoseId?: string;
}
const useAppointmentTypes = (params?: useAppointmentTypesParams) => {
  const {syncUrl, config, theme} = useUrlData();
  const [appointmentTypes, setAppointmentTypes] = useState<AppointmentType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [queryErrors, setQueryErrors] = useState<{
    recordsFound?: boolean;
    apiError?: string;
  }>();

  const handleLoad = async (count: number, params?: useAppointmentTypesParams) => {
    setLoading(true);

    let appointmentTypes: AppointmentType[] = [];
    try {
      const bookingPageId: string =
        params?.rescheduling && config.rescheduleBookingPageId
          ? config.rescheduleBookingPageId
          : config.id;
      const response = await axios.get<{results: AppointmentType[]; total: number}>(
        syncUrl + `/public/booking-page/${bookingPageId}/appointment-types`,
        {
          validateStatus: (status) => {
            return status >= 200 && status < 500;
          },
          params,
        }
      );

      if (response.status === 200) {
        if (response.data.total <= 0) {
          setQueryErrors({recordsFound: false});
        } else {
          setQueryErrors({});
        }
        appointmentTypes = response.data.results;
      } else {
        setQueryErrors({apiError: 'An error has occurred.'});
      }
    } catch (error) {
      // @ts-ignore
      setQueryErrors({apiError: error.message || 'An error has occurred.'});
    }

    // setCount(count + default_page_count);
    setAppointmentTypes(appointmentTypes);

    setLoading(false);
  };

  useEffect(() => {
    handleLoad(0, params);
  }, [config]);

  return {
    appointmentTypes,
    loading,
    queryErrors,
    // filtersComponent: useLocation ? filtersComponent : null,
    // loaderComponent: loading || count < total ? loaderComponent : null,
  };
};
interface AppointmentCardProps extends BFCardProps {
  selected: boolean;
  appointment: any;
  appointmentCode?: string;
  location?;
  actionBar?: any;
  onClick?: () => void;
  roundedBottom?: boolean;
  headline?: string;
  attendee?: string;
  dateTime?: string;
}

export const AppointmentCard = (props: AppointmentCardProps) => {
  const {config, lang} = useUrlData();
  const Str = useStr();
  const {
    selected,
    appointment,
    dateTime,
    location,
    actionBar,
    selectable,
    roundedBottom = false,
    style,
    headline,
  } = props;

  if (!appointment.appointmentType) return null;

  const cardStyle = {
    ...(roundedBottom ? {} : {borderBottomLeftRadius: 0, borderBottomRightRadius: 0}),
    ...style,
  };

  return (
    <BFCard {...props} selectable={selectable || selected} style={cardStyle}>
      <CardBody>
        <Row className={s.actionButtonsRow}>
          <Col>
            {headline && (
              <CardTitle
                tag="h5"
                className={s.appointmentTypeCardTitle}
                style={{color: selected ? 'white' : 'rgba(0, 0, 0, 0.85)'}}
              >
                {headline}
              </CardTitle>
            )}
            <CardTitle
              tag="h5"
              className={s.appointmentTypeCardTitle}
              style={{color: selected ? 'white' : 'rgba(0, 0, 0, 0.85)'}}
            >
              {appointment.appointmentType.name[lang]}
            </CardTitle>
            <CardSubtitle
              tag="h6"
              className={s.appointmentTypeCardSubtitle}
              style={{color: selected ? 'white' : 'rgba(0, 0, 0, 0.85)'}}
            >
              {appointment.datetime &&
                `${appointmentDateString(
                  moment.tz(appointment.datetime, config.timezone),
                  lang,
                  config.timezone
                )} - `}
              {appointment.appointmentType.durationDisplay[lang] ||
                Str(`${appointment.appointmentType.duration} minutes`)}
            </CardSubtitle>
          </Col>
          <Col
            className={s.actionButtonsContainerAppointmentType}
            style={{
              display: 'flex',
              alignItems: 'center',
              flexGrow: 1,
              flexShrink: 0,
            }}
          >
            {appointment.appointmentType.distance >= 0 &&
              appointment.appointmentType.distance < 1000000000 && (
                <div
                  style={{
                    paddingRight: '15px',
                  }}
                >
                  <FontAwesomeIcon icon={faMapMarkerAlt} style={{marginRight: 10}} />
                  {appointment.appointmentType.distance} km
                </div>
              )}
            {actionBar}
          </Col>
        </Row>
      </CardBody>
    </BFCard>
  );
};

const filterByProducts = (all: any[], vaccineConcepts: any[]) => {
  return all.filter((type) => vaccineConcepts.includes(type.vaccineConceptId));
};

const filterAndSortAppointmentTypes = (all, formValues, useAddressLookup) => {
  const {age, address, ageUnit} = formValues;
  if (useAddressLookup && age === undefined) return [];
  const x = address?.data?.esri_point?.x;
  const y = address?.data?.esri_point?.y;
  const b = all
    .filter((type) => {
      const minAgeUnitInMonth = type.minAgeUnit === 'month' ? type.minAge : type.minAge * 12;
      const maxAgeUnitInMonth = type.maxAgeUnit === 'month' ? type.maxAge : type.maxAge * 12 + 11;
      const monthAges =
        ageUnit === 'month' ? [age] : Array.from(Array(12).keys()).map((mon) => age * 12 + mon);

      return monthAges.some(
        (monthAge) =>
          (!type.minAge || (type.minAge && minAgeUnitInMonth <= monthAge)) &&
          (!type.maxAge || (type.maxAge && maxAgeUnitInMonth >= monthAge))
      );
    })
    .map((type) => {
      if (type.gisX && type.gisY) {
        type.distance = round(Math.sqrt((type.gisX - x) ** 2 + (type.gisY - y) ** 2) / 1000, 1);
      } else {
        type.distance = 1000000000;
      }

      return type;
    })
    .sort((a, b) => {
      return a.distance - b.distance;
    });
  return b;
};

/*
 * A function that rounds a number to a specified number of digits
 * @param {var} value - the value to be rounded.
 * @param {var} precision - the desired number of decimal places
 * @return {var} - the rounded number
 */
function round(value, precision = 0) {
  const exponent = Math.pow(10, precision);
  return Math.round(value * exponent) / exponent;
}
