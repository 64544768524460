import * as Icons from '@ant-design/icons';
import {useAuth0} from '@auth0/auth0-react';
import {faServer} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Dropdown, Menu, Popover, Select, Tag, Tooltip, TreeSelect} from 'antd';
import axios from 'axios';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import uuid from 'uuid';
import {RootState} from '../../models';
import {useCurrentUser} from '../../models/account';
import {useOrganizations} from '../../models/organizations';
import UI from '../../models/ui';
import {useEnvInfo} from '../../services/environment';
import {FhirUtils, useRole} from '../../services/fhir';
import {AbilityContext} from '../../services/roles/ability-context';
import {getExtensionValue} from '../../util/fhir';
import styles from './styles.module.css';
import {UserInfoUpdateModal} from './user-info-update/user-info-update-modal';
import {UserInfoUpdate} from './user-info-update/user-info-update-type';

export const NavBar = () => {
  const user = useCurrentUser();
  const client = FhirUtils.useClient();
  const dispatch = useDispatch();
  const orgs = useOrganizations();
  const {logout} = useAuth0();
  const TREE_SELECT = true;
  const {serverName, buildNum, syncUrl} = useEnvInfo();
  const role = useRole();
  const roles: string[] = Array.isArray(role) ? role : [role];
  const [userInfo, setUserInfo] = React.useState<{
    id: string | undefined;
    roleName: string[] | undefined;
    firstName: string | undefined;
    lastName: string | undefined;
    email: string | undefined;
  }>();
  const [modalType, setModalType] = React.useState<UserInfoUpdate>();
  const [updateModalVisible, setUpdateModalVisible] = React.useState(false);
  const [refreshKey, setRefreshKey] = React.useState(uuid.v4());
  const [serverVersion, setServerVersion] = React.useState('failed to fetch');

  // Retrieve userInfo containing logged user's id in DB for resetting password and role's name to display
  React.useEffect(() => {
    client.get(`user`).then((res) => {
      const user = res.data;
      setUserInfo({
        id: user.id,
        roleName: user.roles?.map((role) => role.name),
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
      });
    });
  }, [refreshKey]);

  React.useEffect(() => {
    const getServerVersion = async () => {
      const healthAPIUrl = syncUrl?.replace('/fhir/v1', '/api/health');
      const res = await axios.get(`${healthAPIUrl}/version/json`).catch((err) => err.response);
      if (res.status === 200) setServerVersion(res?.data?.version);
    };
    getServerVersion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logoutUser = () => {
    logout({returnTo: window.location.origin});
  };

  const selectedOrg = useSelector((state: RootState) => state.ui.localOrg);
  const scrollTop = useSelector((state: RootState) => state.ui.scrollTop);

  const orgColor = selectedOrg
    ? getExtensionValue(selectedOrg, 'https://canimmunize.ca/StructureDefinition/org-primary-color')
    : '#0581FF';

  const roleTagList = (roleNames: any[], max?: number) => {
    const numRoles = roleNames.length;
    const showMax = max || numRoles;

    let roleList = roleNames
      .sort((a, b) => a.localeCompare(b))
      .slice(0, showMax)
      .reduce((a, o) => [...a, <Tag key={o}>{o}</Tag>], []);
    if (showMax < numRoles)
      roleList.push(
        <Tooltip title={roleNames.slice(showMax - numRoles).reduce((a, b) => a + ', ' + b)}>
          <span key="more">+ {numRoles - showMax} more</span>
        </Tooltip>
      );
    return roleList;
  };

  const menu = (
    <Menu>
      {/* <Menu.Item key="settings" onClick={goToAccountSettings}>
        <Icons.UserOutlined />
        Account Settings
      </Menu.Item> */}
      <Menu.Item key="settings">
        <Icons.UserOutlined style={{marginRight: 5}} />
        {user?.email}
      </Menu.Item>
      <Menu.Item key="role">
        <Icons.SafetyCertificateOutlined style={{marginRight: 5}} />
        {`Role: `}
        {roleTagList(userInfo?.roleName || roles || [], 2)}
      </Menu.Item>
      <Menu.Item
        key="updateUsername"
        onClick={() => {
          setModalType(UserInfoUpdate.Username);
          setUpdateModalVisible(true);
        }}
      >
        <Icons.EditOutlined style={{marginRight: 5}} />
        Update User Info
      </Menu.Item>
      <Menu.Item
        key="changePassword"
        onClick={() => {
          setModalType(UserInfoUpdate.Password);
          setUpdateModalVisible(true);
        }}
      >
        <Icons.ToolOutlined style={{marginRight: 5}} />
        Change Password
      </Menu.Item>
      <Menu.Item key="logout" onClick={logoutUser}>
        <Icons.LogoutOutlined style={{marginRight: 5}} />
        Logout
      </Menu.Item>
    </Menu>
  );

  const onChangeOrg = (value) => {
    const org = orgs.find((org) => org.id === value);
    dispatch(UI.slice.actions.SET_LOCAL_ORG(org));
  };

  const superDashboardOption = {
    id: 'super',
    name: {en: 'All Organizations', fr: 'All Organizations'},
  };

  const constructSimpleTreeMenu = () => {
    const superDashboardOptionNode = {
      id: 'super',
      pId: undefined,
      title: 'All Organizations',
      value: 'super',
    };
    const orgList = orgs
      .map((org) => ({
        id: org.id,
        pId: org.parentOrganization?.id,
        title: org.nameEn || org.id,
        value: org.id,
      }))
      .sort((a, b) => (a.title.toUpperCase() > b.title.toUpperCase() ? 1 : -1));

    return [superDashboardOptionNode, ...orgList];
  };
  const ability = React.useContext(AbilityContext);

  return (
    <div className={styles.navWrapper}>
      <div
        style={{
          position: 'absolute',
          backgroundColor: scrollTop ? orgColor : 'transparent',
          height: '100%',
          width: '100%',
        }}
      />
      <div className={styles.navTitle}>
        {!ability.can('hide', 'organizationSelector') && (
          <div className={styles.customSelect}>
            {/* <Typography.Text disabled>{getNavText()}</Typography.Text> */}
            {TREE_SELECT ? (
              <TreeSelect
                className={styles.dashboardOrgSelector}
                value={selectedOrg?.id || 'super'}
                treeData={orgs.length > 0 ? constructSimpleTreeMenu() : undefined}
                onChange={onChangeOrg}
                dropdownMatchSelectWidth={false}
                treeDefaultExpandAll={false}
                treeDataSimpleMode
                suffixIcon={<Icons.CaretDownOutlined style={{color: 'white', fontSize: 10}} />}
              />
            ) : (
              <Select
                className={styles.dashboardOrgSelector}
                value={selectedOrg?.id || 'super'}
                defaultValue="super"
                onChange={onChangeOrg}
                suffixIcon={<Icons.CaretDownOutlined style={{color: 'white', fontSize: 10}} />}
              >
                {[superDashboardOption, ...orgs].map((org) => (
                  <Select.Option value={org.id!}>{org.name.en || org.id}</Select.Option>
                ))}
              </Select>
            )}
          </div>
        )}
      </div>
      {/* <div className={styles.centerMenu}>
        <FHIRSearch />
      </div> */}
      <div className={styles.navMenu}>
        {/* <Button style={{marginRight: 5}} onClick={onClickWelcomeMsgButton}>
          About this Demo 🎉
        </Button> */}

        {!ability.can('hide', 'server') && (
          <Popover
            content={`Server Version: ${serverVersion}${!!buildNum ? ` - ${buildNum}` : ''}`}
          >
            <Button style={{marginRight: 5}}>
              <FontAwesomeIcon icon={faServer} color={'white'} style={{marginRight: 10}} />
              {serverName}
            </Button>
          </Popover>
        )}

        <Dropdown overlay={menu}>
          <Button>
            {user?.email}
            <Icons.UserOutlined />
            <Icons.CaretDownOutlined style={{fontSize: 10}} />
          </Button>
        </Dropdown>
        {modalType && (
          <UserInfoUpdateModal
            visible={updateModalVisible}
            type={modalType}
            userInfo={userInfo}
            closeModal={() => {
              setModalType(undefined);
              setUpdateModalVisible(false);
            }}
            refresh={() => setRefreshKey(uuid.v4())}
          />
        )}
      </div>
    </div>
  );
};
