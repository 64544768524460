"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_TestReport = exports.TestReportResultKind = exports.TestReportStatusKind = void 0;
var t = __importStar(require("io-ts"));
var RTTI_id_1 = require("../Scalar/RTTI_id");
var RTTI_Meta_1 = require("./RTTI_Meta");
var RTTI_uri_1 = require("../Scalar/RTTI_uri");
var RTTI_Element_1 = require("./RTTI_Element");
var RTTI_code_1 = require("../Scalar/RTTI_code");
var RTTI_Narrative_1 = require("./RTTI_Narrative");
var RTTI_ResourceList_1 = require("../Union/RTTI_ResourceList");
var RTTI_Extension_1 = require("./RTTI_Extension");
var RTTI_Identifier_1 = require("./RTTI_Identifier");
var RTTI_Reference_1 = require("./RTTI_Reference");
var RTTI_decimal_1 = require("../Scalar/RTTI_decimal");
var RTTI_dateTime_1 = require("../Scalar/RTTI_dateTime");
var RTTI_TestReport_Participant_1 = require("./RTTI_TestReport_Participant");
var RTTI_TestReport_Setup_1 = require("./RTTI_TestReport_Setup");
var RTTI_TestReport_Test_1 = require("./RTTI_TestReport_Test");
var RTTI_TestReport_Teardown_1 = require("./RTTI_TestReport_Teardown");
var TestReportStatusKind;
(function (TestReportStatusKind) {
    TestReportStatusKind["_completed"] = "completed";
    TestReportStatusKind["_inProgress"] = "in-progress";
    TestReportStatusKind["_waiting"] = "waiting";
    TestReportStatusKind["_stopped"] = "stopped";
    TestReportStatusKind["_enteredInError"] = "entered-in-error";
})(TestReportStatusKind = exports.TestReportStatusKind || (exports.TestReportStatusKind = {}));
var TestReportResultKind;
(function (TestReportResultKind) {
    TestReportResultKind["_pass"] = "pass";
    TestReportResultKind["_fail"] = "fail";
    TestReportResultKind["_pending"] = "pending";
})(TestReportResultKind = exports.TestReportResultKind || (exports.TestReportResultKind = {}));
var EnumType_1 = require("../../EnumType");
exports.RTTI_TestReport = t.recursion('ITestReport', function () {
    return t.intersection([
        t.type({
            resourceType: t.literal('TestReport'),
            testScript: RTTI_Reference_1.RTTI_Reference
        }),
        t.partial({
            id: RTTI_id_1.RTTI_id,
            meta: RTTI_Meta_1.RTTI_Meta,
            implicitRules: RTTI_uri_1.RTTI_uri,
            _implicitRules: RTTI_Element_1.RTTI_Element,
            language: RTTI_code_1.RTTI_code,
            _language: RTTI_Element_1.RTTI_Element,
            text: RTTI_Narrative_1.RTTI_Narrative,
            contained: t.array(RTTI_ResourceList_1.RTTI_ResourceList),
            extension: t.array(RTTI_Extension_1.RTTI_Extension),
            modifierExtension: t.array(RTTI_Extension_1.RTTI_Extension),
            identifier: RTTI_Identifier_1.RTTI_Identifier,
            name: t.string,
            _name: RTTI_Element_1.RTTI_Element,
            status: EnumType_1.createEnumType(TestReportStatusKind, 'TestReportStatusKind'),
            _status: RTTI_Element_1.RTTI_Element,
            result: EnumType_1.createEnumType(TestReportResultKind, 'TestReportResultKind'),
            _result: RTTI_Element_1.RTTI_Element,
            score: RTTI_decimal_1.RTTI_decimal,
            _score: RTTI_Element_1.RTTI_Element,
            tester: t.string,
            _tester: RTTI_Element_1.RTTI_Element,
            issued: RTTI_dateTime_1.RTTI_dateTime,
            _issued: RTTI_Element_1.RTTI_Element,
            participant: t.array(RTTI_TestReport_Participant_1.RTTI_TestReport_Participant),
            setup: RTTI_TestReport_Setup_1.RTTI_TestReport_Setup,
            test: t.array(RTTI_TestReport_Test_1.RTTI_TestReport_Test),
            teardown: RTTI_TestReport_Teardown_1.RTTI_TestReport_Teardown
        })
    ]);
});
