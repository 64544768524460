import * as Icons from '@ant-design/icons';
import {faExternalLinkAlt} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Layout, Menu} from 'antd';
import ErrorBoundary from 'antd/lib/alert/ErrorBoundary';
import React from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import logo from '../../assets/logo-nobird.png';
import {RootState} from '../../models';
import {AbilityContext} from '../../services/roles/ability-context';
import {WelcomeModal} from '../demo/welcome-modal';
import {NavBar} from '../navbar';
import styles from './styles.module.css';

const {SubMenu} = Menu;

export const YukonCallCentreLayout: React.SFC = (props) => {
  const selectedKey = 'yk-call-centre';
  const selected = selectedKey ? [selectedKey] : ['/home'];
  const ability = React.useContext(AbilityContext);

  const scrollTop = useSelector((state: RootState) => state.ui.scrollTop);

  const onScroll = (e) => {
    const newScrollPosition = e.target.scrollTop;
    if (scrollTop > 0 && newScrollPosition > 0) return;
    if (scrollTop <= 0 && newScrollPosition <= 0) return;
  };

  // Wrapper for menu item that conditionally displays the menu item based on the user's role
  const MenuItemForRoles = (props) => {
    return (
      <Menu.Item
        {...props}
        style={{
          display: ability.can('access', 'menu', props.eventKey) ? 'block' : 'none',
        }}
      >
        {props.children}
      </Menu.Item>
    );
  };

  // hide the zendesk chat widget
  document.body.className = 'hide-support-widget';

  const canAccessConsoleUsers = ability.can('view', 'orgAdminUsers');
  const canAccessRoles = ability.can('access', 'menu', 'roles');

  return (
    <Layout
      style={{
        minHeight: '100vh',
        overflow: 'hidden',
        backgroundColor: 'white',
        backgroundAttachment: 'fixed',
        backgroundSize: 'cover',
      }}
    >
      <Layout.Sider collapsible>
        <div className={styles.appSider}>
          <Link to={ability.can('access', 'menu', 'home') ? '/home' : '#'}>
            <div className={styles.appLogo}>
              <img width="80%" src={logo} alt="canimm-logo" />
            </div>
          </Link>

          <Menu mode="inline" selectedKeys={selected} theme="dark" className={styles.appMenu}>
            <MenuItemForRoles key="home">
              <Link to="/home">
                <Icons.HomeOutlined />
                <span>Dashboard</span>
              </Link>
            </MenuItemForRoles>

            <MenuItemForRoles key="patients">
              <Link to="/patients">
                <Icons.HddOutlined />
                <span>Repository</span>
              </Link>
            </MenuItemForRoles>

            <MenuItemForRoles key="appointments">
              <Link to="/appointments">
                <Icons.CalendarOutlined />
                <span>Appointments</span>
              </Link>
            </MenuItemForRoles>

            <MenuItemForRoles key="campaigns">
              <Link to="/campaigns">
                <Icons.SendOutlined />
                <span>Campaigns</span>
              </Link>
            </MenuItemForRoles>

            <MenuItemForRoles key="cohorts">
              <Link to="/cohorts">
                <Icons.TeamOutlined />
                <span>Cohorts</span>
              </Link>
            </MenuItemForRoles>

            <MenuItemForRoles key="lists">
              <Link to="/lists">
                <Icons.MailOutlined />
                <span>Lists</span>
              </Link>
            </MenuItemForRoles>

            <MenuItemForRoles key="portals">
              <Link to="/portals">
                <Icons.DesktopOutlined />
                <span>Portals</span>
              </Link>
            </MenuItemForRoles>

            <MenuItemForRoles key="bookingpages">
              <Link to="/bookingpages">
                <Icons.CarryOutOutlined />
                <span>Booking Pages</span>
              </Link>
            </MenuItemForRoles>

            <MenuItemForRoles key="reports">
              <Link to="/reports">
                <Icons.FileDoneOutlined />
                <span>Reports</span>
              </Link>
            </MenuItemForRoles>
            <MenuItemForRoles key="logs">
              <Link to="/logs">
                <Icons.UnorderedListOutlined />
                <span>Logs</span>
              </Link>
            </MenuItemForRoles>
            <MenuItemForRoles key="mailqueues">
              <Link to="/mailqueues">
                <Icons.MailOutlined />
                <span>Mail Queue</span>
              </Link>
            </MenuItemForRoles>
            <MenuItemForRoles key="yk-call-centre">
              <Link to="/en/ykcallcentre">
                <Icons.PhoneOutlined />
                <span>Yukon Call Centre</span>
              </Link>
            </MenuItemForRoles>
            <MenuItemForRoles key="import">
              <Link to="/import">
                <Icons.CloudUploadOutlined />
                <span>Import</span>
              </Link>
            </MenuItemForRoles>
            <MenuItemForRoles key="organizations">
              <Link to="/organizations">
                <Icons.BankOutlined />
                <span>Organizations</span>
              </Link>
            </MenuItemForRoles>
            <MenuItemForRoles key="clinics">
              <Link to="/clinics">
                <Icons.MedicineBoxOutlined />
                <span>Clinics</span>
              </Link>
            </MenuItemForRoles>

            <MenuItemForRoles key="clinic-flow-forms">
              <Link to="/forms">
                <Icons.FileTextOutlined />
                <span>Forms</span>
              </Link>
            </MenuItemForRoles>

            {/* Users menu item if the user does not have access to the roles tab */}
            {!(canAccessRoles && canAccessConsoleUsers) && (
              <MenuItemForRoles key={'users'}>
                <Link to={canAccessConsoleUsers ? '/orgadminusers' : '/users'}>
                  <Icons.UserOutlined />
                  <span>Users</span>
                </Link>
              </MenuItemForRoles>
            )}

            {/* Users grouped menu if the user has access to the roles tab */}
            {canAccessRoles && canAccessConsoleUsers && (
              <SubMenu key="users-sub-menu" icon={<Icons.UserOutlined />} title="Users">
                <MenuItemForRoles key={'users'}>
                  <Link to={canAccessConsoleUsers ? '/orgadminusers' : '/users'}>
                    <span>Users</span>
                  </Link>
                </MenuItemForRoles>
                <MenuItemForRoles key={'roles'}>
                  <Link to="/roles">
                    <span>Roles</span>
                  </Link>
                </MenuItemForRoles>
              </SubMenu>
            )}

            {/* Only show the menu group if the user can see any of of the sub menu items */}
            {ability.can('access', 'menu', 'globals') ||
            ability.can('access', 'menu', 'terminology') ? (
              <SubMenu key="settings-sub-menu" icon={<Icons.SettingOutlined />} title="Settings">
                <MenuItemForRoles key="globals">
                  <Link to="/globals">
                    <span>Global Settings</span>
                  </Link>
                </MenuItemForRoles>
                <MenuItemForRoles key="terminology">
                  <Link to="/terminology">
                    {/* <Icons.FontSizeOutlined /> */}
                    <span>Terminology</span>
                  </Link>
                </MenuItemForRoles>
              </SubMenu>
            ) : null}

            {/* Only show the menu group if the user can see any of of the sub menu items */}
            {ability.can('access', 'menu', 'api') || ability.can('access', 'menu', 'docs') ? (
              <SubMenu key="developer-sub-menu" icon={<Icons.ApiFilled />} title="Developer">
                <MenuItemForRoles key="api">
                  <Link to="/tokens">
                    <span>API Tokens</span>
                  </Link>
                </MenuItemForRoles>
                <MenuItemForRoles key="docs">
                  <span>API Docs</span>
                  <FontAwesomeIcon
                    icon={faExternalLinkAlt}
                    color="white"
                    style={{marginLeft: 8, opacity: 0.6}}
                  />
                </MenuItemForRoles>
              </SubMenu>
            ) : null}

            <MenuItemForRoles key="patientlookup">
              <Link to="/patientlookup">
                <Icons.SearchOutlined />
                <span>Patient Lookup</span>
              </Link>
            </MenuItemForRoles>

            <MenuItemForRoles key="help">
              <Link to="/help">
                <Icons.QuestionCircleOutlined />
                <span>Help Centre</span>
              </Link>
            </MenuItemForRoles>
          </Menu>
        </div>
      </Layout.Sider>
      <Layout onScroll={onScroll} style={{backgroundColor: 'transparent'}}>
        <NavBar />
        <ErrorBoundary>
          <Layout.Content>{props.children}</Layout.Content>
        </ErrorBoundary>
        <WelcomeModal />
      </Layout>
    </Layout>
  );
};
