import React from 'react';
import {CVCInterface} from '@canimmunize/cvc-js';
import {validateValue} from '../../../validation/validate-value';
import {ValidationRules} from '../../../validation/validation-rules/validation-rules';
import {MaxLengthValidationRuleId} from '../../../validation/validation-rules/max-length-validation';
import {CSVParseError} from '../../imports/upload-modals/upload-modal';
import {GenderValidationRuleId} from '../../../validation/validation-rules/gender-enum-validation';
import {BirthDateValidationRuleId} from '../../../validation/validation-rules/birthdate-validation';
import {EmailValidationRuleId} from '../../../validation/validation-rules/email-validation';
import {HcnTypeValidationRuleId} from '../../../validation/validation-rules/hcn-type-validation';
import {HcnValidationRuleId} from '../../../validation/validation-rules/hcn-validation';
import {PostalCodeValidationRuleId} from '../../../validation/validation-rules/postal-code-validation';
import {doseFields} from '../../imports/upload-modals/patient-upload-modal';
import {RequiredValidationRuleId} from '../../../validation/validation-rules/required-validation';
import {RegexValidationId} from '../../../validation/validation-rules/regex-validation';
import {Tradename} from '../../../models/tradenames';
import {TimezoneValidationRuleId} from '../../../validation/validation-rules/timezone-validation';
import {ListItemValidationRuleId} from '../../../validation/validation-rules/list-validation';
import {doseUOM} from '../../patients/imm-modal/imms-form-fields';
import moment from 'moment';
import {CVCSite} from '../../../models/sites';
import {CVCRoute} from '../../../models/routes';

export interface OptionalParamsType {
  cvc?: CVCInterface;
  tradenames?: Tradename[];
  optionalFields?: string[];
  sites?: CVCSite[];
  routes?: CVCRoute[];
  settings?: {};
}

/**
 * Function to validate an csv data object against a validationRulesObject
 */
export function validateCSVData(
  validationRulesObject:
    | {
        [property: string]: ValidationRules[];
      }
    | ((
        row,
        settings?
      ) => {
        [property: string]: ValidationRules[];
      }),
  data: Object[],
  optionalParams = {} as OptionalParamsType
) {
  const {cvc, tradenames, sites, routes, optionalFields = [], settings} = optionalParams;

  const errors: CSVParseError[] = [];

  data.map((d: any, i) => {
    const fields = d.data;
    const doseColumns = doseFields.filter((d) => fields[d.dataIndex]);
    //Additional validation for cohort uploads
    if (cvc && tradenames && routes && sites) {
      //Validate if dose data is present and if all dose fields are provided
      if (doseColumns.length > 0 && doseColumns.length !== doseFields.length) {
        const missingFields = doseFields.filter((d) => !fields[d.dataIndex]).map((d) => d.title);
        errors.push({
          row: i,
          message: `Missing Fields: ${missingFields.join(', ')}`,
          code: '',
          type: '',
        });
      } else if (doseColumns.length > 0) {
        //TODO: refactor with /cvc/tradenames route (needs to be merged from other branch)
        const vaccineConcept = tradenames.find(
          (vaccine) => vaccine.publicPicklistTermEn === fields.vaccineName
        );
        if (!vaccineConcept) {
          errors.push({row: i, message: `Vaccine not found.`, code: '', type: ''});
        }
        const siteOfAdmin = sites?.find((site) => site.displayNameEn === fields.siteOfAdmin);
        if (!siteOfAdmin) {
          errors.push({
            row: i,
            message: `Site of Administration not recognized.`,
            code: '',
            type: '',
          });
        }
        const routeOfAdmin = routes?.find((route) => route.displayNameEn === fields.routeOfAdmin);
        if (!routeOfAdmin) {
          errors.push({
            row: i,
            message: `Route of Administration not recognized.`,
            code: '',
            type: '',
          });
        }
        const dosageUom = doseUOM.find((item) => item.key === fields.dosageUom);
        if (!dosageUom) {
          errors.push({
            row: i,
            message: `Dosage UOM not recognized.`,
            code: '',
            type: '',
          });
        }
        if (!Number.isInteger(Number(fields.doseNumber)) || Number(fields.doseNumber) <= 0) {
          errors.push({
            row: i,
            message: `Dose Number is invalid.`,
            code: '',
            type: '',
          });
        }
        if ((!Number(fields.dosage) && Number(fields.dosage) !== 0) || Number(fields.dosage) < 0) {
          errors.push({
            row: i,
            message: `Dosage is invalid.`,
            code: '',
            type: '',
          });
        }
        if (
          !moment(fields.timeAdministered, ['HH:mm:ss', 'H:mm:ss', 'H:mm', 'HH:mm'], true).isValid()
        ) {
          errors.push({
            row: i,
            message: `Time Administered is invalid (Enter 24-hour clock format).`,
            code: '',
            type: '',
          });
        }
        if (!moment(fields.dateAdministered).isValid()) {
          errors.push({
            row: i,
            message: `Date Administered is invalid.`,
            code: '',
            type: '',
          });
        }
      }
      //If upload row does not have a dose and if there are duplicate patients in the upload, return duplicate patient error
      else if (doseColumns.length === 0) {
        const patientMatch = data.filter(
          (row: any) => row.data.hcn && row.data.hcn === fields.hcn && !row.data.vaccineName
        );
        if (patientMatch.length > 1) {
          errors.push({
            row: i,
            message: `Duplicate patient(s) with same HCN in upload. Remove duplicates and reupload.`,
            code: '',
            type: '',
          });
        }
      }
    }

    const rules =
      typeof validationRulesObject === 'function'
        ? validationRulesObject(d.data, settings)
        : validationRulesObject;

    //Loop through validation rules object and validates columns found in data
    Object.keys(rules).forEach((key) => {
      if (key in fields) {
        const extraProps = {};

        if (key === 'hcn') extraProps['hcnType'] = fields['hcnType'];

        const message = objectValidate(rules[key], key, fields[key], extraProps);

        if (message !== '') errors.push({row: i, message, code: '', type: ''});
      }
    });
  });

  return errors;
}

/**
Validate value and indicate the name of the field 
 */
function objectValidate(
  validationRules: ValidationRules[],
  field: any,
  value: any,
  extraProps?: any
) {
  const validationFailure = validateValue(validationRules, value, extraProps);

  if (validationFailure === true) {
    return '';
  }

  if (validationFailure.errorMessage) {
    return validationFailure.errorMessage;
  }

  switch (validationFailure.validationRuleType) {
    case RequiredValidationRuleId: {
      return `'${field}' field is required.`;
    }
    case MaxLengthValidationRuleId: {
      return `'${field}' field cannot have more than ${validationFailure.maxLength} characters.`;
    }
    case GenderValidationRuleId: {
      return `'${field}' field invalid (Enter male/female/unknown OR values 0-2).`;
    }
    case BirthDateValidationRuleId: {
      return `'${field}' field not a valid birthdate.`;
    }
    case EmailValidationRuleId: {
      return `'${field}' field invalid. Enter a valid email.`;
    }
    case HcnTypeValidationRuleId: {
      return `'${field}' field invalid. Enter a valid province (ex. ON, NS, YT...).`;
    }
    case HcnValidationRuleId: {
      return `'${field}' field invalid. Enter a valid Health Card Number.`;
    }
    case PostalCodeValidationRuleId: {
      return `'${field}' field invalid. Enter a valid Canadian postal code.`;
    }
    case RegexValidationId: {
      return `'${field}' did not match the required format.`;
    }
    case TimezoneValidationRuleId: {
      return `'${field}' is not a valid timezone.`;
    }
    case ListItemValidationRuleId: {
      return `Invalid value (${value}) for field ${field}`;
    }
    default: {
      return '';
    }
  }
}

export const emptyStringsToUndefined = (row) => {
  const keys = Object.keys(row);
  keys.map((k) => {
    if (row[k] === '') row[k] = undefined;
  });
  return row;
};
