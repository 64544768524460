import React from 'react';
import {createSlice} from '@reduxjs/toolkit';
import {useDispatch, useSelector} from 'react-redux';
import {FhirUtils} from '../services/fhir';
import {RootState} from '../store';
import {ThunkDispatch} from '.';

export interface ConfigSliceInterface {
  defaultBookingPageId: string;
  vaccineReceiptDateFormat: string;
  immsTableDateFormat: string;
  app: string; //'flow' | 'app' | 'shield';
  stage: string; //;'local' | 'dev' | 'qa' | 'prod';
  environmentId: string;
}
const initialState = {
  defaultBookingPageId: '',
  vaccineReceiptDateFormat: '',
  immsTableDateFormat: '',
  app: '',
  stage: '',
  environmentId: '',
};

const slice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    SET_CONFIG: (state: ConfigSliceInterface, action) => {
      state.defaultBookingPageId = action.payload.defaultBookingPageId || '';
      state.app = action.payload.app;
      state.stage = action.payload.stage;
      state.environmentId = action.payload.environmentId;
      state.vaccineReceiptDateFormat = action.payload.vaccineReceiptDateFormat || '';
      state.immsTableDateFormat = action.payload.immsTableDateFormat || '';
    },
  },
});

export default {
  slice,
};

const getConfig = (client) => async (dispatch) => {
  return client.get('/public/config').then((res) => {
    return dispatch(slice.actions.SET_CONFIG(res.data));
  });
};

export const useConfig = (): ConfigSliceInterface => {
  const thunkDispatch = useDispatch<ThunkDispatch>();
  const client = FhirUtils.useClient();

  React.useEffect(() => {
    thunkDispatch(getConfig(client));
  }, []);

  const config = useSelector((state: RootState) => state.config);

  return config;
};
