import {
  Button,
  Card,
  Col,
  Descriptions,
  Menu,
  message,
  Modal,
  PageHeader,
  Row,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {FhirUtils} from '../../services/fhir';
import {useEnvInfo} from '../../services/environment';
import {processGender} from '../../util/helpers/processGender';
import {PVCWalletMenuItem} from '../../views/vaccine-receipt/pvc-wallet-menu-item';
import {downloadPVCPdf} from '../../views/vaccine-receipt/util/download-pvc-pdf';
import ReceiptModal from '../patients/receipt-modal';
import {columns} from '../patients/imms-widget';
import {ForecastTable} from '../patients/forecast-table';

const {Title} = Typography;

export const HcpLookupItemView = () => {
  const [loading, setLoading] = useState(false);
  const [patient, setPatient] = useState<any>();
  const params = useParams<{patientId: string}>();
  const navigate = useNavigate();
  const gender = processGender(patient?.gender);
  const [currentPatient, setCurrentPatient] = useState<any>();
  const [modalVisible, setModalVisible] = useState(false);
  const [pvcLoading, setPvcLoading] = React.useState(false);
  const [showSendEmailButton, setShowSendEmailButton] = React.useState(false);
  const [settings, setSettings] = React.useState({});
  const showPrintRecordButton = settings['hidePrintRecordButton']?.value !== 'true';
  const {environmentId} = useEnvInfo();

  const client = FhirUtils.useAxiosClient();

  // Get patient-details global settings
  async function getGlobalSettings() {
    const response = await client.get('/public/settings/patientlookup');
    setSettings(response.data);
  }

  useEffect(() => {
    setLoading(true);
    getGlobalSettings();
    client
      .get(`hcplookup/${params.patientId}`)
      .then((res) => {
        setPatient(res.data);
        const hasCovidDose = res.data.doses.some((dose) =>
          dose.concept?.protectsAgainst.find((disease) => disease.conceptId === '840539006')
        );
        // Show 'Send to Email' button only if patient has a covid dose in their immunizations list
        if (hasCovidDose && !showSendEmailButton) {
          setShowSendEmailButton(true);
        }
      })
      .catch((err) => {
        alert(err.message);
      })
      .finally(() => setLoading(false));
  }, []);

  if (!patient) return null;

  const handleOpenReceipt = (event, patient) => {
    event.stopPropagation();
    setCurrentPatient(patient);
    setModalVisible(true);
  };

  const onClose = () => {
    setModalVisible(false);
    setCurrentPatient(undefined);
  };

  return (
    <Card>
      <PageHeader
        className="site-page-header-responsive"
        onBack={() => {
          navigate(-1);
        }}
        title={
          <Title level={2} style={{marginBottom: 0}}>
            {patient?.fullName}
          </Title>
        }
        style={{padding: '0px 0px 16px'}}
      />

      <Row gutter={[15, 15]}>
        <Col lg={24}>
          <Row gutter={[15, 15]}>
            <Col lg={24} style={{marginBottom: 40}}>
              <Space align="start">
                <Title level={4}>Patient Info</Title>
                {patient.deceased && <Tag color="red">Deceased</Tag>}
              </Space>
              <Descriptions
                bordered
                size="small"
                column={{xxl: 3, xl: 3, lg: 2, md: 2, sm: 2, xs: 1}}
              >
                <Descriptions.Item label="First Name">{patient.firstName}</Descriptions.Item>
                <Descriptions.Item label="Last Name">{patient.lastName}</Descriptions.Item>
                <Descriptions.Item label="Preferred Name">
                  {patient.preferredName}
                </Descriptions.Item>
                <Descriptions.Item label="Date of Birth">
                  {patient.birthdate
                    ? `${moment(patient.birthdate).format('LL')} (${moment().diff(
                        patient.birthdate,
                        'years'
                      )} years old)`
                    : 'Unknown'}
                </Descriptions.Item>
                <Descriptions.Item label="Gender">{gender}</Descriptions.Item>
                <Descriptions.Item label="Health Card Number">
                  {`${patient.hcn} (${patient.hcnType})`}
                </Descriptions.Item>
                <Descriptions.Item label="Email">
                  <a href={`mailto:${patient.email}`}>{patient.email}</a>
                </Descriptions.Item>
                <Descriptions.Item label="Phone">{patient.phone}</Descriptions.Item>
                <Descriptions.Item label="Address">
                  {`${patient.street ? patient.street : ''}${
                    patient.unitNumber ? `, Unit/Apt ${patient.unitNumber},` : ''
                  } ${patient.city ? patient.city : ''} ${
                    patient.postalCode ? patient.postalCode : ''
                  }`}
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
          {settings['showForecastStatusTable']?.value === 'true' && (
            <Row gutter={[15, 15]}>
              <Col lg={24}>
                <ForecastTable
                  patient={patient}
                  showPrintRecordButton={showPrintRecordButton}
                  filter={{from: 'patientlookup'}}
                />
              </Col>
            </Row>
          )}
          <Row gutter={[15, 15]}>
            <Col lg={24} style={{marginBottom: 40}}>
              <Row
                style={{
                  width: '100%',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Col>
                  <Title level={4}>Vaccinations</Title>
                </Col>
                {patient?.doses && patient?.doses.length > 0 && (
                  <Col>
                    <Space
                      className="button-container"
                      style={{marginLeft: '50px', flexWrap: 'wrap'}}
                    >
                      <Menu mode="horizontal" style={{lineHeight: 0, marginTop: 0, minWidth: 165}}>
                        <Menu.SubMenu
                          style={{margin: 0, padding: '1px 0 0 0', top: 0}}
                          key="pov"
                          title={<Button type="primary">Proof of Vaccination</Button>}
                        >
                          <Menu.Item
                            key="pov"
                            onClick={() =>
                              downloadPVCPdf(
                                patient,
                                client,
                                setPvcLoading,
                                {type: 'pvc', from: 'patientlookup'},
                                environmentId
                              )
                            }
                          >
                            Download PVC
                          </Menu.Item>
                          {environmentId !== 'pei' && (
                            <Menu.Item key="wallet-pov">
                              <PVCWalletMenuItem
                                patientId={patient?.id}
                                patientlookup
                              ></PVCWalletMenuItem>
                            </Menu.Item>
                          )}
                        </Menu.SubMenu>
                      </Menu>
                    </Space>
                  </Col>
                )}
              </Row>
              <Table
                dataSource={patient?.doses}
                columns={columns}
                size="small"
                style={{width: '100%'}}
              />
              {currentPatient && (
                <ReceiptModal
                  patient={currentPatient}
                  visible={modalVisible}
                  onClose={onClose}
                  imm={currentPatient.doses}
                />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};
