import React from 'react';
import {StrLang, Ids} from '@canimmunize/tools';
import {useUrlData} from '../../../components/scheduler/util';
import {useEnvInfo} from '../../../services/environment';

export const ReceiptTitle = () => {
  const {lang} = useUrlData();
  const {environmentId} = useEnvInfo();

  let stringId: string;
  switch (environmentId) {
    case 'pei':
      stringId = 'pei_your_digital_immunization_record';
      break;
    case 'novascotia':
      stringId = 'ns_your_digital_immunization_record';
      break;
    case 'bruyere':
      stringId = 'bruyere_your_digital_immunization_record';
      break;
    default:
      stringId = 'your_digital_immunization_record';
  }

  return <h3>{StrLang(lang, Ids[stringId])}</h3>;
};
