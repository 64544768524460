import {ArrowLeftOutlined} from '@ant-design/icons';
import {Button, Tabs} from 'antd';
import React from 'react';
import {useParams} from 'react-router';
import {useNavigate} from 'react-router-dom';
import {useBetaFlag} from '../../services/fhir';
import {AbilityContext} from '../../services/roles/ability-context';
import {ClinicChildSlug} from '../../util/clinic-url-helpers';
import {CalendarUX} from './calendar/calendar-ux';
import {ClinicSchedule} from './clinic-schedule';
import {ClinicCohortsUX} from './cohorts/clinic-cohorts-ux';
import {ClinicImmunizersUX} from './immunizers/clinic-immunizers-ux';
import {ClinicPatientsTable} from './patients/patients-table';

const {TabPane} = Tabs;

interface IClinicUXItemPageProps {}

export const ClinicUXItemPage = (props: IClinicUXItemPageProps) => {
  const beta = useBetaFlag();
  const ability = React.useContext(AbilityContext);
  /* Use this to get the id current clinic */
  const {id: clinicId, action, tab: activeTab} = useParams();
  const navigate = useNavigate();

  return (
    <Tabs
      activeKey={activeTab}
      onChange={(newActiveTab) => {
        navigate(`/clinics/${clinicId}/${newActiveTab}`);
      }}
    >
      {ability.can('view', 'clinics', 'calendarsTab') && (
        <TabPane tab="Calendars" key={ClinicChildSlug.Calendar}>
          {action === undefined ? (
            <>{<CalendarUX forcePage="search" />}</>
          ) : (
            <>
              <Button
                size="small"
                icon={<ArrowLeftOutlined />}
                onClick={() => {
                  navigate(`/clinics/${clinicId}/calendars`, {replace: true});
                }}
              >
                Back to Calendars
              </Button>
              <CalendarUX forcePage="item" card={false} />
            </>
          )}
        </TabPane>
      )}

      {ability.can('view', 'clinics', 'cohortsTab') && (
        <TabPane tab="Cohorts" key={ClinicChildSlug.Cohort}>
          <ClinicCohortsUX clinicId={clinicId} />
        </TabPane>
      )}

      {ability.can('view', 'clinics', 'immunizersTab') && (
        <TabPane tab="Clinic Mode Users" key={ClinicChildSlug.Immunizer}>
          <ClinicImmunizersUX clinicId={clinicId} />
        </TabPane>
      )}

      {ability.can('view', 'clinics', 'patientsTab') && (
        <TabPane tab="Patients" key={ClinicChildSlug.Patient}>
          <ClinicPatientsTable clinicId={clinicId} />
        </TabPane>
      )}

      {/* May bring this back in the future */}
      {/* <TabPane tab="Invitation Lists" key={ClinicChildSlug.InvitationList}>
        <clinicInvitationListsUX.ItemPage clinicId={clinicId} card={false} />
      </TabPane> */}

      {ability.can('view', 'clinics', 'scheduleTab') && beta && (
        <TabPane tab="Schedule (beta)" key={ClinicChildSlug.Schedule}>
          <ClinicSchedule clinicId={clinicId} />
        </TabPane>
      )}
    </Tabs>
  );
};
