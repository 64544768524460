import {Menu} from 'antd';
import React from 'react';
import {AbilityContext} from '../../../services/roles/ability-context';
import {getDefaultMenuItems} from './default';
import {getYukon2MenuItems} from './yukon-2';

export const getMenuItems = (ability, selectedKey, environmentId) => {
  switch (environmentId) {
    case 'yukon-2':
    case 'yukon-2-uat':
      return getYukon2MenuItems(ability, selectedKey);
    default:
      return getDefaultMenuItems(ability, selectedKey);
  }
};

// Wrapper for menu item that conditionally displays the menu item based on the user's role
export const MenuItemForRoles = (props) => {
  const ability = React.useContext(AbilityContext);
  return (
    <Menu.Item
      {...props}
      style={{
        display: ability.can('access', 'menu', props.eventKey) ? 'block' : 'none',
      }}
    >
      {props.children}
    </Menu.Item>
  );
};
