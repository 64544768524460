import moment from 'moment-timezone';
import {
  LongTextValidationRules,
  RequiredExtraLongTextValidationRules,
  RequiredLongTextValidationRules,
  RequiredShortTextValidationRules,
} from '../../services/ui-validation-rules';
import {EnumValidationRuleId} from '../../validation/validation-rules/enum-validation';
import {RequiredValidationRuleId} from '../../validation/validation-rules/required-validation';
import {RegexValidationId} from '../../validation/validation-rules/regex-validation';
import {getEnumValues} from '../../util/enum-util';
import {ClinicFormCustomFieldName} from '../clinic/clinic-form-ux-model';
import {FormUXFieldType} from '../form-ux/form-ux-models/form-ux-fields/form-ux-field-type';
import {FormUXModel} from '../form-ux/form-ux-models/form-ux-model';
import {BookingPageStatus, getLabelForBookingPageStatus} from './booking-page-status';
import ClinicFlowFormModel from '../../models/form';
import {BookingPageCategory} from '../../models/booking-pages';

export enum BookingPageCustomFieldName {
  AppointmentTypes = 'appointmentTypes',
  rescheduleBookingPage = 'rescheduleBookingPageId',
  category = 'category',
}

export const generateBookingPageFormUXModel = (settings?): FormUXModel => {
  const enableGroupAppointment = settings['enableGroupAppointment']?.value === 'true';
  const enableAddressLookUp = settings['enableAddressLookUp']?.value === 'true';

  return [
    {
      name: 'name',
      type: FormUXFieldType.text,
      label: 'Booking Page Name',
      editable: true,
      inCreateModal: true,
      validationRules: RequiredShortTextValidationRules,
    },
    {
      name: 'id',
      type: FormUXFieldType.text,
      label: 'Booking Page ID',
      editable: false,
      validationRules: RequiredShortTextValidationRules,
    },
    {
      name: 'demographicsFormId',
      type: FormUXFieldType.SingleItemPicker,
      label: 'Demographics Form ID',
      editable: true,
      fromIdValue: true,
      model: ClinicFlowFormModel,
      placeholder: 'Select Form',
      validationRules: [],
    },
    ...(enableGroupAppointment
      ? ([
          {
            name: BookingPageCustomFieldName.category,
            type: FormUXFieldType.custom,
            label: 'Category',
            editable: true,
            validationRules: [
              {
                validationRuleType: EnumValidationRuleId,
                enumValues: getEnumValues(BookingPageCategory),
              },
            ],
            nullable: true,
          },
        ] as FormUXModel)
      : []),
    {
      name: BookingPageCustomFieldName.rescheduleBookingPage,
      type: FormUXFieldType.custom,
      label: 'Reschedule Booking Page',
      editable: true,
      validationRules: [],
      nullable: true,
    },
    {
      name: 'status',
      type: FormUXFieldType.select,
      label: 'Status',
      editable: true,
      validationRules: [
        {
          validationRuleType: EnumValidationRuleId,
          enumValues: getEnumValues(BookingPageStatus),
        },
      ],
      selectableValues: getEnumValues(BookingPageStatus).map((status) => {
        return {
          key: status,
          label: getLabelForBookingPageStatus(status as BookingPageStatus),
        };
      }),
    },
    {
      name: 'password',
      type: FormUXFieldType.text,
      label: 'Password',
      editable: true,
      validationRules: [],
    },
    {
      name: 'clientRegistryOverrideEnabled',
      type: FormUXFieldType.select,
      label: settings.environmentId.includes('yukon-2')
        ? 'HCN Validation Override'
        : 'Client Registry Override Enabled',
      editable: true,
      extra:
        'Determines whether the user of the page will be presented with the option to override the client registry verification step. Useful for password protected booking pages that are given to booking agents or users with similar roles.',
      selectableValues: [
        {key: 'true', label: 'Enabled'},
        {key: 'false', label: 'Disabled'},
      ],
      validationRules: [
        {
          validationRuleType: RequiredValidationRuleId,
        },
        {
          validationRuleType: EnumValidationRuleId,
          enumValues: ['true', 'false'],
        },
      ],
    },
    ...(enableAddressLookUp
      ? ([
          {
            name: 'showAddressLookup',
            type: FormUXFieldType.select,
            label: 'Use Address Lookup',
            editable: true,
            validationRules: [
              {
                validationRuleType: RequiredValidationRuleId,
              },
              {
                validationRuleType: EnumValidationRuleId,
                enumValues: ['true', 'false'],
              },
            ],
            selectableValues: [
              {key: 'true', label: 'True'},
              {key: 'false', label: 'False'},
            ],
          },
        ] as FormUXModel)
      : []),
    {
      name: 'showOnDashboard',
      type: FormUXFieldType.select,
      label: 'Show On Dashboard',
      editable: true,
      validationRules: [
        {
          validationRuleType: EnumValidationRuleId,
          enumValues: ['true', 'false'],
        },
      ],
      selectableValues: [
        {key: 'true', label: 'True'},
        {key: 'false', label: 'False'},
      ],
    },
    {
      groupName: 'Booking Page Slug',
      type: FormUXFieldType.grouped,
      inCreateModal: true,
      fields: [
        {
          name: 'bookingPageSlugEn',
          type: FormUXFieldType.text,
          label: 'Booking Page Slug (English)',
          editable: true,
          validationRules: [
            ...RequiredShortTextValidationRules,
            {
              validationRuleType: RegexValidationId,
              regex: /^[a-z0-9-]+$/,
              errorMessage:
                'Invalid character(s). Allowed characters are: lowercase letters, numbers, -',
            },
          ],
        },
        {
          name: 'bookingPageSlugFr',
          type: FormUXFieldType.text,
          label: 'Booking Page Slug (French)',
          editable: true,
          validationRules: [
            ...RequiredShortTextValidationRules,
            {
              validationRuleType: RegexValidationId,
              regex: /^[a-z0-9-]+$/,
              errorMessage:
                'Invalid character(s). Allowed characters are: lowercase letters, numbers, -',
            },
          ],
        },
      ],
    },
    {
      name: 'timezone',
      type: FormUXFieldType.select,
      label: 'Timezone',
      editable: true,
      validationRules: [
        {
          validationRuleType: RequiredValidationRuleId,
        },
        {
          validationRuleType: EnumValidationRuleId,
          enumValues: moment.tz.names(),
        },
      ],
      selectableValues: moment.tz.names(),
      searchable: true,
      inCreateModal: true,
    },
    {
      name: ClinicFormCustomFieldName.OrganizationId,
      type: FormUXFieldType.custom,
      label: 'Organization',
      editable: true,
      validationRules: [
        {
          validationRuleType: 'required',
        },
      ],
      inCreateModal: true,
    },
    {
      name: 'primaryColor',
      type: FormUXFieldType.text,
      label: 'Primary Color (Hex)',
      editable: true,
      validationRules: RequiredShortTextValidationRules,
    },
    {
      groupName: 'Logo',
      type: FormUXFieldType.grouped,
      fields: [
        {
          name: 'logoEn',
          type: FormUXFieldType.text,
          label: 'Logo URL (English)',
          editable: true,
          validationRules: RequiredLongTextValidationRules,
        },
        {
          name: 'logoFr',
          type: FormUXFieldType.text,
          label: 'Logo URL(French)',
          editable: true,
          validationRules: RequiredLongTextValidationRules,
        },
      ],
    },
    {
      groupName: 'Logo Alt Text',
      type: FormUXFieldType.grouped,
      fields: [
        {
          name: 'logoAltEn',
          type: FormUXFieldType.text,
          label: 'Logo Alt Text (English)',
          editable: true,
          validationRules: [],
        },
        {
          name: 'logoAltFr',
          type: FormUXFieldType.text,
          label: 'Logo Alt Text (French)',
          editable: true,
          validationRules: [],
        },
      ],
    },
    {
      groupName: 'Email Header Image',
      type: FormUXFieldType.grouped,
      fields: [
        {
          name: 'emailHeaderImageSrcEn',
          type: FormUXFieldType.textarea,
          label: 'Email Header Image URL (English)',
          editable: true,
          validationRules: LongTextValidationRules,
        },
        {
          name: 'emailHeaderImageSrcFr',
          type: FormUXFieldType.textarea,
          label: 'Email Header Image URL (French)',
          editable: true,
          validationRules: LongTextValidationRules,
        },
      ],
    },
    {
      groupName: 'Email Header Image Alt Text',
      type: FormUXFieldType.grouped,
      fields: [
        {
          name: 'emailHeaderImageAltEn',
          type: FormUXFieldType.textarea,
          label: 'Email Header Image Alt Text (English)',
          editable: true,
          validationRules: LongTextValidationRules,
        },
        {
          name: 'emailHeaderImageAltFr',
          type: FormUXFieldType.textarea,
          label: 'Email Header Image Alt Text (French)',
          editable: true,
          validationRules: LongTextValidationRules,
        },
      ],
    },
    {
      groupName: 'Email From Name',
      type: FormUXFieldType.grouped,
      fields: [
        {
          name: 'emailFromNameEn',
          type: FormUXFieldType.textarea,
          label: 'Email From Name (English)',
          editable: true,
          validationRules: RequiredLongTextValidationRules,
        },
        {
          name: 'emailFromNameFr',
          type: FormUXFieldType.textarea,
          label: 'Email From Name (French)',
          editable: true,
          validationRules: RequiredLongTextValidationRules,
        },
      ],
    },
    {
      groupName: 'Booking Page Title',
      type: FormUXFieldType.grouped,
      fields: [
        {
          name: 'bookingFormPageTitleEn',
          type: FormUXFieldType.textarea,
          label: 'Booking Page Title (English)',
          editable: true,
          validationRules: RequiredLongTextValidationRules,
        },
        {
          name: 'bookingFormPageTitleFr',
          type: FormUXFieldType.textarea,
          label: 'Booking Page Title (French)',
          editable: true,
          validationRules: RequiredLongTextValidationRules,
        },
      ],
    },
    {
      groupName: 'Booking Page Description',
      type: FormUXFieldType.grouped,
      fields: [
        {
          name: 'bookingFormPageDescriptionEn',
          type: FormUXFieldType.textarea,
          label: 'Booking Page Description (English)',
          editable: true,
          validationRules: RequiredLongTextValidationRules,
        },
        {
          name: 'bookingFormPageDescriptionFr',
          type: FormUXFieldType.textarea,
          label: 'Booking Page Description (French)',
          editable: true,
          validationRules: RequiredLongTextValidationRules,
        },
      ],
    },
    {
      name: 'favicon',
      type: FormUXFieldType.text,
      label: 'Favicon URI',
      editable: true,
      validationRules: RequiredShortTextValidationRules,
    },
    {
      groupName: 'Booking Form Title',
      type: FormUXFieldType.grouped,
      fields: [
        {
          name: 'bookingFormTitleEn',
          type: FormUXFieldType.textarea,
          label: 'Booking Form Title (English)',
          editable: true,
          validationRules: RequiredLongTextValidationRules,
        },
        {
          name: 'bookingFormTitleFr',
          type: FormUXFieldType.textarea,
          label: 'Booking Form Title (French)',
          editable: true,
          validationRules: RequiredLongTextValidationRules,
        },
      ],
    },
    {
      groupName: 'Booking Form Content',
      type: FormUXFieldType.grouped,
      fields: [
        {
          name: 'bookingFormContentEn',
          type: FormUXFieldType.textarea,
          label: 'Booking Form Content (English)',
          editable: true,
          validationRules: RequiredExtraLongTextValidationRules,
        },
        {
          name: 'bookingFormContentFr',
          type: FormUXFieldType.textarea,
          label: 'Booking Form Content (French)',
          editable: true,
          validationRules: RequiredExtraLongTextValidationRules,
        },
      ],
    },
    {
      groupName: 'Contact Info Form Text',
      type: FormUXFieldType.grouped,
      fields: [
        {
          name: 'contactInfoFormTextEn',
          type: FormUXFieldType.textarea,
          label: 'Contact Info Form Text (English)',
          editable: true,
          validationRules: LongTextValidationRules,
        },
        {
          name: 'contactInfoFormTextFr',
          type: FormUXFieldType.textarea,
          label: 'Contact Info Form Text (French)',
          editable: true,
          validationRules: LongTextValidationRules,
        },
      ],
    },
    {
      groupName: 'Thank You For Booking Text',
      type: FormUXFieldType.grouped,
      fields: [
        {
          name: 'thankYouForBookingEn',
          type: FormUXFieldType.textarea,
          label: 'Thank You For Booking (English)',
          editable: true,
          validationRules: RequiredLongTextValidationRules,
        },
        {
          name: 'thankYouForBookingFr',
          type: FormUXFieldType.textarea,
          label: 'Thank You For Booking (French)',
          editable: true,
          validationRules: RequiredLongTextValidationRules,
        },
      ],
    },
    {
      groupName: 'Thank You For Booking Subtext',
      type: FormUXFieldType.grouped,
      fields: [
        {
          name: 'thankYouForBookingSubtextEn',
          type: FormUXFieldType.textarea,
          label: 'Thank You For Booking Subtext (English)',
          editable: true,
          validationRules: RequiredLongTextValidationRules,
        },
        {
          name: 'thankYouForBookingSubtextFr',
          type: FormUXFieldType.textarea,
          label: 'Thank You For Booking Subtext (French)',
          editable: true,
          validationRules: RequiredLongTextValidationRules,
        },
      ],
    },
    {
      groupName: 'Thank You For Filling Out Consent Text',
      type: FormUXFieldType.grouped,
      fields: [
        {
          name: 'thankYouForFillingOutConsentEn',
          type: FormUXFieldType.textarea,
          label: 'Thank You For Filling Out Consent (English)',
          editable: true,
          validationRules: RequiredLongTextValidationRules,
        },
        {
          name: 'thankYouForFillingOutConsentFr',
          type: FormUXFieldType.textarea,
          label: 'Thank You For Filling Out Consent (French)',
          editable: true,
          validationRules: RequiredLongTextValidationRules,
        },
      ],
    },
    {
      groupName: 'Covid Screen Positive Message',
      type: FormUXFieldType.grouped,
      fields: [
        {
          name: 'covidScreenPositiveMessageEn',
          type: FormUXFieldType.textarea,
          label: 'COVID-19 Screen Positive Message (English)',
          editable: true,
          validationRules: RequiredLongTextValidationRules,
        },
        {
          name: 'covidScreenPositiveMessageFr',
          type: FormUXFieldType.textarea,
          label: 'COVID-19 Screen Positive Message (French)',
          editable: true,
          validationRules: RequiredLongTextValidationRules,
        },
      ],
    },
    {
      groupName: 'Custom Appointment Steps',
      type: FormUXFieldType.grouped,
      fields: [
        {
          name: 'customInstructionsEn',
          type: FormUXFieldType.textarea,
          label: 'Custom Appointment Steps (English)',
          editable: true,
          validationRules: [],
        },
        {
          name: 'customInstructionsFr',
          type: FormUXFieldType.textarea,
          label: 'Custom Appointment Steps (French)',
          editable: true,
          validationRules: [],
        },
      ],
    },
    {
      groupName: 'Step 1 Message',
      type: FormUXFieldType.grouped,
      fields: [
        {
          name: 'step1MessageEn',
          type: FormUXFieldType.textarea,
          label: 'Appointment Summary Step 1 Message (English)',
          editable: true,
          validationRules: RequiredLongTextValidationRules,
        },
        {
          name: 'step1MessageFr',
          type: FormUXFieldType.textarea,
          label: 'Appointment Summary Step 1 Message (French)',
          editable: true,
          validationRules: RequiredLongTextValidationRules,
        },
      ],
    },
    {
      groupName: 'Step 2 Message',
      type: FormUXFieldType.grouped,
      fields: [
        {
          name: 'step3MessageEn',
          type: FormUXFieldType.textarea,
          label: 'Appointment Summary Step 2 Message (English)',
          editable: true,
          validationRules: RequiredLongTextValidationRules,
        },
        {
          name: 'step3MessageFr',
          type: FormUXFieldType.textarea,
          label: 'Appointment Summary Step 2 Message (French)',
          editable: true,
          validationRules: RequiredLongTextValidationRules,
        },
      ],
    },
    {
      groupName: 'Confirmation Email Subject',
      type: FormUXFieldType.grouped,
      fields: [
        {
          name: 'confirmationEmailSubjectEn',
          type: FormUXFieldType.text,
          label: 'Confirmation Email Subject (English)',
          editable: true,
          validationRules: RequiredLongTextValidationRules,
        },
        {
          name: 'confirmationEmailSubjectFr',
          type: FormUXFieldType.text,
          label: 'Confirmation Email Subject (French)',
          editable: true,
          validationRules: RequiredLongTextValidationRules,
        },
      ],
    },
    {
      groupName: 'Confirmation Email Preheader',
      type: FormUXFieldType.grouped,
      fields: [
        {
          name: 'confirmationEmailPreheaderEn',
          type: FormUXFieldType.text,
          label: 'Confirmation Email Preheader (English)',
          editable: true,
          validationRules: RequiredShortTextValidationRules,
        },
        {
          name: 'confirmationEmailPreheaderFr',
          type: FormUXFieldType.text,
          label: 'Confirmation Email Preheader (French)',
          editable: true,
          validationRules: RequiredShortTextValidationRules,
        },
      ],
    },
    {
      groupName: 'Confirmation Email Header',
      type: FormUXFieldType.grouped,
      fields: [
        {
          name: 'confirmationEmailHeaderEn',
          type: FormUXFieldType.text,
          label: 'Confirmation Email Header (English)',
          editable: true,
          validationRules: RequiredShortTextValidationRules,
        },
        {
          name: 'confirmationEmailHeaderFr',
          type: FormUXFieldType.text,
          label: 'Confirmation Email Header (French)',
          editable: true,
          validationRules: RequiredShortTextValidationRules,
        },
      ],
    },
    {
      groupName: 'Confirmation Email Paragraph 1',
      type: FormUXFieldType.grouped,
      fields: [
        {
          name: 'confirmationEmailP1En',
          type: FormUXFieldType.textarea,
          label: 'Confirmation Email Paragraph 1 (English)',
          editable: true,
          validationRules: RequiredLongTextValidationRules,
        },
        {
          name: 'confirmationEmailP1Fr',
          type: FormUXFieldType.textarea,
          label: 'Confirmation Email Paragraph 1 (French)',
          editable: true,
          validationRules: RequiredLongTextValidationRules,
        },
      ],
    },
    {
      groupName: 'Confirmation Email Button Title',
      type: FormUXFieldType.grouped,
      fields: [
        {
          name: 'confirmationEmailButtonTitleEn',
          type: FormUXFieldType.text,
          label: 'Confirmation Email Button Title (English)',
          editable: true,
          validationRules: RequiredShortTextValidationRules,
        },
        {
          name: 'confirmationEmailButtonTitleFr',
          type: FormUXFieldType.text,
          label: 'Confirmation Email Button Title (French)',
          editable: true,
          validationRules: RequiredShortTextValidationRules,
        },
      ],
    },
    {
      groupName: 'Confirmation Email Paragraph 2',
      type: FormUXFieldType.grouped,
      fields: [
        {
          name: 'confirmationEmailP2En',
          type: FormUXFieldType.textarea,
          label: 'Confirmation Email Paragraph 2 (English)',
          editable: true,
          validationRules: RequiredLongTextValidationRules,
        },
        {
          name: 'confirmationEmailP2Fr',
          type: FormUXFieldType.textarea,
          label: 'Confirmation Email Paragraph 2 (French)',
          editable: true,
          validationRules: RequiredLongTextValidationRules,
        },
      ],
    },
    {
      groupName: 'Cancellation Email Subject',
      type: FormUXFieldType.grouped,
      fields: [
        {
          name: 'cancellationEmailSubjectEn',
          type: FormUXFieldType.text,
          label: 'Cancellation Email Subject (English)',
          editable: true,
          validationRules: RequiredShortTextValidationRules,
        },
        {
          name: 'cancellationEmailSubjectFr',
          type: FormUXFieldType.text,
          label: 'Cancellation Email Subject (French)',
          editable: true,
          validationRules: RequiredShortTextValidationRules,
        },
      ],
    },
    {
      groupName: 'Cancellation Email Preheader',
      type: FormUXFieldType.grouped,
      fields: [
        {
          name: 'cancellationEmailPreheaderEn',
          type: FormUXFieldType.text,
          label: 'Cancellation Email Preheader (English)',
          editable: true,
          validationRules: RequiredShortTextValidationRules,
        },
        {
          name: 'cancellationEmailPreheaderFr',
          type: FormUXFieldType.text,
          label: 'Cancellation Email Preheader (French)',
          editable: true,
          validationRules: RequiredShortTextValidationRules,
        },
      ],
    },
    {
      groupName: 'Cancellation Email Header',
      type: FormUXFieldType.grouped,
      fields: [
        {
          name: 'cancellationEmailHeaderEn',
          type: FormUXFieldType.text,
          label: 'Cancellation Email Header (English)',
          editable: true,
          validationRules: RequiredShortTextValidationRules,
        },
        {
          name: 'cancellationEmailHeaderFr',
          type: FormUXFieldType.text,
          label: 'Cancellation Email Header (French)',
          editable: true,
          validationRules: RequiredShortTextValidationRules,
        },
      ],
    },
    {
      groupName: 'Cancellation Email Paragraph 1',
      type: FormUXFieldType.grouped,
      fields: [
        {
          name: 'cancellationEmailP1En',
          type: FormUXFieldType.textarea,
          label: 'Cancellation Email Paragraph 1 (English)',
          editable: true,
          validationRules: RequiredLongTextValidationRules,
        },
        {
          name: 'cancellationEmailP1Fr',
          type: FormUXFieldType.textarea,
          label: 'Cancellation Email Paragraph 1 (French)',
          editable: true,
          validationRules: RequiredLongTextValidationRules,
        },
      ],
    },
    {
      groupName: 'Cancellation Email Paragraph 2',
      type: FormUXFieldType.grouped,
      fields: [
        {
          name: 'cancellationEmailP2En',
          type: FormUXFieldType.textarea,
          label: 'Cancellation Email Paragraph 2 (English)',
          editable: true,
          validationRules: RequiredLongTextValidationRules,
        },
        {
          name: 'cancellationEmailP2Fr',
          type: FormUXFieldType.textarea,
          label: 'Cancellation Email Paragraph 2 (French)',
          editable: true,
          validationRules: RequiredLongTextValidationRules,
        },
      ],
    },
    {
      groupName: 'Reschedule Email Subject',
      type: FormUXFieldType.grouped,
      fields: [
        {
          name: 'rescheduleEmailSubjectEn',
          type: FormUXFieldType.text,
          label: 'Reschedule Email Subject (English)',
          editable: true,
          validationRules: RequiredShortTextValidationRules,
        },
        {
          name: 'rescheduleEmailSubjectFr',
          type: FormUXFieldType.text,
          label: 'Reschedule Email Subject (French)',
          editable: true,
          validationRules: RequiredShortTextValidationRules,
        },
      ],
    },
    {
      groupName: 'Reschedule Email Preheader',
      type: FormUXFieldType.grouped,
      fields: [
        {
          name: 'rescheduleEmailPreheaderEn',
          type: FormUXFieldType.text,
          label: 'Reschedule Email Preheader (English)',
          editable: true,
          validationRules: RequiredShortTextValidationRules,
        },
        {
          name: 'rescheduleEmailPreheaderFr',
          type: FormUXFieldType.text,
          label: 'Reschedule Email Preheader (French)',
          editable: true,
          validationRules: RequiredShortTextValidationRules,
        },
      ],
    },
    {
      groupName: 'Reschedule Email Header',
      type: FormUXFieldType.grouped,
      fields: [
        {
          name: 'rescheduleEmailHeaderEn',
          type: FormUXFieldType.text,
          label: 'Reschedule Email Header (English)',
          editable: true,
          validationRules: RequiredShortTextValidationRules,
        },
        {
          name: 'rescheduleEmailHeaderFr',
          type: FormUXFieldType.text,
          label: 'Reschedule Email Header (French)',
          editable: true,
          validationRules: RequiredShortTextValidationRules,
        },
      ],
    },
    {
      groupName: 'Reschedule Email Paragraph 1',
      type: FormUXFieldType.grouped,
      fields: [
        {
          name: 'rescheduleEmailP1En',
          type: FormUXFieldType.textarea,
          label: 'Reschedule Email Paragraph 1 (English)',
          editable: true,
          validationRules: RequiredLongTextValidationRules,
        },
        {
          name: 'rescheduleEmailP1Fr',
          type: FormUXFieldType.textarea,
          label: 'Reschedule Email Paragraph 1 (French)',
          editable: true,
          validationRules: RequiredLongTextValidationRules,
        },
      ],
    },
    {
      groupName: 'Reminder Email Subject',
      type: FormUXFieldType.grouped,
      fields: [
        {
          name: 'reminderEmailSubjectEn',
          type: FormUXFieldType.text,
          label: 'Reminder Email Subject (English)',
          editable: true,
          validationRules: RequiredShortTextValidationRules,
        },
        {
          name: 'reminderEmailSubjectFr',
          type: FormUXFieldType.text,
          label: 'Reminder Email Subject (French)',
          editable: true,
          validationRules: RequiredShortTextValidationRules,
        },
      ],
    },
    {
      groupName: 'Reminder Email Preheader',
      type: FormUXFieldType.grouped,
      fields: [
        {
          name: 'reminderEmailPreheaderEn',
          type: FormUXFieldType.text,
          label: 'Reminder Email Preheader (English)',
          editable: true,
          validationRules: RequiredShortTextValidationRules,
        },
        {
          name: 'reminderEmailPreheaderFr',
          type: FormUXFieldType.text,
          label: 'Reminder Email Preheader (French)',
          editable: true,
          validationRules: RequiredShortTextValidationRules,
        },
      ],
    },
    {
      groupName: 'Reminder Email Paragraph 1',
      type: FormUXFieldType.grouped,
      fields: [
        {
          name: 'reminderEmailP1En',
          type: FormUXFieldType.textarea,
          label: 'Reminder Email Paragraph 1 (English)',
          editable: true,
          validationRules: RequiredLongTextValidationRules,
        },
        {
          name: 'reminderEmailP1Fr',
          type: FormUXFieldType.textarea,
          label: 'Reminder Email Paragraph 1 (French)',
          editable: true,
          validationRules: RequiredLongTextValidationRules,
        },
      ],
    },
    {
      groupName: 'Reminder Email Paragraph 2',
      type: FormUXFieldType.grouped,
      fields: [
        {
          name: 'reminderEmailP2En',
          type: FormUXFieldType.textarea,
          label: 'Reminder Email Paragraph 2 (English)',
          editable: true,
          validationRules: RequiredLongTextValidationRules,
        },
        {
          name: 'reminderEmailP2Fr',
          type: FormUXFieldType.textarea,
          label: 'Reminder Email Paragraph 2 (French)',
          editable: true,
          validationRules: RequiredLongTextValidationRules,
        },
      ],
    },
    {
      groupName: 'Reminder Email Paragraph 3',
      type: FormUXFieldType.grouped,
      fields: [
        {
          name: 'reminderEmailP3En',
          type: FormUXFieldType.textarea,
          label: 'Reminder Email Paragraph 3 (English)',
          editable: true,
          validationRules: RequiredLongTextValidationRules,
        },
        {
          name: 'reminderEmailP3Fr',
          type: FormUXFieldType.textarea,
          label: 'Reminder Email Paragraph 3 (French)',
          editable: true,
          validationRules: RequiredLongTextValidationRules,
        },
      ],
    },
    {
      groupName: 'Vaccination Receipt Email Paragraph 1',
      type: FormUXFieldType.grouped,
      fields: [
        {
          name: 'vaccinationReceiptEmailP1En',
          type: FormUXFieldType.textarea,
          label: 'Vaccination Receipt Email Paragraph 1 (English)',
          editable: true,
          validationRules: RequiredLongTextValidationRules,
        },
        {
          name: 'vaccinationReceiptEmailP1Fr',
          type: FormUXFieldType.textarea,
          label: 'Vaccination Receipt Email Paragraph 1 (French)',
          editable: true,
          validationRules: RequiredLongTextValidationRules,
        },
      ],
    },
    {
      groupName: 'Vaccination Receipt Email Button Label',
      type: FormUXFieldType.grouped,
      fields: [
        {
          name: 'vaccinationReceiptEmailButtonLabelEn',
          type: FormUXFieldType.textarea,
          label: 'Vaccination Receipt Email Button Label (English)',
          editable: true,
          validationRules: RequiredLongTextValidationRules,
        },
        {
          name: 'vaccinationReceiptEmailButtonLabelFr',
          type: FormUXFieldType.textarea,
          label: 'Vaccination Receipt Email Button Label (French)',
          editable: true,
          validationRules: RequiredLongTextValidationRules,
        },
      ],
    },
    {
      groupName: 'Vaccination Receipt Email Paragraph 2',
      type: FormUXFieldType.grouped,
      fields: [
        {
          name: 'vaccinationReceiptEmailP2En',
          type: FormUXFieldType.textarea,
          label: 'Vaccination Receipt Email Paragraph 2 (English)',
          editable: true,
          validationRules: RequiredLongTextValidationRules,
        },
        {
          name: 'vaccinationReceiptEmailP2Fr',
          type: FormUXFieldType.textarea,
          label: 'Vaccination Receipt Email Paragraph 2 (French)',
          editable: true,
          validationRules: RequiredLongTextValidationRules,
        },
      ],
    },
    {
      groupName: 'Health Card Number Already Exists Error Message',
      type: FormUXFieldType.grouped,
      fields: [
        {
          name: 'hcnAlreadyExistsErrorMessageEn',
          type: FormUXFieldType.textarea,
          label: 'Health Card Number Already Exists Error Message (English)',
          editable: true,
          validationRules: RequiredLongTextValidationRules,
        },
        {
          name: 'hcnAlreadyExistsErrorMessageFr',
          type: FormUXFieldType.textarea,
          label: 'Health Card Number Already Exists Error Message (French)',
          editable: true,
          validationRules: RequiredLongTextValidationRules,
        },
      ],
    },
    {
      name: 'matomoTrackerUrl',
      type: FormUXFieldType.text,
      label: 'Matomo Tracker URL',
      editable: true,
      validationRules: [],
    },
    {
      name: 'matomoSiteId',
      type: FormUXFieldType.text,
      label: 'Matomo Site ID',
      editable: true,
      validationRules: [],
    },
  ];
};
