import {CVCGTIN, CVCLot} from '@canimmunize/cvc-js';
import {Col, Row, Tabs, Typography} from 'antd';
import React from 'react';
import {DetailedSection} from './terminology-misc-components';
import {FieldTitleVertical, FieldTitleHorizontal, GeneralTitle} from './terminology-titles';

const {Title, Paragraph, Text} = Typography;
const {TabPane} = Tabs;

export const LotLookupOutput = (props: {concept: CVCLot}) => {
  const linkStyle = {color: '#8c8c8c'};
  return (
    <div>
      <GeneralTitle title="VACCINE LOT NUMBER" />
      <DetailedSection title="" titlePresence={false}>
        <Row gutter={[16, 16]}>
          <Col span={12} style={{maxWidth: '35%'}}>
            <FieldTitleVertical title="Lot Number" />
            {props.concept.lotNumber}
          </Col>
          <Col span={12} style={{maxWidth: '65%'}}>
            <FieldTitleVertical title="Expiry Date" />
            {props.concept.expiryDate}
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12} style={{maxWidth: '35%'}}>
            <FieldTitleVertical title="DIN" />
            {props.concept.din}
          </Col>
          <Col span={12} style={{maxWidth: '65%'}}>
            <FieldTitleVertical title="Linked Tradename Concept" />
            <a
              style={linkStyle}
              href={`/terminology/tradenames/${props.concept.concept.conceptId}`}
            >
              {props.concept.concept.publicPicklistTerm.en}
            </a>
          </Col>
        </Row>
      </DetailedSection>
    </div>
  );
};

export const GtinLookupOutput = (props: {concept: CVCGTIN}) => {
  const linkStyle = {color: '#8c8c8c'};
  return (
    <div>
      <GeneralTitle title="GLOBAL TRADE ITEM NUMBER" />
      <DetailedSection title="" titlePresence={false}>
        <Row gutter={[16, 16]}>
          <Col span={12} style={{maxWidth: '33%'}}>
            <FieldTitleVertical title="Global Trade Item Number" />
            {props.concept.gtin}
          </Col>
          <Col span={12} style={{maxWidth: '33%'}}>
            <FieldTitleVertical title="Hierarchy" />
            {props.concept.productType}
          </Col>
          <Col span={12} style={{maxWidth: '33%'}}>
            <FieldTitleVertical title="Data Provider Name" />
            {props.concept.dataProviderName}
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12} style={{maxWidth: '33%'}}>
            <FieldTitleVertical title="Data Provider GLN" />
            {props.concept.dataProviderGln}
          </Col>
          <Col span={12} style={{maxWidth: '33%'}}>
            <FieldTitleVertical title="Packaging Form Quality" />
            {props.concept.packagingFormQual}
          </Col>
          <Col span={12} style={{maxWidth: '33%'}}>
            <FieldTitleVertical title="Size" />
            {props.concept.size}
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12} style={{maxWidth: '33%'}}>
            <FieldTitleVertical title="Size Unit of Measure" />
            {props.concept.sizeUom}
          </Col>
          <Col span={12} style={{maxWidth: '33%'}}>
            <FieldTitleVertical title="Net Content" />
            {props.concept.netContent}
          </Col>
          <Col span={12} style={{maxWidth: '33%'}}>
            <FieldTitleVertical title="Net Content Unit of Measure" />
            {props.concept.netContentUom}
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12} style={{maxWidth: '33%'}}>
            <FieldTitleVertical title="Total Units" />
            {props.concept.totalUnits}
          </Col>
          <Col span={12} style={{maxWidth: '33%'}}>
            <FieldTitleVertical title="Linked Tradename Concepts" />
            <a
              style={linkStyle}
              href={`/terminology/tradenames/${props.concept.concept.conceptId}`}
            >
              <div>{props.concept.concept.publicPicklistTerm.en}</div>
            </a>
          </Col>
        </Row>
      </DetailedSection>
    </div>
  );
};
