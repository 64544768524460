import {Ids, FormatDateAvailability, FormatTimezoneAbbr} from '@canimmunize/tools';
import {faSortDown} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Spin} from 'antd';
import axios from 'axios';
import _, {PropertyPath} from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import {useStr} from '../../../services/str';
import {Col, Row} from 'reactstrap';
import {
  Appointment,
  AppointmentType,
  Availability,
  AvailabilityList,
  DateAvailabilityList,
} from '../types';
import {useUrlData} from '../util';
import {AppointmentTypePicker} from './appointment-type-picker';
import {BFCard} from './card';
import {FhirProvider, FhirUtils} from '../../../services/fhir';
interface AppointmentPickerProps {
  selectedAppointmentType?: AppointmentType;
  setSelectedAppointmentType?: (a: AppointmentType) => void;
  selectedAppointment?: Appointment;
  setSelectedAppointment: (a: Appointment) => void;
  rescheduling?: boolean;
  reschedulingTitle?: boolean;
  onCancelReschedule?: () => void;
  appointment?: Appointment;
  previewConfig?: {
    includeStaging?: boolean;
    adminUser?: boolean;
  };
  defaultAge?: number;
  disableAddressLookup?: boolean;
  filterProducts?: string[];
  forceUseCurrentAppointment?: boolean;
  previousAppointmentId?: string;
  previousDoseId?: string;
}

export const AppointmentPicker = (props: AppointmentPickerProps) => {
  const {
    selectedAppointmentType,
    setSelectedAppointmentType,
    selectedAppointment,
    setSelectedAppointment,
    rescheduling,
    reschedulingTitle,
    onCancelReschedule,
    appointment,
    previewConfig,
    defaultAge,
    disableAddressLookup,
    filterProducts,
    forceUseCurrentAppointment,
    previousAppointmentId,
    previousDoseId,
  } = props;
  const axiosClient = FhirUtils.useAxiosClient();

  const {syncUrl, config, theme, lang, adminUser, preview, includeStaging, query} =
    useUrlData(previewConfig);
  const [loadingAvailabilities, setLoadingAvailabilities] = React.useState(false);
  const [availabilities, setAvailabilities] = React.useState<AvailabilityList>([]);

  const [showError, setShowError] = React.useState(false);
  const [errMsg, setErrMsg] = React.useState('');

  React.useEffect(() => {
    (async () => {
      if (preview) {
        setAvailabilities([]);
      }
      if (selectedAppointment) return;
      await fetchAppointmentAvailabilities();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAppointmentType, selectedAppointment, includeStaging]);

  const loadMoreAvailabilities = () => {
    const selectedAppointmentTypeAvailabilities = _.get(
      availabilities,
      selectedAppointmentType?.id as PropertyPath,
      []
    );
    const latestDatePulled =
      selectedAppointmentTypeAvailabilities[selectedAppointmentTypeAvailabilities.length - 1].date;
    const startDate = moment(latestDatePulled).add(1, 'day');

    fetchAppointmentAvailabilities(startDate);
  };

  const fetchAppointmentAvailabilities = (startDate?: moment.Moment) => {
    if (selectedAppointmentType === undefined) return;

    if (errMsg.length) setErrMsg('');
    setLoadingAvailabilities(true);

    const client = adminUser ? axiosClient : axios;

    // For admin user call /availability/${config.id}&preview=true&includeStaging=true
    // For public user call /public/availability/${config.id}
    return client
      .get(syncUrl + `${adminUser ? '' : '/public'}/availability/${config.id}`, {
        validateStatus: (status) => {
          return status >= 200 && status < 500;
        },
        params: {
          appointmentId: appointment?.id ? appointment.id : query.previousAppointmentId,
          appointmentTypeId: selectedAppointmentType?.id,
          startDate: startDate?.format('YYYY-MM-DD'),
          timezone: moment.tz.guess(),
          preview,
          includeStaging,
          previousAppointmentId: previousAppointmentId || query.previousAppointmentId,
          previousDoseId: previousDoseId || query.previousDoseId,
        },
      })
      .then((result) => {
        if (result.status > 199 && result.status < 400) {
          // Extract the availabilityLists from the current availabilities state obj for the
          // selectedAppointmentType
          let existingAvailabilitiesForAppointmentType: DateAvailabilityList[] = _.get(
            availabilities,
            selectedAppointmentType?.id,
            []
          );

          // The list of dates we just retrieved availability for
          const retrievedDates = result.data.map((d) => d.date);

          // Remove any entries for dates that just came down
          existingAvailabilitiesForAppointmentType =
            existingAvailabilitiesForAppointmentType.filter((a) => {
              return retrievedDates.indexOf(a.date) < 0;
            });

          const newAvailabilities = {
            ...availabilities,
            [selectedAppointmentType.id]: _.sortBy(
              [
                ...existingAvailabilitiesForAppointmentType,
                ...result.data.map((d) => ({
                  ...d,
                  appointmentTypeId: selectedAppointmentType.id,
                })),
              ],
              'date'
            ),
          };

          setAvailabilities(newAvailabilities as AvailabilityList);
        } else {
          if (result.status > 399 && result.status < 500) {
            const msg = result?.data?.error?.message;
            setErrMsg(msg?.length ? msg : 'An error occurred');
          } else {
            setErrMsg('An error occurred');
          }
        }
        setLoadingAvailabilities(false);
      });
  };

  const appointmentTypeAvailabilities = _.get(
    availabilities,
    selectedAppointmentType?.id as PropertyPath,
    []
  );
  const Str = useStr();
  return (
    <div style={{marginBottom: theme.sectionSpacing}}>
      {reschedulingTitle && (
        <Row style={{marginBottom: theme.sectionSpacing / 2}}>
          <Col xs={9}>
            <h2>{Str(Ids.reschedule_appointment)}</h2>
          </Col>
          <Col xs={3} style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
            <Button
              style={{
                color: 'white',
                backgroundColor: theme.danger,
                borderRadius: theme.borderRadius,
              }}
              size="large"
              onClick={onCancelReschedule}
            >
              {Str(Ids.cancel)}
            </Button>
          </Col>
        </Row>
      )}

      <div
        id="appointment-picker-container"
        style={{
          borderRadius: 10,
        }}
      >
        <AppointmentTypePicker
          theme={theme}
          selectedAppointmentType={selectedAppointmentType}
          onSelectAppointmentType={setSelectedAppointmentType}
          selectedAppointment={selectedAppointment}
          setSelectedAppointment={setSelectedAppointment}
          previewConfig={previewConfig}
          appointment={appointment}
          previousAppointmentId={previousAppointmentId || query?.previousAppointmentId}
          previousDoseId={previousDoseId || query?.previousDoseId}
          defaultAge={defaultAge}
          disableAddressLookup={disableAddressLookup}
          rescheduling={rescheduling}
          filterProducts={filterProducts}
          forceUseCurrentAppointment={forceUseCurrentAppointment}
        />
        {loadingAvailabilities && !(appointmentTypeAvailabilities.length > 0) && (
          <div style={{textAlign: 'center', padding: 15}}>
            <Spin />
            <div style={{fontSize: 16, color: 'grey', paddingTop: 5}}>
              {Str(Ids.this_may_take_a_few_moments)}
            </div>
          </div>
        )}

        {!selectedAppointment && selectedAppointmentType && (
          <div
            style={{
              padding: 15 + theme.borderRadius,
              backgroundColor:
                selectedAppointmentType && appointmentTypeAvailabilities.length > 0
                  ? `${theme.blue}0f`
                  : 'transparent',
              marginTop: -theme.sectionSpacing - theme.borderRadius,
              borderRadius: theme.borderRadius,
            }}
          >
            {appointmentTypeAvailabilities.length > 0 && (
              <div
                style={{
                  textAlign: 'right',
                  fontSize: 16,
                  fontStyle: 'italic',
                  color: 'grey',
                  paddingBottom: 10,
                }}
              >{`${Str(Ids.appointment_times_shown_in)} ${FormatTimezoneAbbr(
                moment.tz.zone(config.timezone)?.abbr(moment.now()),
                lang,
                config.timezone
              )}.`}</div>
            )}
            <div>
              {appointmentTypeAvailabilities.map((dateAvailability, i) => {
                const isToday = moment(dateAvailability.date).isSame(moment());
                const isTomorrow = moment(dateAvailability.date).isSame(moment().add(1, 'day'));
                return (
                  <div style={{marginBottom: 25}}>
                    <div>
                      {isToday && <div>{Str(Ids.today)}</div>}
                      {isTomorrow && <div>{Str(Ids.tomorrow)}</div>}
                      <div style={{marginBottom: 10}}>
                        {moment(dateAvailability.date).format(FormatDateAvailability(lang))}
                      </div>
                      {preview && (
                        <div>{`${dateAvailability.count}/${dateAvailability.slotsAvailable}`}</div>
                      )}
                    </div>
                    {dateAvailability.availabilities.map((availability) => {
                      return (
                        <AvailabilityCell
                          theme={theme}
                          availability={availability}
                          preview={preview}
                          onSelectAvailability={(selectedAvailability) => {
                            document
                              .getElementById('appointment-picker-container')
                              ?.scrollIntoView();
                            setSelectedAppointment({
                              datetime: selectedAvailability.time,
                              ...selectedAppointmentType,
                            });
                          }}
                        />
                      );
                    })}
                  </div>
                );
              })}
            </div>
            {errMsg.length ? (
              <p style={{textAlign: 'center', padding: 15, color: 'red'}}>{errMsg}</p>
            ) : appointmentTypeAvailabilities.length ? (
              <div>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                  <Button
                    type="link"
                    style={{
                      fontSize: 18,
                      fontWeight: 'bold',
                      display: 'flex',
                      color: config.primaryColor,
                    }}
                    onClick={loadMoreAvailabilities}
                  >
                    <FontAwesomeIcon icon={faSortDown} style={{marginLeft: 10, marginRight: 10}} />
                    {Str(Ids.view_more_availabilities)}
                    <FontAwesomeIcon icon={faSortDown} style={{marginLeft: 10, marginRight: 10}} />
                  </Button>
                </div>

                {loadingAvailabilities && (
                  <div style={{textAlign: 'center', padding: 15}}>
                    <Spin />
                  </div>
                )}
              </div>
            ) : (
              <>
                {!loadingAvailabilities && (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginBottom: 15,
                      color: theme.blue,
                    }}
                  >
                    {Str(Ids.no_appointments_currently_available)}
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

interface AvailabilityCellProps {
  availability: Availability;
  theme: any;
  onSelectAvailability: (availability: Availability) => void;
  preview?: boolean;
}

const AvailabilityCell = ({
  theme,
  availability,
  onSelectAvailability,
  preview,
}: AvailabilityCellProps) => {
  const {config, lang} = useUrlData();
  let timeSlot = moment.tz(availability.time, config.timezone).format('h:mma');
  if (lang === 'fr') {
    timeSlot = `${moment.tz(availability.time, config.timezone).format('H')} h ${moment
      .tz(availability.time, config.timezone)
      .format('mm')}`;
  }
  return (
    <div
      style={{
        justifyContent: 'center',
        display: 'inline-block',
        margin: 5,
      }}
    >
      <div>
        <BFCard
          selectable
          style={{
            backgroundColor: 'white',
            paddingLeft: 5,
            paddingRight: 5,
            display: 'flex',
            flexDirection: 'row',
          }}
          onClick={() => {
            onSelectAvailability(availability);
          }}
        >
          <div style={{color: theme.blue}}>{timeSlot}</div>
          {preview && (
            <div
              style={{marginLeft: 10}}
            >{`${availability.count}/${availability.slotsAvailable}`}</div>
          )}
        </BFCard>
      </div>
    </div>
  );
};
