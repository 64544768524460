import {R4} from '@ahryman40k/ts-fhir-types';
import {BundleTypeKind} from '@ahryman40k/ts-fhir-types/lib/R4';
import {createSlice} from '@reduxjs/toolkit';
import {normalize, schema} from 'normalizr';
import querystring from 'querystring';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState, ThunkDispatch} from '.';
import {FhirUtils} from '../services/fhir';
import {
  RequiredShortTextValidationRules,
  ShortTextValidationRules,
} from '../services/ui-validation-rules';
import {mapBundleToResourceArray} from '../util/fhir';
import {EmailValidationRuleId} from '../validation/validation-rules/email-validation';
import {HcnValidationRuleId} from '../validation/validation-rules/hcn-validation';
import {ValidationRules} from '../validation/validation-rules/validation-rules';

export interface ListType {
  id: string;
  name: string;
  organizationId: string;
  itemCount: number;
}

export const ListCSVOptionalFields = ['hcn', 'hcnType'];

export const ListCSVValidation: {
  [property: string]: ValidationRules[];
} = {
  firstName: RequiredShortTextValidationRules,
  lastName: RequiredShortTextValidationRules,
  hcn: ShortTextValidationRules.concat({validationRuleType: HcnValidationRuleId}),
  email: [
    {
      validationRuleType: EmailValidationRuleId,
    },
  ],
};

export interface ListsSliceInterface {
  byId: {[string: string]: ListType};
}
const initialState = {
  byId: {},
};

const list = new schema.Entity('lists', {});

const slice = createSlice({
  name: 'lists',
  initialState,
  reducers: {
    SAVE_COHORTS: (state: ListsSliceInterface, action) => {
      const resources = mapBundleToResourceArray(action.payload);
      state.byId = normalize(resources, [list]).entities.lists || {};
    },
    SAVE_COHORT: (state: ListsSliceInterface, action) => {
      state.byId[action.payload.id] = action.payload;
    },
    DELETE_COHORT: (state: ListsSliceInterface, action) => {
      delete state.byId[action.payload.id];
    },
  },
});

export const getAll = (client, params?) => async (dispatch) => {
  let query = params ? querystring.stringify(params) : undefined;
  const res = await client.get(`/list${query ? `?${query}` : ''}`);
  dispatch(slice.actions.SAVE_COHORTS(res.data));
  return res.data;
};

const getOne = (client, id) => async (dispatch) => {
  return client.get(`/list/${id}`).then((res) => {
    return dispatch(slice.actions.SAVE_COHORT(res.data));
  });
};

const updateOne = (client, org) => async (dispatch) => {
  return client.put(`/list/${org.id}`, org).then(async (res) => {
    await dispatch(slice.actions.SAVE_COHORT(res.data));
    return res.data;
  });
};

export const createOne = (client, list) => async (dispatch) => {
  return client.post(`/list`, list).then(async (res) => {
    await dispatch(slice.actions.SAVE_COHORT(res.data));
    return res.data;
  });
};

export const uploadPatients = (client, itemId: string, patients) => async (dispatch) => {
  const bundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: BundleTypeKind._transaction,
    entry: patients.map((p) => ({resource: p})),
  };

  return client.post(`/list/${itemId}/item`, bundle).then(async (res) => {
    return res.data;
  });
};

export const deleteOne = (client, itemId: string) => async (dispatch) => {
  return client.delete(`/list/${itemId}`).then(async (res) => {
    await dispatch(slice.actions.DELETE_COHORT({id: itemId}));
    return res.data;
  });
};

export default {
  slice,
  getAll,
  getOne,
  updateOne,
  createOne,
  uploadPatients,
  deleteOne,
};

export const useLists = (): ListType[] => {
  const thunkDispatch = useDispatch<ThunkDispatch>();
  const client = FhirUtils.useClient();

  React.useEffect(() => {
    thunkDispatch(getAll(client));
  }, []);

  const lists = useSelector((state: RootState) => state.lists.byId);

  return Object.values(lists);
};
