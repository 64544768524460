import {Button, Space, Typography, Tooltip} from 'antd';
import {SortOrder} from 'antd/lib/table/interface';
import {ReloadOutlined} from '@ant-design/icons';
import moment from 'moment-timezone';
import {useState} from 'react';
import {CreateNoteModal} from './create-note-modal';
import {ViewNoteModal} from './view-note-modal';
import {FHIRTable, FHIRTableProps} from '../../fhir-table';
import {FhirUtils} from '../../../services/fhir';
import React from 'react';
import {getStoredOrg} from '../../../models/organizations';

const {Title} = Typography;

// The columns of the NotesTable
const columns = {
  noteType: {
    title: 'Note Type',
    width: '15%',
    sortDirections: ['descend', 'ascend'] as SortOrder[],
    sorter: (a, b) => (a.form?.type < b.form?.type ? 1 : -1),
    render: (_, note) => {
      return note.form?.typeDefinition?.displayName['en'] || note.form?.type || 'missing';
    },
  },
  datetime: {
    title: 'Date and Time',
    width: '20%',
    sortDirections: ['descend', 'ascend', 'descend'] as SortOrder[],
    defaultSortOrder: 'descend' as SortOrder,
    sorter: (a, b) => moment(a.createdAt).diff(moment(b.createdAt)),
    render: (_, note) => moment.utc(note.createdAt).local().format('LLL'),
  },
  author: {
    title: 'Author',
    width: '20%',
    render: (_, note) => {
      if (note.clinicModeUser)
        return `${note.clinicModeUser.lastName}, ${note.clinicModeUser.firstName}`;
      if (note.consoleUserId) return note.consoleUserEmail || 'Admin User';
      return undefined;
    },
  },
  subject: {
    title: 'Subject',
    width: '20%',
    ellipses: true,
    render: (_, note) => {
      return (
        note.subject || (
          <span
            style={{
              opacity: '0.333',
            }}
          >
            (no subject)
          </span>
        )
      );
    },
  },
  preview: {
    title: 'Note Preview',
    ellipsis: true,
    render: (_, note) => {
      return (
        note.content || (
          <span
            style={{
              opacity: '0.333',
            }}
          >
            (no content)
          </span>
        )
      );
    },
  },
};

// Transforms the returned forms into notes
const responseTransformer = (response) => {
  for (let entry of response.entry) {
    // The primary content of a not varies depending on note type
    const previewTextField = entry.resource.form?.typeDefinition?.previewTextField;
    entry.resource.content = previewTextField && entry.resource.fields[previewTextField];
    entry.resource.subject = entry.resource.fields.subject;
  }
  return response;
};

interface NotesTableProps {
  /* The patient. */
  patient: any;
  /* A list of formTypes to include in the table. Includes all if unspecified.
     Format: ['typeToInclude1', 'typeToInclude2', '!typeToExclude'] */
  formTypes?: string[];
  /* A list of formGroups to include in the table. ['notes'] if unspecified.
     Format: ['null', 'groupToInclude2', !groupToExclude'] */
  formGroups?: string[];
  /* The title of the table. */
  title?: string;
  /* Any props to be passed down into the <FHIRTable /> element. */
  tableProps?: FHIRTableProps;
}

export const NotesTable = (props: NotesTableProps) => {
  const {patient, formTypes = [], title, tableProps} = props;
  const formGroups = props.formGroups || (formTypes.length ? [] : ['notes']);

  const storedOrg = getStoredOrg(patient.organizationId);

  const [currentNote, setCurrentNote] = useState();
  const [viewModalVisible, setViewModalVisible] = useState(false);
  const [createFormModalVisible, setCreateModalVisible] = useState(false);
  const [refreshTable, setRefreshTable] = useState(false);
  const client = FhirUtils.useAxiosClient();

  const displayTitle = title || 'Notes';

  // Choose what columns to display depending on variables.
  const displayColumns = [
    formTypes.length + formGroups.length != 1 && columns.noteType,
    columns.datetime,
    columns.author,
    columns.subject,
    columns.preview,
  ].filter((c) => typeof c === 'object');

  const reloadTable = () => setRefreshTable(!refreshTable);

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingBottom: 15,
          alignItems: 'center',
        }}
      >
        <div>
          <Title level={4}>
            {displayTitle}{' '}
            <Tooltip title="reload table">
              <Button
                icon={<ReloadOutlined style={{opacity: '.3'}} onClick={reloadTable} />}
                type="text"
              />
            </Tooltip>
          </Title>
        </div>
        {/* Show Add Note button only when user has access to patient's managing org */}
        {storedOrg && (
          <Space>
            <Button type="primary" onClick={() => setCreateModalVisible(true)}>
              Add Note
            </Button>
          </Space>
        )}
      </div>

      <FHIRTable
        mode="table"
        fhirResource={'form-submissions'}
        fixedFilters={{
          patientId: patient.id,
          formTypes: formTypes,
          formGroups: formGroups,
          includeFields: 'true',
          includeUploader: 'true',
        }}
        hideSearch
        columns={displayColumns}
        size="small"
        style={{width: '100%'}}
        showCreateButton={true}
        onClickRow={(note) => {
          setCurrentNote(note);
          setViewModalVisible(true);
        }}
        rowClassName="pointer"
        responseTransformer={responseTransformer}
        triggerRefresh={refreshTable}
        {...tableProps}
      />

      <ViewNoteModal
        visible={viewModalVisible}
        note={currentNote}
        onClose={() => {
          setViewModalVisible(false);
        }}
      />

      {createFormModalVisible && (
        <CreateNoteModal
          visible={createFormModalVisible}
          onClose={() => {
            setCreateModalVisible(false);
          }}
          afterSubmit={() => setRefreshTable(!refreshTable)}
          patientId={patient?.id}
          formTypes={formTypes}
          formGroups={formGroups}
        />
      )}
    </>
  );
};
