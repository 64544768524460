import {Button, message, Modal, Space} from 'antd';
import uuid from 'uuid';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState, ThunkDispatch} from '../../../models';
import {FhirUtils} from '../../../services/fhir';
import {FHIRTable} from '../../fhir-table';
import {colors} from '@canimmunize/tools';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckCircle} from '@fortawesome/free-solid-svg-icons';
import {ClinicCohortActions} from './clinic-cohort-actions';
import {Link} from 'react-router-dom';
import {Formik} from 'formik';
import {CohortForm} from '../../cohorts/cohort-ux';
import CohortModel from '../../../models/cohort';
import {AbilityContext} from '../../../services/roles/ability-context';

export const ClinicCohortsUX = ({clinicId}) => {
  const globalOrg = useSelector((state: RootState) => state.ui.localOrg);
  const [selectingCohort, setSelectingCohort] = React.useState(false);
  const [tableState, setTableState] = React.useState(uuid.v4());
  const [addedCohorts, setAddedCohorts] = React.useState<string[]>([]);
  const [showCreate, setShowCreate] = React.useState(false);
  const [isSubmit, setIsSubmit] = React.useState(false);
  const [loading, setLoading] = React.useState<string | undefined>(undefined);
  const ability = React.useContext(AbilityContext);

  const clinic = useSelector((state: RootState) => state.clinics.byId[clinicId]);

  const client = FhirUtils.useAxiosClient();
  const thunkDispatch = useDispatch<ThunkDispatch>();

  const onSelectCohort = async (cohort) => {
    setLoading(cohort.id);
    await client
      .put(`/clinic/${clinicId}/link/cohort`, {
        cohortId: cohort.id,
        clinicId,
        action: 'link',
      })
      .then(() => {
        setTableState(uuid.v4());
        setAddedCohorts([...addedCohorts, cohort.id]);
        message.success('Cohort successfully added to clinic!');
      })
      .catch(() => {
        message.error('Error adding cohort to clinic');
      });
    setLoading(undefined);
  };

  const columns = [
    {
      dataIndex: 'name',
      title: 'Name',
      render: (_, row) => <Link to={`/cohorts/${row.id}`}>{row.name}</Link>,
    },
    {
      dataIndex: 'organizationCode',
      key: 'organizationCode',
      title: 'Org Code',
      render: (_, cohort) => cohort.organization?.organizationCode,
    },
    ...(ability?.can('update', 'clinics', 'cohortsTab')
      ? [
          {
            title: 'Actions',
            render: (_, cohort) => (
              <ClinicCohortActions
                cohort={cohort}
                clinicId={clinicId}
                refreshTable={() => setTableState(uuid.v4())}
              />
            ),
          },
        ]
      : []),
  ];

  const pickerColumns = [
    {
      dataIndex: 'name',
      title: 'Name',
    },
    {
      dataIndex: 'organizationCode',
      title: 'Org Code',
      render: (_, cohort) => cohort.organization?.organizationCode,
    },
    {
      title: 'Select',
      render: (_, cohort) => (
        <Space>
          {cohort.clinics.map((c) => c.id).indexOf(clinicId) > -1 ? (
            <Button
              type="primary"
              className="success"
              size={'small'}
              style={{
                paddingLeft: 10,
                paddingRight: 10,
                backgroundColor: colors.green,
                borderColor: colors.green,
              }}
            >
              <FontAwesomeIcon icon={faCheckCircle} color="white" style={{marginRight: 10}} />
              Added
            </Button>
          ) : (
            <Button
              type="primary"
              size="small"
              onClick={() => onSelectCohort(cohort)}
              loading={loading === cohort.id}
            >
              Add to Clinic
            </Button>
          )}
        </Space>
      ),
    },
  ];

  const onCloseCreate = (formikProps) => {
    setShowCreate(false);
    formikProps.resetForm();
  };

  const handleSubmit = async (formikProps) => {
    setIsSubmit(true);
    await thunkDispatch(CohortModel.createOne(client, formikProps.values))
      .then(async (res) => {
        await onSelectCohort(res).then(() => onCloseCreate(formikProps));
      })
      .catch(() => {
        message.error('Error creating cohort.');
      });
    setIsSubmit(false);
  };

  return (
    <>
      <FHIRTable
        fhirResource="Group"
        mode="table"
        defaultPrimarySearchParam="name"
        triggerRefresh={tableState}
        fixedFilters={{clinicId}}
        columns={columns}
        label={'Cohort'}
        showCreateButton={ability?.can('update', 'clinics', 'cohortsTab') || false}
        createButtonTitle="Link Cohort"
        onClickCreateButton={() => setSelectingCohort(true)}
      />
      <Modal
        width={'40%'}
        visible={selectingCohort}
        title="Link Cohorts"
        onCancel={() => setSelectingCohort(false)}
        onOk={() => setSelectingCohort(false)}
        cancelButtonProps={{style: {display: 'none'}}}
        okText="Done"
        destroyOnClose
      >
        <FHIRTable
          fhirResource="Group"
          mode="table"
          label={'Cohort'}
          defaultPrimarySearchParam="name"
          showCreateButton={true}
          onClickCreateButton={() => setShowCreate(true)}
          fixedFilters={{_organizationId: globalOrg?.id || ''}}
          columns={pickerColumns}
          triggerRefresh={tableState}
        />
        <Formik
          initialValues={{organizationId: clinic?.organizationId}}
          isInitialValid={false}
          onSubmit={() => {}}
        >
          {(formikProps) => {
            const ModalFooter = () => {
              return (
                <Space>
                  <Button onClick={() => onCloseCreate(formikProps)}>Cancel</Button>
                  <Button
                    loading={isSubmit}
                    type="primary"
                    onMouseDown={(e) => {
                      e.preventDefault();
                    }}
                    onClick={() => {
                      handleSubmit(formikProps);
                    }}
                    disabled={!formikProps.isValid}
                  >
                    Save
                  </Button>
                </Space>
              );
            };

            return (
              <Modal
                maskClosable={false}
                closable={false}
                width={'40%'}
                visible={showCreate}
                title="New Cohort"
                footer={<ModalFooter />}
                destroyOnClose
              >
                <CohortForm
                  {...formikProps}
                  createMode={true}
                  modal={true}
                  disableOrgPicker={true}
                />
              </Modal>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};
