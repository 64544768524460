import {Descriptions} from 'antd';
import React from 'react';

/* A note view to act as a catch-all for any formSubmissions. */
export const GenericNoteView = (props) => {
  const {note} = props;
  return (
    <Descriptions
      size="small"
      labelStyle={{
        fontWeight: 'bold',
        width: '150px',
        overflowWrap: 'break-word',
        marginRight: '10px',
        wordBreak: 'break-word',
      }}
      column={1}
      colon={false}
    >
      {Object.keys(note.fields)
        .sort()
        .map((key) => (
          <Descriptions.Item label={key}>
            {note.fields[key] || (
              <span
                style={{
                  opacity: '0.333',
                }}
              >
                (no {key})
              </span>
            )}
          </Descriptions.Item>
        ))}
    </Descriptions>
  );
};
