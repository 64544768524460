import {Alert, message, Modal} from 'antd';
import {Formik} from 'formik';
import {Form, Input, Select} from 'formik-antd';
import BookingPageModel from '../../models/booking-pages';
import React from 'react';
import {FormUXFieldType} from '../form-ux/form-ux-models/form-ux-fields/form-ux-field-type';
import {SingleItemPicker} from '../form-ux-fields/single-item-picker-field';
import {FhirUtils} from '../../services/fhir';
import {useEnvInfo} from '../../services/environment';

interface EmailModalProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
  duplicateWarning: boolean;
  listId: string;
  listLength: Number;
}
export const EmailModal = (props: EmailModalProps) => {
  const {setVisible, listLength, listId} = props;
  const onSubmit = async (values) => {
    client.post(`/list/${listId}/email`, {...values}, {validateStatus: (s) => s === 200}).then(
      () => {
        setVisible(false);
        message.success('Your email was sent successfully!');
      },
      (err) => {
        Modal.error({
          title: 'Error sending email',
          content: FhirUtils.getErrorDisplay(err),
        });
      }
    );
  };
  const [type, setType] = React.useState('clinic-invitation');
  const client = FhirUtils.useAxiosClient();
  const {environmentId} = useEnvInfo();
  let clientName;

  switch (environmentId) {
    case 'novascotia':
      clientName = 'Nova Scotia Health';
      break;
    case 'yukon-2':
    case 'yukon-2-uat':
      clientName = 'The Yukon Government';
      break;
  }

  const bodyExtra =
    'Include variables in the email body by wrapping the variable name in double braces. Ex. {{ first_name }}. The following variables are available to be used: first_name, last_name, preferred_name. preferred_name will default to first_name if no preferred_name is available for the patient.';

  const defaultClinicInvitationSubject = `It is time to book your first COVID-19 vaccination appointment!`;
  const defaultClinicInvitationHeader = `It is time to book your first COVID-19 vaccination appointment!`;
  const defaultClinicInvitationBody = `<p>{{first_name}}, you are invited to book your first COVID-19 vaccination appointment!</p><br/>

<p>${
    clientName ? clientName + ' has' : 'We have'
  } partnered with CANImmunize to make it easier for you to book your COVID-19 vaccination appointments, keep track of your COVID-19 vaccination records and receive reminders when future COVID-19 immunizations are due.</p><br/>

<p>Click the button below to book your first COVID-19 vaccination appointment.</p><br/>
<p><b>Please note that this is a unique link and can only be used once.</b></p>`;

  const duplicateErrorText = `
There are duplicate emails in this list that may not be reliably sent. If you need to send to
multiple users with the same email address please use separate lists.
`;

  const defaultBody = type === 'clinic-invitation' ? defaultClinicInvitationBody : '';
  const defaultHeader = type === 'clinic-invitation' ? defaultClinicInvitationHeader : '';
  const defaultSubject = type === 'clinic-invitation' ? defaultClinicInvitationSubject : '';

  return (
    <Formik
      initialValues={{body: defaultBody, type, subject: defaultSubject, header: defaultHeader}}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {(formikProps) => {
        const onPressSendEmail = () => {
          Modal.confirm({
            title: 'Are you sure?',
            content: `Pressing Send will attempt to send this email to ${listLength} people.`,
            okText: 'Send',
            okType: 'primary',
            cancelText: 'Cancel',
            onOk() {
              formikProps.handleSubmit();
            },
            onCancel() {
              console.log('Cancel');
            },
          });
        };

        return (
          <Modal
            {...props}
            destroyOnClose
            width={844}
            onCancel={() => setVisible(false)}
            onOk={onPressSendEmail}
          >
            <h3>New Email</h3>
            <Form layout="vertical">
              <Form.Item name="type" label="Type">
                <Select
                  name="type"
                  onChange={(e) => {
                    setType(e);
                  }}
                >
                  <Select.Option key={'clinic-invitation'} value="clinic-invitation">
                    Clinic Invitation
                  </Select.Option>
                  <Select.Option key={'custom'} value="custom">
                    Custom Message
                  </Select.Option>
                </Select>
              </Form.Item>
              <Form.Item name="bookingPageId" label="Booking Page" required={type !== 'custom'}>
                <SingleItemPicker
                  field={{
                    name: 'bookingPageId',
                    type: FormUXFieldType.SingleItemPicker,
                    label: 'Booking Page',
                    editable: true,
                    fromIdValue: true,
                    inCreateModal: true,
                    model: BookingPageModel as any,
                    placeholder: 'Select Booking Page',
                    validationRules: [],
                  }}
                />
              </Form.Item>
              {type === 'custom' && !(formikProps.values as any).bookingPageId && (
                // The BP fromName supercedes this value, so hide when BP selected
                <Form.Item name="fromName" label="Email Sender Name">
                  <Input name="fromName" />
                </Form.Item>
              )}
              <Form.Item name="subject" label="Subject" required>
                <Input name="subject" />
              </Form.Item>
              <Form.Item name="header" label="Header" required>
                <Input name="header" />
              </Form.Item>
              <Form.Item name="body" label="Body" extra={bodyExtra}>
                <Input.TextArea name="body" rows={15} />
              </Form.Item>
            </Form>
            {props.duplicateWarning && <Alert message={duplicateErrorText} type="error" showIcon />}
          </Modal>
        );
      }}
    </Formik>
  );
};
