import {AbstractValidationRule} from './abstract-validation-rule';

export const RequiredObjectValidationRuleId = 'required-object';

export interface RequiredObjectValidationRule extends AbstractValidationRule {
  validationRuleType: typeof RequiredObjectValidationRuleId;
}

/**
 * Validation to check if any values of an object are null or undefined.
 *
 * Doesn't support nested objects
 *
 * @export
 * @param {*} value
 * @returns {boolean} True if validation passed. False otherwise.
 */
export function validateRequiredObjectRule(value: any): boolean {
  if (value === null || value === undefined) {
    return false;
  }

  if (typeof value !== 'object') {
    return false;
  }

  for (const [_, val] of Object.entries(value)) {
    if (val === null || val === undefined) {
      return false;
    }

    if (typeof val === 'string' && val.trim().length === 0) {
      return false;
    }

    if (Array.isArray(val) && val.length === 0) {
      return false;
    }
  }

  return true;
}
