import {CVCAntigen, CVCDisease} from '@canimmunize/cvc-js';
import {Col, Row, Tabs, Typography} from 'antd';
import React from 'react';
import {DetailedSection, EnglishFrenchOutput} from './terminology-misc-components';
import {DisplayTerm, FieldTitleVertical, GeneralTitle} from './terminology-titles';

const {Title, Paragraph, Text} = Typography;
const {TabPane} = Tabs;

export const DiseaseAntigenNIDD = (props: {
  concept: CVCDisease | CVCAntigen;
  superTitle: string;
}) => {
  const displayTermStyle = {color: '#595959', fontSize: 22};
  return (
    <div>
      <GeneralTitle title={props.superTitle} />
      <DisplayTerm displayTerm={props.concept.displayTerm.en}></DisplayTerm>
      <DetailedSection title="Pan-Canadian SNOMED CT Terminology" titlePresence={true}>
        <Row gutter={[16, 16]}>
          <Col span={12} style={{maxWidth: '35%'}}>
            <FieldTitleVertical title="Concept ID" />
            {props.concept.conceptId}
          </Col>
          <Col span={12} style={{maxWidth: '65%'}}>
            <FieldTitleVertical title="Fully Specified Name" />
            {props.concept.fsn}
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12} style={{maxWidth: '35%'}}>
            <FieldTitleVertical title="Concept Status" />
            {props.concept.status}
          </Col>
          <Col span={12} style={{maxWidth: '65%'}}>
            <FieldTitleVertical title="Display Term" />
            <EnglishFrenchOutput Lang={props.concept.displayTerm} />
          </Col>
        </Row>
      </DetailedSection>
      <DetailedSection title="Canadian Vaccine Catalogue Info" titlePresence={true}>
        <Row gutter={[16, 16]}>
          <Col span={12} style={{maxWidth: '100%'}}>
            <FieldTitleVertical title="Last Updated" />
            {props.concept.lastUpdated.format('YYYY-MM-DD')}
          </Col>
        </Row>
      </DetailedSection>
    </div>
  );
};
