import {
  Button,
  Col,
  Descriptions,
  Input,
  Modal,
  notification,
  Row,
  Select,
  Space,
  Typography,
} from 'antd';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {history} from '../../history';
import {RootState, ThunkDispatch} from '../../models';
import {fullDisplayName} from '../../models/fhir/patient';
import User from '../../models/users';
import {FhirUtils} from '../../services/fhir';
import {BaseEntityUXProps, BaseEntityV2} from '../base-entity/base-entity';
import {FHIRTableProps} from '../fhir-table';
import {FormUX} from '../form-ux/form-ux';
import {OrganizationPickerFormItem} from '../organization/organization-picker';
import {DebounceFormItem} from '../patients/imm-modal/debounce-form-item';
import {UserAccessFormItem} from './user-access-field';
import {UserFormCustomFieldName, UserFormUXModel} from './user-fields';
import {AccessTag, RoleTag} from './user-status-fields';

const {Title, Paragraph} = Typography;

const UserCopyableValue = (props: {value: string; align?: any}) => {
  const textAlign = props.align || 'start';
  return (
    <Paragraph style={{textAlign, marginBottom: 0}} code copyable>
      {props.value || ''}
    </Paragraph>
  );
};

export const UserUX = (props: Partial<BaseEntityUXProps>) => {
  const globalOrg = useSelector((state: RootState) => state.ui.localOrg);
  const navigate = useNavigate();
  const model = User;

  const FormItems = (props: any) => {
    const client = FhirUtils.useClient();
    const thunkDispatch = useDispatch<ThunkDispatch>();
    const [password, setPassword] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [isGenerated, setGenerated] = React.useState(true);
    const [isModalVisible, setIsModalVisible] = React.useState(false);

    const hasAccess = !!props.values.accountId;

    const onError = (err) => {
      setLoading(false);
      notification['error']({
        message: 'Server Error',
        description: err.message,
      });
    };

    const handleOk = () => {
      setLoading(true);
      thunkDispatch(User.updateAccess(client, props.values.id, password, true))
        .then((res) => {
          setLoading(false);
          setIsModalVisible(false);
          const hasPassword = !!res.userPassword;
          const msg = hasPassword
            ? 'User successfully created with password.'
            : 'User successfully created.';
          Modal.success({
            content: (
              <div>
                <Paragraph style={{marginBottom: 0}}>{msg}</Paragraph>
                {hasPassword && <UserCopyableValue value={res.userPassword} align="center" />}
              </div>
            ),
          });
        })
        .catch(onError);
    };

    const showAccessConfirm = () => {
      Modal.confirm({
        title: props.values.locked
          ? 'Are you sure you wish to reactivate the account with email:'
          : 'Are you sure you wish to deactivate the account with email:',
        content: <UserCopyableValue value={props.values.email} />,
        onOk() {
          return thunkDispatch(
            User.updateAccess(client, props.values.id, null, props.values.locked)
          )
            .then(() => {
              Modal.success({
                content: props.values.locked
                  ? 'User successfully reactivated.'
                  : 'User successfully deactivated.',
              });
            })
            .catch(onError);
        },
      });
    };

    const showResendEmailConfirm = () => {
      Modal.confirm({
        title: 'Are you sure you wish to resend a verify email to:',
        content: <UserCopyableValue value={props.values.email} />,
        onOk() {
          return thunkDispatch(User.resendVerify(client, props.values.id))
            .then(() => {
              notification['success']({
                message: 'Success',
                description: 'Verification email successfully sent.',
              });
            })
            .catch(onError);
        },
      });
    };

    if (props.createMode || props.editMode) {
      return (
        <FormUX
          formUXModel={UserFormUXModel}
          createMode={props.createMode}
          modal={props.modal}
          renderCustomField={(field) => {
            switch (field.name) {
              case UserFormCustomFieldName.OrganizationId: {
                return (
                  <OrganizationPickerFormItem
                    label={field.label}
                    name={field.name}
                    setFieldValue={props.setFieldValue}
                    validationRules={field.validationRules}
                  />
                );
              }
              case UserFormCustomFieldName.UserAccess: {
                return (
                  <UserAccessFormItem
                    label={field.label}
                    name={field.name}
                    setFieldValue={props.setFieldValue}
                    validationRules={field.validationRules}
                  />
                );
              }
              case UserFormCustomFieldName.LicenseBody:
                return (
                  <DebounceFormItem
                    {...field}
                    initialValues={props.values || {}}
                    form={props}
                    editable={field.editable}
                  />
                );
              default: {
                throw new Error(`Unhandled custom field ${field.name}
              in renderCustomField method`);
              }
            }
          }}
        />
      );
    }

    return (
      <>
        <Row gutter={[15, 15]}>
          <Col lg={24}>
            <Title level={4}>User Info</Title>
            <Descriptions bordered column={10} size="small">
              <Descriptions.Item span={5} label="First Name">
                {props.values.firstName}
              </Descriptions.Item>
              <Descriptions.Item span={5} label="UserId">
                <Paragraph code copyable>
                  {props.values.id}
                </Paragraph>
              </Descriptions.Item>
              <Descriptions.Item span={5} label="Last Name">
                {props.values.lastName}
              </Descriptions.Item>
              <Descriptions.Item span={5} label="Email">
                {props.values.email}
              </Descriptions.Item>
              <Descriptions.Item span={5} label="License Body">
                {props.values.licenseBody}
              </Descriptions.Item>
              <Descriptions.Item span={5} label="License Number">
                {props.values.licenseNumber}
              </Descriptions.Item>
              <Descriptions.Item label="Organization" span={10}>
                {props.values.organization?.nameEn || ''}
              </Descriptions.Item>
              <Descriptions.Item span={10} label="Approver">
                {props.values.approver}
              </Descriptions.Item>
              <Descriptions.Item label="Role" span={10}>
                <RoleTag role={props.values.role} />
              </Descriptions.Item>
              <Descriptions.Item label="Clinic Access" span={10}>
                <AccessTag accountId={props.values.accountId} locked={props.values.locked} />
              </Descriptions.Item>
            </Descriptions>
          </Col>
          <Col lg={10}>
            <Title level={4}>Access Info</Title>
            <Space direction="vertical" size="middle" style={{width: '100%'}}>
              <Descriptions bordered column={10} size="small">
                <Descriptions.Item label="Clinic Access">
                  <AccessTag accountId={props.values.accountId} locked={props.values.locked} />
                </Descriptions.Item>
              </Descriptions>
              {!hasAccess && (
                <Button type="primary" onClick={() => setIsModalVisible(true)}>
                  Enable Clinic Access
                </Button>
              )}
              {hasAccess && !props.values.locked && (
                <Button type="primary" onClick={() => showResendEmailConfirm()}>
                  Resend Verify Email
                </Button>
              )}
              {hasAccess && (
                <Button type="primary" onClick={() => showAccessConfirm()}>
                  {props.values.locked ? 'Reactivate Access' : 'Deactivate Access'}
                </Button>
              )}
              <Modal
                confirmLoading={loading}
                title="Enable Clinic Access"
                visible={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                onOk={handleOk}
              >
                <Row style={{textAlign: 'center'}}>
                  <Col lg={24}>
                    <Space direction="vertical">
                      {!hasAccess && (
                        <Select
                          defaultValue={isGenerated ? 'auto' : 'manual'}
                          onChange={(value) => {
                            if (value === 'manual') setGenerated(false);
                            else {
                              setPassword('');
                              setGenerated(true);
                            }
                          }}
                        >
                          <Select.Option value="auto">Server Generated Password</Select.Option>
                          <Select.Option value="manual">Manually Set Password</Select.Option>
                        </Select>
                      )}
                      {!hasAccess && isGenerated === false && (
                        <Input
                          value={password}
                          placeholder="Password"
                          onChange={(value) => setPassword(value.target.value)}
                        />
                      )}
                    </Space>
                  </Col>
                </Row>
              </Modal>
            </Space>
          </Col>
        </Row>
      </>
    );
  };

  const fixedFilters = {
    _organizationId: globalOrg?.id || '',
    _filterField: 'clinicAccess',
    _filterValues: ['granted'],
  };

  const tableConfig: FHIRTableProps = {
    fhirResource: 'clinic-mode-user',
    label: 'User',
    fixedFilters,
    mode: 'table',
    showCreateButton: true,
    defaultPrimarySearchParam: 'name',
    columns: [
      {
        title: 'Last Name',
        dataIndex: 'lastName',
        key: 'lastName',
      },
      {
        title: 'First Name',
        dataIndex: 'firstName',
        key: 'firstName',
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: 'License Body',
        dataIndex: 'licenseBody',
        key: 'licenseBody',
      },
      {
        title: 'License Number',
        dataIndex: 'licenseNumber',
        key: 'licenseNumber',
      },
      {
        title: 'Organization',
        key: 'organization',
        render: (_, user) => user.organization?.nameEn || '',
      },
      {
        title: 'Role',
        dataIndex: 'role',
        key: 'role',
        render: (_, user) => <RoleTag role={user.role} />,
      },
      {
        title: 'Clinic Access',
        key: 'clinicAccess',
        render: (_, user) => <AccessTag accountId={user.accountId} locked={user.locked} />,
        filters: [
          {text: 'Granted', value: 'granted'},
          {text: 'Denied', value: 'denied'},
        ],
      },
    ],
  };

  const config = {
    model,
    slug: 'user',
    showSearchTitle: false,
    modalCreateForm: true,
    onCreationSuccess: (res) => {
      const hasPassword = !!res.userPassword;
      const msg = hasPassword
        ? 'User successfully created with password.'
        : 'User successfully created.';
      Modal.success({
        content: (
          <div>
            <Paragraph style={{marginBottom: 0}}>{msg}</Paragraph>
            {hasPassword && <UserCopyableValue value={res.userPassword} align="center" />}
          </div>
        ),
        afterClose: () => {
          history.replace(`/users`);
          navigate(`/users/${res.id}`);
        },
      });
    },
    itemTitleField: (u) => fullDisplayName(u),
    formConfig: {FormItems},
    tableConfig,
    hideDeleteButton: true,
    ...props,
  };

  return <BaseEntityV2 {...config} />;
};
