"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_Provenance = void 0;
var t = __importStar(require("io-ts"));
var RTTI_id_1 = require("../Scalar/RTTI_id");
var RTTI_Meta_1 = require("./RTTI_Meta");
var RTTI_uri_1 = require("../Scalar/RTTI_uri");
var RTTI_Element_1 = require("./RTTI_Element");
var RTTI_code_1 = require("../Scalar/RTTI_code");
var RTTI_Narrative_1 = require("./RTTI_Narrative");
var RTTI_ResourceList_1 = require("../Union/RTTI_ResourceList");
var RTTI_Extension_1 = require("./RTTI_Extension");
var RTTI_Reference_1 = require("./RTTI_Reference");
var RTTI_Period_1 = require("./RTTI_Period");
var RTTI_instant_1 = require("../Scalar/RTTI_instant");
var RTTI_CodeableConcept_1 = require("./RTTI_CodeableConcept");
var RTTI_Provenance_Agent_1 = require("./RTTI_Provenance_Agent");
var RTTI_Provenance_Entity_1 = require("./RTTI_Provenance_Entity");
var RTTI_Signature_1 = require("./RTTI_Signature");
exports.RTTI_Provenance = t.recursion('IProvenance', function () {
    return t.intersection([
        t.type({
            resourceType: t.literal('Provenance'),
            target: t.array(RTTI_Reference_1.RTTI_Reference),
            agent: t.array(RTTI_Provenance_Agent_1.RTTI_Provenance_Agent)
        }),
        t.partial({
            id: RTTI_id_1.RTTI_id,
            meta: RTTI_Meta_1.RTTI_Meta,
            implicitRules: RTTI_uri_1.RTTI_uri,
            _implicitRules: RTTI_Element_1.RTTI_Element,
            language: RTTI_code_1.RTTI_code,
            _language: RTTI_Element_1.RTTI_Element,
            text: RTTI_Narrative_1.RTTI_Narrative,
            contained: t.array(RTTI_ResourceList_1.RTTI_ResourceList),
            extension: t.array(RTTI_Extension_1.RTTI_Extension),
            modifierExtension: t.array(RTTI_Extension_1.RTTI_Extension),
            occurredPeriod: RTTI_Period_1.RTTI_Period,
            occurredDateTime: t.string,
            _occurredDateTime: RTTI_Element_1.RTTI_Element,
            recorded: RTTI_instant_1.RTTI_instant,
            _recorded: RTTI_Element_1.RTTI_Element,
            policy: t.array(RTTI_uri_1.RTTI_uri),
            _policy: t.array(RTTI_Element_1.RTTI_Element),
            location: RTTI_Reference_1.RTTI_Reference,
            reason: t.array(RTTI_CodeableConcept_1.RTTI_CodeableConcept),
            activity: RTTI_CodeableConcept_1.RTTI_CodeableConcept,
            entity: t.array(RTTI_Provenance_Entity_1.RTTI_Provenance_Entity),
            signature: t.array(RTTI_Signature_1.RTTI_Signature)
        })
    ]);
});
