import {
  RequiredShortTextValidationRules,
  RequiredValidationRule,
} from '../../services/ui-validation-rules';
import {FormUXFieldType} from '../form-ux/form-ux-models/form-ux-fields/form-ux-field-type';
import {FormUXModel} from '../form-ux/form-ux-models/form-ux-model';

export enum OrgAdminUserFormCustomFieldName {
  Organizations = 'organizations',
  Password = 'password',
  Roles = 'roles',
}

export const OrgAdminUserFormUXModel: FormUXModel = [
  {
    groupName: 'Name',
    type: FormUXFieldType.flex,
    inCreateModal: true,
    fields: [
      {
        name: 'firstName',
        type: FormUXFieldType.text,
        label: 'First Name',
        editable: true,
        inCreateModal: true,
        validationRules: RequiredShortTextValidationRules,
      },
      {
        name: 'lastName',
        type: FormUXFieldType.text,
        label: 'Last Name',
        editable: true,
        inCreateModal: true,
        validationRules: RequiredShortTextValidationRules,
      },
    ],
  },
  {
    groupName: 'Info',
    type: FormUXFieldType.flex,
    inCreateModal: true,
    fields: [
      {
        name: 'email',
        type: FormUXFieldType.text,
        label: 'Email',
        editable: true,
        inCreateModal: true,
        validationRules: RequiredShortTextValidationRules,
      },
    ],
  },
  {
    name: OrgAdminUserFormCustomFieldName.Roles,
    type: FormUXFieldType.custom,
    label: 'Roles',
    editable: true,
    inEditMode: false,
    inCreateModal: true,
    validationRules: RequiredValidationRule,
  },
  {
    name: OrgAdminUserFormCustomFieldName.Organizations,
    type: FormUXFieldType.custom,
    label: 'Organizations',
    editable: true,
    inEditMode: false,
    inCreateModal: true,
    validationRules: RequiredValidationRule,
  },
  {
    name: OrgAdminUserFormCustomFieldName.Password,
    type: FormUXFieldType.custom,
    label: 'Password',
    editable: true,
    inEditMode: false,
    inCreateModal: true,
    validationRules: [],
  },
];
