import {Button, message, Popconfirm, Space, Spin} from 'antd';
import React from 'react';
import {FhirUtils} from '../../../services/fhir';

export const ClinicImmunizerActions = (props) => {
  const {immunizer, clinicId, refreshTable} = props;
  const client = FhirUtils.useAxiosClient();
  const [loading, setLoading] = React.useState(false);

  const onRemove = async () => {
    setLoading(true);
    const result = await client.put(`/clinic/${clinicId}/link/immunizer`, {
      action: 'unlink',
      clinicModeUserId: immunizer.id,
      clinicId,
    });

    if (result.status === 200) {
      refreshTable();
      message.success('Immunizer was successfully removed from clinic.');
    } else {
      message.error('Error removing immunizer from clinic.');
    }

    setLoading(false);
  };

  return (
    <Space>
      <Popconfirm
        overlayStyle={{maxWidth: 400}}
        title={
          <div>
            <div>
              <b>Are you sure you want to remove this immunizer from this clinic?</b>
            </div>
          </div>
        }
        onConfirm={onRemove}
        okText="Yes"
        cancelText="No"
      >
        <Space>
          <Button type="link" danger size={'small'} style={{paddingLeft: 10, paddingRight: 10}} loading={loading}>
            Remove
          </Button>
        </Space>
      </Popconfirm>
    </Space>
  );
};