import {R4} from '@ahryman40k/ts-fhir-types';
import {createSlice} from '@reduxjs/toolkit';
import {normalize, schema} from 'normalizr';
import querystring from 'querystring';
import {mapBundleToResourceArray} from '../util/fhir';

export interface QuestionnairesSliceInterface {
  byId: {[string: string]: R4.IQuestionnaire};
}
const initialState = {
  byId: {},
};

const questionnaire = new schema.Entity('questionnaires', {});

const slice = createSlice({
  name: 'questionnaires',
  initialState,
  reducers: {
    SAVE_QUESTIONNAIRES: (state: QuestionnairesSliceInterface, action) => {
      const resources = mapBundleToResourceArray(action.payload);
      state.byId = normalize(resources, [questionnaire]).entities.questionnaires || {};
    },
    SAVE_QUESTIONNAIRE: (state: QuestionnairesSliceInterface, action) => {
      state.byId[action.payload.id] = action.payload;
    },
  },
});

export const getAll = (client, params?) => async (dispatch) => {
  let query = params ? querystring.stringify(params) : undefined;
  const data = await client.request(`Questionnaire${query ? `?${query}` : ''}`);
  dispatch(slice.actions.SAVE_QUESTIONNAIRES(data));
  return data;
};

const getOne = (client, id) => async (dispatch) => {
  return client.request(`/Questionnaire/${id}`).then((res) => {
    return dispatch(slice.actions.SAVE_QUESTIONNAIRE(res));
  });
};

const updateOne = (client, org) => async (dispatch) => {
  return client.update(org).then(async (res) => {
    await dispatch(slice.actions.SAVE_QUESTIONNAIRE(res));
    return res;
  });
};

export const createOne = (client, org) => async (dispatch) => {
  return client.create(org).then(async (res) => {
    await dispatch(slice.actions.SAVE_QUESTIONNAIRE(res));
    return res;
  });
};

export default {
  slice,
  getAll,
  getOne,
  updateOne,
  createOne,
};
