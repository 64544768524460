import React from 'react';
import {useDispatch} from 'react-redux';
import {ThunkDispatch} from '../../../models';
import * as Clinic from '../../../models/clinics';
import {ClinicCSVValidation} from '../../../models/clinics';
import {useGlobalSettings} from '../../../models/global-settings';
import {useEnvInfo} from '../../../services/environment';
import {FhirUtils} from '../../../services/fhir';
import {UploadModal} from './upload-modal';

export const ClinicUploadModal = ({visible, onClose}) => {
  const settings = useGlobalSettings();
  const {environmentId} = useEnvInfo();
  const thunkDispatch = useDispatch<ThunkDispatch>();
  const client = FhirUtils.useClient();

  const onUploadClinic = async (uploadData) => {
    let result;
    await thunkDispatch(Clinic.uploadClinics(client, uploadData)).then((r) => {
      result = r;
    });
    return result;
  };

  return (
    <UploadModal
      visible={visible}
      onClose={onClose}
      onClickUpload={onUploadClinic}
      title="Import Clinics"
      howToUse=""
      preProcessData={(row) => {
        clinicTemplateFieldNames.map((t) => {
          const humanReadableId = t.name.en;
          const uploadId = t.id;
          if (humanReadableId === uploadId) {
            if (row[humanReadableId] === '') delete row[humanReadableId];
            return;
          }

          if (row[humanReadableId] && row[humanReadableId] !== '') {
            row[uploadId] = row[humanReadableId];
          }
          delete row[humanReadableId];
        });
        return row;
      }}
      getValidationRules={ClinicCSVValidation}
      validationOptions={{settings: {...settings, environmentId}}}
      templateName={Clinic.clinicUploadTemplateName}
      uploadedColumns={clinicTemplateFieldNames.map((t, index) => {
        return index === 0
          ? {dataIndex: t.id, title: t.name.en, fixed: 'left', width: 200}
          : {dataIndex: t.id, title: t.name.en, width: t.width};
      })}
    />
  );
};

const clinicTemplateFieldNames = [
  {name: {en: 'Clinic Name'}, id: 'clinicName'},
  {name: {en: 'Clinic ID'}, id: 'clinicId'},
  {name: {en: 'ORG Code'}, id: 'orgCode'},
  {name: {en: 'Campaign Name'}, id: 'Campaign Name'},
  {name: {en: 'campaignId'}, id: 'campaignId'},
  {name: {en: 'Vaccine Product'}, id: 'vaccineProduct'},
  {name: {en: 'vaccineConceptId'}, id: 'vaccineConceptId'},
  {name: {en: 'Self Check-In'}, id: 'selfCheckInClinic'},
  {name: {en: 'Dose 2 Interval (Min)'}, id: 'minSecondAppointmentInterval', width: 150},
  {name: {en: 'Dose 2 Interval (Target)'}, id: 'targetSecondAppointmentInterval', width: 150},
  {name: {en: 'Dose 2 Interval (Max)'}, id: 'maxSecondAppointmentInterval', width: 150},
  {name: {en: 'Consent Form'}, id: 'consentFormName'},
  {name: {en: 'Consent Form ID'}, id: 'consentFormId'},
  {name: {en: 'Health Status Form'}, id: 'healthStatusFormName'},
  {name: {en: 'Health Status Form ID'}, id: 'healthStatusFormId'},
  {name: {en: 'Demographic Form'}, id: 'demographicsFormName'},
  {name: {en: 'Demographic Form ID'}, id: 'clinicModeDemographicsFormId'},
  {name: {en: 'COVID-19 Screen'}, id: 'covid19ScreeningFormName'},
  {name: {en: 'COVID-19 Screen ID'}, id: 'covidScreeningFormId'},
  {name: {en: 'Clinic Type'}, id: 'category'},
  {name: {en: 'timezone'}, id: 'timezone'},
  {name: {en: 'Postal Code'}, id: 'postalCode'},
  {name: {en: 'Location'}, id: 'locationName'},
  {name: {en: 'Service Delivery Location'}, id: 'serviceDeliveryLocation'},
  {name: {en: 'Maps Location'}, id: 'mapsLocationString'},
  {name: {en: 'Location Display ENG'}, id: 'locationDisplayEn'},
  {name: {en: 'Location Display FR'}, id: 'locationDisplayFr'},
  {name: {en: 'City', fr: 'Ville'}, id: 'city'},
  {name: {en: 'Calendar Name'}, id: 'calendarName'},
  {name: {en: 'Autobook Dose 2 and Mirror Calendar'}, id: 'autoBookSecondAppointment', width: 200},
  {name: {en: 'Calendar Type'}, id: 'calendarType', width: 50},
  {name: {en: 'Appointment Duration'}, id: 'duration', width: 50},
  {name: {en: 'Show on Booking Page'}, id: 'showOnBookingPage', width: 150},
  {name: {en: 'Rule Start Date'}, id: 'startDate'},
  {name: {en: 'Rule End Date'}, id: 'endDate'},
  {name: {en: 'Rule Start Time'}, id: 'startTime'},
  {name: {en: 'Rule End Time'}, id: 'endTime'},
  {name: {en: '# of Immunizers'}, id: 'immunizerCount'},
  {name: {en: 'Sunday'}, id: 'sunday'},
  {name: {en: 'Monday'}, id: 'monday'},
  {name: {en: 'Tuesday'}, id: 'tuesday'},
  {name: {en: 'Wednesday'}, id: 'wednesday'},
  {name: {en: 'Thursday'}, id: 'thursday'},
  {name: {en: 'Friday'}, id: 'friday'},
  {name: {en: 'Saturday'}, id: 'saturday'},
];
