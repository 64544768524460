"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_ImmunizationRecommendation_Recommendation = void 0;
var t = __importStar(require("io-ts"));
var RTTI_Extension_1 = require("./RTTI_Extension");
var RTTI_CodeableConcept_1 = require("./RTTI_CodeableConcept");
var RTTI_ImmunizationRecommendation_DateCriterion_1 = require("./RTTI_ImmunizationRecommendation_DateCriterion");
var RTTI_Element_1 = require("./RTTI_Element");
var RTTI_Reference_1 = require("./RTTI_Reference");
exports.RTTI_ImmunizationRecommendation_Recommendation = t.recursion('IImmunizationRecommendation_Recommendation', function () {
    return t.intersection([
        t.type({
            forecastStatus: RTTI_CodeableConcept_1.RTTI_CodeableConcept
        }),
        t.partial({
            id: t.string,
            extension: t.array(RTTI_Extension_1.RTTI_Extension),
            modifierExtension: t.array(RTTI_Extension_1.RTTI_Extension),
            vaccineCode: t.array(RTTI_CodeableConcept_1.RTTI_CodeableConcept),
            targetDisease: RTTI_CodeableConcept_1.RTTI_CodeableConcept,
            contraindicatedVaccineCode: t.array(RTTI_CodeableConcept_1.RTTI_CodeableConcept),
            forecastReason: t.array(RTTI_CodeableConcept_1.RTTI_CodeableConcept),
            dateCriterion: t.array(RTTI_ImmunizationRecommendation_DateCriterion_1.RTTI_ImmunizationRecommendation_DateCriterion),
            description: t.string,
            _description: RTTI_Element_1.RTTI_Element,
            series: t.string,
            _series: RTTI_Element_1.RTTI_Element,
            doseNumberPositiveInt: t.number,
            _doseNumberPositiveInt: RTTI_Element_1.RTTI_Element,
            doseNumberString: t.string,
            _doseNumberString: RTTI_Element_1.RTTI_Element,
            seriesDosesPositiveInt: t.number,
            _seriesDosesPositiveInt: RTTI_Element_1.RTTI_Element,
            seriesDosesString: t.string,
            _seriesDosesString: RTTI_Element_1.RTTI_Element,
            supportingImmunization: t.array(RTTI_Reference_1.RTTI_Reference),
            supportingPatientInformation: t.array(RTTI_Reference_1.RTTI_Reference)
        })
    ]);
});
