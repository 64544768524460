import * as RTK from '@reduxjs/toolkit';
import React from 'react';
import {Provider} from 'react-redux';
import {logger} from 'redux-logger';
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {rootReducer} from './models';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['ui'],
};

const reducer = persistReducer(persistConfig, rootReducer);
const middleware = [
  ...RTK.getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
  logger,
];
export const store = RTK.configureStore({reducer, middleware});
export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk = RTK.ThunkAction<void, RootState, unknown, RTK.Action<string>>;

export const withProvider = (Story) => {
  return (
    <Provider store={store}>
      <Story />
    </Provider>
  );
};
