import React from 'react';
import {Col, Row} from 'antd';
import {Form, Select, Switch, InputNumber} from 'formik-antd';
import {ValidateFormItem} from '../../../validate-form-item';
import {
  DateField,
  DaysField,
  EndDateField,
  EndTimeField,
  IsAvailableField,
  StartDateField,
  StartTimeField,
  ImmunizerCountField,
  DurationField,
  GroupCountField,
  PrimaryAppointmentCountField,
} from './availability-rule-form-fields';
import {FormUXDateField, FormUXTimeField} from '../../../form-ux/form-ux';
import _ from 'lodash';

interface IRuleFormItemsProps {
  namePrefix: string;
  clinic: any;
  rule: any;
  calendarView?: boolean; //if view is from drag and drop, hide certain form items
  enableDose2Availability?: boolean;
}

export const RecurringRuleFormItems = (props: IRuleFormItemsProps) => {
  return (
    <>
      <BaseRuleTimesFormItems {...props} />

      <Row gutter={24}>
        <Col md={12}>
          <ValidateFormItem
            validationRules={StartDateField.validationRules}
            renderFormItem={(validate) => {
              const name = `${props.namePrefix}${StartDateField.name}`;

              return (
                <FormUXDateField name={name} label={StartDateField.label} validate={validate} />
              );
            }}
          />
        </Col>
        <Col md={12}>
          <ValidateFormItem
            validationRules={EndDateField.validationRules}
            renderFormItem={(validate) => {
              const name = `${props.namePrefix}${EndDateField.name}`;

              return <FormUXDateField name={name} label={EndDateField.label} validate={validate} />;
            }}
          />
        </Col>
      </Row>

      <ValidateFormItem
        validationRules={DaysField.validationRules}
        renderFormItem={(validate) => {
          return (
            <Form.Item
              name={`${props.namePrefix}${DaysField.name}`}
              label={DaysField.label}
              validate={validate}
            >
              <Select
                name={`${props.namePrefix}${DaysField.name}`}
                mode="multiple"
                filterOption={(input, option) =>
                  option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {DaysField.selectableValues.map((value) => {
                  if (typeof value === 'string') return;

                  return (
                    <Select.Option key={value.key} value={value.key}>
                      {value.label}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          );
        }}
      />
      <BaseRuleSwitchesFormItems {...props} />
    </>
  );
};

export const NonRecurringRuleFormItems = (props: IRuleFormItemsProps) => {
  return (
    <>
      <BaseRuleTimesFormItems {...props} />

      <ValidateFormItem
        validationRules={DateField.validationRules}
        renderFormItem={(validate) => {
          const name = `${props.namePrefix}${DateField.name}`;

          return <FormUXDateField name={name} label={DateField.label} validate={validate} />;
        }}
      />

      <BaseRuleSwitchesFormItems {...props} />
    </>
  );
};

export const BaseRuleTimesFormItems = (props: IRuleFormItemsProps) => {
  return (
    <>
      <Row gutter={24}>
        <Col md={12}>
          <ValidateFormItem
            validationRules={StartTimeField.validationRules}
            renderFormItem={(validate) => {
              const name = `${props.namePrefix}${StartTimeField.name}`;

              return (
                <FormUXTimeField
                  name={name}
                  validate={validate}
                  label={StartTimeField.label}
                  timeFormat="HH:mm"
                />
              );
            }}
          />
        </Col>
        <Col md={12}>
          <ValidateFormItem
            validationRules={EndTimeField.validationRules}
            renderFormItem={(validate) => {
              const name = `${props.namePrefix}${EndTimeField.name}`;

              return (
                <FormUXTimeField
                  name={name}
                  validate={validate}
                  label={EndTimeField.label}
                  timeFormat="HH:mm"
                />
              );
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export const BaseRuleSwitchesFormItems = (props: IRuleFormItemsProps) => {
  const {rule, clinic, calendarView, enableDose2Availability} = props;
  const immunizerCounterField =
    clinic.category === 'Group' ? PrimaryAppointmentCountField : ImmunizerCountField;

  return (
    <>
      <Row gutter={24}>
        <Col md={12}>
          <ValidateFormItem
            validationRules={immunizerCounterField.validationRules}
            renderFormItem={(validate) => {
              return (
                <Form.Item
                  name={`${props.namePrefix}${immunizerCounterField.name}`}
                  label={immunizerCounterField.label}
                  validate={validate}
                  extra={immunizerCounterField.extra}
                >
                  <InputNumber name={`${props.namePrefix}${immunizerCounterField.name}`} />
                </Form.Item>
              );
            }}
          />
        </Col>
        <Col md={12}>
          <ValidateFormItem
            validationRules={DurationField.validationRules}
            renderFormItem={(validate) => {
              return (
                <Form.Item
                  name={`${props.namePrefix}${DurationField.name}`}
                  label={DurationField.label}
                  validate={validate}
                  extra={DurationField.extra}
                >
                  <InputNumber name={`${props.namePrefix}${DurationField.name}`} />
                </Form.Item>
              );
            }}
          />
        </Col>
        {clinic.category === 'Group' && (
          <Col md={12}>
            <ValidateFormItem
              validationRules={GroupCountField.validationRules}
              renderFormItem={(validate) => {
                return (
                  <Form.Item
                    name={`${props.namePrefix}${GroupCountField.name}`}
                    label={GroupCountField.label}
                    validate={validate}
                    extra={GroupCountField.extra}
                  >
                    <InputNumber name={`${props.namePrefix}${GroupCountField.name}`} />
                  </Form.Item>
                );
              }}
            />
          </Col>
        )}
      </Row>
      {!calendarView && (
        <>
          <Row gutter={24}>
            <Col md={24}>
              <ValidateFormItem
                validationRules={IsAvailableField.validationRules}
                renderFormItem={(validate) => {
                  return (
                    <Form.Item
                      name={`${props.namePrefix}${IsAvailableField.name}`}
                      label={IsAvailableField.label}
                      validate={validate}
                      extra={IsAvailableField.extra}
                    >
                      <Switch size="small" name={`${props.namePrefix}${IsAvailableField.name}`} />
                    </Form.Item>
                  );
                }}
              />
            </Col>
          </Row>
          {enableDose2Availability && (
            <>
            </>
          )}
        </>
      )}
    </>
  );
};
