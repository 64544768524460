import {useAuth0} from '@auth0/auth0-react';
import React from 'react';
import {Navigate, Route, Routes} from 'react-router';
import {APITokenUX} from '../components/api/api-ux';
import {AppointmentUX} from '../components/appointment/appointment-ux';
import {BookingPageUX} from '../components/booking-pages/booking-page-ux';
import {CampaignUX} from '../components/campaign/campaign-ux';
import {ClinicFlowFormUX} from '../components/clinic-flow-form/clinic-flow-form';
import {ClinicUX} from '../components/clinic/clinic-ux';
import {CohortUX} from '../components/cohorts/cohort-ux';
import {HcpLookup} from '../components/hcp-lookup/hcp-ux';
import {HcpLookupItemView} from '../components/hcp-lookup/item-page';
import {InactivityLogout} from '../components/inactivity-logout/inactivity-logout-ux';
import {AppLayout} from '../components/layouts/app';
import {ListUX} from '../components/lists/list-ux';
import {LogUX} from '../components/logs/log-ux';
import {MailQueueUX} from '../components/mail-queue/mail-queue-ux';
import {OrganizationUX} from '../components/organization/organization-ux';
import {PatientUX} from '../components/patients/patient-ux';
import {PortalUX} from '../components/portal/portal-ux';
import {ReportUX} from '../components/reports/report-ux';
import {RoleUX} from '../components/role/role-ux';
import {BookingForm} from '../components/scheduler/booking-form';
import {ConsentForm} from '../components/scheduler/consent-form';
import {BookingPageConfigProvider} from '../components/scheduler/util';
import {OrgAdminUserUX} from '../components/users/org-admin-users-ux';
import {UserUX} from '../components/users/users-ux';
import {Loading} from '../components/util/loading';
import {YKCallCentreUX} from '../components/yk-call-centre/yk-call-centre-ux';
import {DashboardRouter} from '../views/dashboard-router';
import {HelpView} from '../views/help';
import {ImportView} from '../views/import';
import {MissingView} from '../views/missing';
import {SearchView} from '../views/search';
import {TerminologyView} from '../views/terminology';
import {UserManagementView} from '../views/user-management';
import {VaccineReceipt} from '../views/vaccine-receipt';
import {FhirProvider} from './fhir';
import {AbilityProvider} from './roles/ability-context';

export const AuthRoute = (props: any) => (
  <FhirProvider>
    <AbilityProvider path={props.path}>
      <InactivityLogout />
      <AppLayout>{props.children || <props.component />}</AppLayout>
    </AbilityProvider>
  </FhirProvider>
);

export const SchedulerRoute = (props: any) => (
  <BookingPageConfigProvider>{props.children || <props.component />}</BookingPageConfigProvider>
);

export const AppRoutes = () => {
  const {isLoading} = useAuth0();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Routes>
      <Route
        path="/orgadminusers"
        element={
          <AuthRoute>
            <UserManagementView />
          </AuthRoute>
        }
      />
      <Route
        path="/users"
        element={
          <AuthRoute>
            <UserManagementView />
          </AuthRoute>
        }
      />
      <Route path="patients/*" element={<PatientUX />} />
      <Route path="campaigns/*" element={<CampaignUX />} />
      <Route path="appointments/*" element={<AppointmentUX />} />
      <Route path="cohorts/*" element={<CohortUX />} />
      <Route path="lists/*" element={<ListUX />} />
      <Route path="portals/*" element={<PortalUX />} />
      <Route path="logs/*" element={<AuthRoute children={<LogUX />} />} />
      <Route path="mailqueues/*" element={<MailQueueUX />} />
      <Route path="/:lang/ykcallcentre/*" element={<YKCallCentreUX key={Date.now()} />} />
      <Route path="organizations/*" element={<OrganizationUX />} />
      <Route path="forms/*" element={<ClinicFlowFormUX />} />
      <Route path="api-tokens/*" element={<APITokenUX />} />
      <Route path="roles/*" element={<RoleUX />} />
      <Route path="users/*" element={<UserUX />} />
      <Route path="orgadminusers/*" element={<OrgAdminUserUX />} />
      <Route path="bookingpages/*" element={<BookingPageUX />} />
      <Route path="bookingpages/:id/:tab" element={<BookingPageUX forcePage="item" />} />
      <Route path="clinics/*" element={<ClinicUX />} />
      <Route path="clinics/:id/:tab" element={<ClinicUX forcePage="item" />} />
      <Route path="clinics/:id/:tab/:action" element={<ClinicUX forcePage="item" />} />
      <Route path="reports/*" element={<AuthRoute children={<ReportUX />} />} />
      {/* Patient Lookup Routes */}
      <Route
        path="/patientlookup/:patientId"
        element={<AuthRoute children={<HcpLookupItemView />} />}
      />
      <Route path="/patientlookup" element={<AuthRoute children={<HcpLookup />} />} />
      <Route path="/terminology" element={<AuthRoute children={<TerminologyView />} />} />
      <Route path="/terminology/:valueSet" element={<AuthRoute children={<TerminologyView />} />} />
      <Route
        path="/terminology/:valueSet/:conceptId"
        element={<AuthRoute children={<TerminologyView />} />}
      />
      <Route
        path="/:lang/:bookingPageSlug/receipt/:id"
        element={<Navigate to={'/:lang/portal/:id'} />}
      />
      <Route
        path="/:lang/a/:bookingPageSlug/receipt/:id"
        element={<Navigate to={'/:lang/portal/:id'} />}
      />
      {/* COVID portal routes */}
      <Route path="/:lang/portal" element={<VaccineReceipt mode="portal" />} />
      <Route path="/:lang/portal/:id" element={<VaccineReceipt mode="portal" />} />
      {/* Booking page routes */}
      <Route
        path="/:lang/a/:bookingPageSlug/:appointmentId"
        element={<SchedulerRoute children={<ConsentForm />} />}
      />
      <Route
        path="/:lang/:bookingPageSlug/:appointmentId"
        element={<SchedulerRoute children={<ConsentForm />} />}
      />
      <Route
        path="/:lang/:bookingPageSlug"
        element={<SchedulerRoute children={<BookingForm showLandingPanel />} />}
      />
      <Route path="/home" element={<AuthRoute children={<DashboardRouter />} />} />
      <Route path="/help" element={<AuthRoute children={<HelpView />} />} />
      <Route path="/import" element={<AuthRoute children={<ImportView />} />} />
      <Route path="/search" element={<AuthRoute children={<SearchView />} />} />
      <Route path="/404" element={<MissingView />} />
      <Route path="*" element={<Navigate to="/home" replace />} />;
    </Routes>
  );
};