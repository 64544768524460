import React, {useEffect, useState} from 'react';
import {FhirUtils} from '../../../services/fhir';
import {useEnvInfo} from '../../../services/environment';
import {Button, message, Modal, Row, Select, Space, Form, Col, Checkbox, Typography} from 'antd';
import {PlusOutlined} from '@ant-design/icons';
import {Appointment, AppointmentType} from '../../scheduler/types';
import {BookingPageConfigProvider, minimizeFormComponents, useUrlData} from '../../scheduler/util';
import {
  IBookingPageSelectOption,
  BookingPageSelector,
  LoadingSpinner,
  SchedulePasswordForm,
} from './scheduler-utils';
import {AppointmentPicker} from '../../scheduler/booking-form/appointment-picker';
import {ContactForm} from '../../scheduler/booking-form/contact-form';
import {FormsContainer} from '../../scheduler/consent-form';
import AppointmentModel from '../../../models/appointments';
import {ThunkDispatch} from '../../../models';
import {useDispatch, useSelector} from 'react-redux';
import {filterRequiredFormTypesForConfig} from '../../scheduler/consent-form/patient-forms';
import {RootState} from '../../../store';
import {AppointmentCancelled} from '../../scheduler/consent-form/appointment-cancelled';
import {AppointmentSummary} from '../../scheduler/appointment-summary';
import {AbilityContext} from '../../../services/roles/ability-context';
import axios from 'axios';
import {Formio} from '../../util/formio';

interface ISchedulerModalProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  patientId?: string;
  previousAppointmentId?: string;
  previousDoseId?: string;
  onSchedule?;
  defaultAge?;
}

interface ISchedulerState {
  scheduling: boolean;
  contactFormValues: {data: {}};
  isAlternate: boolean;
  alternateSeriesId: any;
  passwordVerified: boolean;
  bookingPage?: IBookingPageSelectOption;
  appointmentId?: string;
  selectedAppointment?: Appointment;
  selectedAppointmentType?: AppointmentType;
  appointmentCancellation?: string;
  loadingAppointment?: boolean;
  loadingBookingPages?: boolean;
  schedulingLoading?: boolean;
  demographicsFormComponent?: any;
  demographicsFormLoading: boolean;
}

const getInitialState = (): ISchedulerState => {
  return {
    scheduling: true,
    contactFormValues: {data: {}},
    isAlternate: false,
    alternateSeriesId: undefined,
    bookingPage: undefined,
    appointmentId: undefined,
    selectedAppointment: undefined,
    selectedAppointmentType: undefined,
    appointmentCancellation: undefined,
    loadingAppointment: false,
    loadingBookingPages: false,
    passwordVerified: false,
    schedulingLoading: false,
    demographicsFormComponent: undefined,
    demographicsFormLoading: false,
  };
};

const schedulerReducer = (state, action) => {
  /*
  const linksCheck = (result) => {
    if (result.selectedAppointmentType === undefined) result.selectedAppointment = undefined;
  };
  */
  if (action.type === 'reset') {
    return getInitialState();
  }
  if (action.type === 'resetAppointment') {
    const result = {...state};
    result.selectedAppointment = undefined;
    result.selectedAppointmentType = undefined;
    return result;
  }
  const result = {...state};
  result[action.type] = action[action.type];
  //linksCheck(result);
  return result;
};

export const SchedulerModal = (props: ISchedulerModalProps) => {
  const {syncUrl} = useEnvInfo();
  const {visible, setVisible, previousAppointmentId, previousDoseId, patientId, defaultAge} = props;
  const [schedulerState, schedulerDispatch] = React.useReducer(schedulerReducer, getInitialState);
  const [overrideFormData, setOverrideFormData] = React.useState({});

  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);

  const setScheduling = (scheduling) => {
    schedulerDispatch({type: 'scheduling', scheduling});
  };
  const setContactFormValues = (contactFormValues) => {
    schedulerDispatch({type: 'contactFormValues', contactFormValues});
  };
  const setIsAlternate = (isAlternate) => {
    schedulerDispatch({type: 'isAlternate', isAlternate});
  };
  const setAlternateSeriesId = (alternateSeriesId) => {
    schedulerDispatch({type: 'alternateSeriesId', alternateSeriesId});
  };
  const setBookingPage = (bookingPage) => {
    schedulerDispatch({type: 'bookingPage', bookingPage});
  };
  const setAppointmentId = (appointmentId) => {
    schedulerDispatch({type: 'appointmentId', appointmentId});
  };
  const setSelectedAppointment = (selectedAppointment) => {
    schedulerDispatch({type: 'selectedAppointment', selectedAppointment});
  };
  const setSelectedAppointmentType = (selectedAppointmentType) => {
    schedulerDispatch({type: 'selectedAppointmentType', selectedAppointmentType});
  };
  const setLoadingAppointment = (loadingAppointment) => {
    schedulerDispatch({type: 'loadingAppointment', loadingAppointment});
  };
  const setLoadingBookingPages = (loadingBookingPages) => {
    schedulerDispatch({type: 'loadingBookingPages', loadingBookingPages});
  };
  const setPasswordVerified = (passwordVerified) => {
    schedulerDispatch({type: 'passwordVerified', passwordVerified});
  };
  const setAppointmentCancellation = (appointmentCancellation) => {
    schedulerDispatch({type: 'appointmentCancellation', appointmentCancellation});
  };
  const setSchedulingLoading = (schedulingLoading) => {
    schedulerDispatch({type: 'schedulingLoading', schedulingLoading});
  };
  const setDemographicsFormLoading = (demographicsFormLoading) => {
    schedulerDispatch({type: 'demographicsFormLoading', demographicsFormLoading});
  };

  const switchDemographicsFormComponent = (demographicsForm) => {
    const {id, form, formOptions} = demographicsForm;

    const component = (
      <Formio
        form={form}
        options={formOptions}
        onChange={(change) => setOverrideFormData({...overrideFormData, ...change.data})}
      />
    );
    schedulerDispatch({
      type: 'demographicsFormComponent',
      demographicsFormComponent: component,
    });
  };

  // Fetch the demographics form when the bookingPage is updated via the dropdown
  useEffect(() => {
    const demographicsFormId = schedulerState.bookingPage?.demographicsFormId;
    if (!demographicsFormId) return;

    setDemographicsFormLoading(true);
    client
      .get(`/public/form/${demographicsFormId}`)
      .then((res) => {
        const {id} = res.data;
        minimizeFormComponents(res.data.form, ['personGroups', 'intendedBranchIds']);
        switchDemographicsFormComponent(res.data);
        setDemographicsFormLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
        setDemographicsFormLoading(false);
      });
  }, [schedulerState.bookingPage]);

  const client = FhirUtils.useAxiosClient();
  const thunkDispatch = useDispatch<ThunkDispatch>();
  const ability = React.useContext(AbilityContext);

  const appointments: Array<any> = Object.values(
    useSelector((state: RootState) => {
      return state[AppointmentModel.slice.name].byId;
    })
  );

  const alternateSeries = useAlternateSeries().alternateSeries;
  const newSeries = !previousAppointmentId && !previousDoseId && !patientId;
  const requiresDemographicsForm = !previousAppointmentId && !previousDoseId && !patientId;

  const appointment = appointments.find((a) => a.id === schedulerState.appointmentId) as any;
  const alternateSeriesProducts = alternateSeries
    .find((series) => series.id === schedulerState.alternateSeriesId)
    ?.seriesDoses?.map((sd) => sd.tradename?.conceptId);

  const patientsWithRequiredForms = !!appointment?.patients?.some(
    (p) =>
      filterRequiredFormTypesForConfig(p.requiredFormTypes, schedulerState.bookingPage, true)
        .length > 0
  );

  const theme = {
    blue: '#265ed6',
    danger: '#dc3545',
    boxShadowStyle: {
      boxShadow: '0 10px 25px rgba(50, 50, 93, 0.1)',
    },
    sectionSpacing: 60,
    borderRadius: 10,
  };
  const bookingPageConfig = {
    lang: 'en',
    theme,
    syncUrl,
    adminUser: true,
    includeStaging: false,
    bookingPageSlug: schedulerState.bookingPage?.bookingPageSlug?.en,
  };

  const getAppointment = async (id?) => {
    setLoadingAppointment(true);
    thunkDispatch(AppointmentModel.getOne(client, id ?? appointment?.id)).then(() => {
      setAppointmentId(id);
      setLoadingAppointment(false);
    });
  };

  const onSchedule = (id) => {
    setScheduling(false);
    setSchedulingLoading(false);
    message.success('Appointment Scheduled');
    props.onSchedule?.(id);
    getAppointment(id);
  };

  const onError = (errMsg) => {
    setSchedulingLoading(false);
    setErrorMessage(errMsg);
  };

  const onFormCompleted = () => {
    message.success('Form successfully submitted!');
    getAppointment();
  };

  const closeModal = () => {
    if (errorMessage) setErrorMessage(undefined);
    setVisible(false);
  };

  const onChangeAlternateSeriesId = (id) => {
    setAlternateSeriesId(id);
    setSelectedAppointmentType(undefined);
    setSelectedAppointment(undefined);
  };

  const resetState = () => {
    schedulerDispatch({type: 'reset'});
  };

  const resetAppointment = () => {
    schedulerDispatch({type: 'resetAppointment'});
  };

  useEffect(() => {
    if (!visible) resetState();
  }, [visible]);

  if (!schedulerState.scheduling && !patientsWithRequiredForms && appointment) {
    closeModal();
  }

  const filterProducts = schedulerState.isAlternate && alternateSeriesProducts;
  const passwordVerificationPass =
    schedulerState.bookingPage?.status === 'protected' ? schedulerState.passwordVerified : true;

  let contactFormOnSubmit;
  const contactForm = (
    <ContactForm
      previewConfig={bookingPageConfig}
      selectedAppointment={schedulerState.selectedAppointment}
      setSelectedAppointment={setSelectedAppointment}
      contactFormValues={schedulerState.contactFormValues}
      setContactFormValues={setContactFormValues}
      onSchedule={onSchedule}
      onError={onError}
      alternateSeriesId={
        schedulerState.isAlternate && schedulerState.alternateSeriesId
          ? schedulerState.alternateSeriesId
          : undefined
      }
      overrideFormData={overrideFormData}
      previousAppointmentId={previousAppointmentId}
      previousDoseId={previousDoseId}
      patientId={patientId}
      returnOnSubmit={(onSubmit) => (contactFormOnSubmit = onSubmit)}
    />
  );

  return (
    <Modal
      width={1200}
      title="Schedule Appointment"
      bodyStyle={{height: 600, overflow: 'scroll'}}
      visible={visible}
      footer={
        <Space>
          {!requiresDemographicsForm && errorMessage && (
            <Typography.Text type="danger">
              <div dangerouslySetInnerHTML={{__html: errorMessage || ''}} />
            </Typography.Text>
          )}
          {!requiresDemographicsForm && schedulerState.scheduling && (
            <Button
              type="primary"
              disabled={!schedulerState.selectedAppointment}
              loading={schedulerState.schedulingLoading}
              onClick={() => {
                contactFormOnSubmit();
                setSchedulingLoading(true);
                setErrorMessage(undefined);
              }}
            >
              Schedule Appointment
            </Button>
          )}
          <Button
            loading={schedulerState.loadingAppointment || schedulerState.loadingBookingPages}
            onClick={closeModal}
          >
            {schedulerState.scheduling ? 'Cancel' : 'Close'}
          </Button>
        </Space>
      }
      style={{top: 20, bottom: 80}}
      onCancel={closeModal}
      destroyOnClose={true}
      maskClosable={false}
    >
      <div>
        {schedulerState.scheduling && (
          <div style={{marginBottom: 25}}>
            <Row gutter={15}>
              <Col>
                <Form.Item label="Booking Page" style={{marginBottom: 5}}>
                  <BookingPageSelector
                    setLoading={setLoadingBookingPages}
                    value={schedulerState.bookingPage?.id}
                    onSelect={(bp) => {
                      setBookingPage(bp);
                      resetAppointment();
                    }}
                    includeFormId
                  />
                </Form.Item>
              </Col>
            </Row>
            {schedulerState.bookingPage &&
              !requiresDemographicsForm &&
              (schedulerState.demographicsFormLoading ? (
                <LoadingSpinner />
              ) : (
                schedulerState.demographicsFormComponent
              ))}
          </div>
        )}
        {schedulerState.bookingPage &&
          (requiresDemographicsForm || !schedulerState.demographicsFormLoading) && (
            <BookingPageConfigProvider
              previewConfig={bookingPageConfig}
              Spinner={LoadingSpinner}
              forceOverride
            >
              {schedulerState.scheduling && (
                <div>
                  {(!schedulerState.isAlternate ||
                    (schedulerState.isAlternate && schedulerState.alternateSeriesId)) &&
                    !schedulerState.appointmentCancellation &&
                    passwordVerificationPass && (
                      <div>
                        <AppointmentPicker
                          setSelectedAppointmentType={setSelectedAppointmentType}
                          selectedAppointmentType={schedulerState.selectedAppointmentType}
                          selectedAppointment={schedulerState.selectedAppointment}
                          setSelectedAppointment={setSelectedAppointment}
                          filterProducts={filterProducts}
                          previewConfig={{adminUser: true}}
                          previousAppointmentId={previousAppointmentId}
                          previousDoseId={previousDoseId}
                          defaultAge={defaultAge}
                          forceUseCurrentAppointment={!!previousAppointmentId}
                        />
                        {requiresDemographicsForm ? (
                          contactForm
                        ) : (
                          // The createAppointment request is created in the contactForm element.
                          // In order to be called, it must exist on the page still, so its left
                          // here but undisplayed. Request not pulled out for the sake of
                          // preventing code duplication.
                          <div style={{display: 'none'}}>{contactForm}</div>
                        )}
                      </div>
                    )}
                  {schedulerState.isAlternate && !schedulerState.alternateSeriesId && (
                    <p
                      style={{
                        color: 'grey',
                        textAlign: 'center',
                      }}
                    >
                      Select a single dose series product to continue
                    </p>
                  )}
                  {!passwordVerificationPass && (
                    <SchedulePasswordForm
                      setPasswordVerified={setPasswordVerified}
                      bookingPageId={schedulerState.bookingPage?.id}
                    />
                  )}
                </div>
              )}
              {!schedulerState.scheduling &&
                patientsWithRequiredForms &&
                appointment &&
                !schedulerState.appointmentCancellation && (
                  <>
                    <AppointmentSummary
                      appointment={appointment}
                      shortMode
                      formsMode
                      booked
                      hideActionButtons
                    />
                    <FormsContainer
                      // Workaround
                      setAppointmentCancellation={setAppointmentCancellation}
                      loadingAppointment={false}
                      appointment={appointment}
                      onFormCompleted={onFormCompleted}
                      containerStyle={{}}
                      hideSubtext
                    />
                  </>
                )}

              {schedulerState.appointmentCancellation && (
                <AppointmentCancelled
                  appointmentCancellation={schedulerState.appointmentCancellation}
                  simpleView={true}
                />
              )}
            </BookingPageConfigProvider>
          )}
        {(schedulerState.loadingAppointment || schedulerState.loadingBookingPages) && (
          <LoadingSpinner />
        )}
      </div>
    </Modal>
  );
};

export const CreateButton = () => {
  const [visible, setVisible] = useState<boolean>(false);
  const onClick = () => {
    setVisible(!visible);
  };
  return (
    <div>
      <Button type="primary" size="large" onClick={onClick}>
        <PlusOutlined />
        {'New Appointment'}
      </Button>
      <SchedulerModal visible={visible} setVisible={setVisible} />
    </div>
  );
};

export const useAlternateSeries = () => {
  const {syncUrl, config} = useUrlData();
  const client = FhirUtils.useAxiosClient();
  const [alternateSeries, setAlternateSeries] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const ability = React.useContext(AbilityContext);
  const [queryErrors, setQueryErrors] =
    useState<{
      recordsFound?: boolean;
      apiError?: string;
    }>();

  const handleLoad = async () => {
    setLoading(true);

    let alternateSeries: any[] = [];
    try {
      const response = await client.get(syncUrl + `/series/alternate`, {
        validateStatus: (status) => {
          return status >= 200 && status < 500;
        },
      });

      if (response.status === 200) {
        if (response.data.length <= 0) {
          setQueryErrors({recordsFound: false});
        } else {
          setQueryErrors({});
        }
        alternateSeries = response.data;
      } else {
        setQueryErrors({apiError: 'An error has occurred.'});
      }
    } catch (error) {
      setQueryErrors({apiError: error.message || 'An error has occurred.'});
    }

    setAlternateSeries(alternateSeries);

    setLoading(false);
  };

  useEffect(() => {
    ability.can('create', 'appointments', 'singleDose') && handleLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);

  return {
    alternateSeries: alternateSeries,
    loading,
    queryErrors,
  };
};
