"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_DiagnosticReport = exports.DiagnosticReportStatusKind = void 0;
var t = __importStar(require("io-ts"));
var RTTI_id_1 = require("../Scalar/RTTI_id");
var RTTI_Meta_1 = require("./RTTI_Meta");
var RTTI_uri_1 = require("../Scalar/RTTI_uri");
var RTTI_Element_1 = require("./RTTI_Element");
var RTTI_code_1 = require("../Scalar/RTTI_code");
var RTTI_Narrative_1 = require("./RTTI_Narrative");
var RTTI_ResourceList_1 = require("../Union/RTTI_ResourceList");
var RTTI_Extension_1 = require("./RTTI_Extension");
var RTTI_Identifier_1 = require("./RTTI_Identifier");
var RTTI_Reference_1 = require("./RTTI_Reference");
var RTTI_CodeableConcept_1 = require("./RTTI_CodeableConcept");
var RTTI_Period_1 = require("./RTTI_Period");
var RTTI_instant_1 = require("../Scalar/RTTI_instant");
var RTTI_DiagnosticReport_Media_1 = require("./RTTI_DiagnosticReport_Media");
var RTTI_Attachment_1 = require("./RTTI_Attachment");
var DiagnosticReportStatusKind;
(function (DiagnosticReportStatusKind) {
    DiagnosticReportStatusKind["_registered"] = "registered";
    DiagnosticReportStatusKind["_partial"] = "partial";
    DiagnosticReportStatusKind["_preliminary"] = "preliminary";
    DiagnosticReportStatusKind["_final"] = "final";
    DiagnosticReportStatusKind["_amended"] = "amended";
    DiagnosticReportStatusKind["_corrected"] = "corrected";
    DiagnosticReportStatusKind["_appended"] = "appended";
    DiagnosticReportStatusKind["_cancelled"] = "cancelled";
    DiagnosticReportStatusKind["_enteredInError"] = "entered-in-error";
    DiagnosticReportStatusKind["_unknown"] = "unknown";
})(DiagnosticReportStatusKind = exports.DiagnosticReportStatusKind || (exports.DiagnosticReportStatusKind = {}));
var EnumType_1 = require("../../EnumType");
exports.RTTI_DiagnosticReport = t.recursion('IDiagnosticReport', function () {
    return t.intersection([
        t.type({
            resourceType: t.literal('DiagnosticReport'),
            code: RTTI_CodeableConcept_1.RTTI_CodeableConcept
        }),
        t.partial({
            id: RTTI_id_1.RTTI_id,
            meta: RTTI_Meta_1.RTTI_Meta,
            implicitRules: RTTI_uri_1.RTTI_uri,
            _implicitRules: RTTI_Element_1.RTTI_Element,
            language: RTTI_code_1.RTTI_code,
            _language: RTTI_Element_1.RTTI_Element,
            text: RTTI_Narrative_1.RTTI_Narrative,
            contained: t.array(RTTI_ResourceList_1.RTTI_ResourceList),
            extension: t.array(RTTI_Extension_1.RTTI_Extension),
            modifierExtension: t.array(RTTI_Extension_1.RTTI_Extension),
            identifier: t.array(RTTI_Identifier_1.RTTI_Identifier),
            basedOn: t.array(RTTI_Reference_1.RTTI_Reference),
            status: EnumType_1.createEnumType(DiagnosticReportStatusKind, 'DiagnosticReportStatusKind'),
            _status: RTTI_Element_1.RTTI_Element,
            category: t.array(RTTI_CodeableConcept_1.RTTI_CodeableConcept),
            subject: RTTI_Reference_1.RTTI_Reference,
            encounter: RTTI_Reference_1.RTTI_Reference,
            effectiveDateTime: t.string,
            _effectiveDateTime: RTTI_Element_1.RTTI_Element,
            effectivePeriod: RTTI_Period_1.RTTI_Period,
            issued: RTTI_instant_1.RTTI_instant,
            _issued: RTTI_Element_1.RTTI_Element,
            performer: t.array(RTTI_Reference_1.RTTI_Reference),
            resultsInterpreter: t.array(RTTI_Reference_1.RTTI_Reference),
            specimen: t.array(RTTI_Reference_1.RTTI_Reference),
            result: t.array(RTTI_Reference_1.RTTI_Reference),
            imagingStudy: t.array(RTTI_Reference_1.RTTI_Reference),
            media: t.array(RTTI_DiagnosticReport_Media_1.RTTI_DiagnosticReport_Media),
            conclusion: t.string,
            _conclusion: RTTI_Element_1.RTTI_Element,
            conclusionCode: t.array(RTTI_CodeableConcept_1.RTTI_CodeableConcept),
            presentedForm: t.array(RTTI_Attachment_1.RTTI_Attachment)
        })
    ]);
});
