import {Ability, AbilityBuilder} from '@casl/ability';
import * as rules from './define-rules';
import {defineYKBookingAgentRules} from './roles/yk-booking-agent';
import {defineClinicAdminAllPatientsRules} from './roles/clinic-admin-all-patients';
import {defineClinicOwnerAllPatientsRules} from './roles/clinic-owner-all-patients';
import {defineConsoleUserManagerRules} from './roles/console-user-manager';
import {defineNSAdminRules} from './roles/ns-admin';
import {defineYKReportingRules} from './roles/yk-reporting';
import {defineYKAdminRules} from './roles/yk-admin';
import {defineYKCallCentreRules} from './roles/yk-call-centre';
import {defineYKMailQueueRules} from './roles/yk-mail-queue';
import {defineYKSuperAdminRules} from './roles/yk-super-admin';

export interface RoleDefinition {
  id: string;
  name: string;
}

const defineRulesFor = (roles) => {
  const builder = new AbilityBuilder(Ability);

  if (roles) {
    const rolesArray = Array.isArray(roles) ? roles : [roles];
    for (const role of rolesArray) {
      switch (role) {
        case 'admin':
          rules.defineSuperAdminRules(builder);
          break;
        case 'clinic-owner':
          rules.defineClinicOwnerRules(builder);
          break;
        case 'clinic-admin':
          rules.defineClinicAdminRules(builder);
          break;
        case 'clinic-owner-all-patients':
          defineClinicOwnerAllPatientsRules(builder);
          break;
        case 'clinic-admin-all-patients':
          defineClinicAdminAllPatientsRules(builder);
          break;
        case 'special-call-center-agent':
          rules.defineSpecialCallCenterAgentRules(builder);
          break;
        case 'call-center-agent':
          rules.defineCallCenterAgentRules(builder);
          break;
        case 'user-support':
          rules.defineUserSupportRules(builder);
          break;
        case 'hc-provider':
          rules.defineHcProviderRules(builder);
          break;
        case 'pei-admin':
          rules.definePEIAdminRules(builder);
          break;
        case 'connect-server-admin':
          rules.defineConnectServerAdminRules(builder);
          break;
        case 'ns-admin':
          defineNSAdminRules(builder);
          break;
        case 'console-user-manager':
          defineConsoleUserManagerRules(builder);
          break;
        case 'yk-admin':
          defineYKAdminRules(builder);
          break;
        case 'yk-call-centre':
          defineYKCallCentreRules(builder);
          break;
        case 'yk-mail-queue':
          defineYKMailQueueRules(builder);
          break;
        case 'yk-super-admin':
          defineYKSuperAdminRules(builder);
          break;
        case 'yk-booking-agent':
          defineYKBookingAgentRules(builder);
          break;
        case 'yk-reporting':
          defineYKReportingRules(builder);
          break;
      }
    }
  }
  return builder.rules;
};

export const updateAbility = (ability, roles) => {
  const rules = defineRulesFor(roles);

  ability.update(rules);

  return ability;
};
