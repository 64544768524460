import React from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '../../models';
import Cohort from '../../models/cohort';
import {CohortCustomFieldName, CohortUXModel} from './cohort-form-ux-model';
import {FormUX} from '../form-ux';
import {FormConfig} from '../base-entity/base-entity';
import {FHIRTableProps} from '../fhir-table';
import {OrganizationPickerFormItem} from '../organization/organization-picker';
import {CohortItemPage} from './item-page';
import {AbilityContext} from '../../services/roles/ability-context';
import {BaseEntityV2} from '../base-entity/base-entity';

export const CohortUX = () => {
  const globalOrg = useSelector((state: RootState) => state.ui.localOrg);
  const model = Cohort;
  const CanCreateCohort = () => {
    const ability = React.useContext(AbilityContext);
    return ability?.can('create', 'cohorts', 'cohort');
  };
  const CantUpdateCohort = () => {
    const ability = React.useContext(AbilityContext);
    return !ability?.can('update', 'cohorts', 'cohort');
  };
  const CantDeleteCohort = () => {
    const ability = React.useContext(AbilityContext);
    return !ability?.can('delete', 'cohorts', 'cohort');
  };
  const itemTitleField = 'name';

  const fixedFilters = globalOrg
    ? {
        _organizationId: globalOrg.id,
      }
    : undefined;

  const tableConfig: FHIRTableProps = {
    fhirResource: 'Group',
    label: 'Cohort',
    // basicSearchParams: ['name', 'given', 'family', 'email', 'phone'],
    fixedFilters,
    defaultPrimarySearchParam: 'name',
    mode: 'table',
    showCreateButton: CanCreateCohort,
    columns: [
      {
        title: 'Name',
        dataIndex: 'name',
        sorter: true,
      },
      {
        title: 'Description',
        dataIndex: 'description',
      },
      // {
      //   title: 'Number of Patients',
      //   render: (_, resource) => {
      //     return '?';
      //   },
      // },
      {
        title: 'Organization',
        dataIndex: 'organization.nameEn',
        render: (_, resource) => resource.organization?.name.en,
        sorter: true,
      },
      {
        title: 'Patients',
        dataIndex: 'patientCount',
      },
    ],
  };

  const formConfig: FormConfig = {
    defaultValues: {},
    FormItems: CohortForm,
  };

  const config = {
    slug: 'cohort',
    model,
    formConfig,
    itemTitleField,
    itemPage: CohortItemPage,
    searchPageTitle: 'Cohorts',
    hideEditButton: CantUpdateCohort,
    fhirResource: 'Group',
    tableConfig,
    modalCreateForm: true,
    modalEditForm: true,
    hideDeleteButton: CantDeleteCohort,
  };

  return <BaseEntityV2 {...config} />;
};

export const CohortForm = (props) => {
  return (
    <FormUX
      formUXModel={CohortUXModel(props.disableOrgPicker)}
      createMode={props.createMode}
      modal={props.modal}
      renderCustomField={(field) => {
        switch (field.name) {
          case CohortCustomFieldName.OrganizationId: {
            return (
              <OrganizationPickerFormItem
                label={field.label}
                name={field.name}
                setFieldValue={props.setFieldValue}
                validationRules={field.validationRules}
                disabled={field.disabled}
              />
            );
          }
          default: {
            throw new Error(`Unhandled custom field ${field.name} in
              renderCustomField method`);
          }
        }
      }}
    />
  );
};
