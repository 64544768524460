import {Button} from 'antd';
import {Field} from 'formik';
import {Select} from 'formik-antd';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState, ThunkDispatch} from '../../models';
import {useGlobalSettings} from '../../models/global-settings';
import LocationModel from '../../models/location';
import {FhirUtils} from '../../services/fhir';
import {FormUX} from '../form-ux';
import {AddressSelect} from '../scheduler/booking-form/address-select';
import {ClinicFormCustomFieldName, generateLocationFormUXModel} from './clinic-form-ux-model';

/**
 *
 *
 * @interface ILocationFormProps
 */
interface ILocationFormProps {
  /**
   * The name of the object in the parent which holds the location's fields
   *
   * @type {string}
   * @memberof ILocationFormProps
   */
  name: string;
  /**
   * The organizationId of the clinic attached to the parent which holds the location's fields
   *
   * @type {string}
   * @memberof ILocationFormProps
   */
  organizationId: string;
}

/* Component to render a form to edit a clinic flow location object */
export const LocationForm = (props: ILocationFormProps) => {
  /* The next section gets all the locations the user can select from */
  const thunkDispatch = useDispatch<ThunkDispatch>();
  const client = FhirUtils.useClient();
  const {organizationId} = props;
  const settings = useGlobalSettings();
  React.useEffect(() => {
    thunkDispatch(LocationModel.getAll(client, {organizationId: props.organizationId}));
  }, [organizationId]);
  /* Get the items from the store we will show to the user */
  const locations: Array<{
    id: string;
    name: string;
    streetNumber: string;
    streetName: string;
    streetDirection: string | null;
    city: string;
    province: string;
    country: string;
  }> = Object.values(
    useSelector((state: RootState) => {
      return state[LocationModel.slice.name].byId;
    })
  );

  return (
    <Field name={props.name}>
      {({field, form}) => {
        {
          /* If there is already a value for location then show
        the form where the user can edit its fields */
        }
        return field.value ? (
          <FormUX
            formUXModel={generateLocationFormUXModel(settings)}
            createMode={false}
            modal={false}
            namePrefix={`${props.name}.`}
            renderCustomField={(field) => {
              switch (field.name) {
                case ClinicFormCustomFieldName.GISLocation: {
                  return <GISLocationField form={form} />;
                }
                default: {
                  throw new Error(`Unhandled custom field ${field.name} in
                    renderCustomField method`);
                }
              }
            }}
          />
        ) : (
          <>
            {/* Otherwise show 2 buttons, one to allow the user to create
            a new one or to select from a list of existing locations
            sent by the user. */}
            <Button
              onClick={() => {
                form.setFieldValue(props.name, {});
              }}
            >
              Add New Location
            </Button>

            <div>Select an existing location</div>
            <Select
              dropdownMatchSelectWidth={false}
              name={props.name}
              showSearch
              placeholder="Add existing location"
              optionFilterProp="children"
              onChange={(index) => {
                form.setFieldValue(props.name, locations[index]);
              }}
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input?.toLowerCase()) >= 0
              }
            >
              {locations.map((item, index) => (
                <Select.Option value={index} key={index}>
                  {item.name} - {item.streetNumber} {item.streetName}
                  {item.streetDirection ? ` ${item.streetDirection}` : ''}, {item.city},{' '}
                  {item.province}, {item.country}
                </Select.Option>
              ))}
            </Select>
          </>
        );
      }}
    </Field>
  );
};

export const GISLocationField = (props) => {
  const {form} = props;
  const onChange = (_, option: any) => {
    form.setFieldValue('location.gisLocationString', option?.data?.civic_address_as_string || '');
    form.setFieldValue('location.gisLocationId', option?.data?.civicnumberid || '');
    form.setFieldValue('location.gisX', Math.round(option?.data?.esri_point?.x || ''));
    form.setFieldValue('location.gisY', Math.round(option?.data?.esri_point?.y || ''));
  };

  const value = {
    label: form.values.location.gisLocationString,
    value: form.values.location.gisLocationId,
    data: {
      civic_address_as_string: form.values.location.gisLocationString,
      civicnumberid: form.values.location.gisLocationId,
      esri_point: {
        x: form.values.location.gisX,
        y: form.values.location.gisY,
      },
    },
  };

  return (
    <div>
      <label>Location Lookup</label>
      <AddressSelect onChange={onChange} value={value} />
    </div>
  );
};
