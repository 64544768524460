"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_MedicinalProduct = void 0;
var t = __importStar(require("io-ts"));
var RTTI_id_1 = require("../Scalar/RTTI_id");
var RTTI_Meta_1 = require("./RTTI_Meta");
var RTTI_uri_1 = require("../Scalar/RTTI_uri");
var RTTI_Element_1 = require("./RTTI_Element");
var RTTI_code_1 = require("../Scalar/RTTI_code");
var RTTI_Narrative_1 = require("./RTTI_Narrative");
var RTTI_ResourceList_1 = require("../Union/RTTI_ResourceList");
var RTTI_Extension_1 = require("./RTTI_Extension");
var RTTI_Identifier_1 = require("./RTTI_Identifier");
var RTTI_CodeableConcept_1 = require("./RTTI_CodeableConcept");
var RTTI_Coding_1 = require("./RTTI_Coding");
var RTTI_MarketingStatus_1 = require("./RTTI_MarketingStatus");
var RTTI_Reference_1 = require("./RTTI_Reference");
var RTTI_MedicinalProduct_Name_1 = require("./RTTI_MedicinalProduct_Name");
var RTTI_MedicinalProduct_ManufacturingBusinessOperation_1 = require("./RTTI_MedicinalProduct_ManufacturingBusinessOperation");
var RTTI_MedicinalProduct_SpecialDesignation_1 = require("./RTTI_MedicinalProduct_SpecialDesignation");
exports.RTTI_MedicinalProduct = t.recursion('IMedicinalProduct', function () {
    return t.intersection([
        t.type({
            resourceType: t.literal('MedicinalProduct'),
            name: t.array(RTTI_MedicinalProduct_Name_1.RTTI_MedicinalProduct_Name)
        }),
        t.partial({
            id: RTTI_id_1.RTTI_id,
            meta: RTTI_Meta_1.RTTI_Meta,
            implicitRules: RTTI_uri_1.RTTI_uri,
            _implicitRules: RTTI_Element_1.RTTI_Element,
            language: RTTI_code_1.RTTI_code,
            _language: RTTI_Element_1.RTTI_Element,
            text: RTTI_Narrative_1.RTTI_Narrative,
            contained: t.array(RTTI_ResourceList_1.RTTI_ResourceList),
            extension: t.array(RTTI_Extension_1.RTTI_Extension),
            modifierExtension: t.array(RTTI_Extension_1.RTTI_Extension),
            identifier: t.array(RTTI_Identifier_1.RTTI_Identifier),
            type: RTTI_CodeableConcept_1.RTTI_CodeableConcept,
            domain: RTTI_Coding_1.RTTI_Coding,
            combinedPharmaceuticalDoseForm: RTTI_CodeableConcept_1.RTTI_CodeableConcept,
            legalStatusOfSupply: RTTI_CodeableConcept_1.RTTI_CodeableConcept,
            additionalMonitoringIndicator: RTTI_CodeableConcept_1.RTTI_CodeableConcept,
            specialMeasures: t.array(t.string),
            _specialMeasures: t.array(RTTI_Element_1.RTTI_Element),
            paediatricUseIndicator: RTTI_CodeableConcept_1.RTTI_CodeableConcept,
            productClassification: t.array(RTTI_CodeableConcept_1.RTTI_CodeableConcept),
            marketingStatus: t.array(RTTI_MarketingStatus_1.RTTI_MarketingStatus),
            pharmaceuticalProduct: t.array(RTTI_Reference_1.RTTI_Reference),
            packagedMedicinalProduct: t.array(RTTI_Reference_1.RTTI_Reference),
            attachedDocument: t.array(RTTI_Reference_1.RTTI_Reference),
            masterFile: t.array(RTTI_Reference_1.RTTI_Reference),
            contact: t.array(RTTI_Reference_1.RTTI_Reference),
            clinicalTrial: t.array(RTTI_Reference_1.RTTI_Reference),
            crossReference: t.array(RTTI_Identifier_1.RTTI_Identifier),
            manufacturingBusinessOperation: t.array(RTTI_MedicinalProduct_ManufacturingBusinessOperation_1.RTTI_MedicinalProduct_ManufacturingBusinessOperation),
            specialDesignation: t.array(RTTI_MedicinalProduct_SpecialDesignation_1.RTTI_MedicinalProduct_SpecialDesignation)
        })
    ]);
});
