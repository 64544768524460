"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_Task = exports.TaskIntentKind = exports.TaskStatusKind = void 0;
var t = __importStar(require("io-ts"));
var RTTI_id_1 = require("../Scalar/RTTI_id");
var RTTI_Meta_1 = require("./RTTI_Meta");
var RTTI_uri_1 = require("../Scalar/RTTI_uri");
var RTTI_Element_1 = require("./RTTI_Element");
var RTTI_code_1 = require("../Scalar/RTTI_code");
var RTTI_Narrative_1 = require("./RTTI_Narrative");
var RTTI_ResourceList_1 = require("../Union/RTTI_ResourceList");
var RTTI_Extension_1 = require("./RTTI_Extension");
var RTTI_Identifier_1 = require("./RTTI_Identifier");
var RTTI_canonical_1 = require("../Scalar/RTTI_canonical");
var RTTI_Reference_1 = require("./RTTI_Reference");
var RTTI_CodeableConcept_1 = require("./RTTI_CodeableConcept");
var RTTI_Period_1 = require("./RTTI_Period");
var RTTI_dateTime_1 = require("../Scalar/RTTI_dateTime");
var RTTI_Annotation_1 = require("./RTTI_Annotation");
var RTTI_Task_Restriction_1 = require("./RTTI_Task_Restriction");
var RTTI_Task_Input_1 = require("./RTTI_Task_Input");
var RTTI_Task_Output_1 = require("./RTTI_Task_Output");
var TaskStatusKind;
(function (TaskStatusKind) {
    TaskStatusKind["_draft"] = "draft";
    TaskStatusKind["_requested"] = "requested";
    TaskStatusKind["_received"] = "received";
    TaskStatusKind["_accepted"] = "accepted";
    TaskStatusKind["_rejected"] = "rejected";
    TaskStatusKind["_ready"] = "ready";
    TaskStatusKind["_cancelled"] = "cancelled";
    TaskStatusKind["_inProgress"] = "in-progress";
    TaskStatusKind["_onHold"] = "on-hold";
    TaskStatusKind["_failed"] = "failed";
    TaskStatusKind["_completed"] = "completed";
    TaskStatusKind["_enteredInError"] = "entered-in-error";
})(TaskStatusKind = exports.TaskStatusKind || (exports.TaskStatusKind = {}));
var TaskIntentKind;
(function (TaskIntentKind) {
    TaskIntentKind["_unknown"] = "unknown";
    TaskIntentKind["_proposal"] = "proposal";
    TaskIntentKind["_plan"] = "plan";
    TaskIntentKind["_order"] = "order";
    TaskIntentKind["_originalOrder"] = "original-order";
    TaskIntentKind["_reflexOrder"] = "reflex-order";
    TaskIntentKind["_fillerOrder"] = "filler-order";
    TaskIntentKind["_instanceOrder"] = "instance-order";
    TaskIntentKind["_option"] = "option";
})(TaskIntentKind = exports.TaskIntentKind || (exports.TaskIntentKind = {}));
var EnumType_1 = require("../../EnumType");
exports.RTTI_Task = t.recursion('ITask', function () {
    return t.intersection([
        t.type({
            resourceType: t.literal('Task')
        }),
        t.partial({
            id: RTTI_id_1.RTTI_id,
            meta: RTTI_Meta_1.RTTI_Meta,
            implicitRules: RTTI_uri_1.RTTI_uri,
            _implicitRules: RTTI_Element_1.RTTI_Element,
            language: RTTI_code_1.RTTI_code,
            _language: RTTI_Element_1.RTTI_Element,
            text: RTTI_Narrative_1.RTTI_Narrative,
            contained: t.array(RTTI_ResourceList_1.RTTI_ResourceList),
            extension: t.array(RTTI_Extension_1.RTTI_Extension),
            modifierExtension: t.array(RTTI_Extension_1.RTTI_Extension),
            identifier: t.array(RTTI_Identifier_1.RTTI_Identifier),
            instantiatesCanonical: RTTI_canonical_1.RTTI_canonical,
            instantiatesUri: RTTI_uri_1.RTTI_uri,
            _instantiatesUri: RTTI_Element_1.RTTI_Element,
            basedOn: t.array(RTTI_Reference_1.RTTI_Reference),
            groupIdentifier: RTTI_Identifier_1.RTTI_Identifier,
            partOf: t.array(RTTI_Reference_1.RTTI_Reference),
            status: EnumType_1.createEnumType(TaskStatusKind, 'TaskStatusKind'),
            _status: RTTI_Element_1.RTTI_Element,
            statusReason: RTTI_CodeableConcept_1.RTTI_CodeableConcept,
            businessStatus: RTTI_CodeableConcept_1.RTTI_CodeableConcept,
            intent: EnumType_1.createEnumType(TaskIntentKind, 'TaskIntentKind'),
            _intent: RTTI_Element_1.RTTI_Element,
            priority: RTTI_code_1.RTTI_code,
            _priority: RTTI_Element_1.RTTI_Element,
            code: RTTI_CodeableConcept_1.RTTI_CodeableConcept,
            description: t.string,
            _description: RTTI_Element_1.RTTI_Element,
            focus: RTTI_Reference_1.RTTI_Reference,
            for: RTTI_Reference_1.RTTI_Reference,
            encounter: RTTI_Reference_1.RTTI_Reference,
            executionPeriod: RTTI_Period_1.RTTI_Period,
            authoredOn: RTTI_dateTime_1.RTTI_dateTime,
            _authoredOn: RTTI_Element_1.RTTI_Element,
            lastModified: RTTI_dateTime_1.RTTI_dateTime,
            _lastModified: RTTI_Element_1.RTTI_Element,
            requester: RTTI_Reference_1.RTTI_Reference,
            performerType: t.array(RTTI_CodeableConcept_1.RTTI_CodeableConcept),
            owner: RTTI_Reference_1.RTTI_Reference,
            location: RTTI_Reference_1.RTTI_Reference,
            reasonCode: RTTI_CodeableConcept_1.RTTI_CodeableConcept,
            reasonReference: RTTI_Reference_1.RTTI_Reference,
            insurance: t.array(RTTI_Reference_1.RTTI_Reference),
            note: t.array(RTTI_Annotation_1.RTTI_Annotation),
            relevantHistory: t.array(RTTI_Reference_1.RTTI_Reference),
            restriction: RTTI_Task_Restriction_1.RTTI_Task_Restriction,
            input: t.array(RTTI_Task_Input_1.RTTI_Task_Input),
            output: t.array(RTTI_Task_Output_1.RTTI_Task_Output)
        })
    ]);
});
