import {Card, Typography} from 'antd';
import qs from 'querystring';
import React from 'react';
import {useLocation} from 'react-router';
import {FHIRTable} from '../components/fhir-table';

const {Title} = Typography;

export const SearchView = () => {
  const location = useLocation();

  const queryString =
    location.search.charAt(0) === '?' ? location.search.substring(1) : location.search;
  const urlQuery = qs.parse(queryString);

  console.log(urlQuery);

  return (
    <Card>
      <Title level={1}>Search</Title>
      <FHIRTable
        columns={[]}
        fhirResource={'Patient'}
        defaultQuery={urlQuery.searchTerm as string}
      />
    </Card>
  );
};
