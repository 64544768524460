"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_Timing_Repeat = exports.Timing_RepeatPeriodUnitKind = exports.Timing_RepeatDurationUnitKind = exports.Timing_RepeatWhenKind = void 0;
var t = __importStar(require("io-ts"));
var RTTI_Extension_1 = require("./RTTI_Extension");
var RTTI_Duration_1 = require("./RTTI_Duration");
var RTTI_Range_1 = require("./RTTI_Range");
var RTTI_Period_1 = require("./RTTI_Period");
var RTTI_positiveInt_1 = require("../Scalar/RTTI_positiveInt");
var RTTI_Element_1 = require("./RTTI_Element");
var RTTI_decimal_1 = require("../Scalar/RTTI_decimal");
var RTTI_code_1 = require("../Scalar/RTTI_code");
var RTTI_time_1 = require("../Scalar/RTTI_time");
var RTTI_unsignedInt_1 = require("../Scalar/RTTI_unsignedInt");
var Timing_RepeatWhenKind;
(function (Timing_RepeatWhenKind) {
    Timing_RepeatWhenKind["_morn"] = "MORN";
    Timing_RepeatWhenKind["_mornEarly"] = "MORN.early";
    Timing_RepeatWhenKind["_mornLate"] = "MORN.late";
    Timing_RepeatWhenKind["_noon"] = "NOON";
    Timing_RepeatWhenKind["_aft"] = "AFT";
    Timing_RepeatWhenKind["_aftEarly"] = "AFT.early";
    Timing_RepeatWhenKind["_aftLate"] = "AFT.late";
    Timing_RepeatWhenKind["_eve"] = "EVE";
    Timing_RepeatWhenKind["_eveEarly"] = "EVE.early";
    Timing_RepeatWhenKind["_eveLate"] = "EVE.late";
    Timing_RepeatWhenKind["_night"] = "NIGHT";
    Timing_RepeatWhenKind["_phs"] = "PHS";
    Timing_RepeatWhenKind["_hs"] = "HS";
    Timing_RepeatWhenKind["_wake"] = "WAKE";
    Timing_RepeatWhenKind["_c"] = "C";
    Timing_RepeatWhenKind["_cm"] = "CM";
    Timing_RepeatWhenKind["_cd"] = "CD";
    Timing_RepeatWhenKind["_cv"] = "CV";
    Timing_RepeatWhenKind["_ac"] = "AC";
    Timing_RepeatWhenKind["_acm"] = "ACM";
    Timing_RepeatWhenKind["_acd"] = "ACD";
    Timing_RepeatWhenKind["_acv"] = "ACV";
    Timing_RepeatWhenKind["_pc"] = "PC";
    Timing_RepeatWhenKind["_pcm"] = "PCM";
    Timing_RepeatWhenKind["_pcd"] = "PCD";
    Timing_RepeatWhenKind["_pcv"] = "PCV";
})(Timing_RepeatWhenKind = exports.Timing_RepeatWhenKind || (exports.Timing_RepeatWhenKind = {}));
var Timing_RepeatDurationUnitKind;
(function (Timing_RepeatDurationUnitKind) {
    Timing_RepeatDurationUnitKind["_s"] = "s";
    Timing_RepeatDurationUnitKind["_min"] = "min";
    Timing_RepeatDurationUnitKind["_h"] = "h";
    Timing_RepeatDurationUnitKind["_d"] = "d";
    Timing_RepeatDurationUnitKind["_wk"] = "wk";
    Timing_RepeatDurationUnitKind["_mo"] = "mo";
    Timing_RepeatDurationUnitKind["_a"] = "a";
})(Timing_RepeatDurationUnitKind = exports.Timing_RepeatDurationUnitKind || (exports.Timing_RepeatDurationUnitKind = {}));
var Timing_RepeatPeriodUnitKind;
(function (Timing_RepeatPeriodUnitKind) {
    Timing_RepeatPeriodUnitKind["_s"] = "s";
    Timing_RepeatPeriodUnitKind["_min"] = "min";
    Timing_RepeatPeriodUnitKind["_h"] = "h";
    Timing_RepeatPeriodUnitKind["_d"] = "d";
    Timing_RepeatPeriodUnitKind["_wk"] = "wk";
    Timing_RepeatPeriodUnitKind["_mo"] = "mo";
    Timing_RepeatPeriodUnitKind["_a"] = "a";
})(Timing_RepeatPeriodUnitKind = exports.Timing_RepeatPeriodUnitKind || (exports.Timing_RepeatPeriodUnitKind = {}));
var EnumType_1 = require("../../EnumType");
exports.RTTI_Timing_Repeat = t.recursion('ITiming_Repeat', function () {
    return t.partial({
        id: t.string,
        extension: t.array(RTTI_Extension_1.RTTI_Extension),
        modifierExtension: t.array(RTTI_Extension_1.RTTI_Extension),
        boundsDuration: RTTI_Duration_1.RTTI_Duration,
        boundsRange: RTTI_Range_1.RTTI_Range,
        boundsPeriod: RTTI_Period_1.RTTI_Period,
        count: RTTI_positiveInt_1.RTTI_positiveInt,
        _count: RTTI_Element_1.RTTI_Element,
        countMax: RTTI_positiveInt_1.RTTI_positiveInt,
        _countMax: RTTI_Element_1.RTTI_Element,
        duration: RTTI_decimal_1.RTTI_decimal,
        _duration: RTTI_Element_1.RTTI_Element,
        durationMax: RTTI_decimal_1.RTTI_decimal,
        _durationMax: RTTI_Element_1.RTTI_Element,
        durationUnit: EnumType_1.createEnumType(Timing_RepeatDurationUnitKind, 'Timing_RepeatDurationUnitKind'),
        _durationUnit: RTTI_Element_1.RTTI_Element,
        frequency: RTTI_positiveInt_1.RTTI_positiveInt,
        _frequency: RTTI_Element_1.RTTI_Element,
        frequencyMax: RTTI_positiveInt_1.RTTI_positiveInt,
        _frequencyMax: RTTI_Element_1.RTTI_Element,
        period: RTTI_decimal_1.RTTI_decimal,
        _period: RTTI_Element_1.RTTI_Element,
        periodMax: RTTI_decimal_1.RTTI_decimal,
        _periodMax: RTTI_Element_1.RTTI_Element,
        periodUnit: EnumType_1.createEnumType(Timing_RepeatPeriodUnitKind, 'Timing_RepeatPeriodUnitKind'),
        _periodUnit: RTTI_Element_1.RTTI_Element,
        dayOfWeek: t.array(RTTI_code_1.RTTI_code),
        _dayOfWeek: t.array(RTTI_Element_1.RTTI_Element),
        timeOfDay: t.array(RTTI_time_1.RTTI_time),
        _timeOfDay: t.array(RTTI_Element_1.RTTI_Element),
        when: t.array(EnumType_1.createEnumType(Timing_RepeatWhenKind, 'Timing_RepeatWhenKind')),
        _when: t.array(RTTI_Element_1.RTTI_Element),
        offset: RTTI_unsignedInt_1.RTTI_unsignedInt,
        _offset: RTTI_Element_1.RTTI_Element
    });
});
