/**
 * The parameters when dealing with the clinic resource
 *
 * @export
 * @interface ClinicUrlParams
 */
export interface BookingPageUrlParams {
  /**
   * The url parameter that has the currently active child slug/tab
   *
   * @type {ClinicChildSlug}
   * @memberof UrlParams
   */
  childSlug: BookingPageChildSlug;
  /**
   * The id of the clinic currently being viewed
   *
   * @type {string}
   * @memberof UrlParams
   */
  bookingpageId: string;
  /**
   * The current action the user wants to do on the resource.
   * Possible values are:
   * undefined - View all the resources of this type
   *
   * @type {string}
   * @memberof ClinicUrlParams
   */
  action?: string;
}

/* The slugs for each of the clinic child resources */
export enum BookingPageChildSlug {
  Invitations = 'invitations',
  Config = 'config',
  AppointmentTypes = 'appointmenttypes',
}

/**
 * Parses the URL and returns the current parameter. Mainly meant to be
 * used outside of React components since we can't use hooks. The URL looks like
 * /clinic/:clinicId/:childSlug/:action
 *
 * @export
 * @returns {{
 *   clinicId: string;
 *   childSlug: ClinicChildSlug;
 * }}
 */
export function getBookingPageUrlComponents(): BookingPageUrlParams {
  const urlComponents = window.location.pathname.split('/');

  return {
    bookingpageId: urlComponents[2],
    childSlug: urlComponents[3] as BookingPageChildSlug,
    action: urlComponents[4],
  };
}

/**
 * Use to navigate to the edit page of one of the child entities
 *
 * @export
 * @param {ClinicChildSlug} childSlug The slug of the child
 * @param {string} itemId The id of the entity the user wants to edit
 * @returns {void}
 */
export function navigateToChildEntityEditPage(
  navigate,
  childSlug: BookingPageChildSlug,
  itemId: string
): void {
  const urlComponents = getBookingPageUrlComponents();

  return navigate(`/bookingpages/${urlComponents.bookingpageId}/${childSlug}/${itemId}`);
}
