import {Modal, Switch} from 'antd';
import React from 'react';
import {useEnvInfo} from '../../services/environment';
import {AppointmentPicker} from '../scheduler/booking-form/appointment-picker';
import {Appointment, AppointmentType} from '../scheduler/types';
import {BookingPageConfigProvider} from '../scheduler/util';

export const BookingPagePreview = (props) => {
  const {bookingPage, setVisible} = props;
  const [selectedAppointmentType, setSelectedAppointmentType] =
    React.useState<AppointmentType | undefined>();
  const [includeStaging, setIncludeStaging] = React.useState(false);

  const [selectedAppointment, setSelectedAppointment] = React.useState<Appointment | undefined>();

  const theme = {
    blue: bookingPage?.primaryColor || '#265ed6',
    danger: '#dc3545',
    boxShadowStyle: {
      boxShadow: '0 10px 25px rgba(50, 50, 93, 0.1)',
    },
    sectionSpacing: 60,
    borderRadius: 10,
  };
  const previewConfig = {
    lang: 'en',
    theme,
    syncUrl: useEnvInfo().syncUrl,
    bookingPageSlug: bookingPage?.bookingPageSlugEn,
    preview: true,
    includeStaging,
    adminUser: true,
  };

  return (
    <Modal
      width="90%"
      title="Availability Preview"
      bodyStyle={{height: 560, overflow: 'scroll'}}
      {...props}
      onCancel={() => setVisible(false)}
      okText="Done"
      onOk={() => setVisible(false)}
      style={{top: 20, bottom: 80}}
      cancelButtonProps={{style: {display: 'none'}}}
      destroyOnClose
    >
      <div style={{marginBottom: 10}}>
        <span style={{marginRight: 5}}>Show Staging:</span>
        <Switch
          checked={includeStaging}
          onChange={(checked) => {
            setSelectedAppointmentType(undefined);
            setIncludeStaging(checked);
          }}
        />
      </div>
      <BookingPageConfigProvider previewConfig={previewConfig}>
        <AppointmentPicker
          setSelectedAppointmentType={setSelectedAppointmentType}
          selectedAppointmentType={selectedAppointmentType}
          selectedAppointment={selectedAppointment}
          setSelectedAppointment={setSelectedAppointment}
          previewConfig={previewConfig}
        />
      </BookingPageConfigProvider>
    </Modal>
  );
};
