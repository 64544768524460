import {Typography} from 'antd';
import {FormikProps} from 'formik';
import _ from 'lodash';
import React, {ReactElement} from 'react';
import {Route, Routes, useLocation, useResolvedPath} from 'react-router';
import {FhirProvider} from '../../services/fhir';
import {AbilityProvider} from '../../services/roles/ability-context';
import {FHIRTableProps} from '../fhir-table';
import {InactivityLogout} from '../inactivity-logout/inactivity-logout-ux';
import {AppLayout} from '../layouts/app';
import {ItemPage} from './base-entity-item';
import {SearchPage} from './base-entity-search';
import {BaseItemForm} from './item-form';

export interface BaseEntityUX {
  routes: any;
  baseItemFormProps?: any;
  slug: string;
  name: string;
  ItemForm?: any;
  ItemPage?: any;
  SearchPage: any;
  tableConfig?: any;
}
export interface BaseEntityUXProps {
  searchPage?: React.ReactNode;
  ItemForm?: (props) => JSX.Element;
  slug: string; //ex. organization
  slugs?: string; //ex. organizations
  name?: string; //ex. Organization
  useGlobalOrgFilter?: boolean;
  fhirResource?: string;
  searchPageTitle?: string; //ex. Employees
  searchPageDescription?: string;
  model: any;
  itemTitleField: ((item: any) => string) | string;
  itemSubtitleField?: ((item: any) => string) | string;
  queryString?: string;
  headerTags?: (item: any) => ReactElement<any> | ReactElement<any>[];
  stickyHeader?: boolean;
  formConfig?: FormConfig;
  createFormConfig?: FormConfig;
  itemPage?: any;
  itemPagePlugin?: any;
  editOnItemPage?: boolean;
  tableConfig?: Partial<FHIRTableProps>;
  modalCreateForm?: boolean;
  showOverride?: boolean;
  modalEditForm?: boolean;
  modalEditWidth?: number;
  hideEditButton?: boolean | ((item) => boolean);
  hideDeleteButton?: boolean | ((item) => boolean);
  hideSaveButton?: boolean;
  TableFooter?: () => JSX.Element;
  ItemPageButtons?: (item) => JSX.Element;
  showSearchTitle?: boolean;
  createButtonTitle?: string;
  isShield?: boolean;
  /**
   * Method called after an entity is created on the server. This overrides
   * the normal behaviour after an entity creation.
   *
   * @memberof BaseEntityUXProps
   */
  onCreationSuccess?: (resource: any) => void;
  /**
   * Method called to get the id of the current entity. Use this if the id
   * of the entity cannot be obtained from the URL param using the slug.
   *
   * @memberof BaseEntityUXProps
   */
  getCurrentItemId?: () => string;
  /**
   * Whether the back button at the top of the ItemPage should be shown.
   * Not shown if the value is false.
   * Shown if the value is undefined or true.
   *
   * @type {boolean}
   * @memberof BaseEntityUXProps
   */
  showBackButton?: boolean;
  /**
   * Validate method that is passed to the Formik component in the ItemForm
   * component.
   *
   * @memberof BaseEntityUXProps
   */
  validate?: (values: any) => {
    [fieldName: string]: string;
  };
  /**
   * Path to navigate when an item has been deleted
   *
   * @memberof BaseEntityUXProps
   */
  getDeleteSuccessPath?: () => string;
  /**
   * Whether to show the title bar at the top of the item page
   *
   * @type {boolean}
   * @memberof BaseEntityUXProps
   */
  showItemTitleBar?: boolean;
  titleBarEntityLabel?: string;
  titleBarFooter?: (item, settings?) => React.ReactNode;
  /**
   * Pop up modal to get inputs for AdminNote when a user deletes item
   *
   * @memberof BaseEntityUXProps
   */
  showNoteModalForDelete?: boolean;
  /**
   * Whether or not to use tabs in form ux
   *
   * @memberof BaseEntityUXProps
   */
  tabs?: boolean;
  forcePage?: 'item' | 'search' | 'form';
  disableWrapper?: boolean;
}

export interface FormConfig {
  defaultValues?: {};
  toForm?: (fhir: any) => any;
  toFhir?: (formData: any, original?: any) => any;
  itemSelector?: (config: any) => (state) => any;
  FormItems: (
    props: {
      createMode: boolean;
      editMode: boolean;
      item: any;
      modal: boolean;
      tabs: boolean;
    } & FormikProps<any>
  ) => ReactElement;
  preSubmit?: (values, actions, defaultOnSubmit, entityContext) => void;
  onSaveActionResolve?: (
    config: {
      createMode: boolean;
      navigate;
      slugs;
      submittedItem;
    },
    defaultSaveActionResolve: (res) => void
  ) => (res) => void;
}

export const BaseEntityV2 = (props: BaseEntityUXProps) => {
  const {
    ItemForm,
    slug,
    model,
    itemTitleField,
    itemSubtitleField,
    formConfig,
    headerTags,
    fhirResource,
    tableConfig,
    modalEditForm,
    hideEditButton,
    hideSaveButton,
    onCreationSuccess,
    validate,
    modalEditWidth,
    showOverride,
    queryString,
    tabs,
    forcePage,
    disableWrapper,
  } = props;

  // Default value for slugs is the plural of the slug
  const slugs = props.slugs || `${slug}s`;
  // Default value for name is the capitalized slug
  const name = props.name || slug.charAt(0).toUpperCase() + slug.slice(1);

  const pluralName = `${name}s`;

  // idPropName is the slug + 'Id' - this is the wildcard id in the router path
  // const idPropName = `${slug}Id`;

  let location = useLocation();
  let resolvedPath = useResolvedPath(location.pathname);

  console.log('Location', location);
  console.log('resolvedPath', resolvedPath);

  const getItemTitles = (item) => {
    const itemId = item?.id;
    const title =
      itemId === undefined
        ? `New ${tableConfig ? tableConfig.label : fhirResource}`
        : item && itemTitleField
        ? typeof itemTitleField === 'string'
          ? _.get(item, itemTitleField)
          : itemTitleField(item)
        : '';

    const subtitle =
      itemId === 'new'
        ? 'New'
        : item && itemSubtitleField
        ? typeof itemSubtitleField === 'string'
          ? _.get(item, itemSubtitleField)
          : itemSubtitleField(item)
        : '';

    const tags = headerTags && item ? headerTags(item) : undefined;

    return {title, subtitle, tags};
  };

  let baseItemFormProps: any = {
    ...props,
    slug,
    slugs,
    pluralName,
    hideSaveButton,
    model,
    modalEditWidth,
    onCreationSuccess,
    validate,
    formConfig,
    tableConfig,
    fhirResource,
    modalEditForm,
    showOverride,
    queryString,
    getTitleInfo: (item) => getItemTitles(item),
    hideEditButton,
    tabs,
  };

  const InternalItemForm: (props) => JSX.Element =
    ItemForm ??
    ((props: {
      itemId?: string;
      modal?: boolean;
      modalVisible?;
      setModalVisible?;
      editMode?;
      setEditMode?;
    }) => <BaseItemForm {...baseItemFormProps} {...props} />);

  baseItemFormProps.ItemForm = InternalItemForm;

  let pageKey;

  if (forcePage) {
    pageKey = forcePage;

    let page;
    switch (pageKey) {
      case 'form':
        page = <InternalItemForm {...baseItemFormProps} />;
        break;
      case 'item':
        page = <ItemPage {...baseItemFormProps} />;
        break;

      default:
        page = <SearchPage {...baseItemFormProps} />;
        break;
    }

    return disableWrapper ? (
      page
    ) : (
      <FhirProvider>
        <AbilityProvider path={`/${slugs}/*`}>
          <InactivityLogout />
          <AppLayout>{page}</AppLayout>
        </AbilityProvider>
      </FhirProvider>
    );
  }

  return (
    <FhirProvider>
      <AbilityProvider path={`/${slugs}/*`}>
        <InactivityLogout />
        <AppLayout>
          <Routes>
            <Route index element={<SearchPage {...baseItemFormProps} />} />
            <Route path=":id" element={<ItemPage {...baseItemFormProps} />} />
            <Route path=":id/:tab" element={<ItemPage {...baseItemFormProps} />} />
            <Route path=":tab" element={<ItemPage {...baseItemFormProps} />} />
            <Route path=":action" element={<ItemPage {...baseItemFormProps} />} />
          </Routes>
        </AppLayout>
      </AbilityProvider>
    </FhirProvider>
  );

  // LKG
  // const showSearchPage = useMatch(`/${slugs}`);
  // const showItemPage = useMatch(`/${slugs}/:id`);
  // const showItemPageWithTabs = useMatch(`/${slugs}/:id/:tab`);
  // const showItemPageWithAction = useMatch(`/${slugs}/:id/:tab/:action`);
};

// A custom hook that builds on useLocation to parse
// the query string for you.
export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
