"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_date = exports.RTTI_dateType = void 0;
var io_ts_1 = require("io-ts");
var RTTI_dateType = (function (_super) {
    __extends(RTTI_dateType, _super);
    function RTTI_dateType() {
        var _this = _super.call(this, 'RTTI_dateType', function (m) {
            return typeof m === 'string' && RTTI_dateType.regexExp.test(m.toString());
        }, function (m, c) { return (_this.is(m) ? io_ts_1.success(m) : io_ts_1.failure(m, c)); }, io_ts_1.identity) || this;
        return _this;
    }
    RTTI_dateType.regexExp = /^([0-9]([0-9]([0-9][1-9]|[1-9]0)|[1-9]00)|[1-9]000)(-(0[1-9]|1[0-2])(-(0[1-9]|[1-2][0-9]|3[0-1]))?)?$/;
    return RTTI_dateType;
}(io_ts_1.Type));
exports.RTTI_dateType = RTTI_dateType;
exports.RTTI_date = new RTTI_dateType();
