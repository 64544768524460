import {colors, Ids, Status, StrLang} from '@canimmunize/tools';
import {faPrint, faDownload, faMobileAlt, IconDefinition} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Col, Row, Space, Tooltip} from 'antd';
import moment from 'moment';
import React, {MouseEventHandler} from 'react';
import {useUrlData} from '../../../../components/scheduler/util';
import {useUnAuthClient} from '../../../../services/fhir';
import {appointmentDateString} from '../../../../util/helpers/appointment-date-string';
import {downloadPVCPdf} from '../../util/download-pvc-pdf';
import {useCOVIDPortalConfig} from '../../util/portal-config';
import {SessionConfigContext} from '../../util/session-config';
import {DoseBox, NSPortalDoseBox} from '../dose-box';
import styles from './index.module.css';
import {PVCWalletSizedButton} from '../../pvc-wallet-sized-button';
import {useEnvInfo} from '../../../../services/environment';

interface PatientLabelProps {
  patientRecord;
  lang: string;
}

// Demographic info: name and date if birth listed at the top of NS portal
export const PatientLabel = (props: PatientLabelProps) => {
  const {patientRecord} = props;
  const lang = props.lang || 'en';

  return (
    <div>
      <div className={styles.largeTitle}>
        {patientRecord.firstName} {patientRecord.lastName}
      </div>

      <div style={{fontSize: '18px', marginTop: 10, marginBottom: 10}}>
        {StrLang(lang, Ids.date_of_birth)}:{' '}
        {moment(patientRecord.birthdate).locale(lang).format('MMMM D, YYYY')}
      </div>
    </div>
  );
};

interface PVCProps {
  patientRecord?;
  lang?: string;
  isYukonCallCentre?: boolean;
  reqViaThirdParty?: boolean;
}

// PVC: Contains the box with a button to download proof of vaccination as well instructions on how to do so.
export const PVC = (props: PVCProps) => {
  const {patientRecord, isYukonCallCentre, reqViaThirdParty} = props;
  const lang = props.lang || 'en';
  const {sessionId} = React.useContext(SessionConfigContext);
  const unAuthClient = useUnAuthClient();
  const {environmentId} = useEnvInfo();
  const {config: covidPortalConfig} = useCOVIDPortalConfig();
  const [pvcLoading, setPvcLoading] = React.useState(false);
  const [pvcMobileLoading, setPvcMobileLoading] = React.useState(false);

  let pvcTitleText,
    pvcText,
    downloadText,
    downloadTooltip,
    downloadWalletText,
    downloadWalletTooltip,
    downloadMobileText,
    downloadMobileTooltip,
    previewLink;

  switch (environmentId) {
    case 'pei': {
      pvcTitleText = StrLang(lang, Ids.pvc_title_text_pei);
      pvcText = StrLang(lang, Ids.pvc_text_pei);
      downloadText = StrLang(lang, Ids.pei_pvc_download_text);
      downloadTooltip = StrLang(lang, Ids.pei_pvc_download_tooltip);
      downloadWalletText = StrLang(lang, Ids.pei_pvc_download_wallet_text);
      downloadWalletTooltip = StrLang(lang, Ids.pei_pvc_download_wallet_tooltip);
      downloadMobileText = StrLang(lang, Ids.pei_pvc_download_mobile_text);
      downloadMobileTooltip = StrLang(lang, Ids.pei_pvc_download_mobile_tooltip);
      previewLink = 'https://cdn.canimmunize.ca/cf2/pei/pei-portal-pvc-banner-temp.png';
      break;
    }
    case 'yukon': {
      pvcTitleText = StrLang(lang, Ids.yk_pvc_download_pdf);
      pvcText = StrLang(lang, Ids.yk_pvc_download_text);
      downloadText = StrLang(lang, Ids.yk_pvc_print_format);
      downloadMobileText = StrLang(lang, Ids.yk_pvc_mobile_format);
      previewLink = 'https://cdn.canimmunize.ca/cf2/yt-pvc-preview.jpg';
      break;
    }
    default: {
      pvcTitleText = 'Your Nova Scotia COVID-19 Proof of Vaccination (PDF)';
      pvcText = `You need to print or download "Your Nova Scotia COVID-19 Proof of Vaccination" PDF.
      Within the province, you can use your Nova Scotia COVID-19 Proof of Vaccination to
      show proof of vaccination (including showing proof of full vaccination as part of the
      Proof of Full Vaccination Policy).`;
      downloadText = StrLang(lang, Ids.ns_pvc_download_text);
      downloadTooltip = StrLang(lang, Ids.ns_pvc_download_tooltip);
      downloadWalletText = StrLang(lang, Ids.ns_pvc_download_wallet_text);
      downloadWalletTooltip = StrLang(lang, Ids.ns_pvc_download_wallet_tooltip);
      downloadMobileText = StrLang(lang, Ids.pei_pvc_download_mobile_text);
      downloadMobileTooltip = StrLang(lang, Ids.pei_pvc_download_mobile_tooltip);
      previewLink = 'https://cdn.canimmunize.ca/cf2/ns-portal-pvc-banner.png';
    }
  }

  let borderColor, titleColor;
  switch (environmentId) {
    case 'pei':
      borderColor = titleColor = '#00745b';
      break;
    case 'yukon':
      borderColor = titleColor = covidPortalConfig.primaryColor;
      break;
    default:
      borderColor = '#0065a4';
      titleColor = colors.blue;
  }

  return (
    <>
      <div className={styles.pvcContainer} style={{borderColor}}>
        <div className={styles.pvcRow}>
          <div className={styles.pvcBody}>
            <div
              style={{
                fontSize: '24px',
                marginTop: 5,
                marginBottom: 5,
                marginLeft: 15,
                color: titleColor,
              }}
            >
              <b>{pvcTitleText}</b>
            </div>

            <div className={styles.pvcText}>{pvcText}</div>
            <div style={{display: 'flex', alignItems: 'flex-start', flexDirection: 'column'}}>
              {/** Download PVC Button */}
              <PortalButton
                tooltipTitle={downloadTooltip}
                buttonTitle={downloadText}
                icon={faDownload}
                onClick={() =>
                  downloadPVCPdf(
                    patientRecord,
                    unAuthClient,
                    setPvcLoading,
                    {isCallCentre: isYukonCallCentre, lang, reqViaThirdParty, type: 'pvc'},
                    environmentId,
                    sessionId
                  )
                }
                loading={pvcLoading}
              />
              {/** Download Mobile PVC Button */}
              {environmentId !== 'novascotia' && (
                <PortalButton
                  tooltipTitle={downloadMobileTooltip}
                  buttonTitle={downloadMobileText}
                  icon={faMobileAlt}
                  onClick={() =>
                    downloadPVCPdf(
                      patientRecord,
                      unAuthClient,
                      setPvcMobileLoading,
                      {type: 'mobile', isCallCentre: isYukonCallCentre, lang, reqViaThirdParty},
                      environmentId,
                      sessionId
                    )
                  }
                  loading={pvcMobileLoading}
                />
              )}
              {/** Print Wallet Receipt Button */}
              {environmentId === 'novascotia' && (
                <PVCWalletSizedButton
                  patientId={patientRecord?.id}
                  portal={{
                    id: patientRecord?.portalId,
                    sessionId: sessionId as string,
                    title: downloadWalletText,
                    tooltip: downloadWalletTooltip,
                  }}
                />
              )}
            </div>
          </div>
          <img className={styles.pvcImage} src={previewLink} />
        </div>
      </div>
    </>
  );
};

interface VaccinationSummaryTableProps {
  patientRecord?;
  lang?;
}

export const VaccinationSummaryTable = (props: VaccinationSummaryTableProps) => {
  const {patientRecord, lang} = props;
  const summaryText = StrLang(lang, Ids.receipt_summary_text);
  const summarySubText = StrLang(lang, Ids.receipt_summary_subtext);

  return (
    <>
      <p className={styles.title} style={{display: 'contents'}}>
        {summaryText}
      </p>

      <div style={{fontSize: '18px', marginTop: 10, marginBottom: 10}}>{summarySubText}</div>
      {(Array.isArray(patientRecord.doses) ? patientRecord.doses : [])
        .slice()
        .sort(compareNS)
        .map((dose, i) => {
          return (
            <>
              <NSPortalDoseBox
                title={dose.concept.abbreviation[lang]}
                subTitle={dose.concept.publicPicklistTerm[lang]}
                status={Status.RECEIVED}
                key={`${dose.doseId}-${i}`}
                statusText={StrLang(
                  lang,
                  Ids.notification_received_on,
                  moment
                    .tz(dose.date, dose.clinicEntry?.clinic?.timezone || moment.tz.guess())
                    .format('LL')
                )}
              />
            </>
          );
        })}
    </>
  );
};
interface PortalReceiptProps {
  patientRecords: any[];
}

// New front end (Sept 28) for NS portal, supports official PVC
export const PortalReceipt = (props: PortalReceiptProps) => {
  const {patientRecords} = props;
  const {lang} = useUrlData();
  const {environmentId} = useEnvInfo();

  let hideForecastList, hideAddress, hideBirthDate;
  hideForecastList = hideBirthDate = hideAddress = environmentId === 'pei';

  return (
    <>
      {patientRecords?.map((patientRecord) => {
        return (
          <div key={`rc_${patientRecord.id}`}>
            {/* Patient Label - Name and DOB */}
            <PatientLabel {...{patientRecord, lang}} />
            <PVC {...{patientRecord, lang}} />
            {!hideForecastList && <ForecastList patientRecord={patientRecord} />}
            <VaccinationSummaryTable {...{patientRecord, lang}} />
          </div>
        );
      })}
    </>
  );
};
interface DoseListProps {
  patientRecords: any[];
  handlePrint?: MouseEventHandler<HTMLElement>;
  hidePrintButton?: boolean;
  hideDownloadPVC?: boolean;
  adminConsoleView?: boolean;
}

// OLD Dose list, still used in the admin console.
export const DoseList = (props: DoseListProps) => {
  const {
    handlePrint,
    patientRecords,
    hidePrintButton,
    hideDownloadPVC = true, // Default this to true
    adminConsoleView,
  } = props;
  const {lang} = useUrlData();
  const {environmentId} = useEnvInfo();
  let hideForecastList, hideAddress, hideBirthDate;
  hideForecastList = hideBirthDate = hideAddress = environmentId === 'pei' || 'bruyere';

  return (
    <>
      {patientRecords?.map((patientRecord) => {
        return (
          <div key={`rc_${patientRecord.id}`}>
            <DoseListTitle
              {...{
                patientRecord,
                handlePrint,
                hidePrintButton,
                hideDownloadPVC,
                lang,
              }}
            />
            <Row gutter={40}>
              <Col>
                <ReceivedVaccinationsList {...{patientRecord, lang, adminConsoleView}} />
                {!hideForecastList && (
                  <ForecastList patientRecord={patientRecord} adminConsoleView={adminConsoleView} />
                )}
              </Col>
            </Row>
          </div>
        );
      })}
    </>
  );
};

interface DoseListTitleProps {
  patientRecord;
  handlePrint?: MouseEventHandler<HTMLElement>;
  lang: string;
  hidePrintButton?: boolean;
  hideDownloadPVC?: boolean;
}
// Admin Console
export const DoseListTitle = (props: DoseListTitleProps) => {
  const {patientRecord, handlePrint, hidePrintButton, hideDownloadPVC} = props;
  const lang = props.lang || 'en';
  const {environmentId} = useEnvInfo();
  let hideForecastList, hideAddress, hideBirthDate;
  hideForecastList = hideBirthDate = hideAddress = environmentId === 'pei';
  const {sessionId} = React.useContext(SessionConfigContext);
  const unAuthClient = useUnAuthClient();
  const [pvcLoading, setPvcLoading] = React.useState(false);

  return (
    <div style={{display: 'flex', alignItems: 'center'}}>
      <div style={{flex: 1}}>
        {/* Patient name */}
        <div className={styles.title} style={{display: 'contents'}}>
          <div style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
            {patientRecord.firstName} {patientRecord.lastName}
          </div>
        </div>

        {/* Patient Address */}
        {!hideAddress && (
          <>
            {/* Temporarily removed per request from NS */}
            {/* <div style={{marginBottom: 10}}>{`${record.street ? record.street : ''}${
                     record.unitNumber ? `, Unit/Apt ${record.unitNumber},` : ''
                   } ${record.city ? record.city : ''} ${
                     record.postalCode ? record.postalCode : ''
                   }`}</div> */}
          </>
        )}

        {/* Patient Birthdate */}
        {!hideBirthDate && (
          <div style={{fontSize: '18px', marginTop: 10, marginBottom: 10}}>
            {StrLang(lang, Ids.date_of_birth)}:{' '}
            {moment(patientRecord.birthdate).locale(lang).format('MMMM D, YYYY')}
          </div>
        )}
      </div>

      {/* 'Print Receipt' Button */}
      <div>
        {!hidePrintButton && (
          <Button
            className="invisible-on-print"
            type="text"
            color={colors.blue}
            style={{fontWeight: 'bold', color: colors.blue, borderColor: 'transparent'}}
            onClick={handlePrint}
          >
            <FontAwesomeIcon icon={faPrint} color={colors.blue} style={{marginRight: 6}} />
            {StrLang(lang, Ids.print_my_receipt)}
          </Button>
        )}
        {!hideDownloadPVC && (
          <Button
            className="invisible-on-print"
            type="text"
            loading={pvcLoading}
            color={colors.blue}
            style={{fontWeight: 'bold', color: colors.blue, borderColor: 'transparent'}}
            onClick={() =>
              downloadPVCPdf(
                patientRecord,
                unAuthClient,
                setPvcLoading,
                undefined,
                environmentId,
                sessionId
              )
            }
          >
            <FontAwesomeIcon icon={faPrint} color={colors.blue} style={{marginRight: 6}} />
            {'Download PVC'}
          </Button>
        )}
      </div>
    </div>
  );
};
interface ReceivedVaccinationsListProps {
  patientRecord;
  lang?: string;
  adminConsoleView?: boolean;
}

export const ReceivedVaccinationsList = (props: ReceivedVaccinationsListProps) => {
  const {patientRecord, adminConsoleView} = props;
  const lang = props.lang || 'en';
  const {environmentId} = useEnvInfo();
  return (
    <>
      {/* Header */}
      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        {/* Section Title */}
        <p className={styles.subtitle}>{StrLang(lang, Ids.received_vaccinations)}</p>
      </div>

      {/* Vaccination List - Portal Version */}
      <div className={!adminConsoleView ? styles.content : styles.contentAdminConsole}>
        {patientRecord.doses
          .slice() //copy of arrray
          .sort(compare) //sorts the copy based off of dose number
          .map((dose, i) => {
            return (
              <DoseBox
                title={dose.concept.abbreviation[lang]}
                subTitle={dose.concept.publicPicklistTerm[lang]}
                status={Status.RECEIVED}
                lotNumber={dose.lotNumber}
                key={`${dose.doseId}-${i}`}
                statusText={StrLang(
                  lang,
                  Ids.notification_received_on,
                  moment
                    .tz(dose.date, dose.clinicEntry?.clinic?.timezone || moment.tz.guess())
                    .format(environmentId === 'pei' ? 'YYYY/MM/DD' : 'LL')
                )}
              />
            );
          })}
        {/* Display 'No Vaccinations' when empty */}
        {patientRecord.doses.length === 0 && (
          <div
            style={{
              width: '100%',
              color: colors.lightText,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 80,
            }}
          >{`No Received Vaccinations`}</div>
        )}
      </div>
    </>
  );
};

interface ForecastListProps {
  patientRecord;
  forceHideRescheduleButtons?: boolean;
  adminConsoleView?: boolean;
}

export const ForecastList = ({
  patientRecord,
  forceHideRescheduleButtons,
  adminConsoleView,
}: ForecastListProps) => {
  const {config, lang} = useCOVIDPortalConfig();

  const upcomingAppointments = patientRecord.appointments?.filter(
    (appt) => moment(appt.datetime).isAfter(moment()) && !appt.canceled && !appt.completed
  );

  return (
    <div>
      <div className={styles.subtitle}>{StrLang(lang, Ids.upcoming_vaccinations)}</div>
      <div className={adminConsoleView ? styles.contentAdminConsole : styles.content}>
        {/* Upcoming Appointment Boxes */}
        {upcomingAppointments.map((appointment) => {
          return (
            <DoseBox
              title={appointment.clinic.vaccineConcept?.abbreviation[lang || 'en']}
              subTitle={appointment.clinic.vaccineConcept?.publicPicklistTerm[lang || 'en']}
              status={Status.APPROACHING}
              statusText={appointmentDateString(
                moment.tz(appointment.datetime, config.timezone),
                lang,
                config.timezone
              )}
              locationText={
                <>
                  <div>{appointment.clinic.name}</div>
                  {appointment.clinic.location && (
                    <div>{appointment.clinic.location.display[lang || 'en']}</div>
                  )}
                </>
              }
              right={
                config.showRescheduleButtonsForUpcomingAppointments === 'true' &&
                !forceHideRescheduleButtons ? (
                  <Space style={{flexGrow: 0, marginLeft: 7}} className={styles.noPrint}>
                    <Button
                      className={styles.upcomingAppointmentButton}
                      href={`/${lang}/${appointment[`bookingPageSlug${lang ? 'En' : 'Fr'}`]}/${
                        appointment.id
                      }`}
                      style={{color: 'white', backgroundColor: 'rgb(0, 100, 164)'}}
                      target="_blank"
                    >
                      {StrLang(lang, Ids.manage_appointment)}
                    </Button>
                  </Space>
                ) : undefined
              }
            />
          );
        })}
        {/* Content to display when if there are no upcoming appointments */}
        {upcomingAppointments.length === 0 && (
          <div
            style={{
              width: '100%',
              color: colors.lightText,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: 50,
              marginBottom: 50,
            }}
          >
            <div>{StrLang(lang, Ids.no_upcoming)}</div>
            <div>
              <Button
                className="hide-on-print"
                type="link"
                style={{color: '#0065a4'}}
                href={config?.bookAppointmentLink?.[lang]}
              >
                {StrLang(lang, Ids.book_appointment)}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

interface DoseListBarcodesProps {
  patientRecord;
  lang?: string;
}

export const DoseListBarcodes = (props: DoseListBarcodesProps) => {
  const {patientRecord} = props;
  const lang = props.lang || 'en';

  const barcodeSrcs =
    patientRecord.barcodes?.map((svg) => {
      let blob = new Blob([svg], {type: 'image/svg+xml'});
      return URL.createObjectURL(blob);
    }) ?? [];

  return (
    <>
      <p className={styles.subtitle}>Save in CANImmunize</p>
      <div className={styles.card} style={{marginTop: 10, paddingLeft: 20, paddingRight: 20}}>
        {barcodeSrcs.map((bc) => (
          <img src={bc} style={{marginBottom: 10, maxWidth: 260}} />
        ))}
        <p>
          Scan the QR code above in your CANImmunize mobile app to add this vaccination record to
          your account.
        </p>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <div style={{flex: 1, flexShrink: 1}}>
            {/*<img src={googlePlayLogo} style={{maxWidth: 150, width: 'auto', marginRight: 10}} />*/}
          </div>
          <div style={{flex: 1, flexShrink: 1}}>
            {/*<img src={appStoreLogo} style={{maxWidth: 150, width: 'auto'}} />*/}
          </div>
        </div>
      </div>
    </>
  );
};

export const OldForecastList = ({record}) => {
  const {lang} = useUrlData();

  const {covidRecommendation} = record;

  const inProgressRecs =
    record.immunizationRecommendations?.filter((rec) => rec.forecastStatus === 'INPROGRESS') || [];

  const secondAppointment = record.appointments?.find(
    (appt) => appt.appointmentSeriesIndex === 2 && !appt.canceled
  );

  // If there are no INPROGRESS recommendations or if there is no second appointment
  // booked don't show anything
  if (inProgressRecs.length === 0 || !secondAppointment) return null;

  return (
    <div>
      <p className={styles.subtitle}>Upcoming Vaccinations</p>
      {/* {covidRecommendation && covidRecommendation.forecastStatus === 'INPROGRESS' && (
        <p style={{marginBottom: 0, fontSize: 16}}>{`${
          record.firstName
        }'s next COVID-19 vaccination is scheduled for ${moment(secondAppointment.datetime).format(
          'LL'
        )}.`}</p>
      )} */}
      <div className={styles.content}>
        <DoseBox
          title={covidRecommendation.concept.abbreviation[lang || 'en']}
          subTitle={covidRecommendation.concept.publicPicklistTerm[lang || 'en']}
          status={Status.APPROACHING}
          statusText={`${moment(secondAppointment.datetime).format('LL')}`}
          doseNumber={''}
          right={
            <Button
              className="hide-on-print"
              style={{marginLeft: 70, marginTop: 15, marginBottom: 15}}
            >
              Reschedule
            </Button>
          }
        />
      </div>
    </div>
  );
};
interface PortalButtonProps {
  buttonStyles?: Object;
  buttonTitle: string;
  tooltipTitle?: string;
  icon: IconDefinition;
  onClick;
  loading: boolean;
}

export const PortalButton = (props: PortalButtonProps) => {
  const {buttonTitle, tooltipTitle, icon, onClick, loading, buttonStyles = {}} = props;
  const {environmentId} = useEnvInfo();

  const type: 'primary' | 'link' | 'text' | 'ghost' | 'default' | 'dashed' = 'primary';
  const textColor = 'white';
  let bgColor = colors.blue;

  switch (environmentId) {
    case 'pei':
      bgColor = '#00745b';
      break;
    case 'yukon':
      // Yukon keeps default primary color for background
      bgColor = '';
      break;
    default:
      bgColor = colors.blue;
  }

  return (
    <Tooltip title={tooltipTitle}>
      <Button
        className="invisible-on-print"
        type={type}
        color={textColor}
        size="large"
        style={{
          ...buttonStyles,
          fontWeight: 'bold',
          color: textColor,
          borderColor: 'transparent',
          height: 'auto',
          marginTop: 10,
          backgroundColor: bgColor,
        }}
        onClick={onClick}
        loading={loading}
      >
        <span style={{whiteSpace: 'normal', textAlign: 'start', wordBreak: 'break-word'}}>
          <FontAwesomeIcon icon={icon} color={textColor} fixedWidth style={{marginRight: 6}} />
          {buttonTitle}
        </span>
      </Button>
    </Tooltip>
  );
};

//helper function to properly sort dose array in proper order (dose 1, then dose 2)
function compare(a, b) {
  const doseA = a.doseNumber;
  const doseB = b.doseNumber;
  let comparison = 0;
  if (doseA > doseB) {
    comparison = 1;
  } else if (doseA < doseB) {
    comparison = -1;
  }
  return comparison;
}

//helper function to properly sort dose array in proper order (dose 2, then dose 1)
function compareNS(a, b) {
  const doseADate = moment.tz(a.date, a.clinicEntry?.clinic?.timezone || moment.tz.guess());
  const doseBDate = moment.tz(b.date, b.clinicEntry?.clinic?.timezone || moment.tz.guess());

  let comparison = 0;
  if (doseADate.isBefore(doseBDate)) {
    comparison = 1;
  } else if (doseADate.isAfter(doseBDate)) {
    comparison = -1;
  }
  return comparison;
}
