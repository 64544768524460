import {R4} from '@ahryman40k/ts-fhir-types';
import {BundleTypeKind, Bundle_RequestMethodKind} from '@ahryman40k/ts-fhir-types/lib/R4';
import {createSlice} from '@reduxjs/toolkit';
import axios from 'axios';
import produce from 'immer';
import moment from 'moment';
import {normalize, schema} from 'normalizr';
import {getExtensionValue} from '../util/fhir';
import Immunization from './immunizations';

export interface CommunicationsSliceInterface {
  byId: {[string: string]: R4.ICommunication};
}
const initialState = {
  byId: {},
};
const communication = new schema.Entity('communications', {});

export const slice = createSlice({
  name: 'communications',
  initialState,
  reducers: {
    SAVE_COMMUNICATIONS: (state: CommunicationsSliceInterface, action) => {
      if (action.payload.entry) {
        const normalizedCommunicationResources = normalize(
          action.payload.entry.map((entry: R4.IBundle_Entry) =>
            communicationResourceParser(entry.resource as R4.ICommunication)
          ),
          [communication]
        );
        state.byId = normalizedCommunicationResources.entities.communications || {};
      }
    },
    SAVE_COMMUNICATION: (state: CommunicationsSliceInterface, action) => {
      const communication = action.payload;
      state.byId[communication.id] = communicationResourceParser(communication);
    },
  },
});

export const getAll = (client) => async (dispatch) => {
  // const res = await client.request('Communication');
  // dispatch(slice.actions.SAVE_COMMUNICATIONS(res));
};

export const getOne = (client, communicationId) => async (dispatch) => {
  return client
    .request(`Communication/${communicationId}`)
    .then((res) => dispatch(slice.actions.SAVE_COMMUNICATION(res)));
};

export const updateOne = (client, comm) => async (dispatch) => {
  return client.update(comm).then(async (res) => {
    await dispatch(slice.actions.SAVE_COMMUNICATION(res));
    return res;
  });
};

export function getPatientCommunications(client, patientId) {
  return function (dispatch) {
    const comm = client
      .request(`Communication?patient=Patient/${patientId}`)
      .then((res) => dispatch(slice.actions.SAVE_COMMUNICATIONS(res)));

    const immIds = getImmunizationIdsFromCommunication(comm);

    immIds.forEach((id) => {
      client
        .request(`Immunization/${id}`)
        .then((res) => dispatch(Immunization.slice.actions.SAVE_IMMUNIZATION));
    });
  };
}

export const approveSubmission = (client, comm, submissionResult) => async (dispatch, getState) => {
  const imms = getState().immunizations.byId;
  const immEntries = Object.keys(submissionResult).map((immId) => {
    const imm = imms[immId];
    const result = submissionResult[immId];
    const immWithResult: any = produce(imm, (draft) => {
      if (!draft.extension) draft.extension = [];
      draft.extension.push({
        url: 'https://canimmunize.ca/StructureDefinition/ca-canimmunize-imm-review-status',
        valueString: result,
      });
    });
    return {
      fullUrl: `Immunization/${immWithResult.id}`,
      resource: immWithResult,
      request: {
        method: Bundle_RequestMethodKind._put,
        url: `Immunization/${immWithResult.id}`,
      },
    };
  });

  const transactionBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: BundleTypeKind._transaction,
    entry: [
      {
        fullUrl: `Communication/${comm.id}`,
        resource: produce(comm, (draft) => {
          draft.status = 'completed';

          if (!draft.extension) draft.extension = [];
          draft.extension.push({
            url: 'https://canimmunize.ca/StructureDefinition/ca-canimmunize-comm-completion-date',
            valueDateTime: moment().toISOString(),
          });
          draft.extension.push({
            url: 'https://canimmunize.ca/StructureDefinition/ca-canimmunize-comm-submission-result-json',
            valueString: JSON.stringify(submissionResult),
          });
        }) as any,
        request: {
          method: Bundle_RequestMethodKind._put,
          url: `Communication/${comm.id}`,
        },
      },
      ...(immEntries as any),
    ],
  };

  return axios.post('https://canimmunize.demo.smilecdr.com/fhir-request', transactionBundle, {
    headers: {
      Authorization: `Bearer ${client.state.tokenResponse.access_token}`,
    },
    withCredentials: true,
  });
};

function communicationResourceParser(resource: R4.ICommunication) {
  return resource;
}

export function getImmunizationIdsFromCommunication(comm) {
  return (
    comm?.payload?.reduce((items, item) => {
      if (item.contentReference.reference.startsWith('Immunization/')) {
        return items.concat([item.contentReference.reference.split('/')[1]]);
      }
      return items;
    }, []) || []
  );
}

export function getMediaIdsFromCommunication(comm) {
  return (
    comm.payload?.reduce((items, item) => {
      if (item.contentReference.reference.startsWith('Media/')) {
        return items.concat([item.contentReference.reference.split('/')[1]]);
      }
      return items;
    }, []) || []
  );
}

export function getMediaLinksFromCommunication(comm) {
  const mediaIds = getMediaIdsFromCommunication(comm);
  return mediaIds.map(
    (id) => `https://canimmunize.demo.smilecdr.com/fhir-request/Media/${id}?_output=data`
  );
}

export function getSubmissionCompletionDate(comm) {
  return moment(
    getExtensionValue(
      comm,
      'https://canimmunize.ca/StructureDefinition/ca-canimmunize-comm-completion-date'
    )
  );
}

export function getSubmissionResult(comm) {
  const extValue = getExtensionValue(
    comm,
    'https://canimmunize.ca/StructureDefinition/ca-canimmunize-comm-submission-result-json'
  );

  return extValue ? JSON.parse(extValue) : undefined;
}

export default {
  slice,
  getAll,
  getOne,
  updateOne,
  approveSubmission,
};
