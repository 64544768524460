import {StrLang} from '@canimmunize/tools';
import {useParams} from 'react-router-dom';
import {useEnvInfo} from './environment';

export const useStr = () => {
  let {lang} =
    useParams<{
      lang?: 'en' | 'fr';
    }>();

  const envInfo = useEnvInfo();
  if (!lang) { // @ts-ignore
    lang = envInfo.lang;
  }

  return (id, ...args) => StrLang(lang || 'en', id, ...args);
};
