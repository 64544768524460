"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_uuid = exports.RTTI_uuidType = void 0;
var io_ts_1 = require("io-ts");
var RTTI_uuidType = (function (_super) {
    __extends(RTTI_uuidType, _super);
    function RTTI_uuidType() {
        var _this = _super.call(this, 'RTTI_uuidType', function (m) {
            return typeof m === 'string' && RTTI_uuidType.regexExp.test(m.toString());
        }, function (m, c) { return (_this.is(m) ? io_ts_1.success(m) : io_ts_1.failure(m, c)); }, io_ts_1.identity) || this;
        return _this;
    }
    RTTI_uuidType.regexExp = /^urn:uuid:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/;
    return RTTI_uuidType;
}(io_ts_1.Type));
exports.RTTI_uuidType = RTTI_uuidType;
exports.RTTI_uuid = new RTTI_uuidType();
