import {processGender} from '../../util/helpers/processGender';

export const hcpSimpleColumns = [
  {
    title: 'Name',
    dataIndex: 'fullName',
    sorter: true,
  },
  {
    title: 'DOB',
    dataIndex: 'birthdate',
  },
  {
    title: 'Address',
    dataIndex: 'address',
    render: (_, patient) =>
      `${patient.street ? patient.street : ''}${
        patient.unitNumber ? `, Unit/Apt ${patient.unitNumber},` : ''
      } ${patient.city ? patient.city : ''} ${patient.postalCode ? patient.postalCode : ''}`,
  },
];
