"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_OperationOutcome_Issue = exports.OperationOutcome_IssueCodeKind = exports.OperationOutcome_IssueSeverityKind = void 0;
var t = __importStar(require("io-ts"));
var RTTI_Extension_1 = require("./RTTI_Extension");
var RTTI_Element_1 = require("./RTTI_Element");
var RTTI_CodeableConcept_1 = require("./RTTI_CodeableConcept");
var OperationOutcome_IssueSeverityKind;
(function (OperationOutcome_IssueSeverityKind) {
    OperationOutcome_IssueSeverityKind["_fatal"] = "fatal";
    OperationOutcome_IssueSeverityKind["_error"] = "error";
    OperationOutcome_IssueSeverityKind["_warning"] = "warning";
    OperationOutcome_IssueSeverityKind["_information"] = "information";
})(OperationOutcome_IssueSeverityKind = exports.OperationOutcome_IssueSeverityKind || (exports.OperationOutcome_IssueSeverityKind = {}));
var OperationOutcome_IssueCodeKind;
(function (OperationOutcome_IssueCodeKind) {
    OperationOutcome_IssueCodeKind["_invalid"] = "invalid";
    OperationOutcome_IssueCodeKind["_structure"] = "structure";
    OperationOutcome_IssueCodeKind["_required"] = "required";
    OperationOutcome_IssueCodeKind["_value"] = "value";
    OperationOutcome_IssueCodeKind["_invariant"] = "invariant";
    OperationOutcome_IssueCodeKind["_security"] = "security";
    OperationOutcome_IssueCodeKind["_login"] = "login";
    OperationOutcome_IssueCodeKind["_unknown"] = "unknown";
    OperationOutcome_IssueCodeKind["_expired"] = "expired";
    OperationOutcome_IssueCodeKind["_forbidden"] = "forbidden";
    OperationOutcome_IssueCodeKind["_suppressed"] = "suppressed";
    OperationOutcome_IssueCodeKind["_processing"] = "processing";
    OperationOutcome_IssueCodeKind["_notSupported"] = "not-supported";
    OperationOutcome_IssueCodeKind["_duplicate"] = "duplicate";
    OperationOutcome_IssueCodeKind["_multipleMatches"] = "multiple-matches";
    OperationOutcome_IssueCodeKind["_notFound"] = "not-found";
    OperationOutcome_IssueCodeKind["_deleted"] = "deleted";
    OperationOutcome_IssueCodeKind["_tooLong"] = "too-long";
    OperationOutcome_IssueCodeKind["_codeInvalid"] = "code-invalid";
    OperationOutcome_IssueCodeKind["_extension"] = "extension";
    OperationOutcome_IssueCodeKind["_tooCostly"] = "too-costly";
    OperationOutcome_IssueCodeKind["_businessRule"] = "business-rule";
    OperationOutcome_IssueCodeKind["_conflict"] = "conflict";
    OperationOutcome_IssueCodeKind["_transient"] = "transient";
    OperationOutcome_IssueCodeKind["_lockError"] = "lock-error";
    OperationOutcome_IssueCodeKind["_noStore"] = "no-store";
    OperationOutcome_IssueCodeKind["_exception"] = "exception";
    OperationOutcome_IssueCodeKind["_timeout"] = "timeout";
    OperationOutcome_IssueCodeKind["_incomplete"] = "incomplete";
    OperationOutcome_IssueCodeKind["_throttled"] = "throttled";
    OperationOutcome_IssueCodeKind["_informational"] = "informational";
})(OperationOutcome_IssueCodeKind = exports.OperationOutcome_IssueCodeKind || (exports.OperationOutcome_IssueCodeKind = {}));
var EnumType_1 = require("../../EnumType");
exports.RTTI_OperationOutcome_Issue = t.recursion('IOperationOutcome_Issue', function () {
    return t.partial({
        id: t.string,
        extension: t.array(RTTI_Extension_1.RTTI_Extension),
        modifierExtension: t.array(RTTI_Extension_1.RTTI_Extension),
        severity: EnumType_1.createEnumType(OperationOutcome_IssueSeverityKind, 'OperationOutcome_IssueSeverityKind'),
        _severity: RTTI_Element_1.RTTI_Element,
        code: EnumType_1.createEnumType(OperationOutcome_IssueCodeKind, 'OperationOutcome_IssueCodeKind'),
        _code: RTTI_Element_1.RTTI_Element,
        details: RTTI_CodeableConcept_1.RTTI_CodeableConcept,
        diagnostics: t.string,
        _diagnostics: RTTI_Element_1.RTTI_Element,
        location: t.array(t.string),
        _location: t.array(RTTI_Element_1.RTTI_Element),
        expression: t.array(t.string),
        _expression: t.array(RTTI_Element_1.RTTI_Element)
    });
});
