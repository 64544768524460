import {Ids} from '@canimmunize/tools';
import {Alert, Button, Col, Row} from 'antd';
import axios from 'axios';
import {FormikContextType, useFormikContext} from 'formik';
import {Form, Input, Radio, Select} from 'formik-antd';
import parse from 'html-react-parser';
import React from 'react';
import {ValidateFormItem} from '../../../../../components/validate-form-item';
import {FhirUtils} from '../../../../../services/fhir';
import {useStr} from '../../../../../services/str';
import {PostalCodeValidationRules} from '../../../../../services/ui-validation-rules';
import {RequiredValidationRuleId} from '../../../../../validation/validation-rules/required-validation';
import {YukonPortalPage} from '../../../components/yukon-portal-page';
import {useCOVIDPortalConfig} from '../../../util/portal-config';
import {SessionConfigContext} from '../../../util/session-config';

export const CredentialDeliveryPage = (props) => {
  const Str = useStr();
  const formikContext: FormikContextType<any> = useFormikContext();
  const {syncUrl} = useCOVIDPortalConfig();
  const [submitting, setSubmitting] = React.useState(false);
  const {sessionId} = React.useContext(SessionConfigContext);
  const fhirClient = FhirUtils.useAxiosClient();

  const postSubmit = async () => {
    formikContext.setFieldValue('error', undefined);
    if (formikContext.values.deliveryMethod === 'mail') {
      if (formikContext.values.numCopies < 1) {
        formikContext.setFieldValue('error', Str(Ids.yk_num_copies_under_min));
        window.scrollTo(0, 0);
        return;
      } else if (formikContext.values.numCopies > 5) {
        formikContext.setFieldValue('error', Str(Ids.yk_num_copies_over_max));
        window.scrollTo(0, 0);
        return;
      }

      const formattedMailingAddress =
        formikContext.values.selectedMailAddress !== 'different'
          ? formikContext.values.selectedMailAddress
          : `${formikContext.values.newStreetAddress}${
              formikContext.values.newUnitNum ? ` ${formikContext.values.newUnitNum}` : ''
            }, ${formikContext.values.newCity} ${
              formikContext.values.newProvince
            } ${formikContext.values.newPostalCode.replace(/\s/g, '').toUpperCase()}`;

      setSubmitting(true);
      const result = formikContext.values.isYukonCallCentre
        ? await fhirClient
            .post('/yk-pvc-call-center/mail-pvc', {
              patientPortalId: formikContext.values.patientInfo.portalId,
              numCopies: formikContext.values.numCopies,
              mailingAddress: formattedMailingAddress,
              requestorName: formikContext.values.requestorName,
            })
            .catch((err) => err.response)
        : await axios
            .post(`${syncUrl}/public/portal/mail-pvc`, {
              id: formikContext.values.portalId,
              sessionId,
              numCopies: formikContext.values.numCopies,
              mailingAddress: formattedMailingAddress,
              contactMethod: formikContext.values.selectedAuthContactMethod,
              requestorName: formikContext.values.requestorName,
            })
            .catch((err) => err.response);
      setSubmitting(false);

      if (result.status !== 200) {
        formikContext.setFieldValue('error', <>{parse(Str(Ids.yk_pvc_system_error))}</>);
        window.scrollTo(0, 0);
        return;
      }
    }

    formikContext.values.setClinicEntry({
      records: formikContext.values.patientInfo,
      deliveryMethod: formikContext.values.deliveryMethod,
      reqViaThirdParty: !formikContext.values.reqForSelf,
    });
    formikContext.setFieldValue('subFormSubmit', undefined);
    formikContext.handleSubmit();
  };

  const pageBody = (
    <>
      {formikContext.values.error && (
        <Alert
          type="error"
          message={formikContext.values.error}
          style={{marginBottom: 15}}
          showIcon
        />
      )}

      <ValidateFormItem
        validationRules={[
          {
            validationRuleType: RequiredValidationRuleId,
          },
        ]}
        renderFormItem={(validate) => {
          return (
            <Form.Item
              label={<b>{Str(Ids.yk_cred_delivery_method)}</b>}
              name="deliveryMethod"
              required
              validate={validate}
            >
              <Radio.Group
                options={[
                  {
                    label: Str(Ids.yk_cred_delivery_download),
                    value: 'download',
                    style: {display: 'block'},
                  },
                  {
                    label: Str(Ids.yk_cred_delivery_mail),
                    value: 'mail',
                    style: {display: 'block'},
                  },
                ]}
                name="deliveryMethod"
              ></Radio.Group>
            </Form.Item>
          );
        }}
      />

      {formikContext.values.deliveryMethod === 'mail' && (
        <div>
          <ValidateFormItem
            validationRules={[
              {
                validationRuleType: RequiredValidationRuleId,
              },
            ]}
            renderFormItem={(validate) => {
              return (
                <Form.Item
                  label={<b>{Str(Ids.yk_postal_method)}</b>}
                  name="selectedMailAddress"
                  required
                  validate={validate}
                >
                  <Radio.Group
                    options={[
                      ...formikContext.values.patientInfo.addresses.map((address) => {
                        return {
                          label: address,
                          value: address,
                          style: {display: 'block'},
                        };
                      }),
                      {
                        label: Str(Ids.yk_send_to_different_address),
                        value: 'different',
                        style: {display: 'block'},
                      },
                    ]}
                    name="selectedMailAddress"
                  ></Radio.Group>
                </Form.Item>
              );
            }}
          />

          <Col md={18}>
            {formikContext.values.selectedMailAddress === 'different' && (
              <>
                <Row gutter={24}>
                  <Col span={24} md={12}>
                    <ValidateFormItem
                      validationRules={[
                        {
                          validationRuleType: RequiredValidationRuleId,
                        },
                      ]}
                      renderFormItem={(validate) => {
                        return (
                          <Form.Item
                            label={Str(Ids.yk_mailing_address)}
                            name="newStreetAddress"
                            validate={validate}
                            required
                          >
                            <Input name="newStreetAddress" size="large" autoFocus />
                          </Form.Item>
                        );
                      }}
                    />
                  </Col>
                  <Col span={24} md={12}>
                    <Form.Item label={Str(Ids.yk_unit_apt_num)} name="newUnitNum">
                      <Input name="newUnitNum" size="large" />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={24}>
                  <Col span={24} md={12}>
                    <ValidateFormItem
                      validationRules={[
                        {
                          validationRuleType: RequiredValidationRuleId,
                        },
                      ]}
                      renderFormItem={(validate) => {
                        return (
                          <Form.Item
                            label={Str(Ids.yk_city_town)}
                            name="newCity"
                            validate={validate}
                            required
                          >
                            <Input name="newCity" size="large" />
                          </Form.Item>
                        );
                      }}
                    />
                  </Col>
                  <Col span={24} md={12}>
                    <ValidateFormItem
                      validationRules={[
                        ...PostalCodeValidationRules,
                        {
                          validationRuleType: RequiredValidationRuleId,
                        },
                      ]}
                      renderFormItem={(validate) => {
                        return (
                          <Form.Item
                            label={Str(Ids.yk_postal_code)}
                            name="newPostalCode"
                            validate={validate}
                            required
                          >
                            <Input name="newPostalCode" size="large" />
                          </Form.Item>
                        );
                      }}
                    />
                  </Col>
                </Row>

                <Row gutter={24}>
                  <Col span={24}>
                    <ValidateFormItem
                      validationRules={[
                        {
                          validationRuleType: RequiredValidationRuleId,
                        },
                      ]}
                      renderFormItem={(validate) => {
                        return (
                          <Form.Item
                            label={Str(Ids.pdf_report_province)}
                            name="newProvince"
                            validate={validate}
                            required
                          >
                            <Select
                              placeholder={Str(Ids.yk_pvc_select_placeholder)}
                              showSearch
                              style={{width: '100%'}}
                              size="large"
                              name="newProvince"
                              value={formikContext.values.newProvince}
                              onChange={(e) => formikContext.setFieldValue('sex', e)}
                              filterOption={(input, option: any) =>
                                option.children
                                  .toLowerCase()
                                  .normalize('NFD')
                                  .replace(/[\u0300-\u036f]/g, '')
                                  .indexOf(
                                    input
                                      ?.toLowerCase()
                                      .normalize('NFD')
                                      .replace(/[\u0300-\u036f]/g, '')
                                  ) >= 0
                              }
                            >
                              <Select.Option id="YT" value="YT">
                                {Str(Ids.YT)}
                              </Select.Option>
                              <Select.Option id="AB" value="AB">
                                {Str(Ids.AB)}
                              </Select.Option>
                              <Select.Option id="BC" value="BC">
                                {Str(Ids.BC)}
                              </Select.Option>
                              <Select.Option id="MB" value="MB">
                                {Str(Ids.MB)}
                              </Select.Option>
                              <Select.Option id="NB" value="NB">
                                {Str(Ids.NB)}
                              </Select.Option>
                              <Select.Option id="NL" value="NL">
                                {Str(Ids.NL)}
                              </Select.Option>
                              <Select.Option id="NS" value="NS">
                                {Str(Ids.NS)}
                              </Select.Option>
                              <Select.Option id="NT" value="NT">
                                {Str(Ids.NT)}
                              </Select.Option>
                              <Select.Option id="NU" value="NU">
                                {Str(Ids.NU)}
                              </Select.Option>
                              <Select.Option id="ON" value="ON">
                                {Str(Ids.ON)}
                              </Select.Option>
                              <Select.Option id="PE" value="PE">
                                {Str(Ids.PE)}
                              </Select.Option>
                              <Select.Option id="QC" value="QC">
                                {Str(Ids.QC)}
                              </Select.Option>
                              <Select.Option id="SK" value="SK">
                                {Str(Ids.SK)}
                              </Select.Option>
                            </Select>
                          </Form.Item>
                        );
                      }}
                    />
                  </Col>
                </Row>
              </>
            )}

            <ValidateFormItem
              validationRules={[
                {
                  validationRuleType: RequiredValidationRuleId,
                },
              ]}
              renderFormItem={(validate) => {
                return (
                  <Form.Item
                    label={<b>{Str(Ids.yk_num_copies)}</b>}
                    name="numCopies"
                    required
                    validate={validate}
                  >
                    <Input
                      name="numCopies"
                      type="number"
                      step={1}
                      max={5}
                      min={1}
                      size="large"
                    ></Input>
                  </Form.Item>
                );
              }}
            />
          </Col>
        </div>
      )}
    </>
  );

  const pageFooter = (
    <>
      <Button
        size="large"
        onClick={() => {
          formikContext.setFieldValue('error', undefined);
          formikContext.setFieldValue(
            'mode',
            formikContext.values.isYukonCallCentre ? 'hci' : 'verification'
          );
        }}
        style={{
          border: '1px solid',
          marginRight: '10px',
          marginBottom: '10px',
          width: '200px',
        }}
      >
        {Str(Ids.yk_pvc_go_back_step)}
      </Button>
      <Button
        size="large"
        type="primary"
        loading={submitting}
        onClick={() => {
          formikContext.setFieldValue('subFormSubmit', postSubmit);
          formikContext.handleSubmit();
        }}
        style={{
          width: '200px',
        }}
      >
        {Str(Ids.yk_pvc_finish_and_submit)}
      </Button>
    </>
  );

  return (
    <>
      <YukonPortalPage
        pageNumber={
          formikContext.values.isYukonCallCentre ? Str(Ids.yk_cc_page4) : Str(Ids.yk_pvc_page6)
        }
        title={Str(Ids.yk_cred_delivery)}
        body={pageBody}
        footer={pageFooter}
        backPage={formikContext.values.isYukonCallCentre ? 'hci' : 'authentication'}
      ></YukonPortalPage>
    </>
  );
};
