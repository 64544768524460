"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_TriggerDefinition = exports.TriggerDefinitionTypeKind = void 0;
var t = __importStar(require("io-ts"));
var RTTI_Extension_1 = require("./RTTI_Extension");
var RTTI_Element_1 = require("./RTTI_Element");
var RTTI_Timing_1 = require("./RTTI_Timing");
var RTTI_Reference_1 = require("./RTTI_Reference");
var RTTI_DataRequirement_1 = require("./RTTI_DataRequirement");
var RTTI_Expression_1 = require("./RTTI_Expression");
var TriggerDefinitionTypeKind;
(function (TriggerDefinitionTypeKind) {
    TriggerDefinitionTypeKind["_namedEvent"] = "named-event";
    TriggerDefinitionTypeKind["_periodic"] = "periodic";
    TriggerDefinitionTypeKind["_dataChanged"] = "data-changed";
    TriggerDefinitionTypeKind["_dataAdded"] = "data-added";
    TriggerDefinitionTypeKind["_dataModified"] = "data-modified";
    TriggerDefinitionTypeKind["_dataRemoved"] = "data-removed";
    TriggerDefinitionTypeKind["_dataAccessed"] = "data-accessed";
    TriggerDefinitionTypeKind["_dataAccessEnded"] = "data-access-ended";
})(TriggerDefinitionTypeKind = exports.TriggerDefinitionTypeKind || (exports.TriggerDefinitionTypeKind = {}));
var EnumType_1 = require("../../EnumType");
exports.RTTI_TriggerDefinition = t.recursion('ITriggerDefinition', function () {
    return t.partial({
        id: t.string,
        extension: t.array(RTTI_Extension_1.RTTI_Extension),
        type: EnumType_1.createEnumType(TriggerDefinitionTypeKind, 'TriggerDefinitionTypeKind'),
        _type: RTTI_Element_1.RTTI_Element,
        name: t.string,
        _name: RTTI_Element_1.RTTI_Element,
        timingTiming: RTTI_Timing_1.RTTI_Timing,
        timingReference: RTTI_Reference_1.RTTI_Reference,
        timingDate: t.string,
        _timingDate: RTTI_Element_1.RTTI_Element,
        timingDateTime: t.string,
        _timingDateTime: RTTI_Element_1.RTTI_Element,
        data: t.array(RTTI_DataRequirement_1.RTTI_DataRequirement),
        condition: RTTI_Expression_1.RTTI_Expression
    });
});
