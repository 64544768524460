import React from 'react';
import {FormConfig} from '../base-entity/base-entity';
import * as ClinicModel from '../../models/clinics';
import {ClinicFormCustomFieldName, generateClinicFormUXModel} from './clinic-form-ux-model';
import {FHIRTableProps} from '../fhir-table';
import {FormUX} from '../form-ux';
import {OrganizationPickerFormItem} from '../organization/organization-picker';
import {useSelector} from 'react-redux';
import {RootState} from '../../models';
import {ClinicUXItemPage} from './clinic-ux-item-page';
import {Collapse, Space, Tag} from 'antd';
import {LocationForm} from './location-form';
import {VaccineConceptPickerFormItem} from './vaccine-concept-picker';
import {CopyIDTag} from '../util/copy-id-tag';
import {Clinic} from '../../models/clinics';
import {AbilityContext} from '../../services/roles/ability-context';
import {BaseEntityV2} from '../base-entity/base-entity';
import {useGlobalSettings} from '../../models/global-settings';
import {useEnvInfo} from '../../services/environment';

export function ClinicUX(props) {
  const ClinicForm = (props) => {
    const settings = useGlobalSettings();
    const {environmentId} = useEnvInfo();

    //organizationId of the organization associated with the clinic or, by extension, the clinic's calendar
    const organizationId =
      props.values && props.values.organizationId ? props.values.organizationId : undefined;
    return (
      <FormUX
        formUXModel={generateClinicFormUXModel({...settings, environmentId})}
        createMode={props.createMode}
        modal={props.modal}
        tabs={props.tabs}
        renderCustomField={(field) => {
          switch (field.name) {
            case ClinicFormCustomFieldName.OrganizationId: {
              return (
                <OrganizationPickerFormItem
                  label={field.label}
                  name={field.name}
                  setFieldValue={props.setFieldValue}
                  validationRules={field.validationRules}
                />
              );
            }
            case ClinicFormCustomFieldName.Location: {
              return (
                <Collapse>
                  <Collapse.Panel header="Location" key={0}>
                    <LocationForm name={field.name} organizationId={organizationId} />
                  </Collapse.Panel>
                </Collapse>
              );
            }
            case ClinicFormCustomFieldName.VaccineConceptId: {
              return (
                <VaccineConceptPickerFormItem
                  label={field.label}
                  name={field.name}
                  setFieldValue={props.setFieldValue}
                  validationRules={field.validationRules}
                />
              );
            }
            default: {
              throw new Error(`Unhandled custom field ${field.name} in
              renderCustomField method`);
            }
          }
        }}
      />
    );
  };

  const formConfig: FormConfig = {
    preSubmit: (values, actions, onSubmit, entity) => {
      /* This will be the object we will send to the server to update/create
      a clinic. 
      Use object.assign since the formik values object cannot
      mutated so we have to create a new object using Object.assign. 
      Do th following updates:
      1. Add organization to the location object if its set
      2. Remove the organization from the clinic object
      3. Remove the campaign object but add a campaignId field set to the
      campaign's id field.*/
      const submitObj = Object.assign({}, values, {
        location: values.location
          ? Object.assign({}, values.location, {
              organization: values.organization,
            })
          : undefined,
      });
      delete submitObj.organization;
      delete submitObj.campaign;

      return onSubmit({...submitObj}, actions);
    },
    FormItems: ClinicForm,
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      render: (_, item) => {
        let color;
        let text;
        switch (item.status) {
          case 'active':
            color = 'green';
            text = 'Active';
            break;
          case 'pending':
            color = 'red';
            text = 'Pending';
            break;
          case 'completed':
            color = 'blue';
            text = 'Completed';
            break;
          default:
            break;
        }
        return <Tag color={color}>{text}</Tag>;
      },
      filters: [
        {text: 'Active', value: 'active'},
        {text: 'Pending', value: 'pending'},
        {text: 'Completed', value: 'completed'},
      ],
    },
    {
      title: 'Organization',
      key: 'organization',
      dataIndex: 'organization.nameEn',
      render: (_, clinic) => {
        return clinic.organization.name.en;
      },
      sorter: true,
    },
    {
      title: 'Organization Code',
      key: 'organizationCode',
      dataIndex: 'organization.organizationCode',
      render: (_, clinic) => {
        return clinic.organization.organizationCode;
      },
      sorter: true,
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      sorter: true,
    },
  ];

  const globalOrg = useSelector((state: RootState) => state.ui.localOrg);
  const fixedFilters = globalOrg
    ? {
        _organizationId: globalOrg.id,
      }
    : undefined;

  const CanCreateClinic = () => {
    const ability = React.useContext(AbilityContext);
    return ability?.can('create', 'clinics', 'clinic');
  };

  const CantUpdateClinic = () => {
    const ability = React.useContext(AbilityContext);
    return !ability?.can('update', 'clinics', 'clinic');
  };

  const tableConfig: FHIRTableProps = {
    fhirResource: 'clinic',
    label: 'Clinic',
    fixedFilters,
    defaultPrimarySearchParam: 'name',
    mode: 'table',
    showCreateButton: CanCreateClinic,
    columns,
  };

  const ClinicSlug = 'clinic';

  const titleBarFooter = (item: Clinic, settings) => {
    const enableDose2Availability =
      settings && settings['enableDose2Availability']?.value === 'true';
    const enableAddressLookUp = settings['enableAddressLookUp']?.value === 'true';

    return (
      <div>
        <Space size={5} style={{marginBottom: 12}}>
          <Tag
            color={
              item.status === 'active'
                ? 'blue'
                : item.status === 'pending'
                ? 'orange'
                : item.status === 'completed'
                ? 'green'
                : 'red'
            }
          >{`Status: ${item.status}`}</Tag>
          <Tag color="blue">{`Category: ${item.category}`}</Tag>
          <Tag>{`Vaccine: ${item.vaccineConcept?.displayNameEn}`}</Tag>
          {enableDose2Availability && (
            <Tag>{`Target Interval: ${item.targetSecondAppointmentInterval} Days`}</Tag>
          )}
          <CopyIDTag id={item.id} />
        </Space>
        <br />
        <Space>
          <Tag color={item.location ? 'default' : 'red'}>
            {item.location?.name || 'No Location'}
          </Tag>
          {enableAddressLookUp &&
            (item.location?.gisLocationId ? (
              <Tag color="green">GIS Location</Tag>
            ) : (
              <Tag color="red">No GIS Location</Tag>
            ))}
          {item?.selfCheckInClinic === 'true' ? (
            <Tag color="green">Self-Check In Enabled</Tag>
          ) : (
            <Tag color="red">Self-Check In Disabled</Tag>
          )}
        </Space>
      </div>
    );
  };

  const config = {
    slug: ClinicSlug,
    fhirResource: 'Clinic',
    model: ClinicModel,
    itemTitleField: 'name',
    formConfig,
    searchPageTitle: 'Clinics',
    hideEditButton: CantUpdateClinic,
    tableConfig,
    itemPage: ClinicUXItemPage,
    modalCreateForm: true,
    modalEditForm: true,
    hideDeleteButton: true,
    titleBarEntityLabel: 'Clinic',
    titleBarFooter,
    tabs: true,
    ...props,
  };

  return <BaseEntityV2 {...config} />;
}
