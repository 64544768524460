import {Button, Modal, Typography} from 'antd';
import React, {useEffect, useReducer} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../models';
import UI from '../../models/ui';
import {useEnvInfo} from '../../services/environment';
import {AbilityContext} from '../../services/roles/ability-context';
import {
  useInactivityHelper,
  useInactivityPersistanceHelper,
} from '../../util/helpers/inactivity-helper';
import {InactivityTimer} from './inactivity-timer';
const {Title, Paragraph: P} = Typography;

/**
 * The class/data object used for keeping local
 * autologout countdown state for the component
 */
class CountdownState {
  countdown: number | undefined = undefined;
}

/**
 * The InactivityLogout React Functional Component
 * Used to display an automatic logout warning with countdown, to the user in
 * the form of a modal, when the user is fourd to be inactive for a period of time.
 * The user is able to stop the pending automatic logout, by clicking on the "Continue"
 * button at the bottom of the modal.
 * @returns the automatic logout countdown modal
 *          (visible only when the logout countdown is active)
 */
export const InactivityLogout = () => {
  const {inactivityLogoutConfig} = useEnvInfo();
  const ability = React.useContext(AbilityContext);
  const maxIdleTime = ability?.can('use', 'maxExtIdleTime')
    ? inactivityLogoutConfig.maxExtIdleTime
    : inactivityLogoutConfig.maxIdleTime;

  /**
   * Reducer used to dispatch actions on the countdown state
   * @param state  the component's previous countdown state
   * @param action the action being dispatched
   * @returns - the modified countdown state
   */
  const countdownStateReducer = (state: CountdownState, action: string) => {
    switch (action) {
      case 'START_COUNTDOWN':
        return {...state, countdown: inactivityLogoutConfig.logoutCountdown};
      case 'TICK_COUNTDOWN':
        return {
          ...state,
          countdown: state.countdown ? Math.max(state.countdown - 1000, 0) : undefined,
        };
      case 'CLEAR_COUNTDOWN':
        return {...state, countdown: undefined};
      default:
        throw new Error(`Unsupported InactivityLogout reducer action type: ${action}`);
    }
  };

  // We are using the useReducer hook for countdownState, because it plays nice with setInterval (unlike useState)
  const [countdownState, countdownDispatch] = useReducer(
    countdownStateReducer,
    new CountdownState()
  );

  const dispatch = useDispatch();
  const idleTime = useSelector((state: RootState) => state.ui.idleTime);
  const lastUserActionTime = useSelector((state: RootState) => state.ui.lastUserActionTime);

  const {updateInactivityTimeout, checkInactivityTimeout} =
    useInactivityPersistanceHelper(maxIdleTime);
  const {logout} = useInactivityHelper(maxIdleTime);

  // Handle changes to idleTime in the redux state
  useEffect(() => {
    if (idleTime) {
      // If idleTime is set (i.e. not undefined) then the user has just been flagged as being idle
      try {
        // Before starting the forced logout countdown, check the inactivity timeout in localstorage
        // just incase the user is actually active in another browser tab
        checkInactivityTimeout();
      } catch (error) {
        countdownDispatch('START_COUNTDOWN');
      }
    } else {
      countdownDispatch('CLEAR_COUNTDOWN');
    }
  }, [idleTime]);

  // Handle updates to the local logout countdown state
  useEffect(() => {
    if (countdownState.countdown === undefined) {
      updateInactivityTimeout(); // reset the inactivity timeout in localstorage, in case the applicaiton is
      // also open in another browser tab
    } else if (countdownState.countdown === 0) {
      // logout countdown has reached 0, proceed with a forced logout
      logout({returnTo: window.location.origin});
    } else if ((countdownState.countdown || 0) > 0) {
      try {
        checkInactivityTimeout();
        // User is probably actively using the application in another browser tab
        // If this is the case, we want to abort our automatic logout countdown
        dispatch(UI.slice.actions.CLEAR_IDLE_TIME());
      } catch (error) {
        // tick down the logout countdownd
        let id = setTimeout(() => {
          countdownDispatch('TICK_COUNTDOWN');
        }, 1000);
        return () => {
          clearTimeout(id);
        };
      }
    }
  }, [countdownState.countdown]);

  // Handle updates to the lastActionTime in the redux state by writing the current time to the
  // browser's localstorage.  This is required, so that we can enforce inactivity logouts properly
  // where the user closes and reopens their browser without actually logging out of the app
  useEffect(() => {
    updateInactivityTimeout();
  }, [lastUserActionTime]);

  /**
   * Cancels the logout countdown
   */
  const onCancelForcedLogout = () => {
    dispatch(UI.slice.actions.CLEAR_IDLE_TIME());
  };

  /**
   * Checks to see if the logout countdown is currently active
   * @retunrs true if the logout countdown is active, false otherwise
   */
  const countdownIsActive = () =>
    countdownState.countdown ? countdownState.countdown >= 0 : false;

  /**
   * Returns the current logout countdown time in a user friendly string format {@see friendlyName}
   * @returns the countdown value in a string format if the countdown is active;
   *          othewise returns an empty string
   */
  const displayCountdown = () =>
    countdownIsActive() ? friendlyTime(countdownState.countdown || 0) : '';

  /**
   * Converts a time duration specified in milliseconds into a user friendly
   * string format
   *
   * Examples:
   *  8145000 is returned as "2:15:45 hours"
   *   310000 is returned as "05:10 minutes"
   *     7000 is returned as "7 seconds"
   *
   * @param  ms - a duration in milliseconds
   * @returns a user friendly string describing the specified duration in hours,
   *          minutes, and/or seconds.
   */
  const friendlyTime = (ms: number) => {
    let hours: number = Math.trunc(ms / (1000 * 60 * 60));
    let minutes: number = Math.trunc(ms / (1000 * 60)) - hours * 60;
    let seconds: number = Math.trunc(ms / 1000) - hours * 60 * 60 - minutes * 60;

    let units = hours ? 'hours' : minutes ? 'minutes' : 'seconds';

    let timeString: string = '';
    timeString += hours ? hours : '';
    timeString += timeString.length ? ':' : '';
    timeString +=
      timeString.length || minutes
        ? timeString.length
          ? minutes.toString().padStart(2, '0')
          : minutes.toString()
        : '';
    timeString += timeString.length ? ':' : '';
    timeString += timeString.length ? seconds.toString().padStart(2, '0') : seconds.toString();

    return `${timeString} ${units}`;
  };

  return (
    <>
      <InactivityTimer maxIdleTime={maxIdleTime} />
      <Modal
        visible={countdownIsActive()}
        closable={false}
        onOk={onCancelForcedLogout}
        style={{width: '400px'}}
        bodyStyle={{padding: 0, borderRadius: 12, overflow: 'hidden'}}
        footer={null}
      >
        <div style={{display: 'flex'}}>
          <div style={{padding: 24, flex: 1, flexShrink: 2, position: 'relative'}}>
            <Title level={2}>Automatic Logout</Title>
            <P>
              It looks like your session has been idle for longer than{' '}
              <b>{friendlyTime(maxIdleTime)}</b>.
            </P>
            <P>
              Click Continue to stay logged in. Otherwise, you will be automatically logged out in{' '}
              <b>{displayCountdown()}</b>.
            </P>
            <Button
              type="primary"
              onClick={onCancelForcedLogout}
              style={{position: 'absolute', right: 24, bottom: 24}}
            >
              Continue
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};