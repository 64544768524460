"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_ImplementationGuide_Resource = exports.ImplementationGuide_ResourceFhirVersionKind = void 0;
var t = __importStar(require("io-ts"));
var RTTI_Extension_1 = require("./RTTI_Extension");
var RTTI_Reference_1 = require("./RTTI_Reference");
var RTTI_Element_1 = require("./RTTI_Element");
var RTTI_id_1 = require("../Scalar/RTTI_id");
var ImplementationGuide_ResourceFhirVersionKind;
(function (ImplementationGuide_ResourceFhirVersionKind) {
    ImplementationGuide_ResourceFhirVersionKind["_001"] = "0.01";
    ImplementationGuide_ResourceFhirVersionKind["_005"] = "0.05";
    ImplementationGuide_ResourceFhirVersionKind["_006"] = "0.06";
    ImplementationGuide_ResourceFhirVersionKind["_011"] = "0.11";
    ImplementationGuide_ResourceFhirVersionKind["_0080"] = "0.0.80";
    ImplementationGuide_ResourceFhirVersionKind["_0081"] = "0.0.81";
    ImplementationGuide_ResourceFhirVersionKind["_0082"] = "0.0.82";
    ImplementationGuide_ResourceFhirVersionKind["_040"] = "0.4.0";
    ImplementationGuide_ResourceFhirVersionKind["_050"] = "0.5.0";
    ImplementationGuide_ResourceFhirVersionKind["_100"] = "1.0.0";
    ImplementationGuide_ResourceFhirVersionKind["_101"] = "1.0.1";
    ImplementationGuide_ResourceFhirVersionKind["_102"] = "1.0.2";
    ImplementationGuide_ResourceFhirVersionKind["_110"] = "1.1.0";
    ImplementationGuide_ResourceFhirVersionKind["_140"] = "1.4.0";
    ImplementationGuide_ResourceFhirVersionKind["_160"] = "1.6.0";
    ImplementationGuide_ResourceFhirVersionKind["_180"] = "1.8.0";
    ImplementationGuide_ResourceFhirVersionKind["_300"] = "3.0.0";
    ImplementationGuide_ResourceFhirVersionKind["_301"] = "3.0.1";
    ImplementationGuide_ResourceFhirVersionKind["_330"] = "3.3.0";
    ImplementationGuide_ResourceFhirVersionKind["_350"] = "3.5.0";
    ImplementationGuide_ResourceFhirVersionKind["_400"] = "4.0.0";
})(ImplementationGuide_ResourceFhirVersionKind = exports.ImplementationGuide_ResourceFhirVersionKind || (exports.ImplementationGuide_ResourceFhirVersionKind = {}));
var EnumType_1 = require("../../EnumType");
exports.RTTI_ImplementationGuide_Resource = t.recursion('IImplementationGuide_Resource', function () {
    return t.intersection([
        t.type({
            reference: RTTI_Reference_1.RTTI_Reference
        }),
        t.partial({
            id: t.string,
            extension: t.array(RTTI_Extension_1.RTTI_Extension),
            modifierExtension: t.array(RTTI_Extension_1.RTTI_Extension),
            fhirVersion: t.array(EnumType_1.createEnumType(ImplementationGuide_ResourceFhirVersionKind, 'ImplementationGuide_ResourceFhirVersionKind')),
            _fhirVersion: t.array(RTTI_Element_1.RTTI_Element),
            name: t.string,
            _name: RTTI_Element_1.RTTI_Element,
            description: t.string,
            _description: RTTI_Element_1.RTTI_Element,
            exampleBoolean: t.boolean,
            _exampleBoolean: RTTI_Element_1.RTTI_Element,
            exampleCanonical: t.string,
            _exampleCanonical: RTTI_Element_1.RTTI_Element,
            groupingId: RTTI_id_1.RTTI_id,
            _groupingId: RTTI_Element_1.RTTI_Element
        })
    ]);
});
