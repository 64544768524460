"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_Appointment_Participant = exports.Appointment_ParticipantStatusKind = exports.Appointment_ParticipantRequiredKind = void 0;
var t = __importStar(require("io-ts"));
var RTTI_Extension_1 = require("./RTTI_Extension");
var RTTI_CodeableConcept_1 = require("./RTTI_CodeableConcept");
var RTTI_Reference_1 = require("./RTTI_Reference");
var RTTI_Element_1 = require("./RTTI_Element");
var RTTI_Period_1 = require("./RTTI_Period");
var Appointment_ParticipantRequiredKind;
(function (Appointment_ParticipantRequiredKind) {
    Appointment_ParticipantRequiredKind["_required"] = "required";
    Appointment_ParticipantRequiredKind["_optional"] = "optional";
    Appointment_ParticipantRequiredKind["_informationOnly"] = "information-only";
})(Appointment_ParticipantRequiredKind = exports.Appointment_ParticipantRequiredKind || (exports.Appointment_ParticipantRequiredKind = {}));
var Appointment_ParticipantStatusKind;
(function (Appointment_ParticipantStatusKind) {
    Appointment_ParticipantStatusKind["_accepted"] = "accepted";
    Appointment_ParticipantStatusKind["_declined"] = "declined";
    Appointment_ParticipantStatusKind["_tentative"] = "tentative";
    Appointment_ParticipantStatusKind["_needsAction"] = "needs-action";
})(Appointment_ParticipantStatusKind = exports.Appointment_ParticipantStatusKind || (exports.Appointment_ParticipantStatusKind = {}));
var EnumType_1 = require("../../EnumType");
exports.RTTI_Appointment_Participant = t.recursion('IAppointment_Participant', function () {
    return t.partial({
        id: t.string,
        extension: t.array(RTTI_Extension_1.RTTI_Extension),
        modifierExtension: t.array(RTTI_Extension_1.RTTI_Extension),
        type: t.array(RTTI_CodeableConcept_1.RTTI_CodeableConcept),
        actor: RTTI_Reference_1.RTTI_Reference,
        required: EnumType_1.createEnumType(Appointment_ParticipantRequiredKind, 'Appointment_ParticipantRequiredKind'),
        _required: RTTI_Element_1.RTTI_Element,
        status: EnumType_1.createEnumType(Appointment_ParticipantStatusKind, 'Appointment_ParticipantStatusKind'),
        _status: RTTI_Element_1.RTTI_Element,
        period: RTTI_Period_1.RTTI_Period
    });
});
