import {Ids} from '@canimmunize/tools';
import {Col} from 'antd';
import {Select} from 'formik-antd';
import React from 'react';
import {useStr} from '../../services/str';

interface SelectDatePickerProps {
  value: {
    day: any;
    month: any;
    year: any;
  };
  highYear: number;
  lowYear: number;
  yearOrder?: 'asc' | 'desc';
  onChange: (e) => void;
}

export const SelectDatePicker = (props: SelectDatePickerProps) => {
  const {value, onChange, highYear, lowYear, yearOrder = 'desc'} = props;
  const Str = useStr();
  const [year, setYear] = React.useState<any>();
  const [month, setMonth] = React.useState<any>();
  const [day, setDay] = React.useState<any>();

  React.useEffect(() => {
    if (value instanceof Date && value.getTime()) {
      setYear(value.getUTCFullYear());
      setMonth(value.getUTCMonth() + 1);
      setDay(value.getUTCDate());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const yearRange = Array.from({length: highYear - lowYear + 1}, (_, i) => {
    return yearOrder === 'desc' ? highYear - i : lowYear + i;
  });

  return (
    <>
      <Col span={8}>
        <Select
          placeholder="YYYY"
          showSearch
          size="large"
          name={year}
          value={year}
          onChange={(e) => {
            setYear(e);
            if (month && day) {
              const formattedDay = day < 10 ? `0${day}` : `${day}`;
              const formattedMonth = month < 10 ? `0${month}` : `${month}`;
              onChange(new Date(`${e}-${formattedMonth}-${formattedDay}`));
            }
          }}
        >
          {yearRange.map((n) => {
            return (
              <Select.Option value={n} key={n}>
                {n}
              </Select.Option>
            );
          })}
        </Select>
      </Col>
      <Col span={8}>
        <Select
          placeholder="MM"
          showSearch
          size="large"
          name={month}
          value={month}
          onChange={(e) => {
            setMonth(e);
            if (day && year) {
              const formattedMonth = e < 10 ? `0${e}` : `${e}`;
              const formattedDay = day < 10 ? `0${day}` : `${day}`;
              onChange(new Date(`${year}-${formattedMonth}-${formattedDay}`));
            }
          }}
          filterOption={(input, option: any) => {
            if (isNaN(+input)) {
              return (
                option.children
                  .toLowerCase()
                  .normalize('NFD')
                  .replace(/[\u0300-\u036f]/g, '')
                  .indexOf(
                    input
                      ?.toLowerCase()
                      .normalize('NFD')
                      .replace(/[\u0300-\u036f]/g, '')
                  ) >= 0
              );
            } else {
              return +input === option.value;
            }
          }}
        >
          {[
            Str(Ids.january),
            Str(Ids.february),
            Str(Ids.march),
            Str(Ids.april),
            Str(Ids.may),
            Str(Ids.june),
            Str(Ids.july),
            Str(Ids.august),
            Str(Ids.september),
            Str(Ids.october),
            Str(Ids.november),
            Str(Ids.december),
          ].map((n, i) => {
            return (
              <Select.Option value={i + 1} key={n}>
                {n}
              </Select.Option>
            );
          })}
        </Select>
      </Col>
      <Col span={8}>
        <Select
          placeholder="DD"
          showSearch
          size="large"
          name={day}
          value={day}
          onChange={(e) => {
            setDay(e);
            if (month && year) {
              const formattedDay = e < 10 ? `0${e}` : `${e}`;
              const formattedMonth = month < 10 ? `0${month}` : `${month}`;
              onChange(new Date(`${year}-${formattedMonth}-${formattedDay}`));
            }
          }}
        >
          {Array.from({length: 31}, (_, i) => i + 1).map((n) => {
            return (
              <Select.Option value={n} key={n}>
                {n}
              </Select.Option>
            );
          })}
        </Select>
      </Col>
    </>
  );
};
