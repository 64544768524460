import {get} from 'lodash';
import React from 'react';
import {
  AntigenDetail,
  DiseaseDetail,
  GenericDetail,
  GtinDetail,
  LotDetail,
  NIDDDetail,
  TradenameDetail,
} from './terminology-detailed-views';

export const convertTableConfigToCols = (tableConfig) => {
  return tableConfig.headers.map((header, i) => {
    return {
      title: header.label,
      dataIndex: tableConfig.rowFormats[i].field,
      key: tableConfig.rowFormats[i].field,
      render: (text, item) => {
        return get(item, tableConfig.rowFormats[i].field, '');
      },
    };
  });
};

export const BaseHeader = () => {
  return <div></div>;
};

export const antigenDiseaseTableConfig = {
  headers: [
    {weight: 9, mobileWeight: 6, label: 'Display Term'},
    {weight: 3, mobileWeight: 6, label: 'Concept ID'},
  ],
  rowFormats: [
    {weight: 9, mobileWeight: 6, field: 'displayTerm.en'},
    {weight: 3, mobileWeight: 6, field: 'conceptId'},
  ],
};

export const niddTableConfig = {
  headers: [
    {weight: 9, mobileWeight: 6, label: 'Display Term'},
    {weight: 3, mobileWeight: 6, label: 'Concept ID'},
  ],
  rowFormats: [
    {weight: 9, mobileWeight: 6, field: 'displayTerm.en'},
    {weight: 3, mobileWeight: 6, field: 'conceptId'},
  ],
};

export const tabConfig = [
  {
    key: 'generics',
    tabTitle: 'Generics',
    title: 'Generic Immunizing Agents',
    description:
      'Generic Immunizing Agents subset contains generically described vaccines and passive immunizing agents used in Canada. It is an extension of the Pan-Canadian Immunization Reference Sets Vaccine Historical Name Code and Passive Historical Immunizing Agent Code subset developed by Canada Health Infoway.',
    tableConfig: {
      headers: [
        {weight: 2, mobileWeight: 6, label: 'Abbreviation'},
        {weight: 7, mobileWeight: 6, label: 'Display Term'},
        {weight: 3, mobileWeight: 0, label: 'Concept ID'},
      ],
      rowKeyField: 'code',
      rowFormats: [
        {weight: 2, mobileWeight: 6, field: 'abbreviation.en'},
        {weight: 7, mobileWeight: 6, field: 'publicPicklistTerm.en'},
        {weight: 3, mobileWeight: 0, field: 'conceptId'},
      ],
    },
    header: BaseHeader,
    detail: GenericDetail,
  },
  {
    key: 'tradenames',
    tabTitle: 'Tradenames',
    title: 'Tradename Immunizing Agents',
    description:
      'Tradename Immunizing Agents subset contains tradenames of vaccines and passive immunizing agents used in Canada. It is an extension of the Pan-Canadian Immunization Reference Sets Vaccine Administered Trade Name Code and Immunioglobulin Code subset developed by Canada Health Infoway.',
    tableConfig: {
      headers: [
        {weight: 2, mobileWeight: 6, label: 'Abbreviation'},
        {weight: 7, mobileWeight: 6, label: 'Display Term'},
        {weight: 3, mobileWeight: 0, label: 'Concept ID'},
      ],
      rowKeyField: 'code',
      rowFormats: [
        {weight: 2, mobileWeight: 6, field: 'abbreviation.en'},
        {weight: 7, mobileWeight: 6, field: 'publicPicklistTerm.en'},
        {weight: 3, mobileWeight: 0, field: 'conceptId'},
      ],
    },
    header: BaseHeader,
    detail: TradenameDetail,
  },
  {
    key: 'diseases',
    tabTitle: 'Diseases',
    title: 'Vaccine Preventable Disease',
    description:
      'The Vaccine Preventable Disease subset is an extension of the Pan-Canadian Immunization Reference Sets Vaccine Preventable Disease Code subset developed by Canada Health Infoway.',
    tableConfig: antigenDiseaseTableConfig,
    header: BaseHeader,
    detail: DiseaseDetail,
  },
  {
    key: 'antigens',
    tabTitle: 'Antigen/Immunoglobulin/Antitoxin',
    title: 'Antigen/Immunoglobulin/Antitoxin',
    description:
      'Antigen/Immunoglobulin/Antitoxin subset is an extension of the Pan-Canadian Immunization Reference Sets Antigen Code and Immunoglobulin Code, subset developed by Canada Health Infoway.',
    tableConfig: antigenDiseaseTableConfig,
    header: BaseHeader,
    detail: AntigenDetail,
  },
  {
    key: 'adminGender',
    tabTitle: 'Administrative Gender',
    title: 'Administrative Gender',
    description:
      'Administrative gender subset contains the gender of a person used for administrative purposes (as opposed to clinical gender). This subset is an extension of the AdministrativeGenderCode subset developed by Canada Health Infoway. ',
    tableConfig: niddTableConfig,
    header: BaseHeader,
    detail: NIDDDetail,
  },
  {
    key: 'anatomicalSite',
    tabTitle: 'Anatomical Site',
    title: 'Anatomical Site',
    description:
      'Anatomical Site subset describes where the body the immunization was administered. The subset is an extension of the AnatomicalSiteCode subset developed by Canada Health Infoway.',
    tableConfig: niddTableConfig,
    header: BaseHeader,
    detail: NIDDDetail,
  },
  {
    key: 'repSource',
    tabTitle: 'Reporting Source',
    title: 'Reporting Source',
    description:
      'The Reporting Source picklist identifies the source from which this immunization record originated.',
    tableConfig: niddTableConfig,
    header: BaseHeader,
    detail: NIDDDetail,
  },
  {
    key: 'routeOfAdmin',
    tabTitle: 'Route Of Administration',
    title: 'Route Of Administration',
    description:
      'Route of Administration subset describes which path the administered immunization takes to get into the body. This subset is an extension of the RouteOfAdministration subset developed by Canada Health Infoway.',
    tableConfig: niddTableConfig,
    header: BaseHeader,
    detail: NIDDDetail,
  },
  {
    key: 'forecastStatus',
    tabTitle: 'Forecast Status',
    title: 'Forecast Status',
    description:
      "Forecast status subset represents the patient's status with respect to immunization guidelines as of an evaluation date. The subset is an extension of the Immunization Forcast Status developed by Canada Health Infoway.",
    tableConfig: niddTableConfig,
    header: BaseHeader,
    detail: NIDDDetail,
  },
  {
    key: 'shelfStatus',
    tabTitle: 'Shelf Status',
    title: 'Shelf Status',
    description:
      'Shelf Status subset describes if the agent/tradename is or has been licensed for use in Canada, is part of a special access program or is an international vaccine.',
    tableConfig: niddTableConfig,
    header: BaseHeader,
    detail: NIDDDetail,
  },
  {
    key: 'lotData',
    tabTitle: 'Lot Lookup',
    title: 'Vaccine Lots',
    description:
      'This list contains Lot Number and Expiry data for all vaccines licensed for use by Health Canada. Each lot is mapped to its corresponding Tradename Vaccine concept. The lot information in the Canadian Vaccine Catalogue is updated monthly by Health Canada.',
    tableConfig: {
      headers: [
        {weight: 2, mobileWeight: 6, label: 'Lot Number'},
        {weight: 2, mobileWeight: 0, label: 'DIN'},
        {weight: 8, mobileWeight: 6, label: 'Concept'},
      ],
      rowFormats: [
        {weight: 2, mobileWeight: 6, field: 'lotNumber'},
        {weight: 2, mobileWeight: 0, field: 'din'},
        {weight: 8, mobileWeight: 6, field: 'concept.publicPicklistTerm.en'},
      ],
    },
    header: BaseHeader,
    detail: LotDetail,
  },
  {
    key: 'gtins',
    tabTitle: 'GTIN Lookup',
    title: 'Global Trade Item Numbers (GTIN)',
    description:
      'This list contains Global Trade Item Numbers (GTIN) for vaccines in Canada. Each GTIN is mapped to its corresponding Tradename Vaccine Concept. The GTIN information in the Canadian Vaccine Catalogue is provided by vaccine manufacturers via the GS1® ECCnet Registry.',
    tableConfig: {
      headers: [
        {weight: 2, mobileWeight: 6, label: 'GTIN'},
        {weight: 7, mobileWeight: 6, label: 'Product'},
        {weight: 3, mobileWeight: 0, label: 'Hierarchy'},
      ],
      rowFormats: [
        {weight: 2, mobileWeight: 6, field: 'gtin'},
        {weight: 7, mobileWeight: 6, field: 'concept.publicPicklistTerm.en'},
        {weight: 3, mobileWeight: 0, field: 'productType'},
      ],
    },
    header: BaseHeader,
    detail: GtinDetail,
  },
];
