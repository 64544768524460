import {QuestionCircleOutlined} from '@ant-design/icons';
import {Ids} from '@canimmunize/tools';
import {Alert, Button, Col, Popover, Row} from 'antd';
import MaskedInput from 'antd-mask-input';
import axios from 'axios';
import {FormikContextType, useFormikContext} from 'formik';
import {Form, Input, Select} from 'formik-antd';
import parse from 'html-react-parser';
import React from 'react';
import {SelectDatePicker} from '../../../../../components/pickers/select-datepicker';
import {ValidateFormItem} from '../../../../../components/validate-form-item';
import {FhirUtils} from '../../../../../services/fhir';
import {useStr} from '../../../../../services/str';
import {
  PhoneValidationRules,
  PostalCodeValidationRules,
} from '../../../../../services/ui-validation-rules';
import {RequiredValidationRuleId} from '../../../../../validation/validation-rules/required-validation';
import {YukonPortalPage} from '../../../components/yukon-portal-page';
import {useCOVIDPortalConfig} from '../../../util/portal-config';
import {SessionConfigContext} from '../../../util/session-config';
import styles from './index.module.css';

export const YukonHCIForm = (props) => {
  const Str = useStr();
  const formikContext: FormikContextType<any> = useFormikContext();
  const {syncUrl} = useCOVIDPortalConfig();
  const [submitting, setSubmitting] = React.useState(false);
  const fhirClient = FhirUtils.useAxiosClient();
  const {setSessionId} = React.useContext(SessionConfigContext);

  const handleCallCenterSubmit = async () => {
    formikContext.setFieldValue('error', undefined);

    const result = await fhirClient
      .post('/yk-pvc-call-center/auth', {...formikContext.values})
      .catch((err) => err.response);

    setSubmitting(false);
    if (result.status === 200) {
      formikContext.setFieldValue('patientInfo', result.data);
      formikContext.setFieldValue('requestorName', {
        firstName: result.data?.requestorFirstName,
        middleName: result.data?.requestorMiddleName,
        lastName: result.data?.requestorLastName,
      });
      formikContext.setFieldValue('deliveryMethod', 'mail');
      formikContext.setFieldValue('mode', 'cred-delivery');
      if (setSessionId) {
        setSessionId(result.data?.sessionId);
      }
    } else if (result.status === 404) {
      formikContext.setFieldValue(
        'error',
        <>
          {parse(
            Ids[result.data.error.stringId]
              ? Str(Ids[result.data.error.stringId])
              : Str(result.data.error.message)
          )}
        </>
      );
      window.scrollTo(0, 0);
    } else {
      formikContext.setFieldValue('error', <>{parse(Str(Ids.yk_pvc_system_error))}</>);
      window.scrollTo(0, 0);
    }
  };

  const postSubmit = async () => {
    setSubmitting(true);
    let filledFieldCount = 0;
    [
      formikContext.values.firstName,
      formikContext.values.phone,
      formikContext.values.postalCode,
      formikContext.values.vaxLotNum,
    ].forEach((field) => {
      if (field !== '') filledFieldCount++;
    });

    if (formikContext.values.dateFirstDose) {
      filledFieldCount++;
    }

    if (filledFieldCount <= 1) {
      formikContext.setFieldValue(
        'error',
        Str(
          formikContext.values.isYukonCallCentre
            ? Ids.yk_hci_few_fields_error_cc
            : Ids.yk_hci_few_fields_error
        )
      );
      window.scrollTo(0, 0);
      setSubmitting(false);
      return;
    }

    if (formikContext.values.isYukonCallCentre) return handleCallCenterSubmit();

    formikContext.setFieldValue('error', undefined);
    const result = await axios
      .post(`${syncUrl}/public/portal/auth`, {
        ...formikContext.values,
        formType: 'yukon-portal-hci',
      })
      .catch((err) => err.response);

    setSubmitting(false);
    if (result.status === 200) {
      formikContext.setFieldValue('authContactMethods', result.data?.contactMethods);
      formikContext.setFieldValue('requestorName', {
        firstName: result.data?.requestorFirstName,
        middleName: result.data?.requestorMiddleName,
        lastName: result.data?.requestorLastName,
      });
      if (result.data?.contactMethods.length === 1) {
        formikContext.setFieldValue('selectedAuthContactMethod', result.data?.contactMethods[0]);
      }
      formikContext.setFieldValue('portalId', result.data?.portalId);
      formikContext.setFieldValue('mode', 'verification');
      if (!result.data?.contactMethods || result.data.contactMethods.length === 0) {
        formikContext.setFieldValue('error', <>{parse(Str(Ids.yk_no_contact_error))}</>);
      }
    } else if (result.status === 404) {
      formikContext.setFieldValue(
        'error',
        <>
          {parse(
            Ids[result.data.error.stringId]
              ? Str(Ids[result.data.error.stringId])
              : Str(result.data.error.message)
          )}
        </>
      );
      window.scrollTo(0, 0);
    } else {
      formikContext.setFieldValue('error', <>{parse(Str(Ids.yk_pvc_system_error))}</>);
      window.scrollTo(0, 0);
    }
  };

  const requestInfo = (
    <Alert type="info" message={<>{Str(Ids.yk_requesting_for_other_info)}</>}></Alert>
  );

  const pageBody = (
    <>
      <div style={{fontSize: '14px', marginBottom: '20px'}}>
        {parse(
          Str(
            formikContext.values.isYukonCallCentre
              ? Ids.yk_info_must_match_cc
              : Ids.yk_info_must_match
          )
        )}
      </div>
      {formikContext.values.error && (
        <Alert
          type="error"
          message={formikContext.values.error}
          style={{marginBottom: 15}}
          showIcon
        />
      )}
      <Row gutter={24} style={{alignItems: 'flex-end'}}>
        <Col span={24} md={12}>
          <Form.Item label={Str(Ids.yk_first_name)} name="firstName">
            <Input name="firstName" size="large" />
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <ValidateFormItem
            validationRules={[
              {
                validationRuleType: RequiredValidationRuleId,
              },
            ]}
            renderFormItem={(validate) => {
              return (
                <Form.Item
                  label={Str(Ids.yk_last_name)}
                  name="lastName"
                  validate={validate}
                  required
                >
                  <Input name="lastName" size="large" />
                </Form.Item>
              );
            }}
          />
        </Col>
      </Row>

      <Row gutter={24} style={{alignItems: 'flex-end'}}>
        <Col span={24} md={12}>
          <ValidateFormItem
            validationRules={[
              {
                validationRuleType: RequiredValidationRuleId,
              },
            ]}
            renderFormItem={(validate) => {
              return (
                <Form.Item
                  label={Str(Ids.yk_date_of_birth)}
                  name="birthdate"
                  required
                  validate={validate}
                >
                  <Row gutter={12}>
                    <SelectDatePicker
                      value={formikContext.values.birthdate}
                      onChange={(e) => formikContext.setFieldValue('birthdate', e)}
                      highYear={new Date().getFullYear()}
                      lowYear={new Date().getFullYear() - 120}
                    />
                  </Row>
                </Form.Item>
              );
            }}
          />
        </Col>
        <Col span={24} md={12}>
          <ValidateFormItem
            validationRules={[
              {
                validationRuleType: RequiredValidationRuleId,
              },
            ]}
            renderFormItem={(validate) => {
              return (
                <Form.Item
                  label={Str(Ids.yk_pvc_sex)}
                  name="sex"
                  required
                  validate={validate}
                  tooltip={Str(Ids.yk_pvc_sex_tooltip)}
                >
                  <Select
                    placeholder={Str(Ids.yk_pvc_select_placeholder)}
                    showSearch
                    style={{width: '100%'}}
                    size="large"
                    name="sex"
                    value={formikContext.values.sex}
                    onChange={(e) => formikContext.setFieldValue('sex', e)}
                    filterOption={(input, option: any) =>
                      option.children
                        .toLowerCase()
                        .normalize('NFD')
                        .replace(/[\u0300-\u036f]/g, '')
                        .indexOf(
                          input
                            ?.toLowerCase()
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')
                        ) >= 0
                    }
                  >
                    <Select.Option value={1} key="F">
                      {Str(Ids.yk_sex_female)}
                    </Select.Option>
                    <Select.Option value={0} key="M">
                      {Str(Ids.yk_sex_male)}
                    </Select.Option>
                    <Select.Option value={2} key="X">
                      {Str(Ids.yk_sex_other)}
                    </Select.Option>
                  </Select>
                </Form.Item>
              );
            }}
          />
        </Col>
      </Row>

      <Row gutter={24} style={{alignItems: 'flex-end'}}>
        <Col span={24} md={12}>
          <ValidateFormItem
            validationRules={[...PostalCodeValidationRules]}
            renderFormItem={(validate) => {
              return (
                <Form.Item
                  label={Str(Ids.yk_postal_code)}
                  name="postalCode"
                  tooltip={Str(Ids.yk_postal_code_tooltip)}
                  validate={validate}
                >
                  <Input name="postalCode" size="large" />
                </Form.Item>
              );
            }}
          />
        </Col>
        <Col span={24} md={12}>
          <ValidateFormItem
            validationRules={[...PhoneValidationRules]}
            renderFormItem={(validate) => {
              return (
                <Form.Item
                  label={Str(Ids.yk_contact_phone_number)}
                  name="phone"
                  validate={validate}
                >
                  <MaskedInput
                    defaultValue={formikContext.values.phone}
                    onChange={(e) => {
                      formikContext.setFieldValue('phone', e.target.value);
                    }}
                    name="phone"
                    size="large"
                    mask="(111) 111-1111"
                  />
                </Form.Item>
              );
            }}
          />
        </Col>
      </Row>

      <Row gutter={24} style={{alignItems: 'flex-end'}}>
        <Col span={24} md={12}>
          <Form.Item
            label={
              <>
                {Str(
                  formikContext.values.isYukonCallCentre
                    ? Ids.yk_date_first_dose_cc
                    : Ids.yk_date_first_dose
                )}
                <Popover
                  content={parse(Str(Ids.yk_vax_card_tooltip))}
                  overlayClassName={styles.tooltip}
                >
                  <QuestionCircleOutlined style={{marginLeft: '4px', color: '#959596'}} />
                </Popover>
              </>
            }
            name="dateFirstDose"
          >
            <Row gutter={12}>
              <SelectDatePicker
                value={formikContext.values.dateFirstDose}
                onChange={(e) => formikContext.setFieldValue('dateFirstDose', e)}
                highYear={new Date().getFullYear()}
                lowYear={2020}
              />
            </Row>
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <Form.Item
            label={
              <>
                {Str(
                  formikContext.values.isYukonCallCentre
                    ? Ids.yk_vaccine_lot_num_cc
                    : Ids.yk_vaccine_lot_num
                )}
                <Popover
                  content={parse(Str(Ids.yk_vax_card_tooltip))}
                  overlayClassName={styles.tooltip}
                >
                  <QuestionCircleOutlined style={{marginLeft: '4px', color: '#959596'}} />
                </Popover>
              </>
            }
            name="vaxLotNum"
          >
            <Input name="vaxLotNum" size="large" />
          </Form.Item>
        </Col>
      </Row>
      {formikContext.values.isYukonCallCentre && (
        <Row gutter={24} style={{alignItems: 'flex-end'}}>
          <Col span={24} md={12}>
            <ValidateFormItem
              validationRules={[
                {
                  validationRuleType: RequiredValidationRuleId,
                },
              ]}
              renderFormItem={(validate) => {
                return (
                  <Form.Item
                    label={
                      <>
                        {Str(Ids.yk_date_recent_dose)}
                        <Popover
                          content={parse(Str(Ids.yk_vax_card_tooltip))}
                          overlayClassName={styles.tooltip}
                        >
                          <QuestionCircleOutlined style={{marginLeft: '4px', color: '#959596'}} />
                        </Popover>
                      </>
                    }
                    name="dateRecentDose"
                    validate={validate}
                    required={formikContext.values.isYukonCallCentre}
                  >
                    <Row gutter={12}>
                      <SelectDatePicker
                        value={formikContext.values.dateRecentDose}
                        onChange={(e) => formikContext.setFieldValue('dateRecentDose', e)}
                        highYear={new Date().getFullYear()}
                        lowYear={2020}
                      />
                    </Row>
                  </Form.Item>
                );
              }}
            />
          </Col>
          <Col span={24} md={12}>
            <ValidateFormItem
              validationRules={[
                {
                  validationRuleType: RequiredValidationRuleId,
                },
              ]}
              renderFormItem={(validate) => {
                return (
                  <>
                    <Form.Item
                      label={
                        <>
                          {Str(Ids.yk_vaccine_lot_num_recent)}
                          <Popover
                            content={parse(Str(Ids.yk_vax_card_tooltip))}
                            overlayClassName={styles.tooltip}
                          >
                            <QuestionCircleOutlined style={{marginLeft: '4px', color: '#959596'}} />
                          </Popover>
                        </>
                      }
                      name="vaxLotNumRecentDose"
                      validate={validate}
                      required={formikContext.values.isYukonCallCentre}
                    >
                      <Input name="vaxLotNumRecentDose" size="large" />
                    </Form.Item>
                  </>
                );
              }}
            />
          </Col>
        </Row>
      )}
    </>
  );

  const pageFooter = (
    <>
      <Button
        size="large"
        onClick={() => {
          formikContext.setFieldValue('error', undefined);
          formikContext.setFieldValue('mode', 'app-info');
        }}
        style={{
          border: '1px solid',
          marginRight: '10px',
          marginBottom: '10px',
          width: '200px',
        }}
      >
        {Str(Ids.yk_pvc_go_back_step)}
      </Button>
      <Button
        size="large"
        type="primary"
        loading={submitting}
        onClick={() => {
          formikContext.setFieldValue('subFormSubmit', postSubmit);
          formikContext.handleSubmit();
        }}
        style={{
          width: '200px',
        }}
      >
        {Str(Ids.yk_pvc_next_step)}
      </Button>
    </>
  );

  return (
    <>
      <YukonPortalPage
        pageNumber={
          formikContext.values.isYukonCallCentre ? Str(Ids.yk_cc_page3) : Str(Ids.yk_pvc_page3)
        }
        preTitle={formikContext.values.reqForSelf ? undefined : requestInfo}
        title={Str(Ids.yk_pvc_hci)}
        body={pageBody}
        footer={pageFooter}
        backPage="app-info"
      ></YukonPortalPage>
    </>
  );
};
