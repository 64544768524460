import {CVC} from '@canimmunize/cvc-js';
import {createSlice} from '@reduxjs/toolkit';

export interface TerminologySliceInterface {
  cvcLoaded: boolean;
}
const initialState = {
  cvcLoaded: false,
};

const slice = createSlice({
  name: 'terminology',
  initialState,
  reducers: {
    SAVE_TERMINOLOGY_BUNDLE: (state: TerminologySliceInterface) => {
      state.cvcLoaded = true;
    },
  },
});

const getAll = () => async (dispatch, getState) => {
  let cvc = CVC.get();
  if (cvc) {
    return cvc;
  }

  cvc = await CVC.initFromApi('https://cvc.canimmunize.ca/v3');

  dispatch(slice.actions.SAVE_TERMINOLOGY_BUNDLE());
};

export default {
  slice,
  getAll,
};

