import {faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Row, Select, Tag} from 'antd';
import {FieldArray} from 'formik';
import {Form} from 'formik-antd';
import {useEffect, useState} from 'react';
import {Organization} from '../../models/organizations';
import {RoleDefinition} from '../../services/roles/abilities';
import {ValidationRules} from '../../validation/validation-rules/validation-rules';
import {ValidateFormItem} from '../validate-form-item';
import React from 'react';
import {FhirUtils} from '../../services/fhir';
import {useGlobalOrg} from '../../models/ui';

interface RoleMultiPickerFormItemProps {
  label?: string;
  name: string;
  setFieldValue: (name: string, value: any) => void;
  item?: Organization;
  validationRules?: ValidationRules[];
  required?: boolean;
}

export const RoleMultiPickerFormItem = (props: RoleMultiPickerFormItemProps) => {
  const globalOrg = useGlobalOrg();
  const client = FhirUtils.useClient();
  const [pickableRoles, setPickableRoles] = useState<RoleDefinition[]>([]);
  const [selectionDisplay, setSelectionDisplay] = useState<string | undefined>(undefined);

  useEffect(() => {
    const params = {assignable: true};
    client
      .get(`role`, {params})
      .then((res) => setPickableRoles(res.data.entry.map((e) => e.resource)));
  }, [globalOrg]);

  useEffect(() => setSelectionDisplay(undefined), [selectionDisplay]);

  return (
    <ValidateFormItem
      validationRules={props.validationRules || []}
      renderFormItem={(validate) => {
        return (
          <Form.Item
            name={props.name || 'roles'}
            label={props.label || 'Roles'}
            validate={validate}
            tooltip={props.required ? {icon: <span style={{color: 'red', fontSize: 14, fontWeight: 'bolder'}}>*</span>,} : {}}
          >
            <FieldArray
              name="roles"
              render={(arrayHelpers) => (
                <>
                  <Select
                    showSearch
                    placeholder="Select one or more roles"
                    optionFilterProp="children"
                    value={selectionDisplay}
                    onChange={(value: string) => {
                      arrayHelpers.push({id: value});
                      setSelectionDisplay(value);
                    }}
                    filterOption={(input, option: any) =>
                      option.children.toLowerCase().indexOf(input?.toLowerCase()) >= 0
                    }
                  >
                    {pickableRoles
                      .reduce((a: RoleDefinition[], o: RoleDefinition) => {
                        return !!arrayHelpers.form.values.roles?.find(
                          (o2: Organization) => o.id === o2.id
                        )
                          ? a
                          : [...a, o];
                      }, [])
                      .map((o) => (
                        <Select.Option value={o.id!} key={o.id}>
                          {o.name || o.id}
                        </Select.Option>
                      ))}
                  </Select>
                  <Row>
                    {arrayHelpers.form.values.roles?.map((o, i) => (
                      <Tag
                        key={o.id}
                        style={{marginTop: '8px'}}
                        onClick={() => arrayHelpers.remove(i)}
                      >
                        {pickableRoles.find((rpick: RoleDefinition) => o.id === rpick.id)?.name}
                        <FontAwesomeIcon icon={faTimesCircle} style={{marginLeft: 10}} />
                      </Tag>
                    ))}
                  </Row>
                </>
              )}
            />
          </Form.Item>
        );
      }}
    />
  );
};
