import {CVCGenericConcept, CVCTradenameConcept} from '@canimmunize/cvc-js';
import {Col, Row, Tabs, Typography} from 'antd';
import React from 'react';
import {DetailedSection, EnglishFrenchOutput, ExpandableList} from './terminology-misc-components';
import {
  FieldTitleVertical,
  FieldTitleHorizontal,
  GeneralTitle,
  IconDisplay,
} from './terminology-titles';

const {Title, Paragraph, Text} = Typography;
const {TabPane} = Tabs;

export const CoreInfoSection = (props: {
  concept: CVCGenericConcept | CVCTradenameConcept;
  superTitle: string;
}) => {
  return (
    <div>
      <GeneralTitle title={props.superTitle} />
      <IconDisplay
        content={props.concept.abbreviation.en}
        displayTerm={props.concept.publicPicklistTerm.en}
      />
      <DetailedSection title="Pan-Canadian SNOMED CT Terminology" titlePresence={true}>
        <Row gutter={[16, 16]}>
          <Col span={12} style={{maxWidth: '100%'}}>
            <FieldTitleHorizontal title="Concept ID">
              {props.concept.conceptId}
            </FieldTitleHorizontal>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12} style={{flex: '0 0 90%', maxWidth: '100%'}}>
            <FieldTitleHorizontal title="Fully Specified Name">
              {props.concept.fsn}
            </FieldTitleHorizontal>
          </Col>
        </Row>
      </DetailedSection>
      <DetailedSection title="Canadian Vaccine Catalogue Info" titlePresence={true}>
        <Row gutter={[16, 16]}>
          <Col span={12} style={{maxWidth: '33%'}}>
            <FieldTitleHorizontal title="Product on Shelf">
              {props.concept.shelfStatus}
            </FieldTitleHorizontal>
          </Col>
          <Col span={12} style={{flex: '0 0 50%', maxWidth: '50%'}}>
            <FieldTitleHorizontal title="Last Updated">
              {props.concept.lastUpdated.format('YYYY-MM-DD')}
            </FieldTitleHorizontal>
          </Col>
        </Row>
      </DetailedSection>
      <DetailedSection title="Clinician Friendly Picklist Terminology" titlePresence={true}>
        <Row gutter={[16, 16]}>
          <Col span={12} style={{maxWidth: '33%'}}>
            <FieldTitleVertical title="Abbreviation:" />
            <div style={{maxWidth: '80%'}}>
              <EnglishFrenchOutput Lang={props.concept.abbreviation} />
            </div>
          </Col>
          <Col span={12} style={{maxWidth: '33%'}}>
            <FieldTitleVertical title="Clinician Friendly Picklist Term:" />
            <div style={{maxWidth: '80%'}}>
              <EnglishFrenchOutput Lang={props.concept.clinicianPicklistTerm} />
            </div>
          </Col>
          <Col span={12} style={{maxWidth: '33%'}}>
            <FieldTitleVertical title="Public Picklist Term:" />
            <div style={{maxWidth: '80%'}}>
              <EnglishFrenchOutput Lang={props.concept.publicPicklistTerm} />
            </div>
          </Col>
        </Row>
      </DetailedSection>
    </div>
  );
};

export const ProductInfoSection = (props: {concept: CVCTradenameConcept}) => {
  return (
    <div style={{marginBottom: '-25px'}}>
      <DetailedSection title="Linked Product Data" titlePresence={true}>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <FieldTitleVertical title="Global Trade Item Numbers" />
            <ExpandableList
              items={props.concept.gtinData.map((gtinElem) => {
                return {title: gtinElem.gtin, link: `/terminology/gtins/${gtinElem.conceptId}`};
              })}
            />
          </Col>
          <Col span={12}>
            <FieldTitleVertical title="Lot Numbers" />
            <ExpandableList
              items={props.concept.lots.map((lot) => {
                return {title: lot.lotNumber, link: `/terminology/gtins/${lot.conceptId}`};
              })}
            />
          </Col>
        </Row>
      </DetailedSection>
    </div>
  );
};

export const RemainingLinkedConcepts = (props: {
  concept: CVCGenericConcept | CVCTradenameConcept;
}) => {
  return (
    <div>
      <FieldTitleVertical title="This vaccine protects against the following diseases:" />
      <div>
        <ExpandableList
          items={props.concept.diseases.map((vaccElem) => {
            return {title: vaccElem.fsn, link: `/terminology/diseases/${vaccElem.conceptId}`};
          })}
        />
      </div>
      <FieldTitleVertical title="This vaccine protects against the following antigens:" />
      <div>
        <ExpandableList
          items={props.concept.antigens.map((antiElem) => {
            return {title: antiElem.fsn, link: `/terminology/antigens/${antiElem.conceptId}`};
          })}
        />
      </div>
    </div>
  );
};

export const LinkedGenericConcepts = (props: {concept: CVCGenericConcept}) => {
  return (
    <div>
      <DetailedSection title="Linked Concepts" titlePresence={true}>
        <FieldTitleVertical title="Linked Tradename Concepts" />
        <ExpandableList
          items={props.concept.childTradenames.map((elem) => {
            return {
              title: elem.publicPicklistTerm.en,
              link: `/terminology/tradenames/${elem.conceptId}`,
            };
          })}
        />
      </DetailedSection>
    </div>
  );
};

export const LinkedTradenameConcepts = (props: {concept: CVCTradenameConcept}) => {
  const linkStyle = {color: '#8c8c8c'};
  return (
    <div>
      <DetailedSection title="Linked Concepts" titlePresence={true}>
        <FieldTitleVertical title="Linked Generic Concepts" />
        <div>
          {props.concept.parentConcept === undefined ? (
            <Text disabled>None</Text>
          ) : (
            <div style={{marginBottom: '20px'}}>
              <a
                style={linkStyle}
                href={`/terminology/generics/${props.concept.genericParentConceptId}`}
              >
                {props.concept.parentConcept.publicPicklistTerm.en}
              </a>
            </div>
          )}
        </div>
      </DetailedSection>
    </div>
  );
};
