import {SyncOutlined} from '@ant-design/icons';
import {useAbility} from '@casl/react';
import {Button, Col, Modal, Progress, Row, Space, Tag, Typography} from 'antd';
import moment from 'moment';
import React from 'react';
import {useSelector} from 'react-redux';
import {FHIRTable} from '../components/fhir-table';
import {ImportCard} from '../components/imports/import-card';
import {importerConfigs} from '../components/imports/upload-modals';
import {CITFPatientUploadModal} from '../components/imports/upload-modals/citf-upload-modal';
import {ClinicUploadModal} from '../components/imports/upload-modals/clinic-upload-modal';
import {ImportResultView, UploadState} from '../components/imports/upload-modals/importer-modal';
import {CohortPatientUploadModal} from '../components/imports/upload-modals/patient-upload-modal';
import {RootState} from '../models';
import {clinicUploadTemplateName} from '../models/clinics';
import {cohortUploadTemplateName} from '../models/cohort';
import {useGlobalOrg} from '../models/ui';
import {AbilityContext} from '../services/roles/ability-context';
import {getExtensionValue} from '../util/fhir';
const {Title} = Typography;

export const ImportView = () => {
  const ability = useAbility(AbilityContext);
  const selectedOrg = useGlobalOrg();
  const [visibleImport, setVisibleImport] = React.useState<string | undefined>();
  const importRefreshTrigger = useSelector((state: RootState) => state.ui.importListRefreshTrigger);

  const logoUrl = selectedOrg
    ? getExtensionValue(selectedOrg!, 'https://canimmunize.ca/StructureDefinition/org-logo-url')
    : undefined;
  return (
    <>
      <Row gutter={[16, 16]}>
        <Col md={12}>
          {logoUrl ? (
            <img src={logoUrl} style={{maxWidth: 200}} alt={`${selectedOrg?.name.en} Logo`} />
          ) : (
            <Title
              level={1}
              style={{
                color: 'white',
                marginBottom: '.5em',
              }}
            >
              Import
            </Title>
          )}
        </Col>
      </Row>
      {ability.can('import', 'clinics') && (
        <ImportCard
          title="Clinics"
          description="Set up multiple clinics at once by uploading a spreadsheet"
          buttonLabel="Import Clinics"
          templateName={clinicUploadTemplateName}
          modal={ClinicUploadModal}
        ></ImportCard>
      )}
      {ability.can('import', 'patients') && (
        <ImportCard
          title="Patients (and Immunizations) in Cohorts"
          description="Upload patient demographic and/or immunization data for those in a cohort."
          buttonLabel="Import Patients"
          templateName={cohortUploadTemplateName}
          modal={CohortPatientUploadModal}
        ></ImportCard>
      )}
      {ability.can('import', 'patients-citf') && (
        <ImportCard
          title="CITF Patient Import"
          description="Upload patient demographic and/or immunization data for patients in the CITF study."
          buttonLabel="Import Patients"
          templateName={importerConfigs['citf-patient-import'].templateName}
          modal={CITFPatientUploadModal}
        ></ImportCard>
      )}

      {ability.can('view', 'importHistory') && (
        <>
          <h4 style={{color: 'white'}}>Import History</h4>
          <FHIRTable
            fhirResource="import"
            mode="table"
            triggerRefresh={importRefreshTrigger}
            onClickRow={(row) => {
              setVisibleImport(row);
            }}
            columns={[
              {title: 'Import Type', render: (_, data) => importerConfigs[data.importType].name},
              {title: 'Timestamp', key: 'updatedAt', dataIndex: 'updatedAt'},
              {title: 'User', key: 'user', dataIndex: 'user'},
              {title: 'Status', render: (_, item) => <ImportStatusCell item={item} />},
            ]}
          />
          <ImportDetailViewModal
            title="Previous Import"
            visible={!!visibleImport}
            visibleImport={visibleImport}
            setVisibleImport={setVisibleImport}
          />
        </>
      )}
    </>
  );
};

const ImportStatusCell = ({item}) => {
  switch (item.status) {
    case UploadState.IMPORT_COMPLETE:
      return (
        <Space>
          {item.successRows > 0 && <Tag color="success">{`${item.successRows} Successes`}</Tag>}
          {item.warningRows > 0 && <Tag color="warning">{`${item.warningRows} Warnings`}</Tag>}
          {item.errorRows > 0 && <Tag color="error">{`${item.errorRows} Errors`}</Tag>}
        </Space>
      );

    case UploadState.UPLOADING_FILE:
      return (
        <Tag icon={<SyncOutlined spin />} color="processing">
          Uploading...
        </Tag>
      );
    case UploadState.IMPORT_IN_PROGRESS:
      const importPercentage = (item.rowsProcessed / item.totalRows) * 100;
      return (
        <div style={{display: 'flex'}}>
          <Tag icon={<SyncOutlined spin />} color="processing">
            Importing...
          </Tag>
          <Progress percent={importPercentage} status="active" />
        </div>
      );
    case UploadState.UPLOAD_ERROR:
      return <Tag title="Error" itemType="error" />;

    case UploadState.REVIEWING_FILE:
    case UploadState.INVALID_FILE:
    case UploadState.AWAITING_FILE:
    default:
      return null;
  }
};

const ImportDetailViewModal = (props) => {
  const {visibleImport, setVisibleImport} = props;

  const title = visibleImport
    ? `Import Log - ${importerConfigs[visibleImport.importType].name} - ${moment(
        visibleImport.createdAt
      ).format('LLL')}`
    : 'Import Log';

  const onClose = () => {
    setVisibleImport(undefined);
  };

  return (
    <Modal
      visible={!!visibleImport}
      title={title}
      width={'85%'}
      bodyStyle={{height: 600, overflow: 'scroll'}}
      centered
      destroyOnClose
      onCancel={onClose}
      footer={
        <div>
          <Button shape="round" type="primary" size={'large'} onClick={onClose}>
            Close
          </Button>
        </div>
      }
    >
      <ImportResultView importId={visibleImport?.id} upload={visibleImport} />
    </Modal>
  );
};
