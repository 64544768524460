import {createSlice} from '@reduxjs/toolkit';
import {normalize, schema} from 'normalizr';
import querystring from 'querystring';
import {mapBundleToResourceArray} from '../util/fhir';

interface User {
  id: string;
  name: string;
  organizationId: string;
  email: string;
}

export interface UsersSliceInterface {
  byId: {[string: string]: User};
}
const initialState = {
  byId: {},
};

const user = new schema.Entity('clinicmodeusers', {});

const slice = createSlice({
  name: 'clinicmodeusers',
  initialState,
  reducers: {
    SAVE_USERS: (state: UsersSliceInterface, action) => {
      const resources = mapBundleToResourceArray(action.payload);
      state.byId = normalize(resources, [user]).entities.clinicmodeusers || {};
    },
    SAVE_USER: (state: UsersSliceInterface, action) => {
      state.byId[action.payload.id] = action.payload;
    },
    DELETE_USER: (state: UsersSliceInterface, action) => {
      delete state.byId[action.payload.id];
    },
  },
});

export const getAll = (client, params?) => async (dispatch) => {
  let query = params ? querystring.stringify(params) : undefined;
  const res = await client.get(`/clinic-mode-user${query ? `?${query}` : ''}`);
  dispatch(slice.actions.SAVE_USERS(res.data));
  return res.data;
};

const getOne = (client, id) => async (dispatch) => {
  return client.get(`/clinic-mode-user/${id}`).then((res) => {
    return dispatch(slice.actions.SAVE_USER(res.data));
  });
};

const updateOne = (client, item) => async (dispatch) => {
  return client.put(`/clinic-mode-user/${item.id}`, item).then(async (res) => {
    await dispatch(slice.actions.SAVE_USER(res.data));
    return res.data;
  });
};

export const createOne = (client, item) => async (dispatch) => {
  return client.post(`/clinic-mode-user`, item).then(async (res) => {
    await dispatch(slice.actions.SAVE_USER(res.data));
    return res.data;
  });
};

export const deleteOne = (client, itemId) => async (dispatch) => {
  return client.delete(`/clinic-mode-user/${itemId}`).then(async (res) => {
    await dispatch(slice.actions.DELETE_USER({id: itemId}));
    return res.data;
  });
};

const resendVerify = (client, itemId) => async () => {
  return client.get(`/clinic-mode-user/${itemId}/access/resend`);
};

const updateAccess = (client, itemId, password, userAccountAccess) => async (dispatch) => {
  return client
    .post(`/clinic-mode-user/${itemId}/access`, {password, userAccountAccess})
    .then((res) => dispatch(getOne(client, itemId)).then(() => res.data));
};

export default {
  slice,
  getAll,
  getOne,
  updateOne,
  createOne,
  deleteOne,
  resendVerify,
  updateAccess,
};
