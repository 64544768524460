import {colors} from '@canimmunize/tools';
import {faCheckCircle, faPlusCircle} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, message, Modal, Space, Tag, Tooltip} from 'antd';
import {Formik, FormikHelpers} from 'formik';
import _ from 'lodash';
import React from 'react';
import {Link} from 'react-router-dom';
import uuid from 'uuid';
import {useGlobalSettings} from '../../../models/global-settings';
import {useGlobalOrg} from '../../../models/ui';
import {FhirUtils} from '../../../services/fhir';
import {FHIRTable} from '../../fhir-table';
import {FormUX} from '../../form-ux/form-ux';
import {AppointmentTypeAgeFormItem} from './appointment-type-age';
import {AppointmentTypeCalendarActions} from './appointment-type-calendar-actions';
import {
  AppointmentTypeCustomFieldName,
  AppointmentTypeFormUXModel,
} from './appointment-type-form-ux-model';
import {AppointmentTypeListActions} from './appointment-type-list-actions';

export const BookingPageAppointmentTypesUX = ({bookingPageId}) => {
  const settings = useGlobalSettings();
  const client = FhirUtils.useAxiosClient();
  const [selectedItem, setSelectedItem] = React.useState();
  const [addingNew, setAddingNew] = React.useState(false);
  const [expandedRowKeys, setExpandedRowKeys] = React.useState<any[]>([]);
  const enableAddressLookUp = settings['enableAddressLookUp']?.value === 'true';

  // Updating this string to a random number will refresh the fhir-table
  const [tableState, setTableState] = React.useState(uuid.v4());

  const onSubmit = async (values, helpers: FormikHelpers<any>) => {
    helpers.setSubmitting(true);

    values = _.pick(values, [
      'id',
      'status',
      'nameEn',
      'nameFr',
      'duration',
      'durationDisplayEn',
      'durationDisplayFr',
      'minAge',
      'maxAge',
      'minAgeUnit',
      'maxAgeUnit',
      'ageEligibilityErrorMessageEn',
      'ageEligibilityErrorMessageFr',
      'eligibleBirthMonths',
      'numberOfPatients',
      'listPriority',
      'deleted',
      'maxAvailability',
      'appointmentBookingPageId',
    ]);

    const result = await client.put(`/appointment-type`, values);

    if (result.status === 200) {
      message.success('Appointment Type Saved');
      helpers.resetForm();
    }

    setTableState(uuid.v4());

    helpers.setSubmitting(false);
    setAddingNew(false);
    setSelectedItem(undefined);
  };

  const onClickRow = (appointmentType: any) => {
    const isOpen = expandedRowKeys.indexOf(appointmentType.id) > -1;
    const newExpandedRows = isOpen
      ? expandedRowKeys.filter((r) => r !== appointmentType.id)
      : _.uniq([...expandedRowKeys, appointmentType.id]);
    setExpandedRowKeys(newExpandedRows);
  };

  const onRowExpand = (expanded, appointmentType) => onClickRow(appointmentType);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'nameEn',
      key: 'nameEn',
      sorter: true,
    },
    // {
    //   title: 'Duration (min)',
    //   dataIndex: 'duration',
    //   key: 'duration',
    //   sorter: true,
    // },
    {
      title: 'Eligibility',
      render: (_, item) => {
        if (item.minAge && !item.maxAge) {
          return `Age: ≥ ${item.minAge + (item.minAgeUnit === 'month' ? '(M)' : '')}`;
        } else if (item.maxAge && !item.minAge) {
          return `Age: ≤ ${item.maxAge + (item.maxAgeUnit === 'month' ? '(M)' : '')}`;
        } else if (item.maxAge && item.minAge) {
          return `Age: ${item.minAge + (item.minAgeUnit === 'month' ? '(M)' : '')} - ${
            item.maxAge + (item.maxAgeUnit === 'month' ? '(M)' : '')
          }`;
        }
        return '';
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      render: (__, item) => {
        let color;
        let text;
        switch (item.status) {
          case 'active':
            color = 'green';
            text = 'Active';
            break;
          case 'disabled':
            color = 'red';
            text = 'Disabled';
            break;
          case 'staging':
            color = 'orange';
            text = 'Staging';
            break;
          default:
            break;
        }

        const locationIds = item.calendars.reduce(
          (locationIds, calendar) =>
            locationIds.indexOf(calendar.clinic.locationId) > -1
              ? locationIds
              : [...locationIds, calendar.clinic.locationId],
          []
        );

        const gisLocationIds = item.calendars.reduce(
          (locationIds, calendar) =>
            locationIds.indexOf(calendar.clinic.location?.gisLocationId) > -1
              ? locationIds
              : [...locationIds, calendar.clinic.location?.gisLocationId],
          []
        );

        const missingLocationError = locationIds.indexOf(null) > -1;
        const missingGISLocationError = enableAddressLookUp && gisLocationIds.indexOf(null) > -1;
        const multipleLocationsError = _.uniq(locationIds).length > 1;

        let tagErrorLabel, tagErrorDescription;

        if (missingLocationError) {
          tagErrorLabel = 'Unset Location';
          tagErrorDescription =
            'There are calendars attached to this appointment type that do not have a clinic location set.';
        } else if (missingGISLocationError) {
          tagErrorLabel = 'Unset GIS Location';
          tagErrorDescription =
            'There are calendars attached to this appointment type that have a clinic location set that does not have a GIS location.';
        } else if (multipleLocationsError) {
          tagErrorLabel = 'Multiple Locations';
          tagErrorDescription =
            'There are calendars attached to this appointment type that have different clinic locations. While this booking page has the address lookup feature enabled, all calendars on an appointment type should have the same clinic location.';
        }

        return (
          <Space>
            <Tag color={color}>{text}</Tag>
            {/* Show user warning if there are no calendars on the appointment type. */}
            {item.calendars.length === 0 && <Tag color="red">No Calendars</Tag>}

            {/* Fully booked flag */}
            {item.noAvailabilityTimestamp && item.calendars.length > 0 && (
              <Tag color="red">Fully Booked</Tag>
            )}

            {/* Error messages related to calendar locations */}
            {tagErrorLabel && (
              <Tooltip title={tagErrorDescription}>
                <Tag color="red">{tagErrorLabel}</Tag>
              </Tooltip>
            )}
          </Space>
        );
      },
      filters: [
        {text: 'Active', value: 'active'},
        {text: 'Staging', value: 'staging'},
        {text: 'Disabled', value: 'disabled'},
      ],
      defaultFilteredValues: ['active', 'staging'],
    },
    {
      title: 'Actions',
      render: (_, appointmentType) => (
        <AppointmentTypeListActions
          appointmentType={appointmentType}
          bookingPageId={bookingPageId}
          setSelectedItem={setSelectedItem}
          refreshTable={() => setTableState(uuid.v4())}
        />
      ),
    },
  ];

  const initialValues = addingNew
    ? {numberOfPatients: 1, appointmentBookingPageId: bookingPageId}
    : {
        ...(selectedItem || {}),
        numberOfPatients: 1,
        appointmentBookingPageId: bookingPageId,
      };

  console.log('IV', initialValues);

  return (
    <div>
      <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
        {(formikProps) => (
          <Modal
            width={'60%'}
            title={addingNew ? 'New Appointment Type' : 'Appointment Type Settings'}
            visible={addingNew || selectedItem}
            bodyStyle={{height: 500, overflow: 'scroll'}}
            okText="Save"
            okButtonProps={{
              /* Disabled if the form is not dirty or if its not valid*/
              disabled: !formikProps.dirty || !formikProps.isValid,
              onClick: () => {
                formikProps.handleSubmit();
              },
              loading: formikProps.isSubmitting,
            }}
            onCancel={() => {
              setAddingNew(false);
              setSelectedItem(undefined);
            }}
            destroyOnClose
          >
            <FormUX
              formUXModel={AppointmentTypeFormUXModel}
              createMode={addingNew}
              modal={true}
              renderCustomField={(field) => {
                switch (field.name) {
                  case AppointmentTypeCustomFieldName.MinAge:
                  case AppointmentTypeCustomFieldName.MaxAge: {
                    return (
                      <AppointmentTypeAgeFormItem
                        name={field.name}
                        label={field.label}
                        validationRules={field.validationRules}
                        disabled={field?.disabled}
                      />
                    );
                  }
                  default: {
                    throw new Error(`Unhandled custom field ${field.name} in
                  renderCustomField method`);
                  }
                }
              }}
            />
          </Modal>
        )}
      </Formik>

      <FHIRTable
        fhirResource="appointment-type"
        fixedFilters={{bookingPageId}}
        mode="table"
        defaultPrimarySearchParam="name"
        label="Appointment Type"
        showCreateButton
        onClickCreateButton={() => setAddingNew(true)}
        columns={columns}
        triggerRefresh={tableState}
        onExpand={onRowExpand}
        onClickRow={onClickRow}
        expandedRowKeys={expandedRowKeys}
        expandable={{
          expandedRowRender: (record) => (
            <p style={{margin: 0}}>
              <AppointmentTypeCalendarsTable
                appointmentType={record}
                bookingPageId={bookingPageId}
              />
            </p>
          ),

          // rowExpandable: (record) => record.name !== 'Not Expandable',
        }}
        responseTransformer={(data) => {
          data.entry.forEach((e) => {
            e.resource.key = e.resource.id;
            // e.resource.children = e.resource.calendars.map((cal) => ({...cal, key: cal.id}));
          });
          return data;
        }}
      />
    </div>
  );
};

export const AppointmentTypeCalendarsTable = (props) => {
  const {appointmentType} = props;
  const [selectingCalendar, setSelectingCalendar] = React.useState(false);
  const [tableState, setTableState] = React.useState(uuid.v4());
  const [calendarData, setCalendarData] = React.useState<any>([]);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      render: (_, data) => (
        <Link to={`/clinics/${data.clinicId}/calendars/${data.id}`}>{data.name}</Link>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      render: (_, item) => {
        let color;
        let text;
        switch (item.status) {
          case 'active':
            color = 'green';
            text = 'Active';
            break;
          case 'disabled':
            color = 'red';
            text = 'Disabled';
            break;
          case 'staging':
            color = 'orange';
            text = 'Staging';
            break;
          default:
            break;
        }
        return <Tag color={color}>{text}</Tag>;
      },
      filters: [
        {text: 'Public', value: 'public'},
        {text: 'Staging', value: 'staging'},
        {text: 'Disabled', value: 'disabled'},
      ],
    },
    {
      title: 'Actions',
      render: (_, calendar) => (
        <AppointmentTypeCalendarActions
          appointmentType={appointmentType}
          calendar={calendar}
          refreshTable={() => setTableState(uuid.v4())}
        />
      ),
    },
  ];

  return (
    <>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <h5>Calendars</h5>

        <Button
          type="primary"
          size={'small'}
          style={{paddingLeft: 10, paddingRight: 10}}
          onClick={(e) => {
            setSelectingCalendar(true);
          }}
        >
          <FontAwesomeIcon icon={faPlusCircle} color="white" style={{marginRight: 10}} />
          Add Calendar
        </Button>
      </div>

      <FHIRTable
        fhirResource="calendar"
        columns={columns}
        searchBar={() => <></>}
        onDataLoad={(data) => setCalendarData(data.entry.map((e) => e.resource))}
        mode="table"
        triggerRefresh={tableState}
        fixedFilters={{appointmentTypeId: appointmentType.id, added: 'true'}}
      />

      <AppointmentTypeCalendarPicker
        appointmentType={appointmentType}
        calendars={calendarData}
        visible={selectingCalendar}
        setVisible={setSelectingCalendar}
        onCalendarsUpdated={() => setTableState(uuid.v4())}
        bookingPageId={props.bookingPageId}
      />
    </>
  );
};

export const AppointmentTypeCalendarPicker = ({
  appointmentType,
  visible,
  setVisible,
  calendars,
  onCalendarsUpdated,
  bookingPageId,
}) => {
  const [loading, setLoading] = React.useState<string | undefined>(undefined);
  const organization = useGlobalOrg();
  const client = FhirUtils.useAxiosClient();

  const onAddCalendarToAppointmentType = async (calendar) => {
    setLoading(calendar.id);
    await client
      .put(`/appointment-type/link`, {
        action: 'link',
        calendarId: calendar.id,
        appointmentTypeId: appointmentType.id,
      })
      .then(() => {
        onCalendarsUpdated();
        message.success('Calendar successfully added to appointment type!');
      })
      .catch(() => message.error(`Error adding calendar to appointment type`));
    setLoading(undefined);
  };

  const calendarPickerColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: true,
    },
    {
      title: 'Actions',
      render: (_, calendar) => (
        <Space>
          {calendars.map((cal) => cal.id).indexOf(calendar.id) > -1 ? (
            <Button
              type="primary"
              className="success"
              size={'small'}
              style={{
                paddingLeft: 10,
                paddingRight: 10,
                backgroundColor: colors.green,
                borderColor: colors.green,
              }}
            >
              <FontAwesomeIcon icon={faCheckCircle} color="white" style={{marginRight: 10}} />
              Added
            </Button>
          ) : (
            <Space>
              <Button
                type="primary"
                size={'small'}
                style={{paddingLeft: 10, paddingRight: 10, alignItems: 'center', display: 'flex'}}
                onClick={() => onAddCalendarToAppointmentType(calendar)}
                loading={loading === calendar.id}
              >
                <FontAwesomeIcon icon={faPlusCircle} color="white" style={{marginRight: 10}} />
                Add to Appointment Type
              </Button>
            </Space>
          )}
        </Space>
      ),
    },
  ];

  return (
    <Modal
      visible={visible}
      title="Add Calendar to Appointment Type"
      width={'60%'}
      okText="Done"
      onOk={() => setVisible(false)}
      bodyStyle={{height: 500, overflow: 'scroll'}}
      onCancel={() => setVisible(false)}
      cancelButtonProps={{style: {display: 'none'}}}
      destroyOnClose
    >
      <FHIRTable
        fhirResource="calendar"
        columns={calendarPickerColumns}
        defaultPrimarySearchParam="name"
        mode="table"
        fixedFilters={{
          _organizationId: organization?.id || '',
          isGroup: appointmentType.category == 'group' ? 'true' : 'false',
          appointmentTypeId: appointmentType.id,
        }}
        // responseTransformer={(data) => {
        //   const filteredEntries = data.entry.filter((calendarById) =>
        //     AppointmentTypeCalendarTransformer(calendarById.resource, calendars, bookingPage)
        //   );
        //   return {...data, total: filteredEntries.length, entry: filteredEntries};
        // }}
      />
    </Modal>
  );
};

// const AppointmentTypeCalendarTransformer = (
//   calendarToCheck: any,
//   existingCalendars: any,
//   bookingPage?: BookingPage
// ) => {
//   const clinicCategory = calendarToCheck.clinic?.category;
//   switch (bookingPage?.category) {
//     case BookingPageCategory.GROUP: {
//       //Only show existing calendars or calendars marked as Group
//       return (
//         clinicCategory === 'Group' ||
//         existingCalendars.some((calendar) => calendar.id === calendarToCheck.id)
//       );
//     }
//     default: {
//       return clinicCategory !== 'Group';
//     }
//   }
// };
