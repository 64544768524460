"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_RequestGroup_Action = void 0;
var t = __importStar(require("io-ts"));
var RTTI_Extension_1 = require("./RTTI_Extension");
var RTTI_Element_1 = require("./RTTI_Element");
var RTTI_code_1 = require("../Scalar/RTTI_code");
var RTTI_CodeableConcept_1 = require("./RTTI_CodeableConcept");
var RTTI_RelatedArtifact_1 = require("./RTTI_RelatedArtifact");
var RTTI_RequestGroup_Condition_1 = require("./RTTI_RequestGroup_Condition");
var RTTI_RequestGroup_RelatedAction_1 = require("./RTTI_RequestGroup_RelatedAction");
var RTTI_Age_1 = require("./RTTI_Age");
var RTTI_Period_1 = require("./RTTI_Period");
var RTTI_Duration_1 = require("./RTTI_Duration");
var RTTI_Range_1 = require("./RTTI_Range");
var RTTI_Timing_1 = require("./RTTI_Timing");
var RTTI_Reference_1 = require("./RTTI_Reference");
exports.RTTI_RequestGroup_Action = t.recursion('IRequestGroup_Action', function () {
    return t.partial({
        id: t.string,
        extension: t.array(RTTI_Extension_1.RTTI_Extension),
        modifierExtension: t.array(RTTI_Extension_1.RTTI_Extension),
        prefix: t.string,
        _prefix: RTTI_Element_1.RTTI_Element,
        title: t.string,
        _title: RTTI_Element_1.RTTI_Element,
        description: t.string,
        _description: RTTI_Element_1.RTTI_Element,
        textEquivalent: t.string,
        _textEquivalent: RTTI_Element_1.RTTI_Element,
        priority: RTTI_code_1.RTTI_code,
        _priority: RTTI_Element_1.RTTI_Element,
        code: t.array(RTTI_CodeableConcept_1.RTTI_CodeableConcept),
        documentation: t.array(RTTI_RelatedArtifact_1.RTTI_RelatedArtifact),
        condition: t.array(RTTI_RequestGroup_Condition_1.RTTI_RequestGroup_Condition),
        relatedAction: t.array(RTTI_RequestGroup_RelatedAction_1.RTTI_RequestGroup_RelatedAction),
        timingDateTime: t.string,
        _timingDateTime: RTTI_Element_1.RTTI_Element,
        timingAge: RTTI_Age_1.RTTI_Age,
        timingPeriod: RTTI_Period_1.RTTI_Period,
        timingDuration: RTTI_Duration_1.RTTI_Duration,
        timingRange: RTTI_Range_1.RTTI_Range,
        timingTiming: RTTI_Timing_1.RTTI_Timing,
        participant: t.array(RTTI_Reference_1.RTTI_Reference),
        type: RTTI_CodeableConcept_1.RTTI_CodeableConcept,
        groupingBehavior: RTTI_code_1.RTTI_code,
        _groupingBehavior: RTTI_Element_1.RTTI_Element,
        selectionBehavior: RTTI_code_1.RTTI_code,
        _selectionBehavior: RTTI_Element_1.RTTI_Element,
        requiredBehavior: RTTI_code_1.RTTI_code,
        _requiredBehavior: RTTI_Element_1.RTTI_Element,
        precheckBehavior: RTTI_code_1.RTTI_code,
        _precheckBehavior: RTTI_Element_1.RTTI_Element,
        cardinalityBehavior: RTTI_code_1.RTTI_code,
        _cardinalityBehavior: RTTI_Element_1.RTTI_Element,
        resource: RTTI_Reference_1.RTTI_Reference,
        action: t.array(exports.RTTI_RequestGroup_Action)
    });
});
