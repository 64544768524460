import {
  faCalendarAlt,
  faCopy,
  faPencilAlt,
  faRocket,
  faWalking,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, message, Space} from 'antd';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {FhirUtils} from '../../services/fhir';

export const BookingPageActions = (props) => {
  const {bookingPage} = props;
  const client = FhirUtils.useAxiosClient();
  const navigate = useNavigate();

  const copyBookingPage = async (bookingPage) => {
    const copiedBookingPage = await client
      .get(`/booking-page/${bookingPage.id}/copy`)
      .then(({data}) => data)
      .catch((err) => {
        message.error(`Error copying booking page.`);
      });

    if (copiedBookingPage) {
      message.success('Successfully copied booking page!');
      navigate(`/bookingpages/${copiedBookingPage.id}`);
    }
  };

  return (
    <Space>
      <Button
        type="primary"
        size={'small'}
        // href={`/en/${bookingPage.bookingPageSlug.en}`}
        onClick={(e) => {
          e.stopPropagation();
          window.open(`/en/${bookingPage.bookingPageSlug.en}`);
        }}
        target="_blank"
        style={{paddingLeft: 10, paddingRight: 10}}
      >
        <FontAwesomeIcon icon={faRocket} color="white" style={{marginRight: 10}} />
        Launch Booking Page
      </Button>
      {/* <Button
        type="primary"
        size={'small'}
        href={`/en/${bookingPage.bookingPageSlug.en}/walk-in`}
        target="_blank"
        style={{paddingLeft: 10, paddingRight: 10}}
      >
        <FontAwesomeIcon icon={faWalking} color="white" style={{marginRight: 10}} />
        Launch Walk-In Form
      </Button> */}
      <Button
        type="primary"
        size={'small'}
        style={{paddingLeft: 10, paddingRight: 10}}
        onClick={(e) => {
          e.stopPropagation();
          navigate(`/bookingpages/${bookingPage.id}`);
        }}
      >
        <FontAwesomeIcon icon={faPencilAlt} color="white" style={{marginRight: 10}} />
        Edit
      </Button>
      <Button
        type="primary"
        size={'small'}
        style={{paddingLeft: 10, paddingRight: 10}}
        onClick={(e) => {
          e.stopPropagation();
          copyBookingPage(bookingPage);
        }}
      >
        <FontAwesomeIcon icon={faCopy} color="white" style={{marginRight: 10}} />
        Copy
      </Button>
    </Space>
  );
};
