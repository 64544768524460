import React, {useEffect, useState} from 'react';
import {Button, message, Modal, Spin, Space, Typography} from 'antd';
import {AppointmentPicker} from '../../scheduler/booking-form/appointment-picker';
import {Appointment, AppointmentType} from '../../scheduler/types';
import {BookingPageConfigProvider} from '../../scheduler/util';
import {FhirUtils} from '../../../services/fhir';
import moment from 'moment';
import {FormsContainer} from '../../scheduler/consent-form';
import {filterRequiredFormTypesForConfig} from '../../scheduler/consent-form/patient-forms';
import {
  IBookingPageSelectOption,
  BookingPageSelector,
  LoadingSpinner,
  SchedulePasswordForm,
} from './scheduler-utils';
import {BookingPageCategory} from '../../../models/booking-pages';
import {useEnvInfo} from '../../../services/environment';

interface IRescheduleModalProps {
  appointment: any;
  visible: any;
  setVisible: any;
  refreshAppointment: (hadSubsequentAppointments?: boolean) => any;
  afterClose: () => any;
}

export const RescheduleModal = (props: IRescheduleModalProps) => {
  const {setVisible, appointment, visible, refreshAppointment, afterClose} = props;
  const modalProps = {setVisible, appointment, visible, refreshAppointment};
  const client = FhirUtils.useAxiosClient();
  const [selectedAppointment, setSelectedAppointment] = useState<Appointment | undefined>();
  const [loadingAppointment, setLoadingAppointment] = useState<boolean>(false);
  const [loadingBookingPages, setLoadingBookingPages] = useState<boolean>();
  const [rescheduling, setRescheduling] = useState(true);
  const [passwordVerified, setPasswordVerified] = React.useState(false);
  const [selectedAppointmentType, setSelectedAppointmentType] =
    useState<AppointmentType | undefined>();
  const [bookingPage, setBookingPage] = useState({
    id: appointment.bookingPageId,
    clientRegistryOverrideEnabled: 'false',
  } as IBookingPageSelectOption);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  useEffect(() => {
    setLoadingAppointment(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointment]);

  const onFormCompleted = () => {
    message.success('Form successfully submitted!');
    setLoadingAppointment(true);
    refreshAppointment();
  };

  useEffect(() => {
    setRescheduling(true);
  }, [props.visible]);

  const patientsWithRequiredForms =
    appointment?.patients?.filter(
      //Workaround
      (p) => filterRequiredFormTypesForConfig(p.requiredFormTypes, bookingPage, true).length > 0
    ) || [];

  const onCloseModal = () => {
    if (errorMessage) setErrorMessage(undefined);
    afterClose();
    setVisible(false);
  };

  const formsNeeded = !rescheduling && !loadingAppointment && patientsWithRequiredForms?.length > 0;
  const formsComplete =
    !rescheduling && !loadingAppointment && !(patientsWithRequiredForms?.length > 0);

  if (formsComplete) onCloseModal();

  const theme = {
    blue: '#265ed6',
    danger: '#dc3545',
    boxShadowStyle: {
      boxShadow: '0 10px 25px rgba(50, 50, 93, 0.1)',
    },
    sectionSpacing: 60,
    borderRadius: 10,
  };
  const bookingPageConfig = {
    lang: 'en',
    theme,
    syncUrl: useEnvInfo().syncUrl,
    adminUser: true,
    includeStaging: false,
    bookingPageSlug: bookingPage?.bookingPageSlug?.en,
  };

  const confirmReschedule = async () => {
    setLoadingAppointment(true);
    setRescheduling(false);
    setErrorMessage(undefined);
    setSelectedAppointmentType(undefined);
    const rescheduleResult = await client.post(
      `/appointment/${appointment.id}/reschedule`,
      {
        target: {
          appointmentTypeId: selectedAppointment?.appointmentTypeId,
          datetime: selectedAppointment?.datetime,
        },
      },
      {
        validateStatus: (status) => true,
      }
    );
    setSelectedAppointment(undefined);
    if (rescheduleResult.status === 200) {
      refreshAppointment(true);
      message.success('Appointment successfully rescheduled!');
    } else {
      setRescheduling(true);
      setLoadingAppointment(false);
      setErrorMessage(
        FhirUtils.getErrorDisplay({response: rescheduleResult}) ||
          `Request failed with status ${rescheduleResult.status}`
      );
    }
  };

  const defaultAge = moment().diff(appointment.patients[0].birthdate, 'years');
  const passwordVerificationPass =
    (bookingPage as any)?.status === 'protected' ? passwordVerified : true;

  return (
    <Modal
      width={1200}
      title="Reschedule Appointment"
      bodyStyle={{height: 600, overflow: 'scroll'}}
      {...modalProps}
      footer={
        <Space>
          {errorMessage && (
            <Typography.Text type="danger">
              <div dangerouslySetInnerHTML={{__html: errorMessage || ''}} />
            </Typography.Text>
          )}
          <Button
            type="primary"
            onClick={confirmReschedule}
            disabled={!selectedAppointment}
            loading={loadingAppointment || loadingBookingPages}
          >
            Reschedule
          </Button>
          <Button onClick={onCloseModal}>Cancel</Button>
        </Space>
      }
      style={{top: 20, bottom: 80}}
      destroyOnClose={true}
      onCancel={onCloseModal}
    >
      <div>
        {rescheduling && (
          <div style={{marginBottom: 25}}>
            <span style={{marginRight: 5}}>Booking Page: </span>
            <BookingPageSelector
              setLoading={setLoadingBookingPages}
              value={bookingPage.id}
              onSelect={(bp) => {
                setBookingPage(bp);
                setSelectedAppointmentType(undefined);
                setSelectedAppointment(undefined);
              }}
              excludeCategories={[BookingPageCategory.GROUP]}
            />
          </div>
        )}
        {(loadingAppointment || loadingBookingPages) && <LoadingSpinner />}
        {bookingPageConfig.bookingPageSlug && (
          <BookingPageConfigProvider
            previewConfig={bookingPageConfig}
            Spinner={LoadingSpinner}
            forceOverride
          >
            {!passwordVerificationPass && (
              <SchedulePasswordForm
                setPasswordVerified={setPasswordVerified}
                bookingPageId={bookingPage?.id}
              />
            )}
            {rescheduling && passwordVerificationPass && (
              <div>
                <AppointmentPicker
                  appointment={appointment}
                  defaultAge={defaultAge}
                  setSelectedAppointmentType={setSelectedAppointmentType}
                  selectedAppointmentType={selectedAppointmentType}
                  selectedAppointment={selectedAppointment}
                  setSelectedAppointment={setSelectedAppointment}
                  previewConfig={{adminUser: true}}
                  rescheduling={true}
                />
              </div>
            )}
            {formsNeeded && (
              <FormsContainer
                loadingAppointment={loadingAppointment}
                appointment={appointment}
                onFormCompleted={onFormCompleted}
                containerStyle={{}}
                hideSubtext
              ></FormsContainer>
            )}
          </BookingPageConfigProvider>
        )}
      </div>
    </Modal>
  );
};
