import {Modal, Select} from 'antd';
import React from 'react';
import {FhirUtils} from '../../services/fhir';
import uuid from 'uuid';
import {Ids} from '@canimmunize/tools';
import {useStr} from '../../services/str';

export const SelectMailRequestStatus = (props) => {
  const {status, id, refresh} = props;
  const [submitting, setSubmitting] = React.useState(false);
  const [selectedStatus, setSelectedStatus] = React.useState(status);
  const statuses = ['pending', 'processed', 'cancelled', 'returned'];
  const client = FhirUtils.useAxiosClient();
  const Str = useStr();

  React.useEffect(() => {
    setSelectedStatus(status);
  }, [status]);

  const SetMailRequestStatus = async (newStatus) => {
    setSubmitting(true);
    const res = await client
      .put(`/mail-request`, {status: newStatus, id})
      .catch((err) => err.response);

    if (res.status === 200) {
      setSelectedStatus(newStatus);
    } else {
      Modal.error({
        title: Str(Ids.error),
        content: `${res?.data?.message || res?.data || 'Failed to update mail request'}`,
      });
    }

    setSubmitting(false);
    refresh(uuid.v4());
  };

  return (
    <Select value={selectedStatus} onChange={(e) => SetMailRequestStatus(e)} loading={submitting}>
      {statuses.map((s, i) => {
        return (
          <Select.Option key={i} value={s}>
            {s}
          </Select.Option>
        );
      })}
    </Select>
  );
};
