import {Typography} from 'antd';
import moment from 'moment-timezone';
import React from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '../../models';
import {BaseEntityV2} from '../base-entity/base-entity';
import {LogTable} from './log-table';

const {Title} = Typography;

export const LogUX = () => {
  const slug = 'log';
  const searchPageTitle = 'Logs';
  const name = slug.charAt(0).toUpperCase() + slug.slice(1);
  const pluralName = `${name}s`;
  const globalOrg = useSelector((state: RootState) => state.ui.localOrg);
  const fixedFilters = globalOrg
    ? {
        _organizationId: globalOrg.id,
      }
    : undefined;

  const MessageComponent = (props) => {
    const {item} = props;
    return (
      <>
        <p>{item.message}</p>
        {item.referenceId && (
          <p style={{marginBottom: 0}}>
            {`${item.referenceType} ` || ''}ID: {item.referenceId}
          </p>
        )}
        {item.patientId && <p style={{marginBottom: 0}}>Patient ID: {item.patientId}</p>}
      </>
    );
  };

  const tableConfig = {
    fixedFilters: fixedFilters,
    fhirResource: 'logs',
    columns: [
      {
        title: 'Date',
        dataIndex: 'createdAt',
        key: 'createdAt',
        sorter: true,
        width: 120,
        render: (_, item) =>
          moment.tz(item.createdAt, moment.tz.guess()).format('YYYY-MM-DD hh:mm A z'),
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        sorter: true,
        width: 120,
      },
      {
        title: 'Submitter',
        dataIndex: 'submitter',
        key: 'submitter',
        sorter: true,
        width: 120,
      },
      {
        title: 'Message',
        dataIndex: 'message',
        key: 'message',
        sorter: true,
        width: 960,
        render: (_, item) => <MessageComponent item={item} />,
      },
    ],
  };

  return (
    <>
      <Title level={1} style={{color: 'white'}}>
        {searchPageTitle || pluralName}
      </Title>
      <div>
        <LogTable {...tableConfig} />
      </div>
    </>
  );
};
