import {RequiredShortTextValidationRules, RequiredSpecialCharsValidationRules} from '../../services/ui-validation-rules';
import {FormUXFieldType} from '../form-ux';
import {FormUXModel} from '../form-ux/form-ux-models/form-ux-model';

/**
 * The names of the fields for the clinic form whose rendering have to
 * be handled by the CampaignUX component
 *
 * @export
 * @enum {number}
 */
export enum CampaignCustomFieldName {
  OrganizationId = 'organizationId',
}

export const CampaignUXModel: FormUXModel = [
  {
    name: 'name',
    type: FormUXFieldType.text,
    label: 'Name',
    editable: true,
    validationRules: [
      ...RequiredShortTextValidationRules,
      ...RequiredSpecialCharsValidationRules
    ],
    inCreateModal: true,
  },
  {
    name: CampaignCustomFieldName.OrganizationId,
    type: FormUXFieldType.custom,
    label: 'Organization',
    editable: true,
    validationRules: [
      {
        validationRuleType: 'required',
      },
    ],
    inCreateModal: true,
  }
];
