import {
  RequiredShortTextValidationRules,
  RequiredValidationRule,
} from '../../../services/ui-validation-rules';
import {FormUXFieldType} from '../../form-ux';
import {FormUXModel} from '../../form-ux/form-ux-models/form-ux-model';

/* Generates the form fields for a FormUX for editing a patient flag. */
export const generateFlagFormFields = (): FormUXModel => {
  return [
    {
      name: 'type',
      type: FormUXFieldType.custom,
      label: 'Flag Type',
      fetchType: 'flag-type',
      generateLabel: (flagType) => flagType.name,
      generateValue: (flagType) => flagType.id,
      editable: true,
      inCreateModal: true,
      validationRules: RequiredValidationRule,
    },
    {
      name: 'startDate',
      type: FormUXFieldType.date,
      label: 'Start Date',
      editable: true,
      validationRules: [],
      inCreateModal: true,
    },
    {
      name: 'endDate',
      type: FormUXFieldType.date,
      label: 'End Date',
      editable: true,
      validationRules: [],
      inCreateModal: true,
    },
  ];
};
