import React from 'react';
import {useUrlData} from './util';

const API_KEY = 'AIzaSyBjB4UbvB-2ItpwrAXzqAHOeLm-g7ixXjI';

interface Props {
  appointment: any;
}
export const AppointmentMap = (props: Props) => {
  const {appointment} = props;

  const locationUriEncodedString = encodeURIComponent(
    appointment.calendar.clinic?.location?.mapsLocationString
  );

  // console.log(
  //   `https://www.google.com/maps/embed/v1/place?key=${API_KEY}&q=${locationUriEncodedString}`
  // );
  return (
    <div style={{overflow: 'hidden'}}>
      <iframe
        width="100%"
        height="200"
        // @ts-ignore
        frameborder="0"
        style={{border: 0}}
        src={`https://www.google.com/maps/embed/v1/place?key=${API_KEY}&q=${locationUriEncodedString}`}
        allowfullscreen
      ></iframe>
    </div>
  );
};
