import {AbstractValidationRule} from './abstract-validation-rule';

export const RequiredDefinedValidationRuleId = 'required-defined';

export interface RequiredDefinedValidationRule extends AbstractValidationRule {
  validationRuleType: typeof RequiredDefinedValidationRuleId;
}

/**
 * Validation to check if a value is null or undefined. Unlike the RequiredValidationRule,
 * empty strings are allowed.
 *
 * @export
 * @param {*} value
 * @returns {boolean} True if validation passed. False otherwise.
 */
export function validateRequiredDefinedRule(value: any): boolean {
  return !(value === null || value === undefined);
}
