import {CVC} from '@canimmunize/cvc-js';
import {colors, FormatTimezoneAbbr} from '@canimmunize/tools';
import {Button, message, Modal, Select, Divider} from 'antd';
import {ReloadOutlined} from '@ant-design/icons';
import moment from 'moment-timezone';
import React, {useEffect, useRef} from 'react';
import {Form} from 'react-formio';
import {useUrlData} from '../../scheduler/util';
import {FhirUtils} from '../../../services/fhir';
import querystring from 'querystring';

interface ClinicFlowForm {
  id: string;
  name: string;
  form: any;
  type: string;
  formOptions: any;
  typeDefinition: {
    doseCompliance: string;
    apptCompliance: string;
  };
}

const dividerStyle = {
  fontSize: '14px',
  borderTopColor: 'rgba(0, 0, 0, 0.25)',
  color: 'rgba(0,0,0,1)',
  marginTop: '48px',
};

const formattedDose = (d) => (
  <>
    {d.displayName || d.concept?.displayName?.en} (
    <i>{moment.utc(d.date).local().format('D MMM YYYY')}</i>)
  </>
);

export const CreateNoteModal = (props) => {
  const {
    visible,
    onClose,
    patientId,
    patient: preloadedPatient,
    onSubmit,
    afterSubmit,
    formTypes,
    formGroups,
    dose: explicitDose,
    appointment: explicitAppointment,
    forceValues,
  } = props;
  const {syncUrl} = useUrlData();
  const [noteForm, setNoteForm] = React.useState<ClinicFlowForm>();
  const [noteForms, setNoteForms] = React.useState<any>();
  const [submitting, setSubmitting] = React.useState(false);
  const [selectedDose, setSelectedDose] = React.useState<string | undefined>(
    explicitDose ? explicitDose.id || 'explicit-dose-id' : undefined
  );
  const [selectedAppointment, setSelectedAppointment] = React.useState<any>(
    explicitAppointment?.id
  );
  const [formOptions, setFormOptions] = React.useState<any>();
  const ref = React.useRef<any>(null);
  const client = FhirUtils.useAxiosClient();

  const [patient, setPatient] = React.useState<any>(preloadedPatient);

  const {doseCompliance, apptCompliance} = noteForm?.typeDefinition || {};
  const showDose = doseCompliance && ['O', 'M'].includes(doseCompliance) && explicitDose !== null;
  const requireDose = doseCompliance === 'M';
  const showAppt =
    apptCompliance && ['O', 'M'].includes(apptCompliance) && explicitAppointment !== null;
  const requireAppt = apptCompliance === 'M';

  // Load the note forms if they have yet be loaded
  if (!noteForms) {
    let searchParams = new URLSearchParams();
    if (formTypes?.length) searchParams.append('formTypes', formTypes);
    if (formGroups?.length) searchParams.append('formGroups', formGroups);

    client
      .get(syncUrl + `/forms?${searchParams.toString()}`)
      .then((res) => {
        setNoteForms(
          // TODO: Do not hardcode removal of doseDecision forms
          res.data.entry.map((e) => e.resource).filter((e) => e.type !== 'doseDecision')
        );
      })
      .catch((err) => console.log('err', err));
  }

  React.useEffect(() => {
    if (!patient) loadPatient();
  }, []);

  async function loadPatient() {
    const params = {includeAppointments: true, includeImmunizations: true, excludeSchedule: true};
    return client
      .get(`/patient/${patientId}?${querystring.stringify(params)}`)
      .then(async (res) => {
        setPatient(res.data);
      });
  }

  // For when a noteForm is selected
  const onSelectForm = (id) => {
    client
      .get(syncUrl + `/public/form/${id}?includeTypeDefinition=true`)
      .then((res) => {
        /* Recursively force values into the form as necessary. */
        if (forceValues) forceValuesInFormComponent(res.data.form, forceValues);
        /* FormOptions must be set before form itself due to a Form rendering issue. */
        setFormOptions(res.data.formOptions);
        setNoteForm(res.data);
      })
      .catch((err) => {});
  };

  // If there is only 1 note form, automatically select it
  if (noteForms?.length === 1 && !noteForm) onSelectForm(noteForms[0].id);

  // For when a note is submitted
  const onSubmitNote = (values) => {
    // Create form submission
    const formSubmission = {
      formId: noteForm?.id,
      formName: noteForm?.name,
      formType: noteForm?.type,
      patientId: patient?.id,
      fields: values.data,
      doseId: (showDose && (explicitDose?.id || selectedDose)) || undefined,
      appointmentId: (showAppt && selectedAppointment) || undefined,
    };

    /* If a custom onSubmit function was provided, call it. */
    setSubmitting(true);
    if (onSubmit)
      return onSubmit(formSubmission, {
        onSuccess: () => setSubmitting(false),
        onError: () => setSubmitting(false),
        closeModal: onModalClose,
      });

    client
      .post(syncUrl + '/form-submissions', formSubmission)
      .then((res) => {
        setSubmitting(false);
        message.success('Note successfully created');
        afterSubmit?.();
        onModalClose();
      })
      .catch((err) => {
        setSubmitting(false);
        message.error('Encountered an error trying to create the note');
        console.log('err:', err);
      });
  };

  // When the modal is closed
  const onModalClose = () => {
    // Reset the forms and dropdowns on close
    setNoteForm(undefined);
    setSelectedDose(explicitDose ? explicitDose.id || 'explicit-dose-id' : undefined);
    setSelectedAppointment(explicitAppointment?.id);
    onClose?.();
  };

  return (
    <Modal
      visible={visible}
      onCancel={onModalClose}
      onOk={onModalClose}
      title={props.title ?? 'Create Note for Patient'}
      footer={
        <>
          <Button onClick={onModalClose}>Cancel</Button>
          <Button
            type="primary"
            onClick={() => {
              ref?.current.formio.submit();
            }}
            disabled={!noteForm}
            loading={submitting}
          >
            Submit
          </Button>
        </>
      }
      width={900}
      destroyOnClose={true}
    >
      <Divider orientation="left" className="dark" style={{...dividerStyle, marginTop: 0}}>
        Note Type
      </Divider>

      {/* Dropdown menu for forms when there 2+ */}
      {noteForms?.length > 1 && (
        <Select
          disabled={false}
          placeholder="Select a note type"
          onChange={onSelectForm}
          style={{width: '100%'}}
          value={noteForm?.name}
        >
          {noteForms?.map((form) => (
            <Select.Option value={form.id}>{form.name}</Select.Option>
          ))}
        </Select>
      )}

      {/* Static dropdown for when there is only 1 form */}
      {noteForms?.length === 1 && (
        <Select disabled={true} placeholder={noteForms[0].name} style={{width: '100%'}} />
      )}

      {/* Links Divider */}
      {(showDose || showAppt) && (
        <Divider orientation="left" className="dark" style={dividerStyle}>
          Links
        </Divider>
      )}

      {/* Dose Dropdown, when appropriate */}
      {noteForm && showDose && (
        <>
          <Select
            disabled={!!explicitDose}
            placeholder="Select the associated dose"
            onChange={(id) => {
              setSelectedDose(id);
            }}
            style={{width: '100%'}}
            value={selectedDose}
          >
            {!requireDose && !explicitDose && (
              <Select.Option value="">No associated dose</Select.Option>
            )}
            {!explicitDose &&
              patient?.doses
                ?.filter((d) => moment(d.date).isSameOrBefore(moment()))
                .map((d) => {
                  return <Select.Option value={d.id}>{formattedDose(d)}</Select.Option>;
                })}
            {explicitDose && (
              <Select.Option value={explicitDose.id || 'explicit-dose-id'}>
                {formattedDose(explicitDose)}
              </Select.Option>
            )}
          </Select>
        </>
      )}

      {/* Appt Dropdown, when appropriate */}
      {noteForm && showAppt && (
        <>
          <Select
            disabled={false}
            placeholder="Select the associated appointment"
            onChange={(id) => {
              setSelectedAppointment(id);
            }}
            style={{width: '100%', marginTop: showDose ? '16px' : '0'}}
            value={selectedAppointment}
          >
            {!requireAppt && <Select.Option value="">No associated appointment</Select.Option>}
            {patient?.appointments
              ?.filter((a) => moment(a.datetime).isSameOrBefore(moment()))
              .map((appt) => {
                return (
                  <Select.Option value={appt.id}>
                    {`${moment
                      .tz(appt.datetime, appt.clinic?.timezone || appt.timezone)
                      .format('LLL')}`}{' '}
                    {`${FormatTimezoneAbbr(
                      moment.tz(appt.datetime, appt.clinic?.timezone || appt.timezone).format('zz'),
                      'en',
                      appt.clinic?.timezone || appt.timezone
                    )}`}{' '}
                    {appt.clinic && `(${appt.clinic.name})`}
                  </Select.Option>
                );
              })}
          </Select>
        </>
      )}

      <div>
        {noteForm &&
          (!showDose || selectedDose !== undefined) &&
          (!showAppt || selectedAppointment !== undefined) && (
            <>
              <Divider orientation="left" className="dark" style={dividerStyle}>
                Fields
              </Divider>
              {
                <Form
                  form={noteForm.form}
                  options={formOptions}
                  onSubmit={onSubmitNote}
                  ref={ref}
                />
              }
            </>
          )}
      </div>
    </Modal>
  );
};

/* Recursively iterates through the form and force-applies the specified
   values. */
const forceValuesInFormComponent = (component, keyValueMap) => {
  if (keyValueMap[component.key]) {
    component.defaultValue = keyValueMap[component.key];
    component.disabled = true;
  }
  if (component.components)
    for (const childComponent of component.components)
      forceValuesInFormComponent(childComponent, keyValueMap);
};
