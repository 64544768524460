import {createModel} from './model-helpers/create-model';

export interface Campaign {
  id: string;
  name: string;
  organizationId: string;
}

const CampaignModel = createModel('campaigns', 'campaign');

export default {
  ...CampaignModel,
};
