import {Ids, StrLang} from '@canimmunize/tools';
import {faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Alert, Button, Col, Row} from 'antd';
import MaskedInput from 'antd-mask-input';
import axios from 'axios';
import {Formik} from 'formik';
import {Form, Input, Radio} from 'formik-antd';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {ReactDatePicker} from '../../../../components/pickers/react-datepicker';
import {useUrlData} from '../../../../components/scheduler/util';
import {ValidateFormItem} from '../../../../components/validate-form-item';
import {
  PhoneValidationRules,
  RequiredBirthDateValidationRules,
} from '../../../../services/ui-validation-rules';
import {RequiredValidationRuleId} from '../../../../validation/validation-rules/required-validation';
import {useCOVIDPortalConfig} from '../../util/portal-config';
import {SessionConfigContext} from '../../util/session-config';
import {SectionTitle, VerifyPhoneForm} from './portal-ns';

export const PEIPortalAuthForm = (props) => {
  const [mode, setMode] =
    React.useState<'select-auth' | 'hcn' | 'phone' | 'verify-phone'>('select-auth');
  const [phone, setPhone] = React.useState('');
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [birthdate, setBirthdate] = React.useState(null);
  const [emailSent, setEmailSent] = React.useState(false);
  const [hcn, setHcn] = React.useState('');

  const formProps = {
    mode,
    setMode,
    phone,
    setPhone,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    hcn,
    setHcn,
    birthdate,
    setBirthdate,
    emailSent,
    setEmailSent,
    setPage: props.setPage,
    setClinicEntry: props.setClinicEntry,
  };

  return (
    <Row>
      <Col>
        {/* Select Auth Method */}
        {mode === 'select-auth' && <SelectAuthMethod {...formProps} />}

        {/* HCN Form */}
        {mode === 'hcn' && <PEIHCNForm {...formProps} />}

        {/* Phone Form */}
        {mode === 'phone' && <PhoneForm {...formProps} />}

        {/* Verify Phone Form*/}
        {mode === 'verify-phone' && <VerifyPhoneForm {...formProps} />}
      </Col>
    </Row>
  );
};

const SelectAuthMethod = (props) => {
  const {setMode} = props;
  const {config} = useCOVIDPortalConfig();
  const {lang} = useUrlData();
  return (
    <>
      <h4>1. {StrLang(lang, Ids.did_you_provide_hcn)}</h4>
      <div style={{display: 'flex', justifyContent: 'center', marginTop: '50px'}}>
        <Button
          size="large"
          onClick={() => setMode('hcn')}
          style={{
            backgroundColor: config.primaryColor,
            border: 'none',
            color: 'white',
            marginLeft: '0 40px',
            width: '200px',
          }}
        >
          {StrLang(lang, Ids.yes)}
        </Button>
        <Button
          size="large"
          onClick={() => setMode('phone')}
          style={{
            backgroundColor: config.primaryColor,
            border: 'none',
            color: 'white',
            margin: '0 20px',
            width: '200px',
          }}
        >
          {StrLang(lang, Ids.no)}
        </Button>
      </div>
    </>
  );
};

const CommonPatientFields = (props) => {
  const {lang} = useUrlData();
  const {formikProps, firstNameTooltip, lastNameTooltip} = props;
  return (
    <>
      <ValidateFormItem
        validationRules={[
          {
            validationRuleType: RequiredValidationRuleId,
          },
        ]}
        renderFormItem={(validate) => {
          return (
            <Form.Item
              label={StrLang(lang, Ids.first_name)}
              name="firstName"
              tooltip={firstNameTooltip}
              required
              validate={validate}
            >
              <Input {...formikProps} name="firstName" size="large" />
            </Form.Item>
          );
        }}
      />
      <ValidateFormItem
        validationRules={[
          {
            validationRuleType: RequiredValidationRuleId,
          },
        ]}
        renderFormItem={(validate) => {
          return (
            <Form.Item
              label={StrLang(lang, Ids.pei_last_name)}
              name="lastName"
              tooltip={lastNameTooltip}
              required
              validate={validate}
            >
              <Input {...formikProps} name="lastName" size="large" />
            </Form.Item>
          );
        }}
      />
      <ValidateFormItem
        validationRules={RequiredBirthDateValidationRules}
        renderFormItem={(validate) => {
          return (
            <Form.Item
              label={StrLang(lang, Ids.date_of_birth)}
              name="birthdate"
              required
              validate={validate}
            >
              <ReactDatePicker
                name="birthdate"
                popperPlacement="top"
                dateFormat="yyyy/MM/dd"
                value={formikProps.values.birthdate}
                placeholder="YYYY/MM/DD"
                onChange={(e) => formikProps.setFieldValue('birthdate', e)}
              />
            </Form.Item>
          );
        }}
      />
    </>
  );
};

const PEIHCNForm = (props) => {
  const navigate = useNavigate();
  const [submitting, setSubmitting] = React.useState(false);
  const {lang} = useUrlData();
  const {setSessionId} = React.useContext(SessionConfigContext);
  const {
    setMode,
    setHcn,
    hcn,
    setFirstName,
    firstName,
    setLastName,
    lastName,
    birthdate,
    setBirthdate,
    setPage,
    setClinicEntry,
  } = props;
  const {syncUrl, config} = useCOVIDPortalConfig();
  const [error, setError] = React.useState<{message: string; stringId?: string}>();
  const handleSubmit = async (values, helpers) => {
    setSubmitting(true);
    setError(undefined);

    const result = await axios
      .post(`${syncUrl}/public/portal/auth`, {
        ...values,
        formType: 'pei-portal-hcn',
      })
      .catch((err) => err.response.data);

    setSubmitting(false);

    if (result.status === 200) {
      setClinicEntry(result.data);
      if (setSessionId) setSessionId(result.data?.sessionId);
      setPage(2);
      navigate(`/${lang}/portal/${result.data.records[0].portalId}`);
    } else {
      window.scrollTo(0, 0);
      console.log(result);
      setError(result.error);
    }
  };
  return (
    <Formik initialValues={{hcn, firstName, lastName, birthdate}} onSubmit={handleSubmit}>
      {(formikProps) => {
        return (
          <Form layout="vertical" style={{fontSize: 18}}>
            {error && (
              <Alert
                type="error"
                message={StrLang(lang, Ids[error?.stringId || '']) || error?.message}
                style={{marginBottom: 15}}
                showIcon
              />
            )}
            <div style={{marginBottom: 20}}>
              <h4>2. {StrLang(lang, Ids.enter_access_info)}</h4>
            </div>
            <div style={{maxWidth: 300}}>
              <ValidateFormItem
                validationRules={[
                  {
                    validationRuleType: RequiredValidationRuleId,
                  },
                ]}
                renderFormItem={(validate) => {
                  return (
                    <Form.Item
                      label={StrLang(lang, Ids.phu_health_card_number)}
                      name="hcn"
                      required
                      validate={validate}
                      tooltip={StrLang(lang, Ids.provide_hcn_on_health_card)}
                    >
                      <Input {...formikProps} name="hcn" size="large" autoComplete="off" />
                    </Form.Item>
                  );
                }}
              />
              <CommonPatientFields
                {...props}
                formikProps={formikProps}
                firstNameTooltip={StrLang(lang, Ids.provide_first_name_on_health_card)}
                lastNameTooltip={StrLang(lang, Ids.provide_last_name_on_health_card)}
              />
            </div>

            <div style={{marginBottom: 25, display: 'flex'}}>
              <Button
                size="large"
                loading={submitting}
                onClick={() => formikProps.handleSubmit()}
                style={{backgroundColor: config.primaryColor, border: 'none', color: 'white'}}
              >
                {StrLang(lang, Ids.submit)}
              </Button>
            </div>
            <p>{StrLang(lang, Ids.pei_consent_opt_out_of_record)}</p>
            <hr />
            <div>
              <Button
                style={{color: config.primaryColor, marginRight: 5}}
                size="large"
                onClick={() => setMode('select-auth')}
              >
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  color={config.primaryColor}
                  style={{marginRight: 5}}
                />
                {StrLang(lang, Ids.return_to_previous_page)}
              </Button>
            </div>

            {/* <hr />
            <div>
              <div style={{marginBottom: 15}}>
                If you did not provide an health card number when booking your COVID-19 vaccination
                appointment, press the button below to access your record by providing your phone
                number instead.
              </div>
              <Button
                style={{color: config.primaryColor, marginRight: 5}}
                size="large"
                onClick={() => {
                  setMode('phone');
                }}
              >
                Verify via Phone
              </Button>
            </div> */}
          </Form>
        );
      }}
    </Formik>
  );
};

const PhoneForm = (props) => {
  const [submitting, setSubmitting] = React.useState(false);
  const [error, setError] = React.useState<{message: string; stringId: string}>();
  const {setMode, setPhone, setHcn, hcn} = props;
  const {config, syncUrl} = useCOVIDPortalConfig();
  const {lang} = useUrlData();

  const handleSubmit = async (values, helpers) => {
    const cleanPhone = ('' + values.phone).replace(/\D/g, '');
    setPhone(cleanPhone);
    setHcn(values.hcn);

    setSubmitting(true);
    setError(undefined);

    const result = await axios
      .post(`${syncUrl}/public/portal/auth`, {
        ...values,
        formType: 'pei-portal-phone',
      })
      .catch((err) => err.response.data);

    setSubmitting(false);

    if (result.status === 200) {
      // setMode('email-sent');
      // setEmailSent(true);
      setMode('verify-phone');
    } else {
      window.scrollTo(0, 0);
      console.log(result);
      setError(result.error);
    }
  };

  return (
    <Formik
      initialValues={{channel: 'sms', phone: '', birthdate: null, firstName: '', lastName: ''}}
      onSubmit={handleSubmit}
    >
      {(formikProps) => {
        return (
          <Form layout="vertical" style={{fontSize: 18}}>
            {error && (
              <Alert
                type="error"
                message={StrLang(lang, Ids[error?.stringId || '']) || error?.message}
                style={{marginBottom: 15}}
                showIcon
              />
            )}
            <div style={{marginBottom: 10}}>
              <SectionTitle title={StrLang(lang, Ids.access_with_phone_number)} />
              <b>2. {StrLang(lang, Ids.enter_details_from_appointment)}</b>
            </div>
            <div style={{maxWidth: 300}}>
              <CommonPatientFields
                {...props}
                formikProps={formikProps}
                firstNameTooltip={StrLang(lang, Ids.provide_first_name_from_appointment)}
                lastNameTooltip={StrLang(lang, Ids.provide_last_name_from_appointment)}
              />
              {/* Twilio Verify Channel */}
              <Form.Item
                label={StrLang(lang, Ids.phone_type)}
                tooltip={
                  <>
                    <div>{StrLang(lang, Ids.phone_call_via_landline)}</div>
                    <br />
                    <div>{StrLang(lang, Ids.text_via_sms)}</div>
                  </>
                }
                name="channel"
                required
              >
                <Radio.Group
                  options={[
                    {label: StrLang(lang, Ids.mobile_phone), value: 'sms'},
                    {label: StrLang(lang, Ids.landline), value: 'call'},
                  ]}
                  name="channel"
                  optionType="button"
                  size="large"
                  buttonStyle="solid"
                />
              </Form.Item>
              <ValidateFormItem
                validationRules={[
                  ...PhoneValidationRules,
                  {
                    validationRuleType: RequiredValidationRuleId,
                  },
                ]}
                renderFormItem={(validate) => {
                  return (
                    <Form.Item
                      label={StrLang(lang, Ids.phone_10_digits)}
                      name="phone"
                      required
                      validate={validate}
                    >
                      <MaskedInput
                        {...formikProps}
                        onChange={(e) => {
                          formikProps.setFieldValue('phone', e.target.value);
                        }}
                        name="phone"
                        size="large"
                        mask="(111) 111-1111"
                      />
                    </Form.Item>
                  );
                }}
              />
            </div>
            <div>
              <p>
                {StrLang(lang, Ids.twilio_note)},{' '}
                <a href="https://www.twilio.com/legal/privacy" target="_blank">
                  {StrLang(lang, Ids.click_here)}
                </a>
                .
              </p>
              <p>{StrLang(lang, Ids.pei_consent_opt_out_of_twilio)}</p>
              <p>{StrLang(lang, Ids.twilio_consent)}</p>
            </div>

            <div style={{marginBottom: 25}}>
              <Button
                size="large"
                loading={submitting}
                onClick={() => formikProps.handleSubmit()}
                style={{backgroundColor: config.primaryColor, border: 'none', color: 'white'}}
              >
                {StrLang(lang, Ids.submit)}
              </Button>
            </div>

            <hr />
            <div>
              <Button
                style={{color: config.primaryColor, marginRight: 5}}
                size="large"
                onClick={() => setMode('select-auth')}
              >
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  color={config.primaryColor}
                  style={{marginRight: 5}}
                />
                {StrLang(lang, Ids.return_to_previous_page)}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
