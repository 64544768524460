import React from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '../../models';
import APIToken from '../../models/api-token';

import {FormUX} from '../form-ux';
import {FormConfig} from '../base-entity/base-entity';
import {FHIRTableProps} from '../fhir-table';

import moment from 'moment';
import {APITokenCustomFieldName, APITokenUXModel} from './api-ux-model';
import {OrganizationPickerFormItem} from '../organization/organization-picker';
import {colors} from '@canimmunize/tools';
import {Badge} from 'antd';
import {useTokenOrgId} from '../../services/fhir';
import {BaseEntityV2} from '../base-entity/base-entity';

export const APITokenUX = () => {
  const globalOrg = useSelector((state: RootState) => state.ui.localOrg);
  const model = APIToken;

  const organizationId = useTokenOrgId();

  const itemTitleField = 'description';

  const fixedFilters: any = {_organizationId: organizationId};

  const tableConfig: FHIRTableProps = {
    fhirResource: 'token',
    label: 'API Tokens',
    fixedFilters,
    defaultPrimarySearchParam: 'name',
    mode: 'table',
    showCreateButton: true,
    columns: [
      {
        title: 'Description',
        dataIndex: 'description',
        sorter: true,
      },
      {
        dataIndex: 'createdAt',
        title: 'Created',
        render: (_, row) => moment(row.createdAt).format('LLL'),
      },
      {
        title: 'Organization',
        dataIndex: 'organization.nameEn',
        render: (_, resource) => resource.organization?.name.en,
        sorter: true,
      },
    ],
  };

  const Form = (props) => {
    return (
      <FormUX
        formUXModel={APITokenUXModel}
        createMode={props.createMode}
        modal={props.modal}
        renderCustomField={(field) => {
          switch (field.name) {
            case APITokenCustomFieldName.OrganizationId: {
              return (
                <OrganizationPickerFormItem
                  label={field.label}
                  name={field.name}
                  setFieldValue={props.setFieldValue}
                  validationRules={field.validationRules}
                />
              );
            }
            default: {
              throw new Error(`Unhandled custom field ${field.name} in
          renderCustomField method`);
            }
          }
        }}
      />
    );
  };

  const formConfig: FormConfig = {
    defaultValues: {},
    FormItems: Form,
  };

  const config = {
    slug: 'token',
    model,
    formConfig,
    itemTitleField,
    // itemPage: ListItemPage,
    searchPageTitle: 'API Tokens',
    fhirResource: 'token',
    tableConfig,
    modalCreateForm: true,
    modalEditForm: true,
    modalEditWidth: 800,
    hideDeleteButton: true,
    titleBarEntityLabel: 'API Token',
  };

  return <BaseEntityV2 {...config} />;
};
