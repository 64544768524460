"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_StructureMap_Target = exports.StructureMap_TargetTransformKind = exports.StructureMap_TargetContextTypeKind = exports.StructureMap_TargetListModeKind = void 0;
var t = __importStar(require("io-ts"));
var RTTI_Extension_1 = require("./RTTI_Extension");
var RTTI_id_1 = require("../Scalar/RTTI_id");
var RTTI_Element_1 = require("./RTTI_Element");
var RTTI_StructureMap_Parameter_1 = require("./RTTI_StructureMap_Parameter");
var StructureMap_TargetListModeKind;
(function (StructureMap_TargetListModeKind) {
    StructureMap_TargetListModeKind["_first"] = "first";
    StructureMap_TargetListModeKind["_share"] = "share";
    StructureMap_TargetListModeKind["_last"] = "last";
    StructureMap_TargetListModeKind["_collate"] = "collate";
})(StructureMap_TargetListModeKind = exports.StructureMap_TargetListModeKind || (exports.StructureMap_TargetListModeKind = {}));
var StructureMap_TargetContextTypeKind;
(function (StructureMap_TargetContextTypeKind) {
    StructureMap_TargetContextTypeKind["_type"] = "type";
    StructureMap_TargetContextTypeKind["_variable"] = "variable";
})(StructureMap_TargetContextTypeKind = exports.StructureMap_TargetContextTypeKind || (exports.StructureMap_TargetContextTypeKind = {}));
var StructureMap_TargetTransformKind;
(function (StructureMap_TargetTransformKind) {
    StructureMap_TargetTransformKind["_create"] = "create";
    StructureMap_TargetTransformKind["_copy"] = "copy";
    StructureMap_TargetTransformKind["_truncate"] = "truncate";
    StructureMap_TargetTransformKind["_escape"] = "escape";
    StructureMap_TargetTransformKind["_cast"] = "cast";
    StructureMap_TargetTransformKind["_append"] = "append";
    StructureMap_TargetTransformKind["_translate"] = "translate";
    StructureMap_TargetTransformKind["_reference"] = "reference";
    StructureMap_TargetTransformKind["_dateOp"] = "dateOp";
    StructureMap_TargetTransformKind["_uuid"] = "uuid";
    StructureMap_TargetTransformKind["_pointer"] = "pointer";
    StructureMap_TargetTransformKind["_evaluate"] = "evaluate";
    StructureMap_TargetTransformKind["_cc"] = "cc";
    StructureMap_TargetTransformKind["_c"] = "c";
    StructureMap_TargetTransformKind["_qty"] = "qty";
    StructureMap_TargetTransformKind["_id"] = "id";
    StructureMap_TargetTransformKind["_cp"] = "cp";
})(StructureMap_TargetTransformKind = exports.StructureMap_TargetTransformKind || (exports.StructureMap_TargetTransformKind = {}));
var EnumType_1 = require("../../EnumType");
exports.RTTI_StructureMap_Target = t.recursion('IStructureMap_Target', function () {
    return t.partial({
        id: t.string,
        extension: t.array(RTTI_Extension_1.RTTI_Extension),
        modifierExtension: t.array(RTTI_Extension_1.RTTI_Extension),
        context: RTTI_id_1.RTTI_id,
        _context: RTTI_Element_1.RTTI_Element,
        contextType: EnumType_1.createEnumType(StructureMap_TargetContextTypeKind, 'StructureMap_TargetContextTypeKind'),
        _contextType: RTTI_Element_1.RTTI_Element,
        element: t.string,
        _element: RTTI_Element_1.RTTI_Element,
        variable: RTTI_id_1.RTTI_id,
        _variable: RTTI_Element_1.RTTI_Element,
        listMode: t.array(EnumType_1.createEnumType(StructureMap_TargetListModeKind, 'StructureMap_TargetListModeKind')),
        _listMode: t.array(RTTI_Element_1.RTTI_Element),
        listRuleId: RTTI_id_1.RTTI_id,
        _listRuleId: RTTI_Element_1.RTTI_Element,
        transform: EnumType_1.createEnumType(StructureMap_TargetTransformKind, 'StructureMap_TargetTransformKind'),
        _transform: RTTI_Element_1.RTTI_Element,
        parameter: t.array(RTTI_StructureMap_Parameter_1.RTTI_StructureMap_Parameter)
    });
});
