import {Button, Col, Modal, Popconfirm, Row, Space, Spin, Typography} from 'antd';
import {Formik, FormikHelpers, FormikProps, useFormikContext} from 'formik';
import {Form, Input as AntdFormikInput} from 'formik-antd';
import React, {useCallback, useContext, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {UNSAFE_NavigationContext as NavigationContext, useNavigate} from 'react-router-dom';
import {ThunkDispatch} from '../../models';
import {useGlobalOrg} from '../../models/ui';
import {FhirUtils} from '../../services/fhir';
import {ButtonGroup} from '../buttons';
import {FHIRTableProps} from '../fhir-table';
import {FormConfig} from './base-entity';

export const BaseItemForm = (props: {
  itemId?: string;
  modal?: boolean;
  model?: any;
  modalVisible?;
  setModalVisible?;
  editMode?;
  formConfig?: FormConfig;
  slug?: string;
  slugs?: string;
  onCreationSuccess?: (resource: any) => void;
  modalEditWidth?: number;
  hideSaveButton?: boolean;
  validate?: (values: any) => {
    [fieldName: string]: string;
  };
  tableConfig?: Partial<FHIRTableProps>;
  fhirResource?: string;
  modalEditForm?: boolean;
  getTitleInfo?: (item: any) => any;
  showOverride?: boolean;
  queryString?: string;
  hideEditButton?: boolean | ((item) => boolean);
  setEditMode?;
  tabs?;
}) => {
  const globalOrg = useGlobalOrg();
  const navigate = useNavigate();
  const {
    itemId = 'new',
    modal,
    setModalVisible,
    modalVisible,
    editMode,
    formConfig,
    getTitleInfo,
    slug,
    onCreationSuccess,
    modalEditWidth,
    model,
    hideSaveButton,
    validate,
    tableConfig,
    fhirResource,
    modalEditForm,
    queryString,
    hideEditButton,
    setEditMode,
    tabs,
  } = props;
  const thunkDispatch = useDispatch<ThunkDispatch>();
  const dispatch = useDispatch();
  const client = FhirUtils.useClient();
  const [error, setError] = React.useState('');
  const [overrideModalVisible, setOverrideModalVisible] = React.useState(false);
  const [afterClose, setAfterClose] = React.useState<() => any>();

  const slugs = props.slugs || `${slug}s`;
  const itemSelector = formConfig?.itemSelector
    ? formConfig.itemSelector({itemId, slugs})
    : (state) => state[slugs]?.byId[itemId];

  const item = useSelector(itemSelector);
  const titleInfo = getTitleInfo ? getTitleInfo(item) : undefined;
  const title = titleInfo?.title ?? 'No Title';

  // Tracks whether we're creating a new object or editing an existing one
  const createMode = itemId === 'new';

  if (!formConfig) return null;

  if (item === undefined && !createMode) {
    return <Spin />;
  }

  // Get the initial values for the form using the toForm transformer if one is provided
  // Otherwise use the item itself
  const initialValues = item
    ? formConfig.toForm
      ? formConfig.toForm(item)
      : item
    : formConfig.defaultValues
    ? formConfig.defaultValues
    : {};

  const entityContext = {
    slug,
    slugs,
    client,
    navigate,
    dispatch,
    globalOrg,
  };

  // Responsible for calling the formConfig's preSubmit function if one is provided
  // Otherwise it should call the default onSubmit
  const onPreSubmit = (values, actions: FormikHelpers<any>) => {
    if (formConfig.preSubmit) {
      formConfig.preSubmit(values, actions, onSubmit, entityContext);
    } else {
      onSubmit(values, actions);
    }
  };

  const onSubmit = (values, actions: FormikHelpers<any>) => {
    actions.setSubmitting(true);
    // Create the fhir resource to submit
    const itemToSubmit = formConfig && formConfig.toFhir ? formConfig.toFhir(values, item) : values;

    // Select which thunk to dispatch
    const submitFn = createMode ? model.createOne : model.updateOne;

    // Default resolve function
    const defaultOnSaveActionResolve = (res) => {
      // On create, navigate to the new item's ItemPage; on update, reset the form.
      if (createMode) {
        if (onCreationSuccess) {
          return onCreationSuccess(res);
        } else {
          /* This makes sure that clicking the back button does not take
            them to new form page but to the page before that */
          setTimeout(() => {
            navigate(`/${slugs}`, {replace: true});
            navigate(`/${slugs}/${res.id}`);
          }, 0);
        }
      } else {
        if (editMode) {
          setEditMode(false);
        }
        actions.resetForm();
      }
    };

    // Use the onResolve from formConfig if one was provided
    const onSaveActionResolve = formConfig.onSaveActionResolve
      ? formConfig.onSaveActionResolve(
          {createMode, navigate, slugs, submittedItem: itemToSubmit},
          defaultOnSaveActionResolve
        )
      : defaultOnSaveActionResolve;

    let submitFnArgs = [client, itemToSubmit];
    if (queryString) submitFnArgs.push(queryString);
    thunkDispatch(submitFn(...submitFnArgs))
      .then((o) => {
        if (modalVisible) {
          //Wait for the modal animation to end before running resolve (workaround)
          setAfterClose(() => () => onSaveActionResolve(o));
          setModalVisible(false);
        } else {
          onSaveActionResolve(o);
        }
      })
      .catch((err) => {
        setError(FhirUtils.getErrorDisplay(err));
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  const ItemFormToolBar = () => {
    const formikContext = useFormikContext();
    const {handleSubmit, isSubmitting} = formikContext;

    return (
      <div>
        <ButtonGroup id="ItemFormToolbar">
          {/* When save button should be hidden
            1. hideSaveButton flag is on
            2. item is not in editMode when itempage is editable(hideEditButton flag is off) */}
          {!(
            hideSaveButton ||
            (!(typeof hideEditButton === 'function' ? hideEditButton(item) : hideEditButton) &&
              !editMode)
          ) && (
            <>
              <Button
                shape="round"
                type="primary"
                loading={isSubmitting}
                disabled={disableSaveButton(formikContext)}
                onClick={handleSubmit as any}
              >{`Save`}</Button>
              {error && (
                <span className="ant-typography ant-typography-danger" style={{marginLeft: 5}}>
                  {error}
                </span>
              )}
            </>
          )}
        </ButtonGroup>
      </div>
    );
  };

  const PromptIfDirty = () => {
    const formikProps = useFormikContext();
    usePrompt(
      'Are you sure you want to leave? You have unsaved changes.',
      !modal && formikProps.dirty && formikProps.submitCount === 0
    );
    return null;
  };

  const {FormItems} = formConfig;

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      initialTouched={initialValues}
      onSubmit={onPreSubmit}
      validateOnMount={true}
      validateOnChange={true}
      validate={validate}
    >
      {(formikProps) => {
        const showOverrideButton = props.showOverride || false;
        const ModalFooter = () => {
          return (
            <Space>
              {error && <Typography.Text type="danger">{error}</Typography.Text>}
              {showOverrideButton && (
                <Button
                  onMouseDown={(e) => {
                    e.preventDefault();
                  }}
                  onClick={() => setOverrideModalVisible(true)}
                >
                  Override
                </Button>
              )}
              <Popconfirm
                title="Are you sure you want to close? You have unsaved changes."
                disabled={!formikProps.dirty}
                onConfirm={() => {
                  setModalVisible(false);
                  setError('');
                  formikProps.resetForm();
                }}
                okButtonProps={{
                  onMouseDown: (e) => {
                    e.preventDefault();
                  },
                }}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  onMouseDown={(e) => {
                    e.preventDefault();
                  }}
                  onClick={() => {
                    if (formikProps.dirty) return;
                    setModalVisible(false);
                  }}
                >
                  Cancel
                </Button>
              </Popconfirm>
              <Button
                loading={formikProps.isSubmitting}
                type="primary"
                onMouseDown={(e) => {
                  e.preventDefault();
                }}
                onClick={() => {
                  formikProps.handleSubmit();
                }}
                disabled={disableSaveButton(formikProps)}
              >
                Save
              </Button>
            </Space>
          );
        };

        const overrideDisabled =
          formikProps.values.override === undefined ||
          formikProps.values.override.overrideReason.length === 0;

        return modal ? (
          <Modal
            title={
              editMode || (!tableConfig && !fhirResource)
                ? title
                : `New ${tableConfig ? tableConfig.label : fhirResource ? fhirResource : title}`
            }
            visible={modalVisible}
            width={modalEditWidth}
            footer={<ModalFooter />}
            closable={false}
            maskClosable={false}
            afterClose={afterClose}
            onCancel={() => setModalVisible(false)}
            destroyOnClose
          >
            <>
              <FormItems
                {...formikProps}
                item={item}
                createMode={createMode}
                editMode={editMode}
                modal={true}
                tabs={tabs}
              />
              {/* Override Modal */}
              <Modal
                title="Override"
                visible={overrideModalVisible}
                onCancel={() => {
                  setOverrideModalVisible(false);
                }}
                okButtonProps={{
                  disabled: overrideDisabled,
                }}
                onOk={() => {
                  setOverrideModalVisible(false);
                  formikProps.handleSubmit();
                }}
              >
                <Form.Item
                  name={'override.overrideReason'}
                  label={'Override Reason'}
                  key={'override.overrideReason'}
                  required
                >
                  <AntdFormikInput.TextArea name="override.overrideReason" />
                </Form.Item>
              </Modal>
            </>
          </Modal>
        ) : (
          <>
            <PromptIfDirty />
            <FormItems
              {...formikProps}
              item={item}
              createMode={createMode}
              editMode={editMode && !modalEditForm}
              modal={false}
              tabs={tabs}
            />
            <ToolBar>
              <ItemFormToolBar />
            </ToolBar>
          </>
        );
      }}
    </Formik>
  );
};

/**
 * Used to check whether the Save button in a form should be disabled
 * <pre></pre>
 * Currently it disables it if the form is not dirty or if its invalid
 *
 * @param {FormikProps<any>} formikProps
 * @returns
 */
function disableSaveButton(formikProps: FormikProps<any>): boolean {
  return !formikProps.dirty || !formikProps.isValid;
}

export const ToolBar = (props) => {
  return (
    <Row gutter={[16, 16]} justify="start">
      <Col style={{display: 'flex', justifyContent: 'flex-end'}}>{props.children}</Col>
    </Row>
  );
};

/**
 * These hooks re-implement the now removed useBlocker and usePrompt hooks in 'react-router-dom'.
 * https://github.com/remix-run/react-router/issues/8139
 */

/**
 * Blocks all navigation attempts. This is useful for preventing the page from
 * changing until some condition is met, like saving form data.
 *
 * @param  blocker
 * @param  when
 * @see https://reactrouter.com/api/useBlocker
 */
export function useBlocker(blocker, when = true) {
  const {navigator} = useContext(NavigationContext);

  useEffect(() => {
    if (!when) return;

    const unblock = (navigator as any).block((tx) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          // Automatically unblock the transition so it can play all the way
          // through before retrying it. TODO: Figure out how to re-enable
          // this block if the transition is cancelled for some reason.
          unblock();
          tx.retry();
        },
      };

      blocker(autoUnblockingTx);
    });

    return unblock;
  }, [navigator, blocker, when]);
}

/**
 * Prompts the user with an Alert before they leave the current screen.
 *
 * @param  message
 * @param  when
 */
export function usePrompt(message, when = true) {
  const blocker = useCallback(
    (tx) => {
      // eslint-disable-next-line no-alert
      if (window.confirm(message)) tx.retry();
    },
    [message]
  );

  useBlocker(blocker, when);
}
