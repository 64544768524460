import {R4} from '@ahryman40k/ts-fhir-types';
import {BundleTypeKind} from '@ahryman40k/ts-fhir-types/lib/R4';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {normalize, schema} from 'normalizr';
import querystring from 'querystring';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState, ThunkDispatch} from '.';
import {FhirUtils} from '../services/fhir';
import {
  makeIntegerValidator,
  makeSpecialCharacterValidator,
  OptionalUUIDValidationRules,
  RequiredInteger,
  RequiredShortTextValidationRules,
  RequiredUUIDValidationRules,
  ShortTextValidationRules,
} from '../services/ui-validation-rules';
import {AppThunk} from '../store';
import {mapBundleToResourceArray} from '../util/fhir';
import {ListItemValidationRuleId} from '../validation/validation-rules/list-validation';
import {PostalCodeValidationRuleId} from '../validation/validation-rules/postal-code-validation';
import {RegexValidationId} from '../validation/validation-rules/regex-validation';
import {RequiredValidationRuleId} from '../validation/validation-rules/required-validation';
import {TimezoneValidationRuleId} from '../validation/validation-rules/timezone-validation';
import {ValidationRules} from '../validation/validation-rules/validation-rules';

export const clinicUploadTemplateName = 'clinic-and-calendar-upload-template';

export interface Clinic {
  id: string;
  name: string;
  location: any;
  covidScreeningForm: any;
  healthStatusForm: any;
  consentForm: any;
  timezone: string;
  category: string;
  status: string;
  autoBookSecondAppointment: string;
  selfCheckInClinic: string;
  organizationId: string;
  targetSecondAppointmentInterval: number;
  vaccineConcept: any;
}

const clinicTimeFormat = /^(?:[0-2]?[0-9]:[0-5][0-9])?$/s;
const clinicDateFormat = /^(?:[0-3][0-9]-[A-Z][a-z][a-z]-202[0-9])?$/s;

const makeDateTimeRule = (
  fieldName: string,
  isDate: boolean,
  required: boolean
): ValidationRules[] => {
  const rule: ValidationRules = {
    validationRuleType: RegexValidationId,
    regex: isDate ? clinicDateFormat : clinicTimeFormat,
    errorMessage: `The provided ${fieldName} should be ${
      isDate ? 'formatted like this: 15-Apr-2021' : 'a 24 hour time, like 13:45'
    }`,
  };
  return required ? [{validationRuleType: RequiredValidationRuleId}, rule] : [rule];
};

export const generateClinicTypeValues = (settings) => {
  const {environmentId} = settings;
  const enableGroupAppointment = settings['enableGroupAppointment']?.value === 'true';
  let clinicTypes;

  switch (environmentId) {
    case 'yukon-2':
    case 'yukon-2-uat':
      clinicTypes = ['Health Care Workers', 'Pharmacy', 'LTC', 'Community', 'Outreach'];
      break;
    default:
      clinicTypes = [
        'First Nations',
        'African NS',
        'Health Care Workers',
        'Community',
        'LTC',
        'Physicians (DNS)',
        'Pharmacy (PANS)',
        'Outreach',
        'Mobile',
        'Pharmacy',
      ];
      break;
  }

  if (enableGroupAppointment) clinicTypes.push('Group');

  return clinicTypes;
};

export const ClinicCSVValidation = (
  clinicRow,
  settings
): {
  [property: string]: ValidationRules[];
} => {
  const clinicTypes = generateClinicTypeValues(settings);

  const hasClinicId = !!clinicRow['Clinic ID'];
  const hasCalendarName = !!clinicRow['Calendar Name'];

  const baseRules: {[property: string]: ValidationRules[]} = {
    'Rule Start Time': makeDateTimeRule('Rule Start Time', false, hasCalendarName),
    'Rule End Time': makeDateTimeRule('Rule End Time', false, hasCalendarName),
    'Rule Start Date': makeDateTimeRule('Rule Start Date', true, hasCalendarName),
    'Rule End Date': makeDateTimeRule('Rule End Date', true, hasCalendarName),
    'Consent Form ID': hasClinicId ? OptionalUUIDValidationRules : RequiredUUIDValidationRules,
    'Health Status Form ID': hasClinicId
      ? OptionalUUIDValidationRules
      : RequiredUUIDValidationRules,
    'Demographic Form ID': hasClinicId ? OptionalUUIDValidationRules : RequiredUUIDValidationRules,
    'COVID-19 Screen ID': hasClinicId ? OptionalUUIDValidationRules : RequiredUUIDValidationRules,
    'Clinic Type': hasClinicId
      ? [
          {
            validationRuleType: ListItemValidationRuleId,
            validValues: clinicTypes,
          },
        ]
      : [
          {validationRuleType: RequiredValidationRuleId},
          {
            validationRuleType: ListItemValidationRuleId,
            validValues: clinicTypes,
          },
        ],

    campaignId: hasClinicId ? OptionalUUIDValidationRules : RequiredUUIDValidationRules,
    'Postal Code': hasClinicId
      ? [{validationRuleType: PostalCodeValidationRuleId}]
      : [
          {validationRuleType: RequiredValidationRuleId},
          {validationRuleType: PostalCodeValidationRuleId},
        ],
    'Clinic Name': makeSpecialCharacterValidator('Clinic Name', !hasClinicId),
    'Calendar Name': makeSpecialCharacterValidator('Calendar Name', false),
    'ORG Code': makeSpecialCharacterValidator('ORG Code', !hasClinicId),
    timezone: hasClinicId
      ? [{validationRuleType: TimezoneValidationRuleId}]
      : [
          {validationRuleType: RequiredValidationRuleId},
          {validationRuleType: TimezoneValidationRuleId},
        ],
    'Dose 2 Interval (Min)': makeIntegerValidator('Dose 2 Interval (Min)', !hasClinicId),
    'Dose 2 Interval (Target)': makeIntegerValidator('Dose 2 Interval (Target)', !hasClinicId),
    'Dose 2 Interval (Max)': makeIntegerValidator('Dose 2 Interval (Max)', !hasClinicId),
    vaccineConceptId: makeIntegerValidator('vaccineConceptId', !hasClinicId),
    City: hasClinicId ? ShortTextValidationRules : RequiredShortTextValidationRules,
    'Service Delivery Location': hasClinicId
      ? ShortTextValidationRules
      : RequiredShortTextValidationRules,
    'Maps Location': hasClinicId ? ShortTextValidationRules : RequiredShortTextValidationRules,
    'Location Display ENG': hasClinicId
      ? ShortTextValidationRules
      : RequiredShortTextValidationRules,
    'Location Display FR': hasClinicId
      ? ShortTextValidationRules
      : RequiredShortTextValidationRules,
  };

  const calendarRules: {[property: string]: ValidationRules[]} = hasCalendarName
    ? {
        'Calendar Type': [
          {validationRuleType: RequiredValidationRuleId},
          {validationRuleType: ListItemValidationRuleId, validValues: ['Public', 'Internal']},
        ],
        'Autobook Dose 2 and Mirror Calendar': [
          {
            validationRuleType: ListItemValidationRuleId,
            validValues: ['yes', 'Yes', 'YES', 'no', 'No', 'NO'],
          },
        ],
        'Show on Booking Page': [
          {
            validationRuleType: ListItemValidationRuleId,
            validValues: ['yes', 'Yes', 'YES', 'no', 'No', 'NO'],
          },
        ],
        'Appointment Duration': RequiredInteger,
        '# of Immunizers': RequiredInteger,
      }
    : {};

  return hasClinicId
    ? {
        ...baseRules,
        ...calendarRules,
        'Clinic ID': [
          {
            validationRuleType: RegexValidationId,
            regex: /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/,
            errorMessage: 'The provided Clinic ID should be a valid UUID',
          },
        ],
      }
    : {...baseRules, ...calendarRules};
};

export interface ClinicsSliceInterface {
  byId: {[clinicId: string]: Clinic};
}

const clinic = new schema.Entity('clinics', {});

export const clinicsSlice = createSlice({
  name: 'clinics',
  initialState: {
    byId: {},
  },
  reducers: {
    saveClinics: (state: ClinicsSliceInterface, action: PayloadAction<Clinic>) => {
      // @ts-ignore
      const resources = mapBundleToResourceArray(action.payload);
      const entities = normalize(resources, [clinic]).entities;
      state.byId = entities.clinic || entities.clinics || {};
    },
    saveClinic: (state: ClinicsSliceInterface, action: PayloadAction<Clinic>) => {
      state.byId[action.payload.id] = action.payload;
    },
    deleteClinic: (state: ClinicsSliceInterface, action: PayloadAction<{id: string}>) => {
      delete state.byId[action.payload.id];
    },
  },
});

const ClinicResourceRoute = '/clinic';

export const getAll = (client, params?) => async (dispatch) => {
  let query = params ? querystring.stringify(params) : undefined;
  const res = await client.get(`/clinic${query ? `?${query}` : ''}`);
  dispatch(clinicsSlice.actions.saveClinics(res.data));
  return res.data;
};

export const createOne = (client, item): AppThunk => {
  return async (dispatch) => {
    return client.post(ClinicResourceRoute, item).then(async (res) => {
      await dispatch(clinicsSlice.actions.saveClinics(res.data));
      return res.data;
    });
  };
};

export const getOne = (client, id): AppThunk => {
  return async (dispatch) => {
    return client.get(`${ClinicResourceRoute}/${id}`).then(async (res) => {
      await dispatch(clinicsSlice.actions.saveClinic(res.data));
    });
  };
};

export const updateOne = (client, item: any): AppThunk => {
  return async (dispatch) => {
    //Remove clinicModeUsers and cohorts from the request
    if (item.cohorts) {
      delete item.cohorts;
    }
    if (item.clinicModeUsers) {
      delete item.clinicModeUsers;
    }
    return client.put(`${ClinicResourceRoute}/${item.id}`, item).then(async (res) => {
      await dispatch(clinicsSlice.actions.saveClinic(res.data));
      return res.data;
    });
  };
};

export const uploadClinics = (client, clinics) => async (dispatch) => {
  const bundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: BundleTypeKind._transaction,
    entry: clinics.map((c) => ({resource: c})),
  };

  return client.post(`/Clinic`, bundle).then(async (res) => {
    return res.data;
  });
};

export const deleteOne = (client, itemId) => async (dispatch) => {
  return client.post(`${ClinicResourceRoute}/${itemId}/delete`).then(async (res) => {
    await dispatch(clinicsSlice.actions.deleteClinic({id: itemId}));
    return res.data;
  });
};

export const useClinics = () => {
  const thunkDispatch = useDispatch<ThunkDispatch>();
  const client = FhirUtils.useClient();

  React.useEffect(() => {
    thunkDispatch(getAll(client));
  }, []);

  const clinics = useSelector((state: RootState) => state.clinics.byId);
  return Object.values(clinics);
};
