import {createSlice} from '@reduxjs/toolkit';
import {normalize, schema} from 'normalizr';
import querystring from 'querystring';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState, ThunkDispatch} from '.';
import {FhirUtils} from '../services/fhir';
import {mapBundleToResourceArray} from '../util/fhir';

interface Appointment {
  id: string;
  name: string;
  organizationId: string;
}

export interface AppointmentsSliceInterface {
  byId: {[string: string]: Appointment};
}
const initialState = {
  byId: {},
};

const appointment = new schema.Entity('appointments', {});

const slice = createSlice({
  name: 'appointments',
  initialState,
  reducers: {
    SAVE_APPOINTMENTS: (state: AppointmentsSliceInterface, action) => {
      const resources = mapBundleToResourceArray(action.payload);
      state.byId = normalize(resources, [appointment]).entities.appointments || {};
    },
    SAVE_APPOINTMENT: (state: AppointmentsSliceInterface, action) => {
      state.byId[action.payload.id] = action.payload;
    },
    DELETE_APPOINTMENT: (state: AppointmentsSliceInterface, action) => {
      delete state.byId[action.payload.id];
    },
  },
});

export const getAll = (client, params?) => async (dispatch) => {
  let query = params ? querystring.stringify(params) : undefined;
  const res = await client.get(`/appointment${query ? `?${query}` : ''}`);
  dispatch(slice.actions.SAVE_APPOINTMENTS(res.data));
  return res.data;
};

const getOne = (client, id) => async (dispatch) => {
  return client.get(`/appointment/${id}`).then((res) => {
    return dispatch(slice.actions.SAVE_APPOINTMENT(res.data));
  });
};

const updateOne = (client, org) => async (dispatch) => {
  return client.put(`/appointment/${org.id}`, org).then(async (res) => {
    await dispatch(slice.actions.SAVE_APPOINTMENT(res.data));
    return res.data;
  });
};

export const createOne = (client, appointment) => async (dispatch) => {
  return client.post(`/appointment`, appointment).then(async (res) => {
    await dispatch(slice.actions.SAVE_APPOINTMENT(res.data));
    return res.data;
  });
};

export const deleteOne = (client, itemId) => async (dispatch) => {
  return client.delete(`/appointment/${itemId}`).then(async (res) => {
    await dispatch(slice.actions.DELETE_APPOINTMENT({id: itemId}));
    return res.data;
  });
};

export default {
  slice,
  getAll,
  getOne,
  updateOne,
  createOne,
  deleteOne,
};

export const useAppointments = () => {
  const thunkDispatch = useDispatch<ThunkDispatch>();
  const client = FhirUtils.useClient();

  React.useEffect(() => {
    thunkDispatch(getAll(client));
  }, []);

  const appointments = useSelector((state: RootState) => state.appointments.byId);

  return Object.values(appointments);
};
