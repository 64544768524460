  
  export const updateOne = async (client, item) => {
    const res = await client.put(`/availability-rule/${item.id}`, item);
    return res.data;
  };
  
  export const createOne = async (client, item) => {
    const res = await client.post(`/availability-rule`, item);
    return res.data;
  };
  
  export const deleteOne = async (client, itemId) => {
    const res = await client.delete(`/availability-rule/${itemId}`);
    return res.data;
  };
  
  export default {
    updateOne,
    createOne,
    deleteOne,
  };
  