import {Alert, Image, Spin} from 'antd';
import React, {MouseEventHandler, RefObject} from 'react';
import {useLocation} from 'react-router-dom';
import {useReactToPrint} from 'react-to-print';
import {Col, Row} from 'reactstrap';
import {useEnvInfo} from '../../services/environment';
import {FhirUtils} from '../../services/fhir';
import {DoseList} from './components/dose-list';
import styles from '../../views/vaccine-receipt/components/dose-list/index.module.css';
import {ReceiptInstructions} from './components/receipt-instructions';
import {ReceiptTitle} from './components/receipt-title';
import {COVIDPortalConfigProvider} from './util/portal-config';

interface PublicVaccineReceiptProps {
  /* A list of patients with receipt data. */
  patients?: Array<any>;
  /* Used to make the receipt load a patient's receipt data when no patients provided. */
  patientId?: string;
  /* A ref to be attached to the PublicVaccineReceipt. */
  printingRef?: RefObject<any>;
  /* Logo displayed in top-left corner. Defaults to logos in env settings. */
  logoUrl?: string;
  /* A custom print function. Defaults to printing the PublicVaccineReceipt. */
  handlePrint?: MouseEventHandler<HTMLElement>;
  /* When true, the receipt will be display:none unless printing.
     Can be used in conjuction with printingRef and/or handlePrint to
     print a receipt if it does not exist in its entirety on a page. */
  showOnPrintOnly?: boolean;
  /* Hides the built in 'Print My Receipt' button when true. */
  hidePrintButton?: boolean;
  /* Used to alter formatting if viewed from admin console instead of portal */
  adminConsoleView?: boolean;
}

export const PublicVaccineReceipt = (props: PublicVaccineReceiptProps) => {
  const [patientRecords, setPatientRecords] = React.useState<any>();
  const [loading, setLoading] = React.useState(false);
  const client = FhirUtils.useAxiosClient();
  const defaultRef = React.useRef();
  const ref = props.printingRef || defaultRef;
  const env = useEnvInfo();
  const logoUrl = props.logoUrl || env.receiptLogoUrl || env.logoUrl;
  const {showOnPrintOnly, hidePrintButton, adminConsoleView} = props;
  const {pathname} = useLocation();

  /* Assign print-handling function. Default must always exist due to React hook behaviour */
  const defaultHandlePrint = useReactToPrint({
    content: () => ref.current,
  });
  const handlePrint = props.handlePrint || defaultHandlePrint;

  React.useEffect(() => {
    if (props.patients) {
      /* Set the patients to the specified patients */
      setPatientRecords(props.patients);
    } else if (props.patientId) {
      /* Set the patients to a retrieved patient */
      retrievePatientReceiptData();
    }
  }, [props.patients, props.patientId]);

  /* Retrieves patient receipt information from the server */
  const retrievePatientReceiptData = async () => {
    setLoading(true);
    const params = Object.assign(
      {},
      pathname?.includes('patientlookup') ? {from: 'patientlookup'} : null
    );
    const result = await client.get(`/patient/${props.patientId}/receipt`, {params});
    setLoading(false);
    if (result.status === 200) {
      setPatientRecords([result.data]);
    }
  };

  /* Return a loading spinner when appropriate */
  if (loading && !showOnPrintOnly)
    return (
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: 300}}>
        <Spin size="large" />
      </div>
    );

  /* Display an error when not loading and missing patient records */
  if (!patientRecords)
    return (
      <span className={showOnPrintOnly ? 'show-on-print' : ''}>
        <Alert showIcon message="Error loading patient receipt." />
      </span>
    );

  /* Receipt header containing logos */
  const logobar = (
    <Row>
      {/* Left-hand side client logo */}
      <Col style={{display: 'flex'}} className={styles.centerJustifyMobile}>
        {logoUrl && (
          <Image src={logoUrl} alt="Receipt Logo" style={{maxWidth: 200, marginBottom: '30px'}} />
        )}
      </Col>
      {/* Right-hand side 'Powered by CANImmunize' */}
      <Col
        style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}
        className={styles.centerJustifyMobile}
      >
        <a href={`https://www.canimmunize.ca/en/home`} target="_blank">
          <img
            src={`https://cdn.canimmunize.ca/powered-by-canimmunize-horizontal-en@2x.png`}
            style={{width: 200, paddingTop: 20}}
          />
        </a>
      </Col>
    </Row>
  );

  /* Receipt title/content before patient records */
  const description = (
    <>
      <ReceiptTitle />
      <ReceiptInstructions />
    </>
  );

  return (
    <div ref={ref} style={{padding: '1.5cm'}} className={showOnPrintOnly ? 'show-on-print' : ''}>
      <COVIDPortalConfigProvider keepTheme>
        {logobar}
        {description}
        <DoseList {...{patientRecords, handlePrint, hidePrintButton, adminConsoleView}} />
      </COVIDPortalConfigProvider>
    </div>
  );
};
