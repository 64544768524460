import axios from 'axios';
import {Formik} from 'formik';
import {Ids, Str} from '@canimmunize/tools';
import {Typography, Select, Spin, message} from 'antd';
import React, {useEffect, useState} from 'react';
import {Input, SubmitButton, Form} from 'formik-antd';

import {BookingPage, BookingPageCategory} from '../../../models/booking-pages';
import {FhirUtils} from '../../../services/fhir';
import {useUrlData} from '../../scheduler/util';

export interface IBookingPageSelectOption extends BookingPage {
  value: string;
  label: string;
}

interface IBookingPageSelectorProps {
  value: string;
  onSelect: (bookingPage) => void;
  excludeCategories?: Array<BookingPageCategory>;
  setLoading?: (loading: boolean) => void;
  includeFormId?: boolean;
}

export const BookingPageSelector = ({
  onSelect,
  value,
  setLoading,
  excludeCategories,
  includeFormId
}: IBookingPageSelectorProps) => {
  const client = FhirUtils.useAxiosClient();
  const [bookingPages, setBookingPages] = useState<IBookingPageSelectOption[]>([]);

  useEffect(() => {
    fetchBookingPages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchDefaultBookingPage = () => {
    return client.get('/public/config').then(async (res) => res.data.defaultBookingPageId);
  };
  const fetchBookingPages = (name?: string) => {
    let params = {
      name,
      _filterField: 'status',
      _filterValues: ['active', 'protected'],
      includeGlobalBookingPages: true,
      includeRescheduleDefaultBookingPage: true,
      includeFormId,
    };

    if (setLoading) setLoading(true);
    return client.get(`/booking-page`, {params}).then(async (res) => {
      if (res.status !== 200) return message.error('An error has occurred');

      const defaultBookingPage = await fetchDefaultBookingPage();
      const bookingPages = res.data.entry
        .map((user) => user.resource)
        .filter((bp) => !excludeCategories?.includes(bp.category));
      const globalRescheduleDefault = bookingPages?.find((bp) => bp.globalRescheduleDefault);
      setBookingPages(
        bookingPages.map((bp) => ({
          ...bp,
          label: bp.name,
          value: bp.id,
        }))
      );
      if (setLoading) setLoading(false);
      if (globalRescheduleDefault) {
        onSelect(globalRescheduleDefault);
      } else if (bookingPages.find((bp) => bp.id === value)) {
        onSelect(bookingPages.find((bp) => bp.id === value));
      } else if (bookingPages.length) {
        onSelect(defaultBookingPage || bookingPages[0]);
      } else {
        onSelect({id: '', label: 'No Booking Pages Available'});
      }
    });
  };

  const onChange = (value) => {
    const bookingPage = bookingPages.find((bp) => bp.id === value);
    onSelect(bookingPage);
  };

  return (
    <Select
      size="middle"
      showSearch
      optionFilterProp="label"
      value={value}
      onChange={onChange}
      options={bookingPages}
      style={{width: 350}}
    />
  );
};

export const LoadingSpinner = () => {
  return (
    <div style={{textAlign: 'center', padding: 15}}>
      <Spin />
      <div style={{fontSize: 16, color: 'grey', paddingTop: 5}}>Loading...</div>
    </div>
  );
};

export const SchedulePasswordForm = (props) => {
  const {syncUrl} = useUrlData();
  const {bookingPageId, setPasswordVerified} = props;
  const [error, setError] = React.useState<string>('');

  const submit = async (values) => {
    await axios
      .post(syncUrl + `/public/booking-page/${bookingPageId}/verify`, {password: values.password})
      .then(() => {
        values.password = '';
        setPasswordVerified(true);
      })
      .catch((err) => {
        values.password = '';
        if (err.response?.status == 400) setError('Invalid Password');
        else setError(err.message);
      });
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Formik initialValues={{password: ''}} onSubmit={submit}>
        {() => (
          <Form style={{display: 'flex', flexDirection: 'column', width: '35%'}}>
            <div style={{marginBottom: 10}}>
              <Form.Item name="password" style={{marginBottom: 5}}>
                <Input.Password name="password" placeholder="Password" />
              </Form.Item>
              <Typography.Text type="danger">{error}</Typography.Text>
            </div>
            <SubmitButton>{Str(Ids.begin)}</SubmitButton>
          </Form>
        )}
      </Formik>
    </div>
  );
};
