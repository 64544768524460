import {createModel} from './model-helpers/create-model';
import {store} from '../store';

export interface ILocation {
  id: string;
  name: string;
}

const LocationModel = createModel('locations', '/location');

export default {
  ...LocationModel,
};
