import {FormikContextType, useFormikContext} from 'formik';
import React from 'react';
import {useEffect} from 'react';

export const YukonPortalPage = (props) => {
  const {pageNumber, preTitle, title, body, footer, backPage} = props;
  const formikContext: FormikContextType<any> = useFormikContext();

  useEffect(() => {
    if (backPage) {
      window.history.replaceState(null, '', window.location.pathname);
      window.addEventListener('popstate', onBackButtonEvent);

      return () => {
        window.removeEventListener('popstate', onBackButtonEvent);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onBackButtonEvent = (e) => {
    if (formikContext) {
      e.preventDefault();
      formikContext.setFieldValue('mode', backPage);
    }
  };

  return (
    <>
      <div style={{marginBottom: 20}}>
        <b>{pageNumber}</b>
      </div>
      {preTitle && <div>{preTitle}</div>}
      <h2 style={{marginTop: '0.5em', marginBottom: '0.5em'}}> {title}</h2>
      {body}
      {footer && (
        <div
          style={{
            marginTop: 40,
            marginBottom: 15,
            paddingTop: 40,
            borderTop: '1px solid #ccc',
            display: 'flex',
            flexWrap: 'wrap',
          }}
        >
          {footer}
        </div>
      )}
    </>
  );
};
