import {Ids} from '@canimmunize/tools';
import {Button, Steps, Typography} from 'antd';
import axios, {AxiosError} from 'axios';
import _ from 'lodash';
import React from 'react';
import {Form} from 'react-formio';
import {useDispatch} from 'react-redux';
import {ThunkDispatch} from '../../../models';
import {getOne} from '../../../models/form';
import {FhirUtils} from '../../../services/fhir';
import {useStr} from '../../../services/str';
import {useUrlData} from '../util';
import {MultiplePatientWrapper} from './patient-form-wrapper';

export const PatientForms = (props) => {
  const {appointment, onFormCompleted, onAppointmentCancellation} = props;
  const {config, lang, syncUrl} = useUrlData();
  const [patientIndex, setPatientIndex] = React.useState(0);
  const [formIndex, setFormIndex] = React.useState(0);
  const [submitting, setSubmitting] = React.useState(false);
  const [error, setError] = React.useState<Error>();
  const [requiredForms, setRequiredForms] = React.useState<any[]>([]);
  const client = FhirUtils.useAxiosClient();
  const thunkDispatch = useDispatch<ThunkDispatch>();
  const Str = useStr();

  const form = React.useRef<any>(null);

  const currentPatient = appointment.patients[patientIndex];

  const requiredFormIds = filterRequiredFormTypesForConfig(currentPatient.requiredFormTypes, config)
    .map((formKey) => {
      return appointment[formKey + 'Id'];
    })
    .filter((f) => !!f);

  const scrollTop = () => {
    if (!props.wrapperRef) return window.scrollTo(0, 0);
    else return props.wrapperRef.current.scrollIntoView();
  };

  React.useEffect(() => {
    const getForms = async () => {
      const forms = (
        await Promise.all(
          requiredFormIds.map(async (formId) => {
            return await thunkDispatch(getOne(client, formId));
          })
        )
      ).filter((f) => !!f);

      setRequiredForms(forms);
    };

    getForms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const next = () => {
    if (formIndex === requiredForms.length - 1) {
      if (patientIndex === appointment.patients.length - 1) {
        onFormCompleted();
      } else {
        setPatientIndex(patientIndex + 1);
        scrollTop();
      }
      setFormIndex(0);
    } else {
      setFormIndex(formIndex + 1);
      scrollTop();
    }
  };

  const submit = async (submission) => {
    const form = requiredForms[formIndex];

    const formSubmissions = [
      {
        patientId: currentPatient.id,
        formId: form.id,
        bookingPageId: config.id,
        fields: submission.data,
      },
    ];

    setSubmitting(true);

    await axios
      .post(
        syncUrl + '/public/form-submission',
        {submissions: formSubmissions, appointmentId: appointment.id, preferredLanguage: lang},
        {
          withCredentials: false,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        }
      )
      .then((res) => {
        // Clear any previous errors
        setError(undefined);

        if (res.data.appointmentCanceled) {
          onAppointmentCancellation(res.data);
        } else next();
      })
      .catch((err: AxiosError) => {
        console.log(err);
        const error = err.response?.data?.error;
        setError(error || err);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const submission = {
    data: {
      patientIndex,
      patientId: currentPatient.id,
      formIndex,
    },
  };

  const currentForm = requiredForms[formIndex];
  return (
    <>
      <MultiplePatientWrapper patients={appointment.patients} page={patientIndex}>
        {requiredForms.length > 1 ? (
          <div style={{padding: 20}}>
            {/* One step for each form for the current patient */}
            <Steps current={formIndex}>
              {requiredForms.map((f) => (
                <Steps.Step title={f.displayName[lang]} />
              ))}
            </Steps>
          </div>
        ) : null}

        <h3 style={{marginTop: 15}}>{currentForm?.displayName[lang]}</h3>

        {/* TODO: Find a way that I don't have to have 3 of these. There's a bug where the options don't update if I map through these */}
        {formIndex === 0 && currentForm ? (
          <Formio
            formRef={form}
            form={currentForm.form}
            onSubmit={submit}
            onError={(errors) => {
              if (errors.length) {
                scrollTop();
              }
            }}
            options={{...currentForm.formOptions, language: lang}}
            submission={submission}
          />
        ) : null}

        {formIndex === 1 && currentForm ? (
          <Formio
            formRef={form}
            form={currentForm.form}
            onSubmit={submit}
            onError={(errors) => {
              if (errors.length) {
                scrollTop();
              }
            }}
            options={{...currentForm.formOptions, language: lang}}
            submission={submission}
          />
        ) : null}

        {formIndex === 2 && currentForm ? (
          <Formio
            formRef={form}
            form={currentForm.form}
            onSubmit={submit}
            onError={(errors) => {
              if (errors.length) {
                scrollTop();
              }
            }}
            options={{...currentForm.formOptions, language: lang}}
            submission={submission}
          />
        ) : null}

        {/* Next Button */}
        <div style={{display: 'flex', alignItems: 'center'}}>
          <div style={{flexGrow: 1, textAlign: 'right'}}>
            <Typography.Text type="danger">{error?.message}</Typography.Text>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              paddingLeft: 20,
              paddingTop: 20,
              paddingBottom: 20,
            }}
          >
            <Button
              size="large"
              disabled={!currentForm}
              loading={submitting}
              type="primary"
              onClick={() => {
                form?.current.formio.submit();
              }}
            >
              {Str(Ids.next)}
            </Button>
          </div>
        </div>
      </MultiplePatientWrapper>
      {/* <RButton
        style={{backgroundColor: theme.blue, borderRadius: 10}}
        size="lg"
        onClick={() => {
          form?.current.formio.submit();
        }}
      >
        <div style={{color: 'white'}}>Next</div>
      </RButton> */}
    </>
  );
};

const Formio = React.memo(
  (props: any) => {
    const {formRef} = props;
    return (
      <div>
        <Form {...props} ref={formRef} />
      </div>
    );
  },
  (a, b) => {
    const equal = _.isEqual(a.submission.data, b.submission.data);
    console.log('Submission.data equal: ', equal);
    return equal;
  }
);

export const filterRequiredFormTypesForConfig = (types, config, forceOverride?) => {
  // Filter out the consent form if the booking page has the override feature enabled
  // This means that the booking page is intended for a call centre agent
  return types.filter(
    (t) =>
      !((config.clientRegistryOverrideEnabled === 'true' || forceOverride) && t === 'consentForm')
  );
};
