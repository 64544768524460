"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_VisionPrescription_LensSpecification = exports.VisionPrescription_LensSpecificationEyeKind = void 0;
var t = __importStar(require("io-ts"));
var RTTI_Extension_1 = require("./RTTI_Extension");
var RTTI_CodeableConcept_1 = require("./RTTI_CodeableConcept");
var RTTI_Element_1 = require("./RTTI_Element");
var RTTI_decimal_1 = require("../Scalar/RTTI_decimal");
var RTTI_integer_1 = require("../Scalar/RTTI_integer");
var RTTI_VisionPrescription_Prism_1 = require("./RTTI_VisionPrescription_Prism");
var RTTI_Quantity_1 = require("./RTTI_Quantity");
var RTTI_Annotation_1 = require("./RTTI_Annotation");
var VisionPrescription_LensSpecificationEyeKind;
(function (VisionPrescription_LensSpecificationEyeKind) {
    VisionPrescription_LensSpecificationEyeKind["_right"] = "right";
    VisionPrescription_LensSpecificationEyeKind["_left"] = "left";
})(VisionPrescription_LensSpecificationEyeKind = exports.VisionPrescription_LensSpecificationEyeKind || (exports.VisionPrescription_LensSpecificationEyeKind = {}));
var EnumType_1 = require("../../EnumType");
exports.RTTI_VisionPrescription_LensSpecification = t.recursion('IVisionPrescription_LensSpecification', function () {
    return t.intersection([
        t.type({
            product: RTTI_CodeableConcept_1.RTTI_CodeableConcept
        }),
        t.partial({
            id: t.string,
            extension: t.array(RTTI_Extension_1.RTTI_Extension),
            modifierExtension: t.array(RTTI_Extension_1.RTTI_Extension),
            eye: EnumType_1.createEnumType(VisionPrescription_LensSpecificationEyeKind, 'VisionPrescription_LensSpecificationEyeKind'),
            _eye: RTTI_Element_1.RTTI_Element,
            sphere: RTTI_decimal_1.RTTI_decimal,
            _sphere: RTTI_Element_1.RTTI_Element,
            cylinder: RTTI_decimal_1.RTTI_decimal,
            _cylinder: RTTI_Element_1.RTTI_Element,
            axis: RTTI_integer_1.RTTI_integer,
            _axis: RTTI_Element_1.RTTI_Element,
            prism: t.array(RTTI_VisionPrescription_Prism_1.RTTI_VisionPrescription_Prism),
            add: RTTI_decimal_1.RTTI_decimal,
            _add: RTTI_Element_1.RTTI_Element,
            power: RTTI_decimal_1.RTTI_decimal,
            _power: RTTI_Element_1.RTTI_Element,
            backCurve: RTTI_decimal_1.RTTI_decimal,
            _backCurve: RTTI_Element_1.RTTI_Element,
            diameter: RTTI_decimal_1.RTTI_decimal,
            _diameter: RTTI_Element_1.RTTI_Element,
            duration: RTTI_Quantity_1.RTTI_Quantity,
            color: t.string,
            _color: RTTI_Element_1.RTTI_Element,
            brand: t.string,
            _brand: RTTI_Element_1.RTTI_Element,
            note: t.array(RTTI_Annotation_1.RTTI_Annotation)
        })
    ]);
});
