"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_Patient = exports.PatientGenderKind = void 0;
var t = __importStar(require("io-ts"));
var RTTI_id_1 = require("../Scalar/RTTI_id");
var RTTI_Meta_1 = require("./RTTI_Meta");
var RTTI_uri_1 = require("../Scalar/RTTI_uri");
var RTTI_Element_1 = require("./RTTI_Element");
var RTTI_code_1 = require("../Scalar/RTTI_code");
var RTTI_Narrative_1 = require("./RTTI_Narrative");
var RTTI_ResourceList_1 = require("../Union/RTTI_ResourceList");
var RTTI_Extension_1 = require("./RTTI_Extension");
var RTTI_Identifier_1 = require("./RTTI_Identifier");
var RTTI_HumanName_1 = require("./RTTI_HumanName");
var RTTI_ContactPoint_1 = require("./RTTI_ContactPoint");
var RTTI_date_1 = require("../Scalar/RTTI_date");
var RTTI_Address_1 = require("./RTTI_Address");
var RTTI_CodeableConcept_1 = require("./RTTI_CodeableConcept");
var RTTI_Attachment_1 = require("./RTTI_Attachment");
var RTTI_Patient_Contact_1 = require("./RTTI_Patient_Contact");
var RTTI_Patient_Communication_1 = require("./RTTI_Patient_Communication");
var RTTI_Reference_1 = require("./RTTI_Reference");
var RTTI_Patient_Link_1 = require("./RTTI_Patient_Link");
var PatientGenderKind;
(function (PatientGenderKind) {
    PatientGenderKind["_male"] = "male";
    PatientGenderKind["_female"] = "female";
    PatientGenderKind["_other"] = "other";
    PatientGenderKind["_unknown"] = "unknown";
})(PatientGenderKind = exports.PatientGenderKind || (exports.PatientGenderKind = {}));
var EnumType_1 = require("../../EnumType");
exports.RTTI_Patient = t.recursion('IPatient', function () {
    return t.intersection([
        t.type({
            resourceType: t.literal('Patient')
        }),
        t.partial({
            id: RTTI_id_1.RTTI_id,
            meta: RTTI_Meta_1.RTTI_Meta,
            implicitRules: RTTI_uri_1.RTTI_uri,
            _implicitRules: RTTI_Element_1.RTTI_Element,
            language: RTTI_code_1.RTTI_code,
            _language: RTTI_Element_1.RTTI_Element,
            text: RTTI_Narrative_1.RTTI_Narrative,
            contained: t.array(RTTI_ResourceList_1.RTTI_ResourceList),
            extension: t.array(RTTI_Extension_1.RTTI_Extension),
            modifierExtension: t.array(RTTI_Extension_1.RTTI_Extension),
            identifier: t.array(RTTI_Identifier_1.RTTI_Identifier),
            active: t.boolean,
            _active: RTTI_Element_1.RTTI_Element,
            name: t.array(RTTI_HumanName_1.RTTI_HumanName),
            telecom: t.array(RTTI_ContactPoint_1.RTTI_ContactPoint),
            gender: EnumType_1.createEnumType(PatientGenderKind, 'PatientGenderKind'),
            _gender: RTTI_Element_1.RTTI_Element,
            birthDate: RTTI_date_1.RTTI_date,
            _birthDate: RTTI_Element_1.RTTI_Element,
            deceasedBoolean: t.boolean,
            _deceasedBoolean: RTTI_Element_1.RTTI_Element,
            deceasedDateTime: t.string,
            _deceasedDateTime: RTTI_Element_1.RTTI_Element,
            address: t.array(RTTI_Address_1.RTTI_Address),
            maritalStatus: RTTI_CodeableConcept_1.RTTI_CodeableConcept,
            multipleBirthBoolean: t.boolean,
            _multipleBirthBoolean: RTTI_Element_1.RTTI_Element,
            multipleBirthInteger: t.number,
            _multipleBirthInteger: RTTI_Element_1.RTTI_Element,
            photo: t.array(RTTI_Attachment_1.RTTI_Attachment),
            contact: t.array(RTTI_Patient_Contact_1.RTTI_Patient_Contact),
            communication: t.array(RTTI_Patient_Communication_1.RTTI_Patient_Communication),
            generalPractitioner: t.array(RTTI_Reference_1.RTTI_Reference),
            managingOrganization: RTTI_Reference_1.RTTI_Reference,
            link: t.array(RTTI_Patient_Link_1.RTTI_Patient_Link)
        })
    ]);
});
