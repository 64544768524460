"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_Specimen_Collection = void 0;
var t = __importStar(require("io-ts"));
var RTTI_Extension_1 = require("./RTTI_Extension");
var RTTI_Reference_1 = require("./RTTI_Reference");
var RTTI_Element_1 = require("./RTTI_Element");
var RTTI_Period_1 = require("./RTTI_Period");
var RTTI_Duration_1 = require("./RTTI_Duration");
var RTTI_Quantity_1 = require("./RTTI_Quantity");
var RTTI_CodeableConcept_1 = require("./RTTI_CodeableConcept");
exports.RTTI_Specimen_Collection = t.recursion('ISpecimen_Collection', function () {
    return t.partial({
        id: t.string,
        extension: t.array(RTTI_Extension_1.RTTI_Extension),
        modifierExtension: t.array(RTTI_Extension_1.RTTI_Extension),
        collector: RTTI_Reference_1.RTTI_Reference,
        collectedDateTime: t.string,
        _collectedDateTime: RTTI_Element_1.RTTI_Element,
        collectedPeriod: RTTI_Period_1.RTTI_Period,
        duration: RTTI_Duration_1.RTTI_Duration,
        quantity: RTTI_Quantity_1.RTTI_Quantity,
        method: RTTI_CodeableConcept_1.RTTI_CodeableConcept,
        bodySite: RTTI_CodeableConcept_1.RTTI_CodeableConcept,
        fastingStatusCodeableConcept: RTTI_CodeableConcept_1.RTTI_CodeableConcept,
        fastingStatusDuration: RTTI_Duration_1.RTTI_Duration
    });
});
