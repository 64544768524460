"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_Library = exports.LibraryStatusKind = void 0;
var t = __importStar(require("io-ts"));
var RTTI_id_1 = require("../Scalar/RTTI_id");
var RTTI_Meta_1 = require("./RTTI_Meta");
var RTTI_uri_1 = require("../Scalar/RTTI_uri");
var RTTI_Element_1 = require("./RTTI_Element");
var RTTI_code_1 = require("../Scalar/RTTI_code");
var RTTI_Narrative_1 = require("./RTTI_Narrative");
var RTTI_ResourceList_1 = require("../Union/RTTI_ResourceList");
var RTTI_Extension_1 = require("./RTTI_Extension");
var RTTI_Identifier_1 = require("./RTTI_Identifier");
var RTTI_CodeableConcept_1 = require("./RTTI_CodeableConcept");
var RTTI_Reference_1 = require("./RTTI_Reference");
var RTTI_dateTime_1 = require("../Scalar/RTTI_dateTime");
var RTTI_ContactDetail_1 = require("./RTTI_ContactDetail");
var RTTI_markdown_1 = require("../Scalar/RTTI_markdown");
var RTTI_UsageContext_1 = require("./RTTI_UsageContext");
var RTTI_date_1 = require("../Scalar/RTTI_date");
var RTTI_Period_1 = require("./RTTI_Period");
var RTTI_RelatedArtifact_1 = require("./RTTI_RelatedArtifact");
var RTTI_ParameterDefinition_1 = require("./RTTI_ParameterDefinition");
var RTTI_DataRequirement_1 = require("./RTTI_DataRequirement");
var RTTI_Attachment_1 = require("./RTTI_Attachment");
var LibraryStatusKind;
(function (LibraryStatusKind) {
    LibraryStatusKind["_draft"] = "draft";
    LibraryStatusKind["_active"] = "active";
    LibraryStatusKind["_retired"] = "retired";
    LibraryStatusKind["_unknown"] = "unknown";
})(LibraryStatusKind = exports.LibraryStatusKind || (exports.LibraryStatusKind = {}));
var EnumType_1 = require("../../EnumType");
exports.RTTI_Library = t.recursion('ILibrary', function () {
    return t.intersection([
        t.type({
            resourceType: t.literal('Library'),
            type: RTTI_CodeableConcept_1.RTTI_CodeableConcept
        }),
        t.partial({
            id: RTTI_id_1.RTTI_id,
            meta: RTTI_Meta_1.RTTI_Meta,
            implicitRules: RTTI_uri_1.RTTI_uri,
            _implicitRules: RTTI_Element_1.RTTI_Element,
            language: RTTI_code_1.RTTI_code,
            _language: RTTI_Element_1.RTTI_Element,
            text: RTTI_Narrative_1.RTTI_Narrative,
            contained: t.array(RTTI_ResourceList_1.RTTI_ResourceList),
            extension: t.array(RTTI_Extension_1.RTTI_Extension),
            modifierExtension: t.array(RTTI_Extension_1.RTTI_Extension),
            url: RTTI_uri_1.RTTI_uri,
            _url: RTTI_Element_1.RTTI_Element,
            identifier: t.array(RTTI_Identifier_1.RTTI_Identifier),
            version: t.string,
            _version: RTTI_Element_1.RTTI_Element,
            name: t.string,
            _name: RTTI_Element_1.RTTI_Element,
            title: t.string,
            _title: RTTI_Element_1.RTTI_Element,
            subtitle: t.string,
            _subtitle: RTTI_Element_1.RTTI_Element,
            status: EnumType_1.createEnumType(LibraryStatusKind, 'LibraryStatusKind'),
            _status: RTTI_Element_1.RTTI_Element,
            experimental: t.boolean,
            _experimental: RTTI_Element_1.RTTI_Element,
            subjectCodeableConcept: RTTI_CodeableConcept_1.RTTI_CodeableConcept,
            subjectReference: RTTI_Reference_1.RTTI_Reference,
            date: RTTI_dateTime_1.RTTI_dateTime,
            _date: RTTI_Element_1.RTTI_Element,
            publisher: t.string,
            _publisher: RTTI_Element_1.RTTI_Element,
            contact: t.array(RTTI_ContactDetail_1.RTTI_ContactDetail),
            description: RTTI_markdown_1.RTTI_markdown,
            _description: RTTI_Element_1.RTTI_Element,
            useContext: t.array(RTTI_UsageContext_1.RTTI_UsageContext),
            jurisdiction: t.array(RTTI_CodeableConcept_1.RTTI_CodeableConcept),
            purpose: RTTI_markdown_1.RTTI_markdown,
            _purpose: RTTI_Element_1.RTTI_Element,
            usage: t.string,
            _usage: RTTI_Element_1.RTTI_Element,
            copyright: RTTI_markdown_1.RTTI_markdown,
            _copyright: RTTI_Element_1.RTTI_Element,
            approvalDate: RTTI_date_1.RTTI_date,
            _approvalDate: RTTI_Element_1.RTTI_Element,
            lastReviewDate: RTTI_date_1.RTTI_date,
            _lastReviewDate: RTTI_Element_1.RTTI_Element,
            effectivePeriod: RTTI_Period_1.RTTI_Period,
            topic: t.array(RTTI_CodeableConcept_1.RTTI_CodeableConcept),
            author: t.array(RTTI_ContactDetail_1.RTTI_ContactDetail),
            editor: t.array(RTTI_ContactDetail_1.RTTI_ContactDetail),
            reviewer: t.array(RTTI_ContactDetail_1.RTTI_ContactDetail),
            endorser: t.array(RTTI_ContactDetail_1.RTTI_ContactDetail),
            relatedArtifact: t.array(RTTI_RelatedArtifact_1.RTTI_RelatedArtifact),
            parameter: t.array(RTTI_ParameterDefinition_1.RTTI_ParameterDefinition),
            dataRequirement: t.array(RTTI_DataRequirement_1.RTTI_DataRequirement),
            content: t.array(RTTI_Attachment_1.RTTI_Attachment)
        })
    ]);
});
