import React from 'react';
import {Col, Row} from 'reactstrap';
import {usePageConfig, useUrlData} from './util';
import styles from './styles.module.css';

export const LogoBar = (props) => {
  const {lang, bookingPageSlug} = useUrlData();
  // Gets either the portal config or a booking page config object
  const config = usePageConfig();
  if (config.loading) return null;

  return (
    <Row style={{marginBottom: 20}}>
      <Col style={{display: 'flex'}} className={styles.centerJustifyMobile}>
        <a
          href={`/${lang}/${
            bookingPageSlug ?? (props.isYukonCallCentre ? 'ykcallcentre' : 'portal')
          }`}
        >
          <img
            src={config.logo[lang]}
            style={{height: 75}}
            alt={(config.logoAlt && config.logoAlt[lang]) ?? 'portal logo'}
            title={(config.logoAlt && config.logoAlt[lang]) ?? 'portal logo'}
          />
        </a>
      </Col>
      {(bookingPageSlug || config['showCANILogoAtPortalHeader'] === 'true') && (
        <Col
          style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}
          className={styles.centerJustifyMobile}
        >
          <a href={`https://www.canimmunize.ca/${lang}/home`} target="_blank">
            <img
              src={`https://cdn.canimmunize.ca/powered-by-canimmunize-horizontal-${lang}@2x.png`}
              style={{width: 200, paddingTop: 20}}
            />
          </a>
        </Col>
      )}
    </Row>
  );
};
