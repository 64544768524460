import * as Icons from '@ant-design/icons';
import {MailOutlined} from '@ant-design/icons';
import {Button, Empty, Space} from 'antd';
import * as _ from 'lodash';
import React from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {RootState} from '../../models';
import {CohortListPatientActions} from '../cohorts/cohort-list-patient-actions';
import {FHIRTable} from '../fhir-table';
import {PatientUploadModal} from '../imports/upload-modals/patient-upload-modal';
import {EmailModal} from './email-modal';

export const ListItemPage = ({itemId}) => {
  const navigate = useNavigate();
  const list = useSelector((state: RootState) => state.lists.byId[itemId]);
  const [listLength, setListLength] = React.useState<Number>(0);
  const [uploadModalVisible, setUploadModalVisible] = React.useState(false);
  const [isTableEmpty, setIsTableEmpty] = React.useState(false);
  const [refresh, setRefresh] = React.useState(1);
  const [emailModalVisible, setEmailModalVisible] = React.useState(false);
  const [duplicateWarning, setDuplicateWarning] = React.useState(false);

  const patientTableColumns = [
    {
      title: 'Name',
      dataIndex: 'fullName',
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'HCN',
      dataIndex: 'hcn',
    },
    {
      title: 'Actions',
      render: (_, patient) => (
        <CohortListPatientActions
          patient={patient}
          cohort={list}
          triggerRefresh={() => setRefresh(Math.random())}
          itemType="item"
          type="list"
        />
      ),
    },
  ];

  //Checks if data in table is empty and sets isTableEmpty
  const onDataLoad = (data) => {
    if (data.total === 0) setIsTableEmpty(true);
    else setIsTableEmpty(false);

    setListLength(data ? data.total : 0);

    if (data.entry && data.entry.length !== _.uniqBy(data.entry, 'resource.email').length) {
      setDuplicateWarning(true);
    } else {
      setDuplicateWarning(false);
    }
  };

  //Callback function on Modal Close
  const onClose = () => {
    setUploadModalVisible(false);
    setRefresh(Math.random());
  };

  //Custom Create Button to display
  const CreateButton = () => {
    return (
      <Button key="fhir" type="primary" size="large" onClick={() => setUploadModalVisible(true)}>
        <Icons.UserAddOutlined /> Upload Additional Patients
      </Button>
    );
  };

  const tableEmptyComponent = () => {
    return (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        imageStyle={{
          height: 60,
        }}
        description={<span>No Data</span>}
      >
        <Button type="primary" size="large" onClick={() => setUploadModalVisible(true)}>
          <Icons.UserAddOutlined /> Upload Patients
        </Button>
      </Empty>
    );
  };

  return (
    <div style={{}}>
      <Space style={{paddingBottom: 15, display: 'flex', justifyContent: 'flex-start'}}>
        <Button onClick={() => setEmailModalVisible(true)}>
          Send Email <MailOutlined />
        </Button>
      </Space>
      <EmailModal
        visible={emailModalVisible}
        setVisible={setEmailModalVisible}
        duplicateWarning={duplicateWarning}
        listLength={listLength}
        listId={itemId}
      />
      <FHIRTable
        mode="table"
        fhirResource="list-item"
        defaultPrimarySearchParam="name"
        columns={patientTableColumns}
        fixedFilters={{listId: list?.id as string}}
        onDataLoad={onDataLoad}
        showCreateButton={!isTableEmpty}
        componentWhenEmpty={tableEmptyComponent}
        CreateButton={CreateButton}
        triggerRefresh={refresh}
      />
      <PatientUploadModal
        visible={uploadModalVisible}
        templateName={'list-template'}
        item={list}
        onClose={onClose}
        type="list"
      />
    </div>
  );
};
