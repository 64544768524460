import * as Icons from '@ant-design/icons';
import {ExclamationCircleOutlined} from '@ant-design/icons';
import {
  Alert,
  Button,
  Col,
  Modal,
  Progress,
  Row,
  Space,
  Spin,
  Statistic,
  Table,
  Tag,
  Tooltip,
} from 'antd';
import {ColumnsType} from 'antd/lib/table';
import axios from 'axios';
import React from 'react';
import {useDropzone} from 'react-dropzone';
import {jsonToCSV, readString} from 'react-papaparse';
import {useDispatch, useSelector} from 'react-redux';
import * as XLSX from 'xlsx';
import {importerConfigs} from '.';
import {RootState} from '../../../models';
import UI from '../../../models/ui';
import {FhirUtils} from '../../../services/fhir';
import {ValidationRules} from '../../../validation/validation-rules/validation-rules';
import {FHIRTable} from '../../fhir-table';
import {OptionalParamsType, validateCSVData} from '../../util/csv/csv-upload-validation';
import {downloadTemplate} from '../download-template';

export interface CSVParseError {
  code: string;
  message: string;
  row: number;
  type: string;
}

export interface ImporterModalProps {
  visible: boolean;
  onClose?: () => void;
  preProcessData?: (row: any) => any;
  title: string;
  getValidationRules?: (row) => {[property: string]: ValidationRules[]};
  validationOptions?: OptionalParamsType;
  getUniqueKey?: (row) => string;
  templateName?: string;
  howToUse?: string;
  uploadedColumns?: ColumnsType<any>;
  targetSheetName?: string;
  skipLines?: number;
}

export enum UploadState {
  AWAITING_FILE = 'AWAITING_FILE',
  REVIEWING_FILE = 'REVIEWING_FILE',
  INVALID_FILE = 'INVALID_FILE',
  UPLOADING_FILE = 'UPLOADING_FILE',
  IMPORT_IN_PROGRESS = 'IMPORT_IN_PROGRESS',
  IMPORT_COMPLETE = 'IMPORT_COMPLETE',
  UPLOAD_ERROR = 'UPLOAD_ERROR',
}

export const ImporterModal = (props: ImporterModalProps) => {
  const {visible, title, targetSheetName} = props;
  const [uploadingState, setUploadingState] = React.useState(UploadState.AWAITING_FILE);
  const [upload, setUpload] = React.useState<any>();
  const [uploadData, setUploadData] = React.useState<any>([]);
  const [uploadProgress, setUploadProgress] = React.useState(0);
  const [uploadResult, setUploadResult] =
    React.useState<{rows: any[]; status?: string} | undefined>();
  const [uploadError, setUploadError] = React.useState('');
  const [uploadErrors, setUploadErrors] = React.useState<CSVParseError[]>([]);
  const client = FhirUtils.useAxiosClient();
  const dispatch = useDispatch();
  const globalOrg = useSelector((state: RootState) => state.ui.localOrg);
  // Timer for polling for changes to the upload's state
  React.useEffect(() => {
    dispatch(UI.slice.actions.SET_IMPORT_LIST_REFRESH_TRIGGER());
    if (uploadingState === UploadState.UPLOADING_FILE) {
      pollForUploadStateChanges();
    }
  }, [uploadingState]);

  const pollForUploadStateChanges = async () => {
    // Request
    const result = await client.get(`/import/${upload.id}`);

    setUpload(result.data);

    setUploadingState(result.data.status);

    if (
      result.data.status === UploadState.AWAITING_FILE ||
      result.data.status === UploadState.IMPORT_IN_PROGRESS ||
      result.data.status === UploadState.UPLOADING_FILE
    ) {
      setTimeout(async () => {
        await pollForUploadStateChanges();
      }, 1000);
    }
  };

  const handleUpload = async (data) => {
    const preSignedResult = await client
      .post('/import', {
        fileName: 'upload-file',
        importType: 'citf-patient-import',
        contentType: 'text/csv',
        totalRows: data.length,
        organizationId: globalOrg?.id,
      })
      .catch((err) => err);

    if (preSignedResult instanceof Error) {
      return;
    }

    setUpload(preSignedResult.data);

    setUploadingState(UploadState.UPLOADING_FILE);

    const csvString = jsonToCSV(data);

    const blob = new Blob([csvString], {type: 'text/csv'});

    const formData = new FormData();
    const {url, fields} = preSignedResult.data.uploadUrl;

    Object.keys(fields).forEach((key) => formData.append(key, fields[key]));
    formData.append('file', blob);

    await axios.post(url, formData, {
      headers: {'Content-Type': 'multipart/form-data', 'Allow-Origin': '*'},
      onUploadProgress: (progressEvent) => {
        let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
        setUploadProgress(percentCompleted);
      },
    });
  };

  const onRetry = () => {
    setUploadData([]);
    setUploadErrors([]);
    setUploadError('');
  };

  const onCancel = () => {
    setUploadData([]);
    setUploadErrors([]);
    setUpload(undefined);
    setUploadResult(undefined);
    setUploadError('');
    setUploadingState(UploadState.AWAITING_FILE);
    if (props.onClose) props.onClose();
  };

  const onClose = () => {
    setUploadData([]);
    setUpload(undefined);
    setUploadErrors([]);
    setUploadResult(undefined);
    setUploadError('');
    setUploadingState(UploadState.AWAITING_FILE);
    if (props.onClose) props.onClose();
  };

  const onClickUpload = async () => {
    setUploadError('');
    try {
      await handleUpload(uploadData);
      // setUploadResult(result);
    } catch (error: any) {
      setUploadResult(error.response?.data);
      setUploadError(
        error.response?.data?.issue ? error.response?.data?.issue[0]?.diagnostics : error.message
      );
    } finally {
    }
  };

  //Helper to get the current 'state' of the upload modal
  const getUpdateState = () => {
    if (uploadError !== '') return 'uploadedWithOverallError';
    if (uploadData.length === 0) return 'base';
    if (uploadErrors.length) return 'validatedWithErrors';
    if (!uploadResult) return 'validatedWithoutErrors';
    if ((uploadResult && uploadResult.rows?.filter((row) => row.error).length) || uploadError)
      return 'uploadedWithErrors';
    else return 'uploadedWithoutErrors';
  };

  const opsBundle = {
    uploadData,
    setUploadData,
    uploadErrors,
    setUploadErrors,
    uploadError,
    setUploadError,
    uploadResult,
    setUploadResult,
    uploadingState,
    setUploadingState,
    onClose,
    onRetry,
    onCancel,
    onClickUpload,
    targetSheetName,
  };

  getUpdateState();

  return (
    <Modal
      visible={visible}
      width={'85%'}
      onCancel={onCancel}
      footer={<Footer {...props} {...opsBundle} />}
    >
      <h3>{title}</h3>

      {/* CSV Upload and Review */}
      {(uploadingState === UploadState.AWAITING_FILE ||
        uploadingState === UploadState.REVIEWING_FILE ||
        uploadingState === UploadState.INVALID_FILE ||
        uploadingState === UploadState.UPLOAD_ERROR) && <UploadCSVView {...props} {...opsBundle} />}

      {/* Upload progress bar */}
      {uploadingState === UploadState.UPLOADING_FILE && (
        <UploadProgressView progress={uploadProgress} />
      )}

      {/* Importer progress bar */}
      {uploadingState === UploadState.IMPORT_IN_PROGRESS && <ImportProgressView upload={upload} />}

      {uploadingState === UploadState.IMPORT_COMPLETE && (
        <>
          <div style={{marginBottom: 15}}>
            <Alert type="success" showIcon message="Import Complete!" />
          </div>
          <ImportResultView importId={upload.id} upload={upload} />
        </>
      )}
    </Modal>
  );
};

const UploadProgressView = ({progress}) => {
  return (
    <div>
      <b>Uploading...</b>
      <Progress percent={progress} status="active" />
    </div>
  );
};

const ImportProgressView = ({upload}) => {
  const importPercentage = Math.round((upload.rowsProcessed / upload.totalRows) * 100);
  return (
    <div>
      <b>Importing...</b>
      <Progress percent={importPercentage} status="active" />
    </div>
  );
};

const UploadCSVView = (props) => {
  const client = FhirUtils.useAxiosClient();

  const {
    uploadData,
    setUploadData,
    uploadErrors,
    setUploadErrors,
    uploadResult,
    setUploadingState,
    uploadError,
    setUploadError,
  } = props;

  const handleOnDrop = (file) => {
    const errors = [];

    // CSV Validation
    const validationErrors = validateCSVData(
      props.getValidationRules,
      file.data.map((data) => ({data})),
      props.validationOptions
    );

    const parsedData = file.data;
    const processedData = preProcessData(parsedData);
    const processingErrors = processedData
      .map((d, index) => {
        return {row: index, message: d.error, code: '', type: ''};
      })
      .filter((d) => !!d.message);

    setUploadErrors(validationErrors.concat(errors).concat(processingErrors));
    setUploadData(processedData);
    setUploadingState(UploadState.REVIEWING_FILE);
  };

  const handleOnError = (e) => {
    console.log(e);
  };

  const preProcessData = (data) => {
    //If no data is found
    if (data.length === 0) {
      setUploadError('No data found.');
    }

    try {
      const processedData = data.map(props.preProcessData);
      return processedData;
    } catch (error: any) {
      setUploadError(error.message);
    }
    return [];
  };

  const columns = [
    {title: 'Line', dataIndex: 'lineNumber', key: 'lineNumber', fixed: 'left'},
    uploadStatusColumn,
    ...(props.uploadedColumns ?? []),
  ];

  //Adds 'errors' field to tableData to display errors under 'Upload Result' column
  const appendUploadErrors = (uploadData, uploadError) => {
    uploadData.forEach((data, index) => {
      data.errors = uploadError.filter((obj) => obj.row === index);
    });
    return uploadData;
  };

  const tableData = uploadResult
    ? uploadData.map((uploadRow, i) => {
        const resultRow = uploadResult?.rows ? uploadResult.rows[i] : undefined;
        return {
          ...uploadRow,
          ...resultRow?.resource,
          action: resultRow?.action,
          error: resultRow?.error,
          errors: resultRow?.errors,
        };
      })
    : appendUploadErrors(uploadData, uploadErrors);

  return (
    <div style={{marginBottom: 15}}>
      {uploadData.length === 0 && (
        <>
          <p>{props.howToUse}</p>
          <Button
            key="fhir"
            style={{marginBottom: 15}}
            onClick={() => downloadTemplate(props.templateName, client)}
          >
            <Icons.TableOutlined /> Download Template
          </Button>

          {/* Show error message */}
          {uploadError !== '' && (
            <AlertComponent
              type="error"
              message={`${uploadError} Please try again.`}
            ></AlertComponent>
          )}

          {/* File Selector */}
          <Dropzone onDrop={handleOnDrop} onError={handleOnError} {...props} />
        </>
      )}

      {uploadData.length > 0 && (
        <>
          <AlertResponseGenerator {...props} />
          <Table
            columns={columns}
            scroll={{x: 'max-content'}}
            dataSource={tableData}
            pagination={{defaultPageSize: 20}}
            rowKey={(item) => item.name}
          />
        </>
      )}
    </div>
  );
};

const uploadStatusColumn = {
  title: 'Upload Status',
  fixed: 'left',
  render: (_, row: any) => {
    // If the row has a createdAt then it has already been uploaded and this is the import-log case
    if (!!row.createdAt) {
      if (row.error) {
        return <Tag color="red">{`Error: ${row.error}`}</Tag>;
      } else {
        return <Tag color="green">Success</Tag>;
      }
    }
    // If row.createdAt does not exist then this is the fresh upload case
    else {
      if (row.errors.length) {
        //If there is only one error
        if (row.errors.length === 1) {
          return <Tag color="red">{row.errors[0].message || row.errors[0]}</Tag>;
        } else {
          return (
            <div style={{maxWidth: 200}}>
              <Tag icon={<ExclamationCircleOutlined />} color="red">
                Multiple Errors
              </Tag>
              {row.errors.map((err) => (
                <p style={{marginTop: 15, marginBottom: 0}}>{err.message || err}</p>
              ))}
            </div>
          );
        }
      }

      return (
        <Tooltip title="Not yet uploaded">
          <Tag color="blue">Validated</Tag>
        </Tooltip>
      );
    }
  },
};

const Dropzone = (props) => {
  const [loadingFile, setLoadingFile] = React.useState(false);

  const onDrop = React.useCallback((acceptedFiles) => {
    setLoadingFile(true);
    if (acceptedFiles.length > 1 || acceptedFiles.length < 1) {
      props.onError('Invalid number of accepted files');
      return;
    }

    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = () => {
        // @ts-ignore
        var data = new Uint8Array(reader.result);
        var workbook = XLSX.read(data, {type: 'array', dateNF: 'YYYYY-MM-DD'});

        const sheetName =
          workbook.SheetNames.length > 1 ? props.targetSheetName : workbook.SheetNames[0];

        let csvString = XLSX.utils.sheet_to_csv(workbook.Sheets[sheetName]);
        const skipLines = props.skipLines || 0;
        // Skip lines implementation
        for (let i = 0; i < skipLines; i++) {
          csvString = csvString.slice(csvString.indexOf('\n') + 1);
        }

        const csvData = readString(csvString, {header: true, skipEmptyLines: 'greedy'} as any);

        props.onDrop(csvData);

        setLoadingFile(false);
      };
      reader.readAsArrayBuffer(file);
    });
  }, []);
  const {getRootProps, getInputProps} = useDropzone({onDrop});

  if (loadingFile) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Spin />
        <div>Validating file...</div>
      </div>
    );
  }

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <aside
        style={{
          textAlign: 'center',
          marginBottom: 10,
          border: '1px lightgrey dashed',
          padding: 20,
          borderRadius: 20,
        }}
      >
        <Button type="default" size="large">
          Click to Upload File
        </Button>

        <div style={{marginTop: 10}}>Or drag and drop a file here.</div>
      </aside>
    </div>
  );
};

const AlertComponent = (props) => {
  return <Alert type={props.type} showIcon message={props.message} style={{marginBottom: 15}} />;
};

const AlertResponseGenerator = ({
  uploadingState,
  uploadData,
  uploadResult,
  uploadErrors,
  uploadError,
}) => {
  switch (uploadingState) {
    case UploadState.UPLOAD_ERROR:
      return (
        <AlertComponent
          type="warning"
          message={`${uploadResult?.rows?.filter((row) => !row.error).length} out of ${
            uploadResult?.rows.length
          } rows succesfully uploaded. See errors below.`}
        />
      );
    case UploadState.IMPORT_IN_PROGRESS:
      return (
        <AlertComponent
          type="success"
          message={
            uploadResult?.status
              ? uploadResult.status
              : `${uploadResult?.rows?.length} rows succesfully uploaded.`
          }
        />
      );
    case UploadState.INVALID_FILE:
      return (
        <AlertComponent
          type="error"
          message={`Reupload CSV file after fixing the following ${uploadErrors.length} error(s) below.`}
        />
      );
    case UploadState.REVIEWING_FILE:
      return (
        <AlertComponent
          type="info"
          message={`${uploadData.length} rows validated and prepared for upload.`}
        />
      );
    default:
      return null;
  }
};

const UploadButton = ({
  uploadingState,
  onRetry,
  onCancel,
  onClose,
  onClickUpload,
  uploadErrors,
  uploadData,
}) => {
  if (uploadingState === 'validatedWithErrors') {
    return (
      <Button
        type="primary"
        shape="round"
        icon={<Icons.RedoOutlined />}
        onClick={onRetry}
        size={'large'}
      >
        Re-Upload
      </Button>
    );
  }

  if (uploadingState === UploadState.IMPORT_IN_PROGRESS) {
    return (
      <Space>
        <div>Pressing close will not terminate the import.</div>
        <Button type="primary" shape="round" size={'large'} onClick={onClose}>
          Close
        </Button>
      </Space>
    );
  }

  if (uploadingState === UploadState.IMPORT_COMPLETE) {
    return (
      <Space>
        <Button type="primary" shape="round" size={'large'} onClick={onClose}>
          Close
        </Button>
      </Space>
    );
  }

  if (uploadingState === 'uploadedWithoutErrors' || uploadingState === 'uploadedWithErrors')
    return (
      <Button type="primary" shape="round" size={'large'} onClick={onCancel}>
        Finish
      </Button>
    );

  return (
    <Button
      type="primary"
      shape="round"
      icon={<Icons.UploadOutlined />}
      size={'large'}
      loading={uploadingState === uploadingState.UPLOADING_FILE}
      onClick={onClickUpload}
      disabled={uploadData.length === 0 || uploadErrors.length > 0}
    >
      Upload
    </Button>
  );
};

const Footer = (props) => {
  const {uploadingState, onRetry, onCancel, onClose, onClickUpload} = props;

  return (
    <>
      {(uploadingState === UploadState.AWAITING_FILE ||
        uploadingState === UploadState.REVIEWING_FILE ||
        uploadingState === UploadState.INVALID_FILE) && (
        <Button shape="round" size={'large'} onClick={onCancel}>
          Cancel
        </Button>
      )}
      <UploadButton
        {...props}
        onCancel={onCancel}
        onClose={onClose}
        onRetry={onRetry}
        onClickUpload={onClickUpload}
      />
    </>
  );
};

export const ImportResultView = ({importId, upload}) => {
  const importColumns = importerConfigs[upload.importType].columns;

  const successPercentage = (upload.successRows / upload.totalRows) * 100;
  const progress = (upload.rowsProcessed / upload.totalRows) * 100;

  const columns = [
    {title: 'Line', dataIndex: 'lineNumber', key: 'lineNumber', fixed: 'left'},
    uploadStatusColumn,
    ...importColumns,
  ];

  return (
    <div>
      <div style={{marginBottom: 15}}>
        <Row gutter={40}>
          <Col>
            <Statistic title="Success" value={upload.successRows} />
          </Col>
          <Col>
            <Statistic title="Warnings" value={upload.warningRows} />
          </Col>
          <Col>
            <Statistic title="Errors" value={upload.errorRows} />
          </Col>
        </Row>
        <Tooltip
          title={`${upload.successRows} Success / ${upload.errorRows} Errors / ${upload.totalRows} Total`}
        >
          <Progress
            success={{percent: successPercentage}}
            percent={progress}
            strokeColor="#ff4d4f"
            showInfo={false}
          />
        </Tooltip>
      </div>
      <FHIRTable
        columns={columns}
        fhirResource="import-log"
        mode="table"
        fixedFilters={{importId}}
        scroll={{x: 'max-content'}}
        pagination={{defaultPageSize: 20}}
        onDataLoad={(data) => {
          // Spreads the input object onto the resource so that the same table column config can be used between this
          // table and the upload review table
          data.entry.map((e) => (e.resource = {...e.resource, ...e.resource.input}));
          return data;
        }}
      />
    </div>
  );
};
