import {R4} from '@ahryman40k/ts-fhir-types';

export function getIdentifierFromReferenceString(reference?: string) {
  return reference ? reference.split('/')[1] : '';
}

export function ensureArray(maybeAnArray) {
  return Array.isArray(maybeAnArray) ? maybeAnArray : [maybeAnArray];
}

export function mapBundleToResourceArray(bundle: R4.IBundle) {
  return (
    bundle.entry?.reduce(
      (resources, entry) => (entry.resource ? resources.concat([entry.resource]) : resources),
      [] as R4.IResource[]
    ) || []
  );
}

export function getIdentifierValue(
  resource: {identifier: R4.IIdentifier | R4.IIdentifier[]},
  identifier: string
) {
  if (resource.identifier === undefined) return undefined;
  const ids = Array.isArray(resource.identifier) ? resource.identifier : [resource.identifier];
  return ids.find((id) => id.system === identifier)?.value;
}

export function getExtensionValue(resource: R4.IDomainResource, url: string) {
  if (!resource.extension) {
    return undefined;
  }

  const extension = {
    ...resource.extension.find((ext) => {
      return ext.url === url;
    }),
  };

  delete (extension as any).url;

  return extension[Object.keys(extension)[0]];
}
