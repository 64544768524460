export function defineYKAdminRules(builder) {
  const {can} = builder;
  // Menus
  can('access', 'menu', [
    'home',
    'patients',
    'reports',
    'logs',
    'organizations',
    'users',
    'roles',
    'orgadminusers',
    'mailqueues',
  ]);
  // Routes
  can('access', 'route', [
    '/home',
    '/patients**',
    '/reports',
    '/logs',
    '/organizations**',
    '/users**',
    '/roles**',
    '/orgadminusers**',
    '/mailqueues**',
  ]);
  // Permissions
  can('view', 'patients', ['aefiWidget', 'notesWidget', 'adminWidget']);
  can('view', ['orgAdminUsers']);
}
