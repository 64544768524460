"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_AllergyIntolerance = exports.AllergyIntoleranceCriticalityKind = exports.AllergyIntoleranceTypeKind = exports.AllergyIntoleranceCategoryKind = void 0;
var t = __importStar(require("io-ts"));
var RTTI_id_1 = require("../Scalar/RTTI_id");
var RTTI_Meta_1 = require("./RTTI_Meta");
var RTTI_uri_1 = require("../Scalar/RTTI_uri");
var RTTI_Element_1 = require("./RTTI_Element");
var RTTI_code_1 = require("../Scalar/RTTI_code");
var RTTI_Narrative_1 = require("./RTTI_Narrative");
var RTTI_ResourceList_1 = require("../Union/RTTI_ResourceList");
var RTTI_Extension_1 = require("./RTTI_Extension");
var RTTI_Identifier_1 = require("./RTTI_Identifier");
var RTTI_CodeableConcept_1 = require("./RTTI_CodeableConcept");
var RTTI_Reference_1 = require("./RTTI_Reference");
var RTTI_Age_1 = require("./RTTI_Age");
var RTTI_Period_1 = require("./RTTI_Period");
var RTTI_Range_1 = require("./RTTI_Range");
var RTTI_dateTime_1 = require("../Scalar/RTTI_dateTime");
var RTTI_Annotation_1 = require("./RTTI_Annotation");
var RTTI_AllergyIntolerance_Reaction_1 = require("./RTTI_AllergyIntolerance_Reaction");
var AllergyIntoleranceCategoryKind;
(function (AllergyIntoleranceCategoryKind) {
    AllergyIntoleranceCategoryKind["_food"] = "food";
    AllergyIntoleranceCategoryKind["_medication"] = "medication";
    AllergyIntoleranceCategoryKind["_environment"] = "environment";
    AllergyIntoleranceCategoryKind["_biologic"] = "biologic";
})(AllergyIntoleranceCategoryKind = exports.AllergyIntoleranceCategoryKind || (exports.AllergyIntoleranceCategoryKind = {}));
var AllergyIntoleranceTypeKind;
(function (AllergyIntoleranceTypeKind) {
    AllergyIntoleranceTypeKind["_allergy"] = "allergy";
    AllergyIntoleranceTypeKind["_intolerance"] = "intolerance";
})(AllergyIntoleranceTypeKind = exports.AllergyIntoleranceTypeKind || (exports.AllergyIntoleranceTypeKind = {}));
var AllergyIntoleranceCriticalityKind;
(function (AllergyIntoleranceCriticalityKind) {
    AllergyIntoleranceCriticalityKind["_low"] = "low";
    AllergyIntoleranceCriticalityKind["_high"] = "high";
    AllergyIntoleranceCriticalityKind["_unableToAssess"] = "unable-to-assess";
})(AllergyIntoleranceCriticalityKind = exports.AllergyIntoleranceCriticalityKind || (exports.AllergyIntoleranceCriticalityKind = {}));
var EnumType_1 = require("../../EnumType");
exports.RTTI_AllergyIntolerance = t.recursion('IAllergyIntolerance', function () {
    return t.intersection([
        t.type({
            resourceType: t.literal('AllergyIntolerance'),
            patient: RTTI_Reference_1.RTTI_Reference
        }),
        t.partial({
            id: RTTI_id_1.RTTI_id,
            meta: RTTI_Meta_1.RTTI_Meta,
            implicitRules: RTTI_uri_1.RTTI_uri,
            _implicitRules: RTTI_Element_1.RTTI_Element,
            language: RTTI_code_1.RTTI_code,
            _language: RTTI_Element_1.RTTI_Element,
            text: RTTI_Narrative_1.RTTI_Narrative,
            contained: t.array(RTTI_ResourceList_1.RTTI_ResourceList),
            extension: t.array(RTTI_Extension_1.RTTI_Extension),
            modifierExtension: t.array(RTTI_Extension_1.RTTI_Extension),
            identifier: t.array(RTTI_Identifier_1.RTTI_Identifier),
            clinicalStatus: RTTI_CodeableConcept_1.RTTI_CodeableConcept,
            verificationStatus: RTTI_CodeableConcept_1.RTTI_CodeableConcept,
            type: EnumType_1.createEnumType(AllergyIntoleranceTypeKind, 'AllergyIntoleranceTypeKind'),
            _type: RTTI_Element_1.RTTI_Element,
            category: t.array(EnumType_1.createEnumType(AllergyIntoleranceCategoryKind, 'AllergyIntoleranceCategoryKind')),
            _category: t.array(RTTI_Element_1.RTTI_Element),
            criticality: EnumType_1.createEnumType(AllergyIntoleranceCriticalityKind, 'AllergyIntoleranceCriticalityKind'),
            _criticality: RTTI_Element_1.RTTI_Element,
            code: RTTI_CodeableConcept_1.RTTI_CodeableConcept,
            encounter: RTTI_Reference_1.RTTI_Reference,
            onsetDateTime: t.string,
            _onsetDateTime: RTTI_Element_1.RTTI_Element,
            onsetAge: RTTI_Age_1.RTTI_Age,
            onsetPeriod: RTTI_Period_1.RTTI_Period,
            onsetRange: RTTI_Range_1.RTTI_Range,
            onsetString: t.string,
            _onsetString: RTTI_Element_1.RTTI_Element,
            recordedDate: RTTI_dateTime_1.RTTI_dateTime,
            _recordedDate: RTTI_Element_1.RTTI_Element,
            recorder: RTTI_Reference_1.RTTI_Reference,
            asserter: RTTI_Reference_1.RTTI_Reference,
            lastOccurrence: RTTI_dateTime_1.RTTI_dateTime,
            _lastOccurrence: RTTI_Element_1.RTTI_Element,
            note: t.array(RTTI_Annotation_1.RTTI_Annotation),
            reaction: t.array(RTTI_AllergyIntolerance_Reaction_1.RTTI_AllergyIntolerance_Reaction)
        })
    ]);
});
