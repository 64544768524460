import {createSlice} from '@reduxjs/toolkit';
import {useSelector} from 'react-redux';
import {FhirUtils} from '../services/fhir';
import {RootState} from '../store';
import {Organization, useOrganizationWithId} from './organizations';
import * as uuid from 'uuid';

export interface UISliceInterface {
  localOrg: Organization | undefined;
  scrollTop: number;
  shownWelcome: boolean;
  welcomeModalVisible: boolean;
  idleTime: number | undefined; // epoch time in ms, when the user was flagged as idle
  lastUserActionTime: number | undefined; // epoch time in ms, since the user's last detected interaction with the application
  importListRefreshTrigger: string;
}
const initialState = {
  localOrg: undefined,
  scrollTop: 0,
  shownWelcome: false,
  welcomeModalVisible: false, // TODO: Flip back
  idleTime: undefined,
  lastUserActionTime: undefined,
  importListRefreshTrigger: uuid.v4(),
};

const slice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    SET_LOCAL_ORG: (state: UISliceInterface, action) => {
      state.localOrg = action.payload;
    },
    SET_SCROLL_TOP: (state: UISliceInterface, action) => {
      state.scrollTop = action.payload;
    },
    SET_SHOWN_WELCOME: (state: UISliceInterface, action) => {
      state.shownWelcome = action.payload;
    },
    SET_WELCOME_MODAL_VISIBLE: (state: UISliceInterface, action) => {
      state.welcomeModalVisible = action.payload;
    },
    SET_IDLE_TIME: (state: UISliceInterface, action) => {
      state.idleTime = action.payload;
    },
    CLEAR_IDLE_TIME: (state: UISliceInterface) => {
      state.idleTime = undefined;
    },
    SET_LAST_USER_ACTION_TIME: (state: UISliceInterface, action) => {
      state.lastUserActionTime = action.payload;
    },
    SET_IMPORT_LIST_REFRESH_TRIGGER: (state: UISliceInterface) => {
      state.importListRefreshTrigger = uuid.v4();
    },
  },
});

export default {
  slice,
};

export const useGlobalOrg = () => {
  const localOrg = useSelector((state: RootState) => state.ui.localOrg);
  return localOrg as Organization | undefined;
};
