import React from 'react';

export class StopPropagation extends React.Component {
  handleClick = (e) => {
    e.stopPropagation(); //  <------ Here is the magic
    // this.props.onClick();
  };

  render() {
    return <div onClick={this.handleClick}>{this.props.children}</div>;
  }
}
