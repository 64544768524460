import {Modal} from 'antd';
import React, {useState} from 'react';
import {ContactForm} from './contact-form';

export const AppointmentGroupModal = (props) => {
  const {visible, onClose, appointment} = props;
  const [contactFormValues, setContactFormValues] = useState({data: {}});

  const selectedAppointment = {
    ...appointment.appointmentType,
    datetime: appointment.datetime,
    appointmentGroupId: appointment.appointmentGroup.id,
  };

  return (
    <Modal visible={visible} closable={false} width={'70%'} footer={false}>
      <ContactForm
        setSelectedAppointment={() => {}}
        selectedAppointment={selectedAppointment}
        contactFormValues={contactFormValues}
        setContactFormValues={setContactFormValues}
        isAddGroupForm={true}
        onClose={onClose}
      />
    </Modal>
  );
};
