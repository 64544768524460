import {CVC, CVCInterface} from '@canimmunize/cvc-js';
import {GenderUtil} from '@canimmunize/tools';
import {ColumnsType} from 'antd/lib/table';
import _ from 'lodash';
import moment, {Moment} from 'moment';
import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {ThunkDispatch} from '../../../models';
import * as Cohort from '../../../models/cohort';
import {CohortCSVOptionalFields, CohortCSVValidation, CohortType} from '../../../models/cohort';
import {fullDisplayName} from '../../../models/fhir/patient';
import {useHcnTypes} from '../../../models/hcn-type';
import * as List from '../../../models/list';
import {ListCSVOptionalFields, ListCSVValidation, ListType} from '../../../models/list';
import {useOrganizations, useOrganizationWithId} from '../../../models/organizations';
import { CVCRoute, useCVCRoutes } from '../../../models/routes';
import { CVCSite, useCVCSites } from '../../../models/sites';
import {Tradename, useTradenames} from '../../../models/tradenames';
import {FhirUtils} from '../../../services/fhir';
import {emptyStringsToUndefined} from '../../util/csv/csv-upload-validation';
import {UploadModal} from './upload-modal';

export interface CSVParseError {
  code: string;
  message: string;
  row: number;
  type: string;
}

export interface PatientUploadModalProps {
  visible: boolean;
  item: any; // Cohort or List
  onClose: () => void;
  templateName: string;
  type: 'cohort' | 'list';
  cvc?: CVCInterface;
  tradenames?: Tradename[];
  routes?: CVCRoute[];
  sites?: CVCSite[];
  preProcessData?: (row) => any;
}

export const PatientUploadModal = (props: PatientUploadModalProps) => {
  const {visible, item, onClose, type, cvc, tradenames, sites, routes} = props;
  const client = FhirUtils.useClient();
  const thunkDispatch = useDispatch<ThunkDispatch>();
  const organization = useOrganizationWithId(item ? item.organizationId : null);
  const organizations = useOrganizations();

  const cohorts: CohortType[] = Cohort.useCohorts();
  const lists: ListType[] = List.useLists();
  const allItems: CohortType[] | ListType[] = type === 'cohort' ? cohorts : lists;
  const hcnTypes = useHcnTypes();

  const optionalFields = type === 'cohort' ? CohortCSVOptionalFields : ListCSVOptionalFields;
  const validationOptions = {
    cvc,
    tradenames,
    sites,
    routes,
    optionalFields,
  };

  const getUniqueKey =
    type === 'list'
      ? (row) => `${row.data.firstName}-${row.data.lastName}-${row.data.email}`
      : undefined;

  const preProcessData = props.preProcessData
    ? props.preProcessData
    : (row) => {
        row = emptyStringsToUndefined(row);

        let cohortId = item?.id;
        if (type === 'cohort') {
          if (!cvc) {
            throw new Error('CVC data not found.');
          } else if (!tradenames) {
            throw new Error('Tradenames data not found.');
          }
          cohortId = item ? item.id : allItems.find((i) => i.name === row.Cohort)?.id;
          row.cohortIds = [cohortId];
          if (!cohortId) {
            row.error = `No cohort with the name "${row.Cohort}" could be found`;
            return row;
          }

          row.id = row.patientId;
        }

        row.organizationId =
          organization?.id || organizations.find((org) => org.organizationCode === row.OrgCode)?.id;
        if (!row.organizationId) {
          const orgCode = organization?.organizationCode || row.OrgCode;
          row.error = `No organization with the code "${orgCode}" could be found`;
          return row;
        }

        if (row.hcnType) {
          if (hcnTypes.findIndex((hcntype) => hcntype.id === row.hcnType) === -1) {
            row.error = `No healthcard Type with the code "${row.hcnType}" could be found`;
            return row;
          }
        }

        if (cvc) {
          let doseObj: {
            conceptId?: string;
            site?: string;
            route?: string;
            date?: Moment;
            lotNumber?: string;
            pageId?: string;
            doseNumber?: number;
            decrementInventory?: boolean;
            dosage?: number;
            aefiRecorded?: boolean;
            dosageUom?: string;
            expiry?: string;
            scheduleId?: string;
            clinicEntry?: {
              clinicName: string;
              immunizerName: string;
              immunizerEmail: string;
              cohortId: string;
            };
            serviceDeliveryLocationName?: string;
          } = {};

          const vaccineConcept = tradenames?.find(
            (vaccine) => vaccine.publicPicklistTermEn === row.vaccineName
          );
          if (vaccineConcept) {
            doseObj.conceptId = vaccineConcept.conceptId;
          }
          const siteOfAdmin = sites?.find(
            (site) => site.displayNameEn === row.siteOfAdmin
          );
          if (siteOfAdmin) {
            doseObj.site = siteOfAdmin.conceptId;
          }
          const routeOfAdmin = routes?.find(
            (route) => route.displayNameEn === row.routeOfAdmin
          );
          if (routeOfAdmin) {
            doseObj.route = routeOfAdmin.conceptId;
          }
          const lot = cvc.lotData?.find((lot) => lot.lotNumber === row.lotNumber);
          if (lot) {
            doseObj.expiry = lot.expiryDate;
          }

          if (!_.isEmpty(doseObj)) {
            doseObj = {
              ...doseObj,
              pageId: '-1',
              decrementInventory: true,
              aefiRecorded: false,
              lotNumber: row.lotNumber,
              doseNumber: row.doseNumber,
              dosage: row.dosage,
              date: moment.utc(`${row.dateAdministered} ${row.timeAdministered}`),
              dosageUom: row.dosageUom,
              serviceDeliveryLocationName: row.DeliveryLocation,
            };

            doseObj.clinicEntry = {
              clinicName: row.clinicName,
              immunizerName: row.immunizerName,
              immunizerEmail: row.immunizerEmail,
              cohortId,
            };
            row.doses = [doseObj];
          }
        }

        if (row.gender) {
          //Change gender values to enums
          const gender = row.gender.toLowerCase();

          if (gender === 'male') row.gender = '0';
          else if (gender === 'female') row.gender = '1';
          else if (gender === 'unknown') row.gender = '2';
        }

        return row;
      };

  const onUploadCohort = async (uploadData) => {
    const patients = uploadData;
    return await thunkDispatch(Cohort.uploadPatients(client, patients));
  };

  const onUploadList = async (uploadData) => {
    const patients = uploadData.map((patientData) => {
      return {
        ...patientData,
        listId: item.id,
      };
    });
    return await thunkDispatch(List.uploadPatients(client, item.id, patients));
  };

  //Additional columns from the organization
  const orgFieldColumns =
    organization?.fieldSchema?.fields.map((field) => ({
      title: field.key,
      dataIndex: field.key,
    })) || [];
  const itemSpecificColumns =
    type === 'cohort'
      ? [
          {width: 150, title: 'HCN', dataIndex: 'hcn'},
          {width: 150, title: 'HCN Type', dataIndex: 'hcnType'},
          {
            title: 'Gender',
            dataIndex: 'gender',
            render: (_, row: any) => {
              if (['0', '1', '2'].indexOf(row.gender) > -1)
                return GenderUtil.toString(parseInt(row.gender));
              else return row.gender;
            },
          },
          {title: 'Birthdate', dataIndex: 'birthdate'},
          {title: 'Phone', dataIndex: 'phone'},
        ]
      : [];

  const cohortAndOrgNameColumns =
    type === 'cohort' && !item
      ? [
          {width: 150, title: 'Cohort', dataIndex: 'Cohort'},
          {width: 150, title: 'Org Code', dataIndex: 'OrgCode'},
        ]
      : [];
  const patientIdColumn =
    type === 'cohort' ? [{width: 200, title: 'Patient ID', dataIndex: 'patientId'}] : [];
  const doseFieldsColumn = type === 'cohort' ? doseFields : [];

  const CROverrideColumn =
    type === 'cohort'
      ? [
          {width: 150, title: 'CR Override', dataIndex: 'CROverride'},
          {width: 150, title: 'CR Override Reason', dataIndex: 'CROverrideReason'},
        ]
      : [];

  let columns: ColumnsType<any> = [
    {
      width: 200,
      title: 'Name',
      render: (_, row: any) => fullDisplayName(row),
      fixed: 'left',
    },
    ...patientIdColumn,
    ...cohortAndOrgNameColumns,
    {title: 'Email', dataIndex: 'email'},
    ...itemSpecificColumns,
    ...orgFieldColumns,
    ...doseFieldsColumn,
    ...CROverrideColumn,
  ];

  return (
    <UploadModal
      visible={visible}
      preProcessData={preProcessData}
      getUniqueKey={getUniqueKey}
      validationOptions={validationOptions}
      title={'Upload Patients'}
      onClose={onClose}
      onClickUpload={type === 'cohort' ? onUploadCohort : onUploadList}
      howToUse="Use the downloadable CSV template to upload patients into this cohort. Uploaded patients will be matched and updated if they already exist in the system. Patients can be place into more than one cohort. Upload up to 1000 patients at once."
      templateName={props.templateName}
      uploadedColumns={columns}
      getValidationRules={type === 'cohort' ? CohortCSVValidation : () => ListCSVValidation}
    ></UploadModal>
  );
};

interface CohortPatientUploadProps {
  visible: boolean;
  onClose: () => void;
  cohort?: any;
}

export const CohortPatientUploadModal = ({visible, onClose, cohort}: CohortPatientUploadProps) => {
  const tradenames = useTradenames();
  const routes = useCVCRoutes();
  const sites = useCVCSites();
  useEffect(() => {
    initializeCVC();
  }, []);

  async function initializeCVC() {
    let cvc = CVC.get();
    if (cvc) {
      return cvc;
    }
    return await CVC.initFromApi('https://cvc.canimmunize.ca/v3');
  }

  const cvc = CVC.get();

  return (
    <PatientUploadModal
      item={cohort}
      cvc={cvc}
      tradenames={tradenames}
      routes={routes}
      sites={sites}
      visible={visible}
      onClose={onClose}
      type="cohort"
      templateName={Cohort.cohortUploadTemplateName}
    />
  );
};

export const doseFields = [
  {title: 'Dose Immunizer Name', dataIndex: 'immunizerName'},
  {title: 'Dose Immunizer Email', dataIndex: 'immunizerEmail'},
  {title: 'Clinic Name', dataIndex: 'clinicName'},
  {title: 'Dose Date Administered', dataIndex: 'dateAdministered'},
  {title: 'Dose Time Administered\n24 hour clock', dataIndex: 'timeAdministered'},
  {title: 'Dose Number', dataIndex: 'doseNumber'},
  {title: 'Dose Vaccine Name', dataIndex: 'vaccineName'},
  {title: 'Dose Lot', dataIndex: 'lotNumber'},
  {title: 'Dose Site', dataIndex: 'siteOfAdmin'},
  {title: 'Dose Route', dataIndex: 'routeOfAdmin'},
  {title: 'Dose Dosage', dataIndex: 'dosage'},
  {title: 'Dose Dosage UoM', dataIndex: 'dosageUom'},
  {title: 'Delivery Location', dataIndex: 'DeliveryLocation'},
];
