import {Ids} from '@canimmunize/tools';
import {Button} from 'reactstrap';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useUrlData} from '../util';
import {MessageCard} from './message-card';
import {useStr} from '../../../services/str';

export const AppointmentCancelled = (props) => {
  const {appointmentCancellation, userCancelled, simpleView} = props;
  const {lang, bookingPageSlug, theme} = useUrlData();
  const navigate = useNavigate();
  const Str = useStr();

  if (userCancelled) {
    return (
      <MessageCard simpleView={simpleView}>
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <div>
            <h4>{Str(Ids.appointment_has_been_cancelled)}</h4>
          </div>
          <div>
            <Button
              style={{backgroundColor: theme.blue, borderRadius: 10}}
              size="lg"
              onClick={() => {
                navigate(`/${lang}/${bookingPageSlug}`);
              }}
            >
              <div style={{color: 'white'}}>{Str(Ids.book_new_appointment)}</div>
            </Button>
          </div>
        </div>
      </MessageCard>
    );
  }

  if (appointmentCancellation) {
    return (
      <MessageCard simpleView={simpleView}>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <div style={{textAlign: 'center'}}>
            <h4>{Str(Ids.appointment_cancelled)}</h4>
            <p
              className="markdown-field"
              dangerouslySetInnerHTML={{__html: appointmentCancellation?.canceledMessage[lang]}}
              style={{textAlign: 'left'}}
            />
          </div>
          {appointmentCancellation.redFlags?.length && (
            <div>
              <p>{Str(Ids.appointment_cancelled_for_reasons)}</p>
              <ul>
                {appointmentCancellation.redFlags.map((f) => {
                  return <li dangerouslySetInnerHTML={{__html: f.value.flagLabel[lang]}}></li>;
                })}
              </ul>
            </div>
          )}
        </div>
      </MessageCard>
    );
  }

  return null;
};
