import moment from 'moment';
import {AbstractValidationRule} from './abstract-validation-rule';

export const ListItemValidationRuleId = 'listItem';

export interface ListItemValidationRule extends AbstractValidationRule {
  validationRuleType: typeof ListItemValidationRuleId;
  validValues: any[];
}

/**
 * Validation to check if a value is one of the allowed list items
 * This will count an empty string/null as valid: use the
 * RequiredValidationRule if you have a mandatory field. Use a
 * drop-down for UXes, but this is good for uploads
 *
 * @export
 * @param {*} value
 * @param {*} validValues
 * @returns {boolean} True if validation passed. False otherwise.
 */
export function validateListItemRule(value: any, validValues: any[]): boolean {
  if (value === null || value === undefined || (typeof value === 'string' && value === '')) {
    return true;
  }

  if (validValues && validValues.includes(value)) {
    return true;
  }

  return false;
}
