import {Tabs} from 'antd';
import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {BookingPageAppointmentTypesUX} from './appointment-type';
import {BookingPageChildSlug} from './booking-page-url-helpers';
import {BookingPageConfigUX} from './config';
import {BookingPageInvitationLists} from './invitation-lists';

// import {BookingPageInvitationListsUX} from './invitation-lists';

const {TabPane} = Tabs;

interface IBookingPageUXItemPageProps {}

export const BookingPageUXItemPage = (props: IBookingPageUXItemPageProps) => {
  const navigate = useNavigate();
  /* Use this to get the id current bookingPage */
  const {id: bookingPageId, tab: activeTab} = useParams();

  return (
    <Tabs
      activeKey={activeTab}
      onChange={(newActiveTab) => {
        navigate(`/bookingpages/${bookingPageId}/${newActiveTab}`);
      }}
    >
      <TabPane tab="Appointment Types" key={BookingPageChildSlug.AppointmentTypes}>
        <BookingPageAppointmentTypesUX bookingPageId={bookingPageId} />
      </TabPane>

      <TabPane tab="Configuration" key={BookingPageChildSlug.Config}>
        <BookingPageConfigUX forcePage="form" card={false} itemId={bookingPageId} />
      </TabPane>

      <TabPane tab="Invitation Lists" key={BookingPageChildSlug.Invitations}>
        <BookingPageInvitationLists bookingPageId={bookingPageId} />
      </TabPane>
    </Tabs>
  );
};
