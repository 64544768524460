import {
  RequiredShortTextValidationRules,
  EmailValidationRules,
  PhoneValidationRules,
  RequiredBirthDateValidationRules,
  RequiredGenderValidationRules,
  RequiredValidationRule,
  ShortTextValidationRules,
} from '../../services/ui-validation-rules';
import {FormUXFieldType} from '../form-ux';
import {FormUXModel} from '../form-ux/form-ux-models/form-ux-model';

/**
 * The names of the fields for the patient form whose rendering have to
 * be handled by the PatientUX component
 *
 * @export
 * @enum {number}
 */
export enum PatientCustomFieldName {
  OrganizationId = 'organizationId',
  HcnType = 'hcnType',
}

export const PatientFieldsSkippableOnOverride = ['hcnType', 'hcn'];

export const PatientUXModel: FormUXModel = [
  {
    groupName: 'Patient Info 1',
    type: FormUXFieldType.grouped,
    inCreateModal: true,
    inEditMode: true,
    fields: [
      {
        name: 'firstName',
        type: FormUXFieldType.text,
        label: 'First Name',
        editable: true,
        validationRules: [...RequiredShortTextValidationRules],
        inCreateModal: true,
      },
      {
        name: 'lastName',
        type: FormUXFieldType.text,
        label: 'Last Name',
        editable: true,
        validationRules: [...RequiredShortTextValidationRules],
        inCreateModal: true,
      },
    ],
  },
  {
    groupName: 'Patient Info 2',
    type: FormUXFieldType.grouped,
    inCreateModal: true,
    fields: [
      {
        name: 'middleName',
        type: FormUXFieldType.text,
        label: 'Middle Name',
        editable: true,
        validationRules: [],
        inCreateModal: true,
      },
      {
        name: 'preferredName',
        type: FormUXFieldType.text,
        label: 'Preferred Name',
        editable: true,
        validationRules: [],
        inCreateModal: true,
      },
    ],
  },
  {
    groupName: 'Patient Info 3',
    type: FormUXFieldType.grouped,
    inCreateModal: true,
    inEditMode: true,
    fields: [
      {
        name: 'gender',
        type: FormUXFieldType.select,
        label: 'Gender',
        editable: true,
        inCreateModal: true,
        selectableValues: [
          {key: 0, label: 'Male'},
          {key: 1, label: 'Female'},
          {key: 3, label: 'Gender X'},
          {key: 4, label: 'Undifferentiated'},
          {key: 2, label: 'Unknown'},
        ],
        validationRules: [...RequiredGenderValidationRules],
      },
      {
        name: 'pronoun',
        type: FormUXFieldType.text,
        label: 'Pronoun',
        editable: true,
        validationRules: [],
        inCreateModal: true,
      },
    ],
  },
  {
    groupName: 'Patient Info 4',
    type: FormUXFieldType.grouped,
    inCreateModal: true,
    inEditMode: true,
    fields: [
      {
        name: 'hcnType',
        type: FormUXFieldType.custom,
        label: 'Health Card Type',
        editable: true,
        validationRules: [],
        looksRequired: true,
        inCreateModal: true,
      },
      {
        name: 'hcn',
        type: FormUXFieldType.text,
        label: 'Health Card Number',
        editable: true,
        validationRules: [...ShortTextValidationRules],
        looksRequired: true,
        inCreateModal: true,
      },
    ],
  },
  {
    groupName: 'Patient Info 5',
    type: FormUXFieldType.grouped,
    inCreateModal: true,
    fields: [
      {
        name: 'email',
        type: FormUXFieldType.text,
        label: 'Email',
        editable: true,
        validationRules: EmailValidationRules,
        inCreateModal: true,
      },
      {
        name: 'phone',
        type: FormUXFieldType.text,
        label: 'Phone',
        editable: true,
        validationRules: PhoneValidationRules,
        inCreateModal: true,
      },
    ],
  },
  {
    groupName: 'Patient Info 6',
    type: FormUXFieldType.grouped,
    inCreateModal: true,
    inEditMode: true,
    fields: [
      {
        name: 'birthdate',
        type: FormUXFieldType.date,
        label: 'Birthdate',
        editable: true,
        placeholder: 'YYYY-MM-DD',
        validationRules: [...RequiredBirthDateValidationRules],
        inCreateModal: true,
      },
      {
        name: PatientCustomFieldName.OrganizationId,
        type: FormUXFieldType.custom,
        label: 'Managing Organization',
        editable: true,
        validationRules: [...RequiredValidationRule],
        inCreateModal: true,
      },
    ],
  },
  {
    groupName: 'Patient Info 7',
    type: FormUXFieldType.grouped,
    inCreateModal: false,
    fields: [
      {
        name: 'street',
        type: FormUXFieldType.text,
        label: 'Street Address',
        editable: true,
        validationRules: [],
        inCreateModal: false,
      },
      {
        name: 'unitNumber',
        type: FormUXFieldType.text,
        label: 'Unit/Apt. #',
        editable: true,
        validationRules: [],
        inCreateModal: false,
      },
    ],
  },
  {
    groupName: 'Patient Info 8',
    type: FormUXFieldType.grouped,
    inCreateModal: false,
    fields: [
      {
        name: 'city',
        type: FormUXFieldType.text,
        label: 'City',
        editable: true,
        validationRules: [],
        inCreateModal: false,
      },
      {
        name: 'postalCode',
        type: FormUXFieldType.text,
        label: 'Postal Code',
        editable: true,
        validationRules: [],
        inCreateModal: false,
      },
    ],
  },
  {
    name: 'deceased',
    type: FormUXFieldType.boolean,
    label: 'Deceased',
    editable: true,
    validationRules: [],
    inCreateModal: false,
  },
];
