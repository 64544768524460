import {Spin} from 'antd';
import axios, {AxiosError, AxiosInstance} from 'axios';
import {changeAntdTheme, generateThemeColor} from 'mini-dynamic-antd-theme';
import queryString from 'query-string';
import React from 'react';
import {Helmet} from 'react-helmet';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {FhirUtils, useUnAuthClient} from '../../services/fhir';
import {useEnvInfo} from '../../services/environment';
import {useCOVIDPortalConfig} from '../../views/vaccine-receipt/util/portal-config';
import {matomoJs} from './matomo';
import {BookingPageConfig} from './types';

export const ConfigContext = React.createContext<BookingPageConfig>({
  loading: true,
} as BookingPageConfig);

interface IBookingPageConfigProviderProps {
  previewConfig?: any;
  forceOverride?: boolean;
  //hideSpinner?: boolean;
  Spinner?: () => JSX.Element;
  walkIn?: boolean;
  children?: React.ReactNode;
}

export const BookingPageConfigProvider = (props: IBookingPageConfigProviderProps) => {
  const {syncUrl, bookingPageSlug, lang, adminUser, query} = useUrlData(props.previewConfig);
  const [throttling, setThrottling] = React.useState(false);
  const axiosClient = FhirUtils.useAxiosClient();
  const client = adminUser ? axiosClient : axios;
  const navigate = useNavigate();
  const logoUrl = useEnvInfo().logoUrl;

  const [bookingPageConfig, setBookingPageConfig] = React.useState<BookingPageConfig>({
    loading: true,
  } as BookingPageConfig);

  React.useEffect(() => {
    if (throttling) return;
    fetchBookingPage();
  }, [throttling, bookingPageSlug]);

  const fetchBookingPage = () => {
    const url =
      (adminUser
        ? `${syncUrl}/booking-page/preview/${bookingPageSlug}?preview=true`
        : `${syncUrl}/public/booking-page/${bookingPageSlug}?includeForms=true`) +
      (query.previousAppointmentId ? `&appointmentId=${query.previousAppointmentId}` : ``) +
      (query.previousDoseId ? `&previousDoseId=${query.previousDoseId}` : ``);

    client
      .get(url)
      .then((r) => {
        if (query.previousAppointmentId && r.data.rescheduleBookingPage) {
          navigate(
            `/${lang}/${r.data.rescheduleBookingPage.bookingPageSlugs[lang]}?${new URLSearchParams(
              query
            ).toString()}`
          );
          return;
        }

        if (props.forceOverride) r.data.clientRegistryOverrideEnabled = 'true';
        setBookingPageConfig({...r.data, loading: false});
      })
      .catch((e: AxiosError) => {
        const shouldThrottle = e?.response?.status === 503 || e?.response?.status === 429;
        if (shouldThrottle) {
          setThrottling(true);
          setTimeout(() => {
            setBookingPageConfig({loading: true, logo: e.response?.data.logo} as BookingPageConfig);
            setThrottling(false);
          }, 10000);
        }

        setBookingPageConfig({loading: false, error: !shouldThrottle} as BookingPageConfig);
      });
  };

  React.useEffect(() => {
    if (adminUser) return;
    changeAntdTheme(
      generateThemeColor(bookingPageConfig.primaryColor),
      'a { text-decoration: underline;}'
    );
  }, [bookingPageConfig.primaryColor, adminUser]);

  if (throttling) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <div style={{padding: 40, textAlign: 'center'}}>
          <div style={{padding: 20}}>{logoUrl && <img src={logoUrl} style={{width: 200}} />}</div>
          <div>
            We are experiencing an unusually high volume of traffic on the website at this time.
            Please try again later. Thank you for your patience!
          </div>
        </div>
      </div>
    );
  }

  if (bookingPageConfig.error || bookingPageConfig.status === 'inactive') {
    const logo = bookingPageConfig?.logo
      ? bookingPageConfig.logo[lang]
      : 'https://cdn.canimmunize.ca/logo.png';

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <div>
          <img src={logo} style={{width: 200}} />
        </div>
        {/* <div>{Str(Ids.invalid_link)}</div> */}
        <div>This page is not currently available. Please try again later!</div>
      </div>
    );
  }

  if (bookingPageConfig.loading)
    return props.Spinner ? (
      <props.Spinner />
    ) : (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <Spin />
      </div>
    );

  return (
    <ConfigContext.Provider value={bookingPageConfig}>
      {!adminUser && (
        <Helmet>
          <title>{bookingPageConfig.bookingFormPageTitle[lang]}</title>
          <meta name="description" content={bookingPageConfig.bookingFormPageDescription[lang]} />
          <link rel="icon" type="image/png" href={bookingPageConfig.favicon}></link>
          {bookingPageConfig.matomoTrackerUrl && (
            <script type="application/javascript">{matomoJs(bookingPageConfig)}</script>
          )}
        </Helmet>
      )}
      <div className="booking-form-provider">{props.children}</div>
    </ConfigContext.Provider>
  );
};

export const useUrlData = (
  previewConfig?
): {
  lang: string;
  config: BookingPageConfig;
  bookingPageSlug?: string;
  appointmentId?: string;
  syncUrl: string;
  theme: any;
  client: AxiosInstance;
  query: any;
  adminUser: boolean;
  preview: boolean;
  includeStaging: boolean;
} => {
  const navigate = useNavigate();
  const urlComps = window.location.hostname.split('.');
  const config = React.useContext(ConfigContext);
  const client = useUnAuthClient();

  const location = useLocation();
  const query = queryString.parse(location.search);

  let env;
  if (urlComps[0] === 'localhost') {
    env = 'local';
  }
  if (urlComps[urlComps.length - 3] === 'qa') {
    env = 'qa';
  }
  if (urlComps[urlComps.length - 3] === 'scheduler') {
    env = 'prod';
  }

  const {lang = 'en', bookingPageSlug, appointmentId} = useParams<{
    lang: 'en' | 'fr';
    bookingPageSlug?: string;
    appointmentId?: string;
  }>();

  // if (lang !== 'en' && lang !== 'fr') {
  //   navigate('/404');
  // }

  const syncUrl = client.defaults.baseURL as string;

  const theme = {
    blue: config?.primaryColor || '#265ed6',
    darkBlue: '#1F6284',
    danger: '#dc3545',
    boxShadowStyle: {
      boxShadow: '0 10px 25px rgba(50, 50, 93, 0.1)',
    },
    sectionSpacing: 60,
    borderRadius: 10,
  };

  return {
    lang,
    config,
    bookingPageSlug,
    appointmentId,
    client,
    syncUrl,
    theme,
    query,
    adminUser: false,
    preview: false,
    ...previewConfig,
  };
};

interface FormioFormComponent {
  key: string;
  components: FormioFormComponent[];
}

export const minimizeDemographicsFormToIdentifyingFields = (component: FormioFormComponent) => {
  minimizeFormComponents(component, [
    'overrideEnabled',
    'birthdate',
    'hcn-columns',
    'waitlistNotice',
    'waitlist',
    'personGroups',
    'intendedBranchIds',
  ]);
};

export const minimizeFormComponents = (component: FormioFormComponent, keys: string[]) => {
  if (component.key && keys.includes(component.key)) return true;

  if (component.components) {
    component.components = component.components.filter((c) => minimizeFormComponents(c, keys));
  }

  return component.components ? component.components.length > 0 : false;
};

export const usePageConfig = () => {
  const {bookingPageSlug, config: bookingPageConfig} = useUrlData();
  const {config: portalConfig} = useCOVIDPortalConfig();

  return bookingPageSlug ? bookingPageConfig : portalConfig;
};
