import {Typography} from 'antd';
import React from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '../../models';
import {ReportActions} from './report-actions';
import {ReportDateCell} from './report-date-cell';
import {ReportFilenameCell} from './report-filename-cell';
import {ReportTable} from './report-ux-components';

const {Title} = Typography;

export const ReportUX = () => {
  const searchPageTitle = 'Reports';
  const globalOrg = useSelector((state: RootState) => state.ui.localOrg);
  const fixedFilters = globalOrg
    ? {
        _organizationId: globalOrg.id,
      }
    : undefined;

  const tableconfig = {
    fixedFilters: fixedFilters,
    fhirResource: 'report',
    columns: [
      {
        title: 'Date',
        dataIndex: 'timestamp',
        key: 'timestamp',
        sorter: true,
        width: 120,
        render: (_, item) => <ReportDateCell report={item} />,
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        sorter: true,
        width: 120,
      },
      {
        title: 'File Name',
        dataIndex: 'fileName',
        key: 'fileName',
        sorter: true,
        width: 400,
        render: (_, item) => <ReportFilenameCell report={item} />,
      },
      {
        title: 'Actions',
        width: 150,
        render: (_, item) => <ReportActions report={item} />,
      },
    ],
  };

  return (
    <>
      <Title level={1} style={{color: 'white'}}>
        {searchPageTitle}
      </Title>
      <div>
        <ReportTable {...tableconfig} />
      </div>
    </>
  );
};
