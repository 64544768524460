import {Button, Modal} from 'antd';
import React from 'react';
import {Note} from './note';

export const ViewNoteModal = (props) => {
  const {visible, note, onClose} = props;

  return (
    <Modal
      title={'View note'}
      visible={visible}
      width={'60%'}
      onCancel={onClose}
      footer={
        <Button type="primary" onClick={onClose}>
          Close
        </Button>
      }
    >
      {(note && <Note note={note} />) || <p>No note selected</p>}
    </Modal>
  );
};
