import {Button, Modal} from 'antd';
import React from 'react';
import {FhirUtils} from '../../services/fhir';
import uuid from 'uuid';
import {DownloadOutlined} from '@ant-design/icons';
import {useStr} from '../../services/str';
import {Ids} from '@canimmunize/tools';

export const DownloadMailRequestPVC = (props) => {
  const {requestId, refresh} = props;
  const [submitting, setSubmitting] = React.useState(false);
  const client = FhirUtils.useAxiosClient();
  const Str = useStr();

  const downloadMailPvc = async () => {
    setSubmitting(true);
    client
      .get(`/mail-request/pvc?requestId=${requestId}`)
      .then((res) => {
        refresh(uuid.v4());
        const pdfBase64 = res.data?.pdf;
        const bytes = atob(pdfBase64);
        let length = bytes.length;
        let out = new Uint8Array(length);

        while (length--) {
          out[length] = bytes.charCodeAt(length);
        }

        const blob = new Blob([out], {type: 'application/pdf'});
        const iframe = document.createElement('iframe');
        iframe.style.visibility = 'hidden';
        iframe.src = window.URL.createObjectURL(blob);
        document.body.appendChild(iframe);
        iframe!.contentWindow!.focus();
        iframe!.contentWindow!.print();
        setSubmitting(false);
      })
      .catch((err) => {
        setSubmitting(false);
        Modal.error({
          title: Str(Ids.error),
          content: `${'Failed to download PVC'}`,
        });
      });
  };

  return (
    <div style={{display: 'flex', justifyContent: 'center'}}>
      <Button
        type="primary"
        loading={submitting}
        onClick={() => {
          downloadMailPvc();
        }}
      >
        <DownloadOutlined />
      </Button>
    </div>
  );
};
