import {createSlice} from '@reduxjs/toolkit';
import {normalize, schema} from 'normalizr';
import querystring from 'querystring';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState, ThunkDispatch} from '.';
import {FhirUtils} from '../services/fhir';
import {mapBundleToResourceArray} from '../util/fhir';

export interface BookingPage {
  id: string;
  name: string;
  organizationId: string;
  bookingPageSlug: {
    en: string;
    fr: string;
  };
  showAddressLookup: string;
  clientRegistryOverrideEnabled: string;
  category: string;
}

export interface BookingPagesSliceInterface {
  byId: {[string: string]: BookingPage};
}

export enum BookingPageCategory {
  GROUP = 'group',
}

const initialState = {
  byId: {},
};

const bookingPage = new schema.Entity('bookingpages', {});

const slice = createSlice({
  name: 'bookingpages',
  initialState,
  reducers: {
    SAVE_BOOKINGPAGES: (state: BookingPagesSliceInterface, action) => {
      const resources = mapBundleToResourceArray(action.payload);
      state.byId = normalize(resources, [bookingPage]).entities.bookingpages || {};
    },
    SAVE_BOOKINGPAGE: (state: BookingPagesSliceInterface, action) => {
      state.byId[action.payload.id] = action.payload;
    },
    DELETE_BOOKINGPAGE: (state: BookingPagesSliceInterface, action) => {
      delete state.byId[action.payload.id];
    },
  },
});

export const getAll = (client, params?) => async (dispatch) => {
  let query = params ? querystring.stringify(params) : undefined;
  const res = await client.get(`/booking-page${query ? `?${query}` : ''}`);
  dispatch(slice.actions.SAVE_BOOKINGPAGES(res.data));
  return res.data;
};

const getOne = (client, id) => async (dispatch) => {
  return client.get(`/booking-page/${id}`).then((res) => {
    return dispatch(slice.actions.SAVE_BOOKINGPAGE(res.data));
  });
};

const updateOne = (client, item) => async (dispatch) => {
  return client.put(`/booking-page/${item.id}`, item).then(async (res) => {
    await dispatch(slice.actions.SAVE_BOOKINGPAGE(res.data));
    return res.data;
  });
};

export const createOne = (client, item) => async (dispatch) => {
  return client.post(`/booking-page`, item).then(async (res) => {
    await dispatch(slice.actions.SAVE_BOOKINGPAGE(res.data));
    return res.data;
  });
};

export const deleteOne = (client, itemId) => async (dispatch) => {
  return client.delete(`/booking-page/${itemId}`).then(async (res) => {
    await dispatch(slice.actions.DELETE_BOOKINGPAGE({id: itemId}));
    return res.data;
  });
};

export const useBookingPages = () => {
  const thunkDispatch = useDispatch<ThunkDispatch>();
  const client = FhirUtils.useClient();

  React.useEffect(() => {
    thunkDispatch(getAll(client));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const bookingpages = useSelector((state: RootState) => state.bookingpages.byId);

  return Object.values(bookingpages);
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  slice,
  getAll,
  getOne,
  updateOne,
  createOne,
  deleteOne,
};
