import {client} from 'fhirclient';
import {Select, Form} from 'formik-antd';
import React from 'react';
import {useSelector} from 'react-redux';
import {Organization, useOrganizations} from '../../models/organizations';
import {FhirUtils} from '../../services/fhir';
import {AbilityContext} from '../../services/roles/ability-context';
import {RootState} from '../../store';
import {ValidationRules} from '../../validation/validation-rules/validation-rules';
import {ValidateFormItem} from '../validate-form-item';

interface OrganizationPickerFormItemProps {
  label?: string;
  name: string;
  setFieldValue: (name: string, value: any) => void;
  item?: Organization;
  validationRules?: ValidationRules[];
  disabled?: boolean;
  organizationId?: string;
}

export const OrganizationPickerFormItem = (props: OrganizationPickerFormItemProps) => {
  const client = FhirUtils.useClient();
  const storedOrgs = useSelector((state: RootState) => state.organizations.byId);
  const [organizations, setOrganizations] = React.useState<any[]>(Object.values(storedOrgs) || []);

  React.useEffect(() => {
    // If initial organizationId not in user's org list, then retrieve organization and
    // add it to the org list
    if (props.organizationId && !storedOrgs[props.organizationId]) {
      fetchOrganization(props.organizationId);
    }
  }, []);

  async function fetchOrganization(id) {
    const result = await client.get(`/organization/${id}`).then((res) => res.data);
    setOrganizations([...Object.values(storedOrgs), result]);
  }

  return (
    <ValidateFormItem
      validationRules={props.validationRules || []}
      renderFormItem={(validate) => {
        return (
          <Form.Item
            name={props.name || 'organizationId'}
            label={props.label || 'Organization'}
            validate={validate}
            tooltip={{
              icon: <span style={{color: 'red', fontSize: 14, fontWeight: 'bolder'}}>*</span>,
            }}
          >
            <Select
              name={props.name}
              disabled={props.disabled}
              showSearch
              placeholder="Select an organization"
              optionFilterProp="children"
              onChange={(value) => {
                props.setFieldValue(props.name || 'organizationId', value);
              }}
              // onFocus={onFocus}
              // onBlur={onBlur}
              // onSearch={onSearch}
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input?.toLowerCase()) >= 0
              }
            >
              {organizations
                .filter((organization) => {
                  /* If this is an old organization then make sure they
                  can't make an organization its own parent */
                  if (props.item) {
                    return organization.id !== props.item.id;
                  }

                  /* Otherwise this is a new organization so allow them to
                  select any organization as a parent */
                  return true;
                })
                .map((org) => (
                  <Select.Option value={org.id!} key={org.id}>
                    {`${org.organizationCode ? `${org.organizationCode} - ` : ''}${org.name?.en}` ||
                      org.id}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        );
      }}
    />
  );
};
