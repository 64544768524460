import React from 'react';
import moment from 'moment-timezone';
import * as Icons from '@ant-design/icons';
import _ from 'lodash';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import queryString from 'query-string';
import {
  Descriptions,
  Popconfirm,
  Typography,
  Button,
  message,
  Space,
  Switch,
  Modal,
  Tag,
  List,
} from 'antd';
import {FormatTimezoneAbbr} from '@canimmunize/tools';
import AppointmentModel from '../../models/appointments';
import {ThunkDispatch} from '../../models';

import {FhirUtils} from '../../services/fhir';
import {useDispatch} from 'react-redux';
import {AppointmentStatusTag} from './appointment-ux';
import {RescheduleModal} from './scheduler/reschedule-modal';
import {SchedulerModal} from './scheduler/schedule-modal';
import {AbilityContext} from '../../services/roles/ability-context';
import {useEnvInfo} from '../../services/environment';

export const AppointmentDetails = (props) => {
  const appointment = props.item;
  const [sendCancellationEmail, setSendCancellationEmail] = React.useState(true);
  const [bookAppointmentModalVisible, setBookAppointmentModalVisible] = React.useState(false);
  const {
    firstName,
    lastName,
    datetime,
    consentComplete,
    covidInitialScreenComplete,
    covid24HourScreenComplete,
    bookingPage,
    duration,
    id,
    canceled,
    canceledReason,
    completed,
    phone,
    email,
    deleted,
    timezone,
    createdAt,
    ruleId,
    noShow,
    checkedIn,
    nextAppointmentId,
    linkedAppointments,
    overrideSeries,
    intendedBranch,
    apptCode,
  } = appointment;

  const navigate = useNavigate();
  const query = queryString.parse(useLocation().search);
  const [rescheduling, setRescheduling] = React.useState(false);
  const [notifying, setNotifying] = React.useState(false);

  const location = appointment?.calendar?.clinic?.location?.name;
  const isSelfCheckInClinic = appointment?.calendar?.clinic?.selfCheckInClinic;
  const clinicName = appointment?.calendar?.clinic?.name || '';
  const clinicTimezone = appointment?.calendar?.clinic?.timezone;
  const calendarName = appointment?.calendar?.name || '';
  const inPast = moment(appointment.datetime).isBefore(moment());
  const {Title} = Typography;
  const client = FhirUtils.useClient();
  const thunkDispatch = useDispatch<ThunkDispatch>();
  const defaultAge = moment().diff(appointment.patients[0].birthdate, 'years');
  const ability = React.useContext(AbilityContext);
  const hasAbilityBookAppointment = ability.can('create', 'patients', 'appointment');
  const {environmentId} = useEnvInfo();

  const onConfirmDelete = async () => {
    return client
      .get(`/appointment/${id}/cancel?sendEmail=${sendCancellationEmail}`)
      .then(async (res) => {
        await thunkDispatch(await AppointmentModel.updateOne(client, {...appointment}))
          .then((res) => {
            message.success('Appointment canceled');
          })
          .catch((err) => {
            message.error(err.message);
          });
      })
      .catch((err) => {
        message.error('An error occurred while cancelling the appointment.');
      });
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onConfirmNoShow = async () => {
    await thunkDispatch(await AppointmentModel.updateOne(client, {...appointment, noShow: !noShow}))
      .then((res) => {
        message.success(noShow ? 'Appointment marked as no-show' : 'Appointment updated');
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  const refreshAppointment = async (setNextAppointmentWasValid?: boolean) => {
    await thunkDispatch(await AppointmentModel.getOne(client, appointment.id));
  };

  const sendReminderEmail = () => {
    return client
      .get(`/appointment/${id}/send-reminder`)
      .then((res) => {
        message.success('Reminder Sent!');
      })
      .catch((err) => {
        message.error('Unable to send Reminder Email as no email address exists on appointment.');
      });
  };

  const confirmContent = (
    <div>
      <b>Are you sure you want to cancel this appointment?</b>
      <div>
        <span style={{marginRight: 5}}>Send Email:</span>
        <Switch
          size="small"
          checked={sendCancellationEmail}
          onChange={(value) => setSendCancellationEmail(value)}
        />
      </div>
    </div>
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const noShowConfirmContent = (
    <div>
      <b>
        {noShow
          ? 'Are you sure you want to cancel no-show for this appointment'
          : 'Are you sure you want to mark this appointment as a no-show?'}
      </b>
      {!noShow && (
        <div>
          <p>
            The patient will be prompted to reschedule their appointment.
            <br />
            If they do not reschedule within three days, they will need to book a new appointment.
          </p>
        </div>
      )}
    </div>
  );

  // Check if popup should appear after reschedule dialogue closed
  const onRescheduleClose = () => {
    if (localStorage.getItem('hadSubsequents') === `true`) setNotifying(true);
    localStorage.removeItem('hadSubsequents');
  };

  const aptGroup = appointment.appointmentGroup;
  const aptPrimary = aptGroup
    ? aptGroup.appointments.find((i) => i.id === aptGroup.primaryAppointmentId)
    : undefined;
  const aptAppointments = aptGroup
    ? aptGroup.appointments.filter((i) => i.id !== aptGroup.primaryAppointmentId)
    : [];
  const cancelText = aptGroup?.isPrimaryAppointment
    ? 'Cancel Group Appointments'
    : 'Cancel Appointment';
  const isYukon2 = environmentId.includes('yukon-2');

  return (
    <>
      <Title level={4}>Appointment Details</Title>
      <Space className="button-container" style={{marginBottom: 15, flexWrap: 'wrap'}}>
        <Popconfirm
          title={confirmContent}
          onConfirm={onConfirmDelete}
          okText="Yes"
          cancelText="No"
          disabled={canceled ? true : noShow ? false : inPast}
        >
          <Button key="delete" danger disabled={canceled ? true : noShow ? false : inPast}>
            <Icons.DeleteOutlined /> {cancelText}
          </Button>
        </Popconfirm>

        <Button key="reminder" onClick={sendReminderEmail} disabled={inPast || canceled}>
          <Icons.SendOutlined /> Send Reminder Email
        </Button>

        {!aptGroup && (
          <Button disabled={canceled || completed} onClick={() => setRescheduling(true)}>
            <Icons.ClockCircleOutlined /> Reschedule
          </Button>
        )}

        <Link
          target="_blank"
          rel="noopener noreferrer"
          to={`/en/${bookingPage.bookingPageSlug.en}/${id}`}
        >
          <Button disabled={canceled}>
            <Icons.CarryOutOutlined /> Open Appointment Summary
          </Button>
        </Link>

        {hasAbilityBookAppointment && (
          <Button
            type="primary"
            onClick={() => {
              setBookAppointmentModalVisible(true);
            }}
          >
            <Icons.CalendarOutlined /> Book Appointment
          </Button>
        )}
      </Space>

      <RescheduleModal
        appointment={appointment}
        visible={rescheduling}
        setVisible={(visible) => {
          setRescheduling(visible);
        }}
        refreshAppointment={refreshAppointment}
        afterClose={onRescheduleClose}
      />

      <SchedulerModal
        visible={bookAppointmentModalVisible}
        setVisible={setBookAppointmentModalVisible}
        patientId={appointment.patients[0].id}
        onSchedule={refreshAppointment}
        defaultAge={defaultAge}
      />

      <Modal
        width={1200}
        title="Subsequent Appointments Cancelled"
        bodyStyle={{height: 600, overflow: 'scroll'}}
        destroyOnClose={true}
        visible={notifying}
        onCancel={() => setNotifying(false)}
        footer={
          <Space>
            <Button onClick={() => setNotifying(false)}>Ok</Button>
          </Space>
        }
      >
        <p>
          This appointment was successfully rescheduled. As a result, all future appointments have
          been cancelled.
        </p>
        <p>
          <b>
            If the patient does not have an email on file, please rebook their second dose
            appointment now by clicking the Book Next Appointment button.
          </b>
          If the patient has an email on file, they will receive an email after their dose 1
          immunization to rebook their second dose appointment.
        </p>
      </Modal>

      <Descriptions bordered size="small">
        {aptGroup && (
          <Descriptions.Item label="Group Appointment" span={12}>
            <Tag color="green">Group {aptGroup?.isPrimaryAppointment ? 'Owner' : 'Member'}</Tag>
          </Descriptions.Item>
        )}
        {aptGroup && aptGroup.isPrimaryAppointment && (
          <Descriptions.Item label="Group Members" span={12}>
            {!!aptAppointments.length ? (
              <List
                dataSource={aptAppointments}
                renderItem={(item: any) => (
                  <List.Item>
                    <a href={`/appointments/${item.id}`}>{`${item.firstName} ${item.lastName}`}</a>
                  </List.Item>
                )}
              />
            ) : (
              'None'
            )}
          </Descriptions.Item>
        )}
        {aptGroup && !aptGroup.isPrimaryAppointment && (
          <Descriptions.Item label="Group Owner" span={12}>
            {aptPrimary ? (
              <a
                href={`/appointments/${aptPrimary.id}`}
              >{`${aptPrimary.firstName} ${aptPrimary.lastName}`}</a>
            ) : (
              'None (Cancelled)'
            )}
          </Descriptions.Item>
        )}
        <Descriptions.Item label="First Name" span={12}>
          {firstName}
        </Descriptions.Item>
        <Descriptions.Item label="Last Name" span={12}>
          {lastName}
        </Descriptions.Item>
        <Descriptions.Item label="Phone Number" span={12}>
          {phone}
        </Descriptions.Item>
        <Descriptions.Item label="Email" span={12}>
          {email}
        </Descriptions.Item>
        {isYukon2 ? (
          <>
            <Descriptions.Item label="Date of Birth" span={12}>
              {moment(appointment.patients[0].birthdate).format('LL')}
            </Descriptions.Item>
            <Descriptions.Item label="Health Card Number" span={12}>
              {`${appointment.patients[0] ? appointment.patients[0].hcn : ''} ${
                appointment.patients[0].hcn && appointment.patients[0].hcnType
                  ? `(${appointment.patients[0].hcnType})`
                  : ''
              }`}
            </Descriptions.Item>
          </>
        ) : (
          <Descriptions.Item label="Patients" span={12}>
            {appointment.patients.map((p, index) => (
              <div key={`${p.id}-${index}`}>
                <a href={`/patients/${p.id}`}>{p.fullName}</a>
              </div>
            ))}
          </Descriptions.Item>
        )}
        <Descriptions.Item label="Appointment Date/Time" span={12}>
          {`${moment
            .tz(datetime, clinicTimezone ? clinicTimezone : timezone)
            .format('LLL')} ${FormatTimezoneAbbr(
            moment.tz(datetime, clinicTimezone ? clinicTimezone : timezone).format('zz'),
            'en',
            clinicTimezone ? clinicTimezone : timezone
          )} (${duration} mins)`}
        </Descriptions.Item>
        <Descriptions.Item label="Clinic" span={12}>
          <a href={`/clinics/${appointment.calendar?.clinic?.id}`}>{clinicName}</a>
        </Descriptions.Item>
        <Descriptions.Item label="Calendar" span={12}>
          <a
            href={`/clinics/${appointment.calendar?.clinic?.id}/calendars/${appointment.calendar?.id}`}
          >
            {calendarName}
          </a>
        </Descriptions.Item>
        <Descriptions.Item label="Rule" span={12}>
          {ruleId}
        </Descriptions.Item>
        <Descriptions.Item label="Intended Series" span={12}>
          {intendedBranch && <div>{intendedBranch.name}</div>}
        </Descriptions.Item>
        <Descriptions.Item label="Location" span={12}>
          {location && <div>{location}</div>}
        </Descriptions.Item>
        {isSelfCheckInClinic === 'true' ? (
          <Descriptions.Item label="Self Check-In Code" span={12}>
            {appointment?.apptCode}
          </Descriptions.Item>
        ) : null}
        {isYukon2 ? null : (
          <>
            <Descriptions.Item label="Consent Form Complete" span={12}>
              {consentComplete ? 'Yes' : 'No'}
            </Descriptions.Item>
          </>
        )}
        <Descriptions.Item label="COVID-19 Initial Screening Complete" span={12}>
          {covidInitialScreenComplete ? 'Yes' : 'No'}
        </Descriptions.Item>
        {isYukon2 ? null : (
          <>
            <Descriptions.Item label="COVID-19 24Hr Screening Complete" span={12}>
              {covid24HourScreenComplete ? 'Yes' : 'No'}
            </Descriptions.Item>
            <Descriptions.Item label="Appointment Status" span={12}>
              <AppointmentStatusTag appointment={appointment} />
            </Descriptions.Item>
            {canceled && (
              <Descriptions.Item label="Cancellation Reason" span={12}>
                {canceledReason ? canceledReason : 'None provided'}
              </Descriptions.Item>
            )}
          </>
        )}
        <Descriptions.Item label="Created at" span={12}>
          {`${moment.tz(createdAt, clinicTimezone ? clinicTimezone : timezone).format('LLL zz')}`}
        </Descriptions.Item>
      </Descriptions>
    </>
  );
};
