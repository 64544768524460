"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Union_1 = require("./Union");
Object.defineProperty(exports, "RTTI_ResourceList", { enumerable: true, get: function () { return Union_1.RTTI_ResourceList; } });
var Scalar_1 = require("./Scalar");
Object.defineProperty(exports, "RTTI_base64Binary", { enumerable: true, get: function () { return Scalar_1.RTTI_base64Binary; } });
Object.defineProperty(exports, "RTTI_canonical", { enumerable: true, get: function () { return Scalar_1.RTTI_canonical; } });
Object.defineProperty(exports, "RTTI_code", { enumerable: true, get: function () { return Scalar_1.RTTI_code; } });
Object.defineProperty(exports, "RTTI_dateTime", { enumerable: true, get: function () { return Scalar_1.RTTI_dateTime; } });
Object.defineProperty(exports, "RTTI_date", { enumerable: true, get: function () { return Scalar_1.RTTI_date; } });
Object.defineProperty(exports, "RTTI_decimal", { enumerable: true, get: function () { return Scalar_1.RTTI_decimal; } });
Object.defineProperty(exports, "RTTI_id", { enumerable: true, get: function () { return Scalar_1.RTTI_id; } });
Object.defineProperty(exports, "RTTI_instant", { enumerable: true, get: function () { return Scalar_1.RTTI_instant; } });
Object.defineProperty(exports, "RTTI_integer", { enumerable: true, get: function () { return Scalar_1.RTTI_integer; } });
Object.defineProperty(exports, "RTTI_markdown", { enumerable: true, get: function () { return Scalar_1.RTTI_markdown; } });
Object.defineProperty(exports, "RTTI_oid", { enumerable: true, get: function () { return Scalar_1.RTTI_oid; } });
Object.defineProperty(exports, "RTTI_positiveInt", { enumerable: true, get: function () { return Scalar_1.RTTI_positiveInt; } });
Object.defineProperty(exports, "RTTI_time", { enumerable: true, get: function () { return Scalar_1.RTTI_time; } });
Object.defineProperty(exports, "RTTI_unsignedInt", { enumerable: true, get: function () { return Scalar_1.RTTI_unsignedInt; } });
Object.defineProperty(exports, "RTTI_uri", { enumerable: true, get: function () { return Scalar_1.RTTI_uri; } });
Object.defineProperty(exports, "RTTI_url", { enumerable: true, get: function () { return Scalar_1.RTTI_url; } });
Object.defineProperty(exports, "RTTI_uuid", { enumerable: true, get: function () { return Scalar_1.RTTI_uuid; } });
Object.defineProperty(exports, "RTTI_xhtml", { enumerable: true, get: function () { return Scalar_1.RTTI_xhtml; } });
var Resource_1 = require("./Resource");
Object.defineProperty(exports, "RTTI_Account_Coverage", { enumerable: true, get: function () { return Resource_1.RTTI_Account_Coverage; } });
Object.defineProperty(exports, "RTTI_Account_Guarantor", { enumerable: true, get: function () { return Resource_1.RTTI_Account_Guarantor; } });
Object.defineProperty(exports, "RTTI_Account", { enumerable: true, get: function () { return Resource_1.RTTI_Account; } });
Object.defineProperty(exports, "AccountStatusKind", { enumerable: true, get: function () { return Resource_1.AccountStatusKind; } });
Object.defineProperty(exports, "RTTI_ActivityDefinition_DynamicValue", { enumerable: true, get: function () { return Resource_1.RTTI_ActivityDefinition_DynamicValue; } });
Object.defineProperty(exports, "RTTI_ActivityDefinition_Participant", { enumerable: true, get: function () { return Resource_1.RTTI_ActivityDefinition_Participant; } });
Object.defineProperty(exports, "RTTI_ActivityDefinition", { enumerable: true, get: function () { return Resource_1.RTTI_ActivityDefinition; } });
Object.defineProperty(exports, "ActivityDefinitionStatusKind", { enumerable: true, get: function () { return Resource_1.ActivityDefinitionStatusKind; } });
Object.defineProperty(exports, "RTTI_Address", { enumerable: true, get: function () { return Resource_1.RTTI_Address; } });
Object.defineProperty(exports, "AddressTypeKind", { enumerable: true, get: function () { return Resource_1.AddressTypeKind; } });
Object.defineProperty(exports, "AddressUseKind", { enumerable: true, get: function () { return Resource_1.AddressUseKind; } });
Object.defineProperty(exports, "RTTI_AdverseEvent_Causality", { enumerable: true, get: function () { return Resource_1.RTTI_AdverseEvent_Causality; } });
Object.defineProperty(exports, "RTTI_AdverseEvent_SuspectEntity", { enumerable: true, get: function () { return Resource_1.RTTI_AdverseEvent_SuspectEntity; } });
Object.defineProperty(exports, "RTTI_AdverseEvent", { enumerable: true, get: function () { return Resource_1.RTTI_AdverseEvent; } });
Object.defineProperty(exports, "AdverseEventActualityKind", { enumerable: true, get: function () { return Resource_1.AdverseEventActualityKind; } });
Object.defineProperty(exports, "RTTI_Age", { enumerable: true, get: function () { return Resource_1.RTTI_Age; } });
Object.defineProperty(exports, "AgeComparatorKind", { enumerable: true, get: function () { return Resource_1.AgeComparatorKind; } });
Object.defineProperty(exports, "RTTI_AllergyIntolerance_Reaction", { enumerable: true, get: function () { return Resource_1.RTTI_AllergyIntolerance_Reaction; } });
Object.defineProperty(exports, "AllergyIntolerance_ReactionSeverityKind", { enumerable: true, get: function () { return Resource_1.AllergyIntolerance_ReactionSeverityKind; } });
Object.defineProperty(exports, "RTTI_AllergyIntolerance", { enumerable: true, get: function () { return Resource_1.RTTI_AllergyIntolerance; } });
Object.defineProperty(exports, "AllergyIntoleranceCategoryKind", { enumerable: true, get: function () { return Resource_1.AllergyIntoleranceCategoryKind; } });
Object.defineProperty(exports, "AllergyIntoleranceCriticalityKind", { enumerable: true, get: function () { return Resource_1.AllergyIntoleranceCriticalityKind; } });
Object.defineProperty(exports, "AllergyIntoleranceTypeKind", { enumerable: true, get: function () { return Resource_1.AllergyIntoleranceTypeKind; } });
Object.defineProperty(exports, "RTTI_Annotation", { enumerable: true, get: function () { return Resource_1.RTTI_Annotation; } });
Object.defineProperty(exports, "RTTI_Appointment_Participant", { enumerable: true, get: function () { return Resource_1.RTTI_Appointment_Participant; } });
Object.defineProperty(exports, "Appointment_ParticipantRequiredKind", { enumerable: true, get: function () { return Resource_1.Appointment_ParticipantRequiredKind; } });
Object.defineProperty(exports, "Appointment_ParticipantStatusKind", { enumerable: true, get: function () { return Resource_1.Appointment_ParticipantStatusKind; } });
Object.defineProperty(exports, "RTTI_AppointmentResponse", { enumerable: true, get: function () { return Resource_1.RTTI_AppointmentResponse; } });
Object.defineProperty(exports, "RTTI_Appointment", { enumerable: true, get: function () { return Resource_1.RTTI_Appointment; } });
Object.defineProperty(exports, "AppointmentStatusKind", { enumerable: true, get: function () { return Resource_1.AppointmentStatusKind; } });
Object.defineProperty(exports, "RTTI_Attachment", { enumerable: true, get: function () { return Resource_1.RTTI_Attachment; } });
Object.defineProperty(exports, "RTTI_AuditEvent_Agent", { enumerable: true, get: function () { return Resource_1.RTTI_AuditEvent_Agent; } });
Object.defineProperty(exports, "RTTI_AuditEvent_Detail", { enumerable: true, get: function () { return Resource_1.RTTI_AuditEvent_Detail; } });
Object.defineProperty(exports, "RTTI_AuditEvent_Entity", { enumerable: true, get: function () { return Resource_1.RTTI_AuditEvent_Entity; } });
Object.defineProperty(exports, "RTTI_AuditEvent_Network", { enumerable: true, get: function () { return Resource_1.RTTI_AuditEvent_Network; } });
Object.defineProperty(exports, "AuditEvent_NetworkTypeKind", { enumerable: true, get: function () { return Resource_1.AuditEvent_NetworkTypeKind; } });
Object.defineProperty(exports, "RTTI_AuditEvent_Source", { enumerable: true, get: function () { return Resource_1.RTTI_AuditEvent_Source; } });
Object.defineProperty(exports, "RTTI_AuditEvent", { enumerable: true, get: function () { return Resource_1.RTTI_AuditEvent; } });
Object.defineProperty(exports, "AuditEventActionKind", { enumerable: true, get: function () { return Resource_1.AuditEventActionKind; } });
Object.defineProperty(exports, "AuditEventOutcomeKind", { enumerable: true, get: function () { return Resource_1.AuditEventOutcomeKind; } });
Object.defineProperty(exports, "RTTI_Basic", { enumerable: true, get: function () { return Resource_1.RTTI_Basic; } });
Object.defineProperty(exports, "RTTI_Binary", { enumerable: true, get: function () { return Resource_1.RTTI_Binary; } });
Object.defineProperty(exports, "RTTI_BiologicallyDerivedProduct_Collection", { enumerable: true, get: function () { return Resource_1.RTTI_BiologicallyDerivedProduct_Collection; } });
Object.defineProperty(exports, "RTTI_BiologicallyDerivedProduct_Manipulation", { enumerable: true, get: function () { return Resource_1.RTTI_BiologicallyDerivedProduct_Manipulation; } });
Object.defineProperty(exports, "RTTI_BiologicallyDerivedProduct_Processing", { enumerable: true, get: function () { return Resource_1.RTTI_BiologicallyDerivedProduct_Processing; } });
Object.defineProperty(exports, "RTTI_BiologicallyDerivedProduct_Storage", { enumerable: true, get: function () { return Resource_1.RTTI_BiologicallyDerivedProduct_Storage; } });
Object.defineProperty(exports, "BiologicallyDerivedProduct_StorageScaleKind", { enumerable: true, get: function () { return Resource_1.BiologicallyDerivedProduct_StorageScaleKind; } });
Object.defineProperty(exports, "RTTI_BiologicallyDerivedProduct", { enumerable: true, get: function () { return Resource_1.RTTI_BiologicallyDerivedProduct; } });
Object.defineProperty(exports, "BiologicallyDerivedProductProductCategoryKind", { enumerable: true, get: function () { return Resource_1.BiologicallyDerivedProductProductCategoryKind; } });
Object.defineProperty(exports, "BiologicallyDerivedProductStatusKind", { enumerable: true, get: function () { return Resource_1.BiologicallyDerivedProductStatusKind; } });
Object.defineProperty(exports, "RTTI_BodyStructure", { enumerable: true, get: function () { return Resource_1.RTTI_BodyStructure; } });
Object.defineProperty(exports, "RTTI_Bundle_Entry", { enumerable: true, get: function () { return Resource_1.RTTI_Bundle_Entry; } });
Object.defineProperty(exports, "RTTI_Bundle_Link", { enumerable: true, get: function () { return Resource_1.RTTI_Bundle_Link; } });
Object.defineProperty(exports, "RTTI_Bundle_Request", { enumerable: true, get: function () { return Resource_1.RTTI_Bundle_Request; } });
Object.defineProperty(exports, "Bundle_RequestMethodKind", { enumerable: true, get: function () { return Resource_1.Bundle_RequestMethodKind; } });
Object.defineProperty(exports, "RTTI_Bundle_Response", { enumerable: true, get: function () { return Resource_1.RTTI_Bundle_Response; } });
Object.defineProperty(exports, "RTTI_Bundle_Search", { enumerable: true, get: function () { return Resource_1.RTTI_Bundle_Search; } });
Object.defineProperty(exports, "Bundle_SearchModeKind", { enumerable: true, get: function () { return Resource_1.Bundle_SearchModeKind; } });
Object.defineProperty(exports, "RTTI_Bundle", { enumerable: true, get: function () { return Resource_1.RTTI_Bundle; } });
Object.defineProperty(exports, "BundleTypeKind", { enumerable: true, get: function () { return Resource_1.BundleTypeKind; } });
Object.defineProperty(exports, "RTTI_CapabilityStatement_Document", { enumerable: true, get: function () { return Resource_1.RTTI_CapabilityStatement_Document; } });
Object.defineProperty(exports, "CapabilityStatement_DocumentModeKind", { enumerable: true, get: function () { return Resource_1.CapabilityStatement_DocumentModeKind; } });
Object.defineProperty(exports, "RTTI_CapabilityStatement_Endpoint", { enumerable: true, get: function () { return Resource_1.RTTI_CapabilityStatement_Endpoint; } });
Object.defineProperty(exports, "RTTI_CapabilityStatement_Implementation", { enumerable: true, get: function () { return Resource_1.RTTI_CapabilityStatement_Implementation; } });
Object.defineProperty(exports, "RTTI_CapabilityStatement_Interaction1", { enumerable: true, get: function () { return Resource_1.RTTI_CapabilityStatement_Interaction1; } });
Object.defineProperty(exports, "CapabilityStatement_Interaction1CodeKind", { enumerable: true, get: function () { return Resource_1.CapabilityStatement_Interaction1CodeKind; } });
Object.defineProperty(exports, "RTTI_CapabilityStatement_Interaction", { enumerable: true, get: function () { return Resource_1.RTTI_CapabilityStatement_Interaction; } });
Object.defineProperty(exports, "CapabilityStatement_InteractionCodeKind", { enumerable: true, get: function () { return Resource_1.CapabilityStatement_InteractionCodeKind; } });
Object.defineProperty(exports, "RTTI_CapabilityStatement_Messaging", { enumerable: true, get: function () { return Resource_1.RTTI_CapabilityStatement_Messaging; } });
Object.defineProperty(exports, "RTTI_CapabilityStatement_Operation", { enumerable: true, get: function () { return Resource_1.RTTI_CapabilityStatement_Operation; } });
Object.defineProperty(exports, "RTTI_CapabilityStatement_Resource", { enumerable: true, get: function () { return Resource_1.RTTI_CapabilityStatement_Resource; } });
Object.defineProperty(exports, "CapabilityStatement_ResourceConditionalDeleteKind", { enumerable: true, get: function () { return Resource_1.CapabilityStatement_ResourceConditionalDeleteKind; } });
Object.defineProperty(exports, "CapabilityStatement_ResourceConditionalReadKind", { enumerable: true, get: function () { return Resource_1.CapabilityStatement_ResourceConditionalReadKind; } });
Object.defineProperty(exports, "CapabilityStatement_ResourceReferencePolicyKind", { enumerable: true, get: function () { return Resource_1.CapabilityStatement_ResourceReferencePolicyKind; } });
Object.defineProperty(exports, "CapabilityStatement_ResourceVersioningKind", { enumerable: true, get: function () { return Resource_1.CapabilityStatement_ResourceVersioningKind; } });
Object.defineProperty(exports, "RTTI_CapabilityStatement_Rest", { enumerable: true, get: function () { return Resource_1.RTTI_CapabilityStatement_Rest; } });
Object.defineProperty(exports, "CapabilityStatement_RestModeKind", { enumerable: true, get: function () { return Resource_1.CapabilityStatement_RestModeKind; } });
Object.defineProperty(exports, "RTTI_CapabilityStatement_SearchParam", { enumerable: true, get: function () { return Resource_1.RTTI_CapabilityStatement_SearchParam; } });
Object.defineProperty(exports, "CapabilityStatement_SearchParamTypeKind", { enumerable: true, get: function () { return Resource_1.CapabilityStatement_SearchParamTypeKind; } });
Object.defineProperty(exports, "RTTI_CapabilityStatement_Security", { enumerable: true, get: function () { return Resource_1.RTTI_CapabilityStatement_Security; } });
Object.defineProperty(exports, "RTTI_CapabilityStatement_Software", { enumerable: true, get: function () { return Resource_1.RTTI_CapabilityStatement_Software; } });
Object.defineProperty(exports, "RTTI_CapabilityStatement_SupportedMessage", { enumerable: true, get: function () { return Resource_1.RTTI_CapabilityStatement_SupportedMessage; } });
Object.defineProperty(exports, "CapabilityStatement_SupportedMessageModeKind", { enumerable: true, get: function () { return Resource_1.CapabilityStatement_SupportedMessageModeKind; } });
Object.defineProperty(exports, "RTTI_CapabilityStatement", { enumerable: true, get: function () { return Resource_1.RTTI_CapabilityStatement; } });
Object.defineProperty(exports, "CapabilityStatementFhirVersionKind", { enumerable: true, get: function () { return Resource_1.CapabilityStatementFhirVersionKind; } });
Object.defineProperty(exports, "CapabilityStatementKindKind", { enumerable: true, get: function () { return Resource_1.CapabilityStatementKindKind; } });
Object.defineProperty(exports, "CapabilityStatementStatusKind", { enumerable: true, get: function () { return Resource_1.CapabilityStatementStatusKind; } });
Object.defineProperty(exports, "RTTI_CarePlan_Activity", { enumerable: true, get: function () { return Resource_1.RTTI_CarePlan_Activity; } });
Object.defineProperty(exports, "RTTI_CarePlan_Detail", { enumerable: true, get: function () { return Resource_1.RTTI_CarePlan_Detail; } });
Object.defineProperty(exports, "CarePlan_DetailStatusKind", { enumerable: true, get: function () { return Resource_1.CarePlan_DetailStatusKind; } });
Object.defineProperty(exports, "RTTI_CarePlan", { enumerable: true, get: function () { return Resource_1.RTTI_CarePlan; } });
Object.defineProperty(exports, "RTTI_CareTeam_Participant", { enumerable: true, get: function () { return Resource_1.RTTI_CareTeam_Participant; } });
Object.defineProperty(exports, "RTTI_CareTeam", { enumerable: true, get: function () { return Resource_1.RTTI_CareTeam; } });
Object.defineProperty(exports, "CareTeamStatusKind", { enumerable: true, get: function () { return Resource_1.CareTeamStatusKind; } });
Object.defineProperty(exports, "RTTI_CatalogEntry_RelatedEntry", { enumerable: true, get: function () { return Resource_1.RTTI_CatalogEntry_RelatedEntry; } });
Object.defineProperty(exports, "CatalogEntry_RelatedEntryRelationtypeKind", { enumerable: true, get: function () { return Resource_1.CatalogEntry_RelatedEntryRelationtypeKind; } });
Object.defineProperty(exports, "RTTI_CatalogEntry", { enumerable: true, get: function () { return Resource_1.RTTI_CatalogEntry; } });
Object.defineProperty(exports, "CatalogEntryStatusKind", { enumerable: true, get: function () { return Resource_1.CatalogEntryStatusKind; } });
Object.defineProperty(exports, "RTTI_ChargeItemDefinition_Applicability", { enumerable: true, get: function () { return Resource_1.RTTI_ChargeItemDefinition_Applicability; } });
Object.defineProperty(exports, "RTTI_ChargeItemDefinition_PriceComponent", { enumerable: true, get: function () { return Resource_1.RTTI_ChargeItemDefinition_PriceComponent; } });
Object.defineProperty(exports, "RTTI_ChargeItemDefinition_PropertyGroup", { enumerable: true, get: function () { return Resource_1.RTTI_ChargeItemDefinition_PropertyGroup; } });
Object.defineProperty(exports, "RTTI_ChargeItemDefinition", { enumerable: true, get: function () { return Resource_1.RTTI_ChargeItemDefinition; } });
Object.defineProperty(exports, "ChargeItemDefinitionStatusKind", { enumerable: true, get: function () { return Resource_1.ChargeItemDefinitionStatusKind; } });
Object.defineProperty(exports, "RTTI_ChargeItem_Performer", { enumerable: true, get: function () { return Resource_1.RTTI_ChargeItem_Performer; } });
Object.defineProperty(exports, "RTTI_ChargeItem", { enumerable: true, get: function () { return Resource_1.RTTI_ChargeItem; } });
Object.defineProperty(exports, "ChargeItemStatusKind", { enumerable: true, get: function () { return Resource_1.ChargeItemStatusKind; } });
Object.defineProperty(exports, "RTTI_Claim_Accident", { enumerable: true, get: function () { return Resource_1.RTTI_Claim_Accident; } });
Object.defineProperty(exports, "RTTI_Claim_CareTeam", { enumerable: true, get: function () { return Resource_1.RTTI_Claim_CareTeam; } });
Object.defineProperty(exports, "RTTI_Claim_Detail", { enumerable: true, get: function () { return Resource_1.RTTI_Claim_Detail; } });
Object.defineProperty(exports, "RTTI_Claim_Diagnosis", { enumerable: true, get: function () { return Resource_1.RTTI_Claim_Diagnosis; } });
Object.defineProperty(exports, "RTTI_Claim_Insurance", { enumerable: true, get: function () { return Resource_1.RTTI_Claim_Insurance; } });
Object.defineProperty(exports, "RTTI_Claim_Item", { enumerable: true, get: function () { return Resource_1.RTTI_Claim_Item; } });
Object.defineProperty(exports, "RTTI_Claim_Payee", { enumerable: true, get: function () { return Resource_1.RTTI_Claim_Payee; } });
Object.defineProperty(exports, "RTTI_Claim_Procedure", { enumerable: true, get: function () { return Resource_1.RTTI_Claim_Procedure; } });
Object.defineProperty(exports, "RTTI_Claim_Related", { enumerable: true, get: function () { return Resource_1.RTTI_Claim_Related; } });
Object.defineProperty(exports, "RTTI_ClaimResponse_AddItem", { enumerable: true, get: function () { return Resource_1.RTTI_ClaimResponse_AddItem; } });
Object.defineProperty(exports, "RTTI_ClaimResponse_Adjudication", { enumerable: true, get: function () { return Resource_1.RTTI_ClaimResponse_Adjudication; } });
Object.defineProperty(exports, "RTTI_ClaimResponse_Detail1", { enumerable: true, get: function () { return Resource_1.RTTI_ClaimResponse_Detail1; } });
Object.defineProperty(exports, "RTTI_ClaimResponse_Detail", { enumerable: true, get: function () { return Resource_1.RTTI_ClaimResponse_Detail; } });
Object.defineProperty(exports, "RTTI_ClaimResponse_Error", { enumerable: true, get: function () { return Resource_1.RTTI_ClaimResponse_Error; } });
Object.defineProperty(exports, "RTTI_ClaimResponse_Insurance", { enumerable: true, get: function () { return Resource_1.RTTI_ClaimResponse_Insurance; } });
Object.defineProperty(exports, "RTTI_ClaimResponse_Item", { enumerable: true, get: function () { return Resource_1.RTTI_ClaimResponse_Item; } });
Object.defineProperty(exports, "RTTI_ClaimResponse_Payment", { enumerable: true, get: function () { return Resource_1.RTTI_ClaimResponse_Payment; } });
Object.defineProperty(exports, "RTTI_ClaimResponse_ProcessNote", { enumerable: true, get: function () { return Resource_1.RTTI_ClaimResponse_ProcessNote; } });
Object.defineProperty(exports, "ClaimResponse_ProcessNoteTypeKind", { enumerable: true, get: function () { return Resource_1.ClaimResponse_ProcessNoteTypeKind; } });
Object.defineProperty(exports, "RTTI_ClaimResponse_SubDetail1", { enumerable: true, get: function () { return Resource_1.RTTI_ClaimResponse_SubDetail1; } });
Object.defineProperty(exports, "RTTI_ClaimResponse_SubDetail", { enumerable: true, get: function () { return Resource_1.RTTI_ClaimResponse_SubDetail; } });
Object.defineProperty(exports, "RTTI_ClaimResponse_Total", { enumerable: true, get: function () { return Resource_1.RTTI_ClaimResponse_Total; } });
Object.defineProperty(exports, "RTTI_ClaimResponse", { enumerable: true, get: function () { return Resource_1.RTTI_ClaimResponse; } });
Object.defineProperty(exports, "RTTI_Claim_SubDetail", { enumerable: true, get: function () { return Resource_1.RTTI_Claim_SubDetail; } });
Object.defineProperty(exports, "RTTI_Claim_SupportingInfo", { enumerable: true, get: function () { return Resource_1.RTTI_Claim_SupportingInfo; } });
Object.defineProperty(exports, "RTTI_Claim", { enumerable: true, get: function () { return Resource_1.RTTI_Claim; } });
Object.defineProperty(exports, "ClaimUseKind", { enumerable: true, get: function () { return Resource_1.ClaimUseKind; } });
Object.defineProperty(exports, "RTTI_ClinicalImpression_Finding", { enumerable: true, get: function () { return Resource_1.RTTI_ClinicalImpression_Finding; } });
Object.defineProperty(exports, "RTTI_ClinicalImpression_Investigation", { enumerable: true, get: function () { return Resource_1.RTTI_ClinicalImpression_Investigation; } });
Object.defineProperty(exports, "RTTI_ClinicalImpression", { enumerable: true, get: function () { return Resource_1.RTTI_ClinicalImpression; } });
Object.defineProperty(exports, "RTTI_CodeableConcept", { enumerable: true, get: function () { return Resource_1.RTTI_CodeableConcept; } });
Object.defineProperty(exports, "RTTI_CodeSystem_Concept", { enumerable: true, get: function () { return Resource_1.RTTI_CodeSystem_Concept; } });
Object.defineProperty(exports, "RTTI_CodeSystem_Designation", { enumerable: true, get: function () { return Resource_1.RTTI_CodeSystem_Designation; } });
Object.defineProperty(exports, "RTTI_CodeSystem_Filter", { enumerable: true, get: function () { return Resource_1.RTTI_CodeSystem_Filter; } });
Object.defineProperty(exports, "RTTI_CodeSystem_Property1", { enumerable: true, get: function () { return Resource_1.RTTI_CodeSystem_Property1; } });
Object.defineProperty(exports, "RTTI_CodeSystem_Property", { enumerable: true, get: function () { return Resource_1.RTTI_CodeSystem_Property; } });
Object.defineProperty(exports, "CodeSystem_PropertyTypeKind", { enumerable: true, get: function () { return Resource_1.CodeSystem_PropertyTypeKind; } });
Object.defineProperty(exports, "RTTI_CodeSystem", { enumerable: true, get: function () { return Resource_1.RTTI_CodeSystem; } });
Object.defineProperty(exports, "CodeSystemContentKind", { enumerable: true, get: function () { return Resource_1.CodeSystemContentKind; } });
Object.defineProperty(exports, "CodeSystemHierarchyMeaningKind", { enumerable: true, get: function () { return Resource_1.CodeSystemHierarchyMeaningKind; } });
Object.defineProperty(exports, "CodeSystemStatusKind", { enumerable: true, get: function () { return Resource_1.CodeSystemStatusKind; } });
Object.defineProperty(exports, "RTTI_Coding", { enumerable: true, get: function () { return Resource_1.RTTI_Coding; } });
Object.defineProperty(exports, "RTTI_Communication_Payload", { enumerable: true, get: function () { return Resource_1.RTTI_Communication_Payload; } });
Object.defineProperty(exports, "RTTI_CommunicationRequest_Payload", { enumerable: true, get: function () { return Resource_1.RTTI_CommunicationRequest_Payload; } });
Object.defineProperty(exports, "RTTI_CommunicationRequest", { enumerable: true, get: function () { return Resource_1.RTTI_CommunicationRequest; } });
Object.defineProperty(exports, "RTTI_Communication", { enumerable: true, get: function () { return Resource_1.RTTI_Communication; } });
Object.defineProperty(exports, "RTTI_CompartmentDefinition_Resource", { enumerable: true, get: function () { return Resource_1.RTTI_CompartmentDefinition_Resource; } });
Object.defineProperty(exports, "RTTI_CompartmentDefinition", { enumerable: true, get: function () { return Resource_1.RTTI_CompartmentDefinition; } });
Object.defineProperty(exports, "CompartmentDefinitionCodeKind", { enumerable: true, get: function () { return Resource_1.CompartmentDefinitionCodeKind; } });
Object.defineProperty(exports, "CompartmentDefinitionStatusKind", { enumerable: true, get: function () { return Resource_1.CompartmentDefinitionStatusKind; } });
Object.defineProperty(exports, "RTTI_Composition_Attester", { enumerable: true, get: function () { return Resource_1.RTTI_Composition_Attester; } });
Object.defineProperty(exports, "Composition_AttesterModeKind", { enumerable: true, get: function () { return Resource_1.Composition_AttesterModeKind; } });
Object.defineProperty(exports, "RTTI_Composition_Event", { enumerable: true, get: function () { return Resource_1.RTTI_Composition_Event; } });
Object.defineProperty(exports, "RTTI_Composition_RelatesTo", { enumerable: true, get: function () { return Resource_1.RTTI_Composition_RelatesTo; } });
Object.defineProperty(exports, "RTTI_Composition_Section", { enumerable: true, get: function () { return Resource_1.RTTI_Composition_Section; } });
Object.defineProperty(exports, "RTTI_Composition", { enumerable: true, get: function () { return Resource_1.RTTI_Composition; } });
Object.defineProperty(exports, "CompositionStatusKind", { enumerable: true, get: function () { return Resource_1.CompositionStatusKind; } });
Object.defineProperty(exports, "RTTI_ConceptMap_DependsOn", { enumerable: true, get: function () { return Resource_1.RTTI_ConceptMap_DependsOn; } });
Object.defineProperty(exports, "RTTI_ConceptMap_Element", { enumerable: true, get: function () { return Resource_1.RTTI_ConceptMap_Element; } });
Object.defineProperty(exports, "RTTI_ConceptMap_Group", { enumerable: true, get: function () { return Resource_1.RTTI_ConceptMap_Group; } });
Object.defineProperty(exports, "RTTI_ConceptMap_Target", { enumerable: true, get: function () { return Resource_1.RTTI_ConceptMap_Target; } });
Object.defineProperty(exports, "ConceptMap_TargetEquivalenceKind", { enumerable: true, get: function () { return Resource_1.ConceptMap_TargetEquivalenceKind; } });
Object.defineProperty(exports, "RTTI_ConceptMap", { enumerable: true, get: function () { return Resource_1.RTTI_ConceptMap; } });
Object.defineProperty(exports, "ConceptMapStatusKind", { enumerable: true, get: function () { return Resource_1.ConceptMapStatusKind; } });
Object.defineProperty(exports, "RTTI_ConceptMap_Unmapped", { enumerable: true, get: function () { return Resource_1.RTTI_ConceptMap_Unmapped; } });
Object.defineProperty(exports, "ConceptMap_UnmappedModeKind", { enumerable: true, get: function () { return Resource_1.ConceptMap_UnmappedModeKind; } });
Object.defineProperty(exports, "RTTI_Condition_Evidence", { enumerable: true, get: function () { return Resource_1.RTTI_Condition_Evidence; } });
Object.defineProperty(exports, "RTTI_Condition_Stage", { enumerable: true, get: function () { return Resource_1.RTTI_Condition_Stage; } });
Object.defineProperty(exports, "RTTI_Condition", { enumerable: true, get: function () { return Resource_1.RTTI_Condition; } });
Object.defineProperty(exports, "RTTI_Consent_Actor", { enumerable: true, get: function () { return Resource_1.RTTI_Consent_Actor; } });
Object.defineProperty(exports, "RTTI_Consent_Data", { enumerable: true, get: function () { return Resource_1.RTTI_Consent_Data; } });
Object.defineProperty(exports, "Consent_DataMeaningKind", { enumerable: true, get: function () { return Resource_1.Consent_DataMeaningKind; } });
Object.defineProperty(exports, "RTTI_Consent_Policy", { enumerable: true, get: function () { return Resource_1.RTTI_Consent_Policy; } });
Object.defineProperty(exports, "RTTI_Consent_Provision", { enumerable: true, get: function () { return Resource_1.RTTI_Consent_Provision; } });
Object.defineProperty(exports, "Consent_ProvisionTypeKind", { enumerable: true, get: function () { return Resource_1.Consent_ProvisionTypeKind; } });
Object.defineProperty(exports, "RTTI_Consent", { enumerable: true, get: function () { return Resource_1.RTTI_Consent; } });
Object.defineProperty(exports, "ConsentStatusKind", { enumerable: true, get: function () { return Resource_1.ConsentStatusKind; } });
Object.defineProperty(exports, "RTTI_Consent_Verification", { enumerable: true, get: function () { return Resource_1.RTTI_Consent_Verification; } });
Object.defineProperty(exports, "RTTI_ContactDetail", { enumerable: true, get: function () { return Resource_1.RTTI_ContactDetail; } });
Object.defineProperty(exports, "RTTI_ContactPoint", { enumerable: true, get: function () { return Resource_1.RTTI_ContactPoint; } });
Object.defineProperty(exports, "ContactPointSystemKind", { enumerable: true, get: function () { return Resource_1.ContactPointSystemKind; } });
Object.defineProperty(exports, "ContactPointUseKind", { enumerable: true, get: function () { return Resource_1.ContactPointUseKind; } });
Object.defineProperty(exports, "RTTI_Contract_Action", { enumerable: true, get: function () { return Resource_1.RTTI_Contract_Action; } });
Object.defineProperty(exports, "RTTI_Contract_Answer", { enumerable: true, get: function () { return Resource_1.RTTI_Contract_Answer; } });
Object.defineProperty(exports, "RTTI_Contract_Asset", { enumerable: true, get: function () { return Resource_1.RTTI_Contract_Asset; } });
Object.defineProperty(exports, "RTTI_Contract_ContentDefinition", { enumerable: true, get: function () { return Resource_1.RTTI_Contract_ContentDefinition; } });
Object.defineProperty(exports, "RTTI_Contract_Context", { enumerable: true, get: function () { return Resource_1.RTTI_Contract_Context; } });
Object.defineProperty(exports, "RTTI_Contract_Friendly", { enumerable: true, get: function () { return Resource_1.RTTI_Contract_Friendly; } });
Object.defineProperty(exports, "RTTI_Contract_Legal", { enumerable: true, get: function () { return Resource_1.RTTI_Contract_Legal; } });
Object.defineProperty(exports, "RTTI_Contract_Offer", { enumerable: true, get: function () { return Resource_1.RTTI_Contract_Offer; } });
Object.defineProperty(exports, "RTTI_Contract_Party", { enumerable: true, get: function () { return Resource_1.RTTI_Contract_Party; } });
Object.defineProperty(exports, "RTTI_Contract_Rule", { enumerable: true, get: function () { return Resource_1.RTTI_Contract_Rule; } });
Object.defineProperty(exports, "RTTI_Contract_SecurityLabel", { enumerable: true, get: function () { return Resource_1.RTTI_Contract_SecurityLabel; } });
Object.defineProperty(exports, "RTTI_Contract_Signer", { enumerable: true, get: function () { return Resource_1.RTTI_Contract_Signer; } });
Object.defineProperty(exports, "RTTI_Contract_Subject", { enumerable: true, get: function () { return Resource_1.RTTI_Contract_Subject; } });
Object.defineProperty(exports, "RTTI_Contract_Term", { enumerable: true, get: function () { return Resource_1.RTTI_Contract_Term; } });
Object.defineProperty(exports, "RTTI_Contract", { enumerable: true, get: function () { return Resource_1.RTTI_Contract; } });
Object.defineProperty(exports, "RTTI_Contract_ValuedItem", { enumerable: true, get: function () { return Resource_1.RTTI_Contract_ValuedItem; } });
Object.defineProperty(exports, "RTTI_Contributor", { enumerable: true, get: function () { return Resource_1.RTTI_Contributor; } });
Object.defineProperty(exports, "ContributorTypeKind", { enumerable: true, get: function () { return Resource_1.ContributorTypeKind; } });
Object.defineProperty(exports, "RTTI_Count", { enumerable: true, get: function () { return Resource_1.RTTI_Count; } });
Object.defineProperty(exports, "CountComparatorKind", { enumerable: true, get: function () { return Resource_1.CountComparatorKind; } });
Object.defineProperty(exports, "RTTI_Coverage_Class", { enumerable: true, get: function () { return Resource_1.RTTI_Coverage_Class; } });
Object.defineProperty(exports, "RTTI_Coverage_CostToBeneficiary", { enumerable: true, get: function () { return Resource_1.RTTI_Coverage_CostToBeneficiary; } });
Object.defineProperty(exports, "RTTI_CoverageEligibilityRequest_Diagnosis", { enumerable: true, get: function () { return Resource_1.RTTI_CoverageEligibilityRequest_Diagnosis; } });
Object.defineProperty(exports, "RTTI_CoverageEligibilityRequest_Insurance", { enumerable: true, get: function () { return Resource_1.RTTI_CoverageEligibilityRequest_Insurance; } });
Object.defineProperty(exports, "RTTI_CoverageEligibilityRequest_Item", { enumerable: true, get: function () { return Resource_1.RTTI_CoverageEligibilityRequest_Item; } });
Object.defineProperty(exports, "RTTI_CoverageEligibilityRequest_SupportingInfo", { enumerable: true, get: function () { return Resource_1.RTTI_CoverageEligibilityRequest_SupportingInfo; } });
Object.defineProperty(exports, "RTTI_CoverageEligibilityRequest", { enumerable: true, get: function () { return Resource_1.RTTI_CoverageEligibilityRequest; } });
Object.defineProperty(exports, "CoverageEligibilityRequestPurposeKind", { enumerable: true, get: function () { return Resource_1.CoverageEligibilityRequestPurposeKind; } });
Object.defineProperty(exports, "RTTI_CoverageEligibilityResponse_Benefit", { enumerable: true, get: function () { return Resource_1.RTTI_CoverageEligibilityResponse_Benefit; } });
Object.defineProperty(exports, "RTTI_CoverageEligibilityResponse_Error", { enumerable: true, get: function () { return Resource_1.RTTI_CoverageEligibilityResponse_Error; } });
Object.defineProperty(exports, "RTTI_CoverageEligibilityResponse_Insurance", { enumerable: true, get: function () { return Resource_1.RTTI_CoverageEligibilityResponse_Insurance; } });
Object.defineProperty(exports, "RTTI_CoverageEligibilityResponse_Item", { enumerable: true, get: function () { return Resource_1.RTTI_CoverageEligibilityResponse_Item; } });
Object.defineProperty(exports, "RTTI_CoverageEligibilityResponse", { enumerable: true, get: function () { return Resource_1.RTTI_CoverageEligibilityResponse; } });
Object.defineProperty(exports, "CoverageEligibilityResponsePurposeKind", { enumerable: true, get: function () { return Resource_1.CoverageEligibilityResponsePurposeKind; } });
Object.defineProperty(exports, "RTTI_Coverage_Exception", { enumerable: true, get: function () { return Resource_1.RTTI_Coverage_Exception; } });
Object.defineProperty(exports, "RTTI_Coverage", { enumerable: true, get: function () { return Resource_1.RTTI_Coverage; } });
Object.defineProperty(exports, "RTTI_DataRequirement_CodeFilter", { enumerable: true, get: function () { return Resource_1.RTTI_DataRequirement_CodeFilter; } });
Object.defineProperty(exports, "RTTI_DataRequirement_DateFilter", { enumerable: true, get: function () { return Resource_1.RTTI_DataRequirement_DateFilter; } });
Object.defineProperty(exports, "RTTI_DataRequirement_Sort", { enumerable: true, get: function () { return Resource_1.RTTI_DataRequirement_Sort; } });
Object.defineProperty(exports, "DataRequirement_SortDirectionKind", { enumerable: true, get: function () { return Resource_1.DataRequirement_SortDirectionKind; } });
Object.defineProperty(exports, "RTTI_DataRequirement", { enumerable: true, get: function () { return Resource_1.RTTI_DataRequirement; } });
Object.defineProperty(exports, "RTTI_DetectedIssue_Evidence", { enumerable: true, get: function () { return Resource_1.RTTI_DetectedIssue_Evidence; } });
Object.defineProperty(exports, "RTTI_DetectedIssue_Mitigation", { enumerable: true, get: function () { return Resource_1.RTTI_DetectedIssue_Mitigation; } });
Object.defineProperty(exports, "RTTI_DetectedIssue", { enumerable: true, get: function () { return Resource_1.RTTI_DetectedIssue; } });
Object.defineProperty(exports, "DetectedIssueSeverityKind", { enumerable: true, get: function () { return Resource_1.DetectedIssueSeverityKind; } });
Object.defineProperty(exports, "RTTI_DeviceDefinition_Capability", { enumerable: true, get: function () { return Resource_1.RTTI_DeviceDefinition_Capability; } });
Object.defineProperty(exports, "RTTI_DeviceDefinition_DeviceName", { enumerable: true, get: function () { return Resource_1.RTTI_DeviceDefinition_DeviceName; } });
Object.defineProperty(exports, "DeviceDefinition_DeviceNameTypeKind", { enumerable: true, get: function () { return Resource_1.DeviceDefinition_DeviceNameTypeKind; } });
Object.defineProperty(exports, "RTTI_DeviceDefinition_Material", { enumerable: true, get: function () { return Resource_1.RTTI_DeviceDefinition_Material; } });
Object.defineProperty(exports, "RTTI_DeviceDefinition_Property", { enumerable: true, get: function () { return Resource_1.RTTI_DeviceDefinition_Property; } });
Object.defineProperty(exports, "RTTI_DeviceDefinition_Specialization", { enumerable: true, get: function () { return Resource_1.RTTI_DeviceDefinition_Specialization; } });
Object.defineProperty(exports, "RTTI_DeviceDefinition", { enumerable: true, get: function () { return Resource_1.RTTI_DeviceDefinition; } });
Object.defineProperty(exports, "RTTI_DeviceDefinition_UdiDeviceIdentifier", { enumerable: true, get: function () { return Resource_1.RTTI_DeviceDefinition_UdiDeviceIdentifier; } });
Object.defineProperty(exports, "RTTI_Device_DeviceName", { enumerable: true, get: function () { return Resource_1.RTTI_Device_DeviceName; } });
Object.defineProperty(exports, "Device_DeviceNameTypeKind", { enumerable: true, get: function () { return Resource_1.Device_DeviceNameTypeKind; } });
Object.defineProperty(exports, "RTTI_DeviceMetric_Calibration", { enumerable: true, get: function () { return Resource_1.RTTI_DeviceMetric_Calibration; } });
Object.defineProperty(exports, "DeviceMetric_CalibrationStateKind", { enumerable: true, get: function () { return Resource_1.DeviceMetric_CalibrationStateKind; } });
Object.defineProperty(exports, "DeviceMetric_CalibrationTypeKind", { enumerable: true, get: function () { return Resource_1.DeviceMetric_CalibrationTypeKind; } });
Object.defineProperty(exports, "RTTI_DeviceMetric", { enumerable: true, get: function () { return Resource_1.RTTI_DeviceMetric; } });
Object.defineProperty(exports, "DeviceMetricCategoryKind", { enumerable: true, get: function () { return Resource_1.DeviceMetricCategoryKind; } });
Object.defineProperty(exports, "DeviceMetricColorKind", { enumerable: true, get: function () { return Resource_1.DeviceMetricColorKind; } });
Object.defineProperty(exports, "DeviceMetricOperationalStatusKind", { enumerable: true, get: function () { return Resource_1.DeviceMetricOperationalStatusKind; } });
Object.defineProperty(exports, "RTTI_Device_Property", { enumerable: true, get: function () { return Resource_1.RTTI_Device_Property; } });
Object.defineProperty(exports, "RTTI_DeviceRequest_Parameter", { enumerable: true, get: function () { return Resource_1.RTTI_DeviceRequest_Parameter; } });
Object.defineProperty(exports, "RTTI_DeviceRequest", { enumerable: true, get: function () { return Resource_1.RTTI_DeviceRequest; } });
Object.defineProperty(exports, "RTTI_Device_Specialization", { enumerable: true, get: function () { return Resource_1.RTTI_Device_Specialization; } });
Object.defineProperty(exports, "RTTI_Device", { enumerable: true, get: function () { return Resource_1.RTTI_Device; } });
Object.defineProperty(exports, "DeviceStatusKind", { enumerable: true, get: function () { return Resource_1.DeviceStatusKind; } });
Object.defineProperty(exports, "RTTI_Device_UdiCarrier", { enumerable: true, get: function () { return Resource_1.RTTI_Device_UdiCarrier; } });
Object.defineProperty(exports, "Device_UdiCarrierEntryTypeKind", { enumerable: true, get: function () { return Resource_1.Device_UdiCarrierEntryTypeKind; } });
Object.defineProperty(exports, "RTTI_DeviceUseStatement", { enumerable: true, get: function () { return Resource_1.RTTI_DeviceUseStatement; } });
Object.defineProperty(exports, "DeviceUseStatementStatusKind", { enumerable: true, get: function () { return Resource_1.DeviceUseStatementStatusKind; } });
Object.defineProperty(exports, "RTTI_Device_Version", { enumerable: true, get: function () { return Resource_1.RTTI_Device_Version; } });
Object.defineProperty(exports, "RTTI_DiagnosticReport_Media", { enumerable: true, get: function () { return Resource_1.RTTI_DiagnosticReport_Media; } });
Object.defineProperty(exports, "RTTI_DiagnosticReport", { enumerable: true, get: function () { return Resource_1.RTTI_DiagnosticReport; } });
Object.defineProperty(exports, "DiagnosticReportStatusKind", { enumerable: true, get: function () { return Resource_1.DiagnosticReportStatusKind; } });
Object.defineProperty(exports, "RTTI_Distance", { enumerable: true, get: function () { return Resource_1.RTTI_Distance; } });
Object.defineProperty(exports, "DistanceComparatorKind", { enumerable: true, get: function () { return Resource_1.DistanceComparatorKind; } });
Object.defineProperty(exports, "RTTI_DocumentManifest_Related", { enumerable: true, get: function () { return Resource_1.RTTI_DocumentManifest_Related; } });
Object.defineProperty(exports, "RTTI_DocumentManifest", { enumerable: true, get: function () { return Resource_1.RTTI_DocumentManifest; } });
Object.defineProperty(exports, "DocumentManifestStatusKind", { enumerable: true, get: function () { return Resource_1.DocumentManifestStatusKind; } });
Object.defineProperty(exports, "RTTI_DocumentReference_Content", { enumerable: true, get: function () { return Resource_1.RTTI_DocumentReference_Content; } });
Object.defineProperty(exports, "RTTI_DocumentReference_Context", { enumerable: true, get: function () { return Resource_1.RTTI_DocumentReference_Context; } });
Object.defineProperty(exports, "RTTI_DocumentReference_RelatesTo", { enumerable: true, get: function () { return Resource_1.RTTI_DocumentReference_RelatesTo; } });
Object.defineProperty(exports, "DocumentReference_RelatesToCodeKind", { enumerable: true, get: function () { return Resource_1.DocumentReference_RelatesToCodeKind; } });
Object.defineProperty(exports, "RTTI_DocumentReference", { enumerable: true, get: function () { return Resource_1.RTTI_DocumentReference; } });
Object.defineProperty(exports, "DocumentReferenceStatusKind", { enumerable: true, get: function () { return Resource_1.DocumentReferenceStatusKind; } });
Object.defineProperty(exports, "RTTI_Dosage_DoseAndRate", { enumerable: true, get: function () { return Resource_1.RTTI_Dosage_DoseAndRate; } });
Object.defineProperty(exports, "RTTI_Dosage", { enumerable: true, get: function () { return Resource_1.RTTI_Dosage; } });
Object.defineProperty(exports, "RTTI_Duration", { enumerable: true, get: function () { return Resource_1.RTTI_Duration; } });
Object.defineProperty(exports, "DurationComparatorKind", { enumerable: true, get: function () { return Resource_1.DurationComparatorKind; } });
Object.defineProperty(exports, "RTTI_EffectEvidenceSynthesis_CertaintySubcomponent", { enumerable: true, get: function () { return Resource_1.RTTI_EffectEvidenceSynthesis_CertaintySubcomponent; } });
Object.defineProperty(exports, "RTTI_EffectEvidenceSynthesis_Certainty", { enumerable: true, get: function () { return Resource_1.RTTI_EffectEvidenceSynthesis_Certainty; } });
Object.defineProperty(exports, "RTTI_EffectEvidenceSynthesis_EffectEstimate", { enumerable: true, get: function () { return Resource_1.RTTI_EffectEvidenceSynthesis_EffectEstimate; } });
Object.defineProperty(exports, "RTTI_EffectEvidenceSynthesis_PrecisionEstimate", { enumerable: true, get: function () { return Resource_1.RTTI_EffectEvidenceSynthesis_PrecisionEstimate; } });
Object.defineProperty(exports, "RTTI_EffectEvidenceSynthesis_ResultsByExposure", { enumerable: true, get: function () { return Resource_1.RTTI_EffectEvidenceSynthesis_ResultsByExposure; } });
Object.defineProperty(exports, "EffectEvidenceSynthesis_ResultsByExposureExposureStateKind", { enumerable: true, get: function () { return Resource_1.EffectEvidenceSynthesis_ResultsByExposureExposureStateKind; } });
Object.defineProperty(exports, "RTTI_EffectEvidenceSynthesis_SampleSize", { enumerable: true, get: function () { return Resource_1.RTTI_EffectEvidenceSynthesis_SampleSize; } });
Object.defineProperty(exports, "RTTI_EffectEvidenceSynthesis", { enumerable: true, get: function () { return Resource_1.RTTI_EffectEvidenceSynthesis; } });
Object.defineProperty(exports, "EffectEvidenceSynthesisStatusKind", { enumerable: true, get: function () { return Resource_1.EffectEvidenceSynthesisStatusKind; } });
Object.defineProperty(exports, "RTTI_ElementDefinition_Base", { enumerable: true, get: function () { return Resource_1.RTTI_ElementDefinition_Base; } });
Object.defineProperty(exports, "RTTI_ElementDefinition_Binding", { enumerable: true, get: function () { return Resource_1.RTTI_ElementDefinition_Binding; } });
Object.defineProperty(exports, "ElementDefinition_BindingStrengthKind", { enumerable: true, get: function () { return Resource_1.ElementDefinition_BindingStrengthKind; } });
Object.defineProperty(exports, "RTTI_ElementDefinition_Constraint", { enumerable: true, get: function () { return Resource_1.RTTI_ElementDefinition_Constraint; } });
Object.defineProperty(exports, "ElementDefinition_ConstraintSeverityKind", { enumerable: true, get: function () { return Resource_1.ElementDefinition_ConstraintSeverityKind; } });
Object.defineProperty(exports, "RTTI_ElementDefinition_Discriminator", { enumerable: true, get: function () { return Resource_1.RTTI_ElementDefinition_Discriminator; } });
Object.defineProperty(exports, "ElementDefinition_DiscriminatorTypeKind", { enumerable: true, get: function () { return Resource_1.ElementDefinition_DiscriminatorTypeKind; } });
Object.defineProperty(exports, "RTTI_ElementDefinition_Example", { enumerable: true, get: function () { return Resource_1.RTTI_ElementDefinition_Example; } });
Object.defineProperty(exports, "RTTI_ElementDefinition_Mapping", { enumerable: true, get: function () { return Resource_1.RTTI_ElementDefinition_Mapping; } });
Object.defineProperty(exports, "RTTI_ElementDefinition_Slicing", { enumerable: true, get: function () { return Resource_1.RTTI_ElementDefinition_Slicing; } });
Object.defineProperty(exports, "ElementDefinition_SlicingRulesKind", { enumerable: true, get: function () { return Resource_1.ElementDefinition_SlicingRulesKind; } });
Object.defineProperty(exports, "RTTI_ElementDefinition", { enumerable: true, get: function () { return Resource_1.RTTI_ElementDefinition; } });
Object.defineProperty(exports, "ElementDefinitionRepresentationKind", { enumerable: true, get: function () { return Resource_1.ElementDefinitionRepresentationKind; } });
Object.defineProperty(exports, "RTTI_ElementDefinition_Type", { enumerable: true, get: function () { return Resource_1.RTTI_ElementDefinition_Type; } });
Object.defineProperty(exports, "ElementDefinition_TypeAggregationKind", { enumerable: true, get: function () { return Resource_1.ElementDefinition_TypeAggregationKind; } });
Object.defineProperty(exports, "ElementDefinition_TypeVersioningKind", { enumerable: true, get: function () { return Resource_1.ElementDefinition_TypeVersioningKind; } });
Object.defineProperty(exports, "RTTI_Element", { enumerable: true, get: function () { return Resource_1.RTTI_Element; } });
Object.defineProperty(exports, "RTTI_Encounter_ClassHistory", { enumerable: true, get: function () { return Resource_1.RTTI_Encounter_ClassHistory; } });
Object.defineProperty(exports, "RTTI_Encounter_Diagnosis", { enumerable: true, get: function () { return Resource_1.RTTI_Encounter_Diagnosis; } });
Object.defineProperty(exports, "RTTI_Encounter_Hospitalization", { enumerable: true, get: function () { return Resource_1.RTTI_Encounter_Hospitalization; } });
Object.defineProperty(exports, "RTTI_Encounter_Location", { enumerable: true, get: function () { return Resource_1.RTTI_Encounter_Location; } });
Object.defineProperty(exports, "Encounter_LocationStatusKind", { enumerable: true, get: function () { return Resource_1.Encounter_LocationStatusKind; } });
Object.defineProperty(exports, "RTTI_Encounter_Participant", { enumerable: true, get: function () { return Resource_1.RTTI_Encounter_Participant; } });
Object.defineProperty(exports, "RTTI_Encounter_StatusHistory", { enumerable: true, get: function () { return Resource_1.RTTI_Encounter_StatusHistory; } });
Object.defineProperty(exports, "Encounter_StatusHistoryStatusKind", { enumerable: true, get: function () { return Resource_1.Encounter_StatusHistoryStatusKind; } });
Object.defineProperty(exports, "RTTI_Encounter", { enumerable: true, get: function () { return Resource_1.RTTI_Encounter; } });
Object.defineProperty(exports, "EncounterStatusKind", { enumerable: true, get: function () { return Resource_1.EncounterStatusKind; } });
Object.defineProperty(exports, "RTTI_Endpoint", { enumerable: true, get: function () { return Resource_1.RTTI_Endpoint; } });
Object.defineProperty(exports, "EndpointStatusKind", { enumerable: true, get: function () { return Resource_1.EndpointStatusKind; } });
Object.defineProperty(exports, "RTTI_EnrollmentRequest", { enumerable: true, get: function () { return Resource_1.RTTI_EnrollmentRequest; } });
Object.defineProperty(exports, "RTTI_EnrollmentResponse", { enumerable: true, get: function () { return Resource_1.RTTI_EnrollmentResponse; } });
Object.defineProperty(exports, "EnrollmentResponseOutcomeKind", { enumerable: true, get: function () { return Resource_1.EnrollmentResponseOutcomeKind; } });
Object.defineProperty(exports, "RTTI_EpisodeOfCare_Diagnosis", { enumerable: true, get: function () { return Resource_1.RTTI_EpisodeOfCare_Diagnosis; } });
Object.defineProperty(exports, "RTTI_EpisodeOfCare_StatusHistory", { enumerable: true, get: function () { return Resource_1.RTTI_EpisodeOfCare_StatusHistory; } });
Object.defineProperty(exports, "EpisodeOfCare_StatusHistoryStatusKind", { enumerable: true, get: function () { return Resource_1.EpisodeOfCare_StatusHistoryStatusKind; } });
Object.defineProperty(exports, "RTTI_EpisodeOfCare", { enumerable: true, get: function () { return Resource_1.RTTI_EpisodeOfCare; } });
Object.defineProperty(exports, "EpisodeOfCareStatusKind", { enumerable: true, get: function () { return Resource_1.EpisodeOfCareStatusKind; } });
Object.defineProperty(exports, "RTTI_EventDefinition", { enumerable: true, get: function () { return Resource_1.RTTI_EventDefinition; } });
Object.defineProperty(exports, "EventDefinitionStatusKind", { enumerable: true, get: function () { return Resource_1.EventDefinitionStatusKind; } });
Object.defineProperty(exports, "RTTI_Evidence", { enumerable: true, get: function () { return Resource_1.RTTI_Evidence; } });
Object.defineProperty(exports, "EvidenceStatusKind", { enumerable: true, get: function () { return Resource_1.EvidenceStatusKind; } });
Object.defineProperty(exports, "RTTI_EvidenceVariable_Characteristic", { enumerable: true, get: function () { return Resource_1.RTTI_EvidenceVariable_Characteristic; } });
Object.defineProperty(exports, "EvidenceVariable_CharacteristicGroupMeasureKind", { enumerable: true, get: function () { return Resource_1.EvidenceVariable_CharacteristicGroupMeasureKind; } });
Object.defineProperty(exports, "RTTI_EvidenceVariable", { enumerable: true, get: function () { return Resource_1.RTTI_EvidenceVariable; } });
Object.defineProperty(exports, "EvidenceVariableStatusKind", { enumerable: true, get: function () { return Resource_1.EvidenceVariableStatusKind; } });
Object.defineProperty(exports, "EvidenceVariableTypeKind", { enumerable: true, get: function () { return Resource_1.EvidenceVariableTypeKind; } });
Object.defineProperty(exports, "RTTI_ExampleScenario_Actor", { enumerable: true, get: function () { return Resource_1.RTTI_ExampleScenario_Actor; } });
Object.defineProperty(exports, "ExampleScenario_ActorTypeKind", { enumerable: true, get: function () { return Resource_1.ExampleScenario_ActorTypeKind; } });
Object.defineProperty(exports, "RTTI_ExampleScenario_Alternative", { enumerable: true, get: function () { return Resource_1.RTTI_ExampleScenario_Alternative; } });
Object.defineProperty(exports, "RTTI_ExampleScenario_ContainedInstance", { enumerable: true, get: function () { return Resource_1.RTTI_ExampleScenario_ContainedInstance; } });
Object.defineProperty(exports, "RTTI_ExampleScenario_Instance", { enumerable: true, get: function () { return Resource_1.RTTI_ExampleScenario_Instance; } });
Object.defineProperty(exports, "RTTI_ExampleScenario_Operation", { enumerable: true, get: function () { return Resource_1.RTTI_ExampleScenario_Operation; } });
Object.defineProperty(exports, "RTTI_ExampleScenario_Process", { enumerable: true, get: function () { return Resource_1.RTTI_ExampleScenario_Process; } });
Object.defineProperty(exports, "RTTI_ExampleScenario_Step", { enumerable: true, get: function () { return Resource_1.RTTI_ExampleScenario_Step; } });
Object.defineProperty(exports, "RTTI_ExampleScenario", { enumerable: true, get: function () { return Resource_1.RTTI_ExampleScenario; } });
Object.defineProperty(exports, "ExampleScenarioStatusKind", { enumerable: true, get: function () { return Resource_1.ExampleScenarioStatusKind; } });
Object.defineProperty(exports, "RTTI_ExampleScenario_Version", { enumerable: true, get: function () { return Resource_1.RTTI_ExampleScenario_Version; } });
Object.defineProperty(exports, "RTTI_ExplanationOfBenefit_Accident", { enumerable: true, get: function () { return Resource_1.RTTI_ExplanationOfBenefit_Accident; } });
Object.defineProperty(exports, "RTTI_ExplanationOfBenefit_AddItem", { enumerable: true, get: function () { return Resource_1.RTTI_ExplanationOfBenefit_AddItem; } });
Object.defineProperty(exports, "RTTI_ExplanationOfBenefit_Adjudication", { enumerable: true, get: function () { return Resource_1.RTTI_ExplanationOfBenefit_Adjudication; } });
Object.defineProperty(exports, "RTTI_ExplanationOfBenefit_BenefitBalance", { enumerable: true, get: function () { return Resource_1.RTTI_ExplanationOfBenefit_BenefitBalance; } });
Object.defineProperty(exports, "RTTI_ExplanationOfBenefit_CareTeam", { enumerable: true, get: function () { return Resource_1.RTTI_ExplanationOfBenefit_CareTeam; } });
Object.defineProperty(exports, "RTTI_ExplanationOfBenefit_Detail1", { enumerable: true, get: function () { return Resource_1.RTTI_ExplanationOfBenefit_Detail1; } });
Object.defineProperty(exports, "RTTI_ExplanationOfBenefit_Detail", { enumerable: true, get: function () { return Resource_1.RTTI_ExplanationOfBenefit_Detail; } });
Object.defineProperty(exports, "RTTI_ExplanationOfBenefit_Diagnosis", { enumerable: true, get: function () { return Resource_1.RTTI_ExplanationOfBenefit_Diagnosis; } });
Object.defineProperty(exports, "RTTI_ExplanationOfBenefit_Financial", { enumerable: true, get: function () { return Resource_1.RTTI_ExplanationOfBenefit_Financial; } });
Object.defineProperty(exports, "RTTI_ExplanationOfBenefit_Insurance", { enumerable: true, get: function () { return Resource_1.RTTI_ExplanationOfBenefit_Insurance; } });
Object.defineProperty(exports, "RTTI_ExplanationOfBenefit_Item", { enumerable: true, get: function () { return Resource_1.RTTI_ExplanationOfBenefit_Item; } });
Object.defineProperty(exports, "RTTI_ExplanationOfBenefit_Payee", { enumerable: true, get: function () { return Resource_1.RTTI_ExplanationOfBenefit_Payee; } });
Object.defineProperty(exports, "RTTI_ExplanationOfBenefit_Payment", { enumerable: true, get: function () { return Resource_1.RTTI_ExplanationOfBenefit_Payment; } });
Object.defineProperty(exports, "RTTI_ExplanationOfBenefit_Procedure", { enumerable: true, get: function () { return Resource_1.RTTI_ExplanationOfBenefit_Procedure; } });
Object.defineProperty(exports, "RTTI_ExplanationOfBenefit_ProcessNote", { enumerable: true, get: function () { return Resource_1.RTTI_ExplanationOfBenefit_ProcessNote; } });
Object.defineProperty(exports, "ExplanationOfBenefit_ProcessNoteTypeKind", { enumerable: true, get: function () { return Resource_1.ExplanationOfBenefit_ProcessNoteTypeKind; } });
Object.defineProperty(exports, "RTTI_ExplanationOfBenefit_Related", { enumerable: true, get: function () { return Resource_1.RTTI_ExplanationOfBenefit_Related; } });
Object.defineProperty(exports, "RTTI_ExplanationOfBenefit_SubDetail1", { enumerable: true, get: function () { return Resource_1.RTTI_ExplanationOfBenefit_SubDetail1; } });
Object.defineProperty(exports, "RTTI_ExplanationOfBenefit_SubDetail", { enumerable: true, get: function () { return Resource_1.RTTI_ExplanationOfBenefit_SubDetail; } });
Object.defineProperty(exports, "RTTI_ExplanationOfBenefit_SupportingInfo", { enumerable: true, get: function () { return Resource_1.RTTI_ExplanationOfBenefit_SupportingInfo; } });
Object.defineProperty(exports, "RTTI_ExplanationOfBenefit_Total", { enumerable: true, get: function () { return Resource_1.RTTI_ExplanationOfBenefit_Total; } });
Object.defineProperty(exports, "RTTI_ExplanationOfBenefit", { enumerable: true, get: function () { return Resource_1.RTTI_ExplanationOfBenefit; } });
Object.defineProperty(exports, "ExplanationOfBenefitStatusKind", { enumerable: true, get: function () { return Resource_1.ExplanationOfBenefitStatusKind; } });
Object.defineProperty(exports, "RTTI_Expression", { enumerable: true, get: function () { return Resource_1.RTTI_Expression; } });
Object.defineProperty(exports, "ExpressionLanguageKind", { enumerable: true, get: function () { return Resource_1.ExpressionLanguageKind; } });
Object.defineProperty(exports, "RTTI_Extension", { enumerable: true, get: function () { return Resource_1.RTTI_Extension; } });
Object.defineProperty(exports, "RTTI_FamilyMemberHistory_Condition", { enumerable: true, get: function () { return Resource_1.RTTI_FamilyMemberHistory_Condition; } });
Object.defineProperty(exports, "RTTI_FamilyMemberHistory", { enumerable: true, get: function () { return Resource_1.RTTI_FamilyMemberHistory; } });
Object.defineProperty(exports, "FamilyMemberHistoryStatusKind", { enumerable: true, get: function () { return Resource_1.FamilyMemberHistoryStatusKind; } });
Object.defineProperty(exports, "RTTI_Flag", { enumerable: true, get: function () { return Resource_1.RTTI_Flag; } });
Object.defineProperty(exports, "FlagStatusKind", { enumerable: true, get: function () { return Resource_1.FlagStatusKind; } });
Object.defineProperty(exports, "RTTI_Goal_Target", { enumerable: true, get: function () { return Resource_1.RTTI_Goal_Target; } });
Object.defineProperty(exports, "RTTI_Goal", { enumerable: true, get: function () { return Resource_1.RTTI_Goal; } });
Object.defineProperty(exports, "GoalLifecycleStatusKind", { enumerable: true, get: function () { return Resource_1.GoalLifecycleStatusKind; } });
Object.defineProperty(exports, "RTTI_GraphDefinition_Compartment", { enumerable: true, get: function () { return Resource_1.RTTI_GraphDefinition_Compartment; } });
Object.defineProperty(exports, "GraphDefinition_CompartmentRuleKind", { enumerable: true, get: function () { return Resource_1.GraphDefinition_CompartmentRuleKind; } });
Object.defineProperty(exports, "GraphDefinition_CompartmentUseKind", { enumerable: true, get: function () { return Resource_1.GraphDefinition_CompartmentUseKind; } });
Object.defineProperty(exports, "RTTI_GraphDefinition_Link", { enumerable: true, get: function () { return Resource_1.RTTI_GraphDefinition_Link; } });
Object.defineProperty(exports, "RTTI_GraphDefinition_Target", { enumerable: true, get: function () { return Resource_1.RTTI_GraphDefinition_Target; } });
Object.defineProperty(exports, "RTTI_GraphDefinition", { enumerable: true, get: function () { return Resource_1.RTTI_GraphDefinition; } });
Object.defineProperty(exports, "GraphDefinitionStatusKind", { enumerable: true, get: function () { return Resource_1.GraphDefinitionStatusKind; } });
Object.defineProperty(exports, "RTTI_Group_Characteristic", { enumerable: true, get: function () { return Resource_1.RTTI_Group_Characteristic; } });
Object.defineProperty(exports, "RTTI_Group_Member", { enumerable: true, get: function () { return Resource_1.RTTI_Group_Member; } });
Object.defineProperty(exports, "RTTI_Group", { enumerable: true, get: function () { return Resource_1.RTTI_Group; } });
Object.defineProperty(exports, "GroupTypeKind", { enumerable: true, get: function () { return Resource_1.GroupTypeKind; } });
Object.defineProperty(exports, "RTTI_GuidanceResponse", { enumerable: true, get: function () { return Resource_1.RTTI_GuidanceResponse; } });
Object.defineProperty(exports, "GuidanceResponseStatusKind", { enumerable: true, get: function () { return Resource_1.GuidanceResponseStatusKind; } });
Object.defineProperty(exports, "RTTI_HealthcareService_AvailableTime", { enumerable: true, get: function () { return Resource_1.RTTI_HealthcareService_AvailableTime; } });
Object.defineProperty(exports, "HealthcareService_AvailableTimeDaysOfWeekKind", { enumerable: true, get: function () { return Resource_1.HealthcareService_AvailableTimeDaysOfWeekKind; } });
Object.defineProperty(exports, "RTTI_HealthcareService_Eligibility", { enumerable: true, get: function () { return Resource_1.RTTI_HealthcareService_Eligibility; } });
Object.defineProperty(exports, "RTTI_HealthcareService_NotAvailable", { enumerable: true, get: function () { return Resource_1.RTTI_HealthcareService_NotAvailable; } });
Object.defineProperty(exports, "RTTI_HealthcareService", { enumerable: true, get: function () { return Resource_1.RTTI_HealthcareService; } });
Object.defineProperty(exports, "RTTI_HumanName", { enumerable: true, get: function () { return Resource_1.RTTI_HumanName; } });
Object.defineProperty(exports, "HumanNameUseKind", { enumerable: true, get: function () { return Resource_1.HumanNameUseKind; } });
Object.defineProperty(exports, "RTTI_Identifier", { enumerable: true, get: function () { return Resource_1.RTTI_Identifier; } });
Object.defineProperty(exports, "IdentifierUseKind", { enumerable: true, get: function () { return Resource_1.IdentifierUseKind; } });
Object.defineProperty(exports, "RTTI_ImagingStudy_Instance", { enumerable: true, get: function () { return Resource_1.RTTI_ImagingStudy_Instance; } });
Object.defineProperty(exports, "RTTI_ImagingStudy_Performer", { enumerable: true, get: function () { return Resource_1.RTTI_ImagingStudy_Performer; } });
Object.defineProperty(exports, "RTTI_ImagingStudy_Series", { enumerable: true, get: function () { return Resource_1.RTTI_ImagingStudy_Series; } });
Object.defineProperty(exports, "RTTI_ImagingStudy", { enumerable: true, get: function () { return Resource_1.RTTI_ImagingStudy; } });
Object.defineProperty(exports, "ImagingStudyStatusKind", { enumerable: true, get: function () { return Resource_1.ImagingStudyStatusKind; } });
Object.defineProperty(exports, "RTTI_Immunization_Education", { enumerable: true, get: function () { return Resource_1.RTTI_Immunization_Education; } });
Object.defineProperty(exports, "RTTI_ImmunizationEvaluation", { enumerable: true, get: function () { return Resource_1.RTTI_ImmunizationEvaluation; } });
Object.defineProperty(exports, "RTTI_Immunization_Performer", { enumerable: true, get: function () { return Resource_1.RTTI_Immunization_Performer; } });
Object.defineProperty(exports, "RTTI_Immunization_ProtocolApplied", { enumerable: true, get: function () { return Resource_1.RTTI_Immunization_ProtocolApplied; } });
Object.defineProperty(exports, "RTTI_Immunization_Reaction", { enumerable: true, get: function () { return Resource_1.RTTI_Immunization_Reaction; } });
Object.defineProperty(exports, "RTTI_ImmunizationRecommendation_DateCriterion", { enumerable: true, get: function () { return Resource_1.RTTI_ImmunizationRecommendation_DateCriterion; } });
Object.defineProperty(exports, "RTTI_ImmunizationRecommendation_Recommendation", { enumerable: true, get: function () { return Resource_1.RTTI_ImmunizationRecommendation_Recommendation; } });
Object.defineProperty(exports, "RTTI_ImmunizationRecommendation", { enumerable: true, get: function () { return Resource_1.RTTI_ImmunizationRecommendation; } });
Object.defineProperty(exports, "RTTI_Immunization", { enumerable: true, get: function () { return Resource_1.RTTI_Immunization; } });
Object.defineProperty(exports, "RTTI_ImplementationGuide_Definition", { enumerable: true, get: function () { return Resource_1.RTTI_ImplementationGuide_Definition; } });
Object.defineProperty(exports, "RTTI_ImplementationGuide_DependsOn", { enumerable: true, get: function () { return Resource_1.RTTI_ImplementationGuide_DependsOn; } });
Object.defineProperty(exports, "RTTI_ImplementationGuide_Global", { enumerable: true, get: function () { return Resource_1.RTTI_ImplementationGuide_Global; } });
Object.defineProperty(exports, "RTTI_ImplementationGuide_Grouping", { enumerable: true, get: function () { return Resource_1.RTTI_ImplementationGuide_Grouping; } });
Object.defineProperty(exports, "RTTI_ImplementationGuide_Manifest", { enumerable: true, get: function () { return Resource_1.RTTI_ImplementationGuide_Manifest; } });
Object.defineProperty(exports, "RTTI_ImplementationGuide_Page1", { enumerable: true, get: function () { return Resource_1.RTTI_ImplementationGuide_Page1; } });
Object.defineProperty(exports, "RTTI_ImplementationGuide_Page", { enumerable: true, get: function () { return Resource_1.RTTI_ImplementationGuide_Page; } });
Object.defineProperty(exports, "ImplementationGuide_PageGenerationKind", { enumerable: true, get: function () { return Resource_1.ImplementationGuide_PageGenerationKind; } });
Object.defineProperty(exports, "RTTI_ImplementationGuide_Parameter", { enumerable: true, get: function () { return Resource_1.RTTI_ImplementationGuide_Parameter; } });
Object.defineProperty(exports, "ImplementationGuide_ParameterCodeKind", { enumerable: true, get: function () { return Resource_1.ImplementationGuide_ParameterCodeKind; } });
Object.defineProperty(exports, "RTTI_ImplementationGuide_Resource1", { enumerable: true, get: function () { return Resource_1.RTTI_ImplementationGuide_Resource1; } });
Object.defineProperty(exports, "RTTI_ImplementationGuide_Resource", { enumerable: true, get: function () { return Resource_1.RTTI_ImplementationGuide_Resource; } });
Object.defineProperty(exports, "ImplementationGuide_ResourceFhirVersionKind", { enumerable: true, get: function () { return Resource_1.ImplementationGuide_ResourceFhirVersionKind; } });
Object.defineProperty(exports, "RTTI_ImplementationGuide_Template", { enumerable: true, get: function () { return Resource_1.RTTI_ImplementationGuide_Template; } });
Object.defineProperty(exports, "RTTI_ImplementationGuide", { enumerable: true, get: function () { return Resource_1.RTTI_ImplementationGuide; } });
Object.defineProperty(exports, "ImplementationGuideFhirVersionKind", { enumerable: true, get: function () { return Resource_1.ImplementationGuideFhirVersionKind; } });
Object.defineProperty(exports, "ImplementationGuideStatusKind", { enumerable: true, get: function () { return Resource_1.ImplementationGuideStatusKind; } });
Object.defineProperty(exports, "ImplementationGuideLicenseKind", { enumerable: true, get: function () { return Resource_1.ImplementationGuideLicenseKind; } });
Object.defineProperty(exports, "RTTI_InsurancePlan_Benefit1", { enumerable: true, get: function () { return Resource_1.RTTI_InsurancePlan_Benefit1; } });
Object.defineProperty(exports, "RTTI_InsurancePlan_Benefit", { enumerable: true, get: function () { return Resource_1.RTTI_InsurancePlan_Benefit; } });
Object.defineProperty(exports, "RTTI_InsurancePlan_Contact", { enumerable: true, get: function () { return Resource_1.RTTI_InsurancePlan_Contact; } });
Object.defineProperty(exports, "RTTI_InsurancePlan_Cost", { enumerable: true, get: function () { return Resource_1.RTTI_InsurancePlan_Cost; } });
Object.defineProperty(exports, "RTTI_InsurancePlan_Coverage", { enumerable: true, get: function () { return Resource_1.RTTI_InsurancePlan_Coverage; } });
Object.defineProperty(exports, "RTTI_InsurancePlan_GeneralCost", { enumerable: true, get: function () { return Resource_1.RTTI_InsurancePlan_GeneralCost; } });
Object.defineProperty(exports, "RTTI_InsurancePlan_Limit", { enumerable: true, get: function () { return Resource_1.RTTI_InsurancePlan_Limit; } });
Object.defineProperty(exports, "RTTI_InsurancePlan_Plan", { enumerable: true, get: function () { return Resource_1.RTTI_InsurancePlan_Plan; } });
Object.defineProperty(exports, "RTTI_InsurancePlan_SpecificCost", { enumerable: true, get: function () { return Resource_1.RTTI_InsurancePlan_SpecificCost; } });
Object.defineProperty(exports, "RTTI_InsurancePlan", { enumerable: true, get: function () { return Resource_1.RTTI_InsurancePlan; } });
Object.defineProperty(exports, "InsurancePlanStatusKind", { enumerable: true, get: function () { return Resource_1.InsurancePlanStatusKind; } });
Object.defineProperty(exports, "RTTI_Invoice_LineItem", { enumerable: true, get: function () { return Resource_1.RTTI_Invoice_LineItem; } });
Object.defineProperty(exports, "RTTI_Invoice_Participant", { enumerable: true, get: function () { return Resource_1.RTTI_Invoice_Participant; } });
Object.defineProperty(exports, "RTTI_Invoice_PriceComponent", { enumerable: true, get: function () { return Resource_1.RTTI_Invoice_PriceComponent; } });
Object.defineProperty(exports, "Invoice_PriceComponentTypeKind", { enumerable: true, get: function () { return Resource_1.Invoice_PriceComponentTypeKind; } });
Object.defineProperty(exports, "RTTI_Invoice", { enumerable: true, get: function () { return Resource_1.RTTI_Invoice; } });
Object.defineProperty(exports, "InvoiceStatusKind", { enumerable: true, get: function () { return Resource_1.InvoiceStatusKind; } });
Object.defineProperty(exports, "RTTI_Library", { enumerable: true, get: function () { return Resource_1.RTTI_Library; } });
Object.defineProperty(exports, "LibraryStatusKind", { enumerable: true, get: function () { return Resource_1.LibraryStatusKind; } });
Object.defineProperty(exports, "RTTI_Linkage_Item", { enumerable: true, get: function () { return Resource_1.RTTI_Linkage_Item; } });
Object.defineProperty(exports, "Linkage_ItemTypeKind", { enumerable: true, get: function () { return Resource_1.Linkage_ItemTypeKind; } });
Object.defineProperty(exports, "RTTI_Linkage", { enumerable: true, get: function () { return Resource_1.RTTI_Linkage; } });
Object.defineProperty(exports, "RTTI_List_Entry", { enumerable: true, get: function () { return Resource_1.RTTI_List_Entry; } });
Object.defineProperty(exports, "RTTI_List", { enumerable: true, get: function () { return Resource_1.RTTI_List; } });
Object.defineProperty(exports, "ListModeKind", { enumerable: true, get: function () { return Resource_1.ListModeKind; } });
Object.defineProperty(exports, "ListStatusKind", { enumerable: true, get: function () { return Resource_1.ListStatusKind; } });
Object.defineProperty(exports, "RTTI_Location_HoursOfOperation", { enumerable: true, get: function () { return Resource_1.RTTI_Location_HoursOfOperation; } });
Object.defineProperty(exports, "RTTI_Location_Position", { enumerable: true, get: function () { return Resource_1.RTTI_Location_Position; } });
Object.defineProperty(exports, "RTTI_Location", { enumerable: true, get: function () { return Resource_1.RTTI_Location; } });
Object.defineProperty(exports, "LocationModeKind", { enumerable: true, get: function () { return Resource_1.LocationModeKind; } });
Object.defineProperty(exports, "LocationStatusKind", { enumerable: true, get: function () { return Resource_1.LocationStatusKind; } });
Object.defineProperty(exports, "RTTI_MarketingStatus", { enumerable: true, get: function () { return Resource_1.RTTI_MarketingStatus; } });
Object.defineProperty(exports, "RTTI_Measure_Component", { enumerable: true, get: function () { return Resource_1.RTTI_Measure_Component; } });
Object.defineProperty(exports, "RTTI_Measure_Group", { enumerable: true, get: function () { return Resource_1.RTTI_Measure_Group; } });
Object.defineProperty(exports, "RTTI_Measure_Population", { enumerable: true, get: function () { return Resource_1.RTTI_Measure_Population; } });
Object.defineProperty(exports, "RTTI_MeasureReport_Component", { enumerable: true, get: function () { return Resource_1.RTTI_MeasureReport_Component; } });
Object.defineProperty(exports, "RTTI_MeasureReport_Group", { enumerable: true, get: function () { return Resource_1.RTTI_MeasureReport_Group; } });
Object.defineProperty(exports, "RTTI_MeasureReport_Population1", { enumerable: true, get: function () { return Resource_1.RTTI_MeasureReport_Population1; } });
Object.defineProperty(exports, "RTTI_MeasureReport_Population", { enumerable: true, get: function () { return Resource_1.RTTI_MeasureReport_Population; } });
Object.defineProperty(exports, "RTTI_MeasureReport_Stratifier", { enumerable: true, get: function () { return Resource_1.RTTI_MeasureReport_Stratifier; } });
Object.defineProperty(exports, "RTTI_MeasureReport_Stratum", { enumerable: true, get: function () { return Resource_1.RTTI_MeasureReport_Stratum; } });
Object.defineProperty(exports, "RTTI_MeasureReport", { enumerable: true, get: function () { return Resource_1.RTTI_MeasureReport; } });
Object.defineProperty(exports, "MeasureReportStatusKind", { enumerable: true, get: function () { return Resource_1.MeasureReportStatusKind; } });
Object.defineProperty(exports, "MeasureReportTypeKind", { enumerable: true, get: function () { return Resource_1.MeasureReportTypeKind; } });
Object.defineProperty(exports, "RTTI_Measure_Stratifier", { enumerable: true, get: function () { return Resource_1.RTTI_Measure_Stratifier; } });
Object.defineProperty(exports, "RTTI_Measure_SupplementalData", { enumerable: true, get: function () { return Resource_1.RTTI_Measure_SupplementalData; } });
Object.defineProperty(exports, "RTTI_Measure", { enumerable: true, get: function () { return Resource_1.RTTI_Measure; } });
Object.defineProperty(exports, "MeasureStatusKind", { enumerable: true, get: function () { return Resource_1.MeasureStatusKind; } });
Object.defineProperty(exports, "RTTI_Media", { enumerable: true, get: function () { return Resource_1.RTTI_Media; } });
Object.defineProperty(exports, "RTTI_MedicationAdministration_Dosage", { enumerable: true, get: function () { return Resource_1.RTTI_MedicationAdministration_Dosage; } });
Object.defineProperty(exports, "RTTI_MedicationAdministration_Performer", { enumerable: true, get: function () { return Resource_1.RTTI_MedicationAdministration_Performer; } });
Object.defineProperty(exports, "RTTI_MedicationAdministration", { enumerable: true, get: function () { return Resource_1.RTTI_MedicationAdministration; } });
Object.defineProperty(exports, "RTTI_Medication_Batch", { enumerable: true, get: function () { return Resource_1.RTTI_Medication_Batch; } });
Object.defineProperty(exports, "RTTI_MedicationDispense_Performer", { enumerable: true, get: function () { return Resource_1.RTTI_MedicationDispense_Performer; } });
Object.defineProperty(exports, "RTTI_MedicationDispense_Substitution", { enumerable: true, get: function () { return Resource_1.RTTI_MedicationDispense_Substitution; } });
Object.defineProperty(exports, "RTTI_MedicationDispense", { enumerable: true, get: function () { return Resource_1.RTTI_MedicationDispense; } });
Object.defineProperty(exports, "RTTI_Medication_Ingredient", { enumerable: true, get: function () { return Resource_1.RTTI_Medication_Ingredient; } });
Object.defineProperty(exports, "RTTI_MedicationKnowledge_AdministrationGuidelines", { enumerable: true, get: function () { return Resource_1.RTTI_MedicationKnowledge_AdministrationGuidelines; } });
Object.defineProperty(exports, "RTTI_MedicationKnowledge_Cost", { enumerable: true, get: function () { return Resource_1.RTTI_MedicationKnowledge_Cost; } });
Object.defineProperty(exports, "RTTI_MedicationKnowledge_Dosage", { enumerable: true, get: function () { return Resource_1.RTTI_MedicationKnowledge_Dosage; } });
Object.defineProperty(exports, "RTTI_MedicationKnowledge_DrugCharacteristic", { enumerable: true, get: function () { return Resource_1.RTTI_MedicationKnowledge_DrugCharacteristic; } });
Object.defineProperty(exports, "RTTI_MedicationKnowledge_Ingredient", { enumerable: true, get: function () { return Resource_1.RTTI_MedicationKnowledge_Ingredient; } });
Object.defineProperty(exports, "RTTI_MedicationKnowledge_Kinetics", { enumerable: true, get: function () { return Resource_1.RTTI_MedicationKnowledge_Kinetics; } });
Object.defineProperty(exports, "RTTI_MedicationKnowledge_MaxDispense", { enumerable: true, get: function () { return Resource_1.RTTI_MedicationKnowledge_MaxDispense; } });
Object.defineProperty(exports, "RTTI_MedicationKnowledge_MedicineClassification", { enumerable: true, get: function () { return Resource_1.RTTI_MedicationKnowledge_MedicineClassification; } });
Object.defineProperty(exports, "RTTI_MedicationKnowledge_MonitoringProgram", { enumerable: true, get: function () { return Resource_1.RTTI_MedicationKnowledge_MonitoringProgram; } });
Object.defineProperty(exports, "RTTI_MedicationKnowledge_Monograph", { enumerable: true, get: function () { return Resource_1.RTTI_MedicationKnowledge_Monograph; } });
Object.defineProperty(exports, "RTTI_MedicationKnowledge_Packaging", { enumerable: true, get: function () { return Resource_1.RTTI_MedicationKnowledge_Packaging; } });
Object.defineProperty(exports, "RTTI_MedicationKnowledge_PatientCharacteristics", { enumerable: true, get: function () { return Resource_1.RTTI_MedicationKnowledge_PatientCharacteristics; } });
Object.defineProperty(exports, "RTTI_MedicationKnowledge_Regulatory", { enumerable: true, get: function () { return Resource_1.RTTI_MedicationKnowledge_Regulatory; } });
Object.defineProperty(exports, "RTTI_MedicationKnowledge_RelatedMedicationKnowledge", { enumerable: true, get: function () { return Resource_1.RTTI_MedicationKnowledge_RelatedMedicationKnowledge; } });
Object.defineProperty(exports, "RTTI_MedicationKnowledge_Schedule", { enumerable: true, get: function () { return Resource_1.RTTI_MedicationKnowledge_Schedule; } });
Object.defineProperty(exports, "RTTI_MedicationKnowledge_Substitution", { enumerable: true, get: function () { return Resource_1.RTTI_MedicationKnowledge_Substitution; } });
Object.defineProperty(exports, "RTTI_MedicationKnowledge", { enumerable: true, get: function () { return Resource_1.RTTI_MedicationKnowledge; } });
Object.defineProperty(exports, "RTTI_MedicationRequest_DispenseRequest", { enumerable: true, get: function () { return Resource_1.RTTI_MedicationRequest_DispenseRequest; } });
Object.defineProperty(exports, "RTTI_MedicationRequest_InitialFill", { enumerable: true, get: function () { return Resource_1.RTTI_MedicationRequest_InitialFill; } });
Object.defineProperty(exports, "RTTI_MedicationRequest_Substitution", { enumerable: true, get: function () { return Resource_1.RTTI_MedicationRequest_Substitution; } });
Object.defineProperty(exports, "RTTI_MedicationRequest", { enumerable: true, get: function () { return Resource_1.RTTI_MedicationRequest; } });
Object.defineProperty(exports, "RTTI_MedicationStatement", { enumerable: true, get: function () { return Resource_1.RTTI_MedicationStatement; } });
Object.defineProperty(exports, "RTTI_Medication", { enumerable: true, get: function () { return Resource_1.RTTI_Medication; } });
Object.defineProperty(exports, "RTTI_MedicinalProductAuthorization_JurisdictionalAuthorization", { enumerable: true, get: function () { return Resource_1.RTTI_MedicinalProductAuthorization_JurisdictionalAuthorization; } });
Object.defineProperty(exports, "RTTI_MedicinalProductAuthorization_Procedure", { enumerable: true, get: function () { return Resource_1.RTTI_MedicinalProductAuthorization_Procedure; } });
Object.defineProperty(exports, "RTTI_MedicinalProductAuthorization", { enumerable: true, get: function () { return Resource_1.RTTI_MedicinalProductAuthorization; } });
Object.defineProperty(exports, "RTTI_MedicinalProductContraindication_OtherTherapy", { enumerable: true, get: function () { return Resource_1.RTTI_MedicinalProductContraindication_OtherTherapy; } });
Object.defineProperty(exports, "RTTI_MedicinalProductContraindication", { enumerable: true, get: function () { return Resource_1.RTTI_MedicinalProductContraindication; } });
Object.defineProperty(exports, "RTTI_MedicinalProduct_CountryLanguage", { enumerable: true, get: function () { return Resource_1.RTTI_MedicinalProduct_CountryLanguage; } });
Object.defineProperty(exports, "RTTI_MedicinalProductIndication_OtherTherapy", { enumerable: true, get: function () { return Resource_1.RTTI_MedicinalProductIndication_OtherTherapy; } });
Object.defineProperty(exports, "RTTI_MedicinalProductIndication", { enumerable: true, get: function () { return Resource_1.RTTI_MedicinalProductIndication; } });
Object.defineProperty(exports, "RTTI_MedicinalProductIngredient_ReferenceStrength", { enumerable: true, get: function () { return Resource_1.RTTI_MedicinalProductIngredient_ReferenceStrength; } });
Object.defineProperty(exports, "RTTI_MedicinalProductIngredient_SpecifiedSubstance", { enumerable: true, get: function () { return Resource_1.RTTI_MedicinalProductIngredient_SpecifiedSubstance; } });
Object.defineProperty(exports, "RTTI_MedicinalProductIngredient_Strength", { enumerable: true, get: function () { return Resource_1.RTTI_MedicinalProductIngredient_Strength; } });
Object.defineProperty(exports, "RTTI_MedicinalProductIngredient_Substance", { enumerable: true, get: function () { return Resource_1.RTTI_MedicinalProductIngredient_Substance; } });
Object.defineProperty(exports, "RTTI_MedicinalProductIngredient", { enumerable: true, get: function () { return Resource_1.RTTI_MedicinalProductIngredient; } });
Object.defineProperty(exports, "RTTI_MedicinalProductInteraction_Interactant", { enumerable: true, get: function () { return Resource_1.RTTI_MedicinalProductInteraction_Interactant; } });
Object.defineProperty(exports, "RTTI_MedicinalProductInteraction", { enumerable: true, get: function () { return Resource_1.RTTI_MedicinalProductInteraction; } });
Object.defineProperty(exports, "RTTI_MedicinalProductManufactured", { enumerable: true, get: function () { return Resource_1.RTTI_MedicinalProductManufactured; } });
Object.defineProperty(exports, "RTTI_MedicinalProduct_ManufacturingBusinessOperation", { enumerable: true, get: function () { return Resource_1.RTTI_MedicinalProduct_ManufacturingBusinessOperation; } });
Object.defineProperty(exports, "RTTI_MedicinalProduct_NamePart", { enumerable: true, get: function () { return Resource_1.RTTI_MedicinalProduct_NamePart; } });
Object.defineProperty(exports, "RTTI_MedicinalProduct_Name", { enumerable: true, get: function () { return Resource_1.RTTI_MedicinalProduct_Name; } });
Object.defineProperty(exports, "RTTI_MedicinalProductPackaged_BatchIdentifier", { enumerable: true, get: function () { return Resource_1.RTTI_MedicinalProductPackaged_BatchIdentifier; } });
Object.defineProperty(exports, "RTTI_MedicinalProductPackaged_PackageItem", { enumerable: true, get: function () { return Resource_1.RTTI_MedicinalProductPackaged_PackageItem; } });
Object.defineProperty(exports, "RTTI_MedicinalProductPackaged", { enumerable: true, get: function () { return Resource_1.RTTI_MedicinalProductPackaged; } });
Object.defineProperty(exports, "RTTI_MedicinalProductPharmaceutical_Characteristics", { enumerable: true, get: function () { return Resource_1.RTTI_MedicinalProductPharmaceutical_Characteristics; } });
Object.defineProperty(exports, "RTTI_MedicinalProductPharmaceutical_RouteOfAdministration", { enumerable: true, get: function () { return Resource_1.RTTI_MedicinalProductPharmaceutical_RouteOfAdministration; } });
Object.defineProperty(exports, "RTTI_MedicinalProductPharmaceutical_TargetSpecies", { enumerable: true, get: function () { return Resource_1.RTTI_MedicinalProductPharmaceutical_TargetSpecies; } });
Object.defineProperty(exports, "RTTI_MedicinalProductPharmaceutical", { enumerable: true, get: function () { return Resource_1.RTTI_MedicinalProductPharmaceutical; } });
Object.defineProperty(exports, "RTTI_MedicinalProductPharmaceutical_WithdrawalPeriod", { enumerable: true, get: function () { return Resource_1.RTTI_MedicinalProductPharmaceutical_WithdrawalPeriod; } });
Object.defineProperty(exports, "RTTI_MedicinalProduct_SpecialDesignation", { enumerable: true, get: function () { return Resource_1.RTTI_MedicinalProduct_SpecialDesignation; } });
Object.defineProperty(exports, "RTTI_MedicinalProduct", { enumerable: true, get: function () { return Resource_1.RTTI_MedicinalProduct; } });
Object.defineProperty(exports, "RTTI_MedicinalProductUndesirableEffect", { enumerable: true, get: function () { return Resource_1.RTTI_MedicinalProductUndesirableEffect; } });
Object.defineProperty(exports, "RTTI_MessageDefinition_AllowedResponse", { enumerable: true, get: function () { return Resource_1.RTTI_MessageDefinition_AllowedResponse; } });
Object.defineProperty(exports, "RTTI_MessageDefinition_Focus", { enumerable: true, get: function () { return Resource_1.RTTI_MessageDefinition_Focus; } });
Object.defineProperty(exports, "RTTI_MessageDefinition", { enumerable: true, get: function () { return Resource_1.RTTI_MessageDefinition; } });
Object.defineProperty(exports, "MessageDefinitionCategoryKind", { enumerable: true, get: function () { return Resource_1.MessageDefinitionCategoryKind; } });
Object.defineProperty(exports, "MessageDefinitionResponseRequiredKind", { enumerable: true, get: function () { return Resource_1.MessageDefinitionResponseRequiredKind; } });
Object.defineProperty(exports, "MessageDefinitionStatusKind", { enumerable: true, get: function () { return Resource_1.MessageDefinitionStatusKind; } });
Object.defineProperty(exports, "RTTI_MessageHeader_Destination", { enumerable: true, get: function () { return Resource_1.RTTI_MessageHeader_Destination; } });
Object.defineProperty(exports, "RTTI_MessageHeader_Response", { enumerable: true, get: function () { return Resource_1.RTTI_MessageHeader_Response; } });
Object.defineProperty(exports, "MessageHeader_ResponseCodeKind", { enumerable: true, get: function () { return Resource_1.MessageHeader_ResponseCodeKind; } });
Object.defineProperty(exports, "RTTI_MessageHeader_Source", { enumerable: true, get: function () { return Resource_1.RTTI_MessageHeader_Source; } });
Object.defineProperty(exports, "RTTI_MessageHeader", { enumerable: true, get: function () { return Resource_1.RTTI_MessageHeader; } });
Object.defineProperty(exports, "RTTI_Meta", { enumerable: true, get: function () { return Resource_1.RTTI_Meta; } });
Object.defineProperty(exports, "RTTI_MolecularSequence_Inner", { enumerable: true, get: function () { return Resource_1.RTTI_MolecularSequence_Inner; } });
Object.defineProperty(exports, "RTTI_MolecularSequence_Outer", { enumerable: true, get: function () { return Resource_1.RTTI_MolecularSequence_Outer; } });
Object.defineProperty(exports, "RTTI_MolecularSequence_Quality", { enumerable: true, get: function () { return Resource_1.RTTI_MolecularSequence_Quality; } });
Object.defineProperty(exports, "MolecularSequence_QualityTypeKind", { enumerable: true, get: function () { return Resource_1.MolecularSequence_QualityTypeKind; } });
Object.defineProperty(exports, "RTTI_MolecularSequence_ReferenceSeq", { enumerable: true, get: function () { return Resource_1.RTTI_MolecularSequence_ReferenceSeq; } });
Object.defineProperty(exports, "MolecularSequence_ReferenceSeqOrientationKind", { enumerable: true, get: function () { return Resource_1.MolecularSequence_ReferenceSeqOrientationKind; } });
Object.defineProperty(exports, "MolecularSequence_ReferenceSeqStrandKind", { enumerable: true, get: function () { return Resource_1.MolecularSequence_ReferenceSeqStrandKind; } });
Object.defineProperty(exports, "RTTI_MolecularSequence_Repository", { enumerable: true, get: function () { return Resource_1.RTTI_MolecularSequence_Repository; } });
Object.defineProperty(exports, "MolecularSequence_RepositoryTypeKind", { enumerable: true, get: function () { return Resource_1.MolecularSequence_RepositoryTypeKind; } });
Object.defineProperty(exports, "RTTI_MolecularSequence_Roc", { enumerable: true, get: function () { return Resource_1.RTTI_MolecularSequence_Roc; } });
Object.defineProperty(exports, "RTTI_MolecularSequence_StructureVariant", { enumerable: true, get: function () { return Resource_1.RTTI_MolecularSequence_StructureVariant; } });
Object.defineProperty(exports, "RTTI_MolecularSequence", { enumerable: true, get: function () { return Resource_1.RTTI_MolecularSequence; } });
Object.defineProperty(exports, "MolecularSequenceTypeKind", { enumerable: true, get: function () { return Resource_1.MolecularSequenceTypeKind; } });
Object.defineProperty(exports, "RTTI_MolecularSequence_Variant", { enumerable: true, get: function () { return Resource_1.RTTI_MolecularSequence_Variant; } });
Object.defineProperty(exports, "RTTI_Money", { enumerable: true, get: function () { return Resource_1.RTTI_Money; } });
Object.defineProperty(exports, "RTTI_NamingSystem", { enumerable: true, get: function () { return Resource_1.RTTI_NamingSystem; } });
Object.defineProperty(exports, "NamingSystemKindKind", { enumerable: true, get: function () { return Resource_1.NamingSystemKindKind; } });
Object.defineProperty(exports, "NamingSystemStatusKind", { enumerable: true, get: function () { return Resource_1.NamingSystemStatusKind; } });
Object.defineProperty(exports, "RTTI_NamingSystem_UniqueId", { enumerable: true, get: function () { return Resource_1.RTTI_NamingSystem_UniqueId; } });
Object.defineProperty(exports, "NamingSystem_UniqueIdTypeKind", { enumerable: true, get: function () { return Resource_1.NamingSystem_UniqueIdTypeKind; } });
Object.defineProperty(exports, "RTTI_Narrative", { enumerable: true, get: function () { return Resource_1.RTTI_Narrative; } });
Object.defineProperty(exports, "NarrativeStatusKind", { enumerable: true, get: function () { return Resource_1.NarrativeStatusKind; } });
Object.defineProperty(exports, "RTTI_NutritionOrder_Administration", { enumerable: true, get: function () { return Resource_1.RTTI_NutritionOrder_Administration; } });
Object.defineProperty(exports, "RTTI_NutritionOrder_EnteralFormula", { enumerable: true, get: function () { return Resource_1.RTTI_NutritionOrder_EnteralFormula; } });
Object.defineProperty(exports, "RTTI_NutritionOrder_Nutrient", { enumerable: true, get: function () { return Resource_1.RTTI_NutritionOrder_Nutrient; } });
Object.defineProperty(exports, "RTTI_NutritionOrder_OralDiet", { enumerable: true, get: function () { return Resource_1.RTTI_NutritionOrder_OralDiet; } });
Object.defineProperty(exports, "RTTI_NutritionOrder_Supplement", { enumerable: true, get: function () { return Resource_1.RTTI_NutritionOrder_Supplement; } });
Object.defineProperty(exports, "RTTI_NutritionOrder_Texture", { enumerable: true, get: function () { return Resource_1.RTTI_NutritionOrder_Texture; } });
Object.defineProperty(exports, "RTTI_NutritionOrder", { enumerable: true, get: function () { return Resource_1.RTTI_NutritionOrder; } });
Object.defineProperty(exports, "RTTI_Observation_Component", { enumerable: true, get: function () { return Resource_1.RTTI_Observation_Component; } });
Object.defineProperty(exports, "RTTI_ObservationDefinition_QualifiedInterval", { enumerable: true, get: function () { return Resource_1.RTTI_ObservationDefinition_QualifiedInterval; } });
Object.defineProperty(exports, "ObservationDefinition_QualifiedIntervalCategoryKind", { enumerable: true, get: function () { return Resource_1.ObservationDefinition_QualifiedIntervalCategoryKind; } });
Object.defineProperty(exports, "ObservationDefinition_QualifiedIntervalGenderKind", { enumerable: true, get: function () { return Resource_1.ObservationDefinition_QualifiedIntervalGenderKind; } });
Object.defineProperty(exports, "RTTI_ObservationDefinition_QuantitativeDetails", { enumerable: true, get: function () { return Resource_1.RTTI_ObservationDefinition_QuantitativeDetails; } });
Object.defineProperty(exports, "RTTI_ObservationDefinition", { enumerable: true, get: function () { return Resource_1.RTTI_ObservationDefinition; } });
Object.defineProperty(exports, "ObservationDefinitionPermittedDataTypeKind", { enumerable: true, get: function () { return Resource_1.ObservationDefinitionPermittedDataTypeKind; } });
Object.defineProperty(exports, "RTTI_Observation_ReferenceRange", { enumerable: true, get: function () { return Resource_1.RTTI_Observation_ReferenceRange; } });
Object.defineProperty(exports, "RTTI_Observation", { enumerable: true, get: function () { return Resource_1.RTTI_Observation; } });
Object.defineProperty(exports, "ObservationStatusKind", { enumerable: true, get: function () { return Resource_1.ObservationStatusKind; } });
Object.defineProperty(exports, "RTTI_OperationDefinition_Binding", { enumerable: true, get: function () { return Resource_1.RTTI_OperationDefinition_Binding; } });
Object.defineProperty(exports, "OperationDefinition_BindingStrengthKind", { enumerable: true, get: function () { return Resource_1.OperationDefinition_BindingStrengthKind; } });
Object.defineProperty(exports, "RTTI_OperationDefinition_Overload", { enumerable: true, get: function () { return Resource_1.RTTI_OperationDefinition_Overload; } });
Object.defineProperty(exports, "RTTI_OperationDefinition_Parameter", { enumerable: true, get: function () { return Resource_1.RTTI_OperationDefinition_Parameter; } });
Object.defineProperty(exports, "OperationDefinition_ParameterSearchTypeKind", { enumerable: true, get: function () { return Resource_1.OperationDefinition_ParameterSearchTypeKind; } });
Object.defineProperty(exports, "OperationDefinition_ParameterUseKind", { enumerable: true, get: function () { return Resource_1.OperationDefinition_ParameterUseKind; } });
Object.defineProperty(exports, "RTTI_OperationDefinition_ReferencedFrom", { enumerable: true, get: function () { return Resource_1.RTTI_OperationDefinition_ReferencedFrom; } });
Object.defineProperty(exports, "RTTI_OperationDefinition", { enumerable: true, get: function () { return Resource_1.RTTI_OperationDefinition; } });
Object.defineProperty(exports, "OperationDefinitionKindKind", { enumerable: true, get: function () { return Resource_1.OperationDefinitionKindKind; } });
Object.defineProperty(exports, "OperationDefinitionStatusKind", { enumerable: true, get: function () { return Resource_1.OperationDefinitionStatusKind; } });
Object.defineProperty(exports, "RTTI_OperationOutcome_Issue", { enumerable: true, get: function () { return Resource_1.RTTI_OperationOutcome_Issue; } });
Object.defineProperty(exports, "OperationOutcome_IssueCodeKind", { enumerable: true, get: function () { return Resource_1.OperationOutcome_IssueCodeKind; } });
Object.defineProperty(exports, "OperationOutcome_IssueSeverityKind", { enumerable: true, get: function () { return Resource_1.OperationOutcome_IssueSeverityKind; } });
Object.defineProperty(exports, "RTTI_OperationOutcome", { enumerable: true, get: function () { return Resource_1.RTTI_OperationOutcome; } });
Object.defineProperty(exports, "RTTI_OrganizationAffiliation", { enumerable: true, get: function () { return Resource_1.RTTI_OrganizationAffiliation; } });
Object.defineProperty(exports, "RTTI_Organization_Contact", { enumerable: true, get: function () { return Resource_1.RTTI_Organization_Contact; } });
Object.defineProperty(exports, "RTTI_Organization", { enumerable: true, get: function () { return Resource_1.RTTI_Organization; } });
Object.defineProperty(exports, "RTTI_ParameterDefinition", { enumerable: true, get: function () { return Resource_1.RTTI_ParameterDefinition; } });
Object.defineProperty(exports, "RTTI_Parameters_Parameter", { enumerable: true, get: function () { return Resource_1.RTTI_Parameters_Parameter; } });
Object.defineProperty(exports, "RTTI_Parameters", { enumerable: true, get: function () { return Resource_1.RTTI_Parameters; } });
Object.defineProperty(exports, "RTTI_Patient_Communication", { enumerable: true, get: function () { return Resource_1.RTTI_Patient_Communication; } });
Object.defineProperty(exports, "RTTI_Patient_Contact", { enumerable: true, get: function () { return Resource_1.RTTI_Patient_Contact; } });
Object.defineProperty(exports, "Patient_ContactGenderKind", { enumerable: true, get: function () { return Resource_1.Patient_ContactGenderKind; } });
Object.defineProperty(exports, "RTTI_Patient_Link", { enumerable: true, get: function () { return Resource_1.RTTI_Patient_Link; } });
Object.defineProperty(exports, "Patient_LinkTypeKind", { enumerable: true, get: function () { return Resource_1.Patient_LinkTypeKind; } });
Object.defineProperty(exports, "RTTI_Patient", { enumerable: true, get: function () { return Resource_1.RTTI_Patient; } });
Object.defineProperty(exports, "PatientGenderKind", { enumerable: true, get: function () { return Resource_1.PatientGenderKind; } });
Object.defineProperty(exports, "RTTI_PaymentNotice", { enumerable: true, get: function () { return Resource_1.RTTI_PaymentNotice; } });
Object.defineProperty(exports, "RTTI_PaymentReconciliation_Detail", { enumerable: true, get: function () { return Resource_1.RTTI_PaymentReconciliation_Detail; } });
Object.defineProperty(exports, "RTTI_PaymentReconciliation_ProcessNote", { enumerable: true, get: function () { return Resource_1.RTTI_PaymentReconciliation_ProcessNote; } });
Object.defineProperty(exports, "PaymentReconciliation_ProcessNoteTypeKind", { enumerable: true, get: function () { return Resource_1.PaymentReconciliation_ProcessNoteTypeKind; } });
Object.defineProperty(exports, "RTTI_PaymentReconciliation", { enumerable: true, get: function () { return Resource_1.RTTI_PaymentReconciliation; } });
Object.defineProperty(exports, "PaymentReconciliationOutcomeKind", { enumerable: true, get: function () { return Resource_1.PaymentReconciliationOutcomeKind; } });
Object.defineProperty(exports, "RTTI_Period", { enumerable: true, get: function () { return Resource_1.RTTI_Period; } });
Object.defineProperty(exports, "RTTI_Person_Link", { enumerable: true, get: function () { return Resource_1.RTTI_Person_Link; } });
Object.defineProperty(exports, "Person_LinkAssuranceKind", { enumerable: true, get: function () { return Resource_1.Person_LinkAssuranceKind; } });
Object.defineProperty(exports, "RTTI_Person", { enumerable: true, get: function () { return Resource_1.RTTI_Person; } });
Object.defineProperty(exports, "PersonGenderKind", { enumerable: true, get: function () { return Resource_1.PersonGenderKind; } });
Object.defineProperty(exports, "RTTI_PlanDefinition_Action", { enumerable: true, get: function () { return Resource_1.RTTI_PlanDefinition_Action; } });
Object.defineProperty(exports, "PlanDefinition_ActionCardinalityBehaviorKind", { enumerable: true, get: function () { return Resource_1.PlanDefinition_ActionCardinalityBehaviorKind; } });
Object.defineProperty(exports, "PlanDefinition_ActionGroupingBehaviorKind", { enumerable: true, get: function () { return Resource_1.PlanDefinition_ActionGroupingBehaviorKind; } });
Object.defineProperty(exports, "PlanDefinition_ActionPrecheckBehaviorKind", { enumerable: true, get: function () { return Resource_1.PlanDefinition_ActionPrecheckBehaviorKind; } });
Object.defineProperty(exports, "PlanDefinition_ActionRequiredBehaviorKind", { enumerable: true, get: function () { return Resource_1.PlanDefinition_ActionRequiredBehaviorKind; } });
Object.defineProperty(exports, "PlanDefinition_ActionSelectionBehaviorKind", { enumerable: true, get: function () { return Resource_1.PlanDefinition_ActionSelectionBehaviorKind; } });
Object.defineProperty(exports, "RTTI_PlanDefinition_Condition", { enumerable: true, get: function () { return Resource_1.RTTI_PlanDefinition_Condition; } });
Object.defineProperty(exports, "PlanDefinition_ConditionKindKind", { enumerable: true, get: function () { return Resource_1.PlanDefinition_ConditionKindKind; } });
Object.defineProperty(exports, "RTTI_PlanDefinition_DynamicValue", { enumerable: true, get: function () { return Resource_1.RTTI_PlanDefinition_DynamicValue; } });
Object.defineProperty(exports, "RTTI_PlanDefinition_Goal", { enumerable: true, get: function () { return Resource_1.RTTI_PlanDefinition_Goal; } });
Object.defineProperty(exports, "RTTI_PlanDefinition_Participant", { enumerable: true, get: function () { return Resource_1.RTTI_PlanDefinition_Participant; } });
Object.defineProperty(exports, "PlanDefinition_ParticipantTypeKind", { enumerable: true, get: function () { return Resource_1.PlanDefinition_ParticipantTypeKind; } });
Object.defineProperty(exports, "RTTI_PlanDefinition_RelatedAction", { enumerable: true, get: function () { return Resource_1.RTTI_PlanDefinition_RelatedAction; } });
Object.defineProperty(exports, "RTTI_PlanDefinition_Target", { enumerable: true, get: function () { return Resource_1.RTTI_PlanDefinition_Target; } });
Object.defineProperty(exports, "RTTI_PlanDefinition", { enumerable: true, get: function () { return Resource_1.RTTI_PlanDefinition; } });
Object.defineProperty(exports, "PlanDefinitionStatusKind", { enumerable: true, get: function () { return Resource_1.PlanDefinitionStatusKind; } });
Object.defineProperty(exports, "RTTI_Population", { enumerable: true, get: function () { return Resource_1.RTTI_Population; } });
Object.defineProperty(exports, "RTTI_Practitioner_Qualification", { enumerable: true, get: function () { return Resource_1.RTTI_Practitioner_Qualification; } });
Object.defineProperty(exports, "RTTI_PractitionerRole_AvailableTime", { enumerable: true, get: function () { return Resource_1.RTTI_PractitionerRole_AvailableTime; } });
Object.defineProperty(exports, "RTTI_PractitionerRole_NotAvailable", { enumerable: true, get: function () { return Resource_1.RTTI_PractitionerRole_NotAvailable; } });
Object.defineProperty(exports, "RTTI_PractitionerRole", { enumerable: true, get: function () { return Resource_1.RTTI_PractitionerRole; } });
Object.defineProperty(exports, "RTTI_Practitioner", { enumerable: true, get: function () { return Resource_1.RTTI_Practitioner; } });
Object.defineProperty(exports, "PractitionerGenderKind", { enumerable: true, get: function () { return Resource_1.PractitionerGenderKind; } });
Object.defineProperty(exports, "RTTI_Procedure_FocalDevice", { enumerable: true, get: function () { return Resource_1.RTTI_Procedure_FocalDevice; } });
Object.defineProperty(exports, "RTTI_Procedure_Performer", { enumerable: true, get: function () { return Resource_1.RTTI_Procedure_Performer; } });
Object.defineProperty(exports, "RTTI_Procedure", { enumerable: true, get: function () { return Resource_1.RTTI_Procedure; } });
Object.defineProperty(exports, "RTTI_ProdCharacteristic", { enumerable: true, get: function () { return Resource_1.RTTI_ProdCharacteristic; } });
Object.defineProperty(exports, "RTTI_ProductShelfLife", { enumerable: true, get: function () { return Resource_1.RTTI_ProductShelfLife; } });
Object.defineProperty(exports, "RTTI_Provenance_Agent", { enumerable: true, get: function () { return Resource_1.RTTI_Provenance_Agent; } });
Object.defineProperty(exports, "RTTI_Provenance_Entity", { enumerable: true, get: function () { return Resource_1.RTTI_Provenance_Entity; } });
Object.defineProperty(exports, "Provenance_EntityRoleKind", { enumerable: true, get: function () { return Resource_1.Provenance_EntityRoleKind; } });
Object.defineProperty(exports, "RTTI_Provenance", { enumerable: true, get: function () { return Resource_1.RTTI_Provenance; } });
Object.defineProperty(exports, "RTTI_Quantity", { enumerable: true, get: function () { return Resource_1.RTTI_Quantity; } });
Object.defineProperty(exports, "QuantityComparatorKind", { enumerable: true, get: function () { return Resource_1.QuantityComparatorKind; } });
Object.defineProperty(exports, "RTTI_Questionnaire_AnswerOption", { enumerable: true, get: function () { return Resource_1.RTTI_Questionnaire_AnswerOption; } });
Object.defineProperty(exports, "RTTI_Questionnaire_EnableWhen", { enumerable: true, get: function () { return Resource_1.RTTI_Questionnaire_EnableWhen; } });
Object.defineProperty(exports, "Questionnaire_EnableWhenOperatorKind", { enumerable: true, get: function () { return Resource_1.Questionnaire_EnableWhenOperatorKind; } });
Object.defineProperty(exports, "RTTI_Questionnaire_Initial", { enumerable: true, get: function () { return Resource_1.RTTI_Questionnaire_Initial; } });
Object.defineProperty(exports, "RTTI_Questionnaire_Item", { enumerable: true, get: function () { return Resource_1.RTTI_Questionnaire_Item; } });
Object.defineProperty(exports, "Questionnaire_ItemEnableBehaviorKind", { enumerable: true, get: function () { return Resource_1.Questionnaire_ItemEnableBehaviorKind; } });
Object.defineProperty(exports, "Questionnaire_ItemTypeKind", { enumerable: true, get: function () { return Resource_1.Questionnaire_ItemTypeKind; } });
Object.defineProperty(exports, "RTTI_QuestionnaireResponse_Answer", { enumerable: true, get: function () { return Resource_1.RTTI_QuestionnaireResponse_Answer; } });
Object.defineProperty(exports, "RTTI_QuestionnaireResponse_Item", { enumerable: true, get: function () { return Resource_1.RTTI_QuestionnaireResponse_Item; } });
Object.defineProperty(exports, "RTTI_QuestionnaireResponse", { enumerable: true, get: function () { return Resource_1.RTTI_QuestionnaireResponse; } });
Object.defineProperty(exports, "QuestionnaireResponseStatusKind", { enumerable: true, get: function () { return Resource_1.QuestionnaireResponseStatusKind; } });
Object.defineProperty(exports, "RTTI_Questionnaire", { enumerable: true, get: function () { return Resource_1.RTTI_Questionnaire; } });
Object.defineProperty(exports, "QuestionnaireStatusKind", { enumerable: true, get: function () { return Resource_1.QuestionnaireStatusKind; } });
Object.defineProperty(exports, "RTTI_Range", { enumerable: true, get: function () { return Resource_1.RTTI_Range; } });
Object.defineProperty(exports, "RTTI_Ratio", { enumerable: true, get: function () { return Resource_1.RTTI_Ratio; } });
Object.defineProperty(exports, "RTTI_Reference", { enumerable: true, get: function () { return Resource_1.RTTI_Reference; } });
Object.defineProperty(exports, "RTTI_RelatedArtifact", { enumerable: true, get: function () { return Resource_1.RTTI_RelatedArtifact; } });
Object.defineProperty(exports, "RelatedArtifactTypeKind", { enumerable: true, get: function () { return Resource_1.RelatedArtifactTypeKind; } });
Object.defineProperty(exports, "RTTI_RelatedPerson_Communication", { enumerable: true, get: function () { return Resource_1.RTTI_RelatedPerson_Communication; } });
Object.defineProperty(exports, "RTTI_RelatedPerson", { enumerable: true, get: function () { return Resource_1.RTTI_RelatedPerson; } });
Object.defineProperty(exports, "RelatedPersonGenderKind", { enumerable: true, get: function () { return Resource_1.RelatedPersonGenderKind; } });
Object.defineProperty(exports, "RTTI_RequestGroup_Action", { enumerable: true, get: function () { return Resource_1.RTTI_RequestGroup_Action; } });
Object.defineProperty(exports, "RTTI_RequestGroup_Condition", { enumerable: true, get: function () { return Resource_1.RTTI_RequestGroup_Condition; } });
Object.defineProperty(exports, "RTTI_RequestGroup_RelatedAction", { enumerable: true, get: function () { return Resource_1.RTTI_RequestGroup_RelatedAction; } });
Object.defineProperty(exports, "RTTI_RequestGroup", { enumerable: true, get: function () { return Resource_1.RTTI_RequestGroup; } });
Object.defineProperty(exports, "RTTI_ResearchDefinition", { enumerable: true, get: function () { return Resource_1.RTTI_ResearchDefinition; } });
Object.defineProperty(exports, "ResearchDefinitionStatusKind", { enumerable: true, get: function () { return Resource_1.ResearchDefinitionStatusKind; } });
Object.defineProperty(exports, "RTTI_ResearchElementDefinition_Characteristic", { enumerable: true, get: function () { return Resource_1.RTTI_ResearchElementDefinition_Characteristic; } });
Object.defineProperty(exports, "ResearchElementDefinition_CharacteristicParticipantEffectiveGroupMeasureKind", { enumerable: true, get: function () { return Resource_1.ResearchElementDefinition_CharacteristicParticipantEffectiveGroupMeasureKind; } });
Object.defineProperty(exports, "ResearchElementDefinition_CharacteristicStudyEffectiveGroupMeasureKind", { enumerable: true, get: function () { return Resource_1.ResearchElementDefinition_CharacteristicStudyEffectiveGroupMeasureKind; } });
Object.defineProperty(exports, "RTTI_ResearchElementDefinition", { enumerable: true, get: function () { return Resource_1.RTTI_ResearchElementDefinition; } });
Object.defineProperty(exports, "ResearchElementDefinitionStatusKind", { enumerable: true, get: function () { return Resource_1.ResearchElementDefinitionStatusKind; } });
Object.defineProperty(exports, "ResearchElementDefinitionVariableTypeKind", { enumerable: true, get: function () { return Resource_1.ResearchElementDefinitionVariableTypeKind; } });
Object.defineProperty(exports, "ResearchElementDefinitionTypeKind", { enumerable: true, get: function () { return Resource_1.ResearchElementDefinitionTypeKind; } });
Object.defineProperty(exports, "RTTI_ResearchStudy_Arm", { enumerable: true, get: function () { return Resource_1.RTTI_ResearchStudy_Arm; } });
Object.defineProperty(exports, "RTTI_ResearchStudy_Objective", { enumerable: true, get: function () { return Resource_1.RTTI_ResearchStudy_Objective; } });
Object.defineProperty(exports, "RTTI_ResearchStudy", { enumerable: true, get: function () { return Resource_1.RTTI_ResearchStudy; } });
Object.defineProperty(exports, "ResearchStudyStatusKind", { enumerable: true, get: function () { return Resource_1.ResearchStudyStatusKind; } });
Object.defineProperty(exports, "RTTI_ResearchSubject", { enumerable: true, get: function () { return Resource_1.RTTI_ResearchSubject; } });
Object.defineProperty(exports, "ResearchSubjectStatusKind", { enumerable: true, get: function () { return Resource_1.ResearchSubjectStatusKind; } });
Object.defineProperty(exports, "RTTI_RiskAssessment_Prediction", { enumerable: true, get: function () { return Resource_1.RTTI_RiskAssessment_Prediction; } });
Object.defineProperty(exports, "RTTI_RiskAssessment", { enumerable: true, get: function () { return Resource_1.RTTI_RiskAssessment; } });
Object.defineProperty(exports, "RTTI_RiskEvidenceSynthesis_CertaintySubcomponent", { enumerable: true, get: function () { return Resource_1.RTTI_RiskEvidenceSynthesis_CertaintySubcomponent; } });
Object.defineProperty(exports, "RTTI_RiskEvidenceSynthesis_Certainty", { enumerable: true, get: function () { return Resource_1.RTTI_RiskEvidenceSynthesis_Certainty; } });
Object.defineProperty(exports, "RTTI_RiskEvidenceSynthesis_PrecisionEstimate", { enumerable: true, get: function () { return Resource_1.RTTI_RiskEvidenceSynthesis_PrecisionEstimate; } });
Object.defineProperty(exports, "RTTI_RiskEvidenceSynthesis_RiskEstimate", { enumerable: true, get: function () { return Resource_1.RTTI_RiskEvidenceSynthesis_RiskEstimate; } });
Object.defineProperty(exports, "RTTI_RiskEvidenceSynthesis_SampleSize", { enumerable: true, get: function () { return Resource_1.RTTI_RiskEvidenceSynthesis_SampleSize; } });
Object.defineProperty(exports, "RTTI_RiskEvidenceSynthesis", { enumerable: true, get: function () { return Resource_1.RTTI_RiskEvidenceSynthesis; } });
Object.defineProperty(exports, "RiskEvidenceSynthesisStatusKind", { enumerable: true, get: function () { return Resource_1.RiskEvidenceSynthesisStatusKind; } });
Object.defineProperty(exports, "RTTI_SampledData", { enumerable: true, get: function () { return Resource_1.RTTI_SampledData; } });
Object.defineProperty(exports, "RTTI_Schedule", { enumerable: true, get: function () { return Resource_1.RTTI_Schedule; } });
Object.defineProperty(exports, "RTTI_SearchParameter_Component", { enumerable: true, get: function () { return Resource_1.RTTI_SearchParameter_Component; } });
Object.defineProperty(exports, "RTTI_SearchParameter", { enumerable: true, get: function () { return Resource_1.RTTI_SearchParameter; } });
Object.defineProperty(exports, "SearchParameterComparatorKind", { enumerable: true, get: function () { return Resource_1.SearchParameterComparatorKind; } });
Object.defineProperty(exports, "SearchParameterModifierKind", { enumerable: true, get: function () { return Resource_1.SearchParameterModifierKind; } });
Object.defineProperty(exports, "SearchParameterXpathUsageKind", { enumerable: true, get: function () { return Resource_1.SearchParameterXpathUsageKind; } });
Object.defineProperty(exports, "SearchParameterTypeKind", { enumerable: true, get: function () { return Resource_1.SearchParameterTypeKind; } });
Object.defineProperty(exports, "SearchParameterStatusKind", { enumerable: true, get: function () { return Resource_1.SearchParameterStatusKind; } });
Object.defineProperty(exports, "RTTI_ServiceRequest", { enumerable: true, get: function () { return Resource_1.RTTI_ServiceRequest; } });
Object.defineProperty(exports, "RTTI_Signature", { enumerable: true, get: function () { return Resource_1.RTTI_Signature; } });
Object.defineProperty(exports, "RTTI_Slot", { enumerable: true, get: function () { return Resource_1.RTTI_Slot; } });
Object.defineProperty(exports, "SlotStatusKind", { enumerable: true, get: function () { return Resource_1.SlotStatusKind; } });
Object.defineProperty(exports, "RTTI_Specimen_Collection", { enumerable: true, get: function () { return Resource_1.RTTI_Specimen_Collection; } });
Object.defineProperty(exports, "RTTI_Specimen_Container", { enumerable: true, get: function () { return Resource_1.RTTI_Specimen_Container; } });
Object.defineProperty(exports, "RTTI_SpecimenDefinition_Additive", { enumerable: true, get: function () { return Resource_1.RTTI_SpecimenDefinition_Additive; } });
Object.defineProperty(exports, "RTTI_SpecimenDefinition_Container", { enumerable: true, get: function () { return Resource_1.RTTI_SpecimenDefinition_Container; } });
Object.defineProperty(exports, "RTTI_SpecimenDefinition_Handling", { enumerable: true, get: function () { return Resource_1.RTTI_SpecimenDefinition_Handling; } });
Object.defineProperty(exports, "RTTI_SpecimenDefinition", { enumerable: true, get: function () { return Resource_1.RTTI_SpecimenDefinition; } });
Object.defineProperty(exports, "RTTI_SpecimenDefinition_TypeTested", { enumerable: true, get: function () { return Resource_1.RTTI_SpecimenDefinition_TypeTested; } });
Object.defineProperty(exports, "SpecimenDefinition_TypeTestedPreferenceKind", { enumerable: true, get: function () { return Resource_1.SpecimenDefinition_TypeTestedPreferenceKind; } });
Object.defineProperty(exports, "RTTI_Specimen_Processing", { enumerable: true, get: function () { return Resource_1.RTTI_Specimen_Processing; } });
Object.defineProperty(exports, "RTTI_Specimen", { enumerable: true, get: function () { return Resource_1.RTTI_Specimen; } });
Object.defineProperty(exports, "SpecimenStatusKind", { enumerable: true, get: function () { return Resource_1.SpecimenStatusKind; } });
Object.defineProperty(exports, "RTTI_StructureDefinition_Context", { enumerable: true, get: function () { return Resource_1.RTTI_StructureDefinition_Context; } });
Object.defineProperty(exports, "StructureDefinition_ContextTypeKind", { enumerable: true, get: function () { return Resource_1.StructureDefinition_ContextTypeKind; } });
Object.defineProperty(exports, "RTTI_StructureDefinition_Differential", { enumerable: true, get: function () { return Resource_1.RTTI_StructureDefinition_Differential; } });
Object.defineProperty(exports, "RTTI_StructureDefinition_Mapping", { enumerable: true, get: function () { return Resource_1.RTTI_StructureDefinition_Mapping; } });
Object.defineProperty(exports, "RTTI_StructureDefinition_Snapshot", { enumerable: true, get: function () { return Resource_1.RTTI_StructureDefinition_Snapshot; } });
Object.defineProperty(exports, "RTTI_StructureDefinition", { enumerable: true, get: function () { return Resource_1.RTTI_StructureDefinition; } });
Object.defineProperty(exports, "StructureDefinitionDerivationKind", { enumerable: true, get: function () { return Resource_1.StructureDefinitionDerivationKind; } });
Object.defineProperty(exports, "StructureDefinitionStatusKind", { enumerable: true, get: function () { return Resource_1.StructureDefinitionStatusKind; } });
Object.defineProperty(exports, "StructureDefinitionKindKind", { enumerable: true, get: function () { return Resource_1.StructureDefinitionKindKind; } });
Object.defineProperty(exports, "StructureDefinitionFhirVersionKind", { enumerable: true, get: function () { return Resource_1.StructureDefinitionFhirVersionKind; } });
Object.defineProperty(exports, "RTTI_StructureMap_Dependent", { enumerable: true, get: function () { return Resource_1.RTTI_StructureMap_Dependent; } });
Object.defineProperty(exports, "RTTI_StructureMap_Group", { enumerable: true, get: function () { return Resource_1.RTTI_StructureMap_Group; } });
Object.defineProperty(exports, "RTTI_StructureMap_Input", { enumerable: true, get: function () { return Resource_1.RTTI_StructureMap_Input; } });
Object.defineProperty(exports, "StructureMap_InputModeKind", { enumerable: true, get: function () { return Resource_1.StructureMap_InputModeKind; } });
Object.defineProperty(exports, "RTTI_StructureMap_Parameter", { enumerable: true, get: function () { return Resource_1.RTTI_StructureMap_Parameter; } });
Object.defineProperty(exports, "RTTI_StructureMap_Rule", { enumerable: true, get: function () { return Resource_1.RTTI_StructureMap_Rule; } });
Object.defineProperty(exports, "RTTI_StructureMap_Source", { enumerable: true, get: function () { return Resource_1.RTTI_StructureMap_Source; } });
Object.defineProperty(exports, "StructureMap_SourceListModeKind", { enumerable: true, get: function () { return Resource_1.StructureMap_SourceListModeKind; } });
Object.defineProperty(exports, "RTTI_StructureMap_Structure", { enumerable: true, get: function () { return Resource_1.RTTI_StructureMap_Structure; } });
Object.defineProperty(exports, "StructureMap_StructureModeKind", { enumerable: true, get: function () { return Resource_1.StructureMap_StructureModeKind; } });
Object.defineProperty(exports, "RTTI_StructureMap_Target", { enumerable: true, get: function () { return Resource_1.RTTI_StructureMap_Target; } });
Object.defineProperty(exports, "StructureMap_TargetContextTypeKind", { enumerable: true, get: function () { return Resource_1.StructureMap_TargetContextTypeKind; } });
Object.defineProperty(exports, "StructureMap_TargetListModeKind", { enumerable: true, get: function () { return Resource_1.StructureMap_TargetListModeKind; } });
Object.defineProperty(exports, "StructureMap_TargetTransformKind", { enumerable: true, get: function () { return Resource_1.StructureMap_TargetTransformKind; } });
Object.defineProperty(exports, "RTTI_StructureMap", { enumerable: true, get: function () { return Resource_1.RTTI_StructureMap; } });
Object.defineProperty(exports, "StructureMapStatusKind", { enumerable: true, get: function () { return Resource_1.StructureMapStatusKind; } });
Object.defineProperty(exports, "RTTI_Subscription_Channel", { enumerable: true, get: function () { return Resource_1.RTTI_Subscription_Channel; } });
Object.defineProperty(exports, "Subscription_ChannelTypeKind", { enumerable: true, get: function () { return Resource_1.Subscription_ChannelTypeKind; } });
Object.defineProperty(exports, "RTTI_Subscription", { enumerable: true, get: function () { return Resource_1.RTTI_Subscription; } });
Object.defineProperty(exports, "SubscriptionStatusKind", { enumerable: true, get: function () { return Resource_1.SubscriptionStatusKind; } });
Object.defineProperty(exports, "RTTI_SubstanceAmount_ReferenceRange", { enumerable: true, get: function () { return Resource_1.RTTI_SubstanceAmount_ReferenceRange; } });
Object.defineProperty(exports, "RTTI_SubstanceAmount", { enumerable: true, get: function () { return Resource_1.RTTI_SubstanceAmount; } });
Object.defineProperty(exports, "RTTI_Substance_Ingredient", { enumerable: true, get: function () { return Resource_1.RTTI_Substance_Ingredient; } });
Object.defineProperty(exports, "RTTI_Substance_Instance", { enumerable: true, get: function () { return Resource_1.RTTI_Substance_Instance; } });
Object.defineProperty(exports, "RTTI_SubstanceNucleicAcid_Linkage", { enumerable: true, get: function () { return Resource_1.RTTI_SubstanceNucleicAcid_Linkage; } });
Object.defineProperty(exports, "RTTI_SubstanceNucleicAcid_Subunit", { enumerable: true, get: function () { return Resource_1.RTTI_SubstanceNucleicAcid_Subunit; } });
Object.defineProperty(exports, "RTTI_SubstanceNucleicAcid_Sugar", { enumerable: true, get: function () { return Resource_1.RTTI_SubstanceNucleicAcid_Sugar; } });
Object.defineProperty(exports, "RTTI_SubstanceNucleicAcid", { enumerable: true, get: function () { return Resource_1.RTTI_SubstanceNucleicAcid; } });
Object.defineProperty(exports, "RTTI_SubstancePolymer_DegreeOfPolymerisation", { enumerable: true, get: function () { return Resource_1.RTTI_SubstancePolymer_DegreeOfPolymerisation; } });
Object.defineProperty(exports, "RTTI_SubstancePolymer_MonomerSet", { enumerable: true, get: function () { return Resource_1.RTTI_SubstancePolymer_MonomerSet; } });
Object.defineProperty(exports, "RTTI_SubstancePolymer_Repeat", { enumerable: true, get: function () { return Resource_1.RTTI_SubstancePolymer_Repeat; } });
Object.defineProperty(exports, "RTTI_SubstancePolymer_RepeatUnit", { enumerable: true, get: function () { return Resource_1.RTTI_SubstancePolymer_RepeatUnit; } });
Object.defineProperty(exports, "RTTI_SubstancePolymer_StartingMaterial", { enumerable: true, get: function () { return Resource_1.RTTI_SubstancePolymer_StartingMaterial; } });
Object.defineProperty(exports, "RTTI_SubstancePolymer_StructuralRepresentation", { enumerable: true, get: function () { return Resource_1.RTTI_SubstancePolymer_StructuralRepresentation; } });
Object.defineProperty(exports, "RTTI_SubstancePolymer", { enumerable: true, get: function () { return Resource_1.RTTI_SubstancePolymer; } });
Object.defineProperty(exports, "RTTI_SubstanceProtein_Subunit", { enumerable: true, get: function () { return Resource_1.RTTI_SubstanceProtein_Subunit; } });
Object.defineProperty(exports, "RTTI_SubstanceProtein", { enumerable: true, get: function () { return Resource_1.RTTI_SubstanceProtein; } });
Object.defineProperty(exports, "RTTI_SubstanceReferenceInformation_Classification", { enumerable: true, get: function () { return Resource_1.RTTI_SubstanceReferenceInformation_Classification; } });
Object.defineProperty(exports, "RTTI_SubstanceReferenceInformation_GeneElement", { enumerable: true, get: function () { return Resource_1.RTTI_SubstanceReferenceInformation_GeneElement; } });
Object.defineProperty(exports, "RTTI_SubstanceReferenceInformation_Gene", { enumerable: true, get: function () { return Resource_1.RTTI_SubstanceReferenceInformation_Gene; } });
Object.defineProperty(exports, "RTTI_SubstanceReferenceInformation_Target", { enumerable: true, get: function () { return Resource_1.RTTI_SubstanceReferenceInformation_Target; } });
Object.defineProperty(exports, "RTTI_SubstanceReferenceInformation", { enumerable: true, get: function () { return Resource_1.RTTI_SubstanceReferenceInformation; } });
Object.defineProperty(exports, "RTTI_SubstanceSourceMaterial_Author", { enumerable: true, get: function () { return Resource_1.RTTI_SubstanceSourceMaterial_Author; } });
Object.defineProperty(exports, "RTTI_SubstanceSourceMaterial_FractionDescription", { enumerable: true, get: function () { return Resource_1.RTTI_SubstanceSourceMaterial_FractionDescription; } });
Object.defineProperty(exports, "RTTI_SubstanceSourceMaterial_Hybrid", { enumerable: true, get: function () { return Resource_1.RTTI_SubstanceSourceMaterial_Hybrid; } });
Object.defineProperty(exports, "RTTI_SubstanceSourceMaterial_OrganismGeneral", { enumerable: true, get: function () { return Resource_1.RTTI_SubstanceSourceMaterial_OrganismGeneral; } });
Object.defineProperty(exports, "RTTI_SubstanceSourceMaterial_Organism", { enumerable: true, get: function () { return Resource_1.RTTI_SubstanceSourceMaterial_Organism; } });
Object.defineProperty(exports, "RTTI_SubstanceSourceMaterial_PartDescription", { enumerable: true, get: function () { return Resource_1.RTTI_SubstanceSourceMaterial_PartDescription; } });
Object.defineProperty(exports, "RTTI_SubstanceSourceMaterial", { enumerable: true, get: function () { return Resource_1.RTTI_SubstanceSourceMaterial; } });
Object.defineProperty(exports, "RTTI_SubstanceSpecification_Code", { enumerable: true, get: function () { return Resource_1.RTTI_SubstanceSpecification_Code; } });
Object.defineProperty(exports, "RTTI_SubstanceSpecification_Isotope", { enumerable: true, get: function () { return Resource_1.RTTI_SubstanceSpecification_Isotope; } });
Object.defineProperty(exports, "RTTI_SubstanceSpecification_Moiety", { enumerable: true, get: function () { return Resource_1.RTTI_SubstanceSpecification_Moiety; } });
Object.defineProperty(exports, "RTTI_SubstanceSpecification_MolecularWeight", { enumerable: true, get: function () { return Resource_1.RTTI_SubstanceSpecification_MolecularWeight; } });
Object.defineProperty(exports, "RTTI_SubstanceSpecification_Name", { enumerable: true, get: function () { return Resource_1.RTTI_SubstanceSpecification_Name; } });
Object.defineProperty(exports, "RTTI_SubstanceSpecification_Official", { enumerable: true, get: function () { return Resource_1.RTTI_SubstanceSpecification_Official; } });
Object.defineProperty(exports, "RTTI_SubstanceSpecification_Property", { enumerable: true, get: function () { return Resource_1.RTTI_SubstanceSpecification_Property; } });
Object.defineProperty(exports, "RTTI_SubstanceSpecification_Relationship", { enumerable: true, get: function () { return Resource_1.RTTI_SubstanceSpecification_Relationship; } });
Object.defineProperty(exports, "RTTI_SubstanceSpecification_Representation", { enumerable: true, get: function () { return Resource_1.RTTI_SubstanceSpecification_Representation; } });
Object.defineProperty(exports, "RTTI_SubstanceSpecification_Structure", { enumerable: true, get: function () { return Resource_1.RTTI_SubstanceSpecification_Structure; } });
Object.defineProperty(exports, "RTTI_SubstanceSpecification", { enumerable: true, get: function () { return Resource_1.RTTI_SubstanceSpecification; } });
Object.defineProperty(exports, "RTTI_Substance", { enumerable: true, get: function () { return Resource_1.RTTI_Substance; } });
Object.defineProperty(exports, "SubstanceStatusKind", { enumerable: true, get: function () { return Resource_1.SubstanceStatusKind; } });
Object.defineProperty(exports, "RTTI_SupplyDelivery_SuppliedItem", { enumerable: true, get: function () { return Resource_1.RTTI_SupplyDelivery_SuppliedItem; } });
Object.defineProperty(exports, "RTTI_SupplyDelivery", { enumerable: true, get: function () { return Resource_1.RTTI_SupplyDelivery; } });
Object.defineProperty(exports, "SupplyDeliveryStatusKind", { enumerable: true, get: function () { return Resource_1.SupplyDeliveryStatusKind; } });
Object.defineProperty(exports, "RTTI_SupplyRequest_Parameter", { enumerable: true, get: function () { return Resource_1.RTTI_SupplyRequest_Parameter; } });
Object.defineProperty(exports, "RTTI_SupplyRequest", { enumerable: true, get: function () { return Resource_1.RTTI_SupplyRequest; } });
Object.defineProperty(exports, "SupplyRequestStatusKind", { enumerable: true, get: function () { return Resource_1.SupplyRequestStatusKind; } });
Object.defineProperty(exports, "RTTI_Task_Input", { enumerable: true, get: function () { return Resource_1.RTTI_Task_Input; } });
Object.defineProperty(exports, "RTTI_Task_Output", { enumerable: true, get: function () { return Resource_1.RTTI_Task_Output; } });
Object.defineProperty(exports, "RTTI_Task_Restriction", { enumerable: true, get: function () { return Resource_1.RTTI_Task_Restriction; } });
Object.defineProperty(exports, "RTTI_Task", { enumerable: true, get: function () { return Resource_1.RTTI_Task; } });
Object.defineProperty(exports, "TaskIntentKind", { enumerable: true, get: function () { return Resource_1.TaskIntentKind; } });
Object.defineProperty(exports, "TaskStatusKind", { enumerable: true, get: function () { return Resource_1.TaskStatusKind; } });
Object.defineProperty(exports, "RTTI_TerminologyCapabilities_Closure", { enumerable: true, get: function () { return Resource_1.RTTI_TerminologyCapabilities_Closure; } });
Object.defineProperty(exports, "RTTI_TerminologyCapabilities_CodeSystem", { enumerable: true, get: function () { return Resource_1.RTTI_TerminologyCapabilities_CodeSystem; } });
Object.defineProperty(exports, "RTTI_TerminologyCapabilities_Expansion", { enumerable: true, get: function () { return Resource_1.RTTI_TerminologyCapabilities_Expansion; } });
Object.defineProperty(exports, "RTTI_TerminologyCapabilities_Filter", { enumerable: true, get: function () { return Resource_1.RTTI_TerminologyCapabilities_Filter; } });
Object.defineProperty(exports, "RTTI_TerminologyCapabilities_Implementation", { enumerable: true, get: function () { return Resource_1.RTTI_TerminologyCapabilities_Implementation; } });
Object.defineProperty(exports, "RTTI_TerminologyCapabilities_Parameter", { enumerable: true, get: function () { return Resource_1.RTTI_TerminologyCapabilities_Parameter; } });
Object.defineProperty(exports, "RTTI_TerminologyCapabilities_Software", { enumerable: true, get: function () { return Resource_1.RTTI_TerminologyCapabilities_Software; } });
Object.defineProperty(exports, "RTTI_TerminologyCapabilities_Translation", { enumerable: true, get: function () { return Resource_1.RTTI_TerminologyCapabilities_Translation; } });
Object.defineProperty(exports, "RTTI_TerminologyCapabilities", { enumerable: true, get: function () { return Resource_1.RTTI_TerminologyCapabilities; } });
Object.defineProperty(exports, "TerminologyCapabilitiesCodeSearchKind", { enumerable: true, get: function () { return Resource_1.TerminologyCapabilitiesCodeSearchKind; } });
Object.defineProperty(exports, "TerminologyCapabilitiesStatusKind", { enumerable: true, get: function () { return Resource_1.TerminologyCapabilitiesStatusKind; } });
Object.defineProperty(exports, "RTTI_TerminologyCapabilities_ValidateCode", { enumerable: true, get: function () { return Resource_1.RTTI_TerminologyCapabilities_ValidateCode; } });
Object.defineProperty(exports, "RTTI_TerminologyCapabilities_Version", { enumerable: true, get: function () { return Resource_1.RTTI_TerminologyCapabilities_Version; } });
Object.defineProperty(exports, "RTTI_TestReport_Action1", { enumerable: true, get: function () { return Resource_1.RTTI_TestReport_Action1; } });
Object.defineProperty(exports, "RTTI_TestReport_Action2", { enumerable: true, get: function () { return Resource_1.RTTI_TestReport_Action2; } });
Object.defineProperty(exports, "RTTI_TestReport_Action", { enumerable: true, get: function () { return Resource_1.RTTI_TestReport_Action; } });
Object.defineProperty(exports, "RTTI_TestReport_Assert", { enumerable: true, get: function () { return Resource_1.RTTI_TestReport_Assert; } });
Object.defineProperty(exports, "TestReport_AssertResultKind", { enumerable: true, get: function () { return Resource_1.TestReport_AssertResultKind; } });
Object.defineProperty(exports, "RTTI_TestReport_Operation", { enumerable: true, get: function () { return Resource_1.RTTI_TestReport_Operation; } });
Object.defineProperty(exports, "TestReport_OperationResultKind", { enumerable: true, get: function () { return Resource_1.TestReport_OperationResultKind; } });
Object.defineProperty(exports, "RTTI_TestReport_Participant", { enumerable: true, get: function () { return Resource_1.RTTI_TestReport_Participant; } });
Object.defineProperty(exports, "TestReport_ParticipantTypeKind", { enumerable: true, get: function () { return Resource_1.TestReport_ParticipantTypeKind; } });
Object.defineProperty(exports, "RTTI_TestReport_Setup", { enumerable: true, get: function () { return Resource_1.RTTI_TestReport_Setup; } });
Object.defineProperty(exports, "RTTI_TestReport_Teardown", { enumerable: true, get: function () { return Resource_1.RTTI_TestReport_Teardown; } });
Object.defineProperty(exports, "RTTI_TestReport_Test", { enumerable: true, get: function () { return Resource_1.RTTI_TestReport_Test; } });
Object.defineProperty(exports, "RTTI_TestReport", { enumerable: true, get: function () { return Resource_1.RTTI_TestReport; } });
Object.defineProperty(exports, "TestReportResultKind", { enumerable: true, get: function () { return Resource_1.TestReportResultKind; } });
Object.defineProperty(exports, "TestReportStatusKind", { enumerable: true, get: function () { return Resource_1.TestReportStatusKind; } });
Object.defineProperty(exports, "RTTI_TestScript_Action1", { enumerable: true, get: function () { return Resource_1.RTTI_TestScript_Action1; } });
Object.defineProperty(exports, "RTTI_TestScript_Action2", { enumerable: true, get: function () { return Resource_1.RTTI_TestScript_Action2; } });
Object.defineProperty(exports, "RTTI_TestScript_Action", { enumerable: true, get: function () { return Resource_1.RTTI_TestScript_Action; } });
Object.defineProperty(exports, "RTTI_TestScript_Assert", { enumerable: true, get: function () { return Resource_1.RTTI_TestScript_Assert; } });
Object.defineProperty(exports, "TestScript_AssertDirectionKind", { enumerable: true, get: function () { return Resource_1.TestScript_AssertDirectionKind; } });
Object.defineProperty(exports, "TestScript_AssertOperatorKind", { enumerable: true, get: function () { return Resource_1.TestScript_AssertOperatorKind; } });
Object.defineProperty(exports, "TestScript_AssertRequestMethodKind", { enumerable: true, get: function () { return Resource_1.TestScript_AssertRequestMethodKind; } });
Object.defineProperty(exports, "TestScript_AssertResponseKind", { enumerable: true, get: function () { return Resource_1.TestScript_AssertResponseKind; } });
Object.defineProperty(exports, "RTTI_TestScript_Capability", { enumerable: true, get: function () { return Resource_1.RTTI_TestScript_Capability; } });
Object.defineProperty(exports, "RTTI_TestScript_Destination", { enumerable: true, get: function () { return Resource_1.RTTI_TestScript_Destination; } });
Object.defineProperty(exports, "RTTI_TestScript_Fixture", { enumerable: true, get: function () { return Resource_1.RTTI_TestScript_Fixture; } });
Object.defineProperty(exports, "RTTI_TestScript_Link", { enumerable: true, get: function () { return Resource_1.RTTI_TestScript_Link; } });
Object.defineProperty(exports, "RTTI_TestScript_Metadata", { enumerable: true, get: function () { return Resource_1.RTTI_TestScript_Metadata; } });
Object.defineProperty(exports, "RTTI_TestScript_Operation", { enumerable: true, get: function () { return Resource_1.RTTI_TestScript_Operation; } });
Object.defineProperty(exports, "TestScript_OperationMethodKind", { enumerable: true, get: function () { return Resource_1.TestScript_OperationMethodKind; } });
Object.defineProperty(exports, "RTTI_TestScript_Origin", { enumerable: true, get: function () { return Resource_1.RTTI_TestScript_Origin; } });
Object.defineProperty(exports, "RTTI_TestScript_RequestHeader", { enumerable: true, get: function () { return Resource_1.RTTI_TestScript_RequestHeader; } });
Object.defineProperty(exports, "RTTI_TestScript_Setup", { enumerable: true, get: function () { return Resource_1.RTTI_TestScript_Setup; } });
Object.defineProperty(exports, "RTTI_TestScript_Teardown", { enumerable: true, get: function () { return Resource_1.RTTI_TestScript_Teardown; } });
Object.defineProperty(exports, "RTTI_TestScript_Test", { enumerable: true, get: function () { return Resource_1.RTTI_TestScript_Test; } });
Object.defineProperty(exports, "RTTI_TestScript", { enumerable: true, get: function () { return Resource_1.RTTI_TestScript; } });
Object.defineProperty(exports, "TestScriptStatusKind", { enumerable: true, get: function () { return Resource_1.TestScriptStatusKind; } });
Object.defineProperty(exports, "RTTI_TestScript_Variable", { enumerable: true, get: function () { return Resource_1.RTTI_TestScript_Variable; } });
Object.defineProperty(exports, "RTTI_Timing_Repeat", { enumerable: true, get: function () { return Resource_1.RTTI_Timing_Repeat; } });
Object.defineProperty(exports, "Timing_RepeatDurationUnitKind", { enumerable: true, get: function () { return Resource_1.Timing_RepeatDurationUnitKind; } });
Object.defineProperty(exports, "Timing_RepeatPeriodUnitKind", { enumerable: true, get: function () { return Resource_1.Timing_RepeatPeriodUnitKind; } });
Object.defineProperty(exports, "Timing_RepeatWhenKind", { enumerable: true, get: function () { return Resource_1.Timing_RepeatWhenKind; } });
Object.defineProperty(exports, "RTTI_Timing", { enumerable: true, get: function () { return Resource_1.RTTI_Timing; } });
Object.defineProperty(exports, "RTTI_TriggerDefinition", { enumerable: true, get: function () { return Resource_1.RTTI_TriggerDefinition; } });
Object.defineProperty(exports, "TriggerDefinitionTypeKind", { enumerable: true, get: function () { return Resource_1.TriggerDefinitionTypeKind; } });
Object.defineProperty(exports, "RTTI_UsageContext", { enumerable: true, get: function () { return Resource_1.RTTI_UsageContext; } });
Object.defineProperty(exports, "RTTI_ValueSet_Compose", { enumerable: true, get: function () { return Resource_1.RTTI_ValueSet_Compose; } });
Object.defineProperty(exports, "RTTI_ValueSet_Concept", { enumerable: true, get: function () { return Resource_1.RTTI_ValueSet_Concept; } });
Object.defineProperty(exports, "RTTI_ValueSet_Contains", { enumerable: true, get: function () { return Resource_1.RTTI_ValueSet_Contains; } });
Object.defineProperty(exports, "RTTI_ValueSet_Designation", { enumerable: true, get: function () { return Resource_1.RTTI_ValueSet_Designation; } });
Object.defineProperty(exports, "RTTI_ValueSet_Expansion", { enumerable: true, get: function () { return Resource_1.RTTI_ValueSet_Expansion; } });
Object.defineProperty(exports, "RTTI_ValueSet_Filter", { enumerable: true, get: function () { return Resource_1.RTTI_ValueSet_Filter; } });
Object.defineProperty(exports, "ValueSet_FilterOpKind", { enumerable: true, get: function () { return Resource_1.ValueSet_FilterOpKind; } });
Object.defineProperty(exports, "RTTI_ValueSet_Include", { enumerable: true, get: function () { return Resource_1.RTTI_ValueSet_Include; } });
Object.defineProperty(exports, "RTTI_ValueSet_Parameter", { enumerable: true, get: function () { return Resource_1.RTTI_ValueSet_Parameter; } });
Object.defineProperty(exports, "RTTI_ValueSet", { enumerable: true, get: function () { return Resource_1.RTTI_ValueSet; } });
Object.defineProperty(exports, "ValueSetStatusKind", { enumerable: true, get: function () { return Resource_1.ValueSetStatusKind; } });
Object.defineProperty(exports, "RTTI_VerificationResult_Attestation", { enumerable: true, get: function () { return Resource_1.RTTI_VerificationResult_Attestation; } });
Object.defineProperty(exports, "RTTI_VerificationResult_PrimarySource", { enumerable: true, get: function () { return Resource_1.RTTI_VerificationResult_PrimarySource; } });
Object.defineProperty(exports, "RTTI_VerificationResult", { enumerable: true, get: function () { return Resource_1.RTTI_VerificationResult; } });
Object.defineProperty(exports, "RTTI_VerificationResult_Validator", { enumerable: true, get: function () { return Resource_1.RTTI_VerificationResult_Validator; } });
Object.defineProperty(exports, "RTTI_VisionPrescription_LensSpecification", { enumerable: true, get: function () { return Resource_1.RTTI_VisionPrescription_LensSpecification; } });
Object.defineProperty(exports, "VisionPrescription_LensSpecificationEyeKind", { enumerable: true, get: function () { return Resource_1.VisionPrescription_LensSpecificationEyeKind; } });
Object.defineProperty(exports, "RTTI_VisionPrescription_Prism", { enumerable: true, get: function () { return Resource_1.RTTI_VisionPrescription_Prism; } });
Object.defineProperty(exports, "VisionPrescription_PrismBaseKind", { enumerable: true, get: function () { return Resource_1.VisionPrescription_PrismBaseKind; } });
Object.defineProperty(exports, "RTTI_VisionPrescription", { enumerable: true, get: function () { return Resource_1.RTTI_VisionPrescription; } });
