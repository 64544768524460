import {GenderUtil} from '@canimmunize/tools';
import {ColumnsType} from 'antd/lib/table';
import {fullDisplayName} from '../../../models/fhir/patient';

export const citfUploadTemplateName = 'citf-upload-template';

const itemSpecificColumns = [
  {title: 'Clinic Name', dataIndex: 'clinicName'},
  {title: 'Cohort Name', dataIndex: 'cohortName'},
  {width: 150, title: 'HCN', dataIndex: 'hcn'},
  {width: 150, title: 'HCN Type', dataIndex: 'hcnType'},
  {
    title: 'Gender',
    dataIndex: 'gender',
    render: (_, row: any) => {
      if (['0', '1', '2'].indexOf(row.gender) > -1)
        return GenderUtil.toString(parseInt(row.gender));
      else return row.gender;
    },
  },
  {title: 'Birthdate', dataIndex: 'birthdate'},
  {title: 'Phone', dataIndex: 'phone'},
];

const doseFieldsColumns = [
  {title: 'Dose 1 Date Administered', dataIndex: 'd1_dateAdministered'},
  {title: 'Dose 1 Time Administered\n24 hour clock', dataIndex: 'd1_timeAdministered'},
  {title: 'Dose 1 Number', dataIndex: 'd1_doseNumber'},
  {title: 'Dose 1 Vaccine Name', dataIndex: 'd1_vaccineName'},
  {title: 'Dose 1 Lot', dataIndex: 'd1_lotNumber'},
  {title: 'Dose 1 Site', dataIndex: 'd1_siteOfAdmin'},
  {title: 'Dose 1 Route', dataIndex: 'd1_routeOfAdmin'},
  {title: 'Dose 1 Dosage', dataIndex: 'd1_dosage'},
  {title: 'Dose 1 Dosage UoM', dataIndex: 'd1_dosageUom'},
  {title: 'Dose 2 Date Administered', dataIndex: 'd2_dateAdministered'},
  {title: 'Dose 2 Time Administered\n24 hour clock', dataIndex: 'd2_timeAdministered'},
  {title: 'Dose 2 Number', dataIndex: 'd2_doseNumber'},
  {title: 'Dose 2 Vaccine Name', dataIndex: 'd2_vaccineName'},
  {title: 'Dose 2 Lot', dataIndex: 'd2_lotNumber'},
  {title: 'Dose 2 Site', dataIndex: 'd2_siteOfAdmin'},
  {title: 'Dose 2 Route', dataIndex: 'd2_routeOfAdmin'},
  {title: 'Dose 2 Dosage', dataIndex: 'd2_dosage'},
  {title: 'Dose 2 Dosage UoM', dataIndex: 'd2_dosageUom'},
];

export const citfImportColumns: ColumnsType<any> = [
  {
    width: 200,
    title: 'Name',
    render: (_, row: any) => fullDisplayName(row),
    fixed: 'left',
  },
  {title: 'Email', dataIndex: 'email'},
  ...itemSpecificColumns,
  ...doseFieldsColumns,
];

export const citfPatientImportConfig = {
  name: 'CITF Patient Upload',
  columns: citfImportColumns,
  templateName: citfUploadTemplateName,
};
