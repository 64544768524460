"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_CodeSystem_Property = exports.CodeSystem_PropertyTypeKind = void 0;
var t = __importStar(require("io-ts"));
var RTTI_Extension_1 = require("./RTTI_Extension");
var RTTI_code_1 = require("../Scalar/RTTI_code");
var RTTI_Element_1 = require("./RTTI_Element");
var RTTI_uri_1 = require("../Scalar/RTTI_uri");
var CodeSystem_PropertyTypeKind;
(function (CodeSystem_PropertyTypeKind) {
    CodeSystem_PropertyTypeKind["_code"] = "code";
    CodeSystem_PropertyTypeKind["_coding"] = "Coding";
    CodeSystem_PropertyTypeKind["_string"] = "string";
    CodeSystem_PropertyTypeKind["_integer"] = "integer";
    CodeSystem_PropertyTypeKind["_boolean"] = "boolean";
    CodeSystem_PropertyTypeKind["_dateTime"] = "dateTime";
    CodeSystem_PropertyTypeKind["_decimal"] = "decimal";
})(CodeSystem_PropertyTypeKind = exports.CodeSystem_PropertyTypeKind || (exports.CodeSystem_PropertyTypeKind = {}));
var EnumType_1 = require("../../EnumType");
exports.RTTI_CodeSystem_Property = t.recursion('ICodeSystem_Property', function () {
    return t.partial({
        id: t.string,
        extension: t.array(RTTI_Extension_1.RTTI_Extension),
        modifierExtension: t.array(RTTI_Extension_1.RTTI_Extension),
        code: RTTI_code_1.RTTI_code,
        _code: RTTI_Element_1.RTTI_Element,
        uri: RTTI_uri_1.RTTI_uri,
        _uri: RTTI_Element_1.RTTI_Element,
        description: t.string,
        _description: RTTI_Element_1.RTTI_Element,
        type: EnumType_1.createEnumType(CodeSystem_PropertyTypeKind, 'CodeSystem_PropertyTypeKind'),
        _type: RTTI_Element_1.RTTI_Element
    });
});
