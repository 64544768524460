"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_ObservationDefinition = exports.ObservationDefinitionPermittedDataTypeKind = void 0;
var t = __importStar(require("io-ts"));
var RTTI_id_1 = require("../Scalar/RTTI_id");
var RTTI_Meta_1 = require("./RTTI_Meta");
var RTTI_uri_1 = require("../Scalar/RTTI_uri");
var RTTI_Element_1 = require("./RTTI_Element");
var RTTI_code_1 = require("../Scalar/RTTI_code");
var RTTI_Narrative_1 = require("./RTTI_Narrative");
var RTTI_ResourceList_1 = require("../Union/RTTI_ResourceList");
var RTTI_Extension_1 = require("./RTTI_Extension");
var RTTI_CodeableConcept_1 = require("./RTTI_CodeableConcept");
var RTTI_Identifier_1 = require("./RTTI_Identifier");
var RTTI_ObservationDefinition_QuantitativeDetails_1 = require("./RTTI_ObservationDefinition_QuantitativeDetails");
var RTTI_ObservationDefinition_QualifiedInterval_1 = require("./RTTI_ObservationDefinition_QualifiedInterval");
var RTTI_Reference_1 = require("./RTTI_Reference");
var ObservationDefinitionPermittedDataTypeKind;
(function (ObservationDefinitionPermittedDataTypeKind) {
    ObservationDefinitionPermittedDataTypeKind["_quantity"] = "Quantity";
    ObservationDefinitionPermittedDataTypeKind["_codeableConcept"] = "CodeableConcept";
    ObservationDefinitionPermittedDataTypeKind["_string"] = "string";
    ObservationDefinitionPermittedDataTypeKind["_boolean"] = "boolean";
    ObservationDefinitionPermittedDataTypeKind["_integer"] = "integer";
    ObservationDefinitionPermittedDataTypeKind["_range"] = "Range";
    ObservationDefinitionPermittedDataTypeKind["_ratio"] = "Ratio";
    ObservationDefinitionPermittedDataTypeKind["_sampledData"] = "SampledData";
    ObservationDefinitionPermittedDataTypeKind["_time"] = "time";
    ObservationDefinitionPermittedDataTypeKind["_dateTime"] = "dateTime";
    ObservationDefinitionPermittedDataTypeKind["_period"] = "Period";
})(ObservationDefinitionPermittedDataTypeKind = exports.ObservationDefinitionPermittedDataTypeKind || (exports.ObservationDefinitionPermittedDataTypeKind = {}));
var EnumType_1 = require("../../EnumType");
exports.RTTI_ObservationDefinition = t.recursion('IObservationDefinition', function () {
    return t.intersection([
        t.type({
            resourceType: t.literal('ObservationDefinition'),
            code: RTTI_CodeableConcept_1.RTTI_CodeableConcept
        }),
        t.partial({
            id: RTTI_id_1.RTTI_id,
            meta: RTTI_Meta_1.RTTI_Meta,
            implicitRules: RTTI_uri_1.RTTI_uri,
            _implicitRules: RTTI_Element_1.RTTI_Element,
            language: RTTI_code_1.RTTI_code,
            _language: RTTI_Element_1.RTTI_Element,
            text: RTTI_Narrative_1.RTTI_Narrative,
            contained: t.array(RTTI_ResourceList_1.RTTI_ResourceList),
            extension: t.array(RTTI_Extension_1.RTTI_Extension),
            modifierExtension: t.array(RTTI_Extension_1.RTTI_Extension),
            category: t.array(RTTI_CodeableConcept_1.RTTI_CodeableConcept),
            identifier: t.array(RTTI_Identifier_1.RTTI_Identifier),
            permittedDataType: t.array(EnumType_1.createEnumType(ObservationDefinitionPermittedDataTypeKind, 'ObservationDefinitionPermittedDataTypeKind')),
            _permittedDataType: t.array(RTTI_Element_1.RTTI_Element),
            multipleResultsAllowed: t.boolean,
            _multipleResultsAllowed: RTTI_Element_1.RTTI_Element,
            method: RTTI_CodeableConcept_1.RTTI_CodeableConcept,
            preferredReportName: t.string,
            _preferredReportName: RTTI_Element_1.RTTI_Element,
            quantitativeDetails: RTTI_ObservationDefinition_QuantitativeDetails_1.RTTI_ObservationDefinition_QuantitativeDetails,
            qualifiedInterval: t.array(RTTI_ObservationDefinition_QualifiedInterval_1.RTTI_ObservationDefinition_QualifiedInterval),
            validCodedValueSet: RTTI_Reference_1.RTTI_Reference,
            normalCodedValueSet: RTTI_Reference_1.RTTI_Reference,
            abnormalCodedValueSet: RTTI_Reference_1.RTTI_Reference,
            criticalCodedValueSet: RTTI_Reference_1.RTTI_Reference
        })
    ]);
});
