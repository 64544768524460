import {
  RequiredShortTextValidationRules,
  RequiredLongTextValidationRules,
  RequiredSpecialCharsValidationRules,
} from '../../services/ui-validation-rules';
import {EnumValidationRuleId} from '../../validation/validation-rules/enum-validation';
import {RequiredValidationRuleId} from '../../validation/validation-rules/required-validation';
import {FormUXFieldType} from '../form-ux';
import {FormUXModel} from '../form-ux/form-ux-models/form-ux-model';

/**
 * The names of the fields for the clinic form whose rendering have to
 * be handled by the CohortUX component
 *
 * @export
 * @enum {number}
 */
export enum CohortCustomFieldName {
  OrganizationId = 'organizationId',
}

export const CohortUXModel = (disableOrgPicker): FormUXModel => [
  {
    name: 'name',
    type: FormUXFieldType.text,
    label: 'Name',
    editable: true,
    validationRules: [...RequiredShortTextValidationRules, ...RequiredSpecialCharsValidationRules],
    inCreateModal: true,
  },
  {
    name: 'description',
    type: FormUXFieldType.text,
    label: 'Description',
    editable: true,
    validationRules: RequiredLongTextValidationRules,
    inCreateModal: true,
  },
  {
    name: 'type',
    type: FormUXFieldType.text,
    label: 'Type',
    editable: true,
    inCreateModal: true,
    validationRules: [
      {
        validationRuleType: RequiredValidationRuleId,
      },
    ],
  },
  {
    name: CohortCustomFieldName.OrganizationId,
    type: FormUXFieldType.custom,
    label: 'Organization',
    editable: true,
    disabled: disableOrgPicker,
    validationRules: [
      {
        validationRuleType: 'required',
      },
    ],
    inCreateModal: true,
  },
  {
    name: 'clinicIds',
    type: FormUXFieldType.hidden,
    label: '',
    editable: false,
    validationRules: [],
    inCreateModal: true,
    hideDefaultLabel: true,
    inEditMode: false,
  },
];
