import {R4} from '@ahryman40k/ts-fhir-types';
import {createSlice} from '@reduxjs/toolkit';
import {normalize, schema} from 'normalizr';
import querystring from 'querystring';
import {mapBundleToResourceArray} from '../util/fhir';

export interface PatientsSliceInterface {
  byId: {[string: string]: R4.IPatient};
  historyById: {[string: string]: R4.IPatient};
}
const initialState = {
  byId: {},
  historyById: {},
};
const patient = new schema.Entity('patients', {});
const patientHistory = new schema.Entity(
  'patients',
  {},
  {
    idAttribute: (value) => {
      return `${value.id}-${value.meta.versionId}`;
    },
  }
);

export const slice = createSlice({
  name: 'patients',
  initialState,
  reducers: {
    SAVE_PATIENTS: (state: PatientsSliceInterface, action) => {
      if (action.payload.entry) {
        const normalizedPatientResources = normalize(
          action.payload.entry.map((entry: R4.IBundle_Entry) =>
            patientResourceParser(entry.resource as PatientWithIdentifier)
          ),
          [patient]
        );
        state.byId = normalizedPatientResources.entities.patients || {};
      }
    },
    SAVE_PATIENT: (state: PatientsSliceInterface, action) => {
      const patient = action.payload;
      state.byId[patient.id] = patientResourceParser(patient);
    },
    SAVE_PATIENT_HISTORY: (state: PatientsSliceInterface, action) => {
      const resources = mapBundleToResourceArray(action.payload);
      state.historyById = normalize(resources, [patientHistory]).entities.organizations || {};
    },
    DELETE_PATIENT: (state: PatientsSliceInterface, action) => {
      delete state.byId[action.payload.id];
    },
  },
});

export const getAll = (client, params?) => async (dispatch) => {
  let query = params ? querystring.stringify(params) : undefined;
  const res = await client.get(`Patient${query ? `?${query}` : ''}`);
  dispatch(slice.actions.SAVE_PATIENTS(res.data));
  return res.data;
};

export const getOne = (client, patientId) => async (dispatch) => {
  return client
    .get(`Patient/${patientId}`)
    .then((res) => dispatch(slice.actions.SAVE_PATIENT(res.data)));
};

export const getOneHistory = (client, patientId) => async (dispatch) => {
  return client.request(`/Patient/${patientId}/_history`).then((res) => {
    return dispatch(slice.actions.SAVE_PATIENT_HISTORY(res));
  });
};

export const createOne =
  (client, patient) =>
  async (dispatch): Promise<any> => {
    return client.post(`/Patient`, patient).then((res) => {
      dispatch(slice.actions.SAVE_PATIENT(res.data));
      return res.data;
    });
  };

export const deleteOne = (client, patientId, note?) => async (dispatch) => {
  return client.delete(`/Patient/${patientId}`, note && {data: {note}}).then(async (res) => {
    await dispatch(slice.actions.DELETE_PATIENT({id: patientId}));
    return res.data;
  });
};

export const updateOne =
  (client, patient, queryString = '') =>
  async (dispatch) => {
    const updateableFields = {
      id: patient.id,
      firstName: patient.firstName,
      lastName: patient.lastName,
      gender: patient.gender,
      birthdate: patient.birthdate,
      hcn: patient.hcn,
      hcnType: patient.hcnType,
      phone: patient.phone,
      cohortIds: patient.cohortIds,
      email: patient.email,
      pronoun: patient.pronoun,
      preferredName: patient.preferredName,
      preferredLanguage: patient.preferredLanguage,
      genderIdentifyAs: patient.genderIdentifyAs,
      street: patient.street,
      unitNumber: patient.unitNumber,
      city: patient.city,
      postalCode: patient.postalCode,
      deleted: patient.deleted,
      override: patient.override,
      deceased: patient.deceased,
      organizationId: patient.organizationId,
    };

    return client
      .put(`/Patient/${patient.id}?${queryString}`, updateableFields)
      .then(async (res) => {
        await dispatch(slice.actions.SAVE_PATIENT(res.data));
        return res.data;
      });
  };

// Extends Patient to make Identifier not optional
interface PatientWithIdentifier extends R4.IPatient {
  identifier: R4.IIdentifier[];
}

function patientResourceParser(resource: PatientWithIdentifier) {
  return resource;
  // return {
  //   ...resource,
  //   firstName: FhirUtils.patient.getPatientFirstName(resource),
  //   lastName: FhirUtils.patient.getPatientLastName(resource),
  //   fullName: FhirUtils.patient.getPatientFullName(resource),
  //   department: FhirUtils.patient.getPatientDepartment(resource),
  //   email: FhirUtils.patient.getPatientTelecomValues(resource, 'email'),
  //   phone: FhirUtils.patient.getPatientTelecomValues(resource, 'phone'),
  //   canimmunizeRecordId: FhirUtils.getIdentifierValue(
  //     resource,
  //     'https://canimmunize.ca/StructureDefinition/canimmunize-record-id'
  //   ),
  //   canimmunizeAccountId: FhirUtils.getIdentifierValue(
  //     resource,
  //     'https://canimmunize.ca/StructureDefinition/canimmunize-account-id'
  //   ),
  // };
}

export default {
  slice,
  getAll,
  getOne,
  updateOne,
  createOne,
  getOneHistory,
  deleteOne,
};
