import {Tag} from 'antd';
import React from 'react';

export const RoleTag = (props: {role: string}) => {
  let text;
  let color;
  switch (props.role) {
    case 'immunizer':
      color = 'purple';
      text = 'Immunizer';
      break;
    case 'greeter':
      color = 'blue';
      text = 'Greeter';
      break;
    default:
      text = 'Unknown Role';
      break;
  }
  return <Tag color={color}>{text}</Tag>;
};

export const AccessTag = (props: {accountId: string, locked: boolean}) => {
  let text;
  let color;
  if (props.accountId && props.locked === false) {
    color = 'green';
    text = 'Granted';
  } else {
    text = 'Denied';
  }
  return <Tag color={color}>{text}</Tag>;
};
