import React from 'react';
import moment from 'moment';

import {FHIRTable, FHIRTableProps} from '../../fhir-table';

export interface VersionHistoryTableProps {
  fhirResource: string;
  entityId: string;
  /* The columns of a FHIRTable used to display a past entity version. */
  columns: any[];
}

/* The columns to display information about the version history itself. */
const versionHistoryColumns = [
  {
    title: 'Version',
    key: 'version',
    children: [
      {
        title: 'Date',
        key: 'vhCreatedAt',
        fixed: true,
        render: (_, vh) => {
          return {
            props: {style: {background: '#fafafa', fontWeight: 500}},
            children: moment(vh.vhCreatedAt).format('YYYY-MM-DD hh:mm'),
          };
        },
      },
      {
        title: '#',
        key: 'versionNumber',
        fixed: true,
        render: (_, vh) => {
          return {
            props: {style: {background: '#fafafa', fontWeight: 500}},
            children: vh.versionNumber + 1,
          };
        },
      },
    ],
  },
];

/* A table to display information about past versions of an entity. */
export const VersionHistoryTable = (props: VersionHistoryTableProps) => {
  const {fhirResource, entityId} = props;
  /* The route called is a combination of fhirResource and entityId. */
  const resourcePath = `${fhirResource}/${entityId}/history`;

  /* Combine the past entity columns and version history-specific columns. */
  const columns = [...versionHistoryColumns, {title: 'Entity', children: props.columns}];

  return (
    <FHIRTable
      mode="table"
      fhirResource={resourcePath}
      columns={columns}
      size="small"
      hideSearch
      triggerRefresh={entityId}
      rowKey={({vhId}) => vhId}
      marginBottom={0}
    />
  );
};
