"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_ResearchStudy = exports.ResearchStudyStatusKind = void 0;
var t = __importStar(require("io-ts"));
var RTTI_id_1 = require("../Scalar/RTTI_id");
var RTTI_Meta_1 = require("./RTTI_Meta");
var RTTI_uri_1 = require("../Scalar/RTTI_uri");
var RTTI_Element_1 = require("./RTTI_Element");
var RTTI_code_1 = require("../Scalar/RTTI_code");
var RTTI_Narrative_1 = require("./RTTI_Narrative");
var RTTI_ResourceList_1 = require("../Union/RTTI_ResourceList");
var RTTI_Extension_1 = require("./RTTI_Extension");
var RTTI_Identifier_1 = require("./RTTI_Identifier");
var RTTI_Reference_1 = require("./RTTI_Reference");
var RTTI_CodeableConcept_1 = require("./RTTI_CodeableConcept");
var RTTI_ContactDetail_1 = require("./RTTI_ContactDetail");
var RTTI_RelatedArtifact_1 = require("./RTTI_RelatedArtifact");
var RTTI_markdown_1 = require("../Scalar/RTTI_markdown");
var RTTI_Period_1 = require("./RTTI_Period");
var RTTI_Annotation_1 = require("./RTTI_Annotation");
var RTTI_ResearchStudy_Arm_1 = require("./RTTI_ResearchStudy_Arm");
var RTTI_ResearchStudy_Objective_1 = require("./RTTI_ResearchStudy_Objective");
var ResearchStudyStatusKind;
(function (ResearchStudyStatusKind) {
    ResearchStudyStatusKind["_active"] = "active";
    ResearchStudyStatusKind["_administrativelyCompleted"] = "administratively-completed";
    ResearchStudyStatusKind["_approved"] = "approved";
    ResearchStudyStatusKind["_closedToAccrual"] = "closed-to-accrual";
    ResearchStudyStatusKind["_closedToAccrualAndIntervention"] = "closed-to-accrual-and-intervention";
    ResearchStudyStatusKind["_completed"] = "completed";
    ResearchStudyStatusKind["_disapproved"] = "disapproved";
    ResearchStudyStatusKind["_inReview"] = "in-review";
    ResearchStudyStatusKind["_temporarilyClosedToAccrual"] = "temporarily-closed-to-accrual";
    ResearchStudyStatusKind["_temporarilyClosedToAccrualAndIntervention"] = "temporarily-closed-to-accrual-and-intervention";
    ResearchStudyStatusKind["_withdrawn"] = "withdrawn";
})(ResearchStudyStatusKind = exports.ResearchStudyStatusKind || (exports.ResearchStudyStatusKind = {}));
var EnumType_1 = require("../../EnumType");
exports.RTTI_ResearchStudy = t.recursion('IResearchStudy', function () {
    return t.intersection([
        t.type({
            resourceType: t.literal('ResearchStudy')
        }),
        t.partial({
            id: RTTI_id_1.RTTI_id,
            meta: RTTI_Meta_1.RTTI_Meta,
            implicitRules: RTTI_uri_1.RTTI_uri,
            _implicitRules: RTTI_Element_1.RTTI_Element,
            language: RTTI_code_1.RTTI_code,
            _language: RTTI_Element_1.RTTI_Element,
            text: RTTI_Narrative_1.RTTI_Narrative,
            contained: t.array(RTTI_ResourceList_1.RTTI_ResourceList),
            extension: t.array(RTTI_Extension_1.RTTI_Extension),
            modifierExtension: t.array(RTTI_Extension_1.RTTI_Extension),
            identifier: t.array(RTTI_Identifier_1.RTTI_Identifier),
            title: t.string,
            _title: RTTI_Element_1.RTTI_Element,
            protocol: t.array(RTTI_Reference_1.RTTI_Reference),
            partOf: t.array(RTTI_Reference_1.RTTI_Reference),
            status: EnumType_1.createEnumType(ResearchStudyStatusKind, 'ResearchStudyStatusKind'),
            _status: RTTI_Element_1.RTTI_Element,
            primaryPurposeType: RTTI_CodeableConcept_1.RTTI_CodeableConcept,
            phase: RTTI_CodeableConcept_1.RTTI_CodeableConcept,
            category: t.array(RTTI_CodeableConcept_1.RTTI_CodeableConcept),
            focus: t.array(RTTI_CodeableConcept_1.RTTI_CodeableConcept),
            condition: t.array(RTTI_CodeableConcept_1.RTTI_CodeableConcept),
            contact: t.array(RTTI_ContactDetail_1.RTTI_ContactDetail),
            relatedArtifact: t.array(RTTI_RelatedArtifact_1.RTTI_RelatedArtifact),
            keyword: t.array(RTTI_CodeableConcept_1.RTTI_CodeableConcept),
            location: t.array(RTTI_CodeableConcept_1.RTTI_CodeableConcept),
            description: RTTI_markdown_1.RTTI_markdown,
            _description: RTTI_Element_1.RTTI_Element,
            enrollment: t.array(RTTI_Reference_1.RTTI_Reference),
            period: RTTI_Period_1.RTTI_Period,
            sponsor: RTTI_Reference_1.RTTI_Reference,
            principalInvestigator: RTTI_Reference_1.RTTI_Reference,
            site: t.array(RTTI_Reference_1.RTTI_Reference),
            reasonStopped: RTTI_CodeableConcept_1.RTTI_CodeableConcept,
            note: t.array(RTTI_Annotation_1.RTTI_Annotation),
            arm: t.array(RTTI_ResearchStudy_Arm_1.RTTI_ResearchStudy_Arm),
            objective: t.array(RTTI_ResearchStudy_Objective_1.RTTI_ResearchStudy_Objective)
        })
    ]);
});
