import React from 'react';
import {Button, Typography, Modal} from 'antd';
import UI from '../../models/ui';
import {useSelector, useDispatch} from 'react-redux';
import {RootState} from '../../models';

const {Title, Paragraph: P} = Typography;
export const WelcomeModal = () => {
  const dispatch = useDispatch();
  const welcomeModalVisible = useSelector((state: RootState) => state.ui.welcomeModalVisible);

  const onClose = () => {
    dispatch(UI.slice.actions.SET_WELCOME_MODAL_VISIBLE(false));
  };

  return (
    <Modal
      visible={welcomeModalVisible}
      onCancel={onClose}
      onOk={onClose}
      style={{minWidth: '75%'}}
      bodyStyle={{padding: 0, borderRadius: 12, overflow: 'hidden'}}
      footer={null}
    >
      <div style={{display: 'flex'}}>
        <div style={{padding: 24, flex: 1, flexShrink: 2, position: 'relative'}}>
          <Title level={2}>Welcome to the CANImmunize Employer Portal Demo!</Title>
          <P>You are currently logged in as a superuser.</P>
          <P>
            This portal allows employers to retrieve and analyze data that has been submitted to
            them by their employees through the CANImmunize consumer facing mobile and web
            applications.
          </P>
          <Button
            type="primary"
            onClick={onClose}
            style={{position: 'absolute', right: 24, bottom: 24}}
          >
            Explore
          </Button>
        </div>
        <div
          style={{
            flexShrink: 0,
            width: 400,
            borderLeft: '#eeeeee .5px solid',
            // backgroundColor: 'lightyellow',
            padding: 24,
            paddingBottom: 0,
          }}
        >
          <div
            style={{
              overflow: 'hidden',
              maxHeight: 515,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-start',
            }}
          >
            <img
              src="https://cdn.canimmunize.ca/screenshots/ios-home.png"
              style={{maxWidth: 300}}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};
