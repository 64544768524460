import {colors, Ids, Status, StrLang} from '@canimmunize/tools';
import {faCalendarAlt} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Divider, Space, Row, Col} from 'antd';
import cn from 'classnames';
import React from 'react';
import {useUrlData} from '../../../../components/scheduler/util';
import greenCheckmark from '../../assets/checkmark_circle_1.png';
import styles from './index.module.css';

interface DoseBoxProps {
  title: string;
  subTitle: string;
  disabled?: boolean;
  statusText: string;
  lotNumber?: string;
  doseNumber?: string;
  status?: Status;
  locationText?: React.ReactChild;
  right?: React.ReactChild;
  deliveryLocationID?: string; //province code (ie, NS)
  deliveryLocationCountryName?: string; //country name (ie, Canada)
}

export const DoseBox = (props: DoseBoxProps) => {
  const status = props.status || Status.FUTURE;
  const {lang} = useUrlData();

  let color;
  let icon;
  switch (status) {
    case Status.RECEIVED: {
      icon = <img src={greenCheckmark} width="25" height="25" alt="" />;
      color = props.disabled ? '#b2e3b5' : colors.green;
      break;
    }
    case Status.APPROACHING: {
      icon = <FontAwesomeIcon icon={faCalendarAlt} style={{fontSize: 24}} color={colors.blue} />;
      color = colors.blue;
      break;
    }
    // case Status.OVERDUE: {
    //   color = colors.red;
    //   break;
    // }

    default:
      break;
  }

  const borderStyle = {borderLeftColor: color};
  return (
    <div className={cn(styles.doseBoxContainer)} style={borderStyle}>
      <div className={cn(styles.doseBox)}>
        <div
          style={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            paddingRight: 20,
            //flexWrap: 'wrap'
          }}
        >
          <div style={{flex: 1, display: 'flex'}}>
            {props.disabled && <div className={styles.disabled} />}
            <div className={styles.icon}>{icon}</div>
            <div className={styles.content}>
              <div className={styles.title}>{props.title}</div>
              <div className={styles.text}>{props.subTitle}</div>
              {props.lotNumber && (
                <div className={styles.text}>{`${StrLang(lang, Ids.lot_number)}: ${
                  props.lotNumber || StrLang(lang, Ids.unknown)
                }`}</div>
              )}
              <div className={styles.text}>{props.statusText}</div>
              {props.locationText && <div className={styles.text}>{props.locationText}</div>}
              {props.doseNumber && <div className={styles.text}>Dose: {props.doseNumber}</div>}
              {
                props.deliveryLocationID &&
                  renderVaccineLocation(
                    props.deliveryLocationID,
                    props.deliveryLocationCountryName
                  ) /*Do not display location unless it exists*/
              }
            </div>
          </div>
          {props.right ?? <div>{props.right}</div>}
        </div>
      </div>
    </div>
  );
};

export const NSPortalDoseBox = (props: DoseBoxProps) => {
  return (
    <>
      <div className={cn(styles.doseBoxContainerNS)}>
        <div className={cn(styles.doseBoxNS)}>
          <Row justify="space-around">
            <Col span={4}>
              <div className={styles.text}>{props.title}</div>
            </Col>
            <Col span={7}>
              <div className={styles.text}>{props.subTitle}</div>
            </Col>
            <Col span={5}>
              <div className={styles.text}>{props.statusText}</div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

//disregard province if vaccine was administered outside of canada
const renderVaccineLocation = (ID, deliveryName) => {
  if (ID.length > 2) {
    //only canadian service locations have 2 character ID
    return <div className={styles.text}>Country of vaccination: {deliveryName}</div>;
  } else {
    return (
      <>
        <div className={styles.text}>Province of vaccination: {deliveryName}</div>
        <div className={styles.text}>Country of vaccination: Canada</div>
      </>
    );
  }
};
