import {Input} from 'antd';
import {Select, Form, InputNumber} from 'formik-antd';
import React from 'react';
import {ValidationRules} from '../../../validation/validation-rules/validation-rules';
import {ValidateFormItem} from '../../validate-form-item';

interface AppointmentTypeAgeFormItemProps {
  label?: string;
  name: string;
  validationRules?: ValidationRules[];
  disabled?: boolean;
}

const selectableValues = [
  {key: 'year', label: 'Years'},
  {key: 'month', label: 'Months'},
];

export const AppointmentTypeAgeFormItem = (props: AppointmentTypeAgeFormItemProps) => {
  return (
    <ValidateFormItem
      validationRules={props.validationRules || []}
      renderFormItem={(validate) => {
        return (
          <Form.Item name={props.name} label={props.label}>
            <Input.Group compact>
              <Form.Item name={props.name} validate={validate} noStyle>
                <InputNumber name={props.name} />
              </Form.Item>
              <Form.Item name={props.name + 'Unit'} noStyle>
                <Select
                  name={props.name + 'Unit'}
                  disabled={props.disabled}
                  style={{width: 100}}
                  defaultValue="year"
                >
                  {selectableValues?.map((selectableValue) => {
                    return (
                      <Select.Option key={selectableValue.key} value={selectableValue.key}>
                        {selectableValue.label}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Input.Group>
          </Form.Item>
        );
      }}
    />
  );
};
