import {Ids} from '@canimmunize/tools';
import {Button} from 'antd';
import {FormikContextType, useFormikContext} from 'formik';
import parse from 'html-react-parser';
import React from 'react';
import {useStr} from '../../../../../services/str';
import {YukonPortalPage} from '../../../components/yukon-portal-page';

export const InfoPage = (props) => {
  const Str = useStr();
  const formikContext: FormikContextType<any> = useFormikContext();

  const StartRequestButton = () => {
    return (
      <Button
        size="large"
        type="primary"
        onClick={() => formikContext.setFieldValue('mode', 'app-info')}
      >
        {Str(Ids.yk_pvc_new_request)}
      </Button>
    );
  };

  const pageBody = (
    <>
      {!formikContext.values.isYukonCallCentre && (
        <>
          {parse(Str(Ids.yk_before_you_start_info))}
          <h3>{Str(Ids.yk_if_you_need_help)}</h3>
          {parse(Str(Ids.yk_if_you_need_help_info))}
          <h3>{Str(Ids.yk_privacy_consent)}</h3>
          {parse(Str(Ids.yk_privacy_consent_info))}
        </>
      )}
      {formikContext.values.isYukonCallCentre && <StartRequestButton />}
    </>
  );

  return (
    <>
      <YukonPortalPage
        pageNumber={
          formikContext.values.isYukonCallCentre ? Str(Ids.yk_cc_page1) : Str(Ids.yk_pvc_page1)
        }
        preTitle={!formikContext.values.isYukonCallCentre ? Str(Ids.yk_pvc_subtitle) : null}
        title={!formikContext.values.isYukonCallCentre ? Str(Ids.yk_before_you_start) : null}
        body={pageBody}
        footer={!formikContext.values.isYukonCallCentre ? <StartRequestButton /> : null}
      ></YukonPortalPage>
    </>
  );
};
