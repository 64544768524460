"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_ConceptMap_Target = exports.ConceptMap_TargetEquivalenceKind = void 0;
var t = __importStar(require("io-ts"));
var RTTI_Extension_1 = require("./RTTI_Extension");
var RTTI_code_1 = require("../Scalar/RTTI_code");
var RTTI_Element_1 = require("./RTTI_Element");
var RTTI_ConceptMap_DependsOn_1 = require("./RTTI_ConceptMap_DependsOn");
var ConceptMap_TargetEquivalenceKind;
(function (ConceptMap_TargetEquivalenceKind) {
    ConceptMap_TargetEquivalenceKind["_relatedto"] = "relatedto";
    ConceptMap_TargetEquivalenceKind["_equivalent"] = "equivalent";
    ConceptMap_TargetEquivalenceKind["_equal"] = "equal";
    ConceptMap_TargetEquivalenceKind["_wider"] = "wider";
    ConceptMap_TargetEquivalenceKind["_subsumes"] = "subsumes";
    ConceptMap_TargetEquivalenceKind["_narrower"] = "narrower";
    ConceptMap_TargetEquivalenceKind["_specializes"] = "specializes";
    ConceptMap_TargetEquivalenceKind["_inexact"] = "inexact";
    ConceptMap_TargetEquivalenceKind["_unmatched"] = "unmatched";
    ConceptMap_TargetEquivalenceKind["_disjoint"] = "disjoint";
})(ConceptMap_TargetEquivalenceKind = exports.ConceptMap_TargetEquivalenceKind || (exports.ConceptMap_TargetEquivalenceKind = {}));
var EnumType_1 = require("../../EnumType");
exports.RTTI_ConceptMap_Target = t.recursion('IConceptMap_Target', function () {
    return t.partial({
        id: t.string,
        extension: t.array(RTTI_Extension_1.RTTI_Extension),
        modifierExtension: t.array(RTTI_Extension_1.RTTI_Extension),
        code: RTTI_code_1.RTTI_code,
        _code: RTTI_Element_1.RTTI_Element,
        display: t.string,
        _display: RTTI_Element_1.RTTI_Element,
        equivalence: EnumType_1.createEnumType(ConceptMap_TargetEquivalenceKind, 'ConceptMap_TargetEquivalenceKind'),
        _equivalence: RTTI_Element_1.RTTI_Element,
        comment: t.string,
        _comment: RTTI_Element_1.RTTI_Element,
        dependsOn: t.array(RTTI_ConceptMap_DependsOn_1.RTTI_ConceptMap_DependsOn),
        product: t.array(RTTI_ConceptMap_DependsOn_1.RTTI_ConceptMap_DependsOn)
    });
});
