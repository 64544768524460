import {Modal} from 'antd';
import {AxiosInstance, AxiosRequestConfig} from 'axios';
import moment from 'moment';

/**
 * download PVC in PDF format
 *
 * Supposed to be used in portal and patient item view to download PVC
 * Based on passed Axios Client, use diffrent routes to download
 * SessionId is required to be used from public client/route
 *
 * @param patient
 * @param client
 * @param sessionId
 */
export const downloadPVCPdf = (
  patient,
  client: AxiosInstance,
  setLoadingFunc: ((boolean) => void) | undefined,
  params: any,
  environmentId: string,
  sessionId?: string
) => {
  const useAuth = !!client.defaults.headers?.Authorization;

  const options = {
    method: useAuth ? 'get' : 'post',
    data: !useAuth && {id: patient.portalId, sessionId},
    url: useAuth ? `/Patient/${patient.id}/pvc` : '/public/pvc',
    params: params,
  } as AxiosRequestConfig;

  if (setLoadingFunc) setLoadingFunc(true);

  client(options)
    .then((res) => {
      const pdfBase64 = res.data?.pdf;
      const bytes = atob(pdfBase64);
      let length = bytes.length;
      let out = new Uint8Array(length);

      while (length--) {
        out[length] = bytes.charCodeAt(length);
      }

      const blob = new Blob([out], {type: 'application/pdf'});
      const link = document.createElement('a');

      link.href = window.URL.createObjectURL(blob);

      switch (environmentId) {
        case 'yukon':
          link.download = `${patient?.lastName},${patient?.firstName}-YukonPVC-${moment().format(
            'YYYYMMDD'
          )}.pdf`;
          break;
        default:
          link.download = `${moment().format('YYMMDD')}_${patient?.lastName}_PVC.pdf`;
          break;
      }

      link.click();
    })
    .catch((err) => {
      Modal.error({
        title: 'Failed',
        content: `${err.response?.data?.message || err.response?.data || 'Failed to download PVC'}`,
      });
    })
    .finally(() => {
      if (setLoadingFunc) setLoadingFunc(false);
    });
};
