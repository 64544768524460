import {R4} from '@ahryman40k/ts-fhir-types';
import {ContactPointSystemKind} from '@ahryman40k/ts-fhir-types/lib/R4';
import moment, {Moment} from 'moment';
import {getExtensionValue} from '../../util/fhir';

function getPatientFirstName(resource: R4.IPatient): string {
  return resource?.name?.[0]?.given?.[0] || '';
}

function getPatientLastName(resource: R4.IPatient): string {
  return resource?.name?.[0]?.family || '';
}

function getPatientFullName(resource: R4.IPatient) {
  const firstNames = resource?.name?.[0]?.given?.join(' ');
  const lastName = getPatientLastName(resource);
  return `${firstNames}${lastName.length ? ' ' : ''}${lastName}`;
}

function getPatientDepartment(resource: R4.IPatient) {
  return;
  getExtensionValue(
    resource,
    'https://canimmunize.ca/StructureDefinition/ca-canimmunize-patient-department'
  );
}

function getPatientTelecomValues(patient: R4.IPatient, system: 'phone' | 'email') {
  return patient.telecom
    ?.filter((contactPoint) => contactPoint.system === system)
    .map((contactPoint) => contactPoint.value);
}

export interface PatientFactoryInput {
  firstName: string;
  lastName: string;
  birthDate: Moment;
  gender: string;
  email: string;
  phone: string;
  hcn: string;
  cohortIds?: string[];
}

const patientFactory = (data: PatientFactoryInput) => {
  let {firstName, lastName, birthDate} = data;

  birthDate = moment(birthDate);

  return {
    resourceType: 'Patient',
    // from Resource: id, meta, implicitRules, and language
    // from DomainResource: text, contained, extension, and modifierExtension
    // "identifier" : [{ Identifier }], // An identifier for this patient
    active: true, // Whether this patient's record is in active use
    name: [{given: [firstName], family: lastName}], // A name associated with the patient
    //"telecom" : [{ ContactPoint }], // A contact detail for the individual
    //"gender" : "<code>", // male | female | other | unknown
    //"birthDate" : "<date>", // The date of birth for the individual
    birthDate: birthDate.format('YYYY-MM-DD'),
    // deceased[x]: Indicates if the individual is deceased or not. One of these 2:
    // "deceasedBoolean" : false,
    //"deceasedDateTime" : "<dateTime>",
    //"address" : [{ Address }], // An address for the individual
    //"maritalStatus" : { CodeableConcept }, // Marital (civil) status of a patient
    // multipleBirth[x]: Whether patient is part of a multiple birth. One of these 2:
    // "multipleBirthBoolean" : <boolean>,
    // "multipleBirthInteger" : <integer>,
    // "photo" : [{ Attachment }], // Image of the patient
    // "contact" : [{ // A contact party (e.g. guardian, partner, friend) for the patient
    //   "relationship" : [{ CodeableConcept }], // The kind of relationship
    //   "name" : { HumanName }, // A name associated with the contact person
    //   "telecom" : [{ ContactPoint }], // A contact detail for the person
    //   "address" : { Address }, // Address for the contact person
    //   "gender" : "<code>", // male | female | other | unknown
    //   "organization" : { Reference(Organization) }, // C? Organization that is associated with the contact
    //   "period" : { Period } // The period during which this contact person or organization is valid to be contacted relating to this patient
    // }],
    // "communication" : [{ // A language which may be used to communicate with the patient about his or her health
    //   "language" : { CodeableConcept }, // R!  The language which can be used to communicate with the patient about his or her health
    //   "preferred" : <boolean> // Language preference indicator
    // }],
    // "generalPractitioner" : [{ Reference(Organization|Practitioner|
    //  PractitionerRole) }], // Patient's nominated primary care provider
    // "managingOrganization" : { Reference(Organization) }, // Organization that is the custodian of the patient record
    // "link" : [{ // Link to another patient resource that concerns the same actual person
    //   "other" : { Reference(Patient|RelatedPerson) }, // R!  The other patient or related person resource that the link refers to
    //   "type" : "<code>" // R!  replaced-by | replaces | refer | seealso
    // }]
  };
};

const clinicFlowPatientFactory = (data: PatientFactoryInput): R4.IPatient => {
  let {firstName, lastName, birthDate, hcn, gender, email, phone, cohortIds} = data;

  birthDate = moment(birthDate);

  const extensions = cohortIds?.map((cohortId) => ({
    url: 'https://canimmunize.ca/StructureDefinition/cohort-id',
    valueString: cohortId,
  }));

  return {
    resourceType: 'Patient',
    active: true, // Whether this patient's record is in active use
    name: [{given: [firstName], family: lastName}], // A name associated with the patient
    birthDate: birthDate.format('YYYY-MM-DD'),
    gender: gender as R4.PatientGenderKind,
    identifier: [
      {
        system: 'https://canimmunize.ca/StructureDefinition/hcn',
        value: hcn,
      },
    ],

    telecom: [
      {system: ContactPointSystemKind._email, value: email},
      {system: ContactPointSystemKind._phone, value: phone},
    ],
    extension: extensions,
  };
};

export const FHIRUtilPatient = {
  getPatientFirstName,
  getPatientLastName,
  getPatientFullName,
  getPatientDepartment,
  getPatientTelecomValues,
  patientFactory,
  clinicFlowPatientFactory,
};

export const fullDisplayName = (patient) =>
  `${patient.firstName || ''} ${patient.lastName || ''}${
    patient.preferredName ? ` (${patient.preferredName})` : ''
  }`;
