import {Descriptions, Collapse} from 'antd';
import moment from 'moment-timezone';
import {GenericNoteView} from './views/generic-note-view';
import {SimpleNoteView} from './views/simple-note-view';
import {DoseDecisionNoteView} from './views/dose-decision-note-view';
import {useNavigate} from 'react-router-dom';
import React from 'react';

const {Panel} = Collapse;

/* A Note component to display note formSubmissions. */
export const Note = (props: {note}) => {
  const {note} = props;
  const noteType = note?.form?.type;
  const noteFieldKeys = note && Object.keys(note?.fields);

  return (
    <div>
      <NoteHeader note={note} />
      <br />

      {(noteType === 'doseDecision' && <DoseDecisionNoteView note={note} />) ||
        (noteFieldKeys.length <= 2 && noteFieldKeys.includes('subject') && (
          <SimpleNoteView note={note} fieldKey={noteFieldKeys.find((k) => k !== 'subject')} />
        )) || <GenericNoteView note={note} />}

      <br />
      <NoteLinks note={note} />
    </div>
  );
};

/* The header section. (Note type, Created at, Authored by) */
export const NoteHeader = (props) => {
  const {note} = props;

  return (
    <Descriptions
      size="small"
      labelStyle={{fontWeight: 'bold', width: '100px'}}
      column={1}
      colon={false}
    >
      <Descriptions.Item label="Note type">
        {note.form?.typeDefinition?.displayName['en'] || note.form?.type || 'missing'}
      </Descriptions.Item>

      <Descriptions.Item label="Created at">
        {moment.utc(note.createdAt).local().format('LLL')}
      </Descriptions.Item>

      <Descriptions.Item label="Authored by">
        {(note.clinicModeUser &&
          `${note.clinicModeUser.lastName}, ${note.clinicModeUser.firstName}`) ||
          (note.consoleUserId && (note.consoleUserEmail || 'Admin User')) || (
            <span
              style={{
                opacity: '0.333',
              }}
            >
              (no author)
            </span>
          )}
      </Descriptions.Item>
    </Descriptions>
  );
};

/* The collapsible links section. */
export const NoteLinks = (props) => {
  const {note} = props;
  const hasLinks = note?.doseId || note?.appointmentId;
  const navigate = useNavigate();

  return (
    <Collapse expandIconPosition="left" collapsible={hasLinks ? undefined : 'disabled'}>
      <Panel key="links" header={<b>{hasLinks ? 'Links' : 'No Links'}</b>} className="smallPanel">
        <Descriptions
          size="small"
          labelStyle={{fontWeight: 'bold', width: '130px'}}
          column={1}
          colon={false}
        >
          {/* Dose Link */}
          {note?.doseId && <Descriptions.Item label="Dose ID">{note?.doseId}</Descriptions.Item>}
          {/* Appointment Link */}
          {note?.appointmentId && (
            <Descriptions.Item label="Appointment ID">
              <a
                onClick={() => navigate(`/appointments/${note?.appointmentId}`)}
                style={{color: 'blue', textDecoration: 'underline'}}
              >
                {note?.appointmentId}
              </a>
            </Descriptions.Item>
          )}
        </Descriptions>
      </Panel>
    </Collapse>
  );
};
