"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_RelatedArtifact = exports.RelatedArtifactTypeKind = void 0;
var t = __importStar(require("io-ts"));
var RTTI_Extension_1 = require("./RTTI_Extension");
var RTTI_Element_1 = require("./RTTI_Element");
var RTTI_markdown_1 = require("../Scalar/RTTI_markdown");
var RTTI_url_1 = require("../Scalar/RTTI_url");
var RTTI_Attachment_1 = require("./RTTI_Attachment");
var RTTI_canonical_1 = require("../Scalar/RTTI_canonical");
var RelatedArtifactTypeKind;
(function (RelatedArtifactTypeKind) {
    RelatedArtifactTypeKind["_documentation"] = "documentation";
    RelatedArtifactTypeKind["_justification"] = "justification";
    RelatedArtifactTypeKind["_citation"] = "citation";
    RelatedArtifactTypeKind["_predecessor"] = "predecessor";
    RelatedArtifactTypeKind["_successor"] = "successor";
    RelatedArtifactTypeKind["_derivedFrom"] = "derived-from";
    RelatedArtifactTypeKind["_dependsOn"] = "depends-on";
    RelatedArtifactTypeKind["_composedOf"] = "composed-of";
})(RelatedArtifactTypeKind = exports.RelatedArtifactTypeKind || (exports.RelatedArtifactTypeKind = {}));
var EnumType_1 = require("../../EnumType");
exports.RTTI_RelatedArtifact = t.recursion('IRelatedArtifact', function () {
    return t.partial({
        id: t.string,
        extension: t.array(RTTI_Extension_1.RTTI_Extension),
        type: EnumType_1.createEnumType(RelatedArtifactTypeKind, 'RelatedArtifactTypeKind'),
        _type: RTTI_Element_1.RTTI_Element,
        label: t.string,
        _label: RTTI_Element_1.RTTI_Element,
        display: t.string,
        _display: RTTI_Element_1.RTTI_Element,
        citation: RTTI_markdown_1.RTTI_markdown,
        _citation: RTTI_Element_1.RTTI_Element,
        url: RTTI_url_1.RTTI_url,
        _url: RTTI_Element_1.RTTI_Element,
        document: RTTI_Attachment_1.RTTI_Attachment,
        resource: RTTI_canonical_1.RTTI_canonical
    });
});
