//Process gender enum value into associated gender string value (e.g. Male, Female, Unknown, etc.). 'Unknown' is default value.
export const processGender = (value: number) => {
  let gender: string = '';
  switch (value) {
    case 0:
      gender = 'Male';
      break;
    case 1:
      gender = 'Female';
      break;
    case 2:
      gender = 'Unknown';
      break;
    case 3:
      gender = 'Gender X';
      break;
    case 4:
      gender = 'Undifferentiated';
      break;
    default:
      break;
  }
  return gender;
};
