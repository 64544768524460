"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_OperationDefinition_Parameter = exports.OperationDefinition_ParameterSearchTypeKind = exports.OperationDefinition_ParameterUseKind = void 0;
var t = __importStar(require("io-ts"));
var RTTI_Extension_1 = require("./RTTI_Extension");
var RTTI_code_1 = require("../Scalar/RTTI_code");
var RTTI_Element_1 = require("./RTTI_Element");
var RTTI_integer_1 = require("../Scalar/RTTI_integer");
var RTTI_canonical_1 = require("../Scalar/RTTI_canonical");
var RTTI_OperationDefinition_Binding_1 = require("./RTTI_OperationDefinition_Binding");
var RTTI_OperationDefinition_ReferencedFrom_1 = require("./RTTI_OperationDefinition_ReferencedFrom");
var OperationDefinition_ParameterUseKind;
(function (OperationDefinition_ParameterUseKind) {
    OperationDefinition_ParameterUseKind["_in"] = "in";
    OperationDefinition_ParameterUseKind["_out"] = "out";
})(OperationDefinition_ParameterUseKind = exports.OperationDefinition_ParameterUseKind || (exports.OperationDefinition_ParameterUseKind = {}));
var OperationDefinition_ParameterSearchTypeKind;
(function (OperationDefinition_ParameterSearchTypeKind) {
    OperationDefinition_ParameterSearchTypeKind["_number"] = "number";
    OperationDefinition_ParameterSearchTypeKind["_date"] = "date";
    OperationDefinition_ParameterSearchTypeKind["_string"] = "string";
    OperationDefinition_ParameterSearchTypeKind["_token"] = "token";
    OperationDefinition_ParameterSearchTypeKind["_reference"] = "reference";
    OperationDefinition_ParameterSearchTypeKind["_composite"] = "composite";
    OperationDefinition_ParameterSearchTypeKind["_quantity"] = "quantity";
    OperationDefinition_ParameterSearchTypeKind["_uri"] = "uri";
    OperationDefinition_ParameterSearchTypeKind["_special"] = "special";
})(OperationDefinition_ParameterSearchTypeKind = exports.OperationDefinition_ParameterSearchTypeKind || (exports.OperationDefinition_ParameterSearchTypeKind = {}));
var EnumType_1 = require("../../EnumType");
exports.RTTI_OperationDefinition_Parameter = t.recursion('IOperationDefinition_Parameter', function () {
    return t.partial({
        id: t.string,
        extension: t.array(RTTI_Extension_1.RTTI_Extension),
        modifierExtension: t.array(RTTI_Extension_1.RTTI_Extension),
        name: RTTI_code_1.RTTI_code,
        _name: RTTI_Element_1.RTTI_Element,
        use: EnumType_1.createEnumType(OperationDefinition_ParameterUseKind, 'OperationDefinition_ParameterUseKind'),
        _use: RTTI_Element_1.RTTI_Element,
        min: RTTI_integer_1.RTTI_integer,
        _min: RTTI_Element_1.RTTI_Element,
        max: t.string,
        _max: RTTI_Element_1.RTTI_Element,
        documentation: t.string,
        _documentation: RTTI_Element_1.RTTI_Element,
        type: RTTI_code_1.RTTI_code,
        _type: RTTI_Element_1.RTTI_Element,
        targetProfile: t.array(RTTI_canonical_1.RTTI_canonical),
        searchType: EnumType_1.createEnumType(OperationDefinition_ParameterSearchTypeKind, 'OperationDefinition_ParameterSearchTypeKind'),
        _searchType: RTTI_Element_1.RTTI_Element,
        binding: RTTI_OperationDefinition_Binding_1.RTTI_OperationDefinition_Binding,
        referencedFrom: t.array(RTTI_OperationDefinition_ReferencedFrom_1.RTTI_OperationDefinition_ReferencedFrom),
        part: t.array(exports.RTTI_OperationDefinition_Parameter)
    });
});
