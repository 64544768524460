"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_Location = exports.LocationModeKind = exports.LocationStatusKind = void 0;
var t = __importStar(require("io-ts"));
var RTTI_id_1 = require("../Scalar/RTTI_id");
var RTTI_Meta_1 = require("./RTTI_Meta");
var RTTI_uri_1 = require("../Scalar/RTTI_uri");
var RTTI_Element_1 = require("./RTTI_Element");
var RTTI_code_1 = require("../Scalar/RTTI_code");
var RTTI_Narrative_1 = require("./RTTI_Narrative");
var RTTI_ResourceList_1 = require("../Union/RTTI_ResourceList");
var RTTI_Extension_1 = require("./RTTI_Extension");
var RTTI_Identifier_1 = require("./RTTI_Identifier");
var RTTI_Coding_1 = require("./RTTI_Coding");
var RTTI_CodeableConcept_1 = require("./RTTI_CodeableConcept");
var RTTI_ContactPoint_1 = require("./RTTI_ContactPoint");
var RTTI_Address_1 = require("./RTTI_Address");
var RTTI_Location_Position_1 = require("./RTTI_Location_Position");
var RTTI_Reference_1 = require("./RTTI_Reference");
var RTTI_Location_HoursOfOperation_1 = require("./RTTI_Location_HoursOfOperation");
var LocationStatusKind;
(function (LocationStatusKind) {
    LocationStatusKind["_active"] = "active";
    LocationStatusKind["_suspended"] = "suspended";
    LocationStatusKind["_inactive"] = "inactive";
})(LocationStatusKind = exports.LocationStatusKind || (exports.LocationStatusKind = {}));
var LocationModeKind;
(function (LocationModeKind) {
    LocationModeKind["_instance"] = "instance";
    LocationModeKind["_kind"] = "kind";
})(LocationModeKind = exports.LocationModeKind || (exports.LocationModeKind = {}));
var EnumType_1 = require("../../EnumType");
exports.RTTI_Location = t.recursion('ILocation', function () {
    return t.intersection([
        t.type({
            resourceType: t.literal('Location')
        }),
        t.partial({
            id: RTTI_id_1.RTTI_id,
            meta: RTTI_Meta_1.RTTI_Meta,
            implicitRules: RTTI_uri_1.RTTI_uri,
            _implicitRules: RTTI_Element_1.RTTI_Element,
            language: RTTI_code_1.RTTI_code,
            _language: RTTI_Element_1.RTTI_Element,
            text: RTTI_Narrative_1.RTTI_Narrative,
            contained: t.array(RTTI_ResourceList_1.RTTI_ResourceList),
            extension: t.array(RTTI_Extension_1.RTTI_Extension),
            modifierExtension: t.array(RTTI_Extension_1.RTTI_Extension),
            identifier: t.array(RTTI_Identifier_1.RTTI_Identifier),
            status: EnumType_1.createEnumType(LocationStatusKind, 'LocationStatusKind'),
            _status: RTTI_Element_1.RTTI_Element,
            operationalStatus: RTTI_Coding_1.RTTI_Coding,
            name: t.string,
            _name: RTTI_Element_1.RTTI_Element,
            alias: t.array(t.string),
            _alias: t.array(RTTI_Element_1.RTTI_Element),
            description: t.string,
            _description: RTTI_Element_1.RTTI_Element,
            mode: EnumType_1.createEnumType(LocationModeKind, 'LocationModeKind'),
            _mode: RTTI_Element_1.RTTI_Element,
            type: t.array(RTTI_CodeableConcept_1.RTTI_CodeableConcept),
            telecom: t.array(RTTI_ContactPoint_1.RTTI_ContactPoint),
            address: RTTI_Address_1.RTTI_Address,
            physicalType: RTTI_CodeableConcept_1.RTTI_CodeableConcept,
            position: RTTI_Location_Position_1.RTTI_Location_Position,
            managingOrganization: RTTI_Reference_1.RTTI_Reference,
            partOf: RTTI_Reference_1.RTTI_Reference,
            hoursOfOperation: t.array(RTTI_Location_HoursOfOperation_1.RTTI_Location_HoursOfOperation),
            availabilityExceptions: t.string,
            _availabilityExceptions: RTTI_Element_1.RTTI_Element,
            endpoint: t.array(RTTI_Reference_1.RTTI_Reference)
        })
    ]);
});
