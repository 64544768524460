import React from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '../../models';
import Role from '../../models/role';
import {AbilityContext} from '../../services/roles/ability-context';
import {BaseEntityV2, FormConfig} from '../base-entity/base-entity';
import {FHIRTableMode, FHIRTableProps} from '../fhir-table';
import {FormUX} from '../form-ux';
import {OrganizationPickerFormItem} from '../organization/organization-picker';
import {RoleFormCustomFieldName, RoleFormUXModel} from './role-form-ux-model';

export const RoleUX = () => {
  const globalOrg = useSelector((state: RootState) => state.ui.localOrg);

  const CantDeleteRole = () => {
    const ability = React.useContext(AbilityContext);
    return !ability?.can('delete', 'roles');
  };

  const fixedFilters: any = globalOrg
    ? {
        _organizationId: globalOrg.id,
        includeFields: true,
        assignable: true,
      }
    : {includeFields: true, assignable: true};

  const columns = [
    {
      title: `Name`,
      dataIndex: 'name',
      key: 'name',
      sorter: true,
    },
    {title: 'Description', dataIndex: 'description', key: 'description'},
  ];

  const model = Role;

  const itemTitleField = 'name';

  const FormItems = (props) => {
    const {parentOrganizationId, id} = props.values;
    return (
      <FormUX
        formUXModel={RoleFormUXModel}
        createMode={props.createMode}
        modal={props.modal}
        renderCustomField={(field) => {
          switch (field.name) {
            case RoleFormCustomFieldName.OrganizationId: {
              return (
                <OrganizationPickerFormItem
                  label={field.label}
                  name={field.name}
                  setFieldValue={props.setFieldValue}
                  validationRules={field.validationRules}
                />
              );
            }
            default: {
              throw new Error(`Unhandled custom field ${field.name} in
          renderCustomField method`);
            }
          }
        }}
      />
    );
  };

  const formConfig: FormConfig = {
    defaultValues: {
      organizationId: globalOrg?.id,
    },
    FormItems,
  };

  const tableConfig: FHIRTableProps = {
    fhirResource: 'role',
    label: 'Role',
    fixedFilters,
    mode: 'table' as FHIRTableMode,
    showCreateButton: false,
    defaultPrimarySearchParam: 'name',
    columns,
    onClickRow: () => {},
  };

  const config = {
    slug: 'role',
    fhirResource: 'role',
    model,
    itemTitleField,
    formConfig,
    tableConfig,
    hideEditButton: true,
    hideDeleteButton: CantDeleteRole,
    hideSaveButton: true,
    modalCreateForm: true,
    titleBarEntityLabel: 'Role',
  };
  return <BaseEntityV2 {...config} />;
};
