import {GenderUtil} from '@canimmunize/tools';
import {ColumnsType} from 'antd/lib/table';
import React from 'react';
import {importerConfigs} from '.';
import {fullDisplayName} from '../../../models/fhir/patient';
import {
  RequiredBirthDateValidationRules,
  RequiredGenderValidationRules,
  RequiredShortTextValidationRules,
} from '../../../services/ui-validation-rules';
import {EmailValidationRuleId} from '../../../validation/validation-rules/email-validation';
import {HcnTypeValidationRuleId} from '../../../validation/validation-rules/hcn-type-validation';
import {HcnValidationRuleId} from '../../../validation/validation-rules/hcn-validation';
import {RequiredValidationRuleId} from '../../../validation/validation-rules/required-validation';
import {ValidationRules} from '../../../validation/validation-rules/validation-rules';
import {ImporterModal} from './importer-modal';

interface CITFPatientUploadProps {
  visible: boolean;
  onClose: () => void;
  cohort?: any;
}

export const CITFPatientUploadModal = ({visible, onClose, cohort}: CITFPatientUploadProps) => {
  const {columns, templateName} = importerConfigs['citf-patient-import'];
  return (
    <ImporterModal
      visible={visible}
      preProcessData={(row) => row}
      title={'Upload CITF Patient Import File'}
      onClose={onClose}
      targetSheetName={'Template - To Complete'}
      skipLines={2}
      howToUse="Use the downloadable Excel template to upload patients into ClinicFlow. Uploaded patients will be matched and updated if they already exist in the system."
      templateName={templateName}
      uploadedColumns={columns}
      getValidationRules={CITFCSVValidation}
    ></ImporterModal>
  );
};

export const CITFCSVValidation = (
  cohortRow
): {
  [property: string]: ValidationRules[];
} => {
  const basicValidation: {
    [property: string]: ValidationRules[];
  } = {
    email: [
      {
        validationRuleType: EmailValidationRuleId,
      },
    ],
  };

  return {
    ...basicValidation,
    firstName: RequiredShortTextValidationRules,
    lastName: RequiredShortTextValidationRules,
    gender: RequiredGenderValidationRules,
    birthdate: RequiredBirthDateValidationRules,
    hcn: [
      {
        validationRuleType: RequiredValidationRuleId,
      },
      ...RequiredShortTextValidationRules.concat({validationRuleType: HcnValidationRuleId}),
    ],
    hcnType: [
      {
        validationRuleType: RequiredValidationRuleId,
      },
      {
        validationRuleType: HcnTypeValidationRuleId,
      },
    ],
  };
};
