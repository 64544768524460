import React from 'react';
import {Card} from 'reactstrap';
import {useUrlData} from '../util';
import s from '../styles.module.css';

export interface BFCardProps {
  selected?: boolean;
  children?: any;
  selectable?: boolean;
  style?: any;
  onClick?: () => void;
}

export const BFCard = (props: BFCardProps) => {
  const {config} = useUrlData();

  const {selected, children, selectable, style} = props;
  const [hover, setHover] = React.useState(false);

  const color = config.primaryColor || '#265ed6';
  return (
    <Card
      {...props}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={
        selectable
          ? selected
            ? s.appointmentTypeCardSelected
            : s.appointmentTypeCardSelectable
          : s.appointmentTypeCard
      }
      style={{
        backgroundColor: selected ? color : 'white',
        borderColor: selectable && (hover || selected) ? color : 'white',
        ...style,
      }}
    >
      {children}
    </Card>
  );
};
