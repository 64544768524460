import {Button, message, Popconfirm, Space, Spin} from 'antd';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {FhirUtils} from '../../../services/fhir';

export const BookingPageInvitationListActions = (props) => {
  const {list, bookingPageId, refreshTable} = props;
  const client = FhirUtils.useAxiosClient();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);

  const onRemove = async () => {
    setLoading(true);
    const result = await client.put(`/list/link`, {
      action: 'unlink',
      listId: list.id,
      itemId: bookingPageId,
      itemType: 'bookingPage',
    });

    if (result.status === 200) {
      refreshTable();
      message.success('List was successfully removed from booking page.');
    } else {
      message.error('Error removing list from booking page.');
    }

    setLoading(false);
  };

  return (
    <Space>
      <Popconfirm
        overlayStyle={{maxWidth: 400}}
        title={
          <div>
            <div>
              <b>Are you sure you want to remove this invitation list from this booking page?</b>
            </div>
            {`Patients that have already been sent invitations emails from this list WILL NOT be able to book appointments using the link in those emails, unless there are no other invitation lists set for this booking page.`}
          </div>
        }
        onConfirm={onRemove}
        okText="Yes"
        cancelText="No"
      >
        <Space>
          <Button type="link" danger size={'small'} style={{paddingLeft: 10, paddingRight: 10}}>
            Remove
          </Button>
          {loading && <Spin size="small" />}
        </Space>
      </Popconfirm>
    </Space>
  );
};
