import {faFileAlt, faFileDownload} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, message, Space} from 'antd';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {FhirUtils} from '../../services/fhir';

export const ReportActions = (props) => {
  const {report} = props;
  const client = FhirUtils.useAxiosClient();
  const navigate = useNavigate();

  const openReport = async (report) => {
    try {
      const presignedUrl = await client.get(`/extracts/${report.fileName}/open`);
      window.open(presignedUrl.data.presignedUrl, '_blank');
    } catch (err) {
      message.error('Failed to open report.');
    }
  };

  const downloadReport = async (report) => {
    try {
      const presignedUrl = await client.get(`/extracts/${report.fileName}/download`);
      window.open(presignedUrl.data.presignedUrl, '_blank');
    } catch (err) {
      message.error('Failed to download report.');
    }
  };

  return (
    <Space>
      <Button
        type="primary"
        size={'small'}
        target="_blank"
        style={{paddingLeft: 10, paddingRight: 10}}
        onClick={() => openReport(report)}
      >
        <FontAwesomeIcon icon={faFileAlt} color="white" style={{marginRight: 10}} />
        Open
      </Button>
      <Button
        type="primary"
        size={'small'}
        target="_blank"
        style={{paddingLeft: 10, paddingRight: 10}}
        onClick={() => downloadReport(report)}
      >
        <FontAwesomeIcon icon={faFileDownload} color="white" style={{marginRight: 10}} />
        Download
      </Button>
    </Space>
  );
};
