import {Button, Modal, message, Popconfirm, Space} from 'antd';
import {DeleteOutlined} from '@ant-design/icons';
import {Formik, FormikProps, FormikHelpers} from 'formik';
import React from 'react';
import moment from 'moment';

import {FormUX} from '../../form-ux';
import {FhirUtils} from '../../../services/fhir';
import {generateFlagFormFields} from './flag-form-fields';
import {useUrlData} from '../../scheduler/util';
import {AbilityContext} from '../../../services/roles/ability-context';
import {DebounceFormItem} from '../imm-modal/debounce-form-item';

export const UpdateFlagModal = (props) => {
  const {visible, onClose, flag, afterSubmit, afterDelete, patient} = props;
  const {syncUrl} = useUrlData();
  const client = FhirUtils.useAxiosClient();
  const ability = React.useContext(AbilityContext);

  const [submitting, setSubmitting] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);
  const onModalClose = () => onClose?.();

  /* Function called when the 'Submit' button is pressed. */
  const onSubmit = (values, helpers: FormikHelpers<any>) => {
    /* If the user changed the start date, the UTC time returned from the
       picker needs to be converted into local time. */
    const startDateChanged = values.startDate != flag?.startDate;
    let startDate = values.startDate ? moment.utc(values.startDate) : undefined;
    if (startDate && startDateChanged) startDate = startDate.local();

    /* Same logic as the above needs to be applied to the end date. */
    const endDateChanged = values.endDate != flag?.endDate;
    let endDate = values.endDate ? moment.utc(values.endDate) : undefined;
    if (endDate && endDateChanged) endDate = endDate.local();

    /* Create the flag. */
    const flagToSubmit = {
      ...values,
      id: flag?.id,
      patientId: patient?.id,
      startDate: startDate?.format() ?? null,
      endDate: endDate?.format() ?? null,
    };

    /* Make the flag submission request to the server and handle the response. */
    setSubmitting(true);
    client
      .post(syncUrl + '/flag', flagToSubmit)
      .then((res) => {
        setSubmitting(false);
        message.success('Flag successfully submitted!');
        afterSubmit?.();
        onModalClose();
      })
      .catch((err) => {
        setSubmitting(false);
        message.error('Encountered an error trying to submit the flag.');
        console.warn(err);
      });
  };

  /* Function called when the 'Delete' button is pressed. */
  const onDelete = () => {
    /* Make the flag submission request to the server and handle the response. */
    setDeleting(true);
    client
      .delete(syncUrl + `/flag/${flag.id}`)
      .then((res) => {
        setDeleting(false);
        message.success('Flag successfully deleted.');
        afterDelete?.();
        onModalClose();
      })
      .catch((err) => {
        setDeleting(false);
        message.error('Encountered an error trying to delete the flag.');
        console.warn(err);
      });
  };

  /* Title bar displays appropriate title and delete button. */
  const titleBar = (
    <div style={{display: 'flex', justifyContent: 'space-between'}}>
      <label>{flag ? 'Edit Flag for Patient' : 'New Flag for Patient'}</label>
      {/* Only show the delete button in edit mode. */}
      {flag && ability.can('delete', 'patients', 'flag') && (
        <Popconfirm
          title={`Are you sure you want to delete this flag instead of modifying its end date?`}
          onConfirm={onDelete}
          okText="Yes"
          cancelText="No"
          placement="leftTop"
        >
          <Button key="delete" danger>
            <DeleteOutlined /> Delete
          </Button>
        </Popconfirm>
      )}
    </div>
  );

  /* Return the Formik-wrapped CreateFlagModal. */
  return (
    <Formik
      initialValues={{...flag}}
      onSubmit={onSubmit}
      enableReinitialize
      validateOnChange={true}
    >
      {(formikProps: FormikProps<any>) => {
        /* The footer of the modal. */
        const footer = (
          <div>
            <Button onClick={onModalClose}>Cancel</Button>
            <Button
              type="primary"
              onClick={() => formikProps.handleSubmit()}
              disabled={!formikProps.dirty || !formikProps.isValid}
              loading={submitting}
            >
              Submit
            </Button>
          </div>
        );

        /* Return the modal containing the formUX for editing/creating flags. */
        return (
          <Modal
            title={titleBar}
            visible={visible}
            onCancel={onModalClose}
            onOk={onModalClose}
            footer={footer}
            closable={false}
            destroyOnClose={true}
          >
            <FormUX
              formUXModel={generateFlagFormFields()}
              renderCustomField={(field) => {
                switch (field.name) {
                  case 'type':
                    return (
                      <DebounceFormItem
                        {...field}
                        initialValues={flag}
                        form={formikProps}
                        editable={field.editable}
                      />
                    );
                  default: {
                    throw new Error(`Unhandled custom field ${field.name} in
                            renderCustomField method`);
                  }
                }
              }}
              createMode
            />
          </Modal>
        );
      }}
    </Formik>
  );
};

/* TODO - make this not hard coded */
export const initialFlagTypes = (type) => {
  switch (type) {
    case 'africanNovaScotian':
      return 'African Nova Scotian';
    case 'firstNation':
      return 'First Nation';
    case 'immunocompromised':
      return 'Immunocompromised';
    case 'LTCOrSeniorCongregateLiving':
      return 'LTC or Senior Congregate Living Setting';
    case 'nonHCApprovedDoses':
      return 'Non-HC Approved Vaccines';
    case 'otherCongregateLiving':
      return 'Other Congregate Living Setting';
    case 'pregnant':
      return 'Pregnant';
    case 'workOrTravelException':
      return 'Work/Travel Related';
    default:
      return '';
  }
};
