import {Ids, StrLang} from '@canimmunize/tools';
import {useStr} from '../../services/str';
import React, {useState} from 'react';
import {useParams} from 'react-router-dom';
import {BisectedPageLayout} from '../../components/scheduler/booking-form/BisectedPageLayout';
import {useUrlData} from '../../components/scheduler/util';
import {Action, Values} from './config';
import {AuthPage} from './pages/auth';
import {SummaryPage} from './pages/summary';
import {COVIDPortalConfigProvider, useCOVIDPortalConfig} from './util/portal-config';
import {ReceiptInstructions} from './components/receipt-instructions';
import {ReceiptTitle} from './components/receipt-title';
import {SessionConfigProvider} from './util/session-config';
import {useQuery} from '../../components/base-entity/base-entity';

export interface VaccineReceiptProps {
  mode: 'receipt' | 'portal';
  isYukonCallCentre?: boolean;
}

export const VaccineReceipt = (props: VaccineReceiptProps) => {
  const {mode, isYukonCallCentre = false} = props;
  const query = useQuery();
  const {lang} = useUrlData();
  const Str = useStr();

  const {id} = useParams<{id: string}>();
  const email = query.get('email') || '';
  const [page, setPage] = useState(id ? 1 : 0);

  // Set up state for record values and clinic entry data
  const [values, setValues] = useState<Values>({});
  const [clinicEntry, setClinicEntry] = useState<null | any>(null);

  // Define a function for updating state record values to determine import
  const updateValue = (recordId: string, value: Action) => () => {
    const newValues = {...values, [recordId]: value};
    setValues(newValues);
  };

  // Define basic page navigation functions
  const pages = [AuthPage, SummaryPage, SummaryPage];
  const nextPage = () => setPage(Math.min(page + 1, pages.length));
  const prevPage = () => setPage(Math.max(page - 1, 0));

  // Get current view using page config and state value
  let PageComponent = pages[page];

  const NSHeader = () => {
    return (
      <>
        <div className="hide-on-print">
          <h1>Your Nova Scotia COVID-19 Proof of Vaccination</h1>
          {page < 2 ? (
            <>
              <p>
                You can securely access your COVID-19 proof of vaccination. Your record will be
                updated after each immunization.
              </p>
              <p>Access your COVID-19 proof of vaccination by entering your information.</p>
            </>
          ) : (
            <p>{StrLang(lang, Ids.find_summary_below)}</p>
          )}
        </div>
        <div className="show-on-print">
          <ReceiptTitle />
          <ReceiptInstructions />
        </div>
      </>
    );
  };

  const Page1Header = () => {
    const {config} = useCOVIDPortalConfig();
    const {lang} = useUrlData();
    switch (config.portalAuthSchemeKey) {
      case 'pei':
        return (
          <>
            <h1>{StrLang(lang, Ids.my_covid_immunization_record)}</h1>
            <p>{StrLang(lang, Ids.fill_form_to_access_record)}</p>
          </>
        );
      case 'bruyere':
        return (
          <>
            <h1>{StrLang(lang, Ids.bruyere_your_digital_immunization_record)}</h1>
            <p>{StrLang(lang, Ids.bruyere_securely_access_flu)}</p>
          </>
        );
      case 'yukon':
        return (
          <>
            <h1>{Str(Ids.yk_pvc_title)}</h1>
          </>
        );
      default:
        return <NSHeader />;
    }
  };

  const Page2Header = () => {
    const {config} = useCOVIDPortalConfig();
    switch (config.portalAuthSchemeKey) {
      case 'pei':
        return (
          <>
            <h1>{StrLang(lang, Ids.my_covid_immunization_record)}</h1>
            <p>{StrLang(lang, Ids.pei_find_summary_below)}</p>
            <p>{StrLang(lang, Ids.pei_copy_and_store)}</p>
            <p>{StrLang(lang, Ids.pei_report_error)}</p>
          </>
        );
      case 'bruyere':
        return (
          <>
            <h1>{StrLang(lang, Ids.bruyere_your_digital_immunization_record)}</h1>
            <p>{StrLang(lang, Ids.bruyere_find_summary_below)}</p>
          </>
        );
      case 'yukon':
        return (
          <>
            <h1>{Str(Ids.yk_pvc_title)}</h1>
          </>
        );
      default:
        return <NSHeader />;
    }
  };

  const Header = () => {
    return (
      <div>
        {(page === 0 || page === 1) && <Page1Header />}
        {page === 2 && <Page2Header />}
      </div>
    );
  };

  return (
    <SessionConfigProvider>
      <COVIDPortalConfigProvider keepTheme={false} keepHeader={isYukonCallCentre}>
        <BisectedPageLayout
          header={<Header />}
          hideOnPrint={true}
          isYukonCallCentre={isYukonCallCentre}
        >
          <PageComponent
            email={email}
            values={values}
            isLoaded={true}
            clinicEntry={clinicEntry}
            setClinicEntry={setClinicEntry}
            updateValue={updateValue}
            nextPage={nextPage}
            prevPage={prevPage}
            setPage={setPage}
            mode={mode}
            isYukonCallCentre={isYukonCallCentre}
          />
        </BisectedPageLayout>
      </COVIDPortalConfigProvider>
    </SessionConfigProvider>
  );
};
