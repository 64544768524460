import {
  Button,
  Col,
  Descriptions,
  message,
  Modal,
  notification,
  Row,
  Space,
  Tag,
  Typography,
} from 'antd';
import moment from 'moment';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {history} from '../../history';
import {RootState, ThunkDispatch} from '../../models';
import {fullDisplayName} from '../../models/fhir/patient';
import OrgAdminUser from '../../models/org-admin-users';
import {FhirUtils} from '../../services/fhir';
import {BaseEntityUXProps, BaseEntityV2} from '../base-entity/base-entity';
import {FHIRTableProps} from '../fhir-table';
import {FormUX} from '../form-ux/form-ux';
import {OrganizationMultiPickerFormItem} from '../organization/organization-multi-picker';
import {RoleMultiPickerFormItem} from '../organization/role-multi-picker';
import {OrgAdminUserFormCustomFieldName, OrgAdminUserFormUXModel} from './org-admin-user-fields';
import {OrganizationAdminMembershipTable} from './org-admin-user-membership';
import {OrganizationAdminRoleMembershipTable} from './org-admin-user-role-membership';
import {AccountStatusTag, EmailVerificationStatusTag} from './org-admin-user-status-fields';
import {UserPasswordFormItem, UserPasswordItem} from './user-password-field';

const {Title, Paragraph} = Typography;

const UserCopyableValue = (props: {value: string; align?: any}) => {
  const textAlign = props.align || 'start';
  return (
    <Paragraph style={{textAlign, marginBottom: 0}} code copyable>
      {props.value || ''}
    </Paragraph>
  );
};

export const OrgAdminUserUX = (props: Partial<BaseEntityUXProps>) => {
  const globalOrg = useSelector((state: RootState) => state.ui.localOrg);

  const navigate = useNavigate();
  const model = OrgAdminUser;

  const orgTagList = (organizations: any[], max?: number) => {
    const numOrgs = organizations.length;
    const showMax = max || numOrgs;

    let orgList = [...organizations]
      .sort((a, b) => a.nameEn.localeCompare(b.nameEn))
      .slice(0, showMax)
      .reduce((a, o) => [...a, <Tag key={o.organizationCode}>{o.nameEn}</Tag>], []);
    if (showMax < numOrgs) orgList.push(<span key="more">+ {numOrgs - showMax} more</span>);
    return orgList;
  };

  const roleTagList = (roles: any[], max?: number) => {
    const numRoles = roles.length;
    const showMax = max || numRoles;

    let roleList = [...roles]
      .sort((a, b) => a.name.localeCompare(b.name))
      .slice(0, showMax)
      .reduce((a, o) => [...a, <Tag key={o.id}>{o.name}</Tag>], []);
    if (showMax < numRoles) roleList.push(<span key="more">+ {numRoles - showMax} more</span>);
    return roleList;
  };

  const FormItems = (props: any) => {
    const client = FhirUtils.useClient();
    const thunkDispatch = useDispatch<ThunkDispatch>();

    const isAccountActive = !!props.values?.active;
    const isEmailVerified = !!props.values?.emailVerified;
    const isUpdatable = !!props.values?.updatable;

    const onError = (err) => {
      notification['error']({
        message: 'Server Error',
        description: err.message,
      });
    };

    const showAccountActivationConfirm = () => {
      Modal.confirm({
        title: props.values.active
          ? 'Are you sure you wish to deactivate the account with email:'
          : 'Are you sure you wish to enable the account with email:',
        content: <UserCopyableValue value={props.values.email} />,
        onOk: async () => {
          try {
            await thunkDispatch(
              OrgAdminUser.updateOne(
                client,
                {id: props.values.id, active: !props.values.active},
                {_organizationId: globalOrg?.id}
              )
            );
            message.success(
              props.values.active
                ? `Successfully deactivated user ${props.values.email}`
                : `Successfully enabled user ${props.values.email}`
            );
          } catch (error) {
            message.error(
              props.values.active
                ? `Failed to deactivate user ${props.values.email}`
                : `Failed to enable user ${props.values.email}`
            );
          }
        },
      });
    };

    const showResetPasswordConfirm = () => {
      let password = '';
      Modal.confirm({
        title: 'Reset password for account:',
        content: (
          <>
            <UserCopyableValue value={props.values.email} />
            <div style={{height: '12px', width: '100%'}} />
            <UserPasswordItem setFieldValue={(n, v) => (password = v)} />
          </>
        ),
        onOk: async () => {
          try {
            const res = await thunkDispatch(
              OrgAdminUser.updateOne(
                client,
                {id: props.values.id, password},
                {_organizationId: globalOrg?.id}
              )
            );
            Modal.success({
              content: (
                <div>
                  <Paragraph style={{marginBottom: 0}}>Password successfully reset</Paragraph>
                  {res.password?.length && (
                    <UserCopyableValue value={res.password} align="center" />
                  )}
                </div>
              ),
            });
          } catch (error) {
            Modal.error({
              title: 'Error updating password.',
              width: 500,
              content: <Paragraph>Failed to reset password for {props.values.email}.</Paragraph>,
            });
          }
        },
      });
    };

    const showResendEmailConfirm = () => {
      Modal.confirm({
        title: 'Do you want to resend an email verification request to:',
        content: <UserCopyableValue value={props.values.email} />,
        onOk: async () => {
          try {
            await thunkDispatch(
              OrgAdminUser.updateOne(
                client,
                {id: props.values.id, verifyEmail: true},
                {_organizationId: globalOrg?.id}
              )
            );
            message.success(`An email verification request has been sent to ${props.values.email}`);
          } catch (error) {
            message.error(`Failed to send an email verification request to ${props.values.email}`);
          }
        },
      });
    };

    if (props.createMode || props.editMode) {
      return (
        <FormUX
          formUXModel={OrgAdminUserFormUXModel}
          createMode={props.createMode}
          modal={props.modal}
          renderCustomField={(field) => {
            switch (field.name) {
              case OrgAdminUserFormCustomFieldName.Organizations: {
                return (
                  <OrganizationMultiPickerFormItem
                    label={field.label}
                    name={field.name}
                    setFieldValue={(name, value) => props.setFieldValue(name, value)}
                    validationRules={field.validationRules}
                    required
                  />
                );
              }
              case OrgAdminUserFormCustomFieldName.Roles: {
                return (
                  <RoleMultiPickerFormItem
                    label={field.label}
                    name={field.name}
                    setFieldValue={(name, value) => props.setFieldValue(name, value)}
                    validationRules={field.validationRules}
                    required
                  />
                );
              }
              case OrgAdminUserFormCustomFieldName.Password: {
                return (
                  <UserPasswordFormItem
                    label={field.label}
                    name={field.name}
                    setFieldValue={props.setFieldValue}
                    validationRules={field.validationRules}
                  />
                );
              }
              default: {
                throw new Error(`Unhandled custom field ${field.name}
              in renderCustomField method`);
              }
            }
          }}
        />
      );
    }

    return (
      <>
        <Row gutter={[15, 15]} style={{marginBottom: '15px'}}>
          <Col lg={24}>
            {/* <Card style={{marginBottom: '8px'}}> */}
            <Title level={4}>User Info</Title>
            <Descriptions bordered column={10} size="small" style={{marginBottom: '16px'}}>
              <Descriptions.Item span={5} label="First Name">
                {props.values.firstName}
              </Descriptions.Item>
              <Descriptions.Item span={5} label="UserId">
                <Paragraph code copyable>
                  {props.values.id}
                </Paragraph>
              </Descriptions.Item>
              <Descriptions.Item span={5} label="Last Name">
                {props.values.lastName}
              </Descriptions.Item>
              <Descriptions.Item span={5} label="Email">
                {props.values.email}
              </Descriptions.Item>
              <Descriptions.Item label="Account Status" span={5}>
                <AccountStatusTag active={isAccountActive} />
              </Descriptions.Item>
              <Descriptions.Item label="Email Status" span={5}>
                <EmailVerificationStatusTag verified={isEmailVerified} />
              </Descriptions.Item>
              <Descriptions.Item span={5} label="Last Login">
                {props.values.lastLogin && moment(props.values.lastLogin).format('LLL')}
              </Descriptions.Item>
            </Descriptions>
            <OrganizationAdminRoleMembershipTable
              organizationAdminUser={props.values}
              updatable={isUpdatable}
            />
            <OrganizationAdminMembershipTable
              organizationAdminUser={props.values}
              updatable={isUpdatable}
            />
            {isUpdatable && (
              <Space direction="horizontal" size="middle" style={{marginTop: '8px', width: '100%'}}>
                {isAccountActive && (
                  <Button type="primary" onClick={() => showResetPasswordConfirm()}>
                    Reset Password
                  </Button>
                )}
                <Button type="primary" onClick={() => showAccountActivationConfirm()}>
                  {isAccountActive ? 'Deactivate Account' : 'Enable Account'}
                </Button>
                {isAccountActive && !isEmailVerified && (
                  <Button type="primary" onClick={() => showResendEmailConfirm()}>
                    Resend Verify Email
                  </Button>
                )}
              </Space>
            )}
            {/* </Card> */}
          </Col>
        </Row>
      </>
    );
  };

  const fixedFilters = {
    _organizationId: globalOrg?.id || '',
    _filterField: 'blocked',
    _filterValues: ['false'],
  };

  const tableConfig: FHIRTableProps = {
    fhirResource: 'org-admin-user',
    label: 'User',
    fixedFilters,
    mode: 'table',
    showCreateButton: true,
    defaultPrimarySearchParam: 'name',
    columns: [
      {
        title: 'Last Name',
        dataIndex: 'lastName',
        key: 'lastName',
      },
      {
        title: 'First Name',
        dataIndex: 'firstName',
        key: 'firstName',
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: 'Active',
        dataIndex: 'active',
        key: 'blocked',
        render: (_, user) => <AccountStatusTag active={!!user.active} />,
        filters: [
          {text: 'Active', value: 'false'},
          {text: 'Disabled', value: 'true'},
        ],
      },
      {
        title: 'Role',
        dataIndex: 'role',
        key: 'role',
        render: (_, user) => roleTagList(user.roles || [], 3),
      },
      {
        title: 'Organizations',
        key: 'organizations',
        render: (_, user) => orgTagList(user.organizations || [], 3),
      },
    ],
  };

  const config = {
    model,
    slug: 'orgadminuser',
    searchPageTitle: 'Organization Admin Users',
    showSearchTitle: false,
    modalCreateForm: true,
    useGlobalOrgFilter: true,
    onCreationSuccess: (res) => {
      const hasPassword = !!res.password;
      const msg = hasPassword
        ? 'User successfully created with password.'
        : 'User successfully created.';
      Modal.success({
        content: (
          <div>
            <Paragraph style={{marginBottom: 0}}>{msg}</Paragraph>
            {hasPassword && <UserCopyableValue value={res.password} align="center" />}
          </div>
        ),
        afterClose: () => {
          history.replace(`/orgadminusers`);
          navigate(`/orgadminusers/${res.id}`);
        },
      });
    },
    itemTitleField: (u) =>
      u.firstName?.trim() || u.lastName?.trim() ? fullDisplayName(u) : u.email || '',
    formConfig: {FormItems},
    tableConfig,
    hideDeleteButton: (item) => !item?.deletable,
    hideEditButton: (item) => !item?.updatable,
    ...props,
  };

  return <BaseEntityV2 {...config} />;
};
