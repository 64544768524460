import {CaretRightOutlined} from '@ant-design/icons';
import {Ids} from '@canimmunize/tools';
import {Alert, Button, Collapse} from 'antd';
import axios from 'axios';
import {FormikContextType, useFormikContext} from 'formik';
import {Form, Radio} from 'formik-antd';
import parse from 'html-react-parser';
import React from 'react';
import {useUrlData} from '../../../../../components/scheduler/util';
import {ValidateFormItem} from '../../../../../components/validate-form-item';
import {useStr} from '../../../../../services/str';
import {RequiredValidationRuleId} from '../../../../../validation/validation-rules/required-validation';
import {YukonPortalPage} from '../../../components/yukon-portal-page';
import {useCOVIDPortalConfig} from '../../../util/portal-config';

export const IdVerificationPage = (props) => {
  const {lang} = useUrlData();
  const Str = useStr();
  const formikContext: FormikContextType<any> = useFormikContext();
  const {syncUrl} = useCOVIDPortalConfig();
  const [submitting, setSubmitting] = React.useState(false);

  const postSubmit = async () => {
    setSubmitting(true);
    formikContext.setFieldValue('error', undefined);
    const result = await axios
      .post(`${syncUrl}/public/portal/send-code`, {
        contactMethod: formikContext.values.selectedAuthContactMethod,
        method: formikContext.values.authMethod,
        portalId: formikContext.values.portalId,
        language: lang,
      })
      .catch((err) => err.response);

    setSubmitting(false);
    if (result.status === 200) {
      formikContext.setFieldValue('mode', 'authentication');
    } else if (result.status === 403) {
      const errorId = result?.data?.error?.stringId;
      formikContext.setFieldValue(
        'error',
        <>{parse(Str(Ids[errorId] ?? Ids.yk_pvc_system_error))}</>
      );
      window.scrollTo(0, 0);
    } else {
      formikContext.setFieldValue('error', <>{parse(Str(Ids.yk_pvc_system_error))}</>);
      window.scrollTo(0, 0);
    }
  };

  const pageBody = (
    <>
      {formikContext.values.authContactMethods &&
        formikContext.values.authContactMethods.length !== 0 && (
          <div style={{fontSize: '14px', marginBottom: '20px'}}>
            {parse(Str(Ids.yk_verification_info))}
          </div>
        )}
      {formikContext.values.error && (
        <Alert
          type="error"
          message={formikContext.values.error}
          style={{marginBottom: 15}}
          showIcon
        />
      )}

      {formikContext.values.authContactMethods &&
        formikContext.values.authContactMethods.length !== 0 && (
          <>
            <ValidateFormItem
              validationRules={[
                {
                  validationRuleType: RequiredValidationRuleId,
                },
              ]}
              renderFormItem={(validate) => {
                return (
                  <Form.Item
                    label={<b>{Str(Ids.yk_auth_method)}</b>}
                    name="selectedAuthContactMethod"
                    required
                    validate={validate}
                  >
                    <Radio.Group name="selectedAuthContactMethod">
                      {formikContext.values.authContactMethods?.map((m) => {
                        return (
                          <Radio
                            name="selectedAuthContactMethod"
                            key={m.value}
                            value={m}
                            style={{display: 'block'}}
                          >
                            {m?.maskedValue}
                          </Radio>
                        );
                      })}
                    </Radio.Group>
                  </Form.Item>
                );
              }}
            />
            {formikContext.values.selectedAuthContactMethod?.type === 'phone' && (
              <ValidateFormItem
                validationRules={[
                  {
                    validationRuleType: RequiredValidationRuleId,
                  },
                ]}
                renderFormItem={(validate) => {
                  return (
                    <Form.Item
                      label={<b>{Str(Ids.yk_auth_reception)}</b>}
                      name="authMethod"
                      required
                      validate={validate}
                    >
                      <Radio.Group
                        options={[
                          {
                            label: (
                              <>
                                {Str(Ids.yk_auth_call)}
                                <small style={{display: 'block', marginLeft: 24}}>
                                  {Str(Ids.yk_auth_call_note)}
                                </small>
                              </>
                            ),
                            value: 'call',
                            style: {display: 'block'},
                          },
                          {
                            label: (
                              <>
                                {Str(Ids.yk_auth_text)}
                                <small style={{display: 'block', marginLeft: 24}}>
                                  {Str(Ids.yk_auth_text_note)}
                                </small>
                              </>
                            ),
                            value: 'sms',
                            style: {display: 'block'},
                          },
                        ]}
                        name="authMethod"
                      ></Radio.Group>
                    </Form.Item>
                  );
                }}
              />
            )}
          </>
        )}

      {formikContext.values.authContactMethods.length !== 0 && (
        <Collapse
          expandIcon={({isActive}) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
          className="site-collapse-custom-collapse"
          style={{marginBottom: 24}}
        >
          <Collapse.Panel
            header={Str(Ids.yk_auth_no_options)}
            key="1"
            className="site-collapse-custom-collapse"
          >
            <p>{parse(Str(Ids.yk_auth_no_options_info))}</p>
          </Collapse.Panel>
        </Collapse>
      )}
    </>
  );

  const pageFooter = (
    <>
      <Button
        size="large"
        onClick={() => {
          formikContext.setFieldValue('error', undefined);
          formikContext.setFieldValue('selectedAuthContactMethod', null);
          formikContext.setFieldValue('mode', 'hci');
        }}
        style={{
          border: '1px solid',
          marginRight: '10px',
          marginBottom: '10px',
          width: '200px',
        }}
      >
        {Str(Ids.yk_pvc_go_back_step)}
      </Button>
      <Button
        size="large"
        type="primary"
        loading={submitting}
        disabled={
          !formikContext.values.authContactMethods ||
          formikContext.values.authContactMethods.length === 0
        }
        onClick={() => {
          formikContext.setFieldValue('subFormSubmit', postSubmit);
          formikContext.handleSubmit();
        }}
        style={{
          width: '200px',
        }}
      >
        {Str(Ids.yk_pvc_send_code_now)}
      </Button>
    </>
  );

  return (
    <>
      <YukonPortalPage
        pageNumber={Str(Ids.yk_pvc_page4)}
        title={Str(Ids.yk_pvc_verification)}
        body={pageBody}
        footer={pageFooter}
        backPage="hci"
      ></YukonPortalPage>
    </>
  );
};
