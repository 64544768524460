import {faQuestionCircle} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Card, Checkbox, Form, Row, Space, Table, Tooltip, Typography} from 'antd';
import moment from 'moment';
import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '../../models';
import {Calendar} from '../../models/calendar';
import {Clinic} from '../../models/clinics';
import {FhirUtils} from '../../services/fhir';
import {CalendarPicker} from '../appointment/calendar-picker';
import {AppointmentStatsDownload} from './appointment-stats-download';

const {Title} = Typography;

const qs = require('query-string');

export const AppointmentStats = () => {
  const [calendar, setCalendar] = useState<Calendar[]>();
  const [clinic, setClinic] = useState<Clinic[]>();
  const [timezone, setTimezone] = useState(moment.tz.guess());
  const [calendarDate, setCalendarDate] = useState([
    moment().format('YYYY-MM-DD'),
    moment().format('YYYY-MM-DD'),
  ]);
  const [fetchingAppointments, setFetchingAppointments] = useState(false);
  const [appointmentStats, setAppointmentStats] = useState([]);
  const [calendarType, setCalendarType] = useState({PUBLIC: true, INTERNAL: false});
  const client = FhirUtils.useAxiosClient();
  const globalOrg = useSelector((state: RootState) => state.ui.localOrg);

  React.useEffect(() => {
    refreshAppointmentStats(
      clinic?.map((val) => val.id),
      calendar?.map((val) => val.id),
      calendarDate
    );
  }, [calendar, clinic, calendarDate, calendarType, globalOrg?.id]);

  const refreshAppointmentStats = async (clinicId, calendarId, calendarDate) => {
    setFetchingAppointments(true);

    if (clinicId?.length > 0) {
      const startDate = moment(calendarDate[0]).toISOString();
      const endDate = moment(calendarDate[1]).toISOString();
      const calTypePub = calendarType.PUBLIC;
      const calTypeInt = calendarType.INTERNAL;
      const query = qs.stringify({
        calendarId,
        clinicId,
        startDate,
        endDate,
        calTypePub,
        calTypeInt,
      });
      const result: any = await client.get(
        `/appointment/stats?_organizationId=${globalOrg?.id || ''}&${query}`
      );
      console.log('AppointmentStats:', result);

      setAppointmentStats(result.data);
    }
    setFetchingAppointments(false);
  };

  const statsTooltip = {
    Planned:
      'Total number of potential appointments, as inputted into the system. These may or may not be open for bookings',
    Available:
      'Total number of appointments that are available for the public to be booked in, regardless of if they are booked or not',
    Booked: 'Total number of appointments booked',
    Remaining: 'Total number of appointments still open for booking, i.e. Available - Booked',
  };

  const statsTitle = (title) => {
    return (
      <>
        {title}
        <Tooltip title={statsTooltip[title]}>
          <FontAwesomeIcon icon={faQuestionCircle} color="gray" style={{marginLeft: 5}} />
        </Tooltip>
      </>
    );
  };

  const columns = [
    {
      title: 'Clinic',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Appointments',
      children: [
        {
          title: () => statsTitle('Planned'),
          render: (_, data) =>
            `${data.planned}` + (data.isGroup ? `\n(${data.plannedGroup} groups)` : ''),
          key: 'planned',
          align: 'right',
        },
        {
          title: () => statsTitle('Available'),
          render: (_, data) =>
            `${data.available}` + (data.isGroup ? `\n(${data.availableGroup} groups)` : ''),
          key: 'available',
          align: 'right',
        },
        {
          title: () => statsTitle('Booked'),
          render: (_, data) =>
            `${data.booked} (${data.bookedPercentage}%)` +
            (data.isGroup ? `\n(${data.bookedGroup} groups)` : ''),
          align: 'right',
        },
        {
          title: () => statsTitle('Remaining'),
          render: (_, data) =>
            `${data.remaining} (${data.remainingPercentage}%)` +
            (data.isGroup ? `\n(${data.remainingGroup} groups)` : ''),
          align: 'right',
        },
        {
          title: 'Canceled',
          dataIndex: 'canceled',
          key: 'canceled',
          align: 'right',
        },
        {
          title: 'No Show',
          dataIndex: 'noShow',
          key: 'noShow',
          align: 'right',
        },
      ],
    },
    {
      title: 'Patients',
      children: [
        {
          title: 'Immunized',
          dataIndex: 'immunized',
          key: 'immunized',
          align: 'right',
        },
        {
          title: 'Left to immunize',
          dataIndex: 'leftToImmunize',
          key: 'leftToImmunize',
          align: 'right',
        },
      ],
    },
  ];

  const onRefresh = () => {
    clinic &&
      calendarDate &&
      refreshAppointmentStats(
        clinic?.map((val) => val.id),
        calendar?.map((val) => val.id),
        calendarDate
      );
  };

  return (
    <Card>
      <Title level={4}>Clinic Stats</Title>
      <AppointmentStatsDownload
        dates={calendarDate}
        targetData={appointmentStats}
        calendarType={calendarType}
      />
      <Row>
        <CalendarPicker
          setClinic={(clinic) => setClinic(clinic)}
          clinic={clinic}
          setCalendar={(calendar) => setCalendar(calendar)}
          calendar={calendar}
          setCalendarDate={setCalendarDate}
          calendarDate={calendarDate}
          setTimezone={setTimezone}
          timezone={timezone}
          fetchingAppointments={fetchingAppointments}
          isRangePicker
          hideTimezone
          multipleCalendars
          multipleClinics
          onRefresh={onRefresh}
          hideIncludeAppointments
          includeCalendarType={calendarType}
        />
        <Form layout="vertical">
          <Form.Item label="Calendar Type">
            <Space>
              <Checkbox
                checked={calendarType.PUBLIC}
                onChange={(e) => setCalendarType({...calendarType, PUBLIC: e.target.checked})}
              >
                PUBLIC
              </Checkbox>
              <Checkbox
                checked={calendarType.INTERNAL}
                onChange={(e) => setCalendarType({...calendarType, INTERNAL: e.target.checked})}
              >
                INTERNAL
              </Checkbox>
            </Space>
          </Form.Item>
        </Form>
      </Row>
      <Table
        style={{whiteSpace: 'pre-wrap'}}
        dataSource={appointmentStats}
        columns={columns}
        indentSize={0}
        bordered
        pagination={false}
        scroll={{x: 400}}
      />
    </Card>
  );
};
