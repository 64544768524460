import {getClinicUrlComponents} from '../util/clinic-url-helpers';
import {createModel} from './model-helpers/create-model';
import moment from 'moment';
import {AvailabilityRuleType} from '../interface-models/availability-rules/availability-rule-types/availability-rule-type';
import {Clinic} from './clinics';
import {INonRecurringAvailabilityRule} from '../interface-models/availability-rules/availability-rule-types/non-recurring-availability-rule';
import {IRecurringDayAvailabilityRule} from '../interface-models/availability-rules/availability-rule-types/recurring-day-availability-rule';
import {IBlockRules} from './block-rule';

export interface Calendar {
  id: string;
  name: string;
  clinic: Clinic;
  availabilityRules: (IRecurringDayAvailabilityRule | INonRecurringAvailabilityRule)[];
  blockRules: IBlockRules[];
  maxNumberOfDosesPerDay?: number;
}

const calendarModel = createModel<Calendar>(
  'calendars',
  '/calendar',
  () => {
    return {
      clinicId: getClinicUrlComponents().clinicId,
    };
  },
  (calendarServerObject) => {
    return Object.assign({}, calendarServerObject, {
      availabilityRules: calendarServerObject.availabilityRules.map((availabilityRule) => {
        const baseFields = {
          startTime: moment.utc(availabilityRule.startTime),
          endTime: moment.utc(availabilityRule.endTime),
        };
        switch (availabilityRule.type) {
          case AvailabilityRuleType.NonRecurring: {
            return Object.assign({}, availabilityRule, baseFields, {
              date: moment.utc(availabilityRule.date),
            });
          }
          case AvailabilityRuleType.RecurringDay: {
            return Object.assign({}, availabilityRule, baseFields, {
              startDate: moment.utc(availabilityRule.startDate),
              endDate: moment.utc(availabilityRule.endDate),
            });
          }
        }
      }),
    });
  }
);

export default {
  ...calendarModel,
};
