import React from 'react';
import {FormConfig} from '../../base-entity/base-entity';
import {ClinicFormCustomFieldName} from '../../clinic/clinic-form-ux-model';
import {FormUX} from '../../form-ux/form-ux';
import {FormUXFieldType} from '../../form-ux/form-ux-models/form-ux-fields/form-ux-field-type';
import BookingPage from '../../../models/booking-pages';
import {OrganizationPickerFormItem} from '../../organization/organization-picker';
import {AppointmentTypesField} from '../appointment-type/appointment-types-field';
import {BookingPageCustomFieldName, generateBookingPageFormUXModel} from '../booking-page-fields';
import {BookingPagePickerFormItem} from '../booking-page-picker';
import {BookingPageCategoryPickerFormItem} from '../booking-page-category-picker';
import {BaseEntityV2} from '../../base-entity/base-entity';
import {useGlobalSettings} from '../../../models/global-settings';
import {useEnvInfo} from '../../../services/environment';

export const BookingPageConfigUX = (props) => {
  const globalSettings = useGlobalSettings();
  const {environmentId} = useEnvInfo();
  const settings = {...globalSettings, environmentId};
  const formConfig: FormConfig = {
    defaultValues: {organizationId: undefined}, //globalOrg?.id},
    // Add organizationid to the submission
    preSubmit: (values, actions, onSubmit) => {
      const BookingPageFormUXModel = generateBookingPageFormUXModel(settings);
      const fields: {name: string}[] = BookingPageFormUXModel.reduce((fields, field) => {
        return field.type === FormUXFieldType.grouped
          ? [...fields, ...field.fields]
          : [...fields, field];
      }, [] as any[]);

      const submittableValues = fields.reduce((submittables, field) => {
        return {...submittables, [field.name]: values[field.name]};
      }, {});

      onSubmit({...submittableValues}, actions);
    },
    FormItems: (props) => (
      <FormUX
        formUXModel={generateBookingPageFormUXModel(settings)}
        createMode={props.createMode}
        modal={props.modal}
        renderCustomField={(field) => {
          switch (field.name) {
            case ClinicFormCustomFieldName.OrganizationId: {
              return (
                <OrganizationPickerFormItem
                  label={field.label}
                  name={field.name}
                  setFieldValue={props.setFieldValue}
                  validationRules={field.validationRules}
                />
              );
            }
            case BookingPageCustomFieldName.AppointmentTypes: {
              return (
                <AppointmentTypesField fieldName={BookingPageCustomFieldName.AppointmentTypes} />
              );
            }
            case BookingPageCustomFieldName.rescheduleBookingPage: {
              return (
                <BookingPagePickerFormItem
                  label={field.label}
                  name={field.name}
                  setFieldValue={props.setFieldValue}
                  validationRules={field.validationRules}
                  currentBookingPageId={props.values.id}
                  nullable={field?.nullable}
                />
              );
            }
            case BookingPageCustomFieldName.category: {
              return (
                <BookingPageCategoryPickerFormItem
                  label={field.label}
                  name={field.name}
                  setFieldValue={props.setFieldValue}
                  validationRules={field.validationRules}
                  nullable={field?.nullable}
                  editable={field?.editable}
                />
              );
            }
            default: {
              throw new Error(`Unhandled custom field ${field.name} in
                renderCustomField method`);
            }
          }
        }}
      />
    ),
  };

  const config = {
    slug: 'bookingpage',
    itemTitleField: 'name',
    model: BookingPage,
    searchPageTitle: 'Booking Pages',
    hideEditButton: true,
    showItemTitleBar: false,
    formConfig,
    modalCreateForm: true,
    hideDeleteButton: true,
    card: false,
    ...props,
  };

  return <BaseEntityV2 {...config} disableWrapper />;
};
