import { CVCAntigen, CVCDisease, CVCGenericConcept, CVCGTIN, CVCLot, CVCNIDDConcept, CVCTradenameConcept } from '@canimmunize/cvc-js';
import React from 'react';
import { DiseaseAntigenNIDD } from './components/terminology-disease-antigen-nidd';
import { CoreInfoSection, LinkedGenericConcepts, LinkedTradenameConcepts, ProductInfoSection, RemainingLinkedConcepts } from './components/terminology-generics-tradenames';
import { GtinLookupOutput, LotLookupOutput } from './components/terminology-lot-gtin';

export const TradenameDetail = (concept: CVCTradenameConcept) => {
  return (
    <div>
      <CoreInfoSection concept={concept} superTitle="TRADENAME VACCINE CONCEPT" />
      <ProductInfoSection concept={concept} />
      <LinkedTradenameConcepts concept={concept} />
      <RemainingLinkedConcepts concept={concept} />
    </div>
  );
};

export const GenericDetail = (concept: CVCGenericConcept) => {
  return (
    <div>
      <CoreInfoSection concept={concept} superTitle="GENERIC VACCINE CONCEPT" />
      <LinkedGenericConcepts concept={concept} />
      <RemainingLinkedConcepts concept={concept} />
    </div>
  );
};

export const DiseaseDetail = (concept: CVCDisease) => {
  return <DiseaseAntigenNIDD concept={concept} superTitle="DISEASE CONCEPT" />;
};

export const AntigenDetail = (concept: CVCAntigen) => {
  return <DiseaseAntigenNIDD concept={concept} superTitle="ANTIGEN CONCEPT" />;
};

export const NIDDDetail = (concept: CVCNIDDConcept) => {
  return <DiseaseAntigenNIDD concept={concept} superTitle="DISEASE CONCEPT" />;
};

export const LotDetail = (concept: CVCLot) => {
  return <LotLookupOutput concept={concept} />;
};

export const GtinDetail = (concept: CVCGTIN) => {
  return <GtinLookupOutput concept={concept} />;
};
