import {Form, Input} from 'formik-antd';
import React from 'react';
import {RequiredShortTextValidationRules} from '../../../services/ui-validation-rules';
import {ValidateFormItem} from '../../validate-form-item';
/**
 * Title, Form, Validation definition to update Username
 * @returns
 */
const UserInfoNameForm = () => {
  return (
    <>
      <ValidateFormItem
        validationRules={RequiredShortTextValidationRules}
        renderFormItem={(validate) => {
          return (
            <Form.Item name="firstname" label="First Name" validate={validate}>
              <Input name="firstname" />
            </Form.Item>
          );
        }}
      />
      <ValidateFormItem
        validationRules={RequiredShortTextValidationRules}
        renderFormItem={(validate) => {
          return (
            <Form.Item name="lastname" label="Last Name" validate={validate}>
              <Input name="lastname" />
            </Form.Item>
          );
        }}
      />
    </>
  );
};

const title = 'Update User Info';

export const userInfoNameForm = {title, form: UserInfoNameForm};
