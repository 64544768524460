import React from 'react';
import {Form} from 'formik-antd';
import {Input, Switch, Select, Space} from 'antd';
import {ValidateFormItem} from '../validate-form-item';
import {ValidationRules} from '../../validation/validation-rules/validation-rules';
import {ClinicModeUser} from '../../views/admin/helpers/types';

const {Option} = Select;

interface UserAccessFormItemProps {
  name: string;
  label?: string;
  setFieldValue: (name: string, value: any) => void;
  validationRules?: ValidationRules[];
  item?: ClinicModeUser;
}

export const UserAccessFormItem = (props: UserAccessFormItemProps) => {
  const [isEnabled, setEnabled] = React.useState(true);
  const [isGenerated, setGenerated] = React.useState(true);

  const updateGenerated = (value) => {
    if (value === 'manual') setGenerated(false);
    else {
      props.setFieldValue('password', '');
      setGenerated(true);
    }
  };
  const updateEnabled = (value) => {
    props.setFieldValue('userAccountAccess', value);
    setEnabled(value);
  };

  return (
    <ValidateFormItem
      validationRules={props.validationRules || []}
      renderFormItem={(validate) => {
        return (
          <Form.Item name="User-Access" label={props.label || 'User-Access'} validate={validate}>
            <Space direction="vertical">
              <Space direction="horizontal">
                <Switch checked={isEnabled} onChange={updateEnabled} />
                {isEnabled && (
                  <>
                    <Select
                      defaultValue={isGenerated ? 'auto' : 'manual'}
                      onChange={updateGenerated}
                    >
                      <Option value="auto">Server Generated Password</Option>
                      <Option value="manual">Manually Set Password</Option>
                    </Select>
                  </>
                )}
              </Space>
              {isEnabled && isGenerated === false && (
                <Input
                  placeholder="Password"
                  onChange={(value) => props.setFieldValue('password', value.target.value)}
                />
              )}
            </Space>
          </Form.Item>
        );
      }}
    />
  );
};
