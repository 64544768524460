export function defineYKCallCentreRules(builder) {
  const {can} = builder;
  // Menus
  can('access', 'menu', ['yk-call-centre']);
  // Routes
  can('access', 'route', ['/home', '/:lang/ykcallcentre**']);
  // Permissions
  can('hide', 'organizationSelector');
  can('hide', 'server');
  can('use', 'maxExtIdleTime');
}
