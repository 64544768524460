import {
  RequiredShortTextValidationRules,
  RequiredSpecialCharsValidationRules,
} from '../../../services/ui-validation-rules';
import {EnumValidationRuleId} from '../../../validation/validation-rules/enum-validation';
import {RequiredValidationRuleId} from '../../../validation/validation-rules/required-validation';
import {FormUXFieldType} from '../../form-ux';
import {FormUXModel} from '../../form-ux/form-ux-models/form-ux-model';
import moment from 'moment-timezone';
import {getClinicUrlComponents} from '../../../util/clinic-url-helpers';
import {getEnumValues} from '../../../util/enum-util';
import {MinValidationRuleId} from '../../../validation/validation-rules/min-validation';

export enum CalendarCustomFieldNames {
  AvailabilityRules = 'availabilityRules',
  Location = 'location',
}

export enum CalendarStatus {
  ACTIVE = 'active',
  STAGING = 'staging',
  DISABLED = 'disabled',
}

export const generateCalendarFormUXModel = (category): any => [
  {
    name: 'name',
    type: FormUXFieldType.text,
    label: 'Name',
    editable: true,
    validationRules: [...RequiredShortTextValidationRules, ...RequiredSpecialCharsValidationRules],
    inCreateModal: true,
  },
  {
    name: 'status',
    type: FormUXFieldType.select,
    label: 'Status',
    editable: true,
    validationRules: [
      {
        validationRuleType: EnumValidationRuleId,
        enumValues: getEnumValues(CalendarStatus),
      },
    ],
    selectableValues: getEnumValues(CalendarStatus).map((status) => {
      return {
        key: status,
        label: getLabelForCalendarStatus(status as CalendarStatus),
      };
    }),
  },
  ...(category !== 'appointment-view'
    ? [
        {
          name: 'type',
          type: FormUXFieldType.select,
          label: 'Type',
          editable: true,
          inCreateModal: true,
          selectableValues: [
            {key: 'PUBLIC', label: 'Public'},
            {key: 'INTERNAL', label: 'Internal'},
          ],
          validationRules: [
            {
              validationRuleType: RequiredValidationRuleId,
            },
            {
              validationRuleType: EnumValidationRuleId,
              enumValues: ['PUBLIC', 'INTERNAL'],
            },
          ],
        },
      ]
    : []),
  ...(category === 'Group'
    ? [
        {
          name: 'maxNumberOfDosesPerDay',
          type: FormUXFieldType.number,
          label: 'Max Number of Doses Per Day',
          editable: true,
          validationRules: [
            {
              validationRuleType: MinValidationRuleId,
              min: 0,
              inclusive: true,
            },
          ],
        },
      ]
    : []),
  {
    name: CalendarCustomFieldNames.AvailabilityRules,
    type: FormUXFieldType.custom,
    label: 'Availability Rules',
    editable: true,
    validationRules: [],
  },
];

export function getLabelForCalendarStatus(status: CalendarStatus): string {
  switch (status) {
    case CalendarStatus.STAGING: {
      return 'Staging';
    }
    case CalendarStatus.ACTIVE: {
      return 'Active';
    }
    case CalendarStatus.DISABLED: {
      return 'Disabled';
    }
  }
}
