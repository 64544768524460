"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_OperationDefinition = exports.OperationDefinitionKindKind = exports.OperationDefinitionStatusKind = void 0;
var t = __importStar(require("io-ts"));
var RTTI_id_1 = require("../Scalar/RTTI_id");
var RTTI_Meta_1 = require("./RTTI_Meta");
var RTTI_uri_1 = require("../Scalar/RTTI_uri");
var RTTI_Element_1 = require("./RTTI_Element");
var RTTI_code_1 = require("../Scalar/RTTI_code");
var RTTI_Narrative_1 = require("./RTTI_Narrative");
var RTTI_ResourceList_1 = require("../Union/RTTI_ResourceList");
var RTTI_Extension_1 = require("./RTTI_Extension");
var RTTI_dateTime_1 = require("../Scalar/RTTI_dateTime");
var RTTI_ContactDetail_1 = require("./RTTI_ContactDetail");
var RTTI_markdown_1 = require("../Scalar/RTTI_markdown");
var RTTI_UsageContext_1 = require("./RTTI_UsageContext");
var RTTI_CodeableConcept_1 = require("./RTTI_CodeableConcept");
var RTTI_canonical_1 = require("../Scalar/RTTI_canonical");
var RTTI_OperationDefinition_Parameter_1 = require("./RTTI_OperationDefinition_Parameter");
var RTTI_OperationDefinition_Overload_1 = require("./RTTI_OperationDefinition_Overload");
var OperationDefinitionStatusKind;
(function (OperationDefinitionStatusKind) {
    OperationDefinitionStatusKind["_draft"] = "draft";
    OperationDefinitionStatusKind["_active"] = "active";
    OperationDefinitionStatusKind["_retired"] = "retired";
    OperationDefinitionStatusKind["_unknown"] = "unknown";
})(OperationDefinitionStatusKind = exports.OperationDefinitionStatusKind || (exports.OperationDefinitionStatusKind = {}));
var OperationDefinitionKindKind;
(function (OperationDefinitionKindKind) {
    OperationDefinitionKindKind["_operation"] = "operation";
    OperationDefinitionKindKind["_query"] = "query";
})(OperationDefinitionKindKind = exports.OperationDefinitionKindKind || (exports.OperationDefinitionKindKind = {}));
var EnumType_1 = require("../../EnumType");
exports.RTTI_OperationDefinition = t.recursion('IOperationDefinition', function () {
    return t.intersection([
        t.type({
            resourceType: t.literal('OperationDefinition')
        }),
        t.partial({
            id: RTTI_id_1.RTTI_id,
            meta: RTTI_Meta_1.RTTI_Meta,
            implicitRules: RTTI_uri_1.RTTI_uri,
            _implicitRules: RTTI_Element_1.RTTI_Element,
            language: RTTI_code_1.RTTI_code,
            _language: RTTI_Element_1.RTTI_Element,
            text: RTTI_Narrative_1.RTTI_Narrative,
            contained: t.array(RTTI_ResourceList_1.RTTI_ResourceList),
            extension: t.array(RTTI_Extension_1.RTTI_Extension),
            modifierExtension: t.array(RTTI_Extension_1.RTTI_Extension),
            url: RTTI_uri_1.RTTI_uri,
            _url: RTTI_Element_1.RTTI_Element,
            version: t.string,
            _version: RTTI_Element_1.RTTI_Element,
            name: t.string,
            _name: RTTI_Element_1.RTTI_Element,
            title: t.string,
            _title: RTTI_Element_1.RTTI_Element,
            status: EnumType_1.createEnumType(OperationDefinitionStatusKind, 'OperationDefinitionStatusKind'),
            _status: RTTI_Element_1.RTTI_Element,
            kind: EnumType_1.createEnumType(OperationDefinitionKindKind, 'OperationDefinitionKindKind'),
            _kind: RTTI_Element_1.RTTI_Element,
            experimental: t.boolean,
            _experimental: RTTI_Element_1.RTTI_Element,
            date: RTTI_dateTime_1.RTTI_dateTime,
            _date: RTTI_Element_1.RTTI_Element,
            publisher: t.string,
            _publisher: RTTI_Element_1.RTTI_Element,
            contact: t.array(RTTI_ContactDetail_1.RTTI_ContactDetail),
            description: RTTI_markdown_1.RTTI_markdown,
            _description: RTTI_Element_1.RTTI_Element,
            useContext: t.array(RTTI_UsageContext_1.RTTI_UsageContext),
            jurisdiction: t.array(RTTI_CodeableConcept_1.RTTI_CodeableConcept),
            purpose: RTTI_markdown_1.RTTI_markdown,
            _purpose: RTTI_Element_1.RTTI_Element,
            affectsState: t.boolean,
            _affectsState: RTTI_Element_1.RTTI_Element,
            code: RTTI_code_1.RTTI_code,
            _code: RTTI_Element_1.RTTI_Element,
            comment: RTTI_markdown_1.RTTI_markdown,
            _comment: RTTI_Element_1.RTTI_Element,
            base: RTTI_canonical_1.RTTI_canonical,
            resource: t.array(RTTI_code_1.RTTI_code),
            _resource: t.array(RTTI_Element_1.RTTI_Element),
            system: t.boolean,
            _system: RTTI_Element_1.RTTI_Element,
            type: t.boolean,
            _type: RTTI_Element_1.RTTI_Element,
            instance: t.boolean,
            _instance: RTTI_Element_1.RTTI_Element,
            inputProfile: RTTI_canonical_1.RTTI_canonical,
            outputProfile: RTTI_canonical_1.RTTI_canonical,
            parameter: t.array(RTTI_OperationDefinition_Parameter_1.RTTI_OperationDefinition_Parameter),
            overload: t.array(RTTI_OperationDefinition_Overload_1.RTTI_OperationDefinition_Overload)
        })
    ]);
});
