import {
  BirthDateValidationRuleId,
  validateBirthDateRule,
} from './validation-rules/birthdate-validation';
import {EmailValidationRuleId, validateEmailRule} from './validation-rules/email-validation';
import {PhoneValidationRuleId, validatePhoneRule} from './validation-rules/phone-validation';
import {
  PostalCodeValidationRuleId,
  validatePostalCodeRule,
} from './validation-rules/postal-code-validation';
import {
  TimezoneValidationRuleId,
  validateTimezoneRule,
} from './validation-rules/timezone-validation';
import {EnumValidationRuleId, validateEnumRule} from './validation-rules/enum-validation';
import {
  GenderValidationRuleId,
  validateGenderRule,
} from './validation-rules/gender-enum-validation';
import {HcnTypeValidationRuleId, validateHcnTypeRule} from './validation-rules/hcn-type-validation';
import {HcnValidationRuleId, validateHcnRule} from './validation-rules/hcn-validation';
import {JsonValidationRuleId, validateJsonRule} from './validation-rules/json-validation';
import {
  MaxLengthValidationRuleId,
  validateMaxLengthRule,
} from './validation-rules/max-length-validation';
import {MinValidationRuleId, validateMinRule} from './validation-rules/min-validation';
import {RegexValidationId, validateRegexRule} from './validation-rules/regex-validation';
import {
  RequiredValidationRuleId,
  validateRequiredRule,
} from './validation-rules/required-validation';
import {ValidationRules} from './validation-rules/validation-rules';
import {
  RequiredDefinedValidationRuleId,
  validateRequiredDefinedRule,
} from './validation-rules/required-defined-validation';
import {ListItemValidationRuleId, validateListItemRule} from './validation-rules/list-validation';
import {
  UserPasswordValidationRuleId,
  validateUserPasswordRule,
} from './validation-rules/user-password-validation';
import {
  NumberValidationRuleId,
  validateNumberRule,
} from './validation-rules/number-validation-rule';
import {
  RequiredObjectValidationRuleId,
  validateRequiredObjectRule,
} from './validation-rules/required-object-validation';
import {
  PreviousDateValidationRuleId,
  validatePreviousDateRule,
} from './validation-rules/previous-date-validation-rule';
import {
  FutureDateValidationRuleId,
  validateFutureDateRule,
} from './validation-rules/future-date-validation-rule';

/**
 * Validates a value based on the rules in the validationRules parameter.
 * It will stop validating subsequent rules as soon as it finds one that
 * failed.
 *
 * @export
 * @param {Array<ValidationRules>} validationRules
 * @param {*} value
 * @param {*} extraProps
 * @returns {(true | Array<ValidationRules>)} If there are no validation errors
 * it returns true. Otherwise it returns the first rule that fails.
 */
export function validateValue(
  validationRules: Array<ValidationRules>,
  value: any,
  extraProps?: any
): true | ValidationRules {
  /* The first rule that failed validation */
  const firstValidationFailure = validationRules
    /* Find the first rule failed */
    .find((validationRule) => {
      switch (validationRule.validationRuleType) {
        case RequiredValidationRuleId: {
          return !validateRequiredRule(value);
        }
        case RequiredDefinedValidationRuleId: {
          return !validateRequiredDefinedRule(value);
        }
        case MaxLengthValidationRuleId: {
          return !validateMaxLengthRule(value, validationRule);
        }
        case JsonValidationRuleId: {
          return !validateJsonRule(value);
        }
        case GenderValidationRuleId: {
          return !validateGenderRule(value);
        }
        case BirthDateValidationRuleId: {
          return !validateBirthDateRule(value);
        }
        case PreviousDateValidationRuleId: {
          return !validatePreviousDateRule(value);
        }
        case FutureDateValidationRuleId: {
          return !validateFutureDateRule(value);
        }
        case EmailValidationRuleId: {
          return !validateEmailRule(value);
        }
        case PhoneValidationRuleId: {
          return !validatePhoneRule(value);
        }
        case HcnTypeValidationRuleId: {
          return !validateHcnTypeRule(value);
        }
        case PostalCodeValidationRuleId: {
          return !validatePostalCodeRule(value);
        }
        case HcnValidationRuleId: {
          return !validateHcnRule(value, extraProps.hcnType);
        }
        case EnumValidationRuleId: {
          return !validateEnumRule(value, validationRule);
        }
        case MinValidationRuleId: {
          return !validateMinRule(value, validationRule);
        }
        case RegexValidationId: {
          return !validateRegexRule(value, validationRule);
        }
        case TimezoneValidationRuleId: {
          return !validateTimezoneRule(value);
        }
        case ListItemValidationRuleId: {
          return !validateListItemRule(value, validationRule.validValues);
        }
        case UserPasswordValidationRuleId: {
          return !validateUserPasswordRule(value);
        }
        case NumberValidationRuleId: {
          return !validateNumberRule(value);
        }
        case RequiredObjectValidationRuleId: {
          return !validateRequiredObjectRule(value);
        }
      }
    });

  /* If there are no validation failures, return true */
  if (firstValidationFailure === undefined) {
    return true;
  }

  /* Otherwise return the first rule that failed validation */
  return firstValidationFailure;
}
