import {IResource} from '@ahryman40k/ts-fhir-types/lib/R4';
import {DownOutlined} from '@ant-design/icons';
import {Col, Dropdown, Menu, Row, Typography} from 'antd';
import {Formik} from 'formik';
import React from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import uuid from 'uuid';
import {getStoredOrg} from '../../models/organizations';
import {updateOne} from '../../models/patients';
import {FhirUtils} from '../../services/fhir';
import {AbilityContext} from '../../services/roles/ability-context';
import {LinkByPermission} from '../../services/roles/roles-utils';
import {mapBundleToResourceArray} from '../../util/fhir';
import {FHIRTable} from '../fhir-table';
import {FormUX, FormUXFieldType} from '../form-ux';
import {FormUXModel} from '../form-ux/form-ux-models/form-ux-model';

const {Title} = Typography;

const CohortsFormUXModel: FormUXModel = [
  {
    name: 'selectedCohorts',
    type: FormUXFieldType.MultipleItemPicker,
    label: 'Cohorts',
    editable: true,
    editItemsButtonLabel: 'Link/Unlink Cohorts',
    hideDefaultLabel: true,
    itemsTableColumns: [
      {
        dataIndex: 'name',
        key: 'name',
        title: 'Name',
      },
      {
        dataIndex: 'organizationCode',
        key: 'organizationCode',
        title: 'Org Code',
        render: (_, cohort) => cohort.organization?.organizationCode,
      },
    ],
    fhirResourceName: 'Group',
    validationRules: [],
    submitFormOnModalOk: true,
    hideTable: true,
    onRowClickPath: (id) => `/cohorts/${id}`,
  },
];

interface CohortModalProps {
  patient: any;
  onSelect: (value: string[]) => void;
  onDeselect: (value: string) => void;
  cohorts: IResource[];
}

const CohortsSelect = (props: CohortModalProps) => {
  return (
    <Formik
      initialValues={{selectedCohorts: props.cohorts}}
      onSubmit={async (values, actions) => {
        const selectedCohortIds: string[] = values.selectedCohorts.map((c) => c.id || '');
        const addedCohorts = selectedCohortIds.filter(
          (cId: string) => !props.cohorts.find((c: IResource) => c.id === cId)
        );
        const removedCohorts = props.cohorts.filter(
          (c: IResource) => !selectedCohortIds.find((cId: string) => c.id === cId)
        );

        await props.onSelect(addedCohorts);
        removedCohorts.map((c) => props.onDeselect(c.id || ''));
      }}
    >
      {(formikProps) => (
        <FormUX {...formikProps} formUXModel={CohortsFormUXModel} createMode={false} />
      )}
    </Formik>
  );
};

export const CohortsWidget = (props) => {
  const navigate = useNavigate();
  const {patient} = props;
  const storedOrg = getStoredOrg(patient?.organizationId);
  const [refreshKey, setRefreshKey] = React.useState<string>(uuid.v4());
  const [selectedCohorts, setSelectedCohorts] = React.useState<IResource[] | undefined>(undefined);
  const ability = React.useContext(AbilityContext);
  const client = FhirUtils.useClient();
  const dispatch = useDispatch();

  const onSelect = async (value: string[]) => {
    const updatedPatient = {...patient, cohortIds: value};
    await dispatch(updateOne(client, updatedPatient));
    setRefreshKey(uuid.v4());
  };

  const onDeselect = async (value: string) => {
    const result = await client.delete(`/Group/${value}/patient/${patient.id}`);

    if (result.status === 200) {
      setRefreshKey(uuid.v4());
    }
  };

  const onDataLoad = (data) => {
    const cohorts = mapBundleToResourceArray(data);
    setSelectedCohorts(cohorts);
    props.onDataLoad?.(data);
  };

  const columns = [
    {
      title: 'Cohort',
      dataIndex: 'name',
      key: 'name',
      render: (text, obj) => (
        <LinkByPermission route="/cohorts/:cohortId" target={`/cohorts/${obj.id}`} text={text} />
      ),
    },
    {
      title: 'Clinic',
      render: (_, cohort) =>
        cohort.clinics && cohort.clinics.length > 0 ? (
          <Dropdown
            trigger={['click']}
            overlay={
              <Menu onClick={({key}) => navigate(`/clinics/${key}`)}>
                {cohort.clinics.map((item) => (
                  <Menu.Item key={item.id}>{item.name}</Menu.Item>
                ))}
              </Menu>
            }
          >
            <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
              View Clinics <DownOutlined />
            </a>
          </Dropdown>
        ) : (
          ''
        ),
    },
  ];

  const canEditCohorts = ability.can('update', 'patients', 'cohortsWidget') && storedOrg;

  return (
    <>
      <Row>
        {!canEditCohorts && (
          <Col span={24}>
            <Title level={4} style={{flex: 1}}>
              Cohorts
            </Title>
          </Col>
        )}
        {selectedCohorts && canEditCohorts && (
          <Col span={24}>
            <CohortsSelect
              onSelect={onSelect}
              patient={patient}
              cohorts={selectedCohorts}
              onDeselect={onDeselect}
            />
          </Col>
        )}
      </Row>

      <Row>
        <Col span={24}>
          <FHIRTable
            fhirResource={'Group'}
            onDataLoad={onDataLoad}
            fixedFilters={{patientId: patient.id}}
            triggerRefresh={refreshKey}
            hideSearch
            mode="table"
            columns={columns}
            size="small"
            style={{width: '100%'}}
            // onRow={(imm: any) => ({onClick: (event) => setCurrent(imm.id)})}
          />
        </Col>
      </Row>
    </>
  );
};
