"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_ElementDefinition_Binding = exports.ElementDefinition_BindingStrengthKind = void 0;
var t = __importStar(require("io-ts"));
var RTTI_Extension_1 = require("./RTTI_Extension");
var RTTI_Element_1 = require("./RTTI_Element");
var RTTI_canonical_1 = require("../Scalar/RTTI_canonical");
var ElementDefinition_BindingStrengthKind;
(function (ElementDefinition_BindingStrengthKind) {
    ElementDefinition_BindingStrengthKind["_required"] = "required";
    ElementDefinition_BindingStrengthKind["_extensible"] = "extensible";
    ElementDefinition_BindingStrengthKind["_preferred"] = "preferred";
    ElementDefinition_BindingStrengthKind["_example"] = "example";
})(ElementDefinition_BindingStrengthKind = exports.ElementDefinition_BindingStrengthKind || (exports.ElementDefinition_BindingStrengthKind = {}));
var EnumType_1 = require("../../EnumType");
exports.RTTI_ElementDefinition_Binding = t.recursion('IElementDefinition_Binding', function () {
    return t.partial({
        id: t.string,
        extension: t.array(RTTI_Extension_1.RTTI_Extension),
        modifierExtension: t.array(RTTI_Extension_1.RTTI_Extension),
        strength: EnumType_1.createEnumType(ElementDefinition_BindingStrengthKind, 'ElementDefinition_BindingStrengthKind'),
        _strength: RTTI_Element_1.RTTI_Element,
        description: t.string,
        _description: RTTI_Element_1.RTTI_Element,
        valueSet: RTTI_canonical_1.RTTI_canonical
    });
});
