import {Descriptions} from 'antd';
import React from 'react';

/* A note view for doseDecision formSubmissions. */
export const DoseDecisionNoteView = (props) => {
  const {note} = props;
  return (
    <Descriptions
      size="small"
      labelStyle={{
        fontWeight: 'bold',
        width: '140px',
        overflowWrap: 'break-word',
        marginRight: '10px',
        wordBreak: 'break-word',
      }}
      column={1}
      colon={false}
    >
      <Descriptions.Item label="Can Proceed">{'' + note.fields.canProceed}</Descriptions.Item>
      <Descriptions.Item label="Proceed With Vax">
        {'' + note.fields.proceedWithVax}
      </Descriptions.Item>
      <Descriptions.Item
        label="Decision Notes"
        contentStyle={{
          border: '1px dashed rgba(0,0,0,0.1)',
          borderRadius: '10px',
          padding: '10px',
        }}
      >
        {note.fields.proceedWithCovidVaxNotes}
      </Descriptions.Item>
    </Descriptions>
  );
};
