"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_ImplementationGuide = exports.ImplementationGuideLicenseKind = exports.ImplementationGuideStatusKind = exports.ImplementationGuideFhirVersionKind = void 0;
var t = __importStar(require("io-ts"));
var RTTI_id_1 = require("../Scalar/RTTI_id");
var RTTI_Meta_1 = require("./RTTI_Meta");
var RTTI_uri_1 = require("../Scalar/RTTI_uri");
var RTTI_Element_1 = require("./RTTI_Element");
var RTTI_code_1 = require("../Scalar/RTTI_code");
var RTTI_Narrative_1 = require("./RTTI_Narrative");
var RTTI_ResourceList_1 = require("../Union/RTTI_ResourceList");
var RTTI_Extension_1 = require("./RTTI_Extension");
var RTTI_dateTime_1 = require("../Scalar/RTTI_dateTime");
var RTTI_ContactDetail_1 = require("./RTTI_ContactDetail");
var RTTI_markdown_1 = require("../Scalar/RTTI_markdown");
var RTTI_UsageContext_1 = require("./RTTI_UsageContext");
var RTTI_CodeableConcept_1 = require("./RTTI_CodeableConcept");
var RTTI_ImplementationGuide_DependsOn_1 = require("./RTTI_ImplementationGuide_DependsOn");
var RTTI_ImplementationGuide_Global_1 = require("./RTTI_ImplementationGuide_Global");
var RTTI_ImplementationGuide_Definition_1 = require("./RTTI_ImplementationGuide_Definition");
var RTTI_ImplementationGuide_Manifest_1 = require("./RTTI_ImplementationGuide_Manifest");
var ImplementationGuideFhirVersionKind;
(function (ImplementationGuideFhirVersionKind) {
    ImplementationGuideFhirVersionKind["_001"] = "0.01";
    ImplementationGuideFhirVersionKind["_005"] = "0.05";
    ImplementationGuideFhirVersionKind["_006"] = "0.06";
    ImplementationGuideFhirVersionKind["_011"] = "0.11";
    ImplementationGuideFhirVersionKind["_0080"] = "0.0.80";
    ImplementationGuideFhirVersionKind["_0081"] = "0.0.81";
    ImplementationGuideFhirVersionKind["_0082"] = "0.0.82";
    ImplementationGuideFhirVersionKind["_040"] = "0.4.0";
    ImplementationGuideFhirVersionKind["_050"] = "0.5.0";
    ImplementationGuideFhirVersionKind["_100"] = "1.0.0";
    ImplementationGuideFhirVersionKind["_101"] = "1.0.1";
    ImplementationGuideFhirVersionKind["_102"] = "1.0.2";
    ImplementationGuideFhirVersionKind["_110"] = "1.1.0";
    ImplementationGuideFhirVersionKind["_140"] = "1.4.0";
    ImplementationGuideFhirVersionKind["_160"] = "1.6.0";
    ImplementationGuideFhirVersionKind["_180"] = "1.8.0";
    ImplementationGuideFhirVersionKind["_300"] = "3.0.0";
    ImplementationGuideFhirVersionKind["_301"] = "3.0.1";
    ImplementationGuideFhirVersionKind["_330"] = "3.3.0";
    ImplementationGuideFhirVersionKind["_350"] = "3.5.0";
    ImplementationGuideFhirVersionKind["_400"] = "4.0.0";
})(ImplementationGuideFhirVersionKind = exports.ImplementationGuideFhirVersionKind || (exports.ImplementationGuideFhirVersionKind = {}));
var ImplementationGuideStatusKind;
(function (ImplementationGuideStatusKind) {
    ImplementationGuideStatusKind["_draft"] = "draft";
    ImplementationGuideStatusKind["_active"] = "active";
    ImplementationGuideStatusKind["_retired"] = "retired";
    ImplementationGuideStatusKind["_unknown"] = "unknown";
})(ImplementationGuideStatusKind = exports.ImplementationGuideStatusKind || (exports.ImplementationGuideStatusKind = {}));
var ImplementationGuideLicenseKind;
(function (ImplementationGuideLicenseKind) {
    ImplementationGuideLicenseKind["_notOpenSource"] = "not-open-source";
    ImplementationGuideLicenseKind["_0Bsd"] = "0BSD";
    ImplementationGuideLicenseKind["_aal"] = "AAL";
    ImplementationGuideLicenseKind["_abstyles"] = "Abstyles";
    ImplementationGuideLicenseKind["_adobe2006"] = "Adobe-2006";
    ImplementationGuideLicenseKind["_adobeGlyph"] = "Adobe-Glyph";
    ImplementationGuideLicenseKind["_adsl"] = "ADSL";
    ImplementationGuideLicenseKind["_afl11"] = "AFL-1.1";
    ImplementationGuideLicenseKind["_afl12"] = "AFL-1.2";
    ImplementationGuideLicenseKind["_afl20"] = "AFL-2.0";
    ImplementationGuideLicenseKind["_afl21"] = "AFL-2.1";
    ImplementationGuideLicenseKind["_afl30"] = "AFL-3.0";
    ImplementationGuideLicenseKind["_afmparse"] = "Afmparse";
    ImplementationGuideLicenseKind["_agpl10Only"] = "AGPL-1.0-only";
    ImplementationGuideLicenseKind["_agpl10OrLater"] = "AGPL-1.0-or-later";
    ImplementationGuideLicenseKind["_agpl30Only"] = "AGPL-3.0-only";
    ImplementationGuideLicenseKind["_agpl30OrLater"] = "AGPL-3.0-or-later";
    ImplementationGuideLicenseKind["_aladdin"] = "Aladdin";
    ImplementationGuideLicenseKind["_amdplpa"] = "AMDPLPA";
    ImplementationGuideLicenseKind["_aml"] = "AML";
    ImplementationGuideLicenseKind["_ampas"] = "AMPAS";
    ImplementationGuideLicenseKind["_antlrPd"] = "ANTLR-PD";
    ImplementationGuideLicenseKind["_apache10"] = "Apache-1.0";
    ImplementationGuideLicenseKind["_apache11"] = "Apache-1.1";
    ImplementationGuideLicenseKind["_apache20"] = "Apache-2.0";
    ImplementationGuideLicenseKind["_apafml"] = "APAFML";
    ImplementationGuideLicenseKind["_apl10"] = "APL-1.0";
    ImplementationGuideLicenseKind["_apsl10"] = "APSL-1.0";
    ImplementationGuideLicenseKind["_apsl11"] = "APSL-1.1";
    ImplementationGuideLicenseKind["_apsl12"] = "APSL-1.2";
    ImplementationGuideLicenseKind["_apsl20"] = "APSL-2.0";
    ImplementationGuideLicenseKind["_artistic10Cl8"] = "Artistic-1.0-cl8";
    ImplementationGuideLicenseKind["_artistic10Perl"] = "Artistic-1.0-Perl";
    ImplementationGuideLicenseKind["_artistic10"] = "Artistic-1.0";
    ImplementationGuideLicenseKind["_artistic20"] = "Artistic-2.0";
    ImplementationGuideLicenseKind["_bahyph"] = "Bahyph";
    ImplementationGuideLicenseKind["_barr"] = "Barr";
    ImplementationGuideLicenseKind["_beerware"] = "Beerware";
    ImplementationGuideLicenseKind["_bitTorrent10"] = "BitTorrent-1.0";
    ImplementationGuideLicenseKind["_bitTorrent11"] = "BitTorrent-1.1";
    ImplementationGuideLicenseKind["_borceux"] = "Borceux";
    ImplementationGuideLicenseKind["_bsd1Clause"] = "BSD-1-Clause";
    ImplementationGuideLicenseKind["_bsd2ClauseFreeBsd"] = "BSD-2-Clause-FreeBSD";
    ImplementationGuideLicenseKind["_bsd2ClauseNetBsd"] = "BSD-2-Clause-NetBSD";
    ImplementationGuideLicenseKind["_bsd2ClausePatent"] = "BSD-2-Clause-Patent";
    ImplementationGuideLicenseKind["_bsd2Clause"] = "BSD-2-Clause";
    ImplementationGuideLicenseKind["_bsd3ClauseAttribution"] = "BSD-3-Clause-Attribution";
    ImplementationGuideLicenseKind["_bsd3ClauseClear"] = "BSD-3-Clause-Clear";
    ImplementationGuideLicenseKind["_bsd3ClauseLbnl"] = "BSD-3-Clause-LBNL";
    ImplementationGuideLicenseKind["_bsd3ClauseNoNuclearLicense2014"] = "BSD-3-Clause-No-Nuclear-License-2014";
    ImplementationGuideLicenseKind["_bsd3ClauseNoNuclearLicense"] = "BSD-3-Clause-No-Nuclear-License";
    ImplementationGuideLicenseKind["_bsd3ClauseNoNuclearWarranty"] = "BSD-3-Clause-No-Nuclear-Warranty";
    ImplementationGuideLicenseKind["_bsd3Clause"] = "BSD-3-Clause";
    ImplementationGuideLicenseKind["_bsd4ClauseUc"] = "BSD-4-Clause-UC";
    ImplementationGuideLicenseKind["_bsd4Clause"] = "BSD-4-Clause";
    ImplementationGuideLicenseKind["_bsdProtection"] = "BSD-Protection";
    ImplementationGuideLicenseKind["_bsdSourceCode"] = "BSD-Source-Code";
    ImplementationGuideLicenseKind["_bsl10"] = "BSL-1.0";
    ImplementationGuideLicenseKind["_bzip2105"] = "bzip2-1.0.5";
    ImplementationGuideLicenseKind["_bzip2106"] = "bzip2-1.0.6";
    ImplementationGuideLicenseKind["_caldera"] = "Caldera";
    ImplementationGuideLicenseKind["_catosl11"] = "CATOSL-1.1";
    ImplementationGuideLicenseKind["_ccBy10"] = "CC-BY-1.0";
    ImplementationGuideLicenseKind["_ccBy20"] = "CC-BY-2.0";
    ImplementationGuideLicenseKind["_ccBy25"] = "CC-BY-2.5";
    ImplementationGuideLicenseKind["_ccBy30"] = "CC-BY-3.0";
    ImplementationGuideLicenseKind["_ccBy40"] = "CC-BY-4.0";
    ImplementationGuideLicenseKind["_ccByNc10"] = "CC-BY-NC-1.0";
    ImplementationGuideLicenseKind["_ccByNc20"] = "CC-BY-NC-2.0";
    ImplementationGuideLicenseKind["_ccByNc25"] = "CC-BY-NC-2.5";
    ImplementationGuideLicenseKind["_ccByNc30"] = "CC-BY-NC-3.0";
    ImplementationGuideLicenseKind["_ccByNc40"] = "CC-BY-NC-4.0";
    ImplementationGuideLicenseKind["_ccByNcNd10"] = "CC-BY-NC-ND-1.0";
    ImplementationGuideLicenseKind["_ccByNcNd20"] = "CC-BY-NC-ND-2.0";
    ImplementationGuideLicenseKind["_ccByNcNd25"] = "CC-BY-NC-ND-2.5";
    ImplementationGuideLicenseKind["_ccByNcNd30"] = "CC-BY-NC-ND-3.0";
    ImplementationGuideLicenseKind["_ccByNcNd40"] = "CC-BY-NC-ND-4.0";
    ImplementationGuideLicenseKind["_ccByNcSa10"] = "CC-BY-NC-SA-1.0";
    ImplementationGuideLicenseKind["_ccByNcSa20"] = "CC-BY-NC-SA-2.0";
    ImplementationGuideLicenseKind["_ccByNcSa25"] = "CC-BY-NC-SA-2.5";
    ImplementationGuideLicenseKind["_ccByNcSa30"] = "CC-BY-NC-SA-3.0";
    ImplementationGuideLicenseKind["_ccByNcSa40"] = "CC-BY-NC-SA-4.0";
    ImplementationGuideLicenseKind["_ccByNd10"] = "CC-BY-ND-1.0";
    ImplementationGuideLicenseKind["_ccByNd20"] = "CC-BY-ND-2.0";
    ImplementationGuideLicenseKind["_ccByNd25"] = "CC-BY-ND-2.5";
    ImplementationGuideLicenseKind["_ccByNd30"] = "CC-BY-ND-3.0";
    ImplementationGuideLicenseKind["_ccByNd40"] = "CC-BY-ND-4.0";
    ImplementationGuideLicenseKind["_ccBySa10"] = "CC-BY-SA-1.0";
    ImplementationGuideLicenseKind["_ccBySa20"] = "CC-BY-SA-2.0";
    ImplementationGuideLicenseKind["_ccBySa25"] = "CC-BY-SA-2.5";
    ImplementationGuideLicenseKind["_ccBySa30"] = "CC-BY-SA-3.0";
    ImplementationGuideLicenseKind["_ccBySa40"] = "CC-BY-SA-4.0";
    ImplementationGuideLicenseKind["_cc010"] = "CC0-1.0";
    ImplementationGuideLicenseKind["_cddl10"] = "CDDL-1.0";
    ImplementationGuideLicenseKind["_cddl11"] = "CDDL-1.1";
    ImplementationGuideLicenseKind["_cdlaPermissive10"] = "CDLA-Permissive-1.0";
    ImplementationGuideLicenseKind["_cdlaSharing10"] = "CDLA-Sharing-1.0";
    ImplementationGuideLicenseKind["_cecill10"] = "CECILL-1.0";
    ImplementationGuideLicenseKind["_cecill11"] = "CECILL-1.1";
    ImplementationGuideLicenseKind["_cecill20"] = "CECILL-2.0";
    ImplementationGuideLicenseKind["_cecill21"] = "CECILL-2.1";
    ImplementationGuideLicenseKind["_cecillB"] = "CECILL-B";
    ImplementationGuideLicenseKind["_cecillC"] = "CECILL-C";
    ImplementationGuideLicenseKind["_clArtistic"] = "ClArtistic";
    ImplementationGuideLicenseKind["_cnriJython"] = "CNRI-Jython";
    ImplementationGuideLicenseKind["_cnriPythonGplCompatible"] = "CNRI-Python-GPL-Compatible";
    ImplementationGuideLicenseKind["_cnriPython"] = "CNRI-Python";
    ImplementationGuideLicenseKind["_condor11"] = "Condor-1.1";
    ImplementationGuideLicenseKind["_cpal10"] = "CPAL-1.0";
    ImplementationGuideLicenseKind["_cpl10"] = "CPL-1.0";
    ImplementationGuideLicenseKind["_cpol102"] = "CPOL-1.02";
    ImplementationGuideLicenseKind["_crossword"] = "Crossword";
    ImplementationGuideLicenseKind["_crystalStacker"] = "CrystalStacker";
    ImplementationGuideLicenseKind["_cuaOpl10"] = "CUA-OPL-1.0";
    ImplementationGuideLicenseKind["_cube"] = "Cube";
    ImplementationGuideLicenseKind["_curl"] = "curl";
    ImplementationGuideLicenseKind["_dFsl10"] = "D-FSL-1.0";
    ImplementationGuideLicenseKind["_diffmark"] = "diffmark";
    ImplementationGuideLicenseKind["_doc"] = "DOC";
    ImplementationGuideLicenseKind["_dotseqn"] = "Dotseqn";
    ImplementationGuideLicenseKind["_dsdp"] = "DSDP";
    ImplementationGuideLicenseKind["_dvipdfm"] = "dvipdfm";
    ImplementationGuideLicenseKind["_ecl10"] = "ECL-1.0";
    ImplementationGuideLicenseKind["_ecl20"] = "ECL-2.0";
    ImplementationGuideLicenseKind["_efl10"] = "EFL-1.0";
    ImplementationGuideLicenseKind["_efl20"] = "EFL-2.0";
    ImplementationGuideLicenseKind["_eGenix"] = "eGenix";
    ImplementationGuideLicenseKind["_entessa"] = "Entessa";
    ImplementationGuideLicenseKind["_epl10"] = "EPL-1.0";
    ImplementationGuideLicenseKind["_epl20"] = "EPL-2.0";
    ImplementationGuideLicenseKind["_erlPl11"] = "ErlPL-1.1";
    ImplementationGuideLicenseKind["_euDatagrid"] = "EUDatagrid";
    ImplementationGuideLicenseKind["_eupl10"] = "EUPL-1.0";
    ImplementationGuideLicenseKind["_eupl11"] = "EUPL-1.1";
    ImplementationGuideLicenseKind["_eupl12"] = "EUPL-1.2";
    ImplementationGuideLicenseKind["_eurosym"] = "Eurosym";
    ImplementationGuideLicenseKind["_fair"] = "Fair";
    ImplementationGuideLicenseKind["_frameworx10"] = "Frameworx-1.0";
    ImplementationGuideLicenseKind["_freeImage"] = "FreeImage";
    ImplementationGuideLicenseKind["_fsfap"] = "FSFAP";
    ImplementationGuideLicenseKind["_fsful"] = "FSFUL";
    ImplementationGuideLicenseKind["_fsfullr"] = "FSFULLR";
    ImplementationGuideLicenseKind["_ftl"] = "FTL";
    ImplementationGuideLicenseKind["_gfdl11Only"] = "GFDL-1.1-only";
    ImplementationGuideLicenseKind["_gfdl11OrLater"] = "GFDL-1.1-or-later";
    ImplementationGuideLicenseKind["_gfdl12Only"] = "GFDL-1.2-only";
    ImplementationGuideLicenseKind["_gfdl12OrLater"] = "GFDL-1.2-or-later";
    ImplementationGuideLicenseKind["_gfdl13Only"] = "GFDL-1.3-only";
    ImplementationGuideLicenseKind["_gfdl13OrLater"] = "GFDL-1.3-or-later";
    ImplementationGuideLicenseKind["_giftware"] = "Giftware";
    ImplementationGuideLicenseKind["_gl2Ps"] = "GL2PS";
    ImplementationGuideLicenseKind["_glide"] = "Glide";
    ImplementationGuideLicenseKind["_glulxe"] = "Glulxe";
    ImplementationGuideLicenseKind["_gnuplot"] = "gnuplot";
    ImplementationGuideLicenseKind["_gpl10Only"] = "GPL-1.0-only";
    ImplementationGuideLicenseKind["_gpl10OrLater"] = "GPL-1.0-or-later";
    ImplementationGuideLicenseKind["_gpl20Only"] = "GPL-2.0-only";
    ImplementationGuideLicenseKind["_gpl20OrLater"] = "GPL-2.0-or-later";
    ImplementationGuideLicenseKind["_gpl30Only"] = "GPL-3.0-only";
    ImplementationGuideLicenseKind["_gpl30OrLater"] = "GPL-3.0-or-later";
    ImplementationGuideLicenseKind["_gSoap13B"] = "gSOAP-1.3b";
    ImplementationGuideLicenseKind["_haskellReport"] = "HaskellReport";
    ImplementationGuideLicenseKind["_hpnd"] = "HPND";
    ImplementationGuideLicenseKind["_ibmPibs"] = "IBM-pibs";
    ImplementationGuideLicenseKind["_icu"] = "ICU";
    ImplementationGuideLicenseKind["_ijg"] = "IJG";
    ImplementationGuideLicenseKind["_imageMagick"] = "ImageMagick";
    ImplementationGuideLicenseKind["_iMatix"] = "iMatix";
    ImplementationGuideLicenseKind["_imlib2"] = "Imlib2";
    ImplementationGuideLicenseKind["_infoZip"] = "Info-ZIP";
    ImplementationGuideLicenseKind["_intelAcpi"] = "Intel-ACPI";
    ImplementationGuideLicenseKind["_intel"] = "Intel";
    ImplementationGuideLicenseKind["_interbase10"] = "Interbase-1.0";
    ImplementationGuideLicenseKind["_ipa"] = "IPA";
    ImplementationGuideLicenseKind["_ipl10"] = "IPL-1.0";
    ImplementationGuideLicenseKind["_isc"] = "ISC";
    ImplementationGuideLicenseKind["_jasPer20"] = "JasPer-2.0";
    ImplementationGuideLicenseKind["_json"] = "JSON";
    ImplementationGuideLicenseKind["_lal12"] = "LAL-1.2";
    ImplementationGuideLicenseKind["_lal13"] = "LAL-1.3";
    ImplementationGuideLicenseKind["_latex2E"] = "Latex2e";
    ImplementationGuideLicenseKind["_leptonica"] = "Leptonica";
    ImplementationGuideLicenseKind["_lgpl20Only"] = "LGPL-2.0-only";
    ImplementationGuideLicenseKind["_lgpl20OrLater"] = "LGPL-2.0-or-later";
    ImplementationGuideLicenseKind["_lgpl21Only"] = "LGPL-2.1-only";
    ImplementationGuideLicenseKind["_lgpl21OrLater"] = "LGPL-2.1-or-later";
    ImplementationGuideLicenseKind["_lgpl30Only"] = "LGPL-3.0-only";
    ImplementationGuideLicenseKind["_lgpl30OrLater"] = "LGPL-3.0-or-later";
    ImplementationGuideLicenseKind["_lgpllr"] = "LGPLLR";
    ImplementationGuideLicenseKind["_libpng"] = "Libpng";
    ImplementationGuideLicenseKind["_libtiff"] = "libtiff";
    ImplementationGuideLicenseKind["_liLiQP11"] = "LiLiQ-P-1.1";
    ImplementationGuideLicenseKind["_liLiQR11"] = "LiLiQ-R-1.1";
    ImplementationGuideLicenseKind["_liLiQRplus11"] = "LiLiQ-Rplus-1.1";
    ImplementationGuideLicenseKind["_linuxOpenIb"] = "Linux-OpenIB";
    ImplementationGuideLicenseKind["_lpl10"] = "LPL-1.0";
    ImplementationGuideLicenseKind["_lpl102"] = "LPL-1.02";
    ImplementationGuideLicenseKind["_lppl10"] = "LPPL-1.0";
    ImplementationGuideLicenseKind["_lppl11"] = "LPPL-1.1";
    ImplementationGuideLicenseKind["_lppl12"] = "LPPL-1.2";
    ImplementationGuideLicenseKind["_lppl13A"] = "LPPL-1.3a";
    ImplementationGuideLicenseKind["_lppl13C"] = "LPPL-1.3c";
    ImplementationGuideLicenseKind["_makeIndex"] = "MakeIndex";
    ImplementationGuideLicenseKind["_mirOs"] = "MirOS";
    ImplementationGuideLicenseKind["_mit0"] = "MIT-0";
    ImplementationGuideLicenseKind["_mitAdvertising"] = "MIT-advertising";
    ImplementationGuideLicenseKind["_mitCmu"] = "MIT-CMU";
    ImplementationGuideLicenseKind["_mitEnna"] = "MIT-enna";
    ImplementationGuideLicenseKind["_mitFeh"] = "MIT-feh";
    ImplementationGuideLicenseKind["_mit"] = "MIT";
    ImplementationGuideLicenseKind["_mitnfa"] = "MITNFA";
    ImplementationGuideLicenseKind["_motosoto"] = "Motosoto";
    ImplementationGuideLicenseKind["_mpich2"] = "mpich2";
    ImplementationGuideLicenseKind["_mpl10"] = "MPL-1.0";
    ImplementationGuideLicenseKind["_mpl11"] = "MPL-1.1";
    ImplementationGuideLicenseKind["_mpl20NoCopyleftException"] = "MPL-2.0-no-copyleft-exception";
    ImplementationGuideLicenseKind["_mpl20"] = "MPL-2.0";
    ImplementationGuideLicenseKind["_msPl"] = "MS-PL";
    ImplementationGuideLicenseKind["_msRl"] = "MS-RL";
    ImplementationGuideLicenseKind["_mtll"] = "MTLL";
    ImplementationGuideLicenseKind["_multics"] = "Multics";
    ImplementationGuideLicenseKind["_mup"] = "Mup";
    ImplementationGuideLicenseKind["_nasa13"] = "NASA-1.3";
    ImplementationGuideLicenseKind["_naumen"] = "Naumen";
    ImplementationGuideLicenseKind["_nbpl10"] = "NBPL-1.0";
    ImplementationGuideLicenseKind["_ncsa"] = "NCSA";
    ImplementationGuideLicenseKind["_netSnmp"] = "Net-SNMP";
    ImplementationGuideLicenseKind["_netCdf"] = "NetCDF";
    ImplementationGuideLicenseKind["_newsletr"] = "Newsletr";
    ImplementationGuideLicenseKind["_ngpl"] = "NGPL";
    ImplementationGuideLicenseKind["_nlod10"] = "NLOD-1.0";
    ImplementationGuideLicenseKind["_nlpl"] = "NLPL";
    ImplementationGuideLicenseKind["_nokia"] = "Nokia";
    ImplementationGuideLicenseKind["_nosl"] = "NOSL";
    ImplementationGuideLicenseKind["_noweb"] = "Noweb";
    ImplementationGuideLicenseKind["_npl10"] = "NPL-1.0";
    ImplementationGuideLicenseKind["_npl11"] = "NPL-1.1";
    ImplementationGuideLicenseKind["_nposl30"] = "NPOSL-3.0";
    ImplementationGuideLicenseKind["_nrl"] = "NRL";
    ImplementationGuideLicenseKind["_ntp"] = "NTP";
    ImplementationGuideLicenseKind["_occtPl"] = "OCCT-PL";
    ImplementationGuideLicenseKind["_oclc20"] = "OCLC-2.0";
    ImplementationGuideLicenseKind["_oDbL10"] = "ODbL-1.0";
    ImplementationGuideLicenseKind["_ofl10"] = "OFL-1.0";
    ImplementationGuideLicenseKind["_ofl11"] = "OFL-1.1";
    ImplementationGuideLicenseKind["_ogtsl"] = "OGTSL";
    ImplementationGuideLicenseKind["_oldap11"] = "OLDAP-1.1";
    ImplementationGuideLicenseKind["_oldap12"] = "OLDAP-1.2";
    ImplementationGuideLicenseKind["_oldap13"] = "OLDAP-1.3";
    ImplementationGuideLicenseKind["_oldap14"] = "OLDAP-1.4";
    ImplementationGuideLicenseKind["_oldap201"] = "OLDAP-2.0.1";
    ImplementationGuideLicenseKind["_oldap20"] = "OLDAP-2.0";
    ImplementationGuideLicenseKind["_oldap21"] = "OLDAP-2.1";
    ImplementationGuideLicenseKind["_oldap221"] = "OLDAP-2.2.1";
    ImplementationGuideLicenseKind["_oldap222"] = "OLDAP-2.2.2";
    ImplementationGuideLicenseKind["_oldap22"] = "OLDAP-2.2";
    ImplementationGuideLicenseKind["_oldap23"] = "OLDAP-2.3";
    ImplementationGuideLicenseKind["_oldap24"] = "OLDAP-2.4";
    ImplementationGuideLicenseKind["_oldap25"] = "OLDAP-2.5";
    ImplementationGuideLicenseKind["_oldap26"] = "OLDAP-2.6";
    ImplementationGuideLicenseKind["_oldap27"] = "OLDAP-2.7";
    ImplementationGuideLicenseKind["_oldap28"] = "OLDAP-2.8";
    ImplementationGuideLicenseKind["_oml"] = "OML";
    ImplementationGuideLicenseKind["_openSsl"] = "OpenSSL";
    ImplementationGuideLicenseKind["_opl10"] = "OPL-1.0";
    ImplementationGuideLicenseKind["_osetPl21"] = "OSET-PL-2.1";
    ImplementationGuideLicenseKind["_osl10"] = "OSL-1.0";
    ImplementationGuideLicenseKind["_osl11"] = "OSL-1.1";
    ImplementationGuideLicenseKind["_osl20"] = "OSL-2.0";
    ImplementationGuideLicenseKind["_osl21"] = "OSL-2.1";
    ImplementationGuideLicenseKind["_osl30"] = "OSL-3.0";
    ImplementationGuideLicenseKind["_pddl10"] = "PDDL-1.0";
    ImplementationGuideLicenseKind["_php30"] = "PHP-3.0";
    ImplementationGuideLicenseKind["_php301"] = "PHP-3.01";
    ImplementationGuideLicenseKind["_plexus"] = "Plexus";
    ImplementationGuideLicenseKind["_postgreSql"] = "PostgreSQL";
    ImplementationGuideLicenseKind["_psfrag"] = "psfrag";
    ImplementationGuideLicenseKind["_psutils"] = "psutils";
    ImplementationGuideLicenseKind["_python20"] = "Python-2.0";
    ImplementationGuideLicenseKind["_qhull"] = "Qhull";
    ImplementationGuideLicenseKind["_qpl10"] = "QPL-1.0";
    ImplementationGuideLicenseKind["_rdisc"] = "Rdisc";
    ImplementationGuideLicenseKind["_rHeCos11"] = "RHeCos-1.1";
    ImplementationGuideLicenseKind["_rpl11"] = "RPL-1.1";
    ImplementationGuideLicenseKind["_rpl15"] = "RPL-1.5";
    ImplementationGuideLicenseKind["_rpsl10"] = "RPSL-1.0";
    ImplementationGuideLicenseKind["_rsaMd"] = "RSA-MD";
    ImplementationGuideLicenseKind["_rscpl"] = "RSCPL";
    ImplementationGuideLicenseKind["_ruby"] = "Ruby";
    ImplementationGuideLicenseKind["_saxPd"] = "SAX-PD";
    ImplementationGuideLicenseKind["_saxpath"] = "Saxpath";
    ImplementationGuideLicenseKind["_scea"] = "SCEA";
    ImplementationGuideLicenseKind["_sendmail"] = "Sendmail";
    ImplementationGuideLicenseKind["_sgiB10"] = "SGI-B-1.0";
    ImplementationGuideLicenseKind["_sgiB11"] = "SGI-B-1.1";
    ImplementationGuideLicenseKind["_sgiB20"] = "SGI-B-2.0";
    ImplementationGuideLicenseKind["_simPl20"] = "SimPL-2.0";
    ImplementationGuideLicenseKind["_sissl12"] = "SISSL-1.2";
    ImplementationGuideLicenseKind["_sissl"] = "SISSL";
    ImplementationGuideLicenseKind["_sleepycat"] = "Sleepycat";
    ImplementationGuideLicenseKind["_smlnj"] = "SMLNJ";
    ImplementationGuideLicenseKind["_smppl"] = "SMPPL";
    ImplementationGuideLicenseKind["_snia"] = "SNIA";
    ImplementationGuideLicenseKind["_spencer86"] = "Spencer-86";
    ImplementationGuideLicenseKind["_spencer94"] = "Spencer-94";
    ImplementationGuideLicenseKind["_spencer99"] = "Spencer-99";
    ImplementationGuideLicenseKind["_spl10"] = "SPL-1.0";
    ImplementationGuideLicenseKind["_sugarCrm113"] = "SugarCRM-1.1.3";
    ImplementationGuideLicenseKind["_swl"] = "SWL";
    ImplementationGuideLicenseKind["_tcl"] = "TCL";
    ImplementationGuideLicenseKind["_tcpWrappers"] = "TCP-wrappers";
    ImplementationGuideLicenseKind["_tMate"] = "TMate";
    ImplementationGuideLicenseKind["_torque11"] = "TORQUE-1.1";
    ImplementationGuideLicenseKind["_tosl"] = "TOSL";
    ImplementationGuideLicenseKind["_unicodeDfs2015"] = "Unicode-DFS-2015";
    ImplementationGuideLicenseKind["_unicodeDfs2016"] = "Unicode-DFS-2016";
    ImplementationGuideLicenseKind["_unicodeTou"] = "Unicode-TOU";
    ImplementationGuideLicenseKind["_unlicense"] = "Unlicense";
    ImplementationGuideLicenseKind["_upl10"] = "UPL-1.0";
    ImplementationGuideLicenseKind["_vim"] = "Vim";
    ImplementationGuideLicenseKind["_vostrom"] = "VOSTROM";
    ImplementationGuideLicenseKind["_vsl10"] = "VSL-1.0";
    ImplementationGuideLicenseKind["_w3C19980720"] = "W3C-19980720";
    ImplementationGuideLicenseKind["_w3C20150513"] = "W3C-20150513";
    ImplementationGuideLicenseKind["_w3C"] = "W3C";
    ImplementationGuideLicenseKind["_watcom10"] = "Watcom-1.0";
    ImplementationGuideLicenseKind["_wsuipa"] = "Wsuipa";
    ImplementationGuideLicenseKind["_wtfpl"] = "WTFPL";
    ImplementationGuideLicenseKind["_x11"] = "X11";
    ImplementationGuideLicenseKind["_xerox"] = "Xerox";
    ImplementationGuideLicenseKind["_xFree8611"] = "XFree86-1.1";
    ImplementationGuideLicenseKind["_xinetd"] = "xinetd";
    ImplementationGuideLicenseKind["_xnet"] = "Xnet";
    ImplementationGuideLicenseKind["_xpp"] = "xpp";
    ImplementationGuideLicenseKind["_xSkat"] = "XSkat";
    ImplementationGuideLicenseKind["_ypl10"] = "YPL-1.0";
    ImplementationGuideLicenseKind["_ypl11"] = "YPL-1.1";
    ImplementationGuideLicenseKind["_zed"] = "Zed";
    ImplementationGuideLicenseKind["_zend20"] = "Zend-2.0";
    ImplementationGuideLicenseKind["_zimbra13"] = "Zimbra-1.3";
    ImplementationGuideLicenseKind["_zimbra14"] = "Zimbra-1.4";
    ImplementationGuideLicenseKind["_zlibAcknowledgement"] = "zlib-acknowledgement";
    ImplementationGuideLicenseKind["_zlib"] = "Zlib";
    ImplementationGuideLicenseKind["_zpl11"] = "ZPL-1.1";
    ImplementationGuideLicenseKind["_zpl20"] = "ZPL-2.0";
    ImplementationGuideLicenseKind["_zpl21"] = "ZPL-2.1";
})(ImplementationGuideLicenseKind = exports.ImplementationGuideLicenseKind || (exports.ImplementationGuideLicenseKind = {}));
var EnumType_1 = require("../../EnumType");
exports.RTTI_ImplementationGuide = t.recursion('IImplementationGuide', function () {
    return t.intersection([
        t.type({
            resourceType: t.literal('ImplementationGuide')
        }),
        t.partial({
            id: RTTI_id_1.RTTI_id,
            meta: RTTI_Meta_1.RTTI_Meta,
            implicitRules: RTTI_uri_1.RTTI_uri,
            _implicitRules: RTTI_Element_1.RTTI_Element,
            language: RTTI_code_1.RTTI_code,
            _language: RTTI_Element_1.RTTI_Element,
            text: RTTI_Narrative_1.RTTI_Narrative,
            contained: t.array(RTTI_ResourceList_1.RTTI_ResourceList),
            extension: t.array(RTTI_Extension_1.RTTI_Extension),
            modifierExtension: t.array(RTTI_Extension_1.RTTI_Extension),
            url: RTTI_uri_1.RTTI_uri,
            _url: RTTI_Element_1.RTTI_Element,
            version: t.string,
            _version: RTTI_Element_1.RTTI_Element,
            name: t.string,
            _name: RTTI_Element_1.RTTI_Element,
            title: t.string,
            _title: RTTI_Element_1.RTTI_Element,
            status: EnumType_1.createEnumType(ImplementationGuideStatusKind, 'ImplementationGuideStatusKind'),
            _status: RTTI_Element_1.RTTI_Element,
            experimental: t.boolean,
            _experimental: RTTI_Element_1.RTTI_Element,
            date: RTTI_dateTime_1.RTTI_dateTime,
            _date: RTTI_Element_1.RTTI_Element,
            publisher: t.string,
            _publisher: RTTI_Element_1.RTTI_Element,
            contact: t.array(RTTI_ContactDetail_1.RTTI_ContactDetail),
            description: RTTI_markdown_1.RTTI_markdown,
            _description: RTTI_Element_1.RTTI_Element,
            useContext: t.array(RTTI_UsageContext_1.RTTI_UsageContext),
            jurisdiction: t.array(RTTI_CodeableConcept_1.RTTI_CodeableConcept),
            copyright: RTTI_markdown_1.RTTI_markdown,
            _copyright: RTTI_Element_1.RTTI_Element,
            packageId: RTTI_id_1.RTTI_id,
            _packageId: RTTI_Element_1.RTTI_Element,
            license: EnumType_1.createEnumType(ImplementationGuideLicenseKind, 'ImplementationGuideLicenseKind'),
            _license: RTTI_Element_1.RTTI_Element,
            fhirVersion: t.array(EnumType_1.createEnumType(ImplementationGuideFhirVersionKind, 'ImplementationGuideFhirVersionKind')),
            _fhirVersion: t.array(RTTI_Element_1.RTTI_Element),
            dependsOn: t.array(RTTI_ImplementationGuide_DependsOn_1.RTTI_ImplementationGuide_DependsOn),
            global: t.array(RTTI_ImplementationGuide_Global_1.RTTI_ImplementationGuide_Global),
            definition: RTTI_ImplementationGuide_Definition_1.RTTI_ImplementationGuide_Definition,
            manifest: RTTI_ImplementationGuide_Manifest_1.RTTI_ImplementationGuide_Manifest
        })
    ]);
});
