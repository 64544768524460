import {Row, Col, Button, Collapse} from 'antd';
import Modal from 'antd/lib/modal/Modal';
import {FieldArray, Formik} from 'formik';
import React from 'react';
import {FormUX} from '../../form-ux';
import {AppointmentTypeFormUXModel} from './appointment-type-form-ux-model';

/**
 *
 *
 * @export
 * @interface IAppointmentTypeFieldProps
 */
export interface IAppointmentTypeFieldProps {
  /**
   * The name of the field in the parent object which has appointment types
   *
   * @type {string}
   * @memberof IAppointmentTypeFieldProps
   */
  fieldName: string;
}

/* Formik field to add/edit/delete appointment types */
export const AppointmentTypesField = (props: IAppointmentTypeFieldProps) => {
  /* Keeps track of whether the user is currently adding a new 
    appointment type*/
  const [addingNew, setAddingNew] = React.useState(false);
  /* Keep track off whether the index of the appointment type the user wants
  to delete. Set to -1 if the user is not deleting anything. */
  const [deletingIndex, setDeletingIndex] = React.useState(-1);

  //Close add appointment types modal
  const onClose = () => setAddingNew(false);

  return (
    <FieldArray name={props.fieldName}>
      {(fieldArrayHelpers) => {
        return (
          <>
            <Row>
              <Col>Appointment Types</Col>
              <Col>
                <Button
                  onClick={() => {
                    setAddingNew(true);
                  }}
                >
                  Add new appointment type
                </Button>
              </Col>
            </Row>

            {/* The modal to show when the user wants to add a new
            appointment type */}
            <Formik
              initialValues={{
                nameEn: '',
                nameFr: '',
                duration: 0,
                numberOfPatients: 0,
                calendars: [],
              }}
              onSubmit={() => {}}
            >
              {(newAppointmentTypeForm) => {
                return (
                  <Modal
                    visible={addingNew}
                    okText="Save"
                    okButtonProps={{
                      /* Disabled if the form is not dirty or if its not valid*/
                      disabled: !newAppointmentTypeForm.dirty || !newAppointmentTypeForm.isValid,
                      onClick: () => {
                        fieldArrayHelpers.push(newAppointmentTypeForm.values);
                        setAddingNew(false);
                        newAppointmentTypeForm.resetForm();
                      },
                    }}
                    onCancel={onClose}
                  >
                    <FormUX
                      formUXModel={AppointmentTypeFormUXModel}
                      createMode={true}
                      modal={true}
                    />
                  </Modal>
                );
              }}
            </Formik>

            {/* The current appointment types added by the user */}
            {fieldArrayHelpers.form.values[props.fieldName].map((value, index) => {
              return (
                <Collapse>
                  <Collapse.Panel
                    header={value.nameEn}
                    key={index}
                    extra={
                      <Button
                        onClick={() => {
                          setDeletingIndex(index);
                        }}
                      >
                        Delete
                      </Button>
                    }
                  >
                    <FormUX
                      formUXModel={AppointmentTypeFormUXModel}
                      createMode={false}
                      modal={false}
                      namePrefix={`appointmentTypes[${index}]`}
                    />
                  </Collapse.Panel>
                </Collapse>
              );
            })}

            {/* Modal to confirm if the user wants to delete the appointment */}
            <Modal
              visible={deletingIndex !== -1}
              onCancel={() => {
                setDeletingIndex(-1);
              }}
              onOk={() => {
                fieldArrayHelpers.remove(deletingIndex);
                setDeletingIndex(-1);
              }}
            >
              Are you sure you want to delete this Appointment Type?
            </Modal>
          </>
        );
      }}
    </FieldArray>
  );
};
