import {FormatTimezoneAbbr} from '@canimmunize/tools';
import {Button, Card, Tag, Space, message, Popconfirm} from 'antd';
import {Input} from 'formik-antd';
import moment from 'moment-timezone';
import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {RootState, ThunkDispatch} from '../../models';
import AppointmentModel from '../../models/appointments';
import {fullDisplayName} from '../../models/fhir/patient';
import {FhirUtils} from '../../services/fhir';
import {FormConfig} from '../base-entity/base-entity';
import {FHIRTableProps} from '../fhir-table';
import {FormUX} from '../form-ux';
import {AppointmentCalendar} from './appointment-calendar';
import {AppointmentDetails} from './appointment-details';
import {AppointmentUXModel} from './appointment-form-ux-model';
import * as Icons from '@ant-design/icons';
import {CreateButton} from './scheduler/schedule-modal';
import {BaseEntityV2} from '../base-entity/base-entity';
import {useEnvInfo} from '../../services/environment';
import {useGlobalOrg} from '../../models/ui';

const {TextArea} = Input;

export const AppointmentUX = () => {
  const globalOrg = useGlobalOrg();
  const navigate = useNavigate();
  const {environmentId} = useEnvInfo();
  const model = AppointmentModel;

  const itemTitleField = 'name';

  const FormItems = (props) => {
    return !props.editMode ? (
      <AppointmentDetails {...props} />
    ) : (
      <FormUX formUXModel={AppointmentUXModel} createMode={props.createMode} modal={props.modal} />
    );
  };

  const formConfig: FormConfig = {
    defaultValues: {},
    // toFhir: FhirUtils.patient.patientFactory,
    FormItems,
  };

  const fixedFilters: any = globalOrg
    ? {
        _organizationId: globalOrg?.id,
      }
    : {};

  const columns = [
    {
      title: 'Name',
      render: (_, appt) => (
        <a
          onClick={() => {
            navigate(`/appointments/${appt.id}`);
          }}
        >
          {appt.fullName}
        </a>
      ),
    },
    ...(environmentId.includes('yukon-2')
      ? [
          {
            title: 'Date of Birth',
            render: (_, appt) =>
              appt.patients[0]?.birthdate ? moment(appt.patients[0].birthdate).format('LL') : '-',
          },
          {
            title: 'Email',
            render: (_, appt) => appt.email || '-',
          },
          {
            title: 'HCN',
            render: (_, appt) => appt.patients[0].hcn || '-',
          },
        ]
      : []),
    {
      title: 'Date',
      dataIndex: 'datetime',
      key: 'datetime',
      render: (_, appt) =>
        `${moment.tz(appt.datetime, appt.timezone).format('LLL')} ${FormatTimezoneAbbr(
          moment.tz(appt.datetime, appt.timezone).format('zz'),
          'en',
          appt.timezone
        )}`,
    },
    {
      title: 'Status',
      render: (_, item) => <AppointmentStatusTag appointment={item} />,
    },

    {
      title: 'Actions',
      render: (_, item) => (
        <Button onClick={() => navigate(`/appointments/${item.id}`)} type="primary">
          Open
        </Button>
      ),
    },
  ];

  const TableFooter = () => (
    <Card>
      <AppointmentCalendar />
    </Card>
  );

  const tableConfig: FHIRTableProps = {
    fhirResource: 'appointment',
    // basicSearchParams: ['name', 'given', 'family', 'email', 'phone'],
    mode: 'search',
    label: 'Appointment',
    defaultPrimarySearchParam: 'name',
    fixedFilters,
    showCreateButton: true,
    onClickRow: () => {},
    columns,
    CreateButton: CreateButton,
  };

  const config = {
    slug: 'appointment',
    model,
    itemTitleField: (u) => fullDisplayName(u),
    formConfig,
    searchPageTitle: 'Appointments',
    tableConfig,
    TableFooter,
    modalEditForm: true,
    hideDeleteButton: true,
    hideSaveButton: true,
  };

  return <BaseEntityV2 {...config} />;
};

const getAppointmentStatus = (app): {status; color} => {
  let status, color;

  if (app.canceled) {
    status = 'Canceled';
    color = 'red';
  } else if (app.deleted) {
    status = 'Deleted';
    color = 'red';
  } else if (app.completed) {
    status = 'Completed';
    color = 'green';
  } else if (app.noShow) {
    status = 'No-show';
    color = 'purple';
  } else if (app.checkedIn && moment(app.datetime).isAfter(moment(), 'day')) {
    status = 'Checked In';
    color = 'pink';
  } else if (moment(app.datetime).isBefore(moment())) {
    status = 'Past (Unactioned)';
    color = 'orange';
  } else {
    status = 'Scheduled';
    color = 'blue';
  }

  return {status, color};
};

export const AppointmentStatusTag = (props) => {
  const {status, color} = getAppointmentStatus(props.appointment);
  return <Tag color={color}>{status}</Tag>;
};
