import {FormatTimezoneAbbr} from '@canimmunize/tools';
import {Form} from 'formik-antd';
import moment from 'moment';
import React, {useRef} from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '../../../models';
import {FhirUtils} from '../../../services/fhir';
import {RequiredValidationRuleId} from '../../../validation/validation-rules/required-validation';
import {RequiredDefinedValidationRuleId} from '../../../validation/validation-rules/required-defined-validation';
import {ValidateFormItem} from '../../validate-form-item';
import {DebounceSelect} from './debounce-select';
import { initialFlagTypes } from '../flags/update-flag-modal';

// Usage of DebounceSelect
export interface DebounceValue {
  label: string;
  value: string;
}

// interface DebounceFormItemProps extends IFormUXCustomField{
//   form: FormikProps<any>;
// }

export const DebounceFormItem = (props) => {
  const {
    name,
    label,
    validationRules,
    editable,
    form,
    generateValue,
    generateLabel,
    initialValues,
    immunizer,
    patientId,
    clinicId,
    setClinicId,
    fetchType,
    handleSetExpiryDate,
    handleSetCountry,
    allowClear,
    showSearch,
    allowFreeForm,
    extra,
  } = props;

  const client = FhirUtils.useClient();
  const globalOrg = useSelector((state: RootState) => state.ui.localOrg);

  let fetchParams: {[key: string]: string} = {};
  let defaultValue, defaultOption: DebounceValue | null | undefined;
  let fetchRoute: string, placeholder: string;
  let noneOption: {label: string; value: string};
  switch (fetchType) {
    case 'lots':
      defaultValue = initialValues.lotNumber;
      fetchRoute = `/cvc/lot-numbers/${form.values.conceptId}`;
      break;
    case 'users':
      defaultValue = {
        label: immunizer
          ? `${immunizer.firstName} ${immunizer.lastName}${
              immunizer?.licenseNumber ? ` - ` + immunizer?.licenseNumber : ''
            }`
          : '',
        value: immunizer?.id || '',
      };
      fetchRoute = `/clinic-mode-user`;
      fetchParams = {
        role: 'immunizer',
        clinicId,
        _pageView: 'clinic',
      };
      break;
    case 'clinic':
      defaultValue = {
        label: initialValues.clinicEntry?.clinic?.name,
        value: initialValues.clinicEntry?.clinic?.id,
      };
      fetchRoute = `/clinic`;
      fetchParams = {
        _organizationId: globalOrg?.id || '',
      };
      break;
    case 'appointment':
      noneOption = {label: 'No associated appointment', value: ''};
      const appt = initialValues.clinicEntry?.appointment;
      defaultValue = appt
        ? {
            label: `${moment.tz(appt.datetime, appt.timezone).format('LLL')} ${FormatTimezoneAbbr(
              moment.tz(appt.datetime, appt.timezone).format('zz'),
              'en',
              appt.timezone
            )} - ${appt.clinic?.name || ''}`,
            value: appt.id,
          }
        : Object.keys(initialValues).length // Check if there is no appointmentId but there is dose information
        ? noneOption // explicitly no appointment attached
        : undefined;
      fetchRoute = `/appointment`;
      fetchParams = {
        patientId: patientId,
        includeClinic: 'true',
        isPast: 'true',
        excludeCanceled: 'true',
        hasNoDose: 'true',
      };
      defaultOption = defaultValue;
      placeholder = 'Select the associated appointment';
      break;
    case 'test':
      defaultValue = {
        label: initialValues?.name,
        value: initialValues?.testDefinitionId,
      };
      fetchRoute = `/test-definition`;
      break;
    case 'license-body':
      defaultValue = {
        label: initialValues?.licenseBodyObj?.name,
        value: initialValues?.licenseBody,
      };
      fetchRoute = `/license-body`;
      break;
    case 'service-delivery-location':
      defaultValue = {
        label: initialValues?.serviceDeliveryLocation?.name?.en,
        value: initialValues?.serviceDeliveryLocation?.id,
      };
      fetchRoute = `/service-delivery-location`;
      break;
    case 'intendedBranch':
      defaultValue = {
        label: initialValues?.intendedBranch?.name ?? initialValues?.intendedBranchId,
        value: initialValues?.intendedBranchId,
      };
      fetchRoute = `/branch`;
      break;
    case 'invalidReason':
      defaultValue = {
        label: initialValues?.invalidDoseText,
        value: initialValues?.invalidReasonCode,
      };
      fetchRoute = `/invalid-dose`;
      break;
    case 'validReason':
      defaultValue = {
        label: initialValues?.validReason?.display,
        value: initialValues?.validReasonCode,
      };
      fetchRoute = 'valid-dose-reason';
      break;
    case 'flag-type':
      defaultValue = {
        label: initialFlagTypes(initialValues?.type),
        value: initialValues?.type,
      };
      fetchRoute = `/flag-types`;
      break;
    case 'validOnBranchDose':
      defaultValue = {
        label: initialValues?.validOnBranchDose
          ? `${initialValues.validOnBranchDose?.branch?.name} - Dose ${initialValues.validOnBranchDose?.branchDoseNumber}`
          : undefined,
        value: initialValues?.validOnBranchDoseId,
      };
      fetchRoute = '/branch-dose';
      break;
    default:
      defaultValue = {label: '', value: ''};
      fetchRoute = '';
  }

  const [selection, setSelection] = React.useState<any>(defaultValue);

  //set form value when debouncer type selection is made
  React.useEffect(() => {
    const fieldValue = allowFreeForm ? selection : selection?.value;
    form.setFieldValue(name, fieldValue);
    if (fetchType === 'lots') {
      handleSetExpiryDate(fieldValue, form.values.conceptId);
    } else if (fetchType === 'service-delivery-location') {
      handleSetCountry(fieldValue);
    }
  }, [selection]);

  const isInitialMount = useRef(true);
  //if form item is lot picker, clear lotNumber field in form when vaccine type (conceptId) is changed
  //Do not run useEffect on initial mount of component
  React.useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else if (fetchType === 'lots') {
      setSelection('');
    }
  }, [form.values.conceptId]);

  async function fetchOptions(search?: string): Promise<DebounceValue[]> {
    const params = {...fetchParams, ...(typeof search === 'string' && {name: search})};

    return client.get(fetchRoute, {params}).then(async (res) => {
      const resData = ['invalidReason', 'validReason'].includes(fetchType)
        ? res.data.entry
        : res.data.entry.map((item) => item.resource);
      const filteredData =
        fetchType === 'appointment'
          ? resData?.filter((apmt) => apmt.clinicId === clinicId || apmt.clinic?.id === clinicId)
          : resData;
      return filteredData.map((val) => ({
        label: generateLabel(val),
        value: generateValue(val),
      }));
    });
  }

  const onHandleChange = (item) => {
    const emptyField = allowFreeForm ? '' : {label: '', value: ''};
    if (name === 'clinicId') {
      setClinicId(item.value);
    }
    setSelection(item || (noneOption ? undefined : emptyField));
  };

  const required =
    validationRules.find(
      (rule) =>
        rule.validationRuleType === RequiredValidationRuleId ||
        rule.validationRuleType === RequiredDefinedValidationRuleId
    ) !== undefined;

  return (
    <ValidateFormItem
      validationRules={validationRules || []}
      renderFormItem={(validate) => {
        return (
          <Form.Item
            name={name}
            label={<label style={{height: '0px'}}>{label}</label>}
            validate={validate}
            required={required}
            tooltip={
              required
                ? {
                    icon: <span style={{color: 'red', fontSize: 14, fontWeight: 'bolder'}}>*</span>,
                  }
                : undefined
            }
            extra={extra}
          >
            <DebounceSelect
              name={name}
              showSearch={showSearch}
              size="middle"
              value={selection}
              fetchOptions={fetchOptions}
              onChange={onHandleChange}
              disabled={form.values.deleted || !editable}
              style={{width: '100%'}}
              allowClear={allowClear}
              noneOption={noneOption}
              placeholder={placeholder}
              defaultOption={defaultOption}
              allowFreeForm={allowFreeForm}
            />
          </Form.Item>
        );
      }}
    />
  );
};
