import {Card, Col, Collapse, Row, Statistic, Tag} from 'antd';
import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import CalendarModel from '../../../models/calendar';
import {useGlobalSettings} from '../../../models/global-settings';
import {getClinicUrlComponents} from '../../../util/clinic-url-helpers';
import {BaseEntityV2, FormConfig} from '../../base-entity/base-entity';
import {LocationForm} from '../../clinic/location-form';
import {FHIRTableProps} from '../../fhir-table';
import {FormUX} from '../../form-ux';
import {AvailabilityRulesField} from './availability-rule/availability-rules-field';
import {CalendarCustomFieldNames, generateCalendarFormUXModel} from './calendar-form-ux-model';
import {CalendarListActions} from './calendar-list-actions';

export function CalendarUX(props) {
  const {id: clinicId, action} = useParams();
  const navigate = useNavigate();
  const setting = useGlobalSettings();
  const CalendarUxForm = (props) => {
    //organizationId associated with the clinic of the calendar
    const organizationId =
      props.values && props.values.clinic ? props.values.clinic.organizationId : undefined;
    const showAppointmentSummary = setting['showAppointmentSummary']?.value === 'true';

    return (
      <>
        <FormUX
          formUXModel={generateCalendarFormUXModel(props.values?.clinic?.category)}
          createMode={props.createMode}
          modal={props.modal}
          renderCustomField={(field) => {
            switch (field.name) {
              case CalendarCustomFieldNames.AvailabilityRules: {
                return (
                  <AvailabilityRulesField
                    fieldName="availabilityRules"
                    clinic={props.values.clinic}
                  />
                );
              }
              case CalendarCustomFieldNames.Location: {
                return (
                  <Collapse>
                    <Collapse.Panel header="Location" key={0}>
                      <LocationForm name={field.name} organizationId={organizationId} />
                    </Collapse.Panel>
                  </Collapse>
                );
              }
              default: {
                throw new Error(
                  `Unhandled custom field with name ${field.name} for edit calendar form ux`
                );
              }
            }
          }}
        />
        {showAppointmentSummary && !props.createMode && <CalendarStats />}
      </>
    );
  };

  const formConfig: FormConfig = {
    preSubmit: (values, actions, onSubmit, entity) => {
      const submitObj = values;
      /* Add the id of the clinic this calendar object is part of */
      submitObj.clinicId = clinicId;

      return onSubmit({...submitObj}, actions);
    },
    FormItems: CalendarUxForm,
    defaultValues: {
      availabilityRules: [],
    },
  };

  const tableConfig: FHIRTableProps = {
    mode: 'table' as 'table',
    fhirResource: 'calendar',
    label: 'Calendar',
    defaultPrimarySearchParam: 'name',
    columns: [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        sorter: true,
        render: (_, item) => {
          let color;
          let text;
          switch (item.status) {
            case 'active':
              color = 'green';
              text = 'Active';
              break;
            case 'disabled':
              color = 'red';
              text = 'Disabled';
              break;
            case 'staging':
              color = 'orange';
              text = 'Staging';
              break;
            default:
              break;
          }
          return <Tag color={color}>{text}</Tag>;
        },
        filters: [
          {text: 'Public', value: 'public'},
          {text: 'Staging', value: 'staging'},
          {text: 'Disabled', value: 'disabled'},
        ],
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
      },
      {
        title: 'Links',
        render: (_, item) => <CalendarListActions calendar={item} />,
      },
    ],
    showCreateButton: true,
    /* Need to dynamically set the fixed filters since its depends
    on the current clinicId in the URL */
    fixedFilters: () => {
      return {
        clinicId: getClinicUrlComponents().clinicId,
      };
    },
    onClickRow: (row) => {
      console.log(row);
      navigate(`/clinics/${clinicId}/calendars/${row.id}`);
    },
  };

  const config = {
    slug: 'calendar',
    model: CalendarModel,
    itemTitleField: 'name',
    modalCreateForm: true,
    formConfig,
    hideEditButton: true,
    showBackButton: false,
    showSearchTitle: false,
    onCreationSuccess: (res) => {
      setTimeout(() => navigate(`/clinics/${clinicId}/calendars/${res.id}`), 0);
    },
    getCurrentItemId: () => {
      return action === undefined ? 'new' : action;
    },
    getDeleteSuccessPath: () => {
      return `/clinics/${clinicId}`;
    },
    tableConfig,
  };

  return <BaseEntityV2 {...props} {...config} disableWrapper />;
}

export const CalendarStats = () => {
  return (
    <Card style={{boxShadow: 'none', marginBottom: 15}}>
      <Row gutter={16}>
        <Col span={8}>
          <Statistic title="Total Appointments" value={'--'} />
        </Col>
        <Col span={8}>
          <Statistic title="Future Appointments" value={'--'} />
        </Col>
        <Col span={8}>
          <Statistic title="Past Appointments" value={'--'} />
        </Col>
      </Row>
    </Card>
  );
};
