"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_ImplementationGuide_Parameter = exports.ImplementationGuide_ParameterCodeKind = void 0;
var t = __importStar(require("io-ts"));
var RTTI_Extension_1 = require("./RTTI_Extension");
var RTTI_Element_1 = require("./RTTI_Element");
var ImplementationGuide_ParameterCodeKind;
(function (ImplementationGuide_ParameterCodeKind) {
    ImplementationGuide_ParameterCodeKind["_apply"] = "apply";
    ImplementationGuide_ParameterCodeKind["_pathResource"] = "path-resource";
    ImplementationGuide_ParameterCodeKind["_pathPages"] = "path-pages";
    ImplementationGuide_ParameterCodeKind["_pathTxCache"] = "path-tx-cache";
    ImplementationGuide_ParameterCodeKind["_expansionParameter"] = "expansion-parameter";
    ImplementationGuide_ParameterCodeKind["_ruleBrokenLinks"] = "rule-broken-links";
    ImplementationGuide_ParameterCodeKind["_generateXml"] = "generate-xml";
    ImplementationGuide_ParameterCodeKind["_generateJson"] = "generate-json";
    ImplementationGuide_ParameterCodeKind["_generateTurtle"] = "generate-turtle";
    ImplementationGuide_ParameterCodeKind["_htmlTemplate"] = "html-template";
})(ImplementationGuide_ParameterCodeKind = exports.ImplementationGuide_ParameterCodeKind || (exports.ImplementationGuide_ParameterCodeKind = {}));
var EnumType_1 = require("../../EnumType");
exports.RTTI_ImplementationGuide_Parameter = t.recursion('IImplementationGuide_Parameter', function () {
    return t.partial({
        id: t.string,
        extension: t.array(RTTI_Extension_1.RTTI_Extension),
        modifierExtension: t.array(RTTI_Extension_1.RTTI_Extension),
        code: EnumType_1.createEnumType(ImplementationGuide_ParameterCodeKind, 'ImplementationGuide_ParameterCodeKind'),
        _code: RTTI_Element_1.RTTI_Element,
        value: t.string,
        _value: RTTI_Element_1.RTTI_Element
    });
});
