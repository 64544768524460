import {R4} from '@ahryman40k/ts-fhir-types';
import {createSlice} from '@reduxjs/toolkit';
import {normalize, schema} from 'normalizr';
import {mapBundleToResourceArray} from '../util/fhir';
import Patient from './patients';

export interface ImmunizationsSliceInterface {
  byId: {[string: string]: R4.IImmunization};
  historyById: {[string: string]: R4.IImmunization};
}
const initialState = {
  byId: {},
  historyById: {},
};
const immunization = new schema.Entity('immunizations', {});
const immunizationHistory = new schema.Entity(
  'immunizations',
  {},
  {
    idAttribute: (value) => {
      return `${value.id}-${value.meta.versionId}`;
    },
  }
);

const slice = createSlice({
  name: 'immunizations',
  initialState,
  reducers: {
    SAVE_IMMUNIZATIONS: (state: ImmunizationsSliceInterface, action) => {
      const resources = mapBundleToResourceArray(action.payload);
      state.byId = normalize(resources, [immunization]).entities.immunizations || {};
    },
    SAVE_IMMUNIZATION: (state: ImmunizationsSliceInterface, action) => {
      state.byId[action.payload.id] = action.payload;
    },
    SAVE_IMMUNIZATION_HISTORY: (state: ImmunizationsSliceInterface, action) => {
      const resources = mapBundleToResourceArray(action.payload);
      state.historyById = normalize(resources, [immunizationHistory]).entities.immunizations || {};
    },
    DELETE_IMMUNIZATION: (state: ImmunizationsSliceInterface, action) => {
      delete state.byId[action.payload.id];
    },
  },
});

const getAll = (client) => async (dispatch) => {
  return client
    .request('/Immunization')
    .then((res) => {
      return dispatch(slice.actions.SAVE_IMMUNIZATIONS(res));
    })
    .catch((err) => alert(err));
};

export function getPatientImms(client, patientId) {
  return function (dispatch) {
    return client
      .request(`Immunization?patient=Patient/${patientId}`)
      .then((res) => dispatch(slice.actions.SAVE_IMMUNIZATIONS(res)));
  };
}

// TODO: I don't know which resource to use for this; probably a better way to do this
interface FHIRResource extends R4.IResource {
  resourceType: string;
}

const getOne = (client, id) => async (dispatch) => {
  return client
    .request(`Immunization/_search?_id=${id}&_include=Immunization:patient`)
    .then(async (res) => {
      const resources = mapBundleToResourceArray(res) as FHIRResource[];
      const immunizationResource = resources.find((res) => res.resourceType === 'Immunization');
      const patientResource = resources.find((res) => res.resourceType === 'Patient');
      dispatch(Patient.slice.actions.SAVE_PATIENT(patientResource));
      dispatch(slice.actions.SAVE_IMMUNIZATION(immunizationResource));
    });
};

const getOneHistory = (client, id) => async (dispatch) => {
  return client.request(`/Immunization/${id}/_history`).then((res) => {
    return dispatch(slice.actions.SAVE_IMMUNIZATION_HISTORY(res));
  });
};

const updateOne = (client, imm) => async (dispatch) => {
  return client.put(`/Immunization/${imm.id}`, imm).then(async (res) => {
    await dispatch(slice.actions.SAVE_IMMUNIZATION(res.data));
    return res.data;
  });
};

export const createOne = (client, imm) => async (dispatch) => {
  return client.post(`/Immunization`, imm).then(async (res) => {
    await dispatch(slice.actions.SAVE_IMMUNIZATION(res.data));
    return res.data;
  });
};

export const deleteOne = (client, id, note?) => async (dispatch) => {
  return client.delete(`/Immunization/${id}`, note && {data: {note}}).then(async (res) => {
    await dispatch(slice.actions.DELETE_IMMUNIZATION({id: id}));
    return res.data;
  });
};

export default {
  slice,
  getAll,
  getOne,
  updateOne,
  createOne,
  getPatientImms,
  getOneHistory,
  deleteOne,
};
