import {faRedo, faCalendarPlus} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  Checkbox,
  Col,
  DatePicker,
  Form,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Button,
  message,
} from 'antd';
import {Formik, FormikProps} from 'formik';
import moment from 'moment-timezone';
import React from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '../../models';
import {Calendar} from '../../models/calendar';
import {FhirUtils} from '../../services/fhir';
import {generateCalendarFormUXModel} from '../clinic/calendar/calendar-form-ux-model';
import {FormUX} from '../form-ux';
import {useUrlData} from '../scheduler/util';
import {AbilityContext} from '../../services/roles/ability-context';
import {colors} from '@canimmunize/tools';
interface Clinic {
  id: string;
  name: string;
  calendars: Calendar[];
  timezone: string;
}
export const CalendarPicker = (props) => {
  const {
    clinic,
    setClinic,
    calendar,
    setCalendar,
    timezone,
    setTimezone,
    fetchingAppointments,
    calendarDate,
    setCalendarDate,
    isRangePicker,
    hideTimezone,
    multipleCalendars,
    multipleClinics,
    onRefresh,
    includeAppointments,
    setIncludeAppointments,
    hideIncludeAppointments,
    hideClinicPicker,
    timeGrid,
    setTimeGrid,
    calendarRef,
    includeCalendarType,
    onAppointmentPage,
  } = props;

  const [clinics, setClinics] = React.useState<Clinic[]>([]);
  const [fetching, setFetching] = React.useState(false);
  const globalOrg = useSelector((state: RootState) => state.ui.localOrg);
  const [newCalendarModalVisible, setNewCalendarModalVisible] = React.useState(false);
  const ability = React.useContext(AbilityContext);

  const client = FhirUtils.useClient();

  const params = useUrlData().query;

  const fetchClinics = (override?) => {
    setFetching(true);

    client.get(`/clinic?_organizationId=${globalOrg?.id || ''}`).then((res) => {
      const clinics = res.data.entry.map((e) => e.resource);
      setClinics(clinics);
      if ((!clinic && Object.keys(params).length > 0) || override) {
        let clinic;
        let timezone;
        if (params.clinic) {
          clinic = clinics.find((clinic) => clinic.id === params.clinic);
        }
        if (params.timezone) {
          timezone = params.timezone;
        }
        if (params.calendar) {
          const calendar = clinic.calendars.find((cal) => cal.id === params.calendar);
          setCalendar(calendar);
        }
        if (params.calendarDate) {
          setCalendarDate(moment(params.calendarDate));
        }
        if (params.timeGrid) {
          setTimeGrid(params.timeGrid);
          calendarRef.current?.getApi().changeView(params.timeGrid);
        }
        if (timezone) {
          setTimezone(timezone);
        }

        setClinic(clinic);
      }
      setFetching(false);
    });
  };

  React.useEffect(() => {
    fetchClinics();
  }, [globalOrg?.id]);

  const clinicOptions = clinics.map((clinic) => (
    <Select.Option key={clinic.id} value={clinic.id}>
      {clinic.name}
    </Select.Option>
  ));

  //option to create a new calendar
  const newCalendarOption = (
    <Select.Option key={'new'} value={'new'}>
      <Space>
        <FontAwesomeIcon icon={faCalendarPlus} color={colors.blue} />
        <span style={{color: colors.blue}}>New Calendar</span>
      </Space>
    </Select.Option>
  );

  const calendarOptions = (
    multipleClinics
      ? clinic?.map((c) => [...(c?.calendars || [])]).flat() || []
      : multipleCalendars
      ? [...(clinic?.calendars || [])]
      : [{name: 'All Calendars', id: 'all'}, ...(clinic?.calendars || [])]
  )
    .filter((calendar) => (includeCalendarType ? includeCalendarType[calendar.type] : true))
    .map((calendar) => (
      <Select.Option key={calendar.id} value={calendar.id}>
        {calendar.name}
      </Select.Option>
    ));

  const handleNewCalendar = () => {
    setNewCalendarModalVisible(true);
  };

  const handleCancel = () => {
    setNewCalendarModalVisible(false);
  };

  return (
    <>
      <Form layout="vertical">
        <Row gutter={15} align="bottom">
          <Col>
            <Form.Item label="Date">
              {!isRangePicker ? (
                <DatePicker
                  // defaultValue={moment(calendarDate)}
                  value={moment(calendarDate)}
                  onChange={(date) => (date ? setCalendarDate(date) : null)}
                />
              ) : (
                <DatePicker.RangePicker
                  defaultValue={[moment(calendarDate[0]), moment(calendarDate[1])]}
                  onChange={(date) => (date ? setCalendarDate(date) : null)}
                />
              )}
            </Form.Item>
          </Col>
          {!hideTimezone && (
            <Col>
              <Form.Item label="Timezone">
                <Space>
                  <Select
                    showSearch
                    placeholder="View in Timezone"
                    style={{width: 200}}
                    value={timezone}
                    onChange={(value) => {
                      setTimezone(value);
                    }}
                  >
                    {clinic?.timezone && (
                      <Select.Option value={clinic?.timezone}>{clinic?.timezone}</Select.Option>
                    )}
                    {/* Only show the local timezone option if it is different from the clinic timezone */}
                    {moment.tz.guess() !== clinic?.timezone && (
                      <Select.Option
                        value={moment.tz.guess()}
                      >{`Local Timezone: ${moment.tz.guess()}`}</Select.Option>
                    )}
                  </Select>
                </Space>
              </Form.Item>
            </Col>
          )}
          {!hideClinicPicker && (
            <Col>
              <Form.Item label="Clinic">
                <Select
                  mode={multipleClinics ? 'multiple' : undefined}
                  showSearch
                  value={multipleClinics ? clinic?.map((val) => val.id) : clinic?.id}
                  placeholder="Select Clinic"
                  notFoundContent={fetching ? <Spin size="small" /> : null}
                  filterOption={(input, option) =>
                    option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(value) => {
                    const clinic = multipleClinics
                      ? value.map((val) => clinics.find((clinic) => clinic.id === val))
                      : clinics.find((clinic) => clinic.id === value);
                    setClinic(clinic);

                    if (multipleClinics) {
                      setTimezone(moment.tz.guess());
                      const currentCalendars = clinic?.map((c) => [...(c?.calendars || [])]).flat();
                      const leftCalendar = calendar?.filter((cal) =>
                        currentCalendars.find((val) => cal.id == val.id)
                      );
                      setCalendar(leftCalendar);
                    } else {
                      clinic?.timezone
                        ? setTimezone(clinic?.timezone)
                        : setTimezone(moment.tz.guess());
                      setCalendar(null); // Calendar is resetted to 'All Calendars' because Calendar is dependant on Clinic
                    }
                  }}
                  style={{width: 300}}
                >
                  {clinicOptions}
                </Select>
              </Form.Item>
            </Col>
          )}
          <Col>
            <Form.Item label="Calendar">
              <Space>
                <Select
                  mode={multipleCalendars ? 'multiple' : undefined}
                  showSearch
                  placeholder="Select Calendar"
                  style={{width: 400}}
                  value={multipleCalendars ? calendar?.map((val) => val.id) : calendar?.id || 'all'}
                  filterOption={(input, option) =>
                    option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(value) => {
                    if (value === 'new') {
                      handleNewCalendar();
                    } else if (multipleClinics) {
                      const currentCalendars = clinic?.map((c) => [...(c?.calendars || [])]).flat();
                      const calendar = value.map((val) =>
                        currentCalendars.find((cal) => cal.id === val)
                      );
                      setCalendar(calendar);
                    } else {
                      const calendar = multipleCalendars
                        ? value.map((val) => clinic.calendars.find((cal) => cal.id === val))
                        : clinic.calendars.find((cal) => cal.id === value);
                      setCalendar(calendar);
                    }
                  }}
                >
                  {clinic &&
                    onAppointmentPage &&
                    ability.can('create', 'calendar') &&
                    newCalendarOption}
                  {calendarOptions}
                </Select>
                <div style={{width: 60}}>
                  {fetchingAppointments ? (
                    <Spin spinning={fetchingAppointments} size="small" />
                  ) : (
                    <FontAwesomeIcon icon={faRedo} onClick={onRefresh} />
                  )}
                </div>
              </Space>
            </Form.Item>
          </Col>
          {!hideIncludeAppointments && (
            <Col>
              <Form.Item label="Include Appointments">
                <Space>
                  <Checkbox
                    checked={includeAppointments}
                    onChange={(e) => {
                      setIncludeAppointments(e.target.checked);
                    }}
                  />
                </Space>
              </Form.Item>
            </Col>
          )}
        </Row>
      </Form>
      {newCalendarModalVisible && (
        <NewCalendarModal
          visible={newCalendarModalVisible}
          onCancel={handleCancel}
          clinic={clinic}
          onComplete={() => fetchClinics(true)}
        />
      )}
    </>
  );
};

const NewCalendarModal = (props) => {
  const {visible, onCancel, clinic, onComplete} = props;
  const client = FhirUtils.useClient();

  const initialValues = {
    clinicId: clinic?.id,
    type: 'PUBLIC',
    autoBookSecondAppointment: false,
  };

  const handleSubmit = (values, actions) => {
    return client
      .post('/calendar', values)
      .then(async (res) => {
        if (onComplete) await onComplete();
        onCancel();
      })
      .catch((error) => message.error('Failed to create new calendar'));
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validateOnChange={true}>
      {(formikProps: FormikProps<any>) => {
        const ModalFooterCalendar = () => {
          return (
            <Space>
              <Button
                onMouseDown={(e) => {
                  e.preventDefault();
                }}
                onClick={() => {
                  onCancel();
                  formikProps.resetForm();
                }}
              >
                Cancel
              </Button>

              <Button
                loading={formikProps.isSubmitting}
                type="primary"
                disabled={!formikProps.dirty || !formikProps.isValid}
                onMouseDown={(e) => {
                  e.preventDefault();
                }}
                onClick={() => {
                  formikProps.handleSubmit();
                }}
              >
                Save
              </Button>
            </Space>
          );
        };

        return (
          <Modal
            title="New Calendar"
            visible={visible}
            onCancel={onCancel}
            maskClosable={false}
            closable={false}
            destroyOnClose={true}
            footer={<ModalFooterCalendar />}
          >
            <FormUX
              formUXModel={generateCalendarFormUXModel('appointment-view')}
              createMode={true}
              modal={true}
            />
          </Modal>
        );
      }}
    </Formik>
  );
};
