import {
  RequiredShortTextValidationRules,
  RequiredSpecialCharsValidationRules,
} from '../../services/ui-validation-rules';
import {FormUXFieldType} from '../form-ux';
import {FormUXModel} from '../form-ux/form-ux-models/form-ux-model';

export enum PortalFormCustomFieldName {
  OrganizationId = 'organizationId',
}

export const PortalFormUXModel: FormUXModel = [
  {
    name: 'name',
    type: FormUXFieldType.text,
    label: 'Name',
    inCreateModal: true,
    editable: true,
    validationRules: [...RequiredShortTextValidationRules, ...RequiredSpecialCharsValidationRules],
  },
  {
    name: PortalFormCustomFieldName.OrganizationId,
    type: FormUXFieldType.custom,
    label: 'Organization',
    editable: true,
    validationRules: [
      {
        validationRuleType: 'required',
      },
    ],
    inCreateModal: true,
  },
];
