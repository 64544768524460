import React, {FunctionComponent, useEffect, useState} from 'react';
import IdleTimer from 'react-idle-timer';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../models';
import UI from '../../models/ui';
import {useEnvInfo} from '../../services/environment';

/**
 * The InactivityTimer React Functional Component
 * Used by the InactivityLogout component to determine when a user has become idle
 * and updates the redux UI state accordingly
 * @returns IdleTimer
 */
export const InactivityTimer: FunctionComponent<{maxIdleTime: number}> = ({maxIdleTime}) => {
  const [lastActionTime, setLastActionTime] = useState(0);

  const dispatch = useDispatch();
  const idleTime = useSelector((state: RootState) => state.ui.idleTime);
  let idleTimer: IdleTimer;

  useEffect(() => {
    dispatch(UI.slice.actions.CLEAR_IDLE_TIME());
  }, []);

  useEffect(() => {
    if (!idleTime) idleTimer.reset();
  }, [idleTime]);

  useEffect(() => {
    dispatch(UI.slice.actions.SET_LAST_USER_ACTION_TIME(Date.now()));
  }, [lastActionTime]);

  const onIdleHandler = () => {
    idleTimer.pause();
    dispatch(UI.slice.actions.SET_IDLE_TIME(Date.now()));
  };

  const onActionHandler = (event: Event) => {
    // Only update the lastActionTime state variable if its been more than a minute since
    // the last update.  This is in order to reduce the number of redux dispatch calls to
    // SET_LAST_USER_ACTION_TIME
    setLastActionTime((prev) => (event.timeStamp - prev > 60000 ? event.timeStamp : prev));
  };

  return (
    <IdleTimer
      ref={(ref: IdleTimer) => {
        idleTimer = ref;
      }}
      timeout={maxIdleTime}
      onIdle={onIdleHandler}
      onAction={onActionHandler}
      debounce={500}
    />
  );
};