"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_ElementDefinition = exports.ElementDefinitionRepresentationKind = void 0;
var t = __importStar(require("io-ts"));
var RTTI_Extension_1 = require("./RTTI_Extension");
var RTTI_Element_1 = require("./RTTI_Element");
var RTTI_Coding_1 = require("./RTTI_Coding");
var RTTI_ElementDefinition_Slicing_1 = require("./RTTI_ElementDefinition_Slicing");
var RTTI_markdown_1 = require("../Scalar/RTTI_markdown");
var RTTI_unsignedInt_1 = require("../Scalar/RTTI_unsignedInt");
var RTTI_ElementDefinition_Base_1 = require("./RTTI_ElementDefinition_Base");
var RTTI_uri_1 = require("../Scalar/RTTI_uri");
var RTTI_ElementDefinition_Type_1 = require("./RTTI_ElementDefinition_Type");
var RTTI_Address_1 = require("./RTTI_Address");
var RTTI_Age_1 = require("./RTTI_Age");
var RTTI_Annotation_1 = require("./RTTI_Annotation");
var RTTI_Attachment_1 = require("./RTTI_Attachment");
var RTTI_CodeableConcept_1 = require("./RTTI_CodeableConcept");
var RTTI_ContactPoint_1 = require("./RTTI_ContactPoint");
var RTTI_Count_1 = require("./RTTI_Count");
var RTTI_Distance_1 = require("./RTTI_Distance");
var RTTI_Duration_1 = require("./RTTI_Duration");
var RTTI_HumanName_1 = require("./RTTI_HumanName");
var RTTI_Identifier_1 = require("./RTTI_Identifier");
var RTTI_Money_1 = require("./RTTI_Money");
var RTTI_Period_1 = require("./RTTI_Period");
var RTTI_Quantity_1 = require("./RTTI_Quantity");
var RTTI_Range_1 = require("./RTTI_Range");
var RTTI_Ratio_1 = require("./RTTI_Ratio");
var RTTI_Reference_1 = require("./RTTI_Reference");
var RTTI_SampledData_1 = require("./RTTI_SampledData");
var RTTI_Signature_1 = require("./RTTI_Signature");
var RTTI_Timing_1 = require("./RTTI_Timing");
var RTTI_ContactDetail_1 = require("./RTTI_ContactDetail");
var RTTI_Contributor_1 = require("./RTTI_Contributor");
var RTTI_DataRequirement_1 = require("./RTTI_DataRequirement");
var RTTI_Expression_1 = require("./RTTI_Expression");
var RTTI_ParameterDefinition_1 = require("./RTTI_ParameterDefinition");
var RTTI_RelatedArtifact_1 = require("./RTTI_RelatedArtifact");
var RTTI_TriggerDefinition_1 = require("./RTTI_TriggerDefinition");
var RTTI_UsageContext_1 = require("./RTTI_UsageContext");
var RTTI_Dosage_1 = require("./RTTI_Dosage");
var RTTI_ElementDefinition_Example_1 = require("./RTTI_ElementDefinition_Example");
var RTTI_integer_1 = require("../Scalar/RTTI_integer");
var RTTI_id_1 = require("../Scalar/RTTI_id");
var RTTI_ElementDefinition_Constraint_1 = require("./RTTI_ElementDefinition_Constraint");
var RTTI_ElementDefinition_Binding_1 = require("./RTTI_ElementDefinition_Binding");
var RTTI_ElementDefinition_Mapping_1 = require("./RTTI_ElementDefinition_Mapping");
var ElementDefinitionRepresentationKind;
(function (ElementDefinitionRepresentationKind) {
    ElementDefinitionRepresentationKind["_xmlAttr"] = "xmlAttr";
    ElementDefinitionRepresentationKind["_xmlText"] = "xmlText";
    ElementDefinitionRepresentationKind["_typeAttr"] = "typeAttr";
    ElementDefinitionRepresentationKind["_cdaText"] = "cdaText";
    ElementDefinitionRepresentationKind["_xhtml"] = "xhtml";
})(ElementDefinitionRepresentationKind = exports.ElementDefinitionRepresentationKind || (exports.ElementDefinitionRepresentationKind = {}));
var EnumType_1 = require("../../EnumType");
exports.RTTI_ElementDefinition = t.recursion('IElementDefinition', function () {
    return t.partial({
        id: t.string,
        extension: t.array(RTTI_Extension_1.RTTI_Extension),
        modifierExtension: t.array(RTTI_Extension_1.RTTI_Extension),
        path: t.string,
        _path: RTTI_Element_1.RTTI_Element,
        representation: t.array(EnumType_1.createEnumType(ElementDefinitionRepresentationKind, 'ElementDefinitionRepresentationKind')),
        _representation: t.array(RTTI_Element_1.RTTI_Element),
        sliceName: t.string,
        _sliceName: RTTI_Element_1.RTTI_Element,
        sliceIsConstraining: t.boolean,
        _sliceIsConstraining: RTTI_Element_1.RTTI_Element,
        label: t.string,
        _label: RTTI_Element_1.RTTI_Element,
        code: t.array(RTTI_Coding_1.RTTI_Coding),
        slicing: RTTI_ElementDefinition_Slicing_1.RTTI_ElementDefinition_Slicing,
        short: t.string,
        _short: RTTI_Element_1.RTTI_Element,
        definition: RTTI_markdown_1.RTTI_markdown,
        _definition: RTTI_Element_1.RTTI_Element,
        comment: RTTI_markdown_1.RTTI_markdown,
        _comment: RTTI_Element_1.RTTI_Element,
        requirements: RTTI_markdown_1.RTTI_markdown,
        _requirements: RTTI_Element_1.RTTI_Element,
        alias: t.array(t.string),
        _alias: t.array(RTTI_Element_1.RTTI_Element),
        min: RTTI_unsignedInt_1.RTTI_unsignedInt,
        _min: RTTI_Element_1.RTTI_Element,
        max: t.string,
        _max: RTTI_Element_1.RTTI_Element,
        base: RTTI_ElementDefinition_Base_1.RTTI_ElementDefinition_Base,
        contentReference: RTTI_uri_1.RTTI_uri,
        _contentReference: RTTI_Element_1.RTTI_Element,
        type: t.array(RTTI_ElementDefinition_Type_1.RTTI_ElementDefinition_Type),
        defaultValueBase64Binary: t.string,
        _defaultValueBase64Binary: RTTI_Element_1.RTTI_Element,
        defaultValueBoolean: t.boolean,
        _defaultValueBoolean: RTTI_Element_1.RTTI_Element,
        defaultValueCanonical: t.string,
        _defaultValueCanonical: RTTI_Element_1.RTTI_Element,
        defaultValueCode: t.string,
        _defaultValueCode: RTTI_Element_1.RTTI_Element,
        defaultValueDate: t.string,
        _defaultValueDate: RTTI_Element_1.RTTI_Element,
        defaultValueDateTime: t.string,
        _defaultValueDateTime: RTTI_Element_1.RTTI_Element,
        defaultValueDecimal: t.number,
        _defaultValueDecimal: RTTI_Element_1.RTTI_Element,
        defaultValueId: t.string,
        _defaultValueId: RTTI_Element_1.RTTI_Element,
        defaultValueInstant: t.string,
        _defaultValueInstant: RTTI_Element_1.RTTI_Element,
        defaultValueInteger: t.number,
        _defaultValueInteger: RTTI_Element_1.RTTI_Element,
        defaultValueMarkdown: t.string,
        _defaultValueMarkdown: RTTI_Element_1.RTTI_Element,
        defaultValueOid: t.string,
        _defaultValueOid: RTTI_Element_1.RTTI_Element,
        defaultValuePositiveInt: t.number,
        _defaultValuePositiveInt: RTTI_Element_1.RTTI_Element,
        defaultValueString: t.string,
        _defaultValueString: RTTI_Element_1.RTTI_Element,
        defaultValueTime: t.string,
        _defaultValueTime: RTTI_Element_1.RTTI_Element,
        defaultValueUnsignedInt: t.number,
        _defaultValueUnsignedInt: RTTI_Element_1.RTTI_Element,
        defaultValueUri: t.string,
        _defaultValueUri: RTTI_Element_1.RTTI_Element,
        defaultValueUrl: t.string,
        _defaultValueUrl: RTTI_Element_1.RTTI_Element,
        defaultValueUuid: t.string,
        _defaultValueUuid: RTTI_Element_1.RTTI_Element,
        defaultValueAddress: RTTI_Address_1.RTTI_Address,
        defaultValueAge: RTTI_Age_1.RTTI_Age,
        defaultValueAnnotation: RTTI_Annotation_1.RTTI_Annotation,
        defaultValueAttachment: RTTI_Attachment_1.RTTI_Attachment,
        defaultValueCodeableConcept: RTTI_CodeableConcept_1.RTTI_CodeableConcept,
        defaultValueCoding: RTTI_Coding_1.RTTI_Coding,
        defaultValueContactPoint: RTTI_ContactPoint_1.RTTI_ContactPoint,
        defaultValueCount: RTTI_Count_1.RTTI_Count,
        defaultValueDistance: RTTI_Distance_1.RTTI_Distance,
        defaultValueDuration: RTTI_Duration_1.RTTI_Duration,
        defaultValueHumanName: RTTI_HumanName_1.RTTI_HumanName,
        defaultValueIdentifier: RTTI_Identifier_1.RTTI_Identifier,
        defaultValueMoney: RTTI_Money_1.RTTI_Money,
        defaultValuePeriod: RTTI_Period_1.RTTI_Period,
        defaultValueQuantity: RTTI_Quantity_1.RTTI_Quantity,
        defaultValueRange: RTTI_Range_1.RTTI_Range,
        defaultValueRatio: RTTI_Ratio_1.RTTI_Ratio,
        defaultValueReference: RTTI_Reference_1.RTTI_Reference,
        defaultValueSampledData: RTTI_SampledData_1.RTTI_SampledData,
        defaultValueSignature: RTTI_Signature_1.RTTI_Signature,
        defaultValueTiming: RTTI_Timing_1.RTTI_Timing,
        defaultValueContactDetail: RTTI_ContactDetail_1.RTTI_ContactDetail,
        defaultValueContributor: RTTI_Contributor_1.RTTI_Contributor,
        defaultValueDataRequirement: RTTI_DataRequirement_1.RTTI_DataRequirement,
        defaultValueExpression: RTTI_Expression_1.RTTI_Expression,
        defaultValueParameterDefinition: RTTI_ParameterDefinition_1.RTTI_ParameterDefinition,
        defaultValueRelatedArtifact: RTTI_RelatedArtifact_1.RTTI_RelatedArtifact,
        defaultValueTriggerDefinition: RTTI_TriggerDefinition_1.RTTI_TriggerDefinition,
        defaultValueUsageContext: RTTI_UsageContext_1.RTTI_UsageContext,
        defaultValueDosage: RTTI_Dosage_1.RTTI_Dosage,
        meaningWhenMissing: RTTI_markdown_1.RTTI_markdown,
        _meaningWhenMissing: RTTI_Element_1.RTTI_Element,
        orderMeaning: t.string,
        _orderMeaning: RTTI_Element_1.RTTI_Element,
        fixedBase64Binary: t.string,
        _fixedBase64Binary: RTTI_Element_1.RTTI_Element,
        fixedBoolean: t.boolean,
        _fixedBoolean: RTTI_Element_1.RTTI_Element,
        fixedCanonical: t.string,
        _fixedCanonical: RTTI_Element_1.RTTI_Element,
        fixedCode: t.string,
        _fixedCode: RTTI_Element_1.RTTI_Element,
        fixedDate: t.string,
        _fixedDate: RTTI_Element_1.RTTI_Element,
        fixedDateTime: t.string,
        _fixedDateTime: RTTI_Element_1.RTTI_Element,
        fixedDecimal: t.number,
        _fixedDecimal: RTTI_Element_1.RTTI_Element,
        fixedId: t.string,
        _fixedId: RTTI_Element_1.RTTI_Element,
        fixedInstant: t.string,
        _fixedInstant: RTTI_Element_1.RTTI_Element,
        fixedInteger: t.number,
        _fixedInteger: RTTI_Element_1.RTTI_Element,
        fixedMarkdown: t.string,
        _fixedMarkdown: RTTI_Element_1.RTTI_Element,
        fixedOid: t.string,
        _fixedOid: RTTI_Element_1.RTTI_Element,
        fixedPositiveInt: t.number,
        _fixedPositiveInt: RTTI_Element_1.RTTI_Element,
        fixedString: t.string,
        _fixedString: RTTI_Element_1.RTTI_Element,
        fixedTime: t.string,
        _fixedTime: RTTI_Element_1.RTTI_Element,
        fixedUnsignedInt: t.number,
        _fixedUnsignedInt: RTTI_Element_1.RTTI_Element,
        fixedUri: t.string,
        _fixedUri: RTTI_Element_1.RTTI_Element,
        fixedUrl: t.string,
        _fixedUrl: RTTI_Element_1.RTTI_Element,
        fixedUuid: t.string,
        _fixedUuid: RTTI_Element_1.RTTI_Element,
        fixedAddress: RTTI_Address_1.RTTI_Address,
        fixedAge: RTTI_Age_1.RTTI_Age,
        fixedAnnotation: RTTI_Annotation_1.RTTI_Annotation,
        fixedAttachment: RTTI_Attachment_1.RTTI_Attachment,
        fixedCodeableConcept: RTTI_CodeableConcept_1.RTTI_CodeableConcept,
        fixedCoding: RTTI_Coding_1.RTTI_Coding,
        fixedContactPoint: RTTI_ContactPoint_1.RTTI_ContactPoint,
        fixedCount: RTTI_Count_1.RTTI_Count,
        fixedDistance: RTTI_Distance_1.RTTI_Distance,
        fixedDuration: RTTI_Duration_1.RTTI_Duration,
        fixedHumanName: RTTI_HumanName_1.RTTI_HumanName,
        fixedIdentifier: RTTI_Identifier_1.RTTI_Identifier,
        fixedMoney: RTTI_Money_1.RTTI_Money,
        fixedPeriod: RTTI_Period_1.RTTI_Period,
        fixedQuantity: RTTI_Quantity_1.RTTI_Quantity,
        fixedRange: RTTI_Range_1.RTTI_Range,
        fixedRatio: RTTI_Ratio_1.RTTI_Ratio,
        fixedReference: RTTI_Reference_1.RTTI_Reference,
        fixedSampledData: RTTI_SampledData_1.RTTI_SampledData,
        fixedSignature: RTTI_Signature_1.RTTI_Signature,
        fixedTiming: RTTI_Timing_1.RTTI_Timing,
        fixedContactDetail: RTTI_ContactDetail_1.RTTI_ContactDetail,
        fixedContributor: RTTI_Contributor_1.RTTI_Contributor,
        fixedDataRequirement: RTTI_DataRequirement_1.RTTI_DataRequirement,
        fixedExpression: RTTI_Expression_1.RTTI_Expression,
        fixedParameterDefinition: RTTI_ParameterDefinition_1.RTTI_ParameterDefinition,
        fixedRelatedArtifact: RTTI_RelatedArtifact_1.RTTI_RelatedArtifact,
        fixedTriggerDefinition: RTTI_TriggerDefinition_1.RTTI_TriggerDefinition,
        fixedUsageContext: RTTI_UsageContext_1.RTTI_UsageContext,
        fixedDosage: RTTI_Dosage_1.RTTI_Dosage,
        patternBase64Binary: t.string,
        _patternBase64Binary: RTTI_Element_1.RTTI_Element,
        patternBoolean: t.boolean,
        _patternBoolean: RTTI_Element_1.RTTI_Element,
        patternCanonical: t.string,
        _patternCanonical: RTTI_Element_1.RTTI_Element,
        patternCode: t.string,
        _patternCode: RTTI_Element_1.RTTI_Element,
        patternDate: t.string,
        _patternDate: RTTI_Element_1.RTTI_Element,
        patternDateTime: t.string,
        _patternDateTime: RTTI_Element_1.RTTI_Element,
        patternDecimal: t.number,
        _patternDecimal: RTTI_Element_1.RTTI_Element,
        patternId: t.string,
        _patternId: RTTI_Element_1.RTTI_Element,
        patternInstant: t.string,
        _patternInstant: RTTI_Element_1.RTTI_Element,
        patternInteger: t.number,
        _patternInteger: RTTI_Element_1.RTTI_Element,
        patternMarkdown: t.string,
        _patternMarkdown: RTTI_Element_1.RTTI_Element,
        patternOid: t.string,
        _patternOid: RTTI_Element_1.RTTI_Element,
        patternPositiveInt: t.number,
        _patternPositiveInt: RTTI_Element_1.RTTI_Element,
        patternString: t.string,
        _patternString: RTTI_Element_1.RTTI_Element,
        patternTime: t.string,
        _patternTime: RTTI_Element_1.RTTI_Element,
        patternUnsignedInt: t.number,
        _patternUnsignedInt: RTTI_Element_1.RTTI_Element,
        patternUri: t.string,
        _patternUri: RTTI_Element_1.RTTI_Element,
        patternUrl: t.string,
        _patternUrl: RTTI_Element_1.RTTI_Element,
        patternUuid: t.string,
        _patternUuid: RTTI_Element_1.RTTI_Element,
        patternAddress: RTTI_Address_1.RTTI_Address,
        patternAge: RTTI_Age_1.RTTI_Age,
        patternAnnotation: RTTI_Annotation_1.RTTI_Annotation,
        patternAttachment: RTTI_Attachment_1.RTTI_Attachment,
        patternCodeableConcept: RTTI_CodeableConcept_1.RTTI_CodeableConcept,
        patternCoding: RTTI_Coding_1.RTTI_Coding,
        patternContactPoint: RTTI_ContactPoint_1.RTTI_ContactPoint,
        patternCount: RTTI_Count_1.RTTI_Count,
        patternDistance: RTTI_Distance_1.RTTI_Distance,
        patternDuration: RTTI_Duration_1.RTTI_Duration,
        patternHumanName: RTTI_HumanName_1.RTTI_HumanName,
        patternIdentifier: RTTI_Identifier_1.RTTI_Identifier,
        patternMoney: RTTI_Money_1.RTTI_Money,
        patternPeriod: RTTI_Period_1.RTTI_Period,
        patternQuantity: RTTI_Quantity_1.RTTI_Quantity,
        patternRange: RTTI_Range_1.RTTI_Range,
        patternRatio: RTTI_Ratio_1.RTTI_Ratio,
        patternReference: RTTI_Reference_1.RTTI_Reference,
        patternSampledData: RTTI_SampledData_1.RTTI_SampledData,
        patternSignature: RTTI_Signature_1.RTTI_Signature,
        patternTiming: RTTI_Timing_1.RTTI_Timing,
        patternContactDetail: RTTI_ContactDetail_1.RTTI_ContactDetail,
        patternContributor: RTTI_Contributor_1.RTTI_Contributor,
        patternDataRequirement: RTTI_DataRequirement_1.RTTI_DataRequirement,
        patternExpression: RTTI_Expression_1.RTTI_Expression,
        patternParameterDefinition: RTTI_ParameterDefinition_1.RTTI_ParameterDefinition,
        patternRelatedArtifact: RTTI_RelatedArtifact_1.RTTI_RelatedArtifact,
        patternTriggerDefinition: RTTI_TriggerDefinition_1.RTTI_TriggerDefinition,
        patternUsageContext: RTTI_UsageContext_1.RTTI_UsageContext,
        patternDosage: RTTI_Dosage_1.RTTI_Dosage,
        example: t.array(RTTI_ElementDefinition_Example_1.RTTI_ElementDefinition_Example),
        minValueDate: t.string,
        _minValueDate: RTTI_Element_1.RTTI_Element,
        minValueDateTime: t.string,
        _minValueDateTime: RTTI_Element_1.RTTI_Element,
        minValueInstant: t.string,
        _minValueInstant: RTTI_Element_1.RTTI_Element,
        minValueTime: t.string,
        _minValueTime: RTTI_Element_1.RTTI_Element,
        minValueDecimal: t.number,
        _minValueDecimal: RTTI_Element_1.RTTI_Element,
        minValueInteger: t.number,
        _minValueInteger: RTTI_Element_1.RTTI_Element,
        minValuePositiveInt: t.number,
        _minValuePositiveInt: RTTI_Element_1.RTTI_Element,
        minValueUnsignedInt: t.number,
        _minValueUnsignedInt: RTTI_Element_1.RTTI_Element,
        minValueQuantity: RTTI_Quantity_1.RTTI_Quantity,
        maxValueDate: t.string,
        _maxValueDate: RTTI_Element_1.RTTI_Element,
        maxValueDateTime: t.string,
        _maxValueDateTime: RTTI_Element_1.RTTI_Element,
        maxValueInstant: t.string,
        _maxValueInstant: RTTI_Element_1.RTTI_Element,
        maxValueTime: t.string,
        _maxValueTime: RTTI_Element_1.RTTI_Element,
        maxValueDecimal: t.number,
        _maxValueDecimal: RTTI_Element_1.RTTI_Element,
        maxValueInteger: t.number,
        _maxValueInteger: RTTI_Element_1.RTTI_Element,
        maxValuePositiveInt: t.number,
        _maxValuePositiveInt: RTTI_Element_1.RTTI_Element,
        maxValueUnsignedInt: t.number,
        _maxValueUnsignedInt: RTTI_Element_1.RTTI_Element,
        maxValueQuantity: RTTI_Quantity_1.RTTI_Quantity,
        maxLength: RTTI_integer_1.RTTI_integer,
        _maxLength: RTTI_Element_1.RTTI_Element,
        condition: t.array(RTTI_id_1.RTTI_id),
        _condition: t.array(RTTI_Element_1.RTTI_Element),
        constraint: t.array(RTTI_ElementDefinition_Constraint_1.RTTI_ElementDefinition_Constraint),
        mustSupport: t.boolean,
        _mustSupport: RTTI_Element_1.RTTI_Element,
        isModifier: t.boolean,
        _isModifier: RTTI_Element_1.RTTI_Element,
        isModifierReason: t.string,
        _isModifierReason: RTTI_Element_1.RTTI_Element,
        isSummary: t.boolean,
        _isSummary: RTTI_Element_1.RTTI_Element,
        binding: RTTI_ElementDefinition_Binding_1.RTTI_ElementDefinition_Binding,
        mapping: t.array(RTTI_ElementDefinition_Mapping_1.RTTI_ElementDefinition_Mapping)
    });
});
