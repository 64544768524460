import {
  EmailValidationRules,
  PhoneValidationRules,
  RequiredShortTextValidationRules,
} from '../../services/ui-validation-rules';
import {FormUXFieldType} from '../form-ux';
import {FormUXModel} from '../form-ux/form-ux-models/form-ux-model';

export const AppointmentUXModel: FormUXModel = [
  {
    name: 'preferredName',
    type: FormUXFieldType.text,
    label: 'Preferred Name',
    editable: true,
    validationRules: [],
    inCreateModal: false,
  },
  {
    name: 'email',
    type: FormUXFieldType.text,
    label: 'Email',
    editable: true,
    validationRules: EmailValidationRules,
    inCreateModal: false,
  },
  {
    name: 'phone',
    type: FormUXFieldType.text,
    label: 'Phone',
    editable: true,
    validationRules: PhoneValidationRules,
    inCreateModal: false,
  },
];
