/**
 *
 * @export
 * @enum {string}
 */
export enum FormUXFieldType {
  text = 'text',
  textarea = 'textarea',
  json = 'json',
  custom = 'custom',
  grouped = 'grouped',
  MultipleItemPicker = 'multiple-item-picker',
  select = 'select',
  SingleItemPicker = 'single-item-picker',
  number = 'number',
  flex = 'flex',
  boolean = 'boolean',
  date = 'date',
  radio = 'radio',
  MultipleSelect = 'multi-select',
  hidden = 'hidden',
  tab = 'tab',
}
