import {Layout, Menu} from 'antd';
import ErrorBoundary from 'antd/lib/alert/ErrorBoundary';
import React from 'react';
import {Helmet} from 'react-helmet';
import {useSelector} from 'react-redux';
import {Link, useLocation} from 'react-router-dom';
import bubbleOverlay from '../../assets/bubble-overlay2.png';
import logo from '../../assets/logo-nobird.png';
import {RootState} from '../../models';
import {useEnvInfo} from '../../services/environment';
import {AbilityContext} from '../../services/roles/ability-context';
import {getExtensionValue} from '../../util/fhir';
import {WelcomeModal} from '../demo/welcome-modal';
import {NavBar} from '../navbar';
import {getMenuItems} from './menu-items';
import styles from './styles.module.css';

export const AppLayout: React.SFC = (props) => {
  const location = useLocation();
  const selectedKey = location.pathname.split('/')[1];
  const selected = selectedKey ? [selectedKey] : ['/home'];
  const selectedOrg = useSelector((state: RootState) => state.ui.localOrg);
  const ability = React.useContext(AbilityContext);
  const {environmentId} = useEnvInfo();

  const orgColor = selectedOrg
    ? getExtensionValue(
        selectedOrg,
        'https://canimmunize.ca/StructureDefinition/org-primary-color'
      ) || '#0581FF'
    : '#0581FF';

  const scrollTop = useSelector((state: RootState) => state.ui.scrollTop);

  const onScroll = (e) => {
    const newScrollPosition = e.target.scrollTop;
    if (scrollTop > 0 && newScrollPosition > 0) return;
    if (scrollTop <= 0 && newScrollPosition <= 0) return;
  };

  // hide the zendesk chat widget on patient lookup tab
  if (location.pathname.includes('/patientlookup')) {
    document.body.className = 'hide-support-widget';
  } else document.body.className = '';

  return (
    <Layout
      style={{
        minHeight: '100vh',
        overflow: 'hidden',
        backgroundColor: orgColor,
        backgroundImage: `url(${bubbleOverlay})`,
        backgroundAttachment: 'fixed',
        backgroundSize: 'cover',
      }}
    >
      <ScrollToTop />
      <Layout.Sider collapsible>
        <div className={styles.appSider}>
          <Link to={ability.can('access', 'menu', 'home') ? '/home' : '#'}>
            <div className={styles.appLogo}>
              <img width="80%" src={logo} alt="canimm-logo" />
            </div>
          </Link>
          <Menu mode="inline" selectedKeys={selected} theme="dark" className={styles.appMenu}>
            {getMenuItems(ability, selectedKey, environmentId)}
          </Menu>
        </div>
      </Layout.Sider>
      <Layout onScroll={onScroll} style={{backgroundColor: 'transparent'}}>
        <NavBar />
        <ErrorBoundary>
          <Layout.Content className={styles.appContent}>{props.children}</Layout.Content>
        </ErrorBoundary>
        <WelcomeModal />
      </Layout>
      <Helmet>
        <script
          id="ze-snippet"
          src="https://static.zdassets.com/ekr/snippet.js?key=b917886d-bd84-477e-b3c8-b766962eb54e"
        ></script>
      </Helmet>
    </Layout>
  );
};

export const ScrollToTop = () => {
  const {pathname} = useLocation();
  const selectedKey = pathname.split('/')[1] || 'home';

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [selectedKey]);

  return null;
};
