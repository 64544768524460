import {
  faCalendarAlt,
  faCog,
  faCopy,
  faPencilAlt,
  faWalking,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Dropdown, Menu, message, Modal, Space} from 'antd';
import {Input, Switch} from 'formik-antd';
import {Formik} from 'formik';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {FhirUtils} from '../../../services/fhir';
import {FormUXFieldType} from '../../form-ux';
import BookingPageModel from '../../../models/booking-pages';
import {SingleItemPicker} from '../../form-ux-fields/single-item-picker-field';
import {StopPropagation} from '../../util/stop-propagation';
import {Form} from 'formik-antd';

export const AppointmentTypeListActions = (props) => {
  const {appointmentType, setSelectedItem, refreshTable, bookingPageId} = props;
  const [confirmCopyModalVisible, setConfirmCopyModalVisible] = React.useState(0);
  const client = FhirUtils.useAxiosClient();

  const onSubmitCopy = async (values, formikHelpers) => {
    const result = await client.put('/appointment-type/copy', values);

    if (result.status === 200) {
      message.success('Successfully copied appointment type!');
      refreshTable();
    } else {
      message.error('Error copying appointment type');
    }
    setConfirmCopyModalVisible(0);
  };

  const copyDropdownMenu = (
    <Menu>
      <Menu.Item key="0" onClick={() => setConfirmCopyModalVisible(1)}>
        Copy onto current booking page
      </Menu.Item>
      <Menu.Item key="1" onClick={() => setConfirmCopyModalVisible(2)}>
        Copy onto different booking page
      </Menu.Item>
    </Menu>
  );

  return (
    <Space>
      <Button type="primary" size={'small'} style={{paddingLeft: 10, paddingRight: 10}}>
        <FontAwesomeIcon icon={faCalendarAlt} color="white" style={{marginRight: 10}} />
        Calendars
      </Button>
      <Button
        type="primary"
        size={'small'}
        style={{paddingLeft: 10, paddingRight: 10}}
        onClick={(e) => {
          e.stopPropagation();
          setSelectedItem(appointmentType);
        }}
      >
        <FontAwesomeIcon icon={faCog} color="white" style={{marginRight: 10}} />
        Settings
      </Button>
      <StopPropagation>
        <Dropdown overlay={copyDropdownMenu} trigger={['click']}>
          <Button
            type="primary"
            size={'small'}
            style={{paddingLeft: 10, paddingRight: 10}}
            // onClick={() => copyBookingPage(bookingPage)}
          >
            <FontAwesomeIcon icon={faCopy} color="white" style={{marginRight: 10}} />
            Copy
          </Button>
        </Dropdown>
        <Formik
          initialValues={{
            includeCalendars: true,
            targetBookingPageId: confirmCopyModalVisible === 1 ? bookingPageId : undefined,
            appointmentTypeId: appointmentType.id,
            nameEn: appointmentType.nameEn,
            nameFr: appointmentType.nameFr,
          }}
          enableReinitialize
          onSubmit={onSubmitCopy}
        >
          {(formikProps) => {
            return (
              <Modal
                title="Confirm Copy Appointment Type"
                visible={confirmCopyModalVisible > 0}
                onCancel={() => setConfirmCopyModalVisible(0)}
                onOk={() => formikProps.handleSubmit()}
                okText="Copy"
              >
                {confirmCopyModalVisible === 1 ? (
                  <div style={{marginBottom: 5}}>
                    <i>"{appointmentType.nameEn}"</i> will be copied onto the current booking page.
                  </div>
                ) : (
                  <div style={{marginBottom: 5}}>
                    <i>"{appointmentType.nameEn}"</i> will be copied onto:
                  </div>
                )}

                <Form.Item name="targetBookingPageId" hidden={confirmCopyModalVisible === 1}>
                  <SingleItemPicker
                    dropdownMatchSelectWidth={false}
                    field={{
                      name: 'targetBookingPageId',
                      type: FormUXFieldType.SingleItemPicker,
                      label: 'Booking Page',
                      editable: true,
                      fromIdValue: true,
                      inCreateModal: true,
                      model: BookingPageModel as any,
                      placeholder: 'Select Booking Page',
                      validationRules: [],
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="includeCalendars"
                  label="Include Calendars"
                  style={{marginBottom: 0}}
                >
                  <Switch name="includeCalendars" />
                </Form.Item>
                <Form.Item name="nameEn" label="Name (English)">
                  <Input name="nameEn" />
                </Form.Item>
                <Form.Item name="nameFr" label="Name (French)">
                  <Input name="nameFr" />
                </Form.Item>
              </Modal>
            );
          }}
        </Formik>
      </StopPropagation>
    </Space>
  );
};
