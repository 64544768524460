import {CaretRightOutlined} from '@ant-design/icons';
import {Collapse, Typography, Card} from 'antd';
import React from 'react';
const {Panel} = Collapse;

const {Title, Paragraph} = Typography;

export const HelpView = () => {
  return (
    <Card>
      <Title level={1}>Help Centre</Title>
      <Paragraph>
        Please reach out to our support team at{' '}
        <a href="mailto:novascotiasupport@canimmunize.ca">novascotiasupport@canimmunize.ca</a> with
        any issues or questions!
        <p></p>
        <p>
          Please do not include any personal health information in your message. Personal health
          information includes health card number, proof of vaccination information and any
          combination of information that can identify a person and reveal medical information.
        </p>
      </Paragraph>
      <Title level={4}>Training Materials</Title>
      <Collapse
        bordered={true}
        expandIcon={({isActive}) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
        className="site-collapse-custom-collapse"
      >
        <Panel header="Release Notes" key="1" className="site-collapse-custom-panel">
          <p>
            <a href="https://s3.ca-central-1.amazonaws.com/canimmunize.cdn/cf2/help-centre/Release+Notes+for+811.pdf">
              Release Notes for 811
            </a>{' '}
          </p>
          <p>
            <a href="https://s3.ca-central-1.amazonaws.com/canimmunize.cdn/cf2/help-centre/Release+Notes+for+Clinic+Owners.pdf">
              Release Notes for Clinic Owners
            </a>{' '}
          </p>
          <p>
            <a href="https://s3.ca-central-1.amazonaws.com/canimmunize.cdn/cf2/help-centre/Release+Notes+for+Clinic+Admins.pdf">
              Release Notes for Clinic Admins
            </a>{' '}
          </p>
        </Panel>
        <Panel
          header="Admin Console Training Guides"
          key="2"
          className="site-collapse-custom-panel"
        >
          <p>
            <a href="https://s3.ca-central-1.amazonaws.com/canimmunize.cdn/cf2/help-centre/Admin+Console+Training+-+Super+Admin.pdf">
              Training for Super Admins
            </a>{' '}
          </p>
          <p>
            <a href="https://s3.ca-central-1.amazonaws.com/canimmunize.cdn/cf2/help-centre/Admin+Console+Training+-+Clinic+Admin.pdf">
              Training for Clinic Admins
            </a>{' '}
          </p>
          <p>
            <a href="https://s3.ca-central-1.amazonaws.com/canimmunize.cdn/cf2/help-centre/Admin+Console+Training+-+Clinic+Owner+Role.pdf">
              Training for Clinic Owners{' '}
            </a>
          </p>
          <p>
            <a href="https://s3.ca-central-1.amazonaws.com/canimmunize.cdn/cf2/help-centre/Admin+Console+Training+-+Call+Centre+Agent+Role.pdf">
              Training for Call Centre Agents{' '}
            </a>
          </p>
          <p>
            <a href="https://s3.ca-central-1.amazonaws.com/canimmunize.cdn/cf2/help-centre/COVID-19+Provider+Lookup+Training.pdf">
              Training for Health Care Providers{' '}
            </a>
          </p>
        </Panel>
        <Panel
          header="Clinic Mode Immunizer Training Guide"
          key="3"
          className="site-collapse-custom-panel"
        >
          <p>
            <a href="https://s3.ca-central-1.amazonaws.com/canimmunize.cdn/cf2/help-centre/NS+Immunizer+Clinic+Mode+Training+Manual+Feb+16.pdf">
              Immunizer Training Manual
            </a>
          </p>
        </Panel>
        <Panel
          header="Clinic Mode Greeter Training Guide"
          key="4"
          className="site-collapse-custom-panel"
        >
          <p>
            <a href="https://s3.ca-central-1.amazonaws.com/canimmunize.cdn/cf2/help-centre/NS+Greeter+Clinic+Mode+Training+Manual+Feb+16.pdf">
              Greeter Training Manual
            </a>
          </p>
        </Panel>
        <Panel
          header="Admin Console and Clinic Mode Training Videos"
          key="5"
          className="site-collapse-custom-panel"
        >
          <p>
            <a href="https://s3.ca-central-1.amazonaws.com/canimmunize.cdn/cf2/help-centre/Clinic+Flow+-+Admin+Console+Training+Videos+Links.pdf">
              Admin Console Training Videos Links
            </a>
          </p>
          <p>
            <a href="https://s3.ca-central-1.amazonaws.com/canimmunize.cdn/cf2/help-centre/Clinic+Flow+-+Clinic+Mode+Training+Videos+Links.pdf">
              Clinic Mode Training Videos Links
            </a>
          </p>
        </Panel>
        <Panel header="Frequently Asked Questions" key="6" className="site-collapse-custom-panel">
          <p>
            <a href="https://s3.ca-central-1.amazonaws.com/canimmunize.cdn/cf2/help-centre/FAQ+Immunizers+Appt+Clinic+flow.docx.pdf">
              Immunizer FAQs
            </a>
          </p>
          <p>
            <a href="https://s3.ca-central-1.amazonaws.com/canimmunize.cdn/cf2/help-centre/FAQ+-+Clinic+Mode+LTC+Immunizer+Access.pdf">
              Long Term Care Immunizer FAQs
            </a>
          </p>
          <p>
            <a href="https://s3.ca-central-1.amazonaws.com/canimmunize.cdn/cf2/help-centre/ForgotPasswordFlow.pdf">
              Logging Into Clinic Flow
            </a>
          </p>
        </Panel>
      </Collapse>
    </Card>
  );
};
