import React from 'react';
import {AvailabilitySlotDay} from '../../../../interface-models/availability-rules/availability-rule-day';
import {AvailabilityRuleType} from '../../../../interface-models/availability-rules/availability-rule-types/availability-rule-type';
import {UAvailabilityRuleTypes} from '../../../../interface-models/availability-rules/availability-rules';
import {validateEndDate, validateEndTime} from './availability-rule-form-fields';
import {Formik} from 'formik';
import {Modal, Space, Popconfirm, Button} from 'antd';
import {NonRecurringRuleFormItems, RecurringRuleFormItems} from './availability-rule-forms';
import {Clinic} from '../../../../models/clinics';
import moment from 'moment';
import uuid from 'uuid';
import {useGlobalSettings} from '../../../../models/global-settings';

/**
 *
 *
 * @interface INewAvailabilityProps
 */
interface INewAvailabilityProps {
  /**
   * Whether the modal is currently shown to the user
   *
   * @type {boolean}
   * @memberof INewAvailabilityProps
   */
  isModalOpen: boolean;
  /**
   * The type of rule the form should be for
   *
   * @type {AvailabilityRuleType}
   * @memberof INewAvailabilityProps
   */
  ruleType: AvailabilityRuleType;
  /**
   * Called when the user clicks the OK button with the new created rule
   *
   * @memberof INewAvailabilityProps
   */
  addAvailabilityRule: (newRule: UAvailabilityRuleTypes) => void;
  /**
   * Function to close the modal
   *
   * @memberof INewAvailabilityProps
   */
  closeModal: () => void;
  /**
   * Clinic for current availability rule
   *
   * @memberof INewAvailabilityProps
   */
  clinic: Clinic;
}

/** Modal used in the availability rules field to add a new rule */
export const NewAvailabilityModal = (props: INewAvailabilityProps) => {
  const settings = useGlobalSettings();
  const enableDose2Availability = settings['enableDose2Availability']?.value === 'true';
  const initialValues = Object.assign(
    {},
    /* The base fields every rule needs */
    {
      id: uuid.v4(),
      type: props.ruleType,
      isAvailable: true,
      startTime: moment.utc('09:00', 'HH:mm') as undefined | moment.Moment,
      endTime: moment.utc('17:00', 'HH:mm') as undefined | moment.Moment,
      groupCount: props.clinic.category === 'Group' ? 0 : undefined,
      createGroupAvailability: props.clinic.category === 'Group',
      immunizerCount: 0,
      duration: 0,
    },
    props.ruleType === AvailabilityRuleType.NonRecurring
      ? {
          date: moment.utc() as undefined | moment.Moment,
        }
      : props.ruleType === AvailabilityRuleType.RecurringDay
      ? {
          startDate: moment.utc() as undefined | moment.Moment,
          endDate: moment.utc() as undefined | moment.Moment,
          days: [] as AvailabilitySlotDay[],
        }
      : undefined
  );
  const validate = (values: typeof initialValues) => {
    return {
      ...validateEndTime(values),
      ...(props.ruleType === AvailabilityRuleType.RecurringDay
        ? validateEndDate(values)
        : undefined),
    };
  };

  return (
    <Formik initialValues={initialValues} onSubmit={() => {}} validate={validate}>
      {(formikProps) => {
        const ModalFooter = () => {
          return (
            <Space>
              <Popconfirm
                title="Are you sure you want to close? You have unsaved changes."
                disabled={!formikProps.dirty}
                onConfirm={() => {
                  props.closeModal();
                  formikProps.resetForm();
                }}
                okText="Yes"
                cancelText="No"
                okButtonProps={{
                  onMouseDown: (e) => {
                    e.preventDefault();
                  },
                }}
              >
                <Button
                  onMouseDown={(e) => {
                    e.preventDefault();
                  }}
                  onClick={() => {
                    if (formikProps.dirty) return;
                    props.closeModal();
                  }}
                >
                  Cancel
                </Button>
              </Popconfirm>
              <Button
                loading={formikProps.isSubmitting}
                type="primary"
                onMouseDown={(e) => {
                  e.preventDefault();
                }}
                onClick={() => {
                  {
                    props.addAvailabilityRule({
                      ...(formikProps.values as any),
                      /* Don't use the type in the values object since for some
                      reason it does not change when the prop changes. Use the
                      props.ruleType field */
                      type: props.ruleType,
                    });
                    props.closeModal();
                    formikProps.resetForm();
                  }
                }}
                disabled={!formikProps.isValid}
              >
                Save
              </Button>
            </Space>
          );
        };

        return (
          <Modal
            visible={props.isModalOpen}
            okText="Save"
            closable={false}
            maskClosable={false}
            footer={<ModalFooter />}
            title="New Rule"
          >
            {props.ruleType === AvailabilityRuleType.NonRecurring ? (
              <NonRecurringRuleFormItems
                namePrefix=""
                clinic={props.clinic}
                rule={formikProps.values}
                enableDose2Availability={enableDose2Availability}
              />
            ) : props.ruleType === AvailabilityRuleType.RecurringDay ? (
              <RecurringRuleFormItems
                namePrefix=""
                clinic={props.clinic}
                rule={formikProps.values}
                enableDose2Availability={enableDose2Availability}
              />
            ) : undefined}
          </Modal>
        );
      }}
    </Formik>
  );
};
