"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_PlanDefinition_Action = exports.PlanDefinition_ActionCardinalityBehaviorKind = exports.PlanDefinition_ActionPrecheckBehaviorKind = exports.PlanDefinition_ActionRequiredBehaviorKind = exports.PlanDefinition_ActionSelectionBehaviorKind = exports.PlanDefinition_ActionGroupingBehaviorKind = void 0;
var t = __importStar(require("io-ts"));
var RTTI_Extension_1 = require("./RTTI_Extension");
var RTTI_Element_1 = require("./RTTI_Element");
var RTTI_code_1 = require("../Scalar/RTTI_code");
var RTTI_CodeableConcept_1 = require("./RTTI_CodeableConcept");
var RTTI_RelatedArtifact_1 = require("./RTTI_RelatedArtifact");
var RTTI_id_1 = require("../Scalar/RTTI_id");
var RTTI_Reference_1 = require("./RTTI_Reference");
var RTTI_TriggerDefinition_1 = require("./RTTI_TriggerDefinition");
var RTTI_PlanDefinition_Condition_1 = require("./RTTI_PlanDefinition_Condition");
var RTTI_DataRequirement_1 = require("./RTTI_DataRequirement");
var RTTI_PlanDefinition_RelatedAction_1 = require("./RTTI_PlanDefinition_RelatedAction");
var RTTI_Age_1 = require("./RTTI_Age");
var RTTI_Period_1 = require("./RTTI_Period");
var RTTI_Duration_1 = require("./RTTI_Duration");
var RTTI_Range_1 = require("./RTTI_Range");
var RTTI_Timing_1 = require("./RTTI_Timing");
var RTTI_PlanDefinition_Participant_1 = require("./RTTI_PlanDefinition_Participant");
var RTTI_canonical_1 = require("../Scalar/RTTI_canonical");
var RTTI_PlanDefinition_DynamicValue_1 = require("./RTTI_PlanDefinition_DynamicValue");
var PlanDefinition_ActionGroupingBehaviorKind;
(function (PlanDefinition_ActionGroupingBehaviorKind) {
    PlanDefinition_ActionGroupingBehaviorKind["_visualGroup"] = "visual-group";
    PlanDefinition_ActionGroupingBehaviorKind["_logicalGroup"] = "logical-group";
    PlanDefinition_ActionGroupingBehaviorKind["_sentenceGroup"] = "sentence-group";
})(PlanDefinition_ActionGroupingBehaviorKind = exports.PlanDefinition_ActionGroupingBehaviorKind || (exports.PlanDefinition_ActionGroupingBehaviorKind = {}));
var PlanDefinition_ActionSelectionBehaviorKind;
(function (PlanDefinition_ActionSelectionBehaviorKind) {
    PlanDefinition_ActionSelectionBehaviorKind["_any"] = "any";
    PlanDefinition_ActionSelectionBehaviorKind["_all"] = "all";
    PlanDefinition_ActionSelectionBehaviorKind["_allOrNone"] = "all-or-none";
    PlanDefinition_ActionSelectionBehaviorKind["_exactlyOne"] = "exactly-one";
    PlanDefinition_ActionSelectionBehaviorKind["_atMostOne"] = "at-most-one";
    PlanDefinition_ActionSelectionBehaviorKind["_oneOrMore"] = "one-or-more";
})(PlanDefinition_ActionSelectionBehaviorKind = exports.PlanDefinition_ActionSelectionBehaviorKind || (exports.PlanDefinition_ActionSelectionBehaviorKind = {}));
var PlanDefinition_ActionRequiredBehaviorKind;
(function (PlanDefinition_ActionRequiredBehaviorKind) {
    PlanDefinition_ActionRequiredBehaviorKind["_must"] = "must";
    PlanDefinition_ActionRequiredBehaviorKind["_could"] = "could";
    PlanDefinition_ActionRequiredBehaviorKind["_mustUnlessDocumented"] = "must-unless-documented";
})(PlanDefinition_ActionRequiredBehaviorKind = exports.PlanDefinition_ActionRequiredBehaviorKind || (exports.PlanDefinition_ActionRequiredBehaviorKind = {}));
var PlanDefinition_ActionPrecheckBehaviorKind;
(function (PlanDefinition_ActionPrecheckBehaviorKind) {
    PlanDefinition_ActionPrecheckBehaviorKind["_yes"] = "yes";
    PlanDefinition_ActionPrecheckBehaviorKind["_no"] = "no";
})(PlanDefinition_ActionPrecheckBehaviorKind = exports.PlanDefinition_ActionPrecheckBehaviorKind || (exports.PlanDefinition_ActionPrecheckBehaviorKind = {}));
var PlanDefinition_ActionCardinalityBehaviorKind;
(function (PlanDefinition_ActionCardinalityBehaviorKind) {
    PlanDefinition_ActionCardinalityBehaviorKind["_single"] = "single";
    PlanDefinition_ActionCardinalityBehaviorKind["_multiple"] = "multiple";
})(PlanDefinition_ActionCardinalityBehaviorKind = exports.PlanDefinition_ActionCardinalityBehaviorKind || (exports.PlanDefinition_ActionCardinalityBehaviorKind = {}));
var EnumType_1 = require("../../EnumType");
exports.RTTI_PlanDefinition_Action = t.recursion('IPlanDefinition_Action', function () {
    return t.partial({
        id: t.string,
        extension: t.array(RTTI_Extension_1.RTTI_Extension),
        modifierExtension: t.array(RTTI_Extension_1.RTTI_Extension),
        prefix: t.string,
        _prefix: RTTI_Element_1.RTTI_Element,
        title: t.string,
        _title: RTTI_Element_1.RTTI_Element,
        description: t.string,
        _description: RTTI_Element_1.RTTI_Element,
        textEquivalent: t.string,
        _textEquivalent: RTTI_Element_1.RTTI_Element,
        priority: RTTI_code_1.RTTI_code,
        _priority: RTTI_Element_1.RTTI_Element,
        code: t.array(RTTI_CodeableConcept_1.RTTI_CodeableConcept),
        reason: t.array(RTTI_CodeableConcept_1.RTTI_CodeableConcept),
        documentation: t.array(RTTI_RelatedArtifact_1.RTTI_RelatedArtifact),
        goalId: t.array(RTTI_id_1.RTTI_id),
        _goalId: t.array(RTTI_Element_1.RTTI_Element),
        subjectCodeableConcept: RTTI_CodeableConcept_1.RTTI_CodeableConcept,
        subjectReference: RTTI_Reference_1.RTTI_Reference,
        trigger: t.array(RTTI_TriggerDefinition_1.RTTI_TriggerDefinition),
        condition: t.array(RTTI_PlanDefinition_Condition_1.RTTI_PlanDefinition_Condition),
        input: t.array(RTTI_DataRequirement_1.RTTI_DataRequirement),
        output: t.array(RTTI_DataRequirement_1.RTTI_DataRequirement),
        relatedAction: t.array(RTTI_PlanDefinition_RelatedAction_1.RTTI_PlanDefinition_RelatedAction),
        timingDateTime: t.string,
        _timingDateTime: RTTI_Element_1.RTTI_Element,
        timingAge: RTTI_Age_1.RTTI_Age,
        timingPeriod: RTTI_Period_1.RTTI_Period,
        timingDuration: RTTI_Duration_1.RTTI_Duration,
        timingRange: RTTI_Range_1.RTTI_Range,
        timingTiming: RTTI_Timing_1.RTTI_Timing,
        participant: t.array(RTTI_PlanDefinition_Participant_1.RTTI_PlanDefinition_Participant),
        type: RTTI_CodeableConcept_1.RTTI_CodeableConcept,
        groupingBehavior: EnumType_1.createEnumType(PlanDefinition_ActionGroupingBehaviorKind, 'PlanDefinition_ActionGroupingBehaviorKind'),
        _groupingBehavior: RTTI_Element_1.RTTI_Element,
        selectionBehavior: EnumType_1.createEnumType(PlanDefinition_ActionSelectionBehaviorKind, 'PlanDefinition_ActionSelectionBehaviorKind'),
        _selectionBehavior: RTTI_Element_1.RTTI_Element,
        requiredBehavior: EnumType_1.createEnumType(PlanDefinition_ActionRequiredBehaviorKind, 'PlanDefinition_ActionRequiredBehaviorKind'),
        _requiredBehavior: RTTI_Element_1.RTTI_Element,
        precheckBehavior: EnumType_1.createEnumType(PlanDefinition_ActionPrecheckBehaviorKind, 'PlanDefinition_ActionPrecheckBehaviorKind'),
        _precheckBehavior: RTTI_Element_1.RTTI_Element,
        cardinalityBehavior: EnumType_1.createEnumType(PlanDefinition_ActionCardinalityBehaviorKind, 'PlanDefinition_ActionCardinalityBehaviorKind'),
        _cardinalityBehavior: RTTI_Element_1.RTTI_Element,
        definitionCanonical: t.string,
        _definitionCanonical: RTTI_Element_1.RTTI_Element,
        definitionUri: t.string,
        _definitionUri: RTTI_Element_1.RTTI_Element,
        transform: RTTI_canonical_1.RTTI_canonical,
        dynamicValue: t.array(RTTI_PlanDefinition_DynamicValue_1.RTTI_PlanDefinition_DynamicValue),
        action: t.array(exports.RTTI_PlanDefinition_Action)
    });
});
