/**
 * Set in the type field of an availability slot to differentiate between
 * the different types
 *
 * @export
 * @enum {number}
 */
export enum AvailabilityRuleType {
  RecurringDay,
  NonRecurring
}