import {changeAntdTheme, generateThemeColor} from 'mini-dynamic-antd-theme';
import React from 'react';
import {FhirProvider} from '../../services/fhir';
import {AbilityProvider} from '../../services/roles/ability-context';
import {VaccineReceipt} from '../../views/vaccine-receipt';
import {InactivityLogout} from '../inactivity-logout/inactivity-logout-ux';
import {YukonCallCentreLayout} from '../layouts/yk-call-centre';

export const YKCallCentreUX = () => {
  React.useEffect(() => {
    return () => {
      changeAntdTheme(generateThemeColor('#265ed6'), 'a { text-decoration: underline;}');
    };
  });

  return (
    <FhirProvider>
      <AbilityProvider path={`/:lang/ykcallcentre/*`}>
        <InactivityLogout />
        <YukonCallCentreLayout>
          <VaccineReceipt mode="portal" isYukonCallCentre={true} />
        </YukonCallCentreLayout>
      </AbilityProvider>
    </FhirProvider>
  );
};
