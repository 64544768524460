export function defineSuperAdminRules(builder) {
  const {can, cannot} = builder;
  // Menus
  can('access', 'menu', ['**']);

  // Routes
  can('access', 'route', ['**']);

  // Permissions
  can(['create', 'read', 'update', 'delete', 'execute', 'view', 'reforecast'], 'all');

  can('import', 'all');

  // Adding this to prevent the CITF option from showing for NS Superadmin users
  // Those users should be transitioned to an ns-admin role that doesn't include this permission
  cannot('import', 'patients-citf');
}

export function defineConnectServerAdminRules(builder) {
  const {can} = builder;
  defineConnectionOrgAdminRules(builder);
  can('access', 'menu', ['connectCustomers']);
  can('access', 'route', ['/connectCustomers**']);
  can('view', 'orgAdminUsers');
}

export function defineClinicOwnerRules(builder) {
  const {can} = builder;
  // Menus
  can('access', 'menu', [
    'home',
    'patients',
    'appointments',
    'cohorts',
    'reports',
    'clinics',
    'users',
    'roles',
    'orgadminusers',
    'patientlookup',
    'help',
  ]);
  // Routes
  can('access', 'route', [
    '/home',
    '/patients**',
    '/appointments**',
    '/cohorts**',
    '/reports',
    '/clinics**',
    '/users**',
    '/roles**',
    '/orgadminusers**',
    '/patientlookup**',
    '/help',
  ]);
  // Permissions
  can('create', 'patients', ['newPatients', 'immunization', 'appointment']);
  can('update', 'patients', ['cohortsWidget', 'immunization', 'editPatient', 'overrideSeries']);
  can('delete', 'patients', ['patient', 'immunization']);
  can('view', 'patients', [
    'appointmentsWidget',
    'cohortsWidget',
    'formsWidget',
    'aefiWidget',
    'notesWidget',
    'adminWidget',
    'flagsWidget',
    'launchPortalButton',
  ]);
  can('view', 'clinics', ['cohortsTab', 'immunizersTab', 'patientsTab', 'notesWidget']);
  can('reforecast', 'patients');
  can('execute', 'appointments', ['blockRule']);
  can('view', ['orgAdminUsers', 'clinicUsers']);
}

export function defineClinicAdminRules(builder) {
  const {can} = builder;
  // Menus
  can('access', 'menu', [
    'home',
    'patients',
    'appointments',
    'reports',
    'clinics',
    'users',
    'patientlookup',
    'help',
  ]);
  // Routes
  can('access', 'route', [
    '/home',
    '/patients**',
    '/appointments**',
    '/reports',
    '/clinics**',
    '/users**',
    '/patientlookup**',
    '/help',
  ]);
  // Permissions
  can('create', 'patients', ['appointment']);
  can('update', 'patients', ['immunization', 'editPatient']);
  can('view', 'patients', [
    'appointmentsWidget',
    'cohortsWidget',
    'formsWidget',
    'aefiWidget',
    'notesWidget',
    'flagsWidget',
    'launchPortalButton',
  ]);
  can('view', 'clinics', ['immunizersTab']);
  can('reforecast', 'patients');
  can('execute', 'appointments', ['blockRule']);
  can('view', ['clinicUsers']);
}

export function defineSpecialCallCenterAgentRules(builder) {
  const {can} = builder;
  // Menus
  can('access', 'menu', ['home', 'patients', 'appointments', 'help']);
  // Routes
  can('access', 'route', ['/home', '/patients**', '/appointments**', '/help']);
  // Permissions
  can('create', 'appointments', 'singleDose');
  can('create', 'patients', ['appointment']);
  can('update', 'patients', ['editPatient']);
  can('view', 'patients', ['appointmentsWidget', 'flagsWidget']);
}

export function defineCallCenterAgentRules(builder) {
  const {can} = builder;
  // Menus
  can('access', 'menu', ['home', 'patients', 'appointments', 'help']);
  // Routes
  can('access', 'route', ['/home', '/patients**', '/appointments**', '/help']);
  // Permissions
  can('update', 'patients', ['editPatient']);
  can('create', 'patients', ['appointment']);
  can('view', 'patients', ['appointmentsWidget', 'flagsWidget']);
}

export function defineHcProviderRules(builder) {
  const {can} = builder;
  // Menus
  can('access', 'menu', ['patientlookup']);
  // Routes
  can('access', 'route', ['/home', '/patientlookup**']);
  // Permissions
  can('hide', 'organizationSelector');
}

export function defineUserSupportRules(builder) {
  const {can} = builder;
  // Menus
  can('access', 'menu', ['home', 'clinics', 'users', 'help']);
  // Routes
  can('access', 'route', ['/home', '/clinics**', '/users**', '/help']);
  // Permissions
  can('view', 'clinics', ['immunizersTab']);
  can('reforecast', 'patients');
  can('view', ['clinicUsers']);
}

export function definePEIAdminRules(builder) {
  const {can} = builder;
  // Menus
  can('access', 'menu', ['home', 'patients', 'logs']);
  // Routes
  can('access', 'route', ['/home', '/patients**', '/logs']);
  // Permissions
  can('display', 'dateFormat', ['YYYY/MM/DD', 'LL']);
  can('view', 'patients', ['consentsWidget', 'cohortsWidget', 'formsWidget']);
}

export function defineConnectionOrgAdminRules(builder) {
  const {can} = builder;
  // Menus
  can('access', 'menu', [
    'home',
    'patients',
    'api',
    'connections',
    'users',
    'orgadminusers',
    'terminology',
    'help',
  ]);
  // Routes
  can('access', 'route', [
    '/home',
    '/patients**',
    '/tokens**',
    '/connections**',
    '/users**',
    '/orgadminusers**',
    '/terminology**',
    '/help',
  ]);
  // Permissions
  can('create', 'patients', ['newPatients']);
  can('update', 'patients', ['cohortsWidget', 'immunization', 'editPatient']);
  can('view', 'patients', ['cohortsWidget', 'formsWidget']);
  can('view', 'clinics', ['cohortsTab', 'immunizersTab', 'patientsTab']);
  can('accessDashboard', 'connect');
  can('view', 'employerPatientColumns');
  can('view', ['orgAdminUsers']);
}
