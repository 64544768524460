import {faEye, faRocket} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Space, Tag} from 'antd';
import React from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {RootState} from '../../models';
import BookingPage from '../../models/booking-pages';
import {useGlobalSettings} from '../../models/global-settings';
import {useEnvInfo} from '../../services/environment';
import {AbilityContext} from '../../services/roles/ability-context';
import {FormConfig} from '../base-entity/base-entity';
import {BaseEntityV2} from '../base-entity/base-entity';
import {ClinicFormCustomFieldName} from '../clinic/clinic-form-ux-model';
import {FHIRTableProps} from '../fhir-table';
import {FormUX} from '../form-ux/form-ux';
import {FormUXFieldType} from '../form-ux/form-ux-models/form-ux-fields/form-ux-field-type';
import {OrganizationPickerFormItem} from '../organization/organization-picker';
import {AppointmentTypesField} from './appointment-type/appointment-types-field';
import {BookingPageActions} from './booking-page-actions';
import {BookingPageCustomFieldName, generateBookingPageFormUXModel} from './booking-page-fields';
import {BookingPageUXItemPage} from './booking-page-item-page';
import {BookingPagePreview} from './booking-page-preview';

export const BookingPageUX = (props) => {
  const navigate = useNavigate();
  const model = BookingPage;
  const globalOrg = useSelector((state: RootState) => state.ui.localOrg);
  const CantDeleteBookingPage = () => {
    const ability = React.useContext(AbilityContext);
    return !ability?.can('delete', 'bookingpages', 'bookingpage');
  };
  const globalSettings = useGlobalSettings();
  const {environmentId} = useEnvInfo();
  const settings = {...globalSettings, environmentId};
  const fixedFilters = globalOrg
    ? {
        _organizationId: globalOrg.id,
        _filterField: 'status',
        _filterValues: ['active', 'protected'],
      }
    : {
        _organizationId: '',
        _filterField: 'status',
        _filterValues: ['active', 'protected'],
      };

  const formConfig: FormConfig = {
    defaultValues: {organizationId: globalOrg?.id},
    // Add organizationid to the submission
    preSubmit: (values, actions, onSubmit) => {
      const BookingPageFormUXModel = generateBookingPageFormUXModel(settings);
      const fields: {name: string}[] = BookingPageFormUXModel.reduce((fields, field) => {
        return field.type === FormUXFieldType.grouped
          ? [...fields, ...field.fields]
          : [...fields, field];
      }, [] as any[]);

      const submittableValues = fields.reduce((submittables, field) => {
        return {...submittables, [field.name]: values[field.name]};
      }, {});

      onSubmit({...submittableValues}, actions);
    },
    FormItems: (props) => (
      <FormUX
        formUXModel={generateBookingPageFormUXModel(settings)}
        createMode={props.createMode}
        modal={props.modal}
        renderCustomField={(field) => {
          switch (field.name) {
            case ClinicFormCustomFieldName.OrganizationId: {
              return (
                <OrganizationPickerFormItem
                  label={field.label}
                  name={field.name}
                  setFieldValue={props.setFieldValue}
                  validationRules={field.validationRules}
                />
              );
            }
            case BookingPageCustomFieldName.AppointmentTypes: {
              return (
                <AppointmentTypesField fieldName={BookingPageCustomFieldName.AppointmentTypes} />
              );
            }
            default: {
              throw new Error(`Unhandled custom field ${field.name} in
            renderCustomField method`);
            }
          }
        }}
      />
    ),
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      render: (_, item) => {
        return <BookingPageStatusTag bookingPage={item} />;
      },
      filters: [
        {text: 'Active', value: 'active'},
        {text: 'Password Protected', value: 'protected'},
        {text: 'Inactive', value: 'inactive'},
      ],
    },
    {
      title: 'Links',
      render: (_, item) => <BookingPageActions bookingPage={item} />,
    },
  ];

  const tableConfig: FHIRTableProps = {
    fhirResource: 'booking-page',
    label: 'Booking Page',
    defaultPrimarySearchParam: 'name',
    fixedFilters,
    mode: 'table',
    showCreateButton: true,
    onClickRow: (row) => {
      navigate(`/bookingpages/${row.id}`);
    },
    columns,
  };

  const titleBarFooter = (item, settings) => {
    const overrideText = environmentId.includes('yukon-2')
      ? 'HCN Validation Override Enabled'
      : 'CR Override Enabled';

    return (
      <Space size={5}>
        <BookingPageStatusTag bookingPage={item} />
        <Tag>{`Slug: ${item.bookingPageSlugEn}`}</Tag>
        {item.status === 'protected' && <Tag>{`Password: ${item.password}`}</Tag>}
        {item.clientRegistryOverrideEnabled === 'true' && <Tag>{overrideText}</Tag>}
        {item.showAddressLookup === 'true' && <Tag>Address Lookup Enabled</Tag>}
      </Space>
    );
  };

  const slug = 'bookingpage';
  const config = {
    slug,
    model,
    itemTitleField: 'name',
    formConfig,
    searchPageTitle: 'Booking Pages',
    hideEditButton: true,
    tableConfig,
    itemPage: BookingPageUXItemPage,
    modalCreateForm: true,
    hideDeleteButton: CantDeleteBookingPage,
    titleBarEntityLabel: 'Booking Page',
    ItemPageButtons,
    titleBarFooter,
  };

  return <BaseEntityV2 {...config} {...props} />;
};

export const BookingPageStatusTag = ({bookingPage}) => {
  let color;
  let text;
  switch (bookingPage.status) {
    case 'active':
      color = 'green';
      text = 'Active';
      break;
    case 'inactive':
      color = 'red';
      text = 'Inactive';
      break;
    case 'protected':
      color = 'blue';
      text = 'Password Protected';
      break;
    default:
      break;
  }
  return <Tag color={color}>{text}</Tag>;
};

const ItemPageButtons = ({item: bookingPage}) => {
  const [displayPreview, setDisplayPreview] = React.useState(false);
  return (
    <>
      <Space>
        <Button style={{paddingLeft: 10, paddingRight: 10}} onClick={() => setDisplayPreview(true)}>
          <FontAwesomeIcon icon={faEye} style={{marginRight: 10}} />
          Preview Availability
        </Button>
        <Button
          type="primary"
          href={`/en/${bookingPage.bookingPageSlug.en}`}
          target="_blank"
          style={{paddingLeft: 10, paddingRight: 10}}
        >
          <FontAwesomeIcon icon={faRocket} color="white" style={{marginRight: 10}} />
          Launch Booking Page
        </Button>
      </Space>
      <BookingPagePreview
        visible={displayPreview}
        setVisible={setDisplayPreview}
        bookingPage={bookingPage}
      />
    </>
  );
};
