import {createSlice} from '@reduxjs/toolkit';
import axios from 'axios';
import produce from 'immer';
import {normalize, schema} from 'normalizr';
import querystring from 'querystring';

export interface User {
  pid: string;
  id?: string;
  username: string;
  authorities: any[];
  superuser?: boolean;
  familyName: string;
  givenName: string;
  email: string;
  lastActive: string;
  twoFactorAuthStatus: string;
}

export interface UsersSliceInterface {
  byId: {[string: string]: User};
}
const initialState = {
  byId: {},
};
const user = new schema.Entity(
  'users',
  {},
  {
    idAttribute: 'pid',
    processStrategy: (value: User) =>
      produce(value, (draft: User) => {
        draft.id = value.pid;
      }),
  }
);

const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    SAVE_USERS: (state: UsersSliceInterface, action) => {
      state.byId = normalize(action.payload.users, [user]).entities.users || {};
    },
    SAVE_USER: (state: UsersSliceInterface, action) => {
      state.byId[action.payload.pid] = action.payload;
    },
  },
});

const getAll = (client, params?) => async (dispatch) => {
  let query;
  let _count;
  let pageNum;
  if (params) {
    _count = params._count;
    pageNum = params._getpagesoffset / _count;
    query = params ? querystring.stringify({pageSize: _count, pageNum}) : undefined;
  }
  return axios
    .get(
      `https://canimmunize.demo.smilecdr.com/json-admin/user-management${query ? `?${query}` : ''}`,
      {
        headers: {
          Authorization: `Bearer ${client.state.tokenResponse.access_token}`,
        },
      }
    )
    .then((res) => {
      dispatch(slice.actions.SAVE_USERS(res.data));
      const numUsers = res.data.users.length;
      console.log(`users: ${numUsers} - _count: ${_count}`);
      return {
        total: numUsers === _count ? numUsers * (pageNum + 1) + 1 : numUsers,
        entry: res.data.users.map((datum) => ({resource: datum})),
      };
    });
};

const getOne = getAll;

export const createUser = (client, familyName, givenName, password, username, authorities) => {
  let requestBody = {familyName, givenName, password, username, authorities};
  return axios.post(
    `https://canimmunize.demo.smilecdr.com/json-admin/user-management/Master/local_security`,
    requestBody,
    {
      headers: {
        Authorization: `Bearer ${client.state.tokenResponse.access_token}`,
      },
    }
  );
};

export const create2faCode = (client, style) => {
  return axios.post(
    `https://canimmunize.demo.smilecdr.com/json-admin/user-management/2fa/create-new`,
    style,
    {
      headers: {
        Authorization: `Bearer ${client.state.tokenResponse.access_token}`,
      },
    }
  );
};

export const generateQRCode = (client) => {
  return axios
    .get(`https://canimmunize.demo.smilecdr.com/json-admin/user-management/2fa/generate-qr-code`, {
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${client.state.tokenResponse.access_token}`,
      },
    })
    .then((res) => {
      const url = URL.createObjectURL(res.data);
      return url;
    });
};

export const confirm2faKey = (client, code) => {
  let requestBody = {code, style: 'TOTP'};
  return axios.post(
    `https://canimmunize.demo.smilecdr.com/user-management/2fa/confirm-key`,
    requestBody,
    {
      headers: {
        Authorization: `Bearer ${client.state.tokenResponse.access_token}`,
      },
    }
  );
};

export const delete2faKey = (client, style) => {
  return axios.post(
    `https://canimmunize.demo.smilecdr.com/json-admin/user-management/2fa/delete-key`,
    style,
    {
      headers: {
        Authorization: `Bearer ${client.state.tokenResponse.access_token}`,
      },
    }
  );
};

export const updatePassword = (client, currentPassword, newPassword, user) => {
  let requestBody = {currentPassword, newPassword};
  return axios.put(
    `https://canimmunize.demo.smilecdr.com/json-admin/user-management/Master/local_security/${user.pid}/password`,
    requestBody,
    {
      headers: {
        Authorization: `Bearer ${client.state.tokenResponse.access_token}`,
      },
    }
  );
};

export const updateExpiringPassword = (
  client,
  currentPassword,
  newPassword,
  credentialExpiry,
  user
) => {
  let requestBody = {currentPassword, newPassword, credentialExpiry};
  return axios.put(
    `https://canimmunize.demo.smilecdr.com/json-admin/user-management/Master/local_security/${user.pid}/password`,
    requestBody,
    {
      headers: {
        Authorization: `Bearer ${client.state.tokenResponse.access_token}`,
      },
    }
  );
};

export const updateOwnPassword = (client, currentPassword, newPassword) => {
  let requestBody = {currentPassword, newPassword};
  return axios.put(
    `https://canimmunize.demo.smilecdr.com/json-admin/user-management/password/change`,
    requestBody,
    {
      headers: {
        Authorization: `Bearer ${client.state.tokenResponse.access_token}`,
      },
    }
  );
};

export const updateOne = (client, user) => async (dispatch) => {
  console.log(user);
  return axios
    .put(
      `https://canimmunize.demo.smilecdr.com/json-admin/user-management/Master/local_security/${user.pid}`,
      user,
      {
        headers: {
          Authorization: `Bearer ${client.state.tokenResponse.access_token}`,
        },
      }
    )
    .then((res) => {
      return dispatch(slice.actions.SAVE_USER(res.data));
    })
    .catch((res) => {});
};

const createOne = (client, user) => async (dispatch) => {
  return axios
    .post(
      `https://canimmunize.demo.smilecdr.com/json-admin/user-management/Master/local_security`,
      user,
      {
        headers: {
          Authorization: `Bearer ${client.state.tokenResponse.access_token}`,
        },
      }
    )
    .then((res) => {
      return dispatch(slice.actions.SAVE_USER(res.data));
    });
  // .catch((err) => {
  //   return new Error()
  // });
};

export function userFactory(formData) {
  let {
    familyName,
    givenName,
    password,
    username,
    email,
    notes,
    generatePassword,
    authorities,
    id,
  } = formData;
  if (generatePassword) {
    password = Math.random().toString(36).slice(-8);
  }
  return {
    pid: id,
    familyName,
    givenName,
    password,
    username,
    email,
    notes,
    authorities: authorities || [],
  };
}

export default {
  slice,
  getAll,
  getOne,
  createOne,
  updateOne,
};

export const getUserFullName = (user) => `${user.givenName} ${user.familyName}`;

export const isSuperuser = (user) => {
  return user.authorities.find((auth) => auth.permission === 'ROLE_SUPERUSER') !== undefined;
};
