import * as Icons from '@ant-design/icons';
import {Button, Empty, Space, Tag} from 'antd';
import React from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {RootState} from '../../models';
import {AbilityContext} from '../../services/roles/ability-context';
import {FHIRTable} from '../fhir-table';
import {CohortPatientUploadModal} from '../imports/upload-modals/patient-upload-modal';
import {doseRecDisplayForDate, ForecastStatusTag} from '../patients/forecast-table';
import {PatientFluStatusCell} from '../patients/patient-flu-status-cell';
import {CohortListPatientActions} from './cohort-list-patient-actions';
import {useGlobalSettings} from '../../models/global-settings';

/* A set of status tags for the given patient on a given rule. */
export const CohortRecommendationTagSet = (props: {patient: any; ruleId: string}) => {
  const {patient, ruleId} = props;

  /* Filter the interpretation and forecast entities to only the requested rule. */
  const ruleInterpretation = patient.vaccinationInterpretationFivecast.ruleInterpretations.find(
    (ri) => ri.ruleId === ruleId
  );
  const doseRecommendations = patient.immunizationRecommendationFivecast.doseRecommendations.filter(
    (dr) => dr.ruleId === ruleId
  );

  /* Get display information for today from the dose recommendations. */
  const {
    soonestDoseRecommendations,
    soonestDoseRecommendationDate,
    soonestDoseRecommendationsDisplay,
  } = doseRecDisplayForDate(doseRecommendations);

  return (
    <>
      <ForecastStatusTag status={ruleInterpretation.forecastStatus} />
      {soonestDoseRecommendations.length > 0 && (
        <Tag color="orange">
          {soonestDoseRecommendationsDisplay}: {soonestDoseRecommendationDate?.format('LL')}
        </Tag>
      )}
      {ruleInterpretation.forecastStatus !== 'NOT_STARTED' && (
        <Tag color="cyan">Series: {ruleInterpretation.series.name}</Tag>
      )}
    </>
  );
};

export const CohortItemPage = ({itemId}) => {
  const navigate = useNavigate();
  const cohort = useSelector((state: RootState) => state.cohorts.byId[itemId]);
  const [uploadModalVisible, setUploadModalVisible] = React.useState(false);
  const [isTableEmpty, setIsTableEmpty] = React.useState(false);
  const [refresh, setRefresh] = React.useState(1);
  const ability = React.useContext(AbilityContext);
  const settings = useGlobalSettings();
  const vaccinationStatusColumnData = settings['vaccinationStatusColumns']
    ? JSON.parse(settings['vaccinationStatusColumns'].value)
    : [];

  const patientTableColumns = [
    {
      title: 'Name',
      dataIndex: 'fullName',
      render: (_, p) => (
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <div>{p.fullName}</div>
          {p.deceased && <Tag color="red">Deceased</Tag>}
        </div>
      ),
    },
    /* Retrieved global setting controls what vaccination status columns to display. */
    ...vaccinationStatusColumnData.map((columnData) => ({
      title: `${columnData.name} Vaccination Status`,
      render: (_, patient) => (
        <CohortRecommendationTagSet patient={patient} ruleId={columnData.ruleId} />
      ),
    })),
    {
      title: 'Actions',
      render: (_, patient) => (
        <CohortListPatientActions
          patient={patient}
          cohort={cohort}
          triggerRefresh={() => setRefresh(Math.random())}
        />
      ),
    },
  ];

  //Checks if data in table is empty and sets isTableEmpty
  const onDataLoad = (data) => {
    if (data.total === 0) setIsTableEmpty(true);
    else {
      setIsTableEmpty(false);
    }
  };

  //Callback function on Modal Close
  const onClose = () => {
    setUploadModalVisible(false);
    setRefresh(Math.random());
  };

  //Custom Create Button to display
  const CreateButton = () => {
    return (
      <Button key="fhir" type="primary" size="large" onClick={() => setUploadModalVisible(true)}>
        <Icons.UserAddOutlined /> Upload Additional Patients
      </Button>
    );
  };

  const tableEmptyComponent = () => {
    return (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        imageStyle={{
          height: 60,
        }}
        description={<span>No Data</span>}
      >
        {ability.can('execute', 'cohorts', 'uploadPatients') && (
          <Button type="primary" size="large" onClick={() => setUploadModalVisible(true)}>
            <Icons.UserAddOutlined /> Upload Patients
          </Button>
        )}
      </Empty>
    );
  };

  return (
    <div style={{}}>
      <Space style={{paddingBottom: 15, display: 'flex', justifyContent: 'flex-end'}}></Space>
      <FHIRTable
        mode="table"
        defaultPrimarySearchParam="name"
        columns={patientTableColumns}
        fixedFilters={{cohortId: cohort?.id as string}}
        onClickRow={(item) => navigate(`/patients/${item.id}`)}
        onDataLoad={onDataLoad}
        showCreateButton={ability.can('execute', 'cohorts', 'uploadPatients') && !isTableEmpty}
        componentWhenEmpty={tableEmptyComponent}
        CreateButton={CreateButton}
        triggerRefresh={refresh}
      />
      <CohortPatientUploadModal visible={uploadModalVisible} cohort={cohort} onClose={onClose} />
    </div>
  );
};
