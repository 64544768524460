import * as Icons from '@ant-design/icons';
import {
  generateImmFormFields,
  generateImmDoseValidityOptionsFormFields,
  generateImmInvalidFormFields,
  generateImmValidFormFields,
  generateImmAdditionalFields,
  immFormFieldsCustomFieldRenderer,
} from './imms-form-fields';

import {Button, Divider, Modal, Space, Popconfirm, Typography, message} from 'antd';
import {Formik, FormikProps, FormikHelpers} from 'formik';
import {Form, Input as AntdFormikInput} from 'formik-antd';
import moment from 'moment-timezone';
import React from 'react';
import {FhirUtils} from '../../../services/fhir';
import {ThunkDispatch} from '../../../models';
import {useDispatch} from 'react-redux';

import PatientModel from '../../../models/patients';
import ImmunizationModel from '../../../models/immunizations';
import {FormUX, FormUXFieldType} from '../../form-ux';
import {CreateNoteModal} from '../notes/create-note-modal';
import {AbilityContext} from '../../../services/roles/ability-context';
import {useCurrentUser} from '../../../models/account';
import uuid from 'uuid';
import {getStoredOrg} from '../../../models/organizations';

const dividerStyle = {
  fontSize: '14px',
  borderTopColor: 'rgba(200, 200, 200, 1)',
  color: 'rgba(0,0,0,1)',
};

export const ImmModal = (props) => {
  const client = FhirUtils.useClient();
  const user = useCurrentUser();
  const ability = React.useContext(AbilityContext);
  const {
    onClose,
    visible,
    imm,
    patient,
    showNoteModalForDelete,
    refreshAppointments,
    locationDataUpTop,
  } = props;
  const immunizer = imm?.immunizerClinicModeUser;
  //Expiry date to be displayed for vaccine lot
  const [expiry, setExpiry] = React.useState(imm.expiry);
  //Country to be displayed for service delivery location
  const [country, setCountry] = React.useState(imm.serviceDeliveryLocation?.country?.name.en);
  const [clinicId, setClinicId] = React.useState(imm.clinicEntry?.clinicId);
  //Hide time and timezone in imm modal
  const hideTime = ability.can('display', 'dateFormat', 'LL');
  // Check if patient org is in user's org tree
  const storedOrg = getStoredOrg(patient?.organizationId);
  const isReadOnly = !ability.can('update', 'patients', 'immunization') || !storedOrg;
  const showDeleteButton = ability.can('delete', 'patients', 'immunization') && storedOrg;
  const [deleteModalVisible, setDeleteModalVisible] = React.useState(false);
  const [errorModalMessage, setErrorModalMessage] = React.useState<React.ReactNode>(undefined);
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);
  const initialExplicitDoseValidity = imm.invalidReasonCode
    ? 'invalid'
    : imm.validReasonCode
    ? 'valid'
    : '';
  const [explicitDoseValidity, setExplicitDoseValidity] = React.useState<string>(
    initialExplicitDoseValidity
  );
  const [createNoteModalVisible, setCreateNoteModalVisible] = React.useState(false);

  React.useEffect(() => {
    handleSetExpiryDate(imm.lotNumber, imm.conceptId);
  }, []);

  /* The function from the formik props will be extracted into this later. */
  let revalidateForm;
  React.useEffect(() => {
    revalidateForm?.();
  }, [explicitDoseValidity]);

  //Set expiry datepicker display value
  async function handleSetExpiryDate(lotNumber: string, conceptId: string) {
    if (!lotNumber || !conceptId) return setExpiry(null);
    return client
      .get(`cvc/lot-numbers/${conceptId}`, {params: {lotNumber}})
      .then((res) => setExpiry(res.data.expiryDate))
      .catch(() => setExpiry(null));
  }

  //Set Country display value for service delivery location
  async function handleSetCountry(serviceDeliveryLocationId) {
    if (!serviceDeliveryLocationId) return setCountry(null);
    const sdl = await client
      .get(`/service-delivery-location/${serviceDeliveryLocationId}`)
      .then((res) => res.data);
    if (sdl) {
      setCountry(sdl.country?.name.en);
    } else {
      setExpiry(null);
    }
  }

  const [timezone, setTimezone] = React.useState(
    imm.clinicEntry?.clinic?.timezone || moment.tz.guess()
  );

  const thunkDispatch = useDispatch<ThunkDispatch>();

  const onSubmit = async (values, actions: FormikHelpers<any>) => {
    const {setFieldValue} = actions;

    const modifiedValues = {...values};
    const clearAndModify = (key) => {
      const val = props.actionType === 'create-imm' ? undefined : null;
      if (values[key] === val) return;
      setFieldValue(key, val);
      modifiedValues[key] = val;
    };

    /* Clean-up the hidden fields in the values section. */
    if (values.explicitDoseValidity !== 'valid') {
      clearAndModify('validReasonCode');
      clearAndModify('validOnBranchDoseId');
    }
    if (values.explicitDoseValidity !== 'invalid') {
      clearAndModify('invalidReasonCode');
    }
    setFieldValue('validReasonNote', undefined);

    const requireValidDoseNote =
      modifiedValues.validOnBranchDoseId !== initialFormikValues.validOnBranchDoseId ||
      modifiedValues.validReasonCode !== initialFormikValues.validReasonCode;
    if (requireValidDoseNote && !modifiedValues.validReasonNote)
      return setCreateNoteModalVisible(true);

    actions.setSubmitting(true);
    if (props.actionType === 'update-imm') {
      return handleImmUpdate(modifiedValues, actions);
    } else if (props.actionType === 'create-imm') {
      return handleImmCreate(modifiedValues, actions);
    }
  };

  //Update immunization
  const handleImmUpdate = (values, actions: FormikHelpers<any>) => {
    setErrorMessage(undefined);
    return thunkDispatch(
      ImmunizationModel.updateOne(client, {
        ...values,
        patientId: patient.id,
        invalidReasonCode: values.invalidReasonCode === '' ? null : values.invalidReasonCode,
        intendedBranchId: values.intendedBranchId === '' ? null : values.intendedBranchId,
        invalidReason: undefined,
      })
    )
      .then(async (res) => {
        //Reload patient data with the updated immunization
        await thunkDispatch(PatientModel.getOne(client, patient.id));
        actions.setSubmitting(false);
        if (values.appointmentId && refreshAppointments) refreshAppointments();
        onClose();
      })
      .catch((err) => setErrorMessage(FhirUtils.getErrorDisplay(err)));
  };

  const onConfirmDelete = async (values, formikBag?) => {
    await thunkDispatch(ImmunizationModel.deleteOne(client, imm.id, values?.deleteReason))
      .then(async (res) => {
        await thunkDispatch(PatientModel.getOne(client, patient.id));
        message.success('Vaccination successfully deleted');
        setDeleteModalVisible(false);
        formikBag?.resetForm();
        onClose();
      })
      .catch((err) => {
        if (err.message === 'Request failed with status code 403') {
          setErrorModalMessage(
            `Failed to delete vaccination (Error: You are not authorized to delete this vaccination)`
          );
        } else {
          setErrorModalMessage(`Failed to delete vaccination (${err.message})`);
        }
      });
  };

  const onErrorClose = () => {
    setErrorModalMessage(undefined);
  };

  //Create immunization
  const handleImmCreate = (values, actions: FormikHelpers<any>) => {
    setErrorMessage(undefined);
    const clinicEntryId = uuid.v4();
    const clinicEntry = Object.assign(
      {
        id: clinicEntryId,
        clinicId: values.clinicId,
        consoleSubmitterId: user?.sub,
      },
      values.appointmentId ? {appointmentId: values.appointmentId} : null
    );

    return thunkDispatch(
      ImmunizationModel.createOne(client, {
        ...values,
        pageId: '-1',
        aefiRecorded: false,
        patientId: patient.id,
        expiry: expiry,
        invalidReasonCode: values.invalidReasonCode === '' ? null : values.invalidReasonCode,
        clinicEntryId,
        clinicEntry,
      })
    )
      .then(async (res) => {
        //Reload patient data with the updated immunization
        await thunkDispatch(PatientModel.getOne(client, patient.id));
        actions.setSubmitting(false);
        if (values.appointmentId && refreshAppointments) refreshAppointments();
        onClose();
      })
      .catch((err) => setErrorMessage(FhirUtils.getErrorDisplay(err)));
  };

  /* Ensure the initial values contains the appointmentId for dirty check. */
  const initialFormikValues = {
    ...imm,
    explicitDoseValidity: initialExplicitDoseValidity,
    validDoseNote: undefined,
    appointmentId: imm.clinicEntry ? imm.clinicEntry.appointmentId || '' : undefined,
    invalidReasonCode: imm?.invalidReason?.invalidReasonCode || undefined,
    invalidDoseText: imm?.invalidReason?.invalidReasonText || undefined,
  };

  return (
    <Formik
      initialValues={initialFormikValues}
      enableReinitialize
      validateOnChange={true}
      onSubmit={onSubmit}
    >
      {(formikProps: FormikProps<any>) => {
        const {handleSubmit} = formikProps;
        const {deleted} = formikProps.values;

        /* Extract the validateForm function into higher scope. */
        revalidateForm = formikProps.validateForm;

        /* The function used for rendering the custom fields. */
        const renderCustomField = immFormFieldsCustomFieldRenderer(
          isReadOnly,
          formikProps,
          timezone,
          setTimezone,
          hideTime,
          initialFormikValues,
          immunizer,
          props,
          expiry,
          imm,
          handleSetCountry,
          handleSetExpiryDate,
          setClinicId,
          patient,
          country,
          clinicId
        );

        /* Auto-set date of administration to current date if imm does not have date value (e.g. if new imm being created). */
        if (!formikProps.values?.date) {
          formikProps.setFieldValue('date', moment().format());
        }

        const ModalFooter = () => {
          return isReadOnly ? (
            <Space>
              <Button
                onClick={() => {
                  onClose();
                }}
              >
                Close
              </Button>
            </Space>
          ) : (
            <div id="immModalFooter">
              <Space>
                {errorMessage && <Typography.Text type="danger">{errorMessage}</Typography.Text>}
                <Popconfirm
                  title="Are you sure you want to close? You have unsaved changes."
                  disabled={!formikProps.dirty}
                  onConfirm={() => {
                    onClose();
                    formikProps.resetForm();
                  }}
                  okButtonProps={{
                    onMouseDown: (e) => {
                      e.preventDefault();
                    },
                  }}
                  okText="Yes"
                  cancelText="No"
                  placement="leftBottom"
                  getPopupContainer={(trigger) =>
                    document.getElementById('immModalFooter') || trigger
                  }
                >
                  <Button
                    onMouseDown={(e) => {
                      e.preventDefault();
                    }}
                    onClick={() => {
                      if (formikProps.dirty) return;
                      onClose();
                    }}
                  >
                    Cancel
                  </Button>
                </Popconfirm>
                <Button
                  loading={formikProps.isSubmitting}
                  type="primary"
                  onMouseDown={(e) => {
                    e.preventDefault();
                  }}
                  onClick={handleSubmit as any}
                  disabled={!formikProps.dirty || !formikProps.isValid}
                >
                  Save
                </Button>
              </Space>
            </div>
          );
        };
        const ModalTitle = () => {
          return (
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <label>
                {isReadOnly
                  ? 'Vaccination Record'
                  : props.actionType === 'update-imm'
                  ? 'Edit Vaccination'
                  : 'Add Vaccination'}
              </label>
              {showDeleteButton && props.actionType !== 'create-imm' && (
                <div id="immModalTitle">
                  <Popconfirm
                    title={`Are you sure you want to delete this vaccination record?`}
                    onConfirm={
                      showNoteModalForDelete ? () => setDeleteModalVisible(true) : onConfirmDelete
                    }
                    okText="Yes"
                    cancelText="No"
                    placement="leftTop"
                    getPopupContainer={(trigger) =>
                      document.getElementById('immModalTitle') || trigger
                    }
                  >
                    <Button key="delete" danger>
                      <Icons.DeleteOutlined /> Delete
                    </Button>
                  </Popconfirm>
                </div>
              )}
            </div>
          );
        };

        return (
          <Modal
            title={<ModalTitle />}
            visible={visible}
            width={'60%'}
            onCancel={onClose}
            footer={<ModalFooter />}
            closable={false}
            maskClosable={false}
          >
            {/* Primary section. */}
            <FormUX
              formUXModel={generateImmFormFields(ability, locationDataUpTop, imm)}
              createMode={props.createMode}
              isDisabled={isReadOnly || deleted}
              modal={props.modal}
              renderCustomField={renderCustomField}
            />

            {/* Dose Validation Override section. */}
            <div>
              <Divider orientation="left" className="dark" style={dividerStyle}>
                Dose Validation Override
              </Divider>
              <p>
                The forecaster will automatically determine whether a dose is valid or invalid based
                on the vaccination schedule. Use the buttons below to mark a dose's validity to
                override the forecasted state.
              </p>
              <FormUX
                createMode={props.createMode}
                formUXModel={generateImmDoseValidityOptionsFormFields(
                  ability,
                  [explicitDoseValidity, setExplicitDoseValidity],
                  formikProps.validateForm
                )}
              />

              {/* Invalid dose options. */}
              <span
                style={{
                  display:
                    formikProps.values?.explicitDoseValidity === 'invalid' ? 'inherit' : 'none',
                }}
              >
                <FormUX
                  createMode={props.createMode}
                  formUXModel={generateImmInvalidFormFields(
                    ability,
                    formikProps.values?.explicitDoseValidity === 'invalid'
                  )}
                  renderCustomField={renderCustomField}
                />
              </span>

              {/* Valid dose options. */}
              <span
                style={{
                  display:
                    formikProps.values?.explicitDoseValidity === 'valid' ? 'inherit' : 'none',
                }}
              >
                <FormUX
                  createMode={props.createMode}
                  formUXModel={generateImmValidFormFields(
                    ability,
                    formikProps.values?.explicitDoseValidity === 'valid'
                  )}
                  renderCustomField={renderCustomField}
                />
              </span>
            </div>

            {/* Conditional section. */}
            <FormUX
              formUXModel={generateImmAdditionalFields()}
              createMode={props.createMode}
              isDisabled={isReadOnly || deleted}
              modal={props.modal}
              renderCustomField={renderCustomField}
            />

            {/* Create note modal. */}
            {/* Checking visible state twice to ensure subject is correct. */}
            {createNoteModalVisible && (
              <CreateNoteModal
                visible={createNoteModalVisible}
                onClose={() => setCreateNoteModalVisible(false)}
                formTypes={['clinicalNotes']}
                forceValues={{
                  subject:
                    props.actionType === 'create-imm'
                      ? 'Dose marked as valid'
                      : explicitDoseValidity === 'valid'
                      ? 'Edited - Dose marked as valid'
                      : 'Forced validation of dose removed',
                }}
                patient={patient}
                dose={formikProps.values}
                appointment={null}
                onSubmit={(submission, modalFunctions) => {
                  formikProps.setFieldValue('validReasonNote', submission);
                  modalFunctions.closeModal();
                  formikProps
                    .submitForm()
                    .then(modalFunctions.onSuccess)
                    .catch(modalFunctions.onError);
                }}
              />
            )}

            {/* Delete note modal. */}
            {showNoteModalForDelete && (
              <Formik
                initialValues={{deleteSubject: `Delete Vaccination`, deleteReason: ''}}
                onSubmit={onConfirmDelete}
              >
                {(formikProps) => {
                  return (
                    <>
                      <Modal
                        title="Admin Note for Vaccination Deletion"
                        visible={deleteModalVisible}
                        onCancel={() => {
                          setDeleteModalVisible(false);
                          formikProps.handleReset();
                        }}
                        okButtonProps={{
                          disabled: formikProps.values.deleteReason.length <= 0,
                          loading: formikProps.isSubmitting,
                        }}
                        onOk={() => {
                          formikProps.handleSubmit();
                        }}
                      >
                        <Form labelCol={{span: 4}} wrapperCol={{span: 18}}>
                          <Form.Item name="delete.subject" label="Subject">
                            <AntdFormikInput name="deleteSubject" disabled />
                          </Form.Item>
                          <Form.Item
                            name={'delete.reason'}
                            label={'Reason'}
                            key={'delete.reason'}
                            required
                          >
                            <AntdFormikInput.TextArea name="deleteReason" required />
                          </Form.Item>
                        </Form>
                      </Modal>
                      <Modal
                        visible={!!errorModalMessage}
                        footer={
                          <Button type="primary" onClick={onErrorClose}>
                            Ok
                          </Button>
                        }
                        onCancel={onErrorClose}
                      >
                        <br />
                        {errorModalMessage}
                      </Modal>
                    </>
                  );
                }}
              </Formik>
            )}
          </Modal>
        );
      }}
    </Formik>
  );
};
