"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_TestScript_Assert = exports.TestScript_AssertResponseKind = exports.TestScript_AssertRequestMethodKind = exports.TestScript_AssertOperatorKind = exports.TestScript_AssertDirectionKind = void 0;
var t = __importStar(require("io-ts"));
var RTTI_Extension_1 = require("./RTTI_Extension");
var RTTI_Element_1 = require("./RTTI_Element");
var RTTI_code_1 = require("../Scalar/RTTI_code");
var RTTI_id_1 = require("../Scalar/RTTI_id");
var TestScript_AssertDirectionKind;
(function (TestScript_AssertDirectionKind) {
    TestScript_AssertDirectionKind["_response"] = "response";
    TestScript_AssertDirectionKind["_request"] = "request";
})(TestScript_AssertDirectionKind = exports.TestScript_AssertDirectionKind || (exports.TestScript_AssertDirectionKind = {}));
var TestScript_AssertOperatorKind;
(function (TestScript_AssertOperatorKind) {
    TestScript_AssertOperatorKind["_equals"] = "equals";
    TestScript_AssertOperatorKind["_notEquals"] = "notEquals";
    TestScript_AssertOperatorKind["_in"] = "in";
    TestScript_AssertOperatorKind["_notIn"] = "notIn";
    TestScript_AssertOperatorKind["_greaterThan"] = "greaterThan";
    TestScript_AssertOperatorKind["_lessThan"] = "lessThan";
    TestScript_AssertOperatorKind["_empty"] = "empty";
    TestScript_AssertOperatorKind["_notEmpty"] = "notEmpty";
    TestScript_AssertOperatorKind["_contains"] = "contains";
    TestScript_AssertOperatorKind["_notContains"] = "notContains";
    TestScript_AssertOperatorKind["_eval"] = "eval";
})(TestScript_AssertOperatorKind = exports.TestScript_AssertOperatorKind || (exports.TestScript_AssertOperatorKind = {}));
var TestScript_AssertRequestMethodKind;
(function (TestScript_AssertRequestMethodKind) {
    TestScript_AssertRequestMethodKind["_delete"] = "delete";
    TestScript_AssertRequestMethodKind["_get"] = "get";
    TestScript_AssertRequestMethodKind["_options"] = "options";
    TestScript_AssertRequestMethodKind["_patch"] = "patch";
    TestScript_AssertRequestMethodKind["_post"] = "post";
    TestScript_AssertRequestMethodKind["_put"] = "put";
    TestScript_AssertRequestMethodKind["_head"] = "head";
})(TestScript_AssertRequestMethodKind = exports.TestScript_AssertRequestMethodKind || (exports.TestScript_AssertRequestMethodKind = {}));
var TestScript_AssertResponseKind;
(function (TestScript_AssertResponseKind) {
    TestScript_AssertResponseKind["_okay"] = "okay";
    TestScript_AssertResponseKind["_created"] = "created";
    TestScript_AssertResponseKind["_noContent"] = "noContent";
    TestScript_AssertResponseKind["_notModified"] = "notModified";
    TestScript_AssertResponseKind["_bad"] = "bad";
    TestScript_AssertResponseKind["_forbidden"] = "forbidden";
    TestScript_AssertResponseKind["_notFound"] = "notFound";
    TestScript_AssertResponseKind["_methodNotAllowed"] = "methodNotAllowed";
    TestScript_AssertResponseKind["_conflict"] = "conflict";
    TestScript_AssertResponseKind["_gone"] = "gone";
    TestScript_AssertResponseKind["_preconditionFailed"] = "preconditionFailed";
    TestScript_AssertResponseKind["_unprocessable"] = "unprocessable";
})(TestScript_AssertResponseKind = exports.TestScript_AssertResponseKind || (exports.TestScript_AssertResponseKind = {}));
var EnumType_1 = require("../../EnumType");
exports.RTTI_TestScript_Assert = t.recursion('ITestScript_Assert', function () {
    return t.partial({
        id: t.string,
        extension: t.array(RTTI_Extension_1.RTTI_Extension),
        modifierExtension: t.array(RTTI_Extension_1.RTTI_Extension),
        label: t.string,
        _label: RTTI_Element_1.RTTI_Element,
        description: t.string,
        _description: RTTI_Element_1.RTTI_Element,
        direction: EnumType_1.createEnumType(TestScript_AssertDirectionKind, 'TestScript_AssertDirectionKind'),
        _direction: RTTI_Element_1.RTTI_Element,
        compareToSourceId: t.string,
        _compareToSourceId: RTTI_Element_1.RTTI_Element,
        compareToSourceExpression: t.string,
        _compareToSourceExpression: RTTI_Element_1.RTTI_Element,
        compareToSourcePath: t.string,
        _compareToSourcePath: RTTI_Element_1.RTTI_Element,
        contentType: RTTI_code_1.RTTI_code,
        _contentType: RTTI_Element_1.RTTI_Element,
        expression: t.string,
        _expression: RTTI_Element_1.RTTI_Element,
        headerField: t.string,
        _headerField: RTTI_Element_1.RTTI_Element,
        minimumId: t.string,
        _minimumId: RTTI_Element_1.RTTI_Element,
        navigationLinks: t.boolean,
        _navigationLinks: RTTI_Element_1.RTTI_Element,
        operator: EnumType_1.createEnumType(TestScript_AssertOperatorKind, 'TestScript_AssertOperatorKind'),
        _operator: RTTI_Element_1.RTTI_Element,
        path: t.string,
        _path: RTTI_Element_1.RTTI_Element,
        requestMethod: EnumType_1.createEnumType(TestScript_AssertRequestMethodKind, 'TestScript_AssertRequestMethodKind'),
        _requestMethod: RTTI_Element_1.RTTI_Element,
        requestURL: t.string,
        _requestURL: RTTI_Element_1.RTTI_Element,
        resource: RTTI_code_1.RTTI_code,
        _resource: RTTI_Element_1.RTTI_Element,
        response: EnumType_1.createEnumType(TestScript_AssertResponseKind, 'TestScript_AssertResponseKind'),
        _response: RTTI_Element_1.RTTI_Element,
        responseCode: t.string,
        _responseCode: RTTI_Element_1.RTTI_Element,
        sourceId: RTTI_id_1.RTTI_id,
        _sourceId: RTTI_Element_1.RTTI_Element,
        validateProfileId: RTTI_id_1.RTTI_id,
        _validateProfileId: RTTI_Element_1.RTTI_Element,
        value: t.string,
        _value: RTTI_Element_1.RTTI_Element,
        warningOnly: t.boolean,
        _warningOnly: RTTI_Element_1.RTTI_Element
    });
});
