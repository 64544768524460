"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_ObservationDefinition_QualifiedInterval = exports.ObservationDefinition_QualifiedIntervalGenderKind = exports.ObservationDefinition_QualifiedIntervalCategoryKind = void 0;
var t = __importStar(require("io-ts"));
var RTTI_Extension_1 = require("./RTTI_Extension");
var RTTI_Element_1 = require("./RTTI_Element");
var RTTI_Range_1 = require("./RTTI_Range");
var RTTI_CodeableConcept_1 = require("./RTTI_CodeableConcept");
var ObservationDefinition_QualifiedIntervalCategoryKind;
(function (ObservationDefinition_QualifiedIntervalCategoryKind) {
    ObservationDefinition_QualifiedIntervalCategoryKind["_reference"] = "reference";
    ObservationDefinition_QualifiedIntervalCategoryKind["_critical"] = "critical";
    ObservationDefinition_QualifiedIntervalCategoryKind["_absolute"] = "absolute";
})(ObservationDefinition_QualifiedIntervalCategoryKind = exports.ObservationDefinition_QualifiedIntervalCategoryKind || (exports.ObservationDefinition_QualifiedIntervalCategoryKind = {}));
var ObservationDefinition_QualifiedIntervalGenderKind;
(function (ObservationDefinition_QualifiedIntervalGenderKind) {
    ObservationDefinition_QualifiedIntervalGenderKind["_male"] = "male";
    ObservationDefinition_QualifiedIntervalGenderKind["_female"] = "female";
    ObservationDefinition_QualifiedIntervalGenderKind["_other"] = "other";
    ObservationDefinition_QualifiedIntervalGenderKind["_unknown"] = "unknown";
})(ObservationDefinition_QualifiedIntervalGenderKind = exports.ObservationDefinition_QualifiedIntervalGenderKind || (exports.ObservationDefinition_QualifiedIntervalGenderKind = {}));
var EnumType_1 = require("../../EnumType");
exports.RTTI_ObservationDefinition_QualifiedInterval = t.recursion('IObservationDefinition_QualifiedInterval', function () {
    return t.partial({
        id: t.string,
        extension: t.array(RTTI_Extension_1.RTTI_Extension),
        modifierExtension: t.array(RTTI_Extension_1.RTTI_Extension),
        category: EnumType_1.createEnumType(ObservationDefinition_QualifiedIntervalCategoryKind, 'ObservationDefinition_QualifiedIntervalCategoryKind'),
        _category: RTTI_Element_1.RTTI_Element,
        range: RTTI_Range_1.RTTI_Range,
        context: RTTI_CodeableConcept_1.RTTI_CodeableConcept,
        appliesTo: t.array(RTTI_CodeableConcept_1.RTTI_CodeableConcept),
        gender: EnumType_1.createEnumType(ObservationDefinition_QualifiedIntervalGenderKind, 'ObservationDefinition_QualifiedIntervalGenderKind'),
        _gender: RTTI_Element_1.RTTI_Element,
        age: RTTI_Range_1.RTTI_Range,
        gestationalAge: RTTI_Range_1.RTTI_Range,
        condition: t.string,
        _condition: RTTI_Element_1.RTTI_Element
    });
});
