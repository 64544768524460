import {Card, Tabs, Typography} from 'antd';
import {useLocation, useNavigate} from 'react-router-dom';
import {UserUX} from '../../components/users/users-ux';
import {OrgAdminUserUX} from '../../components/users/org-admin-users-ux';
import {useState} from 'react';
import React from 'react';
import {AbilityContext} from '../../services/roles/ability-context';

const {TabPane} = Tabs;
const {Title, Paragraph, Text} = Typography;

export const UserManagementView = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(location.pathname.split('/')[1]);
  const ability = React.useContext(AbilityContext);

  return (
    <>
      <Title level={1} style={{color: 'white'}}>
        Users
      </Title>
      <Card>
        <Tabs
          activeKey={activeTab}
          onChange={(newActiveTab) => {
            navigate(`/${newActiveTab}`);
            return setActiveTab(newActiveTab);
          }}
        >
          {ability.can('view', 'orgAdminUsers') && (
            <TabPane tab="Console Users" key="orgadminusers">
              <OrgAdminUserUX forcePage="search" disableWrapper />
            </TabPane>
          )}
          {ability.can('view', 'clinicUsers') && (
            <TabPane tab="Clinic Users" key="users">
              <UserUX forcePage="search" disableWrapper />
            </TabPane>
          )}
        </Tabs>
      </Card>
    </>
  );
};
