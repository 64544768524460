import {Typography} from 'antd';
import React from 'react';
import {FHIRTable} from '../fhir-table';

const {Title} = Typography;

export const AddressesWidget = (props) => {
  const {patient} = props;

  return (
    <>
      <Title level={4}>Addresses</Title>

      <FHIRTable
        fhirResource={'address'}
        fixedFilters={{patientId: patient.id}}
        hideSearch
        mode="table"
        columns={columns}
        size="small"
        style={{width: '100%'}}
      />
    </>
  );
};

export const columns = [
  {
    title: 'Unit Number',
    dataIndex: 'unitNumber',
    key: 'unitNumber',
  },
  {
    title: 'Street',
    dataIndex: 'street',
    key: 'street',
  },
  {
    title: 'City',
    dataIndex: 'city',
    key: 'city',
  },
  {
    title: 'Province',
    dataIndex: 'province',
    key: 'province',
  },
  {
    title: 'Country',
    dataIndex: 'country',
    key: 'country',
  },
  {
    title: 'Postal Code',
    dataIndex: 'postalCode',
    key: 'postalCode',
  },
  {
    title: 'Effective From',
    dataIndex: 'effectiveFrom',
    key: 'effectiveFrom',
  },
  {
    title: 'Effective To',
    dataIndex: 'effectiveTo',
    key: 'effectiveTo',
  },
];
