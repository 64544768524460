import React from 'react';
import {Row} from 'reactstrap';
import {useUrlData} from '../util';
import {BFCard} from './card';

export const LandingPanel = () => {
  const {config, lang, theme} = useUrlData();

  return (
    <div>
      <h1 style={{color: theme.primaryColor, marginBottom: 5}}>{config.bookingFormTitle[lang]}</h1>
      <Row>
        <div
          style={{
            height: 4,
            backgroundColor: theme.primaryColor,
            width: '100%',
          }}
        />
      </Row>
      <div
        className="markdown-field"
        dangerouslySetInnerHTML={{__html: config.bookingFormContent[lang]}}
      />
    </div>
  );
};
