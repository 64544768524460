"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_Expression = exports.ExpressionLanguageKind = void 0;
var t = __importStar(require("io-ts"));
var RTTI_Extension_1 = require("./RTTI_Extension");
var RTTI_Element_1 = require("./RTTI_Element");
var RTTI_id_1 = require("../Scalar/RTTI_id");
var RTTI_uri_1 = require("../Scalar/RTTI_uri");
var ExpressionLanguageKind;
(function (ExpressionLanguageKind) {
    ExpressionLanguageKind["_textCql"] = "text/cql";
    ExpressionLanguageKind["_textFhirpath"] = "text/fhirpath";
    ExpressionLanguageKind["_applicationXFhirQuery"] = "application/x-fhir-query";
})(ExpressionLanguageKind = exports.ExpressionLanguageKind || (exports.ExpressionLanguageKind = {}));
var EnumType_1 = require("../../EnumType");
exports.RTTI_Expression = t.recursion('IExpression', function () {
    return t.partial({
        id: t.string,
        extension: t.array(RTTI_Extension_1.RTTI_Extension),
        description: t.string,
        _description: RTTI_Element_1.RTTI_Element,
        name: RTTI_id_1.RTTI_id,
        _name: RTTI_Element_1.RTTI_Element,
        language: EnumType_1.createEnumType(ExpressionLanguageKind, 'ExpressionLanguageKind'),
        _language: RTTI_Element_1.RTTI_Element,
        expression: t.string,
        _expression: RTTI_Element_1.RTTI_Element,
        reference: RTTI_uri_1.RTTI_uri,
        _reference: RTTI_Element_1.RTTI_Element
    });
});
