"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_Questionnaire_Item = exports.Questionnaire_ItemEnableBehaviorKind = exports.Questionnaire_ItemTypeKind = void 0;
var t = __importStar(require("io-ts"));
var RTTI_Extension_1 = require("./RTTI_Extension");
var RTTI_Element_1 = require("./RTTI_Element");
var RTTI_uri_1 = require("../Scalar/RTTI_uri");
var RTTI_Coding_1 = require("./RTTI_Coding");
var RTTI_Questionnaire_EnableWhen_1 = require("./RTTI_Questionnaire_EnableWhen");
var RTTI_integer_1 = require("../Scalar/RTTI_integer");
var RTTI_canonical_1 = require("../Scalar/RTTI_canonical");
var RTTI_Questionnaire_AnswerOption_1 = require("./RTTI_Questionnaire_AnswerOption");
var RTTI_Questionnaire_Initial_1 = require("./RTTI_Questionnaire_Initial");
var Questionnaire_ItemTypeKind;
(function (Questionnaire_ItemTypeKind) {
    Questionnaire_ItemTypeKind["_group"] = "group";
    Questionnaire_ItemTypeKind["_display"] = "display";
    Questionnaire_ItemTypeKind["_boolean"] = "boolean";
    Questionnaire_ItemTypeKind["_decimal"] = "decimal";
    Questionnaire_ItemTypeKind["_integer"] = "integer";
    Questionnaire_ItemTypeKind["_date"] = "date";
    Questionnaire_ItemTypeKind["_dateTime"] = "dateTime";
    Questionnaire_ItemTypeKind["_time"] = "time";
    Questionnaire_ItemTypeKind["_string"] = "string";
    Questionnaire_ItemTypeKind["_text"] = "text";
    Questionnaire_ItemTypeKind["_url"] = "url";
    Questionnaire_ItemTypeKind["_choice"] = "choice";
    Questionnaire_ItemTypeKind["_openChoice"] = "open-choice";
    Questionnaire_ItemTypeKind["_attachment"] = "attachment";
    Questionnaire_ItemTypeKind["_reference"] = "reference";
    Questionnaire_ItemTypeKind["_quantity"] = "quantity";
})(Questionnaire_ItemTypeKind = exports.Questionnaire_ItemTypeKind || (exports.Questionnaire_ItemTypeKind = {}));
var Questionnaire_ItemEnableBehaviorKind;
(function (Questionnaire_ItemEnableBehaviorKind) {
    Questionnaire_ItemEnableBehaviorKind["_all"] = "all";
    Questionnaire_ItemEnableBehaviorKind["_any"] = "any";
})(Questionnaire_ItemEnableBehaviorKind = exports.Questionnaire_ItemEnableBehaviorKind || (exports.Questionnaire_ItemEnableBehaviorKind = {}));
var EnumType_1 = require("../../EnumType");
exports.RTTI_Questionnaire_Item = t.recursion('IQuestionnaire_Item', function () {
    return t.partial({
        id: t.string,
        extension: t.array(RTTI_Extension_1.RTTI_Extension),
        modifierExtension: t.array(RTTI_Extension_1.RTTI_Extension),
        linkId: t.string,
        _linkId: RTTI_Element_1.RTTI_Element,
        definition: RTTI_uri_1.RTTI_uri,
        _definition: RTTI_Element_1.RTTI_Element,
        code: t.array(RTTI_Coding_1.RTTI_Coding),
        prefix: t.string,
        _prefix: RTTI_Element_1.RTTI_Element,
        text: t.string,
        _text: RTTI_Element_1.RTTI_Element,
        type: EnumType_1.createEnumType(Questionnaire_ItemTypeKind, 'Questionnaire_ItemTypeKind'),
        _type: RTTI_Element_1.RTTI_Element,
        enableWhen: t.array(RTTI_Questionnaire_EnableWhen_1.RTTI_Questionnaire_EnableWhen),
        enableBehavior: EnumType_1.createEnumType(Questionnaire_ItemEnableBehaviorKind, 'Questionnaire_ItemEnableBehaviorKind'),
        _enableBehavior: RTTI_Element_1.RTTI_Element,
        required: t.boolean,
        _required: RTTI_Element_1.RTTI_Element,
        repeats: t.boolean,
        _repeats: RTTI_Element_1.RTTI_Element,
        readOnly: t.boolean,
        _readOnly: RTTI_Element_1.RTTI_Element,
        maxLength: RTTI_integer_1.RTTI_integer,
        _maxLength: RTTI_Element_1.RTTI_Element,
        answerValueSet: RTTI_canonical_1.RTTI_canonical,
        answerOption: t.array(RTTI_Questionnaire_AnswerOption_1.RTTI_Questionnaire_AnswerOption),
        initial: t.array(RTTI_Questionnaire_Initial_1.RTTI_Questionnaire_Initial),
        item: t.array(exports.RTTI_Questionnaire_Item)
    });
});
