import {R4} from '@ahryman40k/ts-fhir-types';
import * as Icons from '@ant-design/icons';
import {Button, Col, Input, Result, Row, Select, Table, Typography} from 'antd';
import {TableProps} from 'antd/lib/table';
import qs from 'querystring';
import React, {useEffect, useState, useCallback} from 'react';
import {debounce} from 'lodash';
import {useLocation, useNavigate} from 'react-router-dom';
import {FhirUtils} from '../../services/fhir';
import styles from './styles.module.css';
import {useDispatch} from 'react-redux';
import {getSearchTerm, persistSave} from '../../models/ui-temp';

const {Paragraph, Text} = Typography;

export type FHIRTableMode = 'search' | 'fhir' | 'table';

/**
 * FHIRTableProps.
 */
export interface FHIRTableProps extends TableProps<any> {
  /**
   * The baseURL that the FHIR table will query against.
   */
  baseUrl?: string;
  /**
   * The columns that should be shown in the table; if no columns are provided,
   *  default options will be chosen.
   */
  columns?: any;
  /** The FHIR resource type that the table will query against.
   * If this is provided the user will not be able to change the FHIR resource in FHIR mode
   */
  fhirResource?: string;
  /**
   * If provided, the table is locked in the selected mode. Either "search", "table", or "fhir".
   */
  label?: string;
  mode?: FHIRTableMode;
  /**
   * Determines whether a Create button is shown next to the search bar.
   * Has no effect if fhirResource is undefined.
   */
  showCreateButton?: boolean | (() => boolean);
  /**
   * Called when the user clicks the create button
   */
  onClickCreateButton?: () => void;
  /**
   * Create button component
   */
  CreateButton?: (props) => JSX.Element;
  /**
   * Indicates whether the primary search parameter dropdown should be shown on the left
   * side of the search bar.
   */
  showPrimarySearchParamDropdown?: boolean;
  /**
   * If provided will override the values that would otherwise be shown in the
   * primary search parameter field.
   */
  primarySearchParamOptions?: string[];
  /**
   * Indicates what the default primary search parameter should be.
   */
  defaultPrimarySearchParam?: string;
  /**
   * Idk what this is
   */
  showParams?: boolean;
  /**
   * Determines the default mode of the table if the table has multiple mode options.
   */
  defaultMode?: FHIRTableMode;
  /**
   * Overrides the default search bar.
   */
  searchBar?: (props: any) => JSX.Element;
  /**
   * Force the search results to be hidden.
   */
  hideResults?: boolean;
  /**
   * Hides the search bar
   */
  hideSearch?: boolean;
  /**
   * The default query that the table should perform. If filters are enabled, these will
   * be added on top of the default query.
   */
  defaultQuery?: string;
  /**
   * Function called when the user clicks on a row in the search results table.
   */
  onClickRow?: (item: any) => void;
  /**
   * This should probably be merged with default query
   */
  fixedFilters?: Filters | (() => Filters);

  newResourceRoute?: string;
  /**
   * Component to display when the table is empty
   */
  componentWhenEmpty?: React.ReactNode | (() => React.ReactNode);
  /**
   * Function called when data for the table is loaded
   */
  onDataLoad?: (data: any) => void;
  /**
   * Trigger re-query to update the data
   */
  triggerRefresh?: any;
  /**
   * Do not persist search term
   */
  noPersistSearchTerm?: boolean;
  responseTransformer?: (res) => any;

  expandable?: any;
  expandedRowKeys?: string[];
  createButtonTitle?: string;
  debounceTime?: number;
  hidePagination?: boolean;
  /**
   * The amount of margin space on the bottom of the table.
   */
  marginBottom?: number | string;
}

export interface Filters {
  [key: string]: string | string[];
}

/**
 * FHIRTable
 * */
export const FHIRTable = (props: FHIRTableProps) => {
  const {
    baseUrl = '',
    showCreateButton = false,
    showPrimarySearchParamDropdown = false,
    fhirResource = 'Patient',
    onClickCreateButton,
    responseTransformer,
    expandable,
    expandedRowKeys,
    createButtonTitle,
    noPersistSearchTerm,
    rowKey,
    hidePagination,
    marginBottom = 20,
  } = props;

  const {key} = useLocation();
  // const client = useMemo(() => FhirUtils.useAxiosClient(), []);
  const persistedTerm: string = key && getSearchTerm(key)?.value;
  const client = FhirUtils.useAxiosClient();
  const {columns, onClickRow, fixedFilters, onDataLoad, CreateButton, triggerRefresh} = props;
  const newResourceRoute = props.newResourceRoute || `/${props.fhirResource}s/new`;

  // State: Search mode - set to props.searchMode then props.defaultTableMode which defaults to 'basic'
  const defaultMode: FHIRTableMode = props.defaultMode || 'search';
  const [mode, setMode] = useState<FHIRTableMode>(props.mode || defaultMode);
  const modeOptions = props.mode ? [props.mode] : (['search', 'table', 'fhir'] as FHIRTableMode[]);

  // State: BasicSearchParameter - This is the parameter to be set in the
  // selector on the left side of the search input
  const [basicSearchParameter, setBasicSearchParameter] = useState(props.defaultPrimarySearchParam);

  // State: Text in the search bar
  const [searchTerm, setSearchTerm] = React.useState(persistedTerm || props.defaultQuery || '');

  // State: filters
  const [filters, setFilters] = React.useState<Filters>({});

  // State: pageSize
  const defaultPageSize = 25;
  const [pageSize, setPageSize] = React.useState(defaultPageSize);
  const [currentTablePage, setCurrentTablePage] = React.useState(1);

  //State: sorting by column
  const [sorting, setSorting] = React.useState({
    sortColumn: undefined,
    sortOrder: undefined,
  });

  const defaultColumnFilters = {};
  for (const col of columns) {
    if (col.defaultFilteredValues) {
      defaultColumnFilters[col.key] = col.defaultFilteredValues;
    }
  }

  //State: Column filters
  const [columnFilter, setColumnFilter] = React.useState<any>(defaultColumnFilters);

  useEffect(() => {
    setColumnFilter(defaultColumnFilters);
  }, [columns]);

  const {sortColumn, sortOrder} = sorting;

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Partial<R4.IBundle | R4.IOperationOutcome>>({
    total: 0,
    entry: [],
  });

  const onChangeMode = (newMode) => {
    if (newMode === 'fhir' && mode !== 'fhir') {
      setSearchTerm(qs.stringify(filters));
      setFilters({});
    }

    if (newMode !== 'fhir' && mode === 'fhir') {
      const filters = parseFhirQuery(searchTerm);
      // @ts-ignore
      setFilters(filters);
      setSearchTerm('');
    }

    setMode(newMode);
  };

  //Handles changes on search bar
  const handleSearchChange = (pagination) => {
    const {pageSize, current} = pagination;
    setPageSize(pageSize);
    setCurrentTablePage(current);
  };

  //Handles changes to sort columns and pagination
  const handleTableChange = (pagination, filters, sorter) => {
    const {pageSize, current} = pagination;
    const sortField = sorter.field;
    let sortDirection = sorter.order;
    if (sorter.order === 'ascend') {
      sortDirection = 'ASC';
    } else if (sorter.order === 'descend') {
      sortDirection = 'DESC';
    }
    setPageSize(pageSize);
    setCurrentTablePage(current);
    setSorting({
      sortColumn: sortField,
      sortOrder: sortDirection,
    });
    if (Object.values(filters).find((f: any) => !!f?.length)) {
      for (const col of columns) {
        if (col.storeFilterValues && filters[col.dataIndex]) {
          col.storeFilterValues(filters[col.dataIndex]);
        }
      }
      setColumnFilter(filters);
    } else {
      for (const col of columns) {
        if (col.storeFilterValues) {
          col.storeFilterValues([]);
        }
      }
      setColumnFilter(defaultColumnFilters);
    }
  };

  const query = constructQuery(
    mode,
    fhirResource,
    pageSize,
    currentTablePage,
    searchTerm,
    basicSearchParameter,
    filters,
    fixedFilters,
    sortColumn,
    sortOrder,
    columnFilter
  );

  useEffect(() => {
    if ((mode === 'search' || mode === 'fhir') && searchTerm.length === 0) return;

    // FixedFilters should not display list before filter is load
    if (fixedFilters && !(typeof fixedFilters === 'function')) {
      for (const filter in fixedFilters) {
        if (filter != '_organizationId' && !fixedFilters[filter]) return;
      }
    }

    setLoading(true);

    client!
      .get(query)
      .then((res) => {
        const transformedResponse = responseTransformer ? responseTransformer(res.data) : res.data;
        setData(transformedResponse);
        if (onDataLoad) onDataLoad(res.data);
      })
      .catch((err) => {
        alert(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [query, mode, searchTerm, client, triggerRefresh]);

  let resources: any[] =
    data && data.resourceType === 'Bundle' && data.total
      ? data.entry!.map((entry) => entry.resource)
      : [];
  if (fhirResource === 'Patient') {
    resources.sort((a, b) => {
      return a.fullName.toLowerCase().localeCompare(b.fullName.toLowerCase());
    });
  }
  if (fhirResource === 'form-submissions') {
    resources.sort((a, b) => {
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    });
  }
  const total = data.resourceType === 'Bundle' ? data.total : 0;
  const isError = data.resourceType === 'OperationOutcome';
  const hideTable = query === '' || props.hideResults;

  return (
    <div style={{marginBottom: marginBottom}}>
      {props.hideSearch ? null : props.searchBar ? (
        props.searchBar({
          searchTerm,
          setSearchTerm,
        })
      ) : (
        <div style={{marginBottom: 15}}>
          <SearchTableButtonBar
            {...props}
            query={query}
            searchTerm={searchTerm}
            setQuery={setSearchTerm}
            loading={loading}
            modeOptions={modeOptions}
            mode={mode}
            setMode={onChangeMode}
            basicSearchParameter={basicSearchParameter}
            setBasicSearchParameter={setBasicSearchParameter}
            showPrimarySearchParamDropdown={showPrimarySearchParamDropdown}
            baseURL={client.defaults.baseURL}
            showCreateButton={
              typeof showCreateButton === 'function' ? showCreateButton() : showCreateButton
            }
            newResourceRoute={newResourceRoute}
            onClickCreateButton={onClickCreateButton}
            onSearchChange={() => handleSearchChange({pageSize, current: 1})}
            CreateButton={CreateButton}
            setLoading={setLoading}
            fhirResource={fhirResource}
            noPersistSearchTerm={noPersistSearchTerm}
          />
        </div>
      )}
      {isError ? <QueryError outcome={data} /> : null}
      {!isError && !hideTable ? (
        <Table
          {...props}
          dataSource={resources}
          loading={loading}
          columns={columns}
          scroll={{x: 400}}
          style={{width: '100%', overflow: 'hidden'}}
          onChange={handleTableChange}
          locale={{emptyText: props.componentWhenEmpty}}
          expandable={expandable}
          expandedRowKeys={expandedRowKeys}
          rowKey={rowKey ?? (({id}) => id)}
          pagination={
            hidePagination
              ? undefined
              : {
                  defaultPageSize: defaultPageSize,
                  current: currentTablePage,
                  showTotal: (total, range) => (
                    <span style={{color: 'white'}}>{`Total Items: ${total}`}</span>
                  ),
                  showSizeChanger: true,
                  pageSizeOptions: ['25', '50', '100', '200'],
                  total: total,
                }
          }
          onRow={(item) => ({
            onClick: () => (onClickRow ? onClickRow(item) : undefined),
          })}
        />
      ) : null}
    </div>
  );
};

export function constructQuery(
  mode,
  resourceType,
  pageSize,
  currentTablePage,
  searchTerm,
  basicSearchParameter,
  filters,
  fixedFilters: FHIRTableProps['fixedFilters'],
  sortColumn,
  sortOrder,
  columnFilter
) {
  const params = getSearchParams(
    mode,
    basicSearchParameter,
    pageSize,
    currentTablePage,
    searchTerm,
    filters,
    fixedFilters,
    sortColumn,
    sortOrder,
    columnFilter
  );
  const queryParamString = qs.stringify(params);
  switch (mode) {
    case 'search': {
      if (searchTerm.length) {
        return `${resourceType}?${queryParamString}`;
      } else {
        return '';
      }
    }
    case 'fhir': {
      if (searchTerm.length) {
        return `${resourceType}?${searchTerm}`;
      } else {
        return '';
      }
    }

    case 'table':
      return `${resourceType}?${queryParamString}`;
    default:
      return '';
  }
}

export const getSearchParams = (
  mode,
  basicSearchParameter,
  pageSize,
  currentTablePage,
  searchTerm,
  filters,
  fixedFilters: FHIRTableProps['fixedFilters'],
  sortColumn,
  sortOrder,
  columnFilter
) => {
  const _getpagesoffset = Math.max(currentTablePage - 1, 0) * pageSize;
  const pagingParams = {_total: 'accurate', _count: pageSize, _getpagesoffset};
  const orderingParams = {_sortField: sortColumn, _sortDirection: sortOrder};
  const safeFilters = Object.keys(filters).reduce((safeFilters, key) => {
    const value = filters[key];
    const filter = {[key]: value};
    if (value && value.length > 0) {
      return {...safeFilters, ...filter};
    } else {
      return safeFilters;
    }
  }, {});

  const fixedFiltersToUse = fixedFilters
    ? typeof fixedFilters === 'function'
      ? fixedFilters()
      : fixedFilters
    : {};

  // Only one column is allowed to use a filter
  const columnFilterParams =
    Object.keys(columnFilter).length > 0 && Object.values<string[]>(columnFilter)[0]
      ? {
          _filterField: Object.keys(columnFilter)[0],
          _filterValues: Object.values<string[]>(columnFilter)[0],
        }
      : {};

  if (mode === 'table') {
    return searchTerm.length
      ? {
          ...fixedFiltersToUse,
          ...safeFilters,
          ...pagingParams,
          ...orderingParams,
          ...columnFilterParams,
          [basicSearchParameter]: searchTerm,
        }
      : {
          ...fixedFiltersToUse,
          ...safeFilters,
          ...pagingParams,
          ...orderingParams,
          ...columnFilterParams,
        };
  } else if (mode === 'search') {
    return {
      ...safeFilters,
      ...pagingParams,
      ...orderingParams,
      ...fixedFiltersToUse,
      ...columnFilterParams,
      [basicSearchParameter]: searchTerm,
    };
  }
};

function parseFhirQuery(query) {
  query = query.charAt(0) === '?' ? query.substring(1) : query;
  return qs.parse(query);
}

interface SearchTableButtonBarProps extends FHIRTableProps {
  query: string;
  setQuery: any;
  loading: boolean;
  searchTerm: string;
  mode: FHIRTableMode;
  modeOptions: FHIRTableMode[];
  setMode: (_: any) => void;
  basicSearchParameter?: string;
  setBasicSearchParameter: (param: string) => void;
  showPrimarySearchParamDropdown: boolean;
  baseURL?: string;
  newResourceRoute: string;
  onClickCreateButton?: () => void;
  onSearchChange?: () => void;
  CreateButton?: (props) => JSX.Element;
  createButtonTitle?: string;
  debounceTime?: number;
  setLoading?: any;
}

const SearchTableButtonBar = (props: SearchTableButtonBarProps) => {
  const {
    query,
    fhirResource,
    label,
    loading,
    showCreateButton,
    mode,
    setMode,
    modeOptions,
    baseURL,
    searchTerm,
    showPrimarySearchParamDropdown,
    newResourceRoute,
    onClickCreateButton,
    onSearchChange,
    CreateButton,
    createButtonTitle,
    debounceTime,
    setLoading,
    noPersistSearchTerm,
  } = props;
  const navigate = useNavigate();
  const {key} = useLocation();
  const dispatch = useDispatch();
  const [nextValue, setNextValue] = useState(searchTerm);

  const defaultDebounceTime = 500;

  const debounceSave = useCallback(
    debounce((value) => props.setQuery(value), debounceTime || defaultDebounceTime),
    []
  );

  const onSearchParamChange = (event) => {
    if (onSearchChange) onSearchChange();
    const {value} = event.target;
    setNextValue(value);
    if (setLoading) setLoading(true);
    debounceSave(value);
    if (!noPersistSearchTerm) key && persistSave(dispatch, key, value);
  };

  const getIconForMode = (mode) => {
    switch (mode) {
      case 'search':
        return <Icons.SearchOutlined />;
      case 'table':
        return <Icons.SearchOutlined />;
      case 'fhir':
        return <Icons.SearchOutlined />;
      default:
        throw new Error('Error: unrecognized option');
    }
  };

  const getSearchPlaceholderForMode = (mode) => {
    switch (mode) {
      case 'table':
      case 'search':
        return label ? `${label} Search` : 'Search';

      case 'fhir':
        return 'FHIR Search';

      default:
        break;
    }
  };

  return (
    <Row gutter={[16, 16]}>
      <Col style={{flexGrow: 1}}>
        <div style={{display: 'flex'}}>
          <Input.Group compact style={{display: 'flex', flexGrow: 1, marginRight: 10}}>
            <Select
              defaultValue="1"
              style={{flexShrink: 1, flexGrow: 0}}
              size="large"
              value={mode}
              onSelect={setMode}
              showArrow={modeOptions.length > 1}
              disabled={modeOptions.length < 2}
            >
              {modeOptions.map((option) => (
                <Select.Option key={option} value={option}>
                  {getIconForMode(option)}
                </Select.Option>
              ))}
            </Select>

            {mode === 'search' || mode === 'table' ? (
              showPrimarySearchParamDropdown ? (
                <SearchParamSelector {...props} />
              ) : null
            ) : (
              <ResourceSelector {...props} />
            )}

            <Input
              className={styles.fhirTableSearchBar}
              placeholder={getSearchPlaceholderForMode(mode)}
              // loading={loading}
              // enterButton={false}
              size="large"
              allowClear
              value={nextValue}
              onChange={onSearchParamChange}
            />
            {/* <ParameterControl {...props} /> */}
            <Button
              type="primary"
              icon={<Icons.SearchOutlined />}
              size="large"
              style={{
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                paddingRight: 10,
                paddingLeft: 10,
              }}
            />
          </Input.Group>
          {showCreateButton ? (
            CreateButton ? (
              <CreateButton
                onClickCreateButton={() => {
                  onClickCreateButton ? onClickCreateButton() : navigate(newResourceRoute);
                }}
              />
            ) : (
              <Button
                type="primary"
                size="large"
                onClick={() => {
                  onClickCreateButton ? onClickCreateButton() : navigate(newResourceRoute);
                }}
              >
                <Icons.PlusOutlined />
                {createButtonTitle ? createButtonTitle : `New ${label}`}
              </Button>
            )
          ) : null}
        </div>
        {mode === 'fhir' ? <Text style={{fontSize: 11}}>{`${baseURL}/${query}`}</Text> : null}
      </Col>
    </Row>
  );
};

const SearchParamSelector = (props) => {
  const {fhirResource, basicSearchParams} = props;
  const client = FhirUtils.useClient();
  const [searchParams, setSearchParams] = useState<R4.ISearchParameter[]>(
    props.basicSearchParams || []
  );
  // Queries for the SearchParameters available for this resource
  React.useEffect(() => {
    // Don't query if the basicSearchParams prop is set
    if (basicSearchParams) {
      setSearchParams(basicSearchParams.map((param) => ({code: param})));
      return;
    }
    client?.get(`SearchParameter?base=${fhirResource}`).then((data: any) => {
      if (data.total > 0 && data.entry) {
        setSearchParams(data.entry.map((entry) => entry.resource));
      }
    });
  }, [client, fhirResource, basicSearchParams]);

  return (
    <Select
      value={props.basicSearchParameter}
      onChange={props.setBasicSearchParameter}
      dropdownMatchSelectWidth={false}
      size="large"
    >
      {searchParams.map((param) => (
        <Select.Option key={param.code} value={param.code!}>
          {param.code}
        </Select.Option>
      ))}
    </Select>
  );
};

const ResourceSelector = (props) => {
  const {fhirResource} = props;
  const [resources, setResources] = useState<string[]>([]);
  const client = FhirUtils.useAxiosClient();
  React.useEffect(() => {
    client?.get(`metadata?_summary=true`).then((res) => {
      setResources(res.data.rest[0].resource.map((entry) => entry.type));
    });
  }, [client]);

  return (
    <Select
      defaultValue={fhirResource}
      style={{flexShrink: 1, flexGrow: 0}}
      dropdownMatchSelectWidth={false}
      size="large"
    >
      {resources.map((resourceName) => (
        <Select.Option value={resourceName}>{resourceName}</Select.Option>
      ))}
    </Select>
  );
};

const QueryError = ({outcome}) => {
  return (
    <Result
      status="error"
      title="Query Failed"
      subTitle="The submitted query string was invalid. Please read the OperationOutcome below."
    >
      <div className="desc">
        <Paragraph>
          <Text
            strong
            style={{
              fontSize: 16,
            }}
          >
            OperationOutcome:
          </Text>
        </Paragraph>
        {outcome.issue.map((issue) => {
          return (
            <Paragraph>
              <Icons.CloseCircleOutlined className="site-result-demo-error-icon" />
              {issue.diagnostics}
            </Paragraph>
          );
        })}
      </div>
    </Result>
  );
};
