import {RequiredShortTextValidationRules, RequiredSpecialCharsValidationRules} from '../../services/ui-validation-rules';
import {RequiredValidationRuleId} from '../../validation/validation-rules/required-validation';
import {ClinicFormCustomFieldName} from '../clinic/clinic-form-ux-model';
import {FormUXFieldType} from '../form-ux/form-ux-models/form-ux-fields/form-ux-field-type';
import {FormUXModel} from '../form-ux/form-ux-models/form-ux-model';
import FieldSchemaModel from '../../models/field-schema';

export const OrganizationFormUXModel: FormUXModel = [
  {
    groupName: 'Organization Name',
    type: FormUXFieldType.grouped,
    inCreateModal: true,
    fields: [
      {
        name: 'nameEn',
        type: FormUXFieldType.text,
        label: 'Organization Name (English)',
        editable: true,
        inCreateModal: true,
        validationRules: RequiredShortTextValidationRules,
      },
      {
        name: 'nameFr',
        type: FormUXFieldType.text,
        label: 'Organization Name (French)',
        editable: true,
        inCreateModal: true,
        validationRules: RequiredShortTextValidationRules,
      },
    ],
  },
  {
    name: 'organizationCode',
    type: FormUXFieldType.text,
    label: 'Organization Code',
    inCreateModal: true,
    editable: true,
    validationRules: [
      ...RequiredShortTextValidationRules,
      ...RequiredSpecialCharsValidationRules
    ],
  },
  {
    name: 'type',
    type: FormUXFieldType.select,
    label: 'Organization Type',
    inCreateModal: true,
    editable: true,
    selectableValues: [ "Owner", "Org Type", "Organization", "Facility" ],
    validationRules: RequiredShortTextValidationRules,
    searchable: true,
  },
  {
    name: ClinicFormCustomFieldName.ParentOrganizationId,
    type: FormUXFieldType.custom,
    label: 'Parent Organization',
    editable: true,
    validationRules: [
      {
        validationRuleType: 'required',
      },
    ],
    inCreateModal: true,
  },
  {
    name: 'defaultBookingPageId',
    type: FormUXFieldType.custom,
    label: 'Default Booking Page',
    editable: true,
    validationRules: [],
    inCreateModal: true,
  },
  {
    name: 'fieldSchemaId',
    type: FormUXFieldType.SingleItemPicker,
    label: 'Field Schema',
    editable: true,
    validationRules: [
      {
        validationRuleType: RequiredValidationRuleId,
      },
    ],
    inCreateModal: true,
    model: FieldSchemaModel,
    placeholder: 'Select Field Schema',
    fromIdValue: true,
  },
];
