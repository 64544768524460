"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_HealthcareService = void 0;
var t = __importStar(require("io-ts"));
var RTTI_id_1 = require("../Scalar/RTTI_id");
var RTTI_Meta_1 = require("./RTTI_Meta");
var RTTI_uri_1 = require("../Scalar/RTTI_uri");
var RTTI_Element_1 = require("./RTTI_Element");
var RTTI_code_1 = require("../Scalar/RTTI_code");
var RTTI_Narrative_1 = require("./RTTI_Narrative");
var RTTI_ResourceList_1 = require("../Union/RTTI_ResourceList");
var RTTI_Extension_1 = require("./RTTI_Extension");
var RTTI_Identifier_1 = require("./RTTI_Identifier");
var RTTI_Reference_1 = require("./RTTI_Reference");
var RTTI_CodeableConcept_1 = require("./RTTI_CodeableConcept");
var RTTI_markdown_1 = require("../Scalar/RTTI_markdown");
var RTTI_Attachment_1 = require("./RTTI_Attachment");
var RTTI_ContactPoint_1 = require("./RTTI_ContactPoint");
var RTTI_HealthcareService_Eligibility_1 = require("./RTTI_HealthcareService_Eligibility");
var RTTI_HealthcareService_AvailableTime_1 = require("./RTTI_HealthcareService_AvailableTime");
var RTTI_HealthcareService_NotAvailable_1 = require("./RTTI_HealthcareService_NotAvailable");
exports.RTTI_HealthcareService = t.recursion('IHealthcareService', function () {
    return t.intersection([
        t.type({
            resourceType: t.literal('HealthcareService')
        }),
        t.partial({
            id: RTTI_id_1.RTTI_id,
            meta: RTTI_Meta_1.RTTI_Meta,
            implicitRules: RTTI_uri_1.RTTI_uri,
            _implicitRules: RTTI_Element_1.RTTI_Element,
            language: RTTI_code_1.RTTI_code,
            _language: RTTI_Element_1.RTTI_Element,
            text: RTTI_Narrative_1.RTTI_Narrative,
            contained: t.array(RTTI_ResourceList_1.RTTI_ResourceList),
            extension: t.array(RTTI_Extension_1.RTTI_Extension),
            modifierExtension: t.array(RTTI_Extension_1.RTTI_Extension),
            identifier: t.array(RTTI_Identifier_1.RTTI_Identifier),
            active: t.boolean,
            _active: RTTI_Element_1.RTTI_Element,
            providedBy: RTTI_Reference_1.RTTI_Reference,
            category: t.array(RTTI_CodeableConcept_1.RTTI_CodeableConcept),
            type: t.array(RTTI_CodeableConcept_1.RTTI_CodeableConcept),
            specialty: t.array(RTTI_CodeableConcept_1.RTTI_CodeableConcept),
            location: t.array(RTTI_Reference_1.RTTI_Reference),
            name: t.string,
            _name: RTTI_Element_1.RTTI_Element,
            comment: t.string,
            _comment: RTTI_Element_1.RTTI_Element,
            extraDetails: RTTI_markdown_1.RTTI_markdown,
            _extraDetails: RTTI_Element_1.RTTI_Element,
            photo: RTTI_Attachment_1.RTTI_Attachment,
            telecom: t.array(RTTI_ContactPoint_1.RTTI_ContactPoint),
            coverageArea: t.array(RTTI_Reference_1.RTTI_Reference),
            serviceProvisionCode: t.array(RTTI_CodeableConcept_1.RTTI_CodeableConcept),
            eligibility: t.array(RTTI_HealthcareService_Eligibility_1.RTTI_HealthcareService_Eligibility),
            program: t.array(RTTI_CodeableConcept_1.RTTI_CodeableConcept),
            characteristic: t.array(RTTI_CodeableConcept_1.RTTI_CodeableConcept),
            communication: t.array(RTTI_CodeableConcept_1.RTTI_CodeableConcept),
            referralMethod: t.array(RTTI_CodeableConcept_1.RTTI_CodeableConcept),
            appointmentRequired: t.boolean,
            _appointmentRequired: RTTI_Element_1.RTTI_Element,
            availableTime: t.array(RTTI_HealthcareService_AvailableTime_1.RTTI_HealthcareService_AvailableTime),
            notAvailable: t.array(RTTI_HealthcareService_NotAvailable_1.RTTI_HealthcareService_NotAvailable),
            availabilityExceptions: t.string,
            _availabilityExceptions: RTTI_Element_1.RTTI_Element,
            endpoint: t.array(RTTI_Reference_1.RTTI_Reference)
        })
    ]);
});
