import * as Icons from '@ant-design/icons';
import {Str} from '@canimmunize/tools';
import {Select, Spin} from 'antd';
import {SelectProps} from 'antd/es/select';
import axios from 'axios';
import {useField} from 'formik';
import debounce from 'lodash/debounce';
import React from 'react';

export interface DebounceSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType>, 'options' | 'children'> {
  fetchOptions: (search: string) => Promise<any>;
  debounceTimeout?: number;
}

function DebounceSelect<
  ValueType extends {key?: string; label: React.ReactNode; value: string | number} = any
>({fetchOptions, debounceTimeout = 800, ...props}: DebounceSelectProps) {
  const [fetching, setFetching] = React.useState(false);
  const [options, setOptions] = React.useState<ValueType[]>([]);
  const [error, setError] = React.useState<Error>();

  const fetchRef = React.useRef(0);

  const debounceFetcher = React.useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);

      if (value === '' || value.length < 4) return;
      setFetching(true);

      if (fetchId !== fetchRef.current) {
        // for fetch callback order
        return;
      }
      fetchOptions(value).then((data) => {
        if (data.success) {
          setError(undefined);
          setOptions(
            data.results.map((result) => ({
              label: result.civic_address_as_string,
              value: result.civicnumberid,
              data: result,
            }))
          );
        } else {
          setError(data.error);
        }

        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout, {leading: true});
  }, [fetchOptions, debounceTimeout]);

  return (
    <Select<ValueType>
      labelInValue
      showSearch
      allowClear
      filterOption={false}
      size="large"
      onSearch={debounceFetcher}
      notFoundContent={
        fetching ? (
          <Spin size="small" />
        ) : error ? (
          error.message
        ) : (
          'Start by entering an address (for example, 1723 Hollis St, Halifax) and then select the address from the options.'
        )
      }
      {...props}
      options={options}
    />
  );
}

// Usage of DebounceSelect
interface AddressAPIResult {
  error?: {
    code: number;
    details: string[];
    message: string;
  };
  results: {
    civic_address_as_string: string;
    civicnumberid: string;
    esri_point: {
      x: number;
      y: number;
    };
  }[];
}

async function fetchAddresses(text: string): Promise<AddressAPIResult> {
  const searchParam = encodeURIComponent(text);
  const url = `https://nsgiapp.novascotia.ca/wsf_civicaddress_search/CivicAddress_Search.svc/civicaddress_suggest_search?search_str=${searchParam}&max_recs=30&reqd_wkid=2961&tkey=j33fE2pQtRaBhpWq&inclAlias=true`;
  return axios.get(url).then((res) => res.data);
}

export const AddressSelect = (props) => {
  const [_, __, helpers] = useField(props);

  const onChange = (newValue, option: any) => {
    if (newValue) helpers.setValue({...newValue, data: option?.data});
  };
  return (
    <DebounceSelect
      {...props}
      value={props?.value}
      placeholder={Str('Ex. 1723 Hollis St, Halifax')}
      fetchOptions={fetchAddresses}
      onChange={props.onChange || onChange}
      onClear={() => helpers.setValue(undefined)}
      style={{width: '100%'}}
      suffixIcon={<Icons.SearchOutlined />}
    />
  );
};
