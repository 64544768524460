import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import {Descriptions, Space, Tabs, Tag, Typography} from 'antd';
import moment from 'moment';
import React from 'react';
import {useSelector} from 'react-redux';
import {useOrganizationWithId} from '../../models/organizations';
import {FhirUtils} from '../../services/fhir';
import {AbilityContext} from '../../services/roles/ability-context';
import {RootState} from '../../store';
import {formatPhoneNumber} from '../../util/helpers/phone';
import {processGender} from '../../util/helpers/processGender';
import {AddressesWidget} from './addresses-widget';
import {AppointmentsWidget} from './appointments-widget';
import {CohortsWidget} from './cohorts-widget';
import {ConnectionsWidget} from './connections-widget';
import {ConsentsWidget} from './consents-widget';
import {FlagsTable} from './flags/flags-table';
import {FormsWidget} from './forms-widget';
import {ImmsWidget} from './imms-widget';
import {NotesTable} from './notes/notes-table';
import {PatientSeriesCell} from './patient-series-cell';
import {TestsTable} from './patient-tests/tests-table';
import {RelatedPersonsWidget} from './related-persons-widget';
import {ContactPointsWidget} from './contact-points-widget';

const {Title} = Typography;
const {TabPane} = Tabs;

export const PatientItemPage = ({itemId}) => {
  const patient = useSelector((state: RootState) => state.patients.byId[itemId]);
  const ability = React.useContext(AbilityContext);
  const refreshTabFunctions = {};
  const [settings, setSettings] = React.useState({});

  const client = FhirUtils.useAxiosClient();

  const [immsCount, setImmsCount] = React.useState(-1);
  const [aefiCount, setAefiCount] = React.useState(-1);
  const [noteCount, setNoteCount] = React.useState(-1);
  const [apptCount, setApptCount] = React.useState(-1);
  const [cohortCount, setCohortCount] = React.useState(-1);
  const [connectionCount, setConnectionCount] = React.useState(-1);
  const [consentCount, setConsentCount] = React.useState(-1);
  const [formsCount, setFormsCount] = React.useState(-1);
  const [adminCount, setAdminCount] = React.useState(patient?.adminNoteCount);
  const [testCount, setTestCount] = React.useState(-1);
  const [flagCount, setFlagCount] = React.useState(-1);
  const [countLoaded, setCountLoaded] = React.useState(false);
  const [addressCount, setAddressCount] = React.useState(-1);
  const [contactPointCount, setContactPointCount] = React.useState(-1);
  const [relatedPersonCPCount, setRelatedPersonCPCount] = React.useState(-1);
  const [defaultTab, setDefaultTab] = React.useState({key: 'imms'});

  const [apptRefreshKey, setApptRefreshKey] = React.useState(false);

  /* Update counts on patient change */
  React.useEffect(() => {
    if (!patient) return;
    setImmsCount(patient.immCount);
    setAefiCount(patient.aefiNoteCount);
    setNoteCount(patient.noteCount);
    setApptCount(patient.apptCount);
    setCohortCount(patient.cohortCount);
    setConnectionCount(patient.connectionCount);
    setFormsCount(patient.formSubmissionCount);
    setAdminCount(patient.adminNoteCount);
    setTestCount(patient.testCount);
    setConsentCount(patient.consentCount);
    setAddressCount(patient.addressCount);
    setContactPointCount(patient.contactPointCount);
    setRelatedPersonCPCount(patient.relatedPersonCPCount);
    setFlagCount(patient.flagCount);
    setCountLoaded(true);
  }, [patient]);

  React.useEffect(() => {
    getGlobalSettings();
  }, []);

  async function getGlobalSettings() {
    // Get all public global settings
    const response = await client.get('/public/settings');
    setSettings(response.data);
  }

  if (!patient || !countLoaded) return null;

  const sharedAefiTable = (
    <NotesTable
      patient={patient}
      formTypes={['aefiNotes']}
      title="AEFI Notes"
      tableProps={{onDataLoad: (d) => setAefiCount(d.total)}}
    />
  );
  const sharedNotesTable = (
    <NotesTable
      formTypes={['!aefiNotes', '!adminNotes']}
      formGroups={['notes']}
      patient={patient}
      tableProps={{onDataLoad: (d) => setNoteCount(d.total)}}
    />
  );

  const title = (text, count) => (
    <>
      {text}
      {count > 0 && <sup style={{color: 'rgba(0,0,0,.5)'}}> {count}</sup>}
    </>
  );

  /* Tabs row */
  const PatientTabs = (props: {settings}) => {
    const enableContactPointAndAddress = settings['enableContactPointAndAddress']?.value === 'true';
    const enableRelatedPerson = settings['enableRelatedPerson']?.value === 'true';

    return (
      <Tabs
        defaultActiveKey={defaultTab.key}
        size="large"
        onChange={(key) => (defaultTab.key = key)}
      >
        {/* Immunizations Tab */}
        <TabPane tab={title('Vaccinations', immsCount)} key="imms">
          <ImmsWidget
            patient={patient}
            settings={settings}
            refreshAppointments={() => setApptRefreshKey(!apptRefreshKey)}
            refreshTabFunctions={refreshTabFunctions}
          />
        </TabPane>
        {/* Flags Tab */}
        {ability.can('view', 'patients', 'flagsWidget') && (
          <TabPane tab={title('Flags', flagCount)} key="flags">
            <FlagsTable
              patient={patient}
              tableProps={{
                onDataLoad: (d) => {
                  setFlagCount(
                    d.entry.filter(
                      (e) => !e.resource.endDate || moment().isBefore(e.resource.endDate)
                    ).length
                  );
                },
              }}
              refreshTabFunctions={refreshTabFunctions}
            />
          </TabPane>
        )}
        {enableContactPointAndAddress && (
          <>
            {/* Addresses Tab */}
            <TabPane tab={title('Addresses', addressCount)} key="addresses">
              <AddressesWidget patient={patient} />
            </TabPane>
            {/* ContactPoints Tab */}
            <TabPane tab={title('ContactPoints', contactPointCount)} key="contactPoints">
              <ContactPointsWidget patient={patient} />
            </TabPane>
          </>
        )}
        {/* RelatedPerson Tab */}
        {enableRelatedPerson && (
          <TabPane tab={title('RelatedPersons', relatedPersonCPCount)} key="relatedPersons">
            <RelatedPersonsWidget patient={patient} />
          </TabPane>
        )}
        {/* Tests Tab */}
        {ability.can('view', 'patients', 'testsWidget') && (
          <TabPane tab={title('Tests', testCount)} key="tests">
            <TestsTable
              patient={patient}
              title="Tests"
              tableProps={{onDataLoad: (d) => setTestCount(d.total)}}
            />
          </TabPane>
        )}
        {/* AEFI Tab */}
        {ability.can('view', 'patients', 'aefiWidget') && (
          <TabPane tab={title('AEFI', aefiCount)} key="aefiNotes">
            {sharedAefiTable}
          </TabPane>
        )}
        {/* Consents Tab */}
        {ability.can('view', 'patients', 'consentsWidget') && (
          <TabPane tab={title('Consents', consentCount)} key="consents">
            <ConsentsWidget patient={patient} />
          </TabPane>
        )}
        {/* Notes Tab */}
        {ability.can('view', 'patients', 'notesWidget') && (
          <TabPane tab={title('Notes', noteCount)} key="notes">
            {sharedNotesTable}
          </TabPane>
        )}
        {/* Appointments Tab */}
        {ability.can('view', 'patients', 'appointmentsWidget') && (
          <TabPane tab={title('Appointments', apptCount)} key="appointments">
            <AppointmentsWidget patient={patient} refreshKey={apptRefreshKey} />
          </TabPane>
        )}
        {/* Patients Tab */}
        {ability.can('view', 'patients', 'cohortsWidget') && (
          <TabPane tab={title('Cohorts', cohortCount)} key="cohorts">
            <CohortsWidget patient={patient} onDataLoad={(d) => setCohortCount(d.total)} />
          </TabPane>
        )}
        {/* Forms Tab */}
        {ability.can('view', 'patients', 'formsWidget') && (
          <TabPane tab={title('Forms', formsCount)} key="forms">
            <FormsWidget patient={patient} />
          </TabPane>
        )}
        {/* Fields Tab */}
        {/* }<TabPane tab="Fields" key="fields">
            <FieldsWidget patient={patient} />
          </TabPane> */}
        {ability.can('view', 'patients', 'adminWidget') && (
          <TabPane tab={title('Admin', adminCount)} key="admin">
            <NotesTable
              patient={patient}
              formTypes={['adminNotes']}
              title="Admin Notes"
              tableProps={{onDataLoad: (d) => setAdminCount(d.total)}}
            />
          </TabPane>
        )}
      </Tabs>
    );
  };

  return (
    <div>
      <PatientInfo {...{patient, aefiCount, cohortCount, settings}} />
      <br />
      <br />
      <PatientTabs {...{settings}} />
    </div>
  );
};

/* The static 'Patient Info' section. */
const PatientInfo = (props: {patient; aefiCount; cohortCount; settings}) => {
  const {patient, aefiCount, cohortCount, settings} = props;
  const gender = processGender(patient?.gender);
  const organization = useOrganizationWithId(patient?.organizationId);
  const showCovidStatusTag = settings['showCovidStatusTag']?.value === 'true';
  const showSeriesTag = settings['showSeriesTag']?.value === 'true';
  const enableContactPointAndAddress = settings['enableContactPointAndAddress']?.value === 'true';

  const CovidStatusTag = () => {
    return patient.covidRecommendationFivecast?.forecastStatus === 'COMPLETE' ? (
      <Tag color="success" icon={<CheckCircleOutlined />}>
        COVID-19 Complete
      </Tag>
    ) : patient.covidRecommendationFivecast?.forecastStatus === 'IN_PROGRESS' ? (
      <Tag color="processing" icon={<ClockCircleOutlined />}>
        Dose 1 Received/Dose 2 Required
      </Tag>
    ) : (
      <Tag icon={<CloseCircleOutlined />}>Unvaccinated</Tag>
    );
  };

  return (
    <div>
      <Space align="center" style={{marginBottom: 10}}>
        <Title level={4} style={{marginBottom: 0}}>
          Patient Info
        </Title>
        {patient.deceased && <Tag color="red">Deceased</Tag>}

        {/* AEFI Tag */}
        {aefiCount > 0 && <Tag color="orange">AEFI ({aefiCount})</Tag>}

        {/* Cohort Tag */}
        {cohortCount > 0 && (
          <Tag color="purple" icon={<TeamOutlined />}>
            {cohortCount} Cohort{cohortCount > 1 && 's'}
          </Tag>
        )}
      </Space>
      <Descriptions bordered size="small" column={{xxl: 3, xl: 3, lg: 2, md: 2, sm: 2, xs: 1}}>
        <Descriptions.Item label="First Name">{patient.firstName}</Descriptions.Item>
        <Descriptions.Item label="Last Name">{patient.lastName}</Descriptions.Item>
        <Descriptions.Item label="Middle Name">{patient.middleName}</Descriptions.Item>
        <Descriptions.Item label="Preferred Name">{patient.preferredName}</Descriptions.Item>
        <Descriptions.Item label="Date of Birth">
          {patient.birthdate
            ? `${moment(patient.birthdate).format('LL')} (${moment().diff(
                patient.birthdate,
                'years'
              )} years old)`
            : 'Unknown'}
        </Descriptions.Item>
        <Descriptions.Item label="Gender">{gender}</Descriptions.Item>
        <Descriptions.Item label="Pronoun">{patient.pronoun}</Descriptions.Item>
        <Descriptions.Item label="Health Card Number">
          {`${patient.hcn ? patient.hcn : ''} ${
            patient.hcn && patient.hcnType ? `(${patient.hcnType})` : ''
          }`}
        </Descriptions.Item>
        {/* <Descriptions.Item label="Department" span={12}>
            {props.values.department?.length ? props.values.department : 'N/A'}
          </Descriptions.Item> */}
        {/* <Descriptions.Item label="Patient ID">
          <Paragraph code copyable>
            {props.values.id}
          </Paragraph>
        </Descriptions.Item> */}

        {!enableContactPointAndAddress && (
          <>
            <Descriptions.Item label="Email">
              <a href={`mailto:${patient.email}`}>{patient.email}</a>
            </Descriptions.Item>
            <Descriptions.Item label="Phone">{formatPhoneNumber(patient.phone)}</Descriptions.Item>
            <Descriptions.Item label="Address">
              {`${patient.street ? patient.street : ''}${
                patient.unitNumber ? `, Unit/Apt ${patient.unitNumber},` : ''
              } ${patient.city ? patient.city : ''} ${
                patient.postalCode ? patient.postalCode : ''
              }`}
            </Descriptions.Item>
          </>
        )}
        <Descriptions.Item label="Managing Organization">
          {organization?.name?.en}
        </Descriptions.Item>
        {/* <Descriptions.Item label="CANImmunize Account" span={12}>
            <Paragraph code copyable>
              {props.values.canimmunizeAccountId}
            </Paragraph>
          </Descriptions.Item>
          <Descriptions.Item label="CANImmunize Record" span={12}>
            <Paragraph code copyable>
              {props.values.canimmunizeRecordId}
            </Paragraph>
          </Descriptions.Item> */}
      </Descriptions>
    </div>
  );
};
