import {
  RequiredShortTextValidationRules,
  RequiredValidationRule,
  ShortTextValidationRules,
} from '../../services/ui-validation-rules';
import {FormUXFieldType} from '../form-ux/form-ux-models/form-ux-fields/form-ux-field-type';
import {FormUXModel} from '../form-ux/form-ux-models/form-ux-model';

export enum UserFormCustomFieldName {
  OrganizationId = 'organizationId',
  UserAccess = 'accountId',
  LicenseBody = 'licenseBody',
}

export const UserFormUXModel: FormUXModel = [
  {
    groupName: 'Name',
    type: FormUXFieldType.flex,
    inCreateModal: true,
    fields: [
      {
        name: 'firstName',
        type: FormUXFieldType.text,
        label: 'First Name',
        editable: true,
        inCreateModal: true,
        validationRules: RequiredShortTextValidationRules,
      },
      {
        name: 'lastName',
        type: FormUXFieldType.text,
        label: 'Last Name',
        editable: true,
        inCreateModal: true,
        validationRules: RequiredShortTextValidationRules,
      },
    ],
  },
  {
    groupName: 'Info',
    type: FormUXFieldType.flex,
    inCreateModal: true,
    fields: [
      {
        name: 'email',
        type: FormUXFieldType.text,
        label: 'Email',
        editable: true,
        inCreateModal: true,
        validationRules: RequiredShortTextValidationRules,
      },
      {
        name: 'role',
        type: FormUXFieldType.select,
        label: 'Role',
        inCreateModal: true,
        editable: true,
        selectableValues: ['immunizer', 'greeter'],
        validationRules: RequiredShortTextValidationRules,
      },
    ],
  },
  {
    groupName: 'License',
    type: FormUXFieldType.flex,
    inCreateModal: true,
    fields: [
      {
        name: UserFormCustomFieldName.LicenseBody,
        type: FormUXFieldType.custom,
        label: 'License Body',
        fetchType: 'license-body',
        generateLabel: (l) => l.name,
        generateValue: (l) => l.id,
        editable: true,
        validationRules: RequiredValidationRule,
        showSearch: true
      },
      {
        name: 'licenseNumber',
        type: FormUXFieldType.text,
        label: 'License Number',
        editable: true,
        validationRules: RequiredShortTextValidationRules,
      },
    ],
  },
  {
    name: UserFormCustomFieldName.OrganizationId,
    type: FormUXFieldType.custom,
    label: 'Organization',
    editable: true,
    inCreateModal: true,
    validationRules: RequiredShortTextValidationRules,
  },
  {
    name: 'approver',
    type: FormUXFieldType.text,
    label: 'Approver',
    editable: true,
    inCreateModal: true,
    validationRules: ShortTextValidationRules,
  },
  {
    name: UserFormCustomFieldName.UserAccess,
    type: FormUXFieldType.custom,
    label: 'Enable Clinic Access',
    editable: true,
    inEditMode: false,
    inCreateModal: true,
    validationRules: [],
  },
];
