import {AbstractValidationRule} from './abstract-validation-rule';

export const NumberValidationRuleId = 'number';

export interface NumberValidationRule extends AbstractValidationRule {
  validationRuleType: typeof NumberValidationRuleId;
}

/**
 * Validation to check if a value is a number.
 *
 * @export
 * @param {*} value
 * @returns {boolean} True if validation passed. False otherwise.
 */
export function validateNumberRule(value: any): boolean {
  return !isNaN(value);
}
