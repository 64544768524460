"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_ClaimResponse_AddItem = void 0;
var t = __importStar(require("io-ts"));
var RTTI_Extension_1 = require("./RTTI_Extension");
var RTTI_positiveInt_1 = require("../Scalar/RTTI_positiveInt");
var RTTI_Element_1 = require("./RTTI_Element");
var RTTI_Reference_1 = require("./RTTI_Reference");
var RTTI_CodeableConcept_1 = require("./RTTI_CodeableConcept");
var RTTI_Period_1 = require("./RTTI_Period");
var RTTI_Address_1 = require("./RTTI_Address");
var RTTI_Quantity_1 = require("./RTTI_Quantity");
var RTTI_Money_1 = require("./RTTI_Money");
var RTTI_decimal_1 = require("../Scalar/RTTI_decimal");
var RTTI_ClaimResponse_Adjudication_1 = require("./RTTI_ClaimResponse_Adjudication");
var RTTI_ClaimResponse_Detail1_1 = require("./RTTI_ClaimResponse_Detail1");
exports.RTTI_ClaimResponse_AddItem = t.recursion('IClaimResponse_AddItem', function () {
    return t.intersection([
        t.type({
            productOrService: RTTI_CodeableConcept_1.RTTI_CodeableConcept,
            adjudication: t.array(RTTI_ClaimResponse_Adjudication_1.RTTI_ClaimResponse_Adjudication)
        }),
        t.partial({
            id: t.string,
            extension: t.array(RTTI_Extension_1.RTTI_Extension),
            modifierExtension: t.array(RTTI_Extension_1.RTTI_Extension),
            itemSequence: t.array(RTTI_positiveInt_1.RTTI_positiveInt),
            _itemSequence: t.array(RTTI_Element_1.RTTI_Element),
            detailSequence: t.array(RTTI_positiveInt_1.RTTI_positiveInt),
            _detailSequence: t.array(RTTI_Element_1.RTTI_Element),
            subdetailSequence: t.array(RTTI_positiveInt_1.RTTI_positiveInt),
            _subdetailSequence: t.array(RTTI_Element_1.RTTI_Element),
            provider: t.array(RTTI_Reference_1.RTTI_Reference),
            modifier: t.array(RTTI_CodeableConcept_1.RTTI_CodeableConcept),
            programCode: t.array(RTTI_CodeableConcept_1.RTTI_CodeableConcept),
            servicedDate: t.string,
            _servicedDate: RTTI_Element_1.RTTI_Element,
            servicedPeriod: RTTI_Period_1.RTTI_Period,
            locationCodeableConcept: RTTI_CodeableConcept_1.RTTI_CodeableConcept,
            locationAddress: RTTI_Address_1.RTTI_Address,
            locationReference: RTTI_Reference_1.RTTI_Reference,
            quantity: RTTI_Quantity_1.RTTI_Quantity,
            unitPrice: RTTI_Money_1.RTTI_Money,
            factor: RTTI_decimal_1.RTTI_decimal,
            _factor: RTTI_Element_1.RTTI_Element,
            net: RTTI_Money_1.RTTI_Money,
            bodySite: RTTI_CodeableConcept_1.RTTI_CodeableConcept,
            subSite: t.array(RTTI_CodeableConcept_1.RTTI_CodeableConcept),
            noteNumber: t.array(RTTI_positiveInt_1.RTTI_positiveInt),
            _noteNumber: t.array(RTTI_Element_1.RTTI_Element),
            detail: t.array(RTTI_ClaimResponse_Detail1_1.RTTI_ClaimResponse_Detail1)
        })
    ]);
});
