"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_CarePlan_Detail = exports.CarePlan_DetailStatusKind = void 0;
var t = __importStar(require("io-ts"));
var RTTI_Extension_1 = require("./RTTI_Extension");
var RTTI_code_1 = require("../Scalar/RTTI_code");
var RTTI_Element_1 = require("./RTTI_Element");
var RTTI_canonical_1 = require("../Scalar/RTTI_canonical");
var RTTI_uri_1 = require("../Scalar/RTTI_uri");
var RTTI_CodeableConcept_1 = require("./RTTI_CodeableConcept");
var RTTI_Reference_1 = require("./RTTI_Reference");
var RTTI_Timing_1 = require("./RTTI_Timing");
var RTTI_Period_1 = require("./RTTI_Period");
var RTTI_Quantity_1 = require("./RTTI_Quantity");
var CarePlan_DetailStatusKind;
(function (CarePlan_DetailStatusKind) {
    CarePlan_DetailStatusKind["_notStarted"] = "not-started";
    CarePlan_DetailStatusKind["_scheduled"] = "scheduled";
    CarePlan_DetailStatusKind["_inProgress"] = "in-progress";
    CarePlan_DetailStatusKind["_onHold"] = "on-hold";
    CarePlan_DetailStatusKind["_completed"] = "completed";
    CarePlan_DetailStatusKind["_cancelled"] = "cancelled";
    CarePlan_DetailStatusKind["_stopped"] = "stopped";
    CarePlan_DetailStatusKind["_unknown"] = "unknown";
    CarePlan_DetailStatusKind["_enteredInError"] = "entered-in-error";
})(CarePlan_DetailStatusKind = exports.CarePlan_DetailStatusKind || (exports.CarePlan_DetailStatusKind = {}));
var EnumType_1 = require("../../EnumType");
exports.RTTI_CarePlan_Detail = t.recursion('ICarePlan_Detail', function () {
    return t.partial({
        id: t.string,
        extension: t.array(RTTI_Extension_1.RTTI_Extension),
        modifierExtension: t.array(RTTI_Extension_1.RTTI_Extension),
        kind: RTTI_code_1.RTTI_code,
        _kind: RTTI_Element_1.RTTI_Element,
        instantiatesCanonical: t.array(RTTI_canonical_1.RTTI_canonical),
        instantiatesUri: t.array(RTTI_uri_1.RTTI_uri),
        _instantiatesUri: t.array(RTTI_Element_1.RTTI_Element),
        code: RTTI_CodeableConcept_1.RTTI_CodeableConcept,
        reasonCode: t.array(RTTI_CodeableConcept_1.RTTI_CodeableConcept),
        reasonReference: t.array(RTTI_Reference_1.RTTI_Reference),
        goal: t.array(RTTI_Reference_1.RTTI_Reference),
        status: EnumType_1.createEnumType(CarePlan_DetailStatusKind, 'CarePlan_DetailStatusKind'),
        _status: RTTI_Element_1.RTTI_Element,
        statusReason: RTTI_CodeableConcept_1.RTTI_CodeableConcept,
        doNotPerform: t.boolean,
        _doNotPerform: RTTI_Element_1.RTTI_Element,
        scheduledTiming: RTTI_Timing_1.RTTI_Timing,
        scheduledPeriod: RTTI_Period_1.RTTI_Period,
        scheduledString: t.string,
        _scheduledString: RTTI_Element_1.RTTI_Element,
        location: RTTI_Reference_1.RTTI_Reference,
        performer: t.array(RTTI_Reference_1.RTTI_Reference),
        productCodeableConcept: RTTI_CodeableConcept_1.RTTI_CodeableConcept,
        productReference: RTTI_Reference_1.RTTI_Reference,
        dailyAmount: RTTI_Quantity_1.RTTI_Quantity,
        quantity: RTTI_Quantity_1.RTTI_Quantity,
        description: t.string,
        _description: RTTI_Element_1.RTTI_Element
    });
});
