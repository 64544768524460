import {Typography} from 'antd';
import React from 'react';
import {HcpLookupTable} from './hcp-table';

const {Title} = Typography;

export const HcpLookup = () => {
  return (
    <>
      <Title level={1} style={{color: 'white'}}>
        Patient Lookup
      </Title>
      <HcpLookupTable />
    </>
  );
};
