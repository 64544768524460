"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_AuditEvent = exports.AuditEventOutcomeKind = exports.AuditEventActionKind = void 0;
var t = __importStar(require("io-ts"));
var RTTI_id_1 = require("../Scalar/RTTI_id");
var RTTI_Meta_1 = require("./RTTI_Meta");
var RTTI_uri_1 = require("../Scalar/RTTI_uri");
var RTTI_Element_1 = require("./RTTI_Element");
var RTTI_code_1 = require("../Scalar/RTTI_code");
var RTTI_Narrative_1 = require("./RTTI_Narrative");
var RTTI_ResourceList_1 = require("../Union/RTTI_ResourceList");
var RTTI_Extension_1 = require("./RTTI_Extension");
var RTTI_Coding_1 = require("./RTTI_Coding");
var RTTI_Period_1 = require("./RTTI_Period");
var RTTI_instant_1 = require("../Scalar/RTTI_instant");
var RTTI_CodeableConcept_1 = require("./RTTI_CodeableConcept");
var RTTI_AuditEvent_Agent_1 = require("./RTTI_AuditEvent_Agent");
var RTTI_AuditEvent_Source_1 = require("./RTTI_AuditEvent_Source");
var RTTI_AuditEvent_Entity_1 = require("./RTTI_AuditEvent_Entity");
var AuditEventActionKind;
(function (AuditEventActionKind) {
    AuditEventActionKind["_c"] = "C";
    AuditEventActionKind["_r"] = "R";
    AuditEventActionKind["_u"] = "U";
    AuditEventActionKind["_d"] = "D";
    AuditEventActionKind["_e"] = "E";
})(AuditEventActionKind = exports.AuditEventActionKind || (exports.AuditEventActionKind = {}));
var AuditEventOutcomeKind;
(function (AuditEventOutcomeKind) {
    AuditEventOutcomeKind["_success"] = "Success";
    AuditEventOutcomeKind["_minorFailure"] = "MinorFailure";
    AuditEventOutcomeKind["_seriousFailure"] = "SeriousFailure";
    AuditEventOutcomeKind["_majorFailure"] = "MajorFailure";
})(AuditEventOutcomeKind = exports.AuditEventOutcomeKind || (exports.AuditEventOutcomeKind = {}));
var EnumType_1 = require("../../EnumType");
exports.RTTI_AuditEvent = t.recursion('IAuditEvent', function () {
    return t.intersection([
        t.type({
            resourceType: t.literal('AuditEvent'),
            type: RTTI_Coding_1.RTTI_Coding,
            agent: t.array(RTTI_AuditEvent_Agent_1.RTTI_AuditEvent_Agent),
            source: RTTI_AuditEvent_Source_1.RTTI_AuditEvent_Source,
            recorded: RTTI_instant_1.RTTI_instant,
        }),
        t.partial({
            id: RTTI_id_1.RTTI_id,
            meta: RTTI_Meta_1.RTTI_Meta,
            implicitRules: RTTI_uri_1.RTTI_uri,
            _implicitRules: RTTI_Element_1.RTTI_Element,
            language: RTTI_code_1.RTTI_code,
            _language: RTTI_Element_1.RTTI_Element,
            text: RTTI_Narrative_1.RTTI_Narrative,
            contained: t.array(RTTI_ResourceList_1.RTTI_ResourceList),
            extension: t.array(RTTI_Extension_1.RTTI_Extension),
            modifierExtension: t.array(RTTI_Extension_1.RTTI_Extension),
            subtype: t.array(RTTI_Coding_1.RTTI_Coding),
            action: EnumType_1.createEnumType(AuditEventActionKind, 'AuditEventActionKind'),
            _action: RTTI_Element_1.RTTI_Element,
            period: RTTI_Period_1.RTTI_Period,
            _recorded: RTTI_Element_1.RTTI_Element,
            outcome: EnumType_1.createEnumType(AuditEventOutcomeKind, 'AuditEventOutcomeKind'),
            _outcome: RTTI_Element_1.RTTI_Element,
            outcomeDesc: t.string,
            _outcomeDesc: RTTI_Element_1.RTTI_Element,
            purposeOfEvent: t.array(RTTI_CodeableConcept_1.RTTI_CodeableConcept),
            entity: t.array(RTTI_AuditEvent_Entity_1.RTTI_AuditEvent_Entity)
        })
    ]);
});
