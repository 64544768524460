import React, { Component } from 'react';
import { Input } from 'antd';
import InputMask from './inputmask-core';
import { isRedo, isUndo, getSelection, setSelection } from './helpers';
export default class MaskedInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            input: null,
            _lastValue: null,
            _Input: null,
            mask: null,
            prevMask: null,
            prevValue: null,
        };
        this._onChange = (e) => {
            // console.log('onChange', JSON.stringify(getSelection(this.state.input)), e.target.value)
            let maskValue = this.state.mask.getValue();
            let incomingValue = e.target.value;
            if (incomingValue !== maskValue) {
                // only modify mask if form contents actually changed
                this._updateMaskSelection();
                this.state.mask.setValue(incomingValue); // write the whole updated value into the mask
                this.setInputValue(this._getDisplayValue()); // update the form with pattern applied to the value
                this._updateInputSelection();
            }
            if (this.props.onChange) {
                this.props.onChange(e);
            }
        };
        this._onKeyDown = (e) => {
            setTimeout(() => {
                this.state.input.classList[this.state.input.value ? 'add' : 'remove']('has-value');
            }, 100);
            if (isUndo(e)) {
                e.preventDefault();
                if (this.state.mask.undo()) {
                    this.setInputValue(this._getDisplayValue());
                    this._updateInputSelection();
                    if (this.props.onChange) {
                        this.props.onChange(e);
                    }
                }
                return;
            }
            else if (isRedo(e)) {
                e.preventDefault();
                if (this.state.mask.redo()) {
                    this.setInputValue(this._getDisplayValue());
                    this._updateInputSelection();
                    if (this.props.onChange) {
                        this.props.onChange(e);
                    }
                }
                return;
            }
            if (e.key === 'Backspace') {
                e.preventDefault();
                this._updateMaskSelection();
                if (this.state.mask.backspace()) {
                    let value = this._getDisplayValue();
                    this.setInputValue(value);
                    if (value) {
                        this._updateInputSelection();
                    }
                    if (this.props.onChange) {
                        this.props.onChange(e);
                    }
                }
            }
        };
        this._onKeyPress = (e) => {
            // console.log('onKeyPress', JSON.stringify(getSelection(this.state.input)), e.key, e.target.value)
            // Ignore modified key presses
            // Ignore enter key to allow form submission
            if (e.metaKey || e.altKey || e.ctrlKey || e.key === 'Enter') {
                return;
            }
            e.preventDefault();
            this._updateMaskSelection();
            if (this.state.mask.input(e.key || e.data)) {
                this.setInputValue(this.state.mask.getValue());
                this._updateInputSelection();
                if (this.props.onChange) {
                    this.props.onChange(e);
                }
            }
        };
        this._onPaste = (e) => {
            e.preventDefault();
            this._updateMaskSelection();
            // getData value needed for IE also works in FF & Chrome
            if (this.state.mask.paste(e.clipboardData.getData('Text'))) {
                // @ts-ignore
                this.setInputValue(this.state.mask.getValue());
                // Timeout needed for IE
                setTimeout(() => this._updateInputSelection(), 0);
                if (this.props.onChange) {
                    // @ts-ignore
                    this.props.onChange(e);
                }
            }
        };
        this.getInputProps = () => {
            let maxLength = this.state.mask.pattern.length;
            let eventHandlers = this._getEventHandlers();
            let { placeholder = this.state.mask.emptyValue } = this.props;
            let { placeholderChar, formatCharacters, ...cleanedProps } = this.props;
            const props = { ...cleanedProps, ...eventHandlers, maxLength, placeholder };
            delete props.value;
            return props;
        };
        this.setInputValue = (value) => {
            if (!this.state._Input || !this.state._Input.input)
                return;
            if (value === this.state._lastValue)
                return;
            this.state._lastValue = value;
            this.state._Input.setState({ value });
            this.state._Input.input.value = value;
        };
        this.handleInputRef = (ref) => {
            if (!ref)
                return;
            this.state._Input = ref;
            this.state.input = ref.input;
            if (this.state._lastValue === null &&
                typeof this.props.defaultValue === 'string') {
                this.state.mask.setValue(this.props.defaultValue); // write the whole updated value into the mask
                this.setInputValue(this._getDisplayValue()); // update the form with pattern applied to the value
            }
        };
        let options = {
            pattern: this.props.mask,
            value: this.props.value,
            formatCharacters: this.props.formatCharacters
        };
        if (this.props.placeholderChar) {
            options.placeholderChar = this.props.placeholderChar;
        }
        this.state.mask = new InputMask(options);
    }
    componentDidMount() {
        this.setInputValue(this._getDisplayValue());
    }
    static getDerivedStateFromProps(props, state) {
        const currMask = state.prevMask;
        const currValue = state.prevValue;
        const nextMask = props.mask;
        const nextValue = props.value;
        if (nextMask !== currMask &&
            nextValue !== currValue) {
            // if we get a new value and a new mask at the same time
            // check if the mask.value is still the initial value
            // - if so use the next's value
            // - otherwise the `this.mask` has a value for us (most likely from paste action)
            if (state.mask.getValue() === state.mask.emptyValue) {
                state.mask.setPattern(nextMask, {
                    value: nextValue,
                    selection: state.input && getSelection(state.input)
                });
            }
            else {
                state.mask.setPattern(nextMask, {
                    value: state.mask.getRawValue(),
                    selection: state.input && getSelection(state.input)
                });
            }
        }
        else if (currMask !== nextMask) {
            state.mask.setPattern(nextMask, {
                value: state.mask.getRawValue(),
                selection: state.input && getSelection(state.input)
            });
        }
        if (currValue !== nextValue) {
            state.mask.setValue(nextValue);
            let value = state.mask.getValue();
            value = value === state.mask.emptyValue ? '' : value;
            if (state._Input && state._Input.input && value !== state._lastValue) {
                state._lastValue = value;
                state._Input.setState({ value });
                state._Input.input.value = value;
            }
        }
        if (nextMask !== currMask || nextValue !== currValue) {
            const newState = {};
            if (nextMask !== currMask) {
                newState.prevMask = nextMask;
            }
            if (nextValue !== currValue) {
                newState.prevValue = nextValue;
            }
            return newState;
        }
        return null;
    }
    componentDidUpdate(prevProps) {
        if (!this.props.mask)
            return null;
        if (prevProps.mask !== this.props.mask && this.state.mask.selection.start) {
            this._updateInputSelection();
        }
        return;
    }
    _updateMaskSelection() {
        this.state.mask.selection = getSelection(this.state.input);
    }
    _updateInputSelection() {
        setSelection(this.state.input, this.state.mask.selection);
    }
    _getDisplayValue() {
        let value = this.state.mask.getValue();
        return value === this.state.mask.emptyValue ? '' : value;
    }
    _keyPressPropName() {
        if (typeof navigator !== 'undefined') {
            return navigator.userAgent.match(/Android/i)
                ? 'onBeforeInput'
                : 'onKeyPress';
        }
        return 'onKeyPress';
    }
    _getEventHandlers() {
        return {
            onChange: this._onChange,
            onKeyDown: this._onKeyDown,
            onPaste: this._onPaste,
            [this._keyPressPropName()]: this._onKeyPress
        };
    }
    focus() {
        this.state.input.focus();
    }
    blur() {
        this.state.input.blur();
    }
    render() {
        return React.createElement(Input, Object.assign({}, this.getInputProps(), { ref: this.handleInputRef }));
    }
}
