import {createSlice} from '@reduxjs/toolkit';
import {normalize, schema} from 'normalizr';
import querystring from 'querystring';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState, ThunkDispatch} from '.';
import {FhirUtils} from '../services/fhir';
import {mapBundleToResourceArray} from '../util/fhir';
import {RequiredValidationRuleId} from '../validation/validation-rules/required-validation';
import {ValidationRules} from '../validation/validation-rules/validation-rules';
import {User} from './users-smile';

export interface Role {
  id: string;
  name: string;
  organizationId: string;
}

export const RoleValidation: {
  [property: string]: ValidationRules[];
} = {
  name: [
    {
      validationRuleType: RequiredValidationRuleId,
    },
  ],
};

export interface RolesSliceInterface {
  byId: {[string: string]: Role};
  historyById: {[string: string]: Role};
}
const initialState = {
  byId: {},
  historyById: {},
};
const role = new schema.Entity('roles', {});
const roleHistory = new schema.Entity(
  'roles',
  {},
  {
    idAttribute: (value) => {
      return `${value.id}-${value.meta.versionId}`;
    },
  }
);

const slice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    SAVE_ROLES: (state: RolesSliceInterface, action) => {
      const resources = mapBundleToResourceArray(action.payload);
      state.byId = normalize(resources, [role]).entities.roles || {};
    },
    SAVE_ROLE: (state: RolesSliceInterface, action) => {
      state.byId[action.payload.id] = action.payload;
    },
    DELETE_ROLE: (state: RolesSliceInterface, action) => {
      delete state.byId[action.payload.id];
    },
    SAVE_ROLE_HISTORY: (state: RolesSliceInterface, action) => {
      const resources = mapBundleToResourceArray(action.payload);
      state.historyById = normalize(resources, [roleHistory]).entities.roles || {};
    },
  },
});

export const getAll = (client, params?) => async (dispatch) => {
  let query = params ? querystring.stringify(params) : undefined;
  const res = await client.get(`Role${query ? `?${query}` : ''}`);
  dispatch(slice.actions.SAVE_ROLES(res.data));
  return res.data;
};

const getOne = (client, id) => async (dispatch) => {
  return client.get(`/Role/${id}`).then((res) => {
    return dispatch(slice.actions.SAVE_ROLE(res.data));
  });
};

const getAllForUser = (client, user: User) => async (dispatch) => {
  const orgIds = user.authorities.reduce((orgIds, auth) => {
    if (auth.permission === 'FHIR_READ_ALL_IN_COMPARTMENT' && auth.argument.startsWith('Role/')) {
      const orgId = auth.argument.split('/')[1];
      return orgIds.concat([orgId]);
    }
    return orgIds;
  }, []);

  orgIds.forEach((id) => dispatch(getOne(client, id)));
};

const getOneHistory = (client, id) => async (dispatch) => {
  return client.request(`/Role/${id}/_history`).then((res) => {
    return dispatch(slice.actions.SAVE_ROLE_HISTORY(res));
  });
};

const updateOne = (client, org) => async (dispatch) => {
  return client.put(`/Role/${org.id}`, org).then(async (res) => {
    await dispatch(slice.actions.SAVE_ROLE(res.data));
    return res.data;
  });
};

export const createOne = (client, org) => async (dispatch) => {
  return client.post('/Role', org).then(async (res) => {
    await dispatch(slice.actions.SAVE_ROLE(res.data));
    return res.data;
  });
};

export const deleteOne = (client, itemId) => async (dispatch) => {
  return client.post(`/Role/${itemId}/delete`).then(async (res) => {
    await dispatch(slice.actions.DELETE_ROLE({id: itemId}));
    return res.data;
  });
};

export default {
  slice,
  getAll,
  getAllForUser,
  getOne,
  updateOne,
  createOne,
  getOneHistory,
  deleteOne,
};

export function userHasAuthorityForRole(user, orgId): boolean {
  return user.authorities?.find(
    (auth) =>
      (auth.permission === 'FHIR_READ_ALL_IN_COMPARTMENT' ||
        auth.permission === 'FHIR_WRITE_ALL_IN_COMPARTMENT') &&
      auth.argument === `Role/${orgId}`
  );
}

export const useRoles = () => {
  const thunkDispatch = useDispatch<ThunkDispatch>();
  const client = FhirUtils.useClient();

  React.useEffect(() => {
    thunkDispatch(getAll(client));
  }, []);

  const roles = useSelector((state: RootState) => state.roles.byId);

  return Object.values(roles);
};

export const useRoleWithId = (id) => {
  const thunkDispatch = useDispatch<ThunkDispatch>();
  const client = FhirUtils.useClient();

  React.useEffect(() => {
    if (!id) return;
    thunkDispatch(getOne(client, id));
  }, [id]);

  const role = useSelector((state: RootState) => state.roles.byId[id]);

  return role;
};
