import {createSlice} from '@reduxjs/toolkit';
import {normalize, schema} from 'normalizr';
import querystring from 'querystring';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState, ThunkDispatch} from '.';
import {FhirUtils} from '../services/fhir';
import {mapBundleToResourceArray} from '../util/fhir';

export interface Tradename {
  id: string;
  conceptId: string;
  fsn: string;
  displayNameEn: string;
  displayNameFr: string;
  abbreviationEn: string;
  abbreviationFr: string;
  publicPicklistTermEn: string;
  publicPicklistTermFr: string;
  clinicianPicklistTermEn: string;
  clinicianPicklistTermFr: string;
  din: string;
  marketAuthorizationHolder: string;
  shelfStatus: string;
  typicalDoseSize: string;
  typicalDoseSizeUOM: string;
  strength: string;
  passive: boolean;
  lastUpdated: Date;
}

export interface TradenamesSliceInterface {
  byId: {[string: string]: Tradename};
}
const initialState = {
  byId: {},
};
const tradename = new schema.Entity('tradenames', {});

const slice = createSlice({
  name: 'tradenames',
  initialState,
  reducers: {
    SAVE_TRADENAMES: (state: TradenamesSliceInterface, action) => {
      const resources = mapBundleToResourceArray(action.payload);
      state.byId = normalize(resources, [tradename]).entities.tradenames || {};
    },
    SAVE_TRADENAME: (state: TradenamesSliceInterface, action) => {
      state.byId[action.payload.id] = action.payload;
    },
  },
});

export const getAll = (client, params?) => async (dispatch) => {
  let query = params ? querystring.stringify(params) : undefined;
  const res = await client.get(`cvc/tradenames${query ? `?${query}` : ''}`);
  dispatch(slice.actions.SAVE_TRADENAMES(res.data));
  return res.data;
};

//id params should be conceptId of the tradename entity
const getOne = (client, id) => async (dispatch) => {
  return client.get(`/cvc/tradenames/${id}`).then((res) => {
    return dispatch(slice.actions.SAVE_TRADENAME(res.data));
  });
};

export default {
  slice,
  getAll,
  getOne,
};

export const useTradenames = () => {
  const thunkDispatch = useDispatch<ThunkDispatch>();
  const client = FhirUtils.useClient();

  React.useEffect(() => {
    thunkDispatch(getAll(client));
  }, []);

  const tradenames = useSelector((state: RootState) => state.tradenames.byId);

  return Object.values(tradenames);
};

// export const useTradenameWithId = (id) => {
//   const thunkDispatch = useDispatch<ThunkDispatch>();
//   const client = FhirUtils.useClient();

//   React.useEffect(() => {
//     if (!id) return;
//     thunkDispatch(getOne(client, id));
//   }, [id]);

//   const tradename = useSelector((state: RootState) => state.tradenames.byId[id]);

//   return tradename;
// };
