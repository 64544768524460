import {createSlice} from '@reduxjs/toolkit';
import {normalize, schema} from 'normalizr';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState, ThunkDispatch} from '.';
import {FhirUtils} from '../services/fhir';
import {mapBundleToResourceArray} from '../util/fhir';

export interface HcnTypeInterface {
  id: string;
  name: {en: string; fr: string};
  unique: boolean;
}

export interface HcnTypesSliceInterface {
  byId: {[string: string]: HcnTypeInterface};
}

const initialState = {
  byId: {},
};

const hcntype = new schema.Entity('hcntypes', {});

const slice = createSlice({
  name: 'hcntypes',
  initialState,
  reducers: {
    SAVE_HCNTYPES: (state: HcnTypesSliceInterface, action) => {
      const resources = mapBundleToResourceArray(action.payload);
      state.byId = normalize(resources, [hcntype]).entities.hcntypes || {};
    },
  },
});

const getAll = (client) => async (dispatch) => {
  const res = await client.get(`/hcn-types`);
  dispatch(slice.actions.SAVE_HCNTYPES(res.data));
  return res.data;
};

export default {
  slice,
  getAll,
};

export const useHcnTypes = () => {
  const thunkDispatch = useDispatch<ThunkDispatch>();
  const client = FhirUtils.useClient();

  React.useEffect(() => {
    thunkDispatch(getAll(client));
  }, []);

  const hcntypes = useSelector((state: RootState) => state.hcntypes.byId);

  return Object.values(hcntypes);
};
