import {Button, message, Popconfirm, Space, Spin} from 'antd';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {FhirUtils} from '../../../services/fhir';

export const ClinicCohortActions = (props) => {
  const {cohort, clinicId, refreshTable} = props;
  const client = FhirUtils.useAxiosClient();
  const [loading, setLoading] = React.useState(false);

  const onRemove = async () => {
    setLoading(true);
    const result = await client.put(`/clinic/${clinicId}/link/cohort`, {
      action: 'unlink',
      cohortId: cohort.id,
      clinicId,
    });

    if (result.status === 200) {
      refreshTable();
      message.success('Cohort was successfully removed from clinic.');
    } else {
      message.error('Error removing cohort from clinic.');
    }

    setLoading(false);
  };

  return (
    <Space>
      <Popconfirm
        overlayStyle={{maxWidth: 400}}
        title={
          <div>
            <div>
              <b>Are you sure you want to remove this cohort from this clinic?</b>
            </div>
          </div>
        }
        onConfirm={onRemove}
        okText="Yes"
        cancelText="No"
      >
        <Space>
          <Button
            type="link"
            danger
            size={'small'}
            style={{paddingLeft: 10, paddingRight: 10}}
            loading={loading}
          >
            Remove
          </Button>
        </Space>
      </Popconfirm>
    </Space>
  );
};
