import React from 'react';
import {useNavigate} from 'react-router-dom';
import {LanguagePicker} from '../language-picker';
import {LogoBar} from '../logo-bar';
import {Appointment, AppointmentType} from '../types';
import {useUrlData} from '../util';
import {AppointmentPicker} from './appointment-picker';
import {ContactForm} from './contact-form';
import {LandingPanel} from './landing-panel';
import {BookingPagePasswordForm} from './password-form';
import moment from 'moment';
import 'moment/min/locales';
import {Loading} from '../../util/loading';
import axios from 'axios';
import {useEnvInfo} from '../../../services/environment';
import s from '../styles.module.css';

export const BookingForm = (props) => {
  const navigate = useNavigate();
  const {showLandingPanel} = props;
  const [passwordVerified, setPasswordVerified] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [selectedAppointmentType, setSelectedAppointmentType] =
    React.useState<AppointmentType | undefined>();

  const {supportFrench} = useEnvInfo();
  const [selectedAppointment, setSelectedAppointment] = React.useState<Appointment | undefined>();
  const [contactFormValues, setContactFormValues] = React.useState({data: {}});

  const {config, syncUrl, lang, query} = useUrlData();
  moment.locale(lang);

  const validatePassword = () => {
    const password = localStorage.getItem(`pwd-${config.id}`);

    if (password) {
      axios
        .post(syncUrl + `/public/booking-page/${config.id}/verify`, {password: password})
        .then((res) => setPasswordVerified(true))
        .catch(() => {})
        .finally(() => setLoading(false));
    } else setLoading(false);
  };

  React.useEffect(() => {
    if (config.status === 'protected') validatePassword();
    else setLoading(false);
  }, []);

  if (loading) {
    return <Loading />;
  }

  // If french is not supported redirect any non english language slugs to 404
  if (!supportFrench && lang !== 'en') {
    navigate('/404');
    return null;
  }

  if (config.status === 'inactive') navigate('/404');

  if (config.status === 'protected' && !passwordVerified) {
    return <BookingPagePasswordForm setPasswordVerified={setPasswordVerified} />;
  }

  return (
    <div className={s.background}>
      <div className={s.upperpanel}>
        <div className={'container'}>
          <LanguagePicker />
          <LogoBar />
          {showLandingPanel && <LandingPanel />}
        </div>
      </div>

      <div className={'container'} style={{marginTop: 60}}>
        <AppointmentPicker
          setSelectedAppointmentType={setSelectedAppointmentType}
          selectedAppointmentType={selectedAppointmentType}
          selectedAppointment={selectedAppointment}
          setSelectedAppointment={setSelectedAppointment}
          forceUseCurrentAppointment={!!query.previousAppointmentId}
          defaultAge={config.defaultAge}
        />
        <ContactForm
          selectedAppointment={selectedAppointment}
          setSelectedAppointment={setSelectedAppointment}
          contactFormValues={contactFormValues}
          setContactFormValues={setContactFormValues}
        />
      </div>
    </div>
  );
};
