import React from 'react';
import {Form} from 'formik-antd';
import {Input, Switch, Select, Space, Tooltip} from 'antd';
import {ValidateFormItem} from '../validate-form-item';
import {ValidationRules} from '../../validation/validation-rules/validation-rules';
import { RequiredUserManagementPassword } from '../../services/ui-validation-rules';
import {ClinicModeUser} from '../../views/admin/helpers/types';

const {Option} = Select;

interface UserPasswordFormItemProps {
  name: string;
  label?: string;
  setFieldValue: (name: string, value: any) => void;
  validationRules?: ValidationRules[];
  item?: any;
}

interface UserPasswordItemProps {
  setFieldValue: (name: string, value: any) => void;
  isGeneratedByDefault?: boolean;
}

export const UserPasswordItem = (props: UserPasswordItemProps) => {
  const [isGenerated, setGenerated] = React.useState<boolean>(
    props.isGeneratedByDefault !== undefined ? props.isGeneratedByDefault : true
  );

  const updateGenerated = (value) => {
    if (value === 'manual') setGenerated(false);
    else {
      props.setFieldValue('password', '');
      setGenerated(true);
    }
  };

  return (
    <Space direction="vertical">
      <Space direction="horizontal">
        <Select defaultValue={isGenerated ? 'auto' : 'manual'} onChange={updateGenerated}>
          <Option value="auto">Server Generated Password</Option>
          <Option value="manual">Manually Set Password</Option>
        </Select>
      </Space>
      {isGenerated === false && (
         <Tooltip title='Passwords must be at least 8 characters in length and contain 3 of the following: uppercase letters, lowercase letters, numbers, symbols.' placement='left'>
           <Input
              placeholder="Password"
              onChange={(value) => props.setFieldValue('password', value.target.value)}
          />
         </Tooltip>
      )}
    </Space>
  );
};

export const UserPasswordFormItem = (props: UserPasswordFormItemProps) => {
  const [isGenerated, setGenerated] = React.useState(true);

  React.useEffect(() => {props.setFieldValue('password', '')}, [isGenerated])

  const updateGenerated = (value) => {
    (value === 'manual') ? setGenerated(false) : setGenerated(true);
  };

  return (
    <ValidateFormItem
      validationRules={isGenerated ? [] : RequiredUserManagementPassword}
      renderFormItem={(validate) => {
        return (
          <Form.Item
            name="password"
            label={props.label || 'Password'}
            validate={validate}
          >
            <Space direction="vertical">
              <Space direction="horizontal">
                <Select defaultValue={isGenerated ? 'auto' : 'manual'} onChange={updateGenerated}>
                  <Option value="auto">Server Generated Password</Option>
                  <Option value="manual">Manually Set Password</Option>
                </Select>
              </Space>
              {isGenerated === false && (
                <Tooltip title='Passwords must be at least 8 characters in length and contain 3 of the following: uppercase letters, lowercase letters, numbers, symbols.' placement='left'>
                  <Input.Password
                    placeholder="Password"
                    onChange={(value) => props.setFieldValue('password', value.target.value)}
                  />
                </Tooltip>
              )}
            </Space>
          </Form.Item>
        );
      }}
    />
  );
};
