import {RequiredValidationRuleId} from '../../../validation/validation-rules/required-validation';
import {IBaseFormUxField} from '../../form-ux/form-ux-models/form-ux-fields/abstract-form-ux-fields/base-form-ux-field';

export const StartTimeField: IBaseFormUxField<any> = {
  name: 'startTime',
  type: 'any',
  label: 'Start Time',
  editable: true,
  validationRules: [
    {
      validationRuleType: RequiredValidationRuleId,
    },
  ],
};

export const EndTimeField: IBaseFormUxField<any> = {
  name: 'endTime',
  type: 'any',
  label: 'End Time',
  editable: true,
  validationRules: [
    {
      validationRuleType: RequiredValidationRuleId,
    },
  ],
};

export const StartDateField: IBaseFormUxField<any> = {
  name: 'startDate',
  type: 'any',
  label: 'Start Date',
  editable: true,
  validationRules: [
    {
      validationRuleType: RequiredValidationRuleId,
    },
  ],
};

export const EndDateField: IBaseFormUxField<any> = {
  name: 'endDate',
  type: 'any',
  label: 'End Date',
  editable: true,
  validationRules: [],
};
