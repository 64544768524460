import {Button, message, Menu} from 'antd';
import React from 'react';
import {useReactToPrint} from 'react-to-print';
import {FhirUtils, useUnAuthClient} from '../../services/fhir';
import {CompactReceipt} from './components/compact-receipt';
import {COVIDPortalConfigProvider} from './util/portal-config';
import {PortalButton} from './components/dose-list';
import {faPrint} from '@fortawesome/free-solid-svg-icons';
import {PVCWalletSizedButtonProps} from './pvc-wallet-sized-button';
import {MenuItem} from 'rc-menu';

export const PVCWalletMenuItem = (props: PVCWalletSizedButtonProps) => {
  const {patientId, portal, patientlookup} = props;
  const [pvcWalletData, setPvcWalletData] = React.useState<any>(false);
  const [settings, setSettings] = React.useState<any>({});
  const [loading, setLoading] = React.useState<boolean>(false);
  const walletSizeRef = React.useRef<any>(null);
  const client = FhirUtils.useAxiosClient();
  const unAuthClient = useUnAuthClient();

  React.useEffect(() => {
    if (pvcWalletData) {
      setTimeout(() => {
        if (handlePrintWalletPvc) handlePrintWalletPvc();
        setPvcWalletData(false);
      });
    }
  }, [pvcWalletData]);

  const handlePrintWalletPvc = useReactToPrint({
    content: () => walletSizeRef.current,
  });

  const handleWalletPvcCOnClick = async () => {
    setLoading(true);
    // Use Client With No Auth If On Portal
    const pvcClient = !!portal?.id ? unAuthClient : client;

    const response = await pvcClient.get('/public/settings/pvc');
    setSettings(response.data);

    pvcClient
      .request({
        method: !!portal?.id ? 'POST' : 'GET',
        url: !!portal?.id
          ? `/public/pvc?type=wallet-pvc`
          : `/patient/${patientId}/pvc?type=wallet-pvc${
              !!patientlookup ? '&from=patientlookup' : ''
            }`,
        ...(!!portal?.id && {
          data: JSON.parse(JSON.stringify({id: portal.id, sessionId: portal.sessionId})),
        }),
      })
      .then((res) => {
        setPvcWalletData(res?.data);
      })
      .catch((err) => {
        message.error('Failed to print Wallet PVC');
      })
      .finally(() => setLoading(false));
  };

  return (
    <COVIDPortalConfigProvider key={'wallet-pvc'} keepTheme>
      <>
        {pvcWalletData && (
          <div ref={walletSizeRef} style={{padding: '0.1cm'}} className="show-on-print">
            <CompactReceipt
              data={pvcWalletData}
              logoUrl={settings?.ptLogo?.value}
              logoAlt={settings?.logoAltEn?.value}
            />
          </div>
        )}
        {!!portal?.id ? (
          <PortalButton
            tooltipTitle={portal?.tooltip ?? ''}
            buttonTitle={portal?.title ?? ''}
            icon={faPrint}
            onClick={handleWalletPvcCOnClick}
            loading={loading}
          />
        ) : (
          <p onClick={handleWalletPvcCOnClick}>Print Wallet PVC</p>
        )}
      </>
    </COVIDPortalConfigProvider>
  );
};
