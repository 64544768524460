import {normalize, schema} from 'normalizr';
import {createSlice, PayloadAction, Slice} from '@reduxjs/toolkit';
import querystring from 'querystring';
import {mapBundleToResourceArray} from '../../util/fhir';

interface SliceInterface<
  T extends {
    id: string;
  }
> {
  byId: {[id: string]: T};
}

/**
 * Use this method when creating a new model. The returned object should be
 * default exported from the model module using the code
 * `export default {...createdModel}`
 *
 * @export
 * @template T The shape of your model
 * @param {string} sliceName The name of the slice to use
 * @param {string} fhirResourceRoute The resource route to use for doing
 * the various REST operations
 * @param {() => {[queryName: string]: string}} getQueryParameters Function
 * called before sending a request to add query parameters to it. The object
 * keys are the query paramater names while the values are the actual values.
 * @param {(serverObj: any) => any} convertToClientObject Optional function
 * which will be called on the items returned from the server. The value
 * returned by this function will be saved into the client store.
 * @returns {{
 *   slice: Slice<
 *     {
 *       byId: {};
 *     },
 *     {
 *       saveObjects: (state: SliceInterface<T>, action: PayloadAction<T>) => void;
 *       saveObject: (state: SliceInterface<T>, action: PayloadAction<T>) => void;
 *       deleteObject: (
 *         state: SliceInterface<T>,
 *         action: PayloadAction<{
 *           id: string;
 *         }>
 *       ) => void;
 *     },
 *     string
 *   >;
 *   createOne: (client, item: T) => (dispatch) => Promise<T>;
 *   getOne: (client, itemId: string) => (dispatch) => Promise<T>;
 *   updateOne: (client, item: T) => (dispatch) => Promise<T>;
 *   deleteOne: (client, itemId: string) => (dispatch) => Promise<T>;
 * }}
 */
export function createModel<
  T extends {
    id: string;
  }
>(
  sliceName: string,
  fhirResourceRoute: string,
  getQueryParameters?: () => {[queryName: string]: string},
  convertToClientObject?: (serverObj: any) => any
): {
  slice: Slice<
    {
      byId: {};
    },
    {
      saveObjects: (state: SliceInterface<T>, action: PayloadAction<T>) => void;
      saveObject: (state: SliceInterface<T>, action: PayloadAction<T>) => void;
      deleteObject: (
        state: SliceInterface<T>,
        action: PayloadAction<{
          id: string;
        }>
      ) => void;
    },
    string
  >;
  createOne: (client, item: T) => (dispatch) => Promise<T>;
  getOne: (client, itemId: string) => (dispatch) => Promise<T>;
  updateOne: (client, item: T) => (dispatch) => Promise<T>;
  deleteOne: (client, itemId: string) => (dispatch) => Promise<T>;
  getAll: (
    client,
    params?: {
      [queryParam: string]: any;
    }
  ) => (dispatch) => Promise<T[]>;
} {
  const modelEntity = new schema.Entity(sliceName, {});

  const slice = createSlice({
    name: sliceName,
    initialState: {
      byId: {},
    },
    reducers: {
      saveObjects: (state: SliceInterface<T>, action: PayloadAction<T>) => {
        // @ts-ignore
        const resources = mapBundleToResourceArray(action.payload);

        state.byId = normalize(resources, [modelEntity]).entities[sliceName] || {};
      },
      saveObject: (state: SliceInterface<T>, action: PayloadAction<T>) => {
        state.byId[action.payload.id] = convertToClientObject
          ? convertToClientObject(action.payload)
          : action.payload;
      },
      deleteObject: (state: SliceInterface<T>, action: PayloadAction<{id: string}>) => {
        delete state.byId[action.payload.id];
      },
    },
  });

  const createOne = (client, item) => {
    return async (dispatch) => {
      return client.post(fhirResourceRoute, item).then(async (res) => {
        await dispatch(slice.actions.saveObject(res.data));
        return res.data;
      });
    };
  };

  const getOne = (client, id) => {
    return async (dispatch) => {
      return client
        .get(`${fhirResourceRoute}/${id}`, {
          params: getQueryParameters ? getQueryParameters() : {},
        })
        .then(async (res) => {
          await dispatch(slice.actions.saveObject(res.data));
        });
    };
  };

  const updateOne = (client, item: any) => {
    return async (dispatch) => {
      return client
        .put(`${fhirResourceRoute}/${item.id}`, item, {
          params: getQueryParameters ? getQueryParameters() : {},
        })
        .then(async (res) => {
          await dispatch(slice.actions.saveObject(res.data));
          return res.data;
        });
    };
  };

  const deleteOne = (client, itemId) => async (dispatch) => {
    return client
      .post(`${fhirResourceRoute}/${itemId}/delete`, null, {
        params: getQueryParameters ? getQueryParameters() : {},
      })
      .then(async (res) => {
        await dispatch(slice.actions.deleteObject({id: itemId}));
        return res.data;
      });
  };

  const getAll = (client, params?) => async (dispatch) => {
    let query = params ? querystring.stringify(params) : undefined;
    const res = await client.get(`${fhirResourceRoute}${query ? `?${query}` : ''}`, {
      params: getQueryParameters ? getQueryParameters() : {},
    });
    dispatch(slice.actions.saveObjects(res.data));
    return res.data;
  };

  return {
    slice,
    createOne,
    getOne,
    updateOne,
    deleteOne,
    getAll,
  };
}
