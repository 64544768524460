import React from 'react';
import {Tag, Typography} from 'antd';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck, faCopy} from '@fortawesome/free-solid-svg-icons';
import {colors} from '@canimmunize/tools';

export const CopyIDTag = (props) => {
  const [copied, setCopied] = React.useState(false);
  return (
    <CopyToClipboard text={props.id}>
      <div style={{display: 'flex', cursor: 'pointer'}}>
        <Tag onClick={() => setCopied(true)}>
          {props.label || 'Copy ID'}{' '}
          <FontAwesomeIcon
            icon={copied ? faCheck : faCopy}
            color={copied ? colors.green : colors.darkGray}
          />
        </Tag>
      </div>
    </CopyToClipboard>
  );
};
