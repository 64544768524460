import {createContext, useMemo} from 'react';
import {Ability} from '@casl/ability';
import {updateAbility} from './abilities';
import {useRole} from '../fhir';
import {MissingView} from '../../views/missing';
import React from 'react';
import {Modal} from 'antd';
import {useAuth0} from '@auth0/auth0-react';
import {useLocation} from 'react-router-dom';

export const AbilityContext = createContext<any>(undefined);

export const AbilityProvider = (props) => {
  const role = useRole();
  const {path} = props;
  const {pathname} = useLocation();

  const resolvedPath = path ?? pathname;
  const ability: Ability = useMemo(() => updateAbility(new Ability(), role), [role]);
  const {logout} = useAuth0();

  // When a user tries to access 'home' without access permission, get back to login page after display error message
  if (path === '/home' && !ability.can('access', 'route', resolvedPath)) {
    Modal.error({
      title: 'Insufficient access',
      content: 'Please contact your administrator.',
      afterClose: () => logout({returnTo: window.location.origin}),
    });
  }

  return ability.can('access', 'route', resolvedPath) ? (
    <AbilityContext.Provider value={ability}>{props.children}</AbilityContext.Provider>
  ) : (
    <MissingView />
  );
};
