"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_Consent_Provision = exports.Consent_ProvisionTypeKind = void 0;
var t = __importStar(require("io-ts"));
var RTTI_Extension_1 = require("./RTTI_Extension");
var RTTI_Element_1 = require("./RTTI_Element");
var RTTI_Period_1 = require("./RTTI_Period");
var RTTI_Consent_Actor_1 = require("./RTTI_Consent_Actor");
var RTTI_CodeableConcept_1 = require("./RTTI_CodeableConcept");
var RTTI_Coding_1 = require("./RTTI_Coding");
var RTTI_Consent_Data_1 = require("./RTTI_Consent_Data");
var Consent_ProvisionTypeKind;
(function (Consent_ProvisionTypeKind) {
    Consent_ProvisionTypeKind["_deny"] = "deny";
    Consent_ProvisionTypeKind["_permit"] = "permit";
})(Consent_ProvisionTypeKind = exports.Consent_ProvisionTypeKind || (exports.Consent_ProvisionTypeKind = {}));
var EnumType_1 = require("../../EnumType");
exports.RTTI_Consent_Provision = t.recursion('IConsent_Provision', function () {
    return t.partial({
        id: t.string,
        extension: t.array(RTTI_Extension_1.RTTI_Extension),
        modifierExtension: t.array(RTTI_Extension_1.RTTI_Extension),
        type: EnumType_1.createEnumType(Consent_ProvisionTypeKind, 'Consent_ProvisionTypeKind'),
        _type: RTTI_Element_1.RTTI_Element,
        period: RTTI_Period_1.RTTI_Period,
        actor: t.array(RTTI_Consent_Actor_1.RTTI_Consent_Actor),
        action: t.array(RTTI_CodeableConcept_1.RTTI_CodeableConcept),
        securityLabel: t.array(RTTI_Coding_1.RTTI_Coding),
        purpose: t.array(RTTI_Coding_1.RTTI_Coding),
        class: t.array(RTTI_Coding_1.RTTI_Coding),
        code: t.array(RTTI_CodeableConcept_1.RTTI_CodeableConcept),
        dataPeriod: RTTI_Period_1.RTTI_Period,
        data: t.array(RTTI_Consent_Data_1.RTTI_Consent_Data),
        provision: t.array(exports.RTTI_Consent_Provision)
    });
});
