import 'antd/dist/antd.less';
import ErrorBoundary from 'antd/lib/alert/ErrorBoundary';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import {PersistGate} from 'redux-persist/integration/react';
import Auth0ProviderWithHistory from './auth/auth0-provider-with-history';
import './index.css';
import {AppRoutes} from './services/routing';
import {persistor, store} from './store';

export const App = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <Auth0ProviderWithHistory>
          <ErrorBoundary>
            <AppRoutes />
          </ErrorBoundary>
        </Auth0ProviderWithHistory>
      </BrowserRouter>
    </PersistGate>
  </Provider>
);
