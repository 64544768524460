import {Ids, StrLang} from '@canimmunize/tools';
import {Alert, Button, Spin} from 'antd';
import Axios from 'axios';
import {Formik, FormikProps} from 'formik';
import {Input, Form} from 'formik-antd';
import React from 'react';
import {useParams} from 'react-router-dom';
import {useReactToPrint} from 'react-to-print';
import {ReactDatePicker} from '../../../components/pickers/react-datepicker';
import {ValidateFormItem} from '../../../components/validate-form-item';
import {useUnAuthClient} from '../../../services/fhir';
import {RequiredBirthDateValidationRules} from '../../../services/ui-validation-rules';
import {RequiredValidationRuleId} from '../../../validation/validation-rules/required-validation';
import {DoseList, PortalReceipt} from '../components/dose-list';
import {PageProps} from '../config';
import {useCOVIDPortalConfig} from '../util/portal-config';
import {SessionConfigContext} from '../util/session-config';
import _ from 'lodash';
import {Loading} from '../../../components/util/loading';
import {PublicVaccineReceipt} from '../public-vaccine-receipt';
import {useQuery} from '../../../components/base-entity/base-entity';
import {YukonSummaryPage} from './yukon-summary';

export const SummaryPage = (props: PageProps) => {
  const {config, syncUrl, lang} = useCOVIDPortalConfig();
  const {setPage} = props;
  const {id} = useParams<{id: string}>();
  const [authFactors, setAuthFactors] = React.useState<any>({});
  const [error, setError] = React.useState<{message: string; stringId?: string}>();
  const [loading, setLoading] = React.useState(false);
  const receiptRef = React.useRef(null);
  const {setSessionId} = React.useContext(SessionConfigContext);
  const [clinicEntry, setClinicEntry] = React.useState<any>(props.clinicEntry);
  const query = useQuery();
  const code = query.get('code');
  const phone = query.get('phone');

  React.useEffect(() => {
    if (code?.length || !_.isEmpty(authFactors)) {
      fetchReceiptData();
    }
  }, [code, authFactors]);

  const handlePrint = useReactToPrint({
    content: () => receiptRef.current,
  });

  const fetchReceiptData = async () => {
    setError(undefined);
    setLoading(true);
    const result = await Axios.post(`${syncUrl}/public/receipt`, {
      id,
      code,
      phone,
      ...authFactors,
    }).catch((err) => err.response.data);

    setLoading(false);

    if (result.status === 200) {
      if (config.portalAuthSchemeKey !== 'bruyere') {
        // do not filter covid doses for bruyere
        result.data.records = result.data?.records?.map((record) => ({
          ...record,

          //Filter out doses for now, which is for only COVID
          doses: record.doses.filter((d) =>
            d.concept.protectsAgainst?.find((disease) => disease.conceptId === '840539006')
          ),
        }));
      }
      setClinicEntry(result.data);
      if (setSessionId) setSessionId(result.data?.sessionId);
      setPage(2);
    } else {
      setAuthFactors({});
      setError(result.error);
    }
  };

  // handles which version of receipt to display (old dose list or PVC) - based off of portalAuthSchemeKey
  const Receipt = () => {
    if (config.portalAuthSchemeKey === 'bruyere') {
      return (
        <>
          <div ref={receiptRef}>
            <PublicVaccineReceipt patients={clinicEntry?.records} showOnPrintOnly />
          </div>
          <div className="hide-on-print">
            <DoseList patientRecords={clinicEntry?.records} handlePrint={handlePrint} />
          </div>
        </>
      );
    } else if (config.portalAuthSchemeKey === 'yukon') {
      return (
        <YukonSummaryPage
          patientRecord={clinicEntry?.records}
          deliveryMethod={clinicEntry?.deliveryMethod}
          reqViaThirdParty={clinicEntry?.reqViaThirdParty}
          isYukonCallCentre={props.isYukonCallCentre}
        />
      );
    } else {
      return (
        <div className="hide-on-print">
          <PortalReceipt patientRecords={clinicEntry?.records} />
        </div>
      );
    }
  };

  return (
    <>
      <div>
        {/* Error  */}
        {error && (
          <Alert
            type="error"
            message={StrLang(lang, Ids[error?.stringId || '']) || error?.message}
            style={{marginBottom: 15}}
            showIcon
          />
        )}

        {!code && _.isEmpty(authFactors) && !clinicEntry && !loading && (
          <HCNForm setAuthFactors={setAuthFactors} authFactors={authFactors} />
        )}
        {loading && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: 200,
            }}
          >
            <Spin size="large" style={{color: config.primaryColor}} />
          </div>
        )}

        {clinicEntry?.clinicLogoUri && (
          <img
            src={clinicEntry?.clinicLogoUri}
            style={{width: '65%', maxWidth: 200}}
            alt="clinic-logo"
          />
        )}
        <Receipt />
      </div>
    </>
  );
};

export function clientShouldBookCOVIDAppointment(clinicEntry) {
  // Not yet supporting this for more than 1 patient
  if (clinicEntry.data.records.length > 1) return false;

  const patient = clinicEntry.data.records[0];

  const {covidRecommendation: rec, appointments} = patient;

  // If there is no covid recommendation then don't show booking form
  if (!rec) return false;

  // const hasCovidAppointment = appointments.find((app) => {
  //   // TODO: Determine whether this should be the requirement
  //   // TODO: This should also compare what vaccine product is available at the clinic once we have that data
  //   // return (
  //   //   moment(app.datetime).isAfter(rec.earliestDate) &&
  //   //   moment(app.datetime).isBefore(rec.latestDate)
  //   // );
  // });
  const hasCovidAppointment = appointments.length > 0;

  if (hasCovidAppointment) return false;

  if (rec.forecastStatus === 'COMPLETED') return false;

  return true;
}

const HCNForm = (props) => {
  const {lang, config} = useCOVIDPortalConfig();
  const {portalAuthSchemeKey} = config;
  const {authFactors, setAuthFactors} = props;
  const [authFields, setAuthFields] = React.useState<string[]>([]);
  const [error, setError] = React.useState('');
  const client = useUnAuthClient();

  const generateFormTitle = () => {
    switch (portalAuthSchemeKey) {
      case 'bruyere':
        return StrLang(lang, Ids.bruyere_fill_form_to_access_record);
      default:
        return StrLang(lang, Ids.please_enter_last_4_digits_hcn);
    }
  };

  React.useEffect(() => {
    getGlobalSettings();
  }, []);

  // Get verificationEmailAuthFactors global setting from portal settings
  async function getGlobalSettings() {
    return client
      .get('/public/settings/portal/verificationEmailAuthFactors')
      .then((response) => {
        const result = response.data?.value ? JSON.parse(response.data?.value) : [];
        setAuthFields(result);
      })
      .catch((err) => {
        console.log(`Error: ${err.message}`);
        setError('Error loading page. Please refresh page and try again.');
      });
  }

  const handleSubmit = (values, helpers) => {
    setAuthFactors({...authFactors, ...values});
  };

  if (_.isEmpty(authFields) && !error) {
    return <Loading />;
  }

  if (error) {
    return <h3>{error}</h3>;
  }

  return (
    <Formik initialValues={{hcn: '', birthdate: null}} onSubmit={handleSubmit}>
      {(formikProps) => {
        return (
          <Form layout="vertical" style={{fontSize: 18}}>
            <div style={{marginBottom: 10}}>
              <b>{generateFormTitle()}</b>
            </div>
            {authFields.map((fieldName) => (
              <AuthField formikProps={formikProps} fieldName={fieldName} />
            ))}
            <div style={{marginBottom: 25}}>
              <Button
                size="large"
                onClick={() => formikProps.handleSubmit()}
                style={{backgroundColor: config.primaryColor, border: 'none', color: 'white'}}
              >
                {StrLang(lang, Ids.submit)}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

interface AuthFieldProps {
  fieldName: string;
  formikProps: FormikProps<any>;
}

/**
 * Generate authentication field to access portal from receipt email
 * @param fieldName Authentication factor key (e.g. "hcn", "birthdate", "lastName")
 * @param formikProps
 * */
const AuthField = (props: AuthFieldProps) => {
  const {lang} = useCOVIDPortalConfig();
  const {fieldName, formikProps} = props;

  const generateAuthField = (fieldName: string, formikProps: FormikProps<any>) => {
    switch (fieldName) {
      case 'hcn':
        return (
          <ValidateFormItem
            validationRules={[
              {
                validationRuleType: RequiredValidationRuleId,
              },
            ]}
            renderFormItem={(validate) => {
              return (
                <Form.Item
                  label={StrLang(lang, Ids.hcn_last_4_digits)}
                  name="hcn"
                  tooltip={StrLang(lang, Ids.ontario_hcn_last_4_digits)}
                  required
                  validate={validate}
                >
                  <Input
                    {...formikProps}
                    name="hcn"
                    size="large"
                    placeholder="XXXX"
                    maxLength={4}
                    autoFocus
                    autoComplete="off"
                  />
                </Form.Item>
              );
            }}
          />
        );
      case 'birthdate':
        return (
          <ValidateFormItem
            validationRules={RequiredBirthDateValidationRules}
            renderFormItem={(validate) => {
              return (
                <Form.Item
                  label={StrLang(lang, Ids.date_of_birth)}
                  name="birthdate"
                  required
                  validate={validate}
                >
                  <ReactDatePicker
                    name="birthdate"
                    popperPlacement="top"
                    dateFormat="yyyy/MM/dd"
                    value={formikProps.values.birthdate}
                    placeholder="YYYY/MM/DD"
                    onChange={(e) => formikProps.setFieldValue('birthdate', e)}
                  />
                </Form.Item>
              );
            }}
          />
        );
      case 'lastName':
        return (
          <ValidateFormItem
            validationRules={[
              {
                validationRuleType: RequiredValidationRuleId,
              },
            ]}
            renderFormItem={(validate) => {
              return (
                <Form.Item
                  label={StrLang(lang, Ids.last_name)}
                  name="lastName"
                  required
                  validate={validate}
                >
                  <Input {...formikProps} name="lastName" size="large" />
                </Form.Item>
              );
            }}
          />
        );
      default:
        break;
    }
  };

  return <div style={{maxWidth: 300}}>{generateAuthField(fieldName, formikProps)}</div>;
};
