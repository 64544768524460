import {useAuth0} from '@auth0/auth0-react';
import {createSelector, createSlice} from '@reduxjs/toolkit';
import axios from 'axios';
import {produce} from 'immer';
import jwtDecode from 'jwt-decode';
import Organizations from './organizations';
import Terminology from './terminology';
import Users, {isSuperuser, User} from './users-smile';

interface IdTokenInterface {
  iss: string;
  exp: string;
  sub: string;
}

export interface AccountSliceInterface {
  idToken: null | IdTokenInterface;
  userInfo: null | {sub: string};
}

const initialState: AccountSliceInterface = {
  idToken: null,
  userInfo: null,
};

export const accountSlice = createSlice({
  name: 'entities',
  initialState,
  reducers: {
    SAVE_ID_TOKEN: (state: AccountSliceInterface, action) => {
      state.idToken = action.payload;
    },
    CLEAR_ACCOUNT: (state: AccountSliceInterface, action) => {
      state.idToken = null;
    },
    SAVE_USER_INFO: (state: AccountSliceInterface, action) => {
      state.userInfo = action.payload.userInfo;
      state.idToken = action.payload.idToken;
    },
  },
});

export const saveUserInfo = (client) => async (dispatch, getState) => {
  const {actions} = accountSlice;
  const idToken: IdTokenInterface = jwtDecode(client.state.tokenResponse.id_token);

  axios
    .get(`${idToken.iss}/userinfo`, {
      headers: {
        Authorization: `Bearer ${client.state.tokenResponse.access_token}`,
      },
    })
    .then((res) => {
      const obj = {userInfo: res.data, idToken};
      dispatch(actions.SAVE_USER_INFO(obj));
    });

  //dispatch(queryUserInfo(client));
  //dispatch(actions.SAVE_ID_TOKEN(idTokenData));

  dispatch(Terminology.getAll());
  // Get the users and then get any organizations on the current user
  dispatch(Users.getAll(client)).then(() => {
    // const users: User[] = getState().users.byId;
    // const user = Object.values(users).find((user: User) => user.username === idTokenData.sub);
    // if (user) dispatch(Organizations.getAllForUser(client, user));
  });

  dispatch(Organizations.getAll(client));
};

/**
 * Calls the /userinfo endpoint to get information about the logged in user
 * @param client
 */
export const queryUserInfo = (client) => async (dispatch, getState) => {
  const {actions} = accountSlice;

  const iss = getState().account.idToken.iss;

  axios
    .get(`${iss}/userinfo`, {
      headers: {
        Authorization: `Bearer ${client.state.tokenResponse.access_token}`,
      },
    })
    .then((res) => {
      console.log(res.data);
      dispatch(actions.SAVE_USER_INFO(res.data));
    });
};

// export const logout = (client) => async (dispatch, getState) => {
// const iss = getState().account.idToken.iss;
// return axios
//   .post(
//     `${iss}/logout?cb=none&revoke=token&revoke=token_refresh`,
//     {},
//     {
//       headers: {
//         Authorization: `Bearer ${client.state.tokenResponse.access_token}`,
//       },
//       withCredentials: true,
//     }
//   )
//   .then((res) => {
//     console.log(res.data);

//     const smartKey = sessionStorage.getItem('SMART_KEY');
//     if (smartKey) {
//       const smartKeyNoQuotes = smartKey.replace(/['"]+/g, '');
//       sessionStorage.removeItem(smartKeyNoQuotes);
//     }
//     sessionStorage.removeItem('SMART_KEY');
//   });
// };

export const getUsername = (state) => state.account.idToken?.sub;

export const getUsers = (state) => Object.values(state.users.byId) as any;

// export const grabCurrentUser = (client) => {
//   return axios
//   .get(`https://canimmunize.demo.smilecdr.com/json-admin/user-management?searchTerm=admin`, {
//     headers: {
//       Authorization: `Bearer ${client.state.tokenResponse.access_token}`,
//     },
//   })
// }

export const getCurrentUser = createSelector(
  [getUsername, getUsers],
  (username: string, users: User[]) => {
    let user = users.find((user: User) => user.username === username);
    if (user) {
      user = produce(user, (draft) => {
        draft.superuser = isSuperuser(user);
      });
    }
    return user;
  }
);

export const useCurrentUser = () => {
  return useAuth0().user;
};
