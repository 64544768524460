import {DownOutlined} from '@ant-design/icons';
import {Button, Col, Dropdown, Menu, Row, Typography} from 'antd';
import {FieldArray} from 'formik';
import {Moment} from 'moment-timezone';
import React from 'react';
import {AvailabilityRuleType} from '../../../../interface-models/availability-rules/availability-rule-types/availability-rule-type';
import {Clinic} from '../../../../models/clinics';
import {getEnumValues} from '../../../../util/enum-util';
import {getLabelForRuleType} from './availability-rule-form-fields';
import {AvailabilityPanel} from './availability-rule-panel';
import {NewAvailabilityModal} from './new-availability-rule-modal';

/**
 *
 *
 * @interface IAvailabilityRulesFieldProps
 */
interface IAvailabilityRulesFieldProps {
  /**
   * The name of the field
   *
   * @type {string}
   * @memberof IAvailabilityRulesFieldProps
   */
  fieldName: string;

  clinic: Clinic;
}

/** Component that returns a Formik Field for availabilityRules */
export const AvailabilityRulesField = (props: IAvailabilityRulesFieldProps) => {
  const {clinic} = props;
  /* Whether the user is currently adding a new rule */
  const [addingNewRule, setAddingNewRule] = React.useState(false);
  /* Keeps track of the new rule the user wants to add */
  const [newRuleType, setNewRuleType] = React.useState(AvailabilityRuleType.NonRecurring);

  return (
    <FieldArray name={props.fieldName}>
      {(fieldArrayHelpers) => {
        const rules = fieldArrayHelpers.form.values[props.fieldName].slice();

        return (
          <>
            {/* Modal for adding new availability rule */}
            {addingNewRule && (
              <NewAvailabilityModal
                clinic={clinic}
                ruleType={newRuleType}
                isModalOpen={addingNewRule}
                closeModal={setAddingNewRule.bind(null, false)}
                addAvailabilityRule={(newRule) => {
                  fieldArrayHelpers.push(newRule);
                }}
              />
            )}

            {/* Title */}
            <Row gutter={15} style={{marginTop: 15}}>
              <Col>
                <Typography.Title level={4}>Availabilities</Typography.Title>
              </Col>
            </Row>

            {/* Add New Rule Button */}
            <Row gutter={[15, 15]} style={{marginBottom: 15}}>
              <Col>
                <Dropdown
                  overlay={
                    <Menu
                      onClick={(item) => {
                        /* Cast to number insce antd converts the key to a string */
                        setNewRuleType(Number(item.key));
                        setAddingNewRule(true);
                      }}
                    >
                      {getEnumValues(AvailabilityRuleType).map((ruleType) => {
                        return (
                          <Menu.Item key={ruleType}>
                            {getLabelForRuleType(ruleType as AvailabilityRuleType)}
                          </Menu.Item>
                        );
                      })}
                    </Menu>
                  }
                  trigger={['click']}
                >
                  <Button
                    type="primary"
                    className="ant-dropdown-link"
                    onClick={(e) => e.preventDefault()}
                  >
                    Add New Rule <DownOutlined />
                  </Button>
                </Dropdown>
              </Col>
            </Row>

            {/* Render the panels for each rule */}
            {rules.map((value, index) => {
              return (
                <AvailabilityPanel
                  clinic={clinic}
                  rule={value}
                  ruleIndex={index}
                  onDeleteRule={() => {
                    fieldArrayHelpers.remove(index);
                  }}
                />
              );
            })}
          </>
        );
      }}
    </FieldArray>
  );
};
