import {AbstractValidationRule} from './abstract-validation-rule';

export const PhoneValidationRuleId = 'phone';

export interface PhoneValidationRule extends AbstractValidationRule {
  validationRuleType: typeof PhoneValidationRuleId;
}
/**
 * Validation to check if a phone number is valid
 *
 * @export
 * @param {*} value
 * @returns {boolean} True if validation passed. False otherwise.
 */
export function validatePhoneRule(phone: string): boolean {
  if (phone === null || phone === undefined) return true;

  const phoneClean = phone.trim();

  // Remove all of the non-numeric characters from the phone number
  const numericPhone = phoneClean.replace(/[^0-9]/g, '');

  //This can be caught by the RequiredValidationRule instead, this function can only check formatting
  if (!(phoneClean && phoneClean.length)) return true;

  // Phone must be 10 digits long (i.e. full number)
  if (numericPhone.length !== 10) {
    return false;
  }

  // Phone number cannot start with 1
  if (parseInt(numericPhone.substring(0, 1)) === 1) {
    return false;
  }

  return true;
}
