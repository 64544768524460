"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_Bundle = exports.BundleTypeKind = void 0;
var t = __importStar(require("io-ts"));
var RTTI_id_1 = require("../Scalar/RTTI_id");
var RTTI_Meta_1 = require("./RTTI_Meta");
var RTTI_uri_1 = require("../Scalar/RTTI_uri");
var RTTI_Element_1 = require("./RTTI_Element");
var RTTI_code_1 = require("../Scalar/RTTI_code");
var RTTI_Identifier_1 = require("./RTTI_Identifier");
var RTTI_instant_1 = require("../Scalar/RTTI_instant");
var RTTI_unsignedInt_1 = require("../Scalar/RTTI_unsignedInt");
var RTTI_Bundle_Link_1 = require("./RTTI_Bundle_Link");
var RTTI_Bundle_Entry_1 = require("./RTTI_Bundle_Entry");
var RTTI_Signature_1 = require("./RTTI_Signature");
var BundleTypeKind;
(function (BundleTypeKind) {
    BundleTypeKind["_document"] = "document";
    BundleTypeKind["_message"] = "message";
    BundleTypeKind["_transaction"] = "transaction";
    BundleTypeKind["_transactionResponse"] = "transaction-response";
    BundleTypeKind["_batch"] = "batch";
    BundleTypeKind["_batchResponse"] = "batch-response";
    BundleTypeKind["_history"] = "history";
    BundleTypeKind["_searchset"] = "searchset";
    BundleTypeKind["_collection"] = "collection";
})(BundleTypeKind = exports.BundleTypeKind || (exports.BundleTypeKind = {}));
var EnumType_1 = require("../../EnumType");
exports.RTTI_Bundle = t.recursion('IBundle', function () {
    return t.intersection([
        t.type({
            resourceType: t.literal('Bundle')
        }),
        t.partial({
            id: RTTI_id_1.RTTI_id,
            meta: RTTI_Meta_1.RTTI_Meta,
            implicitRules: RTTI_uri_1.RTTI_uri,
            _implicitRules: RTTI_Element_1.RTTI_Element,
            language: RTTI_code_1.RTTI_code,
            _language: RTTI_Element_1.RTTI_Element,
            identifier: RTTI_Identifier_1.RTTI_Identifier,
            type: EnumType_1.createEnumType(BundleTypeKind, 'BundleTypeKind'),
            _type: RTTI_Element_1.RTTI_Element,
            timestamp: RTTI_instant_1.RTTI_instant,
            _timestamp: RTTI_Element_1.RTTI_Element,
            total: RTTI_unsignedInt_1.RTTI_unsignedInt,
            _total: RTTI_Element_1.RTTI_Element,
            link: t.array(RTTI_Bundle_Link_1.RTTI_Bundle_Link),
            entry: t.array(RTTI_Bundle_Entry_1.RTTI_Bundle_Entry),
            signature: RTTI_Signature_1.RTTI_Signature
        })
    ]);
});
