import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Col, Row, Typography} from 'antd';
import {FHIRTable} from '../fhir-table';

const {Title} = Typography;

export const SearchPage = (props) => {
  const {
    showSearchTitle = true,
    searchPageTitle,
    pluralName,
    searchPageDescription,
    tableConfig,
    slugs,
    modalCreateForm,
    TableFooter,
    ItemForm,
  } = props;
  const [createFormModalVisible, setCreateFormModalVisible] = React.useState(false);
  const navigate = useNavigate();
  return (
    <>
      {showSearchTitle && (
        <>
          <Title level={1} style={{color: 'white'}}>
            {searchPageTitle || pluralName}
          </Title>
          {searchPageDescription && <p style={{color: 'white'}}>{searchPageDescription}</p>}
        </>
      )}
      {/* <SearchTable /> */}
      <div>
        <Row gutter={[16, 0]}>
          <Col md={24}>
            <FHIRTable
              onClickRow={(item) => navigate(`/${slugs}/${item.id}`)}
              onClickCreateButton={
                modalCreateForm
                  ? () => {
                      setCreateFormModalVisible(true);
                    }
                  : undefined
              }
              newResourceRoute={`/${slugs}/new`}
              {...tableConfig}
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col md={24}>{TableFooter ? <TableFooter /> : null}</Col>
        </Row>
      </div>
      <ItemForm
        modal
        modalVisible={createFormModalVisible}
        setModalVisible={setCreateFormModalVisible}
      />
    </>
  );
};

interface BaseSearchPageProps {
  columns?: any;
  fixedFilters?: any;
  searchPageTitle?: string;
  searchBarPlaceholder?: string;
  createButtonTitle?: string;
  CreateButton?: any;
  onClickRow?: (item: any) => void;
  searchPageDescription?;
  pluralName?;
  slugs?;
  modalCreateForm?;
  showSearchTitle?;
  TableFooter?;
  tableConfig?;
  ItemForm?;
}
export const BaseSearchPage = (props: BaseSearchPageProps) => {
  console.log('PROPS', props);

  const {
    searchPageDescription,
    pluralName,
    slugs,
    modalCreateForm,
    showSearchTitle,
    TableFooter,
    tableConfig,
    ItemForm,
  } = props;

  const [createFormModalVisible, setCreateFormModalVisible] = React.useState(false);
  const navigate = useNavigate();

  console.log(tableConfig);

  return (
    <>
      {showSearchTitle ||
        (true && (
          <>
            <Title level={1} style={{color: 'white'}}>
              {props.searchPageTitle || pluralName}
            </Title>
            {searchPageDescription && <p style={{color: 'white'}}>{searchPageDescription}</p>}
          </>
        ))}

      <div>
        <Row gutter={[16, 0]}>
          <Col md={24}>
            <FHIRTable
              onClickRow={
                props.onClickRow ? props.onClickRow : (item) => navigate(`/${slugs}/${item.id}`)
              }
              onClickCreateButton={
                modalCreateForm
                  ? () => {
                      setCreateFormModalVisible(true);
                    }
                  : undefined
              }
              newResourceRoute={`/${slugs}/new`}
              fixedFilters={props.fixedFilters}
              searchBarPlaceholder={props.searchBarPlaceholder}
              columns={props.columns}
              createButtonTitle={props.createButtonTitle}
              CreateButton={props.CreateButton}
              {...tableConfig}
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col md={24}>{TableFooter ? <TableFooter /> : null}</Col>
        </Row>
      </div>
      {ItemForm && (
        <ItemForm
          modal
          modalVisible={createFormModalVisible}
          setModalVisible={setCreateFormModalVisible}
        />
      )}
    </>
  );
};

/** Generates a tooltip title based on the text identifier provided
 * @param identifier String identifying which title to return
 * @type {string}
 */
export const toolTipTitle = (identifier: string) => {
  switch (identifier) {
    case 'patient-delete':
      return 'You can only delete a patient that is within your organizational tree. A patient cannot be deleted if they have a future appointment, completed appointment, or dose on their profile.';
    default:
      return undefined;
  }
};
