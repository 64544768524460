import React from 'react';
import {PageProps} from '../../config';
import {useCOVIDPortalConfig} from '../../util/portal-config';
import {NovaScotiaPortalAuthForm} from './portal-ns';
import {PEIPortalAuthForm} from './portal-pei';
import {YukonPortalAuthPage} from './portal-yukon/index';

const authSchemes = [
  {
    key: 'ns',
    type: 'portal',
    form: NovaScotiaPortalAuthForm,
  },
  {
    key: 'pei',
    type: 'portal',
    form: PEIPortalAuthForm,
  },
  {
    key: 'yukon',
    type: 'portal',
    form: YukonPortalAuthPage,
  },
];

export const AuthPage = (props: PageProps) => {
  const {config} = useCOVIDPortalConfig();

  let authSchemeSettingsKey = `${props.mode}AuthSchemeKey`;
  const authSchemeKey = config[authSchemeSettingsKey];

  const authScheme = authSchemes.find(
    (scheme) => scheme.type === props.mode && scheme.key === authSchemeKey
  );

  if (!authScheme) return <div>No Auth Scheme Specified</div>;

  return <authScheme.form {...props} />;
};
