import {createSlice} from '@reduxjs/toolkit';
import {normalize, schema} from 'normalizr';
import querystring from 'querystring';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState, ThunkDispatch} from '.';
import {FhirUtils} from '../services/fhir';
import {mapBundleToResourceArray} from '../util/fhir';
import {RequiredValidationRuleId} from '../validation/validation-rules/required-validation';
import {ValidationRules} from '../validation/validation-rules/validation-rules';
import {User} from './users-smile';

export interface Portal {
  id: string;
  name: string;
  organizationId: string;
}

export const PortalValidation: {
  [property: string]: ValidationRules[];
} = {
  name: [
    {
      validationRuleType: RequiredValidationRuleId,
    },
  ],
};

export interface PortalsSliceInterface {
  byId: {[string: string]: Portal};
  historyById: {[string: string]: Portal};
}
const initialState = {
  byId: {},
  historyById: {},
};
const portal = new schema.Entity('portals', {});
const portalHistory = new schema.Entity(
  'portals',
  {},
  {
    idAttribute: (value) => {
      return `${value.id}-${value.meta.versionId}`;
    },
  }
);

const slice = createSlice({
  name: 'portals',
  initialState,
  reducers: {
    SAVE_PORTALS: (state: PortalsSliceInterface, action) => {
      const resources = mapBundleToResourceArray(action.payload);
      state.byId = normalize(resources, [portal]).entities.portals || {};
    },
    SAVE_PORTAL: (state: PortalsSliceInterface, action) => {
      state.byId[action.payload.id] = action.payload;
    },
    DELETE_PORTAL: (state: PortalsSliceInterface, action) => {
      delete state.byId[action.payload.id];
    },
    SAVE_PORTAL_HISTORY: (state: PortalsSliceInterface, action) => {
      const resources = mapBundleToResourceArray(action.payload);
      state.historyById = normalize(resources, [portalHistory]).entities.portals || {};
    },
  },
});

export const getAll = (client, params?) => async (dispatch) => {
  let query = params ? querystring.stringify(params) : undefined;
  const res = await client.get(`Portal${query ? `?${query}` : ''}`);
  dispatch(slice.actions.SAVE_PORTALS(res.data));
  return res.data;
};

const getOne = (client, id) => async (dispatch) => {
  return client.get(`/Portal/${id}`).then((res) => {
    return dispatch(slice.actions.SAVE_PORTAL(res.data));
  });
};

const getAllForUser = (client, user: User) => async (dispatch) => {
  const orgIds = user.authorities.reduce((orgIds, auth) => {
    if (auth.permission === 'FHIR_READ_ALL_IN_COMPARTMENT' && auth.argument.startsWith('Portal/')) {
      const orgId = auth.argument.split('/')[1];
      return orgIds.concat([orgId]);
    }
    return orgIds;
  }, []);

  orgIds.forEach((id) => dispatch(getOne(client, id)));
};

const getOneHistory = (client, id) => async (dispatch) => {
  return client.request(`/Portal/${id}/_history`).then((res) => {
    return dispatch(slice.actions.SAVE_PORTAL_HISTORY(res));
  });
};

const updateOne = (client, org) => async (dispatch) => {
  return client.put(`/Portal/${org.id}`, org).then(async (res) => {
    await dispatch(slice.actions.SAVE_PORTAL(res.data));
    return res.data;
  });
};

export const createOne = (client, org) => async (dispatch) => {
  return client.post('/Portal', org).then(async (res) => {
    await dispatch(slice.actions.SAVE_PORTAL(res.data));
    return res.data;
  });
};

export const deleteOne = (client, itemId) => async (dispatch) => {
  return client.delete(`/Portal/${itemId}`).then(async (res) => {
    await dispatch(slice.actions.DELETE_PORTAL({id: itemId}));
    return res.data;
  });
};

export default {
  slice,
  getAll,
  getAllForUser,
  getOne,
  updateOne,
  createOne,
  getOneHistory,
  deleteOne,
};

export function userHasAuthorityForPortal(user, orgId): boolean {
  return user.authorities?.find(
    (auth) =>
      (auth.permission === 'FHIR_READ_ALL_IN_COMPARTMENT' ||
        auth.permission === 'FHIR_WRITE_ALL_IN_COMPARTMENT') &&
      auth.argument === `Portal/${orgId}`
  );
}

export const usePortals = () => {
  const thunkDispatch = useDispatch<ThunkDispatch>();
  const client = FhirUtils.useClient();

  React.useEffect(() => {
    thunkDispatch(getAll(client));
  }, []);

  const portals = useSelector((state: RootState) => state.portals.byId);

  return Object.values(portals);
};

export const usePortalWithId = (id) => {
  const thunkDispatch = useDispatch<ThunkDispatch>();
  const client = FhirUtils.useClient();

  React.useEffect(() => {
    if (!id) return;
    thunkDispatch(getOne(client, id));
  }, [id]);

  const portal = useSelector((state: RootState) => state.portals.byId[id]);

  return portal;
};
