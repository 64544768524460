import {faRocket} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Card, Col, Row, Statistic, Table, Typography} from 'antd';
import React from 'react';
import {useSelector} from 'react-redux';
import {Navigate} from 'react-router';
import {AppointmentStats} from '../components/dashboard/appointment-stats';
import {RootState} from '../models';
import {useGlobalOrg} from '../models/ui';
import {useEnvInfo} from '../services/environment';
import {FhirUtils, useRole} from '../services/fhir';
import {getExtensionValue} from '../util/fhir';

const {Title} = Typography;

export const DashboardView = () => {
  const selectedOrg = useGlobalOrg();
  const client = FhirUtils.useAxiosClient();
  const [patientCount, setPatientCount] = React.useState(-1);
  const [appointmentCount, setAppointmentCount] = React.useState(-1);
  const {supportEmail} = useEnvInfo();

  const logoUrl = selectedOrg
    ? getExtensionValue(selectedOrg!, 'https://canimmunize.ca/StructureDefinition/org-logo-url')
    : undefined;

  const fetchPatientCount = async () => {
    const result = await client.get(
      `/patient?_organizationId=${selectedOrg?.id || ''}&_count=0.1&_total=accurate&_type=count`
    );
    setPatientCount(result.data.total);
  };

  const fetchAppointmentCount = async () => {
    const result = await client.get(
      `/appointment?_organizationId=${selectedOrg?.id || ''}&_count=0.1&_total=accurate&_type=count`
    );
    setAppointmentCount(result.data.total);
  };

  React.useEffect(() => {
    fetchPatientCount();
    fetchAppointmentCount();
  }, [selectedOrg?.id]);

  const role = useRole();
  console.log(role, 'ROLE');
  if (role === 'hc-provider') return <Navigate to="/patientlookup" />;
  else if (role === 'yk-call-centre') return <Navigate to="/en/ykcallcentre" />;

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col md={12}>
          {selectedOrg && (
            <div style={{fontWeight: 600, fontSize: 14, color: 'white', paddingBottom: 5}}>
              Dashboard
            </div>
          )}
          {logoUrl ? (
            <img src={logoUrl} style={{maxWidth: 200}} alt={`${selectedOrg?.name.en} Logo`} />
          ) : (
            <div
              style={{
                fontWeight: 600,
                fontSize: logoUrl ? 14 : 38,
                color: 'white',
                paddingBottom: 5,
              }}
            >
              {selectedOrg?.name.en || 'Dashboard'}
            </div>
          )}
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{marginBottom: 16}}>
        <Col span={24}>
          <Card>
            <Title level={2}>🚀 Welcome to Clinic Flow! </Title>
            <p>
              For support or questions about Clinic Flow contact us at{' '}
              <a href={`mailto:${supportEmail}`}>{supportEmail}</a>.
            </p>
          </Card>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{marginBottom: 16}}>
        <Col span={12}>
          <Card>
            <StatisticLadda title="Patients" value={patientCount} />
          </Card>
        </Col>
        <Col span={12}>
          <Card>
            <StatisticLadda title="Appointments" value={appointmentCount} />
          </Card>
        </Col>
      </Row>

      <Row gutter={[16, 16]} style={{marginBottom: 16}}>
        <Col span={24}>
          <BookingPageListWidget />
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <AppointmentStats />
        </Col>
      </Row>
      {/* This is for spacing at bottom */}
      <Row gutter={[0, 60]}>
        <Col span={24} />
      </Row>
    </>
  );
};

const StatisticLadda = (props) => {
  return <Statistic loading={props.value === -1} {...props} value={props.value} />;
};

const BookingPageListWidget = () => {
  const globalOrg = useSelector((state: RootState) => state.ui.localOrg);
  const client = FhirUtils.useAxiosClient();
  const [bookingPages, setBookingPages] = React.useState([]);

  React.useEffect(() => {
    client
      .get(`/booking-page${globalOrg ? `?_organizationId=${globalOrg.id}` : ``}`)
      .then((res) => {
        setBookingPages(
          res.data.entry?.map((e) => e.resource)?.filter((bp) => bp.showOnDashboard === 'true') ||
            []
        );
      });
  }, [globalOrg?.id]);

  return (
    <Card>
      <Title level={4}>Booking Pages</Title>
      <Table
        dataSource={bookingPages}
        rowKey={({id}) => id}
        columns={[
          {
            dataIndex: 'name',
            key: 'name',
          },
          {
            render: (bookingPage) => {
              return (
                <Button
                  type="primary"
                  size={'small'}
                  href={`/en/${bookingPage.bookingPageSlug.en}`}
                  target="_blank"
                  style={{paddingLeft: 10, paddingRight: 10}}
                >
                  <FontAwesomeIcon icon={faRocket} color="white" style={{marginRight: 10}} />
                  Launch Booking Page
                </Button>
              );
            },
          },
        ]}
      />
    </Card>
  );
};
