import {Button, Modal} from 'antd';
import React from 'react';
import {FhirUtils} from '../../services/fhir';
import uuid from 'uuid';
import {Ids} from '@canimmunize/tools';
import {useStr} from '../../services/str';

export default function BulkPvcButton(props) {
  const {selectedRowKeys, refresh} = props;
  const [submitting, setSubmitting] = React.useState(false);
  const client = FhirUtils.useAxiosClient();
  const Str = useStr();

  const downloadBulkPvc = async () => {
    setSubmitting(true);
    client
      .get(`/mail-request/bulk-pvc?ids=${selectedRowKeys}`)
      .then((res) => {
        refresh(uuid.v4());
        const pdfBase64 = res.data?.pdf;
        const bytes = atob(pdfBase64);
        let length = bytes.length;
        let out = new Uint8Array(length);

        while (length--) {
          out[length] = bytes.charCodeAt(length);
        }

        const blob = new Blob([out], {type: 'application/pdf'});
        const iframe = document.createElement('iframe');
        iframe.style.visibility = 'hidden';
        iframe.src = window.URL.createObjectURL(blob);
        document.body.appendChild(iframe);
        iframe!.contentWindow!.focus();
        iframe!.contentWindow!.print();
      })
      .catch((err) => {
        setSubmitting(false);
        Modal.error({
          title: Str(Ids.error),
          content: `${'Failed to download PVC'}`,
        });
      });
  };

  return (
    <Button
      type="primary"
      size="large"
      loading={submitting}
      disabled={selectedRowKeys.length === 0}
      onClick={() => {
        downloadBulkPvc();
      }}
    >
      Print bulk PVC
    </Button>
  );
}
