"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_StructureMap_Group = exports.StructureMap_GroupTypeModeKind = void 0;
var t = __importStar(require("io-ts"));
var RTTI_Extension_1 = require("./RTTI_Extension");
var RTTI_id_1 = require("../Scalar/RTTI_id");
var RTTI_Element_1 = require("./RTTI_Element");
var RTTI_StructureMap_Input_1 = require("./RTTI_StructureMap_Input");
var RTTI_StructureMap_Rule_1 = require("./RTTI_StructureMap_Rule");
var StructureMap_GroupTypeModeKind;
(function (StructureMap_GroupTypeModeKind) {
    StructureMap_GroupTypeModeKind["_none"] = "none";
    StructureMap_GroupTypeModeKind["_types"] = "types";
    StructureMap_GroupTypeModeKind["_typeAndTypes"] = "type-and-types";
})(StructureMap_GroupTypeModeKind = exports.StructureMap_GroupTypeModeKind || (exports.StructureMap_GroupTypeModeKind = {}));
var EnumType_1 = require("../../EnumType");
exports.RTTI_StructureMap_Group = t.recursion('IStructureMap_Group', function () {
    return t.intersection([
        t.type({
            input: t.array(RTTI_StructureMap_Input_1.RTTI_StructureMap_Input),
            rule: t.array(RTTI_StructureMap_Rule_1.RTTI_StructureMap_Rule)
        }),
        t.partial({
            id: t.string,
            extension: t.array(RTTI_Extension_1.RTTI_Extension),
            modifierExtension: t.array(RTTI_Extension_1.RTTI_Extension),
            name: RTTI_id_1.RTTI_id,
            _name: RTTI_Element_1.RTTI_Element,
            extends: RTTI_id_1.RTTI_id,
            _extends: RTTI_Element_1.RTTI_Element,
            typeMode: EnumType_1.createEnumType(StructureMap_GroupTypeModeKind, 'StructureMap_GroupTypeModeKind'),
            _typeMode: RTTI_Element_1.RTTI_Element,
            documentation: t.string,
            _documentation: RTTI_Element_1.RTTI_Element
        })
    ]);
});
