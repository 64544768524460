"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_MolecularSequence_Quality = exports.MolecularSequence_QualityTypeKind = void 0;
var t = __importStar(require("io-ts"));
var RTTI_Extension_1 = require("./RTTI_Extension");
var RTTI_Element_1 = require("./RTTI_Element");
var RTTI_CodeableConcept_1 = require("./RTTI_CodeableConcept");
var RTTI_integer_1 = require("../Scalar/RTTI_integer");
var RTTI_Quantity_1 = require("./RTTI_Quantity");
var RTTI_decimal_1 = require("../Scalar/RTTI_decimal");
var RTTI_MolecularSequence_Roc_1 = require("./RTTI_MolecularSequence_Roc");
var MolecularSequence_QualityTypeKind;
(function (MolecularSequence_QualityTypeKind) {
    MolecularSequence_QualityTypeKind["_indel"] = "indel";
    MolecularSequence_QualityTypeKind["_snp"] = "snp";
    MolecularSequence_QualityTypeKind["_unknown"] = "unknown";
})(MolecularSequence_QualityTypeKind = exports.MolecularSequence_QualityTypeKind || (exports.MolecularSequence_QualityTypeKind = {}));
var EnumType_1 = require("../../EnumType");
exports.RTTI_MolecularSequence_Quality = t.recursion('IMolecularSequence_Quality', function () {
    return t.partial({
        id: t.string,
        extension: t.array(RTTI_Extension_1.RTTI_Extension),
        modifierExtension: t.array(RTTI_Extension_1.RTTI_Extension),
        type: EnumType_1.createEnumType(MolecularSequence_QualityTypeKind, 'MolecularSequence_QualityTypeKind'),
        _type: RTTI_Element_1.RTTI_Element,
        standardSequence: RTTI_CodeableConcept_1.RTTI_CodeableConcept,
        start: RTTI_integer_1.RTTI_integer,
        _start: RTTI_Element_1.RTTI_Element,
        end: RTTI_integer_1.RTTI_integer,
        _end: RTTI_Element_1.RTTI_Element,
        score: RTTI_Quantity_1.RTTI_Quantity,
        method: RTTI_CodeableConcept_1.RTTI_CodeableConcept,
        truthTP: RTTI_decimal_1.RTTI_decimal,
        _truthTP: RTTI_Element_1.RTTI_Element,
        queryTP: RTTI_decimal_1.RTTI_decimal,
        _queryTP: RTTI_Element_1.RTTI_Element,
        truthFN: RTTI_decimal_1.RTTI_decimal,
        _truthFN: RTTI_Element_1.RTTI_Element,
        queryFP: RTTI_decimal_1.RTTI_decimal,
        _queryFP: RTTI_Element_1.RTTI_Element,
        gtFP: RTTI_decimal_1.RTTI_decimal,
        _gtFP: RTTI_Element_1.RTTI_Element,
        precision: RTTI_decimal_1.RTTI_decimal,
        _precision: RTTI_Element_1.RTTI_Element,
        recall: RTTI_decimal_1.RTTI_decimal,
        _recall: RTTI_Element_1.RTTI_Element,
        fScore: RTTI_decimal_1.RTTI_decimal,
        _fScore: RTTI_Element_1.RTTI_Element,
        roc: RTTI_MolecularSequence_Roc_1.RTTI_MolecularSequence_Roc
    });
});
