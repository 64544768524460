import React from 'react';
import debounce from 'lodash/debounce';
import {Spin} from 'antd';
import {SelectProps} from 'antd/es/select';
import * as Icons from '@ant-design/icons';
import {AutoComplete, Select} from 'formik-antd';

interface DebounceSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType>, 'options' | 'children'> {
  fetchOptions: (search?: string) => Promise<ValueType[]>;
  name: string;
  noneOption?: {label: string; value: string};
  defaultOption?: {label: string; value: string} | null;
  allowFreeForm?: boolean;
}

export function DebounceSelect<
  ValueType extends {key?: string; label: React.ReactNode; value: string | number} = any
>({fetchOptions, name, showSearch, noneOption, defaultOption, ...props}: DebounceSelectProps) {
  const [fetching, setFetching] = React.useState(false);
  const [options, setOptions] = React.useState<ValueType[] | undefined>(undefined);
  const fetchRef = React.useRef(0);

  const debounceFetcher = (debounceTimeout: number) => {
    const loadOptions = (value?: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions(undefined);
      setFetching(true);
      value = typeof value === 'string' && value.length ? value : undefined;

      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }

        if (noneOption) newOptions = [noneOption, ...newOptions];
        if (defaultOption) {
          /* If the default already exists in the list */
          const loadedDefault = newOptions.find((v) => v.value === defaultOption.value);
          if (!loadedDefault) newOptions = [defaultOption, ...newOptions];
        }
        setOptions(newOptions);
        setFetching(false);
      });
    };
    return debounce((value) => loadOptions(value), debounceTimeout);
  };

  return props.allowFreeForm ? (
    <AutoComplete
      name={name}
      onSearch={debounceFetcher(800)}
      onFocus={debounceFetcher(0)}
      /* reset options after debouncer loses focus and dropdown menu disappearing animation is complete. */
      onBlur={() => setTimeout(() => setOptions(undefined), 300)}
      notFoundContent={
        fetching ? <Spin size="small" /> : options !== undefined ? 'No eligible data' : null
      }
      {...props}
      placeholder={props.disabled ? undefined : props.placeholder}
      options={options}
    />
  ) : (
    <Select
      name={name}
      showSearch={showSearch}
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher(800)}
      onFocus={debounceFetcher(0)}
      /* reset options after debouncer loses focus and dropdown menu disappearing animation is complete. */
      onBlur={() => setTimeout(() => setOptions(undefined), 300)}
      notFoundContent={
        fetching ? <Spin size="small" /> : options !== undefined ? 'No eligible data' : null
      }
      {...props}
      placeholder={props.disabled ? undefined : props.placeholder}
      options={options}
      suffixIcon={showSearch ? <Icons.SearchOutlined /> : undefined}
    />
  );
}
