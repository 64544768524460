import produce from 'immer';
import React from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '../../models';
import Organization, {userHasAuthorityForOrganization} from '../../models/organizations';

import {ClinicFormCustomFieldName} from '../clinic/clinic-form-ux-model';
import {FHIRTableMode, FHIRTableProps} from '../fhir-table';
import {FormUX} from '../form-ux';
import {OrganizationFormUXModel} from './organization-fields';
import {OrganizationPickerFormItem} from './organization-picker';
import {BookingPagePickerFormItem} from './booking-page-picker';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExclamationCircle} from '@fortawesome/free-solid-svg-icons';
import {Tooltip, Alert} from 'antd';
import {BaseEntityV2, FormConfig} from '../base-entity/base-entity';

export const OrganizationUX = () => {
  const globalOrg = useSelector((state: RootState) => state.ui.localOrg);

  const fixedFilters: any = globalOrg
    ? {
        _organizationId: globalOrg.id,
        includeFields: true,
      }
    : {includeFields: true};

  const columns = [
    {
      title: `Name`,
      dataIndex: 'nameEn',
      key: 'name',
      render: (_, org) => {
        return (
          <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <div>{org.name.en}</div>
            {!org.defaultBookingPageId && (
              <Tooltip title="Missing Form(s)">
                <FontAwesomeIcon icon={faExclamationCircle} color="red" style={{marginRight: 10}} />
              </Tooltip>
            )}
          </div>
        );
      },
      sorter: true,
    },
    {
      title: `Code`,
      dataIndex: 'organizationCode',
      sorter: true,
    },
    {
      title: `Type`,
      dataIndex: 'type',
      sorter: true,
    },
    {
      title: `Parent Organization`,
      dataIndex: 'parentOrganization.nameEn',
      render: (_, org) => org.parentOrganization?.name.en,
      sorter: true,
    },
  ];

  const model = Organization;

  const itemTitleField = 'nameEn';

  const FormItems = (props) => {
    const {parentOrganizationId, id} = props.values;
    return (
      <FormUX
        formUXModel={OrganizationFormUXModel}
        createMode={props.createMode}
        modal={props.modal}
        renderCustomField={(field) => {
          switch (field.name) {
            case ClinicFormCustomFieldName.ParentOrganizationId: {
              return (
                <OrganizationPickerFormItem
                  label={field.label}
                  name={field.name}
                  setFieldValue={props.setFieldValue}
                  validationRules={field.validationRules}
                  item={props.values}
                />
              );
            }
            case 'defaultBookingPageId': {
              return (
                <>
                  {parentOrganizationId && (
                    <BookingPagePickerFormItem
                      label={field.label}
                      name={field.name}
                      setFieldValue={props.setFieldValue}
                      validationRules={field.validationRules}
                      parentOrganizationId={parentOrganizationId}
                      organizationId={id}
                    />
                  )}
                </>
              );
            }
            default: {
              throw new Error(`Unhandled custom field ${field.name} in
          renderCustomField method`);
            }
          }
        }}
      />
    );
  };

  const titleBarFooter = (item, settings) =>
    !item.defaultBookingPageId ? (
      <Alert
        message="This organization is missing a default booking page."
        description=""
        type="error"
        icon={<FontAwesomeIcon icon={faExclamationCircle} color="red" />}
        showIcon
      />
    ) : undefined;

  const formConfig: FormConfig = {
    defaultValues: {
      organizationId: globalOrg?.id,
    },
    // Item selector is used by the ItemForm to pull the state it needs from the store
    itemSelector:
      ({itemId, slugs}) =>
      (state: RootState) => {
        const item = state[slugs].byId[itemId];
        if (!item) return;
        return produce(item, (draft) => {
          draft.users = Object.values(state.users.byId).filter((user) =>
            userHasAuthorityForOrganization(user, itemId)
          );
        });
      },
    FormItems,
  };

  const tableConfig: FHIRTableProps = {
    fhirResource: 'Organization',
    label: 'Organization',
    fixedFilters,
    mode: 'table' as FHIRTableMode,
    defaultPrimarySearchParam: 'name',
    columns,
    showCreateButton: true,
  };

  const config = {
    slug: 'organization',
    fhirResource: 'Organization',
    model,
    itemTitleField,
    formConfig,
    tableConfig,
    hideEditButton: true,
    titleBarFooter,
    hideDeleteButton: true,
    modalCreateForm: true,
    titleBarEntityLabel: 'Organization',
  };

  return <BaseEntityV2 {...config} />;
};
