import React from 'react';
import {processGender} from '../../../util/helpers/processGender';
import {FHIRTable, FHIRTableProps} from '../../fhir-table';
import {PatientCovidStatusCell} from '../../patients/patient-covid-status-cell';
import {Tag} from 'antd';
import {useNavigate} from 'react-router-dom';

export const ClinicPatientsTable = ({clinicId}) => {
  const navigate = useNavigate();

  const tableConfig: FHIRTableProps = {
    fhirResource: 'Patient',
    label: 'Patient',
    fixedFilters: {clinicId},
    mode: 'table',
    showCreateButton: false,
    defaultPrimarySearchParam: 'name',
    onClickRow: (item) => navigate(`/patients/${item.id}`),
    columns: [
      {
        title: 'Name',
        dataIndex: 'fullName',
        sorter: (a,b) => a.fullName.toLowerCase().localeCompare(b.fullName.toLowerCase()),
        render: (_, p) => (
          <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <div>{p.fullName}</div>
            {p.deceased && <Tag color="red">Deceased</Tag>}
          </div>
        ),
      },
      {
        title: 'Email',
        dataIndex: 'email',
        sorter: true,
      },
      {
        title: 'DOB',
        dataIndex: 'birthdate',
        sorter: true,
      },
      {
        title: 'Gender',
        dataIndex: 'gender',
        render: (_, patient) => processGender(patient.gender),
      },
      {
        title: 'HCN',
        dataIndex: 'hcn',
        sorter: true,
      },
      // {
      //   title: 'COVID-19 Vaccination Status',
      //   render: (_, patient) => <PatientCovidStatusCell patient={patient} />,
      // },
    ],
  };

  return <FHIRTable {...tableConfig} />;
};
