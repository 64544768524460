"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_EvidenceVariable_Characteristic = exports.EvidenceVariable_CharacteristicGroupMeasureKind = void 0;
var t = __importStar(require("io-ts"));
var RTTI_Extension_1 = require("./RTTI_Extension");
var RTTI_Element_1 = require("./RTTI_Element");
var RTTI_Reference_1 = require("./RTTI_Reference");
var RTTI_CodeableConcept_1 = require("./RTTI_CodeableConcept");
var RTTI_Expression_1 = require("./RTTI_Expression");
var RTTI_DataRequirement_1 = require("./RTTI_DataRequirement");
var RTTI_TriggerDefinition_1 = require("./RTTI_TriggerDefinition");
var RTTI_UsageContext_1 = require("./RTTI_UsageContext");
var RTTI_Period_1 = require("./RTTI_Period");
var RTTI_Duration_1 = require("./RTTI_Duration");
var RTTI_Timing_1 = require("./RTTI_Timing");
var EvidenceVariable_CharacteristicGroupMeasureKind;
(function (EvidenceVariable_CharacteristicGroupMeasureKind) {
    EvidenceVariable_CharacteristicGroupMeasureKind["_mean"] = "mean";
    EvidenceVariable_CharacteristicGroupMeasureKind["_median"] = "median";
    EvidenceVariable_CharacteristicGroupMeasureKind["_meanOfMean"] = "mean-of-mean";
    EvidenceVariable_CharacteristicGroupMeasureKind["_meanOfMedian"] = "mean-of-median";
    EvidenceVariable_CharacteristicGroupMeasureKind["_medianOfMean"] = "median-of-mean";
    EvidenceVariable_CharacteristicGroupMeasureKind["_medianOfMedian"] = "median-of-median";
})(EvidenceVariable_CharacteristicGroupMeasureKind = exports.EvidenceVariable_CharacteristicGroupMeasureKind || (exports.EvidenceVariable_CharacteristicGroupMeasureKind = {}));
var EnumType_1 = require("../../EnumType");
exports.RTTI_EvidenceVariable_Characteristic = t.recursion('IEvidenceVariable_Characteristic', function () {
    return t.partial({
        id: t.string,
        extension: t.array(RTTI_Extension_1.RTTI_Extension),
        modifierExtension: t.array(RTTI_Extension_1.RTTI_Extension),
        description: t.string,
        _description: RTTI_Element_1.RTTI_Element,
        definitionReference: RTTI_Reference_1.RTTI_Reference,
        definitionCanonical: t.string,
        _definitionCanonical: RTTI_Element_1.RTTI_Element,
        definitionCodeableConcept: RTTI_CodeableConcept_1.RTTI_CodeableConcept,
        definitionExpression: RTTI_Expression_1.RTTI_Expression,
        definitionDataRequirement: RTTI_DataRequirement_1.RTTI_DataRequirement,
        definitionTriggerDefinition: RTTI_TriggerDefinition_1.RTTI_TriggerDefinition,
        usageContext: t.array(RTTI_UsageContext_1.RTTI_UsageContext),
        exclude: t.boolean,
        _exclude: RTTI_Element_1.RTTI_Element,
        participantEffectiveDateTime: t.string,
        _participantEffectiveDateTime: RTTI_Element_1.RTTI_Element,
        participantEffectivePeriod: RTTI_Period_1.RTTI_Period,
        participantEffectiveDuration: RTTI_Duration_1.RTTI_Duration,
        participantEffectiveTiming: RTTI_Timing_1.RTTI_Timing,
        timeFromStart: RTTI_Duration_1.RTTI_Duration,
        groupMeasure: EnumType_1.createEnumType(EvidenceVariable_CharacteristicGroupMeasureKind, 'EvidenceVariable_CharacteristicGroupMeasureKind'),
        _groupMeasure: RTTI_Element_1.RTTI_Element
    });
});
