import {createSlice} from '@reduxjs/toolkit';
import {normalize, schema} from 'normalizr';
import querystring from 'querystring';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState, ThunkDispatch} from '.';
import {FhirUtils} from '../services/fhir';
import {mapBundleToResourceArray} from '../util/fhir';

interface OrgAdminUser {
  id: string;
  name: string;
  organizationIds: string[];
  roles: string[];
  email: string;
}

export interface OrgAdminUsersSliceInterface {
  byId: {[string: string]: OrgAdminUser};
}
const initialState = {
  byId: {},
};

const orgadminuser = new schema.Entity('orgadminusers', {});

const slice = createSlice({
  name: 'orgadminusers',
  initialState,
  reducers: {
    SAVE_USERS: (state: OrgAdminUsersSliceInterface, action) => {
      const resources = mapBundleToResourceArray(action.payload);
      state.byId = normalize(resources, [orgadminuser]).entities.orgadminusers || {};
    },
    SAVE_USER: (state: OrgAdminUsersSliceInterface, action) => {
      state.byId[action.payload.id] = action.payload;
    },
    DELETE_USER: (state: OrgAdminUsersSliceInterface, action) => {
      delete state.byId[action.payload.id];
    },
  },
});

export const getAll = (client, params?) => async (dispatch) => {
  let query = params ? querystring.stringify(params) : undefined;
  const res = await client.get(`/org-admin-user${query ? `?${query}` : ''}`);
  dispatch(slice.actions.SAVE_USERS(res.data));
  return res.data;
};

const getOne = (client, id, params?) => async (dispatch) => {
  let query = params ? querystring.stringify(params) : undefined;
  let result: any | undefined;
  try {
    result = (await client.get(`/org-admin-user/${id}${query ? `?${query}` : ''}`)).data;
    dispatch(slice.actions.SAVE_USER(result));
  } catch (error) {
    dispatch(slice.actions.DELETE_USER({id: id}));
  }
  return result;
};

const updateOne = (client, item, params?) => async (dispatch) => {
  let query = params ? querystring.stringify(params) : undefined;
  let result: any | undefined;
  try {
    const response = await client.put(
      `/org-admin-user/${item.id}${query ? `?${query}` : ''}`,
      item
    );
    if (response.data.errMsg) throw new Error(response.data.errMsg);
    result = response.data;
    dispatch(slice.actions.SAVE_USER(result));
  } catch (error) {
    throw error;
  }
  return result;
};

export const createOne = (client, item, params?) => async (dispatch) => {
  let query = params ? querystring.stringify(params) : undefined;
  return client
    .post(`/org-admin-user${query ? `?${query}` : ''}`, {...item, verifyEmail: true})
    .then(async (res) => {
      await dispatch(slice.actions.SAVE_USER(res.data));
      return res.data;
    });
};

export const deleteOne = (client, itemId, params?) => async (dispatch) => {
  let query = params ? querystring.stringify(params) : undefined;
  return client.delete(`/org-admin-user/${itemId}${query ? `?${query}` : ''}`).then(async (res) => {
    await dispatch(slice.actions.DELETE_USER({id: itemId}));
    return res.data;
  });
};

export const useOrgAdminUsers = (params?) => {
  const thunkDispatch = useDispatch<ThunkDispatch>();
  const client = FhirUtils.useClient();

  React.useEffect(() => {
    thunkDispatch(getAll(client, params));
  }, []);

  const users = useSelector((state: RootState) => state.users.byId);
  return Object.values(users);
};

export default {
  slice,
  getAll,
  getOne,
  updateOne,
  createOne,
  deleteOne,
};
