"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_ChargeItem = exports.ChargeItemStatusKind = void 0;
var t = __importStar(require("io-ts"));
var RTTI_id_1 = require("../Scalar/RTTI_id");
var RTTI_Meta_1 = require("./RTTI_Meta");
var RTTI_uri_1 = require("../Scalar/RTTI_uri");
var RTTI_Element_1 = require("./RTTI_Element");
var RTTI_code_1 = require("../Scalar/RTTI_code");
var RTTI_Narrative_1 = require("./RTTI_Narrative");
var RTTI_ResourceList_1 = require("../Union/RTTI_ResourceList");
var RTTI_Extension_1 = require("./RTTI_Extension");
var RTTI_Identifier_1 = require("./RTTI_Identifier");
var RTTI_canonical_1 = require("../Scalar/RTTI_canonical");
var RTTI_Reference_1 = require("./RTTI_Reference");
var RTTI_CodeableConcept_1 = require("./RTTI_CodeableConcept");
var RTTI_Period_1 = require("./RTTI_Period");
var RTTI_Timing_1 = require("./RTTI_Timing");
var RTTI_ChargeItem_Performer_1 = require("./RTTI_ChargeItem_Performer");
var RTTI_Quantity_1 = require("./RTTI_Quantity");
var RTTI_decimal_1 = require("../Scalar/RTTI_decimal");
var RTTI_Money_1 = require("./RTTI_Money");
var RTTI_dateTime_1 = require("../Scalar/RTTI_dateTime");
var RTTI_Annotation_1 = require("./RTTI_Annotation");
var ChargeItemStatusKind;
(function (ChargeItemStatusKind) {
    ChargeItemStatusKind["_planned"] = "planned";
    ChargeItemStatusKind["_billable"] = "billable";
    ChargeItemStatusKind["_notBillable"] = "not-billable";
    ChargeItemStatusKind["_aborted"] = "aborted";
    ChargeItemStatusKind["_billed"] = "billed";
    ChargeItemStatusKind["_enteredInError"] = "entered-in-error";
    ChargeItemStatusKind["_unknown"] = "unknown";
})(ChargeItemStatusKind = exports.ChargeItemStatusKind || (exports.ChargeItemStatusKind = {}));
var EnumType_1 = require("../../EnumType");
exports.RTTI_ChargeItem = t.recursion('IChargeItem', function () {
    return t.intersection([
        t.type({
            resourceType: t.literal('ChargeItem'),
            code: RTTI_CodeableConcept_1.RTTI_CodeableConcept,
            subject: RTTI_Reference_1.RTTI_Reference
        }),
        t.partial({
            id: RTTI_id_1.RTTI_id,
            meta: RTTI_Meta_1.RTTI_Meta,
            implicitRules: RTTI_uri_1.RTTI_uri,
            _implicitRules: RTTI_Element_1.RTTI_Element,
            language: RTTI_code_1.RTTI_code,
            _language: RTTI_Element_1.RTTI_Element,
            text: RTTI_Narrative_1.RTTI_Narrative,
            contained: t.array(RTTI_ResourceList_1.RTTI_ResourceList),
            extension: t.array(RTTI_Extension_1.RTTI_Extension),
            modifierExtension: t.array(RTTI_Extension_1.RTTI_Extension),
            identifier: t.array(RTTI_Identifier_1.RTTI_Identifier),
            definitionUri: t.array(RTTI_uri_1.RTTI_uri),
            _definitionUri: t.array(RTTI_Element_1.RTTI_Element),
            definitionCanonical: t.array(RTTI_canonical_1.RTTI_canonical),
            status: EnumType_1.createEnumType(ChargeItemStatusKind, 'ChargeItemStatusKind'),
            _status: RTTI_Element_1.RTTI_Element,
            partOf: t.array(RTTI_Reference_1.RTTI_Reference),
            context: RTTI_Reference_1.RTTI_Reference,
            occurrenceDateTime: t.string,
            _occurrenceDateTime: RTTI_Element_1.RTTI_Element,
            occurrencePeriod: RTTI_Period_1.RTTI_Period,
            occurrenceTiming: RTTI_Timing_1.RTTI_Timing,
            performer: t.array(RTTI_ChargeItem_Performer_1.RTTI_ChargeItem_Performer),
            performingOrganization: RTTI_Reference_1.RTTI_Reference,
            requestingOrganization: RTTI_Reference_1.RTTI_Reference,
            costCenter: RTTI_Reference_1.RTTI_Reference,
            quantity: RTTI_Quantity_1.RTTI_Quantity,
            bodysite: t.array(RTTI_CodeableConcept_1.RTTI_CodeableConcept),
            factorOverride: RTTI_decimal_1.RTTI_decimal,
            _factorOverride: RTTI_Element_1.RTTI_Element,
            priceOverride: RTTI_Money_1.RTTI_Money,
            overrideReason: t.string,
            _overrideReason: RTTI_Element_1.RTTI_Element,
            enterer: RTTI_Reference_1.RTTI_Reference,
            enteredDate: RTTI_dateTime_1.RTTI_dateTime,
            _enteredDate: RTTI_Element_1.RTTI_Element,
            reason: t.array(RTTI_CodeableConcept_1.RTTI_CodeableConcept),
            service: t.array(RTTI_Reference_1.RTTI_Reference),
            productReference: RTTI_Reference_1.RTTI_Reference,
            productCodeableConcept: RTTI_CodeableConcept_1.RTTI_CodeableConcept,
            account: t.array(RTTI_Reference_1.RTTI_Reference),
            note: t.array(RTTI_Annotation_1.RTTI_Annotation),
            supportingInformation: t.array(RTTI_Reference_1.RTTI_Reference)
        })
    ]);
});
