import {Col, Row} from 'antd';
import {Formik} from 'formik';
import {Form} from 'formik-antd';
import React from 'react';
import {ApplicantInformationPage} from './applicant-info-page';
import {AuthenticationPage} from './authentication-page';
import {CredentialDeliveryPage} from './cred-delivery-page';
import {YukonHCIForm} from './hci-page';
import {InfoPage} from './info-page';
import {IdVerificationPage} from './verification-page';

export const YukonPortalAuthPage = (props) => {
  const initialValues = {
    isYukonCallCentre: props.isYukonCallCentre,
    mode: 'info',
    error: undefined,
    phone: '',
    firstName: '',
    lastName: '',
    birthdate: null,
    sex: null,
    reqForSelf: null,
    reqFirstName: '',
    reqLastName: '',
    reqPhone: '',
    reqEmail: '',
    vaxLocation: '',
    submittedReqForOtherForm: null,
    locationRecordsSubmitted: null,
    deferralApproved: null,
    postalCode: '',
    vaxLotNum: '',
    dateFirstDose: null,
    dateRecentDose: null,
    vaxLotNumRecentDose: '',
    authContectMethods: [],
    selectedAuthContactMethod: null,
    authMethod: '',
    authCode: '',
    subFormSubmit: null,
    deliveryMethod: '',
    selectedMailAddress: '',
    newStreetAddress: '',
    newUnitNum: '',
    newCity: '',
    newPostalCode: '',
    newProvince: null,
    numCopies: 1,
    portalId: '',
    patientInfo: null,
    requestorName: null,
    setClinicEntry: props.setClinicEntry,
  };

  const handleSubmit = async (values, helpers) => {
    if (values.subFormSubmit) {
      values.subFormSubmit();
      return;
    }
    props.setPage(2);
  };

  return (
    <Row>
      <Col span={24}>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {(formikProps) => {
            return (
              <>
                {formikProps.values.mode === 'info' && <InfoPage />}
                <Form layout="vertical" style={{fontSize: 14}}>
                  {formikProps.values.mode === 'app-info' && <ApplicantInformationPage />}
                  {formikProps.values.mode === 'hci' && <YukonHCIForm />}
                  {formikProps.values.mode === 'verification' && <IdVerificationPage />}
                  {formikProps.values.mode === 'authentication' && <AuthenticationPage />}
                  {formikProps.values.mode === 'cred-delivery' && <CredentialDeliveryPage />}
                </Form>
              </>
            );
          }}
        </Formik>
      </Col>
    </Row>
  );
};
