export const matomoJs = (config) => {
  return `
      var _paq = window._paq = window._paq || [];
      _paq.push(['trackPageView']);
      _paq.push(['enableLinkTracking']);
      (function() {
        _paq.push(['setTrackerUrl', '${config.matomoTrackerUrl}.php']);
        _paq.push(['setSiteId', '${config.matomoSiteId}']);
        var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
        g.type='text/javascript'; g.async=true; g.src='${config.matomoTrackerUrl}.js'; s.parentNode.insertBefore(g,s);
      })();
    `;
};

//<noscript><p><img src="${config.matomoTrackerUrl}?idsite=${config.matomoSiteId}&amp;rec=1" style="border:0;" alt="" /></p></noscript>
