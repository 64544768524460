import {combineReducers} from '@reduxjs/toolkit';
import {accountSlice, AccountSliceInterface} from './account';
import {ThunkAction} from 'redux-thunk';
import {Action} from '@reduxjs/toolkit';
import Patient, {PatientsSliceInterface} from './patients';
import Organization, {OrganizationsSliceInterface} from './organizations';
import Tradenames, {TradenamesSliceInterface} from './tradenames';
import Immunization, {ImmunizationsSliceInterface} from './immunizations';
import User, {UsersSliceInterface} from './users';
import OrgAdminUser, {OrgAdminUsersSliceInterface} from './org-admin-users';
import Communication, {CommunicationsSliceInterface} from './communication';
import Terminology, {TerminologySliceInterface} from './terminology';
import Questionnaire, {QuestionnairesSliceInterface} from './questionnaire';
import Cohort, {CohortsSliceInterface} from './cohort';
import UI, {UISliceInterface} from './ui';
import BookingPage, {BookingPagesSliceInterface} from './booking-pages';
import Appointment, {AppointmentsSliceInterface} from './appointments';
import * as Clinic from './clinics';
import Portal, {PortalsSliceInterface} from './portal';
import Form, {ClinicFlowForm} from './form';
import CalendarModel, {Calendar} from './calendar';
import CampaignModel, {Campaign} from './campaign';
import FieldSchemaModel, {FieldSchema} from './field-schema';
import {IOrganizationIdSlice, organizationIdSlice} from './organization-id';
import LocationModel, {ILocation} from './location';
import List, {ListsSliceInterface} from './list';
import ConfigModel, {ConfigSliceInterface} from './config';
import UITemp, {UITempSliceInterface} from './ui-temp';
import Connection, {ConnectionsSliceInterface} from './connection';
import ConnectCustomer, {ConnectCustomersSliceInterface} from './connect-customer';
import APIToken, {APITokensSliceInterface} from './api-token';
import Role, {RolesSliceInterface} from './role';
import hcnType, {HcnTypesSliceInterface} from './hcn-type';
import Routes, { RoutesSliceInterface } from './routes';
import Sites, { SitesSliceInterface } from './sites';

export type AppThunk = ThunkAction<Promise<void>, RootState, unknown, Action<string>>;

export type ThunkDispatch = {
  <TAction>(action: TAction): TAction extends (...args: any[]) => infer TResult ? TResult : TAction;
};

export interface RootState {
  account: AccountSliceInterface;
  users: UsersSliceInterface;
  orgadminusers: OrgAdminUsersSliceInterface;
  patients: PatientsSliceInterface;
  organizations: OrganizationsSliceInterface;
  immunizations: ImmunizationsSliceInterface;
  communications: CommunicationsSliceInterface;
  terminology: TerminologySliceInterface;
  tradenames: TradenamesSliceInterface;
  routes: RoutesSliceInterface,
  sites: SitesSliceInterface,
  questionnaires: QuestionnairesSliceInterface;
  cohorts: CohortsSliceInterface;
  connections: ConnectionsSliceInterface;
  connectCustomers: ConnectCustomersSliceInterface;
  lists: ListsSliceInterface;
  bookingpages: BookingPagesSliceInterface;
  ui: UISliceInterface;
  appointments: AppointmentsSliceInterface;
  tokens: APITokensSliceInterface;
  clinics: Clinic.ClinicsSliceInterface;
  forms: {
    byId: {[formId: string]: ClinicFlowForm};
  };
  calendars: {
    byId: {[calendarId: string]: Calendar};
  };
  campaigns: {
    byId: {[campaignId: string]: Campaign};
  };
  fieldSchemas: {
    byId: {[fieldSchemaId: string]: FieldSchema};
  };
  organizationId: IOrganizationIdSlice;
  locations: {
    byId: {[locationId: string]: ILocation};
  };
  portals: {
    byId: {[portalId: string]: PortalsSliceInterface};
  };
  roles: {
    byId: {[roleId: string]: RolesSliceInterface};
  };
  config: ConfigSliceInterface;
  UITemp: UITempSliceInterface;
  hcntypes: HcnTypesSliceInterface;
}

export const rootReducer = combineReducers({
  account: accountSlice.reducer,
  users: User.slice.reducer,
  orgadminusers: OrgAdminUser.slice.reducer,
  patients: Patient.slice.reducer,
  organizations: Organization.slice.reducer,
  immunizations: Immunization.slice.reducer,
  communications: Communication.slice.reducer,
  terminology: Terminology.slice.reducer,
  tradenames: Tradenames.slice.reducer,
  routes: Routes.slice.reducer,
  sites: Sites.slice.reducer,
  questionnaires: Questionnaire.slice.reducer,
  cohorts: Cohort.slice.reducer,
  lists: List.slice.reducer,
  ui: UI.slice.reducer,
  bookingpages: BookingPage.slice.reducer,
  appointments: Appointment.slice.reducer,
  clinics: Clinic.clinicsSlice.reducer,
  forms: Form.slice.reducer,
  calendars: CalendarModel.slice.reducer,
  campaigns: CampaignModel.slice.reducer,
  fieldSchemas: FieldSchemaModel.slice.reducer,
  organizationId: organizationIdSlice.reducer,
  locations: LocationModel.slice.reducer,
  config: ConfigModel.slice.reducer,
  uiTemp: UITemp.slice.reducer,
  connections: Connection.slice.reducer,
  connectCustomers: ConnectCustomer.slice.reducer,
  tokens: APIToken.slice.reducer,
  portals: Portal.slice.reducer,
  roles: Role.slice.reducer,
  hcntypes: hcnType.slice.reducer,
});

export const slices = {
  account: accountSlice,
  users: User.slice,
  orgadminusers: OrgAdminUser.slice,
  patients: Patient.slice,
  organizations: Organization.slice,
  communications: Communication.slice,
  terminology: Terminology.slice,
  tradenames: Tradenames.slice,
  routes: Routes.slice,
  sites: Sites.slice,
  questionnaires: Questionnaire.slice,
  cohorts: Cohort.slice,
  lists: List.slice,
  ui: UI.slice,
  bookingages: BookingPage.slice,
  appointments: Appointment.slice,
  clinics: Clinic.clinicsSlice,
  forms: Form.slice,
  calendars: CalendarModel.slice,
  campaigns: CampaignModel.slice,
  fieldSchemas: FieldSchemaModel.slice,
  organizationId: organizationIdSlice,
  locations: LocationModel.slice,
  config: ConfigModel.slice,
  uiTemp: UITemp.slice,
  connections: Connection.slice,
  connectCustomers: ConnectCustomer.slice,
  tokens: APIToken.slice,
  portals: Portal.slice,
  roles: Role.slice,
  hcntypes: hcnType.slice,
};
