"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_QuestionnaireResponse_Item = void 0;
var t = __importStar(require("io-ts"));
var RTTI_Extension_1 = require("./RTTI_Extension");
var RTTI_Element_1 = require("./RTTI_Element");
var RTTI_uri_1 = require("../Scalar/RTTI_uri");
var RTTI_QuestionnaireResponse_Answer_1 = require("./RTTI_QuestionnaireResponse_Answer");
exports.RTTI_QuestionnaireResponse_Item = t.recursion('IQuestionnaireResponse_Item', function () {
    return t.partial({
        id: t.string,
        extension: t.array(RTTI_Extension_1.RTTI_Extension),
        modifierExtension: t.array(RTTI_Extension_1.RTTI_Extension),
        linkId: t.string,
        _linkId: RTTI_Element_1.RTTI_Element,
        definition: RTTI_uri_1.RTTI_uri,
        _definition: RTTI_Element_1.RTTI_Element,
        text: t.string,
        _text: RTTI_Element_1.RTTI_Element,
        answer: t.array(RTTI_QuestionnaireResponse_Answer_1.RTTI_QuestionnaireResponse_Answer),
        item: t.array(exports.RTTI_QuestionnaireResponse_Item)
    });
});
