import {Button, Modal, message} from 'antd';
import {Formik, FormikProps, FormikHelpers} from 'formik';
import React, {useState} from 'react';
import {FhirUtils} from '../../../services/fhir';
import {
  RequiredBirthDateValidationRules,
  RequiredInteger,
  RequiredShortTextValidationRules,
  RequiredValidationRule,
} from '../../../services/ui-validation-rules';
import {FormUX, FormUXFieldType} from '../../form-ux';
import {FormUXModel} from '../../form-ux/form-ux-models/form-ux-model';
import {DebounceFormItem} from '../imm-modal/debounce-form-item';

export const TestModal = (props) => {
  const {onClose, visible, patient, currentTest, reloadTable} = props;
  const [isSaving, setIsSaving] = useState(false);
  const client = FhirUtils.useAxiosClient();

  const onSubmit = (values, actions: FormikHelpers<any>) => {
    setIsSaving(true);
    if (!values.organizationId) {
      values.organizationId = patient.organizationId;
    }
    values.patientId = patient.id;
    const request = currentTest
      ? client.put(`/test/${currentTest.id}`, values)
      : client.post('/test', values);
    return request
      .then(() => {
        setIsSaving(false);
        //Refresh tests table after successful saves
        if (reloadTable) {
          reloadTable();
        }
        onClose(actions);
      })
      .catch(() => {
        setIsSaving(false);
        message.error('Error saving.');
      });
  };

  const fields: FormUXModel = [
    {
      name: 'testDefinitionId',
      type: FormUXFieldType.custom,
      label: 'Test Type',
      fetchType: 'test',
      generateLabel: (test) => test.name,
      generateValue: (test) => test.id,
      editable: true,
      inCreateModal: true,
      validationRules: RequiredValidationRule,
    },
    {
      name: 'locationName',
      type: FormUXFieldType.text,
      label: 'Lab/Organization Name',
      editable: true,
      inCreateModal: true,
      validationRules: RequiredShortTextValidationRules,
    },
    {
      name: 'specimenId',
      type: FormUXFieldType.text,
      label: 'Lab Specimen Id',
      editable: true,
      inCreateModal: true,
      validationRules: RequiredShortTextValidationRules,
    },
    {
      name: 'collectionDate',
      type: FormUXFieldType.date,
      label: 'Collection Date',
      editable: true,
      inCreateModal: true,
      validationRules: RequiredBirthDateValidationRules,
    },
    {
      name: 'result',
      type: FormUXFieldType.select,
      label: 'Result',
      selectableValues: [
        {label: 'Positive/Detected', key: 0},
        {label: 'Negative/Not Detected', key: 1},
        {label: 'Indeterminate', key: 2},
        {label: 'Inconclusive', key: 3},
      ],
      editable: true,
      inCreateModal: true,
      validationRules: RequiredInteger,
    },
    {
      name: 'resultDate',
      type: FormUXFieldType.date,
      label: 'Result Date',
      editable: true,
      inCreateModal: true,
      validationRules: RequiredBirthDateValidationRules,
    },
  ];

  return (
    <Formik
      initialValues={currentTest || {}}
      enableReinitialize
      validateOnChange={true}
      onSubmit={onSubmit}
    >
      {(formikProps: FormikProps<any>) => {
        const {handleSubmit, dirty, isValid} = formikProps;

        return (
          <Modal
            closable={false}
            maskClosable={false}
            visible={visible}
            onCancel={onClose}
            title={`${currentTest ? 'Edit' : 'New'} Test`}
            footer={[
              <Button key="close" onClick={onClose}>
                Cancel
              </Button>,
              <Button key="submit" disabled={!dirty || !isValid} type="primary" loading={isSaving} onClick={handleSubmit as any}>
                Save
              </Button>,
            ]}
          >
            <FormUX
              formUXModel={fields}
              createMode={true}
              modal={true}
              renderCustomField={(field) => {
                switch (field.name) {
                  case 'testDefinitionId':
                    return (
                      <DebounceFormItem
                        {...field}
                        initialValues={currentTest}
                        form={formikProps}
                        editable={field.editable}
                      />
                    );
                  default: {
                    throw new Error(`Unhandled custom field ${field.name} in
                            renderCustomField method`);
                  }
                }
              }}
            />
          </Modal>
        );
      }}
    </Formik>
  );
};
