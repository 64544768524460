"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_Observation = exports.ObservationStatusKind = void 0;
var t = __importStar(require("io-ts"));
var RTTI_id_1 = require("../Scalar/RTTI_id");
var RTTI_Meta_1 = require("./RTTI_Meta");
var RTTI_uri_1 = require("../Scalar/RTTI_uri");
var RTTI_Element_1 = require("./RTTI_Element");
var RTTI_code_1 = require("../Scalar/RTTI_code");
var RTTI_Narrative_1 = require("./RTTI_Narrative");
var RTTI_ResourceList_1 = require("../Union/RTTI_ResourceList");
var RTTI_Extension_1 = require("./RTTI_Extension");
var RTTI_Identifier_1 = require("./RTTI_Identifier");
var RTTI_Reference_1 = require("./RTTI_Reference");
var RTTI_CodeableConcept_1 = require("./RTTI_CodeableConcept");
var RTTI_Period_1 = require("./RTTI_Period");
var RTTI_Timing_1 = require("./RTTI_Timing");
var RTTI_instant_1 = require("../Scalar/RTTI_instant");
var RTTI_Quantity_1 = require("./RTTI_Quantity");
var RTTI_Range_1 = require("./RTTI_Range");
var RTTI_Ratio_1 = require("./RTTI_Ratio");
var RTTI_SampledData_1 = require("./RTTI_SampledData");
var RTTI_Annotation_1 = require("./RTTI_Annotation");
var RTTI_Observation_ReferenceRange_1 = require("./RTTI_Observation_ReferenceRange");
var RTTI_Observation_Component_1 = require("./RTTI_Observation_Component");
var ObservationStatusKind;
(function (ObservationStatusKind) {
    ObservationStatusKind["_registered"] = "registered";
    ObservationStatusKind["_preliminary"] = "preliminary";
    ObservationStatusKind["_final"] = "final";
    ObservationStatusKind["_amended"] = "amended";
    ObservationStatusKind["_corrected"] = "corrected";
    ObservationStatusKind["_cancelled"] = "cancelled";
    ObservationStatusKind["_enteredInError"] = "entered-in-error";
    ObservationStatusKind["_unknown"] = "unknown";
})(ObservationStatusKind = exports.ObservationStatusKind || (exports.ObservationStatusKind = {}));
var EnumType_1 = require("../../EnumType");
exports.RTTI_Observation = t.recursion('IObservation', function () {
    return t.intersection([
        t.type({
            resourceType: t.literal('Observation'),
            code: RTTI_CodeableConcept_1.RTTI_CodeableConcept
        }),
        t.partial({
            id: RTTI_id_1.RTTI_id,
            meta: RTTI_Meta_1.RTTI_Meta,
            implicitRules: RTTI_uri_1.RTTI_uri,
            _implicitRules: RTTI_Element_1.RTTI_Element,
            language: RTTI_code_1.RTTI_code,
            _language: RTTI_Element_1.RTTI_Element,
            text: RTTI_Narrative_1.RTTI_Narrative,
            contained: t.array(RTTI_ResourceList_1.RTTI_ResourceList),
            extension: t.array(RTTI_Extension_1.RTTI_Extension),
            modifierExtension: t.array(RTTI_Extension_1.RTTI_Extension),
            identifier: t.array(RTTI_Identifier_1.RTTI_Identifier),
            basedOn: t.array(RTTI_Reference_1.RTTI_Reference),
            partOf: t.array(RTTI_Reference_1.RTTI_Reference),
            status: EnumType_1.createEnumType(ObservationStatusKind, 'ObservationStatusKind'),
            _status: RTTI_Element_1.RTTI_Element,
            category: t.array(RTTI_CodeableConcept_1.RTTI_CodeableConcept),
            subject: RTTI_Reference_1.RTTI_Reference,
            focus: t.array(RTTI_Reference_1.RTTI_Reference),
            encounter: RTTI_Reference_1.RTTI_Reference,
            effectiveDateTime: t.string,
            _effectiveDateTime: RTTI_Element_1.RTTI_Element,
            effectivePeriod: RTTI_Period_1.RTTI_Period,
            effectiveTiming: RTTI_Timing_1.RTTI_Timing,
            effectiveInstant: t.string,
            _effectiveInstant: RTTI_Element_1.RTTI_Element,
            issued: RTTI_instant_1.RTTI_instant,
            _issued: RTTI_Element_1.RTTI_Element,
            performer: t.array(RTTI_Reference_1.RTTI_Reference),
            valueQuantity: RTTI_Quantity_1.RTTI_Quantity,
            valueCodeableConcept: RTTI_CodeableConcept_1.RTTI_CodeableConcept,
            valueString: t.string,
            _valueString: RTTI_Element_1.RTTI_Element,
            valueBoolean: t.boolean,
            _valueBoolean: RTTI_Element_1.RTTI_Element,
            valueInteger: t.number,
            _valueInteger: RTTI_Element_1.RTTI_Element,
            valueRange: RTTI_Range_1.RTTI_Range,
            valueRatio: RTTI_Ratio_1.RTTI_Ratio,
            valueSampledData: RTTI_SampledData_1.RTTI_SampledData,
            valueTime: t.string,
            _valueTime: RTTI_Element_1.RTTI_Element,
            valueDateTime: t.string,
            _valueDateTime: RTTI_Element_1.RTTI_Element,
            valuePeriod: RTTI_Period_1.RTTI_Period,
            dataAbsentReason: RTTI_CodeableConcept_1.RTTI_CodeableConcept,
            interpretation: t.array(RTTI_CodeableConcept_1.RTTI_CodeableConcept),
            note: t.array(RTTI_Annotation_1.RTTI_Annotation),
            bodySite: RTTI_CodeableConcept_1.RTTI_CodeableConcept,
            method: RTTI_CodeableConcept_1.RTTI_CodeableConcept,
            specimen: RTTI_Reference_1.RTTI_Reference,
            device: RTTI_Reference_1.RTTI_Reference,
            referenceRange: t.array(RTTI_Observation_ReferenceRange_1.RTTI_Observation_ReferenceRange),
            hasMember: t.array(RTTI_Reference_1.RTTI_Reference),
            derivedFrom: t.array(RTTI_Reference_1.RTTI_Reference),
            component: t.array(RTTI_Observation_Component_1.RTTI_Observation_Component)
        })
    ]);
});
