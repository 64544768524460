"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_Specimen = exports.SpecimenStatusKind = void 0;
var t = __importStar(require("io-ts"));
var RTTI_id_1 = require("../Scalar/RTTI_id");
var RTTI_Meta_1 = require("./RTTI_Meta");
var RTTI_uri_1 = require("../Scalar/RTTI_uri");
var RTTI_Element_1 = require("./RTTI_Element");
var RTTI_code_1 = require("../Scalar/RTTI_code");
var RTTI_Narrative_1 = require("./RTTI_Narrative");
var RTTI_ResourceList_1 = require("../Union/RTTI_ResourceList");
var RTTI_Extension_1 = require("./RTTI_Extension");
var RTTI_Identifier_1 = require("./RTTI_Identifier");
var RTTI_CodeableConcept_1 = require("./RTTI_CodeableConcept");
var RTTI_Reference_1 = require("./RTTI_Reference");
var RTTI_dateTime_1 = require("../Scalar/RTTI_dateTime");
var RTTI_Specimen_Collection_1 = require("./RTTI_Specimen_Collection");
var RTTI_Specimen_Processing_1 = require("./RTTI_Specimen_Processing");
var RTTI_Specimen_Container_1 = require("./RTTI_Specimen_Container");
var RTTI_Annotation_1 = require("./RTTI_Annotation");
var SpecimenStatusKind;
(function (SpecimenStatusKind) {
    SpecimenStatusKind["_available"] = "available";
    SpecimenStatusKind["_unavailable"] = "unavailable";
    SpecimenStatusKind["_unsatisfactory"] = "unsatisfactory";
    SpecimenStatusKind["_enteredInError"] = "entered-in-error";
})(SpecimenStatusKind = exports.SpecimenStatusKind || (exports.SpecimenStatusKind = {}));
var EnumType_1 = require("../../EnumType");
exports.RTTI_Specimen = t.recursion('ISpecimen', function () {
    return t.intersection([
        t.type({
            resourceType: t.literal('Specimen')
        }),
        t.partial({
            id: RTTI_id_1.RTTI_id,
            meta: RTTI_Meta_1.RTTI_Meta,
            implicitRules: RTTI_uri_1.RTTI_uri,
            _implicitRules: RTTI_Element_1.RTTI_Element,
            language: RTTI_code_1.RTTI_code,
            _language: RTTI_Element_1.RTTI_Element,
            text: RTTI_Narrative_1.RTTI_Narrative,
            contained: t.array(RTTI_ResourceList_1.RTTI_ResourceList),
            extension: t.array(RTTI_Extension_1.RTTI_Extension),
            modifierExtension: t.array(RTTI_Extension_1.RTTI_Extension),
            identifier: t.array(RTTI_Identifier_1.RTTI_Identifier),
            accessionIdentifier: RTTI_Identifier_1.RTTI_Identifier,
            status: EnumType_1.createEnumType(SpecimenStatusKind, 'SpecimenStatusKind'),
            _status: RTTI_Element_1.RTTI_Element,
            type: RTTI_CodeableConcept_1.RTTI_CodeableConcept,
            subject: RTTI_Reference_1.RTTI_Reference,
            receivedTime: RTTI_dateTime_1.RTTI_dateTime,
            _receivedTime: RTTI_Element_1.RTTI_Element,
            parent: t.array(RTTI_Reference_1.RTTI_Reference),
            request: t.array(RTTI_Reference_1.RTTI_Reference),
            collection: RTTI_Specimen_Collection_1.RTTI_Specimen_Collection,
            processing: t.array(RTTI_Specimen_Processing_1.RTTI_Specimen_Processing),
            container: t.array(RTTI_Specimen_Container_1.RTTI_Specimen_Container),
            condition: t.array(RTTI_CodeableConcept_1.RTTI_CodeableConcept),
            note: t.array(RTTI_Annotation_1.RTTI_Annotation)
        })
    ]);
});
