import {AppState, Auth0Provider} from '@auth0/auth0-react';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useEnvInfo} from '../services/environment';

const Auth0ProviderWithHistory = ({children}) => {
  const {auth0Domain: domain, auth0ClientId: clientId, auth0Audience: audience} = useEnvInfo();

  const navigate = useNavigate();

  const onRedirectCallback = (appState: AppState) => {
    if (appState?.returnTo?.length) navigate(appState.returnTo);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      audience={audience}
      scope={'openid profile email'}
      useRefreshTokens={true}
      //@ts-ignore
      cacheLocation={window.Cypress ? 'localstorage' : 'memory'}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
