"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_ElementDefinition_Example = void 0;
var t = __importStar(require("io-ts"));
var RTTI_Extension_1 = require("./RTTI_Extension");
var RTTI_Element_1 = require("./RTTI_Element");
var RTTI_Address_1 = require("./RTTI_Address");
var RTTI_Age_1 = require("./RTTI_Age");
var RTTI_Annotation_1 = require("./RTTI_Annotation");
var RTTI_Attachment_1 = require("./RTTI_Attachment");
var RTTI_CodeableConcept_1 = require("./RTTI_CodeableConcept");
var RTTI_Coding_1 = require("./RTTI_Coding");
var RTTI_ContactPoint_1 = require("./RTTI_ContactPoint");
var RTTI_Count_1 = require("./RTTI_Count");
var RTTI_Distance_1 = require("./RTTI_Distance");
var RTTI_Duration_1 = require("./RTTI_Duration");
var RTTI_HumanName_1 = require("./RTTI_HumanName");
var RTTI_Identifier_1 = require("./RTTI_Identifier");
var RTTI_Money_1 = require("./RTTI_Money");
var RTTI_Period_1 = require("./RTTI_Period");
var RTTI_Quantity_1 = require("./RTTI_Quantity");
var RTTI_Range_1 = require("./RTTI_Range");
var RTTI_Ratio_1 = require("./RTTI_Ratio");
var RTTI_Reference_1 = require("./RTTI_Reference");
var RTTI_SampledData_1 = require("./RTTI_SampledData");
var RTTI_Signature_1 = require("./RTTI_Signature");
var RTTI_Timing_1 = require("./RTTI_Timing");
var RTTI_ContactDetail_1 = require("./RTTI_ContactDetail");
var RTTI_Contributor_1 = require("./RTTI_Contributor");
var RTTI_DataRequirement_1 = require("./RTTI_DataRequirement");
var RTTI_Expression_1 = require("./RTTI_Expression");
var RTTI_ParameterDefinition_1 = require("./RTTI_ParameterDefinition");
var RTTI_RelatedArtifact_1 = require("./RTTI_RelatedArtifact");
var RTTI_TriggerDefinition_1 = require("./RTTI_TriggerDefinition");
var RTTI_UsageContext_1 = require("./RTTI_UsageContext");
var RTTI_Dosage_1 = require("./RTTI_Dosage");
exports.RTTI_ElementDefinition_Example = t.recursion('IElementDefinition_Example', function () {
    return t.partial({
        id: t.string,
        extension: t.array(RTTI_Extension_1.RTTI_Extension),
        modifierExtension: t.array(RTTI_Extension_1.RTTI_Extension),
        label: t.string,
        _label: RTTI_Element_1.RTTI_Element,
        valueBase64Binary: t.string,
        _valueBase64Binary: RTTI_Element_1.RTTI_Element,
        valueBoolean: t.boolean,
        _valueBoolean: RTTI_Element_1.RTTI_Element,
        valueCanonical: t.string,
        _valueCanonical: RTTI_Element_1.RTTI_Element,
        valueCode: t.string,
        _valueCode: RTTI_Element_1.RTTI_Element,
        valueDate: t.string,
        _valueDate: RTTI_Element_1.RTTI_Element,
        valueDateTime: t.string,
        _valueDateTime: RTTI_Element_1.RTTI_Element,
        valueDecimal: t.number,
        _valueDecimal: RTTI_Element_1.RTTI_Element,
        valueId: t.string,
        _valueId: RTTI_Element_1.RTTI_Element,
        valueInstant: t.string,
        _valueInstant: RTTI_Element_1.RTTI_Element,
        valueInteger: t.number,
        _valueInteger: RTTI_Element_1.RTTI_Element,
        valueMarkdown: t.string,
        _valueMarkdown: RTTI_Element_1.RTTI_Element,
        valueOid: t.string,
        _valueOid: RTTI_Element_1.RTTI_Element,
        valuePositiveInt: t.number,
        _valuePositiveInt: RTTI_Element_1.RTTI_Element,
        valueString: t.string,
        _valueString: RTTI_Element_1.RTTI_Element,
        valueTime: t.string,
        _valueTime: RTTI_Element_1.RTTI_Element,
        valueUnsignedInt: t.number,
        _valueUnsignedInt: RTTI_Element_1.RTTI_Element,
        valueUri: t.string,
        _valueUri: RTTI_Element_1.RTTI_Element,
        valueUrl: t.string,
        _valueUrl: RTTI_Element_1.RTTI_Element,
        valueUuid: t.string,
        _valueUuid: RTTI_Element_1.RTTI_Element,
        valueAddress: RTTI_Address_1.RTTI_Address,
        valueAge: RTTI_Age_1.RTTI_Age,
        valueAnnotation: RTTI_Annotation_1.RTTI_Annotation,
        valueAttachment: RTTI_Attachment_1.RTTI_Attachment,
        valueCodeableConcept: RTTI_CodeableConcept_1.RTTI_CodeableConcept,
        valueCoding: RTTI_Coding_1.RTTI_Coding,
        valueContactPoint: RTTI_ContactPoint_1.RTTI_ContactPoint,
        valueCount: RTTI_Count_1.RTTI_Count,
        valueDistance: RTTI_Distance_1.RTTI_Distance,
        valueDuration: RTTI_Duration_1.RTTI_Duration,
        valueHumanName: RTTI_HumanName_1.RTTI_HumanName,
        valueIdentifier: RTTI_Identifier_1.RTTI_Identifier,
        valueMoney: RTTI_Money_1.RTTI_Money,
        valuePeriod: RTTI_Period_1.RTTI_Period,
        valueQuantity: RTTI_Quantity_1.RTTI_Quantity,
        valueRange: RTTI_Range_1.RTTI_Range,
        valueRatio: RTTI_Ratio_1.RTTI_Ratio,
        valueReference: RTTI_Reference_1.RTTI_Reference,
        valueSampledData: RTTI_SampledData_1.RTTI_SampledData,
        valueSignature: RTTI_Signature_1.RTTI_Signature,
        valueTiming: RTTI_Timing_1.RTTI_Timing,
        valueContactDetail: RTTI_ContactDetail_1.RTTI_ContactDetail,
        valueContributor: RTTI_Contributor_1.RTTI_Contributor,
        valueDataRequirement: RTTI_DataRequirement_1.RTTI_DataRequirement,
        valueExpression: RTTI_Expression_1.RTTI_Expression,
        valueParameterDefinition: RTTI_ParameterDefinition_1.RTTI_ParameterDefinition,
        valueRelatedArtifact: RTTI_RelatedArtifact_1.RTTI_RelatedArtifact,
        valueTriggerDefinition: RTTI_TriggerDefinition_1.RTTI_TriggerDefinition,
        valueUsageContext: RTTI_UsageContext_1.RTTI_UsageContext,
        valueDosage: RTTI_Dosage_1.RTTI_Dosage
    });
});
