"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_EpisodeOfCare = exports.EpisodeOfCareStatusKind = void 0;
var t = __importStar(require("io-ts"));
var RTTI_id_1 = require("../Scalar/RTTI_id");
var RTTI_Meta_1 = require("./RTTI_Meta");
var RTTI_uri_1 = require("../Scalar/RTTI_uri");
var RTTI_Element_1 = require("./RTTI_Element");
var RTTI_code_1 = require("../Scalar/RTTI_code");
var RTTI_Narrative_1 = require("./RTTI_Narrative");
var RTTI_ResourceList_1 = require("../Union/RTTI_ResourceList");
var RTTI_Extension_1 = require("./RTTI_Extension");
var RTTI_Identifier_1 = require("./RTTI_Identifier");
var RTTI_EpisodeOfCare_StatusHistory_1 = require("./RTTI_EpisodeOfCare_StatusHistory");
var RTTI_CodeableConcept_1 = require("./RTTI_CodeableConcept");
var RTTI_EpisodeOfCare_Diagnosis_1 = require("./RTTI_EpisodeOfCare_Diagnosis");
var RTTI_Reference_1 = require("./RTTI_Reference");
var RTTI_Period_1 = require("./RTTI_Period");
var EpisodeOfCareStatusKind;
(function (EpisodeOfCareStatusKind) {
    EpisodeOfCareStatusKind["_planned"] = "planned";
    EpisodeOfCareStatusKind["_waitlist"] = "waitlist";
    EpisodeOfCareStatusKind["_active"] = "active";
    EpisodeOfCareStatusKind["_onhold"] = "onhold";
    EpisodeOfCareStatusKind["_finished"] = "finished";
    EpisodeOfCareStatusKind["_cancelled"] = "cancelled";
    EpisodeOfCareStatusKind["_enteredInError"] = "entered-in-error";
})(EpisodeOfCareStatusKind = exports.EpisodeOfCareStatusKind || (exports.EpisodeOfCareStatusKind = {}));
var EnumType_1 = require("../../EnumType");
exports.RTTI_EpisodeOfCare = t.recursion('IEpisodeOfCare', function () {
    return t.intersection([
        t.type({
            resourceType: t.literal('EpisodeOfCare'),
            patient: RTTI_Reference_1.RTTI_Reference
        }),
        t.partial({
            id: RTTI_id_1.RTTI_id,
            meta: RTTI_Meta_1.RTTI_Meta,
            implicitRules: RTTI_uri_1.RTTI_uri,
            _implicitRules: RTTI_Element_1.RTTI_Element,
            language: RTTI_code_1.RTTI_code,
            _language: RTTI_Element_1.RTTI_Element,
            text: RTTI_Narrative_1.RTTI_Narrative,
            contained: t.array(RTTI_ResourceList_1.RTTI_ResourceList),
            extension: t.array(RTTI_Extension_1.RTTI_Extension),
            modifierExtension: t.array(RTTI_Extension_1.RTTI_Extension),
            identifier: t.array(RTTI_Identifier_1.RTTI_Identifier),
            status: EnumType_1.createEnumType(EpisodeOfCareStatusKind, 'EpisodeOfCareStatusKind'),
            _status: RTTI_Element_1.RTTI_Element,
            statusHistory: t.array(RTTI_EpisodeOfCare_StatusHistory_1.RTTI_EpisodeOfCare_StatusHistory),
            type: t.array(RTTI_CodeableConcept_1.RTTI_CodeableConcept),
            diagnosis: t.array(RTTI_EpisodeOfCare_Diagnosis_1.RTTI_EpisodeOfCare_Diagnosis),
            managingOrganization: RTTI_Reference_1.RTTI_Reference,
            period: RTTI_Period_1.RTTI_Period,
            referralRequest: t.array(RTTI_Reference_1.RTTI_Reference),
            careManager: RTTI_Reference_1.RTTI_Reference,
            team: t.array(RTTI_Reference_1.RTTI_Reference),
            account: t.array(RTTI_Reference_1.RTTI_Reference)
        })
    ]);
});
