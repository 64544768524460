import {Ids} from '@canimmunize/tools';
import {Space, message, Checkbox, Modal, Button, Typography} from 'antd';
import axios from 'axios';
import moment from 'moment';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useStr} from '../../services/str';
import {AppointmentPicker} from './booking-form/appointment-picker';
import {useUrlData} from './util';
import {Appointment, AppointmentType} from './types';
import React from 'react';
import {appointmentDateString} from '../../util/helpers/appointment-date-string';
import {useEnvInfo} from '../../services/environment';

interface ReschedulerContainerProps {
  children: (props: any) => any;
  appointment: any;
  secondAppointment?: any;
  onRescheduling?: (rescheduling: boolean) => void;
  rescheduling?: boolean;
}

export const ReschedulerContainer = (props: ReschedulerContainerProps) => {
  const Str = useStr();
  const {appointment, secondAppointment, onRescheduling} = props;
  const patient = appointment.patients[0];
  const {config, syncUrl, lang, bookingPageSlug, theme} = useUrlData();
  const navigate = useNavigate();
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [rescheduling, setRescheduling] = useState(props.rescheduling || false);
  const [rescheduleLoading, setRescheduleLoading] = useState(false);
  const [rescheduleAppointment, setRescheduleAppointment] = useState<Appointment>();
  const [selectedAppointmentType, setSelectedAppointmentType] =
    useState<AppointmentType | undefined>();
  const [errorMessage, setErrorMessage] = React.useState<string>();
  const {environmentId} = useEnvInfo();

  let waitlist = patient.fields.waitlist || false;

  // Needed to be able to show/hide the required forms component higher up in the tree
  // based on whether this component was in rescheduling mode or not
  useEffect(() => {
    if (onRescheduling) onRescheduling(rescheduling);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rescheduling]);

  const onCancel = async () => {
    const cancelResult = await axios.get(syncUrl + `/public/appointment/${appointment.id}/cancel`, {
      validateStatus: (status) => {
        return status >= 200 && status < 500;
      },
    });

    if (cancelResult.status === 200) {
      window.location.reload();
    } else if (cancelResult.status === 400) {
      message.error(cancelResult.data.error.message);
    }
  };

  const onBookAgain = () => {
    navigate(`/${lang}/${bookingPageSlug}`);
  };

  const onReschedule = async (target: Appointment) => {
    setErrorMessage(undefined);
    setRescheduleLoading(true);
    const nextApptCanceledBefore = secondAppointment?.canceled;
    const rescheduleResult = await axios.post(
      syncUrl + `/public/appointment/${appointment.id}/reschedule`,
      {target, waitlist: waitlist !== patient.fields.waitlist ? waitlist : undefined},
      {
        validateStatus: (status) => {
          return status >= 200 && status < 500;
        },
      }
    );

    if (rescheduleResult.status === 200) {
      setRescheduleAppointment(undefined);
      navigate({
        search:
          '?booked=true&rescheduled=true' +
          (nextApptCanceledBefore === false ? `&canceledDose2=true` : ``),
      });
      window.location.reload();
    } else {
      //TODO: show alert if reschedule fails
      // message.error(
      //   JSON.stringify(
      //     rescheduleResult.data.error?.message ||
      //       `Encountered an error when trying to reschedule (${rescheduleResult.status})`
      //   )
      // );
      setErrorMessage(
        rescheduleResult.data.error?.display?.[lang] ||
          rescheduleResult.data.error?.message ||
          `Encountered an error when trying to reschedule (${rescheduleResult.status})`
      );
      setRescheduleLoading(false);
    }
  };

  const tempAge = Math.floor(moment().diff(appointment.patients[0].birthdate, 'years'));

  if (appointment && rescheduling) {
    return (
      <>
        <AppointmentPicker
          appointment={appointment}
          rescheduling={rescheduling}
          reschedulingTitle={rescheduling}
          onCancelReschedule={() => setRescheduling(false)}
          //selectedAppointmentType={appointment.appointmentType}
          defaultAge={tempAge}
          selectedAppointmentType={selectedAppointmentType}
          setSelectedAppointmentType={setSelectedAppointmentType}
          setSelectedAppointment={setRescheduleAppointment}
        />
        <Modal
          visible={rescheduleAppointment !== undefined}
          className="cancel-modal"
          title={Str(Ids.reschedule_appointment)}
          onCancel={() => {
            setErrorMessage(undefined);
            setRescheduleAppointment(undefined);
          }}
          footer={
            <Space>
              {errorMessage && <Typography.Text type="danger">{errorMessage}</Typography.Text>}
              <Button
                type="link"
                onClick={() => {
                  setErrorMessage(undefined);
                  setRescheduleAppointment(undefined);
                }}
                style={{borderRadius: 10}}
              >
                {Str(Ids.cancel)}
              </Button>
              <Button
                style={{borderRadius: 10}}
                type="primary"
                onClick={() => {
                  rescheduleAppointment && onReschedule(rescheduleAppointment);
                }}
                loading={rescheduleLoading}
              >
                {Str(Ids.confirm)}
              </Button>
            </Space>
          }
        >
          <div>{Str(Ids.want_to_reschedule_appointment)}</div>
          <div>
            <b>{`${Str(Ids.clinic)}:`}</b> {selectedAppointmentType?.['clinicName']}
          </div>
          <div>
            <b>{`${Str(Ids.venue)}:`}</b> {selectedAppointmentType?.['mapsLocationString']}
          </div>
          <div>
            <b>{Str(Ids.new_time)}</b>{' '}
            {appointmentDateString(
              moment.tz(rescheduleAppointment?.datetime, config.timezone),
              lang,
              config.timezone
            )}
          </div>
          {!environmentId.includes('yukon-2') && (
            <>
              <br />
              <div>
                <b>
                  {' '}
                  Waitlist: {'\t'}
                  <Checkbox
                    defaultChecked={waitlist}
                    onChange={(e) => (waitlist = e.target.checked)}
                    style={{textShadow: `3px 3px 5px ${theme.blue}`}}
                    className="emphasized-checkbox square"
                  />
                </b>{' '}
                <br />
                Please check this box if you want to be contacted on short notice (30 minutes) to
                receive your vaccination sooner should an appointment become available.
              </div>
            </>
          )}
        </Modal>
      </>
    );
  }

  return (
    <div>
      {props.children({
        ...props,
        onSelectReschedule: () => setRescheduling(true),
        onSelectCancel: () => setCancelModalVisible(true),
        setCancelModalVisible,
        onBookAgain,
      })}

      <Modal
        visible={cancelModalVisible}
        onCancel={() => {
          setCancelModalVisible(!cancelModalVisible);
        }}
        className="cancel-modal"
        title={Str(Ids.cancel_appointment)}
        footer={
          <>
            <Button
              type="link"
              onClick={() => setCancelModalVisible(false)}
              style={{borderRadius: 10}}
            >
              {Str(Ids.cancel)}
            </Button>
            <Button
              style={{borderRadius: 10}}
              type="primary"
              danger
              onClick={() => {
                setCancelModalVisible(false);
                onCancel();
              }}
            >
              {Str(Ids.cancel_appointment)}
            </Button>
          </>
        }
      >
        {Str(Ids.want_to_cancel_appointment)}
      </Modal>
    </div>
  );
};

export const AppointmentActionButtons = (props) => {
  const {theme} = useUrlData();
  const Str = useStr();
  const {environmentId} = useEnvInfo();
  const {appointment, includeNewAppointmentButton, includeRescheduleButton} = props;

  return (
    <Space wrap>
      {includeRescheduleButton && (
        <a
          href={appointment.confirmationPage}
          role="button"
          className="btn"
          aria-pressed="true"
          style={{
            color: theme.blue,
            backgroundColor: 'white',
            borderRadius: theme.borderRadius,
          }}
          onClick={props.onSelectReschedule}
        >
          {Str(Ids.reschedule)}
        </a>
      )}
      {includeNewAppointmentButton && (
        <a
          href={appointment.confirmationPage}
          role="button"
          className="btn"
          aria-pressed="true"
          style={{
            color: theme.blue,
            backgroundColor: 'white',
            borderRadius: theme.borderRadius,
          }}
          onClick={props.onBookAgain}
        >
          {Str(Ids.book_another_appointment)}
        </a>
      )}
      <a
        onClick={props.onSelectCancel}
        href={appointment.confirmationPage}
        role="button"
        className="btn"
        aria-pressed="true"
        style={{
          color: 'red',
          backgroundColor: 'white',
          borderRadius: theme.borderRadius,
        }}
      >
        {/* TODO: update text to use Ids */}
        {appointment.appointmentGroup
          ? appointment.appointmentGroup.isPrimaryAppointment
            ? 'Cancel Group'
            : 'Cancel My Appointment'
          : environmentId.includes('yukon-2')
          ? Str(Ids.cancel_appointment)
          : Str(Ids.cancel)}
      </a>
    </Space>
  );
};
