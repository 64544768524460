"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_MessageHeader = void 0;
var t = __importStar(require("io-ts"));
var RTTI_id_1 = require("../Scalar/RTTI_id");
var RTTI_Meta_1 = require("./RTTI_Meta");
var RTTI_uri_1 = require("../Scalar/RTTI_uri");
var RTTI_Element_1 = require("./RTTI_Element");
var RTTI_code_1 = require("../Scalar/RTTI_code");
var RTTI_Narrative_1 = require("./RTTI_Narrative");
var RTTI_ResourceList_1 = require("../Union/RTTI_ResourceList");
var RTTI_Extension_1 = require("./RTTI_Extension");
var RTTI_Coding_1 = require("./RTTI_Coding");
var RTTI_MessageHeader_Destination_1 = require("./RTTI_MessageHeader_Destination");
var RTTI_Reference_1 = require("./RTTI_Reference");
var RTTI_MessageHeader_Source_1 = require("./RTTI_MessageHeader_Source");
var RTTI_CodeableConcept_1 = require("./RTTI_CodeableConcept");
var RTTI_MessageHeader_Response_1 = require("./RTTI_MessageHeader_Response");
var RTTI_canonical_1 = require("../Scalar/RTTI_canonical");
exports.RTTI_MessageHeader = t.recursion('IMessageHeader', function () {
    return t.intersection([
        t.type({
            resourceType: t.literal('MessageHeader'),
            source: RTTI_MessageHeader_Source_1.RTTI_MessageHeader_Source
        }),
        t.partial({
            id: RTTI_id_1.RTTI_id,
            meta: RTTI_Meta_1.RTTI_Meta,
            implicitRules: RTTI_uri_1.RTTI_uri,
            _implicitRules: RTTI_Element_1.RTTI_Element,
            language: RTTI_code_1.RTTI_code,
            _language: RTTI_Element_1.RTTI_Element,
            text: RTTI_Narrative_1.RTTI_Narrative,
            contained: t.array(RTTI_ResourceList_1.RTTI_ResourceList),
            extension: t.array(RTTI_Extension_1.RTTI_Extension),
            modifierExtension: t.array(RTTI_Extension_1.RTTI_Extension),
            eventCoding: RTTI_Coding_1.RTTI_Coding,
            eventUri: t.string,
            _eventUri: RTTI_Element_1.RTTI_Element,
            destination: t.array(RTTI_MessageHeader_Destination_1.RTTI_MessageHeader_Destination),
            sender: RTTI_Reference_1.RTTI_Reference,
            enterer: RTTI_Reference_1.RTTI_Reference,
            author: RTTI_Reference_1.RTTI_Reference,
            responsible: RTTI_Reference_1.RTTI_Reference,
            reason: RTTI_CodeableConcept_1.RTTI_CodeableConcept,
            response: RTTI_MessageHeader_Response_1.RTTI_MessageHeader_Response,
            focus: t.array(RTTI_Reference_1.RTTI_Reference),
            definition: RTTI_canonical_1.RTTI_canonical
        })
    ]);
});
