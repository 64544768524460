import React, {useState} from 'react';
import {FhirUtils, useUnAuthClient} from '../services/fhir';

export const useGlobalSettings = (isPublic: boolean = false) => {
  const [settings, setSettings] = useState({});
  const authClient = FhirUtils.useAxiosClient();
  const unAuthClient = useUnAuthClient();
  const client = isPublic ? unAuthClient : authClient;

  React.useEffect(() => {
    async function getGlobalSettings() {
      const response = await client.get(`${isPublic ? '/public' : ''}/settings`);
      setSettings(response.data);
    }
    getGlobalSettings();
  }, []);
  return settings;
};
