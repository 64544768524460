import {Typography} from 'antd';
import React from 'react';
import {FHIRTable} from '../fhir-table';

const {Title} = Typography;

export const ContactPointsWidget = (props) => {
  const {patient} = props;

  return (
    <>
      <Title level={4}>ContactPoints</Title>

      <FHIRTable
        fhirResource={'contact-point'}
        fixedFilters={{patientId: patient.id}}
        hideSearch
        mode="table"
        columns={columns}
        size="small"
        style={{width: '100%'}}
      />
    </>
  );
};

export const columns = [
  {
    title: 'System',
    dataIndex: 'system',
    key: 'system',
    render: (_, row) => row.system.charAt(0).toUpperCase() + row.system.slice(1),
  },
  {
    title: 'Value',
    dataIndex: 'value',
    key: 'value',
  },
  {
    title: 'Effective From',
    dataIndex: 'effectiveFrom',
    key: 'effectiveFrom',
  },
  {
    title: 'Effective To',
    dataIndex: 'effectiveTo',
    key: 'effectiveTo',
  },
];
