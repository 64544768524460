import {colors} from '@canimmunize/tools';
import {faCheckCircle, faPlusCircle} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, message, Modal, Popconfirm, Space, Spin, Table, Tooltip} from 'antd';
import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {Link} from 'react-router-dom';
import {ThunkDispatch} from '../../models';
import OrgAdminUser from '../../models/org-admin-users';
import {useGlobalOrg} from '../../models/ui';
import {FhirUtils} from '../../services/fhir';
import {FHIRTable} from '../fhir-table';

/**
 * OrganizationAdminMembershipTable Functional Component
 * Used by the OrgAdminUserUX Component to display and manage organization assignments
 * for Organization Auth Users in a table format.
 */
export const OrganizationAdminRoleMembershipTable = (props: {
  organizationAdminUser: any;
  updatable: boolean;
}) => {
  const [selectingRoles, setSelectingRoles] = useState(false);

  const columns = [
    {
      title: 'Role',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (_, data) => <Link to={`/roles/${data.id}`}>{data.name}</Link>,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, data) =>
        props.updatable ? (
          <OrganizationAdminUserRoleMembershipActions
            organizationAdminUser={props.organizationAdminUser}
            role={data}
            refreshTable={() => {}}
          />
        ) : null,
    },
  ];

  return (
    <>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <h5>Roles</h5>
        {props.updatable && (
          <Button
            type="primary"
            size={'small'}
            style={{paddingLeft: 10, paddingRight: 10}}
            onClick={() => setSelectingRoles(true)}
          >
            <FontAwesomeIcon icon={faPlusCircle} color="white" style={{marginRight: 10}} />
            Add Role
          </Button>
        )}
      </div>

      <Table dataSource={props.organizationAdminUser.roles} columns={columns} />

      <OrganizationRoleMembershipPicker
        organizationAdminUser={props.organizationAdminUser}
        visible={selectingRoles}
        onDismiss={() => setSelectingRoles(false)}
      />
    </>
  );
};

export const OrganizationAdminUserRoleMembershipActions = (props: {
  organizationAdminUser: any;
  role: any;
  refreshTable: () => void;
}) => {
  const globalOrg = useGlobalOrg();
  const {refreshTable} = props;
  const thunkDispatch = useDispatch<ThunkDispatch>();

  const client = FhirUtils.useAxiosClient();
  const [loading, setLoading] = React.useState(false);

  const onRemove = async () => {
    setLoading(true);
    try {
      await thunkDispatch(
        OrgAdminUser.updateOne(
          client,
          {
            id: props.organizationAdminUser.id,
            // organizations: props.organizationAdminUser.organizations.filter(
            //   (o) => o.id != props.organization.id
            // ),
            roles: props.organizationAdminUser.roles.filter((r) => r.id !== props.role.id),
          },
          {_organizationId: globalOrg?.id}
        )
      );
      refreshTable();
      message.success('Organization admin user was successfully removed from the organization.');
    } catch (error) {
      message.error(`Error removing the organiztion admin user from the organization: ${error}`, 6);
    }
    setLoading(false);
  };

  return (
    <Space>
      <Popconfirm
        title="Are you sure you want to remove this role from this user?"
        onConfirm={onRemove}
        okText="Yes"
        cancelText="No"
        disabled={props.organizationAdminUser.roles.length <= 1}
      >
        <Space>
          <Tooltip
            title={
              props.organizationAdminUser.roles.length <= 1 &&
              'A user must be assigned to at least one Role. Add a new Role before removing one'
            }
          >
            <Button
              type="link"
              danger
              disabled={props.organizationAdminUser.roles.length <= 1}
              size={'small'}
              style={{paddingLeft: 10, paddingRight: 10}}
            >
              Remove
            </Button>
          </Tooltip>
          {loading && <Spin size="small" />}
        </Space>
      </Popconfirm>
    </Space>
  );
};

export const OrganizationRoleMembershipPicker = (props: {
  organizationAdminUser: any;
  visible: boolean;
  onDismiss?: () => void;
}) => {
  const globalOrg = useGlobalOrg();
  const [visible, setVisible] = useState(props.visible);
  const [loading, setLoading] = React.useState(null);
  const thunkDispatch = useDispatch<ThunkDispatch>();
  const client = FhirUtils.useAxiosClient();

  useEffect(() => setVisible(props.visible), [props.visible]);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Actions',
      render: (_, role) => (
        <Space>
          {props.organizationAdminUser.roles.find((r) => r.id === role.id) ? (
            <Button
              type="primary"
              className="success"
              size={'small'}
              style={{
                paddingLeft: 10,
                paddingRight: 10,
                backgroundColor: colors.green,
                borderColor: colors.green,
              }}
            >
              <FontAwesomeIcon icon={faCheckCircle} color="white" style={{marginRight: 10}} />
              Added
            </Button>
          ) : (
            <Space>
              <Button
                type="primary"
                size={'small'}
                style={{
                  paddingLeft: 10,
                  paddingRight: 10,
                  alignItems: 'center',
                  display: 'flex',
                }}
                onClick={() => onAdd(role)}
              >
                <FontAwesomeIcon icon={faPlusCircle} color="white" style={{marginRight: 10}} />
                Add to User
              </Button>
              {loading === role.id && <Spin size="small" />}
            </Space>
          )}
        </Space>
      ),
    },
  ];

  const onAdd = async (role) => {
    setLoading(role.id);
    try {
      await thunkDispatch(
        OrgAdminUser.updateOne(
          client,
          {
            id: props.organizationAdminUser.id,
            roles: [...props.organizationAdminUser.roles, role],
          },
          {_organizationId: globalOrg?.id || ''}
        )
      );
      message.success(
        `Role ${role.name} successfully added to user (${props.organizationAdminUser.firstName} ${props.organizationAdminUser.lastName}).`
      );
    } catch (error) {
      message.error(
        `Error adding role ${role.name} to user (${props.organizationAdminUser.firstName} ${props.organizationAdminUser.lastName}): ${error}`,
        6
      );
    }
    setLoading(null);
  };

  const dismissModal = () => {
    setVisible(false);
    if (props.onDismiss) props.onDismiss();
  };

  return (
    <Modal
      visible={visible}
      title={`Add Role to User (${props.organizationAdminUser.firstName} ${props.organizationAdminUser.lastName})`}
      width={'60%'}
      okText="Done"
      onOk={dismissModal}
      bodyStyle={{height: 500, overflow: 'scroll'}}
      onCancel={dismissModal}
      cancelButtonProps={{style: {display: 'none'}}}
      destroyOnClose
    >
      <FHIRTable
        fhirResource="role"
        columns={columns}
        defaultPrimarySearchParam="name"
        mode="table"
        fixedFilters={{assignable: 'true'}}
        noPersistSearchTerm={true}
      />
    </Modal>
  );
};
