"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_CoverageEligibilityRequest = exports.CoverageEligibilityRequestPurposeKind = void 0;
var t = __importStar(require("io-ts"));
var RTTI_id_1 = require("../Scalar/RTTI_id");
var RTTI_Meta_1 = require("./RTTI_Meta");
var RTTI_uri_1 = require("../Scalar/RTTI_uri");
var RTTI_Element_1 = require("./RTTI_Element");
var RTTI_code_1 = require("../Scalar/RTTI_code");
var RTTI_Narrative_1 = require("./RTTI_Narrative");
var RTTI_ResourceList_1 = require("../Union/RTTI_ResourceList");
var RTTI_Extension_1 = require("./RTTI_Extension");
var RTTI_Identifier_1 = require("./RTTI_Identifier");
var RTTI_CodeableConcept_1 = require("./RTTI_CodeableConcept");
var RTTI_Reference_1 = require("./RTTI_Reference");
var RTTI_Period_1 = require("./RTTI_Period");
var RTTI_dateTime_1 = require("../Scalar/RTTI_dateTime");
var RTTI_CoverageEligibilityRequest_SupportingInfo_1 = require("./RTTI_CoverageEligibilityRequest_SupportingInfo");
var RTTI_CoverageEligibilityRequest_Insurance_1 = require("./RTTI_CoverageEligibilityRequest_Insurance");
var RTTI_CoverageEligibilityRequest_Item_1 = require("./RTTI_CoverageEligibilityRequest_Item");
var CoverageEligibilityRequestPurposeKind;
(function (CoverageEligibilityRequestPurposeKind) {
    CoverageEligibilityRequestPurposeKind["_authRequirements"] = "auth-requirements";
    CoverageEligibilityRequestPurposeKind["_benefits"] = "benefits";
    CoverageEligibilityRequestPurposeKind["_discovery"] = "discovery";
    CoverageEligibilityRequestPurposeKind["_validation"] = "validation";
})(CoverageEligibilityRequestPurposeKind = exports.CoverageEligibilityRequestPurposeKind || (exports.CoverageEligibilityRequestPurposeKind = {}));
var EnumType_1 = require("../../EnumType");
exports.RTTI_CoverageEligibilityRequest = t.recursion('ICoverageEligibilityRequest', function () {
    return t.intersection([
        t.type({
            resourceType: t.literal('CoverageEligibilityRequest'),
            patient: RTTI_Reference_1.RTTI_Reference,
            insurer: RTTI_Reference_1.RTTI_Reference
        }),
        t.partial({
            id: RTTI_id_1.RTTI_id,
            meta: RTTI_Meta_1.RTTI_Meta,
            implicitRules: RTTI_uri_1.RTTI_uri,
            _implicitRules: RTTI_Element_1.RTTI_Element,
            language: RTTI_code_1.RTTI_code,
            _language: RTTI_Element_1.RTTI_Element,
            text: RTTI_Narrative_1.RTTI_Narrative,
            contained: t.array(RTTI_ResourceList_1.RTTI_ResourceList),
            extension: t.array(RTTI_Extension_1.RTTI_Extension),
            modifierExtension: t.array(RTTI_Extension_1.RTTI_Extension),
            identifier: t.array(RTTI_Identifier_1.RTTI_Identifier),
            status: RTTI_code_1.RTTI_code,
            _status: RTTI_Element_1.RTTI_Element,
            priority: RTTI_CodeableConcept_1.RTTI_CodeableConcept,
            purpose: t.array(EnumType_1.createEnumType(CoverageEligibilityRequestPurposeKind, 'CoverageEligibilityRequestPurposeKind')),
            _purpose: t.array(RTTI_Element_1.RTTI_Element),
            servicedDate: t.string,
            _servicedDate: RTTI_Element_1.RTTI_Element,
            servicedPeriod: RTTI_Period_1.RTTI_Period,
            created: RTTI_dateTime_1.RTTI_dateTime,
            _created: RTTI_Element_1.RTTI_Element,
            enterer: RTTI_Reference_1.RTTI_Reference,
            provider: RTTI_Reference_1.RTTI_Reference,
            facility: RTTI_Reference_1.RTTI_Reference,
            supportingInfo: t.array(RTTI_CoverageEligibilityRequest_SupportingInfo_1.RTTI_CoverageEligibilityRequest_SupportingInfo),
            insurance: t.array(RTTI_CoverageEligibilityRequest_Insurance_1.RTTI_CoverageEligibilityRequest_Insurance),
            item: t.array(RTTI_CoverageEligibilityRequest_Item_1.RTTI_CoverageEligibilityRequest_Item)
        })
    ]);
});
