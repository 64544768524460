import React from 'react';

import {Typography} from 'antd';
import {SortOrder} from 'antd/lib/table/interface';
import moment from 'moment';
import {FHIRTable} from '../fhir-table';

const {Title} = Typography;

export const ConsentsWidget = (props) => {
  const {patient} = props;

  return (
    <>
      <Title level={4}>Consents</Title>

      <FHIRTable
        fhirResource={'consents'}
        fixedFilters={{patientId: patient.id}}
        hideSearch
        mode="table"
        columns={columns}
        size="small"
        style={{width: '100%'}}
      />
    </>
  );
};

export const columns = [
  {
    title: 'Consent Type',
    render: (_, c) => c.consentDefinition?.name,
  },
  // {
  //   title: 'Version',
  //   render: (_, c) => c.consentDefinition?.version,
  // },
  // {
  //   title: 'Date Consented',
  //   dataIndex: 'timestamp',
  //   key: 'timestamp',
  //   sortDirections: ['descend', 'ascend'] as SortOrder[],
  //   render: (timestamp) => moment(timestamp).format('LLL'),
  // },
];
