import {createSlice} from '@reduxjs/toolkit';
import {normalize, schema} from 'normalizr';
import querystring from 'querystring';
import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState, ThunkDispatch} from '.';
import {FhirUtils} from '../services/fhir';
import {store} from '../store';
import {mapBundleToResourceArray} from '../util/fhir';
import {RequiredValidationRuleId} from '../validation/validation-rules/required-validation';
import {ValidationRules} from '../validation/validation-rules/validation-rules';
import {User} from './users-smile';

export interface Organization {
  id: string;
  name: {
    en: string;
    fr: string;
  };
  nameEn: string;
  nameFr: string;
  parentOrganization: {
    id: string;
    name: {
      en: string;
      fr: string;
    };
    nameEn: string;
    nameFr: string;
  };
  demographicSchema: any;
  fieldSchema: {
    id: string;
    name: string;
    fields: {id: string; name: string; key: string; section: string}[];
  };
  organizationCode: string;
}

export const OrganizationValidation: {
  [property: string]: ValidationRules[];
} = {
  nameEn: [
    {
      validationRuleType: RequiredValidationRuleId,
    },
  ],
  nameFr: [
    {
      validationRuleType: RequiredValidationRuleId,
    },
  ],
  organizationCode: [{validationRuleType: RequiredValidationRuleId}],
  type: [{validationRuleType: RequiredValidationRuleId}],
};

export interface OrganizationsSliceInterface {
  byId: {[string: string]: Organization};
  historyById: {[string: string]: Organization};
}
const initialState = {
  byId: {},
  historyById: {},
};
const organization = new schema.Entity('organizations', {});
const organizationHistory = new schema.Entity(
  'organizations',
  {},
  {
    idAttribute: (value) => {
      return `${value.id}-${value.meta.versionId}`;
    },
  }
);

const slice = createSlice({
  name: 'organizations',
  initialState,
  reducers: {
    SAVE_ORGANIZATIONS: (state: OrganizationsSliceInterface, action) => {
      const resources = mapBundleToResourceArray(action.payload);
      state.byId = normalize(resources, [organization]).entities.organizations || {};
    },
    SAVE_ORGANIZATION: (state: OrganizationsSliceInterface, action) => {
      state.byId[action.payload.id] = action.payload;
    },
    DELETE_ORGANIZATION: (state: OrganizationsSliceInterface, action) => {
      delete state.byId[action.payload.id];
    },
    SAVE_ORGANIZATION_HISTORY: (state: OrganizationsSliceInterface, action) => {
      const resources = mapBundleToResourceArray(action.payload);
      state.historyById = normalize(resources, [organizationHistory]).entities.organizations || {};
    },
  },
});

export const getAll = (client, params?) => async (dispatch) => {
  let query = params ? querystring.stringify(params) : undefined;
  const res = await client.get(`Organization${query ? `?${query}` : ''}`);
  dispatch(slice.actions.SAVE_ORGANIZATIONS(res.data));
  return res.data;
};

const getOne = (client, id) => async (dispatch) => {
  return client.get(`/Organization/${id}`).then((res) => {
    const storedOrg = getStoredOrg(res.data.id);
    /* If org is not found in user's list of accessible orgs, do not save/store organization.
     This is relevant to the all-patients roles that require access outside orgs, but no
     write permissions for those orgs */
    if (!storedOrg) return res.data;
    return dispatch(slice.actions.SAVE_ORGANIZATION(res.data));
  });
};

const getAllForUser = (client, user: User) => async (dispatch) => {
  const orgIds = user.authorities.reduce((orgIds, auth) => {
    if (
      auth.permission === 'FHIR_READ_ALL_IN_COMPARTMENT' &&
      auth.argument.startsWith('Organization/')
    ) {
      const orgId = auth.argument.split('/')[1];
      return orgIds.concat([orgId]);
    }
    return orgIds;
  }, []);

  orgIds.forEach((id) => dispatch(getOne(client, id)));
};

const getOneHistory = (client, id) => async (dispatch) => {
  return client.request(`/Organization/${id}/_history`).then((res) => {
    return dispatch(slice.actions.SAVE_ORGANIZATION_HISTORY(res));
  });
};

const updateOne = (client, org) => async (dispatch) => {
  return client.put(`/Organization/${org.id}`, org).then(async (res) => {
    await dispatch(slice.actions.SAVE_ORGANIZATION(res.data));
    return res.data;
  });
};

export const createOne = (client, org) => async (dispatch) => {
  return client.post('/Organization', org).then(async (res) => {
    await dispatch(slice.actions.SAVE_ORGANIZATION(res.data));
    return res.data;
  });
};

export const deleteOne = (client, itemId) => async (dispatch) => {
  return client.delete(`/Organization/${itemId}`).then(async (res) => {
    await dispatch(slice.actions.DELETE_ORGANIZATION({id: itemId}));
    return res.data;
  });
};

export default {
  slice,
  getAll,
  getAllForUser,
  getOne,
  updateOne,
  createOne,
  getOneHistory,
  deleteOne,
};

export function userHasAuthorityForOrganization(user, orgId): boolean {
  return user.authorities?.find(
    (auth) =>
      (auth.permission === 'FHIR_READ_ALL_IN_COMPARTMENT' ||
        auth.permission === 'FHIR_WRITE_ALL_IN_COMPARTMENT') &&
      auth.argument === `Organization/${orgId}`
  );
}

export const useOrganizations = () => {
  const thunkDispatch = useDispatch<ThunkDispatch>();
  const client = FhirUtils.useClient();
  const [organizations, setOrganizations] = useState<{[string: string]: Organization}>(
    useSelector((state: RootState) => state.organizations.byId)
  );

  React.useEffect(() => {
    fetchOrganizations();
  }, []);

  async function fetchOrganizations() {
    const result = await thunkDispatch(getAll(client));
    setOrganizations(result?.entry?.map((o) => o.resource) || []);
  }

  return Object.values(organizations);
};

export const useOrganizationWithId = (id) => {
  const client = FhirUtils.useClient();
  const [organization, setOrganization] = useState<any>(
    useSelector((state: RootState) => state.organizations.byId[id])
  );

  React.useEffect(() => {
    if (!id) return;
    /* Fetch org if organization is not in local state */
    fetchOrganization();
  }, [id]);

  async function fetchOrganization() {
    const result = await client.get(`/organization/${id}`).then((res) => res.data);
    setOrganization(result?.payload || result);
  }

  return organization;
};

/** Get organization from stored local state */
export const getStoredOrg = (id) => {
  return store.getState().organizations.byId[id];
};
