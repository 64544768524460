import {ScissorOutlined} from '@ant-design/icons';
import {colors, formatDateEngFr, Status} from '@canimmunize/tools';
import {Row} from 'antd';
import moment from 'moment';
import React from 'react';
import {useEnvInfo} from '../../../../services/environment';
import {DoseBoxCompact} from '../dose-box-compact';
import styles from './index.module.css';

export const CompactReceipt = (props) => {
  const {data, logoUrl, logoAlt} = props;

  const createdAt = moment();

  return (
    <div className={styles.pageContainer}>
      <img src={logoUrl} alt={logoAlt} className={styles.headerLogo} />
      <WalletDescription />
      <div className={styles.businessCardsContainer}>
        <ScissorOutlined />
        {data && (
          <>
            <CompactFront
              pvcData={data}
              createdAt={createdAt}
              logoUrl={logoUrl}
              logoAlt={logoAlt}
            />
            <CompactRear pvcData={data} createdAt={createdAt} logoUrl={logoUrl} logoAlt={logoAlt} />
          </>
        )}
      </div>
    </div>
  );
};

const WalletDescription = (props) => {
  const {environmentId} = useEnvInfo();

  if (environmentId === 'pei') {
    return (
      <div>
        <hr className={styles.descriptionDivider} />
        <div className={styles.descriptionText}>
          Below you will find a wallet-sized of your Prince Edward Island proof of vaccination (PEI
          Vax Pass). This is included here for your convenience. You can fold it along the dotted
          line to only display the QR portion. Do not fold over the QR Code. You are responsible for
          protecting your personal information. Remember to keep copies of your record in a safe
          place. If you have any questions about the Vax Pass program, please visit
          www.princeedwardisland.ca/vaxpass.
        </div>
        <div className={styles.descriptionText}>
          <b>PLEASE NOTE:</b> This wallet-sized version of your PEI Vax below will not be accepted
          for international travel and may not be accepted for use outside of Prince Edward Island.
          If you choose to use this wallet version, you should keep the your PEI Proof of
          Vaccination (PDF) at hand in case it is required.
        </div>
        <br />
        <hr className={styles.descriptionDivider} />
        <div className={styles.descriptionText}>
          Pour vous faciliter les choses, vous trouverez ci-dessous votre attestation de vaccination
          de l’Île-du-Prince-Édouard (laissez-passer vaccinal de l’Î.-P.-É.) en format portefeuille.
          Vous pouvez plier le papier le long du pointillé pour afficher uniquement le code QR.
          Assurez-vous de ne pas plier le papier par-dessus le code QR. Vous êtes responsable de la
          protection de vos renseignements personnels. N’oubliez pas de conserver des copies de
          votre attestation en lieu sûr. Si vous avez des questions sur le programme de
          laissez-passer vaccinal, visitez le site
          www.princeedwardisland.ca/fr/laissezpasservaccinal.
        </div>
        <div className={styles.descriptionText}>
          <b>VEUILLEZ NOTER : </b>Le format portefeuille de votre laissez-passer vaccinal de
          l’Î.-P.-É. ne <b>sera pas</b> accepté aux fins de voyages internationaux. De même, il est
          possible qu’il ne soit pas accepté à l’extérieur de l’Île-du-Prince-Édouard. Si vous
          choisissez d’utiliser ce format, vous devriez garder à portée de main votre attestation de
          vaccination contre la COVID-19 de l’I.-P.-É , au cas où elle serait requise.
        </div>
        <br />
        <hr className={styles.descriptionDivider} />
      </div>
    );
  } else {
    return (
      <div className={styles.descriptionText}>
        <hr className={styles.descriptionDivider} />
        <div className={styles.descriptionText}>
          The <i>wallet-size</i> version of your Nova Scotia COVID-19 Proof of Vaccination is
          intended for use within Nova Scotia. It may not be recognized by other jurisdictions.
          Please keep and use the full size proof of vaccination when outside the province.
        </div>
        <div className={styles.descriptionText}>
          The <i>wallet-size</i> version can be cut out and folded along the centre line. Do not
          fold the QR code to ensure it can be read.
        </div>
      </div>
    );
  }
};

const CompactFront = (props) => {
  const {createdAt, pvcData, logoUrl, logoAlt} = props;

  const pvcPatientData = pvcData.patient;
  const pvcDoses = pvcData.vaccines;
  const doseCount = pvcDoses?.length || 0;

  return (
    <div className={styles.businessCardContainer}>
      <div className={styles.businessCard}>
        <div className={styles.businessCardHeader}>
          <img src={logoUrl} alt={logoAlt} className={styles.nsLogo} />
          <img
            alt="Powered by CANImmunize Logo"
            src="https://cdn.canimmunize.ca/powered-by-canimmunize-horizontal-en@2x.png"
            className={styles.canImmunizeLogo}
          />
        </div>
        <hr className={styles.businessCardDivider} />
        <div className={styles.patientLabelContainer}>
          <Row align="middle" justify="space-between">
            <div className={styles.titleCard}>{pvcPatientData.name}</div>
            <div className={styles.subtitleCard}>DOB: {pvcPatientData.dateOfBirth}</div>
          </Row>
        </div>
        <DoseGrid doses={pvcDoses} lang={'en'} doseCount={doseCount} />
        <div className={styles.cardLabelContainer}>
          <div className={styles.subtitleCard}>
            Printed on {formatDateEngFr(createdAt.format('YYYY-MM-DD'))}
          </div>
        </div>
      </div>
    </div>
  );
};

const CompactRear = (props) => {
  const {pvcData, createdAt, logoUrl, logoAlt} = props;
  const pvcPatientData = pvcData.patient;

  return (
    <div className={styles.businessCardContainer}>
      <div className={styles.businessCardRear}>
        <div className={styles.rearContentContainer}>
          <img src={logoUrl} alt={logoAlt} className={styles.nsLogoRear} />
          <hr className={styles.businessCardDivider} />
          <div className={styles.indented}>
            <div className={styles.titleCard}>{pvcPatientData.name}</div>
            <div className={styles.subtitleCard}>DOB: {pvcPatientData.dateOfBirth}</div>
            <div className={styles.subtitleCard}>
              Printed on {formatDateEngFr(createdAt.format('YYYY-MM-DD'))}
            </div>
          </div>
        </div>
        <div className={styles.qrContainer}>
          <img
            src={`data:image/svg+xml;base64,${pvcData?.qrcode}`}
            alt=""
            className={styles.qrCode}
          />
        </div>
      </div>
    </div>
  );
};

const DoseGrid = (props) => {
  const {doses} = props;

  return (
    <div className={styles.doseBoxGrid}>
      {doses.slice().map((dose, i) => {
        return (
          <DoseBoxCompact
            key={i}
            subTitle={dose.product}
            status={Status.RECEIVED}
            lotNumber={dose.lot}
            statusText={dose.date}
            extra={doses.length > 2}
          />
        );
      })}
      {doses.length === 0 && (
        <div
          style={{
            width: '100%',
            color: colors.lightText,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 80,
          }}
        >{`No Received Vaccinations`}</div>
      )}
    </div>
  );
};
