import React from 'react';
import {Modal} from 'antd';

import {VersionHistoryTable} from './version-history-table';

/* A modal for displaying an entity's version history. */
export const VersionHistoryModal = (props) => {
  const {visible, onClose, fhirResource, entityId, columns} = props;

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      footer={null}
      width={'90%'}
      title={'Previous Versions'}
      destroyOnClose
    >
      <VersionHistoryTable fhirResource={fhirResource} entityId={entityId} columns={columns} />
    </Modal>
  );
};
