import React from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '../../models';
import Campaign from '../../models/campaign';
import {BaseEntityV2, FormConfig} from '../base-entity/base-entity';
import {FHIRTableProps} from '../fhir-table';
import {FormUX} from '../form-ux';
import {OrganizationPickerFormItem} from '../organization/organization-picker';
import {CampaignCustomFieldName, CampaignUXModel} from './campaign-form-ux-model';

export const CampaignUX = (props) => {
  console.log('CampgianUx2Props', props);
  const globalOrg = useSelector((state: RootState) => state.ui.localOrg);
  const model = Campaign;

  const itemTitleField = 'name';

  const fixedFilters = globalOrg
    ? {
        _organizationId: globalOrg.id,
      }
    : undefined;

  const tableConfig: FHIRTableProps = {
    fhirResource: 'campaign',
    label: 'Campaign',
    fixedFilters,
    defaultPrimarySearchParam: 'name',
    mode: 'table',
    showCreateButton: true,
    columns: [
      {
        title: 'Name',
        dataIndex: 'name',
      },
    ],
  };

  const CampaignForm = (props) => {
    return (
      <FormUX
        formUXModel={CampaignUXModel}
        createMode={props.createMode}
        modal={props.modal}
        renderCustomField={(field) => {
          switch (field.name) {
            case CampaignCustomFieldName.OrganizationId: {
              return (
                <OrganizationPickerFormItem
                  label={field.label}
                  name={field.name}
                  setFieldValue={props.setFieldValue}
                  validationRules={field.validationRules}
                />
              );
            }
            default: {
              throw new Error(`Unhandled custom field ${field.name} in
              renderCustomField method`);
            }
          }
        }}
      />
    );
  };

  const formConfig: FormConfig = {
    defaultValues: {},
    FormItems: CampaignForm,
  };

  const config = {
    slug: 'campaign',
    model,
    formConfig,
    itemTitleField,
    searchPageTitle: 'Campaigns',
    fhirResource: 'campaign',
    tableConfig,
    modalCreateForm: true,
    hideEditButton: true,
    hideDeleteButton: true,
    titleBarEntityLabel: 'Campaign',
  };
  return <BaseEntityV2 {...config} />;
};
