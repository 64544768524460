//this component will hold dose information (same as dose-box), but in a more compact form factor for the business card
import styles from './index.module.css';
import React from 'react';
import {Ids, Status, StrLang} from '@canimmunize/tools';
import {useUrlData} from '../../../../components/scheduler/util';

//the compact dose box uses all the same props as the full scale dose box
interface DoseBoxCompactProps {
  subTitle: string;
  disabled?: boolean;
  statusText: string;
  lotNumber?: string;
  status?: Status;
  locationText?: React.ReactChild;
  right?: React.ReactChild;
  extra?: boolean; //to change formatting for more than 2 doses
}

export const DoseBoxCompact = (props: DoseBoxCompactProps) => {
  const {lang} = useUrlData();
  if (props.extra) {
    return (
      <div className={styles.doseBoxContainerExtra}>
        <div className={styles.doseBoxExtra}>
          {props.disabled && <div className={styles.disabled} />}
          <div className={styles.content}>
            <div className={styles.textExtra}>
              <b>Date: </b>
              {props.statusText}
            </div>
            <div className={styles.textExtra}><b>Product / Produit:</b> {props.subTitle}</div>
            {props.lotNumber && (
              <div className={styles.textExtra}>
                <b>Lot: </b>
                {`${props.lotNumber || StrLang(lang, Ids.unknown)}`}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className={styles.doseBoxContainer}>
        <div className={styles.doseBox}>
          {props.disabled && <div className={styles.disabled} />}
          <div className={styles.content}>
            <div className={styles.text}>
              <b>Date: </b>
              {props.statusText}
            </div>
            <div className={styles.text}><b>Product / Produit:</b> {props.subTitle}</div>
            {props.lotNumber && (
              <div className={styles.text}>
                <b>Lot: </b>
                {`${props.lotNumber || StrLang(lang, Ids.unknown)}`}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
};
