import {useAuth0, RedirectLoginOptions, LogoutOptions} from '@auth0/auth0-react';
import {useLocation} from 'react-router-dom';

/**
 * Provides a set of utility/helpler methods used to keep track of user inactivity state
 * information (in localstorage), in order to provide a consistent auto-logout experience,
 * if/when the user exists the application (i.e. closes their browser) without properly
 * logging out.
 * @param maxIdleTime the maximum amount of time allowed to pass between user interactions
 *                    with the application (ex: mouse/touch/keyboard events) before the user
 *                    is considered as inactive
 * @returns a set of helper functions
 */
export const useInactivityPersistanceHelper = (maxIdleTime: number) => {
  const key: string = '__inactph';

  /**
   * The class/json object containing user inactivity state information that is
   * persisted in localstorage
   */
  class InactivityInfo {
    e: number | null = null; // idle expiration time (epoch ms)
    r: string | null = null; // redirect path
  }

  /**
   * Retrieves inactivty state information from localstorage
   * @returns inactivity state information
   */
  const _get = () => {
    const s: string = window.localStorage.getItem(key) || '';
    let o: InactivityInfo;
    try {
      o = s.length ? <InactivityInfo>JSON.parse(atob(s)) : new InactivityInfo();
    } catch (error) {
      // handle poorly formatted/encoded value
      _save(); // remove item from localstorage
      o = new InactivityInfo();
    }
    return o;
  };

  /**
   * Writes inactivity state information to localstorage
   * @param o the InactivityInfo object to be written to localstorage
   *          if not specified, or contains null data (i.e. o.e === null AND
   *          o.r === null), the item is removed from storage, rather than updated
   */
  const _save = (o?: InactivityInfo) => {
    if (o?.e || o?.r) {
      window.localStorage.setItem(key, btoa(JSON.stringify(o)));
    } else {
      window.localStorage.removeItem(key);
    }
  };

  /**
   * Recalculates the idle expiration time and writes it to localstorage
   */
  const updateInactivityTimeout = () => {
    let o: InactivityInfo = _get();
    o.e = Date.now() + maxIdleTime;
    _save(o);
  };

  /**
   * Clears the idle expiration time (sets it to a meaningless value: 0)
   */
  const clearInactivityTimeout = () => {
    let o: InactivityInfo = _get();
    o.e = null;
    _save(o);
  };

  /**
   * Throws an exception if the current user is considered to have been
   * inactive for longer than the permitted maxIdleTime
   */
  const checkInactivityTimeout = () => {
    let o: InactivityInfo = _get();
    if (o.e && o.e < Date.now()) throw Error();
  };

  /**
   * Persists the redirect url path in localstorage, so that we can seemlessly
   * redirect the user to the appropriate "page", once all forced logout (due to user
   * inactivity) and login related oAuth redirects have been performed
   * @param path the target application url path
   */
  const updateRedirectPath = (path: string) => {
    let o: InactivityInfo = _get();
    o.r = path;
    _save(o);
  };

  /**
   * Clears the redirect path (sets it to a meaningless value: an empty string)
   */
  const clearRedirectPath = () => {
    let o: InactivityInfo = _get();
    o.r = null;
    _save(o);
  };

  /**
   * Retrieves the redirect path from localstorage
   * @returns a string containing the redirect path
   *          if no redirect path is set, null is returned
   */
  const getRedirectPath = () => {
    return _get().r;
  };

  /**
   * Clears all inactivity info from localstorage
   */
  const clearInactivityInfo = () => {
    _save();
  };

  return {
    checkInactivityTimeout,
    clearInactivityInfo,
    clearInactivityTimeout,
    clearRedirectPath,
    getRedirectPath,
    updateInactivityTimeout,
    updateRedirectPath,
  };
};

/**
 * Provides a set of helpler/wrapper methods around the standard auth0-react login and logout
 * methods.  These helper/wrapper methods use the inactivity state information mangaged by
 * the useInactivityPersistanceHelper, in order to provide a consistent auto-logout experience,
 * if/when the user exists the application (i.e. closes their browser) without properly
 * logging out.
 * @param maxIdleTime the maximum amount of time allowed to pass between user interactions
 *                    with the application (ex: mouse/touch/keyboard events) before the user
 *                    is considered as inactive
 * @returns a set of helper functions
 */
export const useInactivityHelper = (maxIdleTime: number) => {
  const {
    checkInactivityTimeout,
    clearInactivityInfo,
    clearInactivityTimeout,
    getRedirectPath,
    updateRedirectPath,
  } = useInactivityPersistanceHelper(maxIdleTime);

  const auth0LoginWithRedirect = useAuth0().loginWithRedirect;
  const auth0Logout = useAuth0().logout;
  const location = useLocation();

  /**
   * Log the user in
   * @param options auth0 login options
   */
  const loginWithRedirect = (options?: {returnTo: string} & RedirectLoginOptions) => {
    const returnTo = options?.returnTo
      ? options?.returnTo
      : location.pathname !== '/home'
      ? location.pathname
      : getRedirectPath();
    clearInactivityInfo();
    return returnTo?.length
      ? auth0LoginWithRedirect(
          options ? {...options, appState: {returnTo}} : {appState: {returnTo}}
        )
      : auth0LoginWithRedirect(options);
  };

  /**
   * Log the user out and remove any stored timestamp for their last
   * detected activity
   * @param options auth0 logout options
   */
  const logout = (options?: LogoutOptions) => {
    clearInactivityInfo();
    auth0Logout(options);
  };

  /**
   * Log the user out if they have not been active for longer than the
   * specified maxIdleTime
   * @param options auth0 logout options
   */
  const inactivityLogout = (options?: LogoutOptions) => {
    try {
      checkInactivityTimeout();
    } catch (error) {
      clearInactivityTimeout();
      updateRedirectPath(location.pathname);
      auth0Logout(options);
    }
  };

  return {loginWithRedirect, logout, inactivityLogout};
};
