import React from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '../../models';
import Portal from '../../models/portal';
import {FormConfig} from '../base-entity/base-entity';
import {BaseEntityV2} from '../base-entity/base-entity';
import {BookingPageStatusTag} from '../booking-pages/booking-page-ux';
import {FHIRTableMode, FHIRTableProps} from '../fhir-table';
import {FormUX} from '../form-ux';
import {OrganizationPickerFormItem} from '../organization/organization-picker';
import {PortalFormCustomFieldName, PortalFormUXModel} from './portal-form-ux-model';
import {PortalListActions} from './portal-list-actions';

export const PortalUX = () => {
  const globalOrg = useSelector((state: RootState) => state.ui.localOrg);

  const fixedFilters = globalOrg
    ? {
        _organizationId: globalOrg.id,
        _filterField: 'status',
        _filterValues: ['active', 'protected'],
      }
    : {
        _organizationId: '',
        _filterField: 'status',
        _filterValues: ['active', 'protected'],
      };

  const columns = [
    {
      title: `Name`,
      dataIndex: 'name',
      key: 'name',
      sorter: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      render: (_, item) => {
        return <BookingPageStatusTag bookingPage={item} />;
      },
      filters: [
        {text: 'Active', value: 'active'},
        {text: 'Password Protected', value: 'protected'},
        {text: 'Inactive', value: 'inactive'},
      ],
    },
    {
      title: 'Actions',
      render: (_, item) => <PortalListActions portal={item} />,
    },
  ];

  const model = Portal;

  const itemTitleField = 'name';

  const FormItems = (props) => {
    const {parentOrganizationId, id} = props.values;
    return (
      <FormUX
        formUXModel={PortalFormUXModel}
        createMode={props.createMode}
        modal={props.modal}
        renderCustomField={(field) => {
          switch (field.name) {
            case PortalFormCustomFieldName.OrganizationId: {
              return (
                <OrganizationPickerFormItem
                  label={field.label}
                  name={field.name}
                  setFieldValue={props.setFieldValue}
                  validationRules={field.validationRules}
                />
              );
            }
            default: {
              throw new Error(`Unhandled custom field ${field.name} in
          renderCustomField method`);
            }
          }
        }}
      />
    );
  };

  const formConfig: FormConfig = {
    defaultValues: {
      organizationId: globalOrg?.id,
    },
    FormItems,
  };

  const tableConfig: FHIRTableProps = {
    fhirResource: 'portal',
    label: 'Portal',
    fixedFilters,
    mode: 'table' as FHIRTableMode,
    defaultPrimarySearchParam: 'name',
    columns,
    showCreateButton: true,
  };

  const config = {
    slug: 'portal',
    fhirResource: 'portal',
    model,
    itemTitleField,
    formConfig,
    tableConfig,
    hideEditButton: true,
    hideDeleteButton: true,
    modalCreateForm: true,
    titleBarEntityLabel: 'Organization',
  };

  return <BaseEntityV2 {...config} />;
};
