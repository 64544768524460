import {RequiredShortTextValidationRules} from '../../../services/ui-validation-rules';
import {MinValidationRuleId} from '../../../validation/validation-rules/min-validation';
import {RequiredValidationRuleId} from '../../../validation/validation-rules/required-validation';
import {FormUXFieldType} from '../../form-ux';
import {FormUXModel} from '../../form-ux/form-ux-models/form-ux-model';
import {getEnumValues} from '../../../util/enum-util';
import {EnumValidationRuleId} from '../../../validation/validation-rules/enum-validation';

export enum EligibleBirthMonth {
  January,
  February,
  March,
  April,
  May,
  June,
  July,
  August,
  September,
  October,
  November,
  December,
}

export enum AppointmentTypeStatus {
  ACTIVE = 'active',
  STAGING = 'staging',
  DISABLED = 'disabled',
}

export enum AppointmentTypeCustomFieldName {
  MinAge = 'minAge',
  MaxAge = 'maxAge',
}

export const AppointmentTypeFormUXModel: FormUXModel = [
  {
    type: FormUXFieldType.grouped,
    groupName: 'Name',
    inCreateModal: true,
    fields: [
      {
        name: 'nameEn',
        type: FormUXFieldType.text,
        label: 'En Name',
        editable: true,
        validationRules: RequiredShortTextValidationRules,
      },
      {
        name: 'nameFr',
        type: FormUXFieldType.text,
        label: 'Fr Name',
        editable: true,
        validationRules: RequiredShortTextValidationRules,
      },
    ],
  },
  {
    name: 'status',
    type: FormUXFieldType.select,
    label: 'Status',
    editable: true,
    inCreateModal: true,
    validationRules: [
      {
        validationRuleType: EnumValidationRuleId,
        enumValues: getEnumValues(AppointmentTypeStatus),
      },
    ],
    selectableValues: getEnumValues(AppointmentTypeStatus).map((status) => {
      return {
        key: status,
        label: getLabelForAppointmentTypeStatus(status as AppointmentTypeStatus),
      };
    }),
  },
  {
    type: FormUXFieldType.grouped,
    groupName: 'Duration Display',
    inCreateModal: true,
    fields: [
      {
        name: 'durationDisplayEn',
        type: FormUXFieldType.text,
        label: 'Duration Display (English)',
        editable: true,
        validationRules: RequiredShortTextValidationRules,
      },
      {
        name: 'durationDisplayFr',
        type: FormUXFieldType.text,
        label: 'Duration Display (French)',
        editable: true,
        validationRules: RequiredShortTextValidationRules,
      },
    ],
  },
  {
    name: AppointmentTypeCustomFieldName.MinAge,
    type: FormUXFieldType.custom,
    label: 'Minimum Age (Inclusive)',
    inCreateModal: true,
    editable: true,
    validationRules: [
      {
        validationRuleType: MinValidationRuleId,
        min: 0,
        inclusive: false,
      },
    ],
  },
  {
    name: AppointmentTypeCustomFieldName.MaxAge,
    type: FormUXFieldType.custom,
    label: 'Maximum Age (Inclusive)',
    inCreateModal: true,
    editable: true,
    validationRules: [
      {
        validationRuleType: MinValidationRuleId,
        min: 0,
        inclusive: false,
      },
    ],
  },
  {
    name: 'eligibleBirthMonths',
    type: FormUXFieldType.MultipleSelect,
    label: 'Eligible Birth Months',
    inCreateModal: true,
    editable: true,
    validationRules: [
      {
        validationRuleType: EnumValidationRuleId,
        enumValues: getEnumValues(EligibleBirthMonth),
      },
    ],
    selectableValues: getEnumValues(EligibleBirthMonth).map((month) => {
      return {
        key: month,
        label: getLabelForMonth(month as EligibleBirthMonth),
      };
    }),
  },
  {
    type: FormUXFieldType.grouped,
    groupName: 'Age Eligibility Error Message',
    inCreateModal: true,
    fields: [
      {
        name: 'ageEligibilityErrorMessageEn',
        type: FormUXFieldType.text,
        label: 'Age Eligibility Error Message (English)',
        editable: true,
        validationRules: RequiredShortTextValidationRules,
      },
      {
        name: 'ageEligibilityErrorMessageFr',
        type: FormUXFieldType.text,
        label: 'Age Eligibility Error Message (French)',
        editable: true,
        validationRules: RequiredShortTextValidationRules,
      },
    ],
  },
  {
    name: 'numberOfPatients',
    type: FormUXFieldType.number,
    label: 'Number of Patients',
    inCreateModal: true,
    editable: false,
    validationRules: [
      {
        validationRuleType: RequiredValidationRuleId,
      },
      {
        validationRuleType: MinValidationRuleId,
        min: 0,
        inclusive: false,
      },
    ],
  },
  {
    name: 'listPriority',
    type: FormUXFieldType.number,
    label: 'List Priority',
    inCreateModal: true,
    editable: true,
    validationRules: [
      {
        validationRuleType: MinValidationRuleId,
        min: 0,
        inclusive: true,
      },
    ],
  },
  {
    name: 'maxAvailability',
    type: FormUXFieldType.number,
    label: 'Maximum Availability (in days)',
    inCreateModal: true,
    editable: true,
    validationRules: [
      {
        validationRuleType: MinValidationRuleId,
        min: 0,
        inclusive: false,
      },
    ],
  },
];

export function getLabelForMonth(month: EligibleBirthMonth): string {
  switch (month) {
    case EligibleBirthMonth.January: {
      return 'January';
    }
    case EligibleBirthMonth.February: {
      return 'February';
    }
    case EligibleBirthMonth.March: {
      return 'March';
    }
    case EligibleBirthMonth.April: {
      return 'April';
    }
    case EligibleBirthMonth.May: {
      return 'May';
    }
    case EligibleBirthMonth.June: {
      return 'June';
    }
    case EligibleBirthMonth.July: {
      return 'July';
    }
    case EligibleBirthMonth.August: {
      return 'August';
    }
    case EligibleBirthMonth.September: {
      return 'September';
    }
    case EligibleBirthMonth.October: {
      return 'October';
    }
    case EligibleBirthMonth.November: {
      return 'November';
    }
    case EligibleBirthMonth.December: {
      return 'December';
    }
  }
}

export function getLabelForAppointmentTypeStatus(status: AppointmentTypeStatus): string {
  switch (status) {
    case AppointmentTypeStatus.STAGING: {
      return 'Staging';
    }
    case AppointmentTypeStatus.ACTIVE: {
      return 'Active';
    }
    case AppointmentTypeStatus.DISABLED: {
      return 'Disabled';
    }
  }
}
