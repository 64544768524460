import React from 'react';
import {Form} from 'react-formio';
import _ from 'lodash';

export const Formio = React.memo(
  (props: any) => {
    const {formRef} = props;
    return (
      <div>
        <Form {...props} ref={formRef} />
      </div>
    );
  },
  (a, b) => {
    // const equal = _.isEqual(a.submission.data, b.submission.data) && _.isEqual(a.form, b.form);
    // console.log('Submission.data equal: ', equal);
    return true;
  }
);