import * as Icons from '@ant-design/icons';
import {Button, Card, Col, Row, Space} from 'antd';
import React from 'react';
import {FhirUtils} from '../../services/fhir';
import {downloadTemplate} from './download-template';

interface ImportCardProps {
  title: string;
  description: string;
  buttonLabel: string;
  templateName: string;
  modal: ({visible, onClose}) => React.ReactElement; //this should be the upload modal
}

export const ImportCard = (props: ImportCardProps) => {
  const [modalVisible, setModalVisible] = React.useState<boolean>(false);
  const client = FhirUtils.useAxiosClient();

  return (
    <>
      <Row gutter={[16, 16]} style={{marginBottom: '2em'}}>
        <Col span={24}>
          <Card>
            <Row align="middle">
              <Col span={12} style={{display: 'flex', alignItems: 'center'}}>
                <div>
                  <h2 style={{fontSize: 18, fontWeight: 'bold'}}>{props.title}</h2>
                  <div>{props.description}</div>
                </div>
              </Col>
              <Col span={12} style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Space wrap>
                  <Button
                    type="primary"
                    title="Import Clinics"
                    onClick={() => setModalVisible(true)}
                    style={{
                      height: 40,
                      marginRight: 15,
                    }}
                  >
                    <Icons.MedicineBoxOutlined style={{fontSize: 16}} />
                    <span style={{fontSize: 16}}>{props.buttonLabel}</span>
                  </Button>
                  <Button
                    title="Download Template"
                    onClick={() => downloadTemplate(props.templateName, client)}
                    style={{
                      height: 40,
                    }}
                  >
                    <Icons.DownloadOutlined style={{fontSize: 16}} />
                    <span style={{fontSize: 16}}>Download Template</span>
                  </Button>
                </Space>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <props.modal visible={modalVisible} onClose={() => setModalVisible(false)} />
    </>
  );
};
