import {Field} from 'formik';
import {Select, SelectProps} from 'formik-antd';
import React, {CSSProperties} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {ThunkDispatch} from '../../models';
import {FhirUtils} from '../../services/fhir';
import {RootState} from '../../store';
import {IFormUXSingleItemPicker} from '../form-ux/form-ux-models/form-ux-fields/form-ux-single-item-picker';

/**
 *
 *
 * @interface SingleItemPickerFieldProps
 */
interface SingleItemPickerFieldProps extends Partial<SelectProps> {
  field: IFormUXSingleItemPicker;
}

/* Component to go with the IFormUXSingleItemPicker. Used to select a single
item of an entity type from a list. */
export const SingleItemPicker = (props: SingleItemPickerFieldProps) => {
  /* The current filter to send to the server when quering the server for items.
  This is the value that the user enters into the search bar. 
  Undefined initially to not send this filter to the server.*/
  const [nameFilter, setNameFilter] = React.useState<string | undefined>(undefined);

  /* The next section gets all the items the user can select from by
    using the getAll method on the model */
  const thunkDispatch = useDispatch<ThunkDispatch>();
  const queryParameters = Object.assign(
    {},
    // {
    //   _count: 10,
    //   _getpagesoffset: 0,
    // },
    /* Add the name query parameter so that the server can only send back
    items whose names field contains the nameFilter. 
    Only add it, if nameFilter is not undefined */
    nameFilter
      ? {
          name: nameFilter,
        }
      : undefined
  );
  const client = FhirUtils.useClient();
  React.useEffect(() => {
    thunkDispatch(props.field.model.getAll(client, queryParameters));
  }, [nameFilter]);

  /* Get the items from the store we will show to the user */
  const items: Array<{
    id: string;
    name: string;
  }> = Object.values(
    useSelector((state: RootState) => {
      return state[props.field.model.slice.name].byId;
    })
  );

  return (
    <Field name={props.field.name}>
      {({form}) => {
        const {fromIdValue} = props.field;
        const fieldValue = form.values[props.field.name];
        const value = fieldValue ? (fromIdValue ? fieldValue : fieldValue.id) : undefined;
        return (
          <Select
            {...props}
            allowClear={props.field.allowClear}
            name={props.field.name}
            showSearch
            placeholder={props.field.placeholder}
            optionFilterProp="children"
            onChange={(itemId) => {
              const curItem = items.find((item) => item.id === itemId);
              const value = props.field.fromIdValue ? curItem?.id : curItem;
              form.setFieldValue(props.field.name, value || null);
              setNameFilter(undefined);
            }}
            value={value}
            // onFocus={onFocus}
            // onBlur={onBlur}
            // onSearch={onSearch}
            onSearch={(searchValue) => {
              return setNameFilter(searchValue);
            }}
          >
            {items.map((item) => (
              <Select.Option value={item.id} key={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        );
      }}
    </Field>
  );
};
