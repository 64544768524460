import {AppThunk} from '../store';
import {createModel} from './model-helpers/create-model';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export interface ClinicFlowForm {
  id: string;
  name: string;
  form: any;
  formOptions: any;
}

const formModel = createModel('forms', '/forms');

export interface FormsSliceInterface {
  byId: {[formId: string]: ClinicFlowForm};
}

export const formsSlice = createSlice({
  name: 'forms',
  initialState: {
    byId: {},
  },
  reducers: {
    saveForm: (state: FormsSliceInterface, action: PayloadAction<ClinicFlowForm>) => {
      state.byId[action.payload.id] = action.payload;
    },
  },
});

const FormResourceRoute = '/public/form';

export const getOne = (client, id): AppThunk => {
  return async (dispatch) => {
    return client.get(`${FormResourceRoute}/${id}`).then(async (res) => {
      await dispatch(formsSlice.actions.saveForm(res.data));
      return res.data;
    });
  };
};

export default {
  ...formModel,
};
