import {
  RequiredJsonValidationRules,
  RequiredShortTextValidationRules,
  RequiredSpecialCharsValidationRules
} from '../../services/ui-validation-rules';
import {FormUXFieldType} from '../form-ux';
import {FormUXModel} from '../form-ux/form-ux-models/form-ux-model';

export const ClinicFlowFormFormUXModel: FormUXModel = [
  {
    name: 'name',
    type: FormUXFieldType.text,
    label: 'Name',
    inCreateModal: true,
    validationRules: [
      ...RequiredShortTextValidationRules,
      ...RequiredSpecialCharsValidationRules
    ],
    editable: true,
  },
  {
    name: 'form',
    type: FormUXFieldType.json,
    label: 'Form',
    inCreateModal: true,
    validationRules: RequiredJsonValidationRules,
    editable: true,
  },
  {
    name: 'formOptions',
    type: FormUXFieldType.json,
    label: 'Form Options',
    inCreateModal: true,
    validationRules: RequiredJsonValidationRules,
    editable: true,
  },
];
