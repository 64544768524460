import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, message, Modal, Popconfirm, Space, Spin, Table, Tooltip} from 'antd';
import React, {useEffect, useState} from 'react';
import {FHIRTable} from '../fhir-table';
import {Link} from 'react-router-dom';
import uuid from 'uuid';
import {FhirUtils} from '../../services/fhir';
import OrgAdminUser from '../../models/org-admin-users';
import {useDispatch, useSelector} from 'react-redux';
import {RootState, ThunkDispatch} from '../../models';
import {faCheckCircle, faPlusCircle} from '@fortawesome/free-solid-svg-icons';
import {colors} from '@canimmunize/tools';

/**
 * OrganizationAdminMembershipTable Functional Component
 * Used by the OrgAdminUserUX Component to display and manage organization assignments
 * for Organization Auth Users in a table format.
 */
export const OrganizationAdminMembershipTable = (props: {
  organizationAdminUser: any;
  updatable: boolean;
}) => {
  const [tableState, setTableState] = React.useState(uuid.v4());
  const [selectingOrganizations, setSelectingOrganizations] = useState(false);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'nameEn',
      key: 'nameEn',
      sorter: (a, b) => a.nameEn.localeCompare(b.nameEn),
      render: (_, data) => <Link to={`/organizations/${data.id}`}>{data.nameEn}</Link>,
    },
    {
      title: 'Organization Code',
      dataIndex: 'organizationCode',
      key: 'organizationCode',
      sorter: (a, b) => a.organizationCode.localeCompare(b.organizationCode),
      render: (_, data) => data.organizationCode,
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, data) =>
        props.updatable ? (
          <OrganizationAdminUserMembershipActions
            organizationAdminUser={props.organizationAdminUser}
            organization={data}
            refreshTable={() => setTableState(uuid.v4())}
          />
        ) : null,
    },
  ];

  return (
    <>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <h5>Organizations</h5>
        {props.updatable && (
          <Button
            type="primary"
            size={'small'}
            style={{paddingLeft: 10, paddingRight: 10}}
            onClick={() => setSelectingOrganizations(true)}
          >
            <FontAwesomeIcon icon={faPlusCircle} color="white" style={{marginRight: 10}} />
            Add Organization
          </Button>
        )}
      </div>

      <Table dataSource={props.organizationAdminUser.organizations} columns={columns} />

      <OrganizationMembershipPicker
        organizationAdminUser={props.organizationAdminUser}
        visible={selectingOrganizations}
        onDismiss={() => setSelectingOrganizations(false)}
      />
    </>
  );
};

export const OrganizationAdminUserMembershipActions = (props: {
  organizationAdminUser: any;
  organization: any;
  refreshTable: () => void;
}) => {
  const globalOrg = useSelector((state: RootState) => state.ui.localOrg);
  const {organization, refreshTable} = props;
  const thunkDispatch = useDispatch<ThunkDispatch>();
  const client = FhirUtils.useAxiosClient();
  const [loading, setLoading] = React.useState(false);

  const onRemove = async () => {
    setLoading(true);
    try {
      await thunkDispatch(
        OrgAdminUser.updateOne(
          client,
          {
            id: props.organizationAdminUser.id,
            organizations: props.organizationAdminUser.organizations.filter(
              (o) => o.id !== props.organization.id
            ),
          },
          {_organizationId: globalOrg?.id}
        )
      );
      refreshTable();
      message.success('Organization admin user was successfully removed from the organization.');
    } catch (error) {
      message.error(
        `Error removing the organiztion admin user from the organization: ${error}`,
        6
      );
    }
    setLoading(false);
  };

  return (
    <Space>
      <Popconfirm
        title="Are you sure you want to remove this organization admin user from this organization?"
        onConfirm={onRemove}
        okText="Yes"
        cancelText="No"
        disabled={props.organizationAdminUser.organizations.length <= 1}
      >
        <Space>
          <Tooltip
            title={
              props.organizationAdminUser.organizations.length <= 1 &&
              'A user must be assigned to at least one Organization. Add a new Organization before removing one'
            }
          >
            <Button
              type="link"
              danger
              disabled={props.organizationAdminUser.organizations.length <= 1}
              size={'small'}
              style={{paddingLeft: 10, paddingRight: 10}}
            >
              Remove
            </Button>
          </Tooltip>
          {loading && <Spin size="small" />}
        </Space>
      </Popconfirm>
    </Space>
  );
};

export const OrganizationMembershipPicker = (props: {
  organizationAdminUser: any;
  visible: boolean;
  onDismiss?: () => void;
}) => {
  const globalOrg = useSelector((state: RootState) => state.ui.localOrg);
  const [visible, setVisible] = useState(props.visible);
  const [loading, setLoading] = React.useState(null);
  const thunkDispatch = useDispatch<ThunkDispatch>();
  const client = FhirUtils.useAxiosClient();

  useEffect(() => setVisible(props.visible), [props.visible]);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'nameEn',
      key: 'name',
      sorter: true,
      render: (_, org) => org.nameEn,
    },
    {
      title: 'Organization Code',
      dataIndex: 'organizationCode',
      key: 'code',
      sorter: true,
      render: (_, org) => org.organizationCode,
    },
    {
      title: 'Actions',
      render: (_, org) => (
        <Space>
          {props.organizationAdminUser.organizations.find((o) => o.id === org.id) ? (
            <Button
              type="primary"
              className="success"
              size={'small'}
              style={{
                paddingLeft: 10,
                paddingRight: 10,
                backgroundColor: colors.green,
                borderColor: colors.green,
              }}
            >
              <FontAwesomeIcon icon={faCheckCircle} color="white" style={{marginRight: 10}} />
              Added
            </Button>
          ) : (
            <Space>
              <Button
                type="primary"
                size={'small'}
                style={{
                  paddingLeft: 10,
                  paddingRight: 10,
                  alignItems: 'center',
                  display: 'flex',
                }}
                onClick={() => onAdd(org)}
              >
                <FontAwesomeIcon icon={faPlusCircle} color="white" style={{marginRight: 10}} />
                Add to User
              </Button>
              {loading === org.id && <Spin size="small" />}
            </Space>
          )}
        </Space>
      ),
    },
  ];

  const onAdd = async (organization) => {
    setLoading(organization.id);
    try {
      await thunkDispatch(
        OrgAdminUser.updateOne(
          client,
          {
            id: props.organizationAdminUser.id,
            organizations: [...props.organizationAdminUser.organizations, organization],
          },
          {_organizationId: globalOrg?.id}
        )
      );
      message.success(
        `Organization admin user (${props.organizationAdminUser.firstName} ${props.organizationAdminUser.lastName}) was successfully added to ${organization.nameEn}.`
      );
    } catch (error) {
      message.error(
        `Error adding organization admin user (${props.organizationAdminUser.firstName} ${props.organizationAdminUser.lastName}) to ${organization.nameEn}.`
      );
    }
    setLoading(null);
  };

  const dismissModal = () => {
    setVisible(false);
    if (props.onDismiss) props.onDismiss();
  };

  return (
    <Modal
      visible={visible}
      title={`Add Organization to Admin User (${props.organizationAdminUser.firstName} ${props.organizationAdminUser.lastName})`}
      width={'60%'}
      okText="Done"
      onOk={dismissModal}
      bodyStyle={{height: 500, overflow: 'scroll'}}
      onCancel={dismissModal}
      cancelButtonProps={{style: {display: 'none'}}}
      destroyOnClose
    >
      <FHIRTable
        fhirResource="organization"
        columns={columns}
        defaultPrimarySearchParam="name"
        mode="table"
        fixedFilters={{
          _organizationId: globalOrg?.id || '',
        }}
        noPersistSearchTerm={true}
      />
    </Modal>
  );
};
