import {createSlice} from '@reduxjs/toolkit';
import {normalize, schema} from 'normalizr';
import querystring from 'querystring';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState, ThunkDispatch} from '.';
import {FhirUtils} from '../services/fhir';
import {mapBundleToResourceArray} from '../util/fhir';

export interface CVCSite {
  id: string;
  conceptId: string;
  fsn: string;
  displayNameEn: string;
  displayNameFr: string;
  lastUpdated: Date;
}

export interface SitesSliceInterface {
  byId: {[string: string]: CVCSite};
}
const initialState = {
  byId: {},
};
const site = new schema.Entity('sites', {});

const slice = createSlice({
  name: 'sites',
  initialState,
  reducers: {
    SAVE_SITES: (state: SitesSliceInterface, action) => {
      const resources = mapBundleToResourceArray(action.payload);
      state.byId = normalize(resources, [site]).entities.sites || {};
    },
    SAVE_SITE: (state: SitesSliceInterface, action) => {
      state.byId[action.payload.id] = action.payload;
    },
  },
});

export const getAll = (client, params?) => async (dispatch) => {
  let query = params ? querystring.stringify(params) : undefined;
  const res = await client.get(`cvc/sites${query ? `?${query}` : ''}`);
  dispatch(slice.actions.SAVE_SITES(res.data));
  return res.data;
};

//id params should be conceptId of the cvc-sites entity
const getOne = (client, id) => async (dispatch) => {
  return client.get(`/cvc/sites/${id}`).then((res) => {
    return dispatch(slice.actions.SAVE_SITE(res.data));
  });
};

export default {
  slice,
  getAll,
  getOne,
};

export const useCVCSites = () => {
  const thunkDispatch = useDispatch<ThunkDispatch>();
  const client = FhirUtils.useClient();

  React.useEffect(() => {
    thunkDispatch(getAll(client));
  }, []);

  const sites = useSelector((state: RootState) => state.sites.byId);

  return Object.values(sites);
};
