"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_Distance = exports.DistanceComparatorKind = void 0;
var t = __importStar(require("io-ts"));
var RTTI_Extension_1 = require("./RTTI_Extension");
var RTTI_decimal_1 = require("../Scalar/RTTI_decimal");
var RTTI_Element_1 = require("./RTTI_Element");
var RTTI_uri_1 = require("../Scalar/RTTI_uri");
var RTTI_code_1 = require("../Scalar/RTTI_code");
var DistanceComparatorKind;
(function (DistanceComparatorKind) {
    DistanceComparatorKind["_lower"] = "Lower";
    DistanceComparatorKind["_lowerOrEqual"] = "LowerOrEqual";
    DistanceComparatorKind["_greaterOrEqual"] = "GreaterOrEqual";
    DistanceComparatorKind["_greater"] = "Greater";
})(DistanceComparatorKind = exports.DistanceComparatorKind || (exports.DistanceComparatorKind = {}));
var EnumType_1 = require("../../EnumType");
exports.RTTI_Distance = t.recursion('IDistance', function () {
    return t.partial({
        id: t.string,
        extension: t.array(RTTI_Extension_1.RTTI_Extension),
        value: RTTI_decimal_1.RTTI_decimal,
        _value: RTTI_Element_1.RTTI_Element,
        comparator: EnumType_1.createEnumType(DistanceComparatorKind, 'DistanceComparatorKind'),
        _comparator: RTTI_Element_1.RTTI_Element,
        unit: t.string,
        _unit: RTTI_Element_1.RTTI_Element,
        system: RTTI_uri_1.RTTI_uri,
        _system: RTTI_Element_1.RTTI_Element,
        code: RTTI_code_1.RTTI_code,
        _code: RTTI_Element_1.RTTI_Element
    });
});
