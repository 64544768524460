import {CVC, CVCConcept} from '@canimmunize/cvc-js';
import {Alert, Card, Radio, Spin, Table, Tabs, Typography} from 'antd';
import {TabsPosition} from 'antd/lib/tabs';
import produce from 'immer';
import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {RootState, ThunkDispatch} from '../../models';
import Terminology from '../../models/terminology';
import {convertTableConfigToCols, tabConfig} from './terminology-config';

const {Title, Paragraph, Text} = Typography;
const {TabPane} = Tabs;

export const TerminologyView = () => {
  const thunkDispatch = useDispatch<ThunkDispatch>();
  const [mode, setMode] = useState<TabsPosition>('left');
  const params = useParams<{valueSet: string; conceptId: string}>();
  const navigate = useNavigate();
  const handleModeChange = (e) => {
    setMode(e.target.value);
  };
  const [expandedRowConceptId, setExpandedRowConceptId] = useState(params.conceptId);

  React.useEffect(() => {
    thunkDispatch(Terminology.getAll());
  });

  const loadingTerminology = useSelector((state: RootState) => !state.terminology.cvcLoaded);
  if (loadingTerminology) return <Spin />;

  return (
    <Card>
      <Title level={1}>Terminology</Title>
      <Alert
        style={{marginBottom: 20}}
        message={`CANImmunize is currently using the latest version of the Canadian Vaccine Catalogue.`}
        type="success"
      />
      <div>
        <Radio.Group onChange={handleModeChange} value={mode} style={{marginBottom: 8}}>
          <Radio.Button value="top">Horizontal</Radio.Button>
          <Radio.Button value="left">Vertical</Radio.Button>
        </Radio.Group>
        <Tabs
          defaultActiveKey="1"
          activeKey={params.valueSet}
          tabPosition={mode}
          animated={false}
          onChange={(key) => {
            navigate(`/terminology/${key}`);
            setExpandedRowConceptId('');
          }}
        >
          {tabConfig.map((config, i) => {
            const cols = convertTableConfigToCols(config.tableConfig);

            const data = CVC.get()[config.key].map((concept) =>
              produce(concept, (concept) => {
                concept.key = concept.conceptId;
              })
            );
            return (
              <TabPane tab={config.tabTitle} key={config.key}>
                <Paragraph>{config.description}</Paragraph>
                <Table
                  columns={cols}
                  rowKey={'conceptId'}
                  pagination={false}
                  expandable={{
                    expandedRowRender: config.detail as (x: any) => JSX.Element,
                    onExpand: (expanded, record: CVCConcept) => {
                      if (expanded) {
                        setExpandedRowConceptId(record.conceptId);
                        navigate(`/terminology/${config.key}/${record.conceptId}`);
                      }
                      if (!expanded) {
                        setExpandedRowConceptId('');
                        navigate(`/terminology/${config.key}`);
                      }
                    },
                    expandedRowKeys: [expandedRowConceptId || ''],
                  }}
                  dataSource={data}
                />
              </TabPane>
            );
          })}
        </Tabs>
      </div>
    </Card>
  );
};
