import {Avatar, Col, Divider, Row, Tabs, Typography} from 'antd';
import React, {ReactNode, useState} from 'react';

const {Title, Paragraph, Text} = Typography;
const {TabPane} = Tabs;

export const GeneralTitle = (props: {title: string}) => {
  const titleStyle = {color: '#141414', fontSize: 12, fontWeight: 700};
  return (
    <Text style={titleStyle} underline>
      {props.title}
    </Text>
  );
};

export const DisplayTerm = (props: {displayTerm: string | null}) => {
  const displayTermStyle = {color: '#595959', fontSize: 22};
  return <Title style={displayTermStyle}>{props.displayTerm}</Title>;
};

export const SectionTitle = (props: {title: string; presence: boolean}) => {
  const headerStyle = {color: '#262626', fontSize: '16px', fontWeight: 700};
  return props.presence ? (
    <div style={{marginBottom: '20px'}}>
      <Text style={headerStyle}>
        <Divider />
        {props.title}
      </Text>
    </div>
  ) : (
    <br></br>
  );
};

export const FieldTitleVertical = (props: {title: string}) => {
  const labelStyle = {color: '#434343', paddingBottom: '0px', fontSize: '14px'};
  return (
    <div>
      <Text style={labelStyle} strong>
        {props.title}
      </Text>
    </div>
  );
};

export const FieldTitleHorizontal = (props: {title: string; children: ReactNode}) => {
  let styles = {
    flexStyle: {
      color: '#434343',
      paddingBottom: '0px',
      fontSize: '14px',
      display: 'flex',
      flexFlow: 'row nowrap',
    } as React.CSSProperties,
  };
  return (
    <Text style={styles.flexStyle}>
      {props.title}: {props.children}
    </Text>
  );
};

export const IconDisplay = (props: {content: string | null; displayTerm: string | null}) => {
  const displayTermStyle = {color: '#595959', fontSize: 22, marginTop: '18px'};
  return (
    <div>
      <br></br>
      <Row gutter={[16, 16]}>
        <Col span={12} style={{maxWidth: '74px'}}>
          <Avatar style={{backgroundColor: '#262626', verticalAlign: 'middle'}} size={64}>
            {props.content}
          </Avatar>
        </Col>
        <Col span={12} style={{maxWidth: '92%'}}>
          <Title style={displayTermStyle}>{props.displayTerm}</Title>
        </Col>
      </Row>
    </div>
  );
};
