import FullCalendar from '@fullcalendar/react';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import {Alert, Button, Card, Descriptions, Popover, Space, Tooltip} from 'antd';
import moment from 'moment-timezone';
import React from 'react';
import {FhirUtils} from '../../services/fhir';
import {Appointment} from '../scheduler/types';
import {CalendarPicker} from './calendar-picker';
import {Clinic} from '../../models/clinics';
import {Calendar} from '../../models/calendar';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import listPlugin from '@fullcalendar/list';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import {colors} from '@canimmunize/tools';
import {AvailabilityRuleType} from '../../interface-models/availability-rules/availability-rule-types/availability-rule-type';
import {IRecurringDayAvailabilityRule} from '../../interface-models/availability-rules/availability-rule-types/recurring-day-availability-rule';
import {INonRecurringAvailabilityRule} from '../../interface-models/availability-rules/availability-rule-types/non-recurring-availability-rule';
import {BlockCalendarModal} from './block-calendar/block-calendar-modal';
import {AbilityContext} from '../../services/roles/ability-context';
import {useLocation, useNavigate} from 'react-router-dom';
import _ from 'lodash';

const qs = require('query-string');

interface AppointmentCalendarProps {
  clinicId?: string;
  hideClinicPicker?: boolean;
}
interface AppointmentData {
  date: string;
  ruleId: string;
  totalAppointment: number;
  calendarId: string;
}
interface CalendarData {
  appointments: Appointment[];
  calendars: Calendar[];
  appointmentData: AppointmentData[];
}

export const AppointmentCalendar = (props: AppointmentCalendarProps) => {
  const {hideClinicPicker, clinicId} = props;
  const navigate = useNavigate();
  const {pathname, search} = useLocation();

  const [calendar, setCalendar] = React.useState<Calendar>();
  const [clinic, setClinic] = React.useState<Clinic>();
  const [calendarStartDate, setCalendarStartDate] = React.useState(moment().format('YYYY-MM-DD'));
  const [calendarEndDate, setCalendarEndDate] = React.useState(moment().format('YYYY-MM-DD'));
  const [calendarData, setCalendarData] = React.useState<CalendarData>({
    appointments: [],
    calendars: [],
    appointmentData: [],
  });
  const [fetchingAppointments, setFetchingAppointments] = React.useState(false);
  const [includeAppointments, setIncludeAppointments] = React.useState(true);
  const [timezone, setTimezone] = React.useState(moment.tz.guess());
  const [timeGrid, setTimeGrid] = React.useState('timeGridDay');
  const client = FhirUtils.useAxiosClient();
  const calendarRef = React.useRef<FullCalendar>(null);
  const [blockCalModalVisible, setBlockCalModalVisible] = React.useState(false);
  const [blockRuleForEdit, setBlockRuleForEdit] = React.useState<any>(null);
  const [selectionInfo, setSelectionInfo] = React.useState<any>(null);
  const [eventsState, setEventsState] = React.useState<any>(null);

  const ability = React.useContext(AbilityContext);

  const fetchClinic = () => {
    client
      .get(`/clinic/${clinicId}`)
      .then((res) => {
        const clinic = res.data;
        setClinic(clinic);
        setTimezone(clinic?.timezone);
      })
      .catch((err) => {
        console.log('Failed to load clinic', err);
      });
  };

  const updateQueryParams = () => {
    const queryVals: {
      calendarDate: string;
      timeGrid: string;
      clinic?: string;
      timezone: string;
      calendar?: string;
      includePatients?: string;
    } = {
      calendarDate: calendarStartDate,
      timeGrid: timeGrid,
      clinic: clinic?.id,
      timezone: timezone,
      includePatients: 'false',
    };
    if (calendar) {
      queryVals.calendar = calendar.id;
    }

    const oldQuery = qs.parse(search);
    if (!_.isEqual(oldQuery, queryVals)) {
      const query = qs.stringify(queryVals);
      // Update navigate code to use window's native API not to reload the page
      // Since navigate triggers reload when calendar view is updated so that the date changes unintendedly
      window.history.replaceState({}, '', `${pathname}?${query}`);
      // navigate(`${pathname}?${query}`, {replace: true});
    }
  };

  React.useEffect(() => {
    if (hideClinicPicker && !clinic) {
      fetchClinic();
    }

    if (!clinic || !calendarStartDate || !calendarEndDate) return;

    refreshAppointments(
      clinic,
      calendar,
      calendarStartDate,
      calendarEndDate,
      timezone,
      includeAppointments,
      timeGrid
    );

    updateQueryParams();
  }, [
    calendar,
    clinic?.id,
    calendarStartDate,
    calendarEndDate,
    timezone,
    includeAppointments,
    timeGrid,
  ]);

  // Update events only if calendarData is updated
  React.useEffect(() => {
    if (calendarData.appointmentData || calendarData.appointments || calendarData.calendars) {
      updateEvents(timeGrid, calendarData, includeAppointments, timezone, setEventsState);
    }
  }, [calendarData.appointmentData, calendarData.appointments, calendarData.calendars]);

  const onRefresh = () => {
    if (clinic && calendarStartDate && calendarEndDate) {
      refreshAppointments(
        clinic,
        calendar,
        calendarStartDate,
        calendarEndDate,
        timezone,
        includeAppointments,
        timeGrid
      );
    }
  };

  const refreshAppointments = async (
    clinic,
    calendar,
    startDate,
    endDate,
    timezone,
    includeAppointments,
    timeGrid
  ) => {
    setFetchingAppointments(true);

    const calendarStart = moment.tz(startDate, timezone).toISOString();
    const calendarEnd = moment.tz(endDate, timezone).toISOString();
    const query = qs.stringify({
      calendarId: calendar?.id,
      clinicId: clinic.id,
      calendarStart,
      calendarEnd,
      includeAppointments,
      timeGrid,
    });
    const result: any = await client.get(`/calendar-info?${query}`);
    setCalendarData(result.data);

    setFetchingAppointments(false);
  };

  const onSetCalendarDate = (date: moment.Moment) => {
    // setClendarDate is automatically called in datesSet callback function after redrawing calendar triggered below
    calendarRef.current?.getApi().gotoDate(date.toDate());
  };

  const goToAppointment = (info) => {
    info.jsEvent.preventDefault();
    if (info.event.id) {
      navigate(`/appointments/${info.event.id}`);
    }
  };

  const closeBlockRuleModal = () => {
    calendarRef.current?.getApi().unselect();
    setBlockCalModalVisible(false);
    setBlockRuleForEdit(null);
    setSelectionInfo(null);
  };

  const blockCalendarInfo = (
    <span>
      1. Select a clinic and calendar. <br />
      2. Drag across the times you want to block. In the pop-up, confirm the start and end date and
      time. <br />
      3. You can block time in a calendar regardless of if appointments are booked. If appointments
      are booked, blocking the time will not cancel the appointments; it will prevent future
      appointments from being booked during that time if an appointment is cancelled or rescheduled.
    </span>
  );

  return (
    <div>
      {ability.can('execute', 'appointments', 'blockRule') && (
        <Space direction="vertical" align="center" style={{float: 'right'}}>
          <Tooltip title={blockCalendarInfo}>
            <Alert message={`Block a calendar`} type="info" showIcon />
          </Tooltip>
        </Space>
      )}
      <CalendarPicker
        setClinic={(clinic) => setClinic(clinic)}
        clinic={clinic}
        setCalendar={(calendar) => setCalendar(calendar)}
        calendar={calendar}
        fetchingAppointments={fetchingAppointments}
        calendarDate={calendarStartDate}
        setCalendarDate={onSetCalendarDate}
        timeGrid={timeGrid}
        setTimeGrid={setTimeGrid}
        calendarRef={calendarRef}
        timezone={timezone}
        setTimezone={setTimezone}
        includeAppointments={includeAppointments}
        setIncludeAppointments={setIncludeAppointments}
        hideClinicPicker={hideClinicPicker}
        onAppointmentPage={true}
        refreshAppointments={refreshAppointments}
      />
      <FullCalendar
        schedulerLicenseKey="GPL-My-Project-Is-Open-Source" // TODO: still need to buy license
        plugins={[
          dayGridPlugin,
          timeGridPlugin,
          listPlugin,
          momentTimezonePlugin,
          resourceTimeGridPlugin,
          interactionPlugin,
        ]}
        eventContent={(arg) => {
          switch (arg.event.extendedProps.type) {
            case 'appointment':
              return <AppointmentPopover arg={arg} />;
            default: {
              return <div>{arg.event.title}</div>;
            }
          }
        }}
        /* This is a hack. viewClassName fires each time the calendar view is changed.
        Using it to set time grid state when change occurs */
        viewClassNames={(e) => {
          if (timeGrid != e.view.type) {
            setEventsState(null);
          }
          setTimeGrid(e.view.type);

          return '';
        }}
        initialView={timeGrid}
        slotMinTime={'05:00:00'}
        slotMaxTime={'24:00:00'}
        slotDuration={'00:10:00'}
        timeZone={timezone}
        displayEventTime={true}
        height="auto"
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
        }}
        initialDate={moment().toDate()}
        ref={calendarRef}
        eventClick={(e) => {
          switch (e.event.extendedProps.type) {
            case 'appointment': {
              goToAppointment(e);
              break;
            }
            case 'block': {
              if (!calendar) return;
              setBlockRuleForEdit(e.event);
              setBlockCalModalVisible(true);
              break;
            }
            case 'appointmentData': {
              onSetCalendarDate(moment(e.event.start));
              calendarRef.current?.getApi().changeView('timeGridDay');
            }
            default: {
            }
          }
        }}
        datesSet={({startStr, endStr, view}) => {
          let startDate = moment.tz(startStr, timezone);
          const endDate = moment.tz(endStr, timezone);
          /* When the view is changed to dayGridMonth, set the startDate to the beginning of the current month.
          This fixes a bug where fullCalendar sets thet month view to the previous month instead. */
          if (view.type === 'dayGridMonth' && Number(startDate.format('D')) > 1) {
            startDate = startDate.add(1, 'M').startOf('month');
          }
          setCalendarStartDate(startDate.format('YYYY-MM-DD'));
          setCalendarEndDate(endDate.format('YYYY-MM-DD'));
        }}
        events={eventsState}
        selectable={ability.can('execute', 'appointments', 'blockRule') && !!calendar}
        unselectAuto={false}
        select={(selection) => {
          const found = eventsState?.findIndex(
            (event) =>
              event.title === 'Blocked' &&
              //Selected time period is inside of an existing block
              ((moment(event.start).isSameOrBefore(selection.start) &&
                moment(event.end).isSameOrAfter(selection.end)) ||
                //Existing block start time is inside of selection time period
                (moment(event.start).isSameOrAfter(selection.start) &&
                  moment(event.start).isBefore(selection.end)) ||
                //Existing block end time is inside of selected time period
                (moment(event.end).isAfter(selection.start) &&
                  moment(event.end).isSameOrBefore(selection.end)))
          );
          if (found >= 0) {
            setBlockRuleForEdit(eventsState[found]);
          }
          setSelectionInfo(selection);
          setBlockCalModalVisible(true);
        }}
      />
      {ability.can('execute', 'appointments', 'blockRule') && blockCalModalVisible && (
        <BlockCalendarModal
          visible={blockCalModalVisible}
          closeModal={closeBlockRuleModal}
          selectionInfo={selectionInfo}
          blockRuleForEdit={blockRuleForEdit}
          calendar={calendar}
          timezone={timezone}
          refresh={onRefresh}
          clinic={clinic}
        />
      )}
    </div>
  );
};

const findRuleFromCalendarData = (
  calendars,
  ruleId: string
): IRecurringDayAvailabilityRule | INonRecurringAvailabilityRule | undefined => {
  for (const calendar of calendars) {
    for (const rule of calendar.availabilityRules) {
      if (rule.id === ruleId) return rule;
    }
  }
  return undefined;
};

// Update events based on appointments info from calendarData
const updateEvents = (timeGrid, calendarData, includeAppointments, timezone, setEventsState) => {
  // The object contains the number of appointments by date and calendarId to check max doses limitation
  const apmtByGroupRule =
    timeGrid === 'timeGridDay'
      ? calendarData.appointments?.reduce((obj, val) => {
          const key =
            moment.tz(val.datetime, val.timezone || moment.tz.guess()).format('YYYY-MM-DD') +
            val.calendarId;
          obj[key] = (obj[key] || 0) + 1;

          return obj;
        }, {})
      : timeGrid === 'timeGridWeek'
      ? calendarData.appointmentData?.reduce((obj, val) => {
          const key = moment.utc(val.date).format('YYYY-MM-DD') + val.calendarId;
          obj[key] = (obj[key] || 0) + 1;

          return obj;
        }, {})
      : {};

  // Insert all appointments into events
  const events: any[] = calendarData.appointments.map((a) => {
    return {
      title: `${a.firstName} ${a.lastName}`,
      start: a.datetime,
      end: moment(a.datetime).add(a.duration, 'minutes').toISOString(),
      id: a.id,
      backgroundColor: a.completed ? colors.green : undefined,
      borderColor: a.completed ? colors.green : undefined,
      extendedProps: {
        type: 'appointment',
      },
      firstName: a.firstName,
      lastName: a.lastName,
      appointmentType: a.appointmentType,
    };
  });

  // Insert all rules into events
  calendarData.calendars.map((cal) => {
    cal.availabilityRules.map((rule) => {
      const immunizerCountString = `${rule.immunizerCount} Immunizer${
        rule.immunizerCount > 1 ? 's' : ''
      }`;

      switch (rule.type) {
        case AvailabilityRuleType.RecurringDay: {
          const startTime = moment.utc(rule.startTime).format('HH:mm:ss');
          const endTime = moment.utc(rule.endTime).format('HH:mm:ss');
          const ruleStartDate = moment.utc(rule.startDate);
          const ruleEndDate = moment.utc(rule.endDate);

          while (ruleStartDate.isSameOrBefore(ruleEndDate)) {
            if (rule.days.includes(ruleStartDate.day())) {
              const ruleDate = ruleStartDate.format('YYYY-MM-DD');
              const ruleStartString = ruleDate + ' ' + startTime;
              const start = moment.tz(ruleStartString, cal.clinic.timezone).toISOString();

              const ruleEndString = ruleDate + ' ' + endTime;
              const end = moment.tz(ruleEndString, cal.clinic.timezone).toISOString();

              events.push({
                title: `Availability - ${cal.name} - ${immunizerCountString} (Recurring)`,
                start,
                end,
                id: rule.id,
                display: includeAppointments ? 'background' : undefined,
                backgroundColor:
                  includeAppointments &&
                  cal?.maxNumberOfDosesPerDay &&
                  apmtByGroupRule &&
                  apmtByGroupRule[ruleDate + cal.id] >= cal.maxNumberOfDosesPerDay
                    ? colors.lightGray
                    : undefined,
                extendedProps: {
                  type: 'availability',
                },
              });
            }
            ruleStartDate.add(1, 'days');
          }
          break;
        }
        case AvailabilityRuleType.NonRecurring: {
          const ruleDate = moment.utc(rule.date).format('YYYY-MM-DD');
          const ruleStartTime = moment.utc(rule.startTime).format('HH:mm:ss');
          const ruleStartString = ruleDate + ' ' + ruleStartTime;
          const start = moment.tz(ruleStartString, cal.clinic.timezone).toISOString();

          const ruleEndTime = moment.utc(rule.endTime).format('HH:mm:ss');
          const ruleEndString = ruleDate + ' ' + ruleEndTime;
          const end = moment.tz(ruleEndString, cal.clinic.timezone).toISOString();

          events.push({
            title: `Availability - ${cal.name} - ${immunizerCountString}`,
            start,
            end,
            id: rule.id,
            display: includeAppointments ? 'background' : undefined,
            backgroundColor:
              includeAppointments &&
              cal?.maxNumberOfDosesPerDay &&
              apmtByGroupRule &&
              apmtByGroupRule[ruleDate + cal.id] >= cal.maxNumberOfDosesPerDay
                ? colors.lightGray
                : undefined,
            extendedProps: {
              type: 'availability',
            },
          });
          break;
        }
      }
    });

    // Insert all block rules into events
    cal.blockRules.map((rule) => {
      const start = moment.tz(rule.startDatetime, cal.clinic.timezone).toISOString();
      const end = moment.tz(rule.endDatetime, cal.clinic.timezone).toISOString();
      const period = `${moment.tz(rule.startDatetime, cal.clinic.timezone).format('H:mm')}-${moment
        .tz(rule.endDatetime, cal.clinic.timezone)
        .format('H:mm')} `;

      events.push({
        title: (timeGrid === 'dayGridMonth' ? period : '') + `Blocked`,
        className: 'fc-event-block',
        start: start,
        end: end,
        display: 'background',
        backgroundColor: colors.black,
        extendedProps: {
          type: 'block',
          id: rule.id,
        },
      });
    });
  });

  // Insert the number of appointments by rules into events
  if (timeGrid === 'dayGridMonth') {
    calendarData.appointmentData?.forEach((a) => {
      events.push({
        title: `${a.totalAppointment} Appointment${a.totalAppointment > 1 ? 's' : ''}`,
        start: moment.tz(moment.utc(a.date).format('YYYY-MM-DD'), timezone).toISOString(),
        extendedProps: {
          type: 'appointmentData',
        },
      });
    });
  } else if (timeGrid === 'timeGridWeek') {
    calendarData.appointmentData?.forEach((a) => {
      const foundRule = findRuleFromCalendarData(calendarData?.calendars, a.ruleId);

      if (foundRule) {
        const ruleDate = moment.utc(a.date).format('YYYY-MM-DD');
        const ruleStartTime = moment.utc(foundRule?.startTime).format('HH:mm:ss');
        const ruleStartString = ruleDate + ' ' + ruleStartTime;
        const start = moment.tz(ruleStartString, timezone).toISOString();

        const ruleEndTime = moment.utc(foundRule?.endTime).format('HH:mm:ss');
        const ruleEndString = ruleDate + ' ' + ruleEndTime;
        const end = moment.tz(ruleEndString, timezone).toISOString();

        events.push({
          title: ` ${a.totalAppointment} Appointments`,
          start: start,
          end: end,
          backgroundColor: 'rgba(37,88,219,0.7)',
          extendedProps: {
            type: 'appointmentData',
          },
        });
      }
    });
  }
  setEventsState(events);
};

export const AppointmentPopover = ({arg}) => {
  const navigate = useNavigate();
  const goToAppointment = (info) => {
    // info.jsEvent.preventDefault();
    if (info.event.id) {
      navigate(`/appointments/${info.event.id}`);
    }
  };

  const {firstName, lastName, appointmentType} = arg.event?._def?.extendedProps || {};

  const content = (
    <div>
      <Descriptions bordered size="small" style={{marginBottom: 15}}>
        <Descriptions.Item label="Appointment Type" span={12}>
          {appointmentType ? appointmentType.nameEn : ''}
        </Descriptions.Item>
        <Descriptions.Item label="First Name" span={12}>
          {firstName || ''}
        </Descriptions.Item>
        <Descriptions.Item label="Last Name" span={12}>
          {lastName || ''}
        </Descriptions.Item>
      </Descriptions>
      <Space>
        <Button
          onClick={() => {
            goToAppointment(arg);
          }}
        >
          View Appointment Details
        </Button>
        {/* <Button>Jump to Dose 2 Appointment</Button> */}
      </Space>
    </div>
  );
  return (
    <Popover content={content} placement="topLeft" title={arg.event.title} trigger="hover">
      <div style={{flex: 1}}>{arg.event.title}</div>
    </Popover>
  );
};
