import moment from 'moment';
import {AbstractValidationRule} from './abstract-validation-rule';

export const PreviousDateValidationRuleId = 'previousDate';

export interface PreviousDateValidationRule extends AbstractValidationRule {
  validationRuleType: typeof PreviousDateValidationRuleId;
}

/**
 * Validation to check if a date is a previous date. Also passes if no date is entered.
 *
 * @export
 * @param {*} value
 * @returns {boolean} True if validation passed. False otherwise.
 */
export function validatePreviousDateRule(value: any): boolean {
  if (!value) return true;
  else if (moment.isMoment(value)) return value.isBefore();
  else return moment(value).isBefore();
}
