import {Ids} from '@canimmunize/tools';
import axios from 'axios';
import React, {useRef, useState} from 'react';
import {useNavigate} from 'react-router';
import {useStr} from '../../../services/str';
import {AppointmentSummary} from '../appointment-summary';
import {PageTemplate} from '../scheduler-template';
import {useUrlData} from '../util';
import {AppointmentCancelled} from './appointment-cancelled';
import {MessageCard} from './message-card';
import {filterRequiredFormTypesForConfig, PatientForms} from './patient-forms';

// NOTES:
// To build the form use this tool: https://formio.github.io/formio.js/app/sandbox
// Plug in the base-form.json file from the public dir then copy the json back
// once you've made your changes

// The Formio form, DOES NOT like to be rerendered (it will reset all the data), which
// is why we're using React.memo like we are

interface IAppointmentCancellation {
  canceledReason: string;
  canceledMessage: {en: string; fr: string};
}

interface IConsentFormProps {
  walkin?: any;
}

// Track the current page the user is on
export const ConsentForm = ({walkin}: IConsentFormProps) => {
  const Str = useStr();

  // const [submitted, setSubmitted] = useState(false);
  const [appointmentCancellation, setAppointmentCancellation] =
    useState<IAppointmentCancellation>();
  const [error, setError] = useState(false);
  const [appointment, setAppointment] = useState<any>();
  const [loadingAppointment, setLoadingAppointment] = useState(true);
  const [unavailable, setUnavailable] = useState(false);
  const [invalidAppointmentId, setInvalidAppointmentId] = useState(false);
  const [hideAppointmentDetails, setHideAppointmentDetails] = useState(false);
  const {appointmentId, lang, config, syncUrl, theme, bookingPageSlug, query} = useUrlData();
  const [formsMode, setFormsMode] = useState<boolean>(
    query.booked === 'true' || !!query.groupRedirect
  );
  const [completedForms, setCompletedForms] = useState(false);
  const [rescheduling, setRescheduling] = useState(false);
  const navigate = useNavigate();
  const {groupRedirect} = query;

  const onFormCompleted = async () => {
    setCompletedForms(true);
    setHideAppointmentDetails(false);
    if (redirectCallback) {
      redirectCallback();
    } else {
      setFormsMode(false);
      window.scrollTo(0, 0);
      await getAppointment();
    }
  };

  // Load the form
  React.useEffect(() => {
    (async () => {
      setLoadingAppointment(true);
      getAppointment();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAppointment = async () => {
    return axios
      .get(syncUrl + `/public/appointment/${appointmentId}`, {
        validateStatus: (status) => {
          return status >= 200 && status < 500;
        },
        params: {
          checkRequiresFollowup: true,
        },
      })
      .then((result) => {
        if (result.status === 404) {
          setInvalidAppointmentId(true);
        } else if (result.status > 199 && result.status < 400) {
          setAppointment(result.data);
          const hasFormsToFill = result.data.patients?.some(
            (p) => filterRequiredFormTypesForConfig(p.requiredFormTypes, config).length > 0
          );
          if (!hasFormsToFill) {
            setFormsMode(false);
          }
        } else {
          setUnavailable(true);
        }
        setLoadingAppointment(false);
      });
  };

  const onRescheduling = (rescheduling) => setRescheduling(rescheduling);

  const redirectCallback = groupRedirect
    ? () => {
        navigate(`/${lang}/${bookingPageSlug}/${groupRedirect}`);
        window.location.reload();
      }
    : undefined;

  const formContainerProps: IFormsContainerProps = {
    loadingAppointment,
    appointment,
    onFormCompleted,
    setAppointmentCancellation,
    redirectCallback,
  };

  if (invalidAppointmentId) {
    return <MessageCard title={Str(Ids.apologies_link_invalid)} />;
  }

  if (appointment?.isPast) {
    return <MessageCard title={Str(Ids.appointment_already_occurred)} />;
  }

  // Show the cancellation page
  if (appointmentCancellation) {
    return <AppointmentCancelled appointmentCancellation={appointmentCancellation} />;
  }

  if (appointment?.canceled) {
    return <AppointmentCancelled userCancelled />;
  }

  const patientsWithRequiredForms =
    appointment?.patients?.filter(
      (p) => filterRequiredFormTypesForConfig(p.requiredFormTypes, config).length > 0
    ) || [];

  if (invalidAppointmentId) {
    return <MessageCard title={Str(Ids.apologies_link_invalid)} />;
  }

  if (loadingAppointment) {
    return (
      <PageTemplate>
        <div style={{textAlign: 'center'}}>
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">{`${Str(Ids.loading_ellipsis)}`}</span>
          </div>

          <div>{`${Str(Ids.loading_ellipsis)}`}</div>
        </div>
      </PageTemplate>
    );
  }

  // If there was an error submitting the form, show this view
  if (error) {
    return (
      <MessageCard
        title={Str(Ids.oops_something_went_wrong)}
        subtext={Str(
          Ids.contact_support_for_assistance,
          '<a href="mailto:support@canimmunize.ca">support@canimmunize.ca</a>'
        )}
      />
    );
  }

  return (
    <PageTemplate>
      {/* Appointment reserved card - only visible if ?booked=true */}
      {/* <AppointmentReservedCard appointment={appointment} formsMode={formsMode} /> */}

      <AppointmentSummary
        appointment={appointment}
        shortMode={formsMode}
        setFormsMode={setFormsMode}
        formsMode={formsMode}
        completedForms={completedForms}
        onRescheduling={onRescheduling}
        groupRedirect={groupRedirect}
        hideAppointmentDetails={hideAppointmentDetails}
        setHideAppointmentDetails={setHideAppointmentDetails}
      />

      {/* Show the forms if there are forms to fill out */}

      {formsMode && !rescheduling && patientsWithRequiredForms.length ? (
        <FormsContainer {...formContainerProps} />
      ) : null}
    </PageTemplate>
  );
};

interface IFormsContainerProps {
  loadingAppointment: boolean;
  appointment: any;
  onFormCompleted: () => any;
  setAppointmentCancellation?: (cancellation: IAppointmentCancellation) => any;
  formErrorsPresent?: boolean;
  containerStyle?: React.CSSProperties;
  hideSubtext?: boolean;
  redirectCallback?: () => any;
}

export const FormsContainer = ({
  appointment,
  loadingAppointment,
  onFormCompleted,
  setAppointmentCancellation,
  formErrorsPresent,
  containerStyle,
  hideSubtext,
  redirectCallback,
}: IFormsContainerProps) => {
  const Str = useStr();
  const wrapperRef = useRef(null);
  const {config, lang} = useUrlData();

  return (
    <div style={containerStyle || {marginTop: 40}}>
      <h4 ref={wrapperRef}>{Str(Ids.required_forms)}</h4>
      {!hideSubtext && <p>{Str(Ids.complete_all_forms_prior_arriving)}</p>}

      {loadingAppointment && (
        <div style={{textAlign: 'center'}}>
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">{Str(Ids.loading_ellipsis, 'Loading...')}</span>
          </div>
          <div>{Str(Ids.loading_ellipsis, 'Loading...')}</div>
        </div>
      )}
      {!loadingAppointment ? (
        <PatientForms
          wrapperRef={wrapperRef}
          appointment={appointment}
          onAppointmentCancellation={setAppointmentCancellation}
          onFormCompleted={onFormCompleted}
        />
      ) : null}
      {formErrorsPresent && (
        <div style={{color: '#dc3545'}}>
          {Str(
            Ids.please_complete_fields_highlighted_red,
            'Please complete the fields highlighted in red.'
          )}
        </div>
      )}
    </div>
  );
};
