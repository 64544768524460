import React from 'react';
import {FormUXModel} from './form-ux-models/form-ux-model';
import {Col, DatePicker, Row, TimePicker, Tabs, Space, Tooltip} from 'antd';
import {
  Form,
  DatePicker as AntdDatePicker,
  FormItemProps,
  Input,
  InputNumber,
  Select,
  Switch,
  Radio,
} from 'formik-antd';
import {ValidateFormItem} from '../validate-form-item';
import {FormUXFieldType} from './form-ux-models/form-ux-fields/form-ux-field-type';
import {JsonField} from '../form-ux-fields/json-field';
import {UFormUXFields} from './form-ux-models/form-ux-fields/form-ux-fields';
import {IFormUXGroupedFields} from './form-ux-models/form-ux-fields/form-ux-grouped-fields';
import {IFormUXCustomField} from './form-ux-models/form-ux-fields/form-ux-custom-field';
import {MultipleItemsPicker} from '../form-ux-fields/multiple-entity-picker-field';
import {SingleItemPicker} from '../form-ux-fields/single-item-picker-field';
import {IFormUXFlexFields} from './form-ux-models/form-ux-fields/form-ux-flex-fields';
import {Field, FieldProps} from 'formik';
import moment from 'moment';
import {RequiredValidationRuleId} from '../../validation/validation-rules/required-validation';
import {IFormUXTabFields} from './form-ux-models/form-ux-fields/form-ux-tab-fields';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faQuestionCircle} from '@fortawesome/free-solid-svg-icons';
const {TextArea} = Input;

/**
 * Props for the FormUX component
 *
 * @export
 * @interface FormUXProps
 */
export interface FormUXProps {
  /**
   * The model object used to inform the rendering of the Form
   *
   * @type {FormUXModel}
   * @memberof FormUXProps
   */
  formUXModel: FormUXModel;
  /**
   * Whether this form is for creating a new item or editing an existing one
   *
   * @type {boolean}
   * @memberof FormUXProps
   */
  createMode: boolean;
  /**
   * Whether this form is created in a modal or not
   *
   * @type {boolean}
   * @memberof FormUXProps
   */
  modal?: boolean;
  /**
   * Prefix to add to all the field names.
   * Use this when the fields in this object are part of an array or an object
   * for example and you need to add the parent object/array name to the field
   * names.
   *
   * @type {string}
   * @memberof FormUXProps
   */
  namePrefix?: string;
  /**
   * Function called when the user should render the form item for a
   * field whose type is custom.
   *
   * @memberof FormUXProps
   */
  renderCustomField?: (field: IFormUXCustomField) => React.ReactElement;
  /**
   *Disable form fields when set to true
   *
   * @memberof FormUXProps
   */
  isDisabled?: boolean;
  /**
   * Separate form fields into tabs
   *
   * @memberof FormUXProps
   */
  tabs?: boolean;
}

/* Use this component when rendering the form used to create or edit
an entity UX */
export const FormUX = (props: FormUXProps) => {
  const namePrefix = props.namePrefix || '';

  /* Depending on whether we are creating a new entity or editing
    an existing one we show different form items. Show only those which
    have the inCreateModal flag set in create mode. */
  const fieldsToRender = props.formUXModel.filter((field) => {
    if (props.createMode) {
      return field.inCreateModal;
    } else if (field.inEditMode === false) {
      return false;
    } else return true;
  });

  const generateFormUXFields = (fields) =>
    fields.map((fieldToRender, fieldToRenderIndex) => {
      /* The fields that need to be rendered in this row */
      let formUXFields: Exclude<
        UFormUXFields,
        IFormUXGroupedFields | IFormUXFlexFields | IFormUXTabFields
      >[];
      let colSpan: number;

      if (fieldToRender.type === FormUXFieldType.tab) {
        formUXFields = generateFormUXFields(fieldToRender.fields);
      } else if (fieldToRender.type === FormUXFieldType.grouped) {
        formUXFields = fieldToRender.fields;
        colSpan = 12;
      } else if (fieldToRender.type === FormUXFieldType.flex) {
        formUXFields = fieldToRender.fields;
        colSpan = props.modal
          ? 24
          : fieldToRender.customColSpan
          ? fieldToRender.customColSpan
          : 24 / fieldToRender.fields.length;
      } else {
        formUXFields = [fieldToRender];
        colSpan = 24;
      }

      return fieldToRender.type === FormUXFieldType.tab ? (
        <Tabs.TabPane tab={fieldToRender.tabName} key={fieldToRender.tabKey}>
          {formUXFields}
        </Tabs.TabPane>
      ) : (
        <Row gutter={16} key={fieldToRenderIndex}>
          {formUXFields.map((formUXField, formUXFieldIndex) => {
            const required =
              formUXField.validationRules.find(
                (rule) => rule.validationRuleType === RequiredValidationRuleId
              ) !== undefined;

            return (
              <Col span={formUXField.colSpan || colSpan} key={formUXFieldIndex}>
                <ValidateFormItem
                  validationRules={formUXField.validationRules}
                  renderFormItem={(validate) => {
                    /* If this is a custom field then render the form
                      input immediately since the user may not want to
                      render it inside a Form.Item */
                    if (formUXField.type === FormUXFieldType.custom) {
                      return renderFormItemInput(
                        formUXField,
                        namePrefix,
                        props.renderCustomField,
                        props.isDisabled || formUXField.disabled
                      );
                    }

                    return (
                      <Form.Item
                        name={`${namePrefix}${formUXField.name}`}
                        label={formUXField.hideDefaultLabel ? '' : formUXField.label}
                        style={{
                          height: formUXField.type === FormUXFieldType.hidden ? 0 : undefined,
                        }}
                        tooltip={
                          required || formUXField.looksRequired
                            ? {
                                icon: (
                                  <span style={{color: 'red', fontSize: 14, fontWeight: 'bolder'}}>
                                    *
                                  </span>
                                ),
                              }
                            : undefined
                        }
                        required={required}
                        key={formUXField.name}
                        validate={validate}
                        extra={formUXField.extra}
                      >
                        {renderFormItemInput(
                          formUXField,
                          namePrefix,
                          props.renderCustomField,
                          props.isDisabled || formUXField.disabled
                        )}
                      </Form.Item>
                    );
                  }}
                />
              </Col>
            );
          })}
        </Row>
      );
    });

  return (
    <Form layout="vertical" requiredMark={false}>
      {props.tabs ? (
        <Tabs>{generateFormUXFields(fieldsToRender)}</Tabs>
      ) : (
        generateFormUXFields(fieldsToRender)
      )}
    </Form>
  );
};

/**
 * Based on the field's type property, decides which input element to render
 *
 * @param {FormUXField} field
 * @param {FormUXProps["renderCustomField"]} renderCustomField
 * @returns {React.ReactElement}
 */
function renderFormItemInput(
  field: Exclude<UFormUXFields, IFormUXGroupedFields | IFormUXFlexFields | IFormUXTabFields>,
  namePrefix: string,
  renderCustomField: FormUXProps['renderCustomField'],
  isDisabled: boolean | undefined
): React.ReactElement | null {
  /* The field name that the current field should use since it has the
    name prefix in it. */
  const fieldName = `${namePrefix}${field.name}`;

  switch (field.type) {
    // case FormUXFieldType.boolean:
    //   return (
    //     <Select name={fieldName} showSearch={field.searchable}>
    //       <Select.Option key={'true'} value={true}>
    //         {'True'}
    //       </Select.Option>
    //       <Select.Option key={'false'} value={false}>
    //         {'False'}
    //       </Select.Option>
    //       {/* {field.selectableValues.map((selectableValue) => {
    //     if (typeof selectableValue === 'string') {
    //       return (

    //       );
    //     } else {
    //       return (
    //         <Select.Option key={selectableValue.key} value={selectableValue.key}>
    //           {selectableValue.label}
    //         </Select.Option>
    //       );
    //     }
    //   })} */}
    //     </Select>
    //   );
    case FormUXFieldType.boolean:
      return <Switch name={fieldName} disabled={isDisabled || !field.editable} />;
    case FormUXFieldType.textarea:
      return (
        <TextArea
          fast
          name={fieldName}
          showCount
          autoSize
          disabled={isDisabled || !field.editable}
        />
      );
    case FormUXFieldType.text: // would have combined the if statements, but the maxLength default is ambiguous
      if (fieldName === 'phone') {
        return (
          <Input
            fast={!field.notFastField}
            name={fieldName}
            disabled={isDisabled || !field.editable}
            maxLength={10}
            type="tel"
          />
        );
      } else {
        return (
          <Input
            fast={!field.notFastField}
            name={fieldName}
            disabled={isDisabled || !field.editable}
          />
        );
      }
    case FormUXFieldType.json:
      return (
        <JsonField
          field={Object.assign({}, field, {
            name: fieldName,
          })}
        />
      );
    case FormUXFieldType.MultipleItemPicker:
      return (
        <MultipleItemsPicker
          field={Object.assign({}, field, {
            name: fieldName,
          })}
        />
      );
    case FormUXFieldType.custom: {
      if (renderCustomField === undefined) {
        throw new Error(
          `Field ${field.name} has type set to custom but no renderCustomField prop provided`
        );
      }

      return renderCustomField(field);
    }
    case FormUXFieldType.select: {
      return (
        <Select
          name={fieldName}
          showSearch={field.searchable}
          disabled={isDisabled || !field.editable}
        >
          {field.selectableValues.map((selectableValue) => {
            if (typeof selectableValue === 'string') {
              return (
                <Select.Option key={selectableValue} value={selectableValue}>
                  {selectableValue}
                </Select.Option>
              );
            } else {
              return (
                <Select.Option key={selectableValue.key} value={selectableValue.key}>
                  {selectableValue.label}
                </Select.Option>
              );
            }
          })}
        </Select>
      );
    }
    case FormUXFieldType.SingleItemPicker: {
      return (
        <SingleItemPicker
          field={Object.assign({}, field, {
            name: fieldName,
          })}
        />
      );
    }
    case FormUXFieldType.number: {
      return <InputNumber name={fieldName} disabled={isDisabled || !field.editable} />;
    }
    case FormUXFieldType.date: {
      return (
        <AntdDatePicker
          name={fieldName}
          placeholder={field.placeholder}
          style={{width: '100%'}}
          disabled={isDisabled || !field.editable}
        />
      );
    }
    case FormUXFieldType.MultipleSelect: {
      return (
        <Select
          name={fieldName}
          mode="multiple"
          filterOption={(input, option) =>
            option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {field.selectableValues.map((value) => {
            if (typeof value === 'string') return;

            return (
              <Select.Option key={value.key} value={value.key}>
                {value.label}
              </Select.Option>
            );
          })}
        </Select>
      );
    }
    case FormUXFieldType.radio:
      const [value, setValue] = field.state;

      return (
        <Radio.Group
          name={fieldName}
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
            // @ts-ignore
            field.onChange?.();
          }}
          disabled={!field.editable}
        >
          <Space direction="vertical">
            {field.options.map((o) => {
              return (
                <>
                  {/* @ts-ignore */}
                  <Radio name={fieldName} value={o.value}>
                    {o.label}
                    {o.description && (
                      <Tooltip title={o.description} placement="right">
                        <FontAwesomeIcon icon={faQuestionCircle} style={{marginLeft: 5}} />
                      </Tooltip>
                    )}
                  </Radio>
                </>
              );
            })}
          </Space>
        </Radio.Group>
      );
    case FormUXFieldType.hidden:
      return null;
  }
}

/** Component to use for time fields. Will eventually be used in the main
 * FormUX component.
 * Replaces the one from formik-antd since that uses string values rather
 * than moment object which was causing timezone issues.
 */
export const FormUXTimeField = (props: {
  name: string;
  label: string;
  validate: FormItemProps['validate'];
  timeFormat?: string;
}) => {
  return (
    <Field name={props.name} validate={props.validate}>
      {({field: {value}, form: {setFieldValue, setFieldTouched}}: FieldProps) => (
        <Form.Item name={props.name} label={props.label} validate={props.validate}>
          <TimePicker
            value={value}
            allowClear={false}
            format={props.timeFormat}
            onChange={(time) => {
              /* The time argument passed by antd in the onChange could be
              in the local timezone rather than UTC, for example when the field
              is cleared and a new value is set. The following code ensures
              that the timezone of the moment object is always in UTC. */
              let utcTime = time;
              if (time) {
                utcTime = moment.utc({
                  hour: time.hours(),
                  minutes: time.minutes(),
                  seconds: time.seconds(),
                });
              }

              setFieldValue(props.name, utcTime);
              setFieldTouched(props.name, true, false);
            }}
          />
        </Form.Item>
      )}
    </Field>
  );
};

/** Component to use for date fields. Will eventually be used in the main
 * FormUX component.
 * Replaces the one from formik-antd since that uses string values rather
 * than moment object which was causing timezone issues.
 */
export const FormUXDateField = (props: {
  name: string;
  label: string;
  validate: FormItemProps['validate'];
}) => {
  return (
    <Field name={props.name} validate={props.validate}>
      {({field: {value}, form: {setFieldValue, setFieldTouched}}: FieldProps) => (
        <Form.Item name={props.name} label={props.label} validate={props.validate}>
          <DatePicker
            value={value}
            /** if you want to set this to true, make sure that the picker still works after date is cleared */
            allowClear={false}
            onChange={(time) => {
              /**Set UTC offset to zero to prevent inconsistencies between datepicker selection and stored value in database */
              const formattedDate = moment(time).utcOffset(0, true);
              setFieldValue(props.name, formattedDate);
              setFieldTouched(props.name, true, false);
            }}
          />
        </Form.Item>
      )}
    </Field>
  );
};
