"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_Media = void 0;
var t = __importStar(require("io-ts"));
var RTTI_id_1 = require("../Scalar/RTTI_id");
var RTTI_Meta_1 = require("./RTTI_Meta");
var RTTI_uri_1 = require("../Scalar/RTTI_uri");
var RTTI_Element_1 = require("./RTTI_Element");
var RTTI_code_1 = require("../Scalar/RTTI_code");
var RTTI_Narrative_1 = require("./RTTI_Narrative");
var RTTI_ResourceList_1 = require("../Union/RTTI_ResourceList");
var RTTI_Extension_1 = require("./RTTI_Extension");
var RTTI_Identifier_1 = require("./RTTI_Identifier");
var RTTI_Reference_1 = require("./RTTI_Reference");
var RTTI_CodeableConcept_1 = require("./RTTI_CodeableConcept");
var RTTI_Period_1 = require("./RTTI_Period");
var RTTI_instant_1 = require("../Scalar/RTTI_instant");
var RTTI_positiveInt_1 = require("../Scalar/RTTI_positiveInt");
var RTTI_decimal_1 = require("../Scalar/RTTI_decimal");
var RTTI_Attachment_1 = require("./RTTI_Attachment");
var RTTI_Annotation_1 = require("./RTTI_Annotation");
exports.RTTI_Media = t.recursion('IMedia', function () {
    return t.intersection([
        t.type({
            resourceType: t.literal('Media'),
            content: RTTI_Attachment_1.RTTI_Attachment
        }),
        t.partial({
            id: RTTI_id_1.RTTI_id,
            meta: RTTI_Meta_1.RTTI_Meta,
            implicitRules: RTTI_uri_1.RTTI_uri,
            _implicitRules: RTTI_Element_1.RTTI_Element,
            language: RTTI_code_1.RTTI_code,
            _language: RTTI_Element_1.RTTI_Element,
            text: RTTI_Narrative_1.RTTI_Narrative,
            contained: t.array(RTTI_ResourceList_1.RTTI_ResourceList),
            extension: t.array(RTTI_Extension_1.RTTI_Extension),
            modifierExtension: t.array(RTTI_Extension_1.RTTI_Extension),
            identifier: t.array(RTTI_Identifier_1.RTTI_Identifier),
            basedOn: t.array(RTTI_Reference_1.RTTI_Reference),
            partOf: t.array(RTTI_Reference_1.RTTI_Reference),
            status: RTTI_code_1.RTTI_code,
            _status: RTTI_Element_1.RTTI_Element,
            type: RTTI_CodeableConcept_1.RTTI_CodeableConcept,
            modality: RTTI_CodeableConcept_1.RTTI_CodeableConcept,
            view: RTTI_CodeableConcept_1.RTTI_CodeableConcept,
            subject: RTTI_Reference_1.RTTI_Reference,
            encounter: RTTI_Reference_1.RTTI_Reference,
            createdDateTime: t.string,
            _createdDateTime: RTTI_Element_1.RTTI_Element,
            createdPeriod: RTTI_Period_1.RTTI_Period,
            issued: RTTI_instant_1.RTTI_instant,
            _issued: RTTI_Element_1.RTTI_Element,
            operator: RTTI_Reference_1.RTTI_Reference,
            reasonCode: t.array(RTTI_CodeableConcept_1.RTTI_CodeableConcept),
            bodySite: RTTI_CodeableConcept_1.RTTI_CodeableConcept,
            deviceName: t.string,
            _deviceName: RTTI_Element_1.RTTI_Element,
            device: RTTI_Reference_1.RTTI_Reference,
            height: RTTI_positiveInt_1.RTTI_positiveInt,
            _height: RTTI_Element_1.RTTI_Element,
            width: RTTI_positiveInt_1.RTTI_positiveInt,
            _width: RTTI_Element_1.RTTI_Element,
            frames: RTTI_positiveInt_1.RTTI_positiveInt,
            _frames: RTTI_Element_1.RTTI_Element,
            duration: RTTI_decimal_1.RTTI_decimal,
            _duration: RTTI_Element_1.RTTI_Element,
            note: t.array(RTTI_Annotation_1.RTTI_Annotation)
        })
    ]);
});
