import {Descriptions} from 'antd';
import React from 'react';

/* Note view for notes which only consist of a text and subject field. */
export const SimpleNoteView = (props) => {
  const {note, subjectKey = 'subject', hideSubject, fieldKey} = props;
  return (
    <Descriptions
      size="small"
      labelStyle={{
        fontWeight: 'bold',
        width: '100px',
        overflowWrap: 'break-word',
        marginRight: '10px',
        wordBreak: 'break-word',
      }}
      column={1}
      colon={false}
    >
      <Descriptions.Item label="Subject">
        {note.subject || (
          <span
            style={{
              opacity: '0.333',
            }}
          >
            (no subject)
          </span>
        )}
      </Descriptions.Item>
      <Descriptions.Item
        label="Content"
        contentStyle={{
          border: '1px dashed rgba(0,0,0,0.1)',
          borderRadius: '10px',
          padding: '10px',
        }}
      >
        {note.fields[fieldKey] || (
          <span
            style={{
              opacity: '0.333',
            }}
          >
            (no content)
          </span>
        )}
      </Descriptions.Item>
    </Descriptions>
  );
};
