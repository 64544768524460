"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_HumanName = exports.HumanNameUseKind = void 0;
var t = __importStar(require("io-ts"));
var RTTI_Extension_1 = require("./RTTI_Extension");
var RTTI_Element_1 = require("./RTTI_Element");
var RTTI_Period_1 = require("./RTTI_Period");
var HumanNameUseKind;
(function (HumanNameUseKind) {
    HumanNameUseKind["_usual"] = "usual";
    HumanNameUseKind["_official"] = "official";
    HumanNameUseKind["_temp"] = "temp";
    HumanNameUseKind["_nickname"] = "nickname";
    HumanNameUseKind["_anonymous"] = "anonymous";
    HumanNameUseKind["_old"] = "old";
    HumanNameUseKind["_maiden"] = "maiden";
})(HumanNameUseKind = exports.HumanNameUseKind || (exports.HumanNameUseKind = {}));
var EnumType_1 = require("../../EnumType");
exports.RTTI_HumanName = t.recursion('IHumanName', function () {
    return t.partial({
        id: t.string,
        extension: t.array(RTTI_Extension_1.RTTI_Extension),
        use: EnumType_1.createEnumType(HumanNameUseKind, 'HumanNameUseKind'),
        _use: RTTI_Element_1.RTTI_Element,
        text: t.string,
        _text: RTTI_Element_1.RTTI_Element,
        family: t.string,
        _family: RTTI_Element_1.RTTI_Element,
        given: t.array(t.string),
        _given: t.array(RTTI_Element_1.RTTI_Element),
        prefix: t.array(t.string),
        _prefix: t.array(RTTI_Element_1.RTTI_Element),
        suffix: t.array(t.string),
        _suffix: t.array(RTTI_Element_1.RTTI_Element),
        period: RTTI_Period_1.RTTI_Period
    });
});
