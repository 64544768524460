export function defineClinicAdminAllPatientsRules(builder) {
  const {can} = builder;
  // Menus
  can('access', 'menu', [
    'home',
    'patients',
    'appointments',
    'reports',
    'clinics',
    'users',
    'patientlookup',
    'help',
  ]);
  // Routes
  can('access', 'route', [
    '/home',
    '/patients**',
    '/appointments**',
    '/reports',
    '/clinics**',
    '/users**',
    '/patientlookup**',
    '/help',
  ]);
  // Permissions
  can('create', 'patients', ['appointment']);
  can('update', 'patients', ['editPatient', 'immunization']);
  can('view', 'patients', [
    'appointmentsWidget',
    'cohortsWidget',
    'formsWidget',
    'aefiWidget',
    'notesWidget',
    'flagsWidget',
    'launchPortalButton',
  ]);
  can('view', 'clinics', ['immunizersTab']);
  can('reforecast', 'patients');
  can('execute', 'appointments', ['blockRule']);
  can('view', ['clinicUsers']);
  can('read', 'crossOrgs');
}
