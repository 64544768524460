export function defineClinicOwnerAllPatientsRules(builder) {
  const {can} = builder;
  // Menus
  can('access', 'menu', [
    'home',
    'patients',
    'appointments',
    'cohorts',
    'reports',
    'clinics',
    'users',
    'roles',
    'orgadminusers',
    'patientlookup',
    'help',
  ]);
  // Routes
  can('access', 'route', [
    '/home',
    '/patients**',
    '/appointments**',
    '/cohorts**',
    '/reports',
    '/clinics**',
    '/users**',
    '/roles**',
    '/orgadminusers**',
    '/patientlookup**',
    '/help',
  ]);
  // Permissions
  can('create', 'patients', ['newPatients', 'immunization', 'appointment']);
  can('update', 'patients', ['cohortsWidget', 'editPatient', 'immunization', 'overrideSeries']);
  can('delete', 'patients', ['patient', 'immunization']);
  can('view', 'patients', [
    'appointmentsWidget',
    'cohortsWidget',
    'formsWidget',
    'aefiWidget',
    'notesWidget',
    'adminWidget',
    'flagsWidget',
    'launchPortalButton',
  ]);
  can('view', 'clinics', ['cohortsTab', 'immunizersTab', 'patientsTab', 'notesWidget']);
  can('reforecast', 'patients');
  can('execute', 'appointments', ['blockRule']);
  can('view', ['orgAdminUsers', 'clinicUsers']);
  can('read', 'crossOrgs');
}
