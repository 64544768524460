import {ContactPointSystemKind} from '@ahryman40k/ts-fhir-types/lib/R4';
import {Space, Tag} from 'antd';
import React from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '../../models';
import {getStoredOrg} from '../../models/organizations';
import Patient from '../../models/patients';
import {AbilityContext} from '../../services/roles/ability-context';
import {processGender} from '../../util/helpers/processGender';
import {RequiredValidationRuleId} from '../../validation/validation-rules/required-validation';
import {BaseEntityV2, FormConfig} from '../base-entity/base-entity';
import {FHIRTableProps} from '../fhir-table';
import {FormUX, FormUXFieldType} from '../form-ux';
import {OrganizationPickerFormItem} from '../organization/organization-picker';
import {HcnTypePickerFormItem} from '../pickers/hcntype-picker';
import {CopyIDTag} from '../util/copy-id-tag';
import {PatientItemPage} from './item-page';
import {
  PatientCustomFieldName,
  PatientFieldsSkippableOnOverride,
  PatientUXModel,
} from './patient-form-ux-model';

export const PatientUX = () => {
  const globalOrg = useSelector((state: RootState) => state.ui.localOrg);

  const CanCreatePatient = () => {
    const ability = React.useContext(AbilityContext);
    return ability?.can('create', 'patients', 'newPatients');
  };

  const CantUpdatePatient = (patient) => {
    const storedOrg = getStoredOrg(patient?.organizationId);
    const ability = React.useContext(AbilityContext);
    return !ability?.can('update', 'patients', 'editPatient') || !storedOrg;
  };

  const CantDeletePatient = (patient) => {
    const storedOrg = getStoredOrg(patient?.organizationId);
    const ability = React.useContext(AbilityContext);
    return !ability?.can('delete', 'patients', 'patient') || !storedOrg;
  };

  const model = Patient;

  const itemTitleField = 'fullName';

  const PatientForm = (props) => {
    let formUXModel = PatientUXModel;
    // Check if patient's managing org is in user's org access tree
    const storedOrg = getStoredOrg(props.values.organizationId);

    if (props.values.override?.overrideReason?.length > 0) {
      const filterCondition = (r) => r.validationRuleType !== RequiredValidationRuleId;

      const findAndFilter = (item) => {
        if (PatientFieldsSkippableOnOverride.includes(item.name)) {
          item.validationRules = item.validationRules.filter(filterCondition);
        }
        return item;
      };

      const mapFunction = (item) => {
        let formItem = JSON.parse(JSON.stringify(item));

        if (formItem.type === FormUXFieldType.grouped) {
          const fields = formItem.fields.map(findAndFilter);

          // @ts-ignore formItem.fields has to be 2 items, and fields will be 2 items,
          // but the compiler doesn't believe that
          formItem.fields = fields;
        } else if (formItem.type !== FormUXFieldType.flex) {
          formItem = findAndFilter(formItem);
        }
        return formItem;
      };
      formUXModel = formUXModel.map(mapFunction);
    }

    return (
      <FormUX
        formUXModel={formUXModel}
        isDisabled={props.editMode && !storedOrg}
        renderCustomField={(field) => {
          switch (field.name) {
            case PatientCustomFieldName.OrganizationId: {
              return (
                <OrganizationPickerFormItem
                  label={field.label}
                  name={field.name}
                  setFieldValue={props.setFieldValue}
                  validationRules={field.validationRules}
                  organizationId={props.values?.organizationId}
                  // Disable editing if patient organization is not within user's orgs list
                  disabled={props.editMode && !storedOrg}
                />
              );
            }
            case PatientCustomFieldName.HcnType: {
              return (
                <HcnTypePickerFormItem
                  label={field.label}
                  name={field.name}
                  setFieldValue={props.setFieldValue}
                  validationRules={field.validationRules}
                  looksRequired={field.looksRequired}
                  disabled={props.editMode && !storedOrg}
                />
              );
            }
            default: {
              throw new Error(`Unhandled custom field ${field.name} in
          renderCustomField method`);
            }
          }
        }}
        createMode={props.createMode}
      />
    );
  };

  const formConfig: FormConfig = {
    defaultValues: {},
    FormItems: PatientForm,
  };

  const titleBarFooter = (item, settings) => {
    return (
      <div>
        <Space size={5} style={{marginBottom: 12}}>
          <CopyIDTag id={item.id} />
        </Space>
      </div>
    );
  };

  const fixedFilters = globalOrg
    ? {
        _organizationId: globalOrg.id,
      }
    : undefined;

  const tableConfig: FHIRTableProps = {
    fhirResource: 'Patient',
    label: 'Patient',
    fixedFilters,
    mode: 'table',
    showCreateButton: CanCreatePatient,
    defaultPrimarySearchParam: 'name',
    columns: [
      {
        title: 'Name',
        dataIndex: 'fullName',
        sorter: (a, b) => a.fullName.toLowerCase().localeCompare(b.fullName.toLowerCase()),
        render: (_, p) => (
          <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <div>{p.fullName}</div>
            {p.deceased && <Tag color="red">Deceased</Tag>}
          </div>
        ),
      },
      {
        title: 'Email',
        dataIndex: 'email',
        sorter: (a, b) => sortParam(a, b, 'email'),
        render: (_, patient) => {
          if (patient.email) return patient.email;

          const emails =
            patient.contactPoints
              ?.filter((c) => c.system === ContactPointSystemKind._email)
              .sort((a, b) => (a.value?.toLowerCase() > b.value?.toLowerCase() ? 1 : -1)) || [];

          return emails.length > 0 ? emails[0].value : '';
        },
      },
      {
        title: 'DOB',
        dataIndex: 'birthdate',
        sorter: (a, b) => a.birthdate.localeCompare(b.birthdate), // birthdate is required
      },
      {
        title: 'Gender',
        dataIndex: 'gender',
        render: (_, patient) => processGender(patient.gender),
      },
      {
        title: 'HCN',
        dataIndex: 'hcn',
        sorter: (a, b) => sortParam(a, b, 'hcn'),
      },
    ],
  };

  const config = {
    slug: 'patient',
    model,
    itemTitleField,
    formConfig,
    searchPageTitle: 'Patients',
    tableConfig,
    modalCreateForm: true,
    modalEditForm: true,
    itemPage: PatientItemPage,
    titleBarFooter,
    modalEditWidth: 900,
    hideDeleteButton: CantDeletePatient,
    titleBarEntityLabel: 'Patient',
    hideEditButton: CantUpdatePatient,
    showOverride: true,
    showNoteModalForDelete: true,
  };

  return <BaseEntityV2 {...config} />;
};

export const sortParam = (a, b, param) => {
  const sort_type_a = a[param];
  const sort_type_b = b[param];
  // easy to add more conditions to sort by when an entry can be null/empty
  if (sort_type_a && sort_type_b)
    return sort_type_a.toLowerCase().localeCompare(sort_type_b.toLowerCase());
  if (sort_type_a) return -1
  if (sort_type_b) return 1;
  return a.fullName.toLowerCase().localeCompare(b.fullName.toLowerCase());
};