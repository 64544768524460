import {Button, Col, DatePicker, Input, Row, Select, Table} from 'antd';
import React, {useCallback, useState} from 'react';
import * as Icons from '@ant-design/icons';
import styles from '../fhir-table/styles.module.css';
import moment from 'moment';
import {FhirUtils} from '../../services/fhir';
import qs from 'querystring';
import {debounce} from 'lodash';
import {getSearchTerm, persistSave} from '../../models/ui-temp';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router';
import {RootState} from '../../models';

export const LogTable = (props) => {
  const {columns, fixedFilters} = props;
  const {key} = useLocation();

  const persistedTerm = key && getSearchTerm(key);

  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState(persistedTerm?.value || '');
  const [calendarDate, setCalendarDate] = useState(persistedTerm?.options?.date);
  const [logType, setLogType] = useState(null);
  const [data, setData] = useState<any>({
    total: 0,
    entry: [],
  });

  const dispatch = useDispatch();
  React.useEffect(() => {
    key && persistSave(dispatch, key, searchTerm, calendarDate ? {date: calendarDate} : undefined);
  }, [searchTerm, calendarDate]);

  // State: pageSize
  const defaultPageSize = 50;
  const [pageSize, setPageSize] = React.useState(defaultPageSize);
  const [currentTablePage, setCurrentTablePage] = React.useState(1);

  //State: sorting by column
  const [sorting, setSorting] = React.useState({
    sortColumn: undefined,
    sortOrder: undefined,
  });

  const {sortColumn, sortOrder} = sorting;
  const client = FhirUtils.useAxiosClient();

  React.useEffect(() => {
    setLoading(true);
    const query = getQueryString();

    client
      .get(query)
      .then((res) => setData(res.data))
      .catch((err) => alert(err.message))
      .finally(() => setLoading(false));
  }, [searchTerm, currentTablePage, calendarDate, sorting, logType]);

  const resources: any[] = data ? data.entry?.map((entry) => entry.resource) : [];
  const total = data ? data.total : 0;

  const getQueryString = () => {
    const _getpagesoffset = Math.max(currentTablePage - 1, 0) * pageSize;
    const pagingParams = {_total: 'accurate', _count: pageSize, _getpagesoffset};
    const orderingParams = {_sortField: sortColumn, _sortDirection: sortOrder};
    const dateParams = calendarDate
      ? {
          startDate: moment(calendarDate?.[0]).toISOString(),
          endDate: moment(calendarDate?.[1]).toISOString(),
        }
      : {};

    const queryParams = qs.stringify({
      ...fixedFilters,
      ...pagingParams,
      ...orderingParams,
      ...dateParams,
      name: searchTerm,
      logType: logType,
    });
    return `logs?${queryParams}`;
  };

  //Handles changes on search bar
  const handleSearchChange = (pagination) => {
    const {pageSize, current} = pagination;
    setPageSize(pageSize);
    setCurrentTablePage(current);
  };

  //Handles changes to sort columns and pagination
  const handleTableChange = (pagination, filters, sorter) => {
    const {pageSize, current} = pagination;
    const sortField = sorter.field;
    let sortDirection = sorter.order;
    if (sorter.order === 'ascend') {
      sortDirection = 'ASC';
    } else if (sorter.order === 'descend') {
      sortDirection = 'DESC';
    }
    setPageSize(pageSize);
    setCurrentTablePage(current);
    setSorting({
      sortColumn: sortField,
      sortOrder: sortDirection,
    });
  };

  return (
    <Row gutter={[16, 0]}>
      <Col md={24}>
        <div style={{marginBottom: 20}}>
          <div style={{marginBottom: 20}}>
            <LogSearchTableButtonBar
              setQuery={setSearchTerm}
              setCalendarDate={setCalendarDate}
              calendarDate={calendarDate}
              onSearchChange={() => handleSearchChange({pageSize, current: 1})}
              searchTerm={searchTerm}
              logType={logType}
              setLogType={setLogType}
            />
          </div>
          <Table
            style={{overflowWrap: 'anywhere', wordBreak: 'break-word'}}
            dataSource={resources}
            columns={columns}
            onChange={handleTableChange}
            loading={loading}
            pagination={{
              defaultPageSize: defaultPageSize,
              current: currentTablePage,
              showTotal: (total, range) => (
                <span style={{color: 'white'}}>{`Total Items: ${total}`}</span>
              ),
              showSizeChanger: true,
              pageSizeOptions: ['50', '100', '200'],
              pageSize: pageSize,
              total: total,
            }}
          ></Table>
        </div>
      </Col>
    </Row>
  );
};

const LogSearchTableButtonBar = (props) => {
  const client = FhirUtils.useAxiosClient();
  const globalOrg = useSelector((state: RootState) => state.ui.localOrg);
  const [logTypes, setLogTypes] = useState([]);
  const {calendarDate, setCalendarDate, setQuery, onSearchChange, searchTerm, logType, setLogType} =
    props;
  const [nextValue, setNextValue] = useState(searchTerm);
  const defaultDebounceTime = 500;
  const debounceSave = useCallback(
    debounce((value) => setQuery(value), defaultDebounceTime),
    []
  );

  React.useEffect(() => {
    fetchLogTypes();
  }, []);

  async function fetchLogTypes() {
    return client.get(`/log-types/${globalOrg?.id || ''}`).then(async (res) => {
      setLogTypes(res.data.entry);
    });
  }

  return (
    <Row gutter={[16, 16]}>
      <Col style={{flexGrow: 1}}>
        <div style={{display: 'flex'}}>
          <DatePicker.RangePicker
            style={{width: 400, marginRight: 16}}
            defaultValue={calendarDate}
            onChange={(date) => {
              if (date) {
                setCalendarDate(date);
                onSearchChange();
              } else setCalendarDate(undefined);
            }}
          />
          <Select
            showSearch
            style={{width: 400, marginRight: 16}}
            size="large"
            value={logType}
            onSelect={(e) => setLogType(e)}
            placeholder="Log Type"
            allowClear={true}
            onClear={() => setLogType(null)}
          >
            {logTypes.map((item, index) => (
              <Select.Option key={`${item}-${index}`} value={item}>
                {item}
              </Select.Option>
            ))}
          </Select>
          <Input.Group compact style={{display: 'flex', flexGrow: 1}}>
            <Select
              defaultValue="0"
              style={{flexShrink: 1, flexGrow: 0}}
              size="large"
              value={'table'}
              showArrow={false}
              disabled={true}
            >
              <Select.Option value={'table'}>
                <Icons.SearchOutlined />
              </Select.Option>
            </Select>
            <Input
              className={styles.fhirTableSearchBar}
              placeholder={'Log Search'}
              size="large"
              allowClear
              value={nextValue}
              onChange={(event) => {
                const input = event.target.value;
                setNextValue(input);
                debounceSave(input);
                onSearchChange();
              }}
            />
            <Button
              type="primary"
              icon={<Icons.SearchOutlined />}
              size="large"
              style={{
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                paddingRight: 10,
                paddingLeft: 10,
              }}
            />
          </Input.Group>
        </div>
      </Col>
    </Row>
  );
};
