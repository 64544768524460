"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_ExplanationOfBenefit = exports.ExplanationOfBenefitStatusKind = void 0;
var t = __importStar(require("io-ts"));
var RTTI_id_1 = require("../Scalar/RTTI_id");
var RTTI_Meta_1 = require("./RTTI_Meta");
var RTTI_uri_1 = require("../Scalar/RTTI_uri");
var RTTI_Element_1 = require("./RTTI_Element");
var RTTI_code_1 = require("../Scalar/RTTI_code");
var RTTI_Narrative_1 = require("./RTTI_Narrative");
var RTTI_ResourceList_1 = require("../Union/RTTI_ResourceList");
var RTTI_Extension_1 = require("./RTTI_Extension");
var RTTI_Identifier_1 = require("./RTTI_Identifier");
var RTTI_CodeableConcept_1 = require("./RTTI_CodeableConcept");
var RTTI_Reference_1 = require("./RTTI_Reference");
var RTTI_Period_1 = require("./RTTI_Period");
var RTTI_dateTime_1 = require("../Scalar/RTTI_dateTime");
var RTTI_ExplanationOfBenefit_Related_1 = require("./RTTI_ExplanationOfBenefit_Related");
var RTTI_ExplanationOfBenefit_Payee_1 = require("./RTTI_ExplanationOfBenefit_Payee");
var RTTI_ExplanationOfBenefit_CareTeam_1 = require("./RTTI_ExplanationOfBenefit_CareTeam");
var RTTI_ExplanationOfBenefit_SupportingInfo_1 = require("./RTTI_ExplanationOfBenefit_SupportingInfo");
var RTTI_ExplanationOfBenefit_Diagnosis_1 = require("./RTTI_ExplanationOfBenefit_Diagnosis");
var RTTI_ExplanationOfBenefit_Procedure_1 = require("./RTTI_ExplanationOfBenefit_Procedure");
var RTTI_positiveInt_1 = require("../Scalar/RTTI_positiveInt");
var RTTI_ExplanationOfBenefit_Insurance_1 = require("./RTTI_ExplanationOfBenefit_Insurance");
var RTTI_ExplanationOfBenefit_Accident_1 = require("./RTTI_ExplanationOfBenefit_Accident");
var RTTI_ExplanationOfBenefit_Item_1 = require("./RTTI_ExplanationOfBenefit_Item");
var RTTI_ExplanationOfBenefit_AddItem_1 = require("./RTTI_ExplanationOfBenefit_AddItem");
var RTTI_ExplanationOfBenefit_Adjudication_1 = require("./RTTI_ExplanationOfBenefit_Adjudication");
var RTTI_ExplanationOfBenefit_Total_1 = require("./RTTI_ExplanationOfBenefit_Total");
var RTTI_ExplanationOfBenefit_Payment_1 = require("./RTTI_ExplanationOfBenefit_Payment");
var RTTI_Attachment_1 = require("./RTTI_Attachment");
var RTTI_ExplanationOfBenefit_ProcessNote_1 = require("./RTTI_ExplanationOfBenefit_ProcessNote");
var RTTI_ExplanationOfBenefit_BenefitBalance_1 = require("./RTTI_ExplanationOfBenefit_BenefitBalance");
var ExplanationOfBenefitStatusKind;
(function (ExplanationOfBenefitStatusKind) {
    ExplanationOfBenefitStatusKind["_active"] = "active";
    ExplanationOfBenefitStatusKind["_cancelled"] = "cancelled";
    ExplanationOfBenefitStatusKind["_draft"] = "draft";
    ExplanationOfBenefitStatusKind["_enteredInError"] = "entered-in-error";
})(ExplanationOfBenefitStatusKind = exports.ExplanationOfBenefitStatusKind || (exports.ExplanationOfBenefitStatusKind = {}));
var EnumType_1 = require("../../EnumType");
exports.RTTI_ExplanationOfBenefit = t.recursion('IExplanationOfBenefit', function () {
    return t.intersection([
        t.type({
            resourceType: t.literal('ExplanationOfBenefit'),
            type: RTTI_CodeableConcept_1.RTTI_CodeableConcept,
            patient: RTTI_Reference_1.RTTI_Reference,
            insurer: RTTI_Reference_1.RTTI_Reference,
            provider: RTTI_Reference_1.RTTI_Reference,
            insurance: t.array(RTTI_ExplanationOfBenefit_Insurance_1.RTTI_ExplanationOfBenefit_Insurance)
        }),
        t.partial({
            id: RTTI_id_1.RTTI_id,
            meta: RTTI_Meta_1.RTTI_Meta,
            implicitRules: RTTI_uri_1.RTTI_uri,
            _implicitRules: RTTI_Element_1.RTTI_Element,
            language: RTTI_code_1.RTTI_code,
            _language: RTTI_Element_1.RTTI_Element,
            text: RTTI_Narrative_1.RTTI_Narrative,
            contained: t.array(RTTI_ResourceList_1.RTTI_ResourceList),
            extension: t.array(RTTI_Extension_1.RTTI_Extension),
            modifierExtension: t.array(RTTI_Extension_1.RTTI_Extension),
            identifier: t.array(RTTI_Identifier_1.RTTI_Identifier),
            status: EnumType_1.createEnumType(ExplanationOfBenefitStatusKind, 'ExplanationOfBenefitStatusKind'),
            _status: RTTI_Element_1.RTTI_Element,
            subType: RTTI_CodeableConcept_1.RTTI_CodeableConcept,
            use: RTTI_code_1.RTTI_code,
            _use: RTTI_Element_1.RTTI_Element,
            billablePeriod: RTTI_Period_1.RTTI_Period,
            created: RTTI_dateTime_1.RTTI_dateTime,
            _created: RTTI_Element_1.RTTI_Element,
            enterer: RTTI_Reference_1.RTTI_Reference,
            priority: RTTI_CodeableConcept_1.RTTI_CodeableConcept,
            fundsReserveRequested: RTTI_CodeableConcept_1.RTTI_CodeableConcept,
            fundsReserve: RTTI_CodeableConcept_1.RTTI_CodeableConcept,
            related: t.array(RTTI_ExplanationOfBenefit_Related_1.RTTI_ExplanationOfBenefit_Related),
            prescription: RTTI_Reference_1.RTTI_Reference,
            originalPrescription: RTTI_Reference_1.RTTI_Reference,
            payee: RTTI_ExplanationOfBenefit_Payee_1.RTTI_ExplanationOfBenefit_Payee,
            referral: RTTI_Reference_1.RTTI_Reference,
            facility: RTTI_Reference_1.RTTI_Reference,
            claim: RTTI_Reference_1.RTTI_Reference,
            claimResponse: RTTI_Reference_1.RTTI_Reference,
            outcome: RTTI_code_1.RTTI_code,
            _outcome: RTTI_Element_1.RTTI_Element,
            disposition: t.string,
            _disposition: RTTI_Element_1.RTTI_Element,
            preAuthRef: t.array(t.string),
            _preAuthRef: t.array(RTTI_Element_1.RTTI_Element),
            preAuthRefPeriod: t.array(RTTI_Period_1.RTTI_Period),
            careTeam: t.array(RTTI_ExplanationOfBenefit_CareTeam_1.RTTI_ExplanationOfBenefit_CareTeam),
            supportingInfo: t.array(RTTI_ExplanationOfBenefit_SupportingInfo_1.RTTI_ExplanationOfBenefit_SupportingInfo),
            diagnosis: t.array(RTTI_ExplanationOfBenefit_Diagnosis_1.RTTI_ExplanationOfBenefit_Diagnosis),
            procedure: t.array(RTTI_ExplanationOfBenefit_Procedure_1.RTTI_ExplanationOfBenefit_Procedure),
            precedence: RTTI_positiveInt_1.RTTI_positiveInt,
            _precedence: RTTI_Element_1.RTTI_Element,
            accident: RTTI_ExplanationOfBenefit_Accident_1.RTTI_ExplanationOfBenefit_Accident,
            item: t.array(RTTI_ExplanationOfBenefit_Item_1.RTTI_ExplanationOfBenefit_Item),
            addItem: t.array(RTTI_ExplanationOfBenefit_AddItem_1.RTTI_ExplanationOfBenefit_AddItem),
            adjudication: t.array(RTTI_ExplanationOfBenefit_Adjudication_1.RTTI_ExplanationOfBenefit_Adjudication),
            total: t.array(RTTI_ExplanationOfBenefit_Total_1.RTTI_ExplanationOfBenefit_Total),
            payment: RTTI_ExplanationOfBenefit_Payment_1.RTTI_ExplanationOfBenefit_Payment,
            formCode: RTTI_CodeableConcept_1.RTTI_CodeableConcept,
            form: RTTI_Attachment_1.RTTI_Attachment,
            processNote: t.array(RTTI_ExplanationOfBenefit_ProcessNote_1.RTTI_ExplanationOfBenefit_ProcessNote),
            benefitPeriod: RTTI_Period_1.RTTI_Period,
            benefitBalance: t.array(RTTI_ExplanationOfBenefit_BenefitBalance_1.RTTI_ExplanationOfBenefit_BenefitBalance)
        })
    ]);
});
