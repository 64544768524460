import React from 'react';
import {useEnvInfo} from '../../services/environment';
import {useUrlData} from './util';

export const LanguagePicker = () => {
  const {lang} = useUrlData();

  const {supportFrench} = useEnvInfo();

  const switchLanguage = (toLanguage) => {
    if (lang === toLanguage) return;

    const currentUrl = new URL(window.location.href);
    const newPathname = currentUrl.pathname.replace(`/${lang}/`, `/${toLanguage}/`);
    // TODO: Figure out why forms do not update with current language when rerendering form component
    // with react-router useHistory
    // navigate(newPathname);
    window.location.replace(newPathname);
  };

  return (
    <div className="invisible-on-print" style={{marginBottom: 20, textAlign: 'right', height: 28}}>
      {supportFrench ? (
        <a
          onClick={() => switchLanguage(lang === 'en' ? 'fr' : 'en')}
          style={{fontSize: 14, fontWeight: 'bold'}}
        >
          {lang === 'en' ? 'Français' : 'English'}
        </a>
      ) : null}
    </div>
  );
};
