export function defineNSAdminRules(builder) {
  const {can} = builder;
  // Menus
  can('access', 'menu', [
    'home',
    'patients',
    'appointments',
    'campaigns',
    'lists',
    'bookingpages',
    'import',
    'organizations',
    'cohorts',
    'reports',
    'clinics',
    'forms',
    'users',
    'roles',
    'orgadminusers',
    'patientlookup',
    'help',
  ]);
  // Routes
  can('access', 'route', [
    '/home',
    '/patients**',
    '/campaigns**',
    '/lists**',
    '/bookingpages**',
    '/logs',
    '/import',
    '/organizations**',
    '/forms**',
    '/roles**',
    '/terminology**',
    '/appointments**',
    '/cohorts**',
    '/reports',
    '/clinics**',
    '/users**',
    '/patientlookup**',
    '/help',
  ]);
  // Permissions
  can('create', 'patients', ['newPatients', 'immunization', 'appointment', 'flag']);
  can('update', 'patients', [
    'cohortsWidget',
    'immunization',
    'editPatient',
    'overrideSeries',
    'doseValidity',
    'flag',
  ]);
  can('delete', 'patients', ['patient', 'immunization', 'flag']);
  can('view', 'patients', [
    'appointmentsWidget',
    'cohortsWidget',
    'formsWidget',
    'aefiWidget',
    'notesWidget',
    'adminWidget',
    'flagsWidget',
    'launchPortalButton',
  ]);
  can('view', 'clinics', [
    'cohortsTab',
    'immunizersTab',
    'patientsTab',
    'notesWidget',
    'calendarsTab',
  ]);
  can('update', 'clinics', 'cohortsTab');
  can('update', 'clinics', 'clinic');
  can('create', 'clinics', 'clinic');
  can('reforecast', 'patients');
  can('execute', 'appointments', ['blockRule']);
  can('import', ['patients', 'clinics']);
  can('view', ['clinicUsers']);
  can('create', 'cohorts');
  can('update', 'cohorts', 'cohort');
  can('execute', 'cohorts', 'uploadPatients');
  can('create', 'appointments', 'singleDose');
}
