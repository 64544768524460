import cn from 'classnames';
import 'moment/min/locales';
import React from 'react';
import {LanguagePicker} from '../language-picker';
import {LogoBar} from '../logo-bar';
import s from '../styles.module.css';

export const BisectedPageLayout = (props) => {
  return (
    <div className={s.background}>
      <div
        className={
          props?.isYukonCallCentre
            ? s.bisectedLayoutUpperPanelYkCallCentre
            : s.bisectedLayoutUpperPanel
        }
        style={props.upperPanelStyle}
      >
        <div className={props.hideOnPrint ? 'container hide-on-print' : 'container'}>
          <LanguagePicker />
          <LogoBar isYukonCallCentre={props.isYukonCallCentre} />
          {props.header}
        </div>
      </div>

      <div className={cn('container', s.bisectedLayoutLowerContainer)} style={props.containerStyle}>
        {props.children}
      </div>
    </div>
  );
};
