"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_EvidenceVariable = exports.EvidenceVariableTypeKind = exports.EvidenceVariableStatusKind = void 0;
var t = __importStar(require("io-ts"));
var RTTI_id_1 = require("../Scalar/RTTI_id");
var RTTI_Meta_1 = require("./RTTI_Meta");
var RTTI_uri_1 = require("../Scalar/RTTI_uri");
var RTTI_Element_1 = require("./RTTI_Element");
var RTTI_code_1 = require("../Scalar/RTTI_code");
var RTTI_Narrative_1 = require("./RTTI_Narrative");
var RTTI_ResourceList_1 = require("../Union/RTTI_ResourceList");
var RTTI_Extension_1 = require("./RTTI_Extension");
var RTTI_Identifier_1 = require("./RTTI_Identifier");
var RTTI_dateTime_1 = require("../Scalar/RTTI_dateTime");
var RTTI_ContactDetail_1 = require("./RTTI_ContactDetail");
var RTTI_markdown_1 = require("../Scalar/RTTI_markdown");
var RTTI_Annotation_1 = require("./RTTI_Annotation");
var RTTI_UsageContext_1 = require("./RTTI_UsageContext");
var RTTI_CodeableConcept_1 = require("./RTTI_CodeableConcept");
var RTTI_date_1 = require("../Scalar/RTTI_date");
var RTTI_Period_1 = require("./RTTI_Period");
var RTTI_RelatedArtifact_1 = require("./RTTI_RelatedArtifact");
var RTTI_EvidenceVariable_Characteristic_1 = require("./RTTI_EvidenceVariable_Characteristic");
var EvidenceVariableStatusKind;
(function (EvidenceVariableStatusKind) {
    EvidenceVariableStatusKind["_draft"] = "draft";
    EvidenceVariableStatusKind["_active"] = "active";
    EvidenceVariableStatusKind["_retired"] = "retired";
    EvidenceVariableStatusKind["_unknown"] = "unknown";
})(EvidenceVariableStatusKind = exports.EvidenceVariableStatusKind || (exports.EvidenceVariableStatusKind = {}));
var EvidenceVariableTypeKind;
(function (EvidenceVariableTypeKind) {
    EvidenceVariableTypeKind["_dichotomous"] = "dichotomous";
    EvidenceVariableTypeKind["_continuous"] = "continuous";
    EvidenceVariableTypeKind["_descriptive"] = "descriptive";
})(EvidenceVariableTypeKind = exports.EvidenceVariableTypeKind || (exports.EvidenceVariableTypeKind = {}));
var EnumType_1 = require("../../EnumType");
exports.RTTI_EvidenceVariable = t.recursion('IEvidenceVariable', function () {
    return t.intersection([
        t.type({
            resourceType: t.literal('EvidenceVariable'),
            characteristic: t.array(RTTI_EvidenceVariable_Characteristic_1.RTTI_EvidenceVariable_Characteristic)
        }),
        t.partial({
            id: RTTI_id_1.RTTI_id,
            meta: RTTI_Meta_1.RTTI_Meta,
            implicitRules: RTTI_uri_1.RTTI_uri,
            _implicitRules: RTTI_Element_1.RTTI_Element,
            language: RTTI_code_1.RTTI_code,
            _language: RTTI_Element_1.RTTI_Element,
            text: RTTI_Narrative_1.RTTI_Narrative,
            contained: t.array(RTTI_ResourceList_1.RTTI_ResourceList),
            extension: t.array(RTTI_Extension_1.RTTI_Extension),
            modifierExtension: t.array(RTTI_Extension_1.RTTI_Extension),
            url: RTTI_uri_1.RTTI_uri,
            _url: RTTI_Element_1.RTTI_Element,
            identifier: t.array(RTTI_Identifier_1.RTTI_Identifier),
            version: t.string,
            _version: RTTI_Element_1.RTTI_Element,
            name: t.string,
            _name: RTTI_Element_1.RTTI_Element,
            title: t.string,
            _title: RTTI_Element_1.RTTI_Element,
            shortTitle: t.string,
            _shortTitle: RTTI_Element_1.RTTI_Element,
            subtitle: t.string,
            _subtitle: RTTI_Element_1.RTTI_Element,
            status: EnumType_1.createEnumType(EvidenceVariableStatusKind, 'EvidenceVariableStatusKind'),
            _status: RTTI_Element_1.RTTI_Element,
            date: RTTI_dateTime_1.RTTI_dateTime,
            _date: RTTI_Element_1.RTTI_Element,
            publisher: t.string,
            _publisher: RTTI_Element_1.RTTI_Element,
            contact: t.array(RTTI_ContactDetail_1.RTTI_ContactDetail),
            description: RTTI_markdown_1.RTTI_markdown,
            _description: RTTI_Element_1.RTTI_Element,
            note: t.array(RTTI_Annotation_1.RTTI_Annotation),
            useContext: t.array(RTTI_UsageContext_1.RTTI_UsageContext),
            jurisdiction: t.array(RTTI_CodeableConcept_1.RTTI_CodeableConcept),
            copyright: RTTI_markdown_1.RTTI_markdown,
            _copyright: RTTI_Element_1.RTTI_Element,
            approvalDate: RTTI_date_1.RTTI_date,
            _approvalDate: RTTI_Element_1.RTTI_Element,
            lastReviewDate: RTTI_date_1.RTTI_date,
            _lastReviewDate: RTTI_Element_1.RTTI_Element,
            effectivePeriod: RTTI_Period_1.RTTI_Period,
            topic: t.array(RTTI_CodeableConcept_1.RTTI_CodeableConcept),
            author: t.array(RTTI_ContactDetail_1.RTTI_ContactDetail),
            editor: t.array(RTTI_ContactDetail_1.RTTI_ContactDetail),
            reviewer: t.array(RTTI_ContactDetail_1.RTTI_ContactDetail),
            endorser: t.array(RTTI_ContactDetail_1.RTTI_ContactDetail),
            relatedArtifact: t.array(RTTI_RelatedArtifact_1.RTTI_RelatedArtifact),
            type: EnumType_1.createEnumType(EvidenceVariableTypeKind, 'EvidenceVariableTypeKind'),
            _type: RTTI_Element_1.RTTI_Element
        })
    ]);
});
