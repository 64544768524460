import {Button, Modal, Table} from 'antd';
import React, {useState} from 'react';
import {FhirUtils} from '../../services/fhir';

export const FormSubmissionModal = (props) => {
  const {onClose, visible, form} = props;
  const [formItem, setFormItem] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);

  const client = FhirUtils.useAxiosClient();

  const processForms = (forms) => {
    const processed: any[] = [];

    for (const form of forms) {
      if (typeof form.value === 'object') {
        for (const subFormName in form.value) {
          processed.push({
            name: form.name + '-' + subFormName,
            value: form.value[subFormName].toString(),
            key: form.name + '-' + subFormName
          });
        }

      } else {
        processed.push({name: form.name, value: form.value.toString(), key: form.name});
      }
    }
    return processed;
  };

  React.useEffect(() => {
    setIsLoading(true);
    client
      .get(`/form-submissions/${form.id}`)
      .then((res) => {
        const data = Object.keys(res.data.fields).map((key) => ({
          name: key,
          value: res.data.fields[key],
        }));
        setFormItem(processForms(data));
      })
      .catch((err) => alert(err.message))
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <div>
      <Modal
        visible={visible}
        onCancel={onClose}
        title={form.formName}
        footer={[
          <Button key="close" type="primary" onClick={onClose}>
            Close
          </Button>,
        ]}
      >
        <Table
          dataSource={formItem}
          loading={isLoading}
          columns={[
            {title: 'Field', dataIndex: 'name', key: 'key'},
            {title: 'Value', dataIndex: 'value', key: 'key'},
          ]}
        />
      </Modal>
    </div>
  );
};
