import {Ids} from '@canimmunize/tools';
import React from 'react';
import {useStr} from '../services/str';

export const MissingView = () => {
  const Str = useStr();

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <div>
        <img src="https://cdn.canimmunize.ca/logo.png" style={{width: 200}} />
      </div>
      <div>
        <div>{Str(Ids.invalid_link)}</div>
      </div>
    </div>
  );
};
