"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_Goal = exports.GoalLifecycleStatusKind = void 0;
var t = __importStar(require("io-ts"));
var RTTI_id_1 = require("../Scalar/RTTI_id");
var RTTI_Meta_1 = require("./RTTI_Meta");
var RTTI_uri_1 = require("../Scalar/RTTI_uri");
var RTTI_Element_1 = require("./RTTI_Element");
var RTTI_code_1 = require("../Scalar/RTTI_code");
var RTTI_Narrative_1 = require("./RTTI_Narrative");
var RTTI_ResourceList_1 = require("../Union/RTTI_ResourceList");
var RTTI_Extension_1 = require("./RTTI_Extension");
var RTTI_Identifier_1 = require("./RTTI_Identifier");
var RTTI_CodeableConcept_1 = require("./RTTI_CodeableConcept");
var RTTI_Reference_1 = require("./RTTI_Reference");
var RTTI_Goal_Target_1 = require("./RTTI_Goal_Target");
var RTTI_date_1 = require("../Scalar/RTTI_date");
var RTTI_Annotation_1 = require("./RTTI_Annotation");
var GoalLifecycleStatusKind;
(function (GoalLifecycleStatusKind) {
    GoalLifecycleStatusKind["_proposed"] = "proposed";
    GoalLifecycleStatusKind["_planned"] = "planned";
    GoalLifecycleStatusKind["_accepted"] = "accepted";
    GoalLifecycleStatusKind["_active"] = "active";
    GoalLifecycleStatusKind["_onHold"] = "on-hold";
    GoalLifecycleStatusKind["_completed"] = "completed";
    GoalLifecycleStatusKind["_cancelled"] = "cancelled";
    GoalLifecycleStatusKind["_enteredInError"] = "entered-in-error";
    GoalLifecycleStatusKind["_rejected"] = "rejected";
})(GoalLifecycleStatusKind = exports.GoalLifecycleStatusKind || (exports.GoalLifecycleStatusKind = {}));
var EnumType_1 = require("../../EnumType");
exports.RTTI_Goal = t.recursion('IGoal', function () {
    return t.intersection([
        t.type({
            resourceType: t.literal('Goal'),
            description: RTTI_CodeableConcept_1.RTTI_CodeableConcept,
            subject: RTTI_Reference_1.RTTI_Reference
        }),
        t.partial({
            id: RTTI_id_1.RTTI_id,
            meta: RTTI_Meta_1.RTTI_Meta,
            implicitRules: RTTI_uri_1.RTTI_uri,
            _implicitRules: RTTI_Element_1.RTTI_Element,
            language: RTTI_code_1.RTTI_code,
            _language: RTTI_Element_1.RTTI_Element,
            text: RTTI_Narrative_1.RTTI_Narrative,
            contained: t.array(RTTI_ResourceList_1.RTTI_ResourceList),
            extension: t.array(RTTI_Extension_1.RTTI_Extension),
            modifierExtension: t.array(RTTI_Extension_1.RTTI_Extension),
            identifier: t.array(RTTI_Identifier_1.RTTI_Identifier),
            lifecycleStatus: EnumType_1.createEnumType(GoalLifecycleStatusKind, 'GoalLifecycleStatusKind'),
            _lifecycleStatus: RTTI_Element_1.RTTI_Element,
            achievementStatus: RTTI_CodeableConcept_1.RTTI_CodeableConcept,
            category: t.array(RTTI_CodeableConcept_1.RTTI_CodeableConcept),
            priority: RTTI_CodeableConcept_1.RTTI_CodeableConcept,
            startDate: t.string,
            _startDate: RTTI_Element_1.RTTI_Element,
            startCodeableConcept: RTTI_CodeableConcept_1.RTTI_CodeableConcept,
            target: t.array(RTTI_Goal_Target_1.RTTI_Goal_Target),
            statusDate: RTTI_date_1.RTTI_date,
            _statusDate: RTTI_Element_1.RTTI_Element,
            statusReason: t.string,
            _statusReason: RTTI_Element_1.RTTI_Element,
            expressedBy: RTTI_Reference_1.RTTI_Reference,
            addresses: t.array(RTTI_Reference_1.RTTI_Reference),
            note: t.array(RTTI_Annotation_1.RTTI_Annotation),
            outcomeCode: t.array(RTTI_CodeableConcept_1.RTTI_CodeableConcept),
            outcomeReference: t.array(RTTI_Reference_1.RTTI_Reference)
        })
    ]);
});
