"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_Appointment = exports.AppointmentStatusKind = void 0;
var t = __importStar(require("io-ts"));
var RTTI_id_1 = require("../Scalar/RTTI_id");
var RTTI_Meta_1 = require("./RTTI_Meta");
var RTTI_uri_1 = require("../Scalar/RTTI_uri");
var RTTI_Element_1 = require("./RTTI_Element");
var RTTI_code_1 = require("../Scalar/RTTI_code");
var RTTI_Narrative_1 = require("./RTTI_Narrative");
var RTTI_ResourceList_1 = require("../Union/RTTI_ResourceList");
var RTTI_Extension_1 = require("./RTTI_Extension");
var RTTI_Identifier_1 = require("./RTTI_Identifier");
var RTTI_CodeableConcept_1 = require("./RTTI_CodeableConcept");
var RTTI_Reference_1 = require("./RTTI_Reference");
var RTTI_unsignedInt_1 = require("../Scalar/RTTI_unsignedInt");
var RTTI_instant_1 = require("../Scalar/RTTI_instant");
var RTTI_positiveInt_1 = require("../Scalar/RTTI_positiveInt");
var RTTI_dateTime_1 = require("../Scalar/RTTI_dateTime");
var RTTI_Appointment_Participant_1 = require("./RTTI_Appointment_Participant");
var RTTI_Period_1 = require("./RTTI_Period");
var AppointmentStatusKind;
(function (AppointmentStatusKind) {
    AppointmentStatusKind["_proposed"] = "proposed";
    AppointmentStatusKind["_pending"] = "pending";
    AppointmentStatusKind["_booked"] = "booked";
    AppointmentStatusKind["_arrived"] = "arrived";
    AppointmentStatusKind["_fulfilled"] = "fulfilled";
    AppointmentStatusKind["_cancelled"] = "cancelled";
    AppointmentStatusKind["_noshow"] = "noshow";
    AppointmentStatusKind["_enteredInError"] = "entered-in-error";
    AppointmentStatusKind["_checkedIn"] = "checked-in";
    AppointmentStatusKind["_waitlist"] = "waitlist";
})(AppointmentStatusKind = exports.AppointmentStatusKind || (exports.AppointmentStatusKind = {}));
var EnumType_1 = require("../../EnumType");
exports.RTTI_Appointment = t.recursion('IAppointment', function () {
    return t.intersection([
        t.type({
            resourceType: t.literal('Appointment'),
            participant: t.array(RTTI_Appointment_Participant_1.RTTI_Appointment_Participant)
        }),
        t.partial({
            id: RTTI_id_1.RTTI_id,
            meta: RTTI_Meta_1.RTTI_Meta,
            implicitRules: RTTI_uri_1.RTTI_uri,
            _implicitRules: RTTI_Element_1.RTTI_Element,
            language: RTTI_code_1.RTTI_code,
            _language: RTTI_Element_1.RTTI_Element,
            text: RTTI_Narrative_1.RTTI_Narrative,
            contained: t.array(RTTI_ResourceList_1.RTTI_ResourceList),
            extension: t.array(RTTI_Extension_1.RTTI_Extension),
            modifierExtension: t.array(RTTI_Extension_1.RTTI_Extension),
            identifier: t.array(RTTI_Identifier_1.RTTI_Identifier),
            status: EnumType_1.createEnumType(AppointmentStatusKind, 'AppointmentStatusKind'),
            _status: RTTI_Element_1.RTTI_Element,
            cancelationReason: RTTI_CodeableConcept_1.RTTI_CodeableConcept,
            serviceCategory: t.array(RTTI_CodeableConcept_1.RTTI_CodeableConcept),
            serviceType: t.array(RTTI_CodeableConcept_1.RTTI_CodeableConcept),
            specialty: t.array(RTTI_CodeableConcept_1.RTTI_CodeableConcept),
            appointmentType: RTTI_CodeableConcept_1.RTTI_CodeableConcept,
            reasonCode: t.array(RTTI_CodeableConcept_1.RTTI_CodeableConcept),
            reasonReference: t.array(RTTI_Reference_1.RTTI_Reference),
            priority: RTTI_unsignedInt_1.RTTI_unsignedInt,
            _priority: RTTI_Element_1.RTTI_Element,
            description: t.string,
            _description: RTTI_Element_1.RTTI_Element,
            supportingInformation: t.array(RTTI_Reference_1.RTTI_Reference),
            start: RTTI_instant_1.RTTI_instant,
            _start: RTTI_Element_1.RTTI_Element,
            end: RTTI_instant_1.RTTI_instant,
            _end: RTTI_Element_1.RTTI_Element,
            minutesDuration: RTTI_positiveInt_1.RTTI_positiveInt,
            _minutesDuration: RTTI_Element_1.RTTI_Element,
            slot: t.array(RTTI_Reference_1.RTTI_Reference),
            created: RTTI_dateTime_1.RTTI_dateTime,
            _created: RTTI_Element_1.RTTI_Element,
            comment: t.string,
            _comment: RTTI_Element_1.RTTI_Element,
            patientInstruction: t.string,
            _patientInstruction: RTTI_Element_1.RTTI_Element,
            basedOn: t.array(RTTI_Reference_1.RTTI_Reference),
            requestedPeriod: t.array(RTTI_Period_1.RTTI_Period)
        })
    ]);
});
