import {
  PhoneValidationRules,
  RequiredLongTextValidationRules,
  RequiredShortTextValidationRules,
  RequiredSpecialCharsValidationRules,
} from '../../services/ui-validation-rules';
import {getEnumValues} from '../../util/enum-util';
import {EnumValidationRuleId} from '../../validation/validation-rules/enum-validation';
import {FormUXFieldType} from '../form-ux';
import {FormUXModel} from '../form-ux/form-ux-models/form-ux-model';

export enum APITokenCustomFieldName {
  OrganizationId = 'organizationId',
}
export const APITokenUXModel: FormUXModel = [
  {
    name: 'description',
    type: FormUXFieldType.text,
    label: 'Description',
    inCreateModal: true,
    editable: true,
    validationRules: [],
  },
  {
    name: 'token',
    type: FormUXFieldType.text,
    label: 'Token',
    inCreateModal: false,
    editable: true,
    validationRules: [],
  },
  {
    name: APITokenCustomFieldName.OrganizationId,
    type: FormUXFieldType.custom,
    label: 'Organization',
    editable: true,
    validationRules: [
      {
        validationRuleType: 'required',
      },
    ],
    inCreateModal: true,
  },
];
