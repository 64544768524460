"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_ValueSet_Filter = exports.ValueSet_FilterOpKind = void 0;
var t = __importStar(require("io-ts"));
var RTTI_Extension_1 = require("./RTTI_Extension");
var RTTI_code_1 = require("../Scalar/RTTI_code");
var RTTI_Element_1 = require("./RTTI_Element");
var ValueSet_FilterOpKind;
(function (ValueSet_FilterOpKind) {
    ValueSet_FilterOpKind["_equal"] = "Equal";
    ValueSet_FilterOpKind["_isA"] = "is-a";
    ValueSet_FilterOpKind["_descendentOf"] = "descendent-of";
    ValueSet_FilterOpKind["_isNotA"] = "is-not-a";
    ValueSet_FilterOpKind["_regex"] = "regex";
    ValueSet_FilterOpKind["_in"] = "in";
    ValueSet_FilterOpKind["_notIn"] = "not-in";
    ValueSet_FilterOpKind["_generalizes"] = "generalizes";
    ValueSet_FilterOpKind["_exists"] = "exists";
})(ValueSet_FilterOpKind = exports.ValueSet_FilterOpKind || (exports.ValueSet_FilterOpKind = {}));
var EnumType_1 = require("../../EnumType");
exports.RTTI_ValueSet_Filter = t.recursion('IValueSet_Filter', function () {
    return t.partial({
        id: t.string,
        extension: t.array(RTTI_Extension_1.RTTI_Extension),
        modifierExtension: t.array(RTTI_Extension_1.RTTI_Extension),
        property: RTTI_code_1.RTTI_code,
        _property: RTTI_Element_1.RTTI_Element,
        op: EnumType_1.createEnumType(ValueSet_FilterOpKind, 'ValueSet_FilterOpKind'),
        _op: RTTI_Element_1.RTTI_Element,
        value: t.string,
        _value: RTTI_Element_1.RTTI_Element
    });
});
