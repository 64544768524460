//HCN Validation based on Yukon Document
import {AbstractValidationRule} from './abstract-validation-rule';
import _ from 'lodash';
import {Validation} from '@canimmunize/tools';

export const HcnValidationRuleId = 'hcn';

export interface HcnValidationRule extends AbstractValidationRule {
  validationRuleType: typeof HcnValidationRuleId;
}

/**
 * Validation to check if the HCN is a valid number based on the province (hcnType)
 *
 * @export
 * @param {*} value
 * @returns {boolean} True if validation passed. False otherwise.
 */
export function validateHcnRule(hcn: any, hcnType: any): boolean {
  if (!hcn && !hcnType) return true;

  const result = validateHcn(hcn, hcnType);
  if (result === null) return true;

  return false;
}

/**
 * Newfoundland and Labrador
 * 12 digit numeric with MOD 10
 */
const validateHcnNL = (hcn: string): null | Error => {
  if (validateHcnNumeric(hcn, 12) !== null) return new Error('Invalid Health Card Number');
  return validateMod(hcn, 12);
};

/**
 * New Brunswick
 * 9 digit numeric with MOD 10
 */
export const validateHcnNB = (hcn: string): null | Error => {
  if (validateHcnNumeric(hcn, 9) !== null) return new Error('Invalid Health Card Number');
  return validateMod(hcn, 9, true);
};

/**
 * Nova Scotia
 * 10 digit numeric with MOD 10
 */
export const validateHcnNS = (hcn: string): null | Error => {
  if (validateHcnNumeric(hcn, 10) !== null) return new Error('Invalid Health Card Number');
  return validateMod(hcn, 10);
};

/**
 * Ontario
 * 10 digit numeric (with optional 2 alpha/numeric at end) with MOD 10
 */
export const validateHcnON = (hcn: string): null | Error => {
  //Accounts for optional 2 alpha characters at end of hcn
  if (hcn.slice(-2).match(/^[A-Z]{2}$/)) {
    hcn = hcn.slice(0, -2);
  }

  if (validateHcnNumeric(hcn, 10) !== null) return new Error('Invalid Health Card Number');
  return validateMod(hcn, 10);
};

/**
 * Quebec
 * Positions 1-4 must be A-Z, 5-10 and 12 must be numeric, 11 can be alpha/numeric
 * ? NOTE - currently allowing lowercase
 */
export const validateHcnQC = (hcn: string): null | Error => {
  if (hcn.match(/^[a-zA-Z]{4}[0-9]{6}[a-zA-Z0-9][0-9]$/)) {
    return null;
  }
  return new Error('Invalid Health Card Number');
};

/**
 * Manitoba
 * TODO - the Yukon document is ambigious in terms of validation for MB.
 */
const validateHcnMB = (hcn: string): null | Error => {
  return validateHcnNumeric(hcn, 9);
};

/**
 * PEI
 * Either a 9-digit numeric MOD 10 | 8-digit numeric following the steps:
 * 1.    Extract odd positioned numbers and reverse order, multiply by 2
 * 2.    Extract even position numbers (except 8th digit) and reverse order, prepend to result from step 1
 * 3.    Sum the individual digits - take the mod 10 and subtract value from 10
 * 4.    Compare result from step 3 (value of 10 = 0) to check digit (8th digit)
 */
export const validateHcnPE = (hcn: string): null | Error => {
  //Prior to June 1st, 1997
  if (hcn.length === 9) {
    if (validateHcnNumeric(hcn, 9) !== null) return new Error('Invalid Health Card Number');
    return validateMod(hcn, 9, true);
  }

  //Post May 1st, 1996
  if (validateHcnNumeric(hcn, 8) !== null) return new Error('Invalid Health Card Number');

  // Get an int array representation of the String
  const digits = hcn.split('').map((char) => parseInt(char));

  const oddSum = parseInt('' + digits[6] + digits[4] + digits[2] + digits[0]) * 2;
  const evenSum = '' + digits[5] + digits[3] + digits[1];
  const sum = '' + evenSum + oddSum;

  const sumDigits = sum.split('').reduce((sum, digit) => {
    return sum + parseInt(digit);
  }, 0);

  const checkSum = sumDigits % 10 === 0 ? 0 : 10 - (sumDigits % 10);

  return digits[7] === checkSum ? null : new Error('Invalid Health Card Number');
};

/**
 * Yukon
 * 1.    Double the even digits.
 * 2.    Add up all the numbers from step 1 but treat double digits as two
 *       separate digits: 6 + 0 + 1 + 4 + 0 + 2 + 1 + (1 + 8) + 7 = 30
 * 3.    Divide the result from step 3 by 10: 30 / 10 = 3
 * 4.    If the result in step 3 is a whole number (no decimals) then the
 *       example is valid
 * @param hcn
 */
export const validateHcnYT = (hcn: string): null | Error => {
  return null;
  // Get an int array representation of the String
  const digits = hcn.split('').map((char) => parseInt(char));

  const multipliers = [1, 2, 1, 2, 1, 2, 1, 2, 1];

  const multipliedDigits = digits.map((d, i) => d * multipliers[i]);

  const sum = multipliedDigits.reduce((sum, digit) => {
    return sum + _.sum(`${digit}`.split('').map((char) => parseInt(char)));
  }, 0);

  return Number.isInteger(sum / 10) ? null : new Error('Invalid Health Card Number');
};

/**
 * Saskatchewan
 * 1.    9 digit numeric
 * 2.    Multiply each digit by the multipliers [9,8,7,6,5,4,3,2,1]
 * 3.    Sum results from step 2 and divide by 11
 * 4.    Valid would have no remainder
 */
export const validateHcnSK = (hcn: string): null | Error => {
  if (validateHcnNumeric(hcn, 9) !== null) return new Error('Invalid Health Card Number');

  // Get an int array representation of the String
  const digits = hcn.split('').map((char) => parseInt(char));

  const multipliers = [9, 8, 7, 6, 5, 4, 3, 2, 1];

  const multipliedDigits = digits.reduce((sum, digit, index) => {
    return sum + digit * multipliers[index];
  }, 0);

  return multipliedDigits % 11 === 0 ? null : new Error('Invalid Health Card Number');
};

/**
 * Nunavut
 * 1.    9 digit numeric: First digit always 1, 8th digit is check digit, last digit is 2-7
 * 2.    Add digits in odd positions (except check digit)
 * 3.    Multiply digits in even positions by two and get MOD 10 for each, then add them up
 * 4.    Multiply digits in even positions by two and get the floor after dividing by 10 for each, then add them up
 * 5.    Add results from 2-4 and do MOD 10
 * 6.    Subtract result of step 5 from 10, and do MOD 10
 * 7.    Result from step 6 should equal the check digit (last digit)
 */
export const validateHcnNU = (hcn: string): null | Error => {
  if (!hcn.match(/^[1][a-zA-Z0-9]{7}[2-7]$/)) {
    return new Error('Invalid Health Card Number');
  }

  // Get an int array representation of the String
  const digits = hcn.split('').map((char) => parseInt(char));

  const even = [2, 4, 6];

  const oddSum = digits[1] + digits[3] + digits[5];

  const evenModSum = even.reduce((sum, pos) => {
    return sum + ((digits[pos] * 2) % 10);
  }, 0);

  const evenFloorSum = even.reduce((sum, pos) => {
    return sum + _.floor((digits[pos] * 2) / 10);
  }, 0);

  const sum = (10 - ((oddSum + evenModSum + evenFloorSum) % 10)) % 10;

  return digits[7] === sum ? null : new Error('Invalid Health Card Number');
};

/**
 * Northwest Territories
 * 1.    8 character alpha numeric string (1st position is D, H, M, N, or T followed by 7 digits)
 * 2.    Add digits in odd positions (except check digit)
 * 3.    Multiply digits in even positions by two and get MOD 10 for each, then add them up
 * 4.    Multiply digits in even positions by two and get the floor after dividing by 10 for each, then add them up
 * 5.    Add results from 2-4 and do MOD 10
 * 6.    Subtract result of step 5 from 10, and do MOD 10
 * 7.    Result from step 6 should equal the check digit (last digit)
 */
export const validateHcnNT = (hcn: string): null | Error => {
  //Ensure it matches overall format
  if (!hcn.match(/^[NMTDH][0-9]{7}$/)) {
    return new Error('Invalid Health Card Number');
  }

  // Get an int array representation of the String
  const digits = hcn.split('').map((char) => parseInt(char));

  const even = [2, 4, 6];

  const oddSum = digits[1] + digits[3] + digits[5];

  const evenModSum = even.reduce((sum, pos) => {
    return sum + ((digits[pos] * 2) % 10);
  }, 0);

  const evenFloorSum = even.reduce((sum, pos) => {
    return sum + _.floor((digits[pos] * 2) / 10);
  }, 0);

  const sum = (10 - ((oddSum + evenModSum + evenFloorSum) % 10)) % 10;

  return digits[7] === sum ? null : new Error('Invalid Health Card Number');
};

/**
 * Alberta
 * 1.    9 digit number where 5th digit is Check Digit
 * 2.    Determine the base number by removing the check digit
 * 3.    Sum the even-positioned (0, 2, 4...) digits
 * 4.    Use the odd-positioned (1, 3, 5...) digits as the indexes for Table A and sum
 * 5.    Sum steps 4 and 5 and divide by 10
 * 6.    Use the first decimal place digit as the index for Table B
 * 7.    The value from step 6 must match the check digit (5th digit)
 */
export const validateHcnAB = (hcn: string): null | Error => {
  const tableA = [0, 2, 4, 6, 8, 1, 3, 5, 7, 9];
  const tableB = [0, 9, 8, 7, 6, 5, 4, 3, 2, 1];

  // Check for a 9 digit numeric hcn
  if (validateHcnNumeric(hcn, 9) !== null) return new Error('Invalid Health Card Number');

  // Get an int array representation of the String
  const digits = hcn.split('').map((char) => parseInt(char));

  //Calculate the remainder via the algorithm, recall digits[4] is the check digit
  const sum =
    digits[0] +
    digits[2] +
    digits[5] +
    digits[7] +
    tableA[digits[1]] +
    tableA[digits[3]] +
    tableA[digits[6]] +
    tableA[digits[8]];

  const decimalValue = sum < 10 ? sum : parseInt(sum.toString()[1]);

  return digits[4] === tableB[decimalValue] ? null : new Error('Invalid Health Card Number');
};

/**
 * British Columbia
 * 1.    10 digit number always starting with 9
 * 2.    Obtain the weighted results by multiplying the digits positions (except 1 and 10) by [2,4,8,5,10,9,7,3]
 * 3.    Sum the weighted results and get the floor after dividing by 11
 * 4.    Multiply result in step 3 by 11 and subtract that from the sum of weighted results
 * 5.    Subtract the result from step 4 from 11 and should equal the check digit (last digit)
 * TODO - some BC Health Cards start with a 1 (could be a different type of card)
 */
export const validateHcnBC = (hcn: string): null | Error => {
  // Check for a 10 digit numeric HCN starting with 9
  if (!hcn.match(/^[9][0-9]{9}$/)) {
    return new Error('Invalid Health Card Number');
  }

  // Get an int array representation of the String
  const digits = hcn.split('').map((char) => parseInt(char));

  const multipliers = [2, 4, 8, 5, 10, 9, 7, 3];

  const sum = digits.slice(1, 9).reduce((sum, digit, index) => {
    return sum + digit * multipliers[index];
  }, 0);

  const modifiedSum = 11 - (sum - _.floor(sum / 11) * 11);

  return digits[9] === modifiedSum ? null : new Error('Invalid Health Card Number');
};

/**
 * First Nations
 * TODO - We don't yet know what the validation for these look like
 */
const validateHcnFN = (hcn: string): null | Error => {
  const error = Validation.validateFieldLength(hcn, 'Health Card Number', 1, 255);
  return error ? new Error(error.message) : null;
};

/**
 * Default
 * Use to validate non-specific types from out of province
 */
const validateHcnDefault = (hcn: string): null | Error => {
  const error = Validation.validateFieldLength(hcn, 'Health Card Number', 1, 255);
  return error ? new Error(error.message) : null;
};

/*
  =================HELPERS===================
*/

/**
 * Ensures the entire hcn is numeric with the appropriate length
 */
const validateHcnNumeric = (hcn: string, length: number): Error | null => {
  // Make sure it's not empty
  if (hcn.length === 0) {
    return new Error('Invalid Health Card Number');
  }

  // Make sure that it's only numeric
  if (!hcn.match(/[0-9]+/)) {
    return new Error('Invalid Health Card Number');
  }

  // Make sure that it's the correct length
  if (hcn.length !== length) {
    return new Error('Invalid Health Card Number');
  }

  return null;
};

/* Lunh's Algorithm - True if MOD 10. False otherwise*/
const validateMod = (hcn: string, length, doubleOddIndices = false): Error | null => {
  // Get an int array representation of the String
  const digits = hcn.split('').map((char) => parseInt(char));

  // Loop through each digit
  let sum = 0;
  let lastDigit = -1;

  digits.forEach((digit, i) => {
    let currentDigit = digit;

    // For the even indices, double the value
    // Optional tag 'doubleOddIndices' if we want to instead double the odd indices
    if ((!doubleOddIndices && i % 2 === 0) || (doubleOddIndices && i % 2 === 1)) {
      currentDigit *= 2;

      // If the new value is a two digit number, sum its digits
      if (currentDigit > 9) {
        let newDigit = 0;
        while (currentDigit !== 0) {
          newDigit = newDigit + (currentDigit % 10);
          currentDigit = Math.floor(currentDigit / 10);
        }

        currentDigit = newDigit;
      }
    }

    // If this is the last number, store it
    if (i === length - 1) {
      lastDigit = currentDigit;
    } else {
      // If not, add the current digit to the sum
      sum += currentDigit;
    }
  });

  // Multiply the sum by 9
  sum *= 9;

  // Take the mod 10 of the sum
  const mod10 = sum % 10;

  // If the mod 10 is not the same as the last digit, then the OHIP is not valid
  if (mod10 !== lastDigit) {
    return new Error('Invalid Health Card Number');
  }
  return null;
};

//Object containing the validation functions for each province
export const validateHcnProvinceFunctionMap = {
  ON: validateHcnON,
  NS: validateHcnNS,
  NL: validateHcnNL,
  PE: validateHcnPE,
  NB: validateHcnNB,
  YT: validateHcnYT,
  MB: validateHcnMB,
  SK: validateHcnSK,
  AB: validateHcnAB,
  BC: validateHcnBC,
  QC: validateHcnQC,
  NT: validateHcnNT,
  NU: validateHcnNU,
  FN: validateHcnFN,
  INTSTUD: validateHcnDefault,
  CORRECT: validateHcnDefault,
  RCMP: validateHcnDefault,
  CDNFORCES: validateHcnDefault,
  VA: validateHcnDefault,
  OUTCAN: validateHcnDefault,
  OTHER: validateHcnDefault,
};

export const validateHcn = (hcn: string, hcnType: string) => {
  if (!hcn || hcn.length === 0) return new Error('Invalid Health Card Number');
  if (!hcnType || hcnType.length === 0) return new Error('Invalid Health Card Number Type');

  const validationFn = validateHcnProvinceFunctionMap[hcnType.toUpperCase()];

  if (!validationFn) return new Error('Invalid Health Card Number Type');

  return validationFn(hcn);
};
