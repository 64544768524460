"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_AuditEvent_Agent = void 0;
var t = __importStar(require("io-ts"));
var RTTI_Extension_1 = require("./RTTI_Extension");
var RTTI_CodeableConcept_1 = require("./RTTI_CodeableConcept");
var RTTI_Reference_1 = require("./RTTI_Reference");
var RTTI_Element_1 = require("./RTTI_Element");
var RTTI_uri_1 = require("../Scalar/RTTI_uri");
var RTTI_Coding_1 = require("./RTTI_Coding");
var RTTI_AuditEvent_Network_1 = require("./RTTI_AuditEvent_Network");
exports.RTTI_AuditEvent_Agent = t.recursion('IAuditEvent_Agent', function () {
    return t.partial({
        id: t.string,
        extension: t.array(RTTI_Extension_1.RTTI_Extension),
        modifierExtension: t.array(RTTI_Extension_1.RTTI_Extension),
        type: RTTI_CodeableConcept_1.RTTI_CodeableConcept,
        role: t.array(RTTI_CodeableConcept_1.RTTI_CodeableConcept),
        who: RTTI_Reference_1.RTTI_Reference,
        altId: t.string,
        _altId: RTTI_Element_1.RTTI_Element,
        name: t.string,
        _name: RTTI_Element_1.RTTI_Element,
        requestor: t.boolean,
        _requestor: RTTI_Element_1.RTTI_Element,
        location: RTTI_Reference_1.RTTI_Reference,
        policy: t.array(RTTI_uri_1.RTTI_uri),
        _policy: t.array(RTTI_Element_1.RTTI_Element),
        media: RTTI_Coding_1.RTTI_Coding,
        network: RTTI_AuditEvent_Network_1.RTTI_AuditEvent_Network,
        purposeOfUse: t.array(RTTI_CodeableConcept_1.RTTI_CodeableConcept)
    });
});
