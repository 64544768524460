import {CVC} from '@canimmunize/cvc-js';
import {Form, Select} from 'formik-antd';
import React from 'react';
import {useCVCRoutes} from '../../../models/routes';
import {useCVCSites} from '../../../models/sites';
import {Tradename, useTradenames} from '../../../models/tradenames';
import {RequiredValidationRuleId} from '../../../validation/validation-rules/required-validation';
import {ValidateFormItem} from '../../validate-form-item';
import {doseUOM} from './imms-form-fields';

export const ConceptFormItem = (props) => {
  const {name, label, validationRules, editable, picklistTerm, form, conceptDataIndex} = props;

  const cvc = CVC.get() || {};

  const tradenames = useTradenames();
  const covidVaccineConceptId = [
    '28571000087109', // moderna
    '28581000087106', // pfizer/biontech
    '28761000087108', // astrazeneca
    '28961000087105', // covishield
    '28951000087107', // janssen
    '29171000087106', // novavax
    '33361000087101', // pfizer ped
    '35651000087105', // moderna (infant)
    '37311000087109', // moderna bivalent
  ];

  const routes = useCVCRoutes();
  const sites = useCVCSites();

  let conceptData: any[] = [];
  if (conceptDataIndex) {
    switch (conceptDataIndex) {
      case 'tradenames':
        conceptData = tradenames;
        break;
      case 'routeOfAdmin':
        conceptData = routes;
        break;
      case 'anatomicalSite':
        conceptData = sites.sort(
          customSortWithPredefined(picklistTerm, ['Left deltoid', 'Right deltoid'])
        );
        break;
      default:
        conceptData = cvc[conceptDataIndex] || [];
    }
  }

  //Handle changing vaccine concept. Reset other fields related to vaccine when vaccine concept is changed.
  const handleVaccineConceptChange = (conceptId: string, tradenames: Tradename[]) => {
    const vaccineConcept = tradenames.find((concept) => concept.conceptId === conceptId);
    form.setFieldValue('concept', vaccineConcept);
    const {typicalDoseSize, typicalDoseSizeUOM} = vaccineConcept || {};
    if (typicalDoseSize && typicalDoseSizeUOM) {
      const dosageUom = doseUOM.find(
        (item) => item.key.toLowerCase() === typicalDoseSizeUOM.toLowerCase()
      );
      form.setFieldValue('dosage', typicalDoseSize ? typicalDoseSize : '');
      form.setFieldValue('dosageUom', dosageUom ? dosageUom.key : '');
    }
    if (covidVaccineConceptId.includes(conceptId)) {
      form.setFieldValue('route', '78421000'); // pre-populate to 'IM: Intramuscular' for COVID vaccine
    } else {
      form.setFieldValue('route', '');
    }
  };

  const required =
    validationRules.find((rule) => rule.validationRuleType === RequiredValidationRuleId) !==
    undefined;

  return (
    <ValidateFormItem
      validationRules={validationRules || []}
      renderFormItem={(validate) => {
        return (
          <Form.Item
            name={name}
            label={<label style={{height: '0px'}}>{label}</label>}
            validate={validate}
            required={required}
            tooltip={
              required
                ? {
                    icon: <span style={{color: 'red', fontSize: 14, fontWeight: 'bolder'}}>*</span>,
                  }
                : undefined
            }
          >
            <Select
              name={name}
              disabled={form.values.deleted || !editable}
              size="middle"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(a: any, b: any) => {
                if (a?.children.toLowerCase() > b?.children.toLowerCase()) return 1;
                return -1;
              }}
              // disabled={isDisabled}
              value={form.values[name]}
              onChange={(conceptId) => {
                form.setFieldValue(name, conceptId);
                if (name === 'conceptId') {
                  handleVaccineConceptChange(conceptId, conceptData);
                }
              }}
            >
              {conceptData.map((concept, key) => (
                <Select.Option key={`${concept}-${key}`} value={concept.conceptId}>
                  {picklistTerm && concept[picklistTerm]
                    ? concept[picklistTerm].en
                    : concept.conceptId}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        );
      }}
    />
  );
};

/**
 * Custom sort function to sort by forced prior items
 * @param picklistTerm Object key for sorting
 * @param predefined String array, which contains priority
 * @returns
 */
const customSortWithPredefined = (picklistTerm: string, predefined: string[]) => {
  return function sort(a, b) {
    const indexA = predefined?.indexOf(a[picklistTerm].en);
    const indexB = predefined?.indexOf(b[picklistTerm].en);

    if (indexA < indexB) return 1;
    if (indexA > indexB) return -1;

    if (a[picklistTerm].en.toLowerCase() > b[picklistTerm].en.toLowerCase()) return 1;
    return -1;
  };
};
