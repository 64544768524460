/**
 * The different days of the week that can be assigned to an availability
 * slot
 *
 * @export
 * @enum {number}
 */
export enum AvailabilitySlotDay {
  Sunday,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
}