import React from 'react';

interface EnvInfo {
  syncUrl: string;
  auth0Audience: string;
  auth0Domain: string;
  auth0ClientId: string;
  supportFrench: boolean;
  supportEmail: string;
  logoUrl: string;
  receiptLogoUrl?: string;
  inactivityLogoutConfig: {maxIdleTime: number; maxExtIdleTime: number; logoutCountdown: number};
  serverName: string;
  app: string;
  stage: string;
  environmentId: string;
  buildNum?: string;
  lang?: string;
}

// The default value of this context is the default envInfo
// This is the value that useEnvInfo will return if the context is accessed outside
// of an EnvProvider
export const EnvContext = React.createContext<EnvInfo>(getEnvInfo());

// This Provider is for overriding the env for stories/tests
export const EnvProvider = (props: {env: EnvInfo; children: React.ReactNode}) => {
  return (
    <EnvContext.Provider value={props.env ?? getEnvInfo()}>{props.children}</EnvContext.Provider>
  );
};

/**
 * Hook for accessing the envInfo
 * @returns
 */
export function useEnvInfo(): EnvInfo {
  return React.useContext(EnvContext);
}

function getEnvInfo(): EnvInfo {
  const inactivityLogoutConfig = {
    maxIdleTime: 20 * 60 * 1000, // 20 minutes (in ms)
    maxExtIdleTime: 10 * 60 * 60 * 1000, // 10 hours (in ms)
    logoutCountdown: 2 * 60 * 1000, // 2 minutes (in ms)
  };

  const requiredEnvVars = [
    'REACT_APP_LOGO_URL',
    'REACT_APP_RECEIPT_LOGO_URL',
    'REACT_APP_SYNC_URL',
    'REACT_APP_AUTH0_AUDIENCE',
    'REACT_APP_AUTH0_DOMAIN',
    'REACT_APP_AUTH0_CLIENT_ID',
    'REACT_APP_SUPPORT_FRENCH',
    'REACT_APP_SUPPORT_EMAIL',
    'REACT_APP_SERVER_NAME',
    'REACT_APP_APP',
    'REACT_APP_ENVIRONMENT',
    'REACT_APP_STAGE',
  ];

  for (const envVar of requiredEnvVars) {
    if (process.env[envVar] === undefined)
      throw new Error(`The following env var is missing: ${envVar}`);
  }

  return {
    logoUrl: process.env.REACT_APP_LOGO_URL!,
    receiptLogoUrl: process.env.REACT_APP_RECEIPT_LOGO_URL,
    syncUrl: process.env.REACT_APP_SYNC_URL!,
    auth0Audience: process.env.REACT_APP_AUTH0_AUDIENCE!,
    auth0Domain: process.env.REACT_APP_AUTH0_DOMAIN!,
    auth0ClientId: process.env.REACT_APP_AUTH0_CLIENT_ID!,
    supportFrench: process.env.REACT_APP_SUPPORT_FRENCH === 'true',
    supportEmail: process.env.REACT_APP_SUPPORT_EMAIL!,
    inactivityLogoutConfig,
    serverName: process.env.REACT_APP_SERVER_NAME!,
    app: process.env.REACT_APP_APP!,
    stage: process.env.REACT_APP_STAGE!,
    environmentId: process.env.REACT_APP_ENVIRONMENT!,
    buildNum: process.env.REACT_APP_BUILD_NUM!,
  };
}
