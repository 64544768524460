export interface IBlockRules {
  id: string;
  startDatetime: Date;
  endDatetime: Date;
  calendarId: string;
}

export const updateOne = async (client, item) => {
  const res = await client.put(`/block-rule/${item.id}`, item);
  return res.data;
};

export const createOne = async (client, item) => {
  const res = await client.post(`/block-rule`, item);
  return res.data;
};

export const deleteOne = async (client, itemId) => {
  const res = await client.delete(`/block-rule/${itemId}`);
  return res.data;
};

export default {
  updateOne,
  createOne,
  deleteOne,
};
