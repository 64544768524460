"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_Address = exports.AddressTypeKind = exports.AddressUseKind = void 0;
var t = __importStar(require("io-ts"));
var RTTI_Extension_1 = require("./RTTI_Extension");
var RTTI_Element_1 = require("./RTTI_Element");
var RTTI_Period_1 = require("./RTTI_Period");
var AddressUseKind;
(function (AddressUseKind) {
    AddressUseKind["_home"] = "home";
    AddressUseKind["_work"] = "work";
    AddressUseKind["_temp"] = "temp";
    AddressUseKind["_old"] = "old";
    AddressUseKind["_billing"] = "billing";
})(AddressUseKind = exports.AddressUseKind || (exports.AddressUseKind = {}));
var AddressTypeKind;
(function (AddressTypeKind) {
    AddressTypeKind["_postal"] = "postal";
    AddressTypeKind["_physical"] = "physical";
    AddressTypeKind["_both"] = "both";
})(AddressTypeKind = exports.AddressTypeKind || (exports.AddressTypeKind = {}));
var EnumType_1 = require("../../EnumType");
exports.RTTI_Address = t.recursion('IAddress', function () {
    return t.partial({
        id: t.string,
        extension: t.array(RTTI_Extension_1.RTTI_Extension),
        use: EnumType_1.createEnumType(AddressUseKind, 'AddressUseKind'),
        _use: RTTI_Element_1.RTTI_Element,
        type: EnumType_1.createEnumType(AddressTypeKind, 'AddressTypeKind'),
        _type: RTTI_Element_1.RTTI_Element,
        text: t.string,
        _text: RTTI_Element_1.RTTI_Element,
        line: t.array(t.string),
        _line: t.array(RTTI_Element_1.RTTI_Element),
        city: t.string,
        _city: RTTI_Element_1.RTTI_Element,
        district: t.string,
        _district: RTTI_Element_1.RTTI_Element,
        state: t.string,
        _state: RTTI_Element_1.RTTI_Element,
        postalCode: t.string,
        _postalCode: RTTI_Element_1.RTTI_Element,
        country: t.string,
        _country: RTTI_Element_1.RTTI_Element,
        period: RTTI_Period_1.RTTI_Period
    });
});
