import React from 'react';
import {StrLang, Ids} from '@canimmunize/tools';
import {Typography} from 'antd';
import {useUrlData} from '../../../components/scheduler/util';
import {Environment} from '../../../util/helpers/environment';

const {Paragraph} = Typography;

export const ReceiptInstructions = () => {
  const {lang} = useUrlData();

  return (
    <>
      <Environment environmentId={['pei', 'novascotia']}>
        <Paragraph style={{fontSize: '18px', marginTop: 10, marginBottom: 10}}>
          {StrLang(lang, Ids.find_summary_below)}
        </Paragraph>
      </Environment>

      <Environment environmentId={['bruyere']}>
        <Paragraph>{StrLang(lang, Ids.bruyere_find_summary_below)}</Paragraph>
      </Environment>

      <Environment environmentId={['pei']}>
        <Paragraph>{StrLang(lang, Ids.pei_copy_and_store)}</Paragraph>
        <Paragraph>{StrLang(lang, Ids.pei_report_error)}</Paragraph>
      </Environment>
    </>
  );
};
