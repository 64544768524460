import {Select, Form} from 'formik-antd';
import React from 'react';
import {BookingPageCategory} from '../../models/booking-pages';
import {getEnumValues} from '../../util/enum-util';
import {ValidationRules} from '../../validation/validation-rules/validation-rules';
import {ValidateFormItem} from '../validate-form-item';

interface BookingPageCategoryPickerFormItemProps {
  label?: string;
  name: string;
  setFieldValue: (name: string, value: any) => void;
  validationRules?: ValidationRules[];
  editable?: any;
  nullable?: boolean;
}

const nullableStyle: React.CSSProperties = {
  color: 'grey',
};

function getLabelForCategory(status: BookingPageCategory): string {
  switch (status) {
    case BookingPageCategory.GROUP: {
      return 'Group';
    }
  }
}

const selectableValues = getEnumValues(BookingPageCategory).map((day) => {
  return {
    key: day,
    label: getLabelForCategory(day as BookingPageCategory),
  };
});

export const BookingPageCategoryPickerFormItem = (
  props: BookingPageCategoryPickerFormItemProps
) => {
  const {setFieldValue} = props;
  let nullString: string; //  Intended not to initialize

  return (
    <ValidateFormItem
      validationRules={props.validationRules || []}
      renderFormItem={(validate) => {
        return (
          <Form.Item name={props.name} label={props.label} validate={validate}>
            <Select
              name={props.name}
              placeholder="Select a category"
              optionLabelProp="label"
              disabled={!props.editable}
              onChange={(value) => {
                setFieldValue(props.name, value);
              }}
            >
              {props.nullable && (
                <Select.Option
                  key={nullString}
                  value={nullString}
                  label={nullString}
                  style={nullableStyle}
                >
                  None
                </Select.Option>
              )}
              {selectableValues?.map((selectableValue) => {
                return (
                  <Select.Option
                    key={selectableValue.key}
                    value={selectableValue.key}
                    label={selectableValue.label}
                  >
                    {selectableValue.label}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        );
      }}
    />
  );
};
