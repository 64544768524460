import moment from 'moment';
import React from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '../../models';
import List from '../../models/list';
import {BaseEntityV2, FormConfig} from '../base-entity/base-entity';
import {FHIRTableProps} from '../fhir-table';
import {FormUX} from '../form-ux';
import {OrganizationPickerFormItem} from '../organization/organization-picker';
import {ListItemPage} from './item-page';
import {ListCustomFieldName, ListUXModel} from './list-form-ux-model';

export const ListUX = () => {
  const globalOrg = useSelector((state: RootState) => state.ui.localOrg);
  const model = List;

  const itemTitleField = 'name';

  const fixedFilters = globalOrg
    ? {
        _organizationId: globalOrg.id,
      }
    : undefined;

  const tableConfig: FHIRTableProps = {
    fhirResource: 'list',
    label: 'List',
    fixedFilters,
    defaultPrimarySearchParam: 'name',
    mode: 'table',
    showCreateButton: true,
    columns: [
      {
        title: 'Name',
        dataIndex: 'name',
        sorter: true,
      },
      {
        title: 'Description',
        dataIndex: 'description',
      },
      {
        dataIndex: 'createdAt',
        title: 'Created',
        render: (_, row) => moment(row.createdAt).format('LLL'),
      },
      {
        title: 'Organization',
        dataIndex: 'organization.nameEn',
        render: (_, resource) => resource.organization?.name.en,
        sorter: true,
      },
      {
        title: 'Patients',
        dataIndex: 'itemCount',
      },
    ],
  };

  const ListForm = (props) => {
    return (
      <FormUX
        formUXModel={ListUXModel}
        createMode={props.createMode}
        modal={props.modal}
        renderCustomField={(field) => {
          switch (field.name) {
            case ListCustomFieldName.OrganizationId: {
              return (
                <OrganizationPickerFormItem
                  label={field.label}
                  name={field.name}
                  setFieldValue={props.setFieldValue}
                  validationRules={field.validationRules}
                />
              );
            }
            default: {
              throw new Error(`Unhandled custom field ${field.name} in
              renderCustomField method`);
            }
          }
        }}
      />
    );
  };

  const formConfig: FormConfig = {
    defaultValues: {},
    FormItems: ListForm,
  };

  const config = {
    slug: 'list',
    model,
    formConfig,
    itemTitleField,
    itemPage: ListItemPage,
    searchPageTitle: 'Lists',
    fhirResource: 'list',
    tableConfig,
    modalCreateForm: true,
    modalEditForm: true,
    hideDeleteButton: true,
    titleBarEntityLabel: 'List',
  };

  return <BaseEntityV2 {...config} />;
};
