import {createSlice} from '@reduxjs/toolkit';
import {useSelector} from 'react-redux';
import {RootState, store} from '../store';

interface UISearchTermPayload {
  value: string;
  key: string;
  options?: any;
}

export interface UITempSliceInterface {
  searchTerms: {
    [key: string]: {
      value: string;
      options?: any;
    };
  };
}

const initialState = () => {
  return {searchTerms: {}};
};

const slice = createSlice({
  name: 'uiTemp',
  initialState: initialState(),
  reducers: {
    SET_SEARCH_TERM: (state: UITempSliceInterface, action: {payload: UISearchTermPayload}) => {
      const {value, key, options} = action.payload;
      state.searchTerms[key] = {
        value,
        options,
      };
    },
    DELETE_SEARCH_TERM: (state: UITempSliceInterface, action) => {
      delete state.searchTerms[action.payload.resource];
    },
  },
});

export default {
  slice,
};

export const trimPath = (path: string) => (path.indexOf('/') === 0 ? path.split('/')[1] : path);

export const persistSave = (dispatch, key: string, value: string, options?) => {
  dispatch(
    slice.actions.SET_SEARCH_TERM({
      value,
      key,
      options,
    })
  );
};

export const getSearchTerm = (key: string) => store.getState().uiTemp.searchTerms[key];
