/**
 * Get values of an enum
 *
 * @export
 * @param {(Record<string, number | string>)} target
 * @returns {(Array<string | number>)}
 */
export function getEnumValues(target: Record<string, number | string>): Array<string | number> {
  const keys = getEnumKeys(target);
  const values: Array<string | number> = [];

  for (const key of keys) {
    values.push(target[key]);
  }

  return values;
}

/**
 * Get the keys in an enum
 *
 * @param {(Record<string, number | string>)} target
 * @returns {string[]}
 */
function getEnumKeys(target: Record<string, number | string>): string[] {
  const allKeys = Object.keys(target);
  const parsedKeys: string[] = [];

  for (const key of allKeys) {
    const needToIgnore = target[target[key]]?.toString() === key && !isNaN(parseInt(key));

    if (!needToIgnore) {
      parsedKeys.push(key);
    }
  }

  return parsedKeys;
}
