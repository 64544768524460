"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_CapabilityStatement_Resource = exports.CapabilityStatement_ResourceConditionalDeleteKind = exports.CapabilityStatement_ResourceConditionalReadKind = exports.CapabilityStatement_ResourceVersioningKind = exports.CapabilityStatement_ResourceReferencePolicyKind = void 0;
var t = __importStar(require("io-ts"));
var RTTI_Extension_1 = require("./RTTI_Extension");
var RTTI_code_1 = require("../Scalar/RTTI_code");
var RTTI_Element_1 = require("./RTTI_Element");
var RTTI_canonical_1 = require("../Scalar/RTTI_canonical");
var RTTI_markdown_1 = require("../Scalar/RTTI_markdown");
var RTTI_CapabilityStatement_Interaction_1 = require("./RTTI_CapabilityStatement_Interaction");
var RTTI_CapabilityStatement_SearchParam_1 = require("./RTTI_CapabilityStatement_SearchParam");
var RTTI_CapabilityStatement_Operation_1 = require("./RTTI_CapabilityStatement_Operation");
var CapabilityStatement_ResourceReferencePolicyKind;
(function (CapabilityStatement_ResourceReferencePolicyKind) {
    CapabilityStatement_ResourceReferencePolicyKind["_literal"] = "literal";
    CapabilityStatement_ResourceReferencePolicyKind["_logical"] = "logical";
    CapabilityStatement_ResourceReferencePolicyKind["_resolves"] = "resolves";
    CapabilityStatement_ResourceReferencePolicyKind["_enforced"] = "enforced";
    CapabilityStatement_ResourceReferencePolicyKind["_local"] = "local";
})(CapabilityStatement_ResourceReferencePolicyKind = exports.CapabilityStatement_ResourceReferencePolicyKind || (exports.CapabilityStatement_ResourceReferencePolicyKind = {}));
var CapabilityStatement_ResourceVersioningKind;
(function (CapabilityStatement_ResourceVersioningKind) {
    CapabilityStatement_ResourceVersioningKind["_noVersion"] = "no-version";
    CapabilityStatement_ResourceVersioningKind["_versioned"] = "versioned";
    CapabilityStatement_ResourceVersioningKind["_versionedUpdate"] = "versioned-update";
})(CapabilityStatement_ResourceVersioningKind = exports.CapabilityStatement_ResourceVersioningKind || (exports.CapabilityStatement_ResourceVersioningKind = {}));
var CapabilityStatement_ResourceConditionalReadKind;
(function (CapabilityStatement_ResourceConditionalReadKind) {
    CapabilityStatement_ResourceConditionalReadKind["_notSupported"] = "not-supported";
    CapabilityStatement_ResourceConditionalReadKind["_modifiedSince"] = "modified-since";
    CapabilityStatement_ResourceConditionalReadKind["_notMatch"] = "not-match";
    CapabilityStatement_ResourceConditionalReadKind["_fullSupport"] = "full-support";
})(CapabilityStatement_ResourceConditionalReadKind = exports.CapabilityStatement_ResourceConditionalReadKind || (exports.CapabilityStatement_ResourceConditionalReadKind = {}));
var CapabilityStatement_ResourceConditionalDeleteKind;
(function (CapabilityStatement_ResourceConditionalDeleteKind) {
    CapabilityStatement_ResourceConditionalDeleteKind["_notSupported"] = "not-supported";
    CapabilityStatement_ResourceConditionalDeleteKind["_single"] = "single";
    CapabilityStatement_ResourceConditionalDeleteKind["_multiple"] = "multiple";
})(CapabilityStatement_ResourceConditionalDeleteKind = exports.CapabilityStatement_ResourceConditionalDeleteKind || (exports.CapabilityStatement_ResourceConditionalDeleteKind = {}));
var EnumType_1 = require("../../EnumType");
exports.RTTI_CapabilityStatement_Resource = t.recursion('ICapabilityStatement_Resource', function () {
    return t.partial({
        id: t.string,
        extension: t.array(RTTI_Extension_1.RTTI_Extension),
        modifierExtension: t.array(RTTI_Extension_1.RTTI_Extension),
        type: RTTI_code_1.RTTI_code,
        _type: RTTI_Element_1.RTTI_Element,
        profile: RTTI_canonical_1.RTTI_canonical,
        supportedProfile: t.array(RTTI_canonical_1.RTTI_canonical),
        documentation: RTTI_markdown_1.RTTI_markdown,
        _documentation: RTTI_Element_1.RTTI_Element,
        interaction: t.array(RTTI_CapabilityStatement_Interaction_1.RTTI_CapabilityStatement_Interaction),
        versioning: EnumType_1.createEnumType(CapabilityStatement_ResourceVersioningKind, 'CapabilityStatement_ResourceVersioningKind'),
        _versioning: RTTI_Element_1.RTTI_Element,
        readHistory: t.boolean,
        _readHistory: RTTI_Element_1.RTTI_Element,
        updateCreate: t.boolean,
        _updateCreate: RTTI_Element_1.RTTI_Element,
        conditionalCreate: t.boolean,
        _conditionalCreate: RTTI_Element_1.RTTI_Element,
        conditionalRead: EnumType_1.createEnumType(CapabilityStatement_ResourceConditionalReadKind, 'CapabilityStatement_ResourceConditionalReadKind'),
        _conditionalRead: RTTI_Element_1.RTTI_Element,
        conditionalUpdate: t.boolean,
        _conditionalUpdate: RTTI_Element_1.RTTI_Element,
        conditionalDelete: EnumType_1.createEnumType(CapabilityStatement_ResourceConditionalDeleteKind, 'CapabilityStatement_ResourceConditionalDeleteKind'),
        _conditionalDelete: RTTI_Element_1.RTTI_Element,
        referencePolicy: t.array(EnumType_1.createEnumType(CapabilityStatement_ResourceReferencePolicyKind, 'CapabilityStatement_ResourceReferencePolicyKind')),
        _referencePolicy: t.array(RTTI_Element_1.RTTI_Element),
        searchInclude: t.array(t.string),
        _searchInclude: t.array(RTTI_Element_1.RTTI_Element),
        searchRevInclude: t.array(t.string),
        _searchRevInclude: t.array(RTTI_Element_1.RTTI_Element),
        searchParam: t.array(RTTI_CapabilityStatement_SearchParam_1.RTTI_CapabilityStatement_SearchParam),
        operation: t.array(RTTI_CapabilityStatement_Operation_1.RTTI_CapabilityStatement_Operation)
    });
});
