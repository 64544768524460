import {Row, Col} from 'antd';
import React from 'react';
import {CardBody, CardSubtitle, CardTitle} from 'reactstrap';
import {BFCard} from '../booking-form/card';
import {PageTemplate} from '../scheduler-template';
import {useUrlData} from '../util';
import {faCheck, faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// const s = require(`../styles.module.css`);
import s from '../styles.module.css';

export interface MessageCardProps {
  title?: string;
  subtext?: string;
  simpleView?: boolean;
  children?: Element | React.ReactChildren | React.ReactChild;
}
export const MessageCard = ({title, simpleView, subtext = '', children}: MessageCardProps) => {
  const Wrapper = simpleView ? (props) => <>{props.children}</> : PageTemplate;
  return (
    <Wrapper>
      <BFCard>
        <CardBody>
          {children ? (
            children
          ) : (
            <div>
              <h4>
                <div style={{textAlign: 'center'}}>{title}</div>
              </h4>
              {subtext.length ? (
                <div
                  style={{textAlign: 'center'}}
                  dangerouslySetInnerHTML={{
                    __html: subtext,
                  }}
                />
              ) : null}
            </div>
          )}
        </CardBody>
      </BFCard>
    </Wrapper>
  );
};

interface AlertCardProps {
  title: string;
  subtitle?: string;
  color?: string;
  fontColor?: string;
  borderColor?: string;
  actionButtons?: any;
  checkIcon?: string;
}

export const AlertCard = ({
  title,
  subtitle = '',
  color,
  fontColor = '#FFFFFF',
  borderColor,
  actionButtons,
  checkIcon = "false",
}: AlertCardProps) => {
  const {config} = useUrlData();

  if (color === `primary`) color = config.primaryColor;
  if (borderColor === `primary`) borderColor = config.primaryColor;
  if (fontColor === `primary`) fontColor = config.primaryColor;

  return (
    <BFCard
      style={{
        backgroundColor: color || config.primaryColor,
        borderColor: borderColor || color || config.primaryColor,
      }}
    >
      <CardBody>
        <Row className={s.actionButtonsRow}>
          <Col style={{flexShrink: 1}}>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <div style={{marginRight: 10}}>
                <FontAwesomeIcon
                  icon={checkIcon === "true" ? faCheck : faExclamationTriangle}
                  style={{marginRight: 10, fontSize: 24, color: checkIcon === "true" ? '#55D734' : '#FDCB24'}}
                />
              </div>
              <div>
                <CardTitle
                  tag="h5"
                  className={s.appointmentTypeCardTitle}
                  style={{color: fontColor, marginBottom: 10}}
                >
                  {title}
                </CardTitle>
                <CardSubtitle tag="h6" style={{color: fontColor, marginBottom: 0}}>
                  {subtitle}
                </CardSubtitle>
              </div>
            </div>
          </Col>

          <Col
            className={s.actionButtonsContainer}
            style={{
              display: 'flex',
              alignItems: 'center',
              flexGrow: 1,
              flexShrink: 0,
            }}
          >
            {actionButtons}
          </Col>
        </Row>
      </CardBody>
    </BFCard>
  );
};
