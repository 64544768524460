import {Select, Form} from 'formik-antd';
import React from 'react';
import {ValidationRules} from '../../validation/validation-rules/validation-rules';
import {useTradenames} from '../../models/tradenames';
import {ValidateFormItem} from '../validate-form-item';

interface VaccineConceptFormItemProps {
  label?: string;
  name: string;
  setFieldValue: (name: string, value: any) => void;
  validationRules?: ValidationRules[];
}

export const VaccineConceptPickerFormItem = (props: VaccineConceptFormItemProps) => {

  const tradenames = useTradenames();

  return (
    <ValidateFormItem
      validationRules={props.validationRules || []}
      renderFormItem={(validate) => {
        return (
          <Form.Item name={props.name} label={props.label} validate={validate}>
            <Select
              name={props.name}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={(value) => {
                props.setFieldValue(props.name || 'vaccineConceptId', value);
              }}
            >
              {tradenames.map((vaccine) => (
                <Select.Option key={vaccine.conceptId} value={vaccine.conceptId}>
                  {vaccine.clinicianPicklistTermEn}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        );
      }}
    />
  );
};
