"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_SearchParameter = exports.SearchParameterXpathUsageKind = exports.SearchParameterTypeKind = exports.SearchParameterStatusKind = exports.SearchParameterModifierKind = exports.SearchParameterComparatorKind = void 0;
var t = __importStar(require("io-ts"));
var RTTI_id_1 = require("../Scalar/RTTI_id");
var RTTI_Meta_1 = require("./RTTI_Meta");
var RTTI_uri_1 = require("../Scalar/RTTI_uri");
var RTTI_Element_1 = require("./RTTI_Element");
var RTTI_code_1 = require("../Scalar/RTTI_code");
var RTTI_Narrative_1 = require("./RTTI_Narrative");
var RTTI_ResourceList_1 = require("../Union/RTTI_ResourceList");
var RTTI_Extension_1 = require("./RTTI_Extension");
var RTTI_canonical_1 = require("../Scalar/RTTI_canonical");
var RTTI_dateTime_1 = require("../Scalar/RTTI_dateTime");
var RTTI_ContactDetail_1 = require("./RTTI_ContactDetail");
var RTTI_markdown_1 = require("../Scalar/RTTI_markdown");
var RTTI_UsageContext_1 = require("./RTTI_UsageContext");
var RTTI_CodeableConcept_1 = require("./RTTI_CodeableConcept");
var RTTI_SearchParameter_Component_1 = require("./RTTI_SearchParameter_Component");
var SearchParameterComparatorKind;
(function (SearchParameterComparatorKind) {
    SearchParameterComparatorKind["_eq"] = "eq";
    SearchParameterComparatorKind["_ne"] = "ne";
    SearchParameterComparatorKind["_gt"] = "gt";
    SearchParameterComparatorKind["_lt"] = "lt";
    SearchParameterComparatorKind["_ge"] = "ge";
    SearchParameterComparatorKind["_le"] = "le";
    SearchParameterComparatorKind["_sa"] = "sa";
    SearchParameterComparatorKind["_eb"] = "eb";
    SearchParameterComparatorKind["_ap"] = "ap";
})(SearchParameterComparatorKind = exports.SearchParameterComparatorKind || (exports.SearchParameterComparatorKind = {}));
var SearchParameterModifierKind;
(function (SearchParameterModifierKind) {
    SearchParameterModifierKind["_missing"] = "missing";
    SearchParameterModifierKind["_exact"] = "exact";
    SearchParameterModifierKind["_contains"] = "contains";
    SearchParameterModifierKind["_not"] = "not";
    SearchParameterModifierKind["_text"] = "text";
    SearchParameterModifierKind["_in"] = "in";
    SearchParameterModifierKind["_notIn"] = "not-in";
    SearchParameterModifierKind["_below"] = "below";
    SearchParameterModifierKind["_above"] = "above";
    SearchParameterModifierKind["_type"] = "type";
    SearchParameterModifierKind["_identifier"] = "identifier";
    SearchParameterModifierKind["_ofType"] = "ofType";
})(SearchParameterModifierKind = exports.SearchParameterModifierKind || (exports.SearchParameterModifierKind = {}));
var SearchParameterStatusKind;
(function (SearchParameterStatusKind) {
    SearchParameterStatusKind["_draft"] = "draft";
    SearchParameterStatusKind["_active"] = "active";
    SearchParameterStatusKind["_retired"] = "retired";
    SearchParameterStatusKind["_unknown"] = "unknown";
})(SearchParameterStatusKind = exports.SearchParameterStatusKind || (exports.SearchParameterStatusKind = {}));
var SearchParameterTypeKind;
(function (SearchParameterTypeKind) {
    SearchParameterTypeKind["_number"] = "number";
    SearchParameterTypeKind["_date"] = "date";
    SearchParameterTypeKind["_string"] = "string";
    SearchParameterTypeKind["_token"] = "token";
    SearchParameterTypeKind["_reference"] = "reference";
    SearchParameterTypeKind["_composite"] = "composite";
    SearchParameterTypeKind["_quantity"] = "quantity";
    SearchParameterTypeKind["_uri"] = "uri";
    SearchParameterTypeKind["_special"] = "special";
})(SearchParameterTypeKind = exports.SearchParameterTypeKind || (exports.SearchParameterTypeKind = {}));
var SearchParameterXpathUsageKind;
(function (SearchParameterXpathUsageKind) {
    SearchParameterXpathUsageKind["_normal"] = "normal";
    SearchParameterXpathUsageKind["_phonetic"] = "phonetic";
    SearchParameterXpathUsageKind["_nearby"] = "nearby";
    SearchParameterXpathUsageKind["_distance"] = "distance";
    SearchParameterXpathUsageKind["_other"] = "other";
})(SearchParameterXpathUsageKind = exports.SearchParameterXpathUsageKind || (exports.SearchParameterXpathUsageKind = {}));
var EnumType_1 = require("../../EnumType");
exports.RTTI_SearchParameter = t.recursion('ISearchParameter', function () {
    return t.intersection([
        t.type({
            resourceType: t.literal('SearchParameter')
        }),
        t.partial({
            id: RTTI_id_1.RTTI_id,
            meta: RTTI_Meta_1.RTTI_Meta,
            implicitRules: RTTI_uri_1.RTTI_uri,
            _implicitRules: RTTI_Element_1.RTTI_Element,
            language: RTTI_code_1.RTTI_code,
            _language: RTTI_Element_1.RTTI_Element,
            text: RTTI_Narrative_1.RTTI_Narrative,
            contained: t.array(RTTI_ResourceList_1.RTTI_ResourceList),
            extension: t.array(RTTI_Extension_1.RTTI_Extension),
            modifierExtension: t.array(RTTI_Extension_1.RTTI_Extension),
            url: RTTI_uri_1.RTTI_uri,
            _url: RTTI_Element_1.RTTI_Element,
            version: t.string,
            _version: RTTI_Element_1.RTTI_Element,
            name: t.string,
            _name: RTTI_Element_1.RTTI_Element,
            derivedFrom: RTTI_canonical_1.RTTI_canonical,
            status: EnumType_1.createEnumType(SearchParameterStatusKind, 'SearchParameterStatusKind'),
            _status: RTTI_Element_1.RTTI_Element,
            experimental: t.boolean,
            _experimental: RTTI_Element_1.RTTI_Element,
            date: RTTI_dateTime_1.RTTI_dateTime,
            _date: RTTI_Element_1.RTTI_Element,
            publisher: t.string,
            _publisher: RTTI_Element_1.RTTI_Element,
            contact: t.array(RTTI_ContactDetail_1.RTTI_ContactDetail),
            description: RTTI_markdown_1.RTTI_markdown,
            _description: RTTI_Element_1.RTTI_Element,
            useContext: t.array(RTTI_UsageContext_1.RTTI_UsageContext),
            jurisdiction: t.array(RTTI_CodeableConcept_1.RTTI_CodeableConcept),
            purpose: RTTI_markdown_1.RTTI_markdown,
            _purpose: RTTI_Element_1.RTTI_Element,
            code: RTTI_code_1.RTTI_code,
            _code: RTTI_Element_1.RTTI_Element,
            base: t.array(RTTI_code_1.RTTI_code),
            _base: t.array(RTTI_Element_1.RTTI_Element),
            type: EnumType_1.createEnumType(SearchParameterTypeKind, 'SearchParameterTypeKind'),
            _type: RTTI_Element_1.RTTI_Element,
            expression: t.string,
            _expression: RTTI_Element_1.RTTI_Element,
            xpath: t.string,
            _xpath: RTTI_Element_1.RTTI_Element,
            xpathUsage: EnumType_1.createEnumType(SearchParameterXpathUsageKind, 'SearchParameterXpathUsageKind'),
            _xpathUsage: RTTI_Element_1.RTTI_Element,
            target: t.array(RTTI_code_1.RTTI_code),
            _target: t.array(RTTI_Element_1.RTTI_Element),
            multipleOr: t.boolean,
            _multipleOr: RTTI_Element_1.RTTI_Element,
            multipleAnd: t.boolean,
            _multipleAnd: RTTI_Element_1.RTTI_Element,
            comparator: t.array(EnumType_1.createEnumType(SearchParameterComparatorKind, 'SearchParameterComparatorKind')),
            _comparator: t.array(RTTI_Element_1.RTTI_Element),
            modifier: t.array(EnumType_1.createEnumType(SearchParameterModifierKind, 'SearchParameterModifierKind')),
            _modifier: t.array(RTTI_Element_1.RTTI_Element),
            chain: t.array(t.string),
            _chain: t.array(RTTI_Element_1.RTTI_Element),
            component: t.array(RTTI_SearchParameter_Component_1.RTTI_SearchParameter_Component)
        })
    ]);
});
