import React, {useContext} from 'react';
import * as Icons from '@ant-design/icons';
import {Button, Col, message, Modal, Popconfirm, Row, Space, Tabs, Switch, Typography} from 'antd';
import moment from 'moment';
import {ValidateFormItem} from '../../validate-form-item';
import {EndDateField, EndTimeField, StartDateField, StartTimeField} from './block-rule-form-fields';
import {FormUXDateField, FormUXTimeField} from '../../form-ux/form-ux';
import {Formik} from 'formik';
import {FhirUtils} from '../../../services/fhir';
import blockRule from '../../../models/block-rule';
import availabilityRule from '../../../models/availability-rule';
import {
  RecurringRuleFormItems,
  NonRecurringRuleFormItems,
} from '../../clinic/calendar/availability-rule/availability-rule-forms';
import {AvailabilityRuleType} from '../../../interface-models/availability-rules/availability-rule-types/availability-rule-type';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCalendarDay, faUserNurse} from '@fortawesome/free-solid-svg-icons';
import {AbilityContext} from '../../../services/roles/ability-context';
import {useGlobalSettings} from '../../../models/global-settings';

export const BlockCalendarModal = (props) => {
  const {
    visible,
    closeModal,
    selectionInfo,
    blockRuleForEdit,
    calendar,
    timezone,
    refresh,
    clinic,
  } = props;

  //configure permissions for drag and drop, currently set to CANI Admin
  const ability = useContext(AbilityContext);
  const superAdmin = ability.can('create', 'availability');

  //set which form is open in the modal, if not a superAdmin, block is default
  const [activeTab, setActiveTab] = React.useState(superAdmin ? 'availability' : 'block');
  const [availabilityMode, setAvailabilityMode] = React.useState('recurring');

  const settings = useGlobalSettings();
  const enableDose2Availability = settings['enableDose2Availability']?.value === 'true';

  const blockRuleModel = blockRule;
  const availabilityRuleModel = availabilityRule;
  const client = FhirUtils.useAxiosClient();

  if (!(selectionInfo || blockRuleForEdit)) return null;

  const dateFormat = 'YYYY-MM-DD';
  const timeFormat = 'HH:mm';
  const startDatetime =
    activeTab === 'block' && blockRuleForEdit ? blockRuleForEdit.start : selectionInfo?.start;
  const endDatetime =
    activeTab === 'block' && blockRuleForEdit ? blockRuleForEdit.end : selectionInfo?.end;
  const initialValues = {
    startDate: moment.utc(moment.tz(startDatetime, timezone).format(dateFormat)),
    endDate: moment.utc(moment.tz(endDatetime, timezone).format(dateFormat)),
    startTime: moment.utc(moment.tz(startDatetime, timezone).format(timeFormat), timeFormat),
    endTime: moment.utc(moment.tz(endDatetime, timezone).format(timeFormat), timeFormat),
    duration: undefined,
    immunizerCount: undefined,
    days: [],
    date: moment.utc(moment.tz(startDatetime, timezone).format(dateFormat)),
    groupCount: undefined,
  };

  //reset the active tab on close depending on role
  const resetActiveTab = () => {
    superAdmin ? setActiveTab('availability') : setActiveTab('block');
  };

  const validate = (values) => {
    const errors: {[id: string]: string} = {};

    if (activeTab === 'availability') {
      //recurring specific extra validation
      if (availabilityMode === 'recurring') {
        if (values.startDate.isSameOrAfter(values.endDate)) {
          errors.endTime = 'End date should be after start date for recurring rules';
        }
      }
      //non-recurring specific validation
      if (availabilityMode === 'non-recurring') {
        if (values.startTime.isSameOrAfter(values.endTime)) {
          errors.endTime = 'End time should be after start time';
        }
      }
    }

    //block rule validation
    else if (activeTab === 'block') {
      if (values.startDate.isAfter(values.endDate)) {
        errors.endDate = 'End date should be after start date!s';
      }
      if (
        values.startDate.isSame(values.endDate) &&
        values.startTime.isSameOrAfter(values.endTime)
      ) {
        errors.endTime = 'End time should be after start time';
      }
    }

    return errors;
  };

  //toggle if availability rule is recurring or not
  const handleAvailabilitiesMode = () => {
    if (availabilityMode == 'recurring') {
      setAvailabilityMode('non-recurring');
    } else if (availabilityMode == 'non-recurring') {
      setAvailabilityMode('recurring');
    }
  };

  const handleBlockRuleSubmit = (values) => {
    const processFunc = blockRuleForEdit ? blockRuleModel.updateOne : blockRuleModel.createOne;
    const startDatetime = moment
      .tz(values.startDate.format(dateFormat) + ' ' + values.startTime.format(timeFormat), timezone)
      .toISOString();
    const endDatetime = moment
      .tz(values.endDate.format(dateFormat) + ' ' + values.endTime.format(timeFormat), timezone)
      .toISOString();

    return processFunc(client, {
      id: blockRuleForEdit?.extendedProps?.id,
      startDatetime: startDatetime,
      endDatetime: endDatetime,
      calendarId: calendar.id,
    })
      .then((res) => refresh())
      .catch((err) => message.error('Error processing Block rule'))
      .finally(() => {
        resetActiveTab();
        closeModal();
      });
  };

  const submitRecurringAvailability = (values) => {
    availabilityRuleModel
      .createOne(client, {
        type: AvailabilityRuleType.RecurringDay,
        startDate: moment.utc(values.startDate, dateFormat),
        endDate: moment.utc(values.endDate, dateFormat),
        startTime: moment.utc(values.startTime, 'HH:mm') as undefined | moment.Moment,
        endTime: moment.utc(values.endTime, 'HH:mm') as undefined | moment.Moment,
        calendar,
        duration: values.duration,
        immunizerCount: values.immunizerCount,
        days: values.days,
        groupCount: values.groupCount,
        createGroupAvailability: values.groupCount ? true : false,
      })
      .then((res) => refresh())
      .catch((err) => message.error('Error processing availability rule'))
      .finally(() => closeModal());
  };

  const submitNonRecurringAvailability = (values) => {
    return availabilityRuleModel
      .createOne(client, {
        type: AvailabilityRuleType.NonRecurring,
        date: moment.utc(values.date, dateFormat),
        startTime: moment.utc(values.startTime, 'HH:mm') as undefined | moment.Moment,
        endTime: moment.utc(values.endTime, 'HH:mm') as undefined | moment.Moment,
        calendar,
        duration: values.duration,
        immunizerCount: values.immunizerCount,
        groupCount: values.groupCount,
        createGroupAvailability: values.groupCount ? true : false,
      })
      .then((res) => refresh())
      .catch((err) => message.error('Error processing availability rule'))
      .finally(() => {
        closeModal();
        setAvailabilityMode('recurring');
      });
  };

  const handleAvailabilityRuleSubmit = async (values) => {
    availabilityMode === 'recurring'
      ? await submitRecurringAvailability(values)
      : await submitNonRecurringAvailability(values);
  };

  const handleSubmit = (values, actions) => {
    switch (activeTab) {
      case 'block':
        return handleBlockRuleSubmit(values);
      case 'availability':
        return handleAvailabilityRuleSubmit(values);
    }
  };
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={validate}
    >
      {(formikProps) => {
        const ModalTitle = () => {
          return (
            <div>
              {blockRuleForEdit && (
                <>
                  {superAdmin ? 'New Availability / Edit Block Rule' : 'Edit Block Rule'}
                  <Space style={{float: 'right'}}>
                    <Popconfirm
                      title="Are you sure you want to delete block rule?"
                      onConfirm={() => {
                        blockRuleModel
                          .deleteOne(client, blockRuleForEdit?.extendedProps?.id)
                          .then((res) => refresh())
                          .catch((err) => message.error('Error processing Block rule'))
                          .finally(() => closeModal());
                        closeModal();
                      }}
                      okText="Yes"
                      cancelText="No"
                    >
                      {activeTab == 'block' && (
                        <Button key="delete" danger>
                          <Icons.DeleteOutlined /> Delete
                        </Button>
                      )}
                    </Popconfirm>
                  </Space>
                </>
              )}
              {!blockRuleForEdit && superAdmin && 'New Availability Rule / New Block Rule'}
              {!blockRuleForEdit && !superAdmin && 'New Block Rule'}
            </div>
          );
        };

        const ModalFooterBlock = () => {
          return (
            <Space>
              <Popconfirm
                title="Are you sure you want to close? You have unsaved changes."
                disabled={!formikProps.dirty}
                onConfirm={() => {
                  setAvailabilityMode('recurring');
                  closeModal();
                }}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  onClick={() => {
                    if (formikProps.dirty) return;
                    resetActiveTab();
                    setAvailabilityMode('recurring');
                    closeModal();
                  }}
                >
                  Cancel
                </Button>
              </Popconfirm>
              {/* When the user create a block on the internal calendar type, please confirm */}
              <Popconfirm
                title="Are you sure you want to create a block for the internal calendar?"
                disabled={!(calendar.type === 'INTERNAL' && !blockRuleForEdit)}
                onConfirm={() => formikProps.handleSubmit()}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  loading={formikProps.isSubmitting}
                  type="primary"
                  onClick={() => {
                    if (calendar.type === 'INTERNAL' && !blockRuleForEdit) return;
                    formikProps.handleSubmit();
                  }}
                  disabled={!formikProps.isValid}
                >
                  Save
                </Button>
              </Popconfirm>
            </Space>
          );
        };

        const SwitchLabel = () => {
          return (
            <>
              {availabilityMode === 'recurring' ? (
                <Typography.Paragraph>Recurring</Typography.Paragraph>
              ) : (
                <Typography.Paragraph>Non-Recurring</Typography.Paragraph>
              )}
            </>
          );
        };

        const ModalFooterAvailabilities = () => {
          return (
            <>
              <Space>
                <Popconfirm
                  title="Are you sure you want to close? You have unsaved changes."
                  disabled={!formikProps.dirty}
                  onConfirm={() => {
                    setAvailabilityMode('recurring');
                    closeModal();
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    onClick={() => {
                      if (formikProps.dirty) return;
                      setAvailabilityMode('recurring');
                      closeModal();
                    }}
                  >
                    Cancel
                  </Button>
                </Popconfirm>
                <Popconfirm
                  title="Saving this rule will add availability to the selected calendar. Are you sure you want to save?"
                  disabled={!formikProps.isValid}
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => formikProps.handleSubmit()}
                >
                  <Button
                    loading={formikProps.isSubmitting}
                    type="primary"
                    disabled={!formikProps.isValid || !formikProps.dirty}
                  >
                    Save
                  </Button>
                </Popconfirm>
              </Space>
            </>
          );
        };

        const handleTabChange = (activeKey) => {
          setActiveTab(activeKey);
          formikProps.resetForm();
        };

        const footer = activeTab === 'block' ? <ModalFooterBlock /> : <ModalFooterAvailabilities />;

        return (
          <Modal
            destroyOnClose
            visible={visible}
            okText="Save"
            closable={false}
            maskClosable={false}
            footer={footer}
            title={<ModalTitle />}
          >
            <Tabs onChange={handleTabChange} activeKey={activeTab}>
              {superAdmin && (
                <Tabs.TabPane key="availability" tab="Availability">
                  {activeTab === 'availability' && (
                    <>
                      <Space align="baseline">
                        <Switch
                          onChange={() => {
                            handleAvailabilitiesMode();
                            formikProps.resetForm();
                          }}
                          defaultChecked
                        />
                        <SwitchLabel />
                      </Space>
                      {availabilityMode === 'recurring' && (
                        <RecurringRuleFormItems
                          namePrefix=""
                          clinic={clinic}
                          rule={formikProps.values}
                          calendarView
                          enableDose2Availability={enableDose2Availability}
                        />
                      )}
                      {availabilityMode === 'non-recurring' && (
                        <NonRecurringRuleFormItems
                          namePrefix=""
                          clinic={clinic}
                          rule={formikProps.values}
                          calendarView
                          enableDose2Availability={enableDose2Availability}
                        />
                      )}
                      <AvailabilityStats
                        values={formikProps.values}
                        availabilityMode={availabilityMode}
                      />
                    </>
                  )}
                </Tabs.TabPane>
              )}
              <Tabs.TabPane key="block" tab="Block">
                {activeTab === 'block' && <BlockForm />}
              </Tabs.TabPane>
            </Tabs>
          </Modal>
        );
      }}
    </Formik>
  );
};

const BlockForm = () => {
  return (
    <>
      <Row gutter={24}>
        <Col md={12}>
          <ValidateFormItem
            validationRules={StartDateField.validationRules}
            renderFormItem={(validate) => {
              return (
                <FormUXDateField
                  name={StartDateField.name}
                  label={StartDateField.label}
                  validate={validate}
                />
              );
            }}
          />
        </Col>
        <Col md={12}>
          <ValidateFormItem
            validationRules={EndDateField.validationRules}
            renderFormItem={(validate) => {
              return (
                <FormUXDateField
                  name={EndDateField.name}
                  label={EndDateField.label}
                  validate={validate}
                />
              );
            }}
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col md={12}>
          <ValidateFormItem
            validationRules={StartTimeField.validationRules}
            renderFormItem={(validate) => {
              return (
                <FormUXTimeField
                  name={StartTimeField.name}
                  validate={validate}
                  label={StartTimeField.label}
                  timeFormat="HH:mm"
                />
              );
            }}
          />
        </Col>
        <Col md={12}>
          <ValidateFormItem
            validationRules={EndTimeField.validationRules}
            renderFormItem={(validate) => {
              return (
                <FormUXTimeField
                  name={EndTimeField.name}
                  validate={validate}
                  label={EndTimeField.label}
                  timeFormat="HH:mm"
                />
              );
            }}
          />
        </Col>
      </Row>
    </>
  );
};

//this component renders how many appointments will be created based off of form values
const AvailabilityStats = (props) => {
  let values = props.values;
  let slots;

  switch (props.availabilityMode) {
    case 'non-recurring': {
      if (values.endTime.isBefore(values.startTime)) {
        slots = 0;
        break;
      } else {
        const minutes = values.endTime.diff(values.startTime, 'minutes');

        slots =
          values.duration === 0 ? 0 : Math.floor(minutes / values.duration) * values.immunizerCount;

        break;
      }
    }

    case 'recurring': {
      if (values.endTime.isBefore(values.startTime)) {
        slots = 0;
        break;
      }
      const timePerDay = values.endTime.diff(values.startTime, 'minutes');
      let dayIter = moment(values.startDate);
      let activeDayCount = 0;
      while (dayIter.isSameOrBefore(values.endDate)) {
        if (values.days.indexOf(dayIter.get('day')) > -1) {
          activeDayCount++;
        }
        dayIter.add(1, 'day');
      }

      slots =
        values.duration === 0
          ? 0
          : Math.floor(timePerDay / values.duration) * activeDayCount * values.immunizerCount;

      break;
    }
  }

  const immunizer = values.groupCount ? 'Primary Appointment' : 'Immunizer';
  const immunizerText = `${values.immunizerCount} ${immunizer}${
    values.immunizerCount > 1 ? 's' : ''
  }`;
  let slotsText;
  if (slots <= 0) {
    slotsText = 'No Slots';
  } else {
    slotsText = slots > 1 ? `${slots} Slots` : `${slots} Slot`;
  }

  return (
    <>
      {values.immunizerCount > 0 && (
        <div style={{paddingLeft: 5, paddingRight: 5}}>
          <FontAwesomeIcon icon={faUserNurse} style={{marginRight: 10}} />
          {immunizerText}
        </div>
      )}
      {slots >= 0 && (
        <div style={{paddingLeft: 5, paddingRight: 15}}>
          <FontAwesomeIcon icon={faCalendarDay} style={{marginRight: 10}} />
          {slotsText}
        </div>
      )}
    </>
  );
};
