import React from 'react';
import {LanguagePicker} from './language-picker';
import {LogoBar} from './logo-bar';
import s from './styles.module.css';

export const PageTemplate = (props) => {
  return (
    <div className={s.background}>
      <div className={'container'}>
        <LanguagePicker />
        <LogoBar />
        {props.children}
      </div>
    </div>
  );
};
