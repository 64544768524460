import React from 'react';
import {Link} from 'react-router-dom';
import {AbilityContext} from './ability-context';

export const LinkByPermission = ({route, target, text}) => {
  const ability = React.useContext(AbilityContext);
  return ability.can('access', 'route', route) ? (
    <Link to={target}>{text}</Link>
  ) : (
    <div>{text}</div>
  );
};
