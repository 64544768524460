import {Button, message, Popconfirm, Space, Spin} from 'antd';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {FhirUtils} from '../../../services/fhir';

export const AppointmentTypeCalendarActions = (props) => {
  const {appointmentType, calendar, refreshTable} = props;
  const client = FhirUtils.useAxiosClient();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);

  const onRemoveCalendar = async () => {
    setLoading(true);
    await client
      .put(`/appointment-type/link`, {
        action: 'unlink',
        appointmentTypeId: appointmentType.id,
        calendarId: calendar.id,
      })
      .then(() => {
        refreshTable();
        message.success('Calendar was successfully removed from appointment type.');
      })
      .catch(() => {
        message.error('Error removing calendar from appointment type.');
      });
    setLoading(false);
  };

  return (
    <Space>
      <Popconfirm
        title="Are you sure you want to remove this calendar from this appointment type?"
        onConfirm={onRemoveCalendar}
        okText="Yes"
        cancelText="No"
      >
        <Space>
          <Button
            type="link"
            danger
            size={'small'}
            style={{paddingLeft: 10, paddingRight: 10}}
            loading={loading}
          >
            Remove
          </Button>
        </Space>
      </Popconfirm>
    </Space>
  );
};
