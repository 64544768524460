import {Select, Form} from 'formik-antd';
import React, {useState} from 'react';
import {ValidationRules} from '../../validation/validation-rules/validation-rules';
import {ValidateFormItem} from '../validate-form-item';
import {BookingPage} from '../../models/booking-pages';
import {FhirUtils} from '../../services/fhir';

interface BookingPagePickerFormItemProps {
  label?: string;
  name: string;
  setFieldValue: (name: string, value: any) => void;
  validationRules?: ValidationRules[];
  currentBookingPageId?: string;
  nullable?: boolean;
}

const nullableStyle: React.CSSProperties = {
  color: 'grey',
};

export const BookingPagePickerFormItem = (props: BookingPagePickerFormItemProps) => {
  const {currentBookingPageId, setFieldValue} = props;
  const client = FhirUtils.useClient();
  const [bookingPages, setBookingPages] = useState<BookingPage[]>([]);

  React.useEffect(() => {
    client.get('/booking-page').then((res) => {
      //Only Show booking pages with id different from the one being configured
      const bookingPagesById = res.data.entry.filter((entry) => {
        if (entry.resource.id !== currentBookingPageId) return true;
        else return false;
      });
      const bookingPages = bookingPagesById.map((bookingPageById) => {
        return bookingPageById.resource;
      });
      if (props.nullable) bookingPages.unshift({id: null, name: 'None'});
      setBookingPages(bookingPages);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ValidateFormItem
      validationRules={props.validationRules || []}
      renderFormItem={(validate) => {
        return (
          <Form.Item name={props.name} label={props.label} validate={validate}>
            <Select
              name={props.name}
              showSearch
              placeholder="Select a booking page"
              optionFilterProp="children"
              optionLabelProp="label"
              onChange={(value) => {
                setFieldValue(props.name, value);
              }}
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input?.toLowerCase()) >= 0
              }
            >
              {bookingPages.map((bookingPage) => (
                <Select.Option
                  value={bookingPage.id}
                  key={bookingPage.id}
                  style={props.nullable && !bookingPage.id ? nullableStyle : {}}
                  label={props.nullable && !bookingPage.id ? null : bookingPage.name}
                >
                  {bookingPage.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        );
      }}
    />
  );
};
