import {Tabs, Tag, Typography} from 'antd';
import {SortOrder} from 'antd/lib/table/interface';
import moment from 'moment';
import React, {useState} from 'react';
import {FHIRTable} from '../fhir-table';
import {FormSubmissionModal} from './forms-modal';

const {Title} = Typography;

export const FormsWidget = (props) => {
  const {patient} = props;
  const [itemModalVisible, setItemModalVisible] = useState(false);
  const [currentItem, setCurrentItem] = useState();

  const onRowClick = (form) => {
    setCurrentItem(form);
    setItemModalVisible(true);
  };

  const onClose = () => {
    setItemModalVisible(false);
    setCurrentItem(undefined);
  };

  return (
    <>
      <Title level={4}>Forms</Title>

      <FHIRTable
        fhirResource={'form-submissions'}
        fixedFilters={{patientId: patient.id, formGroups: ['null']}}
        hideSearch
        mode="table"
        columns={columns}
        size="small"
        style={{width: '100%'}}
        onClickRow={(item: any) => onRowClick(item)}
      />
      {currentItem && (
        <FormSubmissionModal form={currentItem} visible={itemModalVisible} onClose={onClose} />
      )}
    </>
  );
};

export const columns = [
  {
    title: 'Form',
    dataIndex: 'formName',
    key: 'formName',
    sortDirections: ['descend', 'ascend'] as SortOrder[],
  },
  {
    title: 'Submitted',
    dataIndex: 'createdAt',
    key: 'createdAt',
    sortDirections: ['descend', 'ascend'] as SortOrder[],
    render: (text) => moment(text).format('LLL'),
  },
];
