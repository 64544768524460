import {Button, message, Modal, Space} from 'antd';
import {Formik} from 'formik';
import {Form} from 'formik-antd';
import React, {useEffect} from 'react';
import {FhirUtils} from '../../../services/fhir';
import {userInfoNameForm} from './user-info-name-form';
import {userInfoPasswordForm} from './user-info-password-form';
import {UserInfoUpdate} from './user-info-update-type';

export const UserInfoUpdateModal = (props) => {
  const {visible, userInfo, type, closeModal, refresh} = props;
  const [isSubmit, setIsSubmit] = React.useState(false);
  const [form, setForm] = React.useState<any>();
  const client = FhirUtils.useClient();

  useEffect(() => {
    switch (type) {
      case UserInfoUpdate.Password:
        setForm(userInfoPasswordForm);
        break;
      case UserInfoUpdate.Username:
        setForm(userInfoNameForm);
        break;
      default:
        return undefined;
    }
  }, [type]);

  const handleSubmit = async (values) => {
    try {
      setIsSubmit(true);
      const {confirm, ...params} = values;

      await client.put(`org-admin-user/${userInfo?.id}/user-info`, params);
      message.success(`${type} successfully changed`);
      refresh();
    } catch (err: any) {
      message.error(err?.response?.data?.message || `Failed to update ${type?.toLowerCase()}`);
    }
    setIsSubmit(false);
    closeModal();
  };

  const initialValues =
    type === UserInfoUpdate.Username
      ? {firstname: userInfo?.firstName, lastname: userInfo?.lastName}
      : {};

  return (
    <Formik
      initialValues={initialValues}
      validate={form?.validate}
      validateOnMount={true}
      onSubmit={() => {}}
    >
      {(formikProps) => {
        const ModalFooter = () => {
          return (
            <Space>
              <Button
                onClick={() => {
                  formikProps.handleReset();
                  closeModal();
                }}
              >
                Cancel
              </Button>
              <Button
                loading={isSubmit}
                type="primary"
                onMouseDown={(e) => {
                  e.preventDefault();
                }}
                onClick={() => {
                  handleSubmit(formikProps.values);
                }}
                disabled={!formikProps.isValid}
              >
                Save
              </Button>
            </Space>
          );
        };

        return (
          <Modal
            visible={visible}
            width="400px"
            okText="Save"
            closable={false}
            maskClosable={false}
            footer={<ModalFooter />}
            title={form?.title}
            destroyOnClose
          >
            <Form layout="vertical">{form?.form}</Form>
          </Modal>
        );
      }}
    </Formik>
  );
};
