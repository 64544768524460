import {StrLang, Ids, Str} from '@canimmunize/tools';
import {Button, Modal, Space, Typography} from 'antd';
import axios from 'axios';
import {Formik} from 'formik';
import {Form as AntdForm, Input} from 'formik-antd';
import moment from 'moment';
import React, {useEffect} from 'react';
import {useStr} from '../../../services/str';
import {Form, Components} from 'react-formio';
import {useNavigate} from 'react-router-dom';
import {useUrlData, minimizeDemographicsFormToIdentifyingFields} from '../util';
import {BFCard} from './card';
import {FhirUtils} from '../../../services/fhir';
import {BookingPageCategory} from '../../../models/booking-pages';
import {useEnvInfo} from '../../../services/environment';

interface ClinicFlowForm {
  id: string;
  name: string;
  form: any;
  formOptions: any;
}

interface IContactFormProps {
  selectedAppointment;
  setSelectedAppointment;
  contactFormValues;
  setContactFormValues;
  onSchedule?;
  alternateSeriesId?: string;
  overrideFormData?: any;
  isAddGroupForm?: boolean;
  onClose?;
  previewConfig?;
  previousAppointmentId?: string;
  previousDoseId?: string;
  patientId?: string;
  returnOnSubmit?;
  onError?;
  demographicsFormId?: string;
}

export const ContactForm = (props: IContactFormProps) => {
  const {
    selectedAppointment,
    contactFormValues,
    setContactFormValues,
    onSchedule,
    alternateSeriesId,
    overrideFormData,
    onClose,
    isAddGroupForm,
    previewConfig,
    returnOnSubmit,
    onError,
  } = props;
  const Str = useStr();
  const {lang, syncUrl, config, theme, query, adminUser} = useUrlData(previewConfig);
  const adminClient = FhirUtils.useAxiosClient();
  const client = adminUser ? adminClient : axios;
  const navigate = useNavigate();
  const [demographicsForm, setDemographicsForm] = React.useState<ClinicFlowForm>();
  const [submitting, setSubmitting] = React.useState(false);
  const [overrideSubmitting, setOverrideSubmitting] = React.useState(false);
  const [error, setError] = React.useState<string>();
  const [overrideModalVisible, setOverrideModalVisible] = React.useState(false);
  const [overrideSelected, setOverrideSelected] = React.useState(false);
  const [override, setOverride] =
    React.useState<{submitterName?: string; overrideReason?: string} | undefined>();
  const form = React.useRef<any>(null);
  const {demographicsFormId} = config;
  const previousAppointmentId = props.previousAppointmentId || query.previousAppointmentId;
  const previousDoseId = props.previousDoseId || query.previousDoseId;

  useEffect(() => {
    if (!demographicsFormId) return;
    axios
      .get(syncUrl + `/public/form/${demographicsFormId}`)
      .then((res) => {
        // If there is a previous appointment, only include form elements needed to verify user's identity
        // (other fields already exist with the first appointment)
        if (previousAppointmentId || previousDoseId)
          minimizeDemographicsFormToIdentifyingFields(res.data.form);
        setDemographicsForm(res.data);
      })
      .catch((err) => {
        setError(err.message);
      });
  }, [demographicsFormId]);

  useEffect(() => {
    // updateMonthsGetterForLanguage();
    const DayComponent = Components.components.day;
    // Redefines getter for months() method in Day component to use translated option labels for month selection
    Object.defineProperty(DayComponent.prototype, 'months', {
      get: function () {
        this._months = [
          {
            value: '',
            label: '',
          },
          {value: 1, label: Str(Ids.january)},
          {value: 2, label: Str(Ids.february)},
          {value: 3, label: Str(Ids.march)},
          {value: 4, label: Str(Ids.april)},
          {value: 5, label: Str(Ids.may)},
          {value: 6, label: Str(Ids.june)},
          {value: 7, label: Str(Ids.july)},
          {value: 8, label: Str(Ids.august)},
          {value: 9, label: Str(Ids.september)},
          {value: 10, label: Str(Ids.october)},
          {value: 11, label: Str(Ids.november)},
          {value: 12, label: Str(Ids.december)},
        ];

        return this._months;
      },
    });
  }, [lang]);

  if (!selectedAppointment || !demographicsForm) return null;

  const formConfig = demographicsForm?.form;
  const formOptions = demographicsForm?.formOptions;

  const hcnColumnsIndex = formConfig.components[0].components.findIndex(
    (comp) => comp.key === 'hcn-columns'
  );

  const submitOverride = () => {
    setOverrideModalVisible(true);
  };

  const toggleSelectOverride = () => {
    const newOverrideSelectedValue = !overrideSelected;
    const updatedForm = Object.assign({}, formConfig);

    // Clear any errors
    setError(undefined);

    // Set the
    updatedForm.components[0].components[
      hcnColumnsIndex
    ].columns[0].components[0].validate.required = !newOverrideSelectedValue;
    updatedForm.components[0].components[
      hcnColumnsIndex
    ].columns[1].components[0].validate.required = !newOverrideSelectedValue;
    form?.current?.formio.setForm(updatedForm);
    setOverrideSelected(newOverrideSelectedValue);
  };

  function onSubmit() {
    // Clear any existing error
    setError(undefined);

    // Set submitting indicator based on which button is pressed
    override ? setOverrideSubmitting(true) : setSubmitting(true);

    const {data: formData} = contactFormValues;
    const {
      firstName,
      lastName,
      hcn,
      hcnType,
      birthdate: dirtyBirthdate,
      gender,
      genderIdentifyAs,
      phone,
      email,
      city,
      postalCode,
      street,
      unitNumber,
      preferredName,
      pronoun,
      employee,
      employeeEmail,
    } = formData;

    const birthdate = dirtyBirthdate
      ? moment(dirtyBirthdate, 'MM/DD/YYYY').format('YYYY-MM-DD')
      : undefined;

    const additionalPatients = Array.from(Array(formData.numberOfPatients - 1).keys()).map((i) => {
      return formData[`person${i + 2}`];
    });

    // If a code was provided in the query string then include it in the body
    formData.code = query.code;

    formData.patients = [
      {
        firstName,
        lastName,
        hcn,
        hcnType,
        email,
        birthdate,
        phone,
        gender,
        genderIdentifyAs,
        city,
        postalCode,
        street,
        unitNumber,
        preferredName,
        pronoun,
        preferredLanguage: lang,
        // Bruyere want to have employee Id in a demograhpics form
        ...(employee && {employee}),
        ...(employeeEmail && {employeeEmail}),
      },
      ...additionalPatients.map((p) => {
        return {
          ...p,
          birthdate: moment(p.birthdate).format('YYYY-MM-DD'),
          email: '',
          preferredLanguage: lang,
        };
      }),
    ];

    const clientRegistryOverride = override
      ? {...override, overrideTimestamp: moment().toISOString()}
      : undefined;

    const url = adminUser ? '/appointment' : '/public/appointment';
    const patientId = adminUser && !!props.patientId ? {patientId: props.patientId} : {};

    return client
      .post(syncUrl + url, {
        ...formData,
        ...patientId,
        ...overrideFormData,
        previousAppointmentId: previousAppointmentId,
        previousDoseId: previousDoseId,
        datetime: selectedAppointment.datetime,
        bookingPageId: config.id,
        organizationId: config.organizationId,
        appointmentTypeId: selectedAppointment.id,
        timezone: moment.tz.guess(),
        preferredLanguage: lang,
        clientRegistryOverride,
        appointmentGroupId: selectedAppointment.appointmentGroupId,
        useAlternateSeries: alternateSeriesId ? true : undefined,
        alternateSeriesId,
      })
      .then((result) => {
        if (onSchedule) {
          onSchedule(result.data.appointmentId);
        } else if (isAddGroupForm) {
          window.location.reload();
        } else {
          navigate(
            `/${lang}/${config.bookingPageSlug[lang]}/${result.data.appointmentId}${
              config.category === BookingPageCategory.GROUP ? '' : '?booked=true'
            }`
          );
        }
      })
      .catch((err) => {
        let message = err.message;

        switch (err.response?.status) {
          case 404:
            message = `The appointment you selected is no longer available. Please select another time above and retry. This is likely due to a large volume of traffic on the page.`;
            break;
          case 403:
          case 400: {
            const error = err.response.data.error;
            message =
              error.display && error.display[lang].length ? error.display[lang] : error.message;
            break;
          }

          default:
            break;
        }
        setError(message);
        onError?.(message);
      })
      .finally(() => {
        setSubmitting(false);
        setOverrideSubmitting(false);
      });
  }
  returnOnSubmit?.(onSubmit);

  const submission = contactFormValues;
  submission.data.lang = lang;
  submission.data.numberOfPatients = selectedAppointment.numberOfPatients;
  submission.data.bookingPageId = config.id;
  submission.data.minAge = selectedAppointment.minAge;
  submission.data.maxAge = selectedAppointment.maxAge;
  submission.data.minAgeUnit = selectedAppointment.minAgeUnit;
  submission.data.maxAgeUnit = selectedAppointment.maxAgeUnit;
  submission.data.apptDate = selectedAppointment.datetime;
  submission.data.ageEligibilityErrorMessage = selectedAppointment.ageEligibilityErrorMessage;
  submission.data.override = override;
  submission.data.overrideEnabled = overrideSelected;
  submission.data.eligibleBirthMonths = selectedAppointment.eligibleBirthMonths;

  // submission.data.firstName = faker.name.firstName();
  // submission.data.lastName = faker.name.lastName();
  // const email = 'cameron+formtesting@canimmunize.ca';
  // submission.data.email = email;
  // submission.data.confirmEmail = email;
  // submission.data.gender = faker.helpers.arrayElement([0, 1, 2, 3]);
  // submission.data.hcnType = 'FN';
  // submission.data.hcnOther = `${faker.datatype.number(9999999999)}`;
  // submission.data.birthdate = '03/18/1946';
  // submission.data.street = faker.address.streetAddress();
  // submission.data.postalCode = 'M6S2X8';
  // submission.data.city = faker.address.city();
  // submission.data.phone = '(203) 484-4934';

  // If there is an override turn off HCN validation

  return (
    <div>
      <div style={{marginBottom: theme.sectionSpacing}}>
        <h3 id="contact-form-header">
          {isAddGroupForm
            ? Str(Ids.contact_information)
            : (config.showAddressLookup === 'true' ? '3. ' : '2. ') + Str(Ids.contact_information)}
        </h3>

        <BFCard style={{padding: 15, ...(isAddGroupForm ? {boxShadow: 'none'} : {})}}>
          <div dangerouslySetInnerHTML={{__html: config.contactInfoFormTextHtml[lang]}} />
          <Formio
            form={formConfig}
            formRef={form}
            onSubmit={onSubmit}
            onChange={(change) => {
              if (change.changed === undefined) return;

              if (change.changed?.component?.key === 'hcnType') {
                const mask = getInputMaskForHCNType(change.data.hcnType);
                const updatedForm = Object.assign({}, formConfig);
                if (mask) {
                  updatedForm.components[0].components[
                    hcnColumnsIndex
                  ].columns[1].components[0].inputMask = mask;
                } else {
                  delete updatedForm.components[0].components[hcnColumnsIndex].columns[1]
                    .components[0].inputMask;
                }
                form?.current?.formio.setForm(updatedForm).then(() => {
                  //If gender value is 0 (Man), reset to string '0' to overcome null check of choicesjs widget
                  if (
                    contactFormValues.data.hasOwnProperty('gender') &&
                    contactFormValues.data.gender === 0
                  ) {
                    setContactFormValues({
                      ...contactFormValues,
                      data: {...contactFormValues.data, gender: '0'},
                    });
                  }
                });
              }
            }}
            onError={(errors) => {
              if (errors.length) {
                const contactFormTop = document.getElementById('contact-form-header');
                contactFormTop?.scrollIntoView();
              }
            }}
            options={{
              ...formOptions,
              language: lang,
            }}
            submission={submission}
          />
        </BFCard>
      </div>
      {selectedAppointment && (
        <div style={{display: 'flex', alignItems: 'center'}}>
          <div style={{flexGrow: 1, textAlign: 'right'}}>
            <Typography.Text type="danger">
              <div dangerouslySetInnerHTML={{__html: error || ''}} />
            </Typography.Text>
          </div>
          {config.clientRegistryOverrideEnabled === 'true' && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                paddingLeft: 20,
                paddingTop: 20,
                paddingBottom: 20,
              }}
            >
              <Button
                size="large"
                type="link"
                loading={overrideSubmitting}
                onClick={toggleSelectOverride}
              >
                {overrideSelected ? 'Cancel Override' : ' Override'}
              </Button>
            </div>
          )}
          {overrideSelected && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                paddingLeft: 20,
                paddingTop: 20,
                paddingBottom: 20,
              }}
            >
              <Button size="large" loading={overrideSubmitting} danger onClick={submitOverride}>
                Submit Override
              </Button>
            </div>
          )}
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              paddingLeft: 20,
              paddingTop: 20,
              paddingBottom: 20,
            }}
          >
            <Space>
              <Button
                size="large"
                color={theme.blue}
                loading={submitting}
                type="primary"
                onClick={() => {
                  // form?.current.formio.root.setPristine(true);
                  form?.current.formio.submit();
                }}
              >
                {Str(Ids.confirm_appointment)}
              </Button>
              {onClose && (
                <Button size="large" color={theme.blue} type="primary" onClick={onClose}>
                  Close
                </Button>
              )}
            </Space>
          </div>
        </div>
      )}
      <Formik
        initialValues={{submitterName: '', overrideReason: ''}}
        onSubmit={(values) => {
          setOverride(values);
          form?.current.formio.submit();
        }}
      >
        {(formikProps) => (
          <Modal
            visible={overrideModalVisible}
            title={Str('Override Client Registry Verification')}
            onCancel={() => setOverrideModalVisible(false)}
            okButtonProps={{
              disabled: !(
                formikProps.values.submitterName.length > 0 &&
                formikProps.values.overrideReason.length > 0
              ),
            }}
            onOk={() => {
              setOverrideModalVisible(false);
              formikProps.handleSubmit();
            }}
          >
            <AntdForm>
              <AntdForm.Item name="submitterName" label="Submitter Name" required>
                <Input name="submitterName" />
              </AntdForm.Item>
              <AntdForm.Item name="overrideReason" label="Override Reason" required>
                <Input.TextArea name="overrideReason" />
              </AntdForm.Item>
            </AntdForm>
          </Modal>
        )}
      </Formik>
    </div>
  );
};

const Formio = (props: any) => {
  const {formRef} = props;
  return (
    <div>
      <Form {...props} ref={formRef} />
    </div>
  );
};

const stringIds = [
  'next',
  'cancel',
  'submit',
  'previous',
  'first_name',
  'last_name',
  'email',
  'confirm_email',
  'phone',
  'person1',
  'person2',
  'person3',
  'person4',
  'person5',
  'person6',
  'provide_name_other_individual_appt',
];

// Load the translations for all of the stringIds into the i18n object
const i18n = stringIds.reduce(
  (i18n, id) => {
    return {en: {...i18n.en, [id]: StrLang('en', id)}, fr: {...i18n.fr, [id]: StrLang('fr', id)}};
  },
  {en: {}, fr: {}}
);

const options = {
  language: 'en',
  i18n,
};

const getInputMaskForHCNType = (hcnType) => {
  switch (hcnType) {
    case 'YT':
      return '999-999-999';

    case 'NS':
    case 'BC':
      return '9999999999';

    default:
      return undefined;
  }
};
