import moment from 'moment';
import {AbstractValidationRule} from './abstract-validation-rule';

export const FutureDateValidationRuleId = 'futureDate';

export interface FutureDateValidationRule extends AbstractValidationRule {
  validationRuleType: typeof FutureDateValidationRuleId;
}

/**
 * Validation to check if a date is a future date. Also passes if no date is entered.
 *
 * @export
 * @param {*} value
 * @returns {boolean} True if validation passed. False otherwise.
 */
export function validateFutureDateRule(value: any): boolean {
  if (!value) return true;
  else if (moment.isMoment(value)) return value.isAfter();
  else return moment(value).isAfter();
}
