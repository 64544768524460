"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_ResourceList = void 0;
var RTTI_Account_1 = require("../Resource/RTTI_Account");
var RTTI_ActivityDefinition_1 = require("../Resource/RTTI_ActivityDefinition");
var RTTI_AdverseEvent_1 = require("../Resource/RTTI_AdverseEvent");
var RTTI_AllergyIntolerance_1 = require("../Resource/RTTI_AllergyIntolerance");
var RTTI_Appointment_1 = require("../Resource/RTTI_Appointment");
var RTTI_AppointmentResponse_1 = require("../Resource/RTTI_AppointmentResponse");
var RTTI_AuditEvent_1 = require("../Resource/RTTI_AuditEvent");
var RTTI_Basic_1 = require("../Resource/RTTI_Basic");
var RTTI_Binary_1 = require("../Resource/RTTI_Binary");
var RTTI_BiologicallyDerivedProduct_1 = require("../Resource/RTTI_BiologicallyDerivedProduct");
var RTTI_BodyStructure_1 = require("../Resource/RTTI_BodyStructure");
var RTTI_Bundle_1 = require("../Resource/RTTI_Bundle");
var RTTI_CapabilityStatement_1 = require("../Resource/RTTI_CapabilityStatement");
var RTTI_CarePlan_1 = require("../Resource/RTTI_CarePlan");
var RTTI_CareTeam_1 = require("../Resource/RTTI_CareTeam");
var RTTI_ChargeItem_1 = require("../Resource/RTTI_ChargeItem");
var RTTI_ChargeItemDefinition_1 = require("../Resource/RTTI_ChargeItemDefinition");
var RTTI_Claim_1 = require("../Resource/RTTI_Claim");
var RTTI_ClaimResponse_1 = require("../Resource/RTTI_ClaimResponse");
var RTTI_ClinicalImpression_1 = require("../Resource/RTTI_ClinicalImpression");
var RTTI_CodeSystem_1 = require("../Resource/RTTI_CodeSystem");
var RTTI_Communication_1 = require("../Resource/RTTI_Communication");
var RTTI_CommunicationRequest_1 = require("../Resource/RTTI_CommunicationRequest");
var RTTI_CompartmentDefinition_1 = require("../Resource/RTTI_CompartmentDefinition");
var RTTI_Composition_1 = require("../Resource/RTTI_Composition");
var RTTI_ConceptMap_1 = require("../Resource/RTTI_ConceptMap");
var RTTI_Condition_1 = require("../Resource/RTTI_Condition");
var RTTI_Consent_1 = require("../Resource/RTTI_Consent");
var RTTI_Contract_1 = require("../Resource/RTTI_Contract");
var RTTI_Coverage_1 = require("../Resource/RTTI_Coverage");
var RTTI_CoverageEligibilityRequest_1 = require("../Resource/RTTI_CoverageEligibilityRequest");
var RTTI_CoverageEligibilityResponse_1 = require("../Resource/RTTI_CoverageEligibilityResponse");
var RTTI_DetectedIssue_1 = require("../Resource/RTTI_DetectedIssue");
var RTTI_Device_1 = require("../Resource/RTTI_Device");
var RTTI_DeviceDefinition_1 = require("../Resource/RTTI_DeviceDefinition");
var RTTI_DeviceMetric_1 = require("../Resource/RTTI_DeviceMetric");
var RTTI_DeviceRequest_1 = require("../Resource/RTTI_DeviceRequest");
var RTTI_DeviceUseStatement_1 = require("../Resource/RTTI_DeviceUseStatement");
var RTTI_DiagnosticReport_1 = require("../Resource/RTTI_DiagnosticReport");
var RTTI_DocumentManifest_1 = require("../Resource/RTTI_DocumentManifest");
var RTTI_DocumentReference_1 = require("../Resource/RTTI_DocumentReference");
var RTTI_Encounter_1 = require("../Resource/RTTI_Encounter");
var RTTI_Endpoint_1 = require("../Resource/RTTI_Endpoint");
var RTTI_EnrollmentRequest_1 = require("../Resource/RTTI_EnrollmentRequest");
var RTTI_EnrollmentResponse_1 = require("../Resource/RTTI_EnrollmentResponse");
var RTTI_EpisodeOfCare_1 = require("../Resource/RTTI_EpisodeOfCare");
var RTTI_EventDefinition_1 = require("../Resource/RTTI_EventDefinition");
var RTTI_ExampleScenario_1 = require("../Resource/RTTI_ExampleScenario");
var RTTI_ExplanationOfBenefit_1 = require("../Resource/RTTI_ExplanationOfBenefit");
var RTTI_FamilyMemberHistory_1 = require("../Resource/RTTI_FamilyMemberHistory");
var RTTI_Flag_1 = require("../Resource/RTTI_Flag");
var RTTI_Goal_1 = require("../Resource/RTTI_Goal");
var RTTI_GraphDefinition_1 = require("../Resource/RTTI_GraphDefinition");
var RTTI_Group_1 = require("../Resource/RTTI_Group");
var RTTI_GuidanceResponse_1 = require("../Resource/RTTI_GuidanceResponse");
var RTTI_HealthcareService_1 = require("../Resource/RTTI_HealthcareService");
var RTTI_ImagingStudy_1 = require("../Resource/RTTI_ImagingStudy");
var RTTI_Immunization_1 = require("../Resource/RTTI_Immunization");
var RTTI_ImmunizationEvaluation_1 = require("../Resource/RTTI_ImmunizationEvaluation");
var RTTI_ImmunizationRecommendation_1 = require("../Resource/RTTI_ImmunizationRecommendation");
var RTTI_ImplementationGuide_1 = require("../Resource/RTTI_ImplementationGuide");
var RTTI_InsurancePlan_1 = require("../Resource/RTTI_InsurancePlan");
var RTTI_Invoice_1 = require("../Resource/RTTI_Invoice");
var RTTI_Library_1 = require("../Resource/RTTI_Library");
var RTTI_Linkage_1 = require("../Resource/RTTI_Linkage");
var RTTI_List_1 = require("../Resource/RTTI_List");
var RTTI_Location_1 = require("../Resource/RTTI_Location");
var RTTI_Measure_1 = require("../Resource/RTTI_Measure");
var RTTI_MeasureReport_1 = require("../Resource/RTTI_MeasureReport");
var RTTI_Media_1 = require("../Resource/RTTI_Media");
var RTTI_Medication_1 = require("../Resource/RTTI_Medication");
var RTTI_MedicationAdministration_1 = require("../Resource/RTTI_MedicationAdministration");
var RTTI_MedicationDispense_1 = require("../Resource/RTTI_MedicationDispense");
var RTTI_MedicationKnowledge_1 = require("../Resource/RTTI_MedicationKnowledge");
var RTTI_MedicationRequest_1 = require("../Resource/RTTI_MedicationRequest");
var RTTI_MedicationStatement_1 = require("../Resource/RTTI_MedicationStatement");
var RTTI_MedicinalProduct_1 = require("../Resource/RTTI_MedicinalProduct");
var RTTI_MedicinalProductAuthorization_1 = require("../Resource/RTTI_MedicinalProductAuthorization");
var RTTI_MedicinalProductContraindication_1 = require("../Resource/RTTI_MedicinalProductContraindication");
var RTTI_MedicinalProductIndication_1 = require("../Resource/RTTI_MedicinalProductIndication");
var RTTI_MedicinalProductIngredient_1 = require("../Resource/RTTI_MedicinalProductIngredient");
var RTTI_MedicinalProductInteraction_1 = require("../Resource/RTTI_MedicinalProductInteraction");
var RTTI_MedicinalProductManufactured_1 = require("../Resource/RTTI_MedicinalProductManufactured");
var RTTI_MedicinalProductPackaged_1 = require("../Resource/RTTI_MedicinalProductPackaged");
var RTTI_MedicinalProductPharmaceutical_1 = require("../Resource/RTTI_MedicinalProductPharmaceutical");
var RTTI_MedicinalProductUndesirableEffect_1 = require("../Resource/RTTI_MedicinalProductUndesirableEffect");
var RTTI_MessageDefinition_1 = require("../Resource/RTTI_MessageDefinition");
var RTTI_MessageHeader_1 = require("../Resource/RTTI_MessageHeader");
var RTTI_NamingSystem_1 = require("../Resource/RTTI_NamingSystem");
var RTTI_NutritionOrder_1 = require("../Resource/RTTI_NutritionOrder");
var RTTI_Observation_1 = require("../Resource/RTTI_Observation");
var RTTI_ObservationDefinition_1 = require("../Resource/RTTI_ObservationDefinition");
var RTTI_OperationDefinition_1 = require("../Resource/RTTI_OperationDefinition");
var RTTI_OperationOutcome_1 = require("../Resource/RTTI_OperationOutcome");
var RTTI_Organization_1 = require("../Resource/RTTI_Organization");
var RTTI_OrganizationAffiliation_1 = require("../Resource/RTTI_OrganizationAffiliation");
var RTTI_Parameters_1 = require("../Resource/RTTI_Parameters");
var RTTI_Patient_1 = require("../Resource/RTTI_Patient");
var RTTI_PaymentNotice_1 = require("../Resource/RTTI_PaymentNotice");
var RTTI_PaymentReconciliation_1 = require("../Resource/RTTI_PaymentReconciliation");
var RTTI_Person_1 = require("../Resource/RTTI_Person");
var RTTI_PlanDefinition_1 = require("../Resource/RTTI_PlanDefinition");
var RTTI_Practitioner_1 = require("../Resource/RTTI_Practitioner");
var RTTI_PractitionerRole_1 = require("../Resource/RTTI_PractitionerRole");
var RTTI_Procedure_1 = require("../Resource/RTTI_Procedure");
var RTTI_Provenance_1 = require("../Resource/RTTI_Provenance");
var RTTI_Questionnaire_1 = require("../Resource/RTTI_Questionnaire");
var RTTI_QuestionnaireResponse_1 = require("../Resource/RTTI_QuestionnaireResponse");
var RTTI_RelatedPerson_1 = require("../Resource/RTTI_RelatedPerson");
var RTTI_RequestGroup_1 = require("../Resource/RTTI_RequestGroup");
var RTTI_ResearchStudy_1 = require("../Resource/RTTI_ResearchStudy");
var RTTI_ResearchSubject_1 = require("../Resource/RTTI_ResearchSubject");
var RTTI_RiskAssessment_1 = require("../Resource/RTTI_RiskAssessment");
var RTTI_Schedule_1 = require("../Resource/RTTI_Schedule");
var RTTI_SearchParameter_1 = require("../Resource/RTTI_SearchParameter");
var RTTI_ServiceRequest_1 = require("../Resource/RTTI_ServiceRequest");
var RTTI_Slot_1 = require("../Resource/RTTI_Slot");
var RTTI_Specimen_1 = require("../Resource/RTTI_Specimen");
var RTTI_SpecimenDefinition_1 = require("../Resource/RTTI_SpecimenDefinition");
var RTTI_StructureDefinition_1 = require("../Resource/RTTI_StructureDefinition");
var RTTI_StructureMap_1 = require("../Resource/RTTI_StructureMap");
var RTTI_Subscription_1 = require("../Resource/RTTI_Subscription");
var RTTI_Substance_1 = require("../Resource/RTTI_Substance");
var RTTI_SubstancePolymer_1 = require("../Resource/RTTI_SubstancePolymer");
var RTTI_SubstanceReferenceInformation_1 = require("../Resource/RTTI_SubstanceReferenceInformation");
var RTTI_SubstanceSpecification_1 = require("../Resource/RTTI_SubstanceSpecification");
var RTTI_SupplyDelivery_1 = require("../Resource/RTTI_SupplyDelivery");
var RTTI_SupplyRequest_1 = require("../Resource/RTTI_SupplyRequest");
var RTTI_Task_1 = require("../Resource/RTTI_Task");
var RTTI_TerminologyCapabilities_1 = require("../Resource/RTTI_TerminologyCapabilities");
var RTTI_TestReport_1 = require("../Resource/RTTI_TestReport");
var RTTI_TestScript_1 = require("../Resource/RTTI_TestScript");
var RTTI_ValueSet_1 = require("../Resource/RTTI_ValueSet");
var RTTI_VerificationResult_1 = require("../Resource/RTTI_VerificationResult");
var RTTI_VisionPrescription_1 = require("../Resource/RTTI_VisionPrescription");
var t = __importStar(require("io-ts"));
exports.RTTI_ResourceList = t.recursion('IResourceList', function () {
    return t.union([
        RTTI_Account_1.RTTI_Account,
        RTTI_ActivityDefinition_1.RTTI_ActivityDefinition,
        RTTI_AdverseEvent_1.RTTI_AdverseEvent,
        RTTI_AllergyIntolerance_1.RTTI_AllergyIntolerance,
        RTTI_Appointment_1.RTTI_Appointment,
        RTTI_AppointmentResponse_1.RTTI_AppointmentResponse,
        RTTI_AuditEvent_1.RTTI_AuditEvent,
        RTTI_Basic_1.RTTI_Basic,
        RTTI_Binary_1.RTTI_Binary,
        RTTI_BiologicallyDerivedProduct_1.RTTI_BiologicallyDerivedProduct,
        RTTI_BodyStructure_1.RTTI_BodyStructure,
        RTTI_Bundle_1.RTTI_Bundle,
        RTTI_CapabilityStatement_1.RTTI_CapabilityStatement,
        RTTI_CarePlan_1.RTTI_CarePlan,
        RTTI_CareTeam_1.RTTI_CareTeam,
        RTTI_ChargeItem_1.RTTI_ChargeItem,
        RTTI_ChargeItemDefinition_1.RTTI_ChargeItemDefinition,
        RTTI_Claim_1.RTTI_Claim,
        RTTI_ClaimResponse_1.RTTI_ClaimResponse,
        RTTI_ClinicalImpression_1.RTTI_ClinicalImpression,
        RTTI_CodeSystem_1.RTTI_CodeSystem,
        RTTI_Communication_1.RTTI_Communication,
        RTTI_CommunicationRequest_1.RTTI_CommunicationRequest,
        RTTI_CompartmentDefinition_1.RTTI_CompartmentDefinition,
        RTTI_Composition_1.RTTI_Composition,
        RTTI_ConceptMap_1.RTTI_ConceptMap,
        RTTI_Condition_1.RTTI_Condition,
        RTTI_Consent_1.RTTI_Consent,
        RTTI_Contract_1.RTTI_Contract,
        RTTI_Coverage_1.RTTI_Coverage,
        RTTI_CoverageEligibilityRequest_1.RTTI_CoverageEligibilityRequest,
        RTTI_CoverageEligibilityResponse_1.RTTI_CoverageEligibilityResponse,
        RTTI_DetectedIssue_1.RTTI_DetectedIssue,
        RTTI_Device_1.RTTI_Device,
        RTTI_DeviceDefinition_1.RTTI_DeviceDefinition,
        RTTI_DeviceMetric_1.RTTI_DeviceMetric,
        RTTI_DeviceRequest_1.RTTI_DeviceRequest,
        RTTI_DeviceUseStatement_1.RTTI_DeviceUseStatement,
        RTTI_DiagnosticReport_1.RTTI_DiagnosticReport,
        RTTI_DocumentManifest_1.RTTI_DocumentManifest,
        RTTI_DocumentReference_1.RTTI_DocumentReference,
        RTTI_Encounter_1.RTTI_Encounter,
        RTTI_Endpoint_1.RTTI_Endpoint,
        RTTI_EnrollmentRequest_1.RTTI_EnrollmentRequest,
        RTTI_EnrollmentResponse_1.RTTI_EnrollmentResponse,
        RTTI_EpisodeOfCare_1.RTTI_EpisodeOfCare,
        RTTI_EventDefinition_1.RTTI_EventDefinition,
        RTTI_ExampleScenario_1.RTTI_ExampleScenario,
        RTTI_ExplanationOfBenefit_1.RTTI_ExplanationOfBenefit,
        RTTI_FamilyMemberHistory_1.RTTI_FamilyMemberHistory,
        RTTI_Flag_1.RTTI_Flag,
        RTTI_Goal_1.RTTI_Goal,
        RTTI_GraphDefinition_1.RTTI_GraphDefinition,
        RTTI_Group_1.RTTI_Group,
        RTTI_GuidanceResponse_1.RTTI_GuidanceResponse,
        RTTI_HealthcareService_1.RTTI_HealthcareService,
        RTTI_ImagingStudy_1.RTTI_ImagingStudy,
        RTTI_Immunization_1.RTTI_Immunization,
        RTTI_ImmunizationEvaluation_1.RTTI_ImmunizationEvaluation,
        RTTI_ImmunizationRecommendation_1.RTTI_ImmunizationRecommendation,
        RTTI_ImplementationGuide_1.RTTI_ImplementationGuide,
        RTTI_InsurancePlan_1.RTTI_InsurancePlan,
        RTTI_Invoice_1.RTTI_Invoice,
        RTTI_Library_1.RTTI_Library,
        RTTI_Linkage_1.RTTI_Linkage,
        RTTI_List_1.RTTI_List,
        RTTI_Location_1.RTTI_Location,
        RTTI_Measure_1.RTTI_Measure,
        RTTI_MeasureReport_1.RTTI_MeasureReport,
        RTTI_Media_1.RTTI_Media,
        RTTI_Medication_1.RTTI_Medication,
        RTTI_MedicationAdministration_1.RTTI_MedicationAdministration,
        RTTI_MedicationDispense_1.RTTI_MedicationDispense,
        RTTI_MedicationKnowledge_1.RTTI_MedicationKnowledge,
        RTTI_MedicationRequest_1.RTTI_MedicationRequest,
        RTTI_MedicationStatement_1.RTTI_MedicationStatement,
        RTTI_MedicinalProduct_1.RTTI_MedicinalProduct,
        RTTI_MedicinalProductAuthorization_1.RTTI_MedicinalProductAuthorization,
        RTTI_MedicinalProductContraindication_1.RTTI_MedicinalProductContraindication,
        RTTI_MedicinalProductIndication_1.RTTI_MedicinalProductIndication,
        RTTI_MedicinalProductIngredient_1.RTTI_MedicinalProductIngredient,
        RTTI_MedicinalProductInteraction_1.RTTI_MedicinalProductInteraction,
        RTTI_MedicinalProductManufactured_1.RTTI_MedicinalProductManufactured,
        RTTI_MedicinalProductPackaged_1.RTTI_MedicinalProductPackaged,
        RTTI_MedicinalProductPharmaceutical_1.RTTI_MedicinalProductPharmaceutical,
        RTTI_MedicinalProductUndesirableEffect_1.RTTI_MedicinalProductUndesirableEffect,
        RTTI_MessageDefinition_1.RTTI_MessageDefinition,
        RTTI_MessageHeader_1.RTTI_MessageHeader,
        RTTI_NamingSystem_1.RTTI_NamingSystem,
        RTTI_NutritionOrder_1.RTTI_NutritionOrder,
        RTTI_Observation_1.RTTI_Observation,
        RTTI_ObservationDefinition_1.RTTI_ObservationDefinition,
        RTTI_OperationDefinition_1.RTTI_OperationDefinition,
        RTTI_OperationOutcome_1.RTTI_OperationOutcome,
        RTTI_Organization_1.RTTI_Organization,
        RTTI_OrganizationAffiliation_1.RTTI_OrganizationAffiliation,
        RTTI_Parameters_1.RTTI_Parameters,
        RTTI_Patient_1.RTTI_Patient,
        RTTI_PaymentNotice_1.RTTI_PaymentNotice,
        RTTI_PaymentReconciliation_1.RTTI_PaymentReconciliation,
        RTTI_Person_1.RTTI_Person,
        RTTI_PlanDefinition_1.RTTI_PlanDefinition,
        RTTI_Practitioner_1.RTTI_Practitioner,
        RTTI_PractitionerRole_1.RTTI_PractitionerRole,
        RTTI_Procedure_1.RTTI_Procedure,
        RTTI_Provenance_1.RTTI_Provenance,
        RTTI_Questionnaire_1.RTTI_Questionnaire,
        RTTI_QuestionnaireResponse_1.RTTI_QuestionnaireResponse,
        RTTI_RelatedPerson_1.RTTI_RelatedPerson,
        RTTI_RequestGroup_1.RTTI_RequestGroup,
        RTTI_ResearchStudy_1.RTTI_ResearchStudy,
        RTTI_ResearchSubject_1.RTTI_ResearchSubject,
        RTTI_RiskAssessment_1.RTTI_RiskAssessment,
        RTTI_Schedule_1.RTTI_Schedule,
        RTTI_SearchParameter_1.RTTI_SearchParameter,
        RTTI_ServiceRequest_1.RTTI_ServiceRequest,
        RTTI_Slot_1.RTTI_Slot,
        RTTI_Specimen_1.RTTI_Specimen,
        RTTI_SpecimenDefinition_1.RTTI_SpecimenDefinition,
        RTTI_StructureDefinition_1.RTTI_StructureDefinition,
        RTTI_StructureMap_1.RTTI_StructureMap,
        RTTI_Subscription_1.RTTI_Subscription,
        RTTI_Substance_1.RTTI_Substance,
        RTTI_SubstancePolymer_1.RTTI_SubstancePolymer,
        RTTI_SubstanceReferenceInformation_1.RTTI_SubstanceReferenceInformation,
        RTTI_SubstanceSpecification_1.RTTI_SubstanceSpecification,
        RTTI_SupplyDelivery_1.RTTI_SupplyDelivery,
        RTTI_SupplyRequest_1.RTTI_SupplyRequest,
        RTTI_Task_1.RTTI_Task,
        RTTI_TerminologyCapabilities_1.RTTI_TerminologyCapabilities,
        RTTI_TestReport_1.RTTI_TestReport,
        RTTI_TestScript_1.RTTI_TestScript,
        RTTI_ValueSet_1.RTTI_ValueSet,
        RTTI_VerificationResult_1.RTTI_VerificationResult,
        RTTI_VisionPrescription_1.RTTI_VisionPrescription
    ]);
});
