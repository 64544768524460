import {faCalendarDay, faUserNurse} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Alert, Button, Collapse, Popconfirm, Space, Tag} from 'antd';
import moment from 'moment';
import React from 'react';
import {AvailabilityRuleType} from '../../../../interface-models/availability-rules/availability-rule-types/availability-rule-type';
import {UAvailabilityRuleTypes} from '../../../../interface-models/availability-rules/availability-rules';
import {Clinic} from '../../../../models/clinics';
import {useGlobalSettings} from '../../../../models/global-settings';
import {FhirUtils} from '../../../../services/fhir';
import {CopyIDTag} from '../../../util/copy-id-tag';
import {getLabelForDay} from './availability-rule-form-fields';
import {NonRecurringRuleFormItems, RecurringRuleFormItems} from './availability-rule-forms';

/**
 *
 *
 * @interface IAvailabilityRulePanelProps
 */
interface IAvailabilityRulePanelProps {
  /**
   * The current rule to render
   *
   * @type {UAvailabilityRuleTypes}
   * @memberof IAvailabilityRulePanelProps
   */
  rule: UAvailabilityRuleTypes;
  /**
   * Index of this rule in availabilities rule array
   *
   * @type {number}
   * @memberof IAvailabilityRulePanelProps
   */
  ruleIndex: number;
  onDeleteRule: () => void;

  clinic: Clinic;
}

/** Component used to render the panel which users to edit an availability rule */
export const AvailabilityPanel = (props: IAvailabilityRulePanelProps) => {
  const {rule, clinic} = props;

  const [appointmentsExist, setappointmentsExist] = React.useState(false);
  const client = FhirUtils.useAxiosClient();
  const settings = useGlobalSettings();
  const enableDose2Availability = settings['enableDose2Availability']?.value === 'true';

  React.useEffect(() => {
    client
      .get(`/appointment`, {
        params: {
          ruleId: rule.id,
          _exists: true,
          excludeCanceled: true,
        },
      })
      .then((res) => {
        console.log(res);
        setappointmentsExist(res.data.exists);
      });
  }, []);

  const headerStrings = {
    date: formatRuleDateForPanel(rule),
    day: formatRuleDayForPanel(rule),
    time: formatRuleTimesForPanel(rule),
    type: formatRuleTypeForPanel(props.rule),
  };

  /* This rule is part of an array. This prefix tell formik how to access
  this rule in the array. */
  const formNamePrefix = `availabilityRules[${props.ruleIndex}].`;
  const ruleForm =
    rule.type === AvailabilityRuleType.NonRecurring ? (
      <NonRecurringRuleFormItems
        namePrefix={formNamePrefix}
        rule={rule}
        clinic={clinic}
        enableDose2Availability={enableDose2Availability}
      />
    ) : (
      <RecurringRuleFormItems
        namePrefix={formNamePrefix}
        rule={rule}
        clinic={clinic}
        enableDose2Availability={enableDose2Availability}
      />
    );

  const dose2Rule = rule.dose2Rule
    ? rule.dose2Rule
    : rule.createDose2Availability
    ? createDose2RuleFromDose1Rule(rule, clinic)
    : undefined;

  const groupRule = rule.groupRule
    ? rule.groupRule
    : rule.createGroupAvailability
    ? {...rule}
    : undefined;

  const header = (
    <div style={{flex: 1}}>
      <div>
        <strong>{headerStrings.date}</strong>
      </div>
      <div>{headerStrings.time}</div>
      <div>{headerStrings.day}</div>
      <div style={{marginTop: 3}}>
        <Tag color={rule.type === AvailabilityRuleType.NonRecurring ? 'cyan' : 'blue'}>
          {headerStrings.type}
        </Tag>
        {dose2Rule && (
          <Tag color={'green'}>{`Dose 2 Availability - ${formatRuleDateForPanel(dose2Rule)}`}</Tag>
        )}
        {groupRule && <Tag color={'green'}>{`Group Availability`}</Tag>}
      </div>
    </div>
  );

  return (
    <Collapse style={{marginBottom: 15}}>
      <Collapse.Panel
        className="availability-rule-collapse-panel"
        key={props.ruleIndex}
        header={header}
        extra={
          <Space>
            <div style={{display: 'flex'}}>
              <div style={{paddingLeft: 5, paddingRight: 15}}>
                <FontAwesomeIcon icon={faCalendarDay} style={{marginRight: 10}} />
                {getRuleAppointmentCountDescription(rule)}
              </div>
              <div style={{paddingLeft: 5, paddingRight: 5}}>
                <FontAwesomeIcon icon={faUserNurse} style={{marginRight: 10}} />
                {getRuleImmunizerCountDescription(rule)}
              </div>
            </div>
            <div onClick={(e) => e.stopPropagation()}>
              <Popconfirm
                title="Are you sure you want to remove this availability?"
                onConfirm={props.onDeleteRule}
                okText="Yes"
                cancelText="No"
              >
                <Button disabled={appointmentsExist}>Delete</Button>
              </Popconfirm>
            </div>
          </Space>
        }
      >
        {appointmentsExist && (
          <div style={{marginBottom: 15}}>
            <Alert
              type="warning"
              showIcon
              message={`There are appointments associated with this availability rule. Do not delete this rule or reduce the effective availability of this rule without first canceling or rescheduling the associated appointments.`}
            />
          </div>
        )}
        {ruleForm}
        <CopyIDTag id={rule.id} label="Copy Dose 1 Rule ID" />
      </Collapse.Panel>
    </Collapse>
  );
};

function formatRuleTimesForPanel(rule: UAvailabilityRuleTypes): string {
  return `${moment.utc(rule.startTime).format('HH:mm A')} - ${moment
    .utc(rule.endTime)
    .format('HH:mm A')} (${rule.duration} min)`;
}

function formatRuleDateForPanel(rule: UAvailabilityRuleTypes): string {
  const DateFormat = 'dddd, MMMM D YYYY';

  switch (rule.type) {
    case AvailabilityRuleType.NonRecurring: {
      return moment.utc(rule.date).format(DateFormat);
    }
    case AvailabilityRuleType.RecurringDay: {
      const formattedStartDate = moment.utc(rule.startDate).format(DateFormat);
      const formattedEndDate = rule.endDate
        ? moment.utc(rule.endDate).format(DateFormat)
        : 'Not Defined';

      return `${formattedStartDate} - ${formattedEndDate}`;
    }
  }
}

function formatRuleDayForPanel(rule: UAvailabilityRuleTypes): string {
  switch (rule.type) {
    case AvailabilityRuleType.NonRecurring: {
      return ''; //moment.utc(rule.date).format('dddd');
    }
    case AvailabilityRuleType.RecurringDay: {
      return rule.days
        .map((day) => {
          return getLabelForDay(day);
        })
        .join(', ');
    }
  }
}

function formatRuleTypeForPanel(rule: UAvailabilityRuleTypes): string {
  switch (rule.type) {
    case AvailabilityRuleType.NonRecurring: {
      return 'Non Recurring';
    }
    case AvailabilityRuleType.RecurringDay: {
      return 'Recurring';
    }
  }
}

function createDose2RuleFromDose1Rule(rule, clinic) {
  switch (rule.type) {
    case AvailabilityRuleType.NonRecurring: {
      return {
        ...rule,
        date: moment(rule.date).add(clinic.targetSecondAppointmentInterval, 'days'),
      };
    }
    case AvailabilityRuleType.RecurringDay: {
      return {
        ...rule,
        startDate: moment(rule.startDate).add(clinic.targetSecondAppointmentInterval, 'days'),
        endDate: moment(rule.endDate).add(clinic.targetSecondAppointmentInterval, 'days'),
      };
    }
  }
}

export const getRuleAppointmentCountDescription = (rule) => {
  const rule2 = rule.createDose2Availability ? ' (Dose 1)' : '';
  const group = rule.createGroupAvailability
    ? ` (1 Primary + ${rule.groupRule?.groupCount || rule.groupCount} Attendees)`
    : '';
  return `${appointmentCountForRule(rule)} Slots${group}${rule2}`;
};

export const getRuleImmunizerCountDescription = (rule) => {
  const immunizer = rule.createGroupAvailability ? 'Primary Appointment' : 'Immunizer';
  return `${rule.immunizerCount} ${immunizer}${rule.immunizerCount > 1 ? 's' : ''}`;
};

export const appointmentCountForRule = (rule) => {
  switch (rule.type) {
    case AvailabilityRuleType.NonRecurring: {
      if (rule.endTime.isBefore(rule.startTime)) return 0;
      const minutes = rule.endTime.diff(rule.startTime, 'minutes');

      return rule.duration === 0 ? 0 : Math.floor(minutes / rule.duration) * rule.immunizerCount;
    }
    case AvailabilityRuleType.RecurringDay: {
      if (rule.endTime.isBefore(rule.startTime)) return 0;

      const timePerDay = rule.endTime.diff(rule.startTime, 'minutes');

      let dayIter = moment(rule.startDate);

      let activeDayCount = 0;
      while (dayIter.isSameOrBefore(rule.endDate)) {
        if (rule.days.indexOf(dayIter.get('day')) > -1) {
          activeDayCount++;
        }
        dayIter.add(1, 'day');
      }

      return rule.duration === 0
        ? 0
        : Math.floor(timePerDay / rule.duration) * activeDayCount * rule.immunizerCount;
    }
  }
};
