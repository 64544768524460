import {AbstractValidationRule} from './abstract-validation-rule';

export const EnumValidationRuleId = 'enum';

export interface EnumValidationRule extends AbstractValidationRule {
  validationRuleType: typeof EnumValidationRuleId;
  enumValues: any[];
}

/**
 * Checks whether the value is present from a list of values. Makes sure
 * that the type also matches.
 *
 * @export
 * @param {*} value
 * @param {EnumValidationRule} enumRule The enumValues field in this rule
 * is used as the list of allowable values.
 * @returns {boolean}
 */
export function validateEnumRule(value: any, enumRule: EnumValidationRule): boolean {
  return enumRule.enumValues.indexOf(value) > -1;
}
