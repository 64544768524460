import {Ids} from '@canimmunize/tools';
import {Alert, Button, Col, Row} from 'antd';
import MaskedInput from 'antd-mask-input';
import {FormikContextType, useFormikContext} from 'formik';
import {Form, Input, Radio} from 'formik-antd';
import parse from 'html-react-parser';
import React, {useEffect} from 'react';
import {ValidateFormItem} from '../../../../../components/validate-form-item';
import {useStr} from '../../../../../services/str';
import {
  EmailValidationRules,
  PhoneValidationRules,
} from '../../../../../services/ui-validation-rules';
import {RequiredValidationRuleId} from '../../../../../validation/validation-rules/required-validation';
import {YukonPortalPage} from '../../../components/yukon-portal-page';

export const ApplicantInformationPage = (props) => {
  const Str = useStr();
  const formikContext: FormikContextType<any> = useFormikContext();

  useEffect(() => {
    window.history.pushState(null, '', window.location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const postSubmit = async () => {
    formikContext.setFieldValue('error', undefined);
    formikContext.setFieldValue('mode', 'hci');
  };

  const pageBody = (
    <>
      {formikContext.values.error && (
        <Alert
          type="error"
          message={Str(formikContext.values.error)}
          style={{marginBottom: 15}}
          showIcon
        />
      )}
      <ValidateFormItem
        validationRules={[
          {
            validationRuleType: RequiredValidationRuleId,
          },
        ]}
        renderFormItem={(validate) => {
          return (
            <Form.Item
              label={
                <>
                  <b>{Str(Ids.yk_pvc_requesting_for_who)}</b>
                </>
              }
              name="reqForSelf"
              required
              validate={validate}
            >
              <Radio.Group
                options={[
                  {
                    label: Str(Ids.yk_requesting_for_self),
                    value: true,
                    style: {display: 'block'},
                  },
                  {
                    label: Str(Ids.yk_requesting_for_other),
                    value: false,
                    style: {display: 'block'},
                  },
                ]}
                name="reqForSelf"
              ></Radio.Group>
            </Form.Item>
          );
        }}
      />

      {formikContext.values.reqForSelf !== null && !formikContext.values.reqForSelf && (
        <div
          style={{
            border: '1px solid transparent',
            borderColor: '#ddd',
            borderRadius: '4px',
            boxShadow: '0 1px 1px rgb(0 0 0 / 5%)',
            fontSize: 14,
            margin: '20px 0px',
          }}
        >
          <div
            style={{
              color: '#333333',
              backgroundColor: '#f5f5f5',
              borderColor: '#ddd',
              padding: '10px 15px',
              borderBottom: '1px solid transparent',
            }}
          >
            {Str(Ids.yk_info_about_you_title)}
          </div>
          <div style={{padding: '15px'}}>
            {parse(Str(Ids.yk_info_about_you_info))}

            <ValidateFormItem
              validationRules={[
                {
                  validationRuleType: RequiredValidationRuleId,
                },
              ]}
              renderFormItem={(validate) => {
                return (
                  <Form.Item
                    label={<b>{Str(Ids.yk_pvc_have_forms_been_submitted)}</b>}
                    name="submittedReqForOtherForm"
                    validate={validate}
                    required
                  >
                    <Radio.Group
                      options={[
                        {
                          label: Str(Ids.yk_submitted_req_forms),
                          value: true,
                          style: {display: 'block'},
                        },
                        {
                          label: Str(Ids.yk_no_req_forms),
                          value: false,
                          style: {display: 'block'},
                        },
                      ]}
                      name="submittedReqForOtherForm"
                    ></Radio.Group>
                  </Form.Item>
                );
              }}
            />

            {formikContext.values.submittedReqForOtherForm !== null &&
              !formikContext.values.submittedReqForOtherForm && (
                <Alert
                  type="warning"
                  message={<>{parse(Str(Ids.yk_submit_req_forms_warning))}</>}
                ></Alert>
              )}
            {formikContext.values.submittedReqForOtherForm !== null &&
              formikContext.values.submittedReqForOtherForm && (
                <>
                  <Row gutter={24}>
                    <Col span={24} md={12}>
                      <ValidateFormItem
                        validationRules={[{validationRuleType: RequiredValidationRuleId}]}
                        renderFormItem={(validate) => {
                          return (
                            <Form.Item
                              label={Str(Ids.yk_your_first_name)}
                              name="reqFirstName"
                              required
                              validate={validate}
                            >
                              <Input name="reqFirstName" size="large" autoFocus />
                            </Form.Item>
                          );
                        }}
                      />
                    </Col>
                    <Col span={24} md={12}>
                      <ValidateFormItem
                        validationRules={[{validationRuleType: RequiredValidationRuleId}]}
                        renderFormItem={(validate) => {
                          return (
                            <Form.Item
                              label={Str(Ids.yk_your_last_name)}
                              name="reqLastName"
                              required
                              validate={validate}
                            >
                              <Input name="reqLastName" size="large" />
                            </Form.Item>
                          );
                        }}
                      />
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col span={24} md={12}>
                      <ValidateFormItem
                        validationRules={[
                          {validationRuleType: RequiredValidationRuleId},
                          ...PhoneValidationRules,
                        ]}
                        renderFormItem={(validate) => {
                          return (
                            <Form.Item
                              label={Str(Ids.yk_your_phone_number)}
                              name="reqPhone"
                              required
                              validate={validate}
                            >
                              <MaskedInput
                                defaultValue={formikContext.values.reqPhone}
                                onChange={(e) => {
                                  formikContext.setFieldValue('reqPhone', e.target.value);
                                }}
                                name="reqPhone"
                                size="large"
                                mask="(111) 111-1111"
                              />
                            </Form.Item>
                          );
                        }}
                      />
                    </Col>
                    <Col span={24} md={12}>
                      <ValidateFormItem
                        validationRules={[
                          {
                            validationRuleType: RequiredValidationRuleId,
                          },
                          ...EmailValidationRules,
                        ]}
                        renderFormItem={(validate) => {
                          return (
                            <Form.Item
                              label={Str(Ids.yk_your_email)}
                              name="reqEmail"
                              required
                              validate={validate}
                            >
                              <Input name="reqEmail" size="large" />
                            </Form.Item>
                          );
                        }}
                      />
                    </Col>
                  </Row>
                </>
              )}
          </div>
        </div>
      )}

      <ValidateFormItem
        validationRules={[
          {
            validationRuleType: RequiredValidationRuleId,
          },
        ]}
        renderFormItem={(validate) => {
          return (
            <Form.Item
              label={<b>{Str(Ids.yk_situation)}</b>}
              name="vaxLocation"
              validate={validate}
              required
            >
              <Radio.Group
                options={[
                  {
                    label: Str(Ids.yk_all_shots_yukon),
                    value: 'yukon',
                    style: {display: 'block'},
                  },
                  {
                    label: Str(Ids.yk_shots_elsewhere),
                    value: 'not-yukon',
                    style: {display: 'block'},
                  },
                  {
                    label: Str(Ids.yk_medical_deferral),
                    value: 'deferral',
                    style: {display: 'block'},
                  },
                ]}
                name="vaxLocation"
              ></Radio.Group>
            </Form.Item>
          );
        }}
      />

      {formikContext.values.vaxLocation === 'not-yukon' && (
        <>
          <ValidateFormItem
            validationRules={[
              {
                validationRuleType: RequiredValidationRuleId,
              },
            ]}
            renderFormItem={(validate) => {
              return (
                <Form.Item
                  label={<b>{Str(Ids.yk_shots_elsewhere_submitted)}</b>}
                  name="locationRecordsSubmitted"
                  validate={validate}
                  required
                >
                  <Radio.Group
                    options={[
                      {
                        label: Str(Ids.yk_shots_elsewhere_are_submitted),
                        value: true,
                        style: {display: 'block'},
                      },
                      {
                        label: Str(Ids.yk_shots_elsewhere_not_submitted),
                        value: false,
                        style: {display: 'block'},
                      },
                    ]}
                    name="locationRecordsSubmitted"
                  ></Radio.Group>
                </Form.Item>
              );
            }}
          />

          {formikContext.values.locationRecordsSubmitted !== null &&
            !formikContext.values.locationRecordsSubmitted && (
              <Alert
                type="warning"
                style={{marginBottom: 24}}
                message={<>{parse(Str(Ids.yk_submit_req_forms_warning))}</>}
              ></Alert>
            )}
        </>
      )}

      {formikContext.values.vaxLocation === 'deferral' && (
        <>
          <ValidateFormItem
            validationRules={[
              {
                validationRuleType: RequiredValidationRuleId,
              },
            ]}
            renderFormItem={(validate) => {
              return (
                <Form.Item
                  label={<b>{Str(Ids.yk_medical_exemption_approved)}</b>}
                  name="deferralApproved"
                  validate={validate}
                  required
                >
                  <Radio.Group
                    options={[
                      {
                        label: Str(Ids.yk_medical_exemption_is_approved),
                        value: true,
                        style: {display: 'block'},
                      },
                      {
                        label: Str(Ids.yk_medical_exemption_not_approved),
                        value: false,
                        style: {display: 'block'},
                      },
                    ]}
                    name="deferralApproved"
                  ></Radio.Group>
                </Form.Item>
              );
            }}
          />

          {formikContext.values.deferralApproved !== null &&
            !formikContext.values.deferralApproved && (
              <Alert
                type="warning"
                style={{marginBottom: 24}}
                message={<>{parse(Str(Ids.yk_medical_exemption_warning))}</>}
              ></Alert>
            )}

          {formikContext.values.deferralApproved !== null &&
            formikContext.values.deferralApproved && (
              <Alert
                type="warning"
                style={{marginBottom: 24}}
                message={<>{parse(Str(Ids.yk_medical_exemption_info))}</>}
              ></Alert>
            )}
        </>
      )}
    </>
  );

  const pageFooter = (
    <>
      <Button
        size="large"
        onClick={() => {
          formikContext.setFieldValue('error', undefined);
          formikContext.setErrors({});
          window.history.go(-1);
        }}
        style={{
          border: '1px solid',
          marginRight: '10px',
          marginBottom: '10px',
          width: '200px',
        }}
      >
        {Str(Ids.yk_pvc_go_back_step)}
      </Button>
      <Button
        size="large"
        type="primary"
        disabled={
          (formikContext.values.vaxLocation === 'deferral' &&
            formikContext.values.deferralApproved !== null) ||
          (formikContext.values.vaxLocation === 'not-yukon' &&
            formikContext.values.locationRecordsSubmitted !== null &&
            !formikContext.values.locationRecordsSubmitted) ||
          (!formikContext.values.reqForSelf &&
            formikContext.values.submittedReqForOtherForm !== null &&
            !formikContext.values.submittedReqForOtherForm)
        }
        onClick={() => {
          formikContext.setFieldValue('subFormSubmit', postSubmit);
          formikContext.handleSubmit();
        }}
        style={{
          width: '200px',
        }}
      >
        {Str(Ids.yk_pvc_next_step)}
      </Button>
    </>
  );

  return (
    <>
      <YukonPortalPage
        pageNumber={
          formikContext.values.isYukonCallCentre ? Str(Ids.yk_cc_page2) : Str(Ids.yk_pvc_page2)
        }
        title={Str(Ids.yk_pvc_applicant_info)}
        body={pageBody}
        footer={pageFooter}
        backPage="info"
      ></YukonPortalPage>
    </>
  );
};
