import {Button, message, Popconfirm, Space} from 'antd';
import React from 'react';
import {FhirUtils} from '../../services/fhir';

export const CohortListPatientActions = ({
  patient,
  cohort,
  triggerRefresh,
  type = 'Group',
  itemType = 'patient',
}) => {
  const client = FhirUtils.useAxiosClient();

  const removePatientFromCohort = async () => {
    const errMessage =
      itemType === 'patient'
        ? 'Error removing patient from cohort.'
        : 'Error removing the patient from this list.';
    const result = await client
      .delete(`/${type}/${cohort.id}/${itemType}/${patient.id}`)
      .catch((err) => message.error(errMessage));

    if (result.status === 200) {
      message.success(`Patient successfully removed!`);
      triggerRefresh();
    }
  };

  const rUSureMsg =
    itemType === 'patient'
      ? 'Are you sure you want to unlink the patient from this cohort?'
      : 'Are you sure you want to unlink this patient from this list?';
  return (
    <Space>
      <div onClick={(e) => e.stopPropagation()}>
        <Popconfirm
          title={rUSureMsg}
          onConfirm={removePatientFromCohort}
          okText="Yes"
          cancelText="No"
        >
          <Button href="#" type="link" size={'small'} style={{paddingLeft: 10, paddingRight: 10}}>
            Unlink
          </Button>
        </Popconfirm>
      </div>
    </Space>
  );
};
