import {Tag} from 'antd';
import React from 'react';

export const AccountStatusTag = (props: {active: boolean}) => {
  let text;
  let color;
  if (props.active === true) {
    color = 'green';
    text = 'Active';
  } else {
    text = 'Disabled';
  }
  return <Tag color={color}>{text}</Tag>;
};

export const EmailVerificationStatusTag = (props: {verified: boolean}) => {
  let text;
  let color;
  if (props.verified === true) {
    color = 'green';
    text = 'Verified';
  } else {
    color = 'red'
    text = 'Unverified';
  }
  return <Tag color={color}>{text}</Tag>;
};
