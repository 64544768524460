export function defineYKSuperAdminRules(builder) {
  const {can} = builder;
  // Menus
  can('access', 'menu', [
    'home',
    'appointments',
    'campaigns',
    'cohorts',
    'lists',
    'bookingpages',
    'reports',
    'import',
    'organizations',
    'clinics',
    'users',
    'roles',
    'orgadminusers',
  ]);
  // Routes
  can('access', 'route', [
    '/home',
    '/appointments**',
    '/campaigns**',
    '/cohorts**',
    '/lists**',
    '/bookingpages**',
    '/reports',
    '/import',
    '/organizations**',
    '/clinics**',
    '/users**',
    '/roles**',
    '/orgadminusers**',
  ]);
  // Permissions
  can('view', 'patients', ['aefiWidget', 'notesWidget', 'adminWidget']);
  can('view', 'clinics', ['calendarsTab', 'cohortsTab', 'immunizersTab', 'notesWidget']);
  can(['create', 'update'], 'clinics', 'clinic');
  can('execute', 'appointments', ['blockRule']);
  can('view', ['orgAdminUsers', 'clinicUsers']);
  can('create', 'patients', 'appointment');
  can('import', ['patients', 'clinics']);
}
