"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_instant = exports.RTTI_instantType = void 0;
var io_ts_1 = require("io-ts");
var RTTI_instantType = (function (_super) {
    __extends(RTTI_instantType, _super);
    function RTTI_instantType() {
        var _this = _super.call(this, 'RTTI_instantType', function (m) {
            return typeof m === 'string' && RTTI_instantType.regexExp.test(m.toString());
        }, function (m, c) { return (_this.is(m) ? io_ts_1.success(m) : io_ts_1.failure(m, c)); }, io_ts_1.identity) || this;
        return _this;
    }
    RTTI_instantType.regexExp = /^([0-9]([0-9]([0-9][1-9]|[1-9]0)|[1-9]00)|[1-9]000)-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])T([01][0-9]|2[0-3]):[0-5][0-9]:([0-5][0-9]|60)(\.[0-9]+)?(Z|(\+|-)((0[0-9]|1[0-3]):[0-5][0-9]|14:00))$/;
    return RTTI_instantType;
}(io_ts_1.Type));
exports.RTTI_instantType = RTTI_instantType;
exports.RTTI_instant = new RTTI_instantType();
