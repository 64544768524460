"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_StructureMap_Source = exports.StructureMap_SourceListModeKind = void 0;
var t = __importStar(require("io-ts"));
var RTTI_Extension_1 = require("./RTTI_Extension");
var RTTI_id_1 = require("../Scalar/RTTI_id");
var RTTI_Element_1 = require("./RTTI_Element");
var RTTI_integer_1 = require("../Scalar/RTTI_integer");
var RTTI_Address_1 = require("./RTTI_Address");
var RTTI_Age_1 = require("./RTTI_Age");
var RTTI_Annotation_1 = require("./RTTI_Annotation");
var RTTI_Attachment_1 = require("./RTTI_Attachment");
var RTTI_CodeableConcept_1 = require("./RTTI_CodeableConcept");
var RTTI_Coding_1 = require("./RTTI_Coding");
var RTTI_ContactPoint_1 = require("./RTTI_ContactPoint");
var RTTI_Count_1 = require("./RTTI_Count");
var RTTI_Distance_1 = require("./RTTI_Distance");
var RTTI_Duration_1 = require("./RTTI_Duration");
var RTTI_HumanName_1 = require("./RTTI_HumanName");
var RTTI_Identifier_1 = require("./RTTI_Identifier");
var RTTI_Money_1 = require("./RTTI_Money");
var RTTI_Period_1 = require("./RTTI_Period");
var RTTI_Quantity_1 = require("./RTTI_Quantity");
var RTTI_Range_1 = require("./RTTI_Range");
var RTTI_Ratio_1 = require("./RTTI_Ratio");
var RTTI_Reference_1 = require("./RTTI_Reference");
var RTTI_SampledData_1 = require("./RTTI_SampledData");
var RTTI_Signature_1 = require("./RTTI_Signature");
var RTTI_Timing_1 = require("./RTTI_Timing");
var RTTI_ContactDetail_1 = require("./RTTI_ContactDetail");
var RTTI_Contributor_1 = require("./RTTI_Contributor");
var RTTI_DataRequirement_1 = require("./RTTI_DataRequirement");
var RTTI_Expression_1 = require("./RTTI_Expression");
var RTTI_ParameterDefinition_1 = require("./RTTI_ParameterDefinition");
var RTTI_RelatedArtifact_1 = require("./RTTI_RelatedArtifact");
var RTTI_TriggerDefinition_1 = require("./RTTI_TriggerDefinition");
var RTTI_UsageContext_1 = require("./RTTI_UsageContext");
var RTTI_Dosage_1 = require("./RTTI_Dosage");
var StructureMap_SourceListModeKind;
(function (StructureMap_SourceListModeKind) {
    StructureMap_SourceListModeKind["_first"] = "first";
    StructureMap_SourceListModeKind["_notFirst"] = "not_first";
    StructureMap_SourceListModeKind["_last"] = "last";
    StructureMap_SourceListModeKind["_notLast"] = "not_last";
    StructureMap_SourceListModeKind["_onlyOne"] = "only_one";
})(StructureMap_SourceListModeKind = exports.StructureMap_SourceListModeKind || (exports.StructureMap_SourceListModeKind = {}));
var EnumType_1 = require("../../EnumType");
exports.RTTI_StructureMap_Source = t.recursion('IStructureMap_Source', function () {
    return t.partial({
        id: t.string,
        extension: t.array(RTTI_Extension_1.RTTI_Extension),
        modifierExtension: t.array(RTTI_Extension_1.RTTI_Extension),
        context: RTTI_id_1.RTTI_id,
        _context: RTTI_Element_1.RTTI_Element,
        min: RTTI_integer_1.RTTI_integer,
        _min: RTTI_Element_1.RTTI_Element,
        max: t.string,
        _max: RTTI_Element_1.RTTI_Element,
        type: t.string,
        _type: RTTI_Element_1.RTTI_Element,
        defaultValueBase64Binary: t.string,
        _defaultValueBase64Binary: RTTI_Element_1.RTTI_Element,
        defaultValueBoolean: t.boolean,
        _defaultValueBoolean: RTTI_Element_1.RTTI_Element,
        defaultValueCanonical: t.string,
        _defaultValueCanonical: RTTI_Element_1.RTTI_Element,
        defaultValueCode: t.string,
        _defaultValueCode: RTTI_Element_1.RTTI_Element,
        defaultValueDate: t.string,
        _defaultValueDate: RTTI_Element_1.RTTI_Element,
        defaultValueDateTime: t.string,
        _defaultValueDateTime: RTTI_Element_1.RTTI_Element,
        defaultValueDecimal: t.number,
        _defaultValueDecimal: RTTI_Element_1.RTTI_Element,
        defaultValueId: t.string,
        _defaultValueId: RTTI_Element_1.RTTI_Element,
        defaultValueInstant: t.string,
        _defaultValueInstant: RTTI_Element_1.RTTI_Element,
        defaultValueInteger: t.number,
        _defaultValueInteger: RTTI_Element_1.RTTI_Element,
        defaultValueMarkdown: t.string,
        _defaultValueMarkdown: RTTI_Element_1.RTTI_Element,
        defaultValueOid: t.string,
        _defaultValueOid: RTTI_Element_1.RTTI_Element,
        defaultValuePositiveInt: t.number,
        _defaultValuePositiveInt: RTTI_Element_1.RTTI_Element,
        defaultValueString: t.string,
        _defaultValueString: RTTI_Element_1.RTTI_Element,
        defaultValueTime: t.string,
        _defaultValueTime: RTTI_Element_1.RTTI_Element,
        defaultValueUnsignedInt: t.number,
        _defaultValueUnsignedInt: RTTI_Element_1.RTTI_Element,
        defaultValueUri: t.string,
        _defaultValueUri: RTTI_Element_1.RTTI_Element,
        defaultValueUrl: t.string,
        _defaultValueUrl: RTTI_Element_1.RTTI_Element,
        defaultValueUuid: t.string,
        _defaultValueUuid: RTTI_Element_1.RTTI_Element,
        defaultValueAddress: RTTI_Address_1.RTTI_Address,
        defaultValueAge: RTTI_Age_1.RTTI_Age,
        defaultValueAnnotation: RTTI_Annotation_1.RTTI_Annotation,
        defaultValueAttachment: RTTI_Attachment_1.RTTI_Attachment,
        defaultValueCodeableConcept: RTTI_CodeableConcept_1.RTTI_CodeableConcept,
        defaultValueCoding: RTTI_Coding_1.RTTI_Coding,
        defaultValueContactPoint: RTTI_ContactPoint_1.RTTI_ContactPoint,
        defaultValueCount: RTTI_Count_1.RTTI_Count,
        defaultValueDistance: RTTI_Distance_1.RTTI_Distance,
        defaultValueDuration: RTTI_Duration_1.RTTI_Duration,
        defaultValueHumanName: RTTI_HumanName_1.RTTI_HumanName,
        defaultValueIdentifier: RTTI_Identifier_1.RTTI_Identifier,
        defaultValueMoney: RTTI_Money_1.RTTI_Money,
        defaultValuePeriod: RTTI_Period_1.RTTI_Period,
        defaultValueQuantity: RTTI_Quantity_1.RTTI_Quantity,
        defaultValueRange: RTTI_Range_1.RTTI_Range,
        defaultValueRatio: RTTI_Ratio_1.RTTI_Ratio,
        defaultValueReference: RTTI_Reference_1.RTTI_Reference,
        defaultValueSampledData: RTTI_SampledData_1.RTTI_SampledData,
        defaultValueSignature: RTTI_Signature_1.RTTI_Signature,
        defaultValueTiming: RTTI_Timing_1.RTTI_Timing,
        defaultValueContactDetail: RTTI_ContactDetail_1.RTTI_ContactDetail,
        defaultValueContributor: RTTI_Contributor_1.RTTI_Contributor,
        defaultValueDataRequirement: RTTI_DataRequirement_1.RTTI_DataRequirement,
        defaultValueExpression: RTTI_Expression_1.RTTI_Expression,
        defaultValueParameterDefinition: RTTI_ParameterDefinition_1.RTTI_ParameterDefinition,
        defaultValueRelatedArtifact: RTTI_RelatedArtifact_1.RTTI_RelatedArtifact,
        defaultValueTriggerDefinition: RTTI_TriggerDefinition_1.RTTI_TriggerDefinition,
        defaultValueUsageContext: RTTI_UsageContext_1.RTTI_UsageContext,
        defaultValueDosage: RTTI_Dosage_1.RTTI_Dosage,
        element: t.string,
        _element: RTTI_Element_1.RTTI_Element,
        listMode: EnumType_1.createEnumType(StructureMap_SourceListModeKind, 'StructureMap_SourceListModeKind'),
        _listMode: RTTI_Element_1.RTTI_Element,
        variable: RTTI_id_1.RTTI_id,
        _variable: RTTI_Element_1.RTTI_Element,
        condition: t.string,
        _condition: RTTI_Element_1.RTTI_Element,
        check: t.string,
        _check: RTTI_Element_1.RTTI_Element,
        logMessage: t.string,
        _logMessage: RTTI_Element_1.RTTI_Element
    });
});
