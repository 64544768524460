"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_CodeSystem = exports.CodeSystemContentKind = exports.CodeSystemHierarchyMeaningKind = exports.CodeSystemStatusKind = void 0;
var t = __importStar(require("io-ts"));
var RTTI_id_1 = require("../Scalar/RTTI_id");
var RTTI_Meta_1 = require("./RTTI_Meta");
var RTTI_uri_1 = require("../Scalar/RTTI_uri");
var RTTI_Element_1 = require("./RTTI_Element");
var RTTI_code_1 = require("../Scalar/RTTI_code");
var RTTI_Narrative_1 = require("./RTTI_Narrative");
var RTTI_ResourceList_1 = require("../Union/RTTI_ResourceList");
var RTTI_Extension_1 = require("./RTTI_Extension");
var RTTI_Identifier_1 = require("./RTTI_Identifier");
var RTTI_dateTime_1 = require("../Scalar/RTTI_dateTime");
var RTTI_ContactDetail_1 = require("./RTTI_ContactDetail");
var RTTI_markdown_1 = require("../Scalar/RTTI_markdown");
var RTTI_UsageContext_1 = require("./RTTI_UsageContext");
var RTTI_CodeableConcept_1 = require("./RTTI_CodeableConcept");
var RTTI_canonical_1 = require("../Scalar/RTTI_canonical");
var RTTI_unsignedInt_1 = require("../Scalar/RTTI_unsignedInt");
var RTTI_CodeSystem_Filter_1 = require("./RTTI_CodeSystem_Filter");
var RTTI_CodeSystem_Property_1 = require("./RTTI_CodeSystem_Property");
var RTTI_CodeSystem_Concept_1 = require("./RTTI_CodeSystem_Concept");
var CodeSystemStatusKind;
(function (CodeSystemStatusKind) {
    CodeSystemStatusKind["_draft"] = "draft";
    CodeSystemStatusKind["_active"] = "active";
    CodeSystemStatusKind["_retired"] = "retired";
    CodeSystemStatusKind["_unknown"] = "unknown";
})(CodeSystemStatusKind = exports.CodeSystemStatusKind || (exports.CodeSystemStatusKind = {}));
var CodeSystemHierarchyMeaningKind;
(function (CodeSystemHierarchyMeaningKind) {
    CodeSystemHierarchyMeaningKind["_groupedBy"] = "grouped-by";
    CodeSystemHierarchyMeaningKind["_isA"] = "is-a";
    CodeSystemHierarchyMeaningKind["_partOf"] = "part-of";
    CodeSystemHierarchyMeaningKind["_classifiedWith"] = "classified-with";
})(CodeSystemHierarchyMeaningKind = exports.CodeSystemHierarchyMeaningKind || (exports.CodeSystemHierarchyMeaningKind = {}));
var CodeSystemContentKind;
(function (CodeSystemContentKind) {
    CodeSystemContentKind["_notPresent"] = "not-present";
    CodeSystemContentKind["_example"] = "example";
    CodeSystemContentKind["_fragment"] = "fragment";
    CodeSystemContentKind["_complete"] = "complete";
    CodeSystemContentKind["_supplement"] = "supplement";
})(CodeSystemContentKind = exports.CodeSystemContentKind || (exports.CodeSystemContentKind = {}));
var EnumType_1 = require("../../EnumType");
exports.RTTI_CodeSystem = t.recursion('ICodeSystem', function () {
    return t.intersection([
        t.type({
            resourceType: t.literal('CodeSystem')
        }),
        t.partial({
            id: RTTI_id_1.RTTI_id,
            meta: RTTI_Meta_1.RTTI_Meta,
            implicitRules: RTTI_uri_1.RTTI_uri,
            _implicitRules: RTTI_Element_1.RTTI_Element,
            language: RTTI_code_1.RTTI_code,
            _language: RTTI_Element_1.RTTI_Element,
            text: RTTI_Narrative_1.RTTI_Narrative,
            contained: t.array(RTTI_ResourceList_1.RTTI_ResourceList),
            extension: t.array(RTTI_Extension_1.RTTI_Extension),
            modifierExtension: t.array(RTTI_Extension_1.RTTI_Extension),
            url: RTTI_uri_1.RTTI_uri,
            _url: RTTI_Element_1.RTTI_Element,
            identifier: t.array(RTTI_Identifier_1.RTTI_Identifier),
            version: t.string,
            _version: RTTI_Element_1.RTTI_Element,
            name: t.string,
            _name: RTTI_Element_1.RTTI_Element,
            title: t.string,
            _title: RTTI_Element_1.RTTI_Element,
            status: EnumType_1.createEnumType(CodeSystemStatusKind, 'CodeSystemStatusKind'),
            _status: RTTI_Element_1.RTTI_Element,
            experimental: t.boolean,
            _experimental: RTTI_Element_1.RTTI_Element,
            date: RTTI_dateTime_1.RTTI_dateTime,
            _date: RTTI_Element_1.RTTI_Element,
            publisher: t.string,
            _publisher: RTTI_Element_1.RTTI_Element,
            contact: t.array(RTTI_ContactDetail_1.RTTI_ContactDetail),
            description: RTTI_markdown_1.RTTI_markdown,
            _description: RTTI_Element_1.RTTI_Element,
            useContext: t.array(RTTI_UsageContext_1.RTTI_UsageContext),
            jurisdiction: t.array(RTTI_CodeableConcept_1.RTTI_CodeableConcept),
            purpose: RTTI_markdown_1.RTTI_markdown,
            _purpose: RTTI_Element_1.RTTI_Element,
            copyright: RTTI_markdown_1.RTTI_markdown,
            _copyright: RTTI_Element_1.RTTI_Element,
            caseSensitive: t.boolean,
            _caseSensitive: RTTI_Element_1.RTTI_Element,
            valueSet: RTTI_canonical_1.RTTI_canonical,
            hierarchyMeaning: EnumType_1.createEnumType(CodeSystemHierarchyMeaningKind, 'CodeSystemHierarchyMeaningKind'),
            _hierarchyMeaning: RTTI_Element_1.RTTI_Element,
            compositional: t.boolean,
            _compositional: RTTI_Element_1.RTTI_Element,
            versionNeeded: t.boolean,
            _versionNeeded: RTTI_Element_1.RTTI_Element,
            content: EnumType_1.createEnumType(CodeSystemContentKind, 'CodeSystemContentKind'),
            _content: RTTI_Element_1.RTTI_Element,
            supplements: RTTI_canonical_1.RTTI_canonical,
            count: RTTI_unsignedInt_1.RTTI_unsignedInt,
            _count: RTTI_Element_1.RTTI_Element,
            filter: t.array(RTTI_CodeSystem_Filter_1.RTTI_CodeSystem_Filter),
            property: t.array(RTTI_CodeSystem_Property_1.RTTI_CodeSystem_Property),
            concept: t.array(RTTI_CodeSystem_Concept_1.RTTI_CodeSystem_Concept)
        })
    ]);
});
