import {Typography} from 'antd';
import React, {ReactNode, useState} from 'react';
import {SectionTitle} from '../components/terminology-titles';

const {Text} = Typography;

export const DetailedSection = (props: {
  title: string;
  children: ReactNode;
  titlePresence: boolean;
}) => {
  return (
    <div>
      <SectionTitle title={props.title} presence={props.titlePresence} />
      {props.children}
    </div>
  );
};

export const ExpandableList = (props: {items: {title: string | null; link: string}[]}) => {
  const linkStyle = {color: '#8c8c8c'};
  const buttonStyle = {color: '#bfbfbf', fontSize: 12};
  const [expanded, setExpanded] = useState(false);
  if (props.items.length === 0) {
    return (
      <div style={{marginBottom: '20px'}}>
        <Text disabled>None</Text>
      </div>
    );
  }
  if (props.items.length <= 3) {
    return (
      <div style={{marginBottom: '20px'}}>
        {props.items.map((item) => {
          return (
            <div>
              <a href={item.link} style={linkStyle}>
                {item.title}
              </a>
            </div>
          );
        })}
      </div>
    );
  }
  return (
    <div style={{marginBottom: '20px', padding: '0px'}}>
      {props.items.slice(0, expanded ? props.items.length : 2).map((item) => {
        return (
          <div>
            <a href={item.link} style={linkStyle}>
              {item.title}
            </a>
          </div>
        );
      })}

      <a
        style={buttonStyle}
        onClick={() => {
          setExpanded(!expanded);
        }}
      >
        {expanded ? 'Show less' : 'Show more'}
      </a>
    </div>
  );
};

export const EnglishFrenchOutput = (props: {Lang: {en: string | null; fr: string | null}}) => {
  const concept = props.Lang;
  const labelStyle = {color: '#f5222d'};
  return (
    <div>
      <div>
        <span style={labelStyle}>English </span>
        <span>{concept.en}</span>
      </div>
      <div>
        <span style={labelStyle}>French </span>
        <span>{concept.fr}</span>
      </div>
    </div>
  );
};
