import {Space} from 'antd';
import moment from 'moment';
import React from 'react';

export const ReportDateCell = ({report}) => {
  const date = report?.timestamp;

  return (
    <Space wrap>
      <>{`${moment(date).format('YYYY-MM-DD')}`}</>
    </Space>
  );
};
