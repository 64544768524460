import {Ids} from '@canimmunize/tools';
import {
  faAngleLeft,
  faAngleRight,
  faCalendar,
  faCheckCircle,
  faClock,
  faEnvelope,
  faExclamationTriangle,
  faFileSignature,
  faMapMarkerAlt,
  faSyringe,
  faCheck,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Card, Col, message, Popconfirm, Row, Space} from 'antd';
import moment from 'moment-timezone';
import 'moment/min/locales';
import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {CardBody, CardSubtitle, CardTitle} from 'reactstrap';
import {fullDisplayName} from '../../models/fhir/patient';
import {useStr} from '../../services/str';
import {AppointmentMap} from './appointment-map';
import {AppointmentCard} from './booking-form/appointment-type-picker';
import {BFCard} from './booking-form/card';
import {AlertCard} from './consent-form/message-card';
import {AppointmentActionButtons, ReschedulerContainer} from './rescheduler-container';
import {useUrlData} from './util';
import {appointmentDateString} from '../../util/helpers/appointment-date-string';
import {filterRequiredFormTypesForConfig} from './consent-form/patient-forms';
import {AppointmentGroupModal} from './booking-form/appointment-group-modal';
import ReactMarkdown from 'react-markdown';
import axios from 'axios';
import {useUnAuthClient} from '../../services/fhir';
import s from './styles.module.css';

export const AppointmentSummary = (props) => {
  const Str = useStr();
  const {lang, config, theme, syncUrl, bookingPageSlug, query} = useUrlData();
  moment.locale(lang);
  const navigate = useNavigate();
  const {appointment} = props;
  const isSelfCheckInClinic = appointment?.calendar?.clinic?.selfCheckInClinic;
  const {hideAppointmentDetails, setHideAppointmentDetails} = props;
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [isSecondaryAvailable, setIsSecondaryAvailable] = useState({
    isUnderMaxDoses: false,
    isUnderGroupCount: false,
  });
  const apptCode = appointment?.apptCode ?? '';
  const appointmentCode = `${apptCode.toString().slice(0, 4)} - ${apptCode.toString().slice(4)}`;
  const [settings, setSettings] = useState<any>({});
  const showSecondDoseInfo = settings['showSecondDoseInfo']?.value === 'true';
  const enableDose2Availability = settings['enableDose2Availability']?.value === 'true';
  const client = useUnAuthClient();

  // Check secondary appointments availability for Drive Thru(Group)
  // Returns boolean result depending on MaxDose and GroupCount
  useEffect(() => {
    if (appointment.appointmentGroup) {
      axios
        .get(syncUrl + `/public/availability/group/${appointment.id}`)
        .then((res) =>
          setIsSecondaryAvailable({
            isUnderMaxDoses: res.data['isUnderMaxDoses'],
            isUnderGroupCount: res.data['isUnderGroupCount'],
          })
        )
        .catch((err) => message.error('Failed to get availability for second appointments'));
    }
  }, []);

  useEffect(() => {
    getGlobalSettings();
  }, []);

  async function getGlobalSettings() {
    // Get all public global settings
    const response = await client.get('/public/settings/booking');
    setSettings(response.data);
  }

  if (!appointment) return null;

  const cancelSecondaryAppointment = async (id) => {
    axios
      .get(syncUrl + `/public/appointment/${id}/cancel`, {
        validateStatus: (status) => {
          return status >= 200 && status < 500;
        },
      })
      .then((res) => window.location.reload())
      .catch((err) => message.error('Failed to cancel an appointment'));
  };

  const onRowClick = () => {
    setAddModalVisible(true);
  };

  const onClose = () => {
    setAddModalVisible(false);
  };

  const where = appointment.calendar.clinic?.location?.display[lang];
  const when = moment.tz(appointment.datetime, config.timezone).format('ddd, MMMM Do YYYY');
  const time = moment.tz(appointment.datetime, config.timezone).format('HH:mm A zz');

  const InfoSectionTitle = ({title}: {title: string}) => (
    <div style={{color: theme.blue, fontSize: 14, fontWeight: 'bold'}}>{title.toUpperCase()}</div>
  );

  const headline =
    (query.booked === 'true' || props.booked) && props.formsMode
      ? Str(Ids.appointment_reserved)
      : undefined;

  const patient = appointment.patients[0];
  const attendee =
    query.booked === 'true' && props.formsMode
      ? `${patient.firstName} ${patient.lastName}${
          patient.preferredname?.length ? ` (${patient.preferredname})` : ''
        }${patient.email?.length ? ` (${patient.email})` : ''}`
      : '';

  const dateTime = `${when} ${time}`;

  const secondAppointment = appointment.linkedAppointments.find(
    (app) => app.appointmentSeriesIndex === 2
  );

  // hide the 'your next appointment has been automatically booked' message for third and booster doses
  const showAutoBookMessage = appointment.appointmentSeriesIndex > 1 ? false : true;

  const customInstructions =
    config.customInstructions[lang] && config.customInstructions[lang].length
      ? config.customInstructions[lang]
      : undefined;

  const {overrideSeries} = appointment;

  const rescheduling = query.reschedule === 'true';

  const isGroup = !!appointment.appointmentGroup;
  const isPrimary = isGroup && appointment.id === appointment.appointmentGroup.primaryAppointmentId;
  // TODO: find primary appointment to get the contact and display in attendee
  const primaryAppointment =
    isGroup && isPrimary
      ? undefined
      : appointment.appointmentGroup?.appointments?.find(
          (item) => item.id === appointment.appointmentGroup.primaryAppointmentId
        );
  const secondaryAppointments =
    isGroup && isPrimary
      ? appointment.appointmentGroup.appointments?.filter(
          (item) =>
            item.id !== appointment.appointmentGroup.primaryAppointmentId &&
            appointment.datetime === item.datetime
        )
      : undefined;
  const includeRescheduleButton = !isGroup;
  const includeNewAppointmentButton = !isGroup;

  return (
    <div>
      {!query?.rescheduled && (
        <AppointmentConfirmedAlert appointment={appointment} show={props.completedForms} />
      )}
      <ReschedulerContainer
        appointment={appointment}
        secondAppointment={secondAppointment}
        onRescheduling={props.onRescheduling}
        rescheduling={rescheduling}
      >
        {(reschedulerProps) => (
          <>
            <RequiredFormsAlert {...props} setHideAppointmentDetails={setHideAppointmentDetails} />
            {query?.rescheduled && (
              <AlertCard
                color="#E2F6DE"
                borderColor="#91DE80"
                fontColor="black"
                checkIcon="true"
                title={`${
                  enableDose2Availability && appointment.appointmentSeriesIndex === 1
                    ? 'First Dose'
                    : 'Appointment'
                } Rescheduled`}
                subtitle={
                  `Your ${
                    enableDose2Availability && appointment.appointmentSeriesIndex === 1
                      ? 'first dose'
                      : 'appointment'
                  } has been rescheduled.` +
                  (props.completedForms ? ` All your required forms have been submitted.` : ``) +
                  (query.canceledDose2
                    ? ` As a result, your second dose has been cancelled. You will be notified when you can rebook this appointment.`
                    : ``)
                }
              />
            )}
            {!props.completedForms &&
            !query?.rescheduled &&
            props.formsMode &&
            !hideAppointmentDetails ? (
              <AlertCard
                color="#E2F6DE"
                borderColor="#91DE80"
                fontColor="black"
                checkIcon="true"
                title={Str(Ids.appointment_confirmed)}
                subtitle={`${Str(Ids.thank_you)}!${
                  appointment.email ? ` ${Str(Ids.confirmation_email_sent_to_you)}` : ``
                }`}
              />
            ) : null}
            {!props.completedForms && props.formsMode && !hideAppointmentDetails ? (
              <AlertCard
                color="#FBF6E6"
                borderColor="#FDD870"
                fontColor="black"
                title={Str(Ids.complete_required_forms_title)}
                subtitle={Str(Ids.complete_required_forms_description)}
              />
            ) : null}
            <BFCard style={{border: 'none'}}>
              <AppointmentCard
                appointment={appointment}
                selected
                roundedBottom={hideAppointmentDetails}
                actionBar={
                  props.hideActionButtons ? undefined : !props.shortMode ||
                    (props.shortMode && query.booked === 'true') ? (
                    <AppointmentActionButtons
                      {...reschedulerProps}
                      includeRescheduleButton={includeRescheduleButton}
                      includeNewAppointmentButton={includeNewAppointmentButton}
                    />
                  ) : (
                    <BackToSummaryButton
                      {...props}
                      setHideAppointmentDetails={setHideAppointmentDetails}
                    />
                  )
                }
                style={{marginBottom: hideAppointmentDetails ? 0 : 15}}
              />
              {!hideAppointmentDetails && (
                <div>
                  <div className="toast-body">
                    <Row>
                      <Col md={14}>
                        <InfoSectionTitle title={Str(Ids.appointment_information).toUpperCase()} />
                        <ul style={{listStyleType: 'none', paddingLeft: 0}}>
                          <li>
                            <FontAwesomeIcon
                              icon={faCalendar}
                              size={'sm'}
                              style={{marginRight: 10}}
                            />
                            {when}
                          </li>
                          <li>
                            <FontAwesomeIcon icon={faClock} size={'sm'} style={{marginRight: 10}} />
                            {time}
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faMapMarkerAlt}
                              size={'sm'}
                              style={{marginRight: 10}}
                            />
                            {where}
                          </li>
                        </ul>
                        <InfoSectionTitle
                          title={
                            isGroup
                              ? isPrimary
                                ? 'Appointment Owner'
                                : 'Client'
                              : Str(Ids.attendees)
                          }
                        />
                        <ul style={{listStyleType: 'none', paddingLeft: 0}}>
                          {appointment.patients.map((patient) => (
                            <li>
                              <FontAwesomeIcon
                                icon={faUser}
                                size={'sm'}
                                style={{marginRight: 10}}
                              />
                              {`${fullDisplayName(patient)} ${
                                patient.email ? ` (${patient.email})` : '(No email)'
                              }`}
                            </li>
                          ))}
                        </ul>
                        {isGroup && !isPrimary && (
                          <>
                            <InfoSectionTitle title={'Appointment Owner'} />
                            <ul style={{listStyleType: 'none', paddingLeft: 0}}>
                              <li>
                                <FontAwesomeIcon
                                  icon={faUser}
                                  size={'sm'}
                                  style={{marginRight: 10}}
                                />
                                {`${fullDisplayName(primaryAppointment)} ${
                                  primaryAppointment.email
                                    ? ` (${primaryAppointment.email})`
                                    : '(No email)'
                                }`}
                              </li>
                            </ul>
                          </>
                        )}
                        {isSelfCheckInClinic === 'true' && (
                          <>
                            <InfoSectionTitle title={Str(Ids.self_check_in_code_title)} />
                            <p
                              style={{
                                fontSize: 24,
                                fontWeight: 'bold',
                                marginBottom: 5,
                                display: 'inline-block',
                                padding: 5,
                                backgroundColor: '#F1F3F8',
                              }}
                            >
                              {appointmentCode}
                            </p>
                            <p style={{fontSize: 14}}>{Str(Ids.self_check_in_code_description)}</p>
                          </>
                        )}
                        {showSecondDoseInfo &&
                          showAutoBookMessage &&
                          secondAppointment &&
                          !secondAppointment.canceled && (
                            <>
                              <InfoSectionTitle title={Str('Second Dose')} />
                              <p>{`An appointment to receive your second dose of the COVID-19 vaccine has been automatically scheduled for ${appointmentDateString(
                                moment.tz(secondAppointment.datetime, config.timezone),
                                lang,
                                config.timezone
                              )}.`}</p>
                            </>
                          )}
                        {showSecondDoseInfo &&
                          appointment.appointmentSeriesIndex === 1 &&
                          (!secondAppointment || secondAppointment.canceled) &&
                          !overrideSeries && (
                            <>
                              <InfoSectionTitle title={Str('Second Dose')} />
                              <p>{`If this is your first dose, you will need to book an appointment to receive your second dose of the COVID-19 vaccine. You will be notified when you can book this appointment.`}</p>
                            </>
                          )}
                      </Col>
                      <Col md={10}>
                        <AppointmentMap appointment={appointment} />
                      </Col>
                    </Row>
                  </div>
                  {isPrimary && (
                    <div
                      className="card-footer"
                      style={{
                        backgroundColor: `${theme.blue}0f`,
                      }}
                    >
                      <Row style={{display: 'flex', alignItems: 'center'}}>
                        <h5
                          style={{fontWeight: 'bold', fontSize: 14, margin: 0}}
                          className="text-muted"
                        >
                          {`SECONDARY APPOINTMENTS ( ${secondaryAppointments.length} / ${appointment.appointmentGroup.groupRule.groupCount} )`}
                        </h5>
                        <Button
                          size="large"
                          type="primary"
                          className="btn"
                          disabled={
                            !isSecondaryAvailable.isUnderMaxDoses ||
                            !isSecondaryAvailable.isUnderGroupCount
                          }
                          onClick={onRowClick}
                          style={{marginLeft: 25}}
                        >
                          <div>
                            {!isSecondaryAvailable.isUnderMaxDoses
                              ? 'Clinic Fully Booked'
                              : !isSecondaryAvailable.isUnderGroupCount
                              ? 'Group Fully Booked'
                              : 'Add Appointment'}
                          </div>
                        </Button>
                      </Row>
                      <Row>
                        <Col md={24}>
                          <SecondaryAppointment
                            appointment={secondaryAppointments}
                            onCancelAppointment={cancelSecondaryAppointment}
                            onCompleteForm={(id) => {
                              navigate(
                                `/${lang}/${bookingPageSlug}/${id}?groupRedirect=${appointment.id}`
                              );
                              window.location.reload();
                            }}
                          />
                        </Col>
                      </Row>
                    </div>
                  )}
                  {!props.shortMode && (
                    <div
                      className="card-footer"
                      style={{
                        backgroundColor: `${theme.blue}0f`,
                        borderBottomLeftRadius: 10,
                        borderBottomRightRadius: 10,
                      }}
                    >
                      <h5 style={{fontWeight: 'bold', fontSize: 14}} className="text-muted">
                        {Str(Ids.preparing_for_your_appointment).toUpperCase()}
                      </h5>
                      <p className="card-text">
                        {customInstructions ? (
                          //customInstructions must be in markdown format, and is rendered using react-markdown
                          <ReactMarkdown linkTarget="_blank">{customInstructions}</ReactMarkdown>
                        ) : (
                          <ul className="list-group" style={{borderRadius: theme.borderRadius}}>
                            <PrepStepContainer>
                              <PrepStep1
                                {...props}
                                setHideAppointmentDetails={setHideAppointmentDetails}
                              />
                              {appointment.covid24HourScreenEnabled && (
                                <PrepStep24HrScreen {...props} />
                              )}
                              <PrepStep3 {...props} />
                            </PrepStepContainer>
                          </ul>
                        )}
                      </p>
                    </div>
                  )}
                </div>
              )}
            </BFCard>
          </>
        )}
      </ReschedulerContainer>
      {isGroup && (
        <AppointmentGroupModal
          appointment={appointment}
          visible={addModalVisible}
          onClose={onClose}
        />
      )}
    </div>
  );
};

const SecondaryAppointment = (props) => {
  const {appointment, onCancelAppointment, onCompleteForm} = props;
  const {config} = useUrlData();

  for (const item of appointment) {
    item.completedForm = item.appointmentPatients?.some(
      (p) => filterRequiredFormTypesForConfig(p.requiredFormTypes, config).length <= 0
    );
  }

  return appointment?.map((item) => (
    <Card style={{marginTop: 15}}>
      <Row style={{width: '100%', alignItems: 'center'}}>
        <Col>
          <div style={{fontSize: 18}}>
            <FontAwesomeIcon icon={faUser} size={'sm'} style={{marginRight: 10}} />
            {`${fullDisplayName(item)} ${item.email ? ` (${item.email})` : '(No email)'}`}
          </div>
        </Col>
        <Col
          style={{
            display: 'flex',
            flexGrow: 1,
            flexShrink: 0,
            justifyContent: 'flex-end',
          }}
        >
          <Space wrap>
            {item.completedForm && (
              <div style={{fontSize: 16, color: '#5BC569', marginRight: 15}}>
                <span style={{marginRight: 5}}>
                  {<FontAwesomeIcon icon={faCheckCircle} color={'#5BC569'} size={'1x'} />}
                </span>
                Complete Forms
              </div>
            )}
            {!item.completedForm && (
              <Button
                className="btn"
                size="large"
                type="primary"
                style={{
                  borderRadius: 10,
                  display: 'flex',
                  alignItems: 'center',
                }}
                onClick={() => onCompleteForm(item.id)}
              >
                {/* TODO: update text to use Ids */}
                Complete Forms
                <FontAwesomeIcon icon={faAngleRight} color={'white'} style={{marginLeft: 10}} />
              </Button>
            )}

            <Popconfirm
              title="Are you sure that you want to cancel this appointment?"
              onConfirm={() => onCancelAppointment(item.id)}
              okText="Yes"
              cancelText="No"
            >
              <Button
                className="btn"
                size="large"
                type="primary"
                style={{
                  borderRadius: 10,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {/* TODO: update text to use Ids */}
                Cancel Appointment
                <FontAwesomeIcon icon={faAngleRight} color={'white'} style={{marginLeft: 10}} />
              </Button>
            </Popconfirm>
          </Space>
        </Col>
      </Row>
    </Card>
  ));
};

const BackToSummaryButton = (props) => {
  const Str = useStr();
  const {theme, config} = useUrlData();
  const navigate = useNavigate();
  const {lang, bookingPageSlug} = useUrlData();

  return (
    <a
      href={props.appointment.confirmationPage}
      role="button"
      className="btn"
      aria-pressed="true"
      style={{
        color: theme.blue,
        backgroundColor: 'white',
        borderRadius: theme.borderRadius,
        marginTop: 5,
        marginBottom: 5,
      }}
      onClick={() => {
        if (props.groupRedirect) {
          navigate(`/${lang}/${bookingPageSlug}/${props.groupRedirect}`);
          window.location.reload();
        } else {
          props.setFormsMode(false);
          props.setHideAppointmentDetails(false);
        }
      }}
    >
      <FontAwesomeIcon icon={faAngleLeft} color={config.primaryColor} style={{marginRight: 10}} />
      {Str(Ids.back_to_appointment_summary)}
    </a>
  );
};

const PrepStepContainer = (props) => {
  return React.Children.map(
    props.children.filter((child) => React.isValidElement(child)),
    (child, i) => React.cloneElement(child, {stepIndex: i + 1})
  );
};

const PrepStep1 = (props) => {
  const Str = useStr();
  const {config, lang} = useUrlData();
  let right;

  const hasFormsToFill = props.appointment.patients.some(
    (p) => filterRequiredFormTypesForConfig(p.requiredFormTypes, config).length
  );

  if (!hasFormsToFill) {
    right = (
      <ResponsiveRight
        label={Str(Ids.complete)}
        labelColor={'#5BC569'}
        fontSize={18}
        icon={<FontAwesomeIcon icon={faCheckCircle} color={'#5BC569'} size={'1x'} />}
      />
    );
  } else {
    right = (
      <Button
        className="btn"
        size="large"
        type="primary"
        style={{
          borderRadius: 10,
          display: 'flex',
          alignItems: 'center',
        }}
        onClick={(e) => {
          props.setFormsMode(true);
          props.setHideAppointmentDetails(true);
        }}
      >
        <FontAwesomeIcon icon={faFileSignature} color={'white'} style={{marginRight: 10}} />
        <div style={{color: 'white'}}>
          {/* TODO: update text to use Ids */}
          {props.appointment.appointmentGroup ? 'Complete My Forms' : Str(Ids.complete_forms)}
        </div>
        <FontAwesomeIcon icon={faAngleRight} color={'white'} style={{marginLeft: 10}} />
      </Button>
    );
  }

  return <PrepStep {...props} title={config.step1Message[lang || 'en']} right={right} />;
};

const PrepStep24HrScreen = (props) => {
  const Str = useStr();
  const {config, lang} = useUrlData();
  const {appointment} = props;
  let info, infoIcon, topRight;

  if (!appointment.covid24HourScreenComplete && !appointment.within24Hours) {
    info = Str(Ids.will_receive_email_fill_covid_screen, appointment.email);
    infoIcon = faEnvelope;
  }
  if (!appointment.covid24HourScreenComplete && appointment.within24Hours) {
    info = Str(Ids.complete_all_forms_prior_arriving);
    infoIcon = faExclamationTriangle;
  }

  if (appointment.covid24HourScreenComplete) {
    topRight = (
      <ResponsiveRight
        label={Str(Ids.complete)}
        labelColor={'#5BC569'}
        icon={<FontAwesomeIcon icon={faCheckCircle} color={'#5BC569'} size={'1x'} />}
      />
    );
  }

  if (!appointment.covid24HourScreenComplete && appointment.within24Hours) {
    topRight = (
      <div style={{paddingLeft: 15}}>
        <Button
          className="btn"
          size="large"
          type="primary"
          style={{
            borderRadius: 10,
            display: 'flex',
            alignItems: 'center',
          }}
          onClick={(e) => {
            props.setFormsMode(true);
          }}
        >
          <FontAwesomeIcon icon={faFileSignature} color={'white'} style={{marginRight: 10}} />
          <div style={{color: 'white'}}>{Str(Ids.complete_forms)}</div>
        </Button>
      </div>
    );
  }

  if (!appointment.covid24HourScreenComplete && !appointment.within24Hours) {
    topRight = (
      <ResponsiveRight
        label={appointmentDateString(
          moment.tz(appointment.datetime, config.timezone).subtract(24, 'hours'),
          lang,
          config.timezone
        )}
        icon={<FontAwesomeIcon icon={faClock} color={'#555'} size={'1x'} />}
      />
    );
  }

  return (
    <PrepStep
      {...props}
      title={Str(Ids.covid_screen_within_24_hours)}
      info={info}
      infoIcon={infoIcon}
      topRight={topRight}
    />
  );
};

const PrepStep3 = (props) => {
  const Str = useStr();
  const {config, lang} = useUrlData();
  const {appointment} = props;
  const topRight = (
    <ResponsiveRight
      label={appointmentDateString(
        moment.tz(appointment.datetime, config.timezone),
        lang,
        config.timezone
      )}
      icon={<FontAwesomeIcon icon={faSyringe} color={'#555'} size={'1x'} />}
    />
  );

  return (
    <PrepStep
      {...props}
      title={config.step3MessageHtml[lang || 'en']}
      topRight={topRight}
    />
  );
};

const PrepStep = ({appointment, stepIndex, title, info, infoIcon, right, topRight}) => {
  const {theme} = useUrlData();
  const Str = useStr();
  return (
    <li className="list-group-item d-flex justify-content-between align-items-center">
      <Row style={{width: '100%'}} className={s.prepStepOuterRow}>
        <Col style={{width: '100%'}}>
          <Row className={s.actionButtonsRow} style={{alignItems: 'center'}}>
            <Col md={6} className="prep-step-header-col-1">
              <div style={{color: theme.blue, fontWeight: 'bold', fontSize: 16}}>{`${Str(
                Ids.step
              )} ${stepIndex}`}</div>
            </Col>
            {topRight && (
              <Col
                className={s.responsiveRightContainer}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexGrow: 1,
                  flexShrink: 0,
                }}
              >
                {topRight}
              </Col>
            )}
          </Row>
          {title && <div dangerouslySetInnerHTML={{__html: title}} />}
          {info && (
            <div style={{display: 'flex', alignItems: 'center'}}>
              {infoIcon && (
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  color={'#fdcc05'}
                  size={'1x'}
                  style={{marginRight: 15}}
                />
              )}
              {info && (
                <div
                  style={{fontSize: 16, lineHeight: '20px'}}
                  className="text-muted markdown-field"
                  dangerouslySetInnerHTML={{__html: info}}
                />
              )}
            </div>
          )}
        </Col>
        {right && (
          <Col
            className={s.prepStepRightContainer}
            style={{
              display: 'flex',
              alignItems: 'center',
              flexGrow: 1,
              flexShrink: 0,
            }}
          >
            <div>{right}</div>
          </Col>
        )}
      </Row>
    </li>
  );
};

const ResponsiveRight = ({label, fontSize = 16, labelColor = 'inherit', icon}) => {
  return (
    <div className={s.responsiveRight} style={{fontSize, color: labelColor}}>
      {/* <div className={s.responsiveRightIconLabel} style={{color: labelColor}}></div> */}
      <span style={{marginRight: 5}}>{icon}</span>
      {label}
    </div>
  );
};

const AppointmentConfirmedAlert = ({appointment, show}) => {
  const Str = useStr();
  const {lang, config} = useUrlData();
  const hasFormsToFill = appointment.patients.some(
    (p) => filterRequiredFormTypesForConfig(p.requiredFormTypes, config).length > 0
  );
  const showAlert = !hasFormsToFill && show;
  return showAlert ? (
    <BFCard style={{backgroundColor: '#E2F6DE', borderColor: '#91DE80'}}>
      <CardBody>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <div style={{marginRight: 10}}>
            <FontAwesomeIcon
              icon={faCheck}
              style={{marginRight: 10, fontSize: 24, color: '#55D734'}}
            />
          </div>
          <div>
            <CardTitle
              tag="h5"
              className={s.appointmentTypeCardTitle}
              style={{color: 'black', marginBottom: 10}}
            >
              {/* TODO: Make this an booking page field */}

              {Str(Ids.appointment_confirmed)}
            </CardTitle>
            <CardSubtitle tag="h6" style={{color: 'black', marginBottom: 0}}>
              {/* {config.thankYouForFillingOutConsent[lang || 'en']} */}
              {`${Str(Ids.required_forms_submitted)}${
                appointment.email
                  ? ` ${Str(Ids.confirmation_email_sent_to)} ${appointment.email}.`
                  : ''
              }`}
            </CardSubtitle>
          </div>
        </div>
      </CardBody>
    </BFCard>
  ) : null;
};

const RequiredFormsAlert = ({appointment, formsMode, setFormsMode, setHideAppointmentDetails}) => {
  const {config, theme} = useUrlData();
  const Str = useStr();

  const hasFormsToFill = appointment.patients.some(
    (p) => filterRequiredFormTypesForConfig(p.requiredFormTypes, config).length > 0
  );

  if (formsMode || !hasFormsToFill) return null;

  return (
    <AlertCard
      color={'#f5a900'}
      title={Str(Ids.required_forms)}
      subtitle={Str(Ids.complete_required_forms_prior_to_appointment)}
      actionButtons={
        <a
          href={appointment.confirmationPage}
          role="button"
          className="btn"
          aria-pressed="true"
          style={{
            backgroundColor: 'white',
            borderRadius: theme.borderRadius,
            marginTop: 5,
            marginBottom: 5,
            display: 'flex',
            alignItems: 'center',
          }}
          onClick={() => {
            setFormsMode(true);
            setHideAppointmentDetails(true);
          }}
        >
          <FontAwesomeIcon icon={faFileSignature} color={'#f5a900'} style={{marginRight: 10}} />
          <div style={{color: '#f5a900'}}>
            {/* TODO: update text to use Ids */}
            {appointment.appointmentGroup ? 'Complete My Forms' : Str(Ids.complete_forms)}
          </div>
          <FontAwesomeIcon icon={faAngleRight} color={'#f5a900'} style={{marginLeft: 10}} />
        </a>
      }
    />
  );
};
