import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export interface IOrganizationIdSlice {
  organizationId?: string;
}

/* Stores the organization id for the current user */
export const organizationIdSlice = createSlice({
  name: 'organizationId',
  initialState: {
    organizationId: undefined,
  } as IOrganizationIdSlice,
  reducers: {
    saveOrganizationId: (state, action: PayloadAction<string>) => {
      state.organizationId = action.payload;
    },
  },
});
