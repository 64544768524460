import {Select, Form} from 'formik-antd';
import React, {useState, useEffect} from 'react';
import {ValidationRules} from '../../validation/validation-rules/validation-rules';
import {ValidateFormItem} from '../validate-form-item';
import {useBookingPages} from '../../models/booking-pages';

interface BookingPagePickerFormItemProps {
  label?: string;
  name: string;
  setFieldValue: (name: string, value: any) => void;
  validationRules?: ValidationRules[];
  organizationId: string | undefined;
  parentOrganizationId?: string | undefined;
}

export const BookingPagePickerFormItem = (props: BookingPagePickerFormItemProps) => {
  const {organizationId, parentOrganizationId, setFieldValue} = props;
  
  const [ initial, setInitial ] = useState(true);

  const bookingPages = useBookingPages();

  //Only list booking pages that are associated with the organization or with the parent organization
  const filteredBookingPages = bookingPages.filter((item) => item.organizationId === organizationId || item.organizationId === parentOrganizationId);

  /* Set defaultBookingPageId field to null when parent organization 
  is change on organization form */
  useEffect(() => {
      //checks if is initial component render and, if so, don't run useEffect
      if(!initial){
        setFieldValue(props.name, null);
      } else {
          setInitial(false)
      }
  }, [organizationId]);

  return (
    <ValidateFormItem
      validationRules={props.validationRules || []}
      renderFormItem={(validate) => {
        return (
          <Form.Item name={props.name} label={props.label} validate={validate}>
            <Select
              name={props.name}
              showSearch
              placeholder="Select a booking page"
              optionFilterProp="children"
              onChange={(value) => {
                setFieldValue(props.name, value);
              }}
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input?.toLowerCase()) >= 0
              }
            >
              {filteredBookingPages.map((bookingPage) => (
                <Select.Option value={bookingPage.id!} key={bookingPage.id}>
                  {bookingPage.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        );
      }}
    />
  );
};
