/* This module exports common validation rules that can be used by
UI components in the app */
import {BirthDateValidationRuleId} from '../validation/validation-rules/birthdate-validation';
import {GenderValidationRuleId} from '../validation/validation-rules/gender-enum-validation';
import {HcnValidationRuleId} from '../validation/validation-rules/hcn-validation';
import {JsonValidationRuleId} from '../validation/validation-rules/json-validation';
import {MaxLengthValidationRuleId} from '../validation/validation-rules/max-length-validation';
import {RequiredValidationRuleId} from '../validation/validation-rules/required-validation';
import {RequiredDefinedValidationRuleId} from '../validation/validation-rules/required-defined-validation';
import {ValidationRules} from '../validation/validation-rules/validation-rules';
import {RegexValidationId} from '../validation/validation-rules/regex-validation';
import {EmailValidationRuleId} from '../validation/validation-rules/email-validation';
import {PhoneValidationRuleId} from '../validation/validation-rules/phone-validation';
import {UserPasswordValidationRuleId} from '../validation/validation-rules/user-password-validation';
import {NumberValidationRuleId} from '../validation/validation-rules/number-validation-rule';
import {PostalCodeValidationRuleId} from '../validation/validation-rules/postal-code-validation';
import {RequiredObjectValidationRuleId} from '../validation/validation-rules/required-object-validation';
import {PreviousDateValidationRuleId} from '../validation/validation-rules/previous-date-validation-rule';
import {FutureDateValidationRuleId} from '../validation/validation-rules/future-date-validation-rule';

/* Use for just required fields */
export const RequiredValidationRule: ValidationRules[] = [
  {
    validationRuleType: RequiredValidationRuleId,
  },
];

/* Use for fields requiring not undefined and not null */
export const RequiredDefinedValidationRule: ValidationRules[] = [
  {
    validationRuleType: RequiredDefinedValidationRuleId,
  },
];

/* Use this for short texts such as name or an email */
export const RequiredShortTextValidationRules: ValidationRules[] = [
  {
    validationRuleType: RequiredValidationRuleId,
  },
  {
    validationRuleType: MaxLengthValidationRuleId,
    maxLength: 255,
  },
];

/* Use this for text that will be used to create filenames */
export const RequiredSpecialCharsValidationRules: ValidationRules[] = [
  {
    validationRuleType: RegexValidationId,
    regex: /^[a-zA-Z0-9-_)(:>< ]+$/,
    errorMessage: 'Invalid special character(s). Allowed characters are: - _ ) ( : > <',
  },
];

export const makeSpecialCharacterValidator = (
  fieldName: string,
  required = false,
  maxLength = 255
): ValidationRules[] => {
  const specialCharacterValidator: ValidationRules = {
    validationRuleType: RegexValidationId,
    regex: /^[a-zA-Z0-9-_)(:>< ]*$/,
    errorMessage: `${fieldName} has invalid special character(s). Allowed characters are: - _ ) ( : > <`,
  };
  const maxLengthRule: ValidationRules = {
    validationRuleType: MaxLengthValidationRuleId,
    maxLength,
  };

  return required
    ? [{validationRuleType: RequiredValidationRuleId}, specialCharacterValidator, maxLengthRule]
    : [specialCharacterValidator, maxLengthRule];
};

/* Use this to validate email addresses */
export const EmailValidationRules: ValidationRules[] = [
  {
    validationRuleType: EmailValidationRuleId,
  },
];

/* Use this to validate phone numbers*/
export const PhoneValidationRules: ValidationRules[] = [
  {
    validationRuleType: PhoneValidationRuleId,
  },
];

/* Use this to validate postal codes */
export const PostalCodeValidationRules: ValidationRules[] = [
  {
    validationRuleType: PostalCodeValidationRuleId,
  },
];

/* Use this to validate objects */
export const RequiredObjectValidationRules: ValidationRules[] = [
  {
    validationRuleType: RequiredObjectValidationRuleId,
  },
];

/* Validate that a date precedes or falls on today */
export const PreviousDateValidationRules: ValidationRules[] = [
  {
    validationRuleType: PreviousDateValidationRuleId,
  },
];

/* Validate that a date is after today */
export const FutureDateValidationRules: ValidationRules[] = [
  {
    validationRuleType: FutureDateValidationRuleId,
  },
];

/* Use this for longer texts such as those that use a text areas like for
descriptions */
export const RequiredLongTextValidationRules: ValidationRules[] = [
  {
    validationRuleType: RequiredValidationRuleId,
  },
  {
    validationRuleType: MaxLengthValidationRuleId,
    maxLength: 4080,
  },
];

/* Use this for extra long texts (booking page content) that exceed 4080 characters */
export const RequiredExtraLongTextValidationRules: ValidationRules[] = [
  {
    validationRuleType: RequiredValidationRuleId,
  },
  {
    validationRuleType: MaxLengthValidationRuleId,
    maxLength: 10000,
  },
];

/* Use this for longer texts such as those that use a text areas like for
descriptions */
export const LongTextValidationRules: ValidationRules[] = [
  {
    validationRuleType: MaxLengthValidationRuleId,
    maxLength: 4080,
  },
];

/* Use this for short texts such as those that use a text field like for
name but not required */
export const ShortTextValidationRules: ValidationRules[] = [
  {
    validationRuleType: MaxLengthValidationRuleId,
    maxLength: 255,
  },
];

/* Use this for required JSON string */
export const RequiredJsonValidationRules: ValidationRules[] = [
  {
    validationRuleType: RequiredValidationRuleId,
  },
  {
    validationRuleType: JsonValidationRuleId,
  },
];

/* Use this for longer texts such as those that use a text areas like for
descriptions */
export const RequiredGenderValidationRules: ValidationRules[] = [
  {
    validationRuleType: RequiredValidationRuleId,
  },
  {
    validationRuleType: GenderValidationRuleId,
  },
];

/* Use this for longer texts such as those that use a text areas like for
descriptions */
export const RequiredBirthDateValidationRules: ValidationRules[] = [
  {
    validationRuleType: RequiredValidationRuleId,
  },
  {
    validationRuleType: BirthDateValidationRuleId,
  },
];

/* Use this for UUID fields (e.g. in CSV uploads) */
export const OptionalUUIDValidationRules: ValidationRules[] = [
  {
    validationRuleType: RegexValidationId,
    regex: /^(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})?$/,
  },
];

/* Use this for *required* UUID fields (e.g. in CSV uploads) */
export const RequiredUUIDValidationRules: ValidationRules[] = [
  {
    validationRuleType: RequiredValidationRuleId,
  },
  {
    validationRuleType: RegexValidationId,
    regex: /^(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})$/,
  },
];

/* Use this for password of Users from auth0 */
export const RequiredUserManagementPassword: ValidationRules[] = [
  {
    validationRuleType: RequiredValidationRuleId,
  },
  {
    validationRuleType: UserPasswordValidationRuleId,
  },
];

/** *
 * NUMBER VALIDATION
 */
const baseNumberFormatRule: ValidationRules = {
  validationRuleType: RegexValidationId,
  regex: /^[0-9]*$/,
};

/* Use this for validating optional numbers */
export const OptionalInteger: ValidationRules[] = [baseNumberFormatRule];

/* Use this for validating required integers */
export const RequiredInteger: ValidationRules[] = [
  {
    validationRuleType: RequiredValidationRuleId,
  },
  baseNumberFormatRule,
];

/* Use this for validating required numbers */
export const RequiredNumberValidationRules: ValidationRules[] = [
  {
    validationRuleType: RequiredValidationRuleId,
  },
  {
    validationRuleType: NumberValidationRuleId,
  },
];

export const makeIntegerValidator = (fieldName, required = false): ValidationRules[] => {
  const numberFormatRule: ValidationRules = {
    validationRuleType: RegexValidationId,
    regex: baseNumberFormatRule.regex,
    errorMessage: `${fieldName} must be a number with no decimals or special characters`,
  };

  return required
    ? [{validationRuleType: RequiredValidationRuleId}, numberFormatRule]
    : [numberFormatRule];
};
