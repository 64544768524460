import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export const ReactDatePicker = (props) => {
  const {value, onChange, dateFormat, placeholder, className, popperPlacement} = props;

  return (
    <DatePicker
      popperPlacement={popperPlacement}
      className={`${className || ''} receipt-datepicker ant-picker ant-picker-large antd-input`}
      dateFormat={dateFormat}
      selected={value}
      placeholderText={placeholder}
      onChange={onChange}
    />
  );
};
