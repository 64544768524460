import {Button, Checkbox, Col, Modal, Row, Table, Typography} from 'antd';
import {Field, FormikProps} from 'formik';
import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useTokenOrgId} from '../../services/fhir';
import {BaseItemForm} from '../base-entity/item-form';
import {FHIRTable} from '../fhir-table';
import {IFormUXMultipleItemPicker} from '../form-ux/form-ux-models/form-ux-fields/form-ux-multiple-item-picker';

/**
 *
 *
 * @interface MultipleItemPickerProps
 */
interface MultipleItemPickerProps {
  /**
   *
   *
   * @type {IFormUXMultipleItemPicker}
   * @memberof MultipleItemPickerProps
   */
  field: IFormUXMultipleItemPicker;
}

/* Component to use when we want the user to select multiple items of a single
entity  */
export const MultipleItemsPicker = (props: MultipleItemPickerProps) => {
  const [showCreateModal, setShowCreateModal] = React.useState<boolean>(false);
  const orgId = useTokenOrgId();
  const navigate = useNavigate();
  /* State variable to keep track of whether the modal where the user
  selects/removes the cohorts is visible */
  const [isModalVisible, setIsModalVisible] = useState(false);
  /* The items selected before the modal is opened. This way if the user
  presses the Cancel button we make the field value go back to the old value. */
  const [oldItems, setOldItems] = useState([]);

  const onRowClick = (item) => {
    if (!props.field.onRowClickPath) return;

    return navigate(props.field.onRowClickPath(item.id));
  };

  return (
    <Field name={props.field.name}>
      {({field, form}: {field: any; form: FormikProps<any>}) => {
        return (
          <>
            <Row style={{marginTop: 15}}>
              <Col>
                <Typography.Title level={4}>{props.field.label}</Typography.Title>
              </Col>
            </Row>
            {(typeof props.field.editable === 'function'
              ? props.field.editable()
              : props.field.editable) && (
              <Row gutter={[0, 15]}>
                <Col>
                  <Button
                    type="primary"
                    onClick={() => {
                      /* Store the current cohorts value */
                      setOldItems(field.value);
                      /* Show the Modal */
                      return setIsModalVisible(!isModalVisible);
                    }}
                  >
                    {props.field.editItemsButtonLabel}
                  </Button>
                </Col>
              </Row>
            )}
            {!props.field.hideTable && (
              <Table
                columns={props.field.itemsTableColumns}
                dataSource={field.value}
                onRow={(item: any) => ({onClick: (event) => onRowClick(item)})}
              />
            )}
            <Modal
              width={props.field.modalWidth}
              visible={isModalVisible}
              closable={false}
              maskClosable={false}
              onOk={() => {
                if (props.field.submitFormOnModalOk) {
                  form.submitForm();
                }
                setIsModalVisible(false);
              }}
              onCancel={() => {
                /* User pressed cancel which means we should set the
                value of the cohorts field back to its original value */
                form.setFieldValue(props.field.name, oldItems);
                setIsModalVisible(false);
              }}
            >
              <FHIRTable
                onClickCreateButton={() => setShowCreateModal(true)}
                columns={[
                  {
                    title: 'Select',
                    key: 'select-checkbox',
                    render: (_, cohort) => {
                      return (
                        <Checkbox
                          checked={findIndexOfItem(cohort, field.value) !== -1}
                          onChange={(event: any) => {
                            /* We can't update the field.value property
                            so we store it in a new variable and update it */
                            const updatedCohorts = [...field.value];
                            if (event.target.checked) {
                              updatedCohorts.push(cohort as string);
                            } else {
                              const cohortIndex = findIndexOfItem(cohort, updatedCohorts);
                              updatedCohorts.splice(cohortIndex, 1);
                            }

                            form.setFieldValue(props.field.name, updatedCohorts);
                          }}
                        />
                      );
                    },
                  },
                  ...props.field.itemsTableColumns,
                ]}
                fhirResource={
                  typeof props.field.fhirResourceName === 'string'
                    ? props.field.fhirResourceName
                    : props.field.fhirResourceName(orgId as string)
                }
                fixedFilters={props.field.fixedFilters}
                mode="table"
                defaultPrimarySearchParam="name"
                showCreateButton={props.field.showCreateButton}
                createButtonTitle={props.field.createButtonLabel}
              />

              {props.field.showCreateButton && (
                <BaseItemForm
                  modal
                  setModalVisible={(visible) => setShowCreateModal(visible)}
                  modalVisible={showCreateModal}
                  model={props.field.model}
                  editMode={false}
                  slug={props.field.slug || ''}
                  getTitleInfo={(item) => {
                    return {title: props.field.createButtonLabel || ''};
                  }}
                  formConfig={props.field.createFormConfig}
                />
              )}
            </Modal>
          </>
        );
      }}
    </Field>
  );
};

/**
 * Returns the index of the cohortToFind param in the cohorts array param.
 * Returns -1 if it can't find it.
 *
 * @param {*} itemToFind
 * @param {*} items
 * @returns {number}
 */
function findIndexOfItem(itemToFind, items): number {
  return items.findIndex((item) => {
    return item.id === itemToFind.id;
  });
}
