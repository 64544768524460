import {Button, message, Modal, Space} from 'antd';
import uuid from 'uuid';
import React from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '../../../models';
import {FhirUtils} from '../../../services/fhir';
import {FHIRTable} from '../../fhir-table';
import {FormUXFieldType} from '../../form-ux/form-ux-models/form-ux-fields/form-ux-field-type';
import {FormUXModel} from '../../form-ux/form-ux-models/form-ux-model';
import {colors} from '@canimmunize/tools';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckCircle} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import {BookingPageInvitationListActions} from './invite-list-actions';
import {Link} from 'react-router-dom';

export const BookingPageInvitationLists = ({bookingPageId}) => {
  const globalOrg = useSelector((state: RootState) => state.ui.localOrg);
  const [selectingList, setSelectingList] = React.useState(false);
  const [tableState, setTableState] = React.useState(uuid.v4());
  const [addedLists, setAddedLists] = React.useState<string[]>([]);
  const client = FhirUtils.useAxiosClient();

  const onSelectList = async (list) => {
    const result = await client.put('/list/link', {
      listId: list.id,
      itemId: bookingPageId,
      itemType: 'bookingPage',
      action: 'link',
    });

    if (result.status === 200) {
      setTableState(uuid.v4());
      setAddedLists([...addedLists, list.id]);
      message.success('List successfully added to booking page!');
    } else {
      message.error('Error adding invitation list to booking page');
    }
  };

  const columns = [
    {
      dataIndex: 'name',
      title: 'Name',
      render: (_, row) => <Link to={`/lists/${row.id}`}>{row.name}</Link>,
    },
    {
      dataIndex: 'description',
      title: 'Description',
    },
    {
      dataIndex: 'createdAt',
      title: 'Created',
      render: (_, row) => moment(row.createdAt).format('LLL'),
    },
    {
      dataIndex: 'itemCount',
      title: 'Patients',
    },
    {
      title: 'Actions',
      render: (_, list) => (
        <BookingPageInvitationListActions
          list={list}
          bookingPageId={bookingPageId}
          refreshTable={() => setTableState(uuid.v4())}
        />
      ),
    },
  ];

  const pickerColumns = [
    {
      dataIndex: 'name',
      title: 'Name',
    },

    {
      dataIndex: 'description',
      title: 'Description',
    },
    {
      dataIndex: 'createdAt',
      title: 'Created',
      render: (_, row) => moment(row.createdAt).format('LLL'),
    },
    {
      dataIndex: 'itemCount',
      title: 'Patients',
    },
    {
      title: 'Select',
      render: (_, list) => (
        <Space>
          {list.bookingPageIds.indexOf(bookingPageId) > -1 ? (
            <Button
              type="primary"
              className="success"
              size={'small'}
              style={{
                paddingLeft: 10,
                paddingRight: 10,
                backgroundColor: colors.green,
                borderColor: colors.green,
              }}
            >
              <FontAwesomeIcon icon={faCheckCircle} color="white" style={{marginRight: 10}} />
              Added
            </Button>
          ) : (
            <Button type="primary" size="small" onClick={() => onSelectList(list)}>
              Add to Booking Page
            </Button>
          )}
        </Space>
      ),
    },
  ];

  return (
    <>
      <FHIRTable
        fhirResource="list"
        mode="table"
        triggerRefresh={tableState}
        fixedFilters={{bookingPageId}}
        columns={columns}
        label={'Invitation List'}
        showCreateButton
        createButtonTitle="Link Invitation List"
        onClickCreateButton={() => setSelectingList(true)}
      />
      <Modal
        width={'80%'}
        visible={selectingList}
        title="Link Invitation List"
        onCancel={() => setSelectingList(false)}
        onOk={() => setSelectingList(false)}
        cancelButtonProps={{style: {display: 'none'}}}
        okText="Done"
        destroyOnClose
      >
        <FHIRTable
          fhirResource="list"
          mode="table"
          defaultPrimarySearchParam="name"
          fixedFilters={{_organizationId: globalOrg?.id || '', notBookingPageId: bookingPageId}}
          columns={pickerColumns}
          triggerRefresh={tableState}
        />
      </Modal>
    </>
  );
};
