import {DatePicker, Form, Select, TimePicker} from 'formik-antd';
import React from 'react';
import {ValidateFormItem} from '../../validate-form-item';
import moment, {Moment} from 'moment-timezone';
import {RequiredValidationRuleId} from '../../../validation/validation-rules/required-validation';

type DatePickerType = Moment | null;

export const DateTimeTimezoneFormItem = (props) => {
  const {name, label, form, timezone, setTimezone, validationRules, editable, subType} = props;

  const getTimeValue = (date, unit) => Number(date.format(unit));

  //Handle datpicker change such that date is altered but time stays the same. Take timezone into account as well.
  const handleChangeDate = (name: string, date: DatePickerType) => {
    const currentDate = moment.tz(form.values[name], timezone);
    const setTimeValues = {
      hours: getTimeValue(currentDate, 'HH'),
      minutes: getTimeValue(currentDate, 'mm'),
      seconds: getTimeValue(currentDate, 'ss'),
      milliseconds: getTimeValue(currentDate, 'SSS'),
    };

    const newDate = moment.tz(date, timezone).set(setTimeValues);

    return form.setFieldValue(name, moment.utc(newDate).format(`YYYY-MM-DDTHH:mm:ss.SSS[Z]`));
  };

  //Handle timezone change
  const handleChangeTimezone = (name: string, zone: string) => {
    const currentDate = moment.tz(form.values[name], timezone);

    const setTimeValues = {
      hours: getTimeValue(currentDate, 'HH'),
      minutes: getTimeValue(currentDate, 'mm'),
      seconds: getTimeValue(currentDate, 'ss'),
      milliseconds: getTimeValue(currentDate, 'SSS'),
      date: getTimeValue(currentDate, 'D'),
      //Month has to be offset by 1
      month: getTimeValue(currentDate, 'M') - 1,
      year: getTimeValue(currentDate, 'YYYY'),
    };

    const newDate = moment.tz(form.values[name], zone).set(setTimeValues);
    setTimezone(zone);
    return form.setFieldValue(name, moment.utc(newDate).format(`YYYY-MM-DDTHH:mm:ss.SSS[Z]`));
  };

  const fieldToRender = (name: string, fieldType: string) => {
    switch (fieldType) {
      case 'date': {
        return (
          <DatePicker
            disabled={form.values.deleted || !editable}
            allowClear={false}
            size={'middle'}
            format="LL"
            style={{width: '100%'}}
            name={`${name}${subType}`}
            disabledDate={(date) => date.isAfter(moment())}
            value={moment.tz(form.values[name], timezone)}
            onChange={(date: DatePickerType) => handleChangeDate(name, date)}
          />
        );
      }
      case 'time': {
        return (
          <TimePicker
            name={`${name}${subType}`}
            disabled={form.values.deleted || !editable}
            allowClear={false}
            value={moment.tz(form.values[name], timezone)}
            format={'HH:mm'}
            onChange={(date: DatePickerType) =>
              form.setFieldValue(name, moment.utc(date).format(`YYYY-MM-DDTHH:mm:ss.SSS[Z]`))
            }
          />
        );
      }
      case 'timezone': {
        return (
          <Select
            name={`${name}${subType}`}
            disabled={form.values.deleted || !editable}
            showSearch
            defaultValue={timezone || moment.tz.guess()}
            onChange={(value: string) => {
              handleChangeTimezone(name, value);
            }}
          >
            {moment.tz.names().map((option, key) => (
              <Select.Option key={`${option}-${key}`} value={option}>
                {option}
              </Select.Option>
            ))}
          </Select>
        );
      }
      default: {
        throw new Error(`Unhandled custom field ${name} of type ${subType} in
    renderCustomField method`);
      }
    }
  };

  const required =
    validationRules.find((rule) => rule.validationRuleType === RequiredValidationRuleId) !==
    undefined;

  return (
    <ValidateFormItem
      validationRules={validationRules || []}
      renderFormItem={(validate) => {
        return (
          <Form.Item
            name={name}
            label={<label style={{height: '0px'}}>{label}</label>}
            validate={validate}
            required={required}
            tooltip={
              required
                ? {
                    icon: <span style={{color: 'red', fontSize: 14, fontWeight: 'bolder'}}>*</span>,
                  }
                : undefined
            }
          >
            {fieldToRender(name, subType)}
          </Form.Item>
        );
      }}
    />
  );
};
