import {AbstractValidationRule} from './abstract-validation-rule';

export const RegexValidationId = 'regex';

/**
 *
 *
 * @export
 * @interface RegexValidationRule
 * @extends {AbstractValidationRule}
 */
export interface RegexValidationRule extends AbstractValidationRule {
  validationRuleType: typeof RegexValidationId;
  /**
   * The regex to check the value with
   *
   * @type {RegExp}
   * @memberof RegexValidationRule
   */
  regex: RegExp;
  /**
   * Optional error message to show the user if validation fails.
   * Advised to use this since it could be hard for a user to understand
   * a regex which is what is shown in the default error message.
   *
   * @type {string}
   * @memberof RegexValidationRule
   */
  errorMessage?: string;
}

/**
 * Checks whether the value passes the regex using the RegExp.test method
 * If the value is null or undefined, it will return true.
 *
 * @export
 * @param {*} value
 * @param {RegexValidationRule} rule
 * @returns {boolean}
 */
export function validateRegexRule(value: any, rule: RegexValidationRule): boolean {
  if (value === null || value === undefined) {
    return true;
  }

  return rule.regex.test(value);
}
