"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_TestScript_Operation = exports.TestScript_OperationMethodKind = void 0;
var t = __importStar(require("io-ts"));
var RTTI_Extension_1 = require("./RTTI_Extension");
var RTTI_Coding_1 = require("./RTTI_Coding");
var RTTI_code_1 = require("../Scalar/RTTI_code");
var RTTI_Element_1 = require("./RTTI_Element");
var RTTI_integer_1 = require("../Scalar/RTTI_integer");
var RTTI_TestScript_RequestHeader_1 = require("./RTTI_TestScript_RequestHeader");
var RTTI_id_1 = require("../Scalar/RTTI_id");
var TestScript_OperationMethodKind;
(function (TestScript_OperationMethodKind) {
    TestScript_OperationMethodKind["_delete"] = "delete";
    TestScript_OperationMethodKind["_get"] = "get";
    TestScript_OperationMethodKind["_options"] = "options";
    TestScript_OperationMethodKind["_patch"] = "patch";
    TestScript_OperationMethodKind["_post"] = "post";
    TestScript_OperationMethodKind["_put"] = "put";
    TestScript_OperationMethodKind["_head"] = "head";
})(TestScript_OperationMethodKind = exports.TestScript_OperationMethodKind || (exports.TestScript_OperationMethodKind = {}));
var EnumType_1 = require("../../EnumType");
exports.RTTI_TestScript_Operation = t.recursion('ITestScript_Operation', function () {
    return t.partial({
        id: t.string,
        extension: t.array(RTTI_Extension_1.RTTI_Extension),
        modifierExtension: t.array(RTTI_Extension_1.RTTI_Extension),
        type: RTTI_Coding_1.RTTI_Coding,
        resource: RTTI_code_1.RTTI_code,
        _resource: RTTI_Element_1.RTTI_Element,
        label: t.string,
        _label: RTTI_Element_1.RTTI_Element,
        description: t.string,
        _description: RTTI_Element_1.RTTI_Element,
        accept: RTTI_code_1.RTTI_code,
        _accept: RTTI_Element_1.RTTI_Element,
        contentType: RTTI_code_1.RTTI_code,
        _contentType: RTTI_Element_1.RTTI_Element,
        destination: RTTI_integer_1.RTTI_integer,
        _destination: RTTI_Element_1.RTTI_Element,
        encodeRequestUrl: t.boolean,
        _encodeRequestUrl: RTTI_Element_1.RTTI_Element,
        method: EnumType_1.createEnumType(TestScript_OperationMethodKind, 'TestScript_OperationMethodKind'),
        _method: RTTI_Element_1.RTTI_Element,
        origin: RTTI_integer_1.RTTI_integer,
        _origin: RTTI_Element_1.RTTI_Element,
        params: t.string,
        _params: RTTI_Element_1.RTTI_Element,
        requestHeader: t.array(RTTI_TestScript_RequestHeader_1.RTTI_TestScript_RequestHeader),
        requestId: RTTI_id_1.RTTI_id,
        _requestId: RTTI_Element_1.RTTI_Element,
        responseId: RTTI_id_1.RTTI_id,
        _responseId: RTTI_Element_1.RTTI_Element,
        sourceId: RTTI_id_1.RTTI_id,
        _sourceId: RTTI_Element_1.RTTI_Element,
        targetId: RTTI_id_1.RTTI_id,
        _targetId: RTTI_Element_1.RTTI_Element,
        url: t.string,
        _url: RTTI_Element_1.RTTI_Element
    });
});
