import * as Icons from '@ant-design/icons';
import {
  message,
  Button,
  Popconfirm,
  Tooltip,
  Modal,
  Card,
  PageHeader,
  Typography,
  Affix,
} from 'antd';
import {Formik} from 'formik';
import {Form, Input} from 'formik-antd';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams, useLocation} from 'react-router-dom';
import {ThunkDispatch} from '../../models';
import {useGlobalSettings} from '../../models/global-settings';
import {useGlobalOrg} from '../../models/ui';
import {FhirUtils} from '../../services/fhir';
import {useQuery, capitalizeFirstLetter} from './base-entity';
import {toolTipTitle} from './base-entity-search';
const {Title} = Typography;

export const ItemPage = (props) => {
  const globalOrg = useGlobalOrg();
  const navigate = useNavigate();
  const query = useQuery();
  const params = useParams();
  const location = useLocation();
  const settings = useGlobalSettings();
  const thunkDispatch = useDispatch<ThunkDispatch>();

  const {
    formConfig,
    slugs,
    getCurrentItemId,
    model,
    getDeleteSuccessPath: deleteSuccessPath,
    slug,
    useGlobalOrgFilter,
    showItemTitleBar = true,
    titleBarEntityLabel,
    stickyHeader,
    itemPage,
    ItemPageButtons,
    hideEditButton,
    hideDeleteButton,
    showNoteModalForDelete,
    getTitleInfo,
    isShield,
    showBackButton,
    titleBarFooter,
    modalEditForm,
    editOnItemPage,
    ItemForm,
    itemPagePlugin,
  } = props;

  const client = FhirUtils.useClient();
  const itemId = getCurrentItemId ? getCurrentItemId() : params.id;
  const item = useSelector(
    formConfig?.itemSelector
      ? formConfig.itemSelector({itemId, slugs})
      : (state) => state[slugs]?.byId[itemId!]
  );
  const [editMode, setEditMode] = React.useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = React.useState(false);

  /* Force rerender of item page via useSelector hook when organizations in the local store are saved/updated.
    Read more: https://react-redux.js.org/api/hooks#useselector */
  // const organizations = useSelector((state: RootState) => state.organizations.byId);

  const onConfirmDelete = async (values, formikBag?) => {
    await thunkDispatch(model.deleteOne(client, itemId, values?.deleteReason))
      .then(async (res) => {
        message.success(`${capitalizeFirstLetter(slug)} successfully deleted`);
        navigate(deleteSuccessPath ? deleteSuccessPath() : `/${slugs}`);
      })
      .catch((err) => {
        message.error(`Failed to delete ${slug}`);
      })
      .finally(() => formikBag?.resetForm());
  };

  // Retrieve the data for the table
  useEffect(() => {
    if (itemId === 'new') return;
    useGlobalOrgFilter
      ? thunkDispatch(model.getOne(client, itemId, {_organizationId: globalOrg?.id}))
      : thunkDispatch(model.getOne(client, itemId));
  }, [itemId]);

  const shouldShowTitleBar = showItemTitleBar === undefined ? false : showItemTitleBar;
  const IP = itemPage;

  return (
    <ItemPageWrapper {...props}>
      {shouldShowTitleBar === true ? (
        <>
          {titleBarEntityLabel && (
            <div style={{color: '#777', fontSize: 12, fontWeight: 'bold'}}>
              {titleBarEntityLabel.toUpperCase()}
            </div>
          )}
          <TitleBar
            sticky={stickyHeader}
            item={item}
            buttons={
              <>
                {ItemPageButtons && item ? <ItemPageButtons item={item} /> : null}
                {!(typeof hideEditButton === 'function' ? hideEditButton(item) : hideEditButton) &&
                  !editMode && (
                    <Button key="edit" onClick={() => setEditMode(true)}>
                      <Icons.EditOutlined /> Edit
                    </Button>
                  )}
                {!(typeof hideDeleteButton === 'function'
                  ? hideDeleteButton(item)
                  : hideDeleteButton) && (
                  <Popconfirm
                    title={`Are you sure you want to delete this ${slug}?`}
                    onConfirm={
                      showNoteModalForDelete ? () => setDeleteModalVisible(true) : onConfirmDelete
                    }
                    okText="Yes"
                    cancelText="No"
                  >
                    {isShield ? (
                      <Button key="delete" danger>
                        <Icons.DeleteOutlined /> Delete
                      </Button>
                    ) : (
                      <Tooltip title={toolTipTitle(`${slug}-delete`)}>
                        <Button key="delete" danger>
                          <Icons.DeleteOutlined /> Delete
                        </Button>
                      </Tooltip>
                    )}
                  </Popconfirm>
                )}
                {/* <Button key="fhir" onClick={() => navigate(`/${slugs}/${itemId}/fhir`)}>
                <Icons.FireOutlined />
              </Button>
              <Button key="history" disabled>
                <Icons.HistoryOutlined />
              </Button> */}
              </>
            }
            showBackButton={showBackButton === undefined ? true : showBackButton}
            footer={titleBarFooter && item ? titleBarFooter(item, settings) : null}
            getTitleInfo={getTitleInfo}
          />
        </>
      ) : undefined}

      {itemPage && (!editMode || (editMode && modalEditForm) || editOnItemPage) ? (
        <IP itemId={itemId} editMode={editMode} setEditMode={setEditMode} />
      ) : null}

      {formConfig && !itemPage ? (
        <ItemForm itemId={itemId} editMode={editMode} setEditMode={setEditMode} />
      ) : null}

      {modalEditForm && editMode ? (
        <ItemForm
          itemId={itemId}
          editMode={editMode}
          modal={true}
          modalVisible={editMode}
          setModalVisible={setEditMode}
        />
      ) : null}

      {/* Render the itemPagePlugin if one was provided */}
      {itemPagePlugin ? itemPagePlugin({navigate, location, query, slugs}) : null}
      {/* Delete AdminNotes Modal */}
      {showNoteModalForDelete && (
        <Formik
          initialValues={{deleteSubject: `Delete ${slug}`, deleteReason: ''}}
          onSubmit={onConfirmDelete}
        >
          {(formikProps) => {
            return (
              <Modal
                title="Admin Notes for Deletion"
                visible={deleteModalVisible}
                onCancel={() => {
                  setDeleteModalVisible(false);
                  formikProps.handleReset();
                }}
                okButtonProps={{
                  disabled: formikProps.values.deleteReason.length <= 0,
                }}
                onOk={() => {
                  setDeleteModalVisible(false);
                  formikProps.handleSubmit();
                }}
              >
                <Form labelCol={{span: 4}} wrapperCol={{span: 18}}>
                  <Form.Item name="delete.subject" label="Subject">
                    <Input name="deleteSubject" disabled />
                  </Form.Item>
                  <Form.Item name={'delete.reason'} label={'Reason'} key={'delete.reason'} required>
                    <Input.TextArea name="deleteReason" required />
                  </Form.Item>
                </Form>
              </Modal>
            );
          }}
        </Formik>
      )}
    </ItemPageWrapper>
  );
};

const ItemPageWrapper = ({children, card = true}) => {
  return card ? <Card>{children}</Card> : <div>{children}</div>;
};

const TitleBar = (props: {
  item: any;
  footer?: React.ReactNode;
  buttons?: React.ReactNode;
  sticky?: boolean;
  showBackButton: boolean;
  getTitleInfo: (item) => any;
}) => {
  const navigate = useNavigate();
  const {item, buttons, showBackButton, footer, sticky, getTitleInfo} = props;
  const {title, subtitle, tags} = getTitleInfo(item);
  const Header = (
    <PageHeader
      className="site-page-header-responsive"
      onBack={() => {
        navigate(-1);
      }}
      title={
        <Title level={2} style={{marginBottom: 0}}>
          {title}
        </Title>
      }
      subTitle={subtitle}
      tags={tags}
      footer={footer}
      style={{padding: '0px 0px 16px', backgroundColor: 'white'}}
      extra={buttons}
      backIcon={showBackButton ? undefined : false}
    />
  );
  return sticky ? <Affix>{Header}</Affix> : Header;
};
