import {Select, Form} from 'formik-antd';
import React from 'react';
import {useHcnTypes} from '../../models/hcn-type';
import {RequiredValidationRuleId} from '../../validation/validation-rules/required-validation';
import {ValidationRules} from '../../validation/validation-rules/validation-rules';
import {useUrlData} from '../scheduler/util';
import {ValidateFormItem} from '../validate-form-item';

interface HcnTypePickerFormItemProps {
  label?: string;
  name: string;
  setFieldValue: (name: string, value: any) => void;
  validationRules?: ValidationRules[];
  disabled?: boolean;
  looksRequired?: boolean;
}

export const HcnTypePickerFormItem = (props: HcnTypePickerFormItemProps) => {
  const hcnTypes = useHcnTypes();
  const {lang} = useUrlData();

  const required =
    props.looksRequired ||
    props.validationRules?.find((rule) => rule.validationRuleType === RequiredValidationRuleId) !==
      undefined;

  return (
    <ValidateFormItem
      validationRules={props.validationRules || []}
      renderFormItem={(validate) => {
        return (
          <Form.Item
            name={props.name || 'hcnType'}
            label={props.label || 'Health Card Type'}
            validate={validate}
            tooltip={
              required
                ? {
                    icon: <span style={{color: 'red', fontSize: 14, fontWeight: 'bolder'}}>*</span>,
                  }
                : undefined
            }
          >
            <Select
              name={props.name}
              disabled={props.disabled}
              showSearch
              placeholder="Select an Health Card Type"
              optionFilterProp="children"
              onChange={(value) => {
                props.setFieldValue(props.name || 'hcnType', value);
              }}
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input?.toLowerCase()) >= 0
              }
              allowClear
            >
              {hcnTypes.map((hcnType) => (
                <Select.Option value={hcnType.id} key={hcnType.id}>
                  {hcnType.name[lang || 'en']}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        );
      }}
    />
  );
};
