import {message} from 'antd';

export const downloadTemplate = async (template: string, client) => {
  try {
    const presignedUrl = await client.get(`/import/template/${template}`);
    window.open(presignedUrl.data.presignedUrl, '_blank');
  } catch (err) {
    message.error('Failed to download template.');
  }
};
