import {Button, Checkbox, message, Space} from 'antd';
import moment from 'moment';
import React from 'react';
import {DownloadOutlined} from '@ant-design/icons';
import {CSVLink} from 'react-csv';
import {FhirUtils} from '../../services/fhir';
import {useGlobalOrg} from '../../models/ui';

const qs = require('query-string');

export const AppointmentStatsDownload = ({dates, targetData, calendarType}) => {
  const [retrieveAllClinics, setretrieveAllClinics] = React.useState(false);
  const [downloadableData, setDownloadableData] = React.useState(false);
  const refCSVLink = React.useRef<CSVLink>(null);
  const client = FhirUtils.useAxiosClient();
  const selectedOrg = useGlobalOrg();
  const headers = [
    {label: 'Name', key: 'name'},
    {label: 'Date', key: 'date'},
    {label: 'Planned', key: 'planned'},
    {label: 'Available', key: 'available'},
    {label: 'Booked', key: 'booked'},
    {label: 'Booked/Planned (%)', key: 'bookedPercentage'},
    {label: 'Remaining', key: 'remaining'},
    {label: 'Remaining/Planned (%)', key: 'remainingPercentage'},
    {label: 'Canceled', key: 'canceled'},
    {label: 'No Show', key: 'noShow'},
    {label: 'Immunized', key: 'immunized'},
    {label: 'Left to immunize', key: 'leftToImmunize'},
  ];

  React.useEffect(() => {
    if (downloadableData) {
      setTimeout(() => {
        refCSVLink?.current?.link.click();
        setDownloadableData(false);
      });
    }
  }, [downloadableData]);

  const handleCSVOnClick = () => {
    if (retrieveAllClinics) {
      const startDate = moment(dates[0]).toISOString();
      const endDate = moment(dates[1]).toISOString();
      const calTypePub = calendarType.PUBLIC;
      const calTypeInt = calendarType.INTERNAL;
      const query = qs.stringify({startDate, endDate, calTypePub, calTypeInt});
      client
        .get(`/appointment/stats?_organizationId=${selectedOrg?.id || ''}&${query}`)
        .then((res) => {
          if (res.data && res.data.length > 0) {
            setDownloadableData(res.data.map((p) => p.children).flat());
          } else {
            message.error('No available data');
          }
        })
        .catch((err) => {
          message.error('Failed to download data');
        });
    } else {
      if (targetData.length > 0) {
        setDownloadableData(targetData.map((p) => p.children).flat());
      } else {
        message.error('No available data');
      }
    }
  };

  return (
    <Space direction="vertical" align="center" style={{float: 'right'}}>
      {downloadableData && (
        <CSVLink
          filename={'ClinicStats_' + moment().format('YYMMDDHHmm') + '.csv'}
          data={downloadableData}
          headers={headers}
          target="_blank"
          ref={refCSVLink}
        />
      )}
      <Button type="primary" size="small" icon={<DownloadOutlined />} onClick={handleCSVOnClick}>
        Export to CSV
      </Button>
      <Checkbox
        checked={retrieveAllClinics}
        onChange={(e) => setretrieveAllClinics(e.target.checked)}
      >
        All Clinics
      </Checkbox>
    </Space>
  );
};
