import React from 'react';
import {useEnvInfo} from '../../services/environment';

export enum Stage {
  DEV = 'dev',
  QA = 'qa',
  PROD = 'prod',
}

export enum App {
  FLOW = 'flow',
  APP = 'app',
  SHIELD = 'shield',
}

interface Props {
  app?: App | App[];
  stage?: Stage | Stage[];
  environmentId?: string | string[];
  children: React.ReactNode | null;
}

export const Environment = (props: Props) => {
  const {app, stage, environmentId} = useEnvInfo();
  const pluralize = (param) => {
    return param ?? Array.isArray(param) ? param : [param];
  };

  const apps = pluralize(props.app);
  const stages = pluralize(props.stage);
  const environmentIds = pluralize(props.environmentId);

  if (apps && apps.indexOf(app) === -1) {
    return null;
  }

  if (stages && stages.indexOf(stage) === -1) {
    return null;
  }

  if (environmentIds && environmentIds.indexOf(environmentId) === -1) {
    return null;
  }

  return <>{props.children}</>;
};
