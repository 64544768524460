import {Button, Space, Typography, Tooltip, Tag} from 'antd';
import {ReloadOutlined} from '@ant-design/icons';
import moment from 'moment-timezone';
import {useState} from 'react';
import {FHIRTable, FHIRTableProps} from '../../fhir-table';
import React from 'react';
import {TestModal} from './test-modal';

const {Title} = Typography;

// The columns of the TestsTable
const columns = [
  {
    title: 'Test Type',
    dataIndex: 'name',
    width: '16%',
  },
  {
    title: 'Collection Date',
    dataIndex: 'collectionDate',
    width: '16%',
    sorter: true,
    render: (_, test) => moment.utc(test.collectionDate).local().format('LL'),
  },
  {
    title: 'Location',
    dataIndex: 'locationName',
    width: '16%',
  },
  {
    title: 'Specimen Id',
    dataIndex: 'specimenId',
    width: '16%',
  },
  {
    title: 'Result',
    dataIndex: 'result',
    width: '16%',
    sorter: true,
    render: (_, test) => {
      return <ResultTag result={results[test.result]} />;
    },
  },
  {
    title: 'Result Date',
    dataIndex: 'resultDate',
    width: '16%',
    sorter: true,
    render: (_, test) => moment.utc(test.resultDate).local().format('LL'),
  },
];

//Results text mapped to stored number values
const results = {
  0: {label: 'Positive/Detected', color: 'green'},
  1: {label: 'Negative/Not Detected', color: 'red'},
  2: {label: 'Indeterminate', color: 'orange'},
  3: {label: 'Inconclusive', color: 'volcano'},
};

const ResultTag = ({result}) => <Tag color={result.color}>{result.label}</Tag>;

interface TestsTableProps {
  /* The patient. */
  patient: any;
  /* The title of the table. */
  title?: string;
  /* Any props to be passed down into the <FHIRTable /> element. */
  tableProps?: FHIRTableProps;
}

export const TestsTable = (props: TestsTableProps) => {
  const {patient, title, tableProps} = props;

  const [currentTest, setCurrentTest] = useState();
  const [showModal, setShowModal] = useState(false);
  const [refreshTable, setRefreshTable] = useState(false);

  const reloadTable = () => setRefreshTable(!refreshTable);

  const onClose = () => {
    setShowModal(false);
    setCurrentTest(undefined);
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingBottom: 15,
          alignItems: 'center',
        }}
      >
        <div>
          <Title level={4}>
            {title}{' '}
            <Tooltip title="reload table">
              <Button
                icon={<ReloadOutlined style={{opacity: '.3'}} onClick={reloadTable} />}
                type="text"
              />
            </Tooltip>
          </Title>
        </div>
        <Space>
          <Button type="primary" onClick={() => setShowModal(true)}>
            Add Test
          </Button>
        </Space>
      </div>

      <FHIRTable
        mode="table"
        fhirResource={'test'}
        fixedFilters={{
          patientId: patient.id,
        }}
        hideSearch
        columns={columns}
        size="small"
        style={{width: '100%'}}
        showCreateButton={true}
        onClickRow={(test) => {
          setCurrentTest(test);
          setShowModal(true);
        }}
        rowClassName="pointer"
        triggerRefresh={refreshTable}
        {...tableProps}
      />
      {/** Using a conditional rendering of TestModal will trigger rerender of debounce selector
       *  along with the modal */}
      {showModal && (
        <TestModal
          patient={patient}
          currentTest={currentTest}
          visible={showModal}
          onClose={onClose}
          reloadTable={reloadTable}
        />
      )}
    </>
  );
};
