import {Ids, Str} from '@canimmunize/tools';
import {Input, SubmitButton, Form} from 'formik-antd';
import {Formik} from 'formik';
import React from 'react';
import {useUrlData} from '../util';
import {Typography} from 'antd';
import axios from 'axios';

export const BookingPagePasswordForm = (props) => {
  const {setPasswordVerified} = props;
  const [error, setError] = React.useState<string>('');
  const {config, syncUrl} = useUrlData();

  const submit = async (values) => {
    console.log(values);

    await axios
      .post(syncUrl + `/public/booking-page/${config.id}/verify`, {password: values.password})
      .then((res) => {
        localStorage.setItem(`pwd-${config.id}`, values.password);
        setPasswordVerified(true);
      })
      .catch((err) => {
        values.password = '';
        if (err.response?.status == 400) setError('Invalid Password');
        else setError(err.message);
      });
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <div>
        <img src="https://cdn.canimmunize.ca/logo-nobird.png" style={{width: 200}} />
      </div>
      <Formik initialValues={{password: ''}} onSubmit={submit}>
        {(props) => (
          <Form style={{display: 'flex', flexDirection: 'column'}}>
            <div style={{marginTop: 15, marginBottom: 15}}>
              <Form.Item name="password">
                <Input.Password name="password" placeholder="Password" />
              </Form.Item>
              <Typography.Text type="danger">{error}</Typography.Text>
            </div>
            <SubmitButton>{Str(Ids.begin)}</SubmitButton>
          </Form>
        )}
      </Formik>
    </div>
  );
};
