import {Ids} from '@canimmunize/tools';
import cn from 'classnames';
import React from 'react';
import {UncontrolledTooltip} from 'reactstrap';
import {fullDisplayName} from '../../../models/fhir/patient';
import {useStr} from '../../../services/str';
import {BFCard} from '../booking-form/card';
import s from '../styles.module.css';

export const MultiplePatientWrapper = (props) => {
  return (
    <BFCard style={{border: 'none'}}>
      {props.patients && <MultiplePatientHeader {...props} />}
      <div className={s.formWrapper}>{props.children}</div>
    </BFCard>
  );
};

export const MultiplePatientHeader = (props) => {
  const Str = useStr();
  return (
    <div
      className="card-header"
      style={{
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        backgroundColor: 'rgb(232 232 232)',
        borderBottom: 'none',
        boxShadow: 'inset 0px -1px 5px 0px #0000000d',
      }}
    >
      <ul className="nav nav-tabs card-header-tabs">
        {props.patients.map((p, i) => {
          const id = `patient-id-${p.id}`;
          return (
            <li role="presentation" className={cn('nav-item', {active: i === props.page})}>
              <a
                id={id}
                className={cn('nav-link', {active: i === props.page})}
                style={{borderTopRightRadius: 10, borderTopLeftRadius: 10, border: 'none'}}
              >
                {fullDisplayName(p)}
              </a>
              {i > props.page && (
                <UncontrolledTooltip placement="top" target={id} trigger="hover">
                  {Str(
                    Ids.please_complete_forms_in_order,
                    'Please complete the forms in order. Press the next at the bottom of this page to move to the next tab.'
                  )}
                </UncontrolledTooltip>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};
