import {faCalendarAlt, faCopy, faPencilAlt, faWalking} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, message, Space} from 'antd';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {FhirUtils} from '../../../services/fhir';

export const CalendarListActions = (props) => {
  const {calendar} = props;
  const client = FhirUtils.useAxiosClient();
  const navigate = useNavigate();

  const copyCalendar = async (calendar) => {
    const copiedCalendar = await client
      .get(`/calendar/${calendar.id}/copy`)
      .then(({data}) => data)
      .catch((err) => {
        message.error(`Error copying calendar.`);
      });

    if (copiedCalendar) {
      message.success('Successfully copied calendar!');
      navigate(`/clinics/${copiedCalendar.clinicId}/calendars/${copiedCalendar.id}`);
    }
  };

  return (
    <Space>
      <Button
        type="primary"
        size={'small'}
        style={{paddingLeft: 10, paddingRight: 10}}
        onClick={() => copyCalendar(calendar)}
      >
        <FontAwesomeIcon icon={faCopy} color="white" style={{marginRight: 10}} />
        Copy
      </Button>
    </Space>
  );
};
