"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_Consent = exports.ConsentStatusKind = void 0;
var t = __importStar(require("io-ts"));
var RTTI_id_1 = require("../Scalar/RTTI_id");
var RTTI_Meta_1 = require("./RTTI_Meta");
var RTTI_uri_1 = require("../Scalar/RTTI_uri");
var RTTI_Element_1 = require("./RTTI_Element");
var RTTI_code_1 = require("../Scalar/RTTI_code");
var RTTI_Narrative_1 = require("./RTTI_Narrative");
var RTTI_ResourceList_1 = require("../Union/RTTI_ResourceList");
var RTTI_Extension_1 = require("./RTTI_Extension");
var RTTI_Identifier_1 = require("./RTTI_Identifier");
var RTTI_CodeableConcept_1 = require("./RTTI_CodeableConcept");
var RTTI_Reference_1 = require("./RTTI_Reference");
var RTTI_dateTime_1 = require("../Scalar/RTTI_dateTime");
var RTTI_Attachment_1 = require("./RTTI_Attachment");
var RTTI_Consent_Policy_1 = require("./RTTI_Consent_Policy");
var RTTI_Consent_Verification_1 = require("./RTTI_Consent_Verification");
var RTTI_Consent_Provision_1 = require("./RTTI_Consent_Provision");
var ConsentStatusKind;
(function (ConsentStatusKind) {
    ConsentStatusKind["_draft"] = "draft";
    ConsentStatusKind["_proposed"] = "proposed";
    ConsentStatusKind["_active"] = "active";
    ConsentStatusKind["_rejected"] = "rejected";
    ConsentStatusKind["_inactive"] = "inactive";
    ConsentStatusKind["_enteredInError"] = "entered-in-error";
})(ConsentStatusKind = exports.ConsentStatusKind || (exports.ConsentStatusKind = {}));
var EnumType_1 = require("../../EnumType");
exports.RTTI_Consent = t.recursion('IConsent', function () {
    return t.intersection([
        t.type({
            resourceType: t.literal('Consent'),
            scope: RTTI_CodeableConcept_1.RTTI_CodeableConcept,
            category: t.array(RTTI_CodeableConcept_1.RTTI_CodeableConcept)
        }),
        t.partial({
            id: RTTI_id_1.RTTI_id,
            meta: RTTI_Meta_1.RTTI_Meta,
            implicitRules: RTTI_uri_1.RTTI_uri,
            _implicitRules: RTTI_Element_1.RTTI_Element,
            language: RTTI_code_1.RTTI_code,
            _language: RTTI_Element_1.RTTI_Element,
            text: RTTI_Narrative_1.RTTI_Narrative,
            contained: t.array(RTTI_ResourceList_1.RTTI_ResourceList),
            extension: t.array(RTTI_Extension_1.RTTI_Extension),
            modifierExtension: t.array(RTTI_Extension_1.RTTI_Extension),
            identifier: t.array(RTTI_Identifier_1.RTTI_Identifier),
            status: EnumType_1.createEnumType(ConsentStatusKind, 'ConsentStatusKind'),
            _status: RTTI_Element_1.RTTI_Element,
            patient: RTTI_Reference_1.RTTI_Reference,
            dateTime: RTTI_dateTime_1.RTTI_dateTime,
            _dateTime: RTTI_Element_1.RTTI_Element,
            performer: t.array(RTTI_Reference_1.RTTI_Reference),
            organization: t.array(RTTI_Reference_1.RTTI_Reference),
            sourceAttachment: RTTI_Attachment_1.RTTI_Attachment,
            sourceReference: RTTI_Reference_1.RTTI_Reference,
            policy: t.array(RTTI_Consent_Policy_1.RTTI_Consent_Policy),
            policyRule: RTTI_CodeableConcept_1.RTTI_CodeableConcept,
            verification: t.array(RTTI_Consent_Verification_1.RTTI_Consent_Verification),
            provision: RTTI_Consent_Provision_1.RTTI_Consent_Provision
        })
    ]);
});
