import {FormatTimezoneAbbr} from '@canimmunize/tools';
import {Moment} from 'moment-timezone';

export const appointmentDateString = (date: Moment, lang: string, timezone: string) => {
  let formattedDate = `${date.format('LLLL')} ${FormatTimezoneAbbr(
    date.format('zz'),
    lang,
    timezone
  )}`;
  if (lang === 'fr') {
    formattedDate = `${date.format('dddd, LL')} ${date.format('H')} h ${date.format(
      'mm'
    )} ${FormatTimezoneAbbr(date.format('zz'), lang, timezone)}`;
  }
  return formattedDate;
};