"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_SubstanceSpecification = void 0;
var t = __importStar(require("io-ts"));
var RTTI_id_1 = require("../Scalar/RTTI_id");
var RTTI_Meta_1 = require("./RTTI_Meta");
var RTTI_uri_1 = require("../Scalar/RTTI_uri");
var RTTI_Element_1 = require("./RTTI_Element");
var RTTI_code_1 = require("../Scalar/RTTI_code");
var RTTI_Narrative_1 = require("./RTTI_Narrative");
var RTTI_ResourceList_1 = require("../Union/RTTI_ResourceList");
var RTTI_Extension_1 = require("./RTTI_Extension");
var RTTI_Identifier_1 = require("./RTTI_Identifier");
var RTTI_CodeableConcept_1 = require("./RTTI_CodeableConcept");
var RTTI_Reference_1 = require("./RTTI_Reference");
var RTTI_SubstanceSpecification_Moiety_1 = require("./RTTI_SubstanceSpecification_Moiety");
var RTTI_SubstanceSpecification_Property_1 = require("./RTTI_SubstanceSpecification_Property");
var RTTI_SubstanceSpecification_Structure_1 = require("./RTTI_SubstanceSpecification_Structure");
var RTTI_SubstanceSpecification_Code_1 = require("./RTTI_SubstanceSpecification_Code");
var RTTI_SubstanceSpecification_Name_1 = require("./RTTI_SubstanceSpecification_Name");
var RTTI_SubstanceSpecification_MolecularWeight_1 = require("./RTTI_SubstanceSpecification_MolecularWeight");
var RTTI_SubstanceSpecification_Relationship_1 = require("./RTTI_SubstanceSpecification_Relationship");
exports.RTTI_SubstanceSpecification = t.recursion('ISubstanceSpecification', function () {
    return t.intersection([
        t.type({
            resourceType: t.literal('SubstanceSpecification')
        }),
        t.partial({
            id: RTTI_id_1.RTTI_id,
            meta: RTTI_Meta_1.RTTI_Meta,
            implicitRules: RTTI_uri_1.RTTI_uri,
            _implicitRules: RTTI_Element_1.RTTI_Element,
            language: RTTI_code_1.RTTI_code,
            _language: RTTI_Element_1.RTTI_Element,
            text: RTTI_Narrative_1.RTTI_Narrative,
            contained: t.array(RTTI_ResourceList_1.RTTI_ResourceList),
            extension: t.array(RTTI_Extension_1.RTTI_Extension),
            modifierExtension: t.array(RTTI_Extension_1.RTTI_Extension),
            identifier: RTTI_Identifier_1.RTTI_Identifier,
            type: RTTI_CodeableConcept_1.RTTI_CodeableConcept,
            status: RTTI_CodeableConcept_1.RTTI_CodeableConcept,
            domain: RTTI_CodeableConcept_1.RTTI_CodeableConcept,
            description: t.string,
            _description: RTTI_Element_1.RTTI_Element,
            source: t.array(RTTI_Reference_1.RTTI_Reference),
            comment: t.string,
            _comment: RTTI_Element_1.RTTI_Element,
            moiety: t.array(RTTI_SubstanceSpecification_Moiety_1.RTTI_SubstanceSpecification_Moiety),
            property: t.array(RTTI_SubstanceSpecification_Property_1.RTTI_SubstanceSpecification_Property),
            referenceInformation: RTTI_Reference_1.RTTI_Reference,
            structure: RTTI_SubstanceSpecification_Structure_1.RTTI_SubstanceSpecification_Structure,
            code: t.array(RTTI_SubstanceSpecification_Code_1.RTTI_SubstanceSpecification_Code),
            name: t.array(RTTI_SubstanceSpecification_Name_1.RTTI_SubstanceSpecification_Name),
            molecularWeight: t.array(RTTI_SubstanceSpecification_MolecularWeight_1.RTTI_SubstanceSpecification_MolecularWeight),
            relationship: t.array(RTTI_SubstanceSpecification_Relationship_1.RTTI_SubstanceSpecification_Relationship),
            nucleicAcid: RTTI_Reference_1.RTTI_Reference,
            polymer: RTTI_Reference_1.RTTI_Reference,
            protein: RTTI_Reference_1.RTTI_Reference,
            sourceMaterial: RTTI_Reference_1.RTTI_Reference
        })
    ]);
});
