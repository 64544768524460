import {CheckCircleOutlined, MoreOutlined} from '@ant-design/icons';
import {Button, message, Modal, Space, Table, Tag, Timeline, Tooltip, Typography} from 'antd';

import {uniq} from 'lodash';
import moment, {Moment} from 'moment-timezone';
import React from 'react';
import {useDispatch} from 'react-redux';
import {ThunkDispatch} from '../../models/';

import {getStoredOrg} from '../../models/organizations';
import patientModel from '../../models/patients';
import {FhirUtils} from '../../services/fhir';
import {AbilityContext} from '../../services/roles/ability-context';
import {FHIRTable} from '../fhir-table';

const {Title} = Typography;

const getPreferability = (i) => {
  switch (i) {
    case 'ALLOWABLE':
      return (
        <Space wrap>
          <Tag color="processing">Allowed</Tag>
        </Space>
      );
    case 'PREFERRED':
      return <Tag color="success">Preferred</Tag>;
    default:
      return null;
  }
};

export const ForecastStatusTag = (props: {status: string}) => {
  const {status} = props;
  switch (status) {
    case 'IN_PROGRESS':
      return (
        <Space wrap>
          <Tag icon={<CheckCircleOutlined />} color="processing">
            In Progress
          </Tag>
        </Space>
      );
    case 'COMPLETE':
    case 'COMPLETED':
      return (
        <Tag icon={<CheckCircleOutlined />} color="success">
          Fully Vaccinated
        </Tag>
      );
    case 'NOT_STARTED':
      return (
        <Tag icon={<CheckCircleOutlined />} color="red">
          Not Started
        </Tag>
      );
    default:
      return null;
  }
};

// Transforms each row to display by rule instead of dose
const responseTransformer = (response) => {
  let newEntries: any[] = [];
  const doseRecommendations = response.entry[0].resource.doseRecommendations
    .map((doseRec) => {
      // TODO: remove this temporary measure for flu when seasonality is properly implemented
      if (doseRec.ruleId === 'c49559de-0baa-42b3-bd13-d97009b05864') {
        doseRec.forecastedMinDate = moment
          .max(moment.utc(doseRec.forecastedMinDate), moment.utc('2022-10-24', 'YYYY-MM-DD'))
          .format('LL');
        delete doseRec.bookableOnDate;
        doseRec.notBookableOnDateReason = 'EARLY';
      }

      return modifyDoseRecsForDate(doseRec);
    })
    .filter((d) => !!d);

  for (const ruleInterpretation of response.entry[0].resource.vaccinationInterpretation
    .ruleInterpretations) {
    ruleInterpretation.doseRecommendations = doseRecommendations.filter(
      (doseRec) => doseRec.ruleInterpretationId === ruleInterpretation.id
    );
    newEntries.push({id: ruleInterpretation.id, resource: ruleInterpretation});
  }
  let transformedResponse = {...response, entry: newEntries};
  return transformedResponse;
};

export interface ForecastTableProps {
  patient: any;
  showPrintRecordButton?: boolean;
  refreshAppointments?: any;
  refreshKey?: any;
  filter?: any;
}

export const ForecastTable = (props: ForecastTableProps) => {
  const {patient, refreshKey, filter} = props;
  const client = FhirUtils.useAxiosClient();
  const ability = React.useContext(AbilityContext);
  const showReforecast = ability.can('reforecast', 'patients');
  const storedOrg = getStoredOrg(patient?.organizationId);
  const [reforecasting, setReforecasting] = React.useState(false);
  const thunkDispatch = useDispatch<ThunkDispatch>();
  const [recommendationModalVisible, setRecommendationModalVisible] = React.useState(false);
  const [doseMapModalVisible, setDoseMapModalVisible] = React.useState(false);
  const [selectedForecast, setSelectedForecast] = React.useState<any>();
  const openRecommendationModal = (forecast) => {
    setRecommendationModalVisible(true);
    setSelectedForecast(forecast);
  };
  const closeRecommendationModal = () => {
    setRecommendationModalVisible(false);
    setSelectedForecast(undefined);
  };
  const openDoseMapModal = (forecast) => {
    setSelectedForecast(forecast);
    setDoseMapModalVisible(true);
  };
  const closeDoseMapModal = () => {
    setDoseMapModalVisible(false);
    setSelectedForecast(undefined);
  };

  const reforecastPatient = async () => {
    setReforecasting(true);
    return client
      .get(`/Patient/${patient.id}/reforecast`)
      .then(() => {
        message.success('Successfully reforecasted patient.');
      })
      .catch((err) => {
        message.error('Error reforecasting patient.');
      })
      .finally(() => {
        thunkDispatch(patientModel.getOne(client, patient.id)).then(() => {
          setReforecasting(false);
        });
      });
  };

  return (
    <>
      {/* reforecast button */}
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <div>
          {' '}
          <Title level={4}>Forecast Status</Title>{' '}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row-reverse',
            justifyContent: 'space-between',
            paddingBottom: 15,
            alignItems: 'center',
          }}
        >
          <Space className="button-container" style={{marginLeft: '50px', flexWrap: 'wrap'}}>
            {showReforecast && (
              <Button
                type="primary"
                name="Reforecast"
                hidden={!showReforecast || !storedOrg}
                loading={reforecasting}
                onClick={reforecastPatient}
              >
                Reforecast
              </Button>
            )}
          </Space>
        </div>
      </div>

      {/* Actual forecast table. */}
      <FHIRTable
        fhirResource="FivecastImmunization"
        responseTransformer={responseTransformer}
        hidePagination
        mode="table"
        triggerRefresh={refreshKey}
        fixedFilters={{patientId: patient.id, date: moment().format('YYYY-MM-DD'), ...filter}}
        hideSearch
        columns={forecastColumns(openRecommendationModal, openDoseMapModal)}
        size="small"
        style={{width: '100%'}}
        // onDataLoad={onDataLoad}
      />

      {/* Modal displaying dose recommendations. */}
      <DoseRecommendationsModal
        visible={recommendationModalVisible}
        onClose={closeRecommendationModal}
        columns={doseColumns}
        doseRecommendations={selectedForecast?.doseRecommendations}
      />

      {/* Modal for displaying branch history. */}
      <BranchHistoryModal
        visible={doseMapModalVisible}
        onClose={closeDoseMapModal}
        ruleInterpretation={selectedForecast}
      />
    </>
  );
};

// Dose Recommendation columns (used with DoseRecommendationsModal)
const doseColumns = [
  {
    title: 'Dose Name',
    dataIndex: 'product',
    key: 'product',
    render: (product, obj) => {
      return (
        <div>
          {product.publicPicklistTermEn} {obj.bookableOnDate}
        </div>
      );
    },
    sorter: (a, b) => {
      return sortHelper(a, b, a?.product?.publicPicklistTermEn, b?.product?.publicPicklistTermEn);
    },
  },
  {
    title: 'Preferability',
    dataIndex: 'preferability',
    key: 'preferability',
    render: (preferability) => {
      return <div>{getPreferability(preferability)}</div>;
    },
    sorter: (a, b) => {
      return sortHelper(a, b, a?.preferability, b?.preferability);
    },
  },
  {
    title: 'Minimum Forecast Date',
    dataIndex: 'forecastedMinDate',
    key: 'forecastedMinDate',
    render: (forecastedMinDate, doseRec) => moment.utc(forecastedMinDate).format('LL'),
    sorter: (a, b) => {
      if (!a.forecastedMinDate) return -1;
      else if (!b.forecastedMinDate) return 1;
      else return moment(a.forecastedMinDate).valueOf() - moment(b.forecastedMinDate).valueOf();
    },
  },
  {
    title: 'Maximum Forecast Date',
    dataIndex: 'forecastedMaxDate',
    key: 'forecastedMaxDate',
    render: (forecastedMaxDate) => {
      if (!forecastedMaxDate) return <div style={{color: 'rgba(0,0,0,0.3)'}}>Not Applicable</div>;
      return moment.utc(forecastedMaxDate).format('LL');
    },
    sorter: (a, b) => {
      if (!a.forecastedMaxDate) return -1;
      else if (!b.forecastedMaxDate) return 1;
      else return moment(a.forecastedMaxDate).valueOf() - moment(b.forecastedMaxDate).valueOf();
    },
  },
  {
    title: 'Target Date',
    dataIndex: 'forecastedTargetDate',
    key: 'forecastedTargetDate',
    render: (forecastedTargetDate) => {
      return moment.utc(forecastedTargetDate).format('LL');
    },
    sorter: (a, b) => {
      if (!a.forecastedTargetDate) return -1;
      else if (!b.forecastedTargetDate) return 1;
      else return moment(a.forecastedMinDate).valueOf() - moment(b.forecastedMinDate).valueOf();
    },
  },
  {
    title: 'Series',
    dataIndex: 'branch',
    key: 'branch',
    render: (branch, dr) =>
      (dr.branchRepetitionNumber === 1
        ? ''
        : moment.localeData().ordinal(dr.branchRepetitionNumber) + ' ') + branch.name,
    sorter: (a, b) => {
      return sortHelper(a, b, a?.branch?.name, b?.branch?.name);
    },
  },
  {
    title: 'Series Dose #',
    dataIndex: 'branchDoseNumber',
    key: 'branchDoseNumber',
  },
  {
    title: (
      <Tooltip title="The eligibility shown is based on the patient's age and person group flags which determines if a patient can book an appointment. Changing the person group flags may affect the patient's booking eligibility.">
        Eligible to Book Today
        <sup>?</sup>
      </Tooltip>
    ),
    dataIndex: 'bookableOnDate',
    key: 'bookableOnDate',
    render: (bookableOnDate, doseRec) => {
      if (bookableOnDate) return <Tag color="success">Eligible Today</Tag>;
      if (doseRec.notBookableOnDateReason === 'EARLY')
        return (
          <Tag color="processing">
            Eligible starting {moment.utc(doseRec.forecastedMinDate).format('LL')}
          </Tag>
        );
      if (doseRec.notBookableOnDateReason === 'ELIGIBILITY_RULE')
        return <Tag color="red">Currently ineligible (group restrictions)</Tag>;
    },
  },
];

// Forecast Table columns
const forecastColumns = (onDoseRecommendationClick, onSeriesTagClick) => [
  {
    title: 'Disease',
    dataIndex: 'displayName',
    key: 'displayName',
    render: (_, i) => (
      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <div>{i?.rule?.diseases[0]?.displayNameEn}</div>
      </div>
    ),
  },
  {
    title: 'Series',
    dataIndex: 'displayName',
    key: 'displayName',
    render: (_, i) => {
      return (
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <Tag
            color=""
            onClick={(e) => {
              e.stopPropagation();
              onSeriesTagClick(i);
            }}
            style={{cursor: 'pointer'}}
          >
            {i.series.name} <MoreOutlined />
          </Tag>
        </div>
      );
    },
  },
  {
    title: 'Primary Series Status',
    dataIndex: 'displayName',
    key: 'displayName',
    render: (_, ruleInterpretation) => (
      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <div>
          <ForecastStatusTag status={ruleInterpretation.forecastStatus} />
        </div>
      </div>
    ),
  },
  {
    title: 'Last Dose Received',
    dataIndex: 'date',
    key: 'date',
    render: (_, obj) => {
      const mostRecentValidDoseMap = obj.doseMap
        .filter((d) => d.absoluteDoseNumber > 0)
        .sort((d1, d2) => (d2.absoluteDoseNumber > d1.absoluteDoseNumber ? 1 : -1))[0];

      return mostRecentValidDoseMap
        ? moment
            .tz(
              mostRecentValidDoseMap.dose.date,
              mostRecentValidDoseMap.dose.clinicEntry?.clinic?.timezone || moment.tz.guess()
            )
            .format('LLL zz')
        : null;
    },
  },
  {
    title: 'Recommended Dose Date',
    render: (_, obj) => {
      const {
        soonestDoseRecommendations,
        soonestDoseRecommendationDate,
        soonestDoseRecommendationsDisplay,
      } = doseRecDisplayForDate(obj.doseRecommendations);

      if (soonestDoseRecommendations.length === 0)
        return <div style={{color: 'rgba(0,0,0,0.3)'}}>No Further Recommendations</div>;

      return (
        <>
          <Tag
            color=""
            onClick={(e) => {
              e.stopPropagation();
              onDoseRecommendationClick(obj);
            }}
            style={{cursor: 'pointer'}}
          >
            {soonestDoseRecommendationsDisplay}:{' '}
            {soonestDoseRecommendations[0].notBookableOnDateReason === 'ELIGIBILITY_RULE'
              ? 'Currently Ineligible'
              : soonestDoseRecommendationDate?.format('LL')}{' '}
            <MoreOutlined />
          </Tag>
        </>
      );
    },
  },
];

/* Creates a timeline visual of doses and branches based off a dose map. */
const DoseMapTimeline = (props: {doseMap: any[]}) => {
  const {doseMap} = props;

  if (!doseMap || !doseMap.find((dm) => dm.absoluteDoseNumber > 0))
    return (
      <div style={{textAlign: 'center', color: 'rgba(0,0,0,0.25)'}}>
        <i>there are no valid doses to display on the timeline</i>
      </div>
    );

  const sortedDoseMap = [...doseMap].sort((dm1, dm2) => (dm2.index > dm1.index ? 1 : -1));

  return (
    <Timeline>
      {sortedDoseMap.map((dm) => {
        if (dm.absoluteDoseNumber < 0) return null;
        return (
          <Timeline.Item color={dm.branchComplete ? 'green' : 'gray'}>
            <span>
              {dm.branchRepetitionNumber === 1
                ? undefined
                : `${moment.localeData().ordinal(dm.branchRepetitionNumber)} `}
              {dm.branch.name} <span style={{opacity: 0.5}}>(D{dm.branchDoseNumber})</span>
            </span>
          </Timeline.Item>
        );
      })}
    </Timeline>
  );
};

// Modal displaying recommended doses
const DoseRecommendationsModal = (props) => {
  if (!props.doseRecommendations) {
    return null;
  }
  const {visible, onClose, columns, doseRecommendations} = props;
  let doseRecCpy = [...doseRecommendations];
  doseRecCpy.sort((a, b) => sortHelper(a, b, a?.forecastedMinDate, b?.forecastedMinDate));
  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      footer={<Button onClick={onClose}>Cancel</Button>}
      width={'90%'}
      title={'Dose Recommendations'}
      destroyOnClose
    >
      <Table dataSource={doseRecCpy} columns={columns} />
    </Modal>
  );
};

/* Modal for displaying the branch history of a patient. */
const BranchHistoryModal = (props) => {
  const {visible, onClose, ruleInterpretation} = props;

  /* A series of elements to display diseases joined by '/' while enforcing no
     text wrapping within a single disease name. */
  const diseaseDisplay: any[] = [];
  if (ruleInterpretation) {
    const diseaseNameElements = ruleInterpretation.rule.diseases.map((d) => (
      <span style={{whiteSpace: 'nowrap'}}>{d.displayNameEn}</span>
    ));
    for (let i = 0; i < diseaseNameElements.length - 1; i++)
      diseaseDisplay.push(diseaseNameElements[i], ', ');
    if (diseaseNameElements.length > 1) diseaseDisplay.push('and ');
    diseaseDisplay.push(diseaseNameElements[diseaseNameElements.length - 1]);
  }

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      onOk={onClose}
      title={'Series History'}
      footer={null}
    >
      {ruleInterpretation && (
        <>
          <div>
            A timeline view of all valid doses the patient has received for {diseaseDisplay}. The
            last dose received is displayed at the top.
          </div>
          <br />
          <DoseMapTimeline doseMap={ruleInterpretation?.doseMap} />
        </>
      )}
    </Modal>
  );
};

/* Returns dose recommendations and display summary information for a set of
   dose recommendations on the specified date or today. */
export const doseRecDisplayForDate = (unmodifedDoseRecs, date?: Moment) => {
  const modifiedDoseRecommendations = unmodifedDoseRecs
    .map((dose) => modifyDoseRecsForDate(dose, date))
    .filter((d) => !!d);

  if (modifiedDoseRecommendations.length === 0) return {soonestDoseRecommendations: []};

  const soonestDoseRecommendations = getSoonestDoseRecommendations(modifiedDoseRecommendations);
  const soonestDoseRecommendationsDisplay = uniq(
    soonestDoseRecommendations.map((dr) => doseRecBranchToString(dr))
  ).join(' or ');
  let soonestDoseRecommendationDate = moment
    .min(soonestDoseRecommendations.map((rec) => moment(rec.forecastedMinDate)))
    .utc();

  return {
    soonestDoseRecommendations,
    soonestDoseRecommendationDate,
    soonestDoseRecommendationsDisplay,
  };
};

// Reformats the date when the min date is before the current date
const modifyDoseRecsForDate = (doseRecommendation, date?: Moment) => {
  const today = moment(date);
  const doseRecCpy = {...doseRecommendation};
  if (today.isAfter(doseRecCpy.forecastedMinDate, 'day'))
    doseRecCpy.forecastedMinDate = today.format('YYYY-MM-DD');
  if (moment(doseRecCpy.forecastedMinDate).isAfter(doseRecCpy.forecastedMaxDate, 'day'))
    return null;
  return doseRecCpy;
};

const sortHelper = (a, b, selectedA, selectedB) => {
  if (a?.preferability !== selectedA) {
    if (selectedA.localeCompare(selectedB) === -1) return -1;
    if (selectedA.localeCompare(selectedB) === 1) return 1;
  }
  if (a?.preferability === 'PREFERRED' && b?.preferability === 'ALLOWABLE') return -1;
  if (a?.preferability === 'ALLOWABLE' && b?.preferability === 'PREFERRED') return 1;
  if (a?.product?.publicPicklistTermEn.localeCompare(b?.product?.publicPicklistTermEn === 0))
    return a?.forecastedMinDate.localeCompare(b?.forecastedMinDate);
  return a?.product?.publicPicklistTermEn.localeCompare(b?.product?.publicPicklistTermEn);
};

export const doseRecCanBookToday = (doseRec) =>
  !!doseRec.bookableOnDate || doseRec.notBookableOnDateReason === 'EARLY';

/* Returns an array of the soonest dose recommendations. */
export const getSoonestDoseRecommendations = (doseRecs) => {
  let soonestDoseRecs = [doseRecs[0]];
  let minDate = moment(soonestDoseRecs[0].forecastedMinDate);
  let canBookToday = doseRecCanBookToday(soonestDoseRecs[0]);
  for (let i = 1; i < doseRecs.length; i++) {
    const newRecDate = moment(doseRecs[i].forecastedMinDate);
    if (minDate.isSame(newRecDate) && doseRecCanBookToday(doseRecs[i]) === canBookToday) {
      soonestDoseRecs.push(doseRecs[i]);
    } else if (
      /* If there is a sooner date and canBookToday is not worse OR canBookToday
         is better regardless of date, collect that group of recs. */
      (minDate.isAfter(newRecDate) && (doseRecCanBookToday(doseRecs[i]) || !canBookToday)) ||
      (doseRecCanBookToday(doseRecs[i]) && !canBookToday)
    ) {
      soonestDoseRecs = [doseRecs[i]];
      minDate = newRecDate;
      canBookToday = doseRecCanBookToday(doseRecs[i]);
    }
  }
  return soonestDoseRecs;
};

/* Returns a string to represent the branc and branch dose the dose
   is for recomendation. */
const doseRecBranchToString = (doseRec) => {
  const ordinal =
    doseRec.branchRepetitionNumber > 1
      ? moment.localeData().ordinal(doseRec.branchRepetitionNumber) + ' '
      : '';

  const branchDoseNumber =
    doseRec.branchDoseNumber > 1 ? ` (Dose ${doseRec.branchDoseNumber})` : '';

  return ordinal + doseRec.branch.name + branchDoseNumber;
};
