import React from 'react';
import {FormatTimezoneAbbr} from '@canimmunize/tools';
import moment from 'moment';
import {Col, Row, Collapse, Input} from 'antd';
import {DatePicker, Form, Input as AntdFormikInput} from 'formik-antd';

import {
  RequiredShortTextValidationRules,
  RequiredValidationRule,
  RequiredDefinedValidationRule,
  RequiredNumberValidationRules,
} from '../../../services/ui-validation-rules';
import {MinValidationRuleId} from '../../../validation/validation-rules/min-validation';
import {RequiredValidationRuleId} from '../../../validation/validation-rules/required-validation';
import {FormUXFieldType} from '../../form-ux';
import {FormUXModel} from '../../form-ux/form-ux-models/form-ux-model';
import {DateTimeTimezoneFormItem} from './date-time-timezone-form-item';
import {DebounceFormItem} from './debounce-form-item';
import {ConceptFormItem} from './concept-form-item';
import {ValidateFormItem} from '../../validate-form-item';

export const doseUOM = [
  {key: 'mL', label: 'mL'},
  {key: 'Mcg', label: 'Mcg'},
  {key: 'Box', label: 'Boxes'},
  {key: 'Capsule', label: 'Capsules'},
  {key: 'Dose', label: 'Doses'},
  {key: 'gtts', label: 'Drops'},
  {key: 'IU', label: 'IU'},
  {key: 'mcg/ml', label: 'Mcg/mL'},
  {key: 'Sachet', label: 'Sachets'},
  {key: 'Pallet', label: 'Pallets'},
  {key: 'Vial', label: 'Vials'},
  {key: 'Unknown', label: 'Unknown'},
];

export const generateImmFormFields = (
  ability,
  locationDataUpTop: boolean = false,
  imm?: any
): FormUXModel => {
  const locationFields: FormUXModel = [
    {
      groupName: 'Clinic Info',
      type: FormUXFieldType.flex,
      fields: [
        {
          name: 'clinicId',
          label: 'Clinic',
          editable: true,
          type: FormUXFieldType.custom,
          fetchType: 'clinic',
          generateLabel: (clinic) => clinic.name,
          generateValue: (clinic) => clinic.id,
          validationRules: RequiredValidationRule,
          showSearch: true,
        },
        {
          name: 'immunizerClinicModeUserId',
          label: 'Immunizer',
          allowClear: true,
          editable: true,
          type: FormUXFieldType.custom,
          fetchType: 'users',
          generateLabel: (user) =>
            `${user.firstName} ${user.lastName}${
              user.licenseNumber ? ` - ` + user.licenseNumber : ''
            }`,
          generateValue: (user) => user.id,
          validationRules: RequiredValidationRule,
          showSearch: true,
        },
      ],
    },
    {
      groupName: 'Service Delivery Location',
      type: FormUXFieldType.flex,
      fields: [
        {
          name: 'serviceDeliveryLocationId',
          label: 'Vaccination Location',
          editable: true,
          type: FormUXFieldType.custom,
          fetchType: 'service-delivery-location',
          generateLabel: (location) => location.name.en,
          generateValue: (location) => location.id,
          validationRules: RequiredValidationRule,
          showSearch: true,
        },
        {
          name: 'country',
          type: FormUXFieldType.custom,
          label: 'Country',
          editable: false,
          validationRules: [],
        },
      ],
    },
    {
      name: 'appointmentId',
      label: 'Appointment',
      editable: true,
      type: FormUXFieldType.custom,
      fetchType: 'appointment',
      generateLabel: (appt) =>
        `${moment.tz(appt.datetime, appt.timezone).format('LLL')} ${FormatTimezoneAbbr(
          moment.tz(appt.datetime, appt.timezone).format('zz'),
          'en',
          appt.timezone
        )} - ${appt.clinic?.name}`,
      generateValue: (appt) => appt.id,
      allowClear: true,
      validationRules: RequiredDefinedValidationRule,
    },
  ];

  const topLocationContent = locationDataUpTop ? locationFields : [];
  const bottomLocationContent = locationDataUpTop ? [] : locationFields;
  return [
    ...topLocationContent,
    {
      groupName: 'Date/Time',
      type: FormUXFieldType.flex,
      fields: [
        {
          name: 'date',
          type: FormUXFieldType.custom,
          label: 'Date of Vaccination',
          subType: 'date',
          editable: true,
          validationRules: RequiredValidationRule,
        },
        {
          name: 'date',
          type: FormUXFieldType.custom,
          label: 'Time of Vaccination',
          subType: 'time',
          editable: true,
          validationRules: RequiredValidationRule,
        },
        {
          name: 'date',
          type: FormUXFieldType.custom,
          label: 'Timezone',
          subType: 'timezone',
          editable: true,
          validationRules: RequiredValidationRule,
        },
      ],
    },
    {
      groupName: 'Vaccine',
      type: FormUXFieldType.flex,
      fields: [
        {
          name: 'conceptId',
          label: 'Vaccine',
          editable: true,
          type: FormUXFieldType.custom,
          conceptDataIndex: 'tradenames',
          picklistTerm: 'clinicianPicklistTerm',
          validationRules: RequiredShortTextValidationRules,
        },
        {
          name: 'lotNumber',
          label: 'Lot Number',
          editable: true,
          type: FormUXFieldType.custom,
          allowFreeForm: true,
          fetchType: 'lots',
          generateLabel: (lot) => lot.lotNumber,
          generateValue: (lot) => lot.lotNumber,
          validationRules: RequiredValidationRule,
          showSearch: true,
        },
        {
          name: 'expiry',
          type: FormUXFieldType.custom,
          label: 'Lot Expiry Date',
          editable: false,
          validationRules: [],
        },
      ],
    },
    {
      groupName: 'Vaccine',
      type: FormUXFieldType.flex,
      fields: [
        {
          name: 'site',
          label: 'Site',
          editable: true,
          type: FormUXFieldType.custom,
          picklistTerm: 'displayName',
          conceptDataIndex: 'anatomicalSite',
          validationRules: RequiredShortTextValidationRules,
        },
        {
          name: 'route',
          label: 'Route of Administration',
          editable: true,
          type: FormUXFieldType.custom,
          picklistTerm: 'displayName',
          conceptDataIndex: 'routeOfAdmin',
          validationRules: RequiredShortTextValidationRules,
        },
        {
          name: 'doseNumber',
          type: FormUXFieldType.number,
          label: 'Recorded Dose Number',
          editable: true,
          validationRules: [
            {
              validationRuleType: RequiredValidationRuleId,
            },
            {
              validationRuleType: MinValidationRuleId,
              min: 0,
              inclusive: false,
            },
          ],
        },
      ],
    },
    {
      groupName: 'Dose',
      type: FormUXFieldType.flex,
      fields: [
        {
          name: 'dosage',
          type: FormUXFieldType.text,
          notFastField: true,
          label: 'Dosage',
          editable: true,
          validationRules: RequiredNumberValidationRules,
        },
        {
          name: 'dosageUom',
          type: FormUXFieldType.select,
          label: 'Dosage UOM',
          editable: true,
          validationRules: RequiredShortTextValidationRules,
          selectableValues: doseUOM,
        },
        {
          name: 'intendedBranchId',
          label: 'Intended Series',
          editable: true,
          fetchType: 'intendedBranch',
          generateLabel: (branch) => branch.name,
          generateValue: (branch) => branch.id,
          type: FormUXFieldType.custom,
          validationRules: [],
          allowClear: true,
          extra: imm?.fivecastDoseMap?.[0]?.branch
            ? `This dose has been forecasted on branch ` +
              JSON.stringify(imm.fivecastDoseMap?.[0]?.branch.name)
            : undefined,
        },
      ],
    },
    ...bottomLocationContent,
  ];
};

const ImmAdditionalFormFields = [
  {name: 'id', label: 'Dose ID', type: 'text', itemProps: {disabled: true}},
  {
    name: 'clinicEntryId',
    label: 'Clinic Entry ID',
    type: 'text',
    itemProps: {disabled: true},
  },
  {name: 'conceptId', label: 'Concept ID', type: 'text', itemProps: {disabled: true}},
];

export const generateImmDoseValidityOptionsFormFields = (ability, showState, onChange) => {
  return [
    {
      name: 'explicitDoseValidity',
      editable: ability.can('update', 'patients', 'doseValidity'),
      type: FormUXFieldType.radio,
      validationRules: [],
      allowDeselect: true,
      state: showState,
      options: [
        {
          value: 'invalid',
          label: 'Mark dose as Invalid',
          description:
            'Marking a dose invalid will cause the forecaster to treat the dose as invalid and modify intervals based off the invalid reason code.',
        },
        {
          value: 'valid',
          label: 'Mark dose as Valid',
          description:
            'Marking a dose as valid will allow the dose to skip product and interval checks for the specified series dose. If the dose is forecasted on any other series dose, its validity will be calculated normally.',
        },
        {value: '', label: 'No override'},
      ],
      onChange,
    },
  ] as any;
};

export const generateImmInvalidFormFields = (ability, visible: boolean) => {
  return [
    {
      name: 'invalidReasonCode',
      label: 'Reason For Invalid Dose',
      editable: ability.can('update', 'patients', 'doseValidity'),
      fetchType: 'invalidReason',
      generateLabel: (i) => {
        return i.invalidReasonText;
      },
      generateValue: (i) => i.invalidReasonCode,
      type: FormUXFieldType.custom,
      validationRules: visible ? RequiredValidationRule : [],
      allowClear: true,
    } as any,
  ];
};

export const generateImmValidFormFields = (ability, visible: boolean) => {
  return [
    {
      groupName: 'validDose',
      type: FormUXFieldType.flex,
      fields: [
        {
          name: 'validReasonCode',
          label: 'Reason For Valid Dose',
          editable: ability.can('update', 'patients', 'doseValidity'),
          fetchType: 'validReason',
          generateLabel: (reason) => reason.display,
          generateValue: (reason) => reason.id,
          type: FormUXFieldType.custom,
          validationRules: visible ? RequiredValidationRule : [],
          allowClear: true,
        },
        {
          name: 'validOnBranchDoseId',
          label: 'Intended Series Dose',
          editable: ability.can('update', 'patients', 'doseValidity'),
          fetchType: 'validOnBranchDose',
          generateLabel: (branchDose) => generateBranchDoseLabel(branchDose),
          generateValue: (branchDose) => branchDose.id,
          type: FormUXFieldType.custom,
          validationRules: visible ? RequiredValidationRule : [],
          allowClear: true,
        },
      ],
    },
  ] as any[];
};

/* Helper function to remove `Dose 1` from Additional Dose and Booster */
const generateBranchDoseLabel = (branchDose) => {
  if (
    branchDose.branch.name == 'Additional Dose' ||
    branchDose.branch.name == 'Booster'
  )
    return `${branchDose.branch.name}`;
  else return `${branchDose.branch.name} - Dose ${branchDose.branchDoseNumber}`;
};

export const generateImmAdditionalFields = () => {
  return [
    {
      name: 'noteContent',
      label: 'Reason for New Vaccination',
      editable: true,
      type: FormUXFieldType.custom,
      validationRules: RequiredValidationRule,
    },
    {
      name: 'additionalInfo',
      type: FormUXFieldType.custom,
      label: 'Additional Information',
      editable: true,
      validationRules: [],
    },
  ] as any;
};

export const immFormFieldsCustomFieldRenderer = (
  isReadOnly,
  formikProps,
  timezone,
  setTimezone,
  hideTime,
  initialFormikValues,
  immunizer,
  props,
  expiry,
  imm,
  handleSetCountry,
  handleSetExpiryDate,
  setClinicId,
  patient,
  country,
  clinicId
) => {
  /** Immutable input field component used for displaying values
   * @param field Field data from form model. Includes name and label for field
   * @param value Value to display in input field
   * @param required Whether or not to display the required asterix
   */
  const DisplayInputField = (props) => {
    const {field, value, required} = props;
    return (
      <Form.Item
        name={field.name}
        label={<label style={{height: '0px'}}>{field.label}</label>}
        style={{marginBottom: '10px'}}
        tooltip={{
          icon: (
            <span
              style={{
                color: 'red',
                fontSize: 14,
                fontWeight: 'bolder',
                visibility: required ? 'visible' : 'hidden',
              }}
            >
              *
            </span>
          ),
        }}
      >
        <Input size="middle" disabled value={value} />
      </Form.Item>
    );
  };

  return (field) => {
    field.editable = field.editable !== false && !isReadOnly;

    switch (field.name) {
      case 'date': {
        return (
          <div hidden={hideTime && (field.subType === 'time' || field.subType === 'timezone')}>
            <DateTimeTimezoneFormItem
              {...field}
              form={formikProps}
              timezone={timezone}
              setTimezone={setTimezone}
            />
          </div>
        );
      }
      case 'country': {
        return <DisplayInputField field={field} value={country} />;
      }
      case 'expiry': {
        const required =
          field.validationRules.find(
            (rule) => rule.validationRuleType === RequiredValidationRuleId
          ) !== undefined;

        return (
          <ValidateFormItem
            validationRules={field.validationRules || []}
            renderFormItem={(validate) => {
              return (
                <Form.Item
                  name={field.name}
                  label={<label style={{height: '0px'}}>{field.label}</label>}
                  validate={validate}
                  tooltip={{
                    icon: (
                      <span
                        style={{
                          color: 'red',
                          fontSize: 14,
                          fontWeight: 'bolder',
                          visibility: required ? 'visible' : 'hidden',
                        }}
                      >
                        *
                      </span>
                    ),
                  }}
                  required={required}
                >
                  <DatePicker
                    disabled
                    allowClear={false}
                    size={'middle'}
                    format="LL"
                    style={{width: '100%'}}
                    name={field.name}
                    value={expiry && moment.tz(expiry, timezone)}
                  />
                </Form.Item>
              );
            }}
          />
        );
      }
      case 'conceptId':
      case 'route':
      case 'site': {
        return <ConceptFormItem {...field} form={formikProps} />;
      }
      case 'immunizerClinicModeUserId':
      case 'clinicId':
      case 'appointmentId':
      case 'serviceDeliveryLocationId':
      case 'lotNumber':
      case 'intendedBranchId':
      case 'validOnBranchDoseId':
      case 'validReasonCode':
      case 'invalidReasonCode': {
        //Disable Clinic selector when updating an immunization
        const disabledConditions =
          (field.name === 'immunizerClinicModeUserId' || field.name === 'appointmentId') &&
          !formikProps.values?.clinicId
            ? true
            : props.actionType === 'update-imm'
            ? field.name === 'clinicId'
            : false;

        return (
          <DebounceFormItem
            {...field}
            form={formikProps}
            initialValues={{...imm, ...initialFormikValues}}
            immunizer={immunizer}
            patientId={patient.id}
            clinicId={clinicId}
            setClinicId={setClinicId}
            handleSetExpiryDate={handleSetExpiryDate}
            handleSetCountry={handleSetCountry}
            editable={field.editable && !disabledConditions}
          />
        );
      }
      case 'noteContent': {
        return props.actionType === 'create-imm' ? (
          <ValidateFormItem
            validationRules={field.validationRules || []}
            renderFormItem={(validate) => {
              return (
                <Form.Item
                  name={field.name}
                  validate={validate}
                  label={<label style={{height: '0px'}}>{field.label}</label>}
                  tooltip={{
                    icon: <span style={{color: 'red', fontSize: 14, fontWeight: 'bolder'}}>*</span>,
                  }}
                  required={true}
                >
                  <AntdFormikInput.TextArea name="noteContent" />
                </Form.Item>
              );
            }}
          />
        ) : (
          <></>
        );
      }
      case 'additionalInfo': {
        return props.actionType === 'update-imm' ? (
          <Collapse style={{marginTop: '10px'}}>
            <Collapse.Panel header="Additional Information" key="1">
              <Row gutter={24}>
                {ImmAdditionalFormFields.map((field, key) => {
                  return field.name in imm ? (
                    <Col key={`${formikProps.values.id}-${field.name}-${key}`} span={8}>
                      <DisplayInputField field={field} value={formikProps.values[field.name]} />
                    </Col>
                  ) : null;
                })}
              </Row>
            </Collapse.Panel>
          </Collapse>
        ) : (
          <></>
        );
      }
      default: {
        throw new Error(`Unhandled custom field ${field.name} in renderCustomField method`);
      }
    }
  };
};
