"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_decimal = exports.RTTI_decimalType = void 0;
var io_ts_1 = require("io-ts");
var RTTI_decimalType = (function (_super) {
    __extends(RTTI_decimalType, _super);
    function RTTI_decimalType() {
        var _this = _super.call(this, 'RTTI_decimalType', function (m) {
            return typeof m === 'number' && RTTI_decimalType.regexExp.test(m.toString());
        }, function (m, c) { return (_this.is(m) ? io_ts_1.success(m) : io_ts_1.failure(m, c)); }, io_ts_1.identity) || this;
        return _this;
    }
    RTTI_decimalType.regexExp = /^-?(0|[1-9][0-9]*)(\.[0-9]+)?([eE][+-]?[0-9]+)?$/;
    return RTTI_decimalType;
}(io_ts_1.Type));
exports.RTTI_decimalType = RTTI_decimalType;
exports.RTTI_decimal = new RTTI_decimalType();
