import {AvailabilitySlotDay} from '../../../../interface-models/availability-rules/availability-rule-day';
import {getEnumValues} from '../../../../util/enum-util';
import {EnumValidationRuleId} from '../../../../validation/validation-rules/enum-validation';
import {RequiredValidationRuleId} from '../../../../validation/validation-rules/required-validation';
import {FormUXFieldType} from '../../../form-ux';
import {IBaseFormUxField} from '../../../form-ux/form-ux-models/form-ux-fields/abstract-form-ux-fields/base-form-ux-field';
import {IFormUXSelectField} from '../../../form-ux/form-ux-models/form-ux-fields/form-ux-select-field';
import moment from 'moment';
import {AvailabilityRuleType} from '../../../../interface-models/availability-rules/availability-rule-types/availability-rule-type';
import {MinValidationRuleId} from '../../../../validation/validation-rules/min-validation';

export const ImmunizerCountField: IBaseFormUxField<any> = {
  name: 'immunizerCount',
  type: 'any',
  label: 'Immunizer Count',
  extra: 'Determines how many concurrent appointment slots are made available by this rule.',
  editable: true,
  validationRules: [
    {
      validationRuleType: RequiredValidationRuleId,
    },
    {
      validationRuleType: MinValidationRuleId,
      min: 1,
      inclusive: true,
    },
  ],
};

export const PrimaryAppointmentCountField: IBaseFormUxField<any> = {
  name: 'immunizerCount',
  type: 'any',
  label: 'Primary Appointment Count',
  extra:
    'Determines how many concurrent, primary appointment slots are made available by this rule.',
  editable: true,
  validationRules: [
    {
      validationRuleType: RequiredValidationRuleId,
    },
    {
      validationRuleType: MinValidationRuleId,
      min: 0,
      inclusive: true,
    },
  ],
};

export const GroupCountField: IBaseFormUxField<any> = {
  name: 'groupCount',
  type: 'any',
  label: 'Secondary Appointment Count',
  extra:
    'Determines how many secondary appointment slots are made available per group, in addition to the primary appointment.',
  editable: true,
  validationRules: [
    {
      validationRuleType: MinValidationRuleId,
      min: 0,
      inclusive: true,
    },
  ],
};

export const DurationField: IBaseFormUxField<any> = {
  name: 'duration',
  type: 'any',
  label: 'Appointment Duration',
  extra: 'Determines how long the appointment is scheduled for in minutes.',
  editable: true,
  validationRules: [
    {
      validationRuleType: RequiredValidationRuleId,
    },
    {
      validationRuleType: MinValidationRuleId,
      min: 1,
      inclusive: true,
    },
  ],
};

export const IsAvailableField: IBaseFormUxField<any> = {
  name: 'isAvailable',
  type: 'any',
  label: 'Show on Booking Page',
  extra:
    'Determines whether this availability rule is visible on booking pages that this calendar has been added to.',
  editable: true,
  validationRules: [
    {
      validationRuleType: RequiredValidationRuleId,
    },
  ],
};

export const StartTimeField: IBaseFormUxField<any> = {
  name: 'startTime',
  type: 'any',
  label: 'Start Time',
  editable: true,
  validationRules: [
    {
      validationRuleType: RequiredValidationRuleId,
    },
  ],
};

export const EndTimeField: IBaseFormUxField<any> = {
  name: 'endTime',
  type: 'any',
  label: 'End Time',
  editable: true,
  validationRules: [
    {
      validationRuleType: RequiredValidationRuleId,
    },
  ],
};

export const StartDateField: IBaseFormUxField<any> = {
  name: 'startDate',
  type: 'any',
  label: 'Start Date',
  editable: true,
  validationRules: [
    {
      validationRuleType: RequiredValidationRuleId,
    },
  ],
};

export const EndDateField: IBaseFormUxField<any> = {
  name: 'endDate',
  type: 'any',
  label: 'End Date',
  editable: true,
  validationRules: [],
};

export const DaysField: IFormUXSelectField = {
  name: 'days',
  type: FormUXFieldType.select,
  label: 'Days',
  editable: true,
  validationRules: [
    {
      validationRuleType: RequiredValidationRuleId,
    },
    {
      validationRuleType: EnumValidationRuleId,
      enumValues: getEnumValues(AvailabilitySlotDay),
    },
  ],
  selectableValues: getEnumValues(AvailabilitySlotDay).map((day) => {
    return {
      key: day,
      label: getLabelForDay(day as AvailabilitySlotDay),
    };
  }),
};
export function getLabelForDay(day: AvailabilitySlotDay): string {
  switch (day) {
    case AvailabilitySlotDay.Saturday: {
      return 'Saturday';
    }
    case AvailabilitySlotDay.Sunday: {
      return 'Sunday';
    }
    case AvailabilitySlotDay.Monday: {
      return 'Monday';
    }
    case AvailabilitySlotDay.Tuesday: {
      return 'Tuesday';
    }
    case AvailabilitySlotDay.Wednesday: {
      return 'Wednesday';
    }
    case AvailabilitySlotDay.Thursday: {
      return 'Thursday';
    }
    case AvailabilitySlotDay.Friday: {
      return 'Friday';
    }
  }
}

export const DateField: IBaseFormUxField<any> = {
  name: 'date',
  type: 'any',
  label: 'Date',
  editable: true,
  validationRules: [
    {
      validationRuleType: RequiredValidationRuleId,
    },
  ],
};

export function getLabelForRuleType(ruleType: AvailabilityRuleType): string {
  switch (ruleType) {
    case AvailabilityRuleType.NonRecurring: {
      return 'Non Recurring';
    }
    case AvailabilityRuleType.RecurringDay: {
      return 'Recurring';
    }
  }
}

export function validateEndTime(values: {startTime?: moment.Moment; endTime?: moment.Moment}): {
  endTime?: string;
} {
  const errors: ReturnType<typeof validateEndTime> = {};

  if (values.endTime && values.startTime) {
    if (moment(values.endTime).isSameOrBefore(moment(values.startTime))) {
      errors.endTime = 'End time should be after start time';
    }
  }

  return errors;
}

export function validateEndDate(values: {startDate?: moment.Moment; endDate?: moment.Moment}): {
  endDate?: string;
} {
  const errors: ReturnType<typeof validateEndDate> = {};

  if (values.startDate && values.endDate) {
    if (moment(values.endDate).isSameOrBefore(moment(values.startDate))) {
      errors.endDate = 'End date should be after start date';
    }
  }

  return errors;
}
