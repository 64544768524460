import {
  faCalendarAlt,
  faCopy,
  faPencilAlt,
  faRocket,
  faWalking,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, message, Space} from 'antd';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {FhirUtils} from '../../services/fhir';

export const PortalListActions = (props) => {
  const {portal} = props;
  const client = FhirUtils.useAxiosClient();
  const navigate = useNavigate();

  const copyPortal = async (portal) => {
    const copiedPortal = await client
      .get(`/portal/${portal.id}/copy`)
      .then(({data}) => data)
      .catch((err) => {
        message.error(`Error copying portal.`);
      });

    if (copiedPortal) {
      message.success('Successfully copied portal!');
      navigate(`/portals/${copiedPortal.id}`);
    }
  };

  return (
    <Space>
      <Button
        type="primary"
        size={'small'}
        onClick={(e) => {
          e.stopPropagation();
          window.open(`/en/p/${portal.portalSlug.en}`);
        }}
        target="_blank"
        style={{paddingLeft: 10, paddingRight: 10}}
      >
        <FontAwesomeIcon icon={faRocket} color="white" style={{marginRight: 10}} />
        Launch Portal
      </Button>
      <Button
        type="primary"
        size={'small'}
        style={{paddingLeft: 10, paddingRight: 10}}
        onClick={(e) => {
          e.stopPropagation();
          navigate(`/portals/${portal.id}`);
        }}
      >
        <FontAwesomeIcon icon={faPencilAlt} color="white" style={{marginRight: 10}} />
        Edit
      </Button>
      <Button
        type="primary"
        size={'small'}
        style={{paddingLeft: 10, paddingRight: 10}}
        onClick={(e) => {
          e.stopPropagation();
          copyPortal(portal);
        }}
      >
        <FontAwesomeIcon icon={faCopy} color="white" style={{marginRight: 10}} />
        Copy
      </Button>
    </Space>
  );
};
