import {R4} from '@ahryman40k/ts-fhir-types';
import {BundleTypeKind} from '@ahryman40k/ts-fhir-types/lib/R4';
import {createSlice} from '@reduxjs/toolkit';
import {normalize, schema} from 'normalizr';
import querystring from 'querystring';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState, ThunkDispatch} from '.';
import {FhirUtils} from '../services/fhir';
import {
  RequiredBirthDateValidationRules,
  RequiredGenderValidationRules,
  RequiredShortTextValidationRules,
  RequiredUUIDValidationRules,
  ShortTextValidationRules,
} from '../services/ui-validation-rules';
import {mapBundleToResourceArray} from '../util/fhir';
import {BirthDateValidationRuleId} from '../validation/validation-rules/birthdate-validation';
import {EmailValidationRuleId} from '../validation/validation-rules/email-validation';
import {GenderValidationRuleId} from '../validation/validation-rules/gender-enum-validation';
import {HcnValidationRuleId} from '../validation/validation-rules/hcn-validation';
import {RequiredValidationRuleId} from '../validation/validation-rules/required-validation';
import {ValidationRules} from '../validation/validation-rules/validation-rules';

export const cohortUploadTemplateName = 'cohort-no-roster-upload-template-v8';
export interface CohortType {
  id: string;
  name: string;
  organizationId: string;
}

export const CohortCSVOptionalFields = [];

export const CohortCSVValidation = (
  cohortRow
): {
  [property: string]: ValidationRules[];
} => {
  const basicValidation: {
    [property: string]: ValidationRules[];
  } = {
    email: [
      {
        validationRuleType: EmailValidationRuleId,
      },
    ],
  };

  if (cohortRow.patientId) {
    return {
      ...basicValidation,
      patientId: RequiredUUIDValidationRules,
      firstName: ShortTextValidationRules,
      lastName: ShortTextValidationRules,
      gender: [
        {
          validationRuleType: GenderValidationRuleId,
        },
      ],
      birthdate: [
        {
          validationRuleType: BirthDateValidationRuleId,
        },
      ],
      hcn: [...ShortTextValidationRules.concat({validationRuleType: HcnValidationRuleId})],
    };
  }
  return {
    ...basicValidation,
    firstName: RequiredShortTextValidationRules,
    lastName: RequiredShortTextValidationRules,
    gender: RequiredGenderValidationRules,
    birthdate: RequiredBirthDateValidationRules,
    hcn: [
      {
        validationRuleType: RequiredValidationRuleId,
      },
      ...RequiredShortTextValidationRules.concat({validationRuleType: HcnValidationRuleId}),
    ],
  };
};

export interface CohortsSliceInterface {
  byId: {[string: string]: CohortType};
}
const initialState = {
  byId: {},
};

const cohort = new schema.Entity('cohorts', {});

const slice = createSlice({
  name: 'cohorts',
  initialState,
  reducers: {
    SAVE_COHORTS: (state: CohortsSliceInterface, action) => {
      const resources = mapBundleToResourceArray(action.payload);
      state.byId = normalize(resources, [cohort]).entities.cohorts || {};
    },
    SAVE_COHORT: (state: CohortsSliceInterface, action) => {
      state.byId[action.payload.id] = action.payload;
    },
    DELETE_COHORT: (state: CohortsSliceInterface, action) => {
      delete state.byId[action.payload.id];
    },
  },
});

export const getAll = (client, params?) => async (dispatch) => {
  let query = params ? querystring.stringify(params) : undefined;
  const res = await client.get(`/Group${query ? `?${query}` : ''}`);
  dispatch(slice.actions.SAVE_COHORTS(res.data));
  return res.data;
};

const getOne = (client, id) => async (dispatch) => {
  return client.get(`/Group/${id}`).then((res) => {
    return dispatch(slice.actions.SAVE_COHORT(res.data));
  });
};

const updateOne = (client, org) => async (dispatch) => {
  return client.put(`/Group/${org.id}`, org).then(async (res) => {
    await dispatch(slice.actions.SAVE_COHORT(res.data));
    return res.data;
  });
};

export const createOne = (client, cohort) => async (dispatch) => {
  return client.post(`/Group`, cohort).then(async (res) => {
    await dispatch(slice.actions.SAVE_COHORT(res.data));
    return res.data;
  });
};

export const uploadPatients = (client, entries) => async (dispatch) => {
  const bundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: BundleTypeKind._transaction,
    entry: entries.map((p) => ({resource: p})),
  };

  return client.post(`/Patient`, bundle).then(async (res) => {
    return res.data;
  });
};

export const deleteOne = (client, itemId) => async (dispatch) => {
  return client.delete(`/Group/${itemId}`).then(async (res) => {
    await dispatch(slice.actions.DELETE_COHORT({id: itemId}));
    return res.data;
  });
};

export default {
  slice,
  getAll,
  getOne,
  updateOne,
  createOne,
  uploadPatients,
  deleteOne,
};

export const useCohorts = () => {
  const thunkDispatch = useDispatch<ThunkDispatch>();
  const client = FhirUtils.useClient();

  React.useEffect(() => {
    thunkDispatch(getAll(client));
  }, []);

  const cohorts = useSelector((state: RootState) => state.cohorts.byId);

  return Object.values(cohorts);
};
