"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_ElementDefinition_Constraint = exports.ElementDefinition_ConstraintSeverityKind = void 0;
var t = __importStar(require("io-ts"));
var RTTI_Extension_1 = require("./RTTI_Extension");
var RTTI_id_1 = require("../Scalar/RTTI_id");
var RTTI_Element_1 = require("./RTTI_Element");
var RTTI_canonical_1 = require("../Scalar/RTTI_canonical");
var ElementDefinition_ConstraintSeverityKind;
(function (ElementDefinition_ConstraintSeverityKind) {
    ElementDefinition_ConstraintSeverityKind["_error"] = "error";
    ElementDefinition_ConstraintSeverityKind["_warning"] = "warning";
})(ElementDefinition_ConstraintSeverityKind = exports.ElementDefinition_ConstraintSeverityKind || (exports.ElementDefinition_ConstraintSeverityKind = {}));
var EnumType_1 = require("../../EnumType");
exports.RTTI_ElementDefinition_Constraint = t.recursion('IElementDefinition_Constraint', function () {
    return t.partial({
        id: t.string,
        extension: t.array(RTTI_Extension_1.RTTI_Extension),
        modifierExtension: t.array(RTTI_Extension_1.RTTI_Extension),
        key: RTTI_id_1.RTTI_id,
        _key: RTTI_Element_1.RTTI_Element,
        requirements: t.string,
        _requirements: RTTI_Element_1.RTTI_Element,
        severity: EnumType_1.createEnumType(ElementDefinition_ConstraintSeverityKind, 'ElementDefinition_ConstraintSeverityKind'),
        _severity: RTTI_Element_1.RTTI_Element,
        human: t.string,
        _human: RTTI_Element_1.RTTI_Element,
        expression: t.string,
        _expression: RTTI_Element_1.RTTI_Element,
        xpath: t.string,
        _xpath: RTTI_Element_1.RTTI_Element,
        source: RTTI_canonical_1.RTTI_canonical
    });
});
