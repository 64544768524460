import React from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '../../models';
import {BaseEntityV2} from '../base-entity/base-entity';
import {FHIRTableProps} from '../fhir-table';
import BulkPvcButton from './bulk-pvc-button';
import {DownloadMailRequestPVC} from './download-mailreq-pvc';
import {SelectMailRequestStatus} from './select-mailreq-status';
import uuid from 'uuid';

export const MailQueueUX = () => {
  const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);
  // Column filter needs to be stored to keep while updating this whole component with the selection function
  const [statusColumnFilter, setStatusColumnFilter] = React.useState([]);
  const [refreshKey, setRefreshKey] = React.useState<string>(uuid.v4());
  const globalOrg = useSelector((state: RootState) => state.ui.localOrg);
  const fixedFilters = globalOrg
    ? {
        _organizationId: globalOrg.id,
      }
    : undefined;

  const tableConfig: FHIRTableProps = {
    fhirResource: 'mail-request',
    triggerRefresh: refreshKey,
    label: 'Mail Queue',
    fixedFilters,
    mode: 'table',
    defaultPrimarySearchParam: 'name',
    onClickRow: undefined,
    rowSelection: {
      selectedRowKeys,
      onChange: (selectedRowKeys: any, selectedRows) => {
        setSelectedRowKeys(selectedRowKeys);
      },
    },
    onDataLoad: () => {
      setSelectedRowKeys([]);
    },
    CreateButton: (props) => {
      return <BulkPvcButton selectedRowKeys={selectedRowKeys} refresh={setRefreshKey} />;
    },
    showCreateButton: true,
    columns: [
      {
        title: 'Name',
        dataIndex: `fullName`,
        sorter: true,
      },
      {
        title: 'Mailing address',
        dataIndex: 'mailingAddress',
        sorter: true,
      },
      {
        title: '# of Copies',
        dataIndex: 'numCopies',
        sorter: true,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        sorter: true,
        render: (_, item) => {
          return <SelectMailRequestStatus {...item} refresh={setRefreshKey} />;
        },
        filters: [
          {text: 'Pending', value: 'pending'},
          {text: 'Processed', value: 'processed'},
          {text: 'Cancelled', value: 'cancelled'},
          {text: 'Returned', value: 'returned'},
        ],
        defaultFilteredValues: statusColumnFilter.length > 0 ? statusColumnFilter : ['pending'],
        storeFilterValues: setStatusColumnFilter,
      },
      {
        title: 'Requested at',
        dataIndex: 'createdAt',
        sorter: true,
      },
      {
        title: 'Last printed at',
        dataIndex: 'lastDownloadedAt',
        sorter: true,
      },
      {
        title: 'Call Centre User',
        dataIndex: 'callCenterUserEmail',
        sorter: true,
      },
      {
        title: 'Printed By',
        dataIndex: 'printedByUserEmail',
        sorter: true,
      },
      {
        title: <div style={{whiteSpace: 'nowrap'}}>Contact method</div>,
        dataIndex: 'contactMethod',
        sorter: true,
      },
      {
        title: 'Actions',
        render: (_, item) => {
          return <DownloadMailRequestPVC requestId={item.id} refresh={setRefreshKey} />;
        },
      },
    ],
  };

  const config = {
    slug: 'mailqueue',
    model: undefined,
    itemTitleField: 'Mail queue',
    searchPageTitle: 'Mail queue',
    tableConfig,
  };

  return <BaseEntityV2 {...config} />;
};
