import {Spin} from 'antd';
import axios, {AxiosInstance} from 'axios';
import {changeAntdTheme, generateThemeColor} from 'mini-dynamic-antd-theme';
import queryString from 'query-string';
import React from 'react';
import {Helmet} from 'react-helmet';
import {useLocation, useParams} from 'react-router-dom';
import {matomoJs} from '../../../components/scheduler/matomo';
import {useUnAuthClient} from '../../../services/fhir';

export interface COVIDPortalConfig {
  status: 'active' | 'inactive' | 'protected';
  loading: boolean;
  primaryColor: string;
  logo: {en: string; fr: string};
  logoAlt: {en: string; fr: string};
  pageTitle: {en: string; fr: string};
  pageDescription: {en: string; fr: string};
  favicon: string;
  error?: boolean;
  bookAppointmentLink?: {en: string; fr: string};
  showRescheduleButtonsForUpcomingAppointments?: string;
  showCANILogoAtHeader?: string;
  portalAuthSchemeKey?: string;
  timezone: string;
  maxListItems: string;
  matomoTrackerUrl?: string;
  matomoSiteId?: string;
}

export const COVIDPortalConfigContext = React.createContext<COVIDPortalConfig>({
  loading: true,
} as COVIDPortalConfig);

interface IPortalConfigProviderProps {
  children?: React.ReactNode[] | React.ReactNode;
  keepTheme?: boolean;
  keepHeader?: boolean;
}

export const COVIDPortalConfigProvider = (props: IPortalConfigProviderProps) => {
  const {syncUrl, lang} = useCOVIDPortalConfig();

  const [portalConfig, setPortalConfig] = React.useState<COVIDPortalConfig>({
    loading: true,
  } as COVIDPortalConfig);

  React.useEffect(() => {
    fetchPortalConfig();
  }, []);

  const fetchPortalConfig = () => {
    const url = `${syncUrl}/public/settings/portal`;

    axios
      .get(url)
      .then((r) => {
        const simplifiedConfig = Object.keys(r.data).reduce((res, key) => {
          const value = r.data[key].value;
          return {...res, [key]: value};
        }, {}) as COVIDPortalConfig;
        setPortalConfig({...simplifiedConfig, loading: false});
      })
      .catch((e) => {
        setPortalConfig({loading: false, error: true} as COVIDPortalConfig);
      });
  };

  React.useEffect(() => {
    if (!props.keepTheme) {
      changeAntdTheme(
        generateThemeColor(portalConfig.primaryColor),
        'a { text-decoration: underline;}'
      );
    }
  }, [portalConfig.primaryColor, props.keepTheme]);

  if (portalConfig.loading) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <Spin />
      </div>
    );
  }

  if (portalConfig.error || portalConfig.status === 'inactive') {
    const logo = portalConfig?.logo
      ? portalConfig.logo[lang]
      : 'https://cdn.canimmunize.ca/logo.png';

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <div>
          <img src={logo} style={{width: 200}} alt={'logo'} />
        </div>
        {/* <div>{Str(Ids.invalid_link)}</div> */}
        <div>This page is not currently available. Please try again later!</div>
      </div>
    );
  }

  return (
    <COVIDPortalConfigContext.Provider value={portalConfig}>
      {!props.keepTheme && !props.keepHeader && (
        <Helmet>
          <title>{portalConfig.pageTitle[lang]}</title>
          <meta name="description" content={portalConfig.pageDescription[lang]} />
          <link rel="icon" type="image/png" href={portalConfig.favicon}></link>
          {portalConfig.matomoTrackerUrl && (
            <script type="application/javascript">{matomoJs(portalConfig)}</script>
          )}
        </Helmet>
      )}

      <div className="booking-form-provider">{props.children}</div>
    </COVIDPortalConfigContext.Provider>
  );
};

export const useCOVIDPortalConfig = (): {
  lang: string;
  config: COVIDPortalConfig;
  syncUrl: string;
  theme: any;
  client: AxiosInstance;
  query: any;
} => {
  const config = React.useContext(COVIDPortalConfigContext);
  const client = useUnAuthClient();

  const location = useLocation();
  const query = queryString.parse(location.search);

  const {lang = 'en'} =
    useParams<{
      lang: 'en' | 'fr';
    }>();

  const syncUrl = client.defaults.baseURL as string;

  const theme = {
    blue: config?.primaryColor || '#265ed6',
    darkBlue: '#1F6284',
    danger: '#dc3545',
    boxShadowStyle: {
      boxShadow: '0 10px 25px rgba(50, 50, 93, 0.1)',
    },
    sectionSpacing: 60,
    borderRadius: 10,
  };

  return {
    lang,
    config,
    client,
    syncUrl,
    theme,
    query,
  };
};
