import {Form, Input} from 'formik-antd';
import React from 'react';
import {RequiredUserManagementPassword} from '../../../services/ui-validation-rules';
import {ValidateFormItem} from '../../validate-form-item';
/**
 * Title, Form, Validation definition to update User Password
 * @returns
 */
const UserInfoPasswordForm = () => {
  return (
    <>
      <ValidateFormItem
        validationRules={RequiredUserManagementPassword}
        renderFormItem={(validate) => {
          return (
            <Form.Item name="password" label="New Password" validate={validate}>
              <Input.Password name="password" />
            </Form.Item>
          );
        }}
      />
      <Form.Item name="confirm" label="Confirm Password" style={{marginBottom: 5}}>
        <Input.Password name="confirm" />
      </Form.Item>
    </>
  );
};

const validate = (values: {password?: string; confirm?: string}): {confirm?: string} => {
  const errors: {[id: string]: string} = {};

  if (values.password !== values.confirm) {
    errors.confirm = 'Passwords do not match';
  }
  return errors;
};

const title = 'Change Password for account';

export const userInfoPasswordForm = {title, form: UserInfoPasswordForm, validate};
