import {Button, Card, Col, Input, Row, Form, Radio, DatePicker} from 'antd';
import * as Icons from '@ant-design/icons';
import React, {useState} from 'react';
import moment from 'moment';

export const HcpLookupSearchBar = (props) => {
  const {query, setQuery, onClear} = props;
  const [searchType, setSearchType] = useState(query?.lastName ? 'nonHcnSearch' : 'hcnSearch');

  const onChangeSearchType = (e) => {
    setSearchType(e.target.value);
    onClear();
  };

  return (
    <Row gutter={[16, 16]}>
      <Col style={{flexGrow: 1}}>
        <div style={{display: 'flex'}}>
          <Card style={{flexGrow: 1}}>
            <Row align="middle" style={{marginBottom: 15}}>
              <Radio.Group onChange={onChangeSearchType} value={searchType}>
                <Radio value="hcnSearch">DOB and HCN</Radio>
                <Radio value="nonHcnSearch">
                  Last Name without HCN
                  <span style={{color: 'grey', marginLeft: 5}}>
                    (This search will only include patients without HCNs)
                  </span>
                </Radio>
              </Radio.Group>
            </Row>
            <Row align="middle">
              {searchType == 'hcnSearch' && <HcnSearchForm query={query} setQuery={setQuery} />}
              {searchType == 'nonHcnSearch' && (
                <NonHcnSearchForm query={query} setQuery={setQuery} />
              )}
            </Row>
          </Card>
        </div>
      </Col>
    </Row>
  );
};

const HcnSearchForm = ({query, setQuery}) => {
  const [dob, setDob] = useState<any>(undefined);

  return (
    <Form
      onFinish={(values) => {
        setQuery({
          dob: dob?.format('YYYY-MM-DD'),
          hcn: values.hcn,
        });
      }}
      layout="vertical"
    >
      <Row gutter={[16, 16]}>
        <Col>
          <Form.Item
            label="Date of Birth"
            name="dob"
            rules={[{required: true, message: 'DOB is required to search'}]}
            initialValue={query?.dob ? moment(query?.dob) : undefined}
          >
            <DatePicker
              size="middle"
              style={{width: 250}}
              placeholder="Select date (YYYY-MM-DD)"
              onChange={(date) => {
                if (date) setDob(date);
              }}
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item
            label="Health Card Number"
            name="hcn"
            rules={[{required: true, message: 'HCN is required to search'}]}
            initialValue={query?.hcn}
          >
            <Input allowClear size="middle" style={{width: 250}} />
          </Form.Item>
        </Col>
        <Col
          style={{
            display: 'inline-flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <Button type="primary" icon={<Icons.SearchOutlined />} size="middle" htmlType="submit">
            Search
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const NonHcnSearchForm = ({query, setQuery}) => {
  return (
    <Form
      onFinish={(values) => {
        setQuery({
          lastName: values.lastName,
        });
      }}
      layout="vertical"
    >
      <Row gutter={[16, 16]}>
        <Col>
          <Form.Item
            label="Last Name"
            name="lastName"
            rules={[{required: true, message: 'Last Name is required to search'}]}
            initialValue={query?.lastName}
          >
            <Input allowClear size="middle" style={{width: 250}} />
          </Form.Item>
        </Col>
        <Col
          span={6}
          style={{
            display: 'inline-flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <Button type="primary" icon={<Icons.SearchOutlined />} size="middle" htmlType="submit">
            Search
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
