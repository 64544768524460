import {
  RequiredShortTextValidationRules,
  RequiredSpecialCharsValidationRules,
} from '../../services/ui-validation-rules';
import {FormUXFieldType} from '../form-ux';
import {FormUXModel} from '../form-ux/form-ux-models/form-ux-model';

export enum RoleFormCustomFieldName {
  OrganizationId = 'organizationId',
}

export const RoleFormUXModel: FormUXModel = [
  {
    name: 'name',
    type: FormUXFieldType.text,
    label: 'Name',
    inCreateModal: true,
    editable: true,
    validationRules: [...RequiredShortTextValidationRules, ...RequiredSpecialCharsValidationRules],
  },
];
