import {Button, message, Modal, Space} from 'antd';
import uuid from 'uuid';
import React from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '../../../models';
import {FhirUtils} from '../../../services/fhir';
import {FHIRTable} from '../../fhir-table';
import {colors} from '@canimmunize/tools';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckCircle} from '@fortawesome/free-solid-svg-icons';
import {ClinicImmunizerActions} from './clinic-immunizer-actions';
import {store} from '../../../store';
import {getStoredOrg} from '../../../models/organizations';

export const ClinicImmunizersUX = ({clinicId}) => {
  const globalOrg = useSelector((state: RootState) => state.ui.localOrg);
  const [selectingImmunizer, setSelectingImmunizer] = React.useState(false);
  const [tableState, setTableState] = React.useState(uuid.v4());
  const [addedImmunizers, setAddedImmunizers] = React.useState<string[]>([]);
  const [loading, setLoading] = React.useState<string | undefined>(undefined);
  const client = FhirUtils.useAxiosClient();

  const onSelectImmunizer = async (clinicModeUser) => {
    setLoading(clinicModeUser.id);
    await client
      .put(`/clinic/${clinicId}/link/immunizer`, {
        clinicModeUserId: clinicModeUser.id,
        clinicId,
        action: 'link',
      })
      .then((res) => {
        setTableState(uuid.v4());
        setAddedImmunizers([...addedImmunizers, clinicModeUser.id]);
        message.success('Immunizer successfully added to clinic!');
      })
      .catch((err) => {
        message.error(`Error adding immunizer to clinic`);
      });
    setLoading(undefined);
  };

  const columns = [
    {
      dataIndex: 'firstName',
      title: 'First Name',
    },
    {
      dataIndex: 'lastName',
      title: 'Last Name',
    },
    {
      dataIndex: 'licenseBody',
      title: 'License Body',
    },
    {
      dataIndex: 'licenseNumber',
      title: 'License Number',
    },
    {
      dataIndex: 'organization.organizationCode',
      title: 'Managing Organization',
      //Checks store for organizationId and uses the retrieved data to render organizationCode
      render: (_, org) => {
        const storedOrg = getStoredOrg(org.organizationId);
        return (
          (org.organization && org.organization.organizationCode) ||
          (storedOrg ? storedOrg.organizationCode : org.organizationId)
        );
      },
    },
    {
      dataIndex: 'email',
      title: 'Email',
    },
    {
      title: 'Actions',
      render: (_, clinicModeUser) => (
        <ClinicImmunizerActions
          immunizer={clinicModeUser}
          clinicId={clinicId}
          refreshTable={() => setTableState(uuid.v4())}
        />
      ),
    },
  ];

  const pickerColumns = [
    {
      dataIndex: 'firstName',
      title: 'First Name',
    },
    {
      dataIndex: 'lastName',
      title: 'Last Name',
    },
    {
      dataIndex: 'licenseBody',
      title: 'License Body',
    },
    {
      dataIndex: 'licenseNumber',
      title: 'License Number',
    },
    {
      dataIndex: 'organization.organizationCode',
      title: 'Managing Organization',
      //Checks store for organizationId and uses the retrieved data to render organizationCode
      render: (_, org) => {
        const storedOrg = getStoredOrg(org.organizationId);
        return (
          (org.organization && org.organization.organizationCode) ||
          (storedOrg ? storedOrg.organizationCode : org.organizationId)
        );
      },
    },
    {
      dataIndex: 'email',
      title: 'Email',
    },
    {
      title: 'Select',
      render: (_, clinicModeUser) => (
        <Space>
          {clinicModeUser.clinicIds.indexOf(clinicId) > -1 ? (
            <Button
              type="primary"
              className="success"
              size={'small'}
              style={{
                paddingLeft: 10,
                paddingRight: 10,
                backgroundColor: colors.green,
                borderColor: colors.green,
              }}
            >
              <FontAwesomeIcon icon={faCheckCircle} color="white" style={{marginRight: 10}} />
              Added
            </Button>
          ) : (
            <Button
              type="primary"
              size="small"
              onClick={() => onSelectImmunizer(clinicModeUser)}
              loading={loading === clinicModeUser.id}
            >
              Add to Clinic
            </Button>
          )}
        </Space>
      ),
    },
  ];

  return (
    <>
      <FHIRTable
        fhirResource="clinic-mode-user"
        mode="table"
        defaultPrimarySearchParam="name"
        triggerRefresh={tableState}
        fixedFilters={{clinicId, includeAll: 'true'}}
        columns={columns}
        label={'Clinic Mode User'}
        showCreateButton
        createButtonTitle="Link Clinic Mode User"
        onClickCreateButton={() => setSelectingImmunizer(true)}
      />
      <Modal
        width={'80%'}
        visible={selectingImmunizer}
        title="Link Clinic Mode Users"
        onCancel={() => setSelectingImmunizer(false)}
        onOk={() => setSelectingImmunizer(false)}
        cancelButtonProps={{style: {display: 'none'}}}
        okText="Done"
        destroyOnClose
      >
        <FHIRTable
          fhirResource="clinic-mode-user"
          mode="table"
          defaultPrimarySearchParam="name"
          fixedFilters={{includeAll: 'true'}}
          columns={pickerColumns}
          triggerRefresh={tableState}
        />
      </Modal>
    </>
  );
};
