import React from 'react';
import {FormConfig} from '../base-entity/base-entity';
import ClinicFlowFormModel from '../../models/form';
import {ClinicFlowFormFormUXModel} from './clinic-flow-form-form-ux';
import {FHIRTableProps} from '../fhir-table';
import {FormUX} from '../form-ux';
import {store} from '../../store';
import {BaseEntityV2} from '../base-entity/base-entity';

export function ClinicFlowFormUX() {
  const ClinicForm = (props) => {
    return (
      <FormUX
        formUXModel={ClinicFlowFormFormUXModel}
        createMode={props.createMode}
        modal={props.modal}
      />
    );
  };

  const formConfig: FormConfig = {
    preSubmit: (values, actions, onSubmit, entity) => {
      /* Add the user's organizationId to the form object so the server
      can validate whether it has access to edit it. */
      const submitObj = Object.assign({}, values);
      submitObj.organizationId = store.getState().organizationId.organizationId;

      return onSubmit({...submitObj}, actions);
    },
    FormItems: ClinicForm,
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
  ];

  const tableConfig: FHIRTableProps = {
    fhirResource: '/forms',
    label: 'Form',
    // basicSearchParams: ['name', 'given', 'family', 'email', 'phone'],
    defaultPrimarySearchParam: 'name',
    mode: 'table',
    showCreateButton: true,
    columns,
  };

  const config = {
    slug: 'form',
    model: ClinicFlowFormModel,
    itemTitleField: 'name',
    formConfig,
    searchPageTitle: 'Forms',
    hideEditButton: true,
    tableConfig,
    hideDeleteButton: true,
  };

  return <BaseEntityV2 {...config} />;
}
