"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_Questionnaire = exports.QuestionnaireStatusKind = void 0;
var t = __importStar(require("io-ts"));
var RTTI_id_1 = require("../Scalar/RTTI_id");
var RTTI_Meta_1 = require("./RTTI_Meta");
var RTTI_uri_1 = require("../Scalar/RTTI_uri");
var RTTI_Element_1 = require("./RTTI_Element");
var RTTI_code_1 = require("../Scalar/RTTI_code");
var RTTI_Narrative_1 = require("./RTTI_Narrative");
var RTTI_ResourceList_1 = require("../Union/RTTI_ResourceList");
var RTTI_Extension_1 = require("./RTTI_Extension");
var RTTI_Identifier_1 = require("./RTTI_Identifier");
var RTTI_canonical_1 = require("../Scalar/RTTI_canonical");
var RTTI_dateTime_1 = require("../Scalar/RTTI_dateTime");
var RTTI_ContactDetail_1 = require("./RTTI_ContactDetail");
var RTTI_markdown_1 = require("../Scalar/RTTI_markdown");
var RTTI_UsageContext_1 = require("./RTTI_UsageContext");
var RTTI_CodeableConcept_1 = require("./RTTI_CodeableConcept");
var RTTI_date_1 = require("../Scalar/RTTI_date");
var RTTI_Period_1 = require("./RTTI_Period");
var RTTI_Coding_1 = require("./RTTI_Coding");
var RTTI_Questionnaire_Item_1 = require("./RTTI_Questionnaire_Item");
var QuestionnaireStatusKind;
(function (QuestionnaireStatusKind) {
    QuestionnaireStatusKind["_draft"] = "draft";
    QuestionnaireStatusKind["_active"] = "active";
    QuestionnaireStatusKind["_retired"] = "retired";
    QuestionnaireStatusKind["_unknown"] = "unknown";
})(QuestionnaireStatusKind = exports.QuestionnaireStatusKind || (exports.QuestionnaireStatusKind = {}));
var EnumType_1 = require("../../EnumType");
exports.RTTI_Questionnaire = t.recursion('IQuestionnaire', function () {
    return t.intersection([
        t.type({
            resourceType: t.literal('Questionnaire')
        }),
        t.partial({
            id: RTTI_id_1.RTTI_id,
            meta: RTTI_Meta_1.RTTI_Meta,
            implicitRules: RTTI_uri_1.RTTI_uri,
            _implicitRules: RTTI_Element_1.RTTI_Element,
            language: RTTI_code_1.RTTI_code,
            _language: RTTI_Element_1.RTTI_Element,
            text: RTTI_Narrative_1.RTTI_Narrative,
            contained: t.array(RTTI_ResourceList_1.RTTI_ResourceList),
            extension: t.array(RTTI_Extension_1.RTTI_Extension),
            modifierExtension: t.array(RTTI_Extension_1.RTTI_Extension),
            url: RTTI_uri_1.RTTI_uri,
            _url: RTTI_Element_1.RTTI_Element,
            identifier: t.array(RTTI_Identifier_1.RTTI_Identifier),
            version: t.string,
            _version: RTTI_Element_1.RTTI_Element,
            name: t.string,
            _name: RTTI_Element_1.RTTI_Element,
            title: t.string,
            _title: RTTI_Element_1.RTTI_Element,
            derivedFrom: t.array(RTTI_canonical_1.RTTI_canonical),
            status: EnumType_1.createEnumType(QuestionnaireStatusKind, 'QuestionnaireStatusKind'),
            _status: RTTI_Element_1.RTTI_Element,
            experimental: t.boolean,
            _experimental: RTTI_Element_1.RTTI_Element,
            subjectType: t.array(RTTI_code_1.RTTI_code),
            _subjectType: t.array(RTTI_Element_1.RTTI_Element),
            date: RTTI_dateTime_1.RTTI_dateTime,
            _date: RTTI_Element_1.RTTI_Element,
            publisher: t.string,
            _publisher: RTTI_Element_1.RTTI_Element,
            contact: t.array(RTTI_ContactDetail_1.RTTI_ContactDetail),
            description: RTTI_markdown_1.RTTI_markdown,
            _description: RTTI_Element_1.RTTI_Element,
            useContext: t.array(RTTI_UsageContext_1.RTTI_UsageContext),
            jurisdiction: t.array(RTTI_CodeableConcept_1.RTTI_CodeableConcept),
            purpose: RTTI_markdown_1.RTTI_markdown,
            _purpose: RTTI_Element_1.RTTI_Element,
            copyright: RTTI_markdown_1.RTTI_markdown,
            _copyright: RTTI_Element_1.RTTI_Element,
            approvalDate: RTTI_date_1.RTTI_date,
            _approvalDate: RTTI_Element_1.RTTI_Element,
            lastReviewDate: RTTI_date_1.RTTI_date,
            _lastReviewDate: RTTI_Element_1.RTTI_Element,
            effectivePeriod: RTTI_Period_1.RTTI_Period,
            code: t.array(RTTI_Coding_1.RTTI_Coding),
            item: t.array(RTTI_Questionnaire_Item_1.RTTI_Questionnaire_Item)
        })
    ]);
});
