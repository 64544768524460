import {
  RequiredShortTextValidationRules,
  RequiredSpecialCharsValidationRules,
  ShortTextValidationRules,
} from '../../services/ui-validation-rules';
import {RequiredValidationRuleId} from '../../validation/validation-rules/required-validation';
import {FormUXFieldType} from '../form-ux';
import {FormUXModel} from '../form-ux/form-ux-models/form-ux-model';
import CampaignModel from '../../models/campaign';
import {RegexValidationId} from '../../validation/validation-rules/regex-validation';
import ClinicFlowFormModel from '../../models/form';
import {EnumValidationRuleId} from '../../validation/validation-rules/enum-validation';
import {MinValidationRuleId} from '../../validation/validation-rules/min-validation';
import moment from 'moment-timezone';
import {generateClinicTypeValues} from '../../models/clinics';

/**
 * The names of the fields for the clinic form whose rendering have to
 * be handled by the ClinicFormUX component
 *
 * @export
 * @enum {number}
 */
export enum ClinicFormCustomFieldName {
  ParentOrganizationId = 'parentOrganizationId',
  OrganizationId = 'organizationId',
  Location = 'location',
  VaccineConceptId = 'vaccineConceptId',
  GISLocation = 'gisLocation',
}

export const generateClinicFormUXModel = (settings): FormUXModel => {
  return [
    {
      tabName: 'General',
      tabKey: 'general',
      type: FormUXFieldType.tab,
      inCreateModal: true,
      inEditMode: true,
      fields: [
        {
          name: 'name',
          type: FormUXFieldType.text,
          label: 'Name',
          editable: true,
          validationRules: [
            ...RequiredShortTextValidationRules,
            ...RequiredSpecialCharsValidationRules,
          ],
          inCreateModal: true,
        },
        {
          name: ClinicFormCustomFieldName.OrganizationId,
          type: FormUXFieldType.custom,
          label: 'Organization',
          editable: true,
          validationRules: [
            {
              validationRuleType: 'required',
            },
          ],
          inCreateModal: true,
        },
        {
          name: 'status',
          type: FormUXFieldType.select,
          label: 'Status',
          editable: true,
          inCreateModal: true,
          selectableValues: ['pending', 'active', 'completed'],
          validationRules: [
            {
              validationRuleType: RequiredValidationRuleId,
            },
            {
              validationRuleType: EnumValidationRuleId,
              enumValues: ['pending', 'active', 'completed'],
            },
          ],
        },
        {
          name: 'selfCheckInClinic',
          type: FormUXFieldType.select,
          label: 'Self Check-In',
          editable: true,
          inCreateModal: true,
          selectableValues: [
            {key: 'true', label: 'Enabled'},
            {key: 'false', label: 'Disabled'},
          ],
          validationRules: [
            {
              validationRuleType: RequiredValidationRuleId,
            },
            {
              validationRuleType: EnumValidationRuleId,
              enumValues: ['true', 'false'],
            },
          ],
        },
        {
          name: 'category',
          type: FormUXFieldType.select,
          label: 'Category',
          editable: true,
          validationRules: [...ShortTextValidationRules],
          selectableValues: generateClinicTypeValues(settings),
          inCreateModal: true,
        },
        {
          name: 'campaignId',
          type: FormUXFieldType.SingleItemPicker,
          label: 'Campaign',
          editable: true,
          validationRules: [
            {
              validationRuleType: RequiredValidationRuleId,
            },
          ],
          inCreateModal: true,
          model: CampaignModel,
          placeholder: 'Select campaign',
          fromIdValue: true,
        },
        {
          name: ClinicFormCustomFieldName.VaccineConceptId,
          type: FormUXFieldType.custom,
          label: 'Vaccine Product',
          editable: true,
          validationRules: [],
          inCreateModal: true,
        },
        {
          name: 'timezone',
          type: FormUXFieldType.select,
          label: 'Timezone',
          editable: true,
          validationRules: [
            {
              validationRuleType: RequiredValidationRuleId,
            },
            {
              validationRuleType: EnumValidationRuleId,
              enumValues: moment.tz.names(),
            },
          ],
          selectableValues: moment.tz.names(),
          searchable: true,
          inCreateModal: true,
        },
        {
          name: ClinicFormCustomFieldName.Location,
          type: FormUXFieldType.custom,
          label: 'Location',
          editable: true,
          validationRules: [],
        },
      ],
    },
    {
      tabName: 'Appointment Configuration',
      tabKey: 'appointment-config',
      type: FormUXFieldType.tab,
      inCreateModal: true,
      inEditMode: true,
      fields: [
        {
          type: FormUXFieldType.grouped,
          groupName: 'Cohort Dose Decision',
          fields: [
            {
              name: 'decisionToVaccinateFormId',
              type: FormUXFieldType.SingleItemPicker,
              allowClear: true,
              label: 'Dose Decision Form',
              colSpan: 20,
              editable: true,
              fromIdValue: true,
              inCreateModal: true,
              model: ClinicFlowFormModel,
              placeholder: 'Select Form',
              validationRules: [],
            },
            {
              name: 'decisionToVaccinateFormMandatory',
              type: FormUXFieldType.boolean,
              label: 'Required',
              editable: true,
              colSpan: 4,
              validationRules: [],
              inCreateModal: true,
            },
          ],
        },
        {
          type: FormUXFieldType.grouped,
          groupName: 'Cohort Covid Screening',
          fields: [
            {
              name: 'covidScreeningFormId',
              type: FormUXFieldType.SingleItemPicker,
              allowClear: true,
              label: 'Covid Screening Form',
              colSpan: 20,
              editable: true,
              fromIdValue: true,
              inCreateModal: true,
              model: ClinicFlowFormModel,
              placeholder: 'Select Form',
              validationRules: [],
            },
            {
              name: 'covidScreeningFormMandatory',
              type: FormUXFieldType.boolean,
              label: 'Required',
              editable: true,
              colSpan: 4,
              validationRules: [],
              inCreateModal: true,
            },
          ],
        },
        {
          type: FormUXFieldType.grouped,
          groupName: 'Cohort Health Status',
          fields: [
            {
              name: 'healthStatusFormId',
              type: FormUXFieldType.SingleItemPicker,
              allowClear: true,
              label: 'Health Status Form',
              colSpan: 20,
              editable: true,
              fromIdValue: true,
              inCreateModal: true,
              model: ClinicFlowFormModel,
              placeholder: 'Select Form',
              validationRules: [],
            },
            {
              name: 'healthStatusFormMandatory',
              type: FormUXFieldType.boolean,
              label: 'Required',
              editable: true,
              colSpan: 4,
              validationRules: [],
              inCreateModal: true,
            },
          ],
        },
        {
          type: FormUXFieldType.grouped,
          groupName: 'Cohort Consent',
          fields: [
            {
              name: 'consentFormId',
              type: FormUXFieldType.SingleItemPicker,
              allowClear: true,
              label: 'Consent Form ID',
              colSpan: 20,
              editable: true,
              fromIdValue: true,
              inCreateModal: true,
              model: ClinicFlowFormModel,
              placeholder: 'Select Form',
              validationRules: [],
            },
            {
              name: 'consentFormMandatory',
              type: FormUXFieldType.boolean,
              label: 'Required',
              editable: true,
              colSpan: 4,
              validationRules: [],
              inCreateModal: true,
            },
          ],
        },
        {
          type: FormUXFieldType.grouped,
          groupName: 'Walk In',
          fields: [
            {
              name: 'walkInFormId',
              type: FormUXFieldType.SingleItemPicker,
              allowClear: true,
              label: 'Walk In Form ID',
              colSpan: 20,
              editable: true,
              fromIdValue: true,
              inCreateModal: true,
              model: ClinicFlowFormModel,
              placeholder: 'Select Form',
              validationRules: [],
            },
            {
              name: 'walkInFormMandatory',
              type: FormUXFieldType.boolean,
              label: 'Required',
              editable: true,
              colSpan: 4,
              validationRules: [],
              inCreateModal: true,
            },
          ],
        },
        {
          name: 'clinicModeDemographicsFormId',
          type: FormUXFieldType.SingleItemPicker,
          allowClear: true,
          label: 'Clinic Mode Demographics Form',
          editable: true,
          fromIdValue: true,
          inCreateModal: true,
          model: ClinicFlowFormModel,
          placeholder: 'Select Form',
          validationRules: [],
        },
        {
          name: 'clinicalNotesFormId',
          type: FormUXFieldType.SingleItemPicker,
          allowClear: true,
          label: 'Clinical Notes Form',
          editable: true,
          fromIdValue: true,
          inCreateModal: true,
          model: ClinicFlowFormModel,
          placeholder: 'Select Form',
          validationRules: [],
        },
        {
          name: 'aefiNotesFormId',
          type: FormUXFieldType.SingleItemPicker,
          allowClear: true,
          label: 'Adverse Event Following Immunization Form',
          editable: true,
          fromIdValue: true,
          inCreateModal: true,
          model: ClinicFlowFormModel,
          placeholder: 'Select Form',
          validationRules: [],
        },
      ],
    },
    {
      tabName: 'Cohort Configuration',
      tabKey: 'cohort-config',
      type: FormUXFieldType.tab,
      inCreateModal: true,
      inEditMode: true,
      fields: [
        {
          type: FormUXFieldType.grouped,
          groupName: 'Cohort Dose Decision',
          fields: [
            {
              name: 'cohortDecisionToVaccinateFormId',
              type: FormUXFieldType.SingleItemPicker,
              allowClear: true,
              label: 'Dose Decision Form',
              colSpan: 20,
              editable: true,
              fromIdValue: true,
              inCreateModal: true,
              model: ClinicFlowFormModel,
              placeholder: 'Select Form',
              validationRules: [],
            },
            {
              name: 'cohortDecisionToVaccinateFormMandatory',
              type: FormUXFieldType.boolean,
              label: 'Required',
              editable: true,
              colSpan: 4,
              validationRules: [],
              inCreateModal: true,
            },
          ],
        },
        {
          type: FormUXFieldType.grouped,
          groupName: 'Cohort Covid Screening',
          fields: [
            {
              name: 'cohortCovidScreeningFormId',
              type: FormUXFieldType.SingleItemPicker,
              allowClear: true,
              label: 'Covid Screening Form',
              colSpan: 20,
              editable: true,
              fromIdValue: true,
              inCreateModal: true,
              model: ClinicFlowFormModel,
              placeholder: 'Select Form',
              validationRules: [],
            },
            {
              name: 'cohortCovidScreeningFormMandatory',
              type: FormUXFieldType.boolean,
              label: 'Required',
              editable: true,
              colSpan: 4,
              validationRules: [],
              inCreateModal: true,
            },
          ],
        },
        {
          type: FormUXFieldType.grouped,
          groupName: 'Cohort Health Status',
          fields: [
            {
              name: 'cohortHealthStatusFormId',
              type: FormUXFieldType.SingleItemPicker,
              allowClear: true,
              label: 'Health Status Form',
              colSpan: 20,
              editable: true,
              fromIdValue: true,
              inCreateModal: true,
              model: ClinicFlowFormModel,
              placeholder: 'Select Form',
              validationRules: [],
            },
            {
              name: 'cohortHealthStatusFormMandatory',
              type: FormUXFieldType.boolean,
              label: 'Required',
              editable: true,
              colSpan: 4,
              validationRules: [],
              inCreateModal: true,
            },
          ],
        },
        {
          type: FormUXFieldType.grouped,
          groupName: 'Cohort Consent',
          fields: [
            {
              name: 'cohortConsentFormId',
              type: FormUXFieldType.SingleItemPicker,
              allowClear: true,
              label: 'Consent Form ID',
              colSpan: 20,
              editable: true,
              fromIdValue: true,
              inCreateModal: true,
              model: ClinicFlowFormModel,
              placeholder: 'Select Form',
              validationRules: [],
            },
            {
              name: 'cohortConsentFormMandatory',
              type: FormUXFieldType.boolean,
              label: 'Required',
              editable: true,
              colSpan: 4,
              validationRules: [],
              inCreateModal: true,
            },
          ],
        },
        {
          name: 'cohortClinicModeDemographicsFormId',
          type: FormUXFieldType.SingleItemPicker,
          allowClear: true,
          label: 'Clinic Mode Demographics Form',
          editable: true,
          fromIdValue: true,
          inCreateModal: true,
          model: ClinicFlowFormModel,
          placeholder: 'Select Form',
          validationRules: [],
        },
        {
          name: 'cohortClinicalNotesFormId',
          type: FormUXFieldType.SingleItemPicker,
          allowClear: true,
          label: 'Clinical Notes Form',
          editable: true,
          fromIdValue: true,
          inCreateModal: true,
          model: ClinicFlowFormModel,
          placeholder: 'Select Form',
          validationRules: [],
        },
        {
          name: 'cohortAefiNotesFormId',
          type: FormUXFieldType.SingleItemPicker,
          allowClear: true,
          label: 'Adverse Event Following Immunization Form',
          editable: true,
          fromIdValue: true,
          inCreateModal: true,
          model: ClinicFlowFormModel,
          placeholder: 'Select Form',
          validationRules: [],
        },
      ],
    },
  ];
};
export const generateLocationFormUXModel = (settings?): FormUXModel => {
  const enableAddressLookUp = settings['enableAddressLookUp']?.value === 'true';

  return [
    {
      name: 'name',
      type: FormUXFieldType.text,
      label: 'Name',
      editable: true,
      validationRules: [
        ...RequiredShortTextValidationRules,
        ...RequiredSpecialCharsValidationRules,
      ],
    },
    {
      name: 'streetNumber',
      type: FormUXFieldType.text,
      label: 'Street Number',
      editable: true,
      validationRules: ShortTextValidationRules,
    },
    {
      name: 'streetName',
      type: FormUXFieldType.text,
      label: 'Street Name',
      editable: true,
      validationRules: ShortTextValidationRules,
    },
    {
      name: 'streetDirection',
      type: FormUXFieldType.text,
      label: 'Street Direction',
      editable: true,
      validationRules: [],
    },
    {
      name: 'streetType',
      type: FormUXFieldType.text,
      label: 'Street Type',
      editable: true,
      validationRules: [],
    },
    {
      name: 'unitNumber',
      type: FormUXFieldType.text,
      label: 'Unit Number',
      editable: true,
      validationRules: [],
    },
    {
      name: 'city',
      type: FormUXFieldType.text,
      label: 'City',
      editable: true,
      validationRules: RequiredShortTextValidationRules,
    },
    {
      name: 'postalCode',
      type: FormUXFieldType.text,
      label: 'PostalCode',
      editable: true,
      validationRules: [
        ...ShortTextValidationRules,
        {
          validationRuleType: RegexValidationId,
          regex: /^$|(^[ABCEGHJKLMNPRSTVXY]\d([ABCEGHJKLMNPRSTVWXYZ]\d){2}$)/,
          errorMessage:
            'Value is not a valid postal code. Should use capital letters and have no spaces, for eg., H2X2E3',
        },
      ],
    },
    {
      name: 'serviceDeliveryLocation',
      type: FormUXFieldType.text,
      label: 'Service Delivery Location',
      editable: true,
      validationRules: RequiredShortTextValidationRules,
    },
    {
      name: 'mapsLocationString',
      type: FormUXFieldType.text,
      label: 'Google Maps Location String',
      editable: true,
      validationRules: RequiredShortTextValidationRules,
    },
    {
      name: 'displayEn',
      type: FormUXFieldType.text,
      label: 'Location Display (English)',
      editable: true,
      validationRules: RequiredShortTextValidationRules,
    },
    {
      name: 'displayFr',
      type: FormUXFieldType.text,
      label: 'Location Display (French)',
      editable: true,
      validationRules: RequiredShortTextValidationRules,
    },
    ...(enableAddressLookUp
      ? ([
          {
            name: 'gisLocation',
            type: FormUXFieldType.custom,
            label: 'GIS Location',
            editable: true,
            inCreateModal: true,
            validationRules: [],
          },
        ] as FormUXModel)
      : []),
  ];
};
