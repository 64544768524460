import {Col, message, Row, Table} from 'antd';
import qs from 'querystring';
import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {FhirUtils} from '../../services/fhir';
import {useUrlData} from '../scheduler/util';
import {HcpReceiptModal} from './hcp-imm-modal';
import {HcpLookupSearchBar} from './hcp-searchbar';
import {hcpSimpleColumns} from './hcp-table-columns';

export const HcpLookupTable = () => {
  const useItemPage = true;
  const {lastName, dob, hcn} = useUrlData().query;
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState({lastName: lastName, dob: dob, hcn: hcn});
  const [data, setData] = useState<any>({
    total: 0,
    entry: [],
  });
  const navigate = useNavigate();

  // State: pageSize
  const defaultPageSize = 50;
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [currentTablePage, setCurrentTablePage] = useState(1);

  //State: sorting by column
  const [sorting, setSorting] = useState({
    sortColumn: undefined,
    sortOrder: undefined,
  });

  const {sortColumn, sortOrder} = sorting;
  const client = FhirUtils.useAxiosClient();

  const [patientId, setPatientId] = useState();
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    if (!((searchTerm.dob && searchTerm.hcn) || searchTerm.lastName)) return;

    setLoading(true);
    const query = getQueryString();
    client
      .get(query)
      .then((res) => {
        if (searchTerm.hcn && res.data?.entry?.length === 1) {
          // HCN Search && 1 item found
          if (useItemPage) {
            navigate(`/patientlookup/${res.data.entry[0].id}`);
          } else {
            setPatientId(res.data.id);
            setModalVisible(true);
          }
        } else if (res.data?.entry?.length > 0) {
          // Non-HCN OR more than 1 item found
          setData(res.data);
          navigate(`/patientlookup?${qs.stringify(searchTerm)}`, {replace: true});
        } else {
          setData({total: 0, entry: []});
        }
      })
      .catch((err) => {
        // Display errors except 'Not Found'
        if (err.response?.status !== 404) {
          message.error(err.message);
        }
      })
      .finally(() => setLoading(false));
  }, [searchTerm, currentTablePage, sorting]);

  const onClose = () => {
    setModalVisible(false);
    setPatientId(undefined);
  };

  const resources: any[] = data ? data.entry?.map((entry) => entry.resource) : [];
  const total = data ? data.total : 0;

  const getQueryString = () => {
    const _getpagesoffset = Math.max(currentTablePage - 1, 0) * pageSize;
    const pagingParams = {_total: 'accurate', _count: pageSize, _getpagesoffset};
    const orderingParams = {_sortField: sortColumn, _sortDirection: sortOrder};
    const queryParams = qs.stringify({
      ...pagingParams,
      ...orderingParams,
      ...searchTerm,
    });
    return `hcplookup?${queryParams}`;
  };

  //Handles changes to sort columns and pagination
  const handleTableChange = (pagination, filters, sorter) => {
    const {pageSize, current} = pagination;
    const sortField = sorter.field;
    let sortDirection = sorter.order;
    if (sorter.order === 'ascend') {
      sortDirection = 'ASC';
    } else if (sorter.order === 'descend') {
      sortDirection = 'DESC';
    }
    setPageSize(pageSize);
    setCurrentTablePage(current);
    setSorting({
      sortColumn: sortField,
      sortOrder: sortDirection,
    });
  };

  const onClear = () => {
    setData({total: 0, entry: []});
    setSearchTerm({lastName: '', dob: '', hcn: ''});
    navigate(`/patientlookup`, {replace: true});
  };

  return (
    <Row gutter={[16, 0]}>
      <Col md={24}>
        <div style={{marginBottom: 20}}>
          <div style={{marginBottom: 20}}>
            <HcpLookupSearchBar query={searchTerm} setQuery={setSearchTerm} onClear={onClear} />
          </div>
          <Table
            dataSource={resources}
            columns={hcpSimpleColumns}
            onChange={handleTableChange}
            loading={loading}
            pagination={{
              defaultPageSize: defaultPageSize,
              current: currentTablePage,
              showTotal: (total, range) => (
                <span style={{color: 'white'}}>{`Total Items: ${total}`}</span>
              ),
              showSizeChanger: true,
              pageSizeOptions: ['50', '100', '200'],
              pageSize: pageSize,
              total: total,
            }}
            onRow={(item) => ({
              onClick: () => {
                if (useItemPage) {
                  navigate(`/patientlookup/${item.id}`);
                } else {
                  setPatientId(item.id);
                  setModalVisible(true);
                }
              },
            })}
          ></Table>
          {!useItemPage && patientId && (
            <HcpReceiptModal patientId={patientId} visible={modalVisible} onClose={onClose} />
          )}
        </div>
      </Col>
    </Row>
  );
};
