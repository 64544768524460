import {CVC} from '@canimmunize/cvc-js';
import {colors} from '@canimmunize/tools';
import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Menu, message, Modal, Popover, Space, Tag, Tooltip, Typography} from 'antd';
import uuid from 'uuid';
import {ImmModal} from './imm-modal';
import moment from 'moment-timezone';
import React, {useEffect, useRef} from 'react';
import {useEnvInfo} from '../../services/environment';
import {FhirUtils} from '../../services/fhir';
import {AbilityContext} from '../../services/roles/ability-context';
import {PublicVaccineReceipt} from '../../views/vaccine-receipt/public-vaccine-receipt';
import {getStoredOrg} from '../../models/organizations';
import {store} from '../../store';
import {PVCWalletMenuItem} from '../../views/vaccine-receipt/pvc-wallet-menu-item';
import {downloadPVCPdf} from '../../views/vaccine-receipt/util/download-pvc-pdf';
import {FHIRTable} from '../fhir-table';
import {ForecastTable} from './forecast-table';
import ReceiptModal from './receipt-modal';

const {Title} = Typography;

interface ImmsWidgetProps {
  /* The patient containing the immunizations and forms. */
  patient;
  /* Function to refresh appointments when necessary */
  refreshAppointments?;
  /* Global settings for patient-details page */
  settings: any;
  /* Object containing functions to refresh other tabs. */
  refreshTabFunctions?: {[tab: string]: Function};
}

export const ImmsWidget = (props: ImmsWidgetProps) => {
  const ability = React.useContext(AbilityContext);
  const {patient, refreshAppointments, settings = {}, refreshTabFunctions} = props;
  const [dataLoaded, setDataLoaded] = React.useState(false);
  const client = FhirUtils.useAxiosClient();
  const {environmentId} = useEnvInfo();
  const [currentImm, setCurrentImm] = React.useState<any>();
  const [modalVisible, setModalVisible] = React.useState(false);
  const [modalType, setModalType] = React.useState('');
  const [refreshKey, setRefreshKey] = React.useState(uuid.v4());
  if (refreshTabFunctions) refreshTabFunctions['ImmsWidget'] = () => setRefreshKey(uuid.v4());
  const storedOrg = getStoredOrg(patient?.organizationId);
  const showLaunchPortalButton =
    ability.can('view', 'patients', 'launchPortalButton') &&
    settings['showLaunchPortalButton']?.value === 'true';
  const showCreate = ability.can('create', 'patients', 'immunization');
  const showPrintRecordButton = settings['hidePrintRecordButton']?.value !== 'true';
  const showReceiptButton = settings['hideReceiptButton']?.value !== 'true';
  const showDownloadPvcButton = settings['hideDownloadPvcButton']?.value !== 'true';
  const showPrintPvcWalletButton = settings['showPrintPvcWalletButton']?.value === 'true';
  const showSendToEmailButton = settings['showSendToEmailButton']?.value === 'true';
  const locationDataUpTop = settings['locationDataUpTop']?.value === 'true';
  const componentRef: any = useRef();

  //Initialize CVC if it is not yet initialized. CVC is needed for imms modal concept form items.
  useEffect(() => {
    initializeCVC();
  }, []);

  async function initializeCVC() {
    let cvc = CVC.get();
    if (cvc) {
      return cvc;
    }

    return await CVC.initFromApi('https://cvc.canimmunize.ca/v3');
  }

  const onDataLoad = (data) => {
    const doses = data.entry.map((item) => item.resource);
    const hasCovidDose = doses.some((dose) =>
      dose.concept?.protectsAgainst.find((disease) => disease.conceptId === '840539006')
    );
    setDataLoaded(true);
  };

  const onRowClick = (imm) => {
    setCurrentImm(imm);
    setModalType('update-imm');
    setModalVisible(true);
  };

  const handleOpenReceipt = (event, imm) => {
    event.stopPropagation();
    setCurrentImm(imm);
    setModalType('receipt');
    setModalVisible(true);
  };

  const onClose = () => {
    setRefreshKey(uuid.v4());
    setModalVisible(false);
    setCurrentImm(undefined);
  };

  const sendReceiptEmail = () => {
    client
      .get(`/Patient/${patient.id}/resend-receipt`)
      .then(() => {
        message.success('Successfully sent immunization receipt.');
      })
      .catch((err) => {
        message.error('Error sending immunization receipt.');
      });
  };

  const launchPortal = () => {
    window.open(`/en/portal/${patient.portalId}`, '_blank');
  };

  const showSendReceiptEmailConfirm = () => {
    Modal.confirm({
      title: 'Are you sure you wish to send an immunization receipt email?',
      onOk: () => sendReceiptEmail(),
    });
  };

  const handleNewImmunization = () => {
    setCurrentImm({});
    setModalType('create-imm');
    setModalVisible(true);
  };

  return (
    <>
      {settings['showForecastStatusTable']?.value === 'true' && (
        <ForecastTable
          patient={patient}
          showPrintRecordButton={showPrintRecordButton}
          refreshKey={patient && refreshKey}
        />
      )}
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <Title level={4}>Vaccinations</Title>

        {dataLoaded && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row-reverse',
              justifyContent: 'space-between',
              paddingBottom: 15,
              alignItems: 'center',
            }}
          >
            <Space className="button-container" style={{flexWrap: 'wrap'}}>
              {showCreate && storedOrg && (
                <Button type="primary" onClick={handleNewImmunization}>
                  Add Vaccination
                </Button>
              )}
              <Menu mode="horizontal" style={{lineHeight: 0, marginTop: 0, minWidth: 165}}>
                <Menu.SubMenu
                  style={{margin: 0, padding: '1px 0 0 0', top: 0}}
                  key="pov"
                  title={<Button type="primary">Proof of Vaccination</Button>}
                >
                  {showLaunchPortalButton && (
                    <Menu.Item key="launch" onClick={launchPortal}>
                      Launch Portal
                    </Menu.Item>
                  )}
                  {showDownloadPvcButton && (
                    <Menu.Item
                      key="pov"
                      onClick={() =>
                        downloadPVCPdf(patient, client, undefined, {type: 'pvc'}, environmentId)
                      }
                    >
                      Download PVC
                    </Menu.Item>
                  )}
                  {showPrintPvcWalletButton && (
                    <Menu.Item key="wallet-pov">
                      <PVCWalletMenuItem patientId={patient?.id}></PVCWalletMenuItem>
                    </Menu.Item>
                  )}
                  {showSendToEmailButton && (
                    <Menu.Item
                      key="email"
                      onClick={showSendReceiptEmailConfirm}
                      disabled={!patient.email || !patient.hcn}
                    >
                      Send to Email
                    </Menu.Item>
                  )}
                </Menu.SubMenu>
              </Menu>
            </Space>

            {/* Immunization record component that printed when Print Record button is clicked.
                Only render when Print Record button is included in imms widget. */}
            {showPrintRecordButton && (
              <PublicVaccineReceipt
                showOnPrintOnly
                printingRef={componentRef}
                patientId={patient?.id}
              />
            )}
          </div>
        )}
      </div>

      <FHIRTable
        fhirResource="Immunization"
        mode="table"
        triggerRefresh={refreshKey}
        fixedFilters={{patientId: patient.id}}
        hideSearch
        onClickRow={onRowClick}
        columns={[
          ...columns,
          ...(showReceiptButton
            ? [
                {
                  title: '',
                  render: (_, imm) => (
                    <Space>
                      {showReceiptButton && (
                        <Button
                          type="primary"
                          style={{paddingLeft: 10, paddingRight: 10}}
                          onClick={(event) => handleOpenReceipt(event, imm)}
                        >
                          Receipt
                        </Button>
                      )}
                    </Space>
                  ),
                },
              ]
            : []),
        ]}
        size="small"
        style={{width: '100%'}}
        onRow={(imm: any) => ({onClick: (event) => onRowClick(imm)})}
        onDataLoad={onDataLoad}
      />

      {currentImm && modalType === 'receipt' && (
        <ReceiptModal patient={patient} visible={modalVisible} onClose={onClose} imm={currentImm} />
      )}
      {currentImm && (modalType === 'update-imm' || modalType === 'create-imm') && (
        <ImmModal
          patient={patient}
          visible={modalVisible}
          onClose={onClose}
          imm={currentImm}
          actionType={modalType}
          refreshAppointments={refreshAppointments}
          locationDataUpTop={locationDataUpTop}
          showNoteModalForDelete
        />
      )}
    </>
  );
};

const invalidDoseReasonDisplay = (reason: string) => {
  switch (reason) {
    case 'INTERVAL':
      return 'The forecaster marked this dose as invalid due to the interval at which it was administered.';
    case 'PRODUCT':
      return 'The forecaster marked this dose as invalid due to the product of the dose that was received.';
    case 'NO_RECOMMENDATIONS':
      return 'The forecaster marked this dose as invalid because the patient was recommended futher immunization.';
    case 'EXPLICIT':
      return 'The forecaster marked this dose as invalid because it was indicated to be invalid.';
    default:
      return reason?.length
        ? 'The forecasted marked this dose as invalid.'
        : 'The forecaster marked this dose as valid.';
  }
};

export const columns = [
  {
    title: 'Vaccination',
    dataIndex: 'displayName',
    key: 'displayName',
    render: (_, i) => (
      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <div>{i.displayName}</div>
        {i.invalidReason && (
          <Tooltip title={i?.invalidReason?.invalidReasonText} mouseEnterDelay={0.3}>
            <Tag color="red">Invalid</Tag>
          </Tooltip>
        )}
      </div>
    ),
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    render: (_, obj) => {
      const {immsTableDateFormat} = store.getState().config;
      return moment
        .tz(obj.date, obj.clinicEntry?.clinic?.timezone || moment.tz.guess())
        .format(immsTableDateFormat || 'LLL zz');
    },
  },
  {
    title: 'Forecasted Dose #',
    render: (dose) => {
      if (dose.fivecastDoseMap.length === 0 && !dose.invalid) return dose.doseNumber || '--';
      if (dose.fivecastDoseMap.length > 1) return 'Multiple';
      const doseMap = dose.fivecastDoseMap[0];

      /* Array of noticed inconsistencies. */
      let inconsistencyWarningContents: string[] = [];

      /* Check for a recorded/forecasted dose number mismatch. */
      if (doseMap.discrepancies?.includes('doseNumberDiscrepancy')) {
        inconsistencyWarningContents.push(
          `The dose number recorded by the immunizer (${dose.doseNumber}) is not the same as the dose number calculated by the forecaster (${doseMap.absoluteDoseNumber})`
        );
      }

      /* Check for an intended/forecasted branch mismatch. */
      if (doseMap.discrepancies?.includes('intendedBranchDiscrepancy')) {
        inconsistencyWarningContents.push(
          `The intended branch of the dose (${dose.intendedBranch.name}) is not the same as the branch calculated by the forecaster (${doseMap.branch.name})`
        );
      }

      /* Check for a discrepancy when the dose could not be forced valid as indicated. */
      if (doseMap.discrepancies?.includes('forceValidBranchDoseDiscrepancy')) {
        inconsistencyWarningContents.push(
          `The forecaster failed to force the dose to be valid on branch ${dose.validOnBranchDose.branch.name}`
        );
      }

      const inconsistencyWarning = inconsistencyWarningContents.length > 0 && (
        <Popover
          content={inconsistencyWarningContents.map((s) => (
            <div>{s}</div>
          ))}
          title={inconsistencyWarningContents.length > 1 ? 'Warnings' : 'Warning'}
        >
          <FontAwesomeIcon icon={faExclamationTriangle} color={colors.yellow} />
        </Popover>
      );

      if (doseMap.absoluteDoseNumber <= -1 || dose.invalid)
        return (
          <Space>
            <Tooltip title={invalidDoseReasonDisplay(doseMap.invalidReason)} mouseEnterDelay={0.3}>
              Invalid
            </Tooltip>
            {inconsistencyWarning}
          </Space>
        );

      return (
        <Space>
          {doseMap.absoluteDoseNumber}
          {inconsistencyWarning}
        </Space>
      );
    },
  },
];
