import {useAuth0} from '@auth0/auth0-react';
import {Modal} from 'antd';
import axios from 'axios';
import React, {useMemo} from 'react';
import {Loading} from '../components/util/loading';
import {FHIRUtilPatient} from '../models/fhir/patient';
import {organizationIdSlice} from '../models/organization-id';
import {store} from '../store';
import {useInactivityHelper} from '../util/helpers/inactivity-helper';
import {useEnvInfo} from './environment';

export const FhirContext = React.createContext<string | undefined>(undefined);
const jwtDecode = require('jwt-decode');

export const useUnAuthClient = () => {
  const axiosClient = axios.create({
    baseURL: useEnvInfo().syncUrl,
  });
  return useMemo(() => axiosClient, []);
};

const useAxiosClient = () => {
  const token = FhirUtils.useToken();

  const axiosClient = axios.create({
    baseURL: useEnvInfo().syncUrl,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return useMemo(() => axiosClient, [token]);
};

// Attempt to get display message from API request error
const getErrorDisplay = (err) => {
  let display = '';
  let errorData = err.response?.data;

  /* To display error message from server in 3 forms using plain json, SyncError, SyncClientError */
  if (errorData) {
    if (typeof errorData == 'string') {
      display = errorData;
    } else {
      if (errorData.error) errorData = errorData.error;

      display =
        errorData.display && errorData.display.en?.length
          ? errorData.display.en
          : errorData.message;
    }
  }
  return display || errorData?.message || err.message;
};

function getOrgIdFromDecodedToken(audience: string, decodedToken: string): string | string[] {
  return decodedToken[`${audience}/app_metadata`].organization;
}

function getRoleFromDecodedToken(audience: string, decodedToken: string): string {
  return decodedToken[`${audience}/app_metadata`].role;
}

export function getBetaFlagFromDecodedToken(audience: string, decodedToken: string): string {
  return decodedToken[`${audience}/app_metadata`].beta;
}

export const useTokenOrgId = () => {
  const token = FhirUtils.useToken();
  const {auth0Audience} = useEnvInfo();
  if (token) {
    const decodedToken = jwtDecode(token);
    const orgId = getOrgIdFromDecodedToken(auth0Audience, decodedToken);
    if (typeof orgId === 'string') return getOrgIdFromDecodedToken(auth0Audience, decodedToken);
  }
  return undefined;
};

export const useBetaFlag = () => {
  const token = FhirUtils.useToken();
  const {auth0Audience} = useEnvInfo();
  if (token) {
    const decodedToken = jwtDecode(token);
    return getBetaFlagFromDecodedToken(auth0Audience, decodedToken);
  }
  return false;
};

export const useRole = () => {
  const token = FhirUtils.useToken();
  const {auth0Audience} = useEnvInfo();
  if (token) {
    const decodedToken = jwtDecode(token);
    return getRoleFromDecodedToken(auth0Audience, decodedToken);
  }
  return '';
};

export const FhirProvider: React.FC = ({children}) => {
  const {auth0Audience} = useEnvInfo();
  const {getAccessTokenSilently, isAuthenticated, isLoading, error} = useAuth0();
  const {loginWithRedirect, inactivityLogout} = useInactivityHelper(
    useEnvInfo().inactivityLogoutConfig.maxIdleTime
  );

  const [token, setToken] = React.useState<string>();

  React.useEffect(() => {
    (async () => {
      getAccessTokenSilently({
        audience: auth0Audience,
        scope: 'openid profile email',
      })
        .then((token) => {
          const orgId = getOrgIdFromDecodedToken(auth0Audience, jwtDecode(token));
          if (typeof orgId === 'string') {
            /* Add the user's organization id to the store */
            store.dispatch(organizationIdSlice.actions.saveOrganizationId(orgId));
          }
          setToken(token);
        })
        .catch((err) => {
          if (!error) loginWithRedirect();
        });
    })();
  }, [getAccessTokenSilently, auth0Audience]);

  if (isLoading) {
    return <Loading />;
  }

  if (!isAuthenticated) {
    const err: any = error;
    const message = CUSTOM_ERROR_MESSAGE[err?.error] || err?.error + ' : ' + err?.message;

    if (err) {
      Modal.error({
        title: message,
        afterClose: () => loginWithRedirect(),
      });
    } else {
      loginWithRedirect();
    }
  } else {
    // NOTE: While the following code will get executed every time an authenticated user
    //       loads a new page (i.e. this componnent renders), we are actually trying to detect
    //       the scenario where the user had previously closed their browser without logging
    //       out, and re-opened the application again after a period of time exceeding the
    //       inactivityLogoutConfig.maxIdleTime value

    // log the user out if we detect they have been inactive for too long
    inactivityLogout({returnTo: window.location.origin});
  }

  return (
    <FhirContext.Provider value={token}>
      <FhirContext.Consumer>
        {(client) => {
          return token ? children : <Loading />;
        }}
      </FhirContext.Consumer>
    </FhirContext.Provider>
  );
};

// Define custom error message by error from auth0 login if necessary
// https://auth0.com/docs/libraries/common-auth0-library-authentication-errors
const CUSTOM_ERROR_MESSAGE = {
  unauthorized: 'Access Denied : This user is currently blocked. Please contact administrator.',
};

export const FhirUtils = {
  useClient: useAxiosClient,
  useToken: () => React.useContext(FhirContext),
  useTokenOrgId,
  useAxiosClient,
  patient: FHIRUtilPatient,
  getErrorDisplay,
};