import {Space} from 'antd';
import moment from 'moment';
import React from 'react';

export const ReportFilenameCell = ({report}) => {
  const filename = report?.fileName;

  return (
    <Space style={{wordBreak: 'break-word'}}>
      <>{`${filename}`}</>
    </Space>
  );
};
