import React from 'react';

export interface SessionConfig {
  sessionId?: string;
  setSessionId?: (string) => {};
}

export const SessionConfigContext = React.createContext<SessionConfig>({} as SessionConfig);

/**
 * Context Provider to keep sessionId for portal
 *
 * @param props
 * @returns
 */
export const SessionConfigProvider = (props) => {
  const [sessionId, setSessionId] = React.useState('');

  return (
    <SessionConfigContext.Provider value={{sessionId, setSessionId} as SessionConfig}>
      {props.children}
    </SessionConfigContext.Provider>
  );
};
