import {DislikeFilled, LikeFilled} from '@ant-design/icons';
import {Ids} from '@canimmunize/tools';
import {Alert, Button, Form, Input, Radio} from 'antd';
import axios from 'axios';
import parse from 'html-react-parser';
import React from 'react';
import {useUrlData} from '../../../components/scheduler/util';
import {useStr} from '../../../services/str';
import {PVC} from '../components/dose-list';
import {useCOVIDPortalConfig} from '../util/portal-config';

export const YukonSummaryPage = (props) => {
  const {patientRecord, deliveryMethod, isYukonCallCentre, reqViaThirdParty} = props;
  const Str = useStr();
  const [wasHelpful, setWasHelpful] = React.useState(null);
  const [feedback, setFeedback] = React.useState('');
  const [submitting, setSubmitting] = React.useState(false);
  const [error, setError]: any = React.useState(undefined);
  const [submittedFeeback, setSubmittedFeedback]: any = React.useState(false);
  const {syncUrl} = useCOVIDPortalConfig();
  const {lang} = useUrlData();

  const handleSubmit = async () => {
    setError(undefined);
    setSubmitting(true);
    const result = await axios
      .post(`${syncUrl}/public/portal/feedback`, {
        wasHelpful,
        feedback,
      })
      .catch((err) => err.response);

    setSubmitting(false);
    if (result.status === 200) {
      setWasHelpful(null);
      setFeedback('');
      setSubmittedFeedback(true);
    } else {
      setError(<>{parse(Str(Ids.yk_pvc_system_error_short))}</>);
    }
  };

  return (
    <>
      <div style={{marginBottom: 20}}>
        <b>{Str(isYukonCallCentre ? Ids.yk_cc_page5 : Ids.yk_pvc_page7)}</b>
      </div>
      <Alert type="success" showIcon message={Str(Ids.yk_pvc_success)} />
      {deliveryMethod === 'download' && (
        <>
          <div style={{margin: '10px 0px'}}>{Str(Ids.yk_pvc_download_summary)}</div>
          <div style={{margin: '10px 0px'}}>{Str(Ids.yk_pvc_safe_place)}</div>
          <div style={{margin: '20px 0px'}}>
            <PVC
              {...{
                patientRecord,
                lang,
                isYukonCallCentre,
                reqViaThirdParty,
              }}
            />
          </div>
        </>
      )}

      {deliveryMethod === 'mail' && (
        <div style={{margin: '20px 0px'}}>{Str(Ids.yk_mail_information)}</div>
      )}

      <div style={{marginBottom: 20}}>
        <b>{Str(Ids.yk_traveling_international)}</b>
        <br></br>
        {parse(Str(Ids.yk_traveling_international_info))}
      </div>
      <div>
        <b>{Str(Ids.yk_need_to_contact)}</b>
        <br></br>
        {parse(Str(Ids.yk_need_to_contact_info))}
      </div>
      <div
        style={{
          marginTop: 40,
          marginBottom: 15,
        }}
      >
        {parse(Str(isYukonCallCentre ? Ids.yk_pvc_cc_final_info : Ids.yk_pvc_final_info))}
      </div>
      <Form
        style={{
          marginTop: 40,
          marginBottom: 15,
          paddingTop: 40,
          borderTop: '1px solid #ccc',
        }}
      >
        {error && <Alert type="error" message={error} style={{marginBottom: 15}} showIcon />}
        {submittedFeeback && (
          <Alert
            type="success"
            showIcon
            style={{marginBottom: 15}}
            message={Str(Ids.yk_feedback_success)}
          />
        )}
        {!submittedFeeback && (
          <>
            <label style={{fontSize: '18px', marginRight: '10px'}}>
              {Str(Ids.yk_feedback_prompt)}
            </label>
            <Radio.Group value={wasHelpful} onChange={(e) => setWasHelpful(e.target.value)}>
              <Radio.Button value={true}>
                <LikeFilled />
              </Radio.Button>
              <Radio.Button value={false}>
                <DislikeFilled />
              </Radio.Button>
            </Radio.Group>

            {wasHelpful !== null && (
              <>
                <Form.Item style={{marginTop: '20px'}}>
                  <label style={{fontSize: '18px', marginRight: '10px'}}>
                    {wasHelpful
                      ? Str(Ids.yk_feedback_question_positive)
                      : Str(Ids.yk_feedback_question_negative)}
                  </label>
                  <Input.TextArea
                    onChange={(e) => setFeedback(e.target.value)}
                    rows={4}
                    maxLength={4000}
                    showCount
                    autoFocus
                  />
                </Form.Item>

                <Button
                  type="primary"
                  size="large"
                  loading={submitting}
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  {Str(Ids.yk_feedback_submit)}
                </Button>
              </>
            )}
          </>
        )}
      </Form>
    </>
  );
};
