import React, {useRef} from 'react';
import {useReactToPrint} from 'react-to-print';
import {Button, Modal} from 'antd';
import {PublicVaccineReceipt} from '../../views/vaccine-receipt/public-vaccine-receipt';

const ReceiptModal = (props) => {
  const {onClose, visible, imm, patient} = props;
  const componentRef: any = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <Modal
      closable={false}
      maskClosable={false}
      visible={visible}
      width={'50%'}
      onCancel={onClose}
      footer={[
        <Button onClick={handlePrint} type="primary" shape="round">
          Print
        </Button>,
        <Button shape="round" onClick={onClose}>
          Close
        </Button>,
      ]}
    >
      <PublicVaccineReceipt hidePrintButton patientId={patient.id} printingRef={componentRef} adminConsoleView />
    </Modal>
  );
};

export default ReceiptModal;
