"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_DeviceMetric_Calibration = exports.DeviceMetric_CalibrationStateKind = exports.DeviceMetric_CalibrationTypeKind = void 0;
var t = __importStar(require("io-ts"));
var RTTI_Extension_1 = require("./RTTI_Extension");
var RTTI_Element_1 = require("./RTTI_Element");
var RTTI_instant_1 = require("../Scalar/RTTI_instant");
var DeviceMetric_CalibrationTypeKind;
(function (DeviceMetric_CalibrationTypeKind) {
    DeviceMetric_CalibrationTypeKind["_unspecified"] = "unspecified";
    DeviceMetric_CalibrationTypeKind["_offset"] = "offset";
    DeviceMetric_CalibrationTypeKind["_gain"] = "gain";
    DeviceMetric_CalibrationTypeKind["_twoPoint"] = "two-point";
})(DeviceMetric_CalibrationTypeKind = exports.DeviceMetric_CalibrationTypeKind || (exports.DeviceMetric_CalibrationTypeKind = {}));
var DeviceMetric_CalibrationStateKind;
(function (DeviceMetric_CalibrationStateKind) {
    DeviceMetric_CalibrationStateKind["_notCalibrated"] = "not-calibrated";
    DeviceMetric_CalibrationStateKind["_calibrationRequired"] = "calibration-required";
    DeviceMetric_CalibrationStateKind["_calibrated"] = "calibrated";
    DeviceMetric_CalibrationStateKind["_unspecified"] = "unspecified";
})(DeviceMetric_CalibrationStateKind = exports.DeviceMetric_CalibrationStateKind || (exports.DeviceMetric_CalibrationStateKind = {}));
var EnumType_1 = require("../../EnumType");
exports.RTTI_DeviceMetric_Calibration = t.recursion('IDeviceMetric_Calibration', function () {
    return t.partial({
        id: t.string,
        extension: t.array(RTTI_Extension_1.RTTI_Extension),
        modifierExtension: t.array(RTTI_Extension_1.RTTI_Extension),
        type: EnumType_1.createEnumType(DeviceMetric_CalibrationTypeKind, 'DeviceMetric_CalibrationTypeKind'),
        _type: RTTI_Element_1.RTTI_Element,
        state: EnumType_1.createEnumType(DeviceMetric_CalibrationStateKind, 'DeviceMetric_CalibrationStateKind'),
        _state: RTTI_Element_1.RTTI_Element,
        time: RTTI_instant_1.RTTI_instant,
        _time: RTTI_Element_1.RTTI_Element
    });
});
