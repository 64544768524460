import React from 'react';
import {FormItemProps} from 'formik-antd/lib/form-item';
import {validateValue} from '../../validation/validate-value';
import {RequiredValidationRuleId} from '../../validation/validation-rules/required-validation';
import {RequiredDefinedValidationRuleId} from '../../validation/validation-rules/required-defined-validation';
import {ValidationRules} from '../../validation/validation-rules/validation-rules';
import {MaxLengthValidationRuleId} from '../../validation/validation-rules/max-length-validation';
import {JsonValidationRuleId} from '../../validation/validation-rules/json-validation';
import {MinValidationRuleId} from '../../validation/validation-rules/min-validation';
import {RegexValidationId} from '../../validation/validation-rules/regex-validation';
import {EmailValidationRuleId} from '../../validation/validation-rules/email-validation';
import {PhoneValidationRuleId} from '../../validation/validation-rules/phone-validation';
import {PostalCodeValidationRuleId} from '../../validation/validation-rules/postal-code-validation';
import {Ids, StrLang} from '@canimmunize/tools';
import {UserPasswordValidationRuleId} from '../../validation/validation-rules/user-password-validation';
import {NumberValidationRuleId} from '../../validation/validation-rules/number-validation-rule';
import {RequiredObjectValidationRuleId} from '../../validation/validation-rules/required-object-validation';
import {PreviousDateValidationRuleId} from '../../validation/validation-rules/previous-date-validation-rule';
import {FutureDateValidationRuleId} from '../../validation/validation-rules/future-date-validation-rule';

export interface ValidateFormItemProps {
  /**
   * The list of validation rules the component should use
   *
   * @type {ValidationRules[]}
   * @memberof ValidateFormItemProps
   */
  validationRules: ValidationRules[];
  /**
   * This function should return a FormItem component. The validate argument
   * passed to this function should be passed in as the validate prop for the
   * FormItem component.
   *
   * @memberof ValidateFormItemProps
   */
  renderFormItem: (validate: FormItemProps['validate']) => React.ReactNode;
}

/**
 * Use this HOC to wrap a FormItem that requires validation.
 *
 * @param props
 */
export const ValidateFormItem = (props: ValidateFormItemProps) => {
  return (
    <>
      {props.renderFormItem((formItemValue) => {
        return formItemValidate(props.validationRules, formItemValue);
      })}
    </>
  );
};

/**
 * Validates the value parameter based on the validationRules parameter and
 * returns a string to display if there's an error
 *
 * @param {ValidationRules[]} validationRules
 * @param {*} value
 * @returns A string representing the validation error or an empty string if
 * there's no error
 */
function formItemValidate(validationRules: ValidationRules[], value: any): string {
  const validationFailure = validateValue(validationRules, value);
  const path = window.location.pathname;

  //Find language from window pathname to use in translating error message
  let lang = 'en';
  if (path.includes('/fr/')) lang = 'fr';

  if (validationFailure === true) {
    return '';
  }

  switch (validationFailure.validationRuleType) {
    case RequiredValidationRuleId:
    case RequiredObjectValidationRuleId:
    case RequiredDefinedValidationRuleId: {
      return StrLang(lang, Ids.this_field_is_required);
    }
    case MaxLengthValidationRuleId: {
      return `This field cannot have more than ${validationFailure.maxLength} characters`;
    }
    case JsonValidationRuleId: {
      return `This field is not valid JSON`;
    }
    case EmailValidationRuleId: {
      return StrLang(lang, Ids.shield_invalid_email_field);
    }
    case PhoneValidationRuleId: {
      return StrLang(lang, Ids.phu_valid_phone_number);
    }
    case PostalCodeValidationRuleId: {
      return StrLang(lang, Ids.outbreaks_invalid_postal_code);
    }
    case MinValidationRuleId: {
      const OrText: string = validationFailure.inclusive ? 'or equal to' : '';

      return `This field should be greater than ${OrText} ${validationFailure.min}`;
    }
    case RegexValidationId: {
      return validationFailure.errorMessage
        ? validationFailure.errorMessage
        : `This field should meet the regex ${validationFailure.regex}`;
    }
    case UserPasswordValidationRuleId: {
      return 'Passwords must be at least 8 characters in length and contain 3 of the following: uppercase letters, lowercase letters, numbers, symbols.';
    }
    case NumberValidationRuleId: {
      return 'This field is not a valid number';
    }
    case PreviousDateValidationRuleId: {
      return 'You must select a date on or preceding today';
    }
    case FutureDateValidationRuleId: {
      return 'You must select a date after today.';
    }
    default: {
      return '';
    }
  }
}
