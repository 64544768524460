import {CaretRightOutlined} from '@ant-design/icons';
import {Ids} from '@canimmunize/tools';
import {Alert, Button, Collapse} from 'antd';
import MaskedInput from 'antd-mask-input';
import axios from 'axios';
import {FormikContextType, useFormikContext} from 'formik';
import {Form} from 'formik-antd';
import parse from 'html-react-parser';
import React from 'react';
import {useUrlData} from '../../../../../components/scheduler/util';
import {ValidateFormItem} from '../../../../../components/validate-form-item';
import {useStr} from '../../../../../services/str';
import {RequiredValidationRuleId} from '../../../../../validation/validation-rules/required-validation';
import {YukonPortalPage} from '../../../components/yukon-portal-page';
import {useCOVIDPortalConfig} from '../../../util/portal-config';
import {SessionConfigContext} from '../../../util/session-config';

export const AuthenticationPage = (props) => {
  const {lang} = useUrlData();
  const Str = useStr();
  const formikContext: FormikContextType<any> = useFormikContext();
  const {syncUrl} = useCOVIDPortalConfig();
  const [submitting, setSubmitting] = React.useState(false);
  const {setSessionId} = React.useContext(SessionConfigContext);

  const postSubmit = async () => {
    if (formikContext.values.authCode?.length !== 6) {
      formikContext.setFieldError('authCode', Str(Ids.this_field_is_required));
      return;
    }

    setSubmitting(true);
    formikContext.setFieldValue('error', undefined);
    const result = await axios
      .post(`${syncUrl}/public/portal/verify-code`, {
        code: formikContext.values.authCode,
        contactMethod: formikContext.values.selectedAuthContactMethod,
        language: lang,
      })
      .catch((err) => err.response);

    setSubmitting(false);
    if (result.status === 200) {
      formikContext.setFieldValue('patientInfo', result.data);
      formikContext.setFieldValue('authCode', '');
      formikContext.setFieldValue('mode', 'cred-delivery');
      if (setSessionId) {
        setSessionId(result.data?.sessionId);
      }
    } else if (result.status === 403) {
      formikContext.setFieldValue('error', <>{parse(Str(Ids.yk_invalid_auth_code))}</>);
      window.scrollTo(0, 0);
    } else {
      formikContext.setFieldValue('error', <>{parse(Str(Ids.yk_pvc_system_error))}</>);
      window.scrollTo(0, 0);
    }
  };

  const pageBody = (
    <>
      <p>{Str(Ids.yk_auth_code_subtitle)}</p>
      {formikContext.values.error && (
        <Alert
          type="error"
          message={formikContext.values.error}
          style={{marginBottom: 15}}
          showIcon
        />
      )}
      <ValidateFormItem
        validationRules={[
          {
            validationRuleType: RequiredValidationRuleId,
          },
        ]}
        renderFormItem={(validate) => {
          return (
            <Form.Item
              label={Str(Ids.yk_auth_code_label)}
              name="authCode"
              validate={validate}
              required
            >
              <MaskedInput
                defaultValue={formikContext.values.authCode}
                onChange={(e) => {
                  formikContext.setFieldValue('authCode', e.target.value.replaceAll('-', ''));
                }}
                name="authCode"
                size="large"
                mask="111111"
                placeholderChar="-"
                onPressEnter={() => {
                  formikContext.setFieldValue('subFormSubmit', postSubmit);
                }}
                style={{
                  width: 'fit-content',
                }}
                autoFocus
              />
            </Form.Item>
          );
        }}
      />

      <Collapse
        expandIcon={({isActive}) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
        className="site-collapse-custom-collapse"
        style={{marginBottom: 24}}
      >
        <Collapse.Panel
          header={Str(Ids.yk_no_auth_code)}
          key="1"
          className="site-collapse-custom-collapse"
        >
          <p>{Str(Ids.yk_no_auth_code_info)}</p>
        </Collapse.Panel>
      </Collapse>
    </>
  );

  const pageFooter = (
    <>
      {' '}
      <Button
        size="large"
        onClick={() => {
          formikContext.setFieldValue('error', undefined);
          formikContext.setFieldValue('authCode', '');
          formikContext.setFieldValue('mode', 'verification');
        }}
        style={{
          border: '1px solid',
          marginRight: '10px',
          marginBottom: '10px',
          width: '200px',
        }}
      >
        {Str(Ids.yk_pvc_go_back_step)}
      </Button>
      <Button
        size="large"
        type="primary"
        loading={submitting}
        onClick={() => {
          formikContext.setFieldValue('subFormSubmit', postSubmit);
          formikContext.handleSubmit();
        }}
        style={{
          width: '200px',
        }}
      >
        {Str(Ids.yk_pvc_next_step)}
      </Button>
    </>
  );

  return (
    <>
      <YukonPortalPage
        pageNumber={Str(Ids.yk_pvc_page5)}
        title={Str(Ids.yk_auth_code_title)}
        body={pageBody}
        footer={pageFooter}
        backPage="verification"
      ></YukonPortalPage>
    </>
  );
};
