"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTTI_url = exports.RTTI_urlType = void 0;
var io_ts_1 = require("io-ts");
var RTTI_urlType = (function (_super) {
    __extends(RTTI_urlType, _super);
    function RTTI_urlType() {
        var _this = _super.call(this, 'RTTI_urlType', function (m) {
            return typeof m === 'string' && RTTI_urlType.regexExp.test(m.toString());
        }, function (m, c) { return (_this.is(m) ? io_ts_1.success(m) : io_ts_1.failure(m, c)); }, io_ts_1.identity) || this;
        return _this;
    }
    RTTI_urlType.regexExp = /^\S*$/;
    return RTTI_urlType;
}(io_ts_1.Type));
exports.RTTI_urlType = RTTI_urlType;
exports.RTTI_url = new RTTI_urlType();
