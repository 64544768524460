import * as Icons from '@ant-design/icons';
import {ExclamationCircleOutlined} from '@ant-design/icons';
import {Alert, Button, Modal, Table, Tag} from 'antd';
import {ColumnsType} from 'antd/lib/table';
import _ from 'lodash';
import React from 'react';
import {CSVReader} from 'react-papaparse';
import {FhirUtils} from '../../../services/fhir';
import {ValidationRules} from '../../../validation/validation-rules/validation-rules';
import {OptionalParamsType, validateCSVData} from '../../util/csv/csv-upload-validation';
import {downloadTemplate} from '../download-template';

export interface CSVParseError {
  code: string;
  message: string;
  row: number;
  type: string;
}

export interface UploadModalProps {
  visible: boolean;
  onClose: () => void;
  onClickUpload: (uploadData: any[]) => any;
  preProcessData: (row: any) => any;
  title: string;
  getValidationRules: (row, settings?) => {[property: string]: ValidationRules[]};
  validationOptions?: OptionalParamsType;
  getUniqueKey?: (row) => string;
  templateName: string;
  howToUse: string;
  uploadedColumns: ColumnsType<any>;
}

export const UploadModal = (props: UploadModalProps) => {
  const {visible, onClose, title} = props;
  const [uploadData, setUploadData] = React.useState<any>([]);
  const [uploadErrors, setUploadErrors] = React.useState<CSVParseError[]>([]);
  const [uploadResult, setUploadResult] =
    React.useState<{rows: any[]; status?: string} | undefined>();
  const [uploadError, setUploadError] = React.useState('');
  const [uploading, setUploading] = React.useState(false);
  const client = FhirUtils.useAxiosClient();
  const [maxListItems, setMaxListItems] = React.useState<any>();

  React.useEffect(() => {
    getMaxListItems();
  }, []);

  async function getMaxListItems() {
    const response = await client.get('/public/settings/portal/maxListItems');
    setMaxListItems(Number(response.data?.value));
  }

  const handleOnDrop = (data) => {
    if (props.getUniqueKey) {
      const reduceFn = (acc, row) => ({...acc, [props.getUniqueKey!(row)]: row});
      const uniqueMap = data.reduce(reduceFn, {});
      data = Object.values(uniqueMap);
    }

    const errors: any = _.flatten(data.filter((d) => d.errors.length).map((d) => d.errors));

    // CSV Validation
    const validationErrors = validateCSVData(
      props.getValidationRules,
      data,
      props.validationOptions
    );

    const parsedData = data.filter((d) => d.errors.length === 0).map((d) => d.data);
    const processedData = preProcessData(parsedData);
    const processingErrors = processedData
      .map((d, index) => {
        return {row: index, message: d.error, code: '', type: ''};
      })
      .filter((d) => !!d.message);

    setUploadErrors(validationErrors.concat(errors).concat(processingErrors));

    setUploadData(processedData);
  };

  const preProcessData = (data) => {
    //If no data is found
    if (data.length === 0) {
      setUploadError('No data found.');
    } else if (maxListItems && data.length > maxListItems) {
      setUploadError(`Lists may contain a maximum of ${maxListItems} rows.`);
    }

    try {
      const processedData = data.map(props.preProcessData);
      return processedData;
    } catch (error: any) {
      setUploadError(error.message);
    }
    return [];
  };

  const handleOnError = (e) => {
    console.log(e);
  };

  const handleOnRemoveFile = (e) => {
    setUploadData([]);
    setUploadErrors([]);
    setUploadError('');
  };

  const onRetry = () => {
    setUploadData([]);
    setUploadErrors([]);
    setUploadError('');
  };

  const onCancel = () => {
    setUploadData([]);
    setUploadErrors([]);
    setUploadResult(undefined);
    setUploadError('');
    onClose();
  };

  //Adds 'errors' field to tableData to display errors under 'Upload Result' column
  const appendUploadErrors = (uploadData, uploadError) => {
    uploadData.forEach((data, index) => {
      data.errors = uploadError.filter((obj) => obj.row === index);
    });
    return uploadData;
  };

  const tableData = uploadResult
    ? uploadData.map((uploadRow, i) => {
        const resultRow = uploadResult?.rows ? uploadResult.rows[i] : undefined;
        return {
          ...uploadRow,
          ...resultRow?.resource,
          action: resultRow?.action,
          error: resultRow?.error,
          errors: resultRow?.errors,
        };
      })
    : appendUploadErrors(uploadData, uploadErrors);

  //Helper to get the current 'state' of the upload modal
  const getUpdateState = () => {
    if (uploadError !== '') return 'uploadedWithOverallError';
    if (uploadData.length === 0) return 'base';
    if (uploadErrors.length) return 'validatedWithErrors';
    if (!uploadResult) return 'validatedWithoutErrors';
    if ((uploadResult && uploadResult.rows?.filter((row) => row.error).length) || uploadError)
      return 'uploadedWithErrors';
    else return 'uploadedWithoutErrors';
  };

  const onClickUpload = async () => {
    setUploading(true);
    setUploadError('');
    try {
      const result = await props.onClickUpload(uploadData);
      setUploadResult(result);
    } catch (error: any) {
      setUploadResult(error.response?.data);
      setUploadError(
        error.response?.data?.issue ? error.response?.data?.issue[0]?.diagnostics : error.message
      );
    } finally {
      setUploading(false);
    }
  };

  const UploadButton = () => {
    const uploadState = getUpdateState();

    if (uploadState === 'validatedWithErrors') {
      return (
        <Button
          type="primary"
          shape="round"
          icon={<Icons.RedoOutlined />}
          onClick={onRetry}
          size={'large'}
        >
          Re-Upload
        </Button>
      );
    }

    if (uploadState === 'uploadedWithoutErrors' || uploadState === 'uploadedWithErrors')
      return (
        <Button type="primary" shape="round" size={'large'} onClick={onCancel}>
          Finish
        </Button>
      );

    return (
      <Button
        type="primary"
        shape="round"
        icon={<Icons.UploadOutlined />}
        size={'large'}
        loading={uploading}
        onClick={onClickUpload}
        disabled={uploadData.length === 0 || uploadErrors.length > 0}
      >
        Upload
      </Button>
    );
  };

  const AlertComponent = (props) => {
    return <Alert type={props.type} showIcon message={props.message} style={{marginBottom: 15}} />;
  };

  const AlertResponseGenerator = () => {
    const uploadState = getUpdateState();

    switch (uploadState) {
      case 'base':
        return null;
      case 'uploadedWithErrors':
        return (
          <AlertComponent
            type="warning"
            message={`${uploadResult?.rows?.filter((row) => !row.error).length} out of ${
              uploadResult?.rows.length
            } rows successfully uploaded. See errors below.`}
          />
        );
      case 'uploadedWithoutErrors':
        return (
          <AlertComponent
            type="success"
            message={
              uploadResult?.status
                ? uploadResult.status
                : `${uploadResult?.rows?.length} rows successfully uploaded.`
            }
          />
        );
      case 'validatedWithErrors':
        return (
          <AlertComponent
            type="error"
            message={`Reupload CSV file after fixing the following ${uploadErrors.length} error(s) below.`}
          />
        );
      case 'validatedWithoutErrors':
        return (
          <AlertComponent
            type="info"
            message={`${uploadData.length} rows validated and prepared for upload.`}
          />
        );
      case 'uploadedWithOverallError':
        return (
          <AlertComponent
            type="error"
            message={`No rows were uploaded because of the following error: ${uploadError}`}
          />
        );
    }
  };

  //Button ref for the CSV File Upload
  const buttonRef: any = React.createRef();

  const handleOpenDialog = (e) => {
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  };

  const uploadState = getUpdateState();

  const columns = props.uploadedColumns.concat({
    title: 'Upload Result',
    render: (_, row: any) => {
      return uploadResult && (!row.errors || row.errors.length === 0) ? (
        row.error ? (
          <Tag color="red">{row.error}</Tag>
        ) : (
          <Tag color="green">{row.action}</Tag>
        )
      ) : row.errors.length ? (
        //If there is only one error
        row.errors.length === 1 ? (
          <Tag color="red">{row.errors[0].message || row.errors[0]}</Tag>
        ) : (
          <div style={{maxWidth: 200}}>
            <Tag icon={<ExclamationCircleOutlined />} color="red">
              Multiple Errors
            </Tag>
            {row.errors.map((err) => (
              <p style={{marginTop: 15, marginBottom: 0}}>{err.message || err}</p>
            ))}
          </div>
        )
      ) : (
        'Valid – Not Yet Uploaded'
      );
    },
    fixed: 'right',
  });

  return (
    <Modal
      visible={visible}
      width={'85%'}
      onCancel={onCancel}
      footer={
        <>
          {uploadState !== 'uploadedWithoutErrors' && (
            <Button shape="round" size={'large'} onClick={onCancel}>
              Cancel
            </Button>
          )}
          <UploadButton />
        </>
      }
    >
      <h3>{title}</h3>
      <div style={{marginBottom: 15}}>
        {uploadData.length === 0 && (
          <>
            <Button
              key="fhir"
              style={{marginBottom: 15}}
              onClick={() => downloadTemplate(props.templateName, client)}
            >
              <Icons.TableOutlined /> Download Template
            </Button>
            <p>{props.howToUse}</p>
            {uploadError !== '' && (
              <AlertComponent
                type="error"
                message={`${uploadError} Please try again.`}
              ></AlertComponent>
            )}
            <CSVReader
              ref={buttonRef}
              onDrop={handleOnDrop}
              onError={handleOnError}
              addRemoveButton
              onRemoveFile={handleOnRemoveFile}
              config={{header: true, skipEmptyLines: 'greedy'}}
            >
              <aside
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'row',
                  marginBottom: 10,
                }}
              >
                <Button type="default" size="large" onClick={handleOpenDialog}>
                  Click to Upload CSV File
                </Button>
              </aside>
              <span>Or drag and drop your CSV file here.</span>
            </CSVReader>
          </>
        )}

        {uploadData.length > 0 && (
          <>
            <AlertResponseGenerator />
            <Table
              columns={columns}
              scroll={{x: 'max-content'}}
              dataSource={tableData}
              pagination={{defaultPageSize: 20}}
              rowKey={(item) => item.name}
            />
          </>
        )}
      </div>
    </Modal>
  );
};
