import moment from 'moment';
import React from 'react';
import {Button, Space, Tooltip, Typography} from 'antd';
import {ReloadOutlined, HistoryOutlined} from '@ant-design/icons';

import {FHIRTable, FHIRTableProps} from '../../fhir-table';
import {initialFlagTypes, UpdateFlagModal} from './update-flag-modal';
import {AbilityContext} from '../../../services/roles/ability-context';
import {getStoredOrg} from '../../../models/organizations';
import {VersionHistoryModal} from './version-history-modal';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {colors} from '@canimmunize/tools';
import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';

const {Title} = Typography;

/* The columns of the flags table. */
const columns = [
  {
    title: 'Type',
    render: (_, flag) => {
      if (moment(flag.startDate).isAfter(moment(flag.endDate))) {
        return flagWarning(flag.type);
      } else return initialFlagTypes(flag.type);
    },
  },
  {
    title: 'Start',
    render: (_, flag) => flag.startDate && moment(flag.startDate).format('LL'),
  },
  {
    title: 'End',
    render: (_, flag) => flag.endDate && moment(flag.endDate).format('LL'),
  },
  {
    title: 'Created At',
    render: (_, flag) => moment(flag.createdAt).format('LLL'),
  },
  {
    title: 'Created By',
    render: (_, flag) => flag.creatorEmail ?? 'Public',
  },
  {
    title: 'Updated At',
    render: (_, flag) =>
      flag.updatedAt === flag.createdAt ? null : moment(flag.updatedAt).format('LLL'),
  },
  {
    title: 'Updated By',
    render: (_, flag) =>
      flag.updatedAt === flag.createdAt ? null : flag.recentUpdaterEmail ?? 'Public',
  },
];

const flagWarning = (flagType: string) => {
  return (
    <>
      {initialFlagTypes(flagType)}
      <Tooltip title="This flag has no active time period">
        <FontAwesomeIcon
          icon={faExclamationTriangle}
          color={colors.yellow}
          style={{marginLeft: 10}}
        />
      </Tooltip>
    </>
  );
};

interface FlagsTableProps {
  /* The patient. */
  patient: any;
  /* Whether or not to include the version history column. */
  hideVersionHistoryColumn?: boolean;
  /* Properties to pass down to the underlying FHIRTable. */
  tableProps?: FHIRTableProps;
  /* Object containing functions to refresh other tabs. */
  refreshTabFunctions?: {[tab: string]: Function};
}

/* A table component to display, edit, create, and delete patient flags. */
export const FlagsTable = (props: FlagsTableProps) => {
  const {patient, hideVersionHistoryColumn, tableProps, refreshTabFunctions} = props;
  const ability = React.useContext(AbilityContext);
  const storedOrg = getStoredOrg(patient?.organizationId);
  const canViewVersionHistory =
    !hideVersionHistoryColumn && storedOrg && ability.can('view', 'patients', 'flag', 'history');

  /* Table states. */
  const [reloadTableKey, setReloadTableKey] = React.useState(false);
  const reloadTable = () => setReloadTableKey(!reloadTableKey);
  const reloadAllTables = () => {
    reloadTable();
    refreshTabFunctions?.['ImmsWidget']?.();
  };
  const [selectedFlag, setSelectedFlag] = React.useState<any>(undefined);

  /* Create modal states. */
  const [createModalVisible, setCreateModalVisible] = React.useState(false);
  const closeCreateModal = () => {
    setCreateModalVisible(false);
    setSelectedFlag(undefined);
  };
  const openCreateModal = (flag?: any) => {
    if (storedOrg && ability.can('update', 'patients', 'flag')) setCreateModalVisible(true);
    setSelectedFlag(flag);
  };

  /* Version history modal states. */
  const [versionHistoryModalVisible, setVersionHistoryModalVisible] = React.useState(false);
  const openVersionHistoryModal = (flag?: any) => {
    setSelectedFlag(flag);
    setVersionHistoryModalVisible(true);
  };
  const closeVersionHistoryModal = () => {
    setVersionHistoryModalVisible(false);
    setSelectedFlag(undefined);
  };

  const versionHistoryColumn = {
    title: '',
    render: (_, flag) => (
      <Button
        icon={
          <HistoryOutlined
            style={{opacity: '.3'}}
            onClick={(e) => {
              e.stopPropagation();
              openVersionHistoryModal(flag);
            }}
          />
        }
        type="text"
      />
    ),
  };

  return (
    <>
      {/* Title bar. */}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingBottom: 5,
          alignItems: 'center',
        }}
      >
        <div style={{verticalAlign: 'center'}}>
          <Title level={4}>
            {'Flags '}
            <Tooltip title="reload table">
              <Button
                icon={<ReloadOutlined style={{opacity: '.3'}} onClick={reloadTable} />}
                type="text"
              />
            </Tooltip>
          </Title>
        </div>
        {/* Show Add Note button only when user has access to patient's managing org */}
        {storedOrg && ability.can('create', 'patients', 'flag') && (
          <Space>
            <Button type="primary" onClick={() => openCreateModal()}>
              Add Flag
            </Button>
          </Space>
        )}
      </div>

      {/* The actual table for displaying flags. */}
      <FHIRTable
        mode="table"
        fhirResource="flag"
        fixedFilters={{
          patientId: patient.id,
        }}
        columns={[...columns, ...(canViewVersionHistory ? [versionHistoryColumn] : [])]}
        size="small"
        triggerRefresh={reloadTableKey}
        onClickRow={openCreateModal}
        hideSearch
        {...(tableProps ?? {})}
      />

      {/* The modal, hidden by default, to edit/create/delete flags. */}
      <UpdateFlagModal
        visible={createModalVisible}
        flag={selectedFlag}
        onClose={closeCreateModal}
        afterSubmit={reloadAllTables}
        afterDelete={reloadAllTables}
        patient={patient}
      />

      {/* The modal, hidden by default, to view the past versions of a flag. */}
      {canViewVersionHistory && (
        <VersionHistoryModal
          visible={versionHistoryModalVisible}
          onClose={closeVersionHistoryModal}
          fhirResource="flag"
          entityId={selectedFlag?.id}
          columns={columns}
        />
      )}
    </>
  );
};
